import { isEqual } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { UserState } from "../../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../../web/store/store";
import { IntroScreenLayout } from "../../../../../organisms";
import { strings } from "./IntroBasalScreenContent";

const IntroTempBasalScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const image = require("../../../../../../../../assets/setup_intro_bg_basal_6.png");
  const { languageContent } = useLanguageContent(strings);
  return (
    <IntroScreenLayout
      heading={languageContent.basal}
      body={languageContent.continuous}
      image={image}
      navigateTo={userState.isExpert ? "ExpertMaxBasal" : "MaxBasal"}
    />
  );
};

export default IntroTempBasalScreen;
