import { isEqual } from "lodash"
import React from "react"
import { View } from "react-native-web"
import { useSelector } from "react-redux"
import { ReactComponent as ModeIconAutomated } from "../../../assets/modeIcons/titlebar_auto.svg"
import { ReactComponent as ModeIconLimited } from "../../../assets/modeIcons/titlebar_limited.svg"
import { ReactComponent as ModeIconManual } from "../../../assets/modeIcons/titlebar_manual.svg"
//ICONS
import { ReactComponent as ModeIconUnknown } from "../../../assets/modeIcons/titlebar_unknown.svg"
import { UserState } from "../../../web/store/reducers/user"
import { RootState } from "../../../web/store/store"

function useModeIconSmall() {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual,
  )
  const [icon, setIcon] = React.useState<any>()

  React.useEffect(() => {
    if (
      (!userState.automatedMode && !userState.setupComplete) ||
      (!userState.automatedMode && !userState.podConfigured)
    ) {
      setIcon(
        <View style={{ paddingRight: 8 }}>
          <ModeIconUnknown />
        </View>,
      )
    } else if (!userState.automatedMode) {
      setIcon(
        <View style={{ paddingRight: 8 }}>
          <ModeIconManual />
        </View>,
      )
    } else if (userState.limitedMode) {
      setIcon(
        <View style={{ paddingRight: 8 }}>
          <ModeIconLimited />
        </View>,
      )
    } else {
      setIcon(
        <View style={{ paddingRight: 8 }}>
          <ModeIconAutomated />
        </View>,
      )
    }
  }, [userState.automatedMode, userState.limitedMode])

  return { icon }
}

export default useModeIconSmall
