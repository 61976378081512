let strings = {
  "en-US": {
    carbs: "Carbs",
    bolusNow: "Bolus Now",
    sensor: "Sensor",
    bg: "Glucose",
    mealBolus: "Meal: %1 U",
    correction: "Correction: %2 U",
    bolusExtended: "Bolus Extended",
    mealExtended: "Meal: %3 U",
    totalBolus: "Total Bolus",
    calculations: "CALCULATIONS",
    adjustedIOB: "Adjusted for IOB of 1 U",
    start: "Start",
    off: "OFF",
    cancel: "Cancel",
    hr: "hr",
    hrs: "hrs",
    u: "U"
  },
  "en-GB": {
    carbs: "Carbs",
    bolusNow: "Bolus Now",
    sensor: "Sensor",
    bg: "Glucose",
    mealBolus: "Meal: %1 U",
    correction: "Correction: %2 U",
    bolusExtended: "Bolus Extended",
    mealExtended: "Meal: %3 U",
    totalBolus: "Total Bolus",
    calculations: "CALCULATIONS",
    adjustedIOB: "Adjusted for IOB of 1 U",
    start: "Start",
    off: "OFF",
    cancel: "Cancel",
    hr: "hr",
    hrs: "hrs",
    u: "U"
  },
  "de-DE": {
    carbs: "KH",
    bolusNow: "Bolus jetzt",
    sensor: "BZ",
    bg: "Glukose",
    mealBolus: "Mahlzeit: %1 E",
    correction: "Korrektur: %2 E",
    bolusExtended: "Bolus verzögert",
    mealExtended: "Mahlzeit: %3 E",
    totalBolus: "Gesamtbolus",
    calculations: "BERECHNUNGEN",
    adjustedIOB: "Angepasst für 1 E AI",
    start: "Starten",
    off: "AUS",
    cancel: "Abbr.",
    hr: "Std.",
    hrs: "Stdn.",
    u: "E"
  },
  "es-US": {
    carbs: "Carbohidratos",
    bolusNow: "Bolo ahora",
    sensor: "GS",
    bg: "Glucosa",
    mealBolus: "Comida: %1 U",
    correction: "Corrección: %2 U",
    bolusExtended: "Bolo extendido",
    mealExtended: "Comida: %3 U",
    totalBolus: "Bolo total",
    calculations: "CÁLCULOS",
    adjustedIOB: "Ajustado para IOB de 1 U",
    start: "Iniciar",
    off: "APAGADO",
    cancel: "Cancelar",
    hr: "h",
    hrs: "h",
    u: "U"
  },
  "fr-FR": {
    carbs: "Glucides",
    bolusNow: "Bolus actuel",
    sensor: "Capteur",
    bg: "Glucose",
    mealBolus: "Repas : 1 % U",
    correction: "Correction : 2 % U",
    bolusExtended: "Bolus prolongé",
    mealExtended: "Repas : 3 % U",
    totalBolus: "Bolus total",
    calculations: "CALCULS",
    adjustedIOB: "Ajusté pour l'InA de 1 U",
    start: "Démarrer",
    off: "Désactivé",
    cancel: "Annuler",
    hr: "h",
    hrs: "h",
    u: "U"
  },
  "nl-NL": {
    carbs: "KH",
    bolusNow: "Bolus nu",
    sensor: "Sensor",
    bg: "Glucose",
    mealBolus: "Maaltijd: %1 E",
    correction: "Correctie: %2 E",
    bolusExtended: "Bolus verlengd",
    mealExtended: "Maaltijd: %3 E",
    totalBolus: "Totale bolus",
    calculations: "BEREKENINGEN",
    adjustedIOB: "Afgestemd op IOB van 1 E",
    start: "Start",
    off: "UIT",
    cancel: "Annuleren",
    hr: "u.",
    hrs: "u.",
    u: "E"
  }
};

export { strings };
