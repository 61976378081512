import React, { MouseEventHandler, PropsWithChildren } from 'react';
import classNames from 'classnames';

interface ModalProps {
  title?: string | React.ReactNode;
  leftButtonText?: string;
  leftButtonOnClick?: MouseEventHandler<HTMLButtonElement>;
  rightButtonText?: string;
  rightButtonOnClick?: MouseEventHandler<HTMLButtonElement>;
  rowControls?: boolean;
  width?: string;
}

export const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  children,
  title,
  leftButtonText,
  leftButtonOnClick,
  rightButtonText,
  rightButtonOnClick,
  rowControls = false,
  width,
}) => {
  const rightButtonClasses = classNames('w-1/2 px-2 py-[11px] font-semibold', {
    'w-full': !leftButtonText || rowControls,
    'border-t border-ios-gray-7': rowControls,
  });

  const leftButtonClasses = classNames(
    'border-r border-ios-gray-7 w-1/2 px-2 py-[11px]',
    {
      'w-full': rowControls,
    }
  );
  const modalClasses = classNames(
    'bg-ios-gray-6 rounded-[16px] min-w-[240px] max-w-[300px]',
    width
  );

  return (
    <div className="absolute inset-0 bg-black/50 flex justify-center font-ios items-center">
      <div className={modalClasses}>
        <div className="p-2 pb-0 font-ios text-center">
          {title && (
            <div className="font-semibold text-regular">{title}</div>
          )}
          {children && <div className="text-modals pb-2">{children}</div>}
        </div>
        {(leftButtonText || rightButtonText) && (
          <div className="border-t text-ios-blue border-ios-gray-7 text-regular">
            {leftButtonText && (
              <button onClick={leftButtonOnClick} className={leftButtonClasses}>
                {leftButtonText}
              </button>
            )}
            {rightButtonText && (
              <button
                onClick={rightButtonOnClick}
                className={rightButtonClasses}
              >
                {rightButtonText}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
