import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { FormattedMessage } from 'react-intl';

import { Button } from 'web/components/ios/Button';
import { ReactComponent as OmniPodLogo } from '../../assets/omnipod5_logo.svg';
import { IOSRoutesEnums } from '../types';
import { messages } from '../FtsStartScreenStack.messages';

export const SplashScreen = () => {
  const { navigate } = useNavigation();

  return (
    <div className="flex justify-between px-2 flex-col flex-1 items-stretch bg-white">
      <div className="flex flex-col items-center justify-center grow">
        <OmniPodLogo style={{ width: 185, height: 99 }} />
        <div className="px-2 mt-2">
          <div className="mt-2 text-center text-ios-secondaryGray opacity-60">
            <FormattedMessage {...messages.hippaPrivacyNoticeMessage} />
          </div>
        </div>
      </div>

      <div className="flex flex-row mb-4 justify-between">
        <Button
          className="w-[160px] rounded-lg mr-1"
          onClick={() => navigate(IOSRoutesEnums.TermsConditions)}
        >
          <FormattedMessage {...messages.readTCTitle} />
        </Button>

        <Button
          className="w-[160px] rounded-lg text-nowrap"
          onClick={() => navigate(IOSRoutesEnums.WelcomeOmnipod5)}
          variant="colored"
        >
          <FormattedMessage {...messages.agreeContinueTitle} />
        </Button>
      </div>
    </div>
  );
};
