import {
  CardStyleInterpolators,
  createStackNavigator
} from "@react-navigation/stack";
import React from "react";
import { Animated } from "react-native";
import { BackButton } from "../../components/atoms";
import {
  IntroTempBasalScreen,
  SetTempBasalScreen
} from "../../components/screens";
import { useModeIconSmall } from "../../hooks";
import useLanguageContent from "../../hooks/useLanguageContent";
import theme from "../../theme";
import { strings } from "./SetTempBasalStackContent";

const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const SetTempBasalStack: React.FC = () => {
  const { icon } = useModeIconSmall();
  const headerRef = new Animated.ValueXY().y;
  const { languageContent } = useLanguageContent(strings);

  const animateHeader = React.useCallback(() => {
    Animated.timing(headerRef, {
      toValue: 0,
      duration: 600,
      useNativeDriver: false
    }).start();
  }, [headerRef]);

  React.useEffect(() => {
    animateHeader();
  }, [animateHeader]);

  return (
    <>
      <Stack.Navigator initialRouteName="IntroTempBasal" mode="card">
        <Stack.Screen
          name="IntroTempBasal"
          options={{
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            headerStyle: defaultHeaderStyle,
            title: "",
            headerLeft: () => (
              <BackButton text={languageContent.setTempBasal} white={false} />
            ),
            headerRight: () => icon
          }}
          component={IntroTempBasalScreen}
        />
        <Stack.Screen
          name="TempBasal"
          options={{
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            headerStyle: defaultHeaderStyle,
            title: "",
            headerLeft: () => (
              <BackButton text={languageContent.tempBasal} white={false} />
            ),
            headerRight: () => icon
          }}
          component={SetTempBasalScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default SetTempBasalStack;
