import React, { ChangeEvent } from 'react';

interface Option {
  text: string;
  value: string | number;
}

interface Props {
  label: string;
  name: string;
  options: Option[];
  defaultValue?: string | number;
  onChange?: Function;
  value?: string | number;
}

const SelectBox: React.FC<Props> = (props) => {
  const { label, name, options, onChange, value, defaultValue } = props;
  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="select-box">
      <label className="block text-2xl font-bold mb-0.5" htmlFor={name}>
        {label}
      </label>
      <select
        className="w-full px-0.5 py-1 text-modes-automated text-base border-solid border-2 rounded border-ios-gray-3 font-bold"
        name={name}
        id={name}
        defaultValue={defaultValue}
        value={value}
        onChange={handleOnChange}
      >
        {options.map(({ text, value: optionValue }) => {
          return (
            <option key={optionValue} value={optionValue}>
              {text}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectBox;
