import React from "react";
import { ImageSourcePropType } from "react-native";
import { Image, StyleSheet, Text, View } from "react-native-web";
import theme from "../../../theme";

interface Props {
  label: string;
  icon: ImageSourcePropType | string | undefined;
  subLabel?: string;
}

const HistoryScreenTile: React.FC<Props> = ({ label, icon, subLabel }) => {
  return (
    <View
      style={{
        flexDirection: "column",
        flex: 1
      }}>
      <Image style={[styles.icon]} resizeMode="contain" source={icon} />
      <Text style={[styles.description]}>{label}</Text>
      {subLabel && (
        <Text
          style={[styles.description, { color: theme.colors.text.inputText }]}>
          {subLabel}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  description: {
    fontWeight: "500",
    fontSize: 12,
    textAlign: "center",
    paddingTop: 4
  },
  icon: {
    width: "100%",
    height: 75,
    justifyContent: "center",
    padding: 0
  }
});
export default HistoryScreenTile;
