let strings = {
  "en-US": {
    dash: "DASHBOARD",
    hypo: "ACTIVITY",
    pod: "POD INFO"
  },
  "en-GB": {
    dash: "DASHBOARD",
    hypo: "ACTIVITY",
    pod: "POD INFO"
  },
  "de-DE": {
    dash: "DASHBOARD",
    hypo: "AKTIVITÄT",
    pod: "POD INFO"
  },
  "es-US": {
    dash: "PANEL DE CONTROL",
    hypo: "ACTIVIDAD",
    pod: "INFORMACIÓN DEL POD"
  },
  "nl-NL": {
    dash: "DASHBOARD",
    hypo: "ACTIVITEIT",
    pod: "POD-INFO"
  },
  "fr-FR": {
    dash: "TABLEAU DE BORD",
    hypo: "Activité",
    pod: "INFOS POD"
  },
};

export { strings };
