let strings = {
  "en-US": {
    signIn: "Sign In",
    enterId:
      "Enter your Omnipod ID and password to link this device to your Omnipod account.",
    noAccount: "Don't have an account? Go to: www.omnipod.com/setup",
    needHelp: "Need help?",
    omnipodID: "Omnipod ID",
    password: "Password"
  },
  "en-GB": {
    signIn: "Sign In",
    enterId:
      "Enter your Omnipod ID and password to link this device to your Omnipod account.",
    noAccount: "Don't have an account? Go to: www.omnipod.com/setup",
    needHelp: "Need help?",
    omnipodID: "Omnipod ID",
    password: "Password"
  },
  "es-US": {
    signIn: "Iniciar sesión",
    enterId:
      "Introduzca su ID y contraseña de \n Omnipod para asociar este dispositivo con su cuenta Omnipod.",
    noAccount: "¿No tiene una cuenta? Diríjase a: www.omnipod.com/setup",
    needHelp: "¿Necesita ayuda?",
    omnipodID: "ID de Omnipod",
    password: "Contraseña"
  },
  "de-DE": {
    signIn: "Anmelden",
    enterId:
      "Geben Sie Ihre Omnipod ID und Ihr Omnipod Passwort ein, um dieses Gerät mit Ihrem Omnipod Konto zu verknüpfen.",
    noAccount: "Haben Sie kein Konto? Gehen Sie zu: www.omnipod.com/setup",
    needHelp: "Benötigen Sie Hilfe?",
    omnipodID: "Omnipod ID",
    password: "Passwort"
  },
  "nl-NL": {
    signIn: "Aanmelden",
    enterId:
      "Voer uw Omnipod-ID en -wachtwoord in om dit apparaat te koppelen met uw Omnipod-account.",
    noAccount: "Hebt u geen account? Ga naar: www.omnipod.com/setup",
    needHelp: "Hulp nodig?",
    omnipodID: "Omnipod-ID",
    password: "Wachtwoord"
  },
  "fr-FR": {
    signIn: "Connexion",
    enterId:
      "Saisissez votre identifiant et votre mot de passe Omnipod pour relier cet appareil à votre compte Omnipod.",
    noAccount: "Vous n\'avez pas de compte? Allez à: www.omnipod.com/setup",
    needHelp: "Vous avez besoin d\'aide?",
    omnipodID: "ID Omnipod",
    password: "Mot de passe"
  },
};

export { strings };
