import { createSlice } from '@reduxjs/toolkit';
import { setOptions } from '../configurator/configuratorSlice';

export const flagNames = {
  SHOW_G7: 'showG7',
  SHOW_L2: 'showL2',
  SHOW_IOS: 'showIOS',
};

export const flagConditions = {
  [flagNames.SHOW_G7]: {
    country: ['United States'],
    device: ['Android/Controller'],
    language: ['en-US', 'es-US'],
    version: ['3.0'],
  },
  [flagNames.SHOW_L2]: {
    country: ['United Kingdom', 'Netherlands'],
    device: ['Android/Controller'],
    language: ['en-GB', 'nl-NL'],
    version: ['2.0'],
  },
  [flagNames.SHOW_IOS]: {
    country: ['United States'],
    device: ['iOS'],
    language: ['en-US'],
    version: ['1.0'],
  }
};

export function generateInitialState() {
  const values: { [key: string]: boolean } = {};
  Object.values(flagNames).forEach((name) => (values[name] = false));
  return values;
}

export const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState: generateInitialState(),
  reducers: {
    setFlag: (state, action) => {
      const { flagName, value } = action.payload;
      state[flagName] = value;
    },
    toggleFlag: (state, action) => {
      const flagName = action.payload;
      state[flagName] = !state[flagName];
    },
  },
  extraReducers: (builder) => {
    // We check for configurator option changes, and then update flags accordingly.
    builder.addCase(setOptions, (state, action) => {
      const { country, device, language, version } = action.payload;
      Object.keys(flagConditions).forEach((flagName) => {
        const currentFlag = flagConditions[flagName];
        state[flagName] = !!(
          currentFlag.country.includes(country) &&
          currentFlag.version.includes(version) &&
          currentFlag.device.includes(device) &&
          currentFlag.language.includes(language)
        );
      });
    });
  },
});

export const { setFlag, toggleFlag } = featureFlagsSlice.actions;
export default featureFlagsSlice.reducer;
