let strings = {
  "en-US": {
    graphKey: "Graph: U/hr",
    changeBasal: "Change Basal",
    byHowMuch: "By How Much",
    duration: "Duration",
    increase: "Increase",
    hr: "hr"
  },
  "en-GB": {
    graphKey: "Graph: U/hr",
    changeBasal: "Change Basal",
    byHowMuch: "By How Much",
    duration: "Duration",
    increase: "Increase",
    hr: "hr"
  },
  "de-DE": {
    graphKey: "Grafik: E/Std.",
    changeBasal: "Basalrate ändern",
    byHowMuch: "Nach Menge",
    duration: "Dauer",
    increase: "Erhöhung",
    hr: "Std."
  },
  "es-US": {
    graphKey: "Gráfico: U/h",
    changeBasal: "Cambiar basal",
    byHowMuch: "Por cuánto",
    duration: "Duración",
    increase: "Aumentar",
    hr: "h"
  },
  "fr-FR": {
    graphKey: "Graphique : U/h",
    changeBasal: "Modifier débit basal",
    byHowMuch: "De combien",
    duration: "Durée",
    increase: "Augmentation",
    hr: "h"
  },
  "nl-NL": {
    graphKey: "Grafiek: E/u.",
    changeBasal: "Basaal wijzigen",
    byHowMuch: "Met hoeveel",
    duration: "Duur",
    increase: "Verhogen",
    hr: "u."
  }
};

export { strings };
