let strings = {
  "en-US": {
    review:
      "Review the Target Glucose and Correct Above values entered for each time segment.",
    segment: "Segment",
    targetBG: "Target Glucose:",
    correctAbove: "Correct Above:",
    cancel: "Cancel",
    save: "Save"
  },
  "en-GB": {
    review:
      "Review the Target Glucose and Correct Above values entered for each time segment.",
    segment: "Segment",
    targetBG: "Target Glucose:",
    correctAbove: "Correct Above:",
    cancel: "Cancel",
    save: "Save"
  },
  "de-DE": {
    review:
      "Prüfen Sie die Werte für „Glukose-Zielwert“ und „Korrigieren über“, die für jedes Zeitsegment eingegeben wurden.",
    segment: "Segment",
    targetBG: "Glukose-Zielwert:",
    correctAbove: "Korrigieren über:",
    cancel: "Abbr.",
    save: "Speichern"
  },
  "es-US": {
    review:
      "Revise los valores de glucosa objetivo y corregir sobre que haya introducido para cada segmento temporal.",
    segment: "Segmento",
    targetBG: "Glucosa Objetivo:",
    correctAbove: "Corregir Sobre:",
    cancel: "Cancelar",
    save: "Guardar"
  },
  "fr-FR": {
    review:
      "Vérifiez les valeurs de Glucose cible et Corriger si supérieur à saisies pour chaque segment temporel.",
    segment: "Segment",
    targetBG: "Glucose cible :",
    correctAbove: "Corriger si supérieur à :",
    cancel: "Annuler",
    save: "Enregistrer"
  },
  "nl-NL": {
    review:
      "Controleer de voor elk tijdsegment ingevoerde waarden bij Streefwaarde voor glucose en Corrigeren boven.",
    segment: "Segment",
    targetBG: "Streefwaarde glucose:\n",
    correctAbove: "Corrigeren boven:",
    cancel: "Annuleren",
    save: "Opslaan"
  }
};

export { strings };
