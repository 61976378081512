import React from "react";

interface Props {
  children: JSX.Element;
}
const SimulatorContainer: React.FC<Props> = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center"
      }}>
      <div>
        {children}
      </div>
    </div>
  );
};

export default SimulatorContainer;
