let strings = {
  'en-US': {
    title: 'Add a New Sensor',
    tap: 'Tap ',
    takePhoto: 'Take Photo',
    toScan: " to scan your Sensor's",
    qrCode: ' QR code.',
    manualEnter:
      'Or manually enter the 4-digit pairing code and 12-digit serial number found on your Sensor applicator.',
    enterManually: 'Enter Code Manually',
    takePhotoButton: 'Take Photo',
  },
  'es-US': {
    title: 'Agregar un Nuevo Sensor',
    tap: 'Toque ',
    takePhoto: 'Tomar Foto',
    toScan: ' para escanear el código',
    qrCode: ' QR de su Sensor.',
    manualEnter:
      'O ingrese manualmente el código de emparejamiento de 4 dígitos y el número de serie de 12 dígitos que se encuentra en el aplicador de su Sensor.',
    enterManually: 'Ingresar Código Manualmente',
    takePhotoButton: 'Tomar Foto',
  },
};

export { strings };