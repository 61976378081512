import { defineMessages } from 'react-intl';

export const historyEventBasalProgramMessages = defineMessages({
  title: {
    id: 'ios.history-event-basal-program.title',
    defaultMessage: 'Basal Program',
  },
  startedStatus: {
    id: 'ios.history-event-basal-program.started-status',
    defaultMessage: 'Started',
  },
  programName: {
    id: 'ios.history-event-basal-program.program-name',
    defaultMessage: 'My Standard Basal',
  },
});
