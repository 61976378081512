import React from "react";
import { Text, View } from "react-native-web";
import { LangState } from "../../../../../web/store/reducers/language";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../web/store/store";
import { isEqual } from "lodash";

const Timeline: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  return (
    <View
      style={{
        paddingBottom: 6,
        flex: 0.75,
        flexDirection: "row"
      }}>
      <View
        style={{
          flex: 1,
          flexDirection: "row"
        }}>
        <Text style={{ paddingTop: 4 }}>
          {langState.is24Hour ? "0:00" : "12am"}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between"
        }}>
        <Text style={{ marginLeft: -12, paddingTop: 4 }}>
          {langState.is24Hour ? "08:00" : "8am"}
        </Text>
        <Text style={{ marginRight: -16, paddingTop: 4 }}>
          {langState.is24Hour ? "16:00" : "4pm"}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignContent: "flex-end",
          justifyContent: "flex-end"
        }}>
        <Text style={{ paddingTop: 4 }}>
          {langState.is24Hour ? "0:00" : "12am"}
        </Text>
      </View>
    </View>
  );
};

export default Timeline;
