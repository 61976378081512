import React, { useState } from 'react';
import classNames from 'classnames';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import Manual3hr from 'assets/ios/graphs/manual-3hr.png';
import Manual6hr from 'assets/ios/graphs/manual-6hr.png';
import Manual12hr from 'assets/ios/graphs/manual-12hr.png';
import Manual24hr from 'assets/ios/graphs/manual-24hr.png';
import Automated3hr from 'assets/ios/graphs/automated-3hr.png';
import Automated6hr from 'assets/ios/graphs/automated-6hr.png';
import Automated12hr from 'assets/ios/graphs/automated-12hr.png';
import Automated24hr from 'assets/ios/graphs/automated-24hr.png';
import {
  selectCurrentOperationMode,
  selectCurrentOperationModeName,
} from 'web/store/reducers/user/user.helpers';
import { selectLanguageUnits } from 'web/store/reducers/language/language.helpers';
import { selectActiveSensor } from 'web/store/reducers/sensor/sensor.helpers';
import { ReactComponent as CgmArrowManual } from 'assets/ios/cgm-arrow.svg';
import { ReactComponent as CgmArrowAutomated } from 'assets/ios/cgm-arrow-automated.svg';
import { AppRoutesEnum } from 'web/routes/types';

interface GraphImagesObject {
  [operationMode: string]: {
    [timespan: number]: string;
  };
}

const graphImages: GraphImagesObject = {
  manual: {
    3: Manual3hr,
    6: Manual6hr,
    12: Manual12hr,
    24: Manual24hr,
  },
  automated: {
    3: Automated3hr,
    6: Automated6hr,
    12: Automated12hr,
    24: Automated24hr,
  },
};

export const GraphScreen: React.FC = () => {
  const operationMode = useSelector(selectCurrentOperationMode);
  const operationModeName = useSelector(selectCurrentOperationModeName);
  const units = useSelector(selectLanguageUnits);
  const activeSensor = useSelector(selectActiveSensor);
  const [activeTimeSpan, setActiveTimeSpan] = useState<3 | 6 | 12 | 24>(3);
  const { navigate } = useNavigation();

  return (
    <div
      className="w-full h-full bg-white font-ios"
      style={{ writingMode: 'vertical-rl' }}
    >
      <div className="pr-2 py-[50px] flex flex-row justify-between items-end">
        <div className="flex flex-row items-center w-[38px]">
          <div
            className={`text-modes-${operationMode} text-2xl font-bold text-center border-b-1 border-ios-baseIcon/20 pb-[32px] px-[5px]`}
          >
            {operationModeName}
          </div>

          <div className="border-b-1 border-ios-baseIcon/20 py-[23px]">
            <div className="text-2xl font-heavy flex flex-row items-center">
              121
              {operationMode === 'manual' ? (
                <CgmArrowManual className="w-[18px] h-[18px] mt-1 rotate-90" />
              ) : (
                <CgmArrowAutomated className="w-[18px] h-[18px] mt-1 rotate-90" />
              )}
            </div>
            <div className="text-[11px] font-semibold mr-[-6px]">{units}</div>
          </div>

          <div className="pt-3">
            <div className="text-2xl font-heavy flex flex-row items-end">
              0.5 <span className="text-[12px] leading-5 mt-0.5">U</span>
            </div>
            <div className="text-[11px] font-semibold mr-[-6px]">IOB</div>
          </div>
        </div>

        <div className="">
          <div className="text-[11px] font-semibold text-ios-secondaryGray/60 text-right">
            {activeSensor}
          </div>
          <div className="rounded-[8px] p-[2px] bg-ios-tertiary/[.12] flex flex-row items-center">
            <button
              className={classNames(
                'px-0.5',
                activeTimeSpan === 3 &&
                  'rounded-[7px] bg-white border-[0.5px] border-black/[.04] shadow-graphButton'
              )}
              onClick={() => setActiveTimeSpan(3)}
            >
              <span
                className={classNames(
                  'h-[80px] block text-center border-b-1 border-transparent text-[13px] leading-[20px]',
                  (activeTimeSpan === 12 || activeTimeSpan === 24) &&
                    'border-y-ios-secondaryGray/[.36]'
                )}
              >
                3 hr
              </span>
            </button>
            <button
              className={classNames(
                'px-0.5',
                activeTimeSpan === 6 &&
                  'rounded-[7px] bg-white border-[0.5px] border-black/[.04] shadow-graphButton'
              )}
              onClick={() => setActiveTimeSpan(6)}
            >
              <span
                className={classNames(
                  'h-[80px] block text-center border-b-1 border-transparent text-[13px] leading-[20px]',
                  (activeTimeSpan === 3 || activeTimeSpan === 24) &&
                    'border-b-ios-secondaryGray/[.36]'
                )}
              >
                6 hrs
              </span>
            </button>
            <button
              className={classNames(
                'px-0.5',
                activeTimeSpan === 12 &&
                  'rounded-[7px] bg-white border-[0.5px] border-black/[.04] shadow-graphButton'
              )}
              onClick={() => setActiveTimeSpan(12)}
            >
              <span
                className={classNames(
                  'h-[80px] block text-center border-b-1 border-transparent text-[13px] leading-[20px]',
                  (activeTimeSpan === 3 || activeTimeSpan === 6) &&
                    'border-b-ios-secondaryGray/[.36]'
                )}
              >
                12 hr
              </span>
            </button>
            <button
              className={classNames(
                'px-0.5',
                activeTimeSpan === 24 &&
                  'rounded-[7px] bg-white border-[0.5px] border-black/[.04] shadow-graphButton'
              )}
              onClick={() => setActiveTimeSpan(24)}
            >
              <span className="h-[80px] block text-center text-[13px] leading-[20px]">24 hr</span>
            </button>
          </div>
        </div>
      </div>
      <div
        className="w-[770px] h-[285px] absolute top-[266px] right-[-170px] rotate-90"
        onClick={() => navigate(AppRoutesEnum.dashboard)}
      >
        <img src={graphImages[operationMode][activeTimeSpan]} alt="Graph" />
      </div>
    </div>
  );
};
