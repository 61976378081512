import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsRow } from 'web/components/ios/SettingsRow';
import { BottomNav } from 'web/components/ios/BottomMenu/BottomNav';
import { messages } from '../SettingsReminders.messages';
import { PodCommunicationModal } from 'web/components/ios/modals/GlobalModals/GlobalModals';
import {
  setConfidenceRemindersValue,
  setProgramRemindersValue,
} from 'web/store/reducers/remindersSettings/remindersSettingsSlice';
import {
  selectIsConfidenceRemindersOn,
  selectIsProgramRemindersOn,
} from 'web/store/reducers/remindersSettings/remindersSettings.helpers';
import {
  setCurrentModal,
  showModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import { SettingsRemindersRoutesEnum } from '../types';

export const RemindersMain: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const isConfidenceRemindersOn = useSelector(selectIsConfidenceRemindersOn);
  const isProgramRemindersOn = useSelector(selectIsProgramRemindersOn);

  const handleSettingToggle = (settingName: string) => () => {
    if (settingName === 'confidence') {
      dispatch(
        setConfidenceRemindersValue({ value: !isConfidenceRemindersOn })
      );
    }

    if (settingName === 'program') {
      dispatch(setProgramRemindersValue({ value: !isProgramRemindersOn }));
    }

    dispatch(
      setCurrentModal({
        modal: (
          <PodCommunicationModal onTimeout={() => dispatch(hideModal())} />
        ),
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="bg-ios-gray-6 pt-2 h-full flex flex-col justify-between text-regular font-ios">
      <div>
        <div className="bg-white">
          <SettingsRow
            text={intl.formatMessage(messages.lowPodInsulinText)}
            subText={intl.formatMessage(messages.lowPodInsulinSubText)}
            showCaret
            onClick={() => navigate(SettingsRemindersRoutesEnum.LowPodInsulin)}
          />
          <SettingsRow
            text={intl.formatMessage(messages.podShutOffText)}
            subText={intl.formatMessage(messages.podShutOffSubText)}
            showStatus
            showCaret
            onClick={() => navigate(SettingsRemindersRoutesEnum.PodShutOff)}
          />
          <SettingsRow
            text={intl.formatMessage(messages.podExpirationText)}
            subText={intl.formatMessage(messages.podExpirationSubText)}
            showCaret
            onClick={() => navigate(SettingsRemindersRoutesEnum.PodExpiration)}
          />
          <SettingsRow
            text={intl.formatMessage(messages.confidenceRemindersText)}
            subText={intl.formatMessage(messages.confidenceRemindersSubText)}
            showToggle
            isToggled={isConfidenceRemindersOn}
            onToggle={handleSettingToggle('confidence')}
          />
        </div>

        <div className="text-modals pt-1 pb-[10px] px-2 text-ios-gray-9/60">
          <FormattedMessage {...messages.confidenceRemindersDisclaimer} />
        </div>

        <div className="bg-white">
          <SettingsRow
            text={intl.formatMessage(messages.programRemindersText)}
            subText={intl.formatMessage(messages.programRemindersSubText)}
            showToggle
            isToggled={isProgramRemindersOn}
            onToggle={handleSettingToggle('program')}
          />
        </div>

        <div className="text-modals pt-1 pb-[10px] px-2 text-ios-gray-9/60">
          <FormattedMessage {...messages.programRemindersDisclaimer} />
        </div>
      </div>

      <BottomNav />
    </div>
  );
};
