import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  toggleOn: {
    id: 'L2.settings.toggle.on',
    defaultMessage: 'On',
  },
  toggleOff: {
    id: 'L2.settings.toggle.off',
    defaultMessage: 'Off. Tap to turn On',
  },
  overrideVolume: {
    id: 'L2.settings.toggle.override-volume',
    defaultMessage: 'Override Volume Settings',
  },
  overrideVolumeDisclaimer: {
    id: 'L2.settings.toggle.override-volume-disclaimer',
    defaultMessage:
      'Turn ON if you want to always hear a sound when a notification appears on the lock screen even if your controller is muted.',
  },
  bottomNavRightAction: {
    id: 'L2.settings.bottom-nav.right-action',
    defaultMessage: 'Next',
  },
  bottomNavRightActionSave: {
    id: 'L2.settings.bottom-nav.right-action-save',
    defaultMessage: 'Save',
  }
});
