import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { TouchableOpacity } from "react-native";
import { StyleSheet, Text, View } from "react-native-web";
import { useSelector } from "react-redux";
import { getUnitByLocale } from "../../../../../../helpers/getUnitByLocale";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./CorrectionFactorSegmentEditScreenContent";

const CorrectionFactorSegmentEditScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const [glucose, setGlucose] = React.useState<string>();

  const lowerRange = getUnitByLocale(1, langState.units);
  const higherRange = `${getUnitByLocale(400, langState.units)} ${
    langState.units
  }`;
  const contentVariables = [lowerRange, higherRange];

  return (
    <View style={[styles.container]}>
      <View>
        <Text
          style={[
            theme.fonts.body2,
            {
              marginHorizontal: 16,
              marginVertical: 8,
              color: theme.colors.greenText
            }
          ]}>
          {languageContent.seg}
        </Text>
        <View style={[styles.break, { marginBottom: 16 }]} />
        <View style={[{ padding: 16 }]}>
          <View style={[{ flexDirection: "row" }]}>
            <Text style={[theme.fonts.h5, { flex: 1.1 }]}>
              {languageContent.start}
            </Text>
            <Text style={[theme.fonts.h5, { flex: 1 }]}>
              {languageContent.end}
            </Text>
          </View>
          <View
            style={[{ flexDirection: "row", width: "100%", height: "20%" }]}>
            <View
              style={[
                {
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flex: 1.1,
                  paddingVertical: 8
                }
              ]}>
              <Text
                style={[
                  {
                    fontSize: 30,
                    color: theme.colors.pureBlack
                  }
                ]}>
                12:00{" "}
                <Text style={[styles.periodText, { fontSize: 16 }]}>AM</Text>
              </Text>
              <View style={[{ alignSelf: "center" }]}>
                <Text style={[{ fontSize: 32 }]}>{" - "}</Text>
              </View>
            </View>
            <View style={[theme.layout.mockInput, { flex: 1 }]}>
              <Text style={[theme.layout.mockInputStyle]}>
                5:00 <Text style={[styles.periodText]}>AM</Text>
              </Text>
            </View>
          </View>
          <View style={[{ flexDirection: "row", marginTop: 8 }]}>
            <Text
              style={[
                theme.fonts.body2,
                { flex: 1.1, color: theme.colors.grayScale.gray400 }
              ]}>
              {languageContent.night}
            </Text>
            <Text
              style={[
                theme.fonts.body2,
                { flex: 1, color: theme.colors.grayScale.gray400 }
              ]}>
              {languageContent.night}
            </Text>
          </View>
          <View style={[{ marginTop: 24 }]}>
            <Text style={[theme.fonts.h5]}>{languageContent.oneUnit}</Text>
            <Text
              style={[
                theme.fonts.body2,
                { color: theme.colors.grayScale.gray400 }
              ]}>
              {replaceWithValues(languageContent.range, contentVariables)}
            </Text>
          </View>
          <TouchableOpacity
            style={[
              theme.layout.inputContainerStyle,
              {
                marginTop: 8,
                flexDirection: "row"
              }
            ]}
            onPress={() => {
              setGlucose("55");
            }}>
            <Text style={[theme.layout.inputStyle]}>
              {glucose
                ? getUnitByLocale(Number(glucose), langState.units)
                : null}
            </Text>
            <Text style={[theme.layout.adornment]}>{langState.units}</Text>
          </TouchableOpacity>
        </View>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.next}
        rightAction={() => navigate("BolusSettingsCorrectionFactor")}
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "white"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  periodText: {
    color: theme.colors.grayScale.gray400,
    fontSize: 18,
    marginLeft: -2
  }
});

export default CorrectionFactorSegmentEditScreen;
