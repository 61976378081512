import { HistoryState } from "./index";
import { initialState } from "./reducer";

export const HISTORY_UPDATE = "history_update";
export const HISTORY_INIT = "history_init";

interface ActionUpdate {
  type: ActionType;
  payload: {
    history: Partial<HistoryState>;
  };
}

interface ActionReset {
  type: ActionType;
  payload: {
    history: HistoryState;
  };
}

type ActionType = typeof HISTORY_UPDATE | typeof HISTORY_INIT;

export const historyUpdate = (historyData: Partial<HistoryState>): Action => {
  return {
    type: HISTORY_UPDATE,
    payload: {
      history: historyData
    }
  };
};

export const historyInit = (): Action => {
  return {
    type: HISTORY_INIT,
    payload: {
      history: initialState
    }
  };
};

export type Action = ActionUpdate | ActionReset;
