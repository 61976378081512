import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { hideModal } from 'web/store/reducers/modals/modalsSlice';
import { ReactComponent as InsertingCannulaSVG } from 'assets/ios/pod-cannula.svg';
import { ReactComponent as CheckPodSVG } from 'assets/ios/check-pod.svg';
import { Spinner } from 'web/components/ios/Spinner';
import { Modal } from '../../Modal';
import {
  PodCommunicationModal,
  DeactivatePodModal,
  ModalWithTimeoutProps,
} from '../GlobalModals/GlobalModals';
import {
  checkCannulaMessages,
  checkPodMessages,
  podPrimingMessages,
  podSecureConfirmationMessages,
  cannulaInsertionMessages,
  cancelPodSetupMessages,
  deactivatePodMessages,
  deactivateYourPodMessages,
} from './ChangePodModals.messages';
import { ConfirmationModalProps } from '../types';

const MODAL_TEXT_CHANGE_TIME = 2000;
const MODAL_TIMEOUT = 2000;

export const CheckCannulaModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  return (
    <Modal
      title={intl.formatMessage(checkCannulaMessages.title)}
      rightButtonText={intl.formatMessage(checkCannulaMessages.button)}
      rightButtonOnClick={() => dispatch(hideModal())}
    >
      <FormattedMessage {...checkCannulaMessages.text} />
    </Modal>
  );
};

export const CheckPodModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  return (
    <Modal
      title={intl.formatMessage(checkPodMessages.title)}
      rightButtonText={intl.formatMessage(checkPodMessages.button)}
      rightButtonOnClick={() => dispatch(hideModal())}
    >
      <FormattedMessage {...checkPodMessages.text} />
    </Modal>
  );
};

interface PodPrimingModalProps {
  onClose?: () => void;
}

export const PodPrimingModal: React.FC<PodPrimingModalProps> = ({
  onClose,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showTimedMessage, setShowTimedMessage] = useState<boolean>(false);

  const handleCommunicationTimeout = () => {
    setShowTimedMessage(true);
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
      dispatch(hideModal());
    }, MODAL_TIMEOUT);
  };

  if (showTimedMessage) {
    return (
      <Modal title={intl.formatMessage(podPrimingMessages.title)}>
        <Spinner />
        <FormattedMessage {...podPrimingMessages.text} />
      </Modal>
    );
  }

  return <PodCommunicationModal onTimeout={handleCommunicationTimeout} />;
};

export const PodSecureConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onConfirm,
  onReject,
}) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage(podSecureConfirmationMessages.title)}
      rightButtonOnClick={onConfirm}
      rightButtonText={intl.formatMessage(
        podSecureConfirmationMessages.confirmButton
      )}
      leftButtonOnClick={onReject}
      leftButtonText={intl.formatMessage(
        podSecureConfirmationMessages.rejectButton
      )}
    >
      <FormattedMessage {...podSecureConfirmationMessages.text} />
    </Modal>
  );
};

export const CannulaInsertedConfirmationModal: React.FC<
  ConfirmationModalProps
> = ({ onConfirm, onReject }) => {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage(cannulaInsertionMessages.confirmTitle)}
      rightButtonOnClick={onConfirm}
      rightButtonText={intl.formatMessage(
        cannulaInsertionMessages.confirmButton
      )}
      leftButtonOnClick={onReject}
      leftButtonText={intl.formatMessage(cannulaInsertionMessages.rejectButton)}
    >
      <FormattedMessage {...cannulaInsertionMessages.confirmText} />
      <div className="mt-3 mb-2">
        <CheckPodSVG />
      </div>
    </Modal>
  );
};

export const CannulaInsertionModal: React.FC<ModalWithTimeoutProps> = ({
  onTimeout,
}) => {
  const intl = useIntl();
  const [showTimedMessage, setShowTimedMessage] = useState<boolean>(false);
  const message = intl.formatMessage(
    showTimedMessage
      ? cannulaInsertionMessages.textSecond
      : cannulaInsertionMessages.textFirst
  );

  useEffect(() => {
    setTimeout(() => {
      setShowTimedMessage(true);
      setTimeout(() => {
        if (onTimeout) {
          onTimeout();
        }
      }, MODAL_TEXT_CHANGE_TIME);
    }, MODAL_TEXT_CHANGE_TIME);
  }, []);

  return (
    <Modal title={intl.formatMessage(cannulaInsertionMessages.title)}>
      {message}
      <div className="mt-3 mb-2">
        <InsertingCannulaSVG />
      </div>
    </Modal>
  );
};

export const CancelPodSetupModal: React.FC<ConfirmationModalProps> = ({
  onReject,
  onConfirm,
}) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage(cancelPodSetupMessages.title)}
      rightButtonOnClick={onConfirm}
      rightButtonText={intl.formatMessage(cancelPodSetupMessages.confirmButton)}
      leftButtonOnClick={onReject}
      leftButtonText={intl.formatMessage(cancelPodSetupMessages.rejectButton)}
    >
      <FormattedMessage {...cancelPodSetupMessages.text} />
    </Modal>
  );
};

interface DeactivatePodConfirmModalProps {
  onTimeout?: () => void;
  onReject: () => void;
}

export const DeactivatePodConfirmModal: React.FC<
  DeactivatePodConfirmModalProps
> = ({ onReject, onTimeout }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showDeactivating, setShowDeactivating] = useState<boolean>(false);
  const handleTimeout = () => {
    if (onTimeout) {
      onTimeout();
    }
    dispatch(hideModal());
  };

  if (showDeactivating) {
    return <DeactivatePodModal onTimeout={handleTimeout} />;
  }

  return (
    <Modal
      title={intl.formatMessage(deactivatePodMessages.title)}
      rightButtonOnClick={onReject}
      rightButtonText={intl.formatMessage(deactivatePodMessages.rejectButton)}
      leftButtonOnClick={() => setShowDeactivating(true)}
      leftButtonText={intl.formatMessage(deactivatePodMessages.confirmButton)}
      rowControls
    >
      <FormattedMessage {...deactivatePodMessages.text} />
    </Modal>
  );
};

export const DeactivatePodConfirmationModal: React.FC<
  ConfirmationModalProps
> = ({ onConfirm, onReject }) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage(deactivateYourPodMessages.title)}
      rightButtonOnClick={onReject}
      rightButtonText={intl.formatMessage(
        deactivateYourPodMessages.cancelButton
      )}
      leftButtonOnClick={onConfirm}
      leftButtonText={intl.formatMessage(
        deactivateYourPodMessages.deactivatePodButton
      )}
      rowControls
    >
      <FormattedMessage {...deactivateYourPodMessages.text} />
    </Modal>
  );
};
