import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useIntl } from 'react-intl';
import { TxIDSetupStart } from './views/TxIDSetupStart';
import { TxIDEnterSerialNumber } from './views/TxIDEnterSerialNumber';
import { TopBar } from 'web/components/ios/TopBar';
import { messages } from './TxID.messages';
import { TxIDRouteNames } from './types';

const Stack = createStackNavigator();

export const TxIDStack: React.FC = () => {
  const intl = useIntl();

  return (
    <Stack.Navigator
      initialRouteName={TxIDRouteNames.setupStart}
      screenOptions={{
        headerStyle: {
          height: 91,
        },
        header: (props) => (
          <TopBar {...props} showRightButton />
        ),
      }}
    >
      <Stack.Screen
        name={TxIDRouteNames.setupStart}
        component={TxIDSetupStart}
        options={{
          headerShown: false,
        }}
      />

      <Stack.Screen
        name={TxIDRouteNames.enterSerialNumber}
        component={TxIDEnterSerialNumber}
        options={{
          title: intl.formatMessage(messages.topBarTitle),
        }}
        initialParams={{
          topBarRightButtonEnabled: false,
        }}
      />
    </Stack.Navigator>
  );
};
