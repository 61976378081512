import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const InsulinIcon: React.FC<IconProps> = ({
  color,
  width = '33',
  height = '33',
}) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons/x/Insulin/Manual/Light">
        <path
          className={classes}
          id="Combined Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4993 3.66667C17.0452 3.66667 17.4952 4.07762 17.5567 4.60704L17.5638 4.73119V9.28732H29.389C29.9349 9.28732 30.3849 9.69826 30.4464 10.2277L30.4535 10.3518V24.7867C30.4535 25.3746 29.9769 25.8512 29.389 25.8512C28.8431 25.8512 28.3932 25.4402 28.3317 24.9108L28.3245 24.7867L28.3237 11.4153L17.5638 11.4163V24.7867C17.5638 25.3746 17.0872 25.8512 16.4993 25.8512C15.9534 25.8512 15.5034 25.4402 15.4419 24.9108L15.4348 24.7867L15.4346 5.7957H4.6755V24.7867C4.6755 25.3326 4.26455 25.7825 3.73512 25.844L3.61098 25.8512C3.06506 25.8512 2.61512 25.4402 2.55363 24.9108L2.54646 24.7867V4.73119C2.54646 4.18527 2.95741 3.73533 3.48683 3.67383L3.61098 3.66667H16.4993ZM7.45161 7.90572H12.7742C13.0682 7.90572 13.3065 8.14402 13.3065 8.43797V25.4702C13.3065 25.7642 13.0682 26.0025 12.7742 26.0025H7.45161C7.15765 26.0025 6.91935 25.7642 6.91935 25.4702V8.43797C6.91935 8.14402 7.15765 7.90572 7.45161 7.90572ZM25.5484 13.2283H20.2258C19.9318 13.2283 19.6935 13.4666 19.6935 13.7606V25.4702C19.6935 25.7642 19.9318 26.0025 20.2258 26.0025H25.5484C25.8423 26.0025 26.0806 25.7642 26.0806 25.4702V13.7606C26.0806 13.4666 25.8423 13.2283 25.5484 13.2283ZM33 29.7283C33 29.1404 32.5234 28.6638 31.9355 28.6638H1.06452L0.940371 28.6709C0.410946 28.7324 0 29.1824 0 29.7283C0 30.3162 0.4766 30.7928 1.06452 30.7928H31.9355L32.0596 30.7857C32.5891 30.7242 33 30.2742 33 29.7283Z"
        />
      </g>
    </svg>
  );
};
