import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { getUnitByLocale } from "../../../../../../helpers/getUnitByLocale";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import {
  bolusActions,
  BolusState
} from "../../../../../../../../web/store/reducers/bolus";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import fonts from "../../../../../../theme/fonts";
import { SettingsSection } from "../../../../../organisms";
import { strings } from "./BolusSettingsScreenContent";

const BolusSettingsScreen: React.FC = () => {
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );

  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [reverseCorrectionToggle, setReverseCorrectionToggle] =
    React.useState<boolean>(true);

  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const targetGlucoseLowerRange = getUnitByLocale(110, langState.units);
  const targetGlucoseHigherRange = `${getUnitByLocale(150, langState.units)} ${
    langState.units
  }`;
  const correctAboveLowerRange = getUnitByLocale(120, langState.units);
  const correctAboveHigherRange = `${getUnitByLocale(170, langState.units)} ${
    langState.units
  }`;
  const correctionFactorLowerRange = getUnitByLocale(40, langState.units);
  const correctionFactorHigherRange = `${getUnitByLocale(
    55,
    langState.units
  )} ${langState.units}`;
  const contentVariables = [
    targetGlucoseLowerRange,
    targetGlucoseHigherRange,
    correctAboveLowerRange,
    correctAboveHigherRange,
    correctionFactorLowerRange,
    correctionFactorHigherRange
  ];

  const changeBolus = () => {
    try {
      dispatch(
        bolusActions.bolusUpdate(
          {
            extendedBolus: bolusState.extendedBolus ? false : true
          },
          bolusState
        )
      );
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <ScrollView>
      <View>
        <View style={[styles.card]}>
          <SettingsSection
            settingTitle={languageContent.bolusSettingTitle}
            subtitleText={languageContent.bolusSettingSubtitle}
            subtitleTextOnPressHandler={() =>
              navigate("MaxBolusSettingsScreen")
            }
          />

          <SettingsSection
            settingTitle={languageContent.extendedSettingTitle}
            hasCustomToggleSwitch
            onToggleChange={changeBolus}
            startToggleTrue={bolusState.extendedBolus}
            settingValueSubTextElement={
              bolusState.extendedBolus ? (
                <>
                  <Text style={[theme.fonts.body3, styles.settingValue]}>
                    {languageContent.on}
                  </Text>
                  <Text style={[theme.fonts.body3, styles.activeValue]}>
                    {languageContent.extendedSettingBody2}
                  </Text>
                </>
              ) : (
                <Text style={[theme.fonts.body3, styles.disabledValue]}>
                  {languageContent.extendedSettingBody3}
                </Text>
              )
            }
          />
        </View>

        <View style={[styles.card]}>
          <SettingsSection
            header={languageContent.calcSettingHeader}
            headerStyle={{
              ...fonts.body1,
              fontWeight: "700",
              padding: 0,
              marginBottom: 8
            }}
            settingTitle={languageContent.calcSettingTitle}
            subtitleText={replaceWithValues(
              languageContent.calcSettingTextTarget,
              contentVariables
            )}
            subtitleTextOnPressHandler={() =>
              navigate("BolusSettingsCorrectAbove")
            }
            settingValueSubTextElement={
              <Text
                style={[theme.fonts.body3, styles.settingValue]}
                onPress={() => navigate("BolusSettingsCorrectAbove")}>
                {replaceWithValues(
                  languageContent.calcSettingSubTextTarget,
                  contentVariables
                )}
              </Text>
            }
          />
          <SettingsSection
            settingTitle={languageContent.minBgSettingTitle}
            subtitleText={`${getUnitByLocale(70, langState.units)} ${
              langState.units
            }`}
            subtitleTextOnPressHandler={() =>
              navigate("BolusSettingsMinBGForCalc")
            }
          />
          <SettingsSection
            settingTitle={languageContent.iCSettingTitle}
            subtitleText={replaceWithValues(
              languageContent.betweenCarbs,
              contentVariables
            )}
            subtitleTextOnPressHandler={() =>
              navigate("BolusSettingsInsulinToCarb")
            }
          />
          <SettingsSection
            settingTitle={languageContent.correctionSettingTitle}
            subtitleText={replaceWithValues(
              languageContent.between,
              contentVariables
            )}
            subtitleTextOnPressHandler={() =>
              navigate("BolusSettingsCorrectionFactor")
            }
          />

          <SettingsSection
            settingTitle={languageContent.reverseSettingTitle}
            hasCustomToggleSwitch
            onToggleChange={() =>
              setReverseCorrectionToggle(!reverseCorrectionToggle)
            }
            startToggleTrue={reverseCorrectionToggle}
            settingValueSubTextElement={
              reverseCorrectionToggle ? (
                <>
                  <Text style={[theme.fonts.body3, styles.settingValue]}>
                    {languageContent.on}
                  </Text>
                  <Text
                    style={[
                      theme.fonts.body3,
                      styles.activeValue,
                      { marginTop: 10 }
                    ]}>
                    {languageContent.reverseSettingSubText2}
                  </Text>
                </>
              ) : (
                <Text style={[theme.fonts.body3, styles.disabledValue]}>
                  {languageContent.reverseSettingSubText3}
                </Text>
              )
            }
          />
          <SettingsSection
            settingTitle={languageContent.durationSettingTitle}
            subtitleText={languageContent.durationSettingSubText}
            subtitleTextOnPressHandler={() =>
              navigate("BolusSettingsInsulinDuration")
            }
          />
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  section: {
    padding: 16
  },
  card: {
    width: "100%",
    backgroundColor: theme.colors.white,
    shadowColor: "#999",
    shadowOpacity: 0.3,
    shadowRadius: 6,
    shadowOffset: {
      height: 1,
      width: 0
    },
    elevation: 6,
    marginBottom: 8,
    borderBottomWidth: 1,
    borderColor: theme.colors.textInputBorder
  },
  settingValue: {
    color: theme.colors.lightBlue
  },
  activeValue: {
    color: theme.colors.text.dark
  },
  disabledValue: {
    color: theme.colors.text.cancel
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  sectionRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

export default BolusSettingsScreen;
