import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  applySensor: {
    id: 'L2.initial-setup.apply-sensor',
    defaultMessage: 'Apply Sensor',
  },
  applyingSensor: {
    id: 'L2.initial-setup.applying-sensor',
    defaultMessage: 'Applying a Sensor',
  },
  scanSensor: {
    id: 'L2.initial-setup.scan-sensor',
    defaultMessage: 'Scan Sensor',
  },
  scanningSensor: {
    id: 'L2.initial-setup.scanning-sensor',
    defaultMessage: 'Scanning a Sensor',
  },
  transmitterOutro: {
    id: 'L2.initial-setup.transmitter-outro',
    defaultMessage: 'Transmitter',
  }
});
