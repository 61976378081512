import {StyleProp, ViewStyle} from 'react-native';

interface NavTheme {
  header: StyleProp<ViewStyle>;
}

const navTheme: NavTheme = {
  header: {
    borderBottomWidth: 0,
    shadowOpacity: 0,
  },
};

export default navTheme;
