import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  manageSensor: {
    id: 'L2.manage-sensor.title',
    defaultMessage: 'Manage Sensor'
  },
  sensorSettings: {
    id: 'L2.sensor-settings.title',
    defaultMessage: 'Sensor Settings'
  },
  lowGlucose: {
    id: 'L2.sensor-settings.low-glucose',
    defaultMessage: 'Low Glucose'
  },
  highGlucose: {
    id: 'L2.sensor-settings.high-glucose',
    defaultMessage: 'High Glucose'
  },
  missingSensorValues: {
    id: 'L2.sensor-settings.missing-sensor-values',
    defaultMessage: 'Missing Sensor Values'
  },
});