let strings = {
  "en-US": {
    selectLanguage: "Select your country",
    continue: "Continue",
    confirm: "Confirm",
    back: "back",
    goback: "Go Back",
    ok: "ok",
    confirmCountryTitle: "Confirm Country",
    selectedCountry: "Selected Country:",
    confirmSelection: "Make sure your selection is correct.",
    resetting:
      "Resetting the Omnipod\u00A05 App is the only way to change the country and will cause data loss.",
    germanModalUnitCheckTitle: "Glucose Readings will display as %1",
    germanModalUnitCheck:
      "Your Omnipod\u00A05 App is set to display glucose readings as %2.",
    customerCareModalHeader: "Contact Customer Care",
    customerCareModalHeaderPurple: "Disabled for Simulator",
    customerCareModal:
      "If you think your Omnipod\u00A05 App should display %3, please Contact Customer Care using the information given by your provider.",
    dontCallCustomerCare:
      "This is a simulation of a message which appears in the Omnipod 5 App. Please do not contact customer care."
  },
  "en-GB": {
    selectLanguage: "Select Your Country",
    continue: "Continue",
    confirm: "Confirm",
    back: "Back",
    goback: "Go Back",
    ok: "ok",
    confirmCountryTitle: "Confirm Country",
    selectedCountry: "Selected Country:",
    confirmSelection: "Make sure your selection is correct.",
    resetting:
      "Resetting the Omnipod\u00A05 App is the only way to change the country and will cause data loss.",
    germanModalUnitCheckTitle: "Glucose readings will display as %1",
    germanModalUnitCheck:
      "Your Omnipod\u00A05 app is set to display glucose readings as %2.",
    customerCareModalHeader: "Contact Customer Care",
    customerCareModalHeaderPurple: "Disabled for Simulator",
    customerCareModal:
      "If you think your Omnipod\u00A05 App should display %3, please Contact Customer Care using the information given by your provider.",
    dontCallCustomerCare:
      "This is a simulation of a message which appears in the Omnipod 5 App. Please do not contact customer care."
  },
  "de-DE": {
    selectLanguage: "Wählen Sie Ihr Land aus",
    continue: "Weiter",
    confirm: "Bestätigen",
    back: "Rücken",
    goback: "Zurück",
    ok: "OK",
    confirmCountryTitle: "Land bestätigen",
    selectedCountry: "Ausgewähltes Land:",
    confirmSelection: "Überprüfen Sie, ob Ihre Auswahl korrekt ist.",
    resetting:
      "Das Zurücksetzen der Omnipod 5-App ist die einzige Möglichkeit, das Land zu ändern, und führt zu Datenverlust.",
    germanModalUnitCheckTitle: "Blutzuckermesswerte werden in %1 angezeigt",
    germanModalUnitCheck:
      "Ihre Omnipod 5-App ist so eingestellt, dass Blutzuckermesswerte als %2 angezeigt werden.",
    customerCareModalHeader: "Kundenservice kontaktieren",
    customerCareModalHeaderPurple: "Für Simulator deaktiviert",
    customerCareModal:
      "Wenn Sie meinen, dass Ihre Omnipod 5-App %3 anzeigen sollte, wenden Sie sich bitte unter Verwendung der von Ihrem Anbieter bereitgestellten Informationen an unseren Kundendienst.",
    dontCallCustomerCare:
      "Dies ist eine Simulation einer Meldung, die in der Omnipod 5-App angezeigt wird. Bitte wenden Sie sich nicht an den Kundendienst."
  },
  "es-US": {
    selectLanguage: "Seleccione su país",
    continue: "Continuar",
    confirm: "Confirmar",
    back: "atrás",
    goback: "Volver atrás",
    ok: "aceptar",
    confirmCountryTitle: "Confirmar país",
    selectedCountry: "País seleccionado:",
    confirmSelection: "Asegúrese de que su selección es correcta.",
    resetting:
      "El reinicio de la aplicación del Omnipod 5 es la única forma de cambiar el país y causará la pérdida de datos.",
    germanModalUnitCheckTitle: "Las lecturas de glucosa se mostrarán como %1",
    germanModalUnitCheck:
      "Su aplicación del Omnipod 5 está ajustada para mostrar las lecturas de glucosa como %2.",
    customerCareModalHeader: "Comuníquese con el equipo de Atención al Cliente",
    customerCareModalHeaderPurple: "Inhabilitado para el simulador",
    customerCareModal:
      "Si cree que su aplicación del Omnipod 5 debe mostrar %3, comuníquese con el equipo de Atención al Cliente usando la información que le proporciona su proveedor.",
    dontCallCustomerCare:
      "Esta es una simulación de un mensaje que aparece en la aplicación del Omnipod 5. No se comunique con el equipo de Atención al Cliente."
  },
  "fr-FR": {
    selectLanguage: "Sélectionner votre pays",
    continue: "Continuer",
    confirm: "Confirmer",
    back: "Retour",
    goback: "Retour",
    ok: "ok",
    confirmCountryTitle: "Confirmer le pays",
    selectedCountry: "Sélectionner un pays :",
    confirmSelection: "Assurez-vous que votre sélection est correcte.",
    resetting:
      "La réinitialisation de l'application Omnipod 5 est le seul moyen de changer de pays et cela entraînera une perte de données.",
    germanModalUnitCheckTitle: "Les relevés de glycémie s'afficheront comme %1",
    germanModalUnitCheck:
      "Votre application Omnipod 5 est configurée pour afficher les mesures du glucose comme %2.",
    customerCareModalHeader: "Contactez le Service clients",
    customerCareModalHeaderPurple: "Désactivé pour le simulateur",
    customerCareModal:
      "Si vous pensez que votre application Omnipod 5 devrait afficher %3, veuillez contacter le Service clients en utilisant les informations données par votre fournisseur.",
    dontCallCustomerCare:
      "Ceci est une simulation de message qui s'affiche sur l'application Omnipod 5. Ne contactez pas le Service clients"
  },
  "nl-NL": {
    selectLanguage: "Selecteer uw land",
    continue: "Doorgaan",
    confirm: "Bevestigen",
    back: "Terug",
    goback: "Terugkeren",
    ok: "OK",
    confirmCountryTitle: "Land bevestigen",
    selectedCountry: "Selecteer land:",
    confirmSelection: "Zorg ervoor dat uw correctie juist is.",
    resetting:
      "De enige manier om een ander land in te stellen is door de Omnipod 5-app te resetten. Daarbij gaan er gegevens verloren.",
    germanModalUnitCheckTitle:
      "De gemeten waarden voor glucose worden weergegeven als %1",
    germanModalUnitCheck:
      "Uw Omnipod 5-app is zo ingesteld dat de gemeten waarden voor glucose worden weergegeven als %2.",
    customerCareModalHeader: "Neem contact op met de Cliëntenzorg",
    customerCareModalHeaderPurple: "Uitgeschakeld voor simulator",
    customerCareModal:
      "Als u denkt dat uw Omnipod 5-app %3 moet weergeven, neem dan contact op met de Cliëntenzorg met behulp van de informatie die uw provider heeft gegeven.",
    dontCallCustomerCare:
      "Dit is een simulatie van een bericht dat verschijnt in de Omnipod 5-app. Neem geen contact op met de Cliëntenzorg."
  }
};

export { strings };
