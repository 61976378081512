import React from 'react';
import { useIntl } from 'react-intl';
import { createStackNavigator } from '@react-navigation/stack';
import { TopBar } from 'web/components/ios/TopBar';
import { BolusSettingsRoutesEnum } from '../../types';
import { insulinToCarbMessages } from '../../BolusSettings.messages';
import { InsulinToCarbMain } from './views/InsulinToCarbMain';
import { InsulinToCarbSegmentScreen } from './views/InsulinToCarbSegmentScreen';

const Stack = createStackNavigator();

export const InsulinToCarbStack: React.FC = () => {
  const intl = useIntl();
  return (
    <Stack.Navigator
      initialRouteName={BolusSettingsRoutesEnum.InsulinToCarb}
      screenOptions={{
        header: (props) => <TopBar {...props} />,
      }}
    >
      <Stack.Screen
        name={BolusSettingsRoutesEnum.InsulinToCarb}
        options={{
          title: intl.formatMessage(insulinToCarbMessages.title),
        }}
        component={InsulinToCarbMain}
      />
      <Stack.Screen
        name={BolusSettingsRoutesEnum.InsulinToCarbSegment}
        options={{
          title: intl.formatMessage(insulinToCarbMessages.title),
          header: (props) => <TopBar {...props} backAndCancelMode />,
        }}
        component={InsulinToCarbSegmentScreen}
      />
    </Stack.Navigator>
  );
};
