import React from "react";
import { StyleSheet, Text, View } from "react-native-web";
import theme from "../../../theme";

interface Props {
  title: string;
  description: string;
  status: string;
}

const RemindersItem: React.FC<Props> = ({ title, description, status }) => {
  return (
    <View>
      <View style={[styles.sectionSpace]}>
        <Text
          style={{
            flex: 1,
            ...theme.fonts.body2,
            color: theme.colors.text.dark
          }}>
          {title}
        </Text>
        <Text style={[styles.status]}>{status}</Text>
      </View>
      <Text style={[styles.desc, { color: theme.colors.grayScale.gray400 }]}>
        {description}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  status: {
    flex: 1,
    textAlign: "right",
    ...theme.fonts.text600,
    fontWeight: "400",
    color: theme.colors.text.grayText
  },
  desc: {
    ...theme.fonts.text600,
    fontWeight: "400",
    paddingHorizontal: 16
  },
  sectionSpace: { flexDirection: "row", paddingHorizontal: 16, paddingTop: 12 }
});

export default RemindersItem;
