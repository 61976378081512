import { isEqual } from "lodash";
import React from "react";
import { Text, View } from "react-native-web";
import { useSelector } from "react-redux";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { UserState } from "../../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { Header, Paragraph } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./targetBGFTSScreenContent";

const TargetBGFTSScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  const { languageContent } = useLanguageContent(strings);

  const isExpert = userState.isExpert;

  return (
    <View style={{ width: "100%", backgroundColor: "white", flex: 1 }}>
      <Header
        label={languageContent.setTarget}
        styles={{ marginTop: 32, marginBottom: 12, paddingVertical: 0 }}
      />
      <View
        style={[
          theme.layout.wrapper,
          { paddingHorizontal: 16, width: "100%", marginBottom: 48 }
        ]}>
        <Paragraph
          label={languageContent.theseValue}
          variant="body2"
          styles={{ marginBottom: 16 }}
        />

        <Text style={[theme.fonts.body2, { marginBottom: 16 }]}>
          {languageContent.inAutomatedMode}
          <Text style={[{ fontWeight: "700" }]}>
            {` ${languageContent.targetBG} `}
          </Text>
          {languageContent.value}
        </Text>
        <Text style={[theme.fonts.body2, { marginBottom: 16 }]}>
          {languageContent.inBothAutomated}
          <Text style={[{ fontWeight: "700" }]}>
            {` ${languageContent.targetBG} `}
          </Text>
          {`${languageContent.and} `}
          <Text style={[{ fontWeight: "700" }]}>
            {`${languageContent.correctAbove} `}
          </Text>
          {languageContent.valuesAre}
        </Text>
      </View>
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.cancel}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.next}
        rightActionNavigateTo={
          isExpert ? "BolusSegments" : "FirstSegmentTargetBG"
        }
        rightActionStyle={{
          color: theme.colors.purple
        }}
      />
    </View>
  );
};

export default TargetBGFTSScreen;
