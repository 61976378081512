import React, { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/core';
import { BottomInnerNav } from 'mobile/latest/components/molecules';
import CustomRadioButton from 'mobile/latest/components/molecules/customRadioButton';
import theme from 'mobile/latest/theme';
import { ScrollView, View, StyleSheet, Text } from 'react-native';
import { SensorType, getActiveSensorList, sensors } from 'web/data/sensors';
import { strings } from './SensorSelectionContent';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { RootState } from 'web/store/store';
import { SensorState, sensorActions } from 'web/store/reducers/sensor';
import { selectActiveFeatureFlags } from 'web/store/reducers/featureFlags/featureFlags.helpers';
import { Modal } from 'web/components/molecules';
import ConfirmSensorSwitch from '../ConfirmSensorSwitch/ConfirmSensorSwitch';
import CheckPodCompatibility from '../G7/CheckPodCompatibility/CheckPodCompatibility';

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
  },
  section: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grayScale.gray600,
    width: '100%',
  },
});
interface Props {
  isFTS?: boolean;
}

const SensorSelection: React.FC<Props> = ({ isFTS }) => {
  const sensorState: SensorState = useSelector(
    (state: RootState) => state.sensor,
    isEqual
  );

  const dispatch = useDispatch();
  const activeFlags = useSelector(selectActiveFeatureFlags);
  const sensorList = getActiveSensorList(activeFlags);
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);
  const [sensorSelected, setCheckedSensor] = useState(sensorState.activeSensor);
  const [showConfirmSensorSwitchModal, setShowConfirmSensorSwitchModal] =
    useState(false);
  const [showCheckPodCompatibilityModal, setShowCheckPodCompatibilityModal] =
    useState(false);

  useEffect(() => {
    setCheckedSensor(sensorState.activeSensor);
  }, []);

  const radioButtonOnPressHandler = (sensor: SensorType) => {
    setCheckedSensor(sensor);
  };

  const getNavigateToValue = () => {
    dispatch(
      sensorActions.sensorUpdate({ selectedSensorFlow: sensorSelected })
    );
    switch (sensorSelected) {
      case sensors.G6:
        return navigate('InitialSetupG6Stack');
      case sensors.G7:
        return setShowCheckPodCompatibilityModal(true);
      case sensors.L2:
        return navigate('InitialSetupL2');
      default:
        return navigate('SelectionConfirm');
    }
  };

  const rightActionOnPressHandler = () => {
    if (sensorSelected === sensors.NO_SENSOR) {
      return;
    }
    if (!isFTS && sensorState.activeSensor !== sensorSelected) {
      setShowConfirmSensorSwitchModal(true);
    } else {
      getNavigateToValue();
    }
  };

  const confirmButtonOnPressHandler = () => {
    if (sensorSelected === sensors.G7) {
      setShowCheckPodCompatibilityModal(true);
    }
    setShowConfirmSensorSwitchModal(false);
    getNavigateToValue();
  };
  const rightActionDisabled = !isFTS && sensorState.activeSensor === sensorSelected;

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text style={theme.fonts.h4}>{languageContent.selectSensor}</Text>
      </View>
      <ScrollView>
        {sensorList.map((name, key) => (
          <View key={key} style={styles.section}>
            <CustomRadioButton
              item={{ label: name }}
              index={key}
              selectedItem={sensorSelected}
              handleRadioButtonPress={() =>
                radioButtonOnPressHandler(name as SensorType)
              }
            />
          </View>
        ))}
      </ScrollView>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.save}
        leftAction
        leftActionNavigateTo="Drawer"
        rightAction={rightActionOnPressHandler}
        rightActionDisabled={rightActionDisabled}
      />
      <Modal isVisible={showCheckPodCompatibilityModal}>
        <CheckPodCompatibility
          continueButtonOnPressHandler={() => navigate('InitialSetupG7Stack')}
        />
      </Modal>
      <Modal isVisible={showConfirmSensorSwitchModal}>
        <ConfirmSensorSwitch
          sensorSelected={sensorSelected}
          activeSensor={sensorState.activeSensor}
          cancelButtonOnPressHandler={() =>
            setShowConfirmSensorSwitchModal(false)
          }
          confirmButtonOnPressHandler={confirmButtonOnPressHandler}
        />
      </Modal>
    </View>
  );
};

export default SensorSelection;
