import React from "react";
import { Image, StyleSheet, View } from "react-native-web";
import ChangePodGif from "../../../../../../../assets/changePod.gif";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import theme from "../../../../../theme";
import { StepListItem } from "../../../../atoms";
import { BottomInnerNav } from "../../../../molecules";
import { strings } from "./ChangePodScreenContent";
const ChangePodScreen: React.FC = () => {
  const { languageContent } = useLanguageContent(strings);
  return (
    <>
      <View
        style={[
          theme.layout.wrapper,
          { backgroundColor: "white", marginTop: -8 }
        ]}>
        <View style={[styles.innerWrapper]}>
          <StepListItem
            stepNumber="1"
            textBeforeHighlight={languageContent.stepOneDesc}
          />

          <StepListItem
            stepNumber="2"
            textBeforeHighlight={languageContent.stepTwoDesc}
            boldedText={languageContent.next}
            lastStepOnPage
          />
          <View style={[styles.imageContainer]}>
            <Image
              style={styles.imageCanvas}
              resizeMode="contain"
              source={ChangePodGif}
            />
          </View>
        </View>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.next}
        rightActionNavigateTo="ChangePodTwo"
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    paddingHorizontal: 24,
    marginTop: 40,
    width: 320
  },
  stepBorder: {
    flexDirection: "row",
    borderLeftWidth: 1,
    borderLeftColor: "#e0e0e0",
    minHeight: 24,
    marginTop: 24
  },
  stepBorderEnd: {
    flexDirection: "row",
    minHeight: 24,
    marginTop: 24
  },
  stepBlueCircle: {
    backgroundColor: theme.colors.blueCircle,
    borderRadius: 50,
    height: 30,
    width: 30,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
    marginLeft: -15,
    marginTop: -40
  },
  stepTextAlign: {
    marginTop: -40
  },
  imageContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  imageCanvas: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "85%",
    height: "85%"
  }
});

export default ChangePodScreen;
