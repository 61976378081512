import React from 'react';
import { ReactComponent as CgmArrowManual } from 'assets/ios/icons/cgm-arrow-manual.svg';
import { ReactComponent as CgmArrowAutomated } from 'assets/ios/icons/cgm-arrow-automated.svg';

interface CgmIconProps {
  className?: string;
  operationMode: 'manual' | 'automated' | 'limited';
}

export const CgmIcon: React.FC<CgmIconProps> = ({ className = '', operationMode }) => {
  switch (operationMode) {
    case 'automated':
      return <CgmArrowAutomated className={className} />
    case 'manual':
    default:
      return <CgmArrowManual className={className} />
  }
}
