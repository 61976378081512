let strings = {
  "en-US": {
    reviewTime: "Review time segments and values.",
    segmentOne: "Segment: 1",
    segmentTwo: "Segment: 2",
    segmentThree: "Segment: 3",
    insulinCovers: "1 U of insulin covers:",
    fifteenOfCarbs: "15 g of carbs",
    tenOfCarbs: "10 g of carbs",
    thirteenOfCarbs: "13 g of carbs",
    cancel: "Cancel",
    save: "Save",
    modalTitle: "Segment Editing",
    modalBody: "Not available for the simulator.",
    ok: "Ok"
  },
  "en-GB": {
    reviewTime: "Review time segments and values.",
    segmentOne: "Segment: 1",
    segmentTwo: "Segment: 2",
    segmentThree: "Segment: 3",
    insulinCovers: "1 U of insulin covers:",
    fifteenOfCarbs: "15 g of carbs",
    tenOfCarbs: "10 g of carbs",
    thirteenOfCarbs: "13 g of carbs",
    cancel: "Cancel",
    save: "Save",
    modalTitle: "Segment Editing",
    modalBody: "Not available for the simulator.",
    ok: "Ok"
  },
  "de-DE": {
    reviewTime: "Zeitsegmente und Werte überprüfen.",
    segmentOne: "Segment: 1",
    segmentTwo: "Segment: 2",
    segmentThree: "Segment: 3",
    insulinCovers: "1 E Insulin reicht für:",
    fifteenOfCarbs: "15 g KH",
    tenOfCarbs: "10 g KH",
    thirteenOfCarbs: "13 g KH",
    cancel: "Abbr.",
    save: "Speichern",
    modalTitle: "Segmentbearbeitung",
    modalBody: "Nicht für den Simulator verfügbar.",
    ok: "OK"
  },
  "es-US": {
    reviewTime: "Revisar los segmentos temporales y valores.",
    segmentOne: "Segmento: 1",
    segmentTwo: "Segmento: 2",
    segmentThree: "Segmento: 3",
    insulinCovers: "1 unidad de insulina cubre:",
    fifteenOfCarbs: "15 g de carb.",
    tenOfCarbs: "10 g de carb.",
    thirteenOfCarbs: "13 g de carb.",
    cancel: "Cancelar",
    save: "Guardar",
    modalTitle: "Editar segmentos",
    modalBody: "No está disponible en el simulador.",
    ok: "Aceptar"
  },
  "fr-FR": {
    reviewTime: "Vérifiez les segments temporels et les valeurs.",
    segmentOne: "Segment : 1",
    segmentTwo: "Segment : 2",
    segmentThree: "Segment : 3",
    insulinCovers: "1 U d'insuline couvre :",
    fifteenOfCarbs: "15 g de glucides",
    tenOfCarbs: "10 g de glucides",
    thirteenOfCarbs: "13 g de glucides",
    cancel: "Annuler",
    save: "Enregistrer",
    modalTitle: "Modification du segment",
    modalBody: "Non disponible pour le simulateur.",
    ok: "Ok"
  },
  "nl-NL": {
    reviewTime: "Tijdsegmenten en waarden nakijken.",
    segmentOne: "Segment: 1",
    segmentTwo: "Segment: 2",
    segmentThree: "Segment: 3",
    insulinCovers: "1 E insuline is voldoende voor:",
    fifteenOfCarbs: "15 g KH",
    tenOfCarbs: "10 g KH",
    thirteenOfCarbs: "13 g KH",
    cancel: "Annuleren",
    save: "Opslaan",
    modalTitle: "Segment bewerken",
    modalBody: "Niet beschikbaar voor de simulator.",
    ok: "OK"
  }
};

export { strings };
