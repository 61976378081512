let strings = {
  "en-US": {
    selectLanguage: "Select your language",
    continue: "Continue"
  },
  "en-GB": {
    selectLanguage: "Select your language",
    continue: "Continue"
  },
  "de-DE": {
    selectLanguage: "Wählen Sie Ihre Sprache",
    continue: "Weiter"
  },
  "es-US": {
    selectLanguage: "Seleccione su idioma",
    continue: "Continuar"
  },
  "fr-FR": {
    selectLanguage: "Sélectionner votre langue",
    continue: "Continuer"
  },
  "nl-NL": {
    selectLanguage: "Selecteer uw taal",
    continue: "Doorgaan"
  }
};

export { strings };
