import React from "react"
import { StyleSheet, Text, View } from "react-native-web"
import useLanguageContent from "../../../hooks/useLanguageContent"
import theme from "../../../theme"
import { strings } from "./DateBannerContent"

interface Props {
  today: string
}

const DateBanner: React.FC<Props> = ({ today }) => {
  const { languageContent } = useLanguageContent(strings)
  return (
    <View
      style={{
        backgroundColor: theme.colors.timeSegmentsBorder,
        borderColor: theme.colors.grayScale.gray600,
        borderWidth: 1,
      }}
    >
      <Text
        style={[styles.todayBanner]}
      >{`${languageContent.yesterday}, ${today}`}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  todayBanner: {
    padding: 2,
    textAlign: "left",
    ...theme.fonts.body1,
    paddingLeft: 16,
    borderBottomWidth: 0.5,
    borderBottomColor: theme.colors.textInputBorder,
    borderTopWidth: 0.5,
    borderTopColor: theme.colors.textInputBorder,
    fontSize: 15,
  },
})

export default DateBanner
