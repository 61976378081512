let strings = {
  "en-US": {
    segment: "Segment",
    reviewSeg: "Review time segments and values.",
    insulinDecreaseBy: "1 U of insulin lowers Glucose by:",
    cancel: "Cancel",
    save: "Save",
    segEditing: "Segment Editing",
    notAvail: "Not available for the simulator.",
    ok: "Ok"
  },
  "en-GB": {
    segment: "Segment",
    reviewSeg: "Review time segments and values.",
    insulinDecreaseBy: "1 U of insulin lowers glucose by:",
    cancel: "Cancel",
    save: "Save",
    segEditing: "Segment Editing",
    notAvail: "Not available for the simulator.",
    ok: "Ok"
  },
  "de-DE": {
    segment: "Segment",
    reviewSeg: "Zeitsegmente und Werte überprüfen.",
    insulinDecreaseBy: "1 E Insulin senkt den Blutzucker um:",
    cancel: "Abbr.",
    save: "Speichern",
    segEditing: "Segmentbearbeitung",
    notAvail: "Nicht für den Simulator verfügbar.",
    ok: "OK"
  },
  "es-US": {
    segment: "Segmento",
    reviewSeg: "Revisar los segmentos temporales y valores.",
    insulinDecreaseBy: "1 U de insulina reduce la glucosa:",
    cancel: "Cancelar",
    save: "Guardar",
    segEditing: "Editar segmentos",
    notAvail: "No está disponible en el simulador.",
    ok: "Aceptar"
  },
  "fr-FR": {
    segment: "Segment",
    reviewSeg: "Vérifiez les segments temporels et les valeurs.",
    insulinDecreaseBy: "1 U d'insuline réduit le glucose de :",
    cancel: "Annuler",
    save: "Enregistrer",
    segEditing: "Modification du segment",
    notAvail: "Non disponible pour le simulateur.",
    ok: "Ok"
  },
  "nl-NL": {
    segment: "Segment",
    reviewSeg: "Tijdsegmenten en waarden nakijken.",
    insulinDecreaseBy: "1 E insuline verlaagt de glucose met:",
    cancel: "Annuleren",
    save: "Opslaan",
    segEditing: "Segment bewerken",
    notAvail: "Niet beschikbaar voor de simulator.",
    ok: "OK"
  }
};

export { strings };
