let strings = {
  "en-US": {
    goBack: "Go back to previous screen?",
    loseChanges:
      "You will lose any changes made on the current screen if you go back.",
    yes: "Yes, go back",
    cancel: "Cancel"
  },
  "en-GB": {
    goBack: "Go back to previous screen?",
    loseChanges:
      "You will lose any changes made on the current screen if you go back.",
    yes: "Yes, go back",
    cancel: "Cancel"
  },
  "de-DE": {
    goBack: "Zurück zu vorherigem Bildschirm?",
    loseChanges:
      "Wenn Sie auf „Zurück“ gehen, werden alle Änderungen am aktuellen Bildschirm verworfen.",
    yes: "Ja, zurückgehen",
    cancel: "Abbr."
  },
  "es-US": {
    goBack: "¿Volver a la pantalla anterior?",
    loseChanges:
      "Perderá los cambios realizados en la pantalla actual si vuelve atrás.",
    yes: "Sí, volver atrás",
    cancel: "Cancelar"
  },
  "fr-FR": {
    goBack: "Revenir à l'écran précédent ?",
    loseChanges:
      "Si vous revenez en arrière, toutes les modifications apportées à l'écran actuel seront perdues.",
    yes: "Oui, revenir en arrière",
    cancel: "Annuler"
  },
  "nl-NL": {
    goBack: "Teruggaan naar vorig scherm?",
    loseChanges: "Als u teruggaat, gaan uw wijzigingen in dit scherm verloren.",
    yes: "Ja, ga terug",
    cancel: "Annuleren"
  }
};

export { strings };
