let strings = {
  "en-US": {
    allSet: "All Set!",
    useDevice: "To use the Omnipod 5 App on this device, tap ",
    continueTwo: "CONTINUE.",
    anotherDevice:
      "To use another compatible device, download and set up the Omnipod 5 App on that device.",
    continue: "Continue"
  },
  "en-GB": {
    allSet: "All Set!",
    useDevice: "To use the Omnipod 5 App on this device, tap ",
    continueTwo: "CONTINUE.",
    anotherDevice:
      "To use another compatible device, download and set up the Omnipod 5 App on that device.",
    continue: "Continue"
  },
  "es-US": {
    allSet: "¡Todo listo!",
    useDevice:
      "Para utilizar la aplicación Omnipod 5 en este dispositivo, pulse ",
    continueTwo: "CONTINUAR.",
    anotherDevice:
      "Para usar otro dispositivo compatible, descargue y configure la aplicación Omnipod 5 en ese dispositivo.",
    continue: "Continuar"
  },
  "de-DE": {
    allSet: "Fertig!",
    useDevice:
      "Zur Verwendung der Omnipod 5 App auf diesem Gerät tippen Sie bitte auf ",
    continueTwo: "FORTFAHREN",
    anotherDevice: "",
    continue: "Weiter"
  },
  "nl-NL": {
    allSet: "Volledig klaar!",
    useDevice: "Om de Omnipod 5 app te gebruiken op dit apparaat, tikt u op ",
    continueTwo: "DOORGAAN.",
    continue: "DOORGAAN"
  },
  "fr-FR": {
    allSet: "Vous êtes prêt(e)!",
    useDevice: "Pour utiliser l\'application Omnipod 5 sur cet appareil, appuyez sur",
    continueTwo: "CONTINUER.",
    anotherDevice:
    "Pour utiliser un autre appareil compatible, téléchargez et configurez l\'application sur cet appareil.Omnipod 5",
    continue: "CONTINUER"
  }
};

export { strings };
