import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const ChevronRight: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={classes} id="SF Symbol / chevron.right" d="M7.99979 6.99658C7.99979 6.71603 7.89715 6.48337 7.67818 6.26441L2.40928 1.11183C2.23137 0.933919 2.01924 0.851807 1.76606 0.851807C1.25286 0.851807 0.835449 1.25553 0.835449 1.76873C0.835449 2.02191 0.944933 2.25457 1.12969 2.43932L5.81011 6.98974L1.12969 11.5538C0.944933 11.7386 0.835449 11.9644 0.835449 12.2244C0.835449 12.7376 1.25286 13.1482 1.76606 13.1482C2.01924 13.1482 2.23137 13.0592 2.40928 12.8813L7.67818 7.72875C7.90399 7.50978 7.99979 7.27713 7.99979 6.99658Z" fill="#3C3C43" />
    </svg>
  );
}
