import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from 'web/components/ios/Button';
import { bolusCalculationsMessages } from '../BolusAdministration.messages';
import { selectCurrentOperationMode } from 'web/store/reducers/user/user.helpers';

interface BolusCalculationsDrawerProps {
  onClose: () => void;
}

export const BolusCalculationsDrawer: React.FC<
  BolusCalculationsDrawerProps
> = ({ onClose }) => {
  const operationMode = useSelector(selectCurrentOperationMode);

  return (
    <div className="absolute inset-0 bg-black/50 flex justify-center font-ios items-end w-full">
      <div className="bg-white rounded-[16px] w-full">
        <div className="pb-2 pt-0 font-ios">
          <div
            className="pt-1 pb-2 flex align-center justify-center cursor-pointer"
            onClick={onClose}
          >
            <div className="w-[36px] h-[5px] rounded-[100px] bg-ios-gray-9/[.18]" />
          </div>

          <div className="text-modals">
            <div className="text-2xl font-bold px-2 pb-[20px] border-b-1 border-b-ios-gray-9/30">
              <FormattedMessage {...bolusCalculationsMessages.title} />
            </div>

            <div className="flex justify-between px-2 mt-2">
              <div>
                <FormattedMessage {...bolusCalculationsMessages.mealBolus} />
              </div>
              <div className="text-ios-gray-9/60">
                <FormattedMessage
                  {...bolusCalculationsMessages.units}
                  values={{ value: 2 }}
                />
              </div>
            </div>
            <div className="flex justify-between px-2 mt-2">
              <div>
                <FormattedMessage
                  {...bolusCalculationsMessages.correctionBolus}
                />
              </div>
              <div className="text-ios-gray-9/60">
                <FormattedMessage
                  {...bolusCalculationsMessages.units}
                  values={{ value: 1 }}
                />
              </div>
            </div>
            <div className="flex justify-between px-2 mt-2">
              <div className="max-w-[230px]">
                <FormattedMessage
                  {...bolusCalculationsMessages.iobAdjustment}
                />
              </div>
              <div className="text-ios-gray-9/60">
                <FormattedMessage
                  {...bolusCalculationsMessages.units}
                  values={{ value: -1.5 }}
                />
              </div>
            </div>
            <div className="flex justify-between px-2 mt-2 pb-[34px] mb-[22px] border-b-1 border-b-ios-gray-9/30">
              <div>
                <FormattedMessage
                  {...bolusCalculationsMessages.yourAdjustment}
                />
              </div>
              <div className="text-ios-gray-9/60">
                <FormattedMessage
                  {...bolusCalculationsMessages.units}
                  values={{ value: 1.5 }}
                />
              </div>
            </div>

            <div className="w-full text-right font-semibold px-2 mb-[28px]">
              <span className="mr-3">
                <FormattedMessage {...bolusCalculationsMessages.totalBolus} />
              </span>
              <span className="text-2xl">
                <FormattedMessage
                  {...bolusCalculationsMessages.units}
                  values={{ value: 3 }}
                />
              </span>
            </div>

            <div className="px-2 pb-[20px]">
              <Button variant="colored" colorMode={operationMode} full onClick={onClose}>
                <FormattedMessage {...bolusCalculationsMessages.button} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
