let strings = {
  'en-US': {
    activity: 'Activity',
  },
  'en-GB': {
    activity: 'Activity',
  },
  'de-DE': {
    activity: 'Aktivität',
  },
  'es-US': {
    activity: 'Actividad',
  },
  'nl-NL': {
    activity: 'Activiteit',
  },
  'fr-FR': {
    activity: 'Activité',
  },
};

export { strings };
