let strings = {
  "en-US": {
    setupComplete: "Setup is complete!",
    connectSensorToOmnipod: "Next, connect your Sensor to Omnipod\u00A05.",
    connectSensor: "Connect Sensor",
    notNow: "Not Now",
    showModalTitle: "Disabled for Simulator",
    showModalMessage:
      "The Sensor is needed for demo purposes so we have disabled this button.",
    confirmText: "OK"
  },
  "en-GB": {
    setupComplete: "Set-up is complete!",
    connectSensorToOmnipod: "Next, connect your Sensor to Omnipod\u00A05.",
    connectSensor: "Connect Sensor",
    notNow: "Not Now",
    showModalTitle: "Disabled for Simulator",
    showModalMessage:
      "The Sensor is needed for demo purposes so we have disabled this button.",
    confirmText: "OK"
  },
  "de-DE": {
    setupComplete: "Einrichtung abgeschlossen!",
    connectSensorToOmnipod:
      "Verbinden Sie als nächstes Ihren Sensor mit dem Omnipod 5. ",
    connectSensor: "Sensor verbinden",
    notNow: "Jetzt nicht",
    showModalTitle: "Für Simulator deaktiviert",
    showModalMessage:
      "Der Sensor wird für Demozwecke benötigt, daher haben wir diese Schaltfläche deaktiviert.",
    confirmText: "OK"
  },
  "es-US": {
    setupComplete: "¡Se completó la configuración!",
    connectSensorToOmnipod: "Ahora, conecte el sensor con Omnipod 5.",
    connectSensor: "Conectar sensor",
    notNow: "Ahora no",
    showModalTitle: "Inhabilitado para el simulador",
    showModalMessage:
      "El sensor es necesario para la demostración, por lo que se inhabilitó este botón.",
    confirmText: "Aceptar"
  },
  "fr-FR": {
    setupComplete: "Configuration terminée !",
    connectSensorToOmnipod: "Ensuite, connectez votre Capteur à l'Omnipod 5.",
    connectSensor: "Connecter le capteur",
    notNow: "Pas maintenant",
    showModalTitle: "Désactivé pour le simulateur",
    showModalMessage:
      "Le capteur est requis à des fins de démonstration, nous avons par conséquent désactivé ce bouton.",
    confirmText: "OK"
  },
  "nl-NL": {
    setupComplete: "Instellen is voltooid!",
    connectSensorToOmnipod: "Verbind uw sensor nu met de Omnipod 5.",
    connectSensor: "Sensor verbinden",
    notNow: "Niet nu",
    showModalTitle: "Uitgeschakeld voor simulator",
    showModalMessage:
      "De sensor is nodig voor demonstratiedoeleinden; we hebben deze knop dus uitgeschakeld.",
    confirmText: "OK"
  }
};

export { strings };
