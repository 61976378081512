let strings = {
  'en-US': {
    enter: 'Enter Pairing Code',
    pairingCode: 'The pairing code is printed on the sensor applicator.',
    setUp:
      'If you have already set up your Sensor in the Dexcom app, you can find the pairing code and serial number in your Dexcom app Sensor settings.',
    save: 'Save',
    cancel: 'Cancel',
    confirmPairingCode: 'Confirm Pairing Code',
    codeNumber: '1  2  3  4',
    confirm: 'Confirm',
  },
  'es-US': {
    enter: 'Ingresar Código de Emparejamiento',
    pairingCode:
      'El código de emparejamiento está impreso en el aplicador del Sensor.',
    setUp:
      'Si ya tiene configurado su Sensor en la aplicación de Dexcom, puede encontrar el código de emparejamiento y el Número de serie en sus ajustes del Sensor de la aplicación de Dexcom.',
    save: 'Guardar',
    cancel: 'Cancelar',
    confirmPairingCode: 'Confirmar Código de Emparejamiento',
    codeNumber: '1 2 3 4',
    confirm: 'Confirmar',
  },
};

export { strings };
