let strings = {
  'en-US': {
    basalPrograms: 'Basal Programs',
    editBasalProgram: 'Edit Basal Program',
    confirmProgram: 'Confirm Program',
  },
  'en-GB': {
    basalPrograms: 'Basal Programmes',
    editBasalProgram: 'Edit Basal Programme',
    confirmProgram: 'Confirm Programme',
  },
  'de-DE': {
    basalPrograms: 'Basalratenprofile',
    editBasalProgram: 'Basalratenprofil bearbeiten',
    confirmProgram: 'Profil bestätigen',
  },
  'es-US': {
    basalPrograms: 'Programas basales',
    editBasalProgram: 'Editar programa basal',
    confirmProgram: 'Confirmar programa',
  },
  'nl-NL': {
    basalPrograms: "Basaalprogramma's",
    editBasalProgram: 'Basaalprogramma bewerken',
    confirmProgram: 'Programma bevestigen',
  },
  'fr-FR': {
    basalPrograms: 'Programmes basaux',
    editBasalProgram: 'Modifier le programme basal',
    confirmProgram: 'Confirmer le programme',
  },
};

export { strings };
