import { useNavigation } from '@react-navigation/native';
import logo from 'assets/bolus_intro1.png';
import basalIcon from 'assets/ios/ios_fts_basal_icon.svg';
import { Button } from 'web/components/ios/Button';
import { IOSBasalRoutesEnums } from 'web/containers/ios/Basal/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages, IntroBasalMessages } from '../Basal.messages';

export const IntroBasal = () => {
  const { navigate } = useNavigation();
  const intl = useIntl();

  return (
    <div className="flex border-t-1 flex-col w-full h-full bg-white">
      <div className="basis-2/5 pt-8 px-6">
        <div className="flex items-center justify-center border-0.5 border-ios-gray-7 mb-5 w-12 h-12 rounded-full">
          <div
            className="bg-cover w-5 h-5 "
            style={{ backgroundImage: `url(${basalIcon})` }}
          ></div>
        </div>
        <h1 className="text-4xl font-bold mb-2">
          <FormattedMessage
            {...IntroBasalMessages.headerTitle}
            values={{
              highlight: (chunk) => (
                <span className="text-modes-automated">{chunk}</span>
              ),
            }}
          />
        </h1>
        <p className="text-regular">
          {intl.formatMessage(IntroBasalMessages.text)}
        </p>
      </div>
      <div className="basis-9/12">
        <div
          className="bg-cover w-full h-full p-6 flex flex-col-reverse"
          style={{ backgroundImage: `url(${logo})` }}
        >
          <Button
            className=""
            onClick={() => navigate(IOSBasalRoutesEnums.SetMaximumBasalRate)}
            variant="colored"
          >
            {intl.formatMessage(messages.nextBtn)}
          </Button>
        </div>
      </div>
    </div>
  );
};
