import { FormattedMessage } from 'react-intl';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import theme from 'mobile/latest/theme';
import { L2Paths } from 'web/routes/L2/InitialSetupL2';
import Button from 'web/components/atoms/Button/Button';
import { Container, Card } from 'web/components/helpers/Layout/Layout';
import { messages } from './ApplySensor.messages';
import { ReactComponent as HelpIcon } from 'assets/Help-icon.svg';
import { ReactComponent as ApplySensor0 } from 'assets/L2/HowToApplySensor_00.svg';

const styles = StyleSheet.create({
  howToApply: {
    color: theme.colors.purple,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  howToApplyContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    marginVertical: 32,
  },
  icon: {
    marginRight: 8,
  },
});

export const ApplySensor = () => {
  const { navigate } = useNavigation();
  const handleHowToApplyClick = () => navigate(L2Paths.howToApply);
  const handleContinueClick = () => navigate(L2Paths.scanSensor);
  return (
    <Container>
      <Card>
        <Text>
          <FormattedMessage {...messages.mainText} />
        </Text>
        <View style={styles.image}>
          <ApplySensor0 width="242" height="310" />
        </View>
        <View style={styles.howToApplyContainer}>
          <View style={styles.icon}>
            <HelpIcon />
          </View>
          <Text onPress={handleHowToApplyClick} style={styles.howToApply}>
            <FormattedMessage {...messages.howToApply} />
          </Text>
        </View>
      </Card>
      <Card>
        <Button onClick={handleContinueClick}>
          <FormattedMessage {...messages.cta} />
        </Button>
      </Card>
    </Container>
  );
};
