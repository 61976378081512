import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  highGlucoseTitle: {
    id: 'L2.settings.high-glucose.title',
    defaultMessage: 'High Glucose'
  },
  highGlucoseNotify: {
    id: 'L2.settings.high-glucose.notification',
    defaultMessage: 'Notify when glucose goes above this level'
  },
  glucoseRange: {
    id: 'L2.settings.high-glucose-range',
    defaultMessage: '(6.7 mmol/L to 22.2 mmol/L)'
  }
})
