import { format } from "date-fns";
import { enUS, es, Locale } from "date-fns/locale";

const locales = { enUS, es };

// TODO: this is only used by multiday history, and should not be kept. Must be refactored to use more modern approaches

export const getLocalizedDateFormat = (
  date: any,
  formatStr = "PP",
  locale: Locale
) => {
  let tempLocale;
  if (locale === enUS) {
    tempLocale = locales.enUS;
  } else if (locale === es) {
    tempLocale = locales.es;
  }
  return format(date, formatStr, {
    locale: tempLocale
  });
};
