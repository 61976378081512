let strings = {
  "en-US": {
    range: "(10 to 50 U)",
    lowPodInsulin: "Low Pod Insulin",
    notify:
      "The Omnipod 5 App notifies you when the \namount of insulin in your Pod reaches this \nlevel.",
    units: "U",
    leftActionText: "cancel",
    rightActionText: "Save"
  },
  "en-GB": {
    range: "(10 to 50 U)",
    lowPodInsulin: "Low Pod Insulin",
    notify:
      "The Omnipod 5 App notifies you when the \namount of insulin in your Pod reaches this \nlevel.",
    units: "U",
    leftActionText: "cancel",
    rightActionText: "Save"
  },
  "es-US": {
    range: "(10 a 50 U)",
    lowPodInsulin: "Pod con insulina baja",
    notify:
      "La aplicación Omnipod 5 le notifica cuando \nla cantidad de insulina en su Pod llega a este \n nivel.",
    units: "u",
    leftActionText: "cancelar",
    rightActionText: "guardar"
  },
  "de-DE": {
    range: "(10 bis 50 E)",
    lowPodInsulin: "Wenig Insulin im Pod",
    notify:
      "Wenn das Insulinvolumen in Ihrem Pod \ndiesen Wert erreicht, gibt die Omnipod 5 App \neine Nachricht aus.",
    units: "E",
    leftActionText: "Abbr.",
    rightActionText: "Speichern"
  },
  "nl-NL": {
    range: "(10 tot 50 E)",
    lowPodInsulin: "Weinig insuline in Pod",
    notify:
      "De Omnipod 5 app waarschuwt als de \nhoeveelheid insuline in uw Pod dit niveau \nbereikt.",
    units: "E",
    leftActionText: "Annuleren",
    rightActionText: "Opslaan"
  },
  "fr-FR": {
    range: "(10 à 50 U)",
    lowPodInsulin: "Insuline du Pod basse",
    notify:
      "L\'application Omnipod 5 vous avertit lorsque la quantité d\'insuline dans votre Pod atteint ce niveau.",
    units: "U",
    leftActionText: "annuler",
    rightActionText: "enregistrer"
  },
};

export { strings };
