import { initialState as langInitialState, LangState } from "./reducer";

export const LANGUAGE_UPDATE = "language_update";
export const LANGUAGE_INIT = "language_init";
export const LANGUAGE_RESET = "language_reset";

interface ActionUpdate {
  type: ActionType;
  payload: {
    language: Partial<LangState>;
  };
}

interface ActionInit {
  type: ActionType;
  payload: {
    language: LangState;
  };
}

type ActionType = typeof LANGUAGE_UPDATE | typeof LANGUAGE_INIT;

export const languageUpdate = (
  languageData: Partial<LangState>,
  initialState?: LangState
): Action => {
  return {
    type: LANGUAGE_UPDATE,
    payload: {
      language: languageData,
    },
  };
};

export const languageInit = (): Action => {
  return {
    type: LANGUAGE_INIT,
    payload: {
      language: langInitialState,
    },
  };
};

export type Action = ActionUpdate | ActionInit;
