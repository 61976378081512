export const getViewerStatus = (status: string, language: string) => {
  switch (language) {
    case "en":
      return status;
    case "es":
      if (status === "Active") {
        return "Activo";
      }
  }
};
