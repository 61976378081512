let strings = {
  'en-US': {
    headerText1: 'About the Omnipod®\u00A05 \nSimulator',
    version: '1.1.23',
    insulet: 'Insulet Corporation',
    udi: '<UDI>',
    copyright: '<copyright_year>',
    customerCare: 'Customer Care',
    customerCareHotline: '<customer_care_hotline>',
    omnipodURL: 'www.myomnipod.com',
    serial: 'Serial Number',
    number: '130088042',
    fccID: 'Pod FCC ID',
    fccIDFull: 'FCC ID: FBV-029C',
    podVersion: 'Pod version',
    podVersionNumber: '5.1.4-2.5.0',
    releaseNotes: 'Release notes',
    versionText: 'Version: 1.1.23',
    lastCommunication: 'Last communication with Pod',
    lastCommunicationSubtext: 'June 10, 2017  7:57 PM (New York)',
    lastSync: 'Last sync to cloud',
    lastSyncSubtext: 'June 10, 2017  9:29 PM (New York)',
    logFiles: 'Send log files',
    sendLogFiles: 'Send files to Customer Care',
    deviceInformation: 'Device information',
    deviceInformationSubtext: 'NUU_A1, A1-AM-DS88-05, 6.0',
    legalInformation: 'Legal information',
    legalInformationSubtext: 'Terms and Conditions for Omnipod 5',
  },
  'en-GB': {
    headerText1: 'About the Omnipod®\u00A05 \nSimulator',
    version: '1.1.23',
    insulet: 'Insulet Corporation',
    udi: '<UDI>',
    copyright: '<copyright_year>',
    customerCare: 'Customer Care',
    customerCareHotline: '<customer_care_hotline>',
    omnipodURL: 'www.myomnipod.com',
    serial: 'Serial Number',
    number: '130088042',
    fccID: 'Pod FCC ID',
    fccIDFull: 'FCC ID: FBV-029C',
    podVersion: 'Pod version',
    podVersionNumber: '5.1.4-2.5.0',
    releaseNotes: 'Release notes',
    versionText: 'Version: 1.1.23',
    lastCommunication: 'Last communication with Pod',
    lastCommunicationSubtext: 'June 10, 2017  7:57 PM (New York)',
    lastSync: 'Last sync to cloud',
    lastSyncSubtext: 'June 10, 2017  9:29 PM (New York)',
    logFiles: 'Send log files',
    sendLogFiles: 'Send files to Customer Care',
    deviceInformation: 'Device information',
    deviceInformationSubtext: 'NUU_A1, A1-AM-DS88-05, 6.0',
    legalInformation: 'Legal information',
    legalInformationSubtext: 'Terms and Conditions for Omnipod 5',
  },
  'es-US': {
    headerText1: 'About the Omnipod®\u00A05 \nSimulator',
    version: '1.1.23',
    insulet: 'Insulet Corporation',
    udi: '<UDI>',
    copyright: '<copyright_year>',
    customerCare: 'Atención al Cliente',
    customerCareHotline: '<customer_care_hotline>',
    omnipodURL: 'www.myomnipod.com',
    serial: 'Número de serie',
    number: '130088042',
    fccID: 'Pod FCC ID',
    fccIDFull: 'FCC ID: FBV-029C',
    podVersion: 'Versión del Pod',
    podVersionNumber: '5.1.4-2.5.0',
    releaseNotes: 'Notas de publicación',
    versionText: '1.1.23',
    lastCommunication: 'Se perdió la comunicación con el Pod',
    lastCommunicationSubtext: 'No hay ningún Pod activo',
    lastSync: 'Última sincronización con la nube',
    lastSyncSubtext: 'J8 de agosto de 2022 4:20 PM (Etc/GMT+4)',
    logFiles: 'Enviar archivos de registro',
    sendLogFiles: 'Enviar archivos a Atención al Cliente',
    deviceInformation: 'Información del dispositivo',
    deviceInformationSubtext: 'NUU_A1, A1-AM-DS88-05, 6.0',
    legalInformation: 'Información legal',
    legalInformationSubtext:
      'Términos y condiciones del Sistema de Administración de Insulina Omnipod 5',
  },
  'de-DE': {
    headerText1: 'About the Omnipod®\u00A05 \nSimulator',
    version: '1.1.23',
    insulet: 'Insulet Corporation',
    udi: '<UDI>',
    copyright: '<copyright_year>',
    omnipodURL:
      'Wenden Sie sich bitte unter \nVerwendung der von Ihrem \nAnbieter bereitgestellten \nInformationen an unseren \nKundenservice.',
    serial: 'Seriennummer',
    number: '111111-18042',
    fccID: 'Pod FCC ID',
    fccIDFull: 'FCC ID: FBV-029C',
    podVersion: 'Pod-Version',
    podVersionNumber: '5.1.4-2.5.0',
    releaseNotes: 'Versionshinweise',
    versionText: '1.1.6',
    lastCommunication: 'Letzte Kommunikation mit dem Pod',
    lastCommunicationSubtext: 'Kein aktiver Pod',
    lastSync: 'Letzte Synchronisierung mit der Cloud',
    lastSyncSubtext: 'Nicht verfügbar',
    logFiles: 'Protokolldateien senden',
    sendLogFiles: 'Dateien an Kundenservice senden',
    deviceInformation: 'Geräteinformationen',
    deviceInformationSubtext: 'N5004L, 5004L-AM-Q-MV01602-04-01.03,10',
    legalInformation: 'Rechtsinformation',
    legalInformationSubtext: 'Allgemeine Geschäftsbedingungen für Omnipod 5',
  },
  'nl-NL': {
    headerText1: 'About the Omnipod®\u00A05 \nSimulator',
    version: '1.2.0-1426',
    insulet: 'Insulet Corporation',
    udi: '<UDI>',
    copyright: '<copyright_year>',
    customerCare:
      'Neem contact op met de Cliëntenzorg met de informatie die u van uw leverancier gekregen heeft.',
    customerCareHotline: '',
    omnipodURL: '',
    serial: 'Serienummer',
    number: '06038000-000002509',
    fccID: 'Pod FCC ID',
    fccIDFull: 'FCC ID: FBV-029C',
    podVersion: 'Versie Pod',
    podVersionNumber: '6.0.0-200.0.0',
    releaseNotes: 'Opmerkingen bij uitgave',
    versionText: '1.2.0-1426',
    lastCommunication: 'Laatste communicatie met Pod',
    lastCommunicationSubtext: '19 mei 2023 02:31 (Etc/GMT+7)',
    lastSync: 'Laatste synchronisatie met cloud',
    lastSyncSubtext: 'Neit beschikbaar',
    logFiles: 'Stuur logbestanden',
    sendLogFiles: 'Stuur bestanden naar de Cliëntenzorg',
    deviceInformation: 'Apparaategegevens',
    deviceInformationSubtext: 'NUU_A1, A1-AM-DS88-05, 6.0',
    legalInformation: 'Legal information',
    legalInformationSubtext: 'Terms and Conditions for Omnipod 5',
  },
  'fr-FR': {
    headerText1: 'About the Omnipod®\u00A05 \nSimulator',
    version: '1.1.23',
    insulet: 'Insulet Corporation',
    udi: '<UDI>',
    copyright: '<copyright_year>',
    customerCare: 'Customer Care',
    customerCareHotline: '<customer_care_hotline>',
    omnipodURL: 'www.myomnipod.com',
    serial: 'Numéro de série',
    number: '130088042',
    fccID: 'Pod FCC ID',
    fccIDFull: 'FCC ID: FBV-029C',
    podVersion: 'Version du Pod',
    podVersionNumber: '5.1.4-2.5.0',
    releaseNotes: 'Notes de version',
    versionText: 'Version: 1.1.23',
    lastCommunication: 'Dernière communication avec le Pod',
    lastCommunicationSubtext: 'June 10, 2017  7:57 PM (New York)',
    lastSync: 'Dernière synchronisation avec le cloud',
    lastSyncSubtext: 'June 10, 2017  9:29 PM (New York)',
    logFiles: 'Envoyer les fichiers du journal',
    sendLogFiles: 'Envoyer des fichiers au Service clients',
    deviceInformation: 'Informations sur le dispositif',
    deviceInformationSubtext: 'NUU_A1, A1-AM-DS88-05, 6.0',
    legalInformation: 'Informations légales',
    legalInformationSubtext: "Conditions générales de l'Omnipod 5",
  },
};

export { strings };
