import React from "react";
import { AuthButton, AuthForm } from "../../components";
import HomeHeader from "../../components/molecules/homeHeader";
const AuthorizationPage: React.FC = () => {
  return (
    <div>
      <HomeHeader />
      <AuthForm>
        <AuthButton label={"Sign In"} />
      </AuthForm>
    </div>
  );
};

export default AuthorizationPage;
