import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { SetTempBasal } from './views/SetTempBasal';
import { AppRoutesEnum } from 'web/routes/types';
import { TopBar } from 'web/components/ios/TopBar';
import { useIntl } from 'react-intl';
import { messages, confirmTempBasalMessages } from './TempBasal.messages';
import { IOSTempBasalEnum } from './types';
import { ConfirmTempBasal } from './views/ConfirmTempBasal';
import { useNavigation } from '@react-navigation/native';

const Stack = createStackNavigator();

export const IOSTempBasalStack: React.FC = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  return (
    <Stack.Navigator
      initialRouteName={AppRoutesEnum.SetTempBasal}
      screenOptions={{
        header: (props) => <TopBar {...props} expanded />,
      }}
      mode="modal"
    >
      <Stack.Screen
        name={AppRoutesEnum.SetTempBasal}
        options={{
          headerTitle: intl.formatMessage(messages.headerTitle),
        }}
        initialParams={{
          topBarOnLeftButtonClick: () => navigate(AppRoutesEnum.menu),
        }}
      >
        {() => <SetTempBasal editMode={false} />}
      </Stack.Screen>
      <Stack.Screen
        name={IOSTempBasalEnum.ConfirmTempBasal}
        options={{
          header: (props) => <TopBar {...props} backAndCancelMode />,
          headerTitle: intl.formatMessage(confirmTempBasalMessages.headerTitle),
        }}
        initialParams={{
          topBarOnLeftButtonClick: () => navigate(AppRoutesEnum.menu),
        }}
        component={ConfirmTempBasal}
      />
    </Stack.Navigator>
  );
};
