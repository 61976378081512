import { defineMessages } from 'react-intl';

export const podCommunicationMessages = defineMessages({
  title: {
    id: 'ios.modals.pod-communication.title',
    defaultMessage: 'Communicating with Pod',
  },
  text: {
    id: 'ios.modals.pod-communication.text',
    defaultMessage:
      'Please wait...',
  },
})

export const deactivatingPodMessages = defineMessages({
  title: {
    id: 'ios.modals.deactivating-pod.title',
    defaultMessage: 'Deactivating Pod',
  },
  text: {
    id: 'ios.modals.deactivating-pod.text',
    defaultMessage:
      'Please wait...',
  },
})

export const sendingInstructionMessages = defineMessages({
  title: {
    id: 'ios.modals.sending-instruction.title',
    defaultMessage: 'Communicating with Pod',
  },
  text: {
    id: 'ios.modals.sending-instruction.text',
    defaultMessage:
      'Sending instruction. Please wait...',
  },
})

export const cancelInstructionMessages = defineMessages({
  title: {
    id: 'ios.modals.cancel-instruction.title',
    defaultMessage: 'Are you sure you want to cancel?',
  },
  yes: {
    id: 'ios.modals.cancel-instruction.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'ios.modals.cancel-instruction.no',
    defaultMessage: 'No',
  },
});
