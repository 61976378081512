import { StyleSheet, Text, View } from "react-native";
import { LangState } from "web/store/reducers/language";
import { SummaryItem } from "../components/atoms";
import BasalProgramInProgress from "../components/molecules/basalProgramInProgress";
import TempBasalCancelled from "../components/molecules/tempBasalCancelled";
import TempBasalStarted from "../components/molecules/tempBasalStarted";
import replaceWithValues from "../helpers/replaceWithValues";
import theme from "../theme";

const LIGHT_BLUE = theme.colors.grayScale.gray600;
const MID_BLUE = "#0093e8";
const BLUE = "#005bbb";
const PURPLE = "#7a339d";
const WHITE = theme.colors.white;

const MANUAL_MODE_ICON = "manual mode icon";
const AUTOMATED_MODE_ICON = "auto mode icon";
const BASAL_PROGRAM_ICON = "basal program icon";
const INSULIN_PAUSED_ICON = "insulin paused icon";
const INSULIN_STARTED_ICON = "insulin started icon";
const POD_ICON = "pod icon";

const styles = StyleSheet.create({
  subItemWrapper: {
    padding: 16
  },
  subItemText: {
    fontSize: 16
  }
});

interface Content {
  glucoseManual: string;
  switchManual: string;
  switchAutomated: string;
  basalProgramStarted: string;
  basalProgramInProgress: string;
  basal1: string;
  tempBasalCanceled: string;
  tempBasalCanceledSubtext: string;
  tempBasalStarted: string;
  basalProgramContinued: string;
  insulinDeliveryStarted: string;
  insulinDeliveryPaused: string;
  lot: string;
  sequence: string;
  podActived: string;
  lastIOB: string;
  podDeactivated: string;
}

export const getHistoryDetailData = (
  languageContent: any,
  langState: LangState
) => {
  const zeroPointFive = (0.5).toLocaleString(langState.locale);
  const twoPointThree = (2.3).toLocaleString(langState.locale);
  const contentVariables = [zeroPointFive, twoPointThree];

  return [
    {
      isExpanded: false,
      time: "1:56 pm",
      subItems: <SummaryItem fullLine={true} />,
      index: 0,
      bgColor: WHITE,
      hasSummaryText: false,
      isCGM: true,
      cgmValue: 155,
      isBolus: true,
      bolusValue: 0.65,
      isCarbs: true,
      carbsValue: 10,
      hasBolusCalcButton: true
    },
    {
      isExpanded: false,
      time: "1:56 pm",
      subItems: (
        <View
          style={[
            styles.subItemWrapper,
            {
              borderTopWidth: 0.5,
              borderTopColor: theme.colors.textInputBorder,
              marginLeft: 64
            }
          ]}>
          <Text style={[styles.subItemText]}>
            {languageContent.glucoseManual}
          </Text>
        </View>
      ),
      index: 1,
      bgColor: WHITE,
      hasSummaryText: false,
      isCGM: true,
      cgmValue: 155
    },
    {
      isExpanded: false,
      time: "1:56 pm  ",
      index: 2,
      bgColor: MID_BLUE,
      hasSummaryText: true,
      startIcon: MANUAL_MODE_ICON,
      summaryText: `${languageContent.switchManual}`
    },
    {
      isExpanded: false,
      time: "12:15 pm",
      index: 3,
      bgColor: PURPLE,
      hasSummaryText: true,
      startIcon: AUTOMATED_MODE_ICON,
      summaryText: `${languageContent.switchAutomated}`
    },
    {
      isExpanded: false,
      time: "12:15 pm",
      subItems: <BasalProgramInProgress />,
      index: 4,
      bgColor: BLUE,
      hasSummaryText: true,
      startIcon: BASAL_PROGRAM_ICON,
      summaryText: `${languageContent.basalProgramInProgress}`,
      subText: `${languageContent.basal1}`
    },
    {
      isExpanded: false,
      time: "12:15 pm",
      subItems: <TempBasalCancelled />,
      index: 5,
      bgColor: BLUE,
      hasSummaryText: true,
      startIcon: BASAL_PROGRAM_ICON,
      summaryText: `${languageContent.tempBasalCanceled}`,
      subText: `${replaceWithValues(
        languageContent.tempBasalCanceledSubtext,
        contentVariables
      )}`
    },
    {
      isExpanded: false,
      time: "11:59 am",
      subItems: <TempBasalStarted />,
      index: 6,
      bgColor: BLUE,
      hasSummaryText: true,
      startIcon: BASAL_PROGRAM_ICON,
      summaryText: `${languageContent.tempBasalStarted}`,
      subText: `${replaceWithValues(
        languageContent.tempBasalCanceledSubtext,
        contentVariables
      )}`
    },
    {
      isExpanded: false,
      time: "11:59 am",
      subItems: <BasalProgramInProgress />,
      index: 7,
      bgColor: BLUE,
      hasSummaryText: true,
      startIcon: BASAL_PROGRAM_ICON,
      summaryText: `${languageContent.basalProgramInProgress}`,
      subText: `${languageContent.basal1}`
    },
    {
      isExpanded: false,
      time: "11:59 am",
      index: 8,
      bgColor: LIGHT_BLUE,
      hasSummaryText: true,
      startIcon: INSULIN_STARTED_ICON,
      summaryText: `${languageContent.insulinDeliveryStarted}`
    },
    {
      isExpanded: false,
      time: "11:57 am",
      index: 9,
      bgColor: LIGHT_BLUE,
      hasSummaryText: true,
      startIcon: INSULIN_PAUSED_ICON,
      summaryText: `${languageContent.insulinDeliveryPaused}`
    },
    {
      isExpanded: false,
      time: "11:57 am",
      index: 10,
      bgColor: MID_BLUE,
      hasSummaryText: true,
      startIcon: MANUAL_MODE_ICON,
      summaryText: `${languageContent.switchManual}`
    },
    {
      isExpanded: false,
      time: "11:44 am",
      subItems: <SummaryItem fullLine={false} />,
      bgColor: WHITE,
      hasSummaryText: false,
      isBolus: true,
      bolusValue: 10,
      index: 11,
      hasBolusCalcButton: true
    },
    {
      isExpanded: false,
      time: "11:32 am",
      index: 12,
      bgColor: PURPLE,
      hasSummaryText: true,
      startIcon: AUTOMATED_MODE_ICON,
      summaryText: `${languageContent.switchAutomated}`
    },
    {
      isExpanded: false,
      time: "11:16 am",
      index: 13,
      bgColor: BLUE,
      subItems: <BasalProgramInProgress />,
      hasSummaryText: true,
      startIcon: BASAL_PROGRAM_ICON,
      summaryText: `${languageContent.basalProgramStarted}`,
      subText: `${languageContent.basal1}`
    },
    {
      isExpanded: false,
      time: "11:16 am",
      subItems: (
        <>
          <View
            style={[
              styles.subItemWrapper,
              {
                marginLeft: 64,
                borderBottomWidth: 0.5,
                borderBottomColor: theme.colors.textInputBorder
              }
            ]}>
            <Text style={[styles.subItemText]}>{languageContent.lot}</Text>
          </View>
          <Text style={[styles.subItemText, { marginLeft: 64, padding: 16 }]}>
            {languageContent.sequence}
          </Text>
        </>
      ),
      index: 14,
      bgColor: LIGHT_BLUE,
      hasSummaryText: true,
      startIcon: POD_ICON,
      summaryText: `${languageContent.podActived}`
    },
    {
      isExpanded: false,
      time: "11:13 am",
      subItems: (
        <>
          <View
            style={[
              styles.subItemWrapper,
              {
                marginLeft: 64,
                borderBottomWidth: 0.5,
                borderBottomColor: theme.colors.textInputBorder
              }
            ]}>
            <Text style={[styles.subItemText]}>
              {replaceWithValues(languageContent.lastIOB, contentVariables)}
            </Text>
          </View>
          <View
            style={[
              styles.subItemWrapper,
              {
                marginLeft: 64,
                borderBottomWidth: 0.5,
                borderBottomColor: theme.colors.textInputBorder
              }
            ]}>
            <Text style={[styles.subItemText]}>{languageContent.lot}</Text>
          </View>
          <Text style={[styles.subItemText, { marginLeft: 64, padding: 16 }]}>
            {languageContent.sequence}
          </Text>
        </>
      ),
      index: 15,
      bgColor: LIGHT_BLUE,
      hasSummaryText: true,
      startIcon: POD_ICON,
      summaryText: `${languageContent.podDeactivated}`
    },
    {
      isExpanded: false,
      time: "12:00 am",
      index: 16,
      bgColor: BLUE,
      subItems: <BasalProgramInProgress />,
      hasSummaryText: true,
      startIcon: BASAL_PROGRAM_ICON,
      summaryText: `${languageContent.basalProgramContinued}`,
      subText: `${languageContent.basal1}`
    }
  ];
};

export default getHistoryDetailData;
