let strings = {
  "en-US": {
    tempBasalIntro: `When in Manual Mode, you can use a temporary basal rate, or "temp basal", to handle a temporary change in your routine. For example, a temp basal can be used when you are exercising or when you are sick.`
  },
  "en-GB": {
    tempBasalIntro: `When in Manual Mode, you can use a temporary basal rate, or "temp basal", to handle a temporary change in your routine. For example, a temp basal can be used when you are exercising or when you are sick.`
  },
  "de-DE": {
    tempBasalIntro: `Im Manuellen Modus können Sie eine temporäre Basalrate, oder temp. Basalrate, verwenden, um mit einer vorübergehenden Änderung Ihrer Routine umzugehen. So können Sie z. B. eine temporäre Basalrate verwenden, wenn Sie Sport treiben oder krank sind.`
  },
  "es-US": {
    tempBasalIntro: `Si está en Modo Manual puede usar la tasa basal temporal para manejar un cambio temporal en su rutina. Por ejemplo, la tasa basal temporal se puede usar cuando está practicando ejercicio o cuando está enfermo.`
  },
  "fr-FR": {
    tempBasalIntro: `Lorsque vous êtes en mode Manuel, vous pouvez utiliser un « débit basal temporaire » pour gérer un changement dans votre routine. Par exemple, un débit basal temporaire peut être utilisé lorsque vous faites de l'exercice ou que vous êtes malade.`
  },
  "nl-NL": {
    tempBasalIntro:
      "In de Handmatige Modus kan een tijdelijke basaalsnelheid worden gebruikt om een tijdelijke verandering in uw routine te ondervangen. Een tijdelijke basaalsnelheid kan worden toegepast als u sport of als u ziek bent."
  }
};

export { strings };
