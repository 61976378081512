import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BolusAutomated } from "../../../../../assets/bolus/bolus_button_auto.svg";
import { ReactComponent as BolusManual } from "../../../../../assets/bolus/bolus_button_manual.svg";
import { ReactComponent as Graph } from "../../../../../assets/Icon_Graph.svg";
import getCurrentHourFormat from "../../../helpers/getCurrentHourFormat";
import useCGM from "../../../hooks/useCGM";
import useLanguageContent from "../../../hooks/useLanguageContent";
import { BolusState } from "../../../../../web/store/reducers/bolus";
import { LangState } from "../../../../../web/store/reducers/language";
import {
  navigationActions,
  NavState
} from "../../../../../web/store/reducers/navigation";
import { UserState } from "../../../../../web/store/reducers/user";
import { RootState } from "../../../../../web/store/store";
import theme from "../../../theme";
import { DashboardDot } from "../../atoms";
import { BottomQuadSection } from "../../molecules";
import { strings } from "./BottomQuadsContent";
import { getUnitByLocale } from "../../../helpers/getUnitByLocale";

interface BottomQuadsProps {
  activeTab: number;
  lastBolusTime?: string;
  lastBolusDate?: string;
}

const BottomQuads: React.FC<BottomQuadsProps> = ({
  activeTab,
  lastBolusTime,
  lastBolusDate
}) => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { trendingIcon } = useCGM();
  const { languageContent } = useLanguageContent(strings);
  const dots = [{ index: 0 }, { index: 1 }, { index: 2 }];

  const getLastReading = React.useCallback(() => {
    const now = new Date(bolusState.timestamp);
    const date = now.toLocaleDateString(langState.locale, {
      month: "short",
      day: "2-digit"
    });
    const time = getCurrentHourFormat({
      date: now,
      is24Hour: langState.is24Hour
    });

    return `${date} (${time})`;
  }, []);

  React.useEffect(() => {
    getLastReading();
  }, [getLastReading]);

  const lastBolusBoxContent = (
    <View>
      <Text style={[styles.bolusNumber]}>{bolusState.lastBolus}</Text>
      <Text style={[styles.units]}>{languageContent.units}</Text>
    </View>
  );

  const lastBolusBottomContent = (
    <Text style={[styles.bolusDate]}>
      {`${lastBolusDate} (${getCurrentHourFormat({
        timeStr: lastBolusTime,
        is24Hour: langState.is24Hour
      })})`}
    </Text>
  );

  const cgmGraphBoxContent = (
    <View
      style={{
        justifyContent: "center"
      }}>
      <TouchableOpacity
        onPress={() => {
          navigate("Graph");
        }}>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <Graph height="60%" width="60%" />
        </View>
      </TouchableOpacity>
    </View>
  );

  const cgmGraphBottomContent = (
    <Text
      style={{
        color: userState.automatedMode
          ? theme.colors.purple
          : theme.colors.blue,
        fontWeight: "700",
        textAlign: "center",
        paddingTop: 8
      }}>
      {languageContent.view}
    </Text>
  );

  const cgmInfoBoxContent = (
    <View
      style={{
        flexDirection: "column",
        margin: -16
      }}>
      <View
        style={{
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "center",
          height: 40
        }}>
        <Text
          style={[
            styles.bigNumber,
            {
              color: userState.automatedMode
                ? theme.colors.purple
                : theme.colors.blue
            }
          ]}>
          {getUnitByLocale(bolusState.cgmInfo.Value, langState.units)}
        </Text>
        <>{!userState.limitedMode && trendingIcon}</>
      </View>
      <View>
        {userState.limitedMode ? (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: -16
            }}>
            <Text
              style={[
                styles.units,
                {
                  fontSize: 18,
                  color: theme.colors.grayScale.gray200,
                  fontWeight: "500"
                }
              ]}>
              {languageContent.searching}
            </Text>
          </View>
        ) : (
          <Text style={[styles.units]}>{langState.units}</Text>
        )}
      </View>
    </View>
  );

  const cgmInfoBottomContent = userState.limitedMode ? (
    <>
      <Text style={[styles.bolusDate, { paddingBottom: 0 }]}>
        {languageContent.lastReading}
      </Text>
      <Text style={[styles.bolusDate, { paddingVertical: 0 }]}>
        {getLastReading()}
      </Text>
    </>
  ) : (
    <TouchableOpacity
      onPress={() => {
        navigate("Graph");
      }}>
      <Text
        style={{
          color: userState.automatedMode
            ? theme.colors.purple
            : theme.colors.blue,
          fontWeight: "700",
          textAlign: "center",
          paddingTop: 8
        }}>
        {languageContent.viewGraph}
      </Text>
    </TouchableOpacity>
  );

  return (
    <View
      style={{
        flex: 0.9,
        backgroundColor: userState.limitedMode
          ? theme.colors.limitedMode
          : userState.automatedMode
          ? theme.colors.purple
          : "#ececec"
      }}>
      <View
        style={[
          styles.wrapper,
          {
            backgroundColor: userState.limitedMode
              ? theme.colors.limitedMode
              : userState.automatedMode
              ? theme.colors.purple
              : "#dfdfdf"
          }
        ]}>
        <View style={[styles.topLeftBlock]} />
        <View style={[styles.dotsWrapper]}>
          <View
            style={{
              paddingBottom: 2,
              flexDirection: "row",
              justifyContent: "space-evenly"
            }}>
            {dots.map((node) => (
              <DashboardDot
                activeTab={activeTab}
                index={node.index}
                key={node.index}
              />
            ))}
          </View>
        </View>
        <View style={styles.topRightBlock} />
      </View>

      <View style={styles.mainContentWrapper}>
        <View
          style={{
            flex: 2,
            flexDirection: "row",
            justifyContent: "center"
          }}>
          <View style={[styles.bottomQuadContainer, { marginBottom: 16 }]}>
            <BottomQuadSection
              title={languageContent.lastBolus}
              boxContent={lastBolusBoxContent}
              bottomContent={lastBolusBottomContent}
            />
          </View>

          <View style={[styles.divider]} />

          <View
            style={
              activeTab === 0
                ? [styles.bottomQuadContainer, { marginTop: -8 }]
                : [styles.bottomQuadContainer, { marginTop: 15 }]
            }>
            {activeTab === 0 ? (
              <BottomQuadSection
                title={languageContent.sensorGraph}
                boxContent={cgmGraphBoxContent}
                bottomContent={cgmGraphBottomContent}
              />
            ) : (
              <BottomQuadSection
                title={languageContent.sensorInfo}
                boxContent={cgmInfoBoxContent}
                bottomContent={cgmInfoBottomContent}
              />
            )}
          </View>
        </View>
        <View
          style={{
            flex: 0.5,
            justifyContent: "center",
            alignItems: "center",
            marginTop: -40
          }}>
          <TouchableOpacity
            onPress={() =>
              dispatch(
                navigationActions.navigationUpdate(
                  { navigateTo: "Bolus" },
                  navState
                )
              )
            }>
            {userState.automatedMode ? (
              <BolusAutomated style={styles.bolusFlowButtonAuto} />
            ) : (
              <BolusManual style={styles.bolusFlowButton} />
            )}
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 0.1,
    zIndex: 0,
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  mainContentWrapper: {
    flex: 2,
    zIndex: 2,
    width: "100%",
    backgroundColor: theme.colors.white
  },
  topLeftBlock: {
    flex: 0.5,
    backgroundColor: theme.colors.white,
    width: "42%",
    borderTopRightRadius: 6,
    marginBottom: "-40%"
  },
  topRightBlock: {
    flex: 0.5,
    backgroundColor: theme.colors.white,
    width: "42%",
    borderTopLeftRadius: 6
  },
  bolusNumber: {
    textAlign: "center",
    textAlignVertical: "center",
    fontFamily: theme.fonts.primary.bold,
    fontSize: 36
  },
  bottomQuadContainer: {
    flex: 1,
    alignItems: "center",
    marginTop: "5%",
    padding: 7
  },
  dotsWrapper: {
    alignSelf: "center",
    zIndex: 12,
    flex: 0.2
  },
  bigNumber: {
    fontSize: 36,
    fontWeight: "700",
    color: theme.colors.purple,
    textAlign: "center",
    marginRight: -16,
    marginLeft: 48,
    marginTop: -4
  },
  units: {
    fontFamily: theme.fonts.primary.medium,
    textAlign: "center"
  },
  bolusDate: {
    fontFamily: theme.fonts.primary.medium,
    color: "#4a4a4a",
    textAlignVertical: "center",
    textAlign: "center",
    paddingVertical: 8
  },
  bolusFlowButton: {
    marginTop: -12,
    width: 46,
    height: 46
  },
  bolusFlowButtonAuto: {
    marginTop: -12,
    width: 53,
    height: 53
  },
  divider: {
    width: 2,
    height: "70%",
    backgroundColor: "#eee",
    alignSelf: "center",
    top: -5
  }
});

export default BottomQuads;
