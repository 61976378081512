import { useNavigation, useRoute } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  BackHandler,
  Dimensions,
  Platform,
  PlatformIOSStatic,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import BOLUS_SEG_ONE_DATA from "../../../../../../data/bolusSeg1Data";
import BOLUS_SEG_ONE_DATA_MMOL from "../../../../../../data/bolusSeg1DataMmol";
import BOLUS_SEG_ONE_DATA_TWO from "../../../../../../data/bolusSeg1DataTwo";
import BOLUS_SEG_ONE_DATA_TWO_MMOL from "../../../../../../data/bolusSeg1DataTwoMmol";
import defaultBasalReduxData from "../../../../../../data/defaultBasalReduxData";
import findExpertBasalStartTimeIndex from "../../../../../../helpers/findExpertBasalStartTimeIndex";
import findExpertBasalStartTimeLabel from "../../../../../../helpers/findExpertBasalStartTimeLabel";
import getAdjustedDataForEndTimePicker from "../../../../../../helpers/getAdjustedDataForEndTimePicker";
import { getUnitByLocale } from "../../../../../../helpers/getUnitByLocale";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useExpertHardwareBackAction from "../../../../../../hooks/useExpertHardwareBackAction";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { bolusActions } from "../../../../../../../../web/store/reducers/bolus";
import { BolusState } from "../../../../../../../../web/store/reducers/bolus/reducer";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import PickerItem from "../../../../../../types/pickerItem";
import {
  BottomInnerNav,
  ConfirmationModal,
  SegmentTopBar
} from "../../../../../molecules";
import { StartAndEndTimeSection } from "../../../../../organisms";
import CustomPicker from "../../../../../organisms/customPicker";
import { strings } from "./ExpertBolusSegmentScreensContent";

interface Props {
  navigation: any;
}

const { width } = Dimensions.get("window");
const NOT_HIGHLIGHTED = "";
const TARGET_BG_FIELD = "targetBG";
const CORRECT_ABOVE_FIELD = "correctAbove";
const platform = Platform as PlatformIOSStatic;

const getPickerHorizontalPlacement = () => {
  if (platform.isPad) {
    if (width > 1000) {
      return -width / 5.2;
    } else {
      return -width / 4.3;
    }
  } else if (Platform.OS === "android") {
    if (width > 395) {
      return -width / 3.95;
    } else if (width > 380) {
      return -width / 3.25;
    } else {
      return -width / 3;
    }
  } else if (width > 395) {
    return -width / 3.55;
  } else if (width > 380) {
    return -width / 3.25;
  } else {
    return -width / 3.13;
  }
};

const getPickerVerticalPlacement = () => {
  if (platform.isPad) {
    if (width > 1000) {
      return "-15%";
    } else {
      return "-22%";
    }
  } else if (Platform.OS === "android") {
    if (width > 395) {
      return "-29.5%";
    } else if (width > 380) {
      return "-37.3%";
    } else {
      return "-39%";
    }
  } else if (width > 395) {
    return "-34%";
  } else if (width > 380) {
    return "-37.3%";
  } else {
    return "-39%";
  }
};

const pickerHorizontalMargin = getPickerHorizontalPlacement();
const pickerVerticalMargin = getPickerVerticalPlacement();

const ExpertBolusSegmentScreens: React.FC<Props> = ({ navigation }) => {
  //GLOBAL STATE
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const segment = bolusState.customFirstSegment;
  const segmentsArrLength = bolusState.newExpertBolusProgram.segments.length;

  let BOLUS_SEG_DATA: Array<PickerItem> = [];
  let BOLUS_SEG_DATA_TWO: Array<PickerItem> = [];
  if (langState.units === "mmol/L") {
    BOLUS_SEG_DATA = BOLUS_SEG_ONE_DATA_MMOL;
    BOLUS_SEG_DATA_TWO = BOLUS_SEG_ONE_DATA_TWO_MMOL;
  } else {
    BOLUS_SEG_DATA = BOLUS_SEG_ONE_DATA;
    BOLUS_SEG_DATA_TWO = BOLUS_SEG_ONE_DATA_TWO;
  }
  //Putting at top b/c state has to use this
  const { languageContent } = useLanguageContent(strings);

  const tGlowerRange = getUnitByLocale(110, langState.units, langState.locale);
  const tGhigherRange = `${getUnitByLocale(
    150,
    langState.units,
    langState.locale
  )} ${langState.units}`;
  const correctAboveHigherRange = `${getUnitByLocale(
    200,
    langState.units,
    langState.locale
  )} ${langState.units}`;
  const contentVariables = [
    tGlowerRange,
    tGhigherRange,
    correctAboveHigherRange
  ];

  //LOCAL STATE
  const [fieldValues, setFieldValues] = React.useState({
    targetBG: 0,
    correctAbove: 0
  });
  const [disablePicker, setDisablePicker] = React.useState({
    targetBG: true,
    correctAbove: true
  });

  const [cancelModal, setCancelModal] = React.useState(false);

  const [startTime, setStartTime] = React.useState("12:00 am");
  const [endTime, setEndTime] = React.useState({
    label: "",
    helpText: languageContent.endTimeHelpText
  });
  const [endTimeIndex, setEndTimeIndex] = React.useState<number>(0);

  const [showEndTimePicker, setShowEndTimePicker] =
    React.useState<boolean>(false);

  // EndTime Picker State
  const [adjustedEndTimeData, setAdjustedEndTimeData] = React.useState<any[]>();

  const [background, setBackground] = React.useState<string>("");

  const [rerenderPicker, setRerenderPicker] = React.useState<boolean>(false);

  const [targetBGSelected, setTargetBGSelected] = React.useState<string>("");
  const [correctAboveSelected, setCorrectAboveSelected] =
    React.useState<string>("");

  const [segmentIndex, setSegmentIndex] = React.useState<number>(
    bolusState.newExpertBolusProgram.segments.length + 1
  );

  const [initialRender, setInitialRender] = React.useState<boolean>(true);

  const [borderColor, setBorderColor] = React.useState(
    theme.colors.textInputBorder
  );
  const [showBolusRatePicker, setShowBolusRatePicker] =
    React.useState<boolean>();

  //second picker starting index
  const [adjustedBolusSegData, setAdjustedBolusSegData] =
    React.useState<any[]>();

  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState<boolean>(false);

  const [showTargetBGPicker, setShowTargetBGPicker] =
    React.useState<boolean>(false);
  const [showCorrectAbovePicker, setShowCorrectAbovePicker] =
    React.useState<boolean>(false);

  //HOOKS
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const route = useRoute<any>();
  const backAction = useExpertHardwareBackAction(navigation, "BolusTG");

  //FUNCTIONS

  const getTargetBGAdornmentColor = () => {
    if (fieldValues.targetBG === 0) {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  const getCorrectAboveAdornmentColor = () => {
    if (fieldValues.correctAbove === 0) {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  const handleNext = () => {
    if (
      endTime.label === "" ||
      fieldValues.correctAbove === 0 ||
      fieldValues.targetBG === 0
    ) {
      return;
    }

    dispatch(
      bolusActions.bolusUpdate(
        {
          newExpertBolusProgram: {
            ...bolusState.newExpertBolusProgram,
            hasUpdate: false,
            segments: [
              ...bolusState.newExpertBolusProgram.segments,
              {
                ...segment,
                start: startTime,
                end: endTime.label.toLowerCase(),
                endHelpText: endTime.helpText,
                startTimeHelpText: endTime.helpText,
                targetBG: fieldValues.targetBG,
                correctAbove: fieldValues.correctAbove
              }
            ]
          }
        },
        bolusState
      )
    );

    if (endTime.label && endTime.label === "12:00 AM") {
      return setShowConfirmationModal(true);
    }

    return navigation.push("BolusSegments");
  };

  const toggleBolusRatePicker = () => {
    if (borderColor === theme.colors.blue) {
      setBorderColor(theme.colors.textInputBorder);
      setShowBolusRatePicker(false);
    } else {
      setBorderColor(theme.colors.blue);
      setShowBolusRatePicker(true);
      setShowEndTimePicker(false);
    }
  };

  const toggleEndTimePicker = () => {
    setShowEndTimePicker((showEndTimePicker) => !showEndTimePicker);
    if (showBolusRatePicker) {
      toggleBolusRatePicker();
    }
  };

  const endTimePickerUpdate = (value: string) => {
    setShowEndTimePicker(false);
    const selected = adjustedEndTimeData?.find(
      (adjustedEndTimeData) => adjustedEndTimeData.label === value
    );
    setEndTime({
      label: selected.label.toUpperCase(),
      helpText: selected.helpText
    });

    setEndTimeIndex(selected.index);
    setDisablePicker({ ...disablePicker, targetBG: false });

    if (!bolusState.newExpertBolusProgram.hasUpdate) {
      dispatch(
        bolusActions.bolusUpdate(
          {
            newExpertBolusProgram: {
              ...bolusState.newExpertBolusProgram,
              hasUpdate: true
            }
          },
          bolusState
        )
      );
    }
  };

  // set selected value for correct above bg
  const onTargetBGConfirm = (value: string) => {
    const selected = BOLUS_SEG_DATA.find(
      (BOLUS_SEG_DATA) => BOLUS_SEG_DATA.label === value
    );

    if (selected) {
      if (
        selected.value > fieldValues.correctAbove &&
        fieldValues.correctAbove !== 0
      ) {
        setFieldValues({
          correctAbove: selected.value,
          targetBG: selected!.value
        });
        setTargetBGSelected(selected.label);
      } else {
        setFieldValues({
          ...fieldValues,
          targetBG: selected.value
        });
        setTargetBGSelected(selected.label);
      }

      adjBolusDataForSecondPicker();
      setBackground(NOT_HIGHLIGHTED);
      setShowTargetBGPicker(false);
      setDisablePicker({ ...disablePicker, correctAbove: false });
    }
  };

  const onTargetBGDismiss = () => {
    setShowTargetBGPicker(false);

    setBackground(NOT_HIGHLIGHTED);
  };

  const onCorrectAboveDismiss = () => {
    setShowCorrectAbovePicker(false);
    setBackground(NOT_HIGHLIGHTED);
  };

  const togglePickers = (fieldName: string) => {
    if (fieldName === TARGET_BG_FIELD) {
      setShowTargetBGPicker(true);
    } else {
      setShowCorrectAbovePicker(true);
    }
  };

  const onCorrectAboveConfirm = (value: string) => {
    const selected = BOLUS_SEG_DATA_TWO.find(
      (BOLUS_SEG_DATA_TWO) => BOLUS_SEG_DATA_TWO.label === value
    );
    setFieldValues({ ...fieldValues, correctAbove: selected!.value });
    setCorrectAboveSelected(selected!.label);
    setBackground(NOT_HIGHLIGHTED);
    setShowCorrectAbovePicker(false);
  };

  //LIFECYCLE

  const setPickerDynamicStartTime = React.useCallback(() => {
    let adjustedStartTime = "";
    if (
      startTime &&
      startTime.toLowerCase() === "12:00 am" &&
      segmentsArrLength === 0
    ) {
      adjustedStartTime = "12:30 am";
    } else if (segmentsArrLength === 0) {
      adjustedStartTime = "12:30 am";
      setStartTime("12:00 am");
    } else {
      // findExpertBasalStartTimeIndex: find the index of previous segment end time in the data set of hours
      // findExpertBasalStartTimeLabel: find 30 minutes past where the index is, use that as the base value for the picker
      adjustedStartTime = findExpertBasalStartTimeLabel(
        findExpertBasalStartTimeIndex(
          bolusState.newExpertBolusProgram.segments[
            segmentsArrLength - 1
          ].end.toUpperCase()
        )
      );
    }

    setAdjustedEndTimeData(
      getAdjustedDataForEndTimePicker(adjustedStartTime.toUpperCase())
    );
  }, [bolusState.newExpertBolusProgram.segments]);

  const adjBolusDataForSecondPicker = React.useCallback(() => {
    const baseValue =
      fieldValues.targetBG < getUnitByLocale(110, langState.units)
        ? getUnitByLocale(110, langState.units)
        : fieldValues.targetBG;
    let adjDataArray: any = [];
    BOLUS_SEG_DATA_TWO.map((item) => {
      if (item.label === "" || item.value >= baseValue) {
        adjDataArray.push(item);
      }
    });
    setAdjustedBolusSegData(adjDataArray);
  }, [fieldValues.targetBG]);

  React.useEffect(() => {
    if (rerenderPicker) {
      setRerenderPicker(false);
    }
  }, [rerenderPicker]);

  React.useEffect(() => {
    adjBolusDataForSecondPicker();
  }, [fieldValues.targetBG, adjBolusDataForSecondPicker]);

  React.useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", backAction);
    setPickerDynamicStartTime();

    setSegmentIndex(segmentsArrLength + 1);

    if (
      endTime.label.toLowerCase() !== "12:00 am" &&
      endTime.label.toLowerCase() !== "00:00" &&
      segmentsArrLength > 0
    ) {
      setStartTime(
        bolusState.newExpertBolusProgram.segments[segmentsArrLength - 1].end
      );
    }

    if (
      bolusState.newExpertBolusProgram.isComplete &&
      route.params &&
      route.params.editEndTime
    ) {
      if (initialRender) {
        let helpText;
        if (segmentsArrLength === 0 && route.params.editEndHelpText) {
          helpText = route.params.editEndHelpText;
        } else {
          helpText =
            bolusState.newExpertBolusProgram.segments[segmentsArrLength - 1]
              .startTimeHelpText!;
        }
        setEndTime({
          label: route.params.editEndTime,
          helpText
        });
      }
    }
    setInitialRender(false);

    return () => {
      BackHandler.removeEventListener("hardwareBackPress", backAction);
    };
  }, [
    bolusState.newExpertBolusProgram.isComplete,
    bolusState.newExpertBolusProgram.segments,
    segmentsArrLength,
    route.params,
    initialRender,
    setPickerDynamicStartTime,
    navigation
  ]);

  React.useEffect(
    () =>
      navigation.addListener("beforeRemove", () => {
        dispatch(
          bolusActions.bolusUpdate(
            {
              newExpertBolusProgram: {
                ...bolusState.newExpertBolusProgram,
                segments: bolusState.newExpertBolusProgram.segments.slice(
                  0,
                  bolusState.newExpertBolusProgram.segments.length - 1
                )
              }
            },
            bolusState
          )
        );
      }),
    [navigation]
  );

  //RETURN

  return (
    <>
      <View style={[styles.container]}>
        <ScrollView>
          <View>
            <SegmentTopBar
              segmentNum={segmentIndex.toString()}
              programName={bolusState.newExpertBolusProgram?.programName}
            />
            <View style={[styles.break, { marginBottom: 16 }]} />
            <View style={[{ paddingVertical: 16 }]}>
              <StartAndEndTimeSection
                is24Hour={langState.is24Hour}
                locale={langState.locale}
                startTime={startTime}
                endTime={endTime}
                segmentsArrLength={segmentsArrLength}
                routeParams={route.params}
                endTimeIndex={endTimeIndex}
                adjustedEndTimeData={adjustedEndTimeData}
                showEndTimePicker={showEndTimePicker}
                toggleEndTimePicker={toggleEndTimePicker}
                onConfirm={endTimePickerUpdate}
                onDismiss={() => setShowEndTimePicker(false)}
                bolus
              />
              <View
                style={[
                  {
                    marginTop: 8,
                    zIndex: -1,
                    height: 140,
                    backgroundColor:
                      background === TARGET_BG_FIELD ? "#EEF2F6" : "transparent"
                  }
                ]}>
                <View
                  style={[
                    { marginTop: 8, zIndex: 200, paddingHorizontal: 16 }
                  ]}>
                  <Text style={[theme.fonts.h4]}>
                    {languageContent.targetGlucose}
                  </Text>
                  <Text
                    style={[
                      theme.fonts.body1,
                      { color: theme.colors.text.cancel, marginBottom: 8 }
                    ]}>
                    {replaceWithValues(
                      languageContent.tGrange,
                      contentVariables
                    )}
                  </Text>
                </View>
                {/* target glucose picker */}
                <>
                  <TouchableOpacity
                    style={[
                      theme.layout.inputContainerStyle,
                      {
                        marginHorizontal: 16,
                        paddingLeft: 8,
                        height: 60,
                        paddingBottom: 2
                      }
                    ]}
                    activeOpacity={1}
                    disabled={disablePicker.targetBG}
                    onPress={() => togglePickers(TARGET_BG_FIELD)}>
                    {fieldValues.targetBG === -1 ? (
                      <TextInput
                        style={[
                          theme.layout.inputStyle,
                          {
                            color: theme.colors.placeholder,
                            fontSize: 40
                          }
                        ]}
                        selectionColor={"transparent"}
                        placeholder={"––"}
                        onFocus={() => togglePickers(TARGET_BG_FIELD)}
                        showSoftInputOnFocus={false}
                      />
                    ) : (
                      <TextInput
                        style={[
                          theme.layout.inputStyle,
                          {
                            color: "#000",
                            fontSize: 40
                          }
                        ]}
                        selectionColor={"transparent"}
                        placeholder={"––"}
                        placeholderTextColor={theme.colors.placeholder}
                        value={
                          fieldValues.targetBG === 0
                            ? ""
                            : fieldValues.targetBG.toLocaleString(
                                langState.locale
                              )
                        }
                        onFocus={() => togglePickers(TARGET_BG_FIELD)}
                        showSoftInputOnFocus={false}
                      />
                    )}
                    <Text
                      style={[
                        theme.layout.adornment,
                        {
                          color: getTargetBGAdornmentColor()
                        }
                      ]}>
                      {langState.units}
                    </Text>
                  </TouchableOpacity>
                </>
                <CustomPicker
                  is24Hour={langState.is24Hour}
                  locale={langState.locale}
                  title={languageContent.targetGlucose}
                  values={BOLUS_SEG_DATA}
                  isVisible={showTargetBGPicker}
                  onDismiss={onTargetBGDismiss}
                  onConfirm={(value) => onTargetBGConfirm(value)}
                  units={langState.units}
                  selectedValue={targetBGSelected}
                />
              </View>
              {/* Correct Above */}

              <View
                style={[
                  {
                    paddingBottom: 16,
                    zIndex: -1,
                    height: 200
                  }
                ]}>
                <View
                  style={{
                    height: 140,
                    backgroundColor:
                      background === CORRECT_ABOVE_FIELD
                        ? "#EEF2F6"
                        : "transparent"
                  }}>
                  <View
                    style={[
                      {
                        marginTop: 16,
                        paddingHorizontal: 16
                      }
                    ]}>
                    <Text style={[theme.fonts.h4]}>
                      {languageContent.correctAbove}
                    </Text>
                    <Text
                      style={[
                        theme.fonts.body1,
                        { color: theme.colors.text.cancel, marginBottom: 8 }
                      ]}>
                      {replaceWithValues(
                        languageContent.correctAboveRange,
                        contentVariables
                      )}
                    </Text>
                  </View>
                  <View>
                    <TouchableOpacity
                      style={[
                        theme.layout.inputContainerStyle,
                        {
                          marginHorizontal: 16,
                          paddingLeft: 8,
                          height: 60,
                          paddingBottom: 2
                        }
                      ]}
                      disabled={disablePicker.correctAbove}
                      activeOpacity={1}
                      onPress={() => togglePickers(CORRECT_ABOVE_FIELD)}>
                      {fieldValues.targetBG === -1 ? (
                        <TextInput
                          style={[
                            theme.layout.inputStyle,
                            {
                              color: theme.colors.placeholder,
                              fontSize: 40
                            }
                          ]}
                          selectionColor={"transparent"}
                          placeholder={"––"}
                          onFocus={() => togglePickers(CORRECT_ABOVE_FIELD)}
                          showSoftInputOnFocus={false}
                        />
                      ) : (
                        <TextInput
                          style={[
                            theme.layout.inputStyle,
                            {
                              color: "#000",
                              fontSize: 40
                            }
                          ]}
                          selectionColor={"transparent"}
                          placeholder={"––"}
                          placeholderTextColor={theme.colors.placeholder}
                          value={
                            fieldValues.correctAbove === 0
                              ? ""
                              : fieldValues.correctAbove.toLocaleString(
                                  langState.locale
                                )
                          }
                          onFocus={() => togglePickers(CORRECT_ABOVE_FIELD)}
                          showSoftInputOnFocus={false}
                        />
                      )}
                      <Text
                        style={[
                          theme.layout.adornment,
                          {
                            color: getCorrectAboveAdornmentColor()
                          }
                        ]}>
                        {langState.units}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View>
                    <CustomPicker
                      is24Hour={langState.is24Hour}
                      locale={langState.locale}
                      title={languageContent.correctAbove}
                      values={adjustedBolusSegData!}
                      isVisible={showCorrectAbovePicker}
                      onDismiss={onCorrectAboveDismiss}
                      onConfirm={(value: string) =>
                        onCorrectAboveConfirm(value)
                      }
                      units={langState.units}
                      selectedValue={correctAboveSelected}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        <BottomInnerNav
          leftActionStyle={{ color: theme.colors.text.cancel }}
          leftActionText={languageContent.cancel}
          rightActionText={languageContent.next}
          leftAction
          leftNavigationAction={() => setCancelModal(true)}
          rightAction={handleNext}
          rightActionStyle={{
            color:
              fieldValues.targetBG < getUnitByLocale(110, langState.units) ||
              fieldValues.correctAbove <
                getUnitByLocale(110, langState.units) ||
              fieldValues.correctAbove < fieldValues.targetBG ||
              !endTime
                ? theme.colors.placeholder
                : theme.colors.purple
          }}
        />
      </View>
      <View>
        {showConfirmationModal ? (
          <ConfirmationModal
            isVisible={true}
            title={languageContent.confirmModalTitle}
            message={
              <View>
                <Text style={[theme.fonts.body1]}>
                  {languageContent.confirmModalBody}
                </Text>
              </View>
            }
            hideDismiss
            confirmText={languageContent.continue}
            onConfirm={() => {
              dispatch(
                bolusActions.bolusUpdate(
                  {
                    newExpertBolusProgram: {
                      ...bolusState.newExpertBolusProgram,
                      isComplete: false
                    }
                  },
                  bolusState
                )
              );

              setShowConfirmationModal(false);
              navigate("ReviewBolus");
            }}
          />
        ) : null}
      </View>
      <ConfirmationModal
        title={languageContent.cancelModalTitle}
        isVisible={cancelModal}
        onDismiss={() => setCancelModal(false)}
        onConfirm={() => {
          setCancelModal(false);
          dispatch(
            bolusActions.bolusUpdate(
              {
                newExpertBolusProgram: defaultBasalReduxData
              },
              bolusState
            )
          );
          navigation.navigate("IntroBolusFTS");
        }}
        message={
          <View>
            <Text style={[theme.fonts.body1, { marginBottom: 8 }]}>
              {languageContent.cancelModalBody}
            </Text>
          </View>
        }
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  periodText: {
    color: theme.colors.text.cancel,
    fontSize: 18
  },
  androidPickerWrapper: {
    flex: 2,
    zIndex: 20,
    marginTop: pickerVerticalMargin,
    marginLeft: pickerHorizontalMargin,
    height: 200,
    marginBottom: -56
  }
});

export default ExpertBolusSegmentScreens;
