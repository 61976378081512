import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../FtsStartScreenStack.messages';

export const TermsConditionsScreen = () => {
  return (
    <div
      className="flex justify-between px-2 flex-col flex-1 items-stretch bg-white"
      style={{ overflow: 'scroll' }}
    >
      <div className="flex flex-col">
        <div className="px-2 mt-2">
          <div className="text-black text-sm">
            <FormattedMessage
              {...messages.insuletTermsMessage}
              values={{ separator: <br /> }}
            />
          </div>
          <div className="mt-2 text-sm">
            <FormattedMessage {...messages.termsConditionsMessage} />
          </div>
          <div className="mt-2 ml-3">
            <div className="mt-1 text-ios-purple">
              <FormattedMessage {...messages.privacyPolicyTitle} />
            </div>
            <div className="mt-1 text-ios-purple">
              <FormattedMessage {...messages.hippaPrivacyNoticeTitle} />
            </div>
            <div className="mt-1 text-ios-purple">
              <FormattedMessage {...messages.endUserLicenseAgreementTitle} />
            </div>
            <div className="mt-1 text-ios-purple">
              <FormattedMessage {...messages.termsOfUseTitle} />
            </div>
            <div className="mt-1 text-ios-purple">
              <FormattedMessage {...messages.limitedExpressWarrantyTitle} />
            </div>
            <div className="mt-1 text-ios-purple">
              <FormattedMessage {...messages.legalNoticesTitle} />
            </div>
          </div>
          <div className="mt-3">
            <div className="font-bold">
              <FormattedMessage {...messages.privacyPolicyTitle} />
            </div>
            <div className="mt-2">
              <FormattedMessage {...messages.privacyPolicyMessage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
