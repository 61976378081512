import { createSlice } from '@reduxjs/toolkit';

const configuratorSlice = createSlice({
  name: 'configurator',
  initialState: {
    country: 'United States',
    device: '',
    language: '',
    version: '',
    loadedConfig: false,
  },
  reducers: {
    setOptions: (state, action) => {
      const { country, device, language, version } = action.payload;
      state.country = country;
      state.device = device;
      state.language = language;
      state.version = version;
      state.loadedConfig = true;
    }
  },
});

export const { setOptions } = configuratorSlice.actions;
export default configuratorSlice.reducer;
