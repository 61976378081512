let strings = {
  'en-US': {
    security: 'Omnipod\u00A05 Security',
    on: 'ON',
    timeZone: 'Time Zone and Language',
    insulinDelivery: 'Insulin Delivery Time Zone',
    newYork: 'New York',
    podDelivers: 'Your Pod delivers insulin according to this time zone.',
    deviceTimeZoneTitle: 'Device Time Zone',
    deviceTimeZoneSubTitle: 'Eastern Daylight Time',
    automaticTimeZone: 'Automatic time zone ON',
    diagnostics: 'Diagnostics',
    checkAlarms: 'Check Alarms',
    check: 'Check Omnipod\u00A05 app and Pod alarms',
    reset: 'Reset',
    clearAllData: 'Clear all data',
    ok: 'Ok',
    language: 'Language',
  },
  'en-GB': {
    security: 'Omnipod\u00A05 Security',
    on: 'ON',
    timeZone: 'Time Zone and Language',
    insulinDelivery: 'Insulin Delivery Time Zone',
    newYork: 'New York',
    podDelivers: 'Your Pod delivers insulin according to this time zone.',
    deviceTimeZoneTitle: 'Device Time Zone',
    deviceTimeZoneSubTitle: 'Eastern Daylight Time',
    automaticTimeZone: 'Automatic time zone ON',
    diagnostics: 'Diagnostics',
    checkAlarms: 'Check Alarms',
    check: 'Check Omnipod\u00A05 app and Pod alarms',
    reset: 'Reset',
    clearAllData: 'Clear all data',
    ok: 'Ok',
    language: 'Language',
  },
  'es-US': {
    security: 'Seguridad del Omnipod 5',
    on: 'PRENDIDO',
    timeZone: 'Zona horaria e idioma',
    insulinDelivery: 'Zona horaria de administración de insulina',
    newYork: 'Nueva York',
    podDelivers: 'Su Pod administra insulina según esta zona horaria.',
    deviceTimeZoneTitle: 'Zona horaria del dispositivo',
    deviceTimeZoneSubTitle: 'hora estándar oriental',
    automaticTimeZone: 'Zona horaria automática Prendido',
    diagnostics: 'Diagnóstico',
    checkAlarms: 'Comprobar alarmas',
    check: 'Comprobar las alarmas de la aplicación Omnipod 5 y el Pod',
    reset: 'Reiniciar',
    clearAllData: 'Borrar todos los datos',
    modalTitle: 'General',
    modalBody: 'No está disponible en el simulador.',
    ok: 'Aceptar',
    language: 'Idioma',
  },
  'de-DE': {
    security: 'Omnipod 5-Sicherheit',
    on: 'EINSCHALTEN',
    timeZone: 'Zeitzone und Sprache',
    insulinDelivery: 'Zeitzone der Insulinabgabe',
    newYork: 'New York',
    podDelivers: 'Ihr Pod gibt Insulin entsprechend dieser Zeitzone ab.',
    deviceTimeZoneTitle: 'Zeitzone des Geräts',
    deviceTimeZoneSubTitle: 'Eastern Daylight Time',
    automaticTimeZone: 'Automatische Zeitzone EIN',
    diagnostics: 'Diagnose',
    checkAlarms: 'Alarme prüfen',
    check: 'Omnipod 5 App- und Pod-Alarme prüfen',
    reset: 'Zurücksetzen',
    clearAllData: 'Alle Daten löschen',
    modalTitle: 'Allgemein',
    modalBody: 'Nicht für den Simulator verfügbar.',
    ok: 'OK',
    language: 'Sprache',
  },
  'nl-NL': {
    security: 'Beveiliging Omnipod 5',
    on: 'AAN',
    timeZone: 'Tijdzone en taal',
    insulinDelivery: 'Tijdzone insulinetoediening',
    newYork: 'New York',
    podDelivers:
      'Uw Pod gaat uit van dee tijdzone voor het toedienen van insuline.',
    deviceTimeZoneTitle: 'Tijdzone apparaat',
    deviceTimeZoneSubTitle: 'Pacific-standaardtijd',
    automaticTimeZone: 'Automatische tijdzone Aan',
    diagnostics: 'Diagnose',
    checkAlarms: 'Alarmen controleren',
    check: 'Omnipod 5 App- en Pod-alarmen controleren',
    reset: 'Resetten',
    clearAllData: 'Alle gegevens wissen',
    ok: 'Ok',
    language: 'Taal',
  },
  'fr-FR': {
    security: 'Omnipod 5 Sécurité',
    on: 'Activé',
    timeZone: 'Fuseau horaire et langue',
    insulinDelivery: "Fuseau horaire pour l'administration de l'insuline",
    newYork: 'New York',
    podDelivers:
      "Votre Pod administre de l'insuline en fonction de ce fuseau horaire.",
    deviceTimeZoneTitle: "Fuseau horaire de l'appareil",
    deviceTimeZoneSubTitle: 'Eastern Daylight Time',
    automaticTimeZone: 'Fuso orario automatico Activé',
    diagnostics: 'Diagnostic',
    checkAlarms: 'Vérifier les alarmes',
    check: "Vérifier les alarmes du Pod et de l'application Omnipod 5",
    reset: 'Réinitialiser',
    clearAllData: 'Effacer toutes les données',
    ok: 'Ok',
    language: 'Langue',
  },
};
export { strings };
