let strings = {
  "en-US": {
    historyDetailModalTitle: "History Detail",
    historyDetailModalMessage:
      "The Omnipod\u00A05 System will store your history records up to 90 days. The History Summary section will demonstrate Sensor data along with records of insulin delivery and carb intake.",
    ok: "OK",
    today: "Today"
  },
  "en-GB": {
    historyDetailModalTitle: "History Detail",
    historyDetailModalMessage:
      "The Omnipod\u00A05 System will store your history records up to 90 days. The History Summary section will demonstrate Sensor data along with records of insulin delivery and carb intake.",
    ok: "OK",
    today: "Today"
  },
  "de-DE": {
    historyDetailModalTitle: "Verlaufsdetail",
    historyDetailModalMessage:
      "Das Omnipod 5-System speichert Ihre Verlaufsdaten bis zu 90 Tage. Im Abschnitt „Verlaufsübersicht“ werden die Sensordaten zusammen mit den Aufzeichnungen über die Insulinabgabe und die Kohlenhydrataufnahme angezeigt.",
    ok: "OK",
    today: "Heute"
  },
  "es-US": {
    historyDetailModalTitle: "Detalle del Historial",
    historyDetailModalMessage:
      "El sistema Omnipod 5 guardará sus registros históricos hasta por 90 días. La sección Resumen del historial mostrará los datos del sensor y los registros de administración de insulina e ingesta de carbohidratos.",
    ok: "Aceptar",
    today: "Hoy"
  },
  "fr-FR": {
    historyDetailModalTitle: "Détails de l’historique",
    historyDetailModalMessage:
      "Le système Omnipod 5 conservera vos enregistrements d'historique jusqu'à 90 jours. La section Résumé de l'historique présentera les données du capteur ainsi que les enregistrements de l'administration d'insuline et de la consommation de glucides.",
    ok: "OK",
    today: "Aujourd'hui"
  },
  "nl-NL": {
    historyDetailModalTitle: "Detail geschiedenis",
    historyDetailModalMessage:
      "Het Omnipod 5-systeem bewaart uw geschiedenis gedurende maximaal 90 dagen. Het gedeelte Samenvatting toont de sensorgegevens en de gegevens over de insulinetoediening en de koolhydraatinname.",
    ok: "OK",
    today: "Vandaag"
  }
};

export { strings };
