let strings = {
  "en-US": {
    current: "Current (No Active Pod)",
    edit: "Edit",
    start: "Start",
    delete: "Delete",
    totalUDay: "Total: 22 U/day",
    savedBasal: "Saved Basal Programs",
    weekend: "Weekend",
    editModalTitle: "Edit a Basal Program",
    editModalMessage:
      "We will walk you through editing an existing basal program. Select the one called %1",
    createNewModalTitle: "Create New Basal Program",
    deleteModalTitle: "Delete Basal Program",
    editModalTitleTwo: "Edit Basal Program",
    startModalTitle: "Start Basal Program",
    notAvailable: "Not available for the simulator.",
    pleaseModalTitle: "Please select Relax Day",
    pleaseModalMessage:
      "Basal program editing is limited to Relax Day for this demonstration.",
    tourModalTitle: "Tour basal editing again",
    tourModalBody:
      "This feature is limited for simulation purposes. Would you like to reset the basal program to experience segment editing again?",
    cancel: "Cancel",
    confirmText: "Reset",
    createNew: "Create New",
    ok: "OK",
    relax: "Relax Day"
  },
  "en-GB": {
    current: "Current (No Active Pod)",
    edit: "Edit",
    start: "Start",
    delete: "Delete",
    totalUDay: "Total: 22 U/day",
    savedBasal: "Saved Basal Programmes",
    weekend: "Weekend",
    editModalTitle: "Edit a Basal Programme",
    editModalMessage:
      "We will walk you through editing an existing basal programme. Select the one called %1",
    createNewModalTitle: "Create New Basal Programme",
    deleteModalTitle: "Delete Basal Programme",
    editModalTitleTwo: "Edit Basal Programme",
    startModalTitle: "Start Basal Programme",
    notAvailable: "Not available for the simulator.",
    pleaseModalTitle: "Please select Relax Day",
    pleaseModalMessage:
      "Basal programme editing is limited to Relax Day for this demonstration.",
    tourModalTitle: "Tour basal editing again",
    tourModalBody:
      "This feature is limited for simulation purposes. Would you like to reset the basal programme to experience segment editing again?",
    cancel: "Cancel",
    confirmText: "Reset",
    createNew: "Create New",
    ok: "OK",
    relax: "Relax Day"
  },
  "de-DE": {
    current: "Aktuell (kein aktiver Pod)",
    edit: "Bearbeiten",
    start: "Start",
    delete: "Löschen",
    totalUDay: "Gesamt: 22 E/Tag",
    savedBasal: "Gespeicherte Basalratenprofile",
    weekend: "Wochenende",
    editModalTitle: "Ein Basalratenprofil bearbeiten",
    editModalMessage:
      "Wir führen Sie durch ein bestehendes Basalrateprofil. Wählen Sie das Profil mit dem Namen %1 aus",
    createNewModalTitle: "Neues Basalratenprofil erstellen",
    deleteModalTitle: "Basalratenprofil löschen",
    editModalTitleTwo: "Basalratenprofil bearbeiten",
    startModalTitle: "Basalratenprofil starten",
    notAvailable: "Nicht für den Simulator verfügbar.",
    pleaseModalTitle: "Bitte wählen Sie Entspannungstag.",
    pleaseModalMessage:
      "Die Bearbeitung von Basalratenprofilen ist für diese Demo auf Entspannungstag beschränkt.",
    tourModalTitle: "Tour zur Basalratenprofilbearbeitung erneut durchführen",
    tourModalBody:
      "Diese Funktion ist zu Simulationszwecken eingeschränkt. Möchten Sie das Basalratenprofil zurücksetzen, um die Segmentbearbeitung erneut durchzuführen?",
    cancel: "Abbr.",
    confirmText: "Zurücksetzen",
    createNew: "Neu erstellen",
    ok: "OK",
    relax: "Entspannungstag"
  },
  "es-US": {
    current: "Actual (No hay Pod activo)",
    edit: "Editar",
    start: "Iniciar",
    delete: "Eliminar",
    totalUDay: "Total: 22 U/día",
    savedBasal: "Programas basales guardados",
    weekend: "Fin de semana",
    editModalTitle: "Editar un programa basal",
    editModalMessage:
      "Lo guiaremos por la edición de un programa basal existente. Seleccione el de nombre %1",
    createNewModalTitle: "Crear un programa basal nuevo",
    deleteModalTitle: "Eliminar un programa basal",
    editModalTitleTwo: "Editar un programa basal",
    startModalTitle: "Empezar un programa basal",
    notAvailable: "No está disponible en el simulador.",
    pleaseModalTitle: "Seleccione Día de Relajación",
    pleaseModalMessage:
      "La edición del programa basal se limita al Día de Relajación para esta demostración.",
    tourModalTitle: "Repita la edición de basal",
    tourModalBody:
      "Esta función está limitada para fines de simulación. ¿Le gustaría reiniciar el programa basal para experimentar de nuevo la edición de segmentos?",
    cancel: "Cancelar",
    confirmText: "Reiniciar",
    createNew: "Crear nuevo",
    ok: "Aceptar",
    relax: "Día de Relajación"
  },
  "fr-FR": {
    current: "Actuel (aucun Pod actif)",
    edit: "Modifier",
    start: "Démarrer",
    delete: "Supprimer",
    totalUDay: "Total : 22 U/jour",
    savedBasal: "Programmes basaux enregistrés",
    weekend: "Week-end",
    editModalTitle: "Modifier un programme basal",
    editModalMessage:
      "Nous vous guiderons tout au long de la modification d'un programme basal existant. Sélectionner celui appelé %1",
    createNewModalTitle: "Créer un nouveau programme basal",
    deleteModalTitle: "Supprimer un programme basal",
    editModalTitleTwo: "Modifier le programme basal",
    startModalTitle: "Démarrer un programme basal",
    notAvailable: "Non disponible pour le simulateur.",
    pleaseModalTitle: "Veuillez sélectionner Jour de détente",
    pleaseModalMessage:
      "La modification du programme basal se limite au Jour de détente pour cette démonstration.",
    tourModalTitle: "Consulter à nouveau la modification d'un programme basal",
    tourModalBody:
      "Cette fonction est utilisée à des fins de simulation uniquement. Souhaitez-vous réinitialiser le programme basal pour procéder à nouveau à la modification de segments ?",
    cancel: "Annuler",
    confirmText: "Réinitialiser",
    createNew: "Créer nouveau",
    ok: "OK"
  },
  "nl-NL": {
    current: "Huidig (geen actieve Pod)",
    edit: "Bewerken",
    start: "Start",
    delete: "Verwijderen",
    totalUDay: "Totaal: 22 E/dag",
    savedBasal: "Opgeslagen basaalprogramma's",
    weekend: "Wochenende",
    editModalTitle: "Een basaalprogramma bewerken",
    editModalMessage:
      "We zullen u begeleiden bij het bewerken van een bestaand basaalprogramma. Selecteer het programma genaamd %1",
    createNewModalTitle: "Een nieuw basaalprogramma maken",
    deleteModalTitle: "Een basaalprogramma verwijderen",
    editModalTitleTwo: "Een basaalprogramma bewerken",
    startModalTitle: "Het basaalprogramma starten",
    notAvailable: "Niet beschikbaar voor de simulator.",
    pleaseModalTitle: "Selecteer rustdag",
    pleaseModalMessage:
      "De bewerking van het basaalprogramma is beperkt tot Rustdag voor deze demonstratie.",
    tourModalTitle: "Tour basaal bewerken opnieuw",
    tourModalBody:
      "Deze functie is beperkt voor simulatiedoeleinden. Wilt u het basaalprogramma resetten om de segmentbewerking opnieuw te ervaren?",
    cancel: "Annuleren",
    confirmText: "Resetten",
    createNew: "Nieuwe maken",
    relax: "Relax Dagje",
    ok: "OK",
  }
};

export { strings };
