import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'G7.pod-compatiblity.modal.title',
    defaultMessage: 'Check Pod Compatibility',
  },
  paragraph: {
    id: 'G7.pod-compatiblity.modal.paragraph',
    defaultMessage:
      'Check your Sensor brand and model on your Pod tray lid to make sure the Pod works with your Sensor.',
  },
  confirmButton: {
    id: 'G7.pod-compatiblity.modal.confirmButton',
    defaultMessage: 'CONTINUE',
  },
});