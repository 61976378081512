import { useNavigation } from '@react-navigation/native';
import React from 'react';
import {
  Dimensions,
  Keyboard,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { ReactComponent as TXOutlineSN } from 'assets/TX_outline_plus_green_circle.svg';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import theme from 'mobile/latest/theme';
import { BottomInnerNav } from 'mobile/latest/components/molecules';
import { strings } from './EnterSNScreenContent';

const EnterSNScreen: React.FC = () => {
  const { navigate } = useNavigation();
  const [txNum, setTxNum] = React.useState<Array<string>>([
    '',
    '',
    '',
    '',
    '',
    '',
  ]);

  const { languageContent } = useLanguageContent(strings);

  const populateTxNum = () => {
    setTxNum(['8', '0', '4', 'J', 'J', '7']);
    Keyboard.dismiss();
  };

  const handleSaveAction = () => {
    if (txNum[0] === '') {
      return;
    }
    navigate('OutroCGM');
  };

  return (
    <>
      <View
        style={[
          styles.innerWrapper,
          {
            backgroundColor: 'white',
          },
        ]}
      >
        <Text style={[theme.fonts.h5, { fontWeight: '500' }]}>
          {languageContent.connectSensorOmnipod}
        </Text>
        <Text style={[theme.fonts.body3, { marginVertical: 10 }]}>
          {languageContent.transmitterNum}
        </Text>
        <View>
          <TXOutlineSN
            width={180}
            height={180}
            style={{ marginTop: -40, marginLeft: 42 }}
          />
          <Text style={[theme.fonts.body3, { marginLeft: 16, marginTop: -8 }]}>
            {languageContent.snTransmitter}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 12,
            }}
          >
            <TextInput
              keyboardType="number-pad"
              selectionColor={theme.colors.blue}
              returnKeyType="done"
              onFocus={populateTxNum}
              value={txNum[0]}
              maxLength={1}
              showSoftInputOnFocus={false}
              style={styles.SNInput}
              editable={false}
            />
            <TextInput
              keyboardType="number-pad"
              selectionColor={theme.colors.blue}
              returnKeyType="done"
              onFocus={populateTxNum}
              value={txNum[1]}
              maxLength={1}
              showSoftInputOnFocus={false}
              style={styles.SNInput}
              editable={false}
            />
            <TextInput
              keyboardType="number-pad"
              selectionColor={theme.colors.blue}
              returnKeyType="done"
              onFocus={populateTxNum}
              value={txNum[2]}
              maxLength={1}
              showSoftInputOnFocus={false}
              style={styles.SNInput}
              editable={false}
            />
            <TextInput
              keyboardType="number-pad"
              selectionColor={theme.colors.blue}
              returnKeyType="done"
              onFocus={populateTxNum}
              value={txNum[3]}
              maxLength={1}
              showSoftInputOnFocus={false}
              style={styles.SNInput}
              editable={false}
            />
            <TextInput
              keyboardType="number-pad"
              selectionColor={theme.colors.blue}
              returnKeyType="done"
              onFocus={populateTxNum}
              value={txNum[4]}
              maxLength={1}
              showSoftInputOnFocus={false}
              style={styles.SNInput}
              editable={false}
            />
            <TextInput
              keyboardType="number-pad"
              selectionColor={theme.colors.blue}
              returnKeyType="done"
              onFocus={populateTxNum}
              value={txNum[5]}
              maxLength={1}
              showSoftInputOnFocus={false}
              style={styles.SNInput}
              editable={false}
            />
          </View>
        </View>
        <Text style={[theme.fonts.body3, { marginVertical: 10 }]}>
          {languageContent.findSNTransmitter}
        </Text>
      </View>
      <BottomInnerNav
        leftActionStyle={{ color: theme.colors.text.cancel }}
        leftActionText={languageContent.cancel}
        rightAction={handleSaveAction}
        rightActionText={languageContent.save}
        rightActionStyle={{
          color:
            txNum[0] === '' ? theme.colors.placeholder : theme.colors.purple,
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 16,
  },
  SNInput: {
    width: 39,
    height: 41,
    marginHorizontal: 3,
    marginTop: 5,
    fontWeight: '500',
    borderWidth: 1,
    borderColor: theme.colors.grayScale.gray400,
    backgroundColor: 'white',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: 36,
    borderRadius: 10,
    color: theme.colors.blue,
  },
});

export default EnterSNScreen;
