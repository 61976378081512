let strings = {
  "en-US": {
    programName: "Program Name",
    relax: "Relax Day",
    cancel: "cancel",
    next: "next",
    basalOne: "Basal"
  },
  "en-GB": {
    programName: "Programme Name",
    relax: "Relax Day",
    cancel: "cancel",
    next: "next",
    basalOne: "Basal"
  },
  "de-DE": {
    programName: "Profilname",
    relax: "Entspannungstag",
    cancel: "Abbr.",
    next: "Weiter",
    basalOne: "Basalrate"
  },
  "es-US": {
    programName: "Nombre del programa",
    relax: "Día de Relajación",
    cancel: "cancelar",
    next: "siguiente",
    basalOne: "Basal"
  },
  "fr-FR": {
    programName: "Nom du programme",
    relax: "Jour de détente",
    cancel: "annuler",
    next: "suivant",
    basalOne: "Débit basal"
  },
  "nl-NL": {
    programName: "Programmanaam",
    relax: "Relax Dagje",
    cancel: "annuleren",
    next: "volgende",
    basalOne: "Basaal"
  }
};

export { strings };
