import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment/moment';
import { Button } from 'web/components/ios/Button';
import { SettingsRow } from 'web/components/ios/SettingsRow';
import { InsulinToCarbSegmentScreenValues } from './InsulinToCarbSegmentScreen';
import { BolusSettingsRoutesEnum } from '../../../types';
import { insulinToCarbMessages } from '../../../BolusSettings.messages';

export const InsulinToCarbMain: React.FC = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const navigateToSegment = (props: InsulinToCarbSegmentScreenValues) => () => {
    navigate(BolusSettingsRoutesEnum.InsulinToCarbSegment, props);
  };

  return (
    <div className="bg-white h-full pt-[29px] pb-[34px] flex flex-col justify-between">
      <div>
        <div className="mb-[25px] px-2">
          <div className="mb-3">
            <FormattedMessage {...insulinToCarbMessages.textOne} />
          </div>
          <div>
            <FormattedMessage {...insulinToCarbMessages.textTwo} />
          </div>
        </div>

        <div className="flex justify-between items-center px-2 pb-[12px] border-b-1 border-b-ios-gray-9/[.36]">
          <div>
            <FormattedMessage {...insulinToCarbMessages.timeSegments} />
          </div>
          <div className="text-ios-gray-9/60">
            <FormattedMessage {...insulinToCarbMessages.totalSegments} />
          </div>
        </div>

        <div>
          <SettingsRow
            text={intl.formatMessage(insulinToCarbMessages.segmentOneTitle)}
            // @ts-ignore it complaints due to value typings, but it's still treated as a string.
            subText={intl.formatMessage(insulinToCarbMessages.segmentOneValue, {
              br: <br />,
            })}
            showCaret
            onClick={navigateToSegment({
              segmentNumber: 1,
              startTime: moment().hour(0).minutes(0),
              endTime: moment().hour(8).minutes(0),
              value: 15,
            })}
          />
          <SettingsRow
            text={intl.formatMessage(insulinToCarbMessages.segmentTwoTitle)}
            // @ts-ignore it complaints due to value typings, but it's still treated as a string.
            subText={intl.formatMessage(insulinToCarbMessages.segmentTwoValue, {
              br: <br />,
            })}
            showCaret
            onClick={navigateToSegment({
              segmentNumber: 2,
              startTime: moment().hour(8).minutes(0),
              endTime: moment().hour(0).minutes(0),
              value: 10,
            })}
          />
        </div>
      </div>

      <div className="px-2">
        <Button
          colorMode="automated"
          variant="colored"
          full
          onClick={() => navigate(BolusSettingsRoutesEnum.BolusSettingsMain)}
        >
          <FormattedMessage {...insulinToCarbMessages.cta} />
        </Button>
      </div>
    </div>
  );
};
