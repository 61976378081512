import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import { ReactComponent as Edit } from "../../../../../../../../assets/UI_Edit.svg";
import { getUnitByLocale } from "../../../../../../helpers/getUnitByLocale";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { UserState } from "../../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { ListHeader } from "../../../../../atoms";
import { BottomInnerNav, ConfirmationModal } from "../../../../../molecules";
import { strings } from "./CorrectAboveSegmentsScreenContent";

const CorrectAboveSegmentsScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const [editingModal, setEditingModal] = React.useState<boolean>(false);
  const toggleEditingModal = () => {
    setEditingModal(!editingModal);
  };

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);
  return (
    <View style={[styles.container]}>
      <View>
        <Text
          style={[
            theme.fonts.body2,
            { marginVertical: 24, paddingHorizontal: 16 }
          ]}>
          {languageContent.review}
        </Text>
        <ListHeader numOfSegments="3" />
        <View style={[styles.segmentRow]}>
          <View>
            <Text>
              <Text style={[theme.fonts.body2, { fontWeight: "500" }]}>
                {`${languageContent.segment} 1: `}
              </Text>
              <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
                12:00 am - 5:00 am
              </Text>
            </Text>
            <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
              <Text style={[{ fontWeight: "500" }]}>
                {`${languageContent.targetGlucose} `}
              </Text>
              {`${getUnitByLocale(130, langState.units)} ${langState.units}`}
            </Text>
            <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
              <Text style={[{ fontWeight: "500" }]}>
                {`${languageContent.correctAbove} `}
              </Text>
              {`${getUnitByLocale(150, langState.units)} ${langState.units}`}
            </Text>
          </View>
          <View style={[{ justifyContent: "center" }]}>
            <TouchableOpacity
              onPress={() => navigate("BolusSettingsCorrectAboveSegementEdit")}>
              <Edit />
            </TouchableOpacity>
          </View>
        </View>
        <View style={[styles.break]} />
        <View style={[styles.segmentRow]}>
          <View>
            <Text>
              <Text style={[theme.fonts.body2, { fontWeight: "500" }]}>
                {`${languageContent.segment} 2: `}
              </Text>
              <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
                5:00 am - 9:00 am
              </Text>
            </Text>
            <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
              <Text style={[{ fontWeight: "500" }]}>
                {`${languageContent.targetGlucose} `}
              </Text>
              {`${getUnitByLocale(110, langState.units)} ${langState.units}`}
            </Text>
            <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
              <Text style={[{ fontWeight: "500" }]}>
                {`${languageContent.correctAbove} `}
              </Text>
              {`${getUnitByLocale(120, langState.units)} ${langState.units}`}
            </Text>
          </View>
          <View style={[{ justifyContent: "center" }]}>
            <Edit />
          </View>
        </View>
        <View style={[styles.break]} />
        <View style={[styles.segmentRow]}>
          <View>
            <Text>
              <Text style={[theme.fonts.body2, { fontWeight: "500" }]}>
                {`${languageContent.segment} 3: `}
              </Text>
              <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
                9:00 am - 12:00 am
              </Text>
            </Text>
            <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
              <Text style={[{ fontWeight: "500" }]}>
                {`${languageContent.targetGlucose} `}
              </Text>
              {`${getUnitByLocale(120, langState.units)} ${langState.units}`}
            </Text>
            <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
              <Text style={[{ fontWeight: "500" }]}>
                {`${languageContent.correctAbove} `}
              </Text>
              {`${getUnitByLocale(140, langState.units)} ${langState.units}`}
            </Text>
          </View>
          <View style={[{ justifyContent: "center" }]}>
            <Edit />
          </View>
        </View>
        <View style={[styles.break]} />
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.save}
        rightAction={() => navigate("BolusSettingsMain")}
        rightActionStyle={{ color: theme.colors.purple }}
      />
      {editingModal ? (
        <ConfirmationModal
          isVisible={editingModal}
          purple
          title={languageContent.modalTitle}
          message={
            <View>
              <Text style={[theme.fonts.body2, { color: "white" }]}>
                {languageContent.modalBody}
              </Text>
            </View>
          }
          hideDismiss={true}
          confirmText={languageContent.ok}
          onConfirm={() => {
            toggleEditingModal();
          }}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "white"
  },
  segmentHeader: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.textInputBorder,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    backgroundColor: "#EDF2F6"
  },
  segmentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    marginVertical: 16
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  }
});

export default CorrectAboveSegmentsScreen;
