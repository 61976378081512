let strings = {
  "en-US": {
    basal: "Basal",
    continuous: "Continuous delivery of a small amount of insulin."
  },
  "en-GB": {
    basal: "Basal",
    continuous: "Continuous delivery of a small amount of insulin."
  },
  "de-DE": {
    basal: "Basal",
    continuous: "Kontinuierliche Verabreichung einer kleinen Menge Insulin."
  },
  "es-US": {
    basal: "Basal",
    continuous: "Administración continua de una pequeña cantidad de insulina."
  },
  "fr-FR": {
    basal: "Débit basal",
    continuous: "Administration continue d'une petite quantité d'insuline."
  },
  "nl-NL": {
    basal: "Basaal",
    continuous: "Continue afgifte van een kleine hoeveelheid insuline."
  }
};

export { strings };
