import { useNavigation, useRoute } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
/* import CheckBox from "react-native-check-box"; */
import { useDispatch, useSelector } from "react-redux";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { viewerActions } from "../../../../../../../web/store/reducers/viewerManagement";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { Button } from "../../../../atoms";
import { ConfirmationModal, PodCommAlert } from "../../../../molecules";
import { strings } from "./AppDisclosureScreenContent";

const AppDisclosureScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const [showPodCommOne, setShowPodCommOne] = React.useState<boolean>(false);
  const [showPodCommTwo, setShowPodCommTwo] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const [date, setDate] = React.useState<string>("");
  const [accepted, setAccepted] = React.useState<boolean>(false);

  const route = useRoute<any>();
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { height } = useWindowDimensions();
  const { languageContent } = useLanguageContent(strings);

  const getFormattedDate = () => {
    const date = new Date();

    setDate(
      date.toLocaleDateString(langState.locale, {
        month: "narrow",
        day: "numeric",
        year: "numeric",
      })
    );
  };

  React.useEffect(() => {
    getFormattedDate();
  }, []);

  return (
    <>
      <View>
        <View style={styles.headerStyle}>
          <Text
            style={
              styles.headerText
            }>{`Omnipod VIEW\u2122 ${languageContent.title}`}</Text>
        </View>
        <ScrollView style={[styles.disclosureBox, { height: height / 2.5 }]}>
          <Text style={styles.date}>{date}</Text>
          <Text style={styles.disclosureText}>
            {`${languageContent.disclosureText} Omnipod\u00a05 ${languageContent.disclosureTextCont} Omnipod VIEW\u2122 ${languageContent.disclosureTextCont2} ${route.params.firstName} ${route.params.lastName} ${languageContent.at} ${route.params.email}.`}
          </Text>
          <Text style={styles.disclosureSubText}>
            {languageContent.disclosureSubtext}
          </Text>
        </ScrollView>
        <View style={{ marginHorizontal: 16 }}>
          <TouchableOpacity
            onPress={() => setAccepted(!accepted)}
            style={{ flexDirection: "row" }}>
            {/* <CheckBox */}
            {/*   checkBoxColor={theme.colors.grayScale.gray200} */}
            {/*   isChecked={accepted} */}
            {/*   onClick={() => { */}
            {/*     setAccepted(!accepted); */}
            {/*   }} */}
            {/* /> */}
            <Text style={styles.acceptTermsText}>
              {languageContent.acceptTerms}
            </Text>
          </TouchableOpacity>
          {accepted ? (
            <Button
              variant="dark"
              style={styles.acceptButton}
              onPress={() => {
                setShowPodCommOne(true);
              }}>
              {languageContent.continue}
            </Button>
          ) : null}
        </View>
        {showPodCommOne ? (
          <PodCommAlert
            title={languageContent.connecting}
            duration={2000}
            onDismiss={() => {
              setShowPodCommOne(false);
              setShowPodCommTwo(true);
            }}
          />
        ) : null}
        {showPodCommTwo ? (
          <PodCommAlert
            title={languageContent.sendingInvitation}
            duration={2000}
            onDismiss={() => {
              setShowPodCommTwo(false);
              setShowModal(true);
            }}
          />
        ) : null}
        <ConfirmationModal
          isVisible={showModal}
          title={languageContent.allSet}
          message={
            <View>
              <Text style={theme.fonts.body1}>
                {languageContent.invitationSent}
              </Text>
            </View>
          }
          hideDismiss={true}
          confirmText={languageContent.confirmText}
          onConfirm={() => {
            setShowModal(false);
            dispatch(
              viewerActions.viewerUpdate({
                newViewer: {
                  firstName: route.params.firstName,
                  lastName: route.params.lastName,
                  email: route.params.email,
                  relationship: route.params.relationship,
                  status: languageContent.invitationPending,
                },
              })
            );
            navigate("Viewer Management");
          }}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  headerStyle: {
    width: "70%",
    alignSelf: "center",
    marginTop: 34,
  },
  headerText: {
    ...theme.fonts.h3,
    textAlign: "center",
  },
  disclosureBox: {
    borderWidth: 1,
    borderColor: theme.colors.graphBlue,
    paddingHorizontal: 8,
    margin: 16,
  },
  date: {
    textAlign: "right",
    ...theme.fonts.body1,
  },
  disclosureText: {
    marginTop: 16,
    ...theme.fonts.body1,
  },
  disclosureSubText: {
    marginTop: 16,
    paddingBottom: 8,
    ...theme.fonts.body1,
  },
  acceptTermsText: {
    marginLeft: 16,
    alignSelf: "center",
    ...theme.fonts.body1,
  },
  acceptButton: {
    elevation: 6,
    backgroundColor: theme.colors.purple,
    marginTop: 16,
  },
});

export default AppDisclosureScreen;
