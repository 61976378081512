let strings = {
  "en-US": {
    start: "Start",
    end: "End",
    night: "Night",
    morning: "Morning",
    targetBG: "Target Glucose",
    correctAbove: "Correct Above",
    range: "(%1 to %2)",
    targetGlucoseTo: "(Target Glucose to %3)",
    cancel: "Cancel",
    next: "Next",
    modalTitle: "Segment End Time",
    ok: "OK",
    modalBody:
      "For simulation purposes we have auto-filled this information for you."
  },
  "en-GB": {
    start: "Start",
    end: "End",
    night: "Night",
    morning: "Morning",
    targetBG: "Target Glucose",
    correctAbove: "Correct Above",
    range: "(%1 to %2)",
    targetGlucoseTo: "(Target Glucose to %3)",
    cancel: "Cancel",
    next: "Next",
    modalTitle: "Segment End Time",
    ok: "OK",
    modalBody:
      "For simulation purposes we have auto-filled this information for you."
  },
  "de-DE": {
    start: "Start",
    end: "Ende",
    night: "Nacht",
    morning: "Vormittag",
    targetBG: "Glukose-Zielwert",
    correctAbove: "Korrigieren über",
    range: "(%1 bis %2)",
    targetGlucoseTo: "(Glukose-Zielwert bis %3)",
    cancel: "Abbr.",
    next: "Weiter",
    modalTitle: "Segmentende",
    ok: "OK",
    modalBody:
      "Zu Simulationszwecken haben wir diese Informationen automatisch für Sie ausgefüllt."
  },
  "es-US": {
    start: "Iniciar",
    end: "Finalizar",
    night: "Noche",
    morning: "Mañana",
    targetBG: "Glucosa Objetivo",
    correctAbove: "Corregir Sobre",
    range: "(%1 a %2)",
    targetGlucoseTo: "(Glucosa Objetivo para %3)",
    cancel: "Cancelar",
    next: "Siguiente",
    modalTitle: "Hora de finalización del segmento",
    ok: "Aceptar",
    modalBody:
      "Completamos automáticamente esta información para fines de simulación."
  },
  "fr-FR": {
    start: "Démarrer",
    end: "Fin",
    night: "Nuit",
    morning: "Matin",
    targetBG: "Glucose cible",
    correctAbove: "Corriger si supérieur à",
    range: "(%1 à %2)",
    targetGlucoseTo: "(Glucose cible à %3)",
    cancel: "Annuler",
    next: "Suivant",
    modalTitle: "Heure de fin du segment",
    ok: "OK",
    modalBody:
      "À des fins de simulation, nous avons rempli automatiquement ces informations pour vous."
  },
  "nl-NL": {
    start: "Start",
    end: "Stop",
    night: "Nacht",
    morning: "Ochtend",
    targetBG: "Streefwaarde glucose",
    correctAbove: "Corrigeren boven",
    range: "(%1 tot %2)",
    targetGlucoseTo: "(Streefwaarde glucose naar %3)",
    cancel: "Annuleren",
    next: "Volgende",
    modalTitle: "Eindtijd segment",
    ok: "OK",
    modalBody:
      "Voor simulatiedoeleinden hebben wij deze informatie automatisch voor u ingevuld."
  }
};

export { strings };
