import { isEqual } from "lodash";
import React from "react";
import { LayoutAnimation, StyleSheet, View } from "react-native-web";
import { useSelector } from "react-redux";
import getHistoryDetailData from "../../../data/defaultHistoryDetailData";
import { strings as historyDataStrings } from "../../../data/defaultHistoryDetailDataContent";
import useLanguageContent from "../../../hooks/useLanguageContent";
import { LangState } from "../../../../../web/store/reducers/language";
import { RootState } from "../../../../../web/store/store";
import ExpandableItem from "../expandableItem";

const ExpandableList: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { languageContent: defaultHistoryDataLangauageContent } =
    useLanguageContent(historyDataStrings);

  const [listDataSource, setListDataSource] = React.useState<Array<any>>(
    getHistoryDetailData(defaultHistoryDataLangauageContent, langState)
  );
  const updateLayout = (index: number) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    const array = [...listDataSource];
    array[index]["isExpanded"] = !array[index]["isExpanded"];
    setListDataSource(array);
  };

  React.useEffect(() => {
    setListDataSource(
      getHistoryDetailData(defaultHistoryDataLangauageContent, langState)
    );
  }, [getHistoryDetailData, defaultHistoryDataLangauageContent, langState]);

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.container}>
        {listDataSource.map((item, key) => (
          <ExpandableItem
            key={item.index}
            onPress={() => {
              updateLayout(key);
            }}
            item={item as any}
            expanded={item.isExpanded}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  titleText: {
    flex: 1,
    fontSize: 22,
    fontWeight: "bold"
  },
  header: {
    padding: 20
  },
  headerText: {
    fontSize: 16,
    fontWeight: "500"
  },
  separator: {
    height: 0.5,
    backgroundColor: "#808080",
    width: "95%",
    marginLeft: 16,
    marginRight: 16
  },
  text: {
    fontSize: 16,
    color: "#606070",
    padding: 10
  },
  content: {
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#fff"
  },
  arrow: {
    width: 36,
    height: 36,
    marginTop: -8,
    alignSelf: "center"
  },
  innerContainer: {
    flex: 3,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  },
  itemText: {
    alignSelf: "center",
    marginLeft: -20,
    fontSize: 14,
    fontWeight: "400"
  }
});

export default ExpandableList;
