import React from "react";
import { StyleSheet, Text, View } from "react-native-web";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import { strings } from "./SummaryItemContent";

interface Props {
  fullLine: boolean;
}

const SummaryItem: React.FC<Props> = ({ fullLine }) => {
  const { languageContent } = useLanguageContent(strings);
  return (
    <>
      {fullLine ? (
        <>
          <View
            style={[
              styles.subItemWrapper,
              {
                borderTopWidth: 0.5,
                borderTopColor: theme.colors.textInputBorder,
                marginLeft: 64
              }
            ]}>
            <Text
              style={[
                styles.subItemText
              ]}>{`${languageContent.bolus} ${languageContent.calc}`}</Text>
          </View>
          <View
            style={[
              styles.subItemWrapper,
              {
                borderTopWidth: 0.5,
                borderTopColor: theme.colors.textInputBorder,
                marginLeft: 64
              }
            ]}>
            <Text
              style={[
                styles.subItemText
              ]}>{`${languageContent.glucose} ${languageContent.manual}`}</Text>
          </View>
          <View
            style={[
              styles.subItemWrapper,
              {
                borderTopWidth: 0.5,
                borderTopColor: theme.colors.textInputBorder,
                borderBottomWidth: 0.5,
                borderBottomColor: theme.colors.textInputBorder,
                marginLeft: 64
              }
            ]}>
            <Text
              style={[
                styles.subItemText
              ]}>{`${languageContent.carbs} ${languageContent.manual}`}</Text>
          </View>
        </>
      ) : (
        <View
          style={[
            styles.subItemWrapper,
            {
              borderTopWidth: 0.5,
              borderTopColor: theme.colors.textInputBorder,
              marginLeft: 64,
              borderBottomWidth: 0.5,
              borderBottomColor: theme.colors.textInputBorder
            }
          ]}>
          <Text
            style={[
              styles.subItemText
            ]}>{`${languageContent.bolus} ${languageContent.calc}`}</Text>
        </View>
      )}
    </>
  );
};
export default SummaryItem;

const styles = StyleSheet.create({
  subItemWrapper: {
    padding: 16
  },
  subItemText: {
    fontSize: 14
  }
});
