import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const AlertsIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={classes} fillRule="evenodd" clipRule="evenodd" d="M15.3986 19.7647C15.3986 20.9992 14.3571 22 13.0725 22H11.9094C10.6247 22 9.58327 20.9992 9.58327 19.7647H15.3986ZM12.4909 3C13.3496 3 14.0634 3.59615 14.2086 4.38125C16.561 5.10194 18.2648 7.21903 18.2648 9.71852V14.2594L20.6893 16.769C21.1263 17.2214 21.099 17.9285 20.6282 18.3485C20.4131 18.5404 20.1305 18.6471 19.8369 18.6471H5.16306C4.52072 18.6471 4 18.1467 4 17.5294C4 17.2343 4.12142 16.9513 4.33778 16.7419L6.60788 14.5451V9.71852C6.60788 7.17817 8.36785 5.03284 10.78 4.34704C10.9293 3.62691 11.5587 3.0731 12.334 3.00669L12.4909 3ZM12.472 6.672L11.5184 6.94412C10.2619 7.30134 9.39005 8.34998 9.31338 9.54663L9.30788 9.71852V15.6896L9.041 15.947H16.14L15.5648 15.3506V9.71852C15.5648 8.53261 14.7818 7.46284 13.5982 7.02387L13.4177 6.96282L12.472 6.672Z" />
    </svg>
  );
}
