import React from "react"
import { StyleSheet, View } from "react-native"
import { BasalProgram } from "web/store/reducers/basal/reducer"
// import CurrentBasalSegment from "../../organisms/currentBasalSegment";

const GRAPH_MAX_HEIGHT = 60

interface CompletedBasalProgramGraphProps {
  program: BasalProgram
  maxHeight?: number
}

const CompletedBasalProgramGraph: React.FC<CompletedBasalProgramGraphProps> = ({
  program,
  maxHeight,
}) => {
  const [maxBasalRateCalc, setMaxBasalRateCalc] = React.useState<any>(3)

  React.useEffect(() => {
    const max = Math.max.apply(
      Math,
      program.segments.map((segment) => {
        return segment.basalRate
      }),
    )
    if (max > 3) {
      setMaxBasalRateCalc(max + 1)
    }
  }, [program.segments])

  return (
    <View
      style={[
        styles.graphView,
        {
          height: maxHeight ? maxHeight : GRAPH_MAX_HEIGHT,
          marginTop: maxHeight && maxHeight <= 90 ? 32 : undefined,
        },
      ]}
    >
      {/* {program.segments.map((segment, index) => (
        // <CurrentBasalSegment
        //   key={index}
        //   startTime={segment.start}
        //   endTime={segment.end}
        //   segmentsArrLength={program.segments.length}
        //   maxHeight={maxHeight ? maxHeight : GRAPH_MAX_HEIGHT}
        //   segment={segment}
        //   maxBasalRateCalc={maxBasalRateCalc}
        //   index={index}
        //   program={program}
        //   segmentEndTime=""
        //   displayOnly
        // />
      ))} 
      TODO: Add Organism Import on PODWEB-15
      */}
    </View>
  )
}

const styles = StyleSheet.create({
  graphView: {
    marginHorizontal: 16,
    marginBottom: 16,
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "row",
  },
})

export default CompletedBasalProgramGraph
