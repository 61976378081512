import React from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import theme from "../../../../theme";
import { useNavigation } from "@react-navigation/native";
import { ConfirmationModal } from "../../../molecules";
import {
  LangState,
  languageActions,
} from "../../../../../../web/store/reducers/language";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../web/store/store";
import { isEqual } from "lodash";
import PurpleArrow from "../../../../../../assets/UI_BackArrowPurple.svg";
import CustomRadioButton from "../../../molecules/customRadioButton";
import INSULIN_UNITS from "../../../../data/insulinUnits";
import LANGUAGES from "../../../../data/languages";
import { LanguageType } from "../../../../types/language";
import { getInsulinUnitsByCountry } from "../../../../helpers/getInsulinUnitsByCountry";
import { LanguageLongType } from "../../../../types/languageLong";
import { getLanguage } from "../../../../helpers/getLanguage";

const MainLanguageSelectScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [languageLong, setLanguageLong] =
    React.useState<LanguageLongType>("English (US)");
  const [showLanguageSelector, setShowLanguageSelector] =
    React.useState<boolean>(false);
  const [showUOMConfirmation, setShowUOMConfirmation] =
    React.useState<boolean>(false);
  const [showUOMSelector] = React.useState<boolean>(
    langState.country === "Germany" ? true : false
  );
  const [selectedUnits, setSelectedUnits] = React.useState<"mg/dL" | "mmol/L">(
    "mg/dL"
  );

  const { navigate } = useNavigation();
  const dispatch = useDispatch();

  const handleRadioButtonPress = (label: string) => {
    setSelectedUnits(label as "mg/dL" | "mmol/L");
  };

  return (
    <View style={[theme.layout.wrapper]}>
      <View style={[styles.innerWrapper]}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
          <TouchableOpacity
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
            onPress={() => navigate("SelectCountry")}>
            <PurpleArrow />
            <Text
              style={[
                theme.fonts.h4,
                { fontWeight: "400", color: theme.colors.purple },
              ]}>
              Back
            </Text>
          </TouchableOpacity>
          <View
            style={{
              width: "60%",
              alignItems: "center",
            }}>
            <Text
              style={[
                theme.fonts.h3,
                {
                  margin: 16,
                  fontWeight: "400",
                  textAlign: "center",
                },
              ]}>
              Select Language
            </Text>
          </View>
        </View>
        <TouchableOpacity
          onPress={() => {
            setShowLanguageSelector(true);
          }}>
          <Text
            style={[
              styles.textInput,
              theme.fonts.text200,
              styles.inputContent,
            ]}>
            {languageLong}
          </Text>
        </TouchableOpacity>
        {showUOMSelector ? (
          <View style={{ marginBottom: 18, marginLeft: 2 }}>
            <Text
              style={[
                theme.fonts.h4,
                { marginTop: 16, marginLeft: 18, fontWeight: "400" },
              ]}>
              Units of Measure
            </Text>
            {INSULIN_UNITS.map((node: any, index: number) => (
              <View key={`${index}--${node.label}`}>
                <CustomRadioButton
                  locale={langState.locale}
                  item={node}
                  index={index}
                  handleRadioButtonPress={handleRadioButtonPress}
                  selectedItem={selectedUnits}
                />
              </View>
            ))}
          </View>
        ) : null}
        <View style={{ paddingBottom: 16, paddingHorizontal: 16 }}>
          <TouchableOpacity
            onPress={() => {
              //TODO MAKE THIS WORK
              /* langState.country === "Germany" */
              /*   ? setShowUOMConfirmation(true) */
              /*   : (dispatch( */
              /*       languageActions.languageUpdate( */
              /*         { */
              /*           // language: getLanguage(languageLong), TODO: TURN THIS BACK ON WHEN OTHER LANGUAGE STRINGS GO IN */
              /*           locale: getLanguage(languageLong), */
              /*           units: getInsulinUnitsByCountry(langState.country) as */
              /*             | "mg/dL" */
              /*             | "mmol/L", */
              /*           showLocaleScreens: false, */
              /*         }, */
              /*         langState */
              /*       ) */
              /*     ), */
              /*     navigate("IntroTour")); */
            }}
            style={{
              backgroundColor: theme.colors.purple,
              padding: 12,
              borderRadius: 18,
            }}>
            <Text
              style={{
                color: theme.colors.white,
                textAlign: "center",
                fontWeight: "bold",
              }}>
              {"Confirm".toUpperCase()}
            </Text>
          </TouchableOpacity>
        </View>
        <ConfirmationModal
          isVisible={showLanguageSelector}
          hideTitle={true}
          hideDismiss={true}
          hideConfirm={true}
          hideFooter={true}
          languageSelectScreen={true}
          onDismiss={() => setShowLanguageSelector(false)}
          message={
            <View>
              {LANGUAGES.map(
                (node: { label: LanguageLongType; value: LanguageType }) => (
                  <TouchableOpacity
                    key={node.value}
                    style={{
                      backgroundColor: theme.colors.textInputBackgroundColor,
                      borderRadius: 8,
                      marginBottom: 6,
                    }}
                    onPress={() => {
                      setLanguageLong(node.label); //ex "United States"
                      setShowLanguageSelector(false);
                    }}>
                    <Text
                      style={[
                        theme.fonts.h4,
                        {
                          fontWeight: "normal",
                          borderColor:
                            languageLong === node.label
                              ? theme.colors.purple
                              : "transparent",
                          borderWidth: 1.5,
                          borderRadius: 8,
                          padding: 12,
                        },
                      ]}>
                      {node.label}
                    </Text>
                  </TouchableOpacity>
                )
              )}
            </View>
          }
        />
      </View>
      <ConfirmationModal
        isVisible={showUOMConfirmation}
        onDismiss={() => setShowUOMConfirmation(false)}
        dismissText={"Cancel"}
        confirmText={"Ok"}
        onConfirm={() => {
          setShowUOMConfirmation(false);
          dispatch(
            languageActions.languageUpdate(
              {
                // language: getLanguage(languageLong), TODO: TURN THIS BACK ON WHEN OTHER LANGUAGE STRINGS GO IN
                locale: getLanguage(languageLong),
                units: selectedUnits,
                showLocaleScreens: false,
              },
              langState
            )
          );
          navigate("IntroTour");
        }}
        title={"Units of Measure"}
        message={
          <Text style={[theme.fonts.body1]}>
            Once confirmed, you may not change your preferred units of measure.
            Your current selection is {selectedUnits}. Continue?
          </Text>
        }
      />
    </View>
  );
};

const height = Dimensions.get("window").height;

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    width: Dimensions.get("screen").width,
    padding: 16,
    marginTop: height < 800 ? height / 40 : height / 20,
  },
  textInput: {
    margin: 16,
    padding: 16,
    fontWeight: "700",
    borderWidth: 1,
    borderRadius: 6,
    textAlign: "center",
    backgroundColor: theme.colors.textInputBackgroundColor,
  },
  inputContent: {
    borderColor: theme.colors.textInputBorder,
    fontWeight: "400",
    color: theme.colors.pureBlack,
    textAlign: "left",
  },
});

export default MainLanguageSelectScreen;
