import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  topBarTitle: {
    id: 'txid.top-bar.title',
    defaultMessage: 'Manage Sensor',
  },
  setupComplete: {
    id: 'txid.fts.setup-complete',
    defaultMessage: 'Setup is complete!',
  },
  initialScreenText: {
    id: 'txid.fts.initial-setup.text',
    defaultMessage:
      'Next, connect your <highlight>Transmitter to Omnipod 5.</highlight>',
  },
  initialScreenCtaButton: {
    id: 'txid.fts.initial-setup.cta-button',
    defaultMessage: 'Connect Transmitter',
  },
  initialScreenCancelButton: {
    id: 'txid.fts.initial-setup.cancel-button',
    defaultMessage: 'Not Now',
  },
  serialNumberTitle: {
    id: 'txid.enter-serial-number.title',
    defaultMessage: 'Connect your Dexcom G6 to Omnipod 5',
  },
  serialNumberText: {
    id: 'txid.enter-serial-number.text',
    defaultMessage:
      'Enter your Transmitter serial number (SN). {br} The SN is printed on the back of your Transmitter and on the Transmitter box.',
  },
  serialNumberDisclaimer: {
    id: 'txid.enter-serial-number.disclaimer',
    defaultMessage:
      'If you have already set up your Transmitter in the Dexcom app, you can find the SN in Dexcom Settings.',
  },
  modalTitle: {
    id: 'txid.enter-serial-number.modal.title',
    defaultMessage: 'Confirm Serial Number'
  },
  modalText: {
    id: 'txid.enter-serial-number.modal.text',
    defaultMessage: '8 0 4 J J 7'
  },
  modalCancel: {
    id: 'txid.enter-serial-number.modal.cancel',
    defaultMessage: 'Cancel',
  },
  modalConfirm: {
    id: 'txid.enter-serial-number.modal.confirm',
    defaultMessage: 'Confirm',
  }
});
