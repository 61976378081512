let strings = {
  "en-US": {
    setupBolus: "Setup: Bolus"
  },
  "en-GB": {
    setupBolus: "Setup: Bolus"
  },
  "de-DE": {
    setupBolus: "Einrichten: Bolus",
    setup: "Einrichten"
  },
  "es-US": {
    setupBolus: "Configuración: Bolo",
    setup: "Configuración"
  },
  "nl-NL": {
    setupBolus: "Instellingen: Bolus",
  },
  "fr-FR": {
    setupBolus: "Configuration: bolus"
  },
};

export { strings };
