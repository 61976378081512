import { createStackNavigator } from "@react-navigation/stack";
import { isEqual } from "lodash";
import React from "react";
import { Animated } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { BackButton } from "../../components/atoms";
import {
  BolusCalculationsScreen,
  BolusScreen,
  ConfirmBolusScreen,
  ExtendBolusScreen
} from "../../components/screens";
import { useModeIconSmall } from "../../hooks";
import { NavState, navigationActions } from "../../../../web/store/reducers/navigation";
import { RootState } from "../../../../web/store/store";
import theme from "../../theme";
import { strings } from './BolusStackContent';

const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const BolusStack: React.FC = () => {
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );
  const dispatch = useDispatch();
  const headerRef = new Animated.ValueXY();
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  const animateHeader = React.useCallback(() => {
    Animated.timing(headerRef, {
      toValue: 0,
      duration: 600,
      useNativeDriver: false
    }).start();
  }, [headerRef]);

  React.useEffect(() => {
    animateHeader();
  }, [animateHeader]);

  return (
    <>
      <Stack.Navigator mode="card">
        <Stack.Screen
          name="Bolus"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text="Bolus"
                white={false}
                beforeNavigation={() =>
                  dispatch(
                    navigationActions.navigationUpdate(
                      { navigateTo: "Dashboard" },
                      navState
                    )
                  )
                }
              />
            ),
            headerRight: () => icon
          }}
          component={BolusScreen}
        />
        <Stack.Screen
          name="BolusCalculations"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.bolusCalculations} white={false} />
            ),
            headerRight: () => icon
          }}
          component={BolusCalculationsScreen}
        />
        <Stack.Screen
          name="ConfirmBolus"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => <BackButton text={languageContent.confirmBolus} white={false} />,
            headerRight: () => icon
          }}
          component={ConfirmBolusScreen}
        />
        <Stack.Screen
          name="ExtendBolus"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.extendedBolus} white={false} />
            ),
            headerRight: () => icon
          }}
          component={ExtendBolusScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default BolusStack;
