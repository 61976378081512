const historyData = [
  {
    cgmInRange: "87",
    totalInsulin: "50",
    totalCarbs: "142"
  },
  {
    cgmInRange: "86",
    totalInsulin: "52.05",
    totalCarbs: "134"
  },
  {
    cgmInRange: "85",
    totalInsulin: "57.35",
    totalCarbs: "149"
  },
  {
    cgmInRange: "77",
    totalInsulin: "41.3",
    totalCarbs: "141"
  },
  {
    cgmInRange: "79",
    totalInsulin: "42.8",
    totalCarbs: "154"
  },
  {
    cgmInRange: "84",
    totalInsulin: "59.3",
    totalCarbs: "156"
  },
  {
    cgmInRange: "73",
    totalInsulin: "44.3",
    totalCarbs: "128"
  },
  {
    cgmInRange: "74",
    totalInsulin: "56.25",
    totalCarbs: "171"
  },
  {
    cgmInRange: "84",
    totalInsulin: "53.2",
    totalCarbs: "151"
  },
  {
    cgmInRange: "87",
    totalInsulin: "53.2",
    totalCarbs: "137"
  },
  {
    cgmInRange: "85",
    totalInsulin: "54.25",
    totalCarbs: "144"
  },
  {
    cgmInRange: "72",
    totalInsulin: "44.3",
    totalCarbs: "125"
  },
  {
    cgmInRange: "84",
    totalInsulin: "41.25",
    totalCarbs: "151"
  },
  {
    cgmInRange: "89",
    totalInsulin: "51.8",
    totalCarbs: "143"
  },
  {
    cgmInRange: "70",
    totalInsulin: "49.55",
    totalCarbs: "164"
  },
  {
    cgmInRange: "78",
    totalInsulin: "56.45",
    totalCarbs: "127"
  },
  {
    cgmInRange: "88",
    totalInsulin: "42.3",
    totalCarbs: "137"
  },
  {
    cgmInRange: "84",
    totalInsulin: "41.55",
    totalCarbs: "139"
  },
  {
    cgmInRange: "72",
    totalInsulin: "42.35",
    totalCarbs: "154"
  },
  {
    cgmInRange: "85",
    totalInsulin: "54.1",
    totalCarbs: "151"
  },
  {
    cgmInRange: "73",
    totalInsulin: "42.15",
    totalCarbs: "131"
  },
  {
    cgmInRange: "81",
    totalInsulin: "58",
    totalCarbs: "125"
  },
  {
    cgmInRange: "72",
    totalInsulin: "49.75",
    totalCarbs: "154"
  },
  {
    cgmInRange: "73",
    totalInsulin: "58.25",
    totalCarbs: "152"
  },
  {
    cgmInRange: "82",
    totalInsulin: "54.5",
    totalCarbs: "162"
  },
  {
    cgmInRange: "73",
    totalInsulin: "56.05",
    totalCarbs: "135"
  },
  {
    cgmInRange: "86",
    totalInsulin: "59.35",
    totalCarbs: "139"
  },
  {
    cgmInRange: "80",
    totalInsulin: "46.05",
    totalCarbs: "131"
  },
  {
    cgmInRange: "85",
    totalInsulin: "43.15",
    totalCarbs: "130"
  },
  {
    cgmInRange: "79",
    totalInsulin: "52.7",
    totalCarbs: "126"
  },
  {
    cgmInRange: "80",
    totalInsulin: "46.25",
    totalCarbs: "149"
  },
  {
    cgmInRange: "87",
    totalInsulin: "49.5",
    totalCarbs: "161"
  },
  {
    cgmInRange: "75",
    totalInsulin: "42.65",
    totalCarbs: "156"
  },
  {
    cgmInRange: "89",
    totalInsulin: "51.2",
    totalCarbs: "129"
  },
  {
    cgmInRange: "84",
    totalInsulin: "59.2",
    totalCarbs: "170"
  },
  {
    cgmInRange: "89",
    totalInsulin: "49.4",
    totalCarbs: "165"
  },
  {
    cgmInRange: "81",
    totalInsulin: "40.45",
    totalCarbs: "169"
  },
  {
    cgmInRange: "85",
    totalInsulin: "48.8",
    totalCarbs: "150"
  },
  {
    cgmInRange: "89",
    totalInsulin: "44.7",
    totalCarbs: "174"
  },
  {
    cgmInRange: "76",
    totalInsulin: "60",
    totalCarbs: "166"
  },
  {
    cgmInRange: "88",
    totalInsulin: "51.8",
    totalCarbs: "142"
  },
  {
    cgmInRange: "77",
    totalInsulin: "42",
    totalCarbs: "137"
  },
  {
    cgmInRange: "75",
    totalInsulin: "44.8",
    totalCarbs: "142"
  },
  {
    cgmInRange: "70",
    totalInsulin: "41.35",
    totalCarbs: "168"
  },
  {
    cgmInRange: "82",
    totalInsulin: "52.1",
    totalCarbs: "169"
  },
  {
    cgmInRange: "88",
    totalInsulin: "45.9",
    totalCarbs: "166"
  },
  {
    cgmInRange: "83",
    totalInsulin: "58",
    totalCarbs: "170"
  },
  {
    cgmInRange: "70",
    totalInsulin: "55.65",
    totalCarbs: "145"
  },
  {
    cgmInRange: "88",
    totalInsulin: "48.3",
    totalCarbs: "142"
  },
  {
    cgmInRange: "89",
    totalInsulin: "44.55",
    totalCarbs: "147"
  },
  {
    cgmInRange: "75",
    totalInsulin: "43.65",
    totalCarbs: "160"
  },
  {
    cgmInRange: "78",
    totalInsulin: "57.6",
    totalCarbs: "172"
  },
  {
    cgmInRange: "88",
    totalInsulin: "44.9",
    totalCarbs: "163"
  },
  {
    cgmInRange: "74",
    totalInsulin: "40.15",
    totalCarbs: "137"
  },
  {
    cgmInRange: "82",
    totalInsulin: "58.9",
    totalCarbs: "168"
  },
  {
    cgmInRange: "77",
    totalInsulin: "57.55",
    totalCarbs: "156"
  },
  {
    cgmInRange: "72",
    totalInsulin: "54.35",
    totalCarbs: "140"
  },
  {
    cgmInRange: "72",
    totalInsulin: "47.6",
    totalCarbs: "131"
  },
  {
    cgmInRange: "82",
    totalInsulin: "40.95",
    totalCarbs: "148"
  },
  {
    cgmInRange: "74",
    totalInsulin: "58.95",
    totalCarbs: "156"
  },
  {
    cgmInRange: "85",
    totalInsulin: "47.15",
    totalCarbs: "150"
  },
  {
    cgmInRange: "86",
    totalInsulin: "57.3",
    totalCarbs: "164"
  },
  {
    cgmInRange: "71",
    totalInsulin: "53.65",
    totalCarbs: "167"
  },
  {
    cgmInRange: "79",
    totalInsulin: "46.1",
    totalCarbs: "159"
  },
  {
    cgmInRange: "88",
    totalInsulin: "57.45",
    totalCarbs: "162"
  },
  {
    cgmInRange: "82",
    totalInsulin: "53.8",
    totalCarbs: "156"
  },
  {
    cgmInRange: "80",
    totalInsulin: "59.55",
    totalCarbs: "173"
  },
  {
    cgmInRange: "70",
    totalInsulin: "45.5",
    totalCarbs: "167"
  },
  {
    cgmInRange: "76",
    totalInsulin: "51.95",
    totalCarbs: "141"
  },
  {
    cgmInRange: "84",
    totalInsulin: "55.45",
    totalCarbs: "134"
  },
  {
    cgmInRange: "76",
    totalInsulin: "55.3",
    totalCarbs: "136"
  },
  {
    cgmInRange: "85",
    totalInsulin: "56.95",
    totalCarbs: "139"
  },
  {
    cgmInRange: "72",
    totalInsulin: "48.15",
    totalCarbs: "136"
  },
  {
    cgmInRange: "85",
    totalInsulin: "59.55",
    totalCarbs: "155"
  },
  {
    cgmInRange: "80",
    totalInsulin: "46.15",
    totalCarbs: "173"
  },
  {
    cgmInRange: "81",
    totalInsulin: "55.1",
    totalCarbs: "156"
  },
  {
    cgmInRange: "88",
    totalInsulin: "57.65",
    totalCarbs: "149"
  },
  {
    cgmInRange: "71",
    totalInsulin: "47.8",
    totalCarbs: "164"
  },
  {
    cgmInRange: "86",
    totalInsulin: "47.8",
    totalCarbs: "136"
  },
  {
    cgmInRange: "85",
    totalInsulin: "55.15",
    totalCarbs: "155"
  },
  {
    cgmInRange: "74",
    totalInsulin: "51.75",
    totalCarbs: "139"
  },
  {
    cgmInRange: "88",
    totalInsulin: "48.45",
    totalCarbs: "154"
  },
  {
    cgmInRange: "72",
    totalInsulin: "47.7",
    totalCarbs: "133"
  },
  {
    cgmInRange: "85",
    totalInsulin: "55.3",
    totalCarbs: "146"
  },
  {
    cgmInRange: "80",
    totalInsulin: "56.65",
    totalCarbs: "153"
  },
  {
    cgmInRange: "85",
    totalInsulin: "46.6",
    totalCarbs: "149"
  },
  {
    cgmInRange: "77",
    totalInsulin: "55.05",
    totalCarbs: "133"
  },
  {
    cgmInRange: "75",
    totalInsulin: "55.55",
    totalCarbs: "140"
  },
  {
    cgmInRange: "80",
    totalInsulin: "43.95",
    totalCarbs: "151"
  },
  {
    cgmInRange: "70",
    totalInsulin: "54.6",
    totalCarbs: "131"
  }
];

export default historyData;
