import React from "react";
import { StyleProp, Text, TextStyle } from "react-native";
import fonts from "../../../theme/fonts";

interface Props {
  variant?: "text200" | "text400" | "text600" | "body1" | "body2" | "body3";
  label: string;
  styles?: StyleProp<TextStyle>;
}

const Paragraph: React.FC<Props> = ({ variant, label, styles }) => {
  const getVariant = () => {
    switch (variant) {
      case "text200":
        return fonts.text200;

      case "text400":
        return fonts.text400;

      case "text600":
        return fonts.text600;

      case "body1":
        return fonts.body1;

      case "body2":
        return fonts.body2;

      case "body3":
        return fonts.body3;

      default:
        return fonts.text200;
    }
  };

  return (
    <Text style={[getVariant(), { fontWeight: "400" }, styles && styles]}>
      {label}
    </Text>
  );
};

export default Paragraph;
