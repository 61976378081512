import React from "react"
import { View } from "react-native-web"
import theme from "../../../theme"

interface Props {
  activeTab: number
  index: number
}

const DashboardDot: React.FC<Props> = ({ activeTab, index }) => {
  return (
    <View
      style={{
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: activeTab === index ? theme.colors.blue : "#C8CACD",
      }}
    />
  )
}

export default DashboardDot
