let strings = {
  'en-US': {
    acknowledgement: 'Acknowledgement & Release',
    accept: 'I accept the terms above.',
    callInsulet: 'Call Insulet at ',
    customerCare: '<customer_care_helpline>',
    fillForm: 'Fill out a form at ',
    omnipodURL: 'www.omnipod.com/setup ',
    callBack: 'to get a call back.',
    health: 'Call your healthcare provider.',
    continue: 'Continue',
    termsOfUse:
      '1. I have reviewed the instructions, precautions, and warnings found in the User Guide provided with the Omnipod 5, and I fully understand those instructions, precautions, and warnings. \n\n2. I have received training regarding the setup and use of the Omnipod 5, and I fully understand the information that was provided during the training. \n\n3. I understand that improper setup and/or use of the Omnipod 5 may cause under- or over- delivery of insulin, which may lead to hypoglycemia or hyperglycemia, and may put my health and safety at risk, and could lead to death. \n\n4. I hearby release the manufacturers, designers, distributors, and sellers of the Omnipod 5 from any and all liability in connection with any injury I may sustain resulting from my setting up or using the Omnipod 5 in a manner that is inconsistent with the instructions, precautions, and warnings provided to me in the User Guide and during training.',
  },
  'en-GB': {
    acknowledgement: 'Acknowledgement & Release',
    accept: 'I accept the terms above.',
    callInsulet: 'Call Insulet at ',
    customerCare: '<customer_care_helpline>',
    fillForm: 'Fill out a form at ',
    omnipodURL: 'www.omnipod.com/setup ',
    callBack: 'to get a call back.',
    health: 'Call your healthcare provider.',
    continue: 'Continue',
    termsOfUse:
      '1. I have reviewed the instructions, precautions, and warnings found in the User Guide provided with the Omnipod 5, and I fully understand those instructions, precautions, and warnings. \n\n2. I have received training regarding the setup and use of the Omnipod 5, and I fully understand the information that was provided during the training. \n\n3. I understand that improper setup and/or use of the Omnipod 5 may cause under- or over- delivery of insulin, which may lead to hypoglycemia or hyperglycemia, and may put my health and safety at risk, and could lead to death. \n\n4. I hearby release the manufacturers, designers, distributors, and sellers of the Omnipod 5 from any and all liability in connection with any injury I may sustain resulting from my setting up or using the Omnipod 5 in a manner that is inconsistent with the instructions, precautions, and warnings provided to me in the User Guide and during training.',
  },
  'es-US': {
    acknowledgement: 'Reconocimiento y exención de responsabilidad',
    accept: 'Acepto los términos expuestos anteriormente.',
    callInsulet: 'Call Insulet at ',
    customerCare: '<customer_care_helpline>',
    fillForm: 'Fill out a form at ',
    omnipodURL: 'www.omnipod.com/setup ',
    callBack: 'to get a call back.',
    health: 'Call your healthcare provider.',
    continue: 'Continue',
    termsOfUse:
      '1. He revisado las instrucciones, precauciones y advertencias de esta Guía del usuario proporcionada con Omnipod 5, y comprendo por completo esas instrucciones, precauciones y advertencias. \n\n2. He recibido la capacitación en torno a la configuración y uso de Omnipod 5, y comprendo por completo la información que obtuve durante la capacitación. \n\n3. Comprendo que la configuración y/o el uso inapropiado de Omnipod 5 podría causar una administración insuficiente o excesiva de insulina, lo que podría generar hipoglucemia o hiperglucemia, y poner mi salud y seguridad en riesgo, o incluso causarme la muerte. \n\n4. Por la presente exonero a los fabricantes, diseñadores, distribuidores y vendedores de Omnipod 5 de toda responsabilidad relacionada con cualquier lesión que pudiera padecer como resultado de configurar o usar Omnipod 5 de una forma que no sea acorde a las instrucciones, precauciones y advertencias proporcionadas en la Guía del usuario y durante la capacitación.',
  },
  'de-DE': {
    acknowledgement: 'Zustimmung und Freigabe',
    accept: 'I accept the terms above.',
    callInsulet: 'Call Insulet at ',
    customerCare: '<customer_care_helpline>',
    fillForm: 'Fill out a form at ',
    omnipodURL: 'www.omnipod.com/setup ',
    callBack: 'to get a call back.',
    health: 'Call your healthcare provider.',
    continue: 'Continue',
    termsOfUse:
      '1. I have reviewed the instructions, precautions, and warnings found in the User Guide provided with the Omnipod 5, and I fully understand those instructions, precautions, and warnings. \n\n2. I have received training regarding the setup and use of the Omnipod 5, and I fully understand the information that was provided during the training. \n\n3. I understand that improper setup and/or use of the Omnipod 5 may cause under- or over- delivery of insulin, which may lead to hypoglycemia or hyperglycemia, and may put my health and safety at risk, and could lead to death. \n\n4. I hearby release the manufacturers, designers, distributors, and sellers of the Omnipod 5 from any and all liability in connection with any injury I may sustain resulting from my setting up or using the Omnipod 5 in a manner that is inconsistent with the instructions, precautions, and warnings provided to me in the User Guide and during training.',
  },
  'nl-NL': {
    acknowledgement: 'Erkenning en ontheffing',
    accept: 'I accept the terms above.',
    callInsulet: 'Call Insulet at ',
    customerCare: '<customer_care_helpline>',
    fillForm: 'Fill out a form at ',
    omnipodURL: 'www.omnipod.com/setup ',
    callBack: 'to get a call back.',
    health: 'Call your healthcare provider.',
    continue: 'Continue',
    termsOfUse:
      '1. I have reviewed the instructions, precautions, and warnings found in the User Guide provided with the Omnipod 5, and I fully understand those instructions, precautions, and warnings. \n\n2. I have received training regarding the setup and use of the Omnipod 5, and I fully understand the information that was provided during the training. \n\n3. I understand that improper setup and/or use of the Omnipod 5 may cause under- or over- delivery of insulin, which may lead to hypoglycemia or hyperglycemia, and may put my health and safety at risk, and could lead to death. \n\n4. I hearby release the manufacturers, designers, distributors, and sellers of the Omnipod 5 from any and all liability in connection with any injury I may sustain resulting from my setting up or using the Omnipod 5 in a manner that is inconsistent with the instructions, precautions, and warnings provided to me in the User Guide and during training.',
  },
  'fr-FR': {
    acknowledgement: 'Reconnaissance et publication',
    accept: 'I accept the terms above.',
    callInsulet: 'Call Insulet at ',
    customerCare: '<customer_care_helpline>',
    fillForm: 'Fill out a form at ',
    omnipodURL: 'www.omnipod.com/setup ',
    callBack: 'to get a call back.',
    health: 'Call your healthcare provider.',
    continue: 'Continue',
    termsOfUse:
      '1. I have reviewed the instructions, precautions, and warnings found in the User Guide provided with the Omnipod 5, and I fully understand those instructions, precautions, and warnings. \n\n2. I have received training regarding the setup and use of the Omnipod 5, and I fully understand the information that was provided during the training. \n\n3. I understand that improper setup and/or use of the Omnipod 5 may cause under- or over- delivery of insulin, which may lead to hypoglycemia or hyperglycemia, and may put my health and safety at risk, and could lead to death. \n\n4. I hearby release the manufacturers, designers, distributors, and sellers of the Omnipod 5 from any and all liability in connection with any injury I may sustain resulting from my setting up or using the Omnipod 5 in a manner that is inconsistent with the instructions, precautions, and warnings provided to me in the User Guide and during training.',
  },
};

export { strings };
