import { useState } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AppRoutesEnum } from 'web/routes/types';
import { TopBar } from 'web/components/ios/TopBar';
import { useIntl } from 'react-intl';
import { CustomFoodScreen } from './views/CustomFoodScreen';
import { AddCustomFoodScreen } from './views/AddCustomFoodScreen';
import { IOSCustomFoodsEnums } from './types';
import { CustomFoodType } from 'web/store/reducers/customFoods/reducer';
import { CustomFoodsStackMessages } from './CustomFoods.messages';

const Stack = createStackNavigator();

export const CustomFoodsStack = () => {
  const intl = useIntl();
  const [editMode, setEditMode] = useState(false);
  const [editCustomFood, setEditCustomFood] = useState<CustomFoodType>();

  return (
    <Stack.Navigator
      initialRouteName={AppRoutesEnum.CustomFoods}
      screenOptions={{
        header: (props) => (
          <TopBar
            {...props}
            editMode
            editModeText={
              editMode
                ? intl.formatMessage(CustomFoodsStackMessages.doneButton)
                : intl.formatMessage(CustomFoodsStackMessages.editButton)
            }
            editOnClickHandler={() => setEditMode(!editMode)}
          />
        ),
      }}
    >
      <Stack.Screen
        name={IOSCustomFoodsEnums.CustomFoodScreen}
        options={{
          headerTitle: intl.formatMessage(
            CustomFoodsStackMessages.customFoodsScreenHeaderTitle
          ),
        }}
      >
        {() => (
          <CustomFoodScreen
            isEditModeActive={editMode}
            setEditCustomFood={setEditCustomFood}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={IOSCustomFoodsEnums.AddCustomFoodScreen}
        options={{
          header: (props) => <TopBar {...props} />,
          headerTitle: intl.formatMessage(
            CustomFoodsStackMessages.addCustomFoodsHeaderTitle
          ),
        }}
      >
        {() => (
          <AddCustomFoodScreen
            editCustomFood={editCustomFood}
            setEditCustomFood={setEditCustomFood}
          />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
};
