import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import theme from 'mobile/latest/theme';
import { Container, Card } from 'web/components/helpers/Layout/Layout';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { Button } from 'mobile/latest/components/atoms';
import { BottomInnerNav } from 'mobile/latest/components/molecules';
import { ManageSensorL2Paths } from 'web/routes/L2/ManageSensorL2Stack';
import { messages } from './ManageSensorL2.messages';
import { ReactComponent as ChevronRight } from 'assets/chevron-right.svg';

const styles = StyleSheet.create({
  currentSensorContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  switch: {
    display: 'flex',
    color: theme.colors.purple,
    alignItems: 'center',
  },
  disclaimerAdd: {
    textAlign: 'center',
  },
  sensorInfoContainer: {
    backgroundColor: theme.colors.pureBlack,
  },
  buttonContainer: {
    marginBottom: theme.layout.spacing.normal,
  },
  textWhite: {
    color: theme.colors.white,
  },
  serialNumber: {
    fontWeight: '300',
  },
  sensorBar: {
    flexDirection: 'row',
    marginVertical: theme.layout.spacing.small,
    alignItems: 'stretch',
  },
  sensorBarBlock: {
    backgroundColor: theme.colors.white,
    flex: 1,
    height: 10,
  },
  backgroundGreen: {
    backgroundColor: theme.colors.greenText,
  },
  marginRight: {
    marginRight: 4,
  }
});

const SensorBars: React.FC = () => {
  const renderSegments = () => {
    const segments = [];
    for (let i = 0; i < 15; i++) {
      const extraStyles = [];
      if (i < 14) {
        extraStyles.push(styles.marginRight);
        extraStyles.push(styles.backgroundGreen);
      }
      segments[i] = <View style={[styles.sensorBarBlock, ...extraStyles]} />;
    }

    return segments;
  };

  return <View style={[styles.sensorBar]}>{renderSegments()}</View>;
};

export const ManageSensorL2: React.FC = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const handleSwitchClick = () => navigate(ManageSensorL2Paths.switchSensor);
  const handleSensorSettingsClick = () => navigate(ManageSensorL2Paths.sensorSettings);
  const handleDeleteSensor = () => {};

  return (
    <Container hasBottomNav>
      <View>
        <Card>
          <View style={[styles.currentSensorContainer]}>
            <View>
              <Text style={[theme.fonts.text200]}><FormattedMessage {...messages.currentSensor} /></Text>
              <Text style={[theme.fonts.body2]}>FreeStyle Libre 2 Plus</Text>
            </View>
            <TouchableOpacity onPress={handleSwitchClick}>
              <Text style={[theme.fonts.body2, styles.switch]}>
                <FormattedMessage {...messages.switchCta} />
                <ChevronRight />
              </Text>
            </TouchableOpacity>
          </View>
        </Card>

        <View style={[styles.sensorInfoContainer]}>
          <Card>
            <Text style={[theme.fonts.text400, styles.textWhite]}>
              <FormattedMessage {...messages.endsIn} />
            </Text>
            <SensorBars />
            <Text
              style={[theme.fonts.body2, styles.textWhite, styles.serialNumber]}
            >
              <FormattedMessage {...messages.serialNumber} />
            </Text>
          </Card>
        </View>

        <Card>
          <Text style={[theme.fonts.text400, styles.disclaimerAdd]}>
            <FormattedMessage {...messages.deleteSensorDisclaimer} />
          </Text>
        </Card>
      </View>
      <Card>
        <View style={[styles.buttonContainer]}>
          <Button onPress={handleSensorSettingsClick} variant="primary">
            <FormattedMessage {...messages.sensorSettingsCta} />
          </Button>
        </View>
      </Card>
      <BottomInnerNav
        leftActionText={intl.formatMessage(messages.close)}
        rightActionText={intl.formatMessage(messages.deleteSensorCta)}
        rightAction={handleDeleteSensor}
      />
    </Container>
  );
};
