import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Modal } from '../../Modal';
import { hideModal } from 'web/store/reducers/modals/modalsSlice';
import {
  CancelEditingCustomFoodsMesssages,
  ConfirmDeleteCustomFoodsMessages,
  MaxCustomFoodsReachedMessages,
} from './CustomFoodsModals.messages';

export const MaxCustomFoodsReached: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <Modal
      rightButtonText={'OK'}
      rightButtonOnClick={() => dispatch(hideModal())}
      children={
        <div className="font-ios">
          <p className=" text-regular font-bold">
            <FormattedMessage {...MaxCustomFoodsReachedMessages.title} />
          </p>
          <p className="mt-0.5">
            <FormattedMessage {...MaxCustomFoodsReachedMessages.text} />
          </p>
        </div>
      }
    />
  );
};
interface ConfirmDeleteCustomFoodsProps {
  rightButtonOnClickHandler: () => void;
}

export const ConfirmDeleteCustomFoods: React.FC<
  ConfirmDeleteCustomFoodsProps
> = ({ rightButtonOnClickHandler }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Modal
      rightButtonText={intl.formatMessage(
        ConfirmDeleteCustomFoodsMessages.rightButton
      )}
      rightButtonOnClick={rightButtonOnClickHandler}
      leftButtonText={intl.formatMessage(
        ConfirmDeleteCustomFoodsMessages.leftButton
      )}
      leftButtonOnClick={() => dispatch(hideModal())}
      title={intl.formatMessage(ConfirmDeleteCustomFoodsMessages.title)}
    />
  );
};

interface CancelEditingCustomFoodsProps {
  rightButtonOnClickHandler: () => void;
}

export const CancelEditingCustomFoods: React.FC<
  CancelEditingCustomFoodsProps
> = ({ rightButtonOnClickHandler }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Modal
      rightButtonText={intl.formatMessage(
        CancelEditingCustomFoodsMesssages.rightButton
      )}
      rightButtonOnClick={rightButtonOnClickHandler}
      leftButtonText={intl.formatMessage(
        CancelEditingCustomFoodsMesssages.leftButton
      )}
      leftButtonOnClick={() => dispatch(hideModal())}
      title={intl.formatMessage(CancelEditingCustomFoodsMesssages.title)}
      children={intl.formatMessage(CancelEditingCustomFoodsMesssages.text)}
    />
  );
};
