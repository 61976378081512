import React from 'react';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from 'web/components/ios/Button';
import { SettingsRow } from 'web/components/ios/SettingsRow';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
  hideModal,
  setCurrentModal,
  showModal,
} from 'web/store/reducers/modals/modalsSlice';
import { PodCommunicationModal } from 'web/components/ios/modals/GlobalModals/GlobalModals';
import { TargetGlucoseSegmentScreenValues } from './TargetGlucoseSegmentScreen';
import { BolusSettingsRoutesEnum } from '../../../types';
import { targetGlucoseMessages } from '../../../BolusSettings.messages';

export const TargetGlucoseMain: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const handlePodCommunicationTimeout = () => {
    dispatch(hideModal());
    navigate(BolusSettingsRoutesEnum.BolusSettingsMain);
  };

  const handleSave = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <PodCommunicationModal onTimeout={handlePodCommunicationTimeout} />
        ),
      })
    );
    dispatch(showModal());
  };

  const navigateToSegment = (props: TargetGlucoseSegmentScreenValues) => () => {
    navigate(BolusSettingsRoutesEnum.SegmentScreen, props);
  };

  return (
    <div className="bg-white h-full pt-[29px] pb-[34px] flex flex-col justify-between text-regular font-ios">
      <div>
        <div className="px-2">
          <div className="mb-3">
            <FormattedMessage {...targetGlucoseMessages.textOne} />
          </div>
          <div className="mb-3">
            <FormattedMessage {...targetGlucoseMessages.textTwo} />
          </div>
          <div className="mb-3">
            <FormattedMessage {...targetGlucoseMessages.textThree} />
          </div>
          <div className="mb-[37px]">
            <FormattedMessage {...targetGlucoseMessages.textFour} />
          </div>
        </div>

        <div className="flex justify-between items-center px-2 pb-[12px] border-b-1 border-b-ios-gray-9/[.36]">
          <div>
            <FormattedMessage {...targetGlucoseMessages.timeSegments} />
          </div>
          <div className="text-ios-gray-9/60">
            <FormattedMessage {...targetGlucoseMessages.totalSegments} />
          </div>
        </div>

        <div>
          <SettingsRow
            text={intl.formatMessage(targetGlucoseMessages.segmentOneTitle)}
            // @ts-ignore it complaints due to value typings, but it's still treated as a string.
            subText={intl.formatMessage(targetGlucoseMessages.segmentOneValue, {
              br: <br />,
            })}
            showCaret
            onClick={navigateToSegment({
              segmentNumber: 1,
              startTime: moment().hour(0).minutes(0),
              endTime: moment().hour(8).minutes(0),
              targetGlucose: 130,
              correctAbove: 150,
            })}
          />
          <SettingsRow
            text={intl.formatMessage(targetGlucoseMessages.segmentTwoTitle)}
            // @ts-ignore it complaints due to value typings, but it's still treated as a string.
            subText={intl.formatMessage(targetGlucoseMessages.segmentTwoValue, {
              br: <br />,
            })}
            showCaret
            onClick={navigateToSegment({
              segmentNumber: 2,
              startTime: moment().hour(8).minutes(0),
              endTime: moment().hour(0).minutes(0),
              targetGlucose: 110,
              correctAbove: 150,
            })}
          />
        </div>
      </div>

      <div className="px-2">
        <Button
          variant="colored"
          colorMode="automated"
          full
          onClick={handleSave}
        >
          <FormattedMessage {...targetGlucoseMessages.cta} />
        </Button>
      </div>
    </div>
  );
};
