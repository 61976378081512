import { FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/core';
import { isEqual } from 'lodash';
import React from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as LogoSVG } from '../../../../../../../assets/Omnipod_5_LOGO_RGB_MANGO_TM.svg';
import { ReactComponent as LogoSimulator } from '../../../../../../../assets/Omnipod_5_Simulator_LOGO_MANGO.svg';
import LANGUAGES from '../../../../../data/languages';
import { getLanguage } from '../../../../../helpers/getLanguage';
import { getLanguageLong } from '../../../../../helpers/getLanguageLong';
import useLanguageContent from '../../../../../hooks/useLanguageContent';
import {
  LangState,
  languageActions,
} from '../../../../../../../web/store/reducers/language';
import { UserState } from '../../../../../../../web/store/reducers/user';
import { RootState } from '../../../../../../../web/store/store';
import theme from '../../../../../theme';
import { LanguageType } from '../../../../../types/language';
import { LanguageLongType } from '../../../../../types/languageLong';
import { BottomInnerNav } from '../../../../molecules';
import CustomRadioButton from '../../../../molecules/customRadioButton';
import { strings } from './LanguageSettingsScreenContent';
import { messages } from './LanguageSettingsScreen.messages';

const LanguageSettingsScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [selectedLanguageLong, setSelectedLanguageLong] =
    React.useState<LanguageLongType>(getLanguageLong(langState.language));

  const [comingFromSettings] = React.useState<boolean>(
    userState.podConfigured && userState.hasTXId
  );

  const { languageContent } = useLanguageContent(strings);
  const { navigate } = useNavigation();
  const dispatch = useDispatch();

  const handleRadioButtonPress = (label: LanguageLongType) => {
    setSelectedLanguageLong(label);
  };

  const getUnits = (language: string) => {
    return {
      'en-US': 'mg/dL',
      'es-US': 'mg/dL',
      'de-DE': 'mg/dL',
      'en-GB': 'mmol/L',
      'nl-NL': 'mmol/L',
      'fr-FR': 'mg/dL',
    }[language];
  };

  const get24hTime = (language: string) => {
    return {
      'en-US': false,
      'es-US': false,
      'de-DE': true,
      'en-GB': true,
      'nl-NL': true,
      'fr-FR': false,
    }[language];
  };

  return (
    <>
      <View style={{ height: '95%', width: '100%', backgroundColor: 'white' }}>
        <View style={styles.section}>
          {userState.podConfigured && userState.hasTXId ? (
            <LogoSimulator
              height={245}
              width={245}
              style={{ alignSelf: 'center', marginBottom: -54 }}
            />
          ) : (
            <LogoSVG
              height={245}
              width={245}
              style={{ alignSelf: 'center', marginBottom: -54 }}
            />
          )}
        </View>
        <View style={styles.section}>
          <Text style={theme.fonts.body2}>
            <FormattedMessage {...messages.selectLanguageTitle} />
          </Text>
        </View>
        <ScrollView>
          {LANGUAGES.map(
            (
              node: {
                label: LanguageLongType;
                value: LanguageType;
              },
              index: number
            ) => (
              <View key={`${index}--${node.label}`} style={styles.section}>
                <CustomRadioButton
                  locale={langState.locale}
                  item={node}
                  index={index}
                  handleRadioButtonPress={(label: string) => {
                    if (label === 'English (US)') {
                      handleRadioButtonPress(label as LanguageLongType);
                    }
                  }}
                  selectedItem={selectedLanguageLong}
                  disabled={selectedLanguageLong !== node.label}
                />
              </View>
            )
          )}
        </ScrollView>
      </View>
      <BottomInnerNav
        leftActionEmpty={comingFromSettings ? false : true}
        leftActionText={comingFromSettings ? languageContent.back : ''}
        leftNavigationAction={() => navigate('default')}
        rightActionText={languageContent.continue}
        rightAction={() => {
          dispatch(
            languageActions.languageUpdate(
              {
                language: getLanguage(selectedLanguageLong) as LanguageType,
                locale: getLanguage(selectedLanguageLong),
                units: getUnits(
                  getLanguage(selectedLanguageLong) as LanguageType
                ) as 'mg/dL' | 'mmol/L',
                is24Hour: get24hTime(
                  getLanguage(selectedLanguageLong) as LanguageType
                ),
              },
              langState
            )
          );
          userState.podConfigured && userState.hasTXId
            ? navigate('default')
            : navigate('SelectCountrySettings');
        }}
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  section: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grayScale.gray600,
    width: '100%',
  },
  logoStyle: {
    alignSelf: 'center',
  },
});

export default LanguageSettingsScreen;
