import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import theme from "../../../../mobile/latest/theme";

const HomeHeader: React.FC = () => {
  const auth = getAuth();
  const [currentUser, setCurrentUser] = React.useState(auth.currentUser);

  const handleSignout = async () => {
    await signOut(auth);
  };

  React.useEffect(() => {
    const showSignOut = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    showSignOut();
  }, [auth]);

  return (
    <View style={styles.header}>
      <Text style={styles.logoText}>Insulet</Text>
      <View style={styles.bar} />
      <View>
        <Text style={styles.title}>OP5 Product Support Web Tool</Text>
      </View>
      {currentUser ? (
        <TouchableOpacity style={styles.sideNavText} onPress={handleSignout}>
          <Text style={styles.sideNavText}>Sign Out</Text>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    width: "100%",
    height: 80,
    paddingVertical: 6,
    backgroundColor: "#783cbc",
    borderRightColor: "#783cbc",
    borderWidth: 2,
    borderRightWidth: 7,
    borderColor: "#783cbc",
    flex: 1
  },
  logo: {
    width: "20%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: "white",
    borderRightWidth: 2,
    borderColor: "#783cbc"
  },
  title: {
    color: "white",
    fontSize: 36,
    marginTop: "auto", // Pushes the component to the middle of the y-axis
    marginBottom: "auto", // Pushes the component to the middle of the y-axis
    marginLeft: 32,
    fontWeight: "bold",
    fontFamily: theme.fonts.primary.normal
  },
  logoText: {
    color: "white",
    fontSize: 48,
    marginLeft: 48,
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: theme.fonts.primary.normal
  },
  bar: {
    width: 2,
    backgroundColor: "white",
    marginLeft: 36,
    marginRight: 10,
    height: 50,
    marginTop: "auto",
    marginBottom: "auto"
  },
  sideNavText: {
    fontSize: 24,
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: 6,
    color: "white",
    alignSelf: "flex-end",
    textDecorationLine: "underline",
    flex: 1
  }
});

export default HomeHeader;
