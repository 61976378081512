let strings = {
  "en-US": {
    bodyText1:
      "These limits are used in Glucose history displays and in Glucose reading statistics.",
    upperLimit: "Upper Limit",
    upperMax: "(max of %1)",
    lowerMax: "(max of %2)",
    lowerLimit: "Lower Limit",
    cancel: "Cancel",
    save: "Save",
    modalTitle: "Disabled The Simulator",
    modalBody: "Glucose Goal Range disabled for the simulation.",
    ok: "OK"
  },
  "en-GB": {
    bodyText1:
      "These limits are used in Glucose history displays and in Glucose reading statistics.",
    upperLimit: "Upper Limit",
    upperMax: "(max of %1)",
    lowerMax: "(max of %2)",
    lowerLimit: "Lower Limit",
    cancel: "Cancel",
    save: "Save",
    modalTitle: "Disabled The Simulator",
    modalBody: "Glucose Goal Range disabled for the simulation.",
    ok: "OK"
  },
  "de-DE": {
    bodyText1:
      "Diese Grenzen werden in Glukose-Verlaufsanzeigen und Glukosewert-Statistiken verwendet.",
    upperLimit: "Obergrenze",
    upperMax: "(maximal %1)",
    lowerMax: "(maximal %2)",
    lowerLimit: "Untergrenze",
    cancel: "Abbr.",
    save: "Speichern",
    modalTitle: "Simulator deaktiviert",
    modalBody: "Der Glukose-Zielbereich wurde für die Simulation deaktiviert.",
    ok: "OK"
  },
  "es-US": {
    bodyText1:
      "Estos límites se usan al mostrar el historial de glucosa y en las estadísticas de lecturas de glucosa.",
    upperLimit: "Límite superior",
    upperMax: "(máx. de %1)",
    lowerMax: "(máx. de %2)",
    lowerLimit: "Límite inferior",
    cancel: "Cancelar",
    save: "Guardar",
    modalTitle: "Desactivado en el simulador",
    modalBody:
      "Rango del objetivo de glucosa no está disponible en la simulación.",
    ok: "Aceptar"
  },
  "fr-FR": {
    bodyText1:
      "Limites utilisées dans l'historique de glycémie et les statistiques de relevés de glycémie.",
    upperLimit: "Limite supérieure",
    upperMax: "(maximum de %1)",
    lowerMax: "(maximum de %2)",
    lowerLimit: "Limite inférieure",
    cancel: "Annuler",
    save: "Enregistrer",
    modalTitle: "Le simulateur a été désactivé",
    modalBody: "La plage cible est désactivée pour la simulation.",
    ok: "OK"
  },
  "nl-NL": {
    bodyText1:
      "Deze grenzen worden gebruikt in de glucosegeschiedenis en in de glucoseschermen.",
    upperLimit: "Bovengrens",
    upperMax: "(max. van %1)",
    lowerMax: "(max. van %2)",
    lowerLimit: "Ondergrens",
    cancel: "Annuleren",
    save: "Opslaan",
    modalTitle: "Uitgeschakeld voor simulator.",
    modalBody: "Glucosedoelbereik is uitgeschakeld voor de simulatie.",
    ok: "OK"
  }
};

export { strings };
