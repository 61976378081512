let strings = {
  "en-US": {
    personalizeController: "Personalize Controller",
    selectBackground: "Select a Background",
    lsDisplay: "This background displays on your lock screen.",
    continue: "Continue",
    selectBackgroundModalTitle: "Select Background",
    selectBackgroundModalMessage:
      "You can select a custom background for your device when it is locked. This feature is disabled for the simulator.",
    confirmText: "OK"
  },
  "en-GB": {
    personalizeController: "Personalise your Controller",
    selectBackground: "Select a Background",
    lsDisplay: "This background displays on your lock screen.",
    continue: "Continue",
    selectBackgroundModalTitle: "Select Background",
    selectBackgroundModalMessage:
      "You can select a custom background for your device when it is locked. This feature is disabled for the simulator.",
    confirmText: "OK"
  },
  "de-DE": {
    personalizeController: "Omnipod\u00A05 individualisieren",
    selectBackground: "Hintergrund auswählen",
    lsDisplay: "Dieser Hintergrund wird auf Ihrem Sperrbildschirm angezeigt.",
    continue: "Weiter",
    selectBackgroundModalTitle: "Hintergrund auswählen",
    selectBackgroundModalMessage:
      "Sie können einen eigenen Hintergrund als Sperrbildschirm für Ihr Gerät auswählen. Diese Funktion ist für den Simulator deaktiviert.",
    confirmText: "OK"
  },
  "es-US": {
    personalizeController: "Personalizar Omnipod 5",
    selectBackground: "Seleccione un fondo de pantalla",
    lsDisplay: "Este fondo aparece en su pantalla de bloqueo.",
    continue: "Continuar",
    selectBackgroundModalTitle: "Seleccionar fondo",
    selectBackgroundModalMessage:
      "Puede seleccionar un fondo personalizado para su dispositivo cuando está bloqueado. Esta función está inhabilitada para el simulador.",
    confirmText: "Aceptar"
  },
  "fr-FR": {
    personalizeController: "Personnaliser le contrôleur",
    selectBackground: "Choisir un fond d'écran",
    lsDisplay: "Ce fond d'écran s'affiche sur votre écran de verrouillage.",
    continue: "Continuer",
    selectBackgroundModalTitle: "Choisir un fond d'écran",
    selectBackgroundModalMessage:
      "Vous pouvez sélectionner un fond d'écran personnalisé pour votre dispositif lorsqu'il est verrouillé. Cette fonction est désactivée pour le simulateur.",
    confirmText: "OK"
  },
  "nl-NL": {
    personalizeController: "Personaliseren Omnipod 5",
    selectBackground: "Selecteer achtergrond",
    lsDisplay: "Deze achtergrond wordt weergegeven op uw vergrendelingsscherm.",
    continue: "Doorgaan",
    selectBackgroundModalTitle: "Selecteer achtergrond",
    selectBackgroundModalMessage:
      "U kunt een aangepaste achtergrond selecteren voor uw apparaat wanneer het vergrendeld is. Deze functie is uitgeschakeld voor de simulator.",
    confirmText: "OK"
  }
};

export { strings };
