import { createSlice } from '@reduxjs/toolkit';

const remindersSettingsSlice = createSlice({
  name: 'remindersSettings',
  initialState: {
    confidenceRemindersOn: true,
    programRemindersOn: true,
  },
  reducers: {
    setConfidenceRemindersValue: (state, action) => {
      const { value } = action.payload;
      state.confidenceRemindersOn = value;
    },
    setProgramRemindersValue: (state, action) => {
      const { value } = action.payload;
      state.programRemindersOn = value;
    },
  },
});

export const { setConfidenceRemindersValue, setProgramRemindersValue } =
  remindersSettingsSlice.actions;
export const reducer = remindersSettingsSlice.reducer;
