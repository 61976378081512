import React from "react";
import { StyleSheet, Text, View } from "react-native";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./HelpScreenContent";

const SignInScreen: React.FC = () => {
  const { languageContent } = useLanguageContent(strings);

  return (
    <View
      style={[
        theme.layout.wrapper,
        { width: "100%", backgroundColor: "white" }
      ]}>
      <View style={[styles.innerWrapper, { width: "100%" }]}>
        <Text style={[theme.fonts.h3, styles.title]}>
          {languageContent.help}
        </Text>
        <Text
          style={[
            theme.fonts.text600,
            { fontWeight: "300", textAlign: "left" }
          ]}>
          {languageContent.signIn}
        </Text>
        <Text
          style={[
            theme.fonts.text600,
            { fontWeight: "600", textAlign: "left", paddingTop: 16 }
          ]}>
          {languageContent.noAccount}
        </Text>
        <Text
          style={[
            theme.fonts.text600,
            { fontWeight: "300", textAlign: "left" }
          ]}>
          {languageContent.createAccount}
        </Text>
        <Text
          style={[
            theme.fonts.text600,
            { fontWeight: "600", textAlign: "left", paddingTop: 16 }
          ]}>
          {languageContent.forgotAccount}
        </Text>
        <Text
          style={[
            theme.fonts.text600,
            { fontWeight: "300", textAlign: "left" }
          ]}>
          {languageContent.resetPassword}
        </Text>
        <Text
          style={[
            theme.fonts.text600,
            { fontWeight: "600", textAlign: "left", paddingTop: 16 }
          ]}>
          {languageContent.otherQuestions}
        </Text>
        <Text
          style={[
            theme.fonts.text600,
            { fontWeight: "300", textAlign: "left" }
          ]}>
          {languageContent.customerCare}
        </Text>
      </View>
      <BottomInnerNav />
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 16,
    marginTop: 20
  },
  title: {
    margin: 16,
    textAlign: "center",
    fontWeight: "200"
  }
});

export default SignInScreen;
