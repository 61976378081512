import { isEqual } from "lodash"
import React from "react"
import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native"
import { useSelector } from "react-redux"
import replaceWithValues from "../../../helpers/replaceWithValues"
import truncate from "../../../helpers/truncate"
import useLanguageContent from "../../../hooks/useLanguageContent"
import { BasalProgram } from "../../../../../web/store/reducers/basal/reducer"
import { LangState } from "../../../../../web/store/reducers/language"
import { RootState } from "../../../../../web/store/store"
import theme from "../../../theme"
import { strings } from "./ExpertProgramCardModalContent"

// import CurrentBasalSegment from "../../organisms/currentBasalSegment"

const { height } = Dimensions.get("window")

export const GRAPH_MAX_HEIGHT = 60

interface ProgramCardProps {
  program: BasalProgram
  pressEdit: () => void
  pressStart: () => void
}

const ExpertProgramCardModal: React.FC<ProgramCardProps> = ({
  program,
  pressEdit,
  pressStart,
}) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual,
  )
  const [maxBasalRateCalc, setMaxBasalRateCalc] = React.useState<any>(3)
  const { languageContent } = useLanguageContent(strings)
  const programUnitsPerDay = program.unitsPerDay.toLocaleString(
    langState.locale,
  )
  const contentVariables = [programUnitsPerDay]

  React.useEffect(() => {
    const max = Math.max.apply(
      Math,
      program.segments.map((segment) => {
        return segment.basalRate
      }),
    )
    if (max > 3) {
      setMaxBasalRateCalc(max + 1)
    }
  }, [program.segments])

  return (
    <>
      <View style={styles.modalView}>
        <View style={styles.modalHeader}>
          <View>
            <Text
              style={[
                theme.fonts.h3,
                {
                  paddingLeft: 12,
                  paddingTop: 12,
                },
              ]}
            >
              {truncate(program.programName, 15)}
            </Text>
          </View>
        </View>
        {/* <View style={[styles.graphView, { height: GRAPH_MAX_HEIGHT }]}>
          {program.segments.map((segment, index) => (
            <CurrentBasalSegment
              key={index}
              startTime={segment.start}
              endTime={segment.end}
              segmentsArrLength={program.segments.length}
              maxHeight={GRAPH_MAX_HEIGHT}
              segment={segment}
              maxBasalRateCalc={maxBasalRateCalc}
              index={index}
              program={program}
              segmentEndTime=""
              displayOnly
            />
          ))}
        </View> 
        TODO: Implement Organism in PODWEB-15
        */}
        <View style={styles.footer}>
          <Text style={[theme.fonts.h4, styles.textTotal]}>
            {replaceWithValues(languageContent.total, contentVariables)}
          </Text>
          <View style={[{ flexDirection: "row" }]}>
            <TouchableOpacity onPress={pressEdit}>
              <Text style={[theme.fonts.body1, styles.textEdit]}>
                {languageContent.edit}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={pressStart}>
              <Text style={[theme.fonts.body1, styles.textStart]}>
                {languageContent.start}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  modalView: {
    backgroundColor: theme.colors.white,
    marginTop: height > 600 ? 375 : 325,
    marginHorizontal: 8,
    zIndex: 20,
    elevation: 3,
    shadowColor: "#888",
    shadowRadius: 3,
    shadowOpacity: 0.5,
    shadowOffset: {
      width: 0,
      height: 4,
    },
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 8,
  },
  textTotal: {
    paddingHorizontal: 16,
    marginVertical: 8,
  },
  textEdit: {
    paddingHorizontal: 16,
    marginVertical: 8,
    textTransform: "uppercase",
    color: theme.colors.purple,
    fontWeight: "bold",
  },
  textStart: {
    paddingHorizontal: 16,
    marginVertical: 8,
    textTransform: "uppercase",
    color: theme.colors.purple,
    fontWeight: "bold",
  },
  graphView: {
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "row",
  },
})
export default ExpertProgramCardModal
