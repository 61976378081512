import {
  CardStyleInterpolators,
  createStackNavigator
} from "@react-navigation/stack";
import { isEqual } from "lodash";
import React from "react";
import { Animated } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../../components/atoms";
import {
  ChangePodScreen,
  ChangePodScreenTwo,
  NewPodLandingScreen,
  NoActivePodScreen,
  PodDetailsScreen
} from "../../components/screens";
import { useModeIconSmall } from "../../hooks";
import useLanguageContent from "../../hooks/useLanguageContent";
import { UserState } from "../../../../web/store/reducers/user";
import { RootState } from "../../../../web/store/store";
import theme from "../../theme";
import { strings } from "./PodSetupStackContent";
import { NavState, navigationActions } from "../../../../web/store/reducers/navigation";
import DrawerStack from "./DrawerStack";

const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const PodSetupStack: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const dispatch = useDispatch();
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  const headerRef = new Animated.ValueXY().y;

  const animateHeader = React.useCallback(() => {
    Animated.timing(headerRef, {
      toValue: 0,
      duration: 600,
      useNativeDriver: false
    }).start();
  }, [headerRef]);

  React.useEffect(() => {
    dispatch(
      navigationActions.navigationUpdate({ navigateTo: "PodSetup" }, navState)
    );
  }, []);

  React.useEffect(() => {
    animateHeader();
  }, [animateHeader]);

  return (
    <>
      <Stack.Navigator
        initialRouteName={userState.hasTXId ? "ChangePod" : "Landing"}
        mode="card">
        <Stack.Screen
          name="Landing"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: "horizontal"
          }}
          component={NewPodLandingScreen}
        />

        <Stack.Screen
          name="ChangePodTwo"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: "horizontal",
            headerLeft: () => (
              <BackButton
                text={languageContent.changePod}
                white={false}
                isFTS
              />
            ),
            headerRight: () => (userState.hasTXId ? icon : null)
          }}
          component={ChangePodScreenTwo}
        />
        <Stack.Screen
          name="ChangePod"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: "horizontal",
            headerLeft: () => (
              <BackButton
                text={languageContent.changePod}
                isFTS
                white={false}
              />
            ),
            headerRight: () => (userState.hasTXId ? icon : null)
          }}
          component={ChangePodScreen}
        />
        <Stack.Screen
          name="PodDetails"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: "horizontal",
            headerLeft: () => (
              <BackButton
                text={languageContent.changePod}
                isFTS
                white={false}
              />
            ),
            headerRight: () => (userState.hasTXId ? icon : null)
          }}
          component={PodDetailsScreen}
        />
        <Stack.Screen
          name="NoActivePod"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: "horizontal",
            headerLeft: () => (
              <BackButton
                text={languageContent.pod}
                white={false}
                navigateTo="Drawer"
                isFTS
                disabled={true}
              />
            ),
            headerRight: () => (userState.hasTXId ? icon : null)
          }}
          component={NoActivePodScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default PodSetupStack;
