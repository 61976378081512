let strings = {
  "en-US": {
    setCorrect: "Set Correction Factors",
    valueIndicates:
      "A value that indicates how much one unit of insulin will lower your glucose.",
    example:
      "For example, if your Correction Factor is %1, one unit of insulin lowers your blood glucose by %2.",
    cancel: "Cancel",
    next: "Next"
  },
  "en-GB": {
    setCorrect: "Set Correction Factors",
    valueIndicates:
      "A value that indicates how much one unit of insulin will lower your glucose.",
    example:
      "For example, if your Correction Factor is %1, one unit of insulin lowers your blood glucose by %2.",
    cancel: "Cancel",
    next: "Next"
  },
  "de-DE": {
    setCorrect: "Korrekturfaktoren festlegen",
    valueIndicates:
      "Ein Wert, der anzeigt, um welchen Wert eine Einheit Insulin Ihren Blutzucker senkt.",
    example:
      "Wenn der Korrekturfaktor z. B. %1 lautet, senkt eine Einheit Insulin Ihren Blutzucker um %2.",
    cancel: "Abbr.",
    next: "Weiter"
  },
  "es-US": {
    setCorrect: "Establecer los factores de corrección",
    valueIndicates:
      "Un valor que indica cuánto reducirá su glucosa una unidad de insulina.",
    example:
      "Por ejemplo, si su factor de correctión es 50, una unidad de glucosa reduce su glucosa en un %2.",
    cancel: "Cancelar",
    next: "Siguiente"
  },
  "fr-FR": {
    setCorrect: "Définir les facteurs de correction",
    valueIndicates:
      "Valeur indiquant la baisse de votre glucose entraîné par une unité d'insuline.",
    example:
      "Par exemple, si votre facteur de correction est de %1, une unité d'insuline réduit votre glucose de %2.",
    cancel: "Annuler",
    next: "Suivant"
  },
  "nl-NL": {
    setCorrect: "Correctiefactoren instellen",
    valueIndicates:
      "Een waarde die aangeeft hoeveel uw glucose daalt door één eenheid insuline.",
    example:
      "Als uw correctiefactor bijvoorbeeld %1 is, verlaagt één eenheid insuline uw glucose met %2.",
    cancel: "Annuleren",
    next: "Volgende"
  }
};

export { strings };
