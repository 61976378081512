import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const EnterBGMenuIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className={classes} width="28" height="28" rx="6"/>
      <rect className={classes} width="16" height="16" transform="translate(6 6)"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5335 15.6C18.5335 13.0248 15.0919 8.5432 13.7335 7.6832C12.3751 8.5432 8.9335 13.0248 8.9335 15.6C8.9335 18.2472 11.0863 20.4 13.7335 20.4C16.3799 20.4 18.5335 18.2472 18.5335 15.6M20.1335 15.6C20.1335 19.1344 17.2679 22 13.7335 22C10.1991 22 7.3335 19.1344 7.3335 15.6C7.3335 12.0656 12.1335 6 13.7335 6C15.3335 6 20.1335 12.0656 20.1335 15.6M16.9335 15.2C16.9335 16.9672 15.5007 18.4 13.7335 18.4C11.9663 18.4 10.5335 16.9672 10.5335 15.2C10.5335 13.4328 12.9335 10.4 13.7335 10.4C14.5335 10.4 16.9335 13.4328 16.9335 15.2"
        fill="white"
      />
    </svg>
  );
};
