import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Button } from 'web/components/ios/Button';
import { ReactComponent as CarbsSvg } from 'assets/ios/icons/carbs.svg';
import { ReactComponent as GlucoseSvg } from 'assets/ios/icons/glucose.svg';
import { ReactComponent as CheckmarkSvg } from 'assets/ios/icons/checkmark.svg';
import { CgmIcon } from 'web/components/ios/icons';
import {
  hideModal,
  showModal,
  setCurrentModal,
} from 'web/store/reducers/modals/modalsSlice';
import {
  selectCurrentOperationMode,
  selectIsAutoMode,
} from 'web/store/reducers/user/user.helpers';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';
import { setBolusDeliveryValues } from 'web/store/reducers/bolus/actions';
import { AppRoutesEnum } from 'web/routes/types';
import { BolusCalculationsDrawer } from '../components/BolusCalculationsDrawer';
import { messages } from '../BolusAdministration.messages';
import { BolusAdministrationRoutesEnum, BolusAdministrationStackParamList } from '../types';

export const BolusAdministrationMain: React.FC = () => {
  const dispatch = useDispatch();
  const { navigate, setParams } = useNavigation();
  const currentTime = moment();

  const { params } = useRoute<RouteProp<BolusAdministrationStackParamList, BolusAdministrationRoutesEnum.BolusAdministrationMain>>();

  const operationMode = useSelector(selectCurrentOperationMode);
  const isAutoMode = useSelector(selectIsAutoMode);
  const [carbs, setCarbs] = useState<number>(0);
  const [mealBolus, setMealBolus] = useState<number>(0);
  const [glucose, setGlucose] = useState<number>(0);
  const [correctionBolus, setCorrectionBolus] = useState<number>(0);
  const [totalBolus, setTotalBolus] = useState<number>(0);
  const [iobAdjusted, setIobAdjusted] = useState<boolean>(false);
  const [sensorUsed, setSensorUsed] = useState<boolean>(false);

  useEffect(() => {
    // We have to set the navigation action due to the Fast Travel menu.
    setParams({
      topBarOnLeftButtonClick: () => {
        dispatch(navigateToRoute(AppRoutesEnum.dashboard));
      },
    });
  }, []);

  useEffect(() => {
    if (params?.usingCustomFoods) {
      handleCarbsClick();
    }
  }, [params]);

  useEffect(() => {
    if (carbs !== 0 && glucose !== 0) {
      setTotalBolus(3);
      setIobAdjusted(true);
      dispatch(
        setBolusDeliveryValues({
          carbs,
          glucose,
          totalBolus: 3,
          sensorUsed,
          currentTime,
        })
      );
    }
  }, [carbs, glucose]);

  const handleCarbsClick = () => {
    setCarbs(10);
    setMealBolus(1);
  };

  const handleGlucoseClick = () => {
    setGlucose(192);
    setCorrectionBolus(5);
  };

  const handleUseSensorClick = () => {
    if (sensorUsed) {
      setSensorUsed(false);
      setGlucose(0);
      return;
    }

    setSensorUsed(true);
    setGlucose(121);
  };

  const handleShowCalculations = () => {
    if (totalBolus === 0) {
      return;
    }

    dispatch(
      setCurrentModal({
        modal: (
          <BolusCalculationsDrawer onClose={() => dispatch(hideModal())} />
        ),
      })
    );
    dispatch(showModal());
  };

  const handleConfirmClick = () => {
    navigate(BolusAdministrationRoutesEnum.ConfirmBolus);
  };

  return (
    <div className="bg-white h-full flex flex-col justify-between pb-[34px] pt-[17px]">
      <div>
        <div className="mb-[29px]">
          <div className="flex justify-between items-end px-2">
            <div className="flex text-small font-semibold">
              <div className="mr-[6px]">
                <CarbsSvg />
              </div>
              <div className="mt-0.5">
                <FormattedMessage {...messages.carbsName} />
              </div>
            </div>
            <div>
              <Button
                className="w-[116px]"
                variant="tinted"
                colorMode={operationMode}
                noSpacings
                onClick={() =>
                  navigate(BolusAdministrationRoutesEnum.BolusCustomFoods)
                }
              >
                <FormattedMessage {...messages.customFoodsButton} />
              </Button>
            </div>
          </div>

          <div
            className={classNames(
              'px-2 border-b-1 pt-1 pb-0.5 text-4xl font-bold mb-[15px] cursor-pointer',
              {
                'text-ios-gray-9/30': carbs === 0,
                'text-black': carbs !== 0,
              }
            )}
            onClick={handleCarbsClick}
          >
            <FormattedMessage
              {...messages.carbsValue}
              values={{ value: carbs }}
            />
          </div>

          <div className="text-ios-gray-9/60 px-2">
            <FormattedMessage
              {...messages.mealBolus}
              values={{ value: mealBolus }}
            />
          </div>
        </div>

        <div className="mb-[29px]">
          <div className="flex justify-between items-end px-2">
            <div className="flex text-small font-semibold">
              <div className="mr-[6px]">
                <GlucoseSvg />
              </div>
              <div className="mt-[2px]">
                {glucose === 0 && (
                  <FormattedMessage {...messages.glucoseName} />
                )}
                {glucose !== 0 && (
                  <FormattedMessage
                    {...messages.bgText}
                    values={{ time: currentTime.format('H:mm A') }}
                  />
                )}
              </div>
            </div>
            <div>
              <Button
                className="w-[116px]"
                variant="tinted"
                colorMode={operationMode}
                onClick={handleUseSensorClick}
                filled={sensorUsed}
                noSpacings
              >
                {sensorUsed && <CheckmarkSvg className="mr-0.5" />}
                <FormattedMessage {...messages.useSensorButton} />
              </Button>
            </div>
          </div>

          <div
            className={classNames(
              'px-2 border-b-1 pt-1 pb-0.5 text-4xl font-bold mb-[15px] cursor-pointer flex',
              {
                'text-ios-gray-9/30': glucose === 0,
                'text-black': glucose !== 0,
              }
            )}
            onClick={handleGlucoseClick}
          >
            <FormattedMessage
              {...messages.glucoseValue}
              values={{ value: glucose === 0 ? '- -' : glucose }}
            />
            {sensorUsed && (
              <CgmIcon
                className="ml-1 w-[38px] h-[38px]"
                operationMode={operationMode}
              />
            )}
          </div>

          <div className="text-ios-gray-9/60 px-2">
            <FormattedMessage
              {...messages.correctionBolus}
              values={{ value: correctionBolus }}
            />
          </div>
        </div>

        <div>
          <div className="flex justify-between items-end px-2">
            <div className="flex text-small font-semibold">
              <FormattedMessage {...messages.totalBolusName} />
            </div>
            <div>
              <Button
                className="w-[116px]"
                variant="tinted"
                colorMode={operationMode}
                noSpacings
                onClick={handleShowCalculations}
              >
                <FormattedMessage {...messages.calculationsButton} />
              </Button>
            </div>
          </div>

          <div
            className={classNames(
              'px-2 border-b-1 pt-1 pb-0.5 text-4xl font-bold mb-[15px] cursor-pointer',
              {
                'text-ios-gray-9/30': totalBolus === 0,
                'text-black': totalBolus !== 0,
              }
            )}
          >
            <FormattedMessage
              {...messages.totalBolusValue}
              values={{ value: totalBolus }}
            />
          </div>

          <div className="text-ios-gray-9/60 px-2">
            {iobAdjusted ? (
              <FormattedMessage {...messages.adjustedIob} />
            ) : (
              <FormattedMessage {...messages.iob} />
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-row px-2">
        {!isAutoMode && (
          <Button
            className="mr-1"
            full
            disabled={!iobAdjusted}
            onClick={() =>
              navigate(
                BolusAdministrationRoutesEnum.ExtendedBolusAdministration
              )
            }
          >
            <FormattedMessage {...messages.extendBolusCta} />
          </Button>
        )}
        <Button
          className={classNames({ 'ml-1': !isAutoMode })}
          full
          colorMode={operationMode}
          variant="colored"
          disabled={!iobAdjusted}
          onClick={handleConfirmClick}
        >
          <FormattedMessage {...messages.confirmCta} />
        </Button>
      </div>
    </div>
  );
};
