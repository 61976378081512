import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { TouchableOpacity } from "react-native-web";
import { useSelector } from "react-redux";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { Paragraph } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./MaxBolusSettingsScreenContent";

const MaxBolusSettingsScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [value, setValue] = React.useState<string>("--");
  const [isPressed, setIsPressed] = React.useState<boolean>(false);

  const { goBack } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const lowerRange = (0.05).toLocaleString(langState.locale);
  const contentVariables = [lowerRange];

  const handlePress = () => {
    setValue("10");
    setIsPressed(true);
  };

  return (
    <View style={[styles.container, { backgroundColor: "white" }]}>
      <View>
        <View style={[{ paddingHorizontal: 16, paddingTop: 24 }]}>
          <Paragraph variant="body2" label={languageContent.header} />
          <View style={[{ marginTop: 24 }]}>
            <Text style={[theme.fonts.h5]}>{languageContent.maxBolus}</Text>
            <Text
              style={[
                theme.fonts.body2,
                { color: theme.colors.grayScale.gray400 }
              ]}>
              {replaceWithValues(languageContent.range, contentVariables)}
            </Text>
          </View>
          <TouchableOpacity onPress={handlePress}>
            <View style={[theme.layout.inputContainerStyle, { marginTop: 8 }]}>
              <Text style={[theme.layout.inputStyle]}>{value}</Text>
              <Text style={[theme.layout.adornment]}>{languageContent.u}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.save}
        rightActionDisabled={!isPressed}
        rightAction={() => goBack()}
        rightActionStyle={{
          color: isPressed ? theme.colors.purple : theme.colors.placeholder
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between"
  }
});

export default MaxBolusSettingsScreen;
