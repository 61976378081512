import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { isEqual } from 'lodash';
import {
  LangState,
} from 'web/store/reducers/language';
import { RootState } from 'web/store/store';

const IntlProviderWrapper = ({ children }: PropsWithChildren) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual,
  );

  return (
    <IntlProvider key={langState.locale} locale={langState.locale} messages={langState.messages}>
      {children}
    </IntlProvider>
  );
};

export default IntlProviderWrapper;
