import React, { PropsWithChildren } from 'react';
import { View, StyleSheet } from 'react-native';
import theme from 'mobile/latest/theme';

interface ContainerProps {
  hasBottomNav?: boolean;
}

export const Container: React.FC<PropsWithChildren<ContainerProps>> = ({ children, hasBottomNav = false }) => {
  const style = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
      backgroundColor: theme.colors.white,
      justifyContent: 'space-between',
      paddingBottom: hasBottomNav ? 50 : 16,
    },
  });

  return <View style={style.container}>{children}</View>;
};

export const Card: React.FC<PropsWithChildren> = ({ children }) => {
  const style = StyleSheet.create({
    card: {
      width: '100%',
      padding: 16,
    },
  });

  return <View style={style.card}>{children}</View>;
};
