import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { Button } from 'web/components/ios/Button';
import { useIntl } from 'react-intl';
import { messages } from '../Bolus.messages';

interface CreateTargetGlucoseAndCorrectAboveSegmentProps {
  bolusSegmentTitle: string;
  startTimeValue: string;
  endTimeValue: string;
  targetGlucoseValue: string;
  correctAboveValue: string;
  navigationRoute: string;
  editMode: boolean;
}

export const CreateTargetGlucoseAndCorrectAboveSegment: React.FC<
  CreateTargetGlucoseAndCorrectAboveSegmentProps
> = ({
  bolusSegmentTitle,
  startTimeValue,
  endTimeValue,
  targetGlucoseValue,
  correctAboveValue,
  navigationRoute,
  editMode,
}) => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const [endTimeIsClicked, setEndTimeIsClicked] = useState(false);
  const [targetGlucoseIsClicked, setTargetGlucoseIsClicked] = useState(false);
  const [correctAboveIsClicked, setCorrectAboveIsClicked] = useState(false);

  useEffect(() => {
    setEndTimeIsClicked(editMode);
    setTargetGlucoseIsClicked(editMode);
    setCorrectAboveIsClicked(editMode);
  }, []);

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-9/12">
        <p className="p-6 font-bold text-xl mb-2">{bolusSegmentTitle}</p>
        <div>
          <div className="flex justify-between items-center mb-2 w-full pb-2 border-b border-b-ios-gray-7">
            <div>
              <p className="pl-6">
                {intl.formatMessage(messages.startTimeInputLabel)}
              </p>
            </div>
            <div>
              <p className="pr-6 font-semibold text-ios-gray-2">
                <span>{startTimeValue}</span>
              </p>
            </div>
          </div>
          <div
            onClick={() => setEndTimeIsClicked(true)}
            className="flex justify-between items-center mb-2 w-full pb-2 border-b border-b-ios-gray-7 hover:cursor-pointer"
          >
            <div>
              <p className="pl-6">
                {intl.formatMessage(messages.endTimeInputLabel)}
              </p>
            </div>
            <div>
              <p
                className={`pr-6 font-semibold ${endTimeIsClicked ? 'text-modes-automated' : 'text-ios-gray-2'}`}
              >
                <span>
                  {endTimeIsClicked
                    ? endTimeValue
                    : intl.formatMessage(messages.emptyValue)}
                </span>
              </p>
            </div>
          </div>
          <div
            onClick={() => endTimeIsClicked && setTargetGlucoseIsClicked(true)}
            className="flex justify-between items-center w-full pb-2 border-b border-b-ios-gray-7 hover:cursor-pointer"
          >
            <div className="pl-6">
              <p>{intl.formatMessage(messages.targetGlucoseInputLabel)}</p>
              <p className="font-semibold text-xs text-ios-gray-2">
                {intl.formatMessage(messages.targetGlucoseInputPlaceholder)}
              </p>
            </div>
            <div className="pr-6">
              <p
                className={`font-semibold ${targetGlucoseIsClicked ? 'text-modes-automated' : 'text-ios-gray-2'}`}
              >
                <span>
                  {targetGlucoseIsClicked
                    ? targetGlucoseValue
                    : intl.formatMessage(
                        messages.targetGlucoseAndCorrectAboveUnitEmptyValue
                      )}
                </span>
              </p>
            </div>
          </div>
          <div
            onClick={() => endTimeIsClicked && setCorrectAboveIsClicked(true)}
            className="flex justify-between items-center w-full pb-2 border-b border-b-ios-gray-7 hover:cursor-pointer"
          >
            <div className="pl-6">
              <p>{intl.formatMessage(messages.correctAboveInputLabel)}</p>
              <p className="font-semibold text-xs text-ios-gray-2">
                {intl.formatMessage(messages.correctAboveInputPlaceholder)}
              </p>
            </div>
            <div className="pr-6">
              <p
                className={`font-semibold ${correctAboveIsClicked ? 'text-modes-automated' : 'text-ios-gray-2'}`}
              >
                <span>
                  {correctAboveIsClicked
                    ? correctAboveValue
                    : intl.formatMessage(
                        messages.targetGlucoseAndCorrectAboveUnitEmptyValue
                      )}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="basis-2/5">
        <div className="pt-2 w-full h-full flex flex-col-reverse">
          <div className="flex flex-col w-full p-6">
            <Button
              onClick={() =>
                endTimeIsClicked &&
                targetGlucoseIsClicked &&
                correctAboveIsClicked &&
                navigate(navigationRoute)
              }
              variant="colored"
              disabled={
                endTimeIsClicked &&
                targetGlucoseIsClicked &&
                correctAboveIsClicked
                  ? false
                  : true
              }
            >
              {editMode
                ? intl.formatMessage(messages.saveBtn)
                : intl.formatMessage(messages.nextBtn)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
