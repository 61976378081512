import React from "react";
import { StyleSheet, Text, View } from "react-native-web";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import theme from "../../../../../theme";
import { strings } from "./ReleaseNotesScreenContent";

const ReleaseNotesScreen: React.FC = () => {
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={styles.container}>
      <Text style={[theme.fonts.text600, styles.text]}>
        {languageContent.releaseNotes}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    paddingTop: 20,
    paddingLeft: 20,
    fontSize: 16,
    fontWeight: "300",
    textAlign: "left"
  },
  container: {
    backgroundColor: "white",
    height: "100%"
  }
});

export default ReleaseNotesScreen;
