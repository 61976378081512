import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import BOLUS_SEG_ONE_DATA from "../../../../../../data/bolusSeg1Data";
import BOLUS_SEG_ONE_DATA_MMOL from "../../../../../../data/bolusSeg1DataMmol";
import BOLUS_SEG_ONE_DATA_TWO from "../../../../../../data/bolusSeg1DataTwo";
import BOLUS_SEG_ONE_DATA_TWO_MMOL from "../../../../../../data/bolusSeg1DataTwoMmol";
import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat";
import { getUnitByLocale } from "../../../../../../helpers/getUnitByLocale";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import {
  bolusActions,
  BolusState
} from "../../../../../../../../web/store/reducers/bolus";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import PickerItem from "../../../../../../types/pickerItem";
import { BottomInnerNav, SegmentTopBar } from "../../../../../molecules";
import { strings } from "./TargetBGFirstSegmentScreenContent";

const CORRECT_ABOVE_FIELD = "correctAbove";

const TargetBGFirstSegmentScreen: React.FC = () => {
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const lowerRange = getUnitByLocale(110, langState.units, langState.locale);
  const higherRange = `${getUnitByLocale(
    150,
    langState.units,
    langState.locale
  )} ${langState.units}`;
  const correctAboveHigherRange = `${getUnitByLocale(
    200,
    langState.units,
    langState.locale
  )} ${langState.units}`;
  const contentVariables = [lowerRange, higherRange, correctAboveHigherRange];

  let BOLUS_SEG_DATA: Array<PickerItem> = [];
  let BOLUS_SEG_DATA_TWO: Array<PickerItem> = [];
  if (langState.units === "mmol/L") {
    BOLUS_SEG_DATA = BOLUS_SEG_ONE_DATA_MMOL;
    BOLUS_SEG_DATA_TWO = BOLUS_SEG_ONE_DATA_TWO_MMOL;
  } else {
    BOLUS_SEG_DATA = BOLUS_SEG_ONE_DATA;
    BOLUS_SEG_DATA_TWO = BOLUS_SEG_ONE_DATA_TWO;
  }

  const segment = bolusState.defaultFirstSegment;

  const determineEndTime = () => {
    if (segment.targetBG >= 110 && segment.correctAbove <= 200) {
      return true;
    }
    return false;
  };

  const [fieldValues, setFieldValues] = React.useState({
    targetBG: 0,
    correctAbove: 0
  });

  const [targetBGSelected, setTargetBGSelected] = React.useState<string>("");
  const [correctAboveSelected, setCorrectAboveSelected] =
    React.useState<string>("");

  const [background, setBackground] = React.useState<string>("");

  const [endTime, setEndTime] = React.useState<boolean>(determineEndTime());

  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);

  const getTargetBGAdornmentColor = () => {
    if (!fieldValues.targetBG) {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  const getCorrectAboveAdornmentColor = () => {
    if (!fieldValues.correctAbove) {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  return (
    <View style={[styles.container]}>
      <View>
        <SegmentTopBar segmentNum="1" />
        <View style={[styles.break, { marginBottom: 16 }]} />
        <View style={[{ paddingVertical: 16 }]}>
          <View style={[{ flexDirection: "row", paddingHorizontal: 16 }]}>
            <Text style={[theme.fonts.h4, { flex: 1.1 }]}>
              {languageContent.start}
            </Text>
            <Text style={[theme.fonts.h4, { flex: 1 }]}>
              {languageContent.end}
            </Text>
          </View>
          <View
            style={[
              {
                width: "100%",
                flexDirection: "row",
                paddingLeft: 8,
                paddingHorizontal: 14
              }
            ]}>
            <View style={styles.startTimeContainer}>
              <Text
                style={[
                  {
                    paddingTop: 8,
                    fontSize: 32,
                    color: theme.colors.text.inputText
                  }
                ]}>
                {getCurrentHourFormat({
                  timeStr: "12:00 am",
                  is24Hour: langState.is24Hour,
                  trimModifier: true
                })}
                {!langState.is24Hour ? (
                  <Text style={[styles.periodText]}>AM</Text>
                ) : null}
              </Text>
              <View style={[{ alignSelf: "center" }]}>
                <Text style={[{ fontSize: 32 }]}>{" - "}</Text>
              </View>
            </View>
            <Pressable
              onPress={() => setEndTime(true)}
              style={[theme.layout.mockInput, { flex: 1, paddingVertical: 8 }]}>
              <Text style={[theme.layout.mockInputStyle]}>
                {endTime ? (
                  <Text style={{ color: theme.colors.text.inputText }}>
                    {`${getCurrentHourFormat({
                      timeStr: "8:00 am",
                      is24Hour: langState.is24Hour,
                      trimModifier: true
                    })}`}

                    {!langState.is24Hour ? (
                      <Text style={[styles.periodText]}>AM</Text>
                    ) : null}
                  </Text>
                ) : (
                  <Text style={[styles.inputPlaceholder]}>––</Text>
                )}
              </Text>
            </Pressable>
          </View>
          <View style={styles.helpTextRow}>
            <Text style={[theme.fonts.body1, styles.startTimeHelpText]}>
              {languageContent.night}
            </Text>
            <Text style={[theme.fonts.body1, styles.endTimeHelpText]}>
              {endTime && languageContent.morning}
            </Text>
          </View>
          <View style={[styles.targetBGContainer]}>
            <View
              style={[{ marginTop: 8, zIndex: 200, paddingHorizontal: 16 }]}>
              <Text style={[theme.fonts.h4]}>{languageContent.targetBG}</Text>
              <Text
                style={[
                  theme.fonts.body1,
                  { color: theme.colors.text.cancel, marginBottom: 8 }
                ]}>
                {replaceWithValues(languageContent.range, contentVariables)}
              </Text>
            </View>
            {/* target glucose picker */}
            <>
              <Pressable
                style={[
                  theme.layout.inputContainerStyle,
                  styles.inputContainer
                ]}
                onPress={() =>
                  setTargetBGSelected(
                    getUnitByLocale(
                      110,
                      langState.units,
                      langState.locale
                    ).toString()
                  )
                }>
                <TextInput
                  editable={false}
                  style={[
                    theme.layout.inputStyle,
                    styles.inputValues,
                    { width: "80%" }
                  ]}
                  selectionColor={"transparent"}
                  placeholder={"––"}
                  placeholderTextColor={theme.colors.placeholder}
                  value={targetBGSelected}
                  showSoftInputOnFocus={false}
                />
                <Text
                  style={[
                    theme.layout.adornment,
                    {
                      color: getTargetBGAdornmentColor()
                    }
                  ]}>
                  {langState.units}
                </Text>
              </Pressable>
            </>
          </View>
          <View style={[styles.correctAboveContainer]}>
            <View
              style={{
                height: 140,
                backgroundColor:
                  background === CORRECT_ABOVE_FIELD ? "white" : "transparent"
              }}>
              <View style={styles.inputHeader}>
                <Text style={[theme.fonts.h4]}>
                  {languageContent.correctAbove}
                </Text>
                <Text
                  style={[
                    theme.fonts.body1,
                    { color: theme.colors.text.cancel, marginBottom: 8 }
                  ]}>
                  {replaceWithValues(
                    languageContent.targetGlucoseTo,
                    contentVariables
                  )}
                </Text>
              </View>
              <>
                <Pressable
                  style={[
                    theme.layout.inputContainerStyle,
                    styles.inputContainer
                  ]}
                  onPress={() =>
                    setCorrectAboveSelected(
                      getUnitByLocale(
                        120,
                        langState.units,
                        langState.locale
                      ).toString()
                    )
                  }>
                  <TextInput
                    editable={false}
                    style={[
                      theme.layout.inputStyle,
                      styles.inputValues,
                      { width: "80%" }
                    ]}
                    selectionColor={"transparent"}
                    placeholder={"––"}
                    placeholderTextColor={theme.colors.placeholder}
                    value={correctAboveSelected}
                    showSoftInputOnFocus={false}
                  />
                  <Text
                    style={[
                      theme.layout.adornment,
                      {
                        color: getCorrectAboveAdornmentColor()
                      }
                    ]}>
                    {langState.units}
                  </Text>
                </Pressable>
              </>
            </View>
          </View>
        </View>
      </View>
      <View style={{ marginTop: -160 }}>
        <BottomInnerNav
          leftActionStyle={{ color: theme.colors.text.cancel }}
          leftActionText={languageContent.cancel}
          rightActionText={languageContent.next}
          rightAction={() => {
            if (!correctAboveSelected || !targetBGSelected || !endTime) {
              return;
            }
            dispatch(
              bolusActions.bolusUpdate(
                {
                  defaultFirstSegment: {
                    ...segment,
                    targetBG: Number(targetBGSelected),
                    correctAbove: Number(correctAboveSelected)
                  }
                },
                bolusState
              )
            );

            navigate("SecondSegmentTargetBG");
          }}
          rightActionStyle={{
            color:
              !correctAboveSelected || !targetBGSelected || !endTime
                ? theme.colors.placeholder
                : theme.colors.purple
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "#fff"
  },
  startTimeContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1.2
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  periodText: {
    color: theme.colors.text.cancel,
    fontSize: 18
  },
  helpTextRow: {
    flexDirection: "row",
    marginTop: 8,
    zIndex: -1
  },
  startTimeHelpText: {
    flex: 1.1,
    color: theme.colors.text.cancel,
    paddingLeft: 16
  },
  endTimeHelpText: {
    flex: 1,
    color: theme.colors.text.cancel,
    paddingHorizontal: 16
  },
  inputContainer: {
    marginHorizontal: 16,
    paddingLeft: 8,
    height: 60,
    paddingBottom: 10
  },
  inputHeader: {
    marginTop: 16,
    paddingHorizontal: 16
  },
  targetBGContainer: {
    marginTop: 8,
    zIndex: -1,
    height: 140
  },
  inputPlaceholder: {
    color: theme.colors.placeholder,
    fontSize: 32
  },
  inputValues: {
    color: "#000",
    fontSize: 32
  },
  correctAboveContainer: {
    paddingBottom: 16,
    zIndex: -1,
    height: 200,
    marginTop: -20
  }
});

export default TargetBGFirstSegmentScreen;
