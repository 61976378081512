import { LanguageType } from "../types/language";
import { LanguageLongType } from "../types/languageLong";

export const getLanguage = (languageLong: LanguageLongType) => {
  return {
    "English (US)": "en-US" as LanguageType,
    Español: "es-US" as LanguageType,
    "English (UK)": "en-GB" as LanguageType,
    Deutsch: "de-DE" as LanguageType,
    French: "fr-FR" as LanguageType,
    Dutch: "nl-NL" as LanguageType
  }[languageLong];
};
