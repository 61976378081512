let strings = {
  "en-US": {
    seg: "Segment 1",
    start: "Start",
    end: "End",
    night: "Night",
    targetBG: "Target Glucose",
    targetTo: "(%1 to %2)",
    correctTo: "(Target Glucose to %3)",
    correctAbove: "Correct Above",
    cancel: "Cancel",
    next: "Next"
  },
  "en-GB": {
    seg: "Segment",
    start: "Start",
    end: "End",
    night: "Night",
    targetBG: "Target Glucose",
    targetTo: "(%1 to %2)",
    correctTo: "(Target Glucose to %3)",
    correctAbove: "Correct Above",
    cancel: "Cancel",
    next: "Next"
  },
  "de-DE": {
    seg: "Segment 1",
    start: "Start",
    end: "Ende",
    night: "Nacht",
    targetBG: "Glukose-Zielwert",
    targetTo: "(%1 bis %2)",
    correctTo: "(Glukose-Zielwert auf %3)",
    correctAbove: "Korrigieren über",
    cancel: "Abbr.",
    next: "Weiter"
  },
  "es-US": {
    seg: "Segmento 1",
    start: "Iniciar",
    end: "Finalizar",
    night: "Noche",
    targetBG: "Glucosa Objetivo",
    targetTo: "(%1 a %2)",
    correctTo: "(Glucosa Objetivo a %3)",
    correctAbove: "Corregir Sobre",
    cancel: "Cancelar",
    next: "Siguiente"
  },
  "fr-FR": {
    seg: "Segment 1",
    start: "Démarrer",
    end: "Fin",
    night: "Nuit",
    targetBG: "Glucose cible",
    targetTo: "(%1 à %2)",
    correctTo: "(Glucose cible à %3)",
    correctAbove: "Corriger si supérieur à",
    cancel: "Annuler",
    next: "Suivant"
  },
  "nl-NL": {
    seg: "Segment 1",
    start: "Start",
    end: "Stop",
    night: "Nacht",
    targetBG: "Streefwaarde glucose",
    targetTo: "(%1 tot %2)",
    correctTo: "(Streefwaarde glucose naar %3)",
    correctAbove: "Corrigeren boven",
    cancel: "Annuleren",
    next: "Volgende"
  }
};

export { strings };
