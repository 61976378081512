import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { Dimensions, StyleSheet, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BasalProgram } from "../../../../../../../assets/basal_program_static.svg";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { basalActions, BasalState } from "../../../../../../../web/store/reducers/basal";
import { userActions, UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { BottomInnerNav } from "../../../../molecules";
import { strings } from "./ExitAutoModeScreenContent";

const { height } = Dimensions.get("window");

const ExitAutoModeScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );

  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const switchMode = () => {
    try {
      dispatch(
        userActions.userUpdate(
          {
            automatedMode: false,
            limitedMode: false,
            hypoProtectOn: false,
            hypoProtectEnd: new Date().toString()
          },
          userState
        )
      );

      dispatch(
        basalActions.basalUpdate(
          {
            insulinPaused: false
          },
          basalState
        )
      );
      navigate("Drawer");
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <View
      style={[
        theme.layout.wrapper,
        { backgroundColor: "white", width: "100%" }
      ]}>
      <View style={[styles.innerWrapper]}>
        <View>
          <View style={{ height: 200, marginBottom: 16 }}>
            <Text style={[theme.fonts.h4]}>
              {languageContent.switchManualMode}
            </Text>
            <Text style={[theme.fonts.body2, { marginVertical: 16 }]}>
              {`${languageContent.everydayBasalProgramStart} `}
              <Text style={{ fontWeight: "700" }}>
                {languageContent.everyday}
              </Text>
              {` ${languageContent.basalProgram}`}
            </Text>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View
                style={{
                  flex: 10,
                  flexDirection: "column",
                  marginTop: 2
                }}>
                <Text style={[theme.fonts.h4]}>{languageContent.everyday}</Text>
                <Text
                  style={[
                    theme.fonts.body2,
                    { color: theme.colors.blue, marginTop: -6, fontSize: 14 }
                  ]}>
                  {languageContent.currentBasalProgram}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ alignItems: "center" }}>
          <BasalProgram width={"110%"} />
        </View>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.leftActionText}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.rightActionText}
        rightAction={switchMode}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    paddingHorizontal: 16,
    marginTop: height < 800 ? height / 40 : 16
  }
});

export default ExitAutoModeScreen;
