import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { Button } from 'web/components/ios/Button';
import { minimumGlucoseMessages } from '../BolusSettings.messages';
import { BolusSettingsRoutesEnum } from '../types';

export const MinimumGlucose: React.FC = () => {
  const { navigate } = useNavigation();
  return (
    <div className="bg-white h-full flex flex-col justify-between pt-[29px] pb-[34px]">
      <div>
        <div className="px-2 mb-[22px]">
          <FormattedMessage {...minimumGlucoseMessages.text} />
        </div>

        <div className="flex flex-row justify-between items-center px-2 py-1 border-b-1 border-b-ios-gray-9/[.36]">
          <div className="max-w-[200px]">
            <div>
              <FormattedMessage {...minimumGlucoseMessages.valueName} />
            </div>
            <div className="text-ios-gray-9/60 text-small">
              <FormattedMessage {...minimumGlucoseMessages.valueRange} />
            </div>
          </div>
          <div className="text-modes-automated">
            <FormattedMessage {...minimumGlucoseMessages.value} />
          </div>
        </div>
      </div>

      <div className="px-2">
        <Button
          colorMode="automated"
          variant="colored"
          full
          onClick={() => navigate(BolusSettingsRoutesEnum.BolusSettingsMain)}
        >
          <FormattedMessage {...minimumGlucoseMessages.cta} />
        </Button>
      </div>
    </div>
  );
};
