let strings = {
  "en-US": {
    viewers: "Viewers"
  },
  "en-GB": {
    viewers: "Viewers"
  },
  "de-DE": {
    viewers: "Betrachter"
  },
  "es-US": {
    viewers: "Observadores"
  }
};

export { strings };
