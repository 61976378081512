import React, { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'web/store/reducers/modals/modalsSlice';

interface BottomModalProps extends PropsWithChildren {
  onClose?: () => void;
}
export const BottomModal: React.FC<BottomModalProps> = ({
  children,
  onClose,
}) => {
  const dispatch = useDispatch();
  const handleOnClose = () => {
    onClose && onClose();
    dispatch(hideModal());
  };

  return (
    <div className="absolute inset-0 bg-black/50 flex justify-center font-ios items-end w-full">
      <div className="bg-white rounded-[16px] w-full">
        <div className="p-2 pt-0 font-ios">
          <div
            className="pt-1 pb-2 flex align-center justify-center cursor-pointer"
            onClick={handleOnClose}
          >
            <div className="w-[36px] h-[5px] rounded-[100px] bg-ios-gray-9/[.18]" />
          </div>
          <div className="text-modals">{children}</div>
        </div>
      </div>
    </div>
  );
};
