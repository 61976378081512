import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from '../../Modal';
import { connectingMessages } from './FtsStartModals.messages';
import { Spinner } from '../../Spinner';

const MODAL_TIMEOUT = 2000;

interface ConnectingConfirmationModalProps {
  onClose?: () => void;
}

export const ConnectingConfirmationModal: React.FC<
  ConnectingConfirmationModalProps
> = ({ onClose }) => {
  const intl = useIntl();

  useEffect(() => {
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, MODAL_TIMEOUT);
  }, []);

  return (
    <Modal title={intl.formatMessage(connectingMessages.title)}>
      <Spinner />
      <div className="text-center">
        <FormattedMessage {...connectingMessages.text} />
      </div>
    </Modal>
  );
};
