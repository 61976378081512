let strings = {
  "en-US": {
    seg: "Segment 1",
    start: "Start",
    end: "End",
    night: "Night",
    oneUnit: "1 Unit of Insulin lowers Glucose by",
    range: "(%1 to %2)",
    cancel: "Cancel",
    next: "Next"
  },
  "en-GB": {
    seg: "Segment 1",
    start: "Start",
    end: "End",
    night: "Night",
    oneUnit: "1 Unit of Insulin lowers Glucose by",
    range: "(%1 to %2)",
    cancel: "Cancel",
    next: "Next"
  },
  "de-DE": {
    seg: "Segment 1",
    start: "Start",
    end: "Ende",
    night: "Nacht",
    oneUnit: "1 Einheit Insulin senkt den Blutzucker um",
    range: "(%1 bis %2)",
    cancel: "Abbr.",
    next: "Weiter"
  },
  "es-US": {
    seg: "Segmento 1",
    start: "Iniciar",
    end: "Finalizar",
    night: "Noche",
    oneUnit: "1 unidad de insulina reduce la glucosa en",
    range: "(%1 a %2)",
    cancel: "Cancelar",
    next: "Siguiente"
  },
  "fr-FR": {
    seg: "Segment 1",
    start: "Démarrer",
    end: "Fin",
    night: "Nuit",
    oneUnit: "1 Unité d'insuline réduit le glucose de",
    range: "(%1 à %2)",
    cancel: "Annuler",
    next: "Suivant"
  },
  "nl-NL": {
    seg: "Segment 1",
    start: "Start",
    end: "Stop",
    night: "Nacht",
    oneUnit: "1 eenheid insuline verlaagt de glucose met",
    range: "(%1 tot %2)",
    cancel: "Annuleren",
    next: "Volgende"
  }
};

export { strings };
