import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React, { useRef } from "react";
import {
  Animated,
  Platform,
  PlatformIOSStatic,
  StyleSheet,
  Text,
  useWindowDimensions,
  View
} from "react-native";
/* import * as Progress from "react-native-progress"; */
import { useSelector } from "react-redux";
import { ReactComponent as LogoSVG } from "../../../../../../../../assets/Omnipod_5_LOGO_RGB_MANGO_TM.svg";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { UserState } from "../../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { strings } from "./SetupLoadingScreenContent";

const SetupLoadingScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  const [isFTS] = React.useState<boolean>(
    userState.podConfigured && userState.hasTXId
  );

  const width = useWindowDimensions().width;
  const { languageContent } = useLanguageContent(strings);

  const fadeLogo = useRef(new Animated.Value(0)).current;
  const fadeLoading = useRef(new Animated.Value(0)).current;
  const platform = Platform as PlatformIOSStatic;

  const { navigate } = useNavigation();

  React.useEffect(() => {
    Animated.timing(fadeLoading, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true
    }).start();

    Animated.timing(fadeLogo, {
      toValue: 1,
      duration: 2500,
      useNativeDriver: true
    }).start();
    setTimeout(() => {
      navigate("SelectLanguageSettings");
    }, 3000);
  }, [fadeLoading, fadeLogo, navigate]);
  return (
    <>
      <Animated.View
        style={[
          theme.layout.trueCenter,
          {
            flex: 2,
            opacity: fadeLogo,
            padding: 48
          }
        ]}>
        {platform.isPad ? (
          <LogoSVG width="75%" height="75%" style={{ position: "absolute" }} />
        ) : (
          <LogoSVG
            width="100%"
            height="100%"
            style={{ position: "absolute" }}
          />
        )}
      </Animated.View>
      <Animated.View
        style={[
          styles.container,
          {
            opacity: fadeLoading
          }
        ]}>
        {/* <Progress.Bar */}
        {/*   indeterminate={true} */}
        {/*   width={width - 92} */}
        {/*   height={5} */}
        {/*   color={theme.colors.purple} */}
        {/*   borderRadius={1} */}
        {/*   borderWidth={0.2} */}
        {/*   borderColor={theme.colors.loading.loadingBarLight} */}
        {/*   unfilledColor={theme.colors.loading.loadingBarLight} */}
        {/* /> */}
        <View style={[theme.layout.trueCenter]}>
          <Text
            style={{
              textAlign: "center",
              color: theme.colors.text.cancel,
              paddingTop: 8
            }}>
            {isFTS ? "Loading..." : languageContent.loading}
          </Text>
        </View>
      </Animated.View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 2,
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center"
  }
});

export default SetupLoadingScreen;
