import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { getUnitByLocale } from "../../../helpers/getUnitByLocale";
import {
  getAverageCGM,
  getBasalInsulin,
  getBolusInsulin,
  getCgmAbove,
  getCgmBelow,
  getCgmInRange,
  getTotalCarbs,
  getTotalInsulin
} from "../../../helpers/historyDynamicImageImports";
import useLanguageContent from "../../../hooks/useLanguageContent";
import { LangState } from "../../../../../web/store/reducers/language";
import { RootState } from "../../../../../web/store/store";
import theme from "../../../theme";
import { HistoryScreenTile } from "../../molecules";
import { strings } from "./AveragesCardMultiDayHistoryContent";

interface Props {
  sectionOneLabel: string;
  sectionTwoLabel: string;
  historyRangeIndex: number;
}

const AveragesCardMultiDayHistory: React.FC<Props> = ({
  sectionOneLabel,
  sectionTwoLabel,
  historyRangeIndex
}) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);
  return (
    <View style={[styles.card]}>
      <View style={styles.cardContainer}>
        <Text style={styles.label}>{sectionOneLabel}</Text>
        <View style={[styles.row]}>
          <HistoryScreenTile
            label={languageContent.averageSensor}
            icon={getAverageCGM(historyRangeIndex)}
          />
          <HistoryScreenTile
            label={languageContent.sensorInRange}
            subLabel={`${getUnitByLocale(70, langState.units)} ${
              languageContent.to
            } ${getUnitByLocale(180, langState.units)} ${langState.units}`}
            icon={getCgmInRange(historyRangeIndex)}
          />
          <HistoryScreenTile
            label={languageContent.sensorAbove}
            icon={getCgmAbove(historyRangeIndex)}
          />
          <HistoryScreenTile
            label={languageContent.sensorAbove}
            icon={getCgmBelow(historyRangeIndex)}
          />
        </View>
        <View style={[styles.divider]} />
        <Text style={[theme.fonts.body2, { alignSelf: "center" }]}>
          {sectionTwoLabel}
        </Text>
        <View style={[styles.row]}>
          <HistoryScreenTile
            label={languageContent.totalInsulin}
            icon={getTotalInsulin(historyRangeIndex)}
          />
          <HistoryScreenTile
            label={languageContent.basalInsulin}
            icon={getBasalInsulin(historyRangeIndex)}
          />
          <HistoryScreenTile
            label={languageContent.bolusInsulin}
            icon={getBolusInsulin(historyRangeIndex)}
          />
          <HistoryScreenTile
            label={languageContent.totalCarbs}
            icon={getTotalCarbs(historyRangeIndex)}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.white,
    shadowColor: "#999",
    shadowOpacity: 0.3,
    shadowRadius: 6,
    shadowOffset: {
      height: 1,
      width: 0
    },
    elevation: 4,
    alignSelf: "center",
    marginBottom: 8,
    paddingHorizontal: 16,
    paddingTop: 16
  },
  cardContainer: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignContent: "space-between"
  },
  label: {
    alignSelf: "center",
    ...theme.fonts.body2
  },
  divider: {
    flex: 1,
    width: "100%",
    height: 1,
    backgroundColor: "#eee",
    alignSelf: "center",
    marginBottom: 8,
    marginTop: -8
  },
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginVertical: 16
  }
});

export default AveragesCardMultiDayHistory;
