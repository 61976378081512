import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { selectCurrentOperationMode } from 'web/store/reducers/user/user.helpers';
import { selectBolusDeliveryData } from 'web/store/reducers/bolus/bolus.helpers';
import {
  cancelBolusDelivery,
  bolusDeliveryComplete,
} from 'web/store/reducers/bolus/actions';
import {
  showModal,
  hideModal,
  setCurrentModal,
} from 'web/store/reducers/modals/modalsSlice';
import { CancelInstructionModal } from 'web/components/ios/modals/GlobalModals/GlobalModals';
import { bolusDeliveryBarMessages } from '../Dashboard.messages';

const BASE_DELIVERY_TIME = 4000;
const BASE_INTERVAL_TIME = 100;
const EXTENDED_INTERVAL_TIME = 5000;

export const BolusDeliveryBar: React.FC = () => {
  const operationMode = useSelector(selectCurrentOperationMode);
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    deliveryQty = 0,
    isExtended,
    deliveryTime = 0,
  } = useSelector(selectBolusDeliveryData);

  const calculatedDeliveryTime = isExtended
    ? deliveryTime * 3600000
    : BASE_DELIVERY_TIME;
  const calculatedIntervalTime = isExtended
    ? EXTENDED_INTERVAL_TIME
    : BASE_INTERVAL_TIME;

  const [currentProgress, setCurrentProgress] = useState<number>(0);
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [remainingTime, setRemainingTime] = useState<number>(
    calculatedDeliveryTime
  );

  const valueIncrement =
    (deliveryQty * (calculatedIntervalTime / 1000)) /
    (calculatedDeliveryTime / 1000);
  const progressIncrement =
    (100 * (calculatedIntervalTime / 1000)) / (calculatedDeliveryTime / 1000);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentProgress(currentProgress + progressIncrement);
      setCurrentValue(currentValue + valueIncrement);
      setRemainingTime(remainingTime - calculatedIntervalTime);
    }, calculatedIntervalTime);

    if (currentProgress >= 100) {
      clearTimeout(timeout);
      dispatch(bolusDeliveryComplete({ lastBolus: deliveryQty }));
    }

    return () => clearTimeout(timeout);
  }, [currentValue]);

  const handleCancelReject = () => dispatch(hideModal());
  const handleCancelConfirm = () => {
    dispatch(cancelBolusDelivery());
    dispatch(hideModal());
  };

  const handleCancelClick = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <CancelInstructionModal
            customTitle={intl.formatMessage(bolusDeliveryBarMessages.cancelExtendedBolus)}
            onCancel={handleCancelReject}
            onConfirm={handleCancelConfirm}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  const remainingTimeDuration = moment.duration(remainingTime);

  return (
    <div className="absolute bottom-[84px] bg-white w-full text-regular">
      <div className="relative">
        <div className="bg-black/10 w-full h-[3px] absolute"></div>
        <div
          className={`bg-modes-${operationMode} h-[3px] transition-all`}
          style={{ width: `${currentProgress}%` }}
        ></div>
      </div>
      <div className="px-2 pb-[13px] pt-[10px] flex items-center justify-between">
        <div className="text-small">
          <div className={`text-modes-${operationMode} font-semibold`}>
            <FormattedMessage
              {...bolusDeliveryBarMessages.deliveringBolus}
              values={{ currentProgress: currentProgress.toFixed(0) }}
            />
          </div>
          <div>
            <span>
              <FormattedMessage
                {...bolusDeliveryBarMessages.bolusValue}
                values={{
                  current:
                    currentValue >= deliveryQty
                      ? deliveryQty
                      : currentValue.toFixed(1),
                  total: deliveryQty,
                }}
              />
            </span>
            {isExtended && (
              <span className="ml-0.5">
                <FormattedMessage
                  {...bolusDeliveryBarMessages.hoursRemaining}
                  values={{
                    hours: remainingTimeDuration.hours(),
                    mins: remainingTimeDuration.minutes(),
                  }}
                />
              </span>
            )}
          </div>
        </div>
        <button className="text-ios-red" onClick={handleCancelClick}>
          <FormattedMessage {...bolusDeliveryBarMessages.cancel} />
        </button>
      </div>
    </div>
  );
};
