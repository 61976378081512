import PickerItem from "../types/pickerItem";

const BOLUS_SEG1_DATA_MMOL: Array<PickerItem> = [
  {
    label: "6.1",
    value: 6.1,
    key: "6.1",
    index: 1
  },
  {
    label: "6.7",
    value: 6.7,
    key: "6.7",
    index: 2
  },
  {
    label: "7.2",
    value: 7.2,
    key: "7.2",
    index: 3
  },
  {
    label: "7.8",
    value: 7.8,
    key: "7.8",
    index: 4
  },
  {
    label: "8.3",
    value: 8.3,
    key: "8.3",
    index: 5
  }
];

export default BOLUS_SEG1_DATA_MMOL;
