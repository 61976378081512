import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as NoPodActive } from "../../../../../../../assets/pod_outline.svg";
import { ReactComponent as NoPod } from "../../../../../../../assets/pod_there_is_no_pod_x.svg";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { bolusActions, BolusState } from "../../../../../../../web/store/reducers/bolus";
import { UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { Button } from "../../../../atoms";
import { BottomInnerNav } from "../../../../molecules";
import { strings } from "./NoActivePodScreenContent";
import {
  NavState,
  navigationActions
} from "../../../../../../../web/store/reducers/navigation";

const NoActivePodScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const bolusState: BolusState = useSelector((state: RootState) => state.bolus);
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const sendUpdate = React.useCallback(() => {
    if (!dispatch) return;
    dispatch(bolusActions.bolusUpdate({ podInsulin: 125 }, bolusState));
  }, [bolusState, dispatch]);

  // React.useEffect(() => {
  //   sendUpdate();
  // }, [sendUpdate]);

  return (
    <>
      <View
        style={[
          theme.layout.wrapper,
          {
            marginTop: 0,
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: "white",
            flex: 1
          }
        ]}>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%"
          }}>
          <View
            style={{
              marginTop: "7%",
              flexDirection: "row",
              alignItems: "center"
            }}>
            <NoPod
              style={{
                width: 24,
                height: 24,
                marginRight: 16
              }}
            />
            <Text style={[theme.fonts.h4, { fontWeight: "700" }]}>
              {languageContent.noActivePod}
            </Text>
          </View>
          <NoPodActive
            style={{ width: "60%", height: "60%", marginTop: "5%" }}
          />
        </View>
        <View style={{ marginHorizontal: 16, marginBottom: 48 }}>
          <Button
            variant="primary"
            style={{ elevation: 6 }}
            onPress={() => {
              dispatch(
                navigationActions.navigationUpdate(
                  { podStackEntryRoute: "Drawer" },
                  navState
                )
              );
              navigate("ChangePod");
            }}>
            {languageContent.setupNewPod}
          </Button>
        </View>
      </View>

      <BottomInnerNav leftActionText={languageContent.close} />
    </>
  );
};

export default NoActivePodScreen;
