let strings = {
  "en-US": {
    totalBasal: "Total Basal: %1 U/day",
    segment: "Segment",
    basalRate: "Basal Rate:",
    cancel: "Cancel",
    save: "Save",
    basal: "Basal",
    cancelTitleOne: "Cancel editing this basal program?",
    cancelTitleTwo: "Are you sure you want to cancel the basal program?",
    cancelTitleThree: "Cancel Basal setup?",
    uHr: "U/hr",
    cancelBody1:
      "Cancelling will erase your Basal entries and return you to the beginning of this section.",
    yes: "Yes",
    no: "No",
    startModalTitle: "Start insulin?",
    changesSaved: "%2 changes are saved.",
    startBasalProgram:
      "You can start the Basal Program now or get a reminder in 30 minutes.",
    startProgram: "Start Program",
    notNow: "Not Now",
    start: "Start %2 Basal Program?",
    changesSavedTwo: "Your changes have been saved.",
    basalProgramError: "Basal Program Error",
    deliverAtLeast: "Your Basal Program must deliver at least:",
    deliverMessageOne: "%3 U/hr for one hour, or",
    deliverMessageTwo: "%4 U/hr for one half-hour",
    ok: "OK",
    pleaseWait: "Please wait..."
  },
  "en-GB": {
    totalBasal: "Total Basal: %1 U/day",
    segment: "Segment",
    basalRate: "Basal Rate:",
    cancel: "Cancel",
    save: "Save",
    basal: "Basal",
    cancelTitleOne: "Cancel editing this basal programme?",
    cancelTitleTwo: "Are you sure you want to cancel the basal programme?",
    cancelTitleThree: "Cancel Basal set-up?",
    uHr: "U/hr",
    cancelBody1:
      "Cancelling will erase your Basal entries and return you to the beginning of this section.",
    yes: "Yes",
    no: "No",
    startModalTitle: "Start insulin?",
    changesSaved: "2% changes are saved.",
    startBasalProgram:
      "You can start the Basal Programme now or get a reminder in 30 minutes.",
    startProgram: "Start Programme",
    notNow: "Not Now",
    start: "Start %2 Basal Programme?",
    changesSavedTwo: "Your changes have been saved.",
    basalProgramError: "Basal Programme Error",
    deliverAtLeast: "Your Basal Programme must deliver at least:",
    deliverMessageOne: "%3 U/hr for one hour, or",
    deliverMessageTwo: "%4 U/hr for one half-hour",
    ok: "OK",
    pleaseWait: "Please wait..."
  },
  "de-DE": {
    totalBasal: "Gesamtbasalrate: %1 E/Tag",
    segment: "Segment",
    basalRate: "Basalrate:",
    cancel: "Abbr.",
    save: "Speichern",
    basal: "Basalrate",
    cancelTitleOne: "Bearbeitung dieses Basalratenprofils abbrechen?",
    cancelTitleTwo:
      "Sind Sie sicher, dass Sie das Basalratenprofil abbrechen möchten?",
    cancelTitleThree: "Einrichten der Basalrate abbrechen?",
    uHr: "E/Std.",
    cancelBody1:
      "Wenn Sie den Vorgang abbrechen, werden alle Basalrateneingaben gelöscht und Sie kehren zum Anfang dieses Abschnittes zurück.",
    yes: "Ja",
    no: "Nein",
    startModalTitle: "Insulin starten?",
    changesSaved: "%2 Änderungen werden gespeichert.",
    startBasalProgram:
      "Sie können das Basalratenprofil jetzt starten oder in 30 Minuten einen Erinnerungshinweis erhalten.",
    startProgram: "Profil starten",
    notNow: "Jetzt nicht",
    start: "Basalratenprofil %2 starten?",
    changesSavedTwo: "Ihre Änderungen wurden gespeichert.",
    basalProgramError: "Fehler Basalratenprofil",
    deliverAtLeast: "Ihr Basalratenprofil muss mindestens Folgendes abgeben:",
    deliverMessageOne: "%3 E/Std. für eine Stunde, oder",
    deliverMessageTwo: "%4 E/Std. für eine halbe Stunde",
    ok: "OK",
    pleaseWait: "Bitte warten..."
  },
  "es-US": {
    totalBasal: "Basal total: %1 U/día",
    segment: "Segmento",
    basalRate: "Tasa basal:",
    cancel: "Cancelar",
    save: "Guardar",
    basal: "Basal",
    cancelTitleOne: "¿Cancelar la edición de este programa basal?",
    cancelTitleTwo: "¿Está seguro de que quiere cancelar el programa basal?",
    cancelTitleThree: "¿Cancelar la configuración de Basal?",
    uHr: "U/h",
    cancelBody1:
      "Si cancela, se borrarán sus entradas Basal y volverá al inicio de esta sección.",
    yes: "Sí",
    no: "No",
    startModalTitle: "¿Iniciar la insulina?",
    changesSaved: "Se guardaron %2 cambios.",
    startBasalProgram:
      "Puede empezar el programa basal ahora o recibir un recordatorio en 30 minutos.",
    startProgram: "Iniciar el programa",
    notNow: "No",
    start: "Iniciar el programa basal %2?",
    changesSavedTwo: "Se guardaron sus cambios.",
    basalProgramError: "Error del programa basal",
    deliverAtLeast: "El programa basal debe administrar al menos:",
    deliverMessageOne: "%3 U/h durante una hora o",
    deliverMessageTwo: "%4 U/h durante media hora",
    ok: "Aceptar"
  },
  "fr-FR": {
    totalBasal: "Débit basal total : %1 U/jour",
    segment: "Segment",
    basalRate: "Débit basal :",
    cancel: "Annuler",
    save: "Enregistrer",
    basal: "Débit basal",
    cancelTitleOne: "Annuler la modification de ce programme basal ?",
    cancelTitleTwo: "Êtes-vous sûr(e) de vouloir annuler le programme basal ?",
    cancelTitleThree: "Annuler la configuration du débit basal ?",
    uHr: "U/h",
    cancelBody1:
      "L'annulation efface vos données de débit basal, puis vous renvoie au début de cette section.",
    yes: "Oui",
    no: "Non",
    startModalTitle: "Démarrer l’administration d’insuline ?",
    changesSaved: "%2 modifications sont enregistrées.",
    startBasalProgram:
      "Vous pouvez démarrer ce programme basal maintenant ou recevoir un rappel dans 30 minutes.",
    startProgram: "Démarrer le programme",
    notNow: "Pas maintenant",
    start: "Démarrer un programme basal %2 ?",
    changesSavedTwo: "Vos modifications ont été enregistrées.",
    basalProgramError: "Erreur du programme basal",
    deliverAtLeast: "Votre programme basal doit administrer au moins :",
    deliverMessageOne: "%3 U/h pendant une heure, ou",
    deliverMessageTwo: "%4 U/h pendant une demi-heure",
    ok: "OK"
  },
  "nl-NL": {
    totalBasal: "Totaal basaal: %1 E/dag",
    segment: "Segment",
    basalRate: "Basaalsnelheid:",
    cancel: "Annuleren",
    save: "Opslaan",
    basal: "Basaal",
    cancelTitleOne: "Bewerken van dit basaalprogramma annuleren?",
    cancelTitleTwo: "Weet u zeker dat u het basaalprogramma wilt annuleren?",
    cancelTitleThree: "Basaalinstellingen annuleren?",
    uHr: "E/u.",
    cancelBody1:
      "Annuleren wist uw basaalinvoer en brengt u naar het begin van deze sectie.",
    yes: "Ja",
    no: "Nee",
    startModalTitle: "Insuline starten?",
    changesSaved: "wijzigingen zijn opgeslagen.",
    startBasalProgram:
      "U kunt nu met het basaalprogramma beginnen of over 30 minuten een herinnering krijgen.",
    startProgram: "Programma starten",
    notNow: "Niet nu",
    start: "Het %2 basaalprogramma starten?",
    changesSavedTwo: "Uw wijzigingen zijn opgeslagen.",
    basalProgramError: "Fout basaalprogramma",
    deliverAtLeast:
      "Uw basaalprogramma moet ten minste de volgende hoeveelheid toedienen:",
    deliverMessageOne: "%3 E/u. gedurende een uur of",
    deliverMessageTwo: "%4 E/u. gedurende een half uur",
    ok: "OK"
  }
};

export { strings };
