import PickerItem from "../types/pickerItem";

const CORRECTION_FACTOR_DATA: Array<PickerItem> = [];
let counter = 0;

for (let i = 1; i <= 400; i++) {
  CORRECTION_FACTOR_DATA.push({
    label: `${i}`,
    value: i,
    key: String(i),
    index: ++counter
  });
}

export default CORRECTION_FACTOR_DATA;
