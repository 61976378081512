import React from "react"
import { StyleSheet, Text, View } from "react-native-web"
import theme from "../../../theme"

interface Props {
  tomorrow?: string
  icon: JSX.Element
  title: string
  time: string
  description: string
}

const NotificationItem: React.FC<Props> = ({
  icon,
  title,
  time,
  description,
}) => {
  return (
    <View>
      <View style={[styles.iconContainer]}>
        <View
          style={{
            flex: 0.2,
            alignItems: "center",
            paddingVertical: 16,
          }}
        >
          <View style={styles.icon}>{icon}</View>
        </View>
        <View
          style={{
            paddingVertical: 10,
            paddingRight: 16,
            flex: 0.8,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                ...theme.fonts.body1,
                fontWeight: "700",
              }}
            >
              {title}
            </Text>
            <Text style={{ color: theme.colors.lightBlue, marginTop: 4 }}>
              {time}
            </Text>
          </View>
          <View>
            <Text
              style={{
                ...theme.fonts.body1,
                color: theme.colors.text.grayText,
                fontSize: 14,
              }}
            >
              {description}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  icon: {
    borderRadius: 36,
    backgroundColor: theme.colors.lightBlue,
    height: 40,
    width: 40,
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderBottomColor: theme.colors.textInputBorder,
  },
})

export default NotificationItem
