import { createStackNavigator } from '@react-navigation/stack';
import React, { useEffect } from 'react';
import { BackButton } from '../../components/atoms';
import {
  BasalProgramEntryScreen,
  BasalSegmentOneScreen,
  BasalSegmentTwoScreen,
  CreateBasalProgramIntroScreen,
  IntroBasalScreen,
  MaxBasalRateScreen,
} from '../../components/screens';
import {
  BasalSegmentsConfirmScreen,
  TempBasalOnScreen,
} from '../../components/screens/simulator/firstTimeSetup/basalFTS';
import useLanguageContent from '../../hooks/useLanguageContent';
import theme from '../../theme';
import { strings } from './BasalFTSStackContent';
import {
  NavState,
  navigationActions,
} from '../../../../web/store/reducers/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../web/store/store';
import { isEqual } from 'lodash';
import { AppRoutesEnum } from 'web/routes/types';

const enum BasalFTSRoutesEnums {
  Intro = 'Intro',
  MaxBasal = 'MaxBasal',
  CreateBasalProgramIntro = 'CreateBasalProgramIntro',
  BasalProgramEntry = 'BasalProgramEntry',
  BasalSegmentOne = 'BasalSegmentOne',
  BasalSegmentTwo = 'BasalSegmentTwo',
  TempBasalToggle = 'TempBasalToggle',
  BasalSegmentsConfirm = 'BasalSegmentsConfirm',
  TempBasalOn = 'TempBasalOn',
}

const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const BasalFTSStack: React.FC = () => {
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      navigationActions.navigationUpdate(
        { navigateTo: AppRoutesEnum.basalFTS },
        navState
      )
    );
  }, []);

  return (
    <>
      <Stack.Navigator initialRouteName={BasalFTSRoutesEnums.Intro} mode="card">
        <Stack.Screen
          name={BasalFTSRoutesEnums.Intro}
          options={{
            title: '',
            headerStyle: [defaultHeaderStyle],
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBasal}
                white={false}
                isFTS
              />
            ),
          }}
          component={IntroBasalScreen}
        />
        <Stack.Screen
          name={BasalFTSRoutesEnums.MaxBasal}
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBasal}
                white={false}
                isFTS
              />
            ),
          }}
          component={MaxBasalRateScreen}
        />
        <Stack.Screen
          name={BasalFTSRoutesEnums.CreateBasalProgramIntro}
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBasal}
                white={false}
                isFTS
              />
            ),
          }}
          component={CreateBasalProgramIntroScreen}
        />
        <Stack.Screen
          name={BasalFTSRoutesEnums.BasalProgramEntry}
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBasal}
                white={false}
                isFTS
              />
            ),
          }}
          component={BasalProgramEntryScreen}
        />
        <Stack.Screen
          name={BasalFTSRoutesEnums.BasalSegmentOne}
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBasal}
                white={false}
                isFTS
              />
            ),
          }}
          component={BasalSegmentOneScreen}
        />
        <Stack.Screen
          name={BasalFTSRoutesEnums.BasalSegmentTwo}
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBasal}
                white={false}
                isFTS
              />
            ),
          }}
          component={BasalSegmentTwoScreen}
        />
        <Stack.Screen
          name={BasalFTSRoutesEnums.TempBasalToggle}
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBasal}
                white={false}
                isFTS
              />
            ),
          }}
          component={IntroBasalScreen}
        />
        <Stack.Screen
          name={BasalFTSRoutesEnums.BasalSegmentsConfirm}
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBasal}
                white={false}
                isFTS
              />
            ),
          }}
          component={BasalSegmentsConfirmScreen}
        />
        <Stack.Screen
          name={BasalFTSRoutesEnums.TempBasalOn}
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBasal}
                white={false}
                isFTS
              />
            ),
          }}
          component={TempBasalOnScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default BasalFTSStack;
