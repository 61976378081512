import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { Image, StyleSheet, View } from "react-native-web";
import { useSelector } from "react-redux";
import BasalDefaultProgram from "../../../../../../../../assets/basal_default_program.png";
import BasalDefaultProgram24 from "../../../../../../../../assets/basal_default_program_24hr.png";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { BasalState } from "../../../../../../../../web/store/reducers/basal";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { ListHeader } from "../../../../../atoms";
import { BottomInnerNav, SegmentTopBar } from "../../../../../molecules";
import SegmentListItem from "../../../../../molecules/segmentListItem";
import { strings } from "./CustomProgramSegmentsScreenContent";
import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat";

const CustomProgramSegmentsScreen: React.FC = () => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={[styles.container]}>
      <View>
        <SegmentTopBar
          showRightUnits
          isOnlyProgramName
          programName={basalState.customProgramName}
          segmentNum="2"
        />

        <View style={[styles.break]} />
        <Image
          style={{
            height: "30%",
            width: "90%",
            marginHorizontal: 18,
            marginBottom: 16
          }}
          resizeMode="contain"
          source={
            langState.is24Hour ? BasalDefaultProgram24 : BasalDefaultProgram
          }
        />
        <ListHeader numOfSegments="2" />
        <SegmentListItem
          onEditPress={() => {}}
          index={1}
          segmentLabel={`${languageContent.segment} 1:`}
          startTime={
            getCurrentHourFormat({
              timeStr: "12:00 am",
              is24Hour: langState.is24Hour
            })!
          }
          endTime={
            getCurrentHourFormat({
              timeStr: "8:00 am",
              is24Hour: langState.is24Hour
            })!
          }
          firstRateOrBoldedLabel={languageContent.basalRate}
          firstRateValue={`${(0.75).toLocaleString(langState.locale)} ${
            languageContent.uHr
          }`}
        />

        <SegmentListItem
          onEditPress={() => navigate("CustomProgramSegmentTwo")}
          index={2}
          segmentLabel={`${languageContent.segment} 2:`}
          startTime={
            getCurrentHourFormat({
              timeStr: "8:00 am",
              is24Hour: langState.is24Hour
            })!
          }
          endTime={
            getCurrentHourFormat({
              timeStr: "12:00 am",
              is24Hour: langState.is24Hour
            })!
          }
          firstRateOrBoldedLabel={languageContent.basalRate}
          firstRateValue={`1 ${languageContent.uHr}`}
        />
      </View>
      <BottomInnerNav
        leftActionText={`${languageContent.cancel}`}
        rightActionText={`${languageContent.save}`}
        rightAction={() => navigate("BasalPrograms")}
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "#fff"
  },
  segmentHeader: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.textInputBorder,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    backgroundColor: "#EDF2F6"
  },
  segmentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    marginVertical: 16
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  }
});

export default CustomProgramSegmentsScreen;
