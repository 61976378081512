import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  topBarTitle: {
    id: 'ios.pod.top-bar.title',
    defaultMessage: 'Pod',
  },
  backTitle: {
    id: 'txid.back-title',
    defaultMessage: 'Back',
  },
  setupComplete: {
    id: 'ios.pod.fts.setup-complete',
    defaultMessage: 'Setup is complete!',
  },
  initialScreenText: {
    id: 'ios.pod.fts.initial-setup.text',
    defaultMessage: "Now, let's activate a <highlight>Pod</highlight>",
  },
  initialScreenCtaButton: {
    id: 'ios.pod.fts.initial-setup.cta-button',
    defaultMessage: 'Setup Up New Pod',
  },
  initialScreenCancelButton: {
    id: 'ios.pod.fts.initial-setup.cancel-button',
    defaultMessage: 'Not Now',
  },
});

export const fillPodMessages = defineMessages({
  step1: {
    id: 'ios.pod.change-pod.step-1',
    defaultMessage: 'Fill new Pod with U-100 insulin.',
  },
  step2: {
    id: 'ios.pod.change-pod.step-2',
    defaultMessage: 'Listen for 2 beeps, then tap Next.',
  },
  button: {
    id: 'ios.pod.change-pod.button-next',
    defaultMessage: 'Next',
  },
});

export const checkCannulaMessages = defineMessages({
  step3: {
    id: 'ios.pod.change-pod.step-3',
    defaultMessage: 'Clean site where Pod will be applied.',
  },
  step4: {
    id: 'ios.pod.change-pod.step-4',
    defaultMessage: 'Remove tab and check cannula.{br}Remove paper backing.',
  },
  step5: {
    id: 'ios.pod.change-pod.step-5',
    defaultMessage: 'Check Pod and apply it to site.',
  },
  step6: {
    id: 'ios.pod.change-pod.step-6',
    defaultMessage:
      'Tap Start to insert cannula and begin your basal delivery.',
  },
  checkCannulaButton: {
    id: 'ios.pod.change-pod.check-cannula-button',
    defaultMessage: 'CHECK CANNULA',
  },
  checkPodButton: {
    id: 'ios.pod.change-pod.check-pod-button',
    defaultMessage: 'CHECK POD',
  },
  button: {
    id: 'ios.pod.change-pod.button-start',
    defaultMessage: 'Start',
  },
});

export const podDetailsMessages = defineMessages({
  insulinLeftTitle: {
    id: 'txid.pod-screen.insulin-left-in-pod-title',
    defaultMessage: 'INSULIN LEFT IN POD',
  },
  backTitle: {
    id: 'txid.pod-screen.back-title',
    defaultMessage: 'Back',
  },
  podTitle: {
    id: 'txid.pod-screen.pod-title',
    defaultMessage: 'Pod',
  },
  tenUnitsTitle: {
    id: 'txid.pod-screen.ten-units-title',
    defaultMessage: '10 Units',
  },
  updatedYesterdaySubtitle: {
    id: 'txid.pod-screen.updated-yesterday-subtitle',
    defaultMessage: '(updated Yesterday, 9:29 PM)',
  },
  podStatusTitle: {
    id: 'txid.pod-screen.pod-status-title',
    defaultMessage: 'POD STATUS',
  },
  expiresTomorrowTitle: {
    id: 'txid.pod-screen.expires-tomorrow-title',
    defaultMessage: 'Expires: Tomorrow',
  },
  expiresTomorrowDateSubtitle: {
    id: 'txid.pod-screen.expires-tomorrow-date-subtitle',
    defaultMessage: 'Feb 11 (10:15 PM)',
  },
  remindersTitle: {
    id: 'txid.pod-screen.reminders-title',
    defaultMessage: 'REMINDERS',
  },
  podExpirationTitle: {
    id: 'txid.pod-screen.pod-expiration-title',
    defaultMessage: 'Pod Expiration',
  },
  remindHoursSubtitle: {
    id: 'txid.pod-screen.remind-hours-subtitle',
    defaultMessage: 'Remind 4 hours before',
  },
  lowPodInsulinTitle: {
    id: 'txid.pod-screen.low-pod-insulin-title',
    defaultMessage: 'Low Pod Insulin',
  },
  remindUnitsSubtitle: {
    id: 'txid.pod-screen.remind-units-subtitle',
    defaultMessage: 'Remind when 10 units remain',
  },
  changePodButtonTitle: {
    id: 'txid.pod-screen.change-pod-button-title',
    defaultMessage: 'Change Pod',
  },
  noActiveTitle: {
    id: 'txid.pod-screen.no-active-pod-title',
    defaultMessage: 'No active Pod',
  },
  setUpNewPodButtonTitle: {
    id: 'txid.pod-screen.set-up-new-pod-button-title',
    defaultMessage: 'Set Up New Pod',
  },
});
