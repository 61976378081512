import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import { PodCommAlert } from "../index";
import { strings } from "./BottomInnerNavContent";

interface Props {
  inverted?: boolean;
  leftAction?: boolean;
  leftActionNavigateTo?: string;
  leftNavigationAction?: () => void;
  leftActionStyle?: any;
  leftActionText?: string;
  leftActionDisabled?: boolean;
  leftActionEmpty?: boolean;
  middleActionText?: string;
  middleActionStyle?: any;
  middleNavigationAction?: () => void;
  middleActionDisabled?: boolean;
  middleActionNavigateTo?: string;
  rightAction?: () => void;
  rightActionNavigateTo?: string;
  rightActionText?: string;
  rightActionStyle?: any;
  rightActionDisabled?: boolean;
  showModalBeforeNavigating?: boolean;
  modalDuration?: number;
  modalMessage?: string;
  modalMessageTwo?: string;
  modalMessageThree?: Element | string;
}

const BottomInnerNav: React.FC<Props> = ({
  inverted,
  leftAction,
  leftActionNavigateTo,
  leftNavigationAction,
  leftActionText,
  leftActionStyle,
  leftActionDisabled,
  leftActionEmpty,
  middleActionText,
  middleActionStyle,
  middleNavigationAction,
  middleActionNavigateTo,
  middleActionDisabled,
  rightAction,
  rightActionNavigateTo,
  rightActionText,
  rightActionStyle,
  rightActionDisabled,
  showModalBeforeNavigating,
  modalDuration,
  modalMessage,
  modalMessageTwo,
  modalMessageThree
}) => {
  const { navigate, goBack } = useNavigation();
  const [showPodComm, setShowPodComm] = React.useState<boolean>(false);

  const insets = useSafeAreaInsets();
  const { languageContent } = useLanguageContent(strings);

  const handleShowPodComm = () => {
    setShowPodComm(true);
  };

  const handleNext = () => {
    if (showModalBeforeNavigating) {
      return handleShowPodComm();
    }

    if (rightActionNavigateTo) {
      return navigate(rightActionNavigateTo);
    }

    if (rightAction) {
      return rightAction();
    }
  };

  const handleMiddleAction = () => {
    // Handles TX Middle Action
    if (middleActionNavigateTo && middleActionNavigateTo === "EnterNewTX") {
      return navigate(middleActionNavigateTo);
    }
    // Handles BolusScreen Middle Action
    if (middleActionNavigateTo !== "EnterNewTX" && middleNavigationAction) {
      middleNavigationAction();
    }
  };

  const determineButtonTextColor = () => {
    if (rightActionDisabled) {
      return [styles.buttonGrayText];
    }
    if (inverted) {
      return [styles.buttonWhiteText];
    }
    return [styles.buttonPurpleText];
  };

  React.useEffect(() => {
    if (showPodComm && modalDuration) {
      setTimeout(() => {
        setShowPodComm(false);
        setTimeout(() => {
          if (rightActionNavigateTo) {
            navigate(rightActionNavigateTo);
          }
        }, 150);
      }, modalDuration);
    }
  }, [modalDuration, navigate, rightActionNavigateTo, showPodComm]);

  return (
    <View
      style={{
        paddingHorizontal: 20,
        width: "100%",
        height: 32 + insets.bottom,
        minHeight: 50,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 10,
        position: "absolute",
        bottom: 0,
        backgroundColor: inverted
          ? theme.colors.purple
          : !rightActionText
          ? "transparent"
          : theme.colors.textInputBackgroundColor
      }}>
      <View>
        {leftAction && (leftActionNavigateTo || leftNavigationAction) ? (
          <TouchableOpacity
            disabled={leftActionDisabled}
            onPress={() =>
              leftNavigationAction
                ? leftNavigationAction()
                : leftActionNavigateTo
                ? navigate(leftActionNavigateTo)
                : goBack()
            }>
            <View>
              <Text
                style={[
                  styles.buttonGrayText,
                  leftActionStyle && leftActionStyle
                ]}>
                {leftActionText ? leftActionText : languageContent.backDefault}
              </Text>
            </View>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity onPress={() => goBack()}>
            <View>
              <Text
                style={[
                  determineButtonTextColor(),
                  leftActionStyle && leftActionStyle
                ]}>
                {leftActionEmpty
                  ? null
                  : leftActionText
                  ? leftActionText
                  : languageContent.backDefault}
              </Text>
            </View>
          </TouchableOpacity>
        )}
      </View>
      {middleActionText ? (
        <TouchableOpacity
          disabled={middleActionDisabled}
          onPress={handleMiddleAction}>
          <View
            style={[
              { backgroundColor: inverted ? theme.colors.purple : "none" }
            ]}>
            <Text
              style={[
                determineButtonTextColor(),
                middleActionStyle && middleActionStyle
              ]}>
              {middleActionText}
            </Text>
          </View>
        </TouchableOpacity>
      ) : null}
      {rightActionText ? (
        <View
          style={[
            { backgroundColor: inverted ? theme.colors.purple : "none" }
          ]}>
          <TouchableOpacity onPress={handleNext} disabled={rightActionDisabled}>
            <View>
              <Text
                style={[
                  determineButtonTextColor(),
                  rightActionStyle && rightActionStyle
                ]}>
                {rightActionText
                  ? rightActionText
                  : languageContent.agreeDefault}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      ) : null}
      {showPodComm ? (
        <PodCommAlert
          duration={modalDuration!}
          message={modalMessage}
          messageTwo={modalMessageTwo}
          messageThree={modalMessageThree}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonWhiteText: {
    color: theme.colors.white,
    fontSize: 18,
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "500"
  },
  buttonPurpleText: {
    color: theme.colors.purple,
    fontSize: 18,
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "500"
  },
  buttonGrayText: {
    color: theme.colors.text.cancel,
    fontSize: 18,
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "500"
  }
});

export default BottomInnerNav;
