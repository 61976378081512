/**
 * Takes care of converting 12h to 24h always returns a string
 * @param {boolean} is24Hour - Boolean to trigger 12h to 24h conversion
 * @param {Date} date - Input a Date object (Optional)
 * @param {string} timeStr - Input a Date string (Optional) **MUST BE FORMATTED LIKE: 8:00 am**
 * @param {boolean} trimModifier - Boolean to trim the modifier off the end (am or pm) (Optional)
 * @return {string} - Returns a 12h to 24h converted string if is24Hour is true
 */

interface Props {
  is24Hour: boolean
  date?: Date
  timeStr?: string
  trimModifier?: boolean
}

const getCurrentHourFormat = ({
  is24Hour,
  date,
  timeStr,
  trimModifier,
}: Props) => {
  if (!date && !timeStr) {
    return
  }

  const format = { hour12: !is24Hour, hour: "2-digit", minute: "2-digit" }
  //checks the timeStr param for date format xx:xx (am or pm)
  const filter = new RegExp("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")

  if (date) {
    return is24Hour
      ? date.toLocaleTimeString("de-DE", format as any)
      : date.toLocaleTimeString("en-US", format as any)
  } else if (timeStr && is24Hour) {
    const [time, modifier] = timeStr.split(" ")
    let [hours, minutes] = time.split(":")

    if (timeStr && !filter.test(time)) {
      return timeStr
    }

    if (Number(hours) < 10) {
      hours.toString()
      hours = hours.padStart(2, "0")
    }

    if (hours === "12" && modifier.toLowerCase() === "am") {
      hours = "00"
    }

    if (Number(hours) < 12 && modifier.toLowerCase() === "pm") {
      hours = String(parseInt(hours, 10) + 12)
    }

    return `${hours}:${minutes}`
  }
  if (trimModifier && !is24Hour && timeStr) {
    const [_, modifier] = timeStr.split(" ")
    return timeStr.split(modifier)[0]
  }

  return timeStr
}

export default getCurrentHourFormat
