import { isEqual } from "lodash";
import moment from "moment";
import React from "react";
import {
  Dimensions,
  Platform,
  PlatformIOSStatic,
  StyleSheet,
  Text,
  useWindowDimensions,
  View
} from "react-native";
import { useSelector } from "react-redux";
import { ReactComponent as Sync } from "../../../../../../../assets/UI_Sync.svg";
import useCGM from "../../../../../hooks/useCGM";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { BolusState } from "../../../../../../../web/store/reducers/bolus";
import { UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import TabStackHeader from "../../../../atoms/tabStackHeader/TabStackHeader";
import { strings } from "./DashboardScreenContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { getUnitByLocale } from "../../../../../helpers/getUnitByLocale";

const platform = Platform as PlatformIOSStatic;
const { height } = Dimensions.get("screen");
interface LimitedModeProps {
  userState: UserState;
  bolusState: BolusState;
}

const determineBigNumberFontSize = () => {
  return 60;
};

const LimitedModeContent: React.FC<LimitedModeProps> = ({
  userState,
  bolusState
}) => {
  const { languageContent } = useLanguageContent(strings);
  const { scale } = useWindowDimensions();
  const getLastReading = () => {
    const now = new Date(bolusState.timestamp);
    const date = moment(now).format("MMM DD").split(" at")[0];
    const time = moment(now).format("h:mm a");

    return `${date} (${time})`;
  };

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%"
      }}>
      <View
        style={{
          alignItems: "center"
        }}>
        <View
          style={{
            flexDirection: "row",
            bottom:
              Platform.OS === "android"
                ? scale === 2.625
                  ? "-10%"
                  : height > 600
                  ? "-9%"
                  : "-14%"
                : "-2%"
          }}>
          <Sync />
          <Text
            style={{
              ...theme.fonts.text200,
              color: theme.colors.grayScale.gray200,
              paddingLeft: 8
            }}>
            {languageContent.searching}
          </Text>
        </View>
        <Text
          style={[
            styles.bigNumber,
            {
              textAlignVertical: "center",
              color: theme.colors.limitedMode
            }
          ]}>
          --
        </Text>
      </View>
      <Text style={[styles.measurement]}>
        {!userState.limitedMode && "mg/dL"}
      </Text>
      <Text
        style={{
          fontSize: 14,
          fontWeight: "500",
          alignSelf: "flex-start",
          paddingLeft: 8,
          bottom: -8
        }}>
        {!userState.limitedMode && languageContent.dexcom}
      </Text>
      <Text
        style={{
          ...theme.fonts.text400,
          fontWeight: "400",
          alignSelf: "center",
          paddingLeft: 8,
          bottom:
            Platform.OS === "android"
              ? scale === 2.625
                ? "36%"
                : height > 700
                ? "24%"
                : height > 600 && height < 700
                ? "34%"
                : "48%"
              : scale === 3
              ? 48
              : 16,
          color: theme.colors.grayScale.gray200
        }}>
        {`${languageContent.lastReading} ${getLastReading()}`}
      </Text>
    </View>
  );
};

const DashboardScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );

  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { trendingIcon, trendingiPadIcon } = useCGM();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={[styles.innerWrapper]}>
      <View style={[styles.card]}>
        <TabStackHeader label={languageContent.unit} hasIOB />
        {!userState.limitedMode ? (
          <>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "100%"
              }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: -24
                }}>
                <>
                  <Text
                    style={[
                      styles.bigNumber,
                      {
                        textAlignVertical: "center",
                        color: userState.automatedMode
                          ? theme.colors.purple
                          : theme.colors.blue
                      }
                    ]}>
                    {getUnitByLocale(
                      bolusState.cgmInfo.Value,
                      langState.units,
                      langState.locale
                    )}
                  </Text>
                  {platform.isPad ? trendingiPadIcon : trendingIcon}
                </>
              </View>

              <Text
                style={[
                  styles.measurement,
                  {
                    fontSize: 16,
                    marginBottom: "13%"
                  }
                ]}>
                {langState.units}
              </Text>
            </View>
            <Text
              style={{
                fontSize: 12,
                fontWeight: "500",
                alignSelf: "flex-start",
                paddingLeft: 8,
                marginTop: -80
              }}>
              {languageContent.dexcom}
            </Text>
          </>
        ) : (
          <LimitedModeContent userState={userState} bolusState={bolusState} />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 8
  },
  card: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.white,
    shadowColor: "#888",
    shadowOpacity: 0.7,
    shadowRadius: 6,
    shadowOffset: {
      height: 1,
      width: 0
    },
    elevation: 10
  },
  bigNumber: {
    fontSize: determineBigNumberFontSize(),
    fontWeight: "500",
    textAlign: "center",
    textAlignVertical: "center"
  },
  measurement: {
    ...theme.fonts.text200,
    textAlign: "center",
    textAlignVertical: "center",
    fontWeight: "400"
  }
});

export default DashboardScreen;
