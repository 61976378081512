import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { ReactComponent as AllSet } from "../../../../../../../../assets/All_Set.svg";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { Button } from "../../../../../atoms";
import { strings } from "./AllSetScreenContent";

const AllSetScreen: React.FC = () => {
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View
      style={[
        theme.layout.wrapper,
        { width: "100%", backgroundColor: "white" }
      ]}>
      <View style={[styles.innerWrapper, { width: "100%" }]}>
        <Text
          style={[
            theme.fonts.h3,
            { margin: 16, textAlign: "center", fontWeight: "300" }
          ]}>
          {languageContent.allSet}
        </Text>
        <AllSet width={200} height={200} style={{ alignSelf: "center" }} />
        <Text
          style={[
            theme.fonts.text600,
            { marginTop: 16, fontWeight: "300", textAlign: "left" }
          ]}>
          {languageContent.useDevice}
          <Text style={{ fontWeight: "600" }}>
            {languageContent.continueTwo}
          </Text>
        </Text>
        <Text
          style={[
            theme.fonts.text600,
            { marginTop: 16, fontWeight: "300", textAlign: "left" }
          ]}>
          {languageContent.anotherDevice}
        </Text>
        <Button
          variant={"primary"}
          style={{ elevation: 6, marginTop: 16 }}
          onPress={() => {
            navigate("Lock");
          }}>
          {languageContent.continue}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 16
  },
  textInput: {
    margin: 8,
    fontSize: 16,
    padding: 16,
    fontWeight: "400",
    borderWidth: 2,
    textAlign: "left",
    borderRadius: 6,
    backgroundColor: theme.colors.textInputBackgroundColor
  }
});

export default AllSetScreen;
