let strings = {
  "en-US": {
    insulinPaused: "Insulin delivery is paused",
    startInsulin: "Do you want to start insulin \ndelivery?",
    basalRestart: "This will restart your Everyday Basal \nProgram.",
    startInsulinButton: "start insulin",
    cancel: "cancel"
  },
  "en-GB": {
    insulinPaused: "Insulin delivery is paused",
    startInsulin: "Do you want to start insulin \ndelivery?",
    basalRestart: "This will restart your Everyday Basal \nProgramme",
    startInsulinButton: "start insulin",
    cancel: "cancel"
  },
  "de-DE": {
    insulinPaused: "Insulinabgabe ist unterbrochen",
    startInsulin: "Möchten Sie die Insulinabgabe \nstarten?",
    basalRestart:
      "Dadurch wird Ihr „Everyday“\n-Basalratenprofil neu gestartet.",
    startInsulinButton: "Insulin starten",
    cancel: "Abbr."
  },
  "es-US": {
    insulinPaused: "Se pausó la administración de insulina",
    startInsulin: "Desea iniciar la administración de \ninsulina?",
    basalRestart: "Se reiniciará su programa basal diario.",
    startInsulinButton: "iniciar la insulina",
    cancel: "cancelar"
  },
  "fr-FR": {
    insulinPaused: "L'administration d'insuline a été mise en pause",
    startInsulin: "Voulez-vous démarrer l'administration d'insuline ?",
    basalRestart: "Cela redémarrera votre programme basal quotidien.",
    startInsulinButton: "démarrer l’administration d’insuline",
    cancel: "annuler"
  },
  "nl-NL": {
    insulinPaused: "Insulinetoediening is gepauzeerd",
    startInsulin: "Wilt u de insulinetoediening \nhervatten?",
    basalRestart:
      "Hierdoor wordt uw dagelijks \nbasaalprogramma opnieuw gestart.",
    startInsulinButton: "insuline starten",
    cancel: "annuleren"
  }
};

export { strings };
