import { useNavigation, useRoute } from '@react-navigation/native';
import { isEqual } from 'lodash';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native-web';
import { useSelector } from 'react-redux';
import { ReactComponent as BGIcon } from 'assets/bolus_bg_icon.svg';
import { ReactComponent as CarbsIcon } from 'assets/bolus_carbs_icon.svg';
import { getTime } from 'mobile/latest/helpers';
import replaceWithValues from 'mobile/latest/helpers/replaceWithValues';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { LangState } from 'web/store/reducers/language';
import { RootState } from 'web/store/store';
import theme from 'mobile/latest/theme';
import BolusParams from 'mobile/latest/types/bolusParams';
import { Button } from 'mobile/latest/components/atoms';
import { BottomInnerNav } from 'mobile/latest/components/molecules';
import { strings } from './ConfirmBolusScreenContent';

const ConfirmBolusScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const route = useRoute();
  const { carbs, bg, total, usingCGM, correction, extended } =
    route.params as BolusParams;
  const { languageContent } = useLanguageContent(strings);
  const { navigate } = useNavigation();

  const mealBolusU = Number(extended?.nowUnits).toLocaleString(
    langState.locale
  );
  const correctionBolusU = Number(correction).toLocaleString(langState.locale);
  const mealBolusExtendedU = Number(extended?.extendedUnits).toLocaleString(
    langState.locale
  );
  const contentVariables = [mealBolusU, correctionBolusU, mealBolusExtendedU];

  return (
    <>
      <View style={[styles.innerWrapper, { backgroundColor: '#FFFFFF' }]}>
        <View>
          <View style={[styles.summaryRow, { marginBottom: 8, marginTop: 8 }]}>
            <View style={[styles.summaryItem, { width: 185 }]}>
              <CarbsIcon height={24} width={24} style={{ marginRight: 8 }} />
              <Text style={[theme.fonts.h5, { fontSize: 16 }]}>
                {languageContent.carbs}
              </Text>
            </View>
            <View style={[styles.summaryItem]}>
              <Text style={[theme.fonts.h4, { fontSize: 16 }]}>
                {carbs ? carbs : '0'}
                <Text style={theme.fonts.body3}>{' g'}</Text>
              </Text>
            </View>
          </View>
          <View style={[styles.summaryRow]}>
            <View style={[styles.summaryItem, { width: 185 }]}>
              <BGIcon height={24} width={24} style={{ marginRight: 8 }} />
              <Text style={[theme.fonts.h5, { fontSize: 16 }]}>
                {usingCGM ? languageContent.sensor : languageContent.bg} (
                {getTime()})
              </Text>
            </View>
            <View style={[styles.summaryItem]}>
              <Text style={[theme.fonts.h4, { fontSize: 16 }]}>
                {bg ? bg : '0'}
                <Text
                  style={[theme.fonts.body1, { fontSize: 16 }]}
                >{` ${langState.units}`}</Text>
              </Text>
            </View>
          </View>
          {extended ? (
            <View style={[styles.extendedSummarySection]}>
              <View>
                <View style={[styles.extendedSummaryRow]}>
                  <View style={[styles.summaryItem]}>
                    <Text style={[theme.fonts.h5, { fontSize: 16 }]}>
                      {languageContent.bolusNow}
                    </Text>
                  </View>
                  <View style={[styles.summaryItem]}>
                    <Text
                      style={[
                        theme.fonts.h5,
                        { fontSize: 16, marginLeft: 109 },
                      ]}
                    >
                      {55}
                      <Text style={theme.fonts.body2}>%</Text>
                    </Text>
                  </View>
                </View>
                <Text style={[theme.fonts.body2, styles.extendedSummarySubRow]}>
                  {languageContent.mealBolus} 1.45 U
                </Text>
                <Text style={[theme.fonts.body2, styles.extendedSummarySubRow]}>
                  {replaceWithValues(
                    languageContent.correction,
                    contentVariables
                  )}
                </Text>
              </View>
              <View style={[styles.extendedSummarySection, { marginTop: 8 }]}>
                <View>
                  <View style={[styles.extendedSummaryRow]}>
                    <View style={[styles.summaryItem]}>
                      <Text style={[theme.fonts.h5, { fontSize: 16 }]}>
                        {languageContent.bolusExtended}
                      </Text>
                    </View>
                    <View style={[styles.summaryItem]}>
                      <Text
                        style={[
                          theme.fonts.h5,
                          { fontSize: 16, marginLeft: 74 },
                        ]}
                      >
                        45% (4 hrs)
                      </Text>
                    </View>
                  </View>
                  <Text
                    style={[theme.fonts.body2, styles.extendedSummarySubRow]}
                  >
                    {languageContent.mealBolus} 1.25 U
                  </Text>
                </View>
              </View>
            </View>
          ) : null}
        </View>
        <View>
          <View style={[styles.break]} />
          <View style={[styles.summaryRow]}>
            <View style={[styles.summaryItem]}>
              <View>
                <Text
                  style={[
                    theme.fonts.h4,
                    { fontWeight: 'bold', marginRight: 40 },
                  ]}
                >
                  {languageContent.totalBolus}
                </Text>
                <Text
                  style={[
                    theme.fonts.body3,
                    styles.inputLink,
                    { fontSize: 14 },
                  ]}
                  onPress={() => navigate('BolusCalculations')}
                >
                  {languageContent.calculations}
                </Text>
              </View>
            </View>
            <View style={[styles.summaryItem]}>
              <View>
                <Text style={[theme.fonts.h1, { fontSize: 40 }]}>
                  {total}
                  <Text
                    style={[
                      theme.fonts.h4,
                      { color: theme.colors.grayScale.gray400 },
                    ]}
                  >
                    {` ${languageContent.u}`}
                  </Text>
                </Text>
                <Text
                  style={[
                    {
                      color: theme.colors.grayScale.gray400,
                      fontSize: 12,
                    },
                  ]}
                >
                  {languageContent.adjustedIOB}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.buttonWrapper}>
            <Button
              variant="primary"
              style={{ elevation: 6, width: 265 }}
              onPress={() => navigate('DeliverBolus', route.params)}
            >
              {languageContent.start}
            </Button>
          </View>
        </View>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        leftActionStyle={styles.bottomNav}
      />
    </>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 16,
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
  },
  break: {
    borderColor: '#eee',
    borderWidth: 1,
    marginVertical: 16,
  },
  summaryRow: {
    flexDirection: 'row',
  },
  summaryItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  extendedSummarySection: {
    marginTop: 24,
  },
  extendedSummaryRow: {
    flexDirection: 'row',
  },
  extendedSummarySubRow: {
    color: theme.colors.grayScale.gray400,
  },
  inputLink: {
    textTransform: 'uppercase',
    fontWeight: '700',
    color: theme.colors.purple,
  },
  buttonWrapper: {
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 32,
  },
  bottomNav: {
    fontSize: 14,
  },
});

export default ConfirmBolusScreen;
