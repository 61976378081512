import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  topBarSignInTitle: {
    id: 'txid.top-bar.sign-in-title',
    defaultMessage: 'Sign In',
  },
  topBarAllSetTitle: {
    id: 'txid.top-bar.all-set-title',
    defaultMessage: 'All Set',
  },
  topBarOmnipodSecurityTitle: {
    id: 'txid.top-bar.omnipod-security-title',
    defaultMessage: 'Omnipod 5 Security',
  },
  topBarOmnipodSecurityCompleteTitle: {
    id: 'txid.top-bar.omnipod-security-complete-title',
    defaultMessage: 'Security Setup Complete',
  },
  topBarNotificationsTitle: {
    id: 'txid.top-bar.notifications-title',
    defaultMessage: 'Notifications',
  },
  topBarSoundTitle: {
    id: 'txid.top-bar.sound-title',
    defaultMessage: 'Sound',
  },
  topBarTermsConditionsTitle: {
    id: 'txid.top-bar.terms-conditions-title',
    defaultMessage: 'Terms & Conditions',
  },
  topBarHelpTitle: {
    id: 'txid.top-bar.terms-conditions-title',
    defaultMessage: 'Help',
  },
  topBarAccessibilitySettingsTitle: {
    id: 'txid.top-bar.accessibility-settings-title',
    defaultMessage: 'Accessibility Settings',
  },
  topBarDateTimeTitle: {
    id: 'txid.top-bar.date-time-title',
    defaultMessage: 'Date & Time',
  },
  backTitle: {
    id: 'txid.back-title',
    defaultMessage: 'Back',
  },
  closeTitle: {
    id: 'txid.close-title',
    defaultMessage: 'Close',
  },
  hippaPrivacyNoticeMessage: {
    id: 'txid.hippa-privacy-notice-message',
    defaultMessage:
      ' I certify I am 18 years old or older and I acknowledge that I have read and accept the HIPAA Privacy Notice, EULA, ToU, and Warranty. If you are under 18, your parent or guardian must accept on your behalf.',
  },
  readTCTitle: {
    id: 'txid.read-tc-title',
    defaultMessage: 'Read T&C',
  },
  agreeContinueTitle: {
    id: 'txid.agree-continue-title',
    defaultMessage: ' Agree & Continue',
  },
  welcomeOmnipodTitle: {
    id: 'txid.welcome-omnipod-title',
    defaultMessage: 'Welcome to Omnipod 5',
  },
  getStartedMessage: {
    id: 'txid.get-started-message',
    defaultMessage:
      ' To get started, create an account, enter therapy and insurance information, and schedule your training at: www.omnipod.com/setup',
  },

  okButtonTitle: {
    id: 'txid.ok-button-title',
    defaultMessage: 'Ok',
  },

  enterOmnipodIdMessage: {
    id: 'txid.ente-omnipodId-message',
    defaultMessage:
      'Enter your Omnipod ID and password to link this device to your Omnipod account.',
  },
  omnipodIdTitle: {
    id: 'txid.omnipod-id-title',
    defaultMessage: 'Omnipod ID',
  },
  usernameTitle: {
    id: 'txid.username-title',
    defaultMessage: 'johnsmith',
  },
  passwordTitle: {
    id: 'txid.password-title',
    defaultMessage: 'Password',
  },
  visiblePasswordTitle: {
    id: 'txid.visible-password-title',
    defaultMessage: 'MyPassword23',
  },
  dontHaveAccountMessage: {
    id: 'txid.dont-have-account-message',
    defaultMessage: "Don't have an account? Go to: www.omnipod.com/setup",
  },
  needHelpMessage: {
    id: 'txid.need-help-message',
    defaultMessage: 'Need help?',
  },
  submitButtonTitle: {
    id: 'txid.submit-button-title',
    defaultMessage: 'Submit',
  },
  deviceLinkedMessage: {
    id: 'txid.device-linked-message',
    defaultMessage: 'Your device has been linked to your Omnipod account.',
  },
  continueButtonTitle: {
    id: 'txid.continue-button-title',
    defaultMessage: 'Continue',
  },
  setUpPinMessage: {
    id: 'txid.set-up-pin-message',
    defaultMessage:
      'You must set up a PIN or other security method before using the Omnipod 5 App.',
  },
  phonesPinMessage: {
    id: 'txid.phones-pin-message',
    defaultMessage:
      "Your phone's PIN (or other security method) must be entered each time you access the Omnipod 5 App.",
  },
  setUpSecurityButtonTitle: {
    id: 'txid.set-up-security-button-title',
    defaultMessage: 'Set Up Security',
  },
  useYourPinMessage: {
    id: 'txid.use-your-pin-message',
    defaultMessage:
      'Your PIN (or other security method you use on your phone) will be required to open the Omnipod 5 App.',
  },
  iUnderstandButtonTitle: {
    id: 'txid.i-understand-button-title',
    defaultMessage: ' I Understand',
  },
  keepNotificationsMessage: {
    id: 'txid.keep-notifications-message',
    defaultMessage:
      'To use the Omnipod 5 App, keep Notifications, Alert Styles, Critical Alerts and Time Sensitive Notifications on.',
  },
  notificationsAlertMessage: {
    id: 'txid.notifications-alert-message',
    defaultMessage:
      'Notifications alert you when an issue needs your attention. Alert Styles include the Lock Screen, Notifications Center, Banners, Sounds, and Badges, and they change how you receive notifications.',
  },
  criticalAlertMessage: {
    id: 'txid.critical-alerts-message',
    defaultMessage:
      'Critical Alerts play a sound and appear on the lock screen even if your iPhone is muted or Do Not Disturb is on.',
  },
  timeSensitiveNotificationsMessage: {
    id: 'txid.time-sensitive-notifications-message',
    defaultMessage:
      'Time Sensitive Notifications allow apps not included in your Allowed Apps to immediately send notifications.',
  },
  cannotUseAppMessage: {
    id: 'txid.cannot-use-app-message',
    defaultMessage:
      'You cannot use the app if you turn off Notifications, Alert Styles, Critical Alerts and Time Sensitive Notifications',
  },
  silenceDeviceMessage: {
    id: 'txid.silent-device-message',
    defaultMessage:
      'If you silence your device, the Omnipod 5 App will still sound for critical alarms and urgent low glucose.',
  },
  yourPodSilencedMessage: {
    id: 'txid.your-pod-silenced-message',
    defaultMessage: 'Your Pod will not be silenced.',
  },
  accessibilitySettingsMessage: {
    id: 'txid.accessibility-settings-message',
    defaultMessage:
      'Accessibility settings are not supported by the Omnipod 5 App.',
  },
  dateTimeOnMessage: {
    id: 'txid.date-time-on-message',
    defaultMessage:
      'To use the Omnipod 5 App, keep Automatic Date and Time on. ',
  },
  dateTimeOffMessage: {
    id: 'txid.date-time-off-message',
    defaultMessage:
      'Turning Automatic Date and Time off may affect your insulin delivery.',
  },
  greatTitle: {
    id: 'txid.great-title',
    defaultMessage: 'Great!',
  },
  readyToBuildMessage: {
    id: 'txid.ready-to-build-message',
    defaultMessage:
      'You are now ready to build your insulin management profile.',
  },
  setUpProfileButtonTitle: {
    id: 'txid.set-up-profile-button-title',
    defaultMessage: 'Set Up Profile',
  },
  insuletTermsMessage: {
    id: 'txid.insulet-terms-message',
    defaultMessage:
      'Insulet Corporation{separator} Terms and Conditions  for Omnipod® 5 Automated Insulin Delivery System',
  },
  termsConditionsMessage: {
    id: 'txid.terms-conditions-message',
    defaultMessage:
      'The Terms and Conditions for Omnipod® 5 Automated Insulin Delivery System consist of the following:',
  },
  hippaPrivacyNoticeTitle: {
    id: 'txid.hippa-privacy-notice-title',
    defaultMessage: 'HIPAA Privacy Notice',
  },
  endUserLicenseAgreementTitle: {
    id: 'txid.end-User-License-Agreement-title',
    defaultMessage: 'End User License Agreement (EULA)',
  },
  termsOfUseTitle: {
    id: 'txid.terms-of-use-title',
    defaultMessage: 'Terms of Use (ToU)',
  },
  limitedExpressWarrantyTitle: {
    id: 'txid.limited-express-warranty-title',
    defaultMessage: 'Limited Express Warranty',
  },
  legalNoticesTitle: {
    id: 'txid.legal-notices-title',
    defaultMessage: 'Legal Notices',
  },
  privacyPolicyTitle: {
    id: 'txid.privacy-policy-title',
    defaultMessage: 'Privacy Policy',
  },
  privacyPolicyMessage: {
    id: 'txid.privacy-policy-message',
    defaultMessage:
      'This Privacy Policy ("Privacy Policy") applies to all personally identifiable information (excluding any Protected Health Information ("PHI") as such term is defined by the Health Insurance Portability and Accountability Act of 1996, as amended ("HIPAA"), collectively, ("Personal Data") that is collected on the www.Omnipod.com or other Insulet Corporation Websites (the "Websites")), the My Omnipod® mobile application or other mobile applications we make accessible or available for download (the “Application(s)”), or any other web-based communication channels or services created, used, offered or operated by Insulet Corporation (collectively the “Services”). Personal Data includes information we can use to specifically identify you such as your name, address, date of birth, telephone number, email address, username, Internet Protocol (IP) address or device identifiers when such address is associated with a specific user. This Privacy Policy applies only to Personal Data collected through the Services. It does not apply to any Personal Data collected by Insulet Corporation through other means. The Privacy Policy also does not apply to any PHI. For information regarding our policies with respect to PHI, please see our HIPAA Privacy Notice.',
  },
  needToSignMessage: {
    id: 'txid.need-to-sign-message',
    defaultMessage:
      'You need to sign in to the Omnipod web site to unlock this device before you can begin setup.',
  },
  needAnAccountTitle: {
    id: 'txid.need-an-account-title',
    defaultMessage: 'Need an account?',
  },
  needAnAccountMessage: {
    id: 'txid.need-an-account-message',
    defaultMessage: 'Create an account at: www.omnipod.com/setup',
  },
  forgotOmnipodIdTitle: {
    id: 'txid.forgot-omnipodId-title',
    defaultMessage: 'Forgot your Omnipod ID or password?',
  },
  forgotOmnipodIdMessage: {
    id: 'txid.forgot-omnipodId-message',
    defaultMessage:
      'To reset your Omnipod ID or password, go to: www.omnipod.com/setup',
  },
  contactCustomerCareTitle: {
    id: 'txid.contact-customer-care-title',
    defaultMessage: 'For other questions, contact Customer Care.',
  },
  contactCustomerCareMessage: {
    id: 'txid.contact-customer-care-message',
    defaultMessage: '<customer_care_helpline>',
  },
});
