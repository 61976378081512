import React from "react";
import {
  FlatList,
  Modal,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import CustomRadioButton from "../../molecules/customRadioButton";
import { strings } from "./CustomPickerContent";
import { LocaleType } from "../../../types/locales";

interface Props {
  title: string;
  values: {
    index: number;
    key: string;
    label: string;
    value: number;
    helpText?: string;
  }[];
  isVisible: boolean;
  reverse?: boolean;
  hideDismiss?: boolean;
  initialIndex?: number;
  selectedValue?: string;
  units?: string;
  onDismiss: () => void;
  onConfirm: (value: string) => void;
  is24Hour: boolean;
  locale: LocaleType;
}

const CustomPicker: React.FC<Props> = ({
  title,
  values,
  isVisible,
  reverse,
  initialIndex,
  selectedValue,
  units,
  onDismiss,
  onConfirm,
  is24Hour,
  locale
}) => {
  const [selected, setSelected] = React.useState<string | null>(null);
  const [scrollPosition, setScrollPosition] = React.useState(initialIndex);

  const { languageContent } = useLanguageContent(strings);

  const handleRadioButtonPress = (label: string) => {
    setSelected(label);
  };

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    let yOffset = event.nativeEvent.contentOffset.y / 50;
    setScrollPosition(yOffset);
  };

  React.useEffect(() => {
    // endtime for expert basal segments
    if (selectedValue) {
      setSelected(selectedValue.toUpperCase());
      const selectedIndex = values.findIndex(
        (values) => values.label === selectedValue.toUpperCase()
      );
      setScrollPosition(selectedIndex);
      // max basal rate for settings screen
    } else if (initialIndex === 599) {
      setSelected("30");
    }

    if (!isVisible) {
      setScrollPosition(initialIndex);
    }
  }, [initialIndex, selectedValue, values, isVisible]);

  return (
    <View style={styles.centeredView}>
      <Modal transparent={true} visible={isVisible}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={styles.modalHeader}>
              <Text style={styles.headerText}>{title}</Text>
            </View>
            <FlatList
              data={values}
              onScroll={(event) => handleScroll(event)}
              inverted={reverse}
              keyExtractor={(_, index) => index.toString()}
              initialScrollIndex={scrollPosition}
              getItemLayout={(_, index) => ({
                length: 50,
                offset: 50 * index,
                index
              })}
              renderItem={({ item }) => (
                <View
                  key={`${item.index}--${item.label}`}
                  style={[
                    styles.radioButtonRowItem,
                    {
                      backgroundColor:
                        selected === item.label ? "#ebe7f6" : "transparent"
                    }
                  ]}>
                  <CustomRadioButton
                    locale={locale}
                    is24Hour={is24Hour}
                    item={item}
                    index={item.index}
                    helpText={item.helpText}
                    handleRadioButtonPress={handleRadioButtonPress}
                    selectedItem={selected}
                    units={
                      units !== `${languageContent.hrs}`
                        ? units
                        : item.index < 1 || item.value === 1
                        ? `${languageContent.hr}`
                        : `${languageContent.hrs}`
                    }
                  />
                </View>
              )}
            />
            <View style={styles.footer}>
              <TouchableOpacity
                style={[styles.button]}
                onPress={() => onDismiss()}
                onBlur={onDismiss}>
                <Text style={styles.cancelStyle}>{languageContent.cancel}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button]}
                onPress={() => {
                  selected && onConfirm(selected);
                }}>
                <Text
                  style={
                    (styles.doneStyle,
                    {
                      ...theme.fonts.text400,
                      color:
                        selected !== null
                          ? theme.colors.purple
                          : theme.colors.placeholder
                    })
                  }>
                  {languageContent.done}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    height: 300,
    margin: 20,
    backgroundColor: "white",
    paddingVertical: 12,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  modalHeader: {
    paddingBottom: 16,
    paddingTop: 4,
    borderBottomWidth: 1,
    alignSelf: "stretch",
    borderBottomColor: theme.colors.grayScale.gray600
  },
  headerText: {
    fontWeight: "bold",
    marginLeft: 16
  },
  radioButtonRowItem: {
    paddingVertical: 6,
    borderBottomWidth: 1,
    height: 50,
    borderBottomColor: theme.colors.grayScale.gray600
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: 210
  },
  cancelStyle: {
    ...theme.fonts.text400,
    color: theme.colors.purple,
    textAlign: "center"
  },
  doneStyle: {
    textAlign: "center"
  },
  button: {
    borderRadius: 8,
    padding: 10
  }
});

export default CustomPicker;
