import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native-web";
import { useSelector } from "react-redux";
import SwitchedToAutomatedMode from "../../../../../assets/history/switched_to_automated_mode.png";
import SwitchedToManualMode from "../../../../../assets/history/switched_to_manual_mode.png";
import getCurrentHourFormat from "../../../helpers/getCurrentHourFormat";
import useLanguageContent from "../../../hooks/useLanguageContent";
import { LangState } from "../../../../../web/store/reducers/language";
import { RootState } from "../../../../../web/store/store";
import theme from "../../../theme";
import { strings } from "./SwitchedModeRowContent";
interface ManualProps {
  time: string;
  manual?: boolean;
  auto?: boolean;
}

const SwitchedModeRow: React.FC<ManualProps> = ({ time, manual, auto }) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between"
      }}>
      <View
        style={[
          styles.header,
          {
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            flex: 1
          }
        ]}>
        <Text
          style={[
            {
              flex: 1,
              fontSize: 14,
              color: theme.colors.white
            }
          ]}>
          {getCurrentHourFormat({
            timeStr: time,
            is24Hour: langState.is24Hour
          })}
        </Text>
        <View style={[styles.innerContainer, { justifyContent: "flex-start" }]}>
          {manual && (
            <img
              style={{ height: 30, marginLeft: -24, marginRight: 16 }}
              src={SwitchedToManualMode}
            />
          )}
          {auto && (
            <img
              style={{ height: 30, marginLeft: -24, marginRight: 16 }}
              src={SwitchedToAutomatedMode}
            />
          )}
          <Text
            style={{
              fontSize: 12,
              color: theme.colors.white
            }}>
            {manual
              ? languageContent.switchedToManual
              : languageContent.switchedToAutomated}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    padding: 20
  },
  innerContainer: {
    flex: 2,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  }
});

export default SwitchedModeRow;
