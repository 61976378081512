import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cta: {
    id: 'L2.scan-sensor.cta',
    defaultMessage: 'Scan Now'
  },
  howToScan: {
    id: 'L2.scan-sensor.how-to-scan',
    defaultMessage: 'HOW TO SCAN A SENSOR'
  }
})