import React from "react";
import {
  BasalProgram,
  ExpertBasalSegment
} from "web/store/reducers/basal/reducer";
import { GraphVerticalLine } from "../../atoms";
import { BasalSegmentChartItem } from "../../molecules";

interface Props {
  startTime: string;
  endTime: string;
  segmentsArrLength: number;
  maxHeight: number;
  segment: ExpertBasalSegment;
  maxBasalRateCalc: number;
  index: number;
  program: BasalProgram;
  segmentEndTime: string;
  displayOnly?: boolean;
}

const CurrentBasalScreen: React.FC<Props> = ({
  startTime,
  endTime,
  segmentsArrLength,
  maxHeight,
  segment,
  maxBasalRateCalc,
  index,
  program,
  segmentEndTime,
  displayOnly
}) => {
  return (
    <>
      {/* far left vertical bar */}
      {!displayOnly && endTime !== "" && segmentsArrLength < 1 && (
        <GraphVerticalLine maxHeight={maxHeight} marginLeftNumber={-1} />
      )}
      <BasalSegmentChartItem
        hideBottomBorder={endTime !== ""}
        segment={segment}
        maxBasalRateCalc={maxBasalRateCalc}
        maxHeight={maxHeight}
        index={index}
        program={program}
        displayOnly
      />
      {!displayOnly && index === segmentsArrLength - 1 && (
        <GraphVerticalLine maxHeight={maxHeight} marginLeftNumber={-3} />
      )}
      {!displayOnly && startTime === segmentEndTime && (
        <GraphVerticalLine maxHeight={maxHeight} marginLeftNumber={-2} />
      )}
    </>
  );
};

export default CurrentBasalScreen;
