import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const HomeIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons/Navigation/Home/Selected/Auto/Light">
        <path className={classes} id="Path" fillRule="evenodd" clipRule="evenodd" d="M10.3577 3.4156L4.35077 8.32694C3.27378 9.2075 2.6499 10.5332 2.6499 11.9318V18.9188C2.6499 20.8071 4.16598 22.3499 6.05163 22.3499H8.39645L8.59339 22.3447C10.399 22.2472 11.2982 20.8018 11.2982 18.9188L11.2979 17.8939L11.4948 17.8846L11.7492 17.8802L13.3861 17.8814L13.7009 17.8939L13.7016 18.919C13.7016 20.869 14.6661 22.3499 16.6034 22.3499H18.9482C20.8338 22.3499 22.3499 20.8071 22.3499 18.9188V11.9318C22.3499 10.5332 21.726 9.2075 20.649 8.32694L14.6421 3.4156C13.3934 2.39467 11.6064 2.39467 10.3577 3.4156Z"/>
      </g>
    </svg>
  );
}
