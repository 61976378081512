let strings = {
  "en-US": {
    start: "Start",
    end: "End",
    am: "AM",
    oneUnitDecrease: "Unit of insulin lowers glucose by",
    oneUnitDecreaseModalTitle: "Unit of insulin lowers",
    oneUnitDecreaseModalTitleCont: "Glucose by",
    correctionFactor: "Correction Factor",
    night: "Night",
    morning: "Morning",
    range: "(%1 to %2)",
    cancel: "Cancel",
    next: "Next",
    modalTitle: "Segment End Time",
    ok: "OK",
    okCamel: "Ok",
    modalBody:
      "For simulation purposes we have auto-filled this information for you.",
    minValue: "Minimum Value",
    correctionFactorMin: "Correction factor cannot be less than 1\u00A0U/hr.",
    maxValue: "Maximum Value",
    correctionFactorMax: "Correction factor cannot be more than 400\u00A0U/hr."
  },
  "en-GB": {
    start: "Start",
    end: "End",
    am: "AM",
    oneUnitDecrease: "Unit of insulin lowers glucose by",
    oneUnitDecreaseModalTitle: "Unit of insulin lowers",
    oneUnitDecreaseModalTitleCont: "Glucose by",
    correctionFactor: "Correction Factor",
    night: "Night",
    morning: "Morning",
    range: "(%1 to %2)",
    cancel: "Cancel",
    next: "Next",
    modalTitle: "Segment End Time",
    ok: "OK",
    okCamel: "Ok",
    modalBody:
      "For simulation purposes we have auto-filled this information for you.",
    minValue: "Minimum Value",
    correctionFactorMin: "Correction factor cannot be less than 1\u00A0U/hr.",
    maxValue: "Maximum Value",
    correctionFactorMax: "Correction factor cannot be more than 400\u00A0U/hr."
  },
  "de-DE": {
    start: "Start",
    end: "Ende",
    am: "AM",
    oneUnitDecrease: "Einheit Insulin senkt den Blutzucker um",
    oneUnitDecreaseModalTitle: "Einheit Insulin senkt",
    oneUnitDecreaseModalTitleCont: "den Blutzucker um",
    correctionFactor: "Korrekturfaktor",
    night: "Nacht",
    morning: "Vormittag",
    range: "(%1 bis %2)",
    cancel: "Abbr.",
    next: "Weiter",
    modalTitle: "Segmentende",
    ok: "OK",
    okCamel: "OK",
    modalBody:
      "Zu Simulationszwecken haben wir diese Informationen automatisch für Sie ausgefüllt.",
    minValue: "Mindestwert",
    correctionFactorMin:
      "Der Korrekturfaktor darf nicht weniger als 1 E/Std. betragen.",
    maxValue: "Höchstwert",
    correctionFactorMax:
      "Der Korrekturfaktor darf nicht mehr als 400 E/Std. betragen."
  },
  "es-US": {
    start: "Iniciar",
    end: "Finalizar",
    am: "a. m.",
    oneUnitDecrease: "unidad de insulina reduce la glucosa en",
    oneUnitDecreaseModalTitle: "unidad de insulina reduce",
    oneUnitDecreaseModalTitleCont: "la glucosa en",
    night: "Noche",
    morning: "Mañana",
    range: "(%1 a %2)",
    cancel: "Cancelar",
    next: "Siguiente",
    modalTitle: "Hora de finalización del segmento",
    ok: "Aceptar",
    okCamel: "Aceptar",
    modalBody:
      "Completamos automáticamente esta información para fines de simulación.",
    minValue: "Valor mínimo",
    correctionFactorMin: "El factor de corrección no puede ser menor de 1 U/h.",
    maxValue: "Valor máximo",
    correctionFactorMax:
      "El factor de corrección no puede ser mayor de 400 U/h.",
    correctionFactor: "Reduce la glucosa en"
  },
  "fr-FR": {
    start: "Démarrer",
    end: "Fin",
    am: "du matin",
    oneUnitDecrease: "Unité d'insuline réduit la glycémie de",
    oneUnitDecreaseModalTitle: "Unité d'insuline réduit la",
    oneUnitDecreaseModalTitleCont: "glycémie de",
    night: "Nuit",
    morning: "Matin",
    range: "(%1 à %2)",
    cancel: "Annuler",
    next: "Suivant",
    modalTitle: "Heure de fin du segment",
    ok: "OK",
    okCamel: "Ok",
    modalBody:
      "À des fins de simulation, nous avons rempli automatiquement ces informations pour vous.",
    minValue: "Valeur minimum",
    correctionFactorMin:
      "Le facteur de correction ne peut être inférieur à 1 U/h.",
    maxValue: "Valeur maximum",
    correctionFactorMax:
      "Le facteur de correction ne peut être supérieur à 400 U/h."
  },
  "nl-NL": {
    start: "Start",
    end: "Stop",
    am: "AM",
    oneUnitDecrease: "Eenheid insuline verlaagt de glucose met",
    oneUnitDecreaseModalTitle: "Eenheid insuline verlaagt",
    oneUnitDecreaseModalTitleCont: "Glucose met",
    night: "Nacht",
    morning: "Ochtend",
    range: "(%1 tot %2)",
    cancel: "Annuleren",
    next: "Volgende",
    modalTitle: "Eindtijd segment",
    ok: "OK",
    okCamel: "OK",
    modalBody:
      "Voor simulatiedoeleinden hebben wij deze informatie automatisch voor u ingevuld.",
    minValue: "Minimumwaarde",
    correctionFactorMin: "Correctiefactor mag niet lager zijn dan 1 E/u.",
    maxValue: "Maximumwaarde",
    correctionFactorMax: "Correctiefactor mag niet hoger zijn dan 400 E/u."
  }
};

export { strings };
