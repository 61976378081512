import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'ios.switch-mode.top-bar.title',
    defaultMessage: 'Switch Mode',
  },
});


export const autoModeMessages = defineMessages({
  title: {
    id: 'ios.switch-mode.auto.title',
    defaultMessage: 'Switch to Automated Mode?',
  },
  paragraphOne: {
    id: 'ios.switch-mode.auto.paragraph-one',
    defaultMessage: 'In Automated Mode, insulin delivery is adjusted based on your sensor glucose values.',
  },
  paragraphTwo: {
    id: 'ios.switch-mode.auto.paragraph-two',
    defaultMessage: 'Automated Mode may be Limited until sensor glucose values are received.',
  },
  button: {
    id: 'ios.switch-mode.auto.button',
    defaultMessage: 'Switch',
  },
});

export const manualModeMessages = defineMessages({
  title: {
    id: 'ios.switch-mode.manual.title',
    defaultMessage: 'Switch to Manual Mode?',
  },
  text: {
    id: 'ios.switch-mode.manual.text',
    defaultMessage: 'This will start your Weekdays Basal Program.',
  },
  currentBasalProgram: {
    id: 'ios.switch-mode.manual.current-basal-program',
    defaultMessage: 'CURRENT BASAL PROGRAM',
  },
  programName: {
    id: 'ios.switch-mode.manual.basal-program-name',
    defaultMessage: 'Weekdays',
  },
  programSchedule: {
    id: 'ios.switch-mode.manual.program-schedule',
    defaultMessage: '12 AM - 12 AM, 31 U/day',
  },
  button: {
    id: 'ios.switch-mode.manual.button',
    defaultMessage: 'Switch',
  },
})