import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { useSelector } from "react-redux";
import { ReactComponent as Decline } from "../../../../../../../../assets/Decline_Triangle.svg";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { Button } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./DeclineScreenContent";

const DeclineScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [accepted, setAccepted] = React.useState(false);

  const { languageContent } = useLanguageContent(strings);
  const { navigate } = useNavigation();

  const handleCheckboxPress = () => {
    setAccepted(!accepted);
  };

  React.useEffect(() => {
    if (langState.language === "de-DE" || langState.language === "nl-NL") {
      navigate("AllSet");
    }
  }, [langState.language, navigate]);

  return (
    <View
      style={[
        theme.layout.wrapper,
        {
          width: "100%",
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: "center"
        }
      ]}>
      <Decline height={75} width={75} />
      <View style={[styles.innerWrapper, { width: "100%" }]}>
        <Text
          style={[
            theme.fonts.h5,
            { fontSize: 18, textAlign: "center", marginTop: -20 }
          ]}>
          {languageContent.declined}
        </Text>
        <Text
          style={[
            theme.fonts.h5,
            { fontSize: 18, textAlign: "center", marginBottom: 20 }
          ]}>
          {languageContent.confirm}
        </Text>
        <View
          style={{
            borderColor: "black",
            borderWidth: 1,
            borderRadius: 4,
            marginBottom: 12
          }}>
          <ScrollView style={{ height: 275 }}>
            <Text style={{ fontSize: 16, lineHeight: 24 }}>
              {languageContent.termsOfUse}
            </Text>
          </ScrollView>
        </View>
        <Text style={[theme.fonts.text600, { fontWeight: 300 }]}>
          <TouchableOpacity
            style={[
              styles.checkbox,
              { backgroundColor: accepted ? "black" : "transparent" }
            ]}
            onPress={handleCheckboxPress}>
            {accepted && (
              <Text
                style={{
                  ...theme.fonts.text400,
                  color: "white",
                  marginLeft: 3,
                  marginTop: -2
                }}>
                ✓
              </Text>
            )}
          </TouchableOpacity>
        </Text>
        <Text
          style={[
            styles.subtext,
            theme.fonts.text600,
            { fontWeight: 300, marginLeft: 32, marginTop: -24 }
          ]}>
          {languageContent.accept}
        </Text>
        {accepted ? (
          <Button
            variant={"primary"}
            style={{ elevation: 6, width: "100%", marginTop: 40 }}
            onPress={() => {
              navigate("AllSet");
            }}>
            {languageContent.continue}
          </Button>
        ) : null}
      </View>
      <BottomInnerNav />
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 16,
    marginTop: 20
  },
  title: {
    margin: 16,
    textAlign: "center",
    fontWeight: "200"
  },
  subtext: {
    fontWeight: "300",
    textAlign: "left",
    backgroundColor: "white",
    marginRight: 12,
    fontSize: 14
  },
  checkbox: {
    width: 18,
    height: 18,
    borderColor: "black",
    borderWidth: 1,
    marginRight: 14
  }
});

export default DeclineScreen;
