import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const ArrowIcon: React.FC<IconProps> = ({
  color,
  width = '18',
  height = '18',
}) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.3319 0.4848C7.55981 0.4848 7.76362 0.57987 7.91144 0.73094L11.9395 4.75903C12.2579 5.0781 12.2579 5.59448 11.9395 5.91224C11.6211 6.23066 11.1054 6.23066 10.787 5.91224V5.91289L8.14521 3.27113L8.14521 12.6967C8.14521 13.1447 7.78186 13.5081 7.33386 13.5081C6.88521 13.5081 6.52186 13.1447 6.52186 12.6967H6.5186L6.5186 3.27764L3.88074 5.91615C3.56232 6.23457 3.04595 6.23457 2.72753 5.91615C2.40911 5.59773 2.40911 5.08136 2.72753 4.76294L6.73869 0.751778C6.88651 0.590289 7.09553 0.4848 7.3319 0.4848Z"
        fill="#FFA700"
      />
      <mask
        id="mask0_2_2803"
        maskUnits="userSpaceOnUse"
        x="2"
        y="0"
        width="11"
        height="14"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.3319 0.4848C7.55981 0.4848 7.76362 0.57987 7.91144 0.73094L11.9395 4.75903C12.2579 5.0781 12.2579 5.59448 11.9395 5.91224C11.6211 6.23066 11.1054 6.23066 10.787 5.91224V5.91289L8.14521 3.27113L8.14521 12.6967C8.14521 13.1447 7.78186 13.5081 7.33386 13.5081C6.88521 13.5081 6.52186 13.1447 6.52186 12.6967H6.5186L6.5186 3.27764L3.88074 5.91615C3.56232 6.23457 3.04595 6.23457 2.72753 5.91615C2.40911 5.59773 2.40911 5.08136 2.72753 4.76294L6.73869 0.751778C6.88651 0.590289 7.09553 0.4848 7.3319 0.4848Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2_2803)"></g>
    </svg>
  );
};
