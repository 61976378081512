import { createDrawerNavigator } from '@react-navigation/drawer';
import { isEqual } from 'lodash';
import React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { NavigationDrawerProp } from 'react-navigation-drawer';
import { useSelector } from 'react-redux';
import { ReactComponent as About } from 'assets/menu/menu_about.svg';
import { ReactComponent as Collapse } from 'assets/menu/menu_arrow_down.svg';
import { ReactComponent as Expand } from 'assets/menu/menu_arrow_up.svg';
import { ReactComponent as BasalProg } from 'assets/menu/menu_basal_programs.svg';
import { ReactComponent as EnterBG } from 'assets/menu/menu_enter_bg.svg';
import { ReactComponent as History } from 'assets/menu/menu_history_detail.svg';
import { ReactComponent as HypoPro } from 'assets/menu/menu_hypo_protect.svg';
import { ReactComponent as ManageSensor } from 'assets/menu/menu_manage_sensor.svg';
import { ReactComponent as Notifications } from 'assets/menu/menu_notifications.svg';
import { ReactComponent as PauseInsulin } from 'assets/menu/menu_pause_insulin.svg';
import { ReactComponent as PauseInsulinDisabled } from 'assets/menu/menu_pause_insulin_disabled.svg';
import { ReactComponent as Pod } from 'assets/menu/menu_pod.svg';
import { ReactComponent as Settings } from 'assets/menu/menu_settings.svg';
import { ReactComponent as SetTempBasal } from 'assets/menu/menu_set_temp_basal.svg';
import { ReactComponent as StartInsulin } from 'assets/menu/menu_start_insulin.svg';
import { ReactComponent as SwitchMode } from 'assets/menu/menu_switch_modes.svg';
import { ReactComponent as TempBasalPre } from 'assets/menu/menu_temp_basal_presets.svg';
import { ReactComponent as Logo } from 'assets/Omnipod_5_Simulator_LOGO_MANGO.svg';
import NavMenuItem from 'mobile/latest/components/molecules/navMenuItem';
import { snackbarLocations, snackbarTypes } from 'mobile/latest/context';
import { useSnackbar } from 'mobile/latest/hooks';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { RootState } from 'web/store/store';
import theme from 'mobile/latest/theme';
import { strings } from './DrawerStackContent';
import { HeaderMenuStack } from './HeaderMenuStack';
import { NavState } from 'web/store/reducers/navigation';
const Drawer = createDrawerNavigator();

const DrawerStack: React.FC = () => (
  <>
    <Drawer.Navigator
      initialRouteName="DrawerMain"
      drawerContent={(props: any) => <CustomDrawerContent {...props} />}
      drawerStyle={{ width: '75%' }}
    >
      <Drawer.Screen
        name="DrawerMain"
        component={HeaderMenuStack}
        options={{
          drawerLabel: () => null,
          title: undefined,
          drawerIcon: () => null,
          headerShown: false,
        }}
      />
    </Drawer.Navigator>
  </>
);
interface Props {
  navigation: NavigationDrawerProp;
}

const CustomDrawerContent: React.FC<Props> = ({ navigation }) => {
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const snackbar = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const userState = useSelector((state: RootState) => state.user, isEqual);
  const basalState = useSelector((state: RootState) => state.basal, isEqual);

  const { languageContent } = useLanguageContent(strings);
  const scrollRef = React.useRef<ScrollView | null>(null);

  const showSimNavWarning = () =>
    snackbar.showSnackbar(
      snackbarTypes.simWarning,
      'Feature unavailable in Simulator',
      snackbarLocations.bottom
    );

  const toggleOpen = () => {
    if (open) {
      endAnimation();
    } else {
      startAnimation();
    }
  };

  const startAnimation = () => {
    setOpen(true);
  };

  const endAnimation = () => {
    setOpen(false);
  };

  const scrollToEnd = () => {
    scrollRef.current?.scrollToEnd({ animated: true });
  };

  const scrollToTop = () => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };

  React.useLayoutEffect(() => {
    const handleDrawerOpen = () => {
      navigation.openDrawer();
      scrollToEnd();
    };

    if (navState.navigateToLabel === 'Settings') {
      handleDrawerOpen();
    }

    if (navState.navigateToLabel === 'Dashboard') {
      navigation.closeDrawer();
      scrollToTop();
    }
  }, [navState.navigateToLabel]);

  return (
    <View
      style={{
        flex: 1,
        height: '100%',
      }}
    >
      <View style={{ flex: 1, height: '100%' }}>
        <ScrollView
          {...navigation}
          showsVerticalScrollIndicator={false}
          ref={scrollRef}
        >
          <View
            style={{
              height: open ? 1108 : 828,
            }}
          >
            <TouchableOpacity
              style={styles.logoContainer}
              onPress={() => {
                navigation.closeDrawer();
              }}
            >
              <Logo />
            </TouchableOpacity>
            <View
              style={{
                borderColor: theme.colors.DrawerStackBorderColor,
                borderWidth: 1,
                marginBottom: 12,
              }}
            />
            <NavMenuItem
              label={languageContent.switchMode}
              navigateTo="SwitchMode"
              icon={<SwitchMode />}
              purple
              navigation={navigation}
              iconStyle={{ paddingTop: 8 }}
            />
            {userState.hypoProtectOn || userState.automatedMode ? (
              <NavMenuItem
                label={languageContent.setTempBasal}
                icon={<SetTempBasal />}
                isDisabled
                navigation={navigation}
                iconStyle={{ paddingTop: 8 }}
              />
            ) : (
              <NavMenuItem
                label={languageContent.setTempBasal}
                navigateTo="SetTempBasal"
                icon={<SetTempBasal />}
                purple
                navigation={navigation}
                iconStyle={{ paddingTop: 8 }}
              />
            )}
            {userState.automatedMode && !userState.hypoProtectOn ? (
              <NavMenuItem
                label={languageContent.activity}
                navigateTo="HypoProtect"
                icon={<HypoPro />}
                purple
                navigation={navigation}
                iconStyle={{ paddingTop: 8 }}
              />
            ) : (
              <NavMenuItem
                label={languageContent.activity}
                icon={<HypoPro />}
                isDisabled
                navigation={navigation}
                iconStyle={{ paddingTop: 8 }}
              />
            )}
            <NavMenuItem
              label={languageContent.pod}
              navigateTo="PodDetailsScreen"
              icon={<Pod />}
              purple
              navigation={navigation}
              iconStyle={{ paddingTop: 8 }}
            />
            <NavMenuItem
              label={languageContent.manageSensor}
              navigateTo="SettingsCGMTransmitter"
              icon={<ManageSensor />}
              purple
              navigation={navigation}
              iconStyle={{ paddingLeft: 6, paddingTop: 8 }}
            />
            <NavMenuItem
              label={languageContent.enterBG}
              navigateTo="EnterBGScreen"
              icon={<EnterBG />}
              purple
              navigation={navigation}
              iconStyle={{ paddingTop: 8 }}
            />
            {userState.automatedMode || userState.hypoProtectOn ? (
              <NavMenuItem
                label={languageContent.pauseInsulin}
                icon={<PauseInsulinDisabled />}
                purple
                isDisabled
                navigation={navigation}
                iconStyle={{ paddingTop: 8 }}
              />
            ) : basalState.insulinPaused ? (
              <NavMenuItem
                label={languageContent.startInsulin}
                navigateTo="StartInsulin"
                icon={<StartInsulin />}
                purple
                navigation={navigation}
                iconStyle={{ paddingTop: 8 }}
              />
            ) : (
              <NavMenuItem
                label={languageContent.pauseInsulin}
                navigateTo="PauseInsulin"
                icon={<PauseInsulin />}
                purple
                navigation={navigation}
                iconStyle={{ paddingTop: 8 }}
              />
            )}

            {/* --------------------------SECTION BREAK-------------------------- */}

            <View style={styles.break} />

            <Text style={[styles.navSectionHeaderText]}>
              {languageContent.manage}
            </Text>
            {userState.hypoProtectOn || userState.automatedMode ? (
              <>
                <NavMenuItem
                  label={languageContent.basalPrograms}
                  icon={<BasalProg />}
                  isDisabled
                  navigation={navigation}
                  iconStyle={{ paddingTop: 8 }}
                />
                <NavMenuItem
                  label={languageContent.tempBasal}
                  icon={<TempBasalPre />}
                  isDisabled
                  navigation={navigation}
                  iconStyle={{ paddingTop: 8 }}
                />
              </>
            ) : (
              <>
                <NavMenuItem
                  label={languageContent.basalPrograms}
                  navigateTo="BasalPrograms"
                  icon={<BasalProg />}
                  navigation={navigation}
                  iconStyle={{ paddingTop: 8 }}
                />
                <NavMenuItem
                  label={languageContent.tempBasal}
                  showSimWarning={showSimNavWarning}
                  icon={<TempBasalPre />}
                  navigation={navigation}
                  iconStyle={{ paddingTop: 8 }}
                />
              </>
            )}

            {/* --------------------------SECTION BREAK-------------------------- */}

            <View style={styles.break} />
            <Text style={[styles.navSectionHeaderText]}>HISTORY</Text>
            <NavMenuItem
              label={languageContent.historyDetail}
              navigateTo="HistoryDetail"
              icon={<History />}
              navigation={navigation}
              iconStyle={{ paddingTop: 8 }}
            />
            <NavMenuItem
              label={languageContent.notifications}
              navigateTo="Notifications"
              icon={<Notifications />}
              navigation={navigation}
              iconStyle={{ paddingTop: 8 }}
            />

            {/* --------------------------SECTION BREAK-------------------------- */}

            <View style={styles.break} />
            <View>
              <TouchableOpacity
                style={styles.itemContainer}
                onPress={() => {
                  toggleOpen();
                }}
              >
                <Settings style={styles.image} />
                <Text style={[styles.navTextBlack]}>
                  {languageContent.settings}
                </Text>
                {open ? (
                  <Expand style={styles.image} />
                ) : (
                  <Collapse style={styles.image} />
                )}
              </TouchableOpacity>
            </View>
            {open ? (
              <View>
                <NavMenuItem
                  label={languageContent.general}
                  navigateTo="GeneralSettings"
                  navigation={navigation}
                  iconStyle={{ paddingTop: 8 }}
                />
                <NavMenuItem
                  label={languageContent.reminders}
                  navigateTo="SettingsReminders"
                  navigation={navigation}
                  iconStyle={{ paddingTop: 8 }}
                />
                <NavMenuItem
                  label={languageContent.glucoseGoal}
                  navigateTo="SettingsCGMBGGoalRange"
                  navigation={navigation}
                  iconStyle={{ paddingTop: 8 }}
                />
                <NavMenuItem
                  label={languageContent.basalAndTemp}
                  navigateTo="SettingsBasalTemp"
                  navigation={navigation}
                  iconStyle={{ paddingTop: 8 }}
                />
                <NavMenuItem
                  label={languageContent.bolus}
                  navigateTo="SettingsBolus"
                  navigation={navigation}
                  iconStyle={{ paddingTop: 8 }}
                />
              </View>
            ) : null}
            <NavMenuItem
              label={languageContent.about}
              navigateTo="AboutScreen"
              icon={<About />}
              navigation={navigation}
              iconStyle={{ paddingTop: 8 }}
            />
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  navText: {
    ...theme.fonts.text600,
    fontFamily: theme.fonts.primary.bold,
    color: theme.colors.purple,
    paddingLeft: 16,
    fontWeight: '700',
  },
  navTextBlack: {
    flex: 1,
    ...theme.fonts.text600,
    fontFamily: theme.fonts.primary.medium,
    color: theme.colors.grayScale.gray100,
    paddingLeft: 16,
    fontWeight: '700',
  },
  navTextDisabled: {
    ...theme.fonts.text600,
    fontFamily: theme.fonts.primary.medium,
    paddingLeft: 16,
    fontWeight: '700',
    color: theme.colors.purple,
    opacity: 0.3,
  },
  navSectionHeaderText: {
    ...theme.fonts.text600,
    fontFamily: theme.fonts.primary.light,
    color: theme.colors.grayScale.gray400,
    paddingLeft: 24,
    paddingVertical: 12,
    fontSize: 12,
    fontWeight: '700',
  },
  break: {
    borderColor: '#eee',
    borderWidth: 1,
    marginVertical: 8,
  },
  container: {
    flex: 1,
  },
  logoContainer: {
    display: 'flex',
    maxHeight: 100,
    maxWidth: '50%',
    paddingLeft: '50%',
    alignItems: 'center',
  },
  image: {
    resizeMode: 'contain',
    width: '12%',
    height: '100%',
  },
  settingsIcon: {
    resizeMode: 'contain',
    width: '10%',
    height: '100%',
    right: 0,
    marginRight: 16,
  },
  omnipodLogo: {
    resizeMode: 'contain',
    alignSelf: 'center',
    width: '100%',
    marginLeft: 16,
  },
  itemContainer: {
    flexDirection: 'row',
    width: '100%',
    height: 40,
    alignItems: 'center',
    paddingLeft: 24,
  },
});

export default DrawerStack;
