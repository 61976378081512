import React from 'react';
import { IconProps } from './types';
import classNames from 'classnames';

export const HistoryIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6806 2.4353C18.1133 2.4353 22.5167 6.83877 22.5167 12.2703C22.5167 17.7028 18.1135 22.1065 12.6806 22.1065C11.935 22.1065 11.3306 21.5021 11.3306 20.7565C11.3306 20.0109 11.935 19.4065 12.6806 19.4065C16.6223 19.4065 19.8167 16.2117 19.8167 12.2703C19.8167 8.32994 16.6221 5.1353 12.6806 5.1353C9.76405 5.1353 7.2562 6.88542 6.14976 9.39256C6.7087 9.07343 7.41835 9.19572 7.8384 9.68198L7.92333 9.79101C8.32449 10.3634 8.21941 11.1391 7.70161 11.5864L7.59258 11.6713L4.95576 13.5193C4.39073 13.9153 3.62596 13.8186 3.17552 13.3148L3.08978 13.2086L1.19396 10.606C0.754972 10.0033 0.887647 9.15891 1.4903 8.71992C2.05528 8.30837 2.83275 8.39926 3.28944 8.90879L3.37635 9.01626L3.39011 9.03514C4.72802 5.19288 8.38228 2.4353 12.6806 2.4353ZM12.681 6.36477C13.3828 6.36477 13.9594 6.90017 14.0249 7.58475L14.031 7.71477L14.031 11.507L16.2108 12.8176C16.8098 13.1778 17.0287 13.9294 16.7372 14.5484L16.672 14.6702C16.3118 15.2693 15.5602 15.4882 14.9412 15.1966L14.8193 15.1314L11.9853 13.4271C11.6199 13.2074 11.3823 12.8298 11.3384 12.4111L11.331 12.2702V7.71477C11.331 6.96918 11.9355 6.36477 12.681 6.36477Z"
      />
    </svg>
  );
};
