let strings = {
  "en-US": {
    summary: "Summary",
    autoEvents: "Auto Events"
  },
  "en-GB": {
    summary: "Summary",
    autoEvents: "Auto Events"
  },
  "de-DE": {
    summary: "Zusammen\nfassung",
    autoEvents: "AutoMatisierte \nEreignisse"
  },
  "es-US": {
    summary: "Resumen",
    autoEvents: "Eventos \nAutomáticos"
  },
  "nl-NL": {
    summary: "Samenvatting",
    autoEvents: "Automatische gebeurtenissen"
  },
  "fr-FR": {
    summary: "RÉSUMÉ",
    autoEvents: "ÉVÉNEMENTS AUTOMATISÉS"
  },
};

export { strings };
