import { useNavigation } from '@react-navigation/native';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import { isEqual } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { CGMTimerHOC } from '../components/atoms';
import { DeliverBolusScreen } from '../components/screens';
import { NavState } from '../../../web/store/reducers/navigation';
import { UserState } from '../../../web/store/reducers/user';
import { RootState } from '../../../web/store/store';
import {
  AboutScreenStack,
  BasalTempSettingsStack,
  CGMBGGoalRangeSettingsStack,
  CGMTransmitterSettingsStack,
  EnterBGStack,
  GeneralSettingsStack,
  HistoryDetailStack,
  NotificationsStack,
  PauseInsulinStack,
  PodDetailsStack,
  RemindersSettingsStack,
  StartInsulinStack,
} from './NestedSettingsStack';
import {
  BasalFTSStack,
  BolusFTSStack,
  BolusSettingsStack,
  BolusStack,
  DrawerStack,
  GraphStack,
  HypoProtectStack,
  PodSetupStack,
  ReplacePodStack,
  SetupStack,
  SwitchModeStack,
  TXSetupStack,
} from './simulator';
import BasalProgramsStack from './simulator/BasalProgramsStack';
import ExpertBasalProgramsStack from './simulator/ExpertBasalProgramsStack';
import SetTempBasalStack from './simulator/SetTempBasalStack';
import ViewerManagementStack from './simulator/ViewerManagementStack';
import { selectShowIOSFeatures } from 'web/store/reducers/featureFlags/featureFlags.helpers';
import FtsStartScreenStack from 'web/containers/ios/FtsStartScreenStack/FtsStartScreenStack';
import { TxIDStack } from 'web/containers/ios/TxID/TxIDStack';
import { PodFtsStack } from 'web/containers/ios/Pod/PodFtsStack';
import IOSSettingsScreenStack from 'web/containers/ios/SettingsScreenStack/SettingsScreenStack';
import { MenuStack } from 'web/containers/ios/MenuStack/MenuStack';
import { Dashboard } from 'web/containers/ios/Dashboard/Dashboard';
import { SwitchModeStack as IOSSwitchModeStack } from 'web/containers/ios/SwitchMode/SwitchModeStack';
import { GraphStack as IOSGraphStack } from 'web/containers/ios/GraphStack/GraphStack';
import { ActivityStack } from 'web/containers/ios/ActivityStack/ActivityStack';
import { AppRoutesEnum } from 'web/routes/types';
import { IOSBasalFTSStack } from 'web/containers/ios/Basal/IOSBasalFTSStack';
import { IOSBolusFTSStack } from 'web/containers/ios/Bolus/IOSBolusFTSStack';
import { PauseStartStack } from 'web/containers/ios/PauseStart/PauseStartStack';
import { BolusSettingsStack as IOSBolusSettings } from 'web/containers/ios/BolusSettingsStack/BolusSettingsStack';
import { IOSGlucoseGoalRangeStack } from 'web/containers/ios/GlucoseGoalRange/IOSGlucoseGoalRangeStack';
import { SettingsRemindersStack } from 'web/containers/ios/SettingsRemindersStack/SettingsRemindersStack';
import { IOSHistoryStack } from 'web/containers/ios/History/IOSHistoryStack';
import { IOSTempBasalStack } from 'web/containers/ios/TempBasal/IOSTempBasalStack';
import IOSBasalTempSettingsStack from 'web/containers/ios/BasalTempSettings/BasalTempSettingsStack';
import { BolusAdministrationStack } from 'web/containers/ios/BolusAdministrationStack/BolusAdministrationStack';
import { IOSAlertsStack } from 'web/containers/ios/Alerts/IOSAlertsStack';
import { CustomFoodsStack } from 'web/containers/ios/CustomFoods/CustomFoodsStack';
import { BasalProgramsStack as IOSBasalProgramStack } from 'web/containers/ios/BasalProgramsStack/BasalProgramsStack';

const Stack = createStackNavigator();

const AppStack: React.FC<any> = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );
  const showIOSFeatures = useSelector(selectShowIOSFeatures);
  const { navigate } = useNavigation();

  React.useEffect(() => {
    let navTo = navState.navigateTo;
    if (navTo === AppRoutesEnum.dashboard && !showIOSFeatures) {
      navTo = AppRoutesEnum.drawer;
    }
    navigate(navTo);
  }, [navState.navigateTo]);

  const renderBasalProgramsStack = () => {
    if (showIOSFeatures) {
      return IOSBasalProgramStack;
    }

    if (userState.isExpert) {
      return ExpertBasalProgramsStack;
    }

    return BasalProgramsStack;
  };

  return (
    <CGMTimerHOC>
      <Stack.Navigator
        headerMode="none"
        mode="modal"
        initialRouteName={
          showIOSFeatures ? AppRoutesEnum.dashboard : AppRoutesEnum.drawer
        }
      >
        {!showIOSFeatures && (
          <Stack.Screen
            name={AppRoutesEnum.drawer}
            options={{
              headerShown: false,
              animationEnabled: false,
              cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            }}
            component={DrawerStack}
          />
        )}

        {showIOSFeatures && (
          <>
            <Stack.Screen
              name={AppRoutesEnum.dashboard}
              options={{
                headerShown: false,
                animationEnabled: false,
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
              }}
              component={Dashboard}
            />
            <Stack.Screen
              name={AppRoutesEnum.Alerts}
              options={{
                headerShown: false,
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
              }}
              component={IOSAlertsStack}
            />
            <Stack.Screen
              name={AppRoutesEnum.History}
              options={{
                headerShown: false,
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
              }}
              component={IOSHistoryStack}
            />
            <Stack.Screen
              name={AppRoutesEnum.menu}
              options={{
                headerShown: false,
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
              }}
              component={MenuStack}
            />
            <Stack.Screen
              name={AppRoutesEnum.CustomFoods}
              options={{
                headerShown: false,
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
              }}
              component={CustomFoodsStack}
            />
          </>
        )}

        <Stack.Screen
          name={AppRoutesEnum.basalFTS}
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={showIOSFeatures ? IOSBasalFTSStack : BasalFTSStack}
        />
        <Stack.Screen
          name={AppRoutesEnum.bolusFTS}
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={showIOSFeatures ? IOSBolusFTSStack : BolusFTSStack}
        />
        <Stack.Screen
          name="FTS"
          options={{
            animationEnabled: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: 'horizontal',
          }}
          component={showIOSFeatures ? FtsStartScreenStack : SetupStack}
        />
        <Stack.Screen
          name="ReplacePod"
          component={ReplacePodStack}
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: 'horizontal',
          }}
        />
        <Stack.Screen
          name="SetTempBasal"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={showIOSFeatures ? IOSTempBasalStack : SetTempBasalStack}
        />

        <Stack.Screen
          name={AppRoutesEnum.podSetup}
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={showIOSFeatures ? PodFtsStack : PodSetupStack}
        />
        <Stack.Screen
          name={AppRoutesEnum.txSetup}
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={showIOSFeatures ? TxIDStack : TXSetupStack}
        />
        <Stack.Screen
          name={AppRoutesEnum.switchMode}
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={showIOSFeatures ? IOSSwitchModeStack : SwitchModeStack}
        />
        <Stack.Screen
          name="EnterBGScreen"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={EnterBGStack}
        />
        <Stack.Screen
          name="PodDetailsScreen"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={PodDetailsStack}
        />
        <Stack.Screen
          name="HypoProtect"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={showIOSFeatures ? ActivityStack : HypoProtectStack}
        />
        <Stack.Screen
          name="HistoryDetail"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={HistoryDetailStack}
        />
        <Stack.Screen
          name="Notifications"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={NotificationsStack}
        />
        <Stack.Screen
          name={AppRoutesEnum.Bolus}
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
          }}
          component={showIOSFeatures ? BolusAdministrationStack : BolusStack}
        />
        <Stack.Screen
          name="DeliverBolus"
          options={{
            headerShown: false,
            animationEnabled: false,
          }}
          component={DeliverBolusScreen}
        />
        <Stack.Screen
          name="Graph"
          options={{ headerShown: false }}
          component={showIOSFeatures ? IOSGraphStack : GraphStack}
        />
        <Stack.Screen
          name="PauseInsulin"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={showIOSFeatures ? PauseStartStack : PauseInsulinStack}
        />
        <Stack.Screen
          name="StartInsulin"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={showIOSFeatures ? PauseStartStack : StartInsulinStack}
        />
        <Stack.Screen
          name="GeneralSettings"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={
            showIOSFeatures ? IOSSettingsScreenStack : GeneralSettingsStack
          }
        />
        <Stack.Screen
          name="SettingsCGMTransmitter"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={CGMTransmitterSettingsStack}
        />
        <Stack.Screen
          name={AppRoutesEnum.settingsReminders}
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={
            showIOSFeatures ? SettingsRemindersStack : RemindersSettingsStack
          }
        />
        <Stack.Screen
          name="SettingsCGMBGGoalRange"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={
            showIOSFeatures
              ? IOSGlucoseGoalRangeStack
              : CGMBGGoalRangeSettingsStack
          }
        />
        <Stack.Screen
          name="SettingsBasalTemp"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={
            showIOSFeatures ? IOSBasalTempSettingsStack : BasalTempSettingsStack
          }
        />
        <Stack.Screen
          name="AboutScreen"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={AboutScreenStack}
        />
        <Stack.Screen
          name={AppRoutesEnum.SettingsBolus}
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={showIOSFeatures ? IOSBolusSettings : BolusSettingsStack}
        />
        <Stack.Screen
          name={AppRoutesEnum.BasalPrograms}
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={renderBasalProgramsStack()}
        />
        <Stack.Screen
          name="ViewerManagement"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={ViewerManagementStack}
        />
      </Stack.Navigator>
    </CGMTimerHOC>
  );
};

export default AppStack;
