import { isEqual } from "lodash";
import React, { useCallback } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import BasalProgramStartedImage from "../../../../../assets/history/basal_program_started.png";
import BolusUImage from "../../../../../assets/history/bolus_u.png";
import carbsGImage from "../../../../../assets/history/carbs_g.png";
import CgmMgdlImage from "../../../../../assets/history/cgm_mgdl.png";
import InsulinDeliverPausedImage from "../../../../../assets/history/insulin_delivery_paused.png";
import InsulinDeliverStartedImage from "../../../../../assets/history/insulin_delivery_started.png";
import PodActivatedImage from "../../../../../assets/history/pod_activated.png";
import AutomatedMode from "../../../../../assets/history/switched_to_automated_mode.png";
import ManualMode from "../../../../../assets/history/switched_to_manual_mode.png";
import { ReactComponent as Collapse } from "../../../../../assets/menu/menu_arrow_down.svg";
import { ReactComponent as CollapseWhite } from "../../../../../assets/menu/menu_arrow_down_white.svg";
import { ReactComponent as Expand } from "../../../../../assets/menu/menu_arrow_up.svg";
import { ReactComponent as ExpandWhite } from "../../../../../assets/menu/menu_arrow_up_white.svg";
import getCurrentHourFormat from "../../../helpers/getCurrentHourFormat";
import useLanguageContent from "../../../hooks/useLanguageContent";
import { LangState } from "../../../../../web/store/reducers/language";
import { RootState } from "../../../../../web/store/store";
import theme from "../../../theme";
import { Button } from "../../atoms";
import { strings } from "./ExpandableItemContent";

const LIGHT_BLUE = "#EDF2F6";
const WHITE = theme.colors.white;
const MANUAL_MODE_ICON = "manual mode icon";
const AUTOMATED_MODE_ICON = "auto mode icon";
const BASAL_PROGRAM_ICON = "basal program icon";
const INSULIN_PAUSED_ICON = "insulin paused icon";
const INSULIN_STARTED_ICON = "insulin started icon";
const POD_ICON = "pod icon";

interface Item {
  isExpanded: boolean;
  time: string;
  startIcon?: string;
  summaryText?: string;
  subText?: string;
  isCGM?: boolean;
  cgmValue?: number;
  isBolus?: boolean;
  bolusValue?: number;
  isCarbs?: boolean;
  carbsValue?: number;
  subItems?: JSX.Element;
  index: number;
  bgColor: string;
  hasBolusCalcButton?: boolean;
}

interface Props {
  onPress: () => void;
  item: Item;
  expanded: boolean;
}

const ExpandableItem: React.FC<Props> = ({ item, onPress, expanded }) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const [itemHeight, setItemHeight] = React.useState<any>(0);
  const [arrow, setArrow] = React.useState<Element>();

  const { languageContent } = useLanguageContent(strings);

  const getExpandArrow = useCallback(() => {
    if (item.bgColor === WHITE || item.bgColor === LIGHT_BLUE) {
      return <Expand style={styles.arrow} />;
    }
    return <ExpandWhite style={styles.arrow} />;
  }, [item.bgColor]);

  const getCollapseArrow = useCallback(() => {
    if (item.bgColor === WHITE || item.bgColor === LIGHT_BLUE) {
      return <Collapse style={styles.arrow} />;
    }
    return <CollapseWhite style={styles.arrow} />;
  }, [item.bgColor]);

  const getStartIcon = (icon: string) => {
    switch (icon) {
      case MANUAL_MODE_ICON:
        return (
          <img
            style={{ height: 30, marginRight: 10 }}
            src={ManualMode}
            alt="manual mode"
          />
        );
      case AUTOMATED_MODE_ICON:
        return (
          <img
            style={{ height: 30, marginRight: 10 }}
            src={AutomatedMode}
            alt="automated mode"
          />
        );
      case BASAL_PROGRAM_ICON:
        return (
          <img
            style={{ height: 24, marginLeft: 4, marginRight: 10 }}
            src={BasalProgramStartedImage}
            alt="basal program"
          />
        );
      case INSULIN_PAUSED_ICON:
        return (
          <img
            style={{ height: 24, marginLeft: 4, marginRight: 10 }}
            src={InsulinDeliverPausedImage}
            alt="insulin paused"
          />
        );
      case INSULIN_STARTED_ICON:
        return (
          <img
            style={{ height: 24, marginLeft: 4, marginRight: 10 }}
            src={InsulinDeliverStartedImage}
            alt="insulin started"
          />
        );
      case POD_ICON:
        return (
          <img
            style={{ height: 24, marginLeft: 4, marginRight: 10 }}
            src={PodActivatedImage}
            alt="pod activated"
          />
        );
    }
  };

  const getItemText = (item: Item) => {
    if (item.subText !== undefined) {
      return (
        <View style={{ flexDirection: "column", width: "85%" }}>
          <Text
            style={{
              fontSize: 12,
              color: WHITE
            }}>
            {item.summaryText}
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: WHITE
            }}>
            {item.subText}
          </Text>
        </View>
      );
    }

    return (
      <Text
        style={{
          fontSize: 12,
          color:
            item.bgColor === WHITE || item.bgColor === LIGHT_BLUE
              ? theme.colors.text.dark
              : WHITE
        }}>
        {item.summaryText}
      </Text>
    );
  };

  const getInnerContent = (item: Item) => {
    if (item.startIcon) {
      return (
        <View
          style={[
            styles.innerContainer,
            {
              justifyContent: "flex-start"
            }
          ]}>
          {getStartIcon(item.startIcon)}
          {getItemText(item)}
        </View>
      );
    } else {
      return (
        <View style={[styles.innerContainer]}>
          <View
            style={{
              flexDirection: "row",
              width: "33%",
              height: "100%"
            }}>
            <img
              style={{
                height: 20,
                marginLeft: 6,
                marginRight: 10,
                display: item.cgmValue ? "flex" : "none"
              }}
              src={CgmMgdlImage}
              alt="cgm"
            />
            <Text style={[styles.itemText, { marginLeft: 4 }]}>
              {item.cgmValue ? item.cgmValue : ""}
            </Text>
          </View>
          <View style={{ flexDirection: "row", width: "33%" }}>
            <img
              style={{
                height: 20,
                marginLeft: 20,
                marginRight: 26,
                display: item.bolusValue ? "flex" : "none"
              }}
              src={BolusUImage}
              alt="bolus"
            />
            <Text style={[styles.itemText]}>
              {item.bolusValue
                ? item.bolusValue.toLocaleString(langState.locale)
                : "       "}
            </Text>
          </View>
          <View style={{ flexDirection: "row", width: "33%" }}>
            <img
              style={{
                height: 20,
                marginLeft: 32,
                display: item.carbsValue ? "flex" : "none"
              }}
              src={carbsGImage}
              alt="carbs"
            />
            <Text style={[styles.itemText, { marginLeft: 8 }]}>
              {item.carbsValue ? item.carbsValue : "    "}
            </Text>
          </View>
        </View>
      );
    }
  };

  React.useEffect(() => {
    if (expanded) {
      setArrow(getExpandArrow() as any);
      setItemHeight(null);
    } else {
      setArrow(getCollapseArrow() as any);
      setItemHeight(0);
    }
  }, [expanded, getCollapseArrow, getExpandArrow]);
  return (
    <>
      <View>
        <TouchableOpacity
          activeOpacity={1}
          onPress={onPress}
          style={[
            {
              backgroundColor: item.bgColor,
              borderRadius: 1,
              height: 60,
              justifyContent: "center"
            }
          ]}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <>
              <View
                style={[
                  styles.header,
                  {
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flex: 1
                  }
                ]}>
                <Text
                  style={[
                    {
                      flex: 1.2,
                      fontSize: 14,
                      color:
                        item.bgColor === WHITE || item.bgColor === LIGHT_BLUE
                          ? theme.colors.text.dark
                          : theme.colors.white
                    }
                  ]}>
                  {getCurrentHourFormat({
                    timeStr: item.time,
                    is24Hour: langState.is24Hour
                  })}
                </Text>
                {getInnerContent(item)}
              </View>

              {!item.subItems ? <View style={[styles.arrow]} /> : arrow}
            </>
          </View>
        </TouchableOpacity>
        <View
          style={{
            height: itemHeight,
            overflow: "hidden"
          }}>
          {item.subItems ? <>{item.subItems}</> : null}
          {item.hasBolusCalcButton ? (
            <View style={{ width: "100%", padding: 24 }}>
              <Button
                variant="default"
                style={{ elevation: 6 }}
                onPress={() => {}}>
                {languageContent.viewBolusCalc}
              </Button>
            </View>
          ) : null}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    padding: 20
  },
  arrow: {
    width: 36,
    height: 36,
    marginTop: 8,
    alignSelf: "center"
  },
  innerContainer: {
    flex: 3,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  },
  itemText: {
    alignSelf: "center",
    marginLeft: -20,
    fontSize: 14,
    fontWeight: "400"
  }
});

export default ExpandableItem;
