export enum IOSBasalRoutesEnums {
  IOSBasalFTS = 'IOSBasalFTS',
  IntroBasal = 'IntroBasal',
  SetMaximumBasalRate = 'SetMaximumBasalRate',
  CreateBasalProgramIntro = 'CreateBasalProgramIntro',
  CreateBasalProgram = 'CreateBasalProgram',
  SegmentOne = 'SegmentOne',
  SegmentTwo = 'SegmentTwo',
  BasalSegments = 'BasalSegments',
  TemporaryBasalSwitch = 'TemporaryBasalSwitch',
  CancelSetupModal = 'CancelSetupModal',
}
