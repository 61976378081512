import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as MobileSignalIcon } from './icons/mobile-signal.svg';
import { ReactComponent as WifiIcon } from './icons/wifi.svg';
import { ReactComponent as BatteryIcon } from './icons/status-bar-battery.svg';
import { selectIOSStatusBarMode } from 'web/store/reducers/user/user.helpers';

export enum TopBarStatusModes {
  BgWhite = 'bg-white',
  BgIos = 'bg-ios-topBar',
  Hide = 'hide',
  Default = '',
}

export const IOSStatusBar = () => {
  const iosStatusBarMode = useSelector(selectIOSStatusBarMode);

  if (iosStatusBarMode === TopBarStatusModes.Hide) {
    return null;
  }

  return (
    <div
      className={`px-[25px] pt-[15px] pb-[12px] flex flex-row items-center justify-between ${iosStatusBarMode}`}
    >
      <div className=" font-semibold text-regular">9:41</div>
      <div className="flex flex-row items-center">
        <MobileSignalIcon />
        <div className="mx-1">
          <WifiIcon />
        </div>
        <BatteryIcon />
      </div>
    </div>
  );
};
