import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentOperationMode } from 'web/store/reducers/user/user.helpers';
import classNames from 'classnames';
import { AppRoutesEnum } from 'web/routes/types';
import { NavState } from 'web/store/reducers/navigation';
import { RootState } from 'web/store/store';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';
import { AlertsIcon, HistoryIcon, HomeIcon, MenuIcon } from '../icons';
import { ReactComponent as NotificationSvg } from 'assets/ios/icons/notification.svg';
import { messages } from './BottomNav.messages';
import { isEqual } from 'lodash';

export const BottomNav: React.FC = () => {
  const dispatch = useDispatch();
  const currentOperationMode = useSelector(selectCurrentOperationMode);
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const handleNavigateTo = (route: string) => () => {
    let label = route;
    if (route === AppRoutesEnum.menu) {
      label = 'Settings';
    }
    dispatch(navigateToRoute(route, {}, label));
  };

  return (
    <div className="flex flex-row justify-between text-menuIcons border-t-1 border-ios-menuBorder pt-[12px] pb-3 bg-white">
      <button
        className={classNames(
          {
            [`text-modes-${currentOperationMode}`]:
              navState.currentRoute === AppRoutesEnum.dashboard,
          },
          'flex flex-col justify-center items-center flex-1 cursor-pointer'
        )}
        onClick={handleNavigateTo(AppRoutesEnum.dashboard)}
      >
        <HomeIcon
          color={classNames({
            [`fill-modes-${currentOperationMode}`]:
              navState.currentRoute === AppRoutesEnum.dashboard,
          })}
        />
        <span>
          <FormattedMessage {...messages.home} />
        </span>
      </button>
      <button
        className={classNames(
          {
            [`text-modes-${currentOperationMode}`]:
              navState.currentRoute === AppRoutesEnum.Alerts,
          },
          'flex flex-col justify-center items-center flex-1 cursor-pointer'
        )}
        onClick={handleNavigateTo(AppRoutesEnum.Alerts)}
      >
        <AlertsIcon
          color={classNames({
            [`fill-modes-${currentOperationMode}`]:
              navState.currentRoute === AppRoutesEnum.Alerts,
          })}
        />
        <span>
          <FormattedMessage {...messages.alerts} />
        </span>
        <div className="absolute mb-[35px] ml-4">
          <NotificationSvg />
        </div>
      </button>
      <button
        className={classNames(
          {
            [`text-modes-${currentOperationMode}`]:
              navState.currentRoute === AppRoutesEnum.History,
          },
          'flex flex-col justify-center items-center flex-1 cursor-pointer'
        )}
        onClick={handleNavigateTo(AppRoutesEnum.History)}
      >
        <HistoryIcon
          color={classNames({
            [`fill-modes-${currentOperationMode}`]:
              navState.currentRoute === AppRoutesEnum.History,
          })}
        />
        <span>
          <FormattedMessage {...messages.history} />
        </span>
      </button>
      <button
        className={classNames(
          {
            [`text-modes-${currentOperationMode}`]:
              navState.currentRoute === AppRoutesEnum.menu,
          },
          'flex flex-col justify-center items-center flex-1 cursor-pointer'
        )}
        onClick={handleNavigateTo(AppRoutesEnum.menu)}
      >
        <MenuIcon
          color={classNames({
            [`fill-modes-${currentOperationMode}`]:
              navState.currentRoute === AppRoutesEnum.menu,
          })}
        />
        <span>
          <FormattedMessage {...messages.menu} />
        </span>
      </button>
    </div>
  );
};
