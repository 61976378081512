import { createStackNavigator } from '@react-navigation/stack';
import { CreateBasalProgramIntro } from './views/CreateBasalProgramIntro';
import { CreateBasalProgram } from './views/CreateBasalProgram';
import { CreateBasalSegment } from './views/CreateBasalSegment';
import { SetMaximumBasalRate } from './views/SetMaximumBasalRate';
import { IOSBasalRoutesEnums } from './types';
import { TopBar } from 'web/components/ios/TopBar';
import React, { useState } from 'react';
import { Animated } from 'react-native';
import segmentOneNoEndTimeSelected from 'web/assets/ios/segment-one-no-end-time.svg';
import segmentOneEndTimeSelected from 'web/assets/ios/segment-one-end-time-selected.svg';
import segmentOneUnitSelected from 'web/assets/ios/segment-one-unit-selected.svg';
import segmentTwoNoEndTimeSelected from 'web/assets/ios/segment-two-no-end-time.svg';
import segmentTwoEndTimeSelected from 'web/assets/ios/segment-two-end-time-selected.svg';
import segmentTwoUnitSelected from 'web/assets/ios/segment-two-unit-selected.svg';
import BasalSegments from './views/BasalSegments';
import { TemporaryBasalSwitch } from './views/TemporaryBasalSwitch';
import { IntroBasal } from './views/IntroBasal';
import { Modal } from 'web/components/ios/Modal';
import { useIntl } from 'react-intl';
import {
  messages,
  BasalSegmentOneMessages,
  BasalSegmentTwoMessages,
  CreateBasalSegmentsMessages,
  CancelSetupModalMessages,
} from './Basal.messages';
import { useNavigation } from '@react-navigation/native';

const Stack = createStackNavigator();

export const IOSBasalFTSStack = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const headerRef = new Animated.ValueXY().y;
  const [showModal, setShowModal] = useState(false);
  const [isEditModeActive, setIsEditModeActive] = useState(false);

  const animateHeader = React.useCallback(() => {
    Animated.timing(headerRef, {
      toValue: 0,
      duration: 600,
      useNativeDriver: false,
    }).start();
  }, [headerRef]);

  const modalRightButttonOnClickHandler = () => {
    setShowModal(false);
    setIsEditModeActive(false);
    navigate(IOSBasalRoutesEnums.IntroBasal);
  };

  React.useEffect(() => {
    animateHeader();
  }, [animateHeader]);

  return (
    <>
      <Stack.Navigator
        initialRouteName={IOSBasalRoutesEnums.IOSBasalFTS}
        screenOptions={{
          header: (props) => <TopBar {...props} backMode />,
        }}
      >
        <Stack.Screen
          name={IOSBasalRoutesEnums.IntroBasal}
          component={IntroBasal}
        />
        <Stack.Screen
          name={IOSBasalRoutesEnums.SetMaximumBasalRate}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
          component={SetMaximumBasalRate}
        />
        <Stack.Screen
          name={IOSBasalRoutesEnums.CreateBasalProgramIntro}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
          component={CreateBasalProgramIntro}
        />
        <Stack.Screen
          name={IOSBasalRoutesEnums.CreateBasalProgram}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
          component={CreateBasalProgram}
        />
        <Stack.Screen
          name={IOSBasalRoutesEnums.SegmentOne}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
        >
          {() => (
            <CreateBasalSegment
              editMode={isEditModeActive}
              basalSegmentTitle={`${intl.formatMessage(CreateBasalSegmentsMessages.segmentTitle)} 1`}
              segmentNoEndTimeSelected={segmentOneNoEndTimeSelected}
              segmentEndTimeSelected={segmentOneEndTimeSelected}
              segmentUnitSelected={segmentOneUnitSelected}
              startTimeValue={intl.formatMessage(
                BasalSegmentOneMessages.startTimeInputValue
              )}
              endTimeValue={intl.formatMessage(
                BasalSegmentOneMessages.endTimeInputValue
              )}
              unitValue={intl.formatMessage(
                BasalSegmentOneMessages.basalRateInputValue
              )}
              navigationRoute={
                isEditModeActive
                  ? IOSBasalRoutesEnums.BasalSegments
                  : IOSBasalRoutesEnums.SegmentTwo
              }
            />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={IOSBasalRoutesEnums.SegmentTwo}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
        >
          {() => (
            <CreateBasalSegment
              editMode={isEditModeActive}
              basalSegmentTitle={`${intl.formatMessage(CreateBasalSegmentsMessages.segmentTitle)} 2`}
              segmentNoEndTimeSelected={segmentTwoNoEndTimeSelected}
              segmentEndTimeSelected={segmentTwoEndTimeSelected}
              segmentUnitSelected={segmentTwoUnitSelected}
              startTimeValue={intl.formatMessage(
                BasalSegmentTwoMessages.startTimeInputValue
              )}
              endTimeValue={intl.formatMessage(
                BasalSegmentTwoMessages.endTimeInputValue
              )}
              unitValue={intl.formatMessage(
                BasalSegmentTwoMessages.basalRateInputValue
              )}
              navigationRoute={IOSBasalRoutesEnums.BasalSegments}
            />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={IOSBasalRoutesEnums.BasalSegments}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
        >
          {() => <BasalSegments editModeHandler={setIsEditModeActive} />}
        </Stack.Screen>
        <Stack.Screen
          name={IOSBasalRoutesEnums.TemporaryBasalSwitch}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
          component={TemporaryBasalSwitch}
        />
      </Stack.Navigator>
      {showModal && (
        <Modal
          title={intl.formatMessage(CancelSetupModalMessages.title)}
          leftButtonText={intl.formatMessage(messages.noBtn)}
          leftButtonOnClick={() => setShowModal(false)}
          rightButtonText={intl.formatMessage(messages.yesBtn)}
          rightButtonOnClick={() => modalRightButttonOnClickHandler()}
        >
          {intl.formatMessage(CancelSetupModalMessages.text)}
        </Modal>
      )}
    </>
  );
};
