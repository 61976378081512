const strings = {
    "en-US": {
      selectSensor: "Select Your Sensor:",
      save: "Save",
      cancel: 'Cancel',
    },
    "es-US": {
      selectSensor: "Seleccione su Sensor:",
      save: "Guardar",
      cancel: 'Cancel',
    },
    "en-GB": {
      selectSensor: "Select Your Sensor:",
      save: "Save",
      cancel: 'Cancel',
    },
    "nl-NL": {
      selectSensor: "Selecteer uw Sensor:",
      save: "Opslaan",
      cancel: 'Annuleren',
    }
  };
  
  export { strings };
  