import { SensorType, sensors } from 'web/data/sensors';
import * as type from './actions';

export interface SensorState {
  activeSensor: SensorType;
  selectedSensorFlow: SensorType;
}

export const initialState: SensorState = {
  activeSensor: sensors.G6,
  selectedSensorFlow: sensors.G6,
};

const reducer = (
  state: SensorState = initialState,
  action: type.Action
): SensorState => {
  switch (action.type) {
    case type.SENSOR_UPDATE:
      return { ...state, ...action.payload.sensor };
    case type.SENSOR_INIT:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;
