import {
  CardStyleInterpolators,
  StackNavigationOptions,
  createStackNavigator,
} from '@react-navigation/stack';
import React from 'react';
import { Animated } from 'react-native';
import { useIntl } from 'react-intl';
import { WelcomeOmnipodScreen } from './views/WelcomeOmnipodScreen';
import { ReactComponent as BackIcon } from '../assets/backIcon.svg';
import { SignInScreen } from './views/SignIn';
import { LinkedScreen } from './views/LinkedScreen';
import { SecurityScreen } from './views/SecurityScreen';
import { SecurityCompleteScreen } from './views/SecurityCompleteScreen';
import { NotificationsScreen } from './views/NotificationsScreen';
import { SoundScreen } from './views/SoundScreen';
import { AccessibilitySettingsScreen } from './views/AccessibilitySettingsScreen';
import { DateTimeScreen } from './views/DateTimeScreen';
import { GreatScreen } from './views/GreatScreen';
import { SplashScreen } from './views/SplashScreen';
import { IOSRoutesEnums } from './types';
import { messages } from './FtsStartScreenStack.messages';
import { TermsConditionsScreen } from './views/TermsConditionsScreen';
import { useNavigation } from '@react-navigation/native';
import { NeedHelpScreen } from './views/NeedHelpScreen';
import { TopBar } from 'web/components/ios/TopBar';

const Stack = createStackNavigator();

const FtsStartScreenStack: React.FC = () => {
  const intl = useIntl();
  const headerRef = new Animated.ValueXY().y;
  const { navigate } = useNavigation();

  const animateHeader = React.useCallback(() => {
    Animated.timing(headerRef, {
      toValue: 0,
      duration: 600,
      useNativeDriver: false,
    }).start();
  }, [headerRef]);

  const commonHeaderStyles: StackNavigationOptions = {
    headerStyle: {
      backgroundColor: 'rgba(249, 249, 249, 0.94)',
      paddingTop: '20px',
      borderBottomWidth: 0.5,
      borderBottomColor: 'rgba(60, 60, 67, 0.36)',
    },
    headerTitleStyle: {
      textAlign: 'center',
      fontSize: 16,
      fontWeight: '600',
      flex: 1,
    },
    headerTitleContainerStyle: {
      left: 0,
    },
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  };

  const backButton = {
    headerBackTitleVisible: true,
    headerBackTitle: intl.formatMessage(messages.backTitle),
    headerBackImage: () => <BackIcon />,
  };

  React.useEffect(() => {
    animateHeader();
  }, [animateHeader]);

  return (
    <>
      <Stack.Navigator
        initialRouteName={IOSRoutesEnums.SplashScreen}
        mode="modal"
        screenOptions={{
          headerStyle: {
            height: 91,
          },
        }}
      >
        <Stack.Screen
          name={IOSRoutesEnums.WelcomeOmnipod5}
          options={{
            headerTitle: '',
            header: (props) => <TopBar {...props} backMode />,
          }}
          component={WelcomeOmnipodScreen}
        />
        <Stack.Screen
          name={IOSRoutesEnums.SignIn}
          options={{
            headerTitle: intl.formatMessage(messages.topBarSignInTitle),
            header: (props) => <TopBar {...props} backMode />,
          }}
          component={SignInScreen}
        />
        <Stack.Screen
          name={IOSRoutesEnums.OmnipodLinked}
          options={{
            headerTitle: intl.formatMessage(messages.topBarAllSetTitle),
            headerLeft: () => {
              return null;
            },
            ...backButton,
            ...commonHeaderStyles,
          }}
          component={LinkedScreen}
        />
        <Stack.Screen
          name={IOSRoutesEnums.OmnipodSecurity}
          options={{
            headerTitle: intl.formatMessage(
              messages.topBarOmnipodSecurityTitle
            ),
            header: (props) => <TopBar {...props} backMode />,
          }}
          component={SecurityScreen}
        />
        <Stack.Screen
          name={IOSRoutesEnums.OmnipodSecurityComplete}
          options={{
            headerTitle: intl.formatMessage(
              messages.topBarOmnipodSecurityTitle
            ),
            header: (props) => <TopBar {...props} backMode />,
          }}
          component={SecurityCompleteScreen}
        />
        <Stack.Screen
          name={IOSRoutesEnums.Notifications}
          options={{
            headerTitle: intl.formatMessage(messages.topBarNotificationsTitle),
            header: (props) => <TopBar {...props} backMode />,
          }}
          component={NotificationsScreen}
        />
        <Stack.Screen
          name={IOSRoutesEnums.Sound}
          options={{
            headerTitle: intl.formatMessage(messages.topBarSoundTitle),
            header: (props) => <TopBar {...props} backMode />,
          }}
          component={SoundScreen}
        />
        <Stack.Screen
          name={IOSRoutesEnums.AccessibilitySettings}
          options={{
            headerTitle: intl.formatMessage(
              messages.topBarAccessibilitySettingsTitle
            ),
            header: (props) => <TopBar {...props} backMode />,
          }}
          component={AccessibilitySettingsScreen}
        />
        <Stack.Screen
          name={IOSRoutesEnums.DateTime}
          options={{
            headerTitle: intl.formatMessage(messages.topBarDateTimeTitle),
            header: (props) => <TopBar {...props} backMode />,
          }}
          component={DateTimeScreen}
        />
        <Stack.Screen
          name={IOSRoutesEnums.Great}
          options={{
            headerTitle: '',
            header: (props) => <TopBar {...props} backMode />,
          }}
          component={GreatScreen}
        />
        <Stack.Screen
          name={IOSRoutesEnums.TermsConditions}
          options={{
            headerTitle: intl.formatMessage(
              messages.topBarTermsConditionsTitle
            ),
            header: (props) => (
              <TopBar
                {...props}
                variant="Close"
                backTitleClassName="text-black"
              />
            ),
          }}
          component={TermsConditionsScreen}
        />
        <Stack.Screen
          name={IOSRoutesEnums.NeedHelp}
          options={{
            headerTitle: intl.formatMessage(messages.topBarHelpTitle),
            header: (props) => (
              <TopBar
                {...props}
                variant="Close"
                backTitleClassName="text-black"
              />
            ),
          }}
          component={NeedHelpScreen}
        />
        <Stack.Screen
          name={IOSRoutesEnums.SplashScreen}
          options={{
            header: () => null,
          }}
          component={SplashScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default FtsStartScreenStack;
