import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../PauseStartStack.messages';
import { Button } from 'web/components/ios/Button';
import { useDispatch } from 'react-redux';
import {
  showModal,
  setCurrentModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import {
  AttentionDeliveryModal,
  CommunicatingWithPodConfirmationModal,
} from 'web/components/ios/modals/PauseStartInsulinModals/PauseStartInsulinModals';
import { AppRoutesEnum } from 'web/routes/types';
import { basalResumeInsulin } from 'web/store/reducers/basal/actions';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';

export const StartInsulin = () => {
  const dispatch = useDispatch();

  const handlePauseInsulinModal = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <AttentionDeliveryModal
            onConfirm={handleCommunicatingWithPodModal}
            onReject={() => dispatch(hideModal())}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  const handleCommunicatingWithPodModal = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <CommunicatingWithPodConfirmationModal
            onClose={() => {
              dispatch(basalResumeInsulin());
              dispatch(navigateToRoute(AppRoutesEnum.dashboard));
              dispatch(hideModal());
            }}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="flex justify-between flex-col flex-1 items-stretch bg-white">
      <div className="flex flex-col px-2">
        <div className="mt-2 ">
          <div className="text-black text-[17px] font-semibold">
            <FormattedMessage {...messages.startInsulinDeliveryTitle} />
          </div>
          <div className="mt-2">
            <FormattedMessage {...messages.restartBasalProgramText} />
          </div>
        </div>
      </div>
      <div className="w-full mb-4 px-2">
        <Button
          className="w-full rounded-lg  bg-modes-manual"
          onClick={handlePauseInsulinModal}
          variant="colored"
        >
          <FormattedMessage {...messages.StartInsulinButtonTitle} />
        </Button>
      </div>
    </div>
  );
};
