let strings = {
  "en-US": {
    releaseNotes: "Release notes not available, try again later."
  },
  "en-GB": {
    releaseNotes: "Release notes not available, try again later."
  },
  "es-US": {
    releaseNotes:
      "Las notas de publicación no están disponibles, vuelva a intentarlo más tarde."
  },
  "de-DE": {
    releaseNotes: "Versionshinweise nicht verfügbar, später erneut versuchen."
  },
  "nl-NL": {
    releaseNotes:
      "Release-opmerkingen niet beschikbaar, probeer het later opnieuw."
  },
  "fr-FR": {
    releaseNotes: "Notes de version non disponibles, réessayez ultérieurement."
  },
};

export { strings };
