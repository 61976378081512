import PickerItem from "../types/pickerItem";
const EXPERT_BASAL_HOURS_DATA: Array<PickerItem> = [];

EXPERT_BASAL_HOURS_DATA.push({
  key: "12:00 AM",
  label: "12:00 AM",
  value: 0,
  index: 0,
  helpText: "NIGHT"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "12:30 AM",
  label: "12:30 AM",
  value: 1,
  index: 1,
  helpText: "NIGHT"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "1:00 AM",
  label: "1:00 AM",
  value: 2,
  index: 2,
  helpText: "NIGHT"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "1:30 AM",
  label: "1:30 AM",
  value: 3,
  index: 3,
  helpText: "NIGHT"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "2:00 AM",
  label: "2:00 AM",
  value: 4,
  index: 4,
  helpText: "NIGHT"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "2:30 AM",
  label: "2:30 AM",
  value: 5,
  index: 5,
  helpText: "NIGHT"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "3:00 AM",
  label: "3:00 AM",
  value: 6,
  index: 6,
  helpText: "NIGHT"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "3:30 AM",
  label: "3:30 AM",
  value: 7,
  index: 7,
  helpText: "NIGHT"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "4:00 AM",
  label: "4:00 AM",
  value: 8,
  index: 8,
  helpText: "NIGHT"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "4:30 AM",
  label: "4:30 AM",
  value: 9,
  index: 9,
  helpText: "NIGHT"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "5:00 AM",
  label: "5:00 AM",
  value: 10,
  index: 10,
  helpText: "NIGHT"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "5:30 AM",
  label: "5:30 AM",
  value: 11,
  index: 11,
  helpText: "NIGHT"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "6:00 AM",
  label: "6:00 AM",
  value: 12,
  index: 12,
  helpText: "MORNING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "6:30 AM",
  label: "6:30 AM",
  value: 13,
  index: 13,
  helpText: "MORNING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "7:00 AM",
  label: "7:00 AM",
  value: 14,
  index: 14,
  helpText: "MORNING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "7:30 AM",
  label: "7:30 AM",
  value: 15,
  index: 15,
  helpText: "MORNING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "8:00 AM",
  label: "8:00 AM",
  value: 16,
  index: 16,
  helpText: "MORNING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "8:30 AM",
  label: "8:30 AM",
  value: 17,
  index: 17,
  helpText: "MORNING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "9:00 AM",
  label: "9:00 AM",
  value: 18,
  index: 18,
  helpText: "MORNING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "9:30 AM",
  label: "9:30 AM",
  value: 19,
  index: 19,
  helpText: "MORNING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "10:00 AM",
  label: "10:00 AM",
  value: 20,
  index: 20,
  helpText: "MORNING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "10:30 AM",
  label: "10:30 AM",
  value: 21,
  index: 21,
  helpText: "MORNING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "11:00 AM",
  label: "11:00 AM",
  value: 22,
  index: 22,
  helpText: "MORNING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "11:30 AM",
  label: "11:30 AM",
  value: 23,
  index: 23,
  helpText: "MORNING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "12:00 PM",
  label: "12:00 PM",
  value: 24,
  index: 24,
  helpText: "AFTERNOON"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "12:30 PM",
  label: "12:30 PM",
  value: 25,
  index: 25,
  helpText: "AFTERNOON"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "1:00 PM",
  label: "1:00 PM",
  value: 26,
  index: 26,
  helpText: "AFTERNOON"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "1:30 PM",
  label: "1:30 PM",
  value: 27,
  index: 27,
  helpText: "AFTERNOON"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "2:00 PM",
  label: "2:00 PM",
  value: 28,
  index: 28,
  helpText: "AFTERNOON"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "2:30 PM",
  label: "2:30 PM",
  value: 29,
  index: 29,
  helpText: "AFTERNOON"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "3:00 PM",
  label: "3:00 PM",
  value: 30,
  index: 30,
  helpText: "AFTERNOON"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "3:30 PM",
  label: "3:30 PM",
  value: 31,
  index: 31,
  helpText: "AFTERNOON"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "4:00 PM",
  label: "4:00 PM",
  value: 32,
  index: 32,
  helpText: "AFTERNOON"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "4:30 PM",
  label: "4:30 PM",
  value: 33,
  index: 33,
  helpText: "AFTERNOON"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "5:00 PM",
  label: "5:00 PM",
  value: 34,
  index: 34,
  helpText: "AFTERNOON"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "5:30 PM",
  label: "5:30 PM",
  value: 35,
  index: 35,
  helpText: "AFTERNOON"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "6:00 PM",
  label: "6:00 PM",
  value: 36,
  index: 36,
  helpText: "EVENING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "6:30 PM",
  label: "6:30 PM",
  value: 37,
  index: 37,
  helpText: "EVENING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "7:00 PM",
  label: "7:00 PM",
  value: 38,
  index: 38,
  helpText: "EVENING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "7:30 PM",
  label: "7:30 PM",
  value: 39,
  index: 39,
  helpText: "EVENING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "8:00 PM",
  label: "8:00 PM",
  value: 40,
  index: 40,
  helpText: "EVENING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "8:30 PM",
  label: "8:30 PM",
  value: 41,
  index: 41,
  helpText: "EVENING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "9:00 PM",
  label: "9:00 PM",
  value: 42,
  index: 42,
  helpText: "EVENING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "9:30 PM",
  label: "9:30 PM",
  value: 43,
  index: 43,
  helpText: "EVENING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "10:00 PM",
  label: "10:00 PM",
  value: 44,
  index: 44,
  helpText: "EVENING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "10:30 PM",
  label: "10:30 PM",
  value: 45,
  index: 45,
  helpText: "EVENING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "11:00 PM",
  label: "11:00 PM",
  value: 46,
  index: 46,
  helpText: "EVENING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "11:30 PM",
  label: "11:30 PM",
  value: 47,
  index: 47,
  helpText: "EVENING"
});
EXPERT_BASAL_HOURS_DATA.push({
  key: "12:00 AM",
  label: "12:00 AM",
  value: 48,
  index: 48,
  helpText: "NIGHT"
});

export default EXPERT_BASAL_HOURS_DATA;
