let strings = {
  "en-US": {
    switch: "Switch to Automated Mode?",
    inAutomatedMode:
      "In Automated Mode, insulin delivery is \nadjusted based on your Sensor values.",
    useAutomatedMode: "You can use automated Mode when \nyou have:",
    anActivePod: "An active Pod",
    transmitterSerialNumber:
      "A Dexcom Transmitter serial \nnumber saved in Omnipod\u00A05.",
    close: "Close",
    switchTwo: "SWITCH",
    communicating: "Communicating with Pod",
    automatedMode: "Switch to Automated Mode?",
    automatedConfirmationModalContent:
      "In Automated mode, insulin delivery is adjusted based on your sensor glucose values.",
    pleaseWait: "Please wait..."
  },
  "en-GB": {
    switch: "Switch to Automated Mode?",
    inAutomatedMode:
      "In Automated Mode, insulin delivery is \nadjusted based on your Sensor values.",
    useAutomatedMode: "You can use Automated Mode when \nyou have:",
    anActivePod: "An active Pod",
    transmitterSerialNumber:
      "A Dexcom Transmitter serial \nnumber saved in Omnipod\u00A05.",
    close: "Close",
    switchTwo: "SWITCH",
    communicating: "Communicating with Pod",
    automatedMode: "Switch to Automated Mode?",
    automatedConfirmationModalContent:
      "In Automated Mode, insulin delivery is adjusted based on your sensor glucose values.",
    pleaseWait: "Please wait..."
  },
  "de-DE": {
    switch: "Auf automatisierten Modus \numschalten?",
    inAutomatedMode:
      "Im Automatisierter Modus wird die \nInsulinabgabe auf der Grundlage Ihrer \nSensor-Glukosewerte angepasst.",
    useAutomatedMode:
      "Sie können den Automatisierter Modus \nverwenden, wenn Sie Folgendes haben:",
    anActivePod: "Einen aktiven Pod",
    transmitterSerialNumber:
      "Eine im Omnipod 5 gespeicherte \nDexcom Transmitter-\nSeriennummer",
    close: "Schließen",
    switchTwo: "UMSCHALTEN",
    communicating: "Kommunikation mit dem Pod",
    automatedMode: "Auf automatisierten Modus umschalten?",
    automatedConfirmationModalContent:
      "Im Automatisierter Modus wird die Insulinabgabe auf der Grundlage Ihrer Sensor-Glukosewerte angepasst.",
    pleaseWait: "Bitte warten..."
  },
  "es-US": {
    switch: "¿Cambiar a modo \nautomatizado?",
    inAutomatedMode:
      "En el Modo Automatizado, la \nadministración de insulina se ajusta en \nfunción de los valores del sensor de \nglucosa.",
    useAutomatedMode: "Puede utilizar el Modo Automatizado \ncuando tenga:",
    anActivePod: "Un Pod activo",
    transmitterSerialNumber:
      "Un número de serie del transmisor \nDexcom guardado en el \nOmnipod 5.",
    close: "Cerrar",
    switchTwo: "CAMBIAR",
    communicating: "Comunicando con el Pod",
    automatedMode: "¿Cambiar al Modo Automatizado?",
    automatedConfirmationModalContent:
      "En el Modo Automatizado, la administración de insulina se ajusta según los valores de glucosa del sensor."
  },
  "fr-FR": {
    switch: "Passer en Mode Automatisé ?",
    inAutomatedMode:
      "En mode Automatisé, l'administration d'insuline est ajustée en fonction des valeurs de votre capteur.",
    useAutomatedMode:
      "Vous pouvez utiliser le mode Automatisé si les conditions suivantes sont réunies :",
    anActivePod: "Un Pod actif",
    transmitterSerialNumber:
      "Un numéro de série de transmetteur Dexcom enregistré dans l'Omnipod 5.",
    close: "Fermer",
    switchTwo: "CHANGER",
    communicating: "Communication avec le Pod",
    automatedMode: "Passer en Mode Automatisé ?",
    automatedConfirmationModalContent:
      "En Mode Automatisé, l'administration d'insuline est ajustée en fonction des valeurs de votre capteur de glucose."
  },
  "nl-NL": {
    switch: "Wijzigen naar de \nautomatische modus?",
    inAutomatedMode:
      "In de Geautomatiseerde Modus modus \nwordt de insulinetoediening bijgesteld \nop basis van uw sensorwaarden.",
    useAutomatedMode:
      "U kunt gebruikmaken van de \nGeautomatiseerde Modus indien u het \nvolgende hebt:",
    anActivePod: "Een actieve Pod",
    transmitterSerialNumber:
      "Een serienummer van een \nDexcom-zender opgeslagen in",
    close: "Sluiten",
    switchTwo: "WIJZIGEN",
    communicating: "Communicatie met Pod",
    automatedMode: "Wijzigen naar de automatische modus?",
    automatedConfirmationModalContent:
      "In de Geautomatiseerde Modus wordt de insulinetoediening bijgesteld op basis van uw sensorglucosewaarden."
  }
};

export { strings };
