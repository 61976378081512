import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { Button } from 'web/components/ios/Button';
import { useIntl } from 'react-intl';
import {
  messages,
  CreateCorrectionFactorSegmentMessages,
} from '../Bolus.messages';

interface CreateCorrectionFactorSegmentProps {
  bolusSegmentTitle: string;
  startTimeValue: string;
  endTimeValue: string;
  unitOfInsulinValue: string;
  navigationRoute: string;
  editMode: boolean;
}

export const CreateCorrectionFactorSegment: React.FC<
  CreateCorrectionFactorSegmentProps
> = ({
  bolusSegmentTitle,
  startTimeValue,
  endTimeValue,
  unitOfInsulinValue,
  navigationRoute,
  editMode,
}) => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const [endTimeIsClicked, setEndTimeIsClicked] = useState(false);
  const [
    unitOfInsulinLowersGlucoseIsClicked,
    setUnitOfInsulinLowersGlucoseIsClicked,
  ] = useState(false);

  useEffect(() => {
    setEndTimeIsClicked(editMode);
    setUnitOfInsulinLowersGlucoseIsClicked(editMode);
  }, []);

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-9/12">
        <p className="p-6 font-bold text-xl mb-2">{bolusSegmentTitle}</p>
        <div>
          <div className="flex justify-between items-center mb-2 w-full pb-2 border-b border-b-ios-gray-7">
            <div>
              <p className="pl-6">
                {intl.formatMessage(messages.startTimeInputLabel)}
              </p>
            </div>
            <div>
              <p className="pr-6 font-semibold text-ios-gray-2">
                <span>{startTimeValue}</span>
              </p>
            </div>
          </div>
          <div
            onClick={() => setEndTimeIsClicked(true)}
            className="flex justify-between items-center mb-2 w-full pb-2 border-b border-b-ios-gray-7 hover:cursor-pointer"
          >
            <div>
              <p className="pl-6">
                {intl.formatMessage(messages.endTimeInputLabel)}
              </p>
            </div>
            <div>
              <p
                className={`pr-6 font-semibold ${endTimeIsClicked ? 'text-modes-automated' : 'text-ios-gray-2'}`}
              >
                <span>
                  {endTimeIsClicked
                    ? endTimeValue
                    : intl.formatMessage(messages.emptyValue)}
                </span>
              </p>
            </div>
          </div>
          <div
            onClick={() =>
              endTimeIsClicked && setUnitOfInsulinLowersGlucoseIsClicked(true)
            }
            className="flex justify-between items-center w-full pb-2 border-b border-b-ios-gray-7 hover:cursor-pointer"
          >
            <div className="pl-6 text-sm">
              <p>
                {intl.formatMessage(
                  CreateCorrectionFactorSegmentMessages.unitOfInsulinLabel
                )}
              </p>
              <p className="font-semibold text-sm text-ios-gray-2">
                {intl.formatMessage(
                  CreateCorrectionFactorSegmentMessages.unitOfInsulinPlaceholder
                )}
              </p>
            </div>
            <div className="pr-6">
              <p
                className={`font-semibold ${unitOfInsulinLowersGlucoseIsClicked ? 'text-modes-automated' : 'text-ios-gray-2'}`}
              >
                <span className="text-sm">
                  {unitOfInsulinLowersGlucoseIsClicked
                    ? unitOfInsulinValue
                    : intl.formatMessage(
                        messages.correctionFactorUnitEmptyValue
                      )}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="basis-2/5">
        <div className="pt-2 w-full h-full flex flex-col-reverse">
          <div className="flex flex-col w-full p-6">
            <Button
              onClick={() =>
                endTimeIsClicked &&
                unitOfInsulinLowersGlucoseIsClicked &&
                navigate(navigationRoute)
              }
              variant="colored"
              disabled={
                endTimeIsClicked && unitOfInsulinLowersGlucoseIsClicked
                  ? false
                  : true
              }
            >
              {editMode
                ? intl.formatMessage(messages.saveBtn)
                : intl.formatMessage(messages.nextBtn)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
