import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { GlucoseGoalRange } from './views/GlucoseGoalRange';
import { AppRoutesEnum } from 'web/routes/types';
import { TopBar } from 'web/components/ios/TopBar';
import { useIntl } from 'react-intl';
import { messages } from './GlucoseGoalRange.messages';

const Stack = createStackNavigator();

export const IOSGlucoseGoalRangeStack: React.FC = () => {
  const intl = useIntl();
  return (
    <Stack.Navigator
      initialRouteName={AppRoutesEnum.glucoseGoalRange}
      screenOptions={{
        header: (props) => <TopBar {...props} backAndCancelMode />,
      }}
      mode="modal"
    >
      <Stack.Screen
        name={AppRoutesEnum.glucoseGoalRange}
        options={{
          headerTitle: intl.formatMessage(messages.headerTitle),
        }}
        component={GlucoseGoalRange}
      />
    </Stack.Navigator>
  );
};
