import { isEqual } from "lodash";
import React, { useCallback } from "react";
import { StyleSheet, Text, useWindowDimensions, View } from "react-native";
import { useSelector } from "react-redux";
import calculateDurationFromTimestamps from "../../../helpers/calculateDurationFromTimetamps";
import {
  BasalProgram,
  ExpertBasalSegment
} from "../../../../../web/store/reducers/basal/reducer";
import { LangState } from "../../../../../web/store/reducers/language";
import { RootState } from "../../../../../web/store/store";
import theme from "../../../theme";
import { GRAPH_MAX_HEIGHT } from "../expertProgramCardModal/ExpertProgramCardModal";
import getCurrentHourFormat from "../../../helpers/getCurrentHourFormat";

interface Props {
  maxBasalRateCalc: number;
  maxHeight: number;
  segment: ExpertBasalSegment;
  index: number;
  program: BasalProgram;
  hideBottomBorder?: boolean;
  basalRateKey?: string;
  displayOnly?: boolean;
  isPlaceholderSegment?: boolean;
}

const BasalSegmentChartItem: React.FC<Props> = ({
  maxBasalRateCalc,
  maxHeight,
  segment,
  index,
  program,
  hideBottomBorder,
  basalRateKey,
  displayOnly,
  isPlaceholderSegment
}) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { width } = useWindowDimensions();
  const [segmentWidth, setSegmentWidth] = React.useState<number>(0);

  const basalRateMaxDifference = maxBasalRateCalc - segment.basalRate;

  const getSegmentHeight = (basalRate: number) => {
    const conversionFactor = basalRate / maxBasalRateCalc;
    return Math.round((maxHeight - 24) * conversionFactor);
  };

  const getSegmentWidth = useCallback(
    (segment: ExpertBasalSegment) => {
      const containerWidth = width - 32;
      const hourIncrement = containerWidth / 24;

      const totalHours = calculateDurationFromTimestamps(
        segment.start,
        segment.end
      );

      const calculatedWidth = Math.abs(totalHours * hourIncrement);
      setSegmentWidth(calculatedWidth);
    },
    [width]
  );

  const getStartTime = () => {
    if (segment.start === "" || !segment.start) {
      return "12:00 am";
    }

    const start = segment.start.split(" ");
    if (start[0].split(":")[1] === "00") {
      if (langState.is24Hour) {
        return getCurrentHourFormat({
          is24Hour: true,
          timeStr: segment.start,
          trimModifier: true
        });
      } else {
        return `${start[0].split(":")[0]}${start[1]}`.toLowerCase();
      }
    }
    return `${start[0]}${start[1]}`.toLowerCase();
  };

  const getEndTime = () => {
    if (segment.end === "") {
      return "12am";
    }

    const end = segment.end.split(" ");

    if (end[0].split(":")[1] === "00") {
      if (langState.is24Hour) {
        return getCurrentHourFormat({
          is24Hour: true,
          timeStr: segment.end,
          trimModifier: true
        });
      } else {
        return `${end[0].split(":")[0]}${end[1]}`.toLowerCase();
      }
    }

    return getCurrentHourFormat({
      is24Hour: langState.is24Hour,
      timeStr: segment.end,
      trimModifier: langState.is24Hour
    })!
      .toLowerCase()
      .split(" ");
  };

  const getInnerColorSmall = () => {
    if (segment.basalRate >= 10 && segmentWidth > 38) {
      return "white";
    } else if (segment.basalRate <= 10 && segmentWidth > 23) {
      return "white";
    } else if (segment.basalRate % 1 === 0 && segmentWidth > 14) {
      return "white";
    } else {
      return "black";
    }
  };

  const showTopText = () => {
    if (GRAPH_MAX_HEIGHT === maxHeight) {
      if (
        segment.basalRate < 4 &&
        segment.end !== "12:00 am" &&
        !isPlaceholderSegment &&
        basalRateKey !== "INIT"
      ) {
        return true;
        // makes 0's work appropriately
      } else if (
        !isPlaceholderSegment &&
        basalRateKey !== "INIT" &&
        segment.basalRate < 4
      ) {
        return true;
        // for when 2 segments have a big diff
      } else if (
        basalRateMaxDifference >= 20 &&
        segment.basalRate < 18 &&
        segment.end !== "12:00 am" &&
        basalRateKey !== "INIT"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      // normal flow
      if (
        segment.basalRate < 2 &&
        segment.end !== "12:00 am" &&
        !isPlaceholderSegment &&
        basalRateKey !== "INIT"
      ) {
        return true;
        // makes 0's work appropriately
      } else if (
        !isPlaceholderSegment &&
        basalRateKey !== "INIT" &&
        segment.basalRate < 2
      ) {
        return true;
        // for when 2 segments have a big diff
      } else if (
        basalRateMaxDifference >= 20 &&
        segment.basalRate < 9 &&
        segment.end !== "12:00 am" &&
        basalRateKey !== "INIT"
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getInnerBasalRateText = () => {
    if (maxHeight === GRAPH_MAX_HEIGHT) {
      return (
        <Text numberOfLines={1} style={styles.innerBasalRateText}>
          {segment.basalRate >= 4 && basalRateMaxDifference < 10
            ? segment.basalRate.toLocaleString(langState.locale)
            : null}
        </Text>
      );
    }
    return (
      <Text numberOfLines={1} style={styles.innerBasalRateText}>
        {segment.basalRate >= 2 && basalRateMaxDifference < 5
          ? segment.basalRate.toLocaleString(langState.locale)
          : null}
      </Text>
    );
  };

  React.useEffect(() => {
    getSegmentWidth(segment);
  }, [getSegmentWidth, segment]);

  return (
    <>
      {/* view is for each bar section of the graph */}
      <View style={{ zIndex: index * -1 }}>
        {/* top text color when column is skinny */}
        {segmentWidth < 30 && segment.basalRate ? (
          <View
            style={[
              styles.topBasalRateView,
              {
                height: getSegmentHeight(segment.basalRate),
                width: segmentWidth
              }
            ]}>
            <Text
              numberOfLines={1}
              style={[
                styles.topBasalRateText,
                styles.topBasalRateTextSmallWidth
              ]}>
              {segment.basalRate < 2
                ? segment.basalRate.toLocaleString(langState.locale)
                : null}
            </Text>
          </View>
        ) : (
          // top text color when column is regular sized
          <Text style={styles.topBasalRateText}>
            {segment.basalRate !== undefined && showTopText()
              ? segment.basalRate.toLocaleString(langState.locale)
              : null}
          </Text>
        )}
        {/* blue graph sections */}
        <View
          style={[
            styles.blueGraphSections,
            {
              height: getSegmentHeight(segment.basalRate),
              width: segmentWidth,
              alignSelf: "flex-end"
            }
          ]}>
          {/* white text for inside the blue graph sections */}
          {segmentWidth < 40 ? (
            <Text
              numberOfLines={1}
              style={[
                styles.innerBasalRateText,
                styles.innerBasalRateTextSmallWidth,
                {
                  color: getInnerColorSmall()
                }
              ]}>
              {segment.basalRate >= 2
                ? segment.basalRate.toLocaleString(langState.locale)
                : null}
            </Text>
          ) : (
            getInnerBasalRateText()
          )}
        </View>
        {/* bottom time bar */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 4,
            borderBottomColor: hideBottomBorder
              ? "transparent"
              : theme.colors.graphBlue,
            borderBottomWidth: 1.5,
            marginLeft: segment.end === "12:00 am" ? -1 : 0
          }}>
          {/* before first segment, show 12am start time */}
          {index === 0 ? (
            <Text
              style={{
                fontSize: 12,
                position: segmentWidth < 60 ? "absolute" : "relative"
              }}>
              {getStartTime()}
            </Text>
          ) : (
            // after first segment, hide this start time
            <Text style={{ fontSize: 12 }} />
          )}
          {/* first time through, show the base timeline */}
          {index === 0 && segment.end === "12:00 am" ? (
            <Text
              style={{
                fontSize: 12
              }}>
              {langState.is24Hour ? "00:00" : "12am"}
            </Text>
          ) : (
            // if not first segment, show the endTime on timeline, but only if noon is not an endtime
            <>
              <Text
                style={{
                  fontSize: 12,
                  marginRight:
                    segment.end === "" ||
                    index === 0 ||
                    segment.end !== "12:00 am"
                      ? -16
                      : 0
                }}>
                {segmentWidth > 60 ? getEndTime() : ""}
              </Text>
            </>
          )}
        </View>
      </View>
      <View
        style={{
          borderWidth: 0.25,
          borderStyle: "dashed",
          borderColor:
            index < program.segments.length - 1
              ? theme.colors.grayScale.gray400
              : "transparent",
          height: displayOnly ? maxHeight - 20 : maxHeight + 2,
          marginBottom: displayOnly ? 22 : 0
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  innerBasalRateText: {
    alignSelf: "flex-start",
    color: theme.colors.white,
    paddingTop: 2,
    fontWeight: "700"
  },
  innerBasalRateTextSmallWidth: {
    zIndex: 99,
    position: "absolute"
  },
  topBasalRateView: {
    flexDirection: "row",
    justifyContent: "center",
    elevation: 1
  },
  topBasalRateText: {
    alignSelf: "center",
    fontWeight: "700",
    flexWrap: "nowrap",
    zIndex: 99
  },
  topBasalRateTextSmallWidth: {
    color: theme.colors.pureBlack,
    zIndex: 99,
    position: "absolute",
    alignSelf: "flex-end"
  },
  blueGraphSections: {
    backgroundColor: theme.colors.graphBlue,
    borderBottomColor: theme.colors.textInputBorder,
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "center",
    marginLeft: -1
  }
});

export default BasalSegmentChartItem;
