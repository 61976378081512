let strings = {
  "en-US": {
    inADay: "in a day",
    inDays: "in 3 days",
    inHours: "in 23 hours",
    podStatus: "POD STATUS",
    leftInPod: "U left in Pod",
    podExpires: "Pod Expires:",
    viewPodDetails: "view Pod details",
    deactivatedModalTitle: "Pod Deactivated",
    deactivatedModalMessage:
      "Your Pod expired! We automatically deactivated it for you. Please configure a new Pod to continue using the simulator.",
    expirationModalTitle: "Pod Expiring",
    expirationModalMessage:
      "Your Pod will expire soon. Please replace your Pod before it expires.",
    lowPodModalTitle: "Low Pod Insulin",
    lowPodModalMessage: "You have 10 U of insulin or less in your Pod.",
    ok: "Ok",
    date: "Jul 15"
  },
  "en-GB": {
    inADay: "in a day",
    inDays: "in 3 days",
    inHours: "in 23 hours",
    podStatus: "POD STATUS",
    leftInPod: "U left in Pod",
    podExpires: "Pod Expires:",
    viewPodDetails: "view pod details",
    deactivatedModalTitle: "Pod Deactivated",
    deactivatedModalMessage:
      "Your Pod has expired! We automatically deactivated it for you. Please configure a new Pod to continue using the simulator.",
    expirationModalTitle: "Pod Expiring",
    expirationModalMessage:
      "Your Pod will expire soon. Please replace your pod before it expires.",
    lowPodModalTitle: "Low Pod Insulin",
    lowPodModalMessage: "You have 10 U of insulin or less in your Pod.",
    ok: "Ok",
    date: "Jul 15"
  },
  "de-DE": {
    inADay: "in einem Tag",
    inDays: "in 3 Tagen",
    inHours: "in 23 Stunden",
    podStatus: "POD-STATUS",
    leftInPod: "E übrig im Pod",
    podExpires: "Pod läuft ab:",
    viewPodDetails: "Pod-Details anzeigen",
    deactivatedModalTitle: "Pod deaktiviert",
    deactivatedModalMessage:
      "Pod-Ende überschritten! Wir haben den Pod automatisch für Sie deaktiviert. Bitte konfigurieren Sie einen neuen Pod, um den Simulator weiter zu verwenden.",
    expirationModalTitle: "Pod-Ende",
    expirationModalMessage:
      "Das Pod-Ende tritt bald ein. Bitte ersetzen Sie Ihren Pod vor dem Ende.",
    lowPodModalTitle: "Wenig Insulin im Pod",
    lowPodModalMessage: "Sie haben 10 E Insulin oder weniger im Pod.",
    ok: "OK",
    date: "13. Jan."
  },
  "es-US": {
    inADay: "en 1 día",
    inDays: "en 3 días",
    inHours: "en 23 horas",
    podStatus: "ESTADO DEL POD",
    leftInPod: "U disponibles en el depósito",
    podExpires: "El Pod caduca:",
    viewPodDetails: "ver detalles del Pod",
    deactivatedModalTitle: "Pod desactivado",
    deactivatedModalMessage:
      "El Pod caducó. Lo desactivamos automáticamente por usted. Configure un nuevo Pod para seguir usando el simulador.",
    expirationModalTitle: "Pod caducará pronto",
    expirationModalMessage:
      "El Pod caducará pronto. Reemplace el Pod antes de que caduque.",
    lowPodModalTitle: "Pod con insulina baja",
    lowPodModalMessage: "Hay 10 U de insulina o menos en el Pod.",
    ok: "Aceptar",
    date: "09-ago."
  },
  "fr-FR": {
    inADay: "dans 1 jour",
    inDays: "dans 3 jours",
    inHours: "dans 23 heures",
    podStatus: "ÉTAT DU POD",
    leftInPod: "U restantes dans le Pod",
    podExpires: "Péremption du Pod :",
    viewPodDetails: "afficher les détails du Pod",
    deactivatedModalTitle: "Pod désactivé",
    deactivatedModalMessage:
      "Votre Pod est périmé ! Nous l'avons désactivé automatiquement pour vous. Veuillez configurer un nouveau Pod pour continuer à utiliser le simulateur.",
    expirationModalTitle: "Péremption du Pod",
    expirationModalMessage:
      "Votre Pod sera bientôt périmé. Veuillez remplacer votre Pod avant sa date de péremption.",
    lowPodModalTitle: "Insuline du Pod basse",
    lowPodModalMessage: "Vous avez 10 U d'insuline ou moins dans votre Pod.",
    ok: "Ok"
  },
  "nl-NL": {
    inADay: "in een dag",
    inDays: "in 3 dagen",
    inHours: "in 23 uur",
    podStatus: "POD-STATUS",
    leftInPod: "E over in Pod",
    podExpires: "Pod verloopt:",
    viewPodDetails: "Pod-gegevens bekijken",
    deactivatedModalTitle: "Pod gedeactiveerd",
    deactivatedModalMessage:
      "Uw Pod is verlopen! We hebben hem automatisch voor u gedeactiveerd. Gelieve een nieuwe Pod te configureren om de simulator te blijven gebruiken.",
    expirationModalTitle: "Pod verloopt",
    expirationModalMessage:
      "De uiterste gebruiksdatum van uw Pod wordt binnenkort bereikt. Vervang uw Pod voordat hij is verlopen.",
    lowPodModalTitle: "Weinig insuline in Pod",
    lowPodModalMessage: "U heeft 10 E insuline of minder in uw Pod.",
    ok: "OK"
  }
};

export { strings };
