import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import {
  basalActions,
  BasalState
} from "../../../../../../../../web/store/reducers/basal";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { UserState } from "../../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import PickerItem from "../../../../../../types/pickerItem";
import { Header } from "../../../../../atoms";
import Paragraph from "../../../../../atoms/paragraph";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./MaxBasalRateScreenContent";

const MaxBasalDetails = () => {
  const { languageContent } = useLanguageContent(strings);
  return (
    <>
      <Header label={languageContent.set} />
      <View style={{ paddingHorizontal: 16 }}>
        <Paragraph
          label={languageContent.upperLimit}
          styles={{ marginBottom: 24 }}
        />
        <Paragraph
          label={languageContent.adjustLater}
          styles={{ marginBottom: 24 }}
        />
      </View>
    </>
  );
};

const defaultRate = {
  inputLabel: "",
  label: "",
  value: 0,
  key: "INIT",
  index: 600
};

const MaxBasalRateScreen: React.FC = () => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [rate, setRate] = React.useState<PickerItem>(defaultRate);
  const [borderColor, setBorderColor] = React.useState<string>(
    theme.colors.textInputBorder
  );
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);

  const lowerRange = (0.05).toLocaleString(langState.locale);
  const contentVariables = [lowerRange];

  const getAdornmentColor = () => {
    if (rate.label == "") {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  return (
    <>
      <View
        style={[
          theme.layout.wrapper,
          {
            width: "100%",
            backgroundColor: "white"
          }
        ]}>
        <MaxBasalDetails />
        <View
          style={[
            styles.durationContainer,
            {
              backgroundColor:
                borderColor === theme.colors.blue ? "#EEF2F6" : "transparent"
            }
          ]}>
          <Text style={[styles.durationHeader]}>
            {languageContent.maxBasalRate}
          </Text>
          <Text style={[styles.subHeader]}>
            {replaceWithValues(languageContent.range, contentVariables)}
          </Text>
          <Pressable
            style={[
              theme.layout.inputContainerStyle,
              {
                borderColor: borderColor,
                width: "100%"
              }
            ]}
            onPress={() => {
              setRate({
                label: "2",
                inputLabel: "",
                value: -1,
                key: "INIT",
                index: 0
              });
            }}>
            <TextInput
              editable={false}
              style={[theme.layout.inputStyle, { width: "100%" }]}
              placeholder={"––"}
              placeholderTextColor={theme.colors.placeholder}
              selectionColor={theme.colors.blue}
              value={
                rate.label === ""
                  ? rate.label
                  : Number(rate.label).toLocaleString(langState.locale, {
                      minimumFractionDigits: 2
                    })
              }
              showSoftInputOnFocus={false}
            />
            <Text
              style={[
                theme.layout.adornment,
                {
                  color: getAdornmentColor()
                }
              ]}>
              {languageContent.unitHour}
            </Text>
          </Pressable>
        </View>
      </View>
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.cancel}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.next}
        rightActionStyle={{
          color:
            rate.label === "" ? theme.colors.placeholder : theme.colors.purple
        }}
        rightAction={() => {
          if (rate.label !== "") {
            if (userState.isExpert) {
              dispatch(
                basalActions.basalUpdate(
                  {
                    maxBasal: rate.label
                  },
                  basalState
                )
              );
              navigate("ExpertProgramEntry");
            } else {
              navigate("CreateBasalProgramIntro");
            }
          }
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  durationContainer: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    height: 158,
    zIndex: -1
  },
  durationHeader: {
    ...theme.fonts.h3
  },
  subHeader: {
    ...theme.fonts.text200,
    color: theme.colors.text.cancel,
    marginBottom: 6,
    fontWeight: "400"
  }
});

export default MaxBasalRateScreen;
