import React from 'react';
import { useIntl } from 'react-intl';
import { createStackNavigator } from '@react-navigation/stack';
import { TopBar } from 'web/components/ios/TopBar';
import { BolusSettingsRoutesEnum } from '../../types';
import { correctionFactorMessages } from '../../BolusSettings.messages';
import { CorrectionFactorMain } from './views/CorrectionFactorMain';
import { CorrectionFactorSegmentScreen } from './views/CorrectionFactorSegmentScreen';

const Stack = createStackNavigator();

export const CorrectionFactorStack: React.FC = () => {
  const intl = useIntl();
  return (
    <Stack.Navigator
      initialRouteName={BolusSettingsRoutesEnum.CorrectionFactor}
      screenOptions={{
        header: (props) => <TopBar {...props} />,
      }}
    >
      <Stack.Screen
        name={BolusSettingsRoutesEnum.CorrectionFactor}
        options={{
          title: intl.formatMessage(correctionFactorMessages.title),
        }}
        component={CorrectionFactorMain}
      />
      <Stack.Screen
        name={BolusSettingsRoutesEnum.CorrectionFactorSegment}
        options={{
          title: intl.formatMessage(correctionFactorMessages.title),
          header: (props) => <TopBar {...props} backAndCancelMode />,
        }}
        component={CorrectionFactorSegmentScreen}
      />
    </Stack.Navigator>
  );
};
