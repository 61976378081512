import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import BASAL_RATES_DATA_WITHOUT_ZERO from "../../../../../../data/basalRatesDataWithoutZero";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { BasalState } from "../../../../../../../../web/store/reducers/basal";
import { basalUpdate } from "../../../../../../../../web/store/reducers/basal/actions";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { UserState } from "../../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import PickerItem from "../../../../../../types/pickerItem";
import { Paragraph } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./MaxBasalRateSettingsScreenContent";

const MaxBasalRateSettingsScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [maxBasalRate, setMaxBasalRate] = React.useState<string>();

  const getDynamicIndex = () => {
    const adjustedInitialIndex = BASAL_RATES_DATA_WITHOUT_ZERO.findIndex(
      (BASAL_RATES_DATA_WITHOUT_ZERO) =>
        BASAL_RATES_DATA_WITHOUT_ZERO.label === basalState.maxBasal
    );
    return adjustedInitialIndex - 1;
  };

  const defaultRate = {
    inputLabel: "",
    label: !userState.isExpert ? "5" : basalState.maxBasal,
    value: !userState.isExpert ? 5 : Number(basalState.maxBasal),
    key: "INIT",
    index: getDynamicIndex()
  };

  const [rate, setRate] = React.useState<PickerItem>(defaultRate);
  const [fieldValue, setFieldValue] = React.useState<string>("");
  const [borderColor, setBorderColor] = React.useState<string>(
    theme.colors.textInputBorder
  );

  const { goBack } = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);

  const lowerRange = (0.05).toLocaleString(langState.locale);
  const basalRateFieldValue = !userState.isExpert ? "5" : fieldValue;
  const contentVariables = [lowerRange, basalRateFieldValue];

  const getAdornmentColor = () => {
    if (!fieldValue) {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  const saveMaxBasalRate = () => {
    dispatch(basalUpdate({ maxBasal: fieldValue }, basalState));
    goBack();
  };

  React.useEffect(() => {
    setFieldValue(rate.label);
  }, [rate]);

  return (
    <View style={[styles.container]}>
      <View>
        <View style={[{ paddingHorizontal: 16, paddingTop: 24 }]}>
          <Paragraph variant="body2" label={languageContent.body1UpperLimit} />
          <View style={[{ marginTop: 24 }]}>
            <Text style={[theme.fonts.h4]}>{languageContent.maxBasalRate}</Text>
            <Text
              style={[
                theme.fonts.body2,
                { color: theme.colors.grayScale.gray400, marginBottom: 8 }
              ]}>
              {replaceWithValues(languageContent.range, contentVariables)}
            </Text>
          </View>
          <TouchableOpacity
            style={[
              theme.layout.inputContainerStyle,
              {
                borderColor: borderColor
              }
            ]}
            activeOpacity={1}
            onPress={() => {
              setMaxBasalRate("5.00");
            }}>
            <TextInput
              editable={false}
              style={[theme.layout.inputStyle, { width: "80%" }]}
              placeholder={"––"}
              placeholderTextColor={theme.colors.placeholder}
              selectionColor={theme.colors.blue}
              value={maxBasalRate}
              showSoftInputOnFocus={false}
            />
            <Text
              style={[
                theme.layout.adornment,
                {
                  color: getAdornmentColor()
                }
              ]}>
              {languageContent.uHr}
            </Text>
          </TouchableOpacity>
        </View>
        <Text
          style={[theme.fonts.body2, { marginTop: 24, paddingHorizontal: 16 }]}>
          {`${languageContent.setMaxBasalRate} `}
          <Text style={{ fontWeight: "bold" }}>
            {replaceWithValues(languageContent.below, contentVariables)}
          </Text>
          {`, ${languageContent.modifyYourBasal}`}
        </Text>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.save}
        rightAction={saveMaxBasalRate}
        rightActionStyle={{
          color: !fieldValue ? theme.colors.placeholder : theme.colors.purple
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "white"
  },
  mockInput: {
    borderColor: theme.colors.textInputBorder,
    borderWidth: 1,
    backgroundColor: theme.colors.textInputBackgroundColor,
    width: "100%",
    borderRadius: 5
  },
  unitText: {
    color: theme.colors.grayScale.gray400,
    fontSize: 18
  },
  androidPickerWrapper: {
    flex: 2,
    zIndex: 20,
    height: 200
  }
});

export default MaxBasalRateSettingsScreen;
