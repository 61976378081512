import { isEqual } from "lodash";
import React from "react";
import { Dimensions, StyleSheet, Text, View } from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import {
  navigationActions,
  NavState
} from "../../../../../../../web/store/reducers/navigation";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import Button from "../../../../atoms/button";
import TabStackHeader from "../../../../atoms/tabStackHeader/TabStackHeader";
import { strings } from "./PodInfoScreenContent";
import getCurrentHourFormat from "../../../../../helpers/getCurrentHourFormat";

const { height } = Dimensions.get("window");

const PodInfoScreen: React.FC = () => {
  const bolusStatePodInsulin = useSelector(
    (state: RootState) => state.bolus.podInsulin
  );

  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const dispatch = useDispatch();

  const { languageContent } = useLanguageContent(strings);

  const getPodInsulin = () => {
    if (bolusStatePodInsulin >= 50) {
      return `50+ `;
    }
    return bolusStatePodInsulin.toFixed(2);
  };

  const handleNav = () => {
    dispatch(
      navigationActions.navigationUpdate(
        { navigateTo: "PodDetailsScreen" },
        navState
      )
    );
  };

  const date = new Date()
  date.setDate(date.getDate() + 3);
  const localizedDateString = date.toLocaleDateString(langState.locale, { month: 'short', day: '2-digit' });

  return (
    <>
      <View style={[styles.innerWrapper]}>
        <View style={[styles.card]}>
          <TabStackHeader labelCentered label={languageContent.podStatus} />
          <View
            style={{
              flex: 4,
              flexDirection: "column"
            }}>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                flex: 3
              }}>
              <View
                style={{
                  flex: 2,
                  justifyContent: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  marginTop: -12
                }}>
                <View
                  style={{
                    borderBottomColor: theme.colors.textInputBorder,
                    borderBottomWidth: 1,
                    marginHorizontal: 8,
                    flexDirection: "row",
                    paddingTop: 16,
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                  <Text
                    style={[
                      theme.fonts.h4,
                      {
                        fontSize: 20,
                        fontWeight: "bold",
                        paddingBottom: 4.5
                      }
                    ]}>{`${getPodInsulin()}`}</Text>
                  <Text
                    style={[
                      height <= 600 ? theme.fonts.body2 : theme.fonts.body1,
                      {
                        alignSelf: "flex-end",
                        paddingBottom: 1.5,
                        fontSize: 16,
                        justifyContent: "center"
                      }
                    ]}>
                    {languageContent.leftInPod}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    paddingTop: height <= 600 ? 2 : 8,
                    justifyContent: "center"
                  }}>
                  <Text
                    style={{
                      color: theme.colors.blue,
                      ...theme.fonts.body1,
                      fontSize: 16
                    }}>
                    {languageContent.podExpires}
                  </Text>
                  <Text
                    style={{
                      ...theme.fonts.body1,
                      fontWeight: "500",
                      fontSize: 16
                    }}>{` ${languageContent.inDays}`}</Text>
                </View>
                <View
                  style={{
                    alignItems: "center"
                  }}>
                  <Text
                    style={[
                      {
                        ...theme.fonts.body1,
                        fontSize: 16
                      }
                    ]}>
                    {`${localizedDateString} (${getCurrentHourFormat({
                      timeStr: "11:51 am",
                      is24Hour: langState.is24Hour
                    })})`}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              marginHorizontal: 8,
              marginBottom: 8
            }}>
            <Button
              variant="primary"
              style={{ elevation: 6 }}
              onPress={handleNav}>
              {languageContent.viewPodDetails}
            </Button>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 8
  },
  card: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.white,
    shadowColor: "#888",
    shadowOpacity: 0.7,
    shadowRadius: 6,
    shadowOffset: {
      height: 1,
      width: 0
    },
    elevation: 10
  }
});

export default PodInfoScreen;
