interface HistoryTogglerProps {
  leftTitle: string;
  rightTitle: string;
  isToggle: boolean;
}

export const HistoryToggler: React.FC<HistoryTogglerProps> = ({
  leftTitle,
  rightTitle,
  isToggle,
}) => {
  return (
    <div className="cursor-pointer font-semibold text-center text-sm flex w-[343px] p-[2px]  h-[32px] rounded-lg bg-ios-gray-4">
      <div className="pt-0.5 m-0 w-full h-[28px] rounded-lg">{leftTitle}</div>
      <div
        className={`pt-0.5 absolute shadow-md w-1/2 h-[28px] bg-white rounded-lg transition-spacing duration-300 ease-in-out ${isToggle && 'ml-[152px]'}`}
      >
        {isToggle ? rightTitle : leftTitle}
      </div>
      <div className="pt-0.5 m-0 w-full h-[28px]">{rightTitle}</div>
    </div>
  );
};
