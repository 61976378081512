import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'web/components/ios/Button';
import { useDispatch } from 'react-redux';
import {
  showModal,
  setCurrentModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import { DeactivatePodConfirmationModal } from 'web/components/ios/modals/ChangePodModals/ChangePodModals';
import { podDetailsMessages } from '../Pod.messages';
import { DeactivatePodModal } from 'web/components/ios/modals/GlobalModals/GlobalModals';
import { useNavigation } from '@react-navigation/native';
import { PodFTSRouteNames } from '../types';

export const PodDetailsScreen = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigation();

  const navToNoActivePodScreen = () => navigate(PodFTSRouteNames.noActivePod);

  const handleDeactivateYourPodModal = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <DeactivatePodConfirmationModal
            onConfirm={() => {
              handleDeactivatingPodModal();
            }}
            onReject={() => {
              dispatch(hideModal());
            }}
          />
        ),
      })
    );

    dispatch(showModal());
  };

  const handleDeactivatingPodModal = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <DeactivatePodModal onTimeout={() => navToNoActivePodScreen()} />
        ),
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="flex justify-between flex-col flex-1 items-stretch bg-white">
      <div className="flex flex-col px-2">
        <div className="mt-2">
          <div className="text-ios-secondaryGray font-semibold opacity-60">
            <FormattedMessage {...podDetailsMessages.insulinLeftTitle} />
          </div>
          <div className="text-black font-bold mt-1 text-[34px] leading-none">
            <FormattedMessage {...podDetailsMessages.tenUnitsTitle} />
          </div>
          <div className="text-ios-secondaryGray font-semibold mt-[4px] opacity-60">
            <FormattedMessage
              {...podDetailsMessages.updatedYesterdaySubtitle}
            />
          </div>
          <hr className="text-ios-secondaryGray mt-2 opacity-30" />
        </div>
        <div className="mt-2">
          <div className="text-ios-secondaryGray font-semibold opacity-60">
            <FormattedMessage {...podDetailsMessages.podStatusTitle} />
          </div>
          <div className="text-black font-bold mt-1 text-[22px]">
            <FormattedMessage {...podDetailsMessages.expiresTomorrowTitle} />
          </div>
          <div className="text-ios-secondaryGray font-semibold opacity-60">
            <FormattedMessage
              {...podDetailsMessages.expiresTomorrowDateSubtitle}
            />
          </div>
          <hr className="text-ios-secondaryGray mt-2 opacity-30" />
        </div>
        <div className="mt-2">
          <div className="text-ios-secondaryGray font-semibold opacity-60">
            <FormattedMessage {...podDetailsMessages.remindersTitle} />
          </div>

          <div>
            <div className="text-black  mt-1 text-xl">
              <FormattedMessage {...podDetailsMessages.podExpirationTitle} />
            </div>
            <div className="text-ios-secondaryGray mt-1 opacity-60">
              <FormattedMessage {...podDetailsMessages.remindHoursSubtitle} />
            </div>
            <hr className="text-ios-secondaryGray mt-2 opacity-30" />
          </div>
          <div className="mt-1">
            <div className="text-black  mt-1 text-xl">
              <FormattedMessage {...podDetailsMessages.lowPodInsulinTitle} />
            </div>
            <div className="text-ios-secondaryGray mt-1 opacity-60">
              <FormattedMessage {...podDetailsMessages.remindUnitsSubtitle} />
            </div>
            <hr className="text-ios-secondaryGray mt-2 opacity-30" />
          </div>
        </div>
      </div>

      <div className="w-full mb-4 px-2">
        <Button
          className="w-full rounded-lg font-bold"
          onClick={handleDeactivateYourPodModal}
          variant="colored"
        >
          <FormattedMessage {...podDetailsMessages.changePodButtonTitle} />
        </Button>
      </div>
    </div>
  );
};
