import { useState } from 'react';
import theme from 'mobile/latest/theme';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

const SerialNumberInput = (props: { hideElement?:Function ,digits: Array<Number> , width: number}) => {
  const [ showDigits, setShowDigits ] = useState(false);
  const { digits, hideElement, width } = props;
  
  const onPressHandler = () => {
    if (hideElement) { hideElement(false) }
    setShowDigits(true)
  }

  return (
    <TouchableOpacity onPress={() => onPressHandler() }>
      <View style={styles.container}>
        {digits.map((digit, index) => {
          if (digits.length === index + 1) {
            return (
              <View key={index} style={[styles.numberContainer, styles.last, {width: width}]}>
                {showDigits ? `${digit}` : ''}
              </View>
            );
          }
          return (
            <View key={index} style={[styles.numberContainer, {width: width}]}>
              {showDigits ? `${digit}` : ''}
            </View>
          );
        })}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.grayScale.gray400,
    borderWidth: 1,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'row',
  },
  numberContainer: {
    height: 25,
    flex: 1,
    borderColor: theme.colors.adornment,
    borderEndWidth: 1,
    textAlign: 'center',
    justifyContent: 'center',
    color: theme.colors.blueLink,
  },
  last: {
    borderEndWidth: 0,
  },
});

export default SerialNumberInput;