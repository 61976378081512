import colors from "./colors";
import fonts from "./fonts";
import layout from "./layout";
import navTheme from "./navigation";

const theme = {
  layout,
  colors,
  fonts,
  navTheme
};

export default theme;
