import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import HighlightRemove from "../../../../../assets/ic_highlight_remove.svg";
import Edit from "../../../../../assets/UI_Edit.svg";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import { strings } from "./ViewerManagementEditMenuContent";

interface Props {
  onPressEdit?: () => any;
  onPressDelete?: () => void;
}

const ViewerManagementEditMenu: React.FC<Props> = ({
  onPressEdit,
  onPressDelete
}) => {
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={[styles.wrapper]}>
      <View style={styles.mainStyle}>
        <TouchableOpacity onPress={onPressEdit} style={styles.editStyle}>
          <Edit style={[{ marginRight: 8 }]} />
          <Text style={[theme.fonts.body1, styles.textStyle]}>
            {languageContent.edit}
          </Text>
        </TouchableOpacity>
        <View style={[styles.break]} />
        <TouchableOpacity onPress={onPressDelete} style={styles.deleteStyle}>
          <HighlightRemove style={[{ marginRight: 8 }]} />
          <Text style={[theme.fonts.body1, styles.textStyle]}>
            {languageContent.delete}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    alignItems: "flex-end",
    zIndex: 21
  },
  mainStyle: {
    width: 135,
    right: 8,
    elevation: 1,
    marginTop: -64,
    backgroundColor: theme.colors.white,
    shadowColor: "#888",
    shadowRadius: 3,
    shadowOffset: {
      width: 0,
      height: 6
    },
    shadowOpacity: 0.5
  },
  editStyle: {
    flexDirection: "row",
    padding: 16,
    alignItems: "center"
  },
  deleteStyle: {
    flexDirection: "row",
    padding: 16,
    alignItems: "center"
  },
  textStyle: { color: theme.colors.grayScale.gray400 },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  }
});

export default ViewerManagementEditMenu;
