import { createStackNavigator } from '@react-navigation/stack';
import MangeSensorG7 from 'web/components/pages/G7/ManageSensorG7';
import theme from 'mobile/latest/theme';
import { strings } from 'mobile/latest/routes/simulator/TXSetupStackContent';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import SwitchSensorStack from '../SwitchSensor/SwitchSensorStack';
import InitialSetupG7Stack from './InitialSetupG7Stack';

const Stack = createStackNavigator();
const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const ManageSensorG7Stack = () => {
  const { languageContent } = useLanguageContent(strings);

  return (
    <Stack.Navigator initialRouteName="ManageSensorG7Stack" mode="card">
      <Stack.Screen
        name="ManageSensorG7"
        options={{
          title: `${languageContent.manageSensorIOS}`,
          headerStyle: defaultHeaderStyle,
        }}
        component={MangeSensorG7}
      />
      <Stack.Screen
        name="SwitchSensorStack"
        options={{
          headerShown: false,
        }}
        component={SwitchSensorStack}
      />
      <Stack.Screen
        name="InitialSetupG7Stack"
        options={{
          headerShown: false,
        }}
        component={InitialSetupG7Stack}
      />
    </Stack.Navigator>
  );
};

export default ManageSensorG7Stack;
