import { defineMessages } from 'react-intl';

export const historyEventBolusMessages = defineMessages({
  title: {
    id: 'ios.history-event-bolus.title',
    defaultMessage: 'Bolus',
  },
  canceledStatus: {
    id: 'ios.history-event-bolus.canceled-status',
    defaultMessage: 'Canceled',
  },
});
