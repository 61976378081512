import { isEqual } from "lodash"
import React from "react"
import {
  Dimensions,
  Platform,
  PlatformIOSStatic,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native"
import { useSelector } from "react-redux"
import EXPERT_BASAL_HOURS_DATA from "../../../data/ExpertBasalSegDurationData"
import getCurrentHourFormat from "../../../helpers/getCurrentHourFormat"
import makePascalCase from "../../../helpers/makePascalCase"
import useLanguageContent from "../../../hooks/useLanguageContent"
import { BasalState } from "../../../../../web/store/reducers/basal"
import { BolusState } from "../../../../../web/store/reducers/bolus"
import { RootState } from "../../../../../web/store/store"
import theme from "../../../theme"
import { LocaleType } from "../../../types/locales"
import CustomPicker from "../customPicker"
import { strings } from "./StartAndEndTimeSectionContent"

const { width } = Dimensions.get("screen")
const platform = Platform as PlatformIOSStatic

interface Props {
  startTime: string
  endTime: {
    label: string
    helpText: string
  }
  segmentsArrLength: number
  routeParams: any
  endTimeIndex: number
  adjustedEndTimeData?: any[]
  showEndTimePicker: boolean
  toggleEndTimePicker: () => void
  onConfirm: (value: string) => void
  onDismiss: () => void
  bolus?: boolean
  icRatio?: boolean
  correctionFactor?: boolean
  is24Hour: boolean
  locale: LocaleType
}

const getPickerHorizontalPlacement = () => {
  if (platform.isPad) {
    if (width > 1000) {
      return -width / 4.3
    } else {
      return -width / 3.7
    }
  } else if (Platform.OS === "android") {
    if (width < 380) {
      return -width / 1.84
    } else if (width < 395) {
      return -width / 2
    } else {
      return -width / 2.43
    }
  } else if (width < 380) {
    return -width / 1.93
  } else if (width < 395) {
    return -width / 2
  } else {
    return -width / 2.15
  }
}
const getPickerVerticalPlacement = () => {
  if (platform.isPad) {
    if (width > 1000) {
      return "-18.1%"
    } else {
      return "-26%"
    }
  } else if (Platform.OS === "android") {
    if (width < 380) {
      return "-51%"
    } else if (width < 395) {
      return "-47%"
    } else {
      return "-37.5%"
    }
  } else if (width < 380) {
    return "-49%"
  } else if (width < 395) {
    return "-47%"
  } else {
    return "-43%"
  }
}

const pickerHorizontalMargin = getPickerHorizontalPlacement()
const pickerVerticalMargin = getPickerVerticalPlacement()

const StartAndEndTimeSection: React.FC<Props> = ({
  startTime,
  endTime,
  segmentsArrLength,
  routeParams,
  endTimeIndex,
  adjustedEndTimeData,
  showEndTimePicker,
  bolus,
  icRatio,
  correctionFactor,
  toggleEndTimePicker,
  onDismiss,
  onConfirm,
  is24Hour,
  locale,
}) => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual,
  )
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual,
  )

  const { languageContent } = useLanguageContent(strings)

  const reducer = bolus
    ? bolusState.newExpertBolusProgram.segments
    : icRatio
    ? bolusState.newExpertBolusProgram.icRatioSegments
    : correctionFactor
    ? bolusState.newExpertBolusProgram.correctionFactorSegments
    : basalState.newExpertBasalProgram.segments

  return (
    <View style={[{ padding: 16 }]}>
      <View style={[{ flexDirection: "row" }]}>
        <Text style={[theme.fonts.h4, { flex: 1.1 }]}>
          {languageContent.start}
        </Text>
        <Text style={[theme.fonts.h4, { flex: 1 }]}>{languageContent.end}</Text>
      </View>
      <View style={[{ flexDirection: "row", width: "100%" }]}>
        <View
          style={[
            {
              flexDirection: "row",
              justifyContent: "space-between",
              flex: 1.1,
              paddingVertical: 8,
            },
          ]}
        >
          <Text
            style={[
              {
                fontSize: 40,
                color: theme.colors.text.inputText,
              },
            ]}
          >
            {`${getCurrentHourFormat({
              timeStr: startTime,
              is24Hour: is24Hour,
              trimModifier: true,
            })}`}
            {!is24Hour ? (
              <Text style={[styles.periodText]}>
                {`${startTime.split(" ")[1].toUpperCase()}`}
              </Text>
            ) : null}
          </Text>
          <View style={[{ alignSelf: "center" }]}>
            <Text style={[{ fontSize: 40 }]}>{" - "}</Text>
          </View>
        </View>
        {/* ENDTIME PICKER */}
        <TouchableOpacity
          onPress={() => toggleEndTimePicker()}
          activeOpacity={0.6}
          style={[
            theme.layout.mockInput,
            {
              flex: 1,
            },
          ]}
        >
          <Text style={[theme.layout.mockInputStyle, { marginLeft: 16 }]}>
            {endTime.label !== "" ? (
              <Text style={{ color: theme.colors.text.inputText }}>
                {`${getCurrentHourFormat({
                  timeStr: endTime.label,
                  is24Hour: is24Hour,
                  trimModifier: true,
                })}`}
                {!is24Hour ? (
                  <Text style={[styles.periodText]}>
                    {endTime.label.split(" ")[1]}
                  </Text>
                ) : null}
              </Text>
            ) : (
              <Text style={{ color: theme.colors.placeholder }}>––</Text>
            )}
          </Text>
        </TouchableOpacity>
      </View>
      <View style={[{ flexDirection: "row", marginTop: 8 }]}>
        <Text
          style={[
            theme.fonts.body1,
            { flex: 1.1, color: theme.colors.text.cancel },
          ]}
        >
          {segmentsArrLength === 0
            ? `${languageContent.night}`
            : makePascalCase(reducer[segmentsArrLength - 1].startTimeHelpText)}
        </Text>
        <Text
          style={[
            theme.fonts.body1,
            { flex: 1, color: theme.colors.text.cancel },
          ]}
        >
          {endTime.helpText &&
            routeParams &&
            routeParams.editEndHelpText &&
            makePascalCase(routeParams.editEndHelpText)}
          {endTime.label !== "" &&
            makePascalCase(EXPERT_BASAL_HOURS_DATA[endTimeIndex].helpText)}
        </Text>
      </View>
      <View style={{ height: 35 }}>
        {adjustedEndTimeData ? (
          <View
            style={[
              styles.endTimePickerWrapper,
              {
                display: showEndTimePicker ? "flex" : "none",
              },
            ]}
          >
            <CustomPicker
              is24Hour={is24Hour}
              locale={locale}
              title={languageContent.endTime}
              values={adjustedEndTimeData}
              isVisible={showEndTimePicker}
              selectedValue={endTime.label}
              onConfirm={(value: string | null) => onConfirm(value!)}
              onDismiss={onDismiss}
            />
          </View>
        ) : null}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  periodText: {
    color: theme.colors.text.cancel,
    fontSize: 18,
  },
  endTimePickerWrapper: {
    flex: 2,
    zIndex: 20,
    marginTop: pickerVerticalMargin,
    marginLeft: pickerHorizontalMargin,
  },
})

export default StartAndEndTimeSection
