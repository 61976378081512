import * as type from "./actions";

export interface ViewerManagementState {
  defaultViewer?: ViewerState;
  newViewer?: ViewerState;
}

export interface ViewerState {
  firstName: string;
  lastName: string;
  email: string;
  relationship?: string;
  status: string;
}

// set state
export const defaultViewer: ViewerState = {
  firstName: "Paul",
  lastName: "Wilson",
  email: "",
  relationship: "",
  status: "Active"
};

export const newViewer: ViewerState = {
  firstName: "",
  lastName: "",
  email: "",
  relationship: "",
  status: ""
};

export const initialState: ViewerManagementState = {
  defaultViewer: defaultViewer
};

const reducer = (
  state: ViewerManagementState = initialState,
  action: type.Action
): ViewerManagementState => {
  switch (action.type) {
    case type.VIEWER_MANAGEMENT_INIT:
      return initialState;
    case type.VIEWER_MANAGEMENT_UPDATE:
      return { ...state, ...action.payload.viewer };
    default:
      return state;
  }
};

export default reducer;
