let strings = {
  "en-US": {
    new: "NEW",
    uDay: "U/day"
  },
  "en-GB": {
    new: "NEW",
    uDay: "U/day"
  },
  "de-DE": {
    new: "NEU",
    uDay: "E/Tag"
  },
  "es-US": {
    new: "NUEVO",
    uDay: "U/día"
  },
  "fr-FR": {
    new: "NOUVEAU",
    uDay: " U/jour"
  },
  "nl-NL": {
    new: "NIEUW",
    uDay: "E/dag"
  }
};

export { strings };
