import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { Image, StyleSheet, Text, View } from "react-native-web";
import { useSelector } from "react-redux";
import BasalCustomProgramFull from "../../../../../../../../assets/basal_custom_program_full.png";
import BasalCustomProgramFull24 from "../../../../../../../../assets/basal_custom_program_full_24hr.png";
import BasalCustomProgramFullComma24 from "../../../../../../../../assets/basal_custom_third_program_full_24hr_comma.png";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { BasalState } from "../../../../../../../../web/store/reducers/basal";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { UserState } from "../../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { ListHeader } from "../../../../../atoms";
import {
  BottomInnerNav,
  ConfirmationModal,
  SegmentTopBar
} from "../../../../../molecules";
import SegmentListItem from "../../../../../molecules/segmentListItem";
import { strings } from "./CustomProgramSegmentsReviewScreenContent";
import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat";

const CustomProgramSegmentsReviewScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  const [editModal, setEditModal] = React.useState<boolean>(false);
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={[styles.container]}>
      <View>
        <SegmentTopBar
          programName={basalState.customProgramName}
          showRightUnits
          isOnlyProgramName
        />
        <View style={[styles.break]} />
        <Image
          style={{
            height: "30%",
            width: "90%",
            marginHorizontal: 18,
            marginBottom: 16
          }}
          resizeMode="contain"
          source={
            langState.is24Hour
              ? langState.locale === "de-DE"
                ? BasalCustomProgramFullComma24
                : BasalCustomProgramFull24
              : BasalCustomProgramFull
          }
        />
        <ListHeader numOfSegments="3" />
        <SegmentListItem
          onEditPress={() => {}}
          index={1}
          segmentLabel={`${languageContent.segment} 1:`}
          startTime={
            getCurrentHourFormat({
              timeStr: "12:00 am",
              is24Hour: langState.is24Hour
            })!
          }
          endTime={
            getCurrentHourFormat({
              timeStr: "8:00 am",
              is24Hour: langState.is24Hour
            })!
          }
          firstRateOrBoldedLabel={languageContent.basalRate}
          firstRateValue={`${(0.75).toLocaleString(langState.locale)} ${
            languageContent.uHr
          }`}
        />

        <SegmentListItem
          onEditPress={() => navigate("CustomProgramSegmentTwo")}
          index={2}
          segmentLabel={`${languageContent.segment} 2:`}
          startTime={
            getCurrentHourFormat({
              timeStr: "8:00 am",
              is24Hour: langState.is24Hour
            })!
          }
          endTime={
            getCurrentHourFormat({
              timeStr: "6:00 pm",
              is24Hour: langState.is24Hour
            })!
          }
          firstRateOrBoldedLabel={languageContent.basalRate}
          firstRateValue={`1 ${languageContent.uHr}`}
        />

        <SegmentListItem
          onEditPress={() => navigate("CustomProgramSegmentThree")}
          index={3}
          segmentLabel={`${languageContent.segment} 3:`}
          startTime={
            getCurrentHourFormat({
              timeStr: "6:00 pm",
              is24Hour: langState.is24Hour
            })!
          }
          endTime={
            getCurrentHourFormat({
              timeStr: "12:00 am",
              is24Hour: langState.is24Hour
            })!
          }
          firstRateOrBoldedLabel={languageContent.basalRate}
          firstRateValue={`${(0.5).toLocaleString(langState.locale)} ${
            languageContent.uHr
          }`}
        />
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.save}
        rightAction={() => {
          navigate("BasalPrograms");
        }}
        rightActionStyle={{ color: theme.colors.purple }}
      />
      <ConfirmationModal
        isVisible={editModal && !userState.isExpert}
        purple
        title={languageContent.edit}
        message={
          <View>
            <Text style={[theme.fonts.body1, { color: theme.colors.white }]}>
              {languageContent.notAvailable}
            </Text>
          </View>
        }
        hideDismiss={true}
        confirmText={languageContent.ok}
        onConfirm={() => {
          setEditModal(false);
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "#fff"
  },
  segmentHeader: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.textInputBorder,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    backgroundColor: "#EDF2F6"
  },
  segmentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    marginVertical: 16
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  }
});

export default CustomProgramSegmentsReviewScreen;
