import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
  Dimensions,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native-web";
import LsDefaultImage from "../../../../../../../../assets/ls-default.jpg";
import LsFlowersImage from "../../../../../../../../assets/ls-flowers.jpg";
import LsGreenImage from "../../../../../../../../assets/ls-green.jpg";
import LsSkyImage from "../../../../../../../../assets/ls-sky.jpg";
import LsTurtleImage from "../../../../../../../../assets/ls-turtle.jpg";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { Button } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./PersonalizeBackgroundScreenContent";

const { height } = Dimensions.get("window");

const PersonalizeBackgroundScreen: React.FC = () => {
  const { languageContent } = useLanguageContent(strings);

  const { navigate } = useNavigation();

  return (
    <View
      style={[
        theme.layout.wrapper,
        { width: "100%", backgroundColor: "white" }
      ]}>
      <View style={[styles.innerWrapper, { width: "100%" }]}>
        <Text
          style={[
            theme.fonts.h3,
            {
              margin: 16,
              marginTop: -16,
              textAlign: "center",
              fontWeight: "200"
            }
          ]}>
          {languageContent.personalizeController}
        </Text>
        <View>
          <Text style={[theme.fonts.h5, styles.subheader]}>
            {languageContent.selectBackground}
          </Text>
          <TouchableOpacity activeOpacity={1} onPress={() => "Fired"}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                height: height / 10.5,
                overflow: "hidden",
                marginVertical: 16,
                marginLeft: 16,
                marginRight: 8
              }}>
              <Image
                resizeMode="contain"
                source={LsDefaultImage}
                style={[
                  styles.selectedImage,
                  { borderColor: theme.colors.blue, borderWidth: 3 }
                ]}
              />
              <Image
                resizeMode="contain"
                source={LsSkyImage}
                style={styles.image}
              />
              <Image
                resizeMode="contain"
                source={LsTurtleImage}
                style={styles.image}
              />
              <Image
                resizeMode="contain"
                source={LsFlowersImage}
                style={styles.image}
              />
              <Image
                resizeMode="contain"
                source={LsGreenImage}
                style={styles.image}
              />
            </View>
          </TouchableOpacity>
          <Text
            style={[theme.fonts.text400, { margin: 16, fontWeight: "400" }]}>
            {languageContent.lsDisplay}
          </Text>
          <View style={{ marginHorizontal: 4 }}>
            <Button
              variant="primary"
              style={{ elevation: 6 }}
              onPress={() => {
                navigate("SetPin");
              }}>
              {languageContent.continue}
            </Button>
          </View>
        </View>
      </View>
      <BottomInnerNav />
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    width: Dimensions.get("screen").width,
    padding: 16,
    marginTop: height < 800 ? height / 40 : height / 20
  },
  subheader: {
    marginBottom: 24,
    fontWeight: "300",
    textAlign: "center"
  },
  image: {
    width: "22%",
    height: "97%",
    marginRight: 8,
    borderRadius: 2,
    marginTop: 1.5
  },
  selectedImage: {
    width: "22.5%",
    height: "100%",
    marginRight: 8,
    borderRadius: 2
  }
});

export default PersonalizeBackgroundScreen;
