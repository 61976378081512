import { defineMessages } from 'react-intl';

export const alertsMessages = defineMessages({
  headerTitle: {
    id: 'ios.alerts.header-title',
    defaultMessage: 'Notifications',
  },
  calendarTitle: {
    id: 'ios.alerts.calendar-title',
    defaultMessage: 'Filter by Date',
  },
  actionsTitle: {
    id: 'ios.alerts.actions-title',
    defaultMessage: 'ACTIONS',
  },
  notificationHistoryTitle: {
    id: 'ios.alerts.notification-history-title',
    defaultMessage: 'NOTIFICATION HISTORY',
  },
  todayDateTitle: {
    id: 'ios.alerts.today-date-title',
    defaultMessage: 'TODAY, NOVEMBER 28',
  },
  podExpiredTitle: {
    id: 'ios.alerts.pod-expired-title',
    defaultMessage: 'Pod Expired',
  },
  podExpiredDescription: {
    id: 'ios.alerts.pod-expired-description',
    defaultMessage: 'Change Pod now',
  },
  podExpirationTitle: {
    id: 'ios.alerts.pod-expiration-title',
    defaultMessage: 'Pod Expiration',
  },
  podExpirationDescription: {
    id: 'ios.alerts.pod-expiration-description',
    defaultMessage: 'Pod expires at 12:00 AM, November 28',
  },
  podErrorTitle: {
    id: 'ios.alerts.pod-error-title',
    defaultMessage: 'Pod Error',
  },
  podErrorDescription: {
    id: 'ios.alerts.pod-error-description',
    defaultMessage:
      'Insulin delivery stopped. <p>Change Pod Now.</p> <p2>Ref: 19-00801-00251-205</p2>',
  },
  lowPodInsulinTitle: {
    id: 'ios.alerts.low-pod-insulin-title',
    defaultMessage: 'Low Pod Insulin',
  },
  lowPodInsulinDescription: {
    id: 'ios.alerts.low-pod-insulin-description',
    defaultMessage: '1 U insulin or less remain in the Pod. Change Pod soon.',
  },
  noActivePodTitle: {
    id: 'ios.alerts.no-active-pod-title',
    defaultMessage: 'No Active Pod',
  },
  noActivePodDescription: {
    id: 'ios.alerts.no-active-pod-description',
    defaultMessage: 'Activate a Pod to start insulin delivery.',
  },
  podShutOffTitle: {
    id: 'ios.alerts.pod-shut-off-title',
    defaultMessage: 'Pod Shut-Off',
  },
  podShutOffDescription: {
    id: 'ios.alerts.pod-shut-off-description',
    defaultMessage: 'Activate a Pod to start insulin delivery.',
  },
});
