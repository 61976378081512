let strings = {
  "en-US": {
    switchManualMode: "Switch to Manual Mode?",
    everydayBasalProgramStart: "This will start your",
    everyday: "Everyday",
    basalProgram: "Basal Program",
    currentBasalProgram: "Current Basal Program",
    uHr: "U/hr",
    leftActionText: "CLOSE",
    rightActionText: "SWITCH",
    showPodCommAlertTitle: "Communicating with Pod",
    pleaseWait: "Please wait..."
  },
  "en-GB": {
    switchManualMode: "Switch to Manual Mode?",
    everydayBasalProgramStart: "This will start your",
    everyday: "Everyday",
    basalProgram: "Basal Programme",
    currentBasalProgram: "Current Basal Programme",
    uHr: "U/hr",
    leftActionText: "CLOSE",
    rightActionText: "SWITCH",
    showPodCommAlertTitle: "Communicating with Pod",
    pleaseWait: "Please wait..."
  },
  "de-DE": {
    switchManualMode: "Auf manuellen Modus umschalten?",
    everydayBasalProgramStart: "Dadurch wird Ihr",
    everyday: "Jeden Tag",
    basalProgram: "Basalratenprofil gestartet.",
    currentBasalProgram: "Aktuelles Basalratenprofil",
    uHr: "E/Std.",
    leftActionText: "SCHLIESSEN",
    rightActionText: "UMSCHALTEN",
    showPodCommAlertTitle: "Kommunikation mit dem Pod",
    pleaseWait: "Bitte warten..."
  },
  "es-US": {
    switchManualMode: "¿Cambiar a modo manual?",
    everydayBasalProgramStart: "Se iniciará su programa basal",
    everyday: "programa basal",
    basalProgram: "",
    currentBasalProgram: "Programa basal actual",
    uHr: "U/h",
    leftActionText: "CERRAR",
    rightActionText: "CAMBIAR",
    showPodCommAlertTitle: "Comunicando con el Pod"
  },
  "fr-FR": {
    switchManualMode: "Passer en Mode Manuel ?",
    everydayBasalProgramStart: "Cela démarrera votre",
    everyday: "programme basal",
    basalProgram: "quotidien",
    currentBasalProgram: "Programme basal actuel",
    uHr: "U/h",
    leftActionText: "FERMER",
    rightActionText: "CHANGER",
    showPodCommAlertTitle: "Communication avec le Pod"
  },
  "nl-NL": {
    switchManualMode: "Wijzigen naar Handmatige Modus?",
    everydayBasalProgramStart: "Hiermee start uw",
    everyday: "Elke dag",
    basalProgram: "Basaalprogramma",
    currentBasalProgram: "Huidig basaalprogramma",
    uHr: "E/u.",
    leftActionText: "SLUITEN",
    rightActionText: "WIJZIGEN",
    showPodCommAlertTitle: "Communicatie met Pod"
  }
};

export { strings };
