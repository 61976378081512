import React from "react";

interface IAuthContext {
  authorized: boolean;
}

const AuthContext = React.createContext<IAuthContext>({
  authorized: false
});

export default AuthContext;
