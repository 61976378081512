import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { ReactComponent as G6SerialNumber } from 'assets/ios/g6-serial-number.svg';
import {
  showModal,
  hideModal,
  setCurrentModal,
} from 'web/store/reducers/modals/modalsSlice';
import { Modal } from 'web/components/ios/Modal';
import { PodCommunicationModal } from 'web/components/ios/modals/GlobalModals/GlobalModals';
import { messages } from '../TxID.messages';

const SERIAL_NUMBER = '804JJ7';

export const TxIDEnterSerialNumber: React.FC = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const [serialNumber, setSerialNumber] = useState<string>('');
  const { setParams } = useNavigation();

  // Define modal for current screen.
  useEffect(() => {
    const handleCancelClick = () => {
      dispatch(hideModal());
    };
    const handleConfirmClick = () => {
      const handlePodCommunicationTimeOut = () => {
        dispatch(hideModal());
        navigate('Dashboard');
      }
      dispatch(setCurrentModal({
        modal: <PodCommunicationModal onTimeout={handlePodCommunicationTimeOut} />
      }));
      dispatch(showModal());
    };
    const modal = (
      <Modal
        title={intl.formatMessage(messages.modalTitle)}
        leftButtonText={intl.formatMessage(messages.modalCancel)}
        leftButtonOnClick={handleCancelClick}
        rightButtonText={intl.formatMessage(messages.modalConfirm)}
        rightButtonOnClick={handleConfirmClick}
      >
        {SERIAL_NUMBER.split('').join(' ')}
      </Modal>
    );
    dispatch(setCurrentModal({ modal }));
  }, [dispatch]);

  const handleSnClick = () => {
    setSerialNumber(SERIAL_NUMBER);
    setParams({
      topBarRightButtonEnabled: true,
      topBarOnRightButtonClick: () => dispatch(showModal()),
    });
  };

  return (
    <div className="font-ios text-regular h-full bg-white p-2">
      <div className="font-bold text-2xl mb-[11px]">
        <FormattedMessage {...messages.serialNumberTitle} />
      </div>
      <div className="mb-4">
        <FormattedMessage
          {...messages.serialNumberText}
          values={{ br: <br /> }}
        />
      </div>
      <div className="flex flex-col items-center px-2 mb-4">
        <div className="border-b pb-2">
          <div className="px-4">
            <G6SerialNumber className="w-full" />
            <div
              className="flex text-4xl font-bold w-full justify-between text-black"
              onClick={handleSnClick}
            >
              <input
                className="w-6 placeholder:text-ios-gray-7 disabled:bg-white"
                placeholder="-"
                value={serialNumber[0]}
              />
              <input
                className="w-6 placeholder:text-ios-gray-7 disabled:bg-white"
                placeholder="-"
                value={serialNumber[1]}
              />
              <input
                className="w-6 placeholder:text-ios-gray-7 disabled:bg-white"
                placeholder="-"
                value={serialNumber[2]}
              />
              <input
                className="w-6 placeholder:text-ios-gray-7 disabled:bg-white"
                placeholder="-"
                value={serialNumber[3]}
              />
              <input
                className="w-6 placeholder:text-ios-gray-7 disabled:bg-white"
                placeholder="-"
                value={serialNumber[4]}
              />
              <input
                className="w-6 placeholder:text-ios-gray-7 disabled:bg-white"
                placeholder="-"
                value={serialNumber[5]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center text-small">
        <FormattedMessage {...messages.serialNumberDisclaimer} />
      </div>
    </div>
  );
};
