import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Animated,
  TouchableWithoutFeedback,
  TouchableOpacity
} from "react-native";
import theme from "../../../theme";
import Ellipse from "../../../../../assets/ellipse.svg";

const { width } = Dimensions.get("window");

const AnimatedView = Animated.createAnimatedComponent(View);

interface BasalProgramEntryProps {
  onPressMenu?: (programName: string) => void;
  onPressItem?: () => void;
  viewerName: string;
  status: string;
  badgeColor: string;
  statusColor: string;
}

const BasalProgramEntry: React.FC<BasalProgramEntryProps> = ({
  onPressItem,
  onPressMenu,
  viewerName,
  status,
  badgeColor,
  statusColor
}) => {
  const [badgeInitials, setBadgeInitials] = React.useState<string>("");

  const getBadgeInitials = (viewerName: string) => {
    const firstName = viewerName.split(" ")[0];
    const lastName = viewerName.split(" ")[1];
    const firstInitial = firstName.slice(0, 1);
    const lastInitial = lastName.slice(0, 1);
    setBadgeInitials(firstInitial + lastInitial);
  };

  React.useEffect(() => {
    getBadgeInitials(viewerName);
  }, [viewerName]);
  return (
    <AnimatedView style={styles.segmentRow}>
      <TouchableWithoutFeedback style={styles.container} onPress={onPressItem}>
        <View style={[{ flexDirection: "row" }]}>
          <View style={[{ marginRight: 8 }]}>
            <Text
              style={[styles.badgeInitials, { backgroundColor: badgeColor }]}>
              {badgeInitials}
            </Text>
          </View>
          <View>
            <Text style={[theme.fonts.body1, styles.viewerNameStyles]}>
              {viewerName}
            </Text>
            <Text style={[theme.fonts.body1, { color: statusColor }]}>
              {status}
            </Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
      <TouchableOpacity
        style={{ justifyContent: "center" }}
        onPress={() => (onPressMenu ? onPressMenu(viewerName) : {})}>
        <Ellipse />
      </TouchableOpacity>
    </AnimatedView>
  );
};

const styles = StyleSheet.create({
  segmentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    marginVertical: 16
  },
  container: {
    flexDirection: "row",
    width: width * 0.8
  },
  badgeInitials: {
    backgroundColor: theme.colors.blue,
    color: "white",
    height: 50,
    width: 50,
    textAlign: "center",
    paddingTop: 15,
    borderRadius: 25,
    overflow: "hidden"
  },
  iconBadge: {
    backgroundColor: theme.colors.greenText,
    borderRadius: 4,
    marginTop: -14,
    padding: 2
  },
  iconBadgeText: {
    color: theme.colors.white,
    textAlign: "center"
  },
  viewerNameStyles: { width: 264, color: theme.colors.blue }
});

export default BasalProgramEntry;
