import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { SettingsSection } from "../../../../../organisms";
import { strings } from "./RemindersSettingsScreenContent";

const ReminderSettingsScreen: React.FC = () => {
  const [podShutOffToggle, setPodShutOffToggle] =
    React.useState<boolean>(false);
  const [confidenceToggle, setConfidenceToggle] = React.useState<boolean>(true);
  const [programRemindersToggle, setProgramRemindersToggle] =
    React.useState<boolean>(true);

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <ScrollView style={{ height: "100%", backgroundColor: "white" }}>
      <View style={[styles.card]}>
        <SettingsSection
          settingTitle={languageContent.podExpiration}
          subtitleText={languageContent.beforeExpiration}
          subtitleTextOnPressHandler={() => navigate("PodExpiration")}
        />
        <SettingsSection
          settingTitle={languageContent.lowPodInsulin}
          subtitleText={languageContent.lowPodU}
          subtitleTextOnPressHandler={() => navigate("LowPodInsulin")}
        />
        <SettingsSection
          settingTitle={languageContent.shutOff}
          hasCustomToggleSwitch
          startToggleTrue={false}
          onToggleChange={() => setPodShutOffToggle(!podShutOffToggle)}
          settingValueSubTextElement={
            podShutOffToggle ? (
              <TouchableOpacity onPress={() => navigate("PodShutOff")}>
                <Text style={[theme.fonts.body3, styles.settingValue]}>
                  {languageContent.hrs}
                </Text>
              </TouchableOpacity>
            ) : (
              <Text style={[theme.fonts.body3, styles.disabledValue]}>
                {languageContent.offTap}
              </Text>
            )
          }
        />
        <SettingsSection
          settingTitle={languageContent.confidenceReminders}
          hasCustomToggleSwitch
          startToggleTrue={true}
          onToggleChange={() => setConfidenceToggle(!confidenceToggle)}
          settingValueSubTextElement={
            confidenceToggle ? (
              <View>
                <Text
                  style={[
                    theme.fonts.body3,
                    styles.settingValue,
                    { width: "75%" }
                  ]}>
                  {languageContent.atStartEnd}
                </Text>
                <Text
                  style={[
                    theme.fonts.body3,
                    styles.settingValue,
                    { width: "75%", color: "#999" }
                  ]}>
                  {languageContent.confidenceRemindersOne}
                </Text>
              </View>
            ) : (
              <Text style={[theme.fonts.body3, styles.disabledValue]}>
                {languageContent.offTap}
              </Text>
            )
          }
        />
        <SettingsSection
          settingTitle={languageContent.modalTitle}
          hasCustomToggleSwitch
          startToggleTrue={true}
          onToggleChange={() =>
            setProgramRemindersToggle(!programRemindersToggle)
          }
          settingValueSubTextElement={
            programRemindersToggle ? (
              <View>
                <Text
                  style={[
                    theme.fonts.body3,
                    styles.settingValue,
                    { width: "75%" }
                  ]}>
                  {languageContent.modalMessage1}
                </Text>
                <Text
                  style={[
                    theme.fonts.body3,
                    styles.settingValue,
                    { width: "75%", color: "#999" }
                  ]}>
                  {languageContent.programRemindersOne}
                </Text>
              </View>
            ) : (
              <Text style={[theme.fonts.body3, styles.disabledValue]}>
                {languageContent.offTap}
              </Text>
            )
          }
        />
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  section: {
    padding: 16
  },
  card: {
    width: "100%",
    height: "130%",
    backgroundColor: theme.colors.white,
    shadowColor: "#999",
    shadowOpacity: 0.3,
    shadowRadius: 6,
    shadowOffset: {
      height: 1,
      width: 0
    },
    elevation: 6,
    marginBottom: 8,
    borderBottomWidth: 1,
    borderColor: theme.colors.textInputBorder
  },
  disabledValue: {
    color: theme.colors.text.cancel
  },
  settingValue: {
    color: theme.colors.lightBlue
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  sectionRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

export default ReminderSettingsScreen;
