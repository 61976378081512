import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'ios.settings-reminders.title',
    defaultMessage: 'Reminders',
  },
  lowPodInsulinText: {
    id: 'ios.settings-reminders.low-pod-insulin-text',
    defaultMessage: 'Low Pod Insulin',
  },
  lowPodInsulinSubText: {
    id: 'ios.settings-reminders.low-pod-insulin-sub-text',
    defaultMessage: '10 U',
  },
  podShutOffText: {
    id: 'ios.settings-reminders.pod-shut-off-text',
    defaultMessage: 'Pod Shut-Off',
  },
  podShutOffSubText: {
    id: 'ios.settings-reminders.pod-shut-off-sub-text',
    defaultMessage: '4 hrs',
  },
  podExpirationText: {
    id: 'ios.settings-reminders.pod-expiration-text',
    defaultMessage: 'Pod Expiration',
  },
  podExpirationSubText: {
    id: 'ios.settings-reminders.pod-expiration-sub-text',
    defaultMessage: '4 hrs before expiration',
  },
  confidenceRemindersText: {
    id: 'ios.settings-reminders.confidence-reminders-text',
    defaultMessage: 'Confidence Reminders',
  },
  confidenceRemindersSubText: {
    id: 'ios.settings-reminders.confidence-reminders-sub-text',
    defaultMessage: 'At start and end of bolus, extended bolus and temp basal',
  },
  confidenceRemindersDisclaimer: {
    id: 'ios.settings-reminders.confidence-reminders-disclaimer',
    defaultMessage:
      'If Confidence Reminders are on, you will hear a beep at the start and end of a temp basal or bolus.',
  },
  programRemindersText: {
    id: 'ios.settings-reminders.program-reminders-text',
    defaultMessage: 'Program Reminders',
  },
  programRemindersSubText: {
    id: 'ios.settings-reminders.program-reminders-sub-text',
    defaultMessage:
      'Pod will beep every 60 minutes during temp basal or ext. bolus',
  },
  programRemindersDisclaimer: {
    id: 'ios.settings-reminders.program-reminders-disclaimer',
    defaultMessage:
      'If Program Reminders are on, the Pod beeps every 60 minutes during a temp basal or extended bolus.',
  },
});

export const lowPodInsulinMessages = defineMessages({
  title: {
    id: 'ios.settings-reminders.low-pod-insulin.title',
    defaultMessage: 'Low Pod Insulin',
  },
  text: {
    id: 'ios.settings-reminders.low-pod-insulin.text',
    defaultMessage:
      'The Omnipod 5 App notifies you when the amount of insulin in your Pod reaches this level.',
  },
  lowPodInsulin: {
    id: 'ios.settings-reminders.low-pod-insulin.reminder-name',
    defaultMessage: 'Low Pod Insulin',
  },
  unitRange: {
    id: 'ios.settings-reminders.low-pod-insulin.unit-range',
    defaultMessage: '(10 to 50 U)',
  },
  value: {
    id: 'ios.settings-reminders.low-pod-insulin.value',
    defaultMessage: '10 U',
  },
});

export const podShutOffMessages = defineMessages({
  title: {
    id: 'ios.settings-reminders.pod-shut-off.title',
    defaultMessage: 'Pod Shut-Off',
  },
  text: {
    id: 'ios.settings-reminders.pod-shut-off.text',
    defaultMessage:
      'This is useful if you are prone to hypoglycemia unawareness.',
  },
  podShutOff: {
    id: 'ios.settings-reminders.pod-shut-off.pod-shut-off',
    defaultMessage: 'Pod Shut-Off',
  },
  inactivityTimer: {
    id: 'ios.settings-reminders.pod-shut-off.inactivity-timer',
    defaultMessage: 'Inactivity Timer',
  },
  inactivityRange: {
    id: 'ios.settings-reminders.pod-shut-off.inactivity-range',
    defaultMessage: '(1 to 24 hrs)',
  },
  value: {
    id: 'ios.settings-reminders.pod-shut-off.value',
    defaultMessage: '4 hrs',
  },
  disclaimer: {
    id: 'ios.settings-reminders.pod-shut-off.disclaimer',
    defaultMessage:
      'If you do not interact with your Omnipod 5 App within the Pod Shut-Off time period, an advisory alarm sounds. If you do not respond within 15 minutes, a hazard alarm sounds. You must then deactivate and change your Pod to start insulin delivery.',
  },
});

export const podExpirationMessages = defineMessages({
  title: {
    id: 'ios.settings-reminders.pod-expiration.title',
    defaultMessage: 'Pod Expiration',
  },
  text: {
    id: 'ios.settings-reminders.pod-expiration.text',
    defaultMessage: 'Set the number of hours advance notice you would like to have before your Pod expires.',
  },
  podExpiration: {
    id: 'ios.settings-reminders.pod-expiration.pod-expiration',
    defaultMessage: 'Pod Expiration',
  },
  podExpirationRange: {
    id: 'ios.settings-reminders.pod-expiration.pod-expiration-range',
    defaultMessage: '(1 to 24 hrs)',
  },
  value: {
    id: 'ios.settings-reminders.pod-expiration.value',
    defaultMessage: '4 hrs',
  },
});
