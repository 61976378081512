const colors = {
  primary: {
    light: "#c9adeb",
    background: "#FFF",
    backgroundCircle: "#EB523D",
    main: "#5B34B9",
    dark: "#5B34B9"
  },
  secondary: {
    light: "#394052",
    main: "#FFA700",
    tierThree: "#434B5B"
  },
  grayScale: {
    gray100: "#2e2e2e",
    gray200: "#2F3951",
    gray400: "#676E7E",
    gray500: "#d3d3d3",
    gray600: "#EDF2F6",
    gray800: "#EFECEC",
    gray900: "#F9F9F9",
    gray1000: "#F8F8F8"
  },
  loading: {
    loadingBarLight: "#5B34B950"
  },
  text: {
    dark: "#3A3E44",
    grayText: "#5C6A7A",
    cancel: "#57697B",
    inputText: "#1D232B"
  },
  toast: {
    success: "#62A633"
  },
  divider: "#DEE4EA",
  placeholder: "#B3BAC3",
  midGray: "#DFE4EA",
  adornment: "#708293",

  tabBackground: "#E4E6E8",
  textInputBorder: "#b5bbc0",
  textInputBackgroundColor: "#f6f8fb",
  timeSegmentsBorder: "#D9E0E7",
  green: "#3CE141",
  greenText: "#53b022",
  hypoProtectGreen: "#DFFDB6",
  blue: "#154fab",
  blueLink: "#006fdd",
  blueCircle: "#3864d1",
  lightBlue: "#428CFE",
  graphBlue: "#006FDD",
  purple: "#5B34B9", //6C3F99
  lightPurple: "#c9adeb",
  modalPurple: "#8D61C8",
  mango: "#FFA700",
  raspberry: "#F7534C",
  red: "#EC442D",
  secondaryRed: "#EF5C43",
  white: "#FFFFFF",
  oyster: "#F1EFE9",
  limitedMode: "#42505E",
  yellow: "#ffc800",
  pureBlack: "#000000",
  modalBakground: '#00000073',
  applicatorContainerBackground: '#f6f8fb',
  DrawerStackBorderColor: '#eee',
  operationModes: {
    manual: { color: "#3F77DA" },
    automated: { color: "#5839B4" },
    limited: { color: "#59697B" },
  },
  // New Colors based on SimApp --> We need to confirm these with design team.
  web: {
    blue: "#294EA6",
    lightBlue: "#74A5E6",
    lightGray: '#F0F2F5',
  },
};
export default colors;
