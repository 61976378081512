import React from "react";
import { ScrollView, View } from "react-native";
import { AveragesCardMultiDayHistory, DetailsCardMultidayHistory } from "..";
import useLanguageContent from "../../../hooks/useLanguageContent";
import { strings } from "./MultidayHistorySectionContent";

interface Props {
  historyRange: any;
  historyRangeIndex: number;
}

const MultidayHistorySection: React.FC<Props> = ({
  historyRange,
  historyRangeIndex
}) => {
  const { languageContent } = useLanguageContent(strings);
  return (
    <ScrollView>
      <View>
        <AveragesCardMultiDayHistory
          sectionOneLabel={`${languageContent.sensorAverage} - ${historyRange.title}`}
          sectionTwoLabel={languageContent.insulinAndCarbs}
          historyRangeIndex={historyRangeIndex}
        />
      </View>
      <View style={{ marginBottom: 8, elevation: 6 }}>
        {historyRange ? (
          <DetailsCardMultidayHistory
            columnOneLabel={languageContent.sensorInRange}
            columnOneSubLabel="%"
            columnTwoLabel={languageContent.totalInsulin}
            columnTwoSubLabel={languageContent.u}
            columnThreeLabel={languageContent.totalCarbs}
            columnThreeSubLabel="g"
            historyRange={historyRange}
          />
        ) : null}
      </View>
    </ScrollView>
  );
};

export default MultidayHistorySection;
