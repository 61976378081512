import { useNavigation } from '@react-navigation/native';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import { isEqual } from 'lodash';
import React from 'react';
import {
  Dimensions,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  Text,
} from 'react-native';
import { useSelector } from 'react-redux';
import { ReactComponent as HamburgerSVG } from 'assets/header/hamburger.svg';
import { ReactComponent as NotificationsBell } from 'assets/header/ui/NotificationBell.svg';
import { ReactComponent as AutomatedIcon } from 'assets/modeIcons/home_auto_neutral.svg';
import { ReactComponent as ManualIcon } from 'assets/modeIcons/home_manual_neutral.svg';
import { ReactComponent as LimitedIcon } from 'assets/modeIcons/home_limited_neutral.svg';
import { UserState } from 'web/store/reducers/user';
import { RootState } from 'web/store/store';
import theme from 'mobile/latest/theme';
import HomeStack from './HomeStack';

import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { strings } from './HeaderMenuStackContent';

interface Props {
  navigation: any;
}

const Stack = createStackNavigator();

export const HeaderMenuStack: React.FC<Props> = ({ navigation }) => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);

  const { navigate } = useNavigation();

  const getCurrentModeIcon = () => {
    let Icon = ManualIcon;
    let text = languageContent.manualMode;
    let color = theme.colors.operationModes.manual;

    if (userState.limitedMode) {
      Icon = LimitedIcon;
      text = languageContent.limitedMode;
      color = theme.colors.operationModes.limited;
    }

    if (userState.automatedMode) {
      Icon = AutomatedIcon;
      text = languageContent.automatedMode;
      color = theme.colors.operationModes.automated;
    }

    return (
      <View style={styles.modeIcon}>
        <Icon />
        <Text style={[styles.modeText, color]}>{text}</Text>
      </View>
    );
  };

  return (
    <>
      <Stack.Navigator initialRouteName="Main" mode="modal">
        <Stack.Screen
          name="Main"
          component={HomeStack}
          options={{
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            headerTitle: '',
            headerStyle: {
              height:
                Platform.OS === 'ios'
                  ? Dimensions.get('window').height > 800
                    ? 106
                    : 74
                  : 60,
              shadowColor: '#888',
              shadowOpacity: 0.4,
              shadowRadius: 3,
              shadowOffset: {
                height: 3,
                width: 0,
              },
              elevation: 8,
            },
            headerLeft: () => {
              return (
                <View style={{ flexDirection: 'row', paddingLeft: 10 }}>
                  <View style={[theme.layout.trueCenter]}>
                    <TouchableOpacity
                      onPress={() => {
                        navigation.toggleDrawer();
                      }}
                    >
                      <HamburgerSVG />
                    </TouchableOpacity>
                  </View>
                </View>
              );
            },
            headerRight: () => (
              <View style={[theme.layout.trueCenter, styles.headerRightStyle]}>
                <TouchableOpacity
                  onPress={() => {
                    setTimeout(() => {
                      navigate('Notifications');
                    }, 400);
                  }}
                >
                  <NotificationsBell />
                </TouchableOpacity>

                <TouchableOpacity onPress={() => {}}>
                  {getCurrentModeIcon()}
                </TouchableOpacity>
              </View>
            ),
          }}
        />
      </Stack.Navigator>
    </>
  );
};

const styles = StyleSheet.create({
  headerRightStyle: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  modeIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 8,
  },
  modeText: {
    fontSize: 13,
    marginLeft: 4,
  },
});
