import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AppRoutesEnum } from 'web/routes/types';
import { History } from './views/History';
import { TopBar } from 'web/components/ios/TopBar';
import {
  historyAutoEventsMessages,
  historyBolusCanceledDetailsMessages,
  historyBolusDetailsMessages,
  historyPodDeactivatedDetailsMessages,
  historySummaryMessages,
} from './History.messages';
import { useIntl } from 'react-intl';
import { IOSHistoryRoutesEnum } from './types';
import { HistoryAutoEvents } from './views/HistoryAutoEvents';
import { HistoryPodDeactivatedDetails } from './views/HistoryPodDeactivatedDetails';
import { HistoryBolusDetails } from './views/HistoryBolusDetails';
import { HistoryBolusCanceledDetails } from './views/HistoryBolusCanceledDetails';

const Stack = createStackNavigator();

export const IOSHistoryStack = () => {
  const intl = useIntl();
  return (
    <Stack.Navigator
      initialRouteName={AppRoutesEnum.History}
      screenOptions={{
        header: (props) => <TopBar {...props} backMode />,
      }}
    >
      <Stack.Screen
        name={AppRoutesEnum.History}
        options={{
          header: (props) => (
            <TopBar {...props} calendarMode calendarModeText="Dec 7" />
          ),
          headerTitle: intl.formatMessage(historySummaryMessages.headerTitle),
        }}
        component={History}
      />

      <Stack.Screen
        name={IOSHistoryRoutesEnum.HistoryAutoEvents}
        options={{
          headerTitle: intl.formatMessage(
            historyAutoEventsMessages.headerTitle
          ),
        }}
        component={HistoryAutoEvents}
      />
      <Stack.Screen
        name={IOSHistoryRoutesEnum.HistoryPodDeactivatedDetails}
        options={{
          headerTitle: intl.formatMessage(
            historyPodDeactivatedDetailsMessages.headerTitle
          ),
        }}
        component={HistoryPodDeactivatedDetails}
      />
      <Stack.Screen
        name={IOSHistoryRoutesEnum.HistoryBolusDetails}
        options={{
          headerTitle: intl.formatMessage(
            historyBolusDetailsMessages.headerTitle
          ),
        }}
        component={HistoryBolusDetails}
      />
      <Stack.Screen
        name={IOSHistoryRoutesEnum.HistoryBolusCanceledDetails}
        options={{
          headerTitle: intl.formatMessage(
            historyBolusCanceledDetailsMessages.headerTitle
          ),
        }}
        component={HistoryBolusCanceledDetails}
      />
    </Stack.Navigator>
  );
};
