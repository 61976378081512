import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  headerTitle: {
    id: 'ios.temp-basal.header-title',
    defaultMessage: 'Set Temp Basal',
  },
  confirmBtn: {
    id: 'ios.temp-basal.confirm-btn',
    defaultMessage: 'Confirm',
  },
  startBtn: {
    id: 'ios.temp-basal.start-btn',
    defaultMessage: 'Start',
  },
  cancelBtn: {
    id: 'ios.temp-basal.cancel-btn',
    defaultMessage: 'Cancel',
  },
  percentage: {
    id: 'ios.temp-basal.percentage',
    defaultMessage: '%',
  },
  hours: {
    id: 'ios.temp-basal.hours',
    defaultMessage: 'hrs',
  },
  emptyValue: {
    id: 'ios.temp-basal.empty-value',
    defaultMessage: '--',
  },
  basalRateInputLabel: {
    id: 'ios.temp-basal.basal-rate-input-label',
    defaultMessage: 'Basal Rate',
  },
  basalRateInputPlaceholder: {
    id: 'ios.temp-basal.basal-rate-input-placeholder',
    defaultMessage: '(up to 30 U/hr)',
  },
  basalRateInputValue: {
    id: 'ios.temp-basal.basal-rate-input-value',
    defaultMessage: '↓ 30% (Decrease)',
  },
  durationInputLabel: {
    id: 'ios.temp-basal.duration-input-label',
    defaultMessage: 'Duration',
  },
  durationInputPlaceholder: {
    id: 'ios.temp-basal.duration-placeholder',
    defaultMessage: '(30 min to 12 hrs)',
  },
  durationInputValue: {
    id: 'ios.temp-basal.duration-input-value',
    defaultMessage: '9.5',
  },
});

export const confirmTempBasalMessages = defineMessages({
  headerTitle: {
    id: 'ios.confirm-temp-basal.header-title',
    defaultMessage: 'Confirm Temp Basal',
  },
  changeBasalRateLabel: {
    id: 'ios.confirm-temp-basal.change-basal-rate-label',
    defaultMessage: 'CHANGE BASAL RATE:',
  },
  changeBasalRateRowValue: {
    id: 'ios.confirm-temp-basal.change-basal-rate-row-value',
    defaultMessage: 'Decrease of ↓30% for 9.5 hrs',
  },
});
