let strings = {
  "en-US": {
    great: "Great!",
    buildProfile: "You are ready to build your insulin management profile.",
    setupProfile: "Set Up Profile"
  },
  "en-GB": {
    great: "Great!",
    buildProfile: "You are ready to build your insulin-management profile.",
    setupProfile: "Set Up Profile"
  },
  "de-DE": {
    great: "Prima!",
    buildProfile:
      "Sie können nun Ihr Profil für das Insulin-Managementsystem erstellen.",
    setupProfile: "Profil einrichten"
  },
  "es-US": {
    great: "¡Excelente!",
    buildProfile:
      "Ya está listo para crear su perfil de administración de insulina.",
    setupProfile: "Configurar el perfil"
  },
  "fr-FR": {
    great: "Super !",
    buildProfile:
      "Vous êtes prêt(e) à créer votre profil de gestion de l'insuline.",
    setupProfile: "Configuration du profil"
  },
  "nl-NL": {
    great: "Prima!",
    buildProfile: "U bent nu klaar om uw insulinebeheerprofiel aan te maken.",
    setupProfile: "Profiel instellen"
  }
};

export { strings };
