import React from 'react';
import { isEqual } from 'lodash';
import { View, Text, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { navigationActions, NavState } from 'web/store/reducers/navigation';
import { userActions, UserState } from 'web/store/reducers/user';
import { RootState } from 'web/store/store';
import { Route, getRouteMap } from 'web/data/fastTravelNavData';
import UISelectorStyles from 'web/styles/UISelectorStyles';
import { selectShowIOSFeatures } from 'web/store/reducers/featureFlags/featureFlags.helpers';
import { AppRoutesEnum } from 'web/routes/types';

const NavigationController: React.FC = () => {
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const dispatch = useDispatch();
  const shouldShowIOS = useSelector(selectShowIOSFeatures);
  const routes = getRouteMap(shouldShowIOS);

  const handleRouteSelected = (route: Route) => () => {
    const { label, name } = route;

    if (name === AppRoutesEnum.podSetup || name === AppRoutesEnum.txSetup) {
      dispatch(
        userActions.userUpdate(
          {
            hasTXId: false,
          },
          userState
        )
      );
    }

    dispatch(
      navigationActions.navigationUpdate({
        navigateTo: name,
        currentRoute: name,
        navigateToLabel: label,
      })
    );
  };

  return (
    <View style={UISelectorStyles.container}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Text style={UISelectorStyles.label}>App Features (Fast Travel)</Text>
        {Object.keys(routes).map((routeKey: string) => {
          const { label, name } = routes[routeKey];

          return (
            <TouchableOpacity
              key={label}
              style={UISelectorStyles.radio}
              onPress={handleRouteSelected(routes[routeKey])}
            >
              <Text style={UISelectorStyles.radioText}>
                <input
                  type="radio"
                  value={name}
                  checked={navState.navigateToLabel === label}
                  style={UISelectorStyles.radioButton}
                  readOnly
                />
                {label}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

export default NavigationController;
