import React from 'react';
import {
  CardStyleInterpolators,
  StackNavigationOptions,
  createStackNavigator,
} from '@react-navigation/stack';
import { useIntl } from 'react-intl';
import { messages } from './PauseStartStack.messages';
import { TopBar } from 'web/components/ios/TopBar';
import { PauseInsulin } from './views/PauseInsulin';
import { AppRoutesEnum } from 'web/routes/types';
import { useSelector } from 'react-redux';
import { StartInsulin } from './views/StartInsulin';

import { selectIsInsulinPaused } from 'web/store/reducers/basal/basal.helpers';

const Stack = createStackNavigator();

export const PauseStartStack = () => {
  const isInsulinPaused = useSelector(selectIsInsulinPaused);
  const intl = useIntl();

  const commonHeaderStyles: StackNavigationOptions = {
    headerStyle: {
      backgroundColor: 'rgba(249, 249, 249, 0.94)',
      paddingTop: '20px',
      borderBottomColor: 'rgba(60, 60, 67, 0.36)',
    },
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  };

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          height: 91,
        },
        headerTitle: isInsulinPaused
          ? intl.formatMessage(messages.topBarStartInsulinTitle)
          : intl.formatMessage(messages.topBarPauseInsulinTitle),
        header: (props) => <TopBar {...props} variant="Cancel" expanded />,
      }}
    >
      <Stack.Screen
        name={AppRoutesEnum.pauseStartInsulin}
        options={{
          ...commonHeaderStyles,
        }}
        component={isInsulinPaused ? StartInsulin : PauseInsulin}
      />
    </Stack.Navigator>
  );
};
