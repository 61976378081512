import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { BottomNav } from 'web/components/ios/BottomMenu/BottomNav';
import { selectCurrentOperationMode } from 'web/store/reducers/user/user.helpers';
import { Button } from 'web/components/ios/Button';
import { switchToManualMode } from 'web/store/reducers/user/actions';
import { basalResumeInsulin } from 'web/store/reducers/basal/actions';
import { PodCommunicationModal } from 'web/components/ios/modals/GlobalModals/GlobalModals';
import {
  setCurrentModal,
  showModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import { AppRoutesEnum } from 'web/routes/types';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';
import { ReactComponent as BasalProgramSVG } from 'web/assets/ios/complete-basal-segments.svg';
import { manualModeMessages } from '../SwitchMode.messages';

export const SwitchToManual: React.FC = () => {
  const dispatch = useDispatch();
  const currentOperationMode = useSelector(selectCurrentOperationMode);

  const handlePodCommTimeout = () => {
    dispatch(hideModal());
    dispatch(switchToManualMode());
    dispatch(navigateToRoute(AppRoutesEnum.dashboard));
  };

  const handleSwitch = () => {
    dispatch(
      setCurrentModal({
        modal: <PodCommunicationModal onTimeout={handlePodCommTimeout} />,
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="font-ios text-regular h-full flex justify-between flex-col bg-white">
      <div className="flex flex-col h-full justify-between pb-3">
        <div>
          <div className="bg-white px-2 py-3">
            <div className="text-2xl font-bold mb-3">
              <FormattedMessage {...manualModeMessages.title} />
            </div>
            <div>
              <FormattedMessage {...manualModeMessages.text} />
            </div>
          </div>

          <div className="px-2 pt-2 pb-0.5 text-modals text-ios-menuItems bg-ios-gray-6">
            <FormattedMessage {...manualModeMessages.currentBasalProgram} />
          </div>
          <div className="bg-white p-2">
            <div>
              <FormattedMessage {...manualModeMessages.programName} />
            </div>
            <div className="text-small text-ios-menuItems mb-[10px]">
              <FormattedMessage {...manualModeMessages.programSchedule} />
            </div>
            <div>
              <BasalProgramSVG className="w-full" />
            </div>
          </div>
        </div>

        <div className="px-2">
          <Button
            onClick={handleSwitch}
            variant="colored"
            colorMode="manual"
            full
          >
            <FormattedMessage {...manualModeMessages.button} />
          </Button>
        </div>
      </div>

      <BottomNav />
    </div>
  );
};
