import React from 'react';
import {
  ActivityIndicator,
  Dimensions,
  StyleProp,
  StyleSheet,
  Text,
  TextProps,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from 'react-native';
import theme from '../../../theme';

const { height } = Dimensions.get('window');

interface Props extends TouchableOpacityProps {
  onPress: any;
  disabled?: boolean;
  loading?: boolean;
  variant:
    | 'primary'
    | 'default'
    | 'light'
    | 'dark'
    | 'primaryDark'
    | 'cancel'
    | 'G7';
  textProps?: TextProps;
  textStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
}

const Button: React.FC<Props> = ({
  onPress,
  disabled,
  loading,
  variant,
  textProps,
  children,
  style,
  textStyle,
  ...rest
}) => {
  const styledText: 'defaultText' = `${variant}Text` as any;

  return (
    <>
      <View style={[styles.shadow]}>
        <TouchableOpacity
          onPress={onPress}
          disabled={disabled}
          style={[styles.btn, styles[variant], style && style]}
          {...rest}
        >
          <>
            {loading ? (
              <ActivityIndicator color={theme.colors.primary.main} />
            ) : (
              <Text
                {...textProps}
                style={[
                  styles.text,
                  styles[styledText],
                  textProps && textProps.style && textProps.style,
                  { fontWeight: '500' },
                  textStyle && textStyle,
                ]}
              >
                {children}
              </Text>
            )}
          </>
        </TouchableOpacity>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  btn: {
    paddingVertical: 8,
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  G7: {
    backgroundColor: theme.colors.white,
  },
  G7Text: {
    color: theme.colors.primary.main,
  },
  cancel: {
    backgroundColor: theme.colors.divider,
  },
  cancelText: {
    color: theme.colors.text.cancel,
  },
  light: {
    backgroundColor: theme.colors.modalPurple,
  },
  lightText: {
    color: theme.colors.primary.main,
  },
  dark: {
    backgroundColor: theme.colors.secondary.main,
  },
  darkText: {
    color: theme.colors.white,
  },
  default: {
    backgroundColor: theme.colors.grayScale.gray600,
  },
  defaultText: {
    color: theme.colors.grayScale.gray200,
  },
  primary: {
    backgroundColor: theme.colors.primary.main,
  },
  primaryText: {
    color: theme.colors.white,
  },
  primaryDark: {
    backgroundColor: theme.colors.primary.dark,
  },
  primaryDarkText: {
    color: theme.colors.white,
  },
  text: {
    ...theme.fonts.text400,
    textAlign: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    lineHeight: height <= 600 ? 18 : 24,
  },
  disabled: {
    opacity: 0.6,
  },
  icon: {
    height: 38,
    width: 50,
    marginRight: 12,
  },
  shadow: {
    zIndex: 20,
    shadowColor: '#888',
    shadowOpacity: 0.3,
    shadowRadius: 3,
    shadowOffset: {
      height: 2,
      width: 0,
    },
    elevation: 6,
  },
});

export default Button;
