import React, { PropsWithChildren, ReactNode } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Card } from 'web/components/helpers/Layout/Layout';
import theme from 'mobile/latest/theme';

interface Props {
  svg: ReactNode;
  title: string;
  mainText: string;
  subText?: string;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    userSelect: 'none',
    cursor: 'pointer',
    textAlign: 'center',
  },
  imageBackground: {
    backgroundColor: 'white',
  },
  title: {
    marginBottom: theme.layout.spacing.large,
  },
  mainText: {
    marginBottom: theme.layout.spacing.large,
  },
});

export const CarouselSlideLayout: React.FC<PropsWithChildren<Props>> = ({
  svg,
  title,
  mainText,
  subText,
}) => {
  return (
    <View style={styles.container}>
      <View>
        <View style={[styles.imageBackground]}>{svg}</View>
        <Card>
          <View>
            <Text style={[theme.fonts.text600, styles.title]}>{title}</Text>
          </View>
          <View>
            <Text style={[theme.fonts.text600, styles.mainText]}>{mainText}</Text>
          </View>
          {subText && (
            <View>
              <Text>{subText}</Text>
            </View>
          )}
        </Card>
      </View>
    </View>
  );
};
