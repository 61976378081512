let strings = {
  "en-US": {
    total: "Total: %1 U/day",
    edit: "Edit",
    start: "Start"
  },
  "en-GB": {
    total: "Total: %1 U/day",
    edit: "Edit",
    start: "Start"
  },
  "de-DE": {
    total: "%1 E...",
    edit: "Bearbeiten",
    start: "Starten"
  },
  "es-US": {
    total: "Total: %1 U/día",
    edit: "Editar",
    start: "Iniciar"
  },
  "fr-FR": {
    total: "Total : %1 U/jour",
    edit: "Modifier",
    start: "Démarrer"
  },
  "nl-NL": {
    total: "Totaal: %1 E/dag",
    edit: "Bewerken",
    start: "Start"
  }
};

export { strings };
