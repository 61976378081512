import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { Pressable, ScrollView } from "react-native";
import {
  Image,
  StyleSheet,
  Text,
  useWindowDimensions,
  View
} from "react-native-web";
import { useSelector } from "react-redux";
import segmentOneEmptyImage from "../../../../../../../../assets/segment1_empty.png";
import segmentOneFilledImage from "../../../../../../../../assets/segment1_filled.png";
import segmentOneTimeImage from "../../../../../../../../assets/segment1_time.png";

import segmentOneEmptyImage24 from "../../../../../../../../assets/segment1_empty_24.png";
import segmentOneFilledImage24 from "../../../../../../../../assets/segment1_filled_24.png";
import segmentOneFilledImageComma24 from "../../../../../../../../assets/segment1_filled_comma.png";
import segmentOneTimeImage24 from "../../../../../../../../assets/segment1_time_24.png";

import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat";
import { getGraphImageHorizontalPlacement } from "../../../../../../helpers/getGraphImageHorizontalPlacement";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { BasalState } from "../../../../../../../../web/store/reducers/basal";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { BottomInnerNav, SegmentTopBar } from "../../../../../molecules";
import { strings } from "./BasalSegmentOneScreenContent";

const BasalSegmentOneScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );

  const [basalRate, setBasalRate] = React.useState<boolean>(false);
  const [endTime, setEndTime] = React.useState<string>("");

  const { width } = useWindowDimensions();
  const { languageContent } = useLanguageContent(strings);
  const { navigate } = useNavigation();

  const getGraphImage = () => {
    if (endTime && basalRate) {
      return langState.is24Hour
        ? langState.locale === "de-DE"
          ? segmentOneFilledImageComma24
          : segmentOneFilledImage24
        : segmentOneFilledImage;
    }

    if (endTime) {
      return langState.is24Hour ? segmentOneTimeImage24 : segmentOneTimeImage;
    }

    return langState.is24Hour ? segmentOneEmptyImage24 : segmentOneEmptyImage;
  };

  React.useEffect(() => {
    getGraphImage();
  }, [langState]);

  return (
    <>
      <ScrollView style={{ backgroundColor: "#fff" }}>
        <SegmentTopBar
          programName={basalState.customProgramName}
          segmentNum="1"
          showRightUnits
        />
        <View style={[styles.break]} />
        <Image
          style={{
            alignSelf: "auto",
            width: getGraphImageHorizontalPlacement(width),
            marginHorizontal: 16,
            height: "35%"
          }}
          resizeMode="contain"
          source={getGraphImage()}
        />
        <View style={[styles.break, { marginBottom: 16 }]} />

        <View style={[{ paddingHorizontal: 16 }]}>
          <View style={[{ flexDirection: "row" }]}>
            <Text style={[theme.fonts.h4, { flex: 1.1 }]}>
              {languageContent.start}
            </Text>
            <Text style={[theme.fonts.h4, { flex: 1 }]}>
              {languageContent.end}
            </Text>
          </View>
          <View style={[{ flexDirection: "row", width: "100%" }]}>
            <View
              style={[
                {
                  flexDirection: "row",
                  marginTop: 5
                }
              ]}>
              <Text
                style={[
                  {
                    fontSize: 32,
                    color: theme.colors.text.inputText
                  }
                ]}>
                {getCurrentHourFormat({
                  timeStr: "12:00 am",
                  is24Hour: langState.is24Hour,
                  trimModifier: true
                })}
                {!langState.is24Hour ? (
                  <Text style={[styles.periodText]}>AM</Text>
                ) : null}
              </Text>
              <View style={[{ alignSelf: "center" }]}>
                <Text style={[{ fontSize: 32 }]}>{" - "}</Text>
              </View>
            </View>
            <Pressable
              onPress={() => setEndTime("8:00")}
              style={[
                theme.layout.mockInput,
                {
                  flex: 1,
                  padding: 5
                }
              ]}>
              <Text style={[theme.layout.mockInputStyle]}>
                {endTime ? (
                  <Text style={{ color: theme.colors.text.inputText }}>
                    {`${getCurrentHourFormat({
                      timeStr: "8:00 am",
                      is24Hour: langState.is24Hour,
                      trimModifier: true
                    })}`}
                    <Text style={[styles.periodText]}>
                      {langState.is24Hour ? "" : "AM"}
                    </Text>
                  </Text>
                ) : (
                  <Text style={{ color: theme.colors.placeholder }}>––</Text>
                )}
              </Text>
            </Pressable>
          </View>
          <View style={[{ flexDirection: "row" }]}>
            <Text
              style={[
                theme.fonts.body1,
                { flex: 1.1, color: theme.colors.text.cancel }
              ]}>
              {languageContent.night}
            </Text>
            <Text
              style={[
                theme.fonts.body1,
                { flex: 1, color: theme.colors.text.cancel }
              ]}>
              {endTime ? languageContent.morning : null}
            </Text>
          </View>
          <View style={[{ marginTop: 16, flex: 1 }]}>
            <Text style={[theme.fonts.h4]}>{languageContent.basalRate}</Text>
            <Text
              style={[
                theme.fonts.body1,
                { color: theme.colors.text.cancel, marginBottom: 8 }
              ]}>
              {languageContent.upTo}
            </Text>
          </View>
          <Pressable
            onPress={() => {
              setBasalRate(true);
            }}
            style={[theme.layout.inputContainerStyle]}>
            {basalRate ? (
              <>
                <Text
                  style={[
                    {
                      ...theme.layout.inputStyle
                    }
                  ]}>
                  {(0.75).toLocaleString(langState.locale)}
                </Text>
                <Text style={[theme.layout.adornment]}>
                  {languageContent.unitsHr}
                </Text>
              </>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%"
                }}>
                <Text
                  style={{
                    ...theme.layout.inputStyle,
                    color: theme.colors.placeholder
                  }}>
                  ––
                </Text>
                <Text
                  style={[
                    theme.layout.adornment,
                    {
                      color: theme.colors.placeholder
                    }
                  ]}>
                  {languageContent.unitsHr}
                </Text>
              </View>
            )}
          </Pressable>
        </View>
      </ScrollView>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.next}
        rightAction={() => {
          if (endTime === "" || !basalRate) {
            return;
          }
          navigate("BasalSegmentTwo");
        }}
        rightActionStyle={{
          color:
            endTime === "" || !basalRate
              ? theme.colors.placeholder
              : theme.colors.purple
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  periodText: {
    color: theme.colors.text.cancel,
    fontSize: 18
  }
});

export default BasalSegmentOneScreen;
