import PickerItem from "../types/pickerItem";

const BASAL_RATES_DATA_WITH_ZERO: Array<PickerItem> = [];

for (let i = 0; i <= 30.05; i += 0.05) {
  if (i.toFixed(2).toString().slice(-2) === "00") {
    BASAL_RATES_DATA_WITH_ZERO.push({
      key: i.toFixed(2),
      label: i.toFixed(2).toString().slice(0, -3),
      value: Number(i.toFixed(2)),
      index: i
    });
  } else {
    BASAL_RATES_DATA_WITH_ZERO.push({
      key: i.toFixed(2),
      label: i.toFixed(2),
      value: Number(i.toFixed(2)),
      index: i
    });
  }
}

export default BASAL_RATES_DATA_WITH_ZERO;
