import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  headerTitle: {
    id: 'ios.glucose-goal-range.header-title',
    defaultMessage: 'Glucose Goal Range',
  },
  text: {
    id: 'ios.glucose-goal-range.text',
    defaultMessage:
      'These limits are used in Glucose history displays and in Glucose reading statistics.',
  },
  upperLimitInputLabel: {
    id: 'ios.glucose-goal-range.uppper-limit.input-label',
    defaultMessage: 'Upper Limit',
  },
  upperLimitValueInputLabel: {
    id: 'ios.glucose-goal-range.uppper-limit-value.input-label',
    defaultMessage: '200 mg/dL',
  },
  upperLimitPlaceholder: {
    id: 'ios.glucose-goal-range.uppper-limit.placeholder',
    defaultMessage: '(max of 200 mg/dL)',
  },
  lowerLimitInputLabel: {
    id: 'ios.glucose-goal-range.lower-limit.input-label',
    defaultMessage: 'Lower Limit',
  },
  lowerLimitValueInputLabel: {
    id: 'ios.glucose-goal-range.lower-limit-value.input-label',
    defaultMessage: '100 mg/dL',
  },
  lowerLimitPlaceholder: {
    id: 'ios.glucose-goal-range.lower-limit.placeholder',
    defaultMessage: '(min of 70 mg/dL)',
  },
  saveBtn: {
    id: 'ios.glucose-goal-range.button',
    defaultMessage: 'Save',
  },
});
