import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { ScrollView } from "react-native";
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View
} from "react-native-web";
import { useSelector } from "react-redux";
import segmentTwoFilledImage from "../../../../../../../../assets/segment2_filled.png";
import segmentTwoTimeImage from "../../../../../../../../assets/segment2_time_before.png";
import segmentTwoFilledImage24 from "../../../../../../../../assets/segment2_filled_24.png";
import segmentTwoFilledImageComma24 from "../../../../../../../../assets//segment2_filled_comma.png";
import segmentTwoTimeImage24 from "../../../../../../../../assets/segment2_time_before_24.png";
import segmentTwoTimeImageComma24 from "../../../../../../../../assets/segment3_empty_comma.png";

import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { BasalState } from "../../../../../../../../web/store/reducers/basal";
import { UserState } from "../../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import {
  BottomInnerNav,
  ConfirmationModal,
  SegmentTopBar
} from "../../../../../molecules";
import { strings } from "./CustomProgramSegmentTwoScreenContent";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat";

const CustomProgramSegmentTwoScreen: React.FC = () => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const [basalRate, setBasalRate] = React.useState<boolean>(false);
  const [endTime, setEndTime] = React.useState<string>("");

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const startTime = "8:00";

  const getGraphImage = () => {
    if (endTime) {
      return langState.is24Hour
        ? langState.locale === "de-DE"
          ? segmentTwoTimeImageComma24
          : segmentTwoTimeImage24
        : segmentTwoTimeImage;
    }

    return langState.is24Hour
      ? langState.locale === "de-DE"
        ? segmentTwoFilledImageComma24
        : segmentTwoFilledImage24
      : segmentTwoFilledImage;
  };

  return (
    <View
      style={{
        backgroundColor: "#fff",
        height: "100%"
      }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View>
          <SegmentTopBar
            programName={basalState.customProgramName}
            segmentNum="2"
            showRightUnits
          />
          <View style={[styles.break]} />
          <Image
            style={{
              alignSelf: "center",
              height: 161,
              width: "96%"
            }}
            resizeMode="contain"
            source={getGraphImage()}
          />
          <View style={[styles.break, { marginBottom: 16 }]} />
          <View style={[{ paddingVertical: 16 }]}>
            <View style={[{ flexDirection: "row", paddingHorizontal: 16 }]}>
              <Text style={[theme.fonts.h4, { flex: 1.1 }]}>
                {languageContent.start}
              </Text>
              <Text style={[theme.fonts.h4, { flex: 1 }]}>
                {languageContent.end}
              </Text>
            </View>
            <View
              style={[
                {
                  flexDirection: "row",
                  width: "100%",
                  paddingLeft: 8,
                  paddingHorizontal: 14
                }
              ]}>
              <View
                style={[
                  {
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingVertical: 8,
                    flex: 1.2
                  }
                ]}>
                <Text style={[{ fontSize: 32, paddingTop: 8 }]}>
                  {startTime} <Text style={[styles.periodText]}>AM</Text>
                </Text>
                <View style={[{ alignSelf: "center" }]}>
                  <Text style={[{ fontSize: 32 }]}>{" - "}</Text>
                </View>
              </View>
              <TouchableOpacity
                onPress={() => {
                  setEndTime("6:00");
                }}
                style={[
                  theme.layout.mockInput,
                  { flex: 1, paddingVertical: 8 }
                ]}>
                <Text style={[theme.layout.mockInputStyle]}>
                  {endTime ? (
                    <Text style={{ color: theme.colors.text.inputText }}>
                      {`${getCurrentHourFormat({
                        timeStr: "6:00 pm",
                        is24Hour: langState.is24Hour,
                        trimModifier: true
                      })}`}
                      <Text style={[styles.periodText]}>
                        {langState.is24Hour ? "" : "AM"}
                      </Text>
                    </Text>
                  ) : (
                    <Text style={{ color: theme.colors.placeholder }}>––</Text>
                  )}
                </Text>
              </TouchableOpacity>
            </View>
            <View style={[{ flexDirection: "row", marginTop: 8 }]}>
              <Text style={[theme.fonts.body1, styles.startTimeHelpText]}>
                {languageContent.morning}
              </Text>
              <Text style={[theme.fonts.body1, styles.endTimeHelpText]}>
                {endTime ? languageContent.night : null}
              </Text>
            </View>
            <View style={[{ marginTop: 8, paddingHorizontal: 16 }]}>
              <Text style={[theme.fonts.h4]}>{languageContent.basalRate}</Text>
              <Text
                style={[
                  theme.fonts.body1,
                  { color: theme.colors.grayScale.gray400, marginBottom: 8 }
                ]}>
                {`(${languageContent.upTo})`}
              </Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                setBasalRate(true);
                /* setShowModal(true); */
              }}
              style={[theme.layout.inputContainerStyle, styles.inputContainer]}>
              {basalRate ? (
                <>
                  <Text style={[theme.layout.inputStyle]}>1</Text>
                  <Text style={[theme.layout.adornment]}>
                    {languageContent.unitsHr}
                  </Text>
                </>
              ) : (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%"
                  }}>
                  <Text
                    style={{
                      color: theme.colors.placeholder,
                      marginLeft: 8,
                      fontSize: 40
                    }}>
                    ––
                  </Text>
                  <Text
                    style={[
                      theme.layout.adornment,
                      {
                        color: theme.colors.placeholder
                      }
                    ]}>
                    {languageContent.unitsHr}
                  </Text>
                </View>
              )}
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.next}
        rightAction={() => {
          if (endTime === "" || !basalRate) {
            return;
          }
          navigate("CustomProgramSegmentThree");
        }}
        rightActionStyle={{
          color:
            endTime === "" || !basalRate
              ? theme.colors.placeholder
              : theme.colors.purple
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  periodText: {
    color: theme.colors.grayScale.gray400,
    fontSize: 18
  },
  endTimeHelpText: {
    flex: 1,
    color: theme.colors.text.cancel,
    paddingHorizontal: 16
  },
  startTimeHelpText: {
    flex: 1.1,
    color: theme.colors.text.cancel,
    paddingLeft: 16
  },
  inputContainer: {
    marginHorizontal: 16,
    paddingLeft: 8,
    height: 60,
    paddingBottom: 2
  }
});

export default CustomProgramSegmentTwoScreen;
