let strings = {
  "en-US": {
    sounds: "Sound",
    dontSilenceUS:
      "If you silence your device, the Omnipod\u00A05 app will still sound for critical alarms and urgent low glucose. Your Pod will not be silenced.",
    dontSilenceIntl:
      "If you silence your device, the Omnipod\u00A05 app will still sound for critical alarms and urgent low glucose. Your Pod will not be silenced.",
    dontSilence2:
      "The Pod will still sound, and you will be able to see the alarm or notification in the App.",
    dontSilence2Intl:
      "The Pod will still sound, and you will be able to see the alarm or notification in the Controller.",
    buttonLabel: "I Understand"
  },
  "en-GB": {
    sounds: "Sound",
    dontSilenceUS:
      "If you silence your device, the Omnipod\u00A05 app will still sound for critical alarms and urgent low glucose. Your Pod will not be silenced.",
    dontSilenceIntl:
      "If you silence your device, the Omnipod\u00A05 app will still sound for critical alarms and urgent low glucose. Your Pod will not be silenced.",
    dontSilence2:
      "The Pod will still sound, and you will be able to see the alarm or notification in the App.",
    dontSilence2Intl:
      "The Pod will still sound, and you will be able to see the alarm or notification in the App.",
    buttonLabel: "I Understand"
  },
  "de-DE": {
    sounds: "Ton",
    dontSilenceUS:
      "Vermeiden Sie es, Ihr Steuergerät oder Ihr Smartphone auf „Stumm“, „Vibration“ oder andere Einstellungen zu setzen, die verhindern, dass Sie Alarme oder Benachrichtigungen von Ihrer Omnipod 5\u00A0App hören.\n\nDer Pod gibt trotzdem einen Ton aus, und Sie können den Alarm oder die Meldung in der App sehen.",
    dontSilenceIntl:
      "Vermeiden Sie es, Ihr Steuergerät auf „Stumm“, „Vibration“ oder andere Einstellungen zu setzen, die verhindern, dass Sie Alarme oder Benachrichtigungen von Ihrem Steuergerät hören",
    dontSilence2:
      "Der Pod gibt trotzdem einen Ton aus, und Sie können den Alarm oder die Meldung in der App sehen.",
    dontSilence2Intl:
      "Der Pod gibt trotzdem einen Ton aus, und Sie können den Alarm oder die Meldung auf dem Steuergerät sehen.",
    buttonLabel: "Ich verstehe"
  },
  "es-US": {
    sounds: "Sonido",
    dontSilenceUS:
      "Evite configurar su controlador o teléfono inteligente en modo Silencio, Vibración o cualquier otro ajuste que le impida oír alarmas o notificaciones de su aplicación Omnipod 5. \n\n El pod sonará de todas formas, y podrá ver la alarma o notificaión en la aplicación.",
    dontSilenceIntl:
      "Evite ajustar el controlador a Silencio, Vibración o cualquier otro ajuste que le impida escuchar las alarmas o notificaciones de su Controlador.",
    dontSilence2:
      "El Pod seguirá sonando y podrá ver la alarma o notificación en la aplicación.",
    dontSilence2Intl:
      "El Pod seguirá sonando y podrá ver la alarma o notificación en el Controlador.",
    buttonLabel: "Entiendo"
  },
  "fr-FR": {
    sounds: "Son",
    dontSilenceUS:
      "Évitez de régler votre contrôleur ou votre smartphone sur Silencieux, Vibreur ou sur tout autre réglage qui vous empêche d’entendre les alarmes ou les notifications de votre application Omnipod 5.",
    dontSilenceIntl:
      "Évitez de régler votre contrôleur sur Silencieux, Vibreur ou sur tout autre réglage qui vous empêche d’entendre les alarmes ou les notifications de votre contrôleur.",
    dontSilence2:
      "Le Pod émettra toujours un son, et vous pourrez voir l'alarme de danger ou la notification dans l'application.",
    dontSilence2Intl:
      "Le Pod émettra toujours un son, et vous pourrez voir l'alarme de danger ou la notification dans le contrôleur.",
    buttonLabel: "J'ai compris"
  },
  "nl-NL": {
    sounds: "Geluid",
    dontSilenceUS:
      "Zet uw controller of smartphone niet op stil, trillen of een andere instelling die verhindert dat u alarmen of meldingen van uw Omnipod 5 app hoort.",
    dontSilenceIntl:
      "Zet uw controller of smartphone niet op stil, trillen of een andere instelling die verhindert dat u alarmen of meldingen van uw controller.",
    dontSilence2:
      "De Pod zal nog steeds afgaan en u zult het alarm of de kennisgeving in de App kunnen zien.",
    dontSilence2Intl:
      "De Pod zal nog steeds afgaan en u zult het alarm of de kennisgeving in de controller kunnen zien.",
    buttonLabel: "Begrepen"
  }
};

export { strings };
