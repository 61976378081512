import { createSlice } from '@reduxjs/toolkit';

export interface Segment {
  id: number;
  startTime: number;
  units?: number;
  endTime?: number;
  imageName?: string;
  noDataImage?: string;
}

export interface BasalProgram {
  id: number;
  name: string;
  unitsTotal: number;
  imageName: string;
  segments: Segment[];
  isActive: boolean;
  isNew: boolean;
}

interface BasalProgramsState {
  selectedProgram: BasalProgram | null;
  newProgram: Partial<BasalProgram>;
  programs: BasalProgram[];
}

export enum SegmentImagesEnum {
  WeekendSegment1 = 'weekend-segment-1',
  WeekendSegment2 = 'weekend-segment-2',
  RelaxDaySegment1Empty = 'relax-day-segment-1-empty',
  RelaxDaySegment1 = 'relax-day-segment-1',
  RelaxDaySegment2Empty = 'relax-day-segment-2-empty',
  RelaxDaySegment2 = 'relax-day-segment-2',
  RelaxDaySegments = 'relax-day-segments',
  WeekendSegments = 'weekend-segments',
}

// This is our defined second Basal Program.
export const relaxDayData: BasalProgram = {
  id: 2,
  name: 'Relax Day', // @ToDo: find a way to translate this in the future.
  unitsTotal: 21,
  imageName: 'relax-day-segments',
  segments: [
    {
      id: 1,
      units: 1,
      startTime: 24,
      endTime: 8,
      imageName: SegmentImagesEnum.RelaxDaySegment1,
      noDataImage: SegmentImagesEnum.RelaxDaySegment1Empty,
    },
    {
      id: 2,
      units: 0.75,
      startTime: 8,
      endTime: 24,
      imageName: SegmentImagesEnum.RelaxDaySegment2,
      noDataImage: SegmentImagesEnum.RelaxDaySegment2Empty,
    },
  ],
  isActive: false,
  isNew: true,
};

const initialState: BasalProgramsState = {
  selectedProgram: null,
  newProgram: {},
  programs: [
    {
      id: 1,
      name: 'Weekend', // @ToDo: find a way to translate this in the future.
      unitsTotal: 21,
      imageName: 'weekend-segments',
      segments: [
        {
          id: 1,
          units: 0.75,
          startTime: 24,
          endTime: 8,
          imageName: SegmentImagesEnum.WeekendSegment1,
        },
        {
          id: 2,
          units: 1,
          startTime: 8,
          endTime: 24,
          imageName: SegmentImagesEnum.WeekendSegment2,
        },
      ],
      isActive: true,
      isNew: false,
    },
  ],
};

const basalProgramsSlice = createSlice({
  name: 'basalPrograms',
  initialState,
  reducers: {
    addProgram: (state, action) => {
      const { program }: { program: BasalProgram } = action.payload;
      state.programs.push({ ...program, isNew: true, isActive: false });
    },

    deleteProgram: (state, action) => {
      const { id }: { id: number } = action.payload;
      if (id) {
        const newPrograms = state.programs.filter(
          (program) => program.id !== id
        );
        state.programs = [...newPrograms];
        basalProgramsSlice.caseReducers.setActiveProgram(state, {
          ...action,
          payload: { id: 1 },
        });
      }
    },

    setActiveProgram: (state, action) => {
      const { id }: { id: number } = action.payload;
      if (id) {
        state.programs.forEach((program) => {
          if (program.isActive && program.id !== id) {
            program.isActive = false;
          }

          if (program.id === id) {
            program.isActive = true;
          }
        });
      }
    },
    setSelectedProgram: (state, action) => {
      const { id }: { id: number } = action.payload;
      const program = state.programs.find((program) => program.id === id);
      if (program) {
        state.selectedProgram = program;
      }
    },
    updateNewProgram: (state, action) => {
      const { programData }: { programData: Partial<BasalProgram> } =
        action.payload;
      state.newProgram = {
        ...state.newProgram,
        ...programData,
        id: state.programs.length + 1,
      };
    },

    addNewSegment: (state, action) => {
      const {
        programId,
        segmentDefaults,
      }: { programId: number; segmentDefaults: Partial<Segment> } =
        action.payload;
      const segments = state.newProgram.segments || [];

      segments.push({
        id: segments.length + 1,
        startTime: segmentDefaults.startTime || 24,
      });

      state.newProgram = {
        ...state.newProgram,
        segments: [...segments],
      };
    },
    clearNewProgram: (state) => {
      state.newProgram = {};
    },
    updateProgramSegment: (state, action) => {
      const {
        programId,
        segmentData,
        isNew,
      }: { programId: number; segmentData: Segment; isNew: boolean } =
        action.payload;
      const currentProgram = !isNew
        ? state.programs.find((program) => program.id === programId)
        : state.newProgram;

      if (currentProgram) {
        const currentSegment = currentProgram.segments?.find(
          (segment) => segment.id === segmentData.id
        );

        if (currentSegment) {
          currentSegment.startTime = segmentData.startTime;
          currentSegment.endTime = segmentData.endTime;
          currentSegment.units = segmentData.units;
          currentSegment.imageName = segmentData.imageName;
        }
      }
    },
  },
});

export const {
  addProgram,
  deleteProgram,
  setActiveProgram,
  setSelectedProgram,
  updateNewProgram,
  addNewSegment,
  clearNewProgram,
  updateProgramSegment,
} = basalProgramsSlice.actions;
export const reducer = basalProgramsSlice.reducer;
