import { defineMessages } from 'react-intl';

export const messages = {
  sceneOne: defineMessages({
    title: {
      id: 'l2.how-to-apply.scene-one.title',
      defaultMessage: 'STEP 1',
    },
    mainText: {
      id: 'l2.how-to-apply.scene-one.main-text',
      defaultMessage: 'Select site on back of upper arm.',
    },
    subText: {
      id: 'l2.how-to-apply.scene-one.sub-text',
      defaultMessage:
        'Note: Avoid scars, moles, stretch marks, lumps, and insulin injection sites. To prevent skin irritation, rotate sites between applications.',
    },
  }),
  sceneTwo: defineMessages({
    title: {
      id: 'l2.how-to-apply.scene-two.title',
      defaultMessage: 'STEP 2',
    },
    mainText: {
      id: 'l2.how-to-apply.scene-two.main-text',
      defaultMessage:
        'Wash site using a plain soap, dry, and then clean with alcohol wipe. Allow site to air dry before proceeding.',
    },
  }),
  sceneThree: defineMessages({
    title: {
      id: 'l2.how-to-apply.scene-three.title',
      defaultMessage: 'STEP 3',
    },
    mainText: {
      id: 'l2.how-to-apply.scene-three.main-text',
      defaultMessage: 'Peel lid completely off sensor pack.',
    },
  }),
  sceneFour: defineMessages({
    title: {
      id: 'l2.how-to-apply.scene-four.title',
      defaultMessage: 'STEP 4',
    },
    mainText: {
      id: 'l2.how-to-apply.scene-four.main-text',
      defaultMessage: 'Unscrew cap from Sensor Applicator.',
    },
  }),
  sceneFive: defineMessages({
    title: {
      id: 'l2.how-to-apply.scene-five.title',
      defaultMessage: 'STEP 5',
    },
    mainText: {
      id: 'l2.how-to-apply.scene-five.main-text',
      defaultMessage:
        'Line up dark mark on Sensor Applicator with dark mark on sensor park. On a hard surface, press down firmly on Sensor Applicator until it comes to a stop.',
    },
  }),
  sceneSix: defineMessages({
    title: {
      id: 'l2.how-to-apply.scene-six.title',
      defaultMessage: 'STEP 6',
    },
    mainText: {
      id: 'l2.how-to-apply.scene-six.main-text',
      defaultMessage: 'Lift Sensor Applicator out of sensor pack.',
    },
  }),
  sceneSeven: defineMessages({
    title: {
      id: 'l2.how-to-apply.scene-seven.title',
      defaultMessage: 'STEP 7',
    },
    mainText: {
      id: 'l2.how-to-apply.scene-seven.main-text',
      defaultMessage:
        'Sensor Applicator is ready to apply Sensor. Do not touch inside Sensor Applicator or put it back into Sensor Pack.',
    },
  }),
  sceneEight: defineMessages({
    title: {
      id: 'l2.how-to-apply.scene-eight.title',
      defaultMessage: 'STEP 8',
    },
    mainText: {
      id: 'l2.how-to-apply.scene-eight.main-text',
      defaultMessage:
        'Place Sensor Applicator over site.<p>Push down firmly to apply Sensor.</p><p2>Do not push down on Sensor Applicator until placed over prepared site to prevent unintended results or injury.</p2>',
    },
  }),
  sceneNine: defineMessages({
    title: {
      id: 'l2.how-to-apply.scene-nine.title',
      defaultMessage: 'STEP 9',
    },
    mainText: {
      id: 'l2.how-to-apply.scene-nine.main-text',
      defaultMessage: 'Gently pull sensor applicator away from your body.',
    },
  }),
  sceneTen: defineMessages({
    title: {
      id: 'l2.how-to-apply.scene-ten.title',
      defaultMessage: 'STEP 10',
    },
    mainText: {
      id: 'l2.how-to-apply.scene-ten.main-text',
      defaultMessage:
        'Make sure sensor is secure. Discard used Sensor Applicator and Sensor Pack according to local regulations.',
    },
  }),
};
