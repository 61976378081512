import React from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  BackHandler
} from "react-native";
import { isEqual } from "lodash";
import theme from "../../../../theme";
import { useNavigation } from "@react-navigation/native";
import { ConfirmationModal } from "../../../molecules";
import {
  LangState,
  languageActions
} from "../../../../../../web/store/reducers/language";
import { useDispatch, useSelector } from "react-redux";
import COUNTRIES from "../../../../data/countries";
import { CountryType } from "../../../../types/country";
import { getInsulinUnitsByCountry } from "../../../../helpers/getInsulinUnitsByCountry";
import { RootState } from "../../../../../../web/store/store";

const MainCountrySelectScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [country, setCountry] = React.useState<CountryType>("United States");
  const [flag, setFlag] = React.useState<any>(<Text>&#127482;&#127480;</Text>);
  const [showCountrySelector, setShowCountrySelector] =
    React.useState<boolean>(false);
  const [showUOMDisclaimer, setShowUOMDisclaimer] =
    React.useState<boolean>(false);

  const { navigate } = useNavigation();
  const dispatch = useDispatch();

  const getFlag = (country: CountryType) => {
    return {
      "United States": <Text>&#127482;&#127480;</Text>,
      "United Kingdom": <Text>&#127468;&#127463;</Text>,
      Germany: <Text>&#127465;&#127466;</Text>,
      Netherlands: <Text>&#127475;&#127473;</Text>,
      "France": <Text>&#127482;&#127480;</Text>,
    }[country];
  };

  React.useEffect(() => {
    if (country === "Germany") {
      setShowUOMDisclaimer(true);
    }
  }, [country]);

  React.useEffect(() => {
    const androidBack = BackHandler.addEventListener(
      "hardwareBackPress",
      () => true
    );

    return () => androidBack.remove();
  }, []);

  return (
    <View style={[theme.layout.wrapper]}>
      <View style={[styles.innerWrapper]}>
        <Text
          style={[
            theme.fonts.h3,
            { margin: 16, textAlign: "center", fontWeight: "400" }
          ]}>
          Select Country
        </Text>
        <TouchableOpacity
          onPress={() => {
            setShowCountrySelector(true);
          }}>
          <Text
            style={[
              styles.textInput,
              theme.fonts.text200,
              styles.inputContent
            ]}>
            {flag} {country}
          </Text>
        </TouchableOpacity>
        <View style={{ paddingBottom: 16, paddingHorizontal: 16 }}>
          <TouchableOpacity
            onPress={() => {
              dispatch(
                languageActions.languageUpdate(
                  {
                    country: country,
                    permanentCountry: country,
                    units: getInsulinUnitsByCountry(country) as
                      | "mg/dL"
                      | "mmol/L"
                  },
                  langState
                )
              );
              navigate("SelectLanguage");
            }}
            style={{
              backgroundColor: theme.colors.purple,
              padding: 12,
              borderRadius: 18
            }}>
            <Text
              style={{
                color: theme.colors.white,
                textAlign: "center",
                fontWeight: "bold"
              }}>
              {"Next".toUpperCase()}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <ConfirmationModal
        isVisible={showCountrySelector}
        hideTitle={true}
        hideDismiss={true}
        hideConfirm={true}
        hideFooter={true}
        languageSelectScreen={true}
        onDismiss={() => setShowCountrySelector(false)}
        message={
          <View>
            {COUNTRIES.map(
              (
                node: { label: CountryType; value: CountryType },
                index: number
              ) => (
                <TouchableOpacity
                  key={node.value}
                  style={{
                    backgroundColor: theme.colors.textInputBackgroundColor,
                    borderRadius: 8,
                    marginBottom: 6
                  }}
                  onPress={() => {
                    setCountry(node.value);
                    setFlag(getFlag(node.value));
                    setShowCountrySelector(false);
                  }}>
                  <Text
                    style={[
                      theme.fonts.h4,
                      {
                        fontWeight: "normal",
                        borderColor:
                          country === node.value
                            ? theme.colors.purple
                            : "transparent",
                        borderWidth: 1.5,
                        borderRadius: 8,
                        padding: 12
                      }
                    ]}>
                    {getFlag(node.value)} {node.value}
                  </Text>
                </TouchableOpacity>
              )
            )}
          </View>
        }
      />
      <ConfirmationModal
        isVisible={showUOMDisclaimer}
        hideDismiss
        confirmText={"Ok"}
        onConfirm={() => setShowUOMDisclaimer(false)}
        title={"Unit Selection"}
        message={
          <Text style={[theme.fonts.body1]}>
            For simulation purposes only, you can choose your units of measure.
            The actual Omnipod®&nbsp;5 app will have separate versions for
            mg/&#8288;dL and mmol/L to match the user's prescription.
          </Text>
        }
      />
    </View>
  );
};

const height = Dimensions.get("window").height;

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    width: Dimensions.get("screen").width,
    padding: 16,
    marginTop: height < 800 ? height / 40 : height / 20
  },
  textInput: {
    margin: 16,
    padding: 16,
    fontWeight: "700",
    borderWidth: 1,
    borderRadius: 6,
    textAlign: "center",
    backgroundColor: theme.colors.textInputBackgroundColor
  },
  inputContent: {
    borderColor: theme.colors.textInputBorder,
    fontWeight: "400",
    color: theme.colors.pureBlack,
    textAlign: "left"
  }
});

export default MainCountrySelectScreen;
