let strings = {
  "en-US": {
    inProgress: "In progress",
    total: "Total: %1 U/day",
    edit: "Edit",
    savedBasal: "Saved Basal Programs",
    deleteProgram: "Delete %2?",
    delete: "Delete",
    sureDelete: "Are you sure you want to delete the",
    basalProgram: "Basal Program?",
    cannotBeUndone: "This cannot be undone",
    cancel: "Cancel",
    createNew: "Create New",
    startThe: "Start the %3 Basal Program?",
    start: "Start",
    pauseInsulinTitle: "Pause insulin?",
    pauseInsulinMessage:
      "You must pause insulin to edit the Basal Program in progress.",
    pauseInsulinMessageTwo:
      "Pausing insulin cancels any temp basal or extended bolus currently in progress.",
    yes: "Yes",
    no: "No",
    pleaseWait: "Please wait..."
  },
  "en-GB": {
    inProgress: "In progress",
    total: "Total: %1 U/day",
    edit: "Edit",
    savedBasal: "Saved Basal Programmes",
    deleteProgram: "Delete %2?",
    delete: "Delete",
    sureDelete: "Are you sure you want to delete the",
    basalProgram: "Basal Programme?",
    cannotBeUndone: "This cannot be undone",
    cancel: "Cancel",
    createNew: "Create New",
    startThe: "Start the %3 Basal Programme?",
    start: "Start",
    pauseInsulinTitle: "Pause insulin?",
    pauseInsulinMessage:
      "You must pause insulin to edit the Basal Programme in progress.",
    pauseInsulinMessageTwo:
      "Pausing insulin cancels any temp basal or extended bolus currently in progress.",
    yes: "Yes",
    no: "No",
    pleaseWait: "Please wait..."
  },
  "de-DE": {
    inProgress: "In Bearbeitung",
    total: "Gesamt: %1 E/Tag",
    edit: "Bearbeiten",
    savedBasal: "Gespeicherte Basalratenprofile",
    deleteProgram: "%2 löschen?",
    delete: "Löschen",
    sureDelete: "Möchten Sie wirklich das",
    basalProgram: "Basalratenprofil löschen?",
    cannotBeUndone: "Dieser Vorgang kann nicht rückgängig gemacht werden",
    cancel: "Abbr.",
    createNew: "Neu erstellen",
    startThe: "Basalratenprofil %3 starten?",
    start: "Start",
    pauseInsulinTitle: "Insulin unterbrechen?",
    pauseInsulinMessage:
      "Sie müssen das Insulin unterbrechen, um das laufende Basalratenprofil zu bearbeiten.",
    pauseInsulinMessageTwo:
      "Die Unterbrechung der Insulinabgabe führt zum Abbruch einer laufenden temporären Basalrate oder eines laufenden verzögerten Bolus.",
    yes: "Ja",
    no: "Nein",
    pleaseWait: "Bitte warten..."
  },
  "es-US": {
    inProgress: "En progreso",
    total: "Total: %1 U/día",
    edit: "Editar",
    savedBasal: "Programas basales guardados",
    deleteProgram: "¿Eliminar %2?",
    delete: "Eliminar",
    sureDelete: "¿Está seguro de que quiere eliminar el",
    basalProgram: "programa basal?",
    cannotBeUndone: "Esta acción no se puede deshacer",
    cancel: "Cancelar",
    createNew: "Crear nuevo",
    startThe: "Iniciar el programa basal %3?",
    start: "Iniciar",
    pauseInsulinTitle: "¿Pausar la insulina?",
    pauseInsulinMessage:
      "Debe pausar la insulina para editar el programa basal actual.",
    pauseInsulinMessageTwo:
      "Pausar la insulina cancela el bolo basal temporal o extendido actualmente en proceso.",
    yes: "Sí",
    no: "No"
  },
  "fr-FR": {
    inProgress: "En cours",
    total: "Total : %1 U/jour",
    edit: "Modifier",
    savedBasal: "Programmes basaux enregistrés",
    deleteProgram: "Supprimer %2 ?",
    delete: "Supprimer",
    sureDelete: "Voulez-vous vraiment supprimer le",
    basalProgram: "programme basal ?",
    cannotBeUndone: "Ceci est irréversible",
    cancel: "Annuler",
    createNew: "Créer nouveau",
    startThe: "Démarrer le programme basal %3 ?",
    start: "Démarrer",
    pauseInsulinTitle: "Mettre en pause l'administration d'insuline ?",
    pauseInsulinMessage:
      "Vous devez mettre en pause l'administration d'insuline pour modifier le programme basal en cours.",
    pauseInsulinMessageTwo:
      "La mise en pause de l'administration d'insuline annule le débit basal temporaire ou le bolus prolongé en cours.",
    yes: "Oui",
    no: "Non"
  },
  "nl-NL": {
    inProgress: "Bezig",
    total: "Totaal: %1 E/dag",
    edit: "Bewerken",
    savedBasal: "Opgeslagen basaalprogramma's",
    deleteProgram: "Verwijderen %2?",
    delete: "Verwijderen",
    sureDelete: "Weet u zeker dat u het",
    basalProgram: "Basaalprogramma wilt verwijderen?",
    cannotBeUndone: "Dit kan niet ongedaan worden gemaakt.",
    cancel: "Annuleren",
    createNew: "Nieuwe maken",
    startThe: "Het  basaalprogramma starten?",
    start: "Start",
    pauseInsulinTitle: "Insuline pauzeren?",
    pauseInsulinMessage:
      "Om het actieve basaalprogramma te bewerken, moet u de insuline pauzeren.",
    pauseInsulinMessageTwo:
      "Door de insuline te pauzeren, wordt de tijdelijke basaalsnelheid of verlengde bolus die momenteel bezig is geannuleerd.",
    yes: "Ja",
    no: "Nee"
  }
};

export { strings };
