import { useNavigation } from '@react-navigation/native';
import { Button } from 'web/components/ios/Button';
import { FormattedMessage } from 'react-intl';
import { messages, confirmTempBasalMessages } from '../TempBasal.messages';
import confirmTempBasal from 'assets/ios/confirm-temp-basal.svg';
import { AppRoutesEnum } from 'web/routes/types';
import {
  showModal,
  setCurrentModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import { useDispatch } from 'react-redux';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';
import { setTempBasal } from 'web/store/reducers/user/actions';
import { ConfirmTempBasalModal } from 'web/components/ios/modals/TempBasalModals/TempBasalModals';
import { ChevronRight } from 'web/components/ios/icons';

const TARGET_HOURS = 9;

export const ConfirmTempBasal: React.FC = () => {
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const futureDate = new Date().setHours(new Date().getHours() + TARGET_HOURS);

  const handlePodCommTimeout = () => {
    dispatch(hideModal());
    dispatch(setTempBasal({ endDate: new Date(futureDate) }));
    dispatch(navigateToRoute(AppRoutesEnum.dashboard));
  };

  const handleOnConfirm = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <ConfirmTempBasalModal handlePodCommTimeout={handlePodCommTimeout} />
        ),
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-3/12 p-6 pt-8 overflow-hidden">
        <img src={confirmTempBasal} alt="temp-basal-complete-segments" />
      </div>
      <div className="basis-9/12">
        <div className="w-full h-full flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-center w-full pb-1 mb-1 border-b border-b-ios-gray-7 hover:cursor-pointer">
              <div className="text-sm font-semibold pl-6 text-ios-gray-9 opacity-[.63]">
                <p>
                  <FormattedMessage
                    {...confirmTempBasalMessages.changeBasalRateLabel}
                  />
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                navigate(AppRoutesEnum.SetTempBasal);
              }}
              className="flex justify-between items-center w-full pb-1 mb-1 border-b border-b-ios-gray-7 hover:cursor-pointer"
            >
              <div className="pl-6">
                <p className="text-regular">
                  <FormattedMessage
                    {...confirmTempBasalMessages.changeBasalRateRowValue}
                  />
                </p>
              </div>
              <div className="pr-6">
                <ChevronRight color="fill-ios-secondaryGray opacity-30" />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full p-6">
            <Button
              onClick={() => handleOnConfirm()}
              colorMode="manual"
              variant="colored"
            >
              <FormattedMessage {...messages.startBtn} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
