let strings = {
  'en-US': {
    automatedMode: 'Automated',
    manualMode: 'Manual',
    limitedMode: 'Limited',
  },
  'en-GB': {
    automatedMode: 'Automated',
    manualMode: 'Manual',
    limitedMode: 'Limited',
  },
  'de-DE': {
    automatedMode: 'Automatisiert',
    manualMode: 'Manuell',
    limitedMode: 'Eingeschränkt',
  },
  'es-US': {
    automatedMode: 'Automático',
    manualMode: 'Manual',
    limitedMode: 'Limitado',
  },
  'nl-NL': {
    automatedMode: 'Geautomatiseerd',
    manualMode: 'Handmatig',
    limitedMode: 'Beperkt',
  },
  'fr-FR': {
    automatedMode: 'Automatisé',
    manualMode: 'Manuel',
    limitedMode: 'Limité',
  },
};

export { strings };
