import EXPERT_BASAL_HOURS_DATA from "../data/ExpertBasalSegDurationData";

const findExpertBasalStartTimeLabel = (newStartTimeIndex: number) => {
  return EXPERT_BASAL_HOURS_DATA.find(
    (EXPERT_BASAL_HOURS_DATA) =>
      EXPERT_BASAL_HOURS_DATA.index === newStartTimeIndex + 1
  )!.label;
};

export default findExpertBasalStartTimeLabel;
