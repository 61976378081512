let strings = {
  'en-US': {
    title: 'Sensor selection confirmed',
    addSensor: 'Do you want to add a ',
    addSensorTwo: ' to the Omnipod 5 Simulator App now?',
    add: 'Add',
    notNow: 'Not Now',
  },
  'en-GB': {
    title: 'Sensor selection confirmed',
    addSensor: 'Do you want to add a ',
    addSensorTwo: ' to the Omnipod 5 Simulator App now?',
    add: 'Add',
    notNow: 'Not Now',
  },
  'es-US': {
    title: 'Selección de Sensor confirmada',
    addSensor: '¿Quiere agregar un ',
    addSensorTwo: ' a la Aplicación Omnipod 5 Simulator ahora?',
    add: 'Agregar',
    notNow: 'No ahora',
  },
  'de-DE': {
    title: 'Sensorauswahl bestätigt ',
    addSensor: 'Möchten Sie',
    addSensorTwo: ' zur Omnipod 5 Simulator-App jetzt',
    add: 'Hinzufügen',
    notNow: 'Jetzt nicht',
  },
  'nl-NL': {
    title: 'Selectie van Sensor bevestigd',
    addSensor: 'Wil je een ',
    addSensorTwo: ' toevoegen aan de Omnipod 5 Simulator App nu?',
    add: 'Toevoegen',
    notNow: 'Niet nu',
  },
};

export { strings };
