import { isEqual } from "lodash";
import React from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import { getUnitByLocale } from "../../../../../../helpers/getUnitByLocale";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { Header, Paragraph } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./CorrectionFactorFTSScreenContent";
import { UserState } from "../../../../../../../../web/store/reducers/user";

const CorrectionFactorFTSScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { languageContent } = useLanguageContent(strings);

  const correctionFactor = getUnitByLocale(
    50,
    langState.units,
    langState.locale
  );
  const correctionFactorWithUnits = `${correctionFactor} ${langState.units}`;

  const contentVariables = [correctionFactor, correctionFactorWithUnits];

  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      <Header
        label={languageContent.setCorrect}
        styles={{ marginTop: 32, marginBottom: 24 }}
      />
      <View style={{ flex: 1, paddingHorizontal: 16 }}>
        <Paragraph
          label={languageContent.valueIndicates}
          variant="body1"
          styles={{ marginBottom: 16 }}
        />
        <Paragraph
          label={replaceWithValues(languageContent.example, contentVariables)}
          variant="body1"
          styles={{ marginBottom: 16 }}
        />
      </View>
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.cancel}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.next}
        rightActionNavigateTo={"CorrectionFactorFirstSegment"}
        rightActionStyle={{
          color: theme.colors.purple
        }}
      />
    </View>
  );
};

export default CorrectionFactorFTSScreen;
