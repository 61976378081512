import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'web/store/store';
import { BasalProgram } from './basalProgramsSlice';

export const selectBasalPrograms = createSelector(
  (state: RootState) => state.basalPrograms,
  (basalPrograms) => basalPrograms.programs
);

export const selectActiveBasalProgram = createSelector(
  (state: RootState) => state.basalPrograms,
  (basalPrograms) =>
    basalPrograms.programs.find((basalProgram) => basalProgram.isActive)
);

export const selectSelectedBasalProgram = createSelector(
  (state: RootState) => state.basalPrograms,
  (basalPrograms) => basalPrograms.selectedProgram
);

export const selectNewProgram = createSelector(
  (state: RootState) => state.basalPrograms,
  (basalPrograms) => basalPrograms.newProgram
);

export const selectBasalProgram = createSelector(
  (state: RootState, id: number) => ({
    basalPrograms: state.basalPrograms,
    id,
  }),
  ({ basalPrograms, id }) =>
    basalPrograms.programs.find((program) => program.id === id)
);

export const getCurrentSegment = (
  program: Partial<BasalProgram>,
  segmentId: number
) => {
  if (program.segments) {
    const segment = program.segments.find((seg) => seg.id === segmentId);
    if (segment) {
      return segment;
    }
  }
};
