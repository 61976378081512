import { useIntl } from 'react-intl';
import { StyleSheet, Text } from 'react-native';
import { messages } from './ScanStepsScenes.messages';
import { CarouselSlideLayout } from 'web/components/helpers/Layout/CarouselSlideLayout';
import { ReactComponent as ScanScreen1 } from 'assets/L2/HowToScanSensor_01.svg';
import { ReactComponent as ScanScreen2 } from 'assets/L2/HowToScanSensor_02.svg';
import { ReactComponent as ScanScreen3 } from 'assets/L2/HowToScanSensor_03.svg';
import { ReactComponent as ScanScreen4 } from 'assets/L2/HowToScanSensor_04.svg';

const styles = StyleSheet.create({
  paragraph: {
    marginTop: 16,
  },
});

export const ScanStep1 = () => {
  const intl = useIntl();
  // @ts-ignore TS doesn't like this, but the code below is correct.
  const formattedMessage = intl.formatMessage(messages.sceneOne.mainText, {
    p: (chunks) => <Text style={styles.paragraph}>{chunks[0]}</Text>,
  });
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneOne.title)}
      svg={<ScanScreen1 width="300" height="210" />}
      mainText={formattedMessage}
    />
  )
}

export const ScanStep2 = () => {
  const intl = useIntl();
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneTwo.title)}
      svg={<ScanScreen2 width="300" height="210" />}
      mainText={intl.formatMessage(messages.sceneTwo.mainText)}
    />
  )
}

export const ScanStep3 = () => {
  const intl = useIntl();
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneThree.title)}
      svg={<ScanScreen3 width="300" height="210" />}
      mainText={intl.formatMessage(messages.sceneThree.mainText)}
    />
  )
}

export const ScanStep4 = () => {
  const intl = useIntl();
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneFour.title)}
      svg={<ScanScreen4 width="300" height="210" />}
      mainText={intl.formatMessage(messages.sceneFour.mainText)}
    />
  )
}
