let strings = {
  "en-US": {
    programName: "Program Name",
    basal: "Basal 1",
    cancel: "cancel",
    next: "next"
  },
  "en-GB": {
    programName: "Programme Name",
    basal: "Basal 1",
    cancel: "cancel",
    next: "next"
  },
  "de-DE": {
    programName: "Profilname",
    basal: "Basalrate 1",
    cancel: "Abbr.",
    next: "Weiter"
  },
  "es-US": {
    programName: "Nombre del programa",
    basal: "Basal 1",
    cancel: "cancelar",
    next: "siguiente"
  },
  "fr-FR": {
    programName: "Nom du programme",
    basal: "Débit basal 1",
    cancel: "annuler",
    next: "suivant"
  },
  "nl-NL": {
    programName: "Programmanaam",
    basal: "Basaal 1",
    cancel: "annuleren",
    next: "volgende"
  }
};

export { strings };
