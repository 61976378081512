import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { getUnitByLocale } from "../../../../../../helpers/getUnitByLocale";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./CorrectAboveSegmentEditScreenContent";

const CorrectAboveSegmentEditScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { goBack } = useNavigation();
  const targetLowerRange = getUnitByLocale(110, langState.units);
  const targetHigherRange = `${getUnitByLocale(150, langState.units)} ${
    langState.units
  }`;
  const correctHigherRange = `${getUnitByLocale(200, langState.units)} ${
    langState.units
  }`;
  const { languageContent } = useLanguageContent(strings);

  const contentVariables = [
    targetLowerRange,
    targetHigherRange,
    correctHigherRange
  ];

  return (
    <View style={[styles.container]}>
      <View>
        <Text
          style={[
            theme.fonts.body2,
            {
              marginHorizontal: 16,
              marginVertical: 8,
              color: theme.colors.greenText
            }
          ]}>
          {`${languageContent.seg} `}
        </Text>
        <View style={[styles.break, { marginBottom: 16 }]} />
        <View style={[{ padding: 16 }]}>
          <View style={[{ flexDirection: "row" }]}>
            <Text style={[theme.fonts.h5, { flex: 1.1 }]}>
              {languageContent.start}
            </Text>
            <Text style={[theme.fonts.h5, { flex: 1 }]}>
              {languageContent.end}
            </Text>
          </View>
          <View style={[{ flexDirection: "row", width: "100%" }]}>
            <View
              style={[
                {
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flex: 1.1,
                  paddingVertical: 8
                }
              ]}>
              <Text
                style={[
                  {
                    fontSize: 32,
                    color: theme.colors.pureBlack
                  }
                ]}>
                12:00 <Text style={[styles.periodText]}>AM</Text>
              </Text>
              <View style={[{ alignSelf: "center" }]}>
                <Text style={[{ fontSize: 32 }]}>{" - "}</Text>
              </View>
            </View>
            <View style={[theme.layout.mockInput, { flex: 1, height: "100%" }]}>
              <Text style={[theme.layout.mockInputStyle]}>
                5:00 <Text style={[styles.periodText]}>AM</Text>
              </Text>
            </View>
          </View>
          <View style={[{ flexDirection: "row", marginTop: 8 }]}>
            <Text
              style={[
                theme.fonts.body2,
                { flex: 1.1, color: theme.colors.grayScale.gray400 }
              ]}>
              {languageContent.night}
            </Text>
            <Text
              style={[
                theme.fonts.body2,
                { flex: 1, color: theme.colors.grayScale.gray400 }
              ]}>
              {languageContent.night}
            </Text>
          </View>
          <View style={[{ marginTop: 16 }]}>
            <Text style={[theme.fonts.h5]}>{languageContent.targetBG}</Text>
            <Text
              style={[
                theme.fonts.body2,
                { color: theme.colors.grayScale.gray400 }
              ]}>
              {replaceWithValues(languageContent.targetTo, contentVariables)}
            </Text>
          </View>
          <View style={[theme.layout.inputContainerStyle, { marginTop: 8 }]}>
            <Text style={[theme.layout.inputStyle, { fontSize: 32 }]}>
              {getUnitByLocale(130, langState.units)}
            </Text>
            <Text style={[theme.layout.adornment, { fontSize: 14 }]}>
              {langState.units}
            </Text>
          </View>
          <View style={[{ marginTop: 16 }]}>
            <Text style={[theme.fonts.h5]}>{languageContent.correctAbove}</Text>
            <Text
              style={[
                theme.fonts.body2,
                { color: theme.colors.grayScale.gray400 }
              ]}>
              {replaceWithValues(languageContent.correctTo, contentVariables)}
            </Text>
          </View>
          <View style={[theme.layout.inputContainerStyle, { marginTop: 8 }]}>
            <Text style={[theme.layout.inputStyle, { fontSize: 32 }]}>
              {getUnitByLocale(150, langState.units)}
            </Text>
            <Text style={[theme.layout.adornment, { fontSize: 14 }]}>
              {langState.units}
            </Text>
          </View>
        </View>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.next}
        rightAction={() => goBack()}
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "white"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  periodText: {
    color: theme.colors.grayScale.gray400,
    fontSize: 12,
    marginLeft: -10
  }
});

export default CorrectAboveSegmentEditScreen;
