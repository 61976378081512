import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const PodCircleIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });

  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27.5" cy="27.4268" r="26.5" stroke="#E5E5EA" />
      <path
        className={classes}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.4976 16.4045H32.0416C35.2652 16.4045 37.8784 19.0178 37.8784 22.2413V29.7533C37.8784 35.3363 33.3525 39.8621 27.7696 39.8621C22.1867 39.8621 17.6608 35.3363 17.6608 29.7533V22.2413C17.6608 19.0178 20.274 16.4045 23.4976 16.4045ZM35.6896 22.2413C35.6896 20.2266 34.0563 18.5933 32.0416 18.5933H23.4976C21.4829 18.5933 19.8496 20.2266 19.8496 22.2413V29.7533C19.8496 34.1274 23.3955 37.6733 27.7696 37.6733C32.1437 37.6733 35.6896 34.1274 35.6896 29.7533V22.2413ZM24.0616 20.3933C22.9283 20.3933 22.0096 21.3121 22.0096 22.4453V29.7533C22.0096 32.9345 24.5884 35.5133 27.7696 35.5133C30.9508 35.5133 33.5296 32.9345 33.5296 29.7533V22.4453C33.5296 21.3121 32.6109 20.3933 31.4776 20.3933H24.0616Z"
      />
    </svg>
  );
};
