import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native-web";
import { NavigationDrawerProp } from "react-navigation-drawer";
import { useSelector } from "react-redux";
import { asyncTimeout } from "../../../helpers";
import { UserState } from "../../../../../web/store/reducers/user";
import { RootState } from "../../../../../web/store/store";
import theme from "../../../theme";

interface NavMenuItemProps {
  navigation: NavigationDrawerProp;
  label: string;
  navigateTo?: string;
  icon?: JSX.Element;
  isDisabled?: boolean;
  purple?: boolean;
  showSimWarning?: () => void;
  iconStyle?: StyleProp<ViewStyle>;
}

const NavMenuItem: React.FC<NavMenuItemProps> = ({
  navigation,
  label,
  navigateTo,
  icon,
  isDisabled,
  purple,
  showSimWarning,
  iconStyle
}) => {
  const { navigate } = useNavigation();
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  return (
    <TouchableOpacity
      style={[styles.itemContainer]}
      onPress={async () => {
        if (!userState.isExpert && showSimWarning) {
          showSimWarning();
          return;
        } else if (navigateTo) {
          navigation.closeDrawer();
          await asyncTimeout(400);
          navigate(navigateTo);
        }
      }}>
      <View
        style={[
          styles.image,
          { opacity: isDisabled ? 0.3 : 1 },
          iconStyle && iconStyle
        ]}>
        {icon && icon}
      </View>
      <Text
        style={[
          isDisabled
            ? styles.navTextDisabledBlack
            : purple && isDisabled
            ? styles.navTextDisabledPurple
            : purple
            ? styles.navText
            : styles.navTextBlack
        ]}>
        {label}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    width: "100%",
    height: 40,
    alignItems: "center",
    paddingLeft: 24
  },
  image: {
    resizeMode: "contain",
    width: "12%",
    height: "100%"
  },
  navText: {
    ...theme.fonts.text600,
    fontFamily: theme.fonts.primary.bold,
    color: theme.colors.purple,
    paddingLeft: 16,
    fontWeight: "700"
  },
  navTextBlack: {
    flex: 1,
    ...theme.fonts.text600,
    fontFamily: theme.fonts.primary.medium,
    color: theme.colors.grayScale.gray100,
    paddingLeft: 16,
    fontWeight: "700"
  },
  navTextDisabledPurple: {
    ...theme.fonts.text600,
    fontFamily: theme.fonts.primary.medium,
    paddingLeft: 16,
    fontWeight: "700",
    color: theme.colors.purple,
    opacity: 0.3
  },
  navTextDisabledBlack: {
    ...theme.fonts.text600,
    fontFamily: theme.fonts.primary.medium,
    paddingLeft: 16,
    fontWeight: "700",
    color: theme.colors.grayScale.gray100,
    opacity: 0.3
  }
});

export default NavMenuItem;
