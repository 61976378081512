let strings = {
  "en-US": {
    setupBasal: "Setup: Basal"
  },
  "en-GB": {
    setupBasal: "Setup: Basal"
  },
  "de-DE": {
    setupBasal: "Einrichten: Basalrate",
    setup: "Einrichten"
  },
  "es-US": {
    setupBasal: "Configuración: Basal",
    setup: "Configuración"
  },
  "nl-NL": {
    setupBasal: "Instellingen: Basaal",
  },
  "fr-FR": {
    setupBasal: "Configuration: débit basal"
  },
};

export { strings };
