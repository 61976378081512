import { BolusState } from './index';
import { BolusDelivery } from './reducer';

export const BOLUS_UPDATE = 'bolus_update';
export const BOLUS_INIT = 'bolus_init';
export const BOLUS_RESET = 'bolus_reset';

export const SET_BOLUS_DELIVERY_VALUES = 'set_bolus_delivery_values';

interface ActionUpdate {
  type: ActionType;
  payload: {
    bolus: Partial<BolusState>;
  };
}

interface ActionReset {
  type: ActionType;
  payload: {
    bolus: BolusState;
  };
}

interface ActionInit {
  type: ActionType;
  payload: {
    bolus: BolusState;
  };
}

interface ActionSetBolusDeliveryValues {
  type: ActionType;
  payload: {
    bolus: {
      bolusDelivery: Partial<BolusDelivery>;
    };
  };
}

type ActionType =
  | typeof BOLUS_UPDATE
  | typeof BOLUS_INIT
  | typeof BOLUS_RESET
  | typeof SET_BOLUS_DELIVERY_VALUES;

export const bolusUpdate = (
  bolusData: Partial<BolusState>,
  initialState: BolusState
): Action => {
  return {
    type: BOLUS_UPDATE,
    payload: {
      bolus: bolusData,
    },
  };
};

export const setExtendedBolus = (value: boolean) => {
  return {
    type: BOLUS_UPDATE,
    payload: {
      bolus: {
        extendedBolus: value,
      },
    },
  };
};

export const setReverseCorrection = (value: boolean) => {
  return {
    type: BOLUS_UPDATE,
    payload: {
      bolus: {
        reverseCorrection: value,
      },
    },
  };
};

export const setBolusDeliveryValues = ({
  carbs,
  glucose,
  totalBolus,
  sensorUsed,
  currentTime,
}: Partial<BolusDelivery>): ActionSetBolusDeliveryValues => ({
  type: SET_BOLUS_DELIVERY_VALUES,
  payload: {
    bolus: {
      bolusDelivery: {
        carbs,
        glucose,
        totalBolus,
        sensorUsed,
        currentTime,
      },
    },
  },
});

export const setExtendedBolusDeliveryValues = ({
  mealBolus,
  correctionBolus,
  extendedBolus,
  totalBolus,
  deliveryTime,
  isExtended,
}: Partial<BolusDelivery>): ActionSetBolusDeliveryValues => ({
  type: SET_BOLUS_DELIVERY_VALUES,
  payload: {
    bolus: {
      bolusDelivery: {
        mealBolus,
        correctionBolus,
        extendedBolus,
        totalBolus,
        deliveryTime,
        isExtended,
      },
    },
  },
});

export const startBolusDelivery = ({
  bolus,
  isExtended,
}: {
  bolus: number;
  isExtended: boolean;
}) => {
  return {
    type: SET_BOLUS_DELIVERY_VALUES,
    payload: {
      bolus: {
        bolusDelivery: {
          isInProgress: true,
          deliveryQty: bolus,
          isExtended,
        },
      },
    },
  };
};

export const bolusDeliveryComplete = ({ lastBolus }: { lastBolus: number }) => {
  return {
    type: BOLUS_UPDATE,
    payload: {
      bolus: {
        bolusDelivery: {
          isInProgress: false,
        },
        lastBolus,
      },
    },
  };
};

export const cancelBolusDelivery = () => {
  return {
    type: BOLUS_UPDATE,
    payload: {
      bolus: {
        bolusDelivery: {
          isInProgress: false,
          deliveryQty: 0,
          isExtended: false,
        },
      },
    },
  };
};

export type Action =
  | ActionUpdate
  | ActionReset
  | ActionInit
  | ActionSetBolusDeliveryValues;
