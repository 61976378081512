import { isEqual } from "lodash"
import React from "react"
import {
  Dimensions,
  Platform,
  PlatformIOSStatic,
  StyleSheet,
  ViewStyle,
} from "react-native"
import { useSelector } from "react-redux"
import { ReactComponent as DoubleDown } from "../../../assets/trending/DoubleDown.svg"
import { ReactComponent as DoubleDownAuto } from "../../../assets/trending/DoubleDownAuto.svg"
import { ReactComponent as DoubleUp } from "../../../assets/trending/DoubleUp.svg"
import { ReactComponent as DoubleUpAuto } from "../../../assets/trending/DoubleUpAuto.svg"
import { ReactComponent as AngledUp } from "../../../assets/trending/High.svg"
import { ReactComponent as AngledUpAuto } from "../../../assets/trending/HighAuto.svg"
import { ReactComponent as Flat } from "../../../assets/trending/InRange.svg"
import { ReactComponent as FlatAuto } from "../../../assets/trending/InRangeAuto.svg"
import { ReactComponent as AngledDown } from "../../../assets/trending/Low.svg"
import { ReactComponent as AngledDownAuto } from "../../../assets/trending/LowAuto.svg"
import { ReactComponent as Up } from "../../../assets/trending/Up.svg"
import { ReactComponent as UpAuto } from "../../../assets/trending/UpAuto.svg"
import { BolusState } from "../../../web/store/reducers/bolus"
import { UserState } from "../../../web/store/reducers/user"
import { RootState } from "../../../web/store/store"

interface CGM {
  Value: number
  Trend:
    | "Flat"
    | "Angeld Down"
    | "Angled Up"
    | "Double Up"
    | "Double Down"
    | "Up"
  Delivery: number
}

const platform = Platform as PlatformIOSStatic
const { width } = Dimensions.get("screen")

const determineIconScale = () => {
  if (platform.isPad) {
    if (width > 1000) {
      return {
        transform: [{ scale: 2.3 }],
        marginLeft: 32,
      } as ViewStyle
    } else {
      return {
        transform: [{ scale: 1.4 }],
        marginLeft: 16,
      } as ViewStyle
    }
  } else {
    return {
      transform: [{ scale: 1 }],
    } as ViewStyle
  }
}
function GetCGMInfo(size?: string) {
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual,
  )

  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual,
  )

  const determineIcon = () => {
    let icon = (
      <FlatAuto height={size ? size : "70%"} style={{ marginTop: 4 }} />
    )

    switch (bolusState.cgmInfo.Trend) {
      case "Angeld Down":
        icon = userState.automatedMode ? (
          <AngledDownAuto
            height={size ? size : "70%"}
            style={{ marginTop: 4 }}
          />
        ) : (
          <AngledDown height={size ? size : "70%"} style={{ marginTop: 4 }} />
        )
        break
      case "Angled Up":
        icon = userState.automatedMode ? (
          <AngledUpAuto height={size ? size : "70%"} style={{ marginTop: 4 }} />
        ) : (
          <AngledUp height={size ? size : "70%"} style={{ marginTop: 4 }} />
        )
        break
      case "Double Down":
        icon = userState.automatedMode ? (
          <DoubleDownAuto
            height={size ? size : "70%"}
            style={{ marginTop: 4 }}
          />
        ) : (
          <DoubleDown height={size ? size : "70%"} style={{ marginTop: 4 }} />
        )
        break
      case "Double Up":
        icon = userState.automatedMode ? (
          <DoubleUpAuto height={size ? size : "70%"} style={{ marginTop: 4 }} />
        ) : (
          <DoubleUp height={size ? size : "70%"} style={{ marginTop: 4 }} />
        )
        break
      case "Flat":
        icon = userState.automatedMode ? (
          <FlatAuto height={size ? size : "70%"} style={{ marginTop: 4 }} />
        ) : (
          <Flat height={size ? size : "70%"} style={{ marginTop: 4 }} />
        )
        break
      case "Up":
        icon = userState.automatedMode ? (
          <UpAuto height={size ? size : "70%"} style={{ marginTop: 4 }} />
        ) : (
          <Up height={size ? size : "70%"} style={{ marginTop: 4 }} />
        )
        break
    }
    return icon
  }

  const determineiPadIcon = () => {
    let icon = <FlatAuto style={{ marginTop: 4 }} />

    switch (bolusState.cgmInfo.Trend) {
      case "Angeld Down":
        icon = userState.automatedMode ? (
          <AngledDownAuto style={[styles.size, { marginTop: 4 }] as any} />
        ) : (
          <AngledDown style={[styles.size, { marginTop: 4 }] as any} />
        )
        break
      case "Angled Up":
        icon = userState.automatedMode ? (
          <AngledUpAuto style={[styles.size, { marginTop: 4 }] as any} />
        ) : (
          <AngledUp style={[styles.size, { marginTop: 4 }] as any} />
        )
        break
      case "Double Down":
        icon = userState.automatedMode ? (
          <DoubleDownAuto style={[styles.size, { marginTop: 4 }] as any} />
        ) : (
          <DoubleDown style={[styles.size, { marginTop: 4 }] as any} />
        )
        break
      case "Double Up":
        icon = userState.automatedMode ? (
          <DoubleUpAuto style={[styles.size, { marginTop: 4 }] as any} />
        ) : (
          <DoubleUp style={[styles.size, { marginTop: 4 }] as any} />
        )
        break
      case "Flat":
        icon = userState.automatedMode ? (
          <FlatAuto style={[styles.size, { marginTop: 4 }] as any} />
        ) : (
          <Flat style={[styles.size, { marginTop: 4 }] as any} />
        )
        break
      case "Up":
        icon = userState.automatedMode ? (
          <UpAuto style={[styles.size, { marginTop: 4 }] as any} />
        ) : (
          <Up style={[styles.size, { marginTop: 4 }] as any} />
        )
        break
    }
    return icon
  }
  // const [cgm, setCGM] = React.useState<CGM>();
  const [trendingIcon, setTrendingIcon] = React.useState<Element>(
    determineIcon() as any,
  )
  const [trendingiPadIcon, setTrendingiPadIcon] = React.useState<Element>(
    determineiPadIcon() as any,
  )
  // const [currentIndex, setCurrentIndex] = React.useState<number>(
  //   bolusState.currentIndex
  // );

  React.useEffect(() => {
    const iPadIcon = determineiPadIcon()
    const icon = determineIcon()
    // setCGM(bolusState.cgmInfo);
    // setCurrentIndex(bolusState.currentIndex);
    setTrendingIcon(icon as any)
    setTrendingiPadIcon(iPadIcon as any)
  }, [
    bolusState.cgmInfo.Trend,
    userState.automatedMode,
    bolusState.currentIndex,
  ])

  return { trendingIcon, trendingiPadIcon }
}

const styles = StyleSheet.create({
  size: determineIconScale()!,
})

export default GetCGMInfo
