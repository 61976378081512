const SectionOne = [
  { time: "1:52 pm", cmgValue: "--", insulinValue: "0" },
  { time: "1:47 pm", cmgValue: "--", insulinValue: "0" },
  { time: "1:42 pm", cmgValue: "--", insulinValue: "0" },
  { time: "1:37 pm", cmgValue: "--", insulinValue: "0" },
  { time: "1:32 pm", cmgValue: "--", insulinValue: "0" },
  { time: "1:27 pm", cmgValue: "--", insulinValue: "0" },
  { time: "1:22 pm", cmgValue: "--", insulinValue: "0" },
  { time: "1:17 pm", cmgValue: "--", insulinValue: "0" },
  { time: "1:12 pm", cmgValue: "--", insulinValue: "0" },
  { time: "1:07 pm", cmgValue: "--", insulinValue: "0" },
  { time: "1:02 pm", cmgValue: "172", insulinValue: "0" },
  { time: "12:57 pm", cmgValue: "176", insulinValue: "0.05" },
  { time: "12:52 pm", cmgValue: "182", insulinValue: "0.05" },
  { time: "12:47 pm", cmgValue: "191", insulinValue: "0.05" },
  { time: "12:42 pm", cmgValue: "186", insulinValue: "0.05" },
  { time: "12:37 pm", cmgValue: "201", insulinValue: "0.05" },
  { time: "12:32 pm", cmgValue: "189", insulinValue: "0.05" },
  { time: "12:27 pm", cmgValue: "194", insulinValue: "0.05" },
  { time: "12:22 pm", cmgValue: "140", insulinValue: "0.05" },
  {
    time: "12:17 pm",
    cmgValue: "140",
    insulinValue: ".1",
    hideBorderBottom: true
  }
];

const SectionTwo = [
  { time: "11:53 am", cmgValue: "--", insulinValue: "0" },
  { time: "11:48 am", cmgValue: "--", insulinValue: "0.05" },
  { time: "11:43 am", cmgValue: "--", insulinValue: "0.05" },
  { time: "11:38 am", cmgValue: "--", insulinValue: "0.05" },
  {
    time: "11:34 am",
    cmgValue: "--",
    insulinValue: "0.05",
    hideBorderBottom: true
  }
];

export default { SectionOne, SectionTwo };
