import React from "react";
import { StyleProp, StyleSheet, Text, TextStyle, View } from "react-native";
import theme from "mobile/latest/theme";
import Header from "mobile/latest/components/atoms/header";
import Paragraph from "mobile/latest/components/atoms/paragraph";
import { CustomToggleSwitch } from "mobile/latest/components/molecules";

interface Props {
  subtitleTextOnPressHandler?: () => void;
  onToggleChange?: (val: boolean) => void;
  settingTitle: string;
  subtitleText?: string;
  aboutSubtitleText?: boolean;
  hasCustomToggleSwitch?: boolean;
  settingValueSubTextElement?: JSX.Element;
  settingValueSubText?: string;
  header?: string;
  headerStyle?: StyleProp<TextStyle>;
  startToggleTrue?: boolean;
  sectionStyle?: StyleProp<TextStyle>;
  hideBreak?: boolean;
}

const SettingsSection: React.FC<Props> = ({
  subtitleTextOnPressHandler,
  onToggleChange,
  settingTitle,
  subtitleText,
  aboutSubtitleText,
  hasCustomToggleSwitch,
  settingValueSubText,
  settingValueSubTextElement,
  header,
  headerStyle,
  startToggleTrue,
  sectionStyle,
  hideBreak = false,
}) => {
  const [toggleValue, setToggleValue] = React.useState<boolean>(
    startToggleTrue ? true : false
  );

  const handleToggleChange = () => {
    setToggleValue((toggleValue) => !toggleValue);
    if (onToggleChange) {
      onToggleChange(!toggleValue);
    }
  };

  return (
    <>
      <View style={[styles.section, sectionStyle && sectionStyle]}>
        <>
          {header && (
            <Header
              variant="h4"
              label={header}
              styles={[{ marginBottom: 16 }, headerStyle && headerStyle]}
            />
          )}
          <View style={[styles.sectionRow]}>
            <Text style={[theme.fonts.body2]}>{settingTitle}</Text>
            {hasCustomToggleSwitch && (
              <CustomToggleSwitch
                leftBorder
                value={toggleValue}
                onValueChange={handleToggleChange}
              />
            )}
          </View>
          {subtitleText && (
            <Text
              style={[
                theme.fonts.body3,
                aboutSubtitleText
                  ? styles.graySubtitleText
                  : styles.settingValue
              ]}
              onPress={subtitleTextOnPressHandler}>
              {subtitleText}
            </Text>
          )}
          {settingValueSubText && (
            <Paragraph label={settingValueSubText} variant="body3" />
          )}
          {settingValueSubTextElement && settingValueSubTextElement}
        </>
      </View>
      {!hideBreak && <View style={[styles.break]} />}
    </>
  );
};

const styles = StyleSheet.create({
  section: {
    padding: 16
  },

  settingValue: {
    color: theme.colors.lightBlue
  },
  graySubtitleText: {
    ...theme.fonts.body3,
    color: theme.colors.grayScale.gray400,
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  sectionRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

export default SettingsSection;
