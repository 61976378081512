import PickerItem from "../types/pickerItem";

const HALF_HOURS_DATA: Array<PickerItem> = [];
for (let i: number = 0; i <= 15; i++) {
  const val = (i + 1) / 2;
  HALF_HOURS_DATA.push({
    label: val.toString(),
    value: i,
    key: `${i}KEY${i}`,
    index: i
  });
}

export default HALF_HOURS_DATA;
