import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  podCommunicationMessages,
  deactivatingPodMessages,
  sendingInstructionMessages,
  cancelInstructionMessages,
} from './GlobalModals.messages';
import { hideModal } from 'web/store/reducers/modals/modalsSlice';
import { Spinner } from 'web/components/ios/Spinner';
import { Modal } from '../../Modal';

const MODAL_TIMEOUT = 2000;

export interface ModalWithTimeoutProps {
  onTimeout?: () => void;
}

export const PodCommunicationModal: React.FC<ModalWithTimeoutProps> = ({
  onTimeout,
}) => {
  const intl = useIntl();

  setTimeout(() => {
    if (onTimeout) {
      onTimeout();
    }
  }, MODAL_TIMEOUT);

  return (
    <Modal title={intl.formatMessage(podCommunicationMessages.title)}>
      <div className="mt-2">
        <Spinner />
      </div>
      <FormattedMessage {...podCommunicationMessages.text} />
    </Modal>
  );
};

export const DeactivatePodModal: React.FC<ModalWithTimeoutProps> = ({
  onTimeout,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  setTimeout(() => {
    if (onTimeout) {
      onTimeout();
    }
    dispatch(hideModal());
  }, MODAL_TIMEOUT);

  return (
    <Modal title={intl.formatMessage(deactivatingPodMessages.title)}>
      <Spinner />
      <FormattedMessage {...deactivatingPodMessages.text} />
    </Modal>
  );
};

export const SendingInstructionModal: React.FC<ModalWithTimeoutProps> = ({
  onTimeout,
}) => {
  const intl = useIntl();

  setTimeout(() => {
    if (onTimeout) {
      onTimeout();
    }
  }, MODAL_TIMEOUT);

  return (
    <Modal title={intl.formatMessage(sendingInstructionMessages.title)}>
      <Spinner />
      <FormattedMessage {...sendingInstructionMessages.text} />
    </Modal>
  );
};

interface CancelInstructionModalProps {
  customTitle?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const CancelInstructionModal: React.FC<CancelInstructionModalProps> = ({
  customTitle,
  onCancel,
  onConfirm,
}) => {
  const intl = useIntl();

  return (
    <Modal
      title={customTitle ? customTitle : intl.formatMessage(cancelInstructionMessages.title)}
      rightButtonText={intl.formatMessage(cancelInstructionMessages.yes)}
      rightButtonOnClick={onConfirm}
      leftButtonText={intl.formatMessage(cancelInstructionMessages.no)}
      leftButtonOnClick={onCancel}
    />
  );
};
