import React from 'react';
import { Button } from 'web/components/ios/Button';
import { useState } from 'react';
import { IOSBolusRoutesEnums } from '../types';
import { useNavigation } from '@react-navigation/native';
import { useIntl } from 'react-intl';
import {
  messages,
  SetDurationOfInsulinActionMessages,
} from '../Bolus.messages';

export const SetDurationOfInsulinAction = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const [isClicked, setIsClicked] = useState(false);

  const onClickHandler = () => {
    setIsClicked(true);
  };

  return (
    <div className="flex flex-col border-t-1 w-full h-full bg-white">
      <div className="basis-2/5 p-6">
        <p className="font-bold text-2xl mb-1">
          {intl.formatMessage(SetDurationOfInsulinActionMessages.title)}
        </p>
        <p className="mb-8">
          {intl.formatMessage(SetDurationOfInsulinActionMessages.text)}
        </p>
        <div
          onClick={onClickHandler}
          className="flex justify-between items-center w-full pb-2 border-b border-b-ios-gray-7 hover:cursor-pointer"
        >
          <div>
            <p>
              {intl.formatMessage(
                SetDurationOfInsulinActionMessages.inputLabel
              )}
            </p>
            <p className="font-semibold text-xs text-ios-gray-2">
              {intl.formatMessage(
                SetDurationOfInsulinActionMessages.inputPlaceholder
              )}
            </p>
          </div>
          <div>
            <p
              className={`font-semibold ${isClicked ? 'text-modes-automated' : 'text-ios-gray-2'}`}
            >
              <span className="mr-0.5">
                {isClicked ? '3' : intl.formatMessage(messages.emptyValue)}
              </span>
              {intl.formatMessage(messages.hours)}
            </p>
          </div>
        </div>
      </div>
      <div className="basis-9/12">
        <div className=" w-full h-full p-6 flex flex-col-reverse">
          <Button
            onClick={() =>
              isClicked && navigate(IOSBolusRoutesEnums.SetMaximumBolus)
            }
            variant="colored"
            disabled={!isClicked}
          >
            {intl.formatMessage(messages.nextBtn)}
          </Button>
        </div>
      </div>
    </div>
  );
};
