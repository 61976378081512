import React from 'react';
import {
  CardStyleInterpolators,
  StackNavigationOptions,
  createStackNavigator,
} from '@react-navigation/stack';
import { FormattedMessage, useIntl } from 'react-intl';
import { TopBar } from 'web/components/ios/TopBar';
import { PodSetupStart } from './views/PodSetupStart';
import { FillPod } from './views/FillPod';
import { CheckCannula } from './views/CheckCannula';
import { messages, podDetailsMessages } from './Pod.messages';
import { PodFTSRouteNames } from './types';
import { PodDetailsScreen } from './views/PodDetailsScreen';
import { ReactComponent as BackIcon } from '../assets/backIcon.svg';
import { NoActivePodScreen } from './views/NoActivePodScreen';

const Stack = createStackNavigator();

export const PodFtsStack: React.FC = () => {
  const intl = useIntl();

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          height: 91,
        },
        title: intl.formatMessage(messages.topBarTitle),
        header: (props) => <TopBar {...props} backMode expanded />,
      }}
    >
      <Stack.Screen
        name={PodFTSRouteNames.setupStart}
        component={PodSetupStart}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen name={PodFTSRouteNames.podFill} component={FillPod} />
      <Stack.Screen
        name={PodFTSRouteNames.podCheckCannula}
        component={CheckCannula}
      />
      <Stack.Screen
        name={PodFTSRouteNames.podDetails}
        component={PodDetailsScreen}
      />
      <Stack.Screen
        name={PodFTSRouteNames.noActivePod}
        component={NoActivePodScreen}
      />
    </Stack.Navigator>
  );
};
