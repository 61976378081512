import React from "react"
import { Button as PaperButton, Card, Modal, Portal } from "react-native-paper"
import { Text, View, ViewStyle } from "react-native"
import useLanguageContent from "../../../hooks/useLanguageContent"
import theme from "../../../theme"
import { strings } from "./ConfirmationModalContent"

interface Props {
  title?: string
  message?: JSX.Element
  onDismiss?: () => void
  hideTitle?: boolean
  hideDismiss?: boolean
  hideConfirm?: boolean
  hideFooter?: boolean
  onConfirm?: () => void
  dismissText?: string
  confirmText?: string
  isVisible: boolean
  purple?: boolean
  hypoScreen?: boolean
  languageSelectScreen?: boolean
  modalStyle?: ViewStyle
}

const ConfirmationModal: React.FC<Props> = ({
  title,
  message,
  onDismiss,
  hideTitle,
  hideDismiss,
  hideConfirm,
  hideFooter,
  onConfirm,
  dismissText,
  confirmText,
  isVisible,
  purple,
  hypoScreen,
  languageSelectScreen,
  modalStyle
}) => {
  const { languageContent } = useLanguageContent(strings)
  return (
    <>
      <Modal visible={isVisible} onDismiss={onDismiss} style={modalStyle}>
        <Card
          style={{
            margin: 54,
            padding: 8,
            display: "flex",
            backgroundColor: purple ? theme.colors.modalPurple : "#FAFAFA",
            borderColor: purple ? theme.colors.white : "transparent",
            borderWidth: purple ? 1.5 : 0,
            borderRadius: purple ? 14 : languageSelectScreen ? 8 : 2
          }}
          elevation={5}
          accessible>
          <Card.Content>
            {hideTitle ? null : (
              <Text
                style={[
                  theme.fonts.h4,
                  {
                    marginBottom: 16,
                    color: purple
                      ? theme.colors.white
                      : theme.colors.grayScale.gray100
                  }
                ]}>
                {title}
              </Text>
            )}
            <View>{message}</View>
          </Card.Content>
          {hideFooter ? null : (
            <Card.Actions
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: 16
              }}>
              {hideDismiss ? null : (
                <PaperButton accessible onPress={onDismiss}>
                  <Text
                    style={[
                      theme.fonts.body2,
                      {
                        color: purple
                          ? theme.colors.white
                          : hypoScreen
                          ? theme.colors.primary.main
                          : theme.colors.purple,
                        fontWeight: "bold"
                      }
                    ]}>
                    {dismissText ? dismissText : languageContent.no}
                  </Text>
                </PaperButton>
              )}
              {hideConfirm ? null : (
                <PaperButton
                  accessible
                  onPress={onConfirm}
                  style={{
                    marginLeft: 32
                  }}>
                  <Text
                    style={[
                      theme.fonts.body2,
                      {
                        color: purple
                          ? theme.colors.white
                          : hypoScreen
                          ? theme.colors.primary.main
                          : theme.colors.purple,
                        fontWeight: "bold"
                      }
                    ]}>
                    {confirmText ? confirmText : languageContent.yes}
                  </Text>
                </PaperButton>
              )}
            </Card.Actions>
          )}
        </Card>
      </Modal>
    </>
  )
}

export default ConfirmationModal
