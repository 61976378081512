import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface ButtonProps {
  full?: boolean;
  variant?: 'clear' | 'colored' | 'tinted' | 'thin' | 'cancel';
  colorMode?: 'automated' | 'manual' | 'limited';
  type?: 'submit' | 'button';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  disabled?: boolean;
  noSpacings?: boolean;
  filled?: boolean;
}

export const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  full = false,
  variant = 'clear',
  colorMode = 'automated',
  type = 'button',
  onClick,
  className = '',
  disabled = false,
  noSpacings = false,
  filled = false,
}) => {
  let bgColorClass = `bg-modes-${colorMode}`;
  if (variant === 'tinted' && !filled) {
    bgColorClass = `${bgColorClass}/10`;
  }

  const shouldRenderBgColor =
    (variant === 'colored' || variant === 'tinted' || variant === 'thin') &&
    !disabled;

  const textColorClass = `text-modes-${colorMode}`;
  const classes = classNames(
    `text-regular flex items-center justify-center ${className}`,
    {
      'px-2': !noSpacings,
      'py-[14px]':
        variant === 'clear' || variant === 'colored' || variant === 'cancel',
      'rounded-lg': variant === 'clear' || variant === 'colored',

      'py-0.5': variant === 'tinted',
      'rounded-2xl': variant === 'tinted',
      'text-small': variant === 'tinted' || variant === 'thin',
      'font-semibold': variant === 'tinted',

      'rounded-[10px]': variant === 'thin',
      'py-1': variant === 'thin',

      'bg-ios-gray-6': variant === 'clear',
      'text-black': variant === 'clear' && !disabled,
      'text-[#939393]': disabled && variant === 'clear',
      'text-white': variant === 'colored' || variant === 'thin' || filled || (disabled && variant !== 'clear'),

      'w-full': full,
    },
    shouldRenderBgColor && bgColorClass,
    variant === 'tinted' && textColorClass,
    disabled && 'bg-ios-disabled',
  );

  return (
    <button
      type={type}
      className={classes}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
