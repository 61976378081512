import React from "react";
import { SectionList, StyleSheet, Text, View } from "react-native";
import { tcDataUS } from "../../../../../data/tcDataUS";
import theme from "../../../../../theme";

type SectionName =
  | "Privacy Policy"
  | "HIPAA Privacy Notice"
  | "Terms of Use (ToU)"
  | "Limited Express Warranty"
  | "Legal Notices";

const ReadTCScreen: React.FC = () => {
  const sectionRef = React.useRef<SectionList>(null);

  const linkMap = (sectionName: SectionName) => {
    return {
      "Privacy Policy": 6,
      "HIPAA Privacy Notice": 24,
      "End User License Agreement (EULA)": 28,
      "Terms of Use (ToU)": 29,
      "Limited Express Warranty": 30,
      "Legal Notices": 31
    }[sectionName];
  };

  return (
    <SectionList
      ref={sectionRef}
      onScrollToIndexFailed={() =>
        sectionRef.current?.scrollToLocation({
          itemIndex: 0,
          sectionIndex: 0
        })
      }
      initialNumToRender={2500}
      extraData={tcDataUS}
      contentContainerStyle={{
        paddingBottom: 20,
        backgroundColor: "white",
        width: "100%",
        marginTop: -40
      }}
      sections={tcDataUS}
      keyExtractor={(item, index) => item + index}
      renderItem={({ item, index }) => (
        <View style={styles.bodyContainer}>
          <Text style={{ ...theme.fonts.text400 }} key={index}>
            {item}
          </Text>
        </View>
      )}
      renderSectionHeader={({ section: { title, hideTitle } }) => (
        <View style={styles.bodyContainer}>
          {hideTitle ? (
            <Text
              onPress={() => {
                sectionRef.current?.scrollToLocation({
                  animated: false,
                  itemIndex: 0,
                  sectionIndex: linkMap(title)
                });
              }}
              style={styles.links}>
              {title}
            </Text>
          ) : (
            <Text
              onPress={() => {
                sectionRef.current?.scrollToLocation({
                  itemIndex: 0,
                  sectionIndex: linkMap(title)
                });
              }}
              style={styles.headerText}>
              {title}
            </Text>
          )}
        </View>
      )}
      stickySectionHeadersEnabled={false}
    />
  );
};

const styles = StyleSheet.create({
  headerText: {
    fontSize: 18,
    lineHeight: 30,
    fontWeight: "bold"
  },
  trademark: {
    fontSize: 15,
    lineHeight: 18
  },
  bodyContainer: {
    width: "100%",
    marginTop: 18,
    paddingLeft: 16,
    paddingRight: 16
  },
  textContainer: {
    width: 215
  },

  links: {
    ...theme.fonts.text400,
    marginTop: -10,
    color: "#8d61c8",
    paddingLeft: 24,
    fontSize: 14
  }
});
export default ReadTCScreen;
