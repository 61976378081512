import { defineMessages } from 'react-intl';

export const connectingMessages = defineMessages({
  title: {
    id: 'ios.modals.connecting.title',
    defaultMessage: 'Connecting',
  },
  text: {
    id: 'ios.modals.please-wait.text',
    defaultMessage: 'Please wait...',
  },
});
