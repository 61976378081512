import { Dispatch, SetStateAction, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { IOSCustomFoodsEnums } from '../types';
import { selectCustomFoods } from 'web/store/reducers/customFoods/customFoods.helpers';
import { useDispatch, useSelector } from 'react-redux';
import { CustomFoodType } from 'web/store/reducers/customFoods/reducer';
import { customFoodsActions } from 'web/store/reducers/customFoods';
import { CustomFood } from '../components/CustomFood';
import { Button } from 'web/components/ios/Button';
import { BottomNav } from 'web/components/ios/BottomMenu/BottomNav';
import {
  hideModal,
  setCurrentModal,
  showModal,
} from 'web/store/reducers/modals/modalsSlice';
import { ConfirmDeleteCustomFoods } from 'web/components/ios/modals/CustomFoodsModals/CustomFoodsModals';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomFoodsScreenMessages } from '../CustomFoods.messages';

interface CustomFoodScreenProps {
  isEditModeActive: boolean;
  setEditCustomFood: Dispatch<SetStateAction<CustomFoodType | undefined>>;
}

export const CustomFoodScreen: React.FC<CustomFoodScreenProps> = ({
  isEditModeActive,
  setEditCustomFood,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const { customFoods } = useSelector(selectCustomFoods);

  const addCustomFoodOnClickHandler = () => {
    setEditCustomFood(undefined);
    navigate(IOSCustomFoodsEnums.AddCustomFoodScreen);
  };

  const editCustomFoodHandler = (customFood: CustomFoodType) => {
    if (customFood.id === 1) return;
    setEditCustomFood(customFood);
    navigate(IOSCustomFoodsEnums.AddCustomFoodScreen);
  };

  const deleteCustomFoods = (customFoodId: number) => {
    dispatch(
      customFoodsActions.customFoodsUpdate({
        customFoods: customFoods.filter((obj) => {
          return obj.id !== customFoodId;
        }),
      })
    );
    dispatch(hideModal());
  };

  const deleteOnClickHandler = (customFoodId: number) => {
    if (customFoodId === 1) return;
    dispatch(
      setCurrentModal({
        modal: (
          <ConfirmDeleteCustomFoods
            rightButtonOnClickHandler={() => deleteCustomFoods(customFoodId)}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  useEffect(() => {
    dispatch(
      customFoodsActions.customFoodsUpdate({
        customFoods: customFoods.map((obj) => {
          return { ...obj, newFood: false };
        }),
      })
    );
  }, []);

  return (
    <>
      <div className="flex flex-col justify-between bg-white h-full font-ios text-regular border-t-[0.5px] border-b-ios-gray-9/36">
        <div className="overflow-y-scroll overscroll-contain max-h-[525px]">
          {customFoods.map((food: CustomFoodType, index: number) => (
            <CustomFood
              key={index}
              name={food.name}
              carbs={food.carbs}
              newFood={food.newFood}
              isEditModeActive={isEditModeActive}
              deleteOnClickHandler={() => deleteOnClickHandler(food.id)}
              editCustomFoodHandler={() => editCustomFoodHandler(food)}
            />
          ))}
        </div>
        <div className="w-full pb-2 px-2 ">
          <Button
            className="w-full"
            variant="colored"
            onClick={() => addCustomFoodOnClickHandler()}
          >
            <FormattedMessage {...CustomFoodsScreenMessages.addButton} />
          </Button>
        </div>
      </div>
      <BottomNav />
    </>
  );
};
