import React from 'react';
import { useIntl } from 'react-intl';
import { createStackNavigator } from '@react-navigation/stack';
import theme from 'mobile/latest/theme';
import { ManageSensorL2 } from 'web/components/pages/L2/ManageSensorL2/ManageSensorL2';
import { ManageSensorSettings } from 'web/components/pages/L2/ManageSensorSettings/ManageSensorSettings';
import { LowGlucose } from 'web/components/pages/L2/LowGlucose/LowGlucose';
import { HighGlucose } from 'web/components/pages/L2/HighGlucose/HighGlucose';
import { MissingSensor } from 'web/components/pages/L2/MissingSensor/MissingSensor';
import { messages } from './ManageSensorL2Stack.messages';
import SwitchSensorStack from 'web/routes/SwitchSensor/SwitchSensorStack';

const Stack = createStackNavigator();
const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

export const ManageSensorL2Paths = {
  manageSensor: 'ManageSensorL2',
  switchSensor: 'SwitchSensorStack',
  sensorSettings: 'SensorSettingsL2',
  lowGlucose: 'SensorSettingsLowGlucose',
  highGlucose: 'SensorSettingsHighGlucose',
  missingSensorValues: 'SensorSettingsMissingValues',
};

export const ManageSensorL2Stack: React.FC = () => {
  const intl = useIntl();

  return (
    <Stack.Navigator initialRouteName={ManageSensorL2Paths.manageSensor} mode="card">
      <Stack.Screen
        name={ManageSensorL2Paths.manageSensor}
        options={{
          title: intl.formatMessage(messages.manageSensor),
          headerStyle: defaultHeaderStyle,
        }}
        component={ManageSensorL2}
      />
      <Stack.Screen
        name={ManageSensorL2Paths.switchSensor}
        options={{
          headerShown: false,
        }}
        component={SwitchSensorStack}
      />
      <Stack.Screen
        name={ManageSensorL2Paths.sensorSettings}
        options={{
          title: intl.formatMessage(messages.sensorSettings),
          headerStyle: defaultHeaderStyle,
        }}
        component={ManageSensorSettings}
      />
      <Stack.Screen
        name={ManageSensorL2Paths.lowGlucose}
        options={{
          title: intl.formatMessage(messages.lowGlucose),
          headerStyle: defaultHeaderStyle,
        }}
        component={LowGlucose}
      />
      <Stack.Screen
        name={ManageSensorL2Paths.highGlucose}
        options={{
          title: intl.formatMessage(messages.highGlucose),
          headerStyle: defaultHeaderStyle,
        }}
        component={HighGlucose}
      />
      <Stack.Screen
        name={ManageSensorL2Paths.missingSensorValues}
        options={{
          title: intl.formatMessage(messages.missingSensorValues),
          headerStyle: defaultHeaderStyle,
        }}
        component={MissingSensor}
      />
    </Stack.Navigator>
  );
};
