let strings = {
  "en-US": {
    set: "Set Insulin to Carb (IC) Ratios",
    number: "Number of grams of carbohydrates covered by one unit of insulin.",
    example:
      "For example, if your IC Ratio is 1:15, then you need to deliver one unit of insulin to cover every fifteen grams of carbohydrates you eat.",
    next: "Next",
    cancel: "Cancel"
  },
  "en-GB": {
    set: "Set Insulin to Carb (IC) Ratios",
    number: "Number of grams of carbohydrates covered by one unit of insulin.",
    example:
      "For example, if your IC Ratio is 1:15, then you need to deliver one unit of insulin to cover every fifteen grams of carbohydrates you eat.",
    next: "Next",
    cancel: "Cancel"
  },
  "de-DE": {
    set: "KH-zu-Insulin (KI)-Verhältnisse festlegen",
    number:
      "Anzahl der KH in Gramm, die von einer Einheit Insulin abgedeckt werden.",
    example:
      "Liegt Ihr KI-Verhältnis z. B. bei 1:15, müssen Sie eine Einheit Insulin abgeben, um fünfzehn Gramm KH abzudecken, die Sie zu sich nehmen.",
    next: "Weiter",
    cancel: "Abbr."
  },
  "es-US": {
    set: "Establecer las proporciones de insulina/carbohidratos (IC)",
    number:
      "Cantidad de gramos de carbohidratos que cubre una unidad de insulina.",
    example:
      "Por ejemplo, si su proporción de IC es 1:15, debe administrar una unidad de insulina por cada 15 g de carbohidratos que consuma.",
    next: "Siguiente",
    cancel: "Cancelar"
  },
  "fr-FR": {
    set: "Définissez les rapports insuline/glucides (I/G)",
    number: "Nombre de grammes de glucides couverts par une unité d'insuline.",
    example:
      "Par exemple, si votre rapport I/G est de 1:15, vous devez administrer une unité d'insuline pour 15 grammes de glucides consommés.",
    next: "Suivant",
    cancel: "Annuler"
  },
  "nl-NL": {
    set: "Insuline/KH-verhouding (I/KH) instellen",
    number:
      "Aantal gram koolhydraten dat wordt opgevangen door één eenheid insuline.",
    example:
      "Als uw I/KH-verhouding bijvoorbeeld 1:15 is, moet u één eenheid insuline toedienen voor elke vijftien gram koolhydraten die u eet.",
    next: "Volgende",
    cancel: "Annuleren"
  }
};

export { strings };
