import { Dimensions, Platform, StyleSheet } from "react-native-web";
import colors from "./colors";
import fonts from "./fonts";

const { width, height } = Dimensions.get("window");

const layout: any = StyleSheet.create({
  boldDesc: {
    fontSize: 16,
    lineHeight: 20,
    fontWeight: Platform.OS === "android" ? "700" : "500",
    fontFamily: "Roboto-Bold",
    color: colors.grayScale.gray400
  },
  wrapper: {
    flex: 1,
    width: width,
    marginTop: 8,
    zIndex: 2
  },
  trueCenter: {
    justifyContent: "center",
    alignItems: "center"
  },
  defaultHeaderStyle: {
    height: Platform.OS === "ios" ? (height > 800 ? 106 : 74) : 60,
    shadowColor: "#888",
    shadowOpacity: 0.4,
    shadowRadius: 1,
    shadowOffset: {
      height: 2,
      width: 0
    },
    elevation: 8
  },
  adornment: {
    alignSelf: "center",
    marginTop: 16,
    fontSize: 18,
    marginHorizontal: 8,
    color: colors.adornment
  },
  inputContainerStyle: {
    flexDirection: "row",
    alignItems: "flex-end",
    borderWidth: 1,
    borderColor: colors.textInputBorder,
    backgroundColor: colors.textInputBackgroundColor,
    borderRadius: 5,
    paddingVertical: 8,
    paddingHorizontal: 8,
    justifyContent: "space-between"
  },
  inputStyle: {
    fontSize: 40,
    textAlign: "left",
    textAlignVertical: "bottom",
    padding: 0,
    paddingLeft: 8,
    margin: 0,
    color: colors.pureBlack
  },
  tourHeader: {
    ...fonts.h3,
    lineHeight: 30,
    alignSelf: "center",
    textAlign: "center",
    paddingVertical: 16,
    fontWeight: "700"
  },
  subHeader: {
    ...fonts.text200,
    fontWeight: "400",
    alignSelf: "center",
    textAlign: "left",
    paddingHorizontal: 16
  },
  mockInput: {
    borderColor: colors.textInputBorder,
    borderWidth: 1,
    backgroundColor: colors.textInputBackgroundColor,
    width: "100%",
    borderRadius: 5,
    justifyContent: "center"
  },
  mockInputStyle: {
    marginLeft: 8,
    fontSize: 32
  },
  compoundToggleViewStyle: {
    marginTop: 16,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  tourSectionHeader: {
    zIndex: 999,
    width: width,
    height: 60,
    backgroundColor: colors.white,
    shadowColor: "#888",
    shadowOpacity: 0.3,
    shadowRadius: 3,
    shadowOffset: {
      height: 3,
      width: 0
    },
    elevation: 8,
    alignItems: "center",
    alignContent: "center",
    flexDirection: "row",
    justifyContent: Platform.OS === "android" ? "flex-start" : "space-between"
  },
  spacing: {
    tiny: 4,
    small: 8,
    normal: 16,
    large: 24,
    huge: 32,
  }
});

export default layout;
