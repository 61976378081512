import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../FtsStartScreenStack.messages';

export const NeedHelpScreen = () => {
  return (
    <div className="flex justify-between px-2 flex-col flex-1 items-stretch bg-white">
      <div className="flex flex-col">
        <div className="px-2 mt-2">
          <div className="text-black">
            <FormattedMessage {...messages.needToSignMessage} />
          </div>
          <div className="mt-2">
            <div className="font-bold">
              <FormattedMessage {...messages.needAnAccountTitle} />
            </div>
            <div>
              <FormattedMessage {...messages.needAnAccountMessage} />
            </div>
          </div>
          <div className="mt-2">
            <div className="font-bold">
              <FormattedMessage {...messages.forgotOmnipodIdTitle} />
            </div>
            <div>
              <FormattedMessage {...messages.forgotOmnipodIdMessage} />
            </div>
          </div>
          <div className="mt-2">
            <div className="font-bold">
              <FormattedMessage {...messages.contactCustomerCareTitle} />
            </div>
            <div>
              <FormattedMessage {...messages.contactCustomerCareMessage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
