import { createStackNavigator } from "@react-navigation/stack";
import { isEqual } from "lodash";
import moment from "moment";
import React from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import { BottomQuads } from "../../components/organisms";
import { RootState } from "../../../../web/store/store";
import theme from "../../theme";
import HypoProtectTabStack from "./HypoProtectTabStack";
import TabStack from "./TabStack";

const Stack = createStackNavigator();

const HomeStack: React.FC = () => {
  const userState = useSelector((state: RootState) => state.user, isEqual);
  const basalState = useSelector((state: RootState) => state.basal, isEqual);
  const bolusState = useSelector((state: RootState) => state.bolus, isEqual);
  const langState = useSelector((state: RootState) => state.language, isEqual);

  const [activeTab, setActiveTab] = React.useState(0);
  const [lastBolusDate, setLastBolusDate] = React.useState<string>();
  const [lastBolusTime, setLastBolusTime] = React.useState<string>();

  const setTabIndex = (index: number) => {
    setActiveTab(index);
  };

  const getLastBolusDate = React.useCallback(() => {
    let lastBolus;

    if (bolusState.dateLastBolus === undefined) {
      lastBolus = new Date(userState.datePodActivated);
    } else {
      lastBolus = new Date(bolusState.dateLastBolus);
    }

    setLastBolusDate(
      lastBolus.toLocaleDateString(langState.locale, {
        month: "short",
        day: "2-digit"
      })
    );
    setLastBolusTime(moment(lastBolus).format("h:mm a"));
  }, [bolusState.dateLastBolus, userState.datePodActivated]);

  React.useEffect(() => {
    getLastBolusDate();
  }, [bolusState, getLastBolusDate]);

  return (
    <>
      <View
        style={{
          flex: 1,
          backgroundColor: userState.automatedMode
            ? theme.colors.purple
            : theme.colors.tabBackground
        }}>
        <View
          style={{
            flex: basalState.insulinPaused ? 1 : 0.8
          }}>
          {userState.hypoProtectOn ? (
            <Stack.Navigator initialRouteName="Main">
              <Stack.Screen
                name="Main"
                options={{
                  headerShown: false,
                  headerStyle: {
                    shadowColor: "#888",
                    shadowOpacity: 0.4,
                    shadowRadius: 3,
                    shadowOffset: {
                      height: 3,
                      width: 0
                    },
                    elevation: 8
                  }
                }}>
                {() => (
                  <View style={{ flex: 1 }}>
                    <HypoProtectTabStack
                      activeTab={activeTab}
                      setActiveTab={setTabIndex}
                    />
                  </View>
                )}
              </Stack.Screen>
            </Stack.Navigator>
          ) : (
            <Stack.Navigator initialRouteName="Main">
              <Stack.Screen
                name="Main"
                options={{
                  headerShown: false,
                  headerStyle: {
                    shadowColor: "#888",
                    shadowOpacity: 0.4,
                    shadowRadius: 3,
                    shadowOffset: {
                      height: 3,
                      width: 0
                    },
                    elevation: 8
                  }
                }}>
                {() => (
                  <View style={{ flex: 1 }}>
                    <TabStack setActiveTab={setTabIndex} />
                  </View>
                )}
              </Stack.Screen>
            </Stack.Navigator>
          )}
        </View>
        <BottomQuads
          activeTab={activeTab}
          lastBolusDate={lastBolusDate}
          lastBolusTime={lastBolusTime}
        />
      </View>
    </>
  );
};

export default HomeStack;
