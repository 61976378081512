import { isEqual } from "lodash";
import React from "react";
import { ScrollView, View } from "react-native";
import { useSelector } from "react-redux";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../web/store/store";
import { AveragesCard, DetailsCard } from "../../../../organisms";
import { strings } from "./SummaryScreenContent";

const SummaryScreen: React.FC = () => {
  const { languageContent } = useLanguageContent(strings);
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  return (
    <ScrollView>
      <View>
        <AveragesCard
          sectionOneLabel={languageContent.sensor}
          sectionTwoLabel={languageContent.insulinCarbs}
        />
      </View>
      <View style={{ marginBottom: 8, elevation: 6 }}>
        <DetailsCard
          columnOneLabel={languageContent.colOneLabel}
          columnOneSubLabel={langState.units}
          columnTwoLabel={languageContent.colTwoLabel}
          columnTwoSubLabel={languageContent.u}
          columnThreeLabel={languageContent.colThreeLabel}
          columnThreeSubLabel="g"
        />
      </View>
    </ScrollView>
  );
};

export default SummaryScreen;
