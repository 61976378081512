import { createStackNavigator } from '@react-navigation/stack';
import { IOSBolusRoutesEnums } from './types';
import { TopBar } from 'web/components/ios/TopBar';
import React, { useState } from 'react';
import { Animated } from 'react-native';
import { Modal } from 'web/components/ios/Modal';
import { useIntl } from 'react-intl';
import {
  CancelSetupModalMessages,
  messages,
  TargetGlucoseAndCorrectAboveSegmentOneMessages,
  TargetGlucoseAndCorrectAboveSegmentTwoMessages,
  BolusInsulinToCarbRatioSegmentOneMessages,
  BolusInsulinToCarbRatioSegmentTwoMessages,
  CorrectionFactorSegmentOneMessages,
  CorrectionFactorSegmentTwoMessages,
} from './Bolus.messages';
import { useNavigation } from '@react-navigation/native';
import { IntroBolus } from './views/IntroBolus';
import { TargetGlucoseAndCorrectAboveValuesIntro } from './views/TargetGlucoseAndCorrectAboveValuesIntro';
import { CreateTargetGlucoseAndCorrectAboveSegment } from './views/CreateTargetGlucoseAndCorrectAboveSegment';
import { TargetGlucoseAndCorrectAboveSegments } from './views/TargetGlucoseAndCorrectAboveSegments';
import { SetInsulinToCarbRatioIntro } from './views/SetInsulinToCarbRatioIntro';
import { CreateInsulinToCarbRatioSegment } from './views/CreateInsulinToCarbRatioSegment';
import { InsulinToCarbRatioSegments } from './views/InsulinToCarbRatioSegments';
import { SetCorrectionFactorIntro } from './views/SetCorrectionFactorIntro';
import { CreateCorrectionFactorSegment } from './views/CreateCorrectionFactorSegment';
import { CorrectionFactorSegments } from './views/CorrectionFactorSegments';
import { SetDurationOfInsulinAction } from './views/SetDurationOfInsulinAction';
import { SetMaximumBolus } from './views/SetMaximumBolus';
import { AllowExtendedBolus } from './views/AllowExtendedBolus';

const Stack = createStackNavigator();

export const IOSBolusFTSStack = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const headerRef = new Animated.ValueXY().y;
  const [showModal, setShowModal] = useState(false);
  const [
    isTargetGlucoseAndCorrectAboveEditModeActive,
    setIsTargetGlucoseAndCorrectAboveEditModeActive,
  ] = useState(false);
  const [
    isInsulinToCarbRatioEditModeActive,
    setIsInsulinToCarbRatioEditModeActive,
  ] = useState(false);
  const [
    isCorrectionFactorEditModeActive,
    setIsCorrectionFactorEditModeActive,
  ] = useState(false);

  const animateHeader = React.useCallback(() => {
    Animated.timing(headerRef, {
      toValue: 0,
      duration: 600,
      useNativeDriver: false,
    }).start();
  }, [headerRef]);

  const modalRightButttonOnClickHandler = () => {
    setShowModal(false);
    setIsTargetGlucoseAndCorrectAboveEditModeActive(false);
    setIsInsulinToCarbRatioEditModeActive(false);
    navigate(IOSBolusRoutesEnums.IntroBolus);
  };

  React.useEffect(() => {
    animateHeader();
  }, [animateHeader]);

  return (
    <>
      <Stack.Navigator
        initialRouteName={IOSBolusRoutesEnums.IOSBolusFTS}
        screenOptions={{
          header: (props) => <TopBar {...props} backMode />,
        }}
      >
        <Stack.Screen
          name={IOSBolusRoutesEnums.IntroBolus}
          component={IntroBolus}
        />
        <Stack.Screen
          name={IOSBolusRoutesEnums.TargetGlucoseAndCorrectAboveValuesIntro}
          options={{
            header: (props) => <TopBar {...props} expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          component={TargetGlucoseAndCorrectAboveValuesIntro}
        />
        <Stack.Screen
          name={IOSBolusRoutesEnums.TargetGlucoseAndCorrectAboveSegmentOne}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
        >
          {() => (
            <CreateTargetGlucoseAndCorrectAboveSegment
              editMode={isTargetGlucoseAndCorrectAboveEditModeActive}
              bolusSegmentTitle={`${intl.formatMessage(messages.segmentTitle)} 1`}
              startTimeValue={intl.formatMessage(
                TargetGlucoseAndCorrectAboveSegmentOneMessages.startTimeInputValue
              )}
              endTimeValue={intl.formatMessage(
                TargetGlucoseAndCorrectAboveSegmentOneMessages.endTimeInputValue
              )}
              targetGlucoseValue={intl.formatMessage(
                TargetGlucoseAndCorrectAboveSegmentOneMessages.targetGlucoseInputValue
              )}
              correctAboveValue={intl.formatMessage(
                TargetGlucoseAndCorrectAboveSegmentOneMessages.correctAboveInputValue
              )}
              navigationRoute={
                isTargetGlucoseAndCorrectAboveEditModeActive
                  ? IOSBolusRoutesEnums.TargetGlucoseAndCorrectAboveSegments
                  : IOSBolusRoutesEnums.TargetGlucoseAndCorrectAboveSegmentTwo
              }
            />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={IOSBolusRoutesEnums.TargetGlucoseAndCorrectAboveSegmentTwo}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
        >
          {() => (
            <CreateTargetGlucoseAndCorrectAboveSegment
              editMode={isTargetGlucoseAndCorrectAboveEditModeActive}
              bolusSegmentTitle={`${intl.formatMessage(messages.segmentTitle)} 2`}
              startTimeValue={intl.formatMessage(
                TargetGlucoseAndCorrectAboveSegmentTwoMessages.startTimeInputValue
              )}
              endTimeValue={intl.formatMessage(
                TargetGlucoseAndCorrectAboveSegmentTwoMessages.endTimeInputValue
              )}
              targetGlucoseValue={intl.formatMessage(
                TargetGlucoseAndCorrectAboveSegmentTwoMessages.targetGlucoseInputValue
              )}
              correctAboveValue={intl.formatMessage(
                TargetGlucoseAndCorrectAboveSegmentTwoMessages.correctAboveInputValue
              )}
              navigationRoute={
                IOSBolusRoutesEnums.TargetGlucoseAndCorrectAboveSegments
              }
            />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={IOSBolusRoutesEnums.TargetGlucoseAndCorrectAboveSegments}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
        >
          {() => (
            <TargetGlucoseAndCorrectAboveSegments
              editModeHandler={setIsTargetGlucoseAndCorrectAboveEditModeActive}
            />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={IOSBolusRoutesEnums.SetInsulinToCarbRatioIntro}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
          component={SetInsulinToCarbRatioIntro}
        />
        <Stack.Screen
          name={IOSBolusRoutesEnums.CreateInsulinToCarbRatioSegmentOne}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
        >
          {() => (
            <CreateInsulinToCarbRatioSegment
              editMode={isInsulinToCarbRatioEditModeActive}
              bolusSegmentTitle={`${intl.formatMessage(messages.segmentTitle)} 1`}
              startTimeValue={intl.formatMessage(
                BolusInsulinToCarbRatioSegmentOneMessages.startTimeInputValue
              )}
              endTimeValue={intl.formatMessage(
                BolusInsulinToCarbRatioSegmentOneMessages.endTimeInputValue
              )}
              unitOfInsulinValue={intl.formatMessage(
                BolusInsulinToCarbRatioSegmentOneMessages.unitOfInsulinInputValue
              )}
              navigationRoute={
                isInsulinToCarbRatioEditModeActive
                  ? IOSBolusRoutesEnums.InsulinToCarbRatioSegments
                  : IOSBolusRoutesEnums.CreateInsulinToCarbRatioSegmentTwo
              }
            />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={IOSBolusRoutesEnums.CreateInsulinToCarbRatioSegmentTwo}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
        >
          {() => (
            <CreateInsulinToCarbRatioSegment
              editMode={isInsulinToCarbRatioEditModeActive}
              bolusSegmentTitle={`${intl.formatMessage(messages.segmentTitle)} 2`}
              startTimeValue={intl.formatMessage(
                BolusInsulinToCarbRatioSegmentTwoMessages.startTimeInputValue
              )}
              endTimeValue={intl.formatMessage(
                BolusInsulinToCarbRatioSegmentTwoMessages.endTimeInputValue
              )}
              unitOfInsulinValue={intl.formatMessage(
                BolusInsulinToCarbRatioSegmentTwoMessages.unitOfInsulinInputValue
              )}
              navigationRoute={IOSBolusRoutesEnums.InsulinToCarbRatioSegments}
            />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={IOSBolusRoutesEnums.InsulinToCarbRatioSegments}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
        >
          {() => (
            <InsulinToCarbRatioSegments
              editModeHandler={setIsInsulinToCarbRatioEditModeActive}
            />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={IOSBolusRoutesEnums.SetCorrectionFactorIntro}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
          component={SetCorrectionFactorIntro}
        />
        <Stack.Screen
          name={IOSBolusRoutesEnums.CreateCorrectionFactorSegmentOne}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
        >
          {() => (
            <CreateCorrectionFactorSegment
              editMode={isCorrectionFactorEditModeActive}
              bolusSegmentTitle={`${intl.formatMessage(messages.segmentTitle)} 1`}
              startTimeValue={intl.formatMessage(
                CorrectionFactorSegmentOneMessages.startTimeInputValue
              )}
              endTimeValue={intl.formatMessage(
                CorrectionFactorSegmentOneMessages.endTimeInputValue
              )}
              unitOfInsulinValue={intl.formatMessage(
                CorrectionFactorSegmentOneMessages.unitOfInsulinInputValue
              )}
              navigationRoute={
                isCorrectionFactorEditModeActive
                  ? IOSBolusRoutesEnums.CorrectionFactorSegments
                  : IOSBolusRoutesEnums.CreateCorrectionFactorSegmentTwo
              }
            />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={IOSBolusRoutesEnums.CreateCorrectionFactorSegmentTwo}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
        >
          {() => (
            <CreateCorrectionFactorSegment
              editMode={isCorrectionFactorEditModeActive}
              bolusSegmentTitle={`${intl.formatMessage(messages.segmentTitle)} 2`}
              startTimeValue={intl.formatMessage(
                CorrectionFactorSegmentTwoMessages.startTimeInputValue
              )}
              endTimeValue={intl.formatMessage(
                CorrectionFactorSegmentTwoMessages.endTimeInputValue
              )}
              unitOfInsulinValue={intl.formatMessage(
                CorrectionFactorSegmentTwoMessages.unitOfInsulinInputValue
              )}
              navigationRoute={IOSBolusRoutesEnums.CorrectionFactorSegments}
            />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={IOSBolusRoutesEnums.CorrectionFactorSegments}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
        >
          {() => (
            <CorrectionFactorSegments
              editModeHandler={setIsCorrectionFactorEditModeActive}
            />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={IOSBolusRoutesEnums.SetDurationOfInsulinAction}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
          component={SetDurationOfInsulinAction}
        />
        <Stack.Screen
          name={IOSBolusRoutesEnums.SetMaximumBolus}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
          component={SetMaximumBolus}
        />
        <Stack.Screen
          name={IOSBolusRoutesEnums.AllowExtendedBolus}
          options={{
            header: (props) => <TopBar {...props} backAndCancelMode expanded />,
            headerTitle: intl.formatMessage(messages.headerTitle),
          }}
          initialParams={{
            topBarOnLeftButtonClick: () => setShowModal(true),
          }}
          component={AllowExtendedBolus}
        />
      </Stack.Navigator>
      {showModal && (
        <Modal
          title={intl.formatMessage(CancelSetupModalMessages.title)}
          leftButtonText={intl.formatMessage(messages.noBtn)}
          leftButtonOnClick={() => setShowModal(false)}
          rightButtonText={intl.formatMessage(messages.yesBtn)}
          rightButtonOnClick={() => modalRightButttonOnClickHandler()}
        >
          {intl.formatMessage(CancelSetupModalMessages.text)}
        </Modal>
      )}
    </>
  );
};
