import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { BackButton } from "../../components/atoms";
import {
  AddViewersScreen,
  AppDisclosureScreen,
  ViewersEntryScreen
} from "../../components/screens";
import { useModeIconSmall } from "../../hooks";
import useLanguageContent from "../../hooks/useLanguageContent";
import theme from "../../theme";
import { strings } from "./ViewerManagementStackContent";

const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const ViewerManagementStack: React.FC = () => {
  const { languageContent } = useLanguageContent(strings);
  const { icon } = useModeIconSmall();

  return (
    <>
      <Stack.Navigator initialRouteName="ViewerManagement" mode="card">
        <Stack.Screen
          name="Viewer Management"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.viewers} white={false} />
            ),
            headerRight: () => icon
          }}
          component={ViewersEntryScreen}
        />
        <Stack.Screen
          name="AddViewers"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.viewers} white={false} />
            ),
            headerRight: () => icon
          }}
          component={AddViewersScreen}
        />
        <Stack.Screen
          name="ViewAppDisclosure"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.viewers} white={false} />
            ),
            headerRight: () => icon
          }}
          component={AppDisclosureScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default ViewerManagementStack;
