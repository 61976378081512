import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { lowPodInsulinMessages } from '../SettingsReminders.messages';
import { PodCommunicationModal } from 'web/components/ios/modals/GlobalModals/GlobalModals';
import { setCurrentModal, showModal, hideModal } from 'web/store/reducers/modals/modalsSlice';
import { SettingsRemindersRoutesEnum } from '../types';

export const LowPodInsulin: React.FC = () => {
  const { navigate, setParams } = useNavigation();
  const dispatch = useDispatch();

  const handlePodTimeout = () => {
    dispatch(hideModal());
    navigate(SettingsRemindersRoutesEnum.RemindersMain);
  }

  const handleSaveClick = () => {
    dispatch(setCurrentModal({
      modal: <PodCommunicationModal onTimeout={handlePodTimeout} />
    }));
    dispatch(showModal());
  };

  useEffect(() => {
    setParams({
      topBarOnRightButtonClick: handleSaveClick
    });
  }, []);

  return (
    <div className="h-full bg-white pt-2 text-regular font-ios">
      <div className="mb-[13px] px-2">
        <FormattedMessage {...lowPodInsulinMessages.text} />
      </div>

      <div className="py-[14px] border-y-1 px-2 border-ios-gray-9/[.36] flex flex-row justify-between items-center">
        <div>
          <div>
            <FormattedMessage {...lowPodInsulinMessages.lowPodInsulin} />
          </div>
          <div className="text-small text-ios-gray-9/60">
            <FormattedMessage {...lowPodInsulinMessages.unitRange} />
          </div>
        </div>

        <div className="text-modes-manual">
          <FormattedMessage {...lowPodInsulinMessages.value} />
        </div>
      </div>
    </div>
  );
};
