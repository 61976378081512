let strings = {
  'en-US': {
    iCertify1:
      'I certify that I am 18 years old or older and I acknowledge that I have read and accept the',
    iCertify2US: ' HIPAA Privacy Notice, EULA, ToU, and Warranty. ',
    iCertify2Intl: 'EULA, ToU and Warranty',
    iCertify3:
      'If you are under 18, your parent or guardian must accept on your behalf.',
    tAndCModalTitle: 'Disabled for Simulator',
    tAndCModalMessage:
      'Detailed Terms and Conditions are only available in the full application and are disabled for the simulator.',
    confirmText: 'OK',
    continue: 'Continue',
  },
  'en-GB': {
    iCertify1:
      'I certify that I am 18 years old or older and I acknowledge that I have read and accept the',
    iCertify2US: ' EULA, ToU and Warranty. ',
    iCertify3:
      'If you are under 18, your parent or guardian must accept on your behalf.',
    tAndCModalTitle: 'Disabled for Simulator',
    tAndCModalMessage:
      'Detailed Terms and Conditions are only available in the full application and are disabled for the simulator.',
    confirmText: 'OK',
    continue: 'Continue',
  },
  'de-DE': {
    iCertify1: 'Ich bestätige, dass ich mindestens 18 Jahre alt bin und die ',
    iCertify2US:
      'Lizenzvereinbarung für Endnutzer, Nutzungsbedingungen und Gewährleistung ',
    iCertify3:
      'Wenn Sie unter 18 Jahre alt sind, muss dies von einem Elternteil oder einem Vormund in Ihrem Namen bestätigt werden.',
    tAndCModalTitle: 'Für Simulator deaktiviert',
    tAndCModalMessage:
      'Detaillierte Geschäftsbedingungen sind nur in der vollständigen Anwendung verfügbar und wurden für den Simulator deaktiviert.',
    confirmText: 'OK',
    continue: 'Weiter',
  },
  'es-US': {
    iCertify1:
      'Certifico que tengo 18 años o más y reconozco que he leído y acepto el',
    iCertify2US:
      ' Aviso de privacidad de la HIPAA, el Acuerdo de licencia de usuario final (EULA, en inglés), los términos de uso (ToU, en inglés) y la garantía. ',
    iCertify2Intl: 'EULA, ToU y garantía',
    iCertify3:
      'Si tienes menos de 18 años, tu padre, madre o tutor deben aceptar en tu nombre.',
    tAndCModalTitle: 'Inhabilitado para el simulador',
    tAndCModalMessage:
      'Los términos y condiciones detallados solo están disponibles en la aplicación completa y están inhabilitados para el simulador.',
    confirmText: 'Aceptar',
    continue: 'Continuar',
  },
  'fr-FR': {
    iCertify1:
      'Je certifie avoir 18 ans ou plus et reconnais avoir lu et accepté la',
    iCertify2US:
      "déclaration de confidentialité HIPAA, le contrat de licence utilisateur final (CLUF), les conditions d'utilisation et la garantie",
    iCertify2Intl:
      "le contrat de licence utilisateur final (CLUF), les conditions d'utilisation et la garantie",
    iCertify3:
      'Si vous avez moins de 18 ans, un parent ou un tuteur doit accepter en votre nom.',
    tAndCModalTitle: 'Désactivé pour le simulateur',
    tAndCModalMessage:
      "Les conditions générales détaillées ne sont disponibles que dans l'application complète et sont désactivées pour le simulateur.",
    confirmText: 'OK',
    continue: 'Continuer',
  },
  'nl-NL': {
    iCertify1: 'Ik verklaar dat ik 18 jaar of ouder ben en dat ik de ',
    iCertify2US:
      'privacyverklaring, licentieovereenkomst voor de eindgebruiker, gebruiksvoorwaarden en garantie heb gelezen en dat ik deze aanvaard.',
    iCertify2Intl: 'EULA, ToU en Garantie',
    iCertify3:
      ' Als u jonger bent dan 18 jaar, moet één van uw ouders of een voogd deze documenten namens u accepteren.',
    tAndCModalTitle: 'Uitgeschakeld voor simulator',
    tAndCModalMessage:
      'Gedetailleerde voorwaarden zijn alleen beschikbaar in de volledige applicatie en zijn uitgeschakeld voor de simulatie.',
    confirmText: 'OK',
    continue: 'Doorgaan',
  },
};

export { strings };
