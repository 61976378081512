import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import {
  MasterNavigationScreen,
  SetupLoadingScreen
} from "../components/screens";

const Stack = createStackNavigator();

const MasterStack: React.FC = () => {
  return (
    <>
      <Stack.Navigator initialRouteName="Simulator" mode="modal">
        <Stack.Screen
          name="Simulator"
          options={{
            headerShown: false
          }}
          component={MasterNavigationScreen}
        />
        <Stack.Screen
          name="Tour"
          options={{
            headerShown: false
          }}
          component={SetupLoadingScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default MasterStack;
