import React from "react";
import { StyleSheet, Text, View } from "react-native-web";
import theme from "../../../theme";

const Segments: React.FC = () => (
  <>
    <View style={[styles.transparentBorder, styles.baseSegment]}>
      <View
        style={{
          flex: 0.33,
          backgroundColor: theme.colors.graphBlue,
          marginBottom: -1.5
        }}></View>
      <View
        style={{
          flex: 0.66,
          justifyContent: "flex-end"
        }}>
        <Text style={[styles.segmentText]}>1</Text>
      </View>
    </View>
    <View
      style={[
        styles.baseSegment,
        {
          borderColor: theme.colors.grayScale.gray100,
          borderWidth: 1,
          borderStyle: "dashed",
          borderRadius: 6
        }
      ]}>
      <View
        style={{
          flex: 0.66,
          backgroundColor: theme.colors.graphBlue,
          marginBottom: -1.5
        }}>
        <Text
          style={[
            styles.segmentText,
            {
              color: theme.colors.white
            }
          ]}>
          2
        </Text>
      </View>
      <View
        style={{
          flex: 0.34,
          width: "100%",
          backgroundColor: theme.colors.white,
          marginTop: -1.5
        }}
      />
    </View>
    <View style={[styles.transparentBorder, styles.baseSegment]}>
      <View
        style={{
          flex: 0.33,
          backgroundColor: theme.colors.graphBlue,
          marginBottom: -1.5
        }}></View>
      <View
        style={{
          flex: 0.66,
          justifyContent: "flex-end"
        }}>
        <Text style={[styles.segmentText]}>1</Text>
      </View>
    </View>
  </>
);

const styles = StyleSheet.create({
  segmentText: {
    textAlign: "center",
    ...theme.fonts.body2,
    fontWeight: "700"
  },
  baseSegment: { flex: 1, flexDirection: "column-reverse" },
  outerSegment: {
    flex: 1,
    flexDirection: "column-reverse"
  },
  transparentBorder: {
    borderColor: "transparent",
    borderWidth: 1,
    borderRadius: 0.0001
  }
});

export default Segments;
