import React, { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { FormattedMessage } from 'react-intl';

import { Button } from 'web/components/ios/Button';
import { ReactComponent as EyeLidIcon } from '../../assets/eye_default.svg';
import { ReactComponent as ShutEyeLidIcon } from '../../assets/eye_off.svg';
import { IOSRoutesEnums } from '../types';
import { messages } from '../FtsStartScreenStack.messages';
import { ConnectingConfirmationModal } from 'web/components/ios/modals/FtsStartModals/FtsStartModals';
import { useDispatch } from 'react-redux';
import {
  hideModal,
  setCurrentModal,
  showModal,
} from 'web/store/reducers/modals/modalsSlice';

export const SignInScreen = () => {
  const { navigate } = useNavigation();
  const dispatch = useDispatch();

  const [showOmnipodId, setShowOmnipodId] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordText, setShowPasswordText] = useState(false);

  const handleDeactivatingPodModal = () => {
    if (showOmnipodId && showPassword) {
      dispatch(
        setCurrentModal({
          modal: (
            <ConnectingConfirmationModal
              onClose={() => {
                navigate(IOSRoutesEnums.OmnipodLinked);
                dispatch(hideModal());
              }}
            />
          ),
        })
      );
      dispatch(showModal());
    }
  };

  return (
    <div className="flex justify-start flex-col flex-1 items-stretch bg-white">
      <div className="px-2 flex flex-1 justify-between flex-col">
        <div className="mt-2">
          <div className="font-normal">
            <FormattedMessage {...messages.enterOmnipodIdMessage} />
          </div>

          <div className="mt-3" onClick={() => setShowOmnipodId(true)}>
            {showOmnipodId ? (
              <div className="text-black">
                <FormattedMessage {...messages.usernameTitle} />
              </div>
            ) : (
              <div className=" text-ios-secondaryGray opacity-60 border-l-2 border-ios-cursorBlue border-solid">
                <FormattedMessage {...messages.omnipodIdTitle} />
              </div>
            )}
            <div className="mt-1 border-t-0.5 border-solid text-ios-secondaryGray" />
          </div>
          <div
            className="mt-3 flex flex-row justify-between"
            onClick={() => setShowPassword(true)}
          >
            {showPassword ? (
              <div className="text-black">
                {showPasswordText ? (
                  <FormattedMessage {...messages.visiblePasswordTitle} />
                ) : (
                  '••••••••••'
                )}
              </div>
            ) : (
              <div className="text-ios-secondaryGray opacity-60">
                <FormattedMessage {...messages.passwordTitle} />
              </div>
            )}
            <div className="flex items-center">
              {showPasswordText ? (
                <EyeLidIcon
                  onClick={() => setShowPasswordText(!showPasswordText)}
                />
              ) : (
                <ShutEyeLidIcon
                  onClick={() => setShowPasswordText(!showPasswordText)}
                />
              )}
            </div>
          </div>
          <div className="mt-1 border-t-0.5 border-solid text-ios-secondaryGray" />

          <div className="text-center mt-4">
            <FormattedMessage {...messages.dontHaveAccountMessage} />
            <div
              className="text-modes-automated mt-2"
              onClick={() => navigate(IOSRoutesEnums.NeedHelp)}
            >
              <FormattedMessage {...messages.needHelpMessage} />
            </div>
          </div>
        </div>

        <div className="w-full mb-4">
          <Button
            className="w-full rounded-lg font-bold"
            onClick={handleDeactivatingPodModal}
            variant={showOmnipodId && showPassword ? 'colored' : 'clear'}
          >
            <FormattedMessage {...messages.submitButtonTitle} />
          </Button>
        </div>
      </div>
    </div>
  );
};
