import React, { useState } from 'react';
import { Button } from 'web/components/ios/Button';
import { ToggleSwitch } from 'web/components/ios/ToggleSwitch';
import { CheckIcon } from 'web/components/ios/icons';
import { useDispatch, useSelector } from 'react-redux';
import { basalAdjustBy } from 'web/store/reducers/basal/actions';
import { useNavigation } from '@react-navigation/native';
import { useIntl } from 'react-intl';
import { messages } from '../BasalTempSettingsStack.messages';
import { RootState } from 'web/store/store';
import { BasalState } from 'web/store/reducers/basal';
import isEqual from 'lodash/isEqual';

enum AdjustByEnum {
  Percent = 'percent (%)',
  FlatRate = 'flat rate (U/hr)',
}

export const TempBasal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { navigate } = useNavigation();

  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );

  const [onToggle, setOnToggle] = useState(!!basalState.adjustBy);
  const [adjustByUnit, setAdjustByUnit] = useState<AdjustByEnum | undefined>(
    basalState.adjustBy as AdjustByEnum
  );

  const handleOnToggle = () => {
    setOnToggle((prevToggle) => {
      if (prevToggle) {
        setAdjustByUnit(undefined);
      } else {
        setAdjustByUnit(AdjustByEnum.Percent);
      }
      return !prevToggle;
    });
  };

  const onHandleAdjustBy = (value: AdjustByEnum) => {
    setAdjustByUnit(value);
  };

  const handleOnSave = () => {
    dispatch(basalAdjustBy(adjustByUnit));
    navigate('BolusSettingsMain');
  };
  return (
    <div className="flex flex-col flex-1 items-stretch ">
      <div className="bg-white">
        <div className="flex flex-col px-2">
          <div className="text-black mt-[29px]">
            {intl.formatMessage(messages.tempBasalChanges)}
          </div>

          <div className="mt-[29px] flex flex-row justify-between">
            <div>
              <div> {intl.formatMessage(messages.tempBasal)}</div>
            </div>
            <div>
              <ToggleSwitch
                defaultValue={!!basalState.adjustBy}
                onToggle={handleOnToggle}
              />
            </div>
          </div>
        </div>
        <hr className="mt-1" />
      </div>
      {onToggle && (
        <div className="pl-2 mt-4 text-ios-secondaryGray opacity-60 text-[13px] mb-[5px]">
          {intl.formatMessage(messages.adjustBy)}
        </div>
      )}
      <div className="bg-white flex flex-1 flex-col items-stretch justify-between">
        <div className={onToggle ? 'visible' : 'invisible'}>
          <div
            className="flex flex-row pl-[22px] py-[17.5px] cursor-pointer"
            onClick={() => onHandleAdjustBy(AdjustByEnum.Percent)}
          >
            <div
              className={
                adjustByUnit === AdjustByEnum.Percent && onToggle
                  ? 'visible'
                  : 'invisible'
              }
            >
              <CheckIcon color="text-ios-purple" />
            </div>
            <div className="ml-2">{`${intl.formatMessage(messages.percent)} (%)`}</div>
          </div>
          <hr />
          <div
            className="flex flex-row pl-[22px] py-[17.5px] cursor-pointer"
            onClick={() => onHandleAdjustBy(AdjustByEnum.FlatRate)}
          >
            <div
              className={
                adjustByUnit === AdjustByEnum.FlatRate && onToggle
                  ? 'visible'
                  : 'invisible'
              }
            >
              <CheckIcon color="text-ios-purple" />
            </div>
            <div className="ml-2">{`${intl.formatMessage(messages.flatRate)} (${intl.formatMessage(messages.basalRateUnit)})`}</div>
          </div>
          <hr />
        </div>

        <div className="w-full mb-4 px-2">
          <Button
            className="w-full rounded-lg"
            onClick={handleOnSave}
            variant="colored"
          >
            {intl.formatMessage(messages.saveButton)}
          </Button>
        </div>
      </div>
    </div>
  );
};
