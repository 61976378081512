import { configurationOptions } from './options';
import { Locale, LocaleMap } from './types';


const localeToNameMap: LocaleMap = {
  'en-US': 'English (US)',
  'en-GB': 'English (UK)',
  'es-US': 'Spanish (US)',
  'de-DE': 'German',
  'nl-NL': 'Dutch',
  'fr-FR': 'French',
};

export const getCurrentLanguageData = ({
  country,
  device,
  language,
}: {
  country: string;
  device: string;
  language: string;
}) => {
  const countryOptions = configurationOptions.find(
    (countryData) => countryData.name === country
  );
  const deviceOptions = countryOptions?.devices.find(
    (deviceData) => deviceData.name === device
  );
  return deviceOptions?.languages.find(
    (languageData) => languageData.locale === language
  );
};

export const getDevices = (country: string) => {
  return configurationOptions.find(
    (countryOptions) => countryOptions.name === country
  )?.devices || [];
};

export const getLanguages = (country: string, device: string) => {
  return getDevices(country)?.find(
    (devices) => devices.name === device
  )?.languages || [];
};

export const getVersions = (country: string, device: string, language: string) => {
  return getLanguages(country, device)?.find(
    (languageOption) => languageOption.name === language
  )?.versions || [];
};

export const getLanguageNameByLocale = (locale: Locale) => {
  return localeToNameMap[locale];
}