import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button } from 'web/components/ios/Button';
import OmnipodConnected from '../../assets/omnipod5-connected.png';
import { IOSRoutesEnums } from '../types';
import { FormattedMessage } from 'react-intl';
import { messages } from '../FtsStartScreenStack.messages';

export const LinkedScreen = () => {
  const { navigate } = useNavigation();

  return (
    <div className="flex justify-between px-2 flex-col flex-1 items-stretch bg-white">
      <div className="flex justify-center items-center flex-col">
        <img
          src={OmnipodConnected}
          alt="Omnipod5 linked"
          style={{ height: 219, width: 170 }}
          className=" mt-16"
        />
        <div className="px-2 mt-16">
          <div className="text-modes-automated text-center ">
            <FormattedMessage {...messages.deviceLinkedMessage} />
          </div>
        </div>
      </div>

      <div className="w-full mb-4">
        <Button
          className="w-full rounded-lg font-bold"
          onClick={() => navigate(IOSRoutesEnums.OmnipodSecurity)}
          variant="colored"
        >
          <FormattedMessage {...messages.continueButtonTitle} />
        </Button>
      </div>
    </div>
  );
};
