let strings = {
  "en-US": {
    stepOneDesc: "Fill new Pod with U-100 insulin",
    stepTwoDesc: "Listen for 2 beeps, then tap ",
    cancel: "Cancel",
    next: "Next",
    modalMessage: "Priming. Please wait...",
    sendingInstruction: "Sending instruction.",
    pleaseWait: "Please wait..."
  },
  "en-GB": {
    stepOneDesc: "Fill new Pod with U-100 insulin",
    stepTwoDesc: "Listen for 2 beeps, then tap ",
    cancel: "Cancel",
    next: "Next",
    modalMessage: "Priming. Please wait...",
    sendingInstruction: "Sending instruction.",
    pleaseWait: "Please wait..."
  },
  "de-DE": {
    stepOneDesc: "Neuen Pod mit U-100-Insulin befüllen",
    stepTwoDesc: "Hören Sie auf 2 Signaltöne und drücken \nSie ",
    cancel: "Abbr.",
    next: "Weiter",
    modalMessage: "Vorbereitung. Bitte warten ...",
    sendingInstruction: "Anweisung wird gesendet.",
    pleaseWait: "Bitte warten ..."
  },
  "es-US": {
    stepOneDesc: "Llene el Pod con 100 U de insulina",
    stepTwoDesc: "Aguarde a escuchar 2 pitidos, leugo \npulse ",
    cancel: "Cancelar",
    next: "Siguiente",
    modalMessage: "Preparando. Espere...",
    sendingInstruction: "Enviando instrucciones.",
    pleaseWait: "Espere..."
  },
  "fr-FR": {
    stepOneDesc: "Remplissez le nouveau Pod avec de l'insuline U-100",
    stepTwoDesc: "Attendez les 2 bips, puis appuyez sur ",
    cancel: "Annuler",
    next: "Suivant",
    modalMessage: "Amorçage. Veuillez patienter...",
    sendingInstruction: "Envoi des instructions.",
    pleaseWait: "Veuillez patienter..."
  },
  "nl-NL": {
    stepOneDesc: "Vul nieuwe Pod met 100-E insuline",
    stepTwoDesc: "Wacht op 2 piepjes, tik vervolgens op\n ",
    cancel: "Annuleren",
    next: "Volgende",
    modalMessage: "Bezig met voorvullen. Even wachten...",
    sendingInstruction: "Instructie wordt verzonden.",
    pleaseWait: "Even wachten..."
  }
};

export { strings };
