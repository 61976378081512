import { useNavigation, useRoute } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  TextInput,
  TouchableWithoutFeedback,
  View
} from "react-native";
import { TouchableOpacity } from "react-native-web";
import { useSelector } from "react-redux";
import HALF_HOURS_DATA from "../../../../../data/halfHoursData";
import { getLocalizedNumberFormat } from "../../../../../helpers/getLocalizedNumberFormat";
import replaceWithValues from "../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import BolusParams from "../../../../../types/bolusParams";
import PickerItem from "../../../../../types/pickerItem";
import { BottomInnerNav, ConfirmationModal } from "../../../../molecules";
import CustomPicker from "../../../../organisms/customPicker";
import { strings } from "./ExtendBolusScreenContent";

interface SummaryParams {
  total: string;
  mealBolus: string;
  correction?: string;
}

const { width } = Dimensions.get("window");
const NOT_HIGHLIGHTED = "";

const Summary: React.FC<SummaryParams> = ({ total, mealBolus, correction }) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);

  return (
    <View
      style={[
        [styles.summary],
        { zIndex: -100, marginTop: -32, marginBottom: 82 }
      ]}>
      <View style={[styles.summaryRow]}>
        <View style={[styles.summaryItem]}>
          <Text style={[[theme.fonts.h4, { fontWeight: "bold" }]]}>
            {languageContent.totalBolus}
          </Text>
        </View>
        <View style={[styles.summaryItem]}>
          <Text style={[theme.fonts.h2, { fontSize: 40 }]}>
            {Number(total).toLocaleString(langState.locale, {
              minimumFractionDigits: 2
            })}
            <Text style={[theme.fonts.h3]}>{` ${languageContent.u}`}</Text>
          </Text>
        </View>
      </View>
      <View style={[styles.summaryRow]}>
        <View style={[styles.summaryItem]}>
          <Text style={[theme.fonts.body1, styles.subItem]}>
            {languageContent.mealBolus}
          </Text>
        </View>
        <View style={[styles.summaryItem]}>
          <Text style={[theme.fonts.body1, styles.subItem]}>
            {`${Number(mealBolus).toLocaleString(langState.locale, {
              minimumFractionDigits: 2
            })} ${languageContent.u}`}
          </Text>
        </View>
      </View>
      <View style={[styles.summaryRow]}>
        <View style={[styles.summaryItem]}>
          <Text style={[theme.fonts.body1, styles.subItem]}>
            {languageContent.correctionBolus}
          </Text>
        </View>
        <View style={[styles.summaryItem]}>
          <Text style={[theme.fonts.body1, styles.subItem]}>
            {`${Number(correction).toLocaleString(langState.locale, {
              minimumFractionDigits: 2
            })} ${languageContent.u}`}
          </Text>
        </View>
      </View>
    </View>
  );
};

/**
 * CONSTANTS
 */
const NOW_KEY = "now";
const EXTENDED_KEY = "extended";

const ExtendBolusScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  /**
   * STATE
   */
  const [nowVal, setNowVal] = React.useState<string>("--");
  const [extendedVal, setExtendedVal] = React.useState<string>("--");
  const [highlightedInput, setHighlightedInput] = React.useState<string>("");
  const [duration, setDuration] = React.useState<PickerItem>({
    label: "––",
    value: -1,
    key: "INIT",
    index: 0
  });

  const [extendedUnits, setExtendedUnits] = React.useState<string>("0");
  const [background, setBackground] = React.useState<string>("");

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showPicker, setShowPicker] = React.useState<boolean>(false);

  const { navigate } = useNavigation();
  const route = useRoute();
  const { languageContent } = useLanguageContent(strings);

  const currentParams = route.params as BolusParams;
  const { total, mealBolus, correction } = currentParams;

  const [nowUnits, setNowUnits] = React.useState<string>(mealBolus);

  /**
   * REFS
   */

  const nowValInput = React.useRef<TextInput>(null);
  const extendedValInput = React.useRef<TextInput>(null);

  /**
   * FUNCTIONS
   */

  const lowerDurationRange = (0.5).toLocaleString(langState.locale);
  const contentVariables = [lowerDurationRange];

  const determineInputHighlighted = (key: string) => {
    if (highlightedInput === key) {
      return { borderColor: theme.colors.blue };
    }
  };

  const handleInputBlur = (key: string) => {
    const nowValNum = Number(nowVal);
    const extendedValNum = Number(extendedVal);
    switch (key) {
      case NOW_KEY:
        if (!nowVal || nowValNum <= 0) {
          setNowVal("0");
          setExtendedVal("100");
        }
        setHighlightedInput("");
        break;
      case EXTENDED_KEY:
        if (!extendedVal || extendedValNum <= 0) {
          setExtendedVal("0");
          setHighlightedInput("");
        }
        break;
      default:
        break;
    }
  };

  const getAdornmentColor = () => {
    if (!duration.label) {
      return theme.colors.placeholder;
    } else if (duration.label === "––") {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  const watchPercentages = React.useCallback(() => {
    const nowValNum = Number(nowVal);
    const extendedValNum = Number(extendedVal);

    // We watch both fields to make sure none of them exceed 100.
    // If it does exceed, we set it to 100 and the opposite to 0 and throw an alert to warn them.
    if (nowValNum > 100) {
      setNowVal("100");
      setExtendedVal("0");
      setShowModal(true);
    } else if (extendedValNum > 100) {
      setNowVal("0");
      setExtendedVal("100");
      setShowModal(true);
    }
  }, [extendedVal, nowVal]);

  /**
   * Calculates the amount of insulin to be used for a Correction Bolus and Total Bolus
   * Incrememnts by 0.05 U.
   * @param currentValue the current raw calculated number before rounding.
   * @param roundUp determines if the current value is rounded up or down to the nearest 0.05 U.
   */
  const roundExtendedVal = (currentValue: number, roundUp: boolean) => {
    let diff;
    let roundedVal;
    let roundedCorrection;
    const array = currentValue.toFixed(2).split(".");

    if (roundUp) {
      diff = 5 - (Number(array[1]) % 5);
      roundedVal = Number(array[1]) + diff;
    } else {
      diff = Number(array[1]) % 5;
      roundedVal = Number(array[1]) - diff;
    }

    if (roundedVal < 10) {
      roundedCorrection = `${array[0]}.0${roundedVal.toString()}`;
    } else {
      roundedCorrection = `${array[0]}.${roundedVal.toString()}`;
    }

    return roundedCorrection;
  };

  const calculateBolusUnits = React.useCallback(() => {
    const totalBolusNum = Number(total);
    // We turn Now and Extended into decimals by dividing by 100.
    const nowValAsPercant = Number(getLocalizedNumberFormat(nowVal)) / 100;
    const extendedValAsPercent =
      Number(getLocalizedNumberFormat(extendedVal)) / 100;

    // We multiply both values by the decimals calculated above to get the units of the percentage.
    // Then we set them to a string, checking to see if there are any decimals and fixing it to two places.
    const now = totalBolusNum * nowValAsPercant;
    const newNowAmount = roundExtendedVal(now, false);
    setNowUnits(newNowAmount);
    const extended = totalBolusNum * extendedValAsPercent;
    const newExtendedAmount =
      extendedVal === "0" ? "0.00" : roundExtendedVal(extended, true);

    setExtendedUnits(newExtendedAmount);
  }, [extendedVal, nowVal, total]);

  const checkShouldProceed = () => {
    let placeholderNowVal = nowVal;
    if (placeholderNowVal.includes(",")) {
      placeholderNowVal = placeholderNowVal.replace(",", ".");
    }
    let placeholderExtendedVal = extendedVal;
    if (placeholderExtendedVal.includes(",")) {
      placeholderExtendedVal = placeholderExtendedVal.replace(",", ".");
    }
    const nowValNum = Number(placeholderNowVal);
    const extendedValNum = Number(placeholderExtendedVal);
    return nowValNum < 100 && extendedValNum > 0 && Number(duration.label) > 0;
  };

  const getConfirmTextColor = () => {
    const proceed = checkShouldProceed();
    if (proceed) {
      return { color: theme.colors.placeholder };
    } else {
      return { color: theme.colors.purple };
    }
  };

  const navigateToConfirmBolus = () => {
    const proceed = checkShouldProceed();
    if (proceed || !proceed) {
      const params: BolusParams = {
        ...currentParams,
        extended: {
          extendedPercentage: extendedVal,
          duration: duration.label,
          nowUnits,
          extendedUnits
        }
      };
      navigate("ConfirmBolus", params);
    }
  };

  const onConfirm = (value: string | null) => {
    const selected = HALF_HOURS_DATA.find(
      (HALF_HOURS_DATA) => HALF_HOURS_DATA.label === value
    );
    setDuration(selected!);
    setBackground(NOT_HIGHLIGHTED);
    setShowPicker(false);
    getConfirmTextColor();
  };

  const onDismiss = () => {
    setBackground(NOT_HIGHLIGHTED);
    setShowPicker(false);
  };

  const handlePressNow = () => {
    setNowVal("55");
  };

  const handlePressExtended = () => {
    setExtendedVal("45");
  };

  const handlePressDuration = () => {
    setDuration({
      label: "4",
      value: -1,
      key: "INIT",
      index: 0
    });
  };

  /**
   * LIFECYCLE
   */

  React.useEffect(() => {
    watchPercentages();
    calculateBolusUnits();
  }, [watchPercentages, calculateBolusUnits]);

  return (
    <View style={{ flex: 1, backgroundColor: "#FFFFFF" }}>
      <View style={[styles.innerWrapper]}>
        <View style={[styles.inputSection]}>
          <View>
            <Text style={[theme.fonts.h4]}>{languageContent.now}</Text>
            <View>
              <View style={[styles.inputCol]}>
                <TouchableWithoutFeedback onPress={handlePressNow}>
                  <TextInput
                    style={[
                      styles.inputStyle,
                      determineInputHighlighted(NOW_KEY)
                    ]}
                    value={nowVal}
                    editable={false}
                    ref={nowValInput}
                    keyboardType="decimal-pad"
                    selectionColor={theme.colors.blue}
                    returnKeyType="done"
                    onBlur={() => handleInputBlur(NOW_KEY)}
                  />
                </TouchableWithoutFeedback>
                <View
                  style={{
                    alignSelf: "flex-end"
                  }}>
                  <Text style={[theme.fonts.body1, styles.percent]}>%</Text>
                </View>
              </View>
            </View>
            <Text style={[theme.fonts.body1, styles.units]}>1.45 U</Text>
          </View>
          <View>
            <Text style={[theme.fonts.h4]}>{languageContent.extended}</Text>
            <View>
              <View style={[styles.inputCol]}>
                <TouchableWithoutFeedback onPress={handlePressExtended}>
                  <TextInput
                    style={[
                      styles.inputStyle,
                      determineInputHighlighted(EXTENDED_KEY)
                    ]}
                    value={extendedVal}
                    editable={false}
                    ref={extendedValInput}
                    keyboardType="decimal-pad"
                    selectionColor={theme.colors.blue}
                    returnKeyType="done"
                    onBlur={() => handleInputBlur(EXTENDED_KEY)}
                  />
                </TouchableWithoutFeedback>
                <View
                  style={{
                    alignSelf: "flex-end"
                  }}>
                  <Text style={[theme.fonts.body1, styles.percent]}>%</Text>
                </View>
              </View>
            </View>
            <Text style={[theme.fonts.body1, styles.units]}>1.25 U</Text>
          </View>
        </View>
        <View
          style={[
            styles.fullRow,
            {
              backgroundColor: background ? "#EEF2F6" : "transparent",
              paddingHorizontal: 16,
              paddingVertical: 12,
              height: 158,
              zIndex: -1,
              marginHorizontal: -16
            }
          ]}>
          <Text style={[theme.fonts.h4]}>{languageContent.duration}</Text>
          <Text style={[styles.durationRange]}>
            {replaceWithValues(languageContent.range, contentVariables)}
          </Text>
          <>
            <TouchableOpacity
              style={[
                theme.layout.inputContainerStyle,
                { marginRight: -10, height: 40 }
              ]}
              activeOpacity={1}
              onPress={handlePressDuration}>
              <TextInput
                style={[
                  theme.layout.inputStyle,
                  {
                    color: theme.colors.pureBlack,
                    fontSize: 32,
                    marginVertical: -8,
                    width: 185
                  }
                ]}
                value={duration.label}
                editable={false}
              />
              <Text
                style={[
                  theme.layout.adornment,
                  {
                    color: getAdornmentColor(),
                    marginBottom: 4
                  }
                ]}>
                {languageContent.hrs}
              </Text>
            </TouchableOpacity>
          </>
          {HALF_HOURS_DATA ? (
            <CustomPicker
              is24Hour={langState.is24Hour}
              locale={langState.locale}
              title={languageContent.chooseDuration}
              values={HALF_HOURS_DATA}
              isVisible={showPicker}
              selectedValue={duration.value === -1 ? undefined : duration.label}
              onConfirm={(value: string | null) => onConfirm(value!)}
              onDismiss={onDismiss}
              units={languageContent.hrs}
            />
          ) : null}
        </View>
        <Summary total={total} mealBolus={mealBolus} correction={correction} />
      </View>
      <BottomInnerNav
        leftActionText={languageContent.leftActionText}
        leftActionStyle={styles.bottomNav}
        rightActionText={languageContent.rightActionText}
        rightActionStyle={
          nowVal !== "--" && extendedVal !== "--" && duration.label !== "––"
            ? styles.bottomNav
            : styles.bottomNavDisabled
        }
        rightAction={navigateToConfirmBolus}
        rightActionDisabled={
          nowVal !== "--" && extendedVal !== "--" && duration.label !== "––"
            ? false
            : true
        }
      />
      <ConfirmationModal
        isVisible={showModal}
        title={languageContent.showValueChangedAlert1}
        message={
          <View>
            <Text style={[theme.fonts.body1]}>
              {languageContent.showValueChangedAlert2}
            </Text>
          </View>
        }
        confirmText={languageContent.ok}
        hideDismiss={true}
        onConfirm={() => setShowModal(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    width: 280,
    padding: 16,
    marginTop: 20
  },
  inputSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 10
  },
  inputCol: {
    flexDirection: "row",
    height: 40,
    width: 6,
    marginLeft: -2
  },
  fullRow: {
    flex: 1,
    marginVertical: 24
  },
  inputStyle: {
    borderWidth: 1,
    borderColor: theme.colors.textInputBorder,
    backgroundColor: theme.colors.textInputBackgroundColor,
    borderRadius: 5,
    paddingHorizontal: 8,
    paddingVertical: 0,
    fontSize: 32,
    textAlignVertical: "bottom",
    minWidth: 90
  },
  percent: {
    marginLeft: 8,
    marginRight: 16
  },
  units: {
    fontStyle: "italic",
    marginTop: 16
  },
  durationRange: {
    ...theme.fonts.text200,
    color: theme.colors.text.grayText,
    marginBottom: 6,
    fontWeight: "400",
    fontSize: 16
  },
  inputContainerStyle: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: theme.colors.textInputBorder,
    backgroundColor: theme.colors.textInputBackgroundColor,
    borderRadius: 5
  },
  adornment: {
    justifyContent: "center",
    alignSelf: "center",
    fontSize: 18,
    marginLeft: 8,
    color: theme.colors.adornment
  },
  summary: {
    marginBottom: 16
  },
  summaryRow: {
    flexDirection: "row",
    width: "100%",
    marginBottom: 4
  },
  summaryItem: {
    width: "60%",
    flexDirection: "row",
    alignItems: "center"
  },
  subItem: {
    color: theme.colors.grayScale.gray400,
    fontWeight: "400"
  },
  ios: {
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 8,
    backgroundColor: theme.colors.textInputBackgroundColor,
    color: "#000",
    fontSize: 40
  },
  androidPickerWrapper: {
    position: "absolute",
    zIndex: 20,
    top: width < 395 ? 5 : 4,
    left: width < 395 ? width / 3.7 : width / 4.6,
    height: 250
  },
  durationInputStyle: {
    fontSize: 40,
    textAlign: "center",
    textAlignVertical: "bottom",
    padding: 0,
    margin: 0,
    color: "#000"
  },
  bottomNav: {
    fontSize: 14,
    color: theme.colors.purple
  },
  bottomNavDisabled: {
    fontSize: 14,
    color: "#B3BAC3"
  }
});

export default ExtendBolusScreen;
