import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { Button } from 'web/components/ios/Button';
import { FormattedMessage } from 'react-intl';
import { messages } from '../TempBasal.messages';
import tempBasalNoValuesSelected from 'assets/ios/temp-basal-no-values-selected.svg';
import tempBasalRateValueSelected from 'assets/ios/temp-basal-rate-value-selected.svg';
import tempBasalDurationValueSelected from 'assets/ios/temp-basal-duration-value-selected.svg';
import { IOSTempBasalEnum } from '../types';

interface TempBasalProps {
  editMode: boolean;
}

export const SetTempBasal: React.FC<TempBasalProps> = ({ editMode }) => {
  const { navigate } = useNavigation();
  const [basalRateIsClicked, setBasalRateIsClicked] = useState(false);
  const [durationIsClicked, setDurationTimeIsClicked] = useState(false);

  useEffect(() => {
    setBasalRateIsClicked(editMode);
    setDurationTimeIsClicked(editMode);
  }, []);

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-3/12 p-6 pt-8 overflow-hidden">
        {!basalRateIsClicked && !durationIsClicked && (
          <img src={tempBasalNoValuesSelected} alt="no-values-selected" />
        )}
        {basalRateIsClicked && !durationIsClicked && (
          <img src={tempBasalRateValueSelected} alt="basal-rate-selected" />
        )}
        {basalRateIsClicked && durationIsClicked && (
          <img src={tempBasalDurationValueSelected} alt="duration-selected" />
        )}
      </div>
      <div className="basis-9/12">
        <div className="pt-2 w-full h-full flex flex-col justify-between">
          <div>
            <div
              onClick={() => setBasalRateIsClicked(true)}
              className="flex justify-between items-center w-full py-1 border-b border-b-ios-gray-7 hover:cursor-pointer"
            >
              <div className="pl-6">
                <p>
                  <FormattedMessage {...messages.basalRateInputLabel} />
                </p>
                <p className="font-semibold text-xs text-ios-gray-2">
                  <FormattedMessage {...messages.basalRateInputPlaceholder} />
                </p>
              </div>
              <div className="pr-6">
                <p
                  className={`${basalRateIsClicked ? 'text-modes-manual' : 'text-ios-gray-2'}`}
                >
                  {basalRateIsClicked ? (
                    <>
                      <FormattedMessage {...messages.basalRateInputValue} />
                    </>
                  ) : (
                    <>
                      <FormattedMessage {...messages.emptyValue} />
                      <span className="ml-0.5">
                        <FormattedMessage {...messages.percentage} />
                      </span>
                    </>
                  )}
                </p>
              </div>
            </div>
            <div
              onClick={() =>
                basalRateIsClicked && setDurationTimeIsClicked(true)
              }
              className="flex justify-between items-center w-full py-1 border-b border-b-ios-gray-7 hover:cursor-pointer"
            >
              <div className="pl-6">
                <p>
                  {' '}
                  <FormattedMessage {...messages.durationInputLabel} />
                </p>

                <p className="font-semibold text-xs text-ios-gray-2">
                  <FormattedMessage {...messages.durationInputPlaceholder} />
                </p>
              </div>
              <div className="pr-6">
                <p
                  className={` ${durationIsClicked ? 'text-modes-manual' : 'text-ios-gray-2'}`}
                >
                  {basalRateIsClicked && durationIsClicked ? (
                    <FormattedMessage {...messages.durationInputValue} />
                  ) : (
                    <FormattedMessage {...messages.emptyValue} />
                  )}
                  <span className="ml-0.5">
                    <FormattedMessage {...messages.hours} />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full p-6">
            <Button
              onClick={() =>
                basalRateIsClicked &&
                durationIsClicked &&
                navigate(IOSTempBasalEnum.ConfirmTempBasal)
              }
              colorMode="manual"
              variant="colored"
              disabled={!(basalRateIsClicked && durationIsClicked)}
            >
              <FormattedMessage {...messages.confirmBtn} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
