import PickerItem from "../types/pickerItem";

const BOLUS_SEG_ONE_DATA_TWO_MMOL: Array<PickerItem> = [];
let counter = 0;

for (let i = 6.1; i <= 11.1; i += 0.551) {
  BOLUS_SEG_ONE_DATA_TWO_MMOL.push({
    label: String(Math.round(10 * i) / 10),
    value: Math.round(10 * i) / 10,
    key: String(Math.round(10 * i) / 10),
    index: ++counter
  });
}

export default BOLUS_SEG_ONE_DATA_TWO_MMOL;
