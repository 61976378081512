let strings = {
  "en-US": {
    hypoDetails1:
      "Use the Activity feature during times of increased risk of hypoglycemia.",
    hypoDetails2:
      "The Activity feature reduces basal insulin delivery and sets basal delivery Target Glucose to %1"
  },
  "en-GB": {
    hypoDetails1:
      "Use the Activity feature during times of increased risk of hypoglycaemia.",
    hypoDetails2:
      "The Activity feature reduces basal insulin delivery and sets basal delivery Target Glucose to %1"
  },
  "de-DE": {
    hypoDetails1:
      "Verwenden Sie die Aktivitätsfunktion in Zeiten mit erhöhtem Hypoglykämierisiko.",
    hypoDetails2:
      "Die Aktivitätsfunktion reduziert die Basalinsulinabgabe und setzt den Glukose-Zielwert für die Basalabgabe auf %1"
  },
  "es-US": {
    hypoDetails1:
      "Utilice la función Actividad en los momentos de aumento del riesgo de hipoglucemia.",
    hypoDetails2:
      "La función de Actividad reduce la administración de insulina basal y establece la glucosa objetivo de la administratión basal en %1"
  },
  "fr-FR": {
    hypoDetails1:
      "Utilisez la fonction Activité pendant les périodes de risque accru d'hypoglycémie.",
    hypoDetails2:
      "La fonction Activité réduit l'administration d'insuline basale et configure le glucose cible de l'administration basale à %1"
  },
  "nl-NL": {
    hypoDetails1:
      "Gebruik het activiteitskenmerk tijdens momenten met een verhoogd risico op hypoglykemie.",
    hypoDetails2:
      "Het activiteitskenmerk vermindert de basale insulinetoediening en stelt de basale toediening van de streefwaarde glucose in op 8,3 mmol/L."
  }
};

export { strings };
