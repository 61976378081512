import React from "react";
import { Animated, Pressable, StyleSheet, Text, View } from "react-native";
import { ReactComponent as Edit } from "../../../../../assets/UI_Edit.svg";
import theme from "../../../theme";

interface Props {
  onEditPress: (index: number) => void;
  index: number;
  segmentLabel: string;
  startTime: string;
  endTime: string;
  firstRateOrBoldedLabel?: string;
  firstRateValue: string;
  secondRateOrBoldedLabel?: string;
  secondRateValue?: string;
  containsAnimatedTouchable?: boolean;
  animate?: boolean;
}

const SegmentListItem: React.FC<Props> = ({
  onEditPress,
  index,
  segmentLabel,
  firstRateOrBoldedLabel,
  firstRateValue,
  secondRateOrBoldedLabel,
  secondRateValue,
  containsAnimatedTouchable,
  animate,
  startTime,
  endTime
}) => {
  const removeHighlight = () => {
    Animated.timing(highlightEditRef, {
      toValue: 0,
      duration: 350,
      useNativeDriver: false
    }).start();
  };

  const addHighlight = () => {
    Animated.timing(highlightEditRef, {
      toValue: 100,
      duration: 350,
      useNativeDriver: false
    }).start();
    setTimeout(() => {
      removeHighlight();
    }, 350);
  };

  const highlightButton = () => {
    addHighlight();
    setTimeout(() => {
      addHighlight();
    }, 700);
  };

  const highlightEditRef = React.useRef(new Animated.Value(0)).current;

  return (
    <>
      <View style={styles.segmentRow}>
        <View>
          <Text>
            <Text style={[theme.fonts.body1, { fontWeight: "500" }]}>
              {`${segmentLabel} `}
            </Text>
            <Text style={[theme.fonts.body1, { color: theme.colors.blue }]}>
              {startTime} - {endTime}
            </Text>
          </Text>
          <Text style={[theme.fonts.body1, { color: theme.colors.blue }]}>
            <Text style={[{ fontWeight: "400" }]}>
              {firstRateOrBoldedLabel && firstRateOrBoldedLabel}
              {firstRateOrBoldedLabel && " "}
            </Text>
            {firstRateValue}
          </Text>
          {secondRateValue && (
            <Text style={[theme.fonts.body1, { color: theme.colors.blue }]}>
              <Text style={[{ fontWeight: "400" }]}>
                {secondRateOrBoldedLabel && secondRateOrBoldedLabel}
                {secondRateOrBoldedLabel && " "}
              </Text>
              {secondRateValue}
            </Text>
          )}
        </View>
        <View style={[{ justifyContent: "center", padding: 8 }]}>
          {containsAnimatedTouchable ? (
            <Pressable
              onPress={() => onEditPress(index)}
              style={{
                borderRadius: 5,
                padding: 8
              }}>
              <>
                {animate && highlightButton()}
                <Edit />
              </>
            </Pressable>
          ) : (
            <Pressable onPress={() => onEditPress(index)}>
              <Edit />
            </Pressable>
          )}
        </View>
      </View>
      <View style={[styles.break]} />
    </>
  );
};

const styles = StyleSheet.create({
  segmentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 4,
    marginLeft: 4,
    marginVertical: 16
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.divider
  }
});
export default SegmentListItem;
