import { useNavigation, useRoute } from "@react-navigation/native";
import { isEqual } from "lodash";
import moment from "moment";
import React from "react";
import {
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { asyncTimeout } from "../../../../../../helpers";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import truncate from "../../../../../../helpers/truncate";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { basalActions } from "../../../../../../../../web/store/reducers/basal";
import {
  BasalProgram,
  BasalState,
  defaultActiveBasalProgram,
  defaultExpertBasalProgramsInactive,
  emptyExpertBasalProgram,
  EVERYDAY_PROGRAM_NAME,
  NEW_BASAL_PROGRAM_ID,
  WEEKEND_PROGRAM_NAME
} from "../../../../../../../../web/store/reducers/basal/reducer";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { ListHeader } from "../../../../../atoms";
import {
  BasalProgramEntry,
  BottomInnerNav,
  CompletedBasalProgramGraph,
  ConfirmationModal,
  ExpertProgramCardModal,
  PodCommAlert,
  WarningMessageBar
} from "../../../../../molecules";
import { BasalProgramsEditMenu } from "../../../../../organisms";
import { strings } from "./ExpertBasalProgramsScreenContent";

export interface StartProgramModal {
  visible: boolean;
  programName: string;
}

const defaultStartProgramModalState = {
  visible: false,
  programName: "",
  isNew: false
};

const ExpertBasalProgramsScreen: React.FC = () => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [editMenu, setEditMenu] = React.useState<string>("");
  const [editModal, setEditModal] = React.useState<boolean>(false);
  const [editModalProgram, setEditModalProgram] = React.useState<BasalProgram>(
    emptyExpertBasalProgram
  );
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [editNewProgramMenu, setNewProgramEditMenu] =
    React.useState<boolean>(false);
  const [startProgramModal, setStartProgramModal] =
    React.useState<StartProgramModal>(defaultStartProgramModalState);
  const [showStartPodComm, setShowStartPodComm] =
    React.useState<boolean>(false);
  const [programToStart, setProgramToStart] = React.useState<BasalProgram>(
    defaultActiveBasalProgram
  );
  const [pauseInsulinModal, setPauseInsulinModal] =
    React.useState<boolean>(false);
  const [showPauseInsulinPodComm, setShowPauseInsulinPodComm] =
    React.useState<boolean>(false);

  const [initialRender, setInitialRender] = React.useState<boolean>(true);

  const { navigate } = useNavigation();
  const { height } = useWindowDimensions();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);

  const activeBasalUnitsPerDay =
    basalState.activeExpertBasalProgram.unitsPerDay.toLocaleString(
      langState.locale
    );
  const basalProgramName = truncate(
    basalState.newExpertBasalProgram.programName,
    15
  );
  const startProgramName = startProgramModal.programName;
  const contentVariables = [
    activeBasalUnitsPerDay,
    basalProgramName,
    startProgramName
  ];

  const programMarginTopAdjustment =
    height > 800 || height < 700 ? -112 : -112.5;

  const getNumberOfProgramsString = () => {
    let num = basalState.expertBasalPrograms.length;
    if (basalState.newExpertBasalProgram.segments.length > 0) {
      return String(num);
    } else {
      return String(num - 1);
    }
  };

  const closeAllEditMenus = () => {
    setNewProgramEditMenu(false);
    setEditMenu("");
  };

  const startBasalProgram = async () => {
    await asyncTimeout(500);
    if (programToStart.id === NEW_BASAL_PROGRAM_ID) {
      dispatch(
        basalActions.basalUpdate(
          {
            activeExpertBasalProgram: programToStart,
            expertBasalPrograms: defaultExpertBasalProgramsInactive,
            newExpertBasalProgram: {
              ...basalState.newExpertBasalProgram,
              isActive: true
            }
          },
          basalState
        )
      );
    } else {
      if (basalState.insulinPaused) {
        dispatch(
          basalActions.basalUpdate(
            {
              insulinPaused: false
            },
            basalState
          )
        );
      }
      const updatedExpertBasalPrograms = basalState.expertBasalPrograms.map(
        (program) => {
          if (program.id === programToStart.id) {
            return { ...program, isActive: true };
          }
          return { ...program, isActive: false };
        }
      );
      dispatch(
        basalActions.basalUpdate(
          {
            activeExpertBasalProgram: programToStart,
            expertBasalPrograms: updatedExpertBasalPrograms,
            newExpertBasalProgram: {
              ...basalState.newExpertBasalProgram,
              isActive: false
            }
          },
          basalState
        )
      );
    }
  };

  React.useEffect(() => {
    if (initialRender) {
      const segmentLength = basalState.newExpertBasalProgram.segments.length;
      if (
        segmentLength >= 1 &&
        !basalState.newExpertBasalProgram.isComplete &&
        basalState.copyOfNewExpertBasalProgram.segments.length > 0
      ) {
        dispatch(
          basalActions.basalUpdate(
            {
              newExpertBasalProgram: basalState.copyOfNewExpertBasalProgram,
              copyOfNewExpertBasalProgram: emptyExpertBasalProgram
            },
            basalState
          )
        );
      }
      if (segmentLength >= 1 && !basalState.newExpertBasalProgram.isComplete) {
        dispatch(
          basalActions.basalUpdate(
            {
              newExpertBasalProgram: emptyExpertBasalProgram
            },
            basalState
          )
        );
      }

      setInitialRender(false);
    }
  }, [basalState, dispatch, initialRender]);

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          closeAllEditMenus();
        }}
        activeOpacity={1}
        style={[styles.container]}>
        <View>
          {basalState.insulinPaused && <WarningMessageBar insulinDelivery />}
          <View style={styles.topCard}>
            <View style={styles.topCardHeader}>
              <View>
                <Text style={[theme.fonts.h3]}>
                  {truncate(
                    basalState.activeExpertBasalProgram.programName,
                    20
                  )}
                </Text>
                <Text style={[theme.fonts.body1, { color: theme.colors.blue }]}>
                  {languageContent.inProgress}
                </Text>
              </View>
            </View>
            <CompletedBasalProgramGraph
              program={basalState.activeExpertBasalProgram}
            />
            <View style={styles.topCardFooter}>
              <Text style={[styles.topCardFooterTotal, theme.fonts.h4]}>
                {replaceWithValues(languageContent.total, contentVariables)}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  if (basalState.newExpertBasalProgram.isActive) {
                    if (basalState.insulinPaused) {
                      setShowPauseInsulinPodComm(true);
                    } else {
                      setPauseInsulinModal(true);
                    }
                  }
                }}>
                <Text style={[theme.fonts.body1, styles.topCardFooterEdit]}>
                  {languageContent.edit}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <ListHeader
            label={languageContent.savedBasal}
            numOfSegments={getNumberOfProgramsString()}
          />
          {/* custom basal program */}
          {!basalState.newExpertBasalProgram.isActive &&
            basalState.newExpertBasalProgram &&
            basalState.newExpertBasalProgram.unitsPerDay !== 0 && (
              <TouchableOpacity
                onPress={() => {
                  setEditModalProgram(basalState.newExpertBasalProgram);
                  setEditModal(true);
                }}
                style={{
                  zIndex: 20,
                  elevation: 10
                }}>
                <BasalProgramEntry
                  onPressModal={() => {
                    setEditModalProgram(basalState.newExpertBasalProgram);
                    setEditModal(true);
                  }}
                  onPressMenu={() => {
                    setEditMenu("");
                    setNewProgramEditMenu(true);
                  }}
                  programName={truncate(
                    basalState.newExpertBasalProgram.programName,
                    25
                  )}
                  unitsPerDay={basalState.newExpertBasalProgram.unitsPerDay.toString()}
                  customEntryStyle={styles.customEntryStyle}
                />
                <View style={styles.break} />
                {editNewProgramMenu && (
                  <BasalProgramsEditMenu
                    onPressStart={() => {
                      setStartProgramModal({
                        visible: true,
                        programName: truncate(
                          basalState.newExpertBasalProgram.programName,
                          15
                        )
                      });
                      setProgramToStart({
                        ...basalState.newExpertBasalProgram,
                        isActive: true
                      });
                    }}
                    onPressEdit={() => {
                      closeAllEditMenus();
                      dispatch(
                        basalActions.basalUpdate(
                          {
                            copyOfNewExpertBasalProgram:
                              basalState.newExpertBasalProgram
                          },
                          basalState
                        )
                      );
                      navigate("ExpertProgramEntry", { edit: true });
                    }}
                    onPressDelete={() => {
                      setNewProgramEditMenu(false);
                      setShowDeleteModal(true);
                    }}
                  />
                )}
              </TouchableOpacity>
            )}
          {/* First Expert Program */}
          {basalState.activeExpertBasalProgram.programName !==
          EVERYDAY_PROGRAM_NAME ? (
            <View pointerEvents="box-none">
              <TouchableOpacity
                onPress={() => {
                  setEditModalProgram(basalState.expertBasalPrograms[0]);
                  setEditModal(true);
                }}
                style={{
                  zIndex: 20,
                  elevation: 0,
                  marginTop: editNewProgramMenu ? programMarginTopAdjustment : 0
                }}>
                <BasalProgramEntry
                  onPressMenu={(programName) => {
                    setNewProgramEditMenu(false);
                    setEditMenu(programName);
                  }}
                  onPressModal={() => {
                    setEditModalProgram(basalState.expertBasalPrograms[0]);
                    setEditModal(true);
                  }}
                  programName={basalState.expertBasalPrograms[0].programName}
                  unitsPerDay={basalState.expertBasalPrograms[0].unitsPerDay.toString()}
                  customEntryStyle={[styles.customEntryStyle]}
                />
                <View style={styles.break} />
                <ConfirmationModal
                  isVisible={showDeleteModal}
                  title={replaceWithValues(
                    languageContent.deleteProgram,
                    contentVariables
                  )}
                  message={
                    <View>
                      <Text style={[theme.fonts.body1]}>
                        {`${languageContent.sureDelete} `}
                        <Text style={{ fontWeight: "bold" }}>
                          {`${basalProgramName} `}
                        </Text>
                        {languageContent.basalProgram}
                      </Text>
                      <Text style={{ fontStyle: "italic", marginTop: 12 }}>
                        {languageContent.cannotBeUndone}
                      </Text>
                    </View>
                  }
                  dismissText={languageContent.cancel}
                  confirmText={languageContent.delete}
                  onConfirm={() => {
                    dispatch(
                      basalActions.basalUpdate(
                        {
                          newExpertBasalProgram: emptyExpertBasalProgram
                        },
                        basalState
                      )
                    );
                    setShowDeleteModal(false);
                  }}
                  onDismiss={() => setShowDeleteModal(false)}
                />
              </TouchableOpacity>
              {basalState.expertBasalPrograms[0].programName === editMenu && (
                <BasalProgramsEditMenu
                  onPressStart={() => {
                    setStartProgramModal({
                      visible: true,
                      programName: basalState.expertBasalPrograms[0].programName
                    });
                    setProgramToStart({
                      ...basalState.expertBasalPrograms[0],
                      isActive: true
                    });
                  }}
                />
              )}
            </View>
          ) : null}
          {/* Second Expert Program */}
          {basalState.activeExpertBasalProgram.programName !==
          WEEKEND_PROGRAM_NAME ? (
            <View pointerEvents="box-none" style={{ zIndex: -1 }}>
              <TouchableOpacity
                onPress={() => {
                  setEditModalProgram(basalState.expertBasalPrograms[1]);
                  setEditModal(true);
                }}
                style={{
                  elevation: 0,
                  marginTop:
                    editMenu === EVERYDAY_PROGRAM_NAME || editNewProgramMenu
                      ? programMarginTopAdjustment
                      : 0
                }}>
                <BasalProgramEntry
                  onPressMenu={(programName) => {
                    setNewProgramEditMenu(false);
                    setEditMenu(programName);
                  }}
                  onPressModal={() => {
                    setEditModalProgram(basalState.expertBasalPrograms[1]);
                    setEditModal(true);
                  }}
                  programName={basalState.expertBasalPrograms[1].programName}
                  unitsPerDay={basalState.expertBasalPrograms[1].unitsPerDay.toString()}
                  customEntryStyle={[styles.customEntryStyle]}
                />
                <View style={[styles.break]} />
                <ConfirmationModal
                  isVisible={showDeleteModal}
                  title={`${languageContent.delete} ${truncate(
                    basalState.newExpertBasalProgram.programName,
                    15
                  )}?`}
                  message={
                    <View>
                      <Text style={[theme.fonts.body1]}>
                        {`${languageContent.sureDelete} `}
                        <Text style={{ fontWeight: "bold" }}>
                          {truncate(
                            basalState.newExpertBasalProgram.programName,
                            15
                          )}
                        </Text>
                        {` ${languageContent.basalProgram}`}
                      </Text>
                      <Text style={{ fontStyle: "italic", marginTop: 12 }}>
                        {languageContent.cannotBeUndone}
                      </Text>
                    </View>
                  }
                  dismissText={languageContent.cancel}
                  confirmText={languageContent.delete}
                  onConfirm={() => {
                    dispatch(
                      basalActions.basalUpdate(
                        {
                          newExpertBasalProgram: emptyExpertBasalProgram
                        },
                        basalState
                      )
                    );
                    setShowDeleteModal(false);
                  }}
                  onDismiss={() => setShowDeleteModal(false)}
                />
              </TouchableOpacity>
              {basalState.expertBasalPrograms[1].programName === editMenu && (
                <BasalProgramsEditMenu
                  onPressStart={() => {
                    setStartProgramModal({
                      visible: true,
                      programName: basalState.expertBasalPrograms[1].programName
                    });
                    setProgramToStart({
                      ...basalState.expertBasalPrograms[1],
                      isActive: true
                    });
                  }}
                />
              )}
            </View>
          ) : null}
        </View>
        <BottomInnerNav
          leftActionEmpty={true}
          rightActionText={languageContent.createNew}
          rightAction={() => {
            if (!basalState.newExpertBasalProgram.isComplete) {
              navigate("ExpertProgramEntry");
            }
          }}
          rightActionStyle={{ color: theme.colors.purple }}
        />
        <Modal
          visible={editModal}
          transparent={true}
          onRequestClose={() => {
            closeAllEditMenus();
          }}
          onShow={() => {
            closeAllEditMenus();
          }}>
          <TouchableOpacity
            style={{ zIndex: 1 }}
            onPress={() => setEditModal(false)}>
            <View
              style={{ backgroundColor: "rgba(0,0,0,0.5)", height: "100%" }}>
              <ExpertProgramCardModal
                program={editModalProgram}
                pressStart={() => {
                  setEditModal(false);
                  setStartProgramModal({
                    ...startProgramModal,
                    programName: editModalProgram.programName,
                    visible: true
                  });
                  setProgramToStart({ ...editModalProgram });
                }}
                pressEdit={() => {
                  setEditModal(false);
                  if (editModalProgram.id === NEW_BASAL_PROGRAM_ID) {
                    dispatch(
                      basalActions.basalUpdate(
                        {
                          copyOfNewExpertBasalProgram:
                            basalState.newExpertBasalProgram
                        },
                        basalState
                      )
                    );
                    navigate("ExpertProgramEntry", { edit: true });
                  }
                }}
              />
            </View>
          </TouchableOpacity>
        </Modal>
      </TouchableOpacity>
      <ConfirmationModal
        isVisible={startProgramModal.visible}
        title={replaceWithValues(languageContent.startThe, contentVariables)}
        confirmText={languageContent.start}
        dismissText={languageContent.cancel}
        onConfirm={() => {
          setStartProgramModal(defaultStartProgramModalState);
          setShowStartPodComm(true);
        }}
        onDismiss={() => {
          setStartProgramModal(defaultStartProgramModalState);
        }}
      />
      <ConfirmationModal
        isVisible={pauseInsulinModal}
        title={languageContent.pauseInsulinTitle}
        message={
          <View>
            <Text style={[theme.fonts.body1, { marginBottom: 16 }]}>
              {languageContent.pauseInsulinMessage}
            </Text>
            <Text style={theme.fonts.body1}>
              {languageContent.pauseInsulinMessageTwo}
            </Text>
          </View>
        }
        confirmText={languageContent.yes}
        dismissText={languageContent.no}
        onConfirm={() => {
          setPauseInsulinModal(false);
          setShowPauseInsulinPodComm(true);
          const today = new Date();
          dispatch(
            basalActions.basalUpdate(
              {
                insulinPaused: true,
                insulinPausedTimestamp: String(
                  moment(today).add(2, "h").toDate()
                )
              },
              basalState
            )
          );

          dispatch(
            basalActions.basalUpdate(
              {
                copyOfNewExpertBasalProgram: basalState.activeExpertBasalProgram
              },
              basalState
            )
          );
        }}
        onDismiss={() => {
          setPauseInsulinModal(false);
        }}
      />
      {showStartPodComm && (
        <PodCommAlert
          duration={1000}
          screen="Drawer"
          onDismiss={startBasalProgram}
        />
      )}
      {showPauseInsulinPodComm && (
        <PodCommAlert
          duration={1000}
          screen="ExpertProgramEntry"
          screenProps={{ edit: true }}
          onDismiss={() => {
            setShowPauseInsulinPodComm(false);
          }}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between"
  },
  topCard: {
    justifyContent: "space-between",
    backgroundColor: theme.colors.white,
    margin: 16,
    zIndex: 20,
    elevation: 3,
    shadowColor: "#888",
    shadowRadius: 3,
    shadowOpacity: 0.5,
    shadowOffset: {
      width: 0,
      height: 4
    }
  },
  topCardHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  topCardFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 8
  },
  topCardFooterTotal: {
    paddingHorizontal: 16,
    marginVertical: 8
  },
  topCardFooterEdit: {
    paddingHorizontal: 16,
    marginVertical: 8,
    textTransform: "uppercase",
    color: theme.colors.purple,
    fontWeight: "bold"
  },
  segmentHeader: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.textInputBorder,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    backgroundColor: "#EDF2F6"
  },
  customEntryStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 16
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  graphView: {
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "row"
  }
});

export default ExpertBasalProgramsScreen;
