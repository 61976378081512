let strings = {
  "en-US": {
    noActivePod: "No active Pod",
    setupNewPod: "Set Up New Pod",
    modalTitle: "Disabled for simulation",
    modalMessage: "You must activate a Pod in the simulated environment.",
    ok: "OK",
    close: "Close"
  },
  "en-GB": {
    noActivePod: "No active Pod",
    setupNewPod: "Set Up New Pod",
    modalTitle: "Disabled for simulation",
    modalMessage: "You must activate a Pod in the simulated environment.",
    ok: "OK",
    close: "Close"
  },
  "de-DE": {
    noActivePod: "Kein aktiver Pod",
    setupNewPod: "Neuen Pod einrichten",
    modalTitle: "Für Simulation deaktiviert",
    modalMessage:
      "Sie müssen einen Pod in der simulierten Umgebung aktivieren.",
    ok: "OK",
    close: "Schließen"
  },
  "es-US": {
    noActivePod: "No hay ningún Pod activo",
    setupNewPod: "Configurar nuevo Pod",
    modalTitle: "Inhabilitado para la simulación",
    modalMessage: "Debe activar un Pod en el ambiente simulado.",
    ok: "Aceptar",
    close: "Cerrar"
  },
  "fr-FR": {
    noActivePod: "Aucun Pod actif",
    setupNewPod: "Configurer un nouveau Pod",
    modalTitle: "Désactivé pour la simulation",
    modalMessage:
      "Vous devez activer un Pod dans l'environnement de simulation.",
    ok: "OK",
    close: "Fermer"
  },
  "nl-NL": {
    noActivePod: "Geen actieve Pod",
    setupNewPod: "Nieuwe Pod instellen",
    modalTitle: "Uitgeschakeld voor simulatie",
    modalMessage: "U moet een Pod activeren in de gesimuleerde omgeving.",
    ok: "OK",
    close: "Sluiten"
  }
};

export { strings };
