import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { Button } from 'web/components/ios/Button';
import { useIntl } from 'react-intl';
import { messages, CreateBasalSegmentsMessages } from '../Basal.messages';
interface props {
  basalSegmentTitle: string;
  segmentNoEndTimeSelected: any;
  segmentEndTimeSelected: any;
  segmentUnitSelected: any;
  startTimeValue: string;
  endTimeValue: string;
  unitValue: string;
  navigationRoute: string;
  editMode: boolean;
}

export const CreateBasalSegment: React.FC<props> = ({
  basalSegmentTitle,
  segmentNoEndTimeSelected,
  segmentEndTimeSelected,
  segmentUnitSelected,
  startTimeValue,
  endTimeValue,
  unitValue,
  navigationRoute,
  editMode,
}) => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const [endTimeIsClicked, setEndTimeIsClicked] = useState(false);
  const [basalRateIsClicked, setBasalRateIsClicked] = useState(false);

  useEffect(() => {
    setEndTimeIsClicked(editMode);
    setBasalRateIsClicked(editMode);
  }, []);

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-2/5 p-6 overflow-hidden">
        <p className="font-bold text-lg text-modes-manual">
          {intl.formatMessage(messages.basalProgramName)}
        </p>
        <p className="font-bold text-xl mb-2">{basalSegmentTitle}</p>
        {!endTimeIsClicked && !basalRateIsClicked && (
          <img
            src={segmentNoEndTimeSelected}
            alt="segment-one-no-end-time-selected"
          />
        )}
        {endTimeIsClicked && !basalRateIsClicked && (
          <img
            src={segmentEndTimeSelected}
            alt="segment-one-end-time-selected"
          />
        )}
        {endTimeIsClicked && basalRateIsClicked && (
          <img src={segmentUnitSelected} alt="segment-one-unit-selected" />
        )}
      </div>
      <div className="basis-9/12">
        <div className="pt-2 w-full h-full flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-center mb-2 w-full pb-2 border-b border-b-ios-gray-7">
              <div>
                <p className="pl-6">
                  {intl.formatMessage(
                    CreateBasalSegmentsMessages.startTimeInputLabel
                  )}
                </p>
              </div>
              <div>
                <p className="pr-6 font-semibold text-ios-gray-2">
                  <span>{startTimeValue}</span>
                </p>
              </div>
            </div>
            <div
              onClick={() => setEndTimeIsClicked(true)}
              className="flex justify-between items-center mb-2 w-full pb-2 border-b border-b-ios-gray-7 hover:cursor-pointer"
            >
              <div>
                <p className="pl-6">
                  {intl.formatMessage(
                    CreateBasalSegmentsMessages.endTimeInputLabel
                  )}
                </p>
              </div>
              <div>
                <p
                  className={`pr-6 font-semibold ${endTimeIsClicked ? 'text-modes-automated' : 'text-ios-gray-2'}`}
                >
                  <span>
                    {endTimeIsClicked
                      ? endTimeValue
                      : intl.formatMessage(messages.emptyValue)}
                  </span>
                </p>
              </div>
            </div>
            <div
              onClick={() => endTimeIsClicked && setBasalRateIsClicked(true)}
              className="flex justify-between items-center w-full pb-2 border-b border-b-ios-gray-7 hover:cursor-pointer"
            >
              <div className="pl-6">
                <p>
                  {intl.formatMessage(
                    CreateBasalSegmentsMessages.basalRateInputLabel
                  )}
                </p>
                <p className="font-semibold text-xs text-ios-gray-2">
                  {intl.formatMessage(messages.basalRateInputPlaceholder)}
                </p>
              </div>
              <div className="pr-6">
                <p
                  className={`font-semibold ${basalRateIsClicked ? 'text-modes-automated' : 'text-ios-gray-2'}`}
                >
                  <span>
                    {basalRateIsClicked
                      ? unitValue
                      : intl.formatMessage(messages.emptyValue)}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full p-6">
            <Button
              onClick={() =>
                endTimeIsClicked &&
                basalRateIsClicked &&
                navigate(navigationRoute)
              }
              variant={
                endTimeIsClicked && basalRateIsClicked ? 'colored' : 'clear'
              }
            >
              {editMode
                ? intl.formatMessage(messages.saveBtn)
                : intl.formatMessage(messages.nextBtn)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
