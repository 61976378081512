import React from 'react';
import { useIntl } from 'react-intl';
import { createStackNavigator } from '@react-navigation/stack';
import { TopBar } from 'web/components/ios/TopBar';
import { EditProgramName } from './EditBasalProgramStack/views/EditProgramName';
import { ConfirmBasalProgram } from './EditBasalProgramStack/views/ConfirmBasalProgram';
import { EditSegment } from './EditBasalProgramStack/views/EditSegment';
import { BasalProgramsRoutesEnum } from '../types';
import { createBasalProgramMessages, confirmBasalProgramMessages } from '../BasalPrograms.messages';

const Stack = createStackNavigator();

export const CreateBasalProgramStack: React.FC = () => {
  const intl = useIntl();

  return (
    <Stack.Navigator
      initialRouteName={BasalProgramsRoutesEnum.EditProgramName}
      screenOptions={{
        header: (props) => <TopBar {...props} backAndCancelMode />
      }}
    >
      <Stack.Screen
        name={BasalProgramsRoutesEnum.EditProgramName}
        options={{
          title: intl.formatMessage(createBasalProgramMessages.title),
          header: (props) => <TopBar {...props} />,
        }}
      >
        {() => <EditProgramName isNew />}
      </Stack.Screen>

      <Stack.Screen
        name={BasalProgramsRoutesEnum.EditSegment}
        options={{
          title: intl.formatMessage(createBasalProgramMessages.title),
        }}
        component={EditSegment}
      />

      <Stack.Screen
        name={BasalProgramsRoutesEnum.ConfirmBasalProgram}
        options={{
          title: intl.formatMessage(confirmBasalProgramMessages.title),
        }}
        component={ConfirmBasalProgram}
      />
    </Stack.Navigator>
  );
}
