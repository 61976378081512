import React from 'react';
import { useIntl } from 'react-intl';
import { createStackNavigator } from '@react-navigation/stack';
import { useSelector } from 'react-redux';
import { selectIsAutoMode } from 'web/store/reducers/user/user.helpers';
import { SwitchToAuto } from './views/SwitchToAuto';
import { SwitchToManual } from './views/SwitchToManual';
import { TopBar } from 'web/components/ios/TopBar';
import { messages } from './SwitchMode.messages';

const Stack = createStackNavigator();

export const SwitchModeStack: React.FC = () => {
  const intl = useIntl();
  const isAutoMode = useSelector(selectIsAutoMode);

  return (
    <Stack.Navigator
      initialRouteName="Switch"
      screenOptions={{
        header: (props) => (
          <TopBar {...props} backMode expanded />
        ),
      }}
    >
      <Stack.Screen
        name="Switch"
        options={{
          title: intl.formatMessage(messages.title),
        }}
        component={isAutoMode ? SwitchToManual : SwitchToAuto}
      />
    </Stack.Navigator>
  );
};
