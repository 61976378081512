let strings = {
  "en-US": {
    segment: "Segment",
    review: "Review time segments and values.",
    targetGlucose: "Target Glucose:",
    correctAbove: "Correct Above:",
    cancel: "Cancel",
    save: "Save",
    modalTitle: "Segment Editing",
    modalBody: "Not available for the simulator.",
    ok: "Ok"
  },
  "en-GB": {
    segment: "Segment",
    review: "Review time segments and values.",
    targetGlucose: "Target Glucose:",
    correctAbove: "Correct Above:",
    cancel: "Cancel",
    save: "Save",
    modalTitle: "Segment Editing",
    modalBody: "Not available for the simulator.",
    ok: "Ok"
  },
  "de-DE": {
    segment: "Segment",
    review: "Zeitsegmente und Werte überprüfen.",
    targetGlucose: "Glukose-Zielwert:",
    correctAbove: "Korrigieren über:",
    cancel: "Abbr.",
    save: "Speichern",
    modalTitle: "Segmentbearbeitung",
    modalBody: "Nicht für den Simulator verfügbar.",
    ok: "OK"
  },
  "es-US": {
    segment: "Segmento",
    review: "Revise los segmentos de tiempo y valores.",
    targetGlucose: "Glucosa Objetivo:",
    correctAbove: "Corregir Sobre:",
    cancel: "Cancelar",
    save: "Guardar",
    modalTitle: "Editar segmentos",
    modalBody: "No está disponible en el simulador.",
    ok: "Aceptar"
  },
  "fr-FR": {
    segment: "Segment",
    review: "Vérifiez les segments temporels et les valeurs.",
    targetGlucose: "Glucose cible :",
    correctAbove: "Corriger si supérieur à :",
    cancel: "Annuler",
    save: "Enregistrer",
    modalTitle: "Modification du segment",
    modalBody: "Non disponible pour le simulateur.",
    ok: "Ok"
  },
  "nl-NL": {
    segment: "Segment",
    review: "Tijdsegmenten en waarden nakijken.",
    targetGlucose: "Streefwaarde glucose:",
    correctAbove: "Corrigeren boven:",
    cancel: "Annuleren",
    save: "Opslaan",
    modalTitle: "Segment bewerken",
    modalBody: "Niet beschikbaar voor de simulator.",
    ok: "OK"
  }
};

export { strings };
