import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { IntroScreen } from 'web/containers/ios/layouts/IntroScreen';
import { ReactComponent as PodIcon } from 'assets/ios/icon-pod-rounded.svg';
import { ReactComponent as Pod } from 'assets/ios/pod-initial-setup.svg';
import { PodFTSRouteNames } from '../types';
import { messages } from '../Pod.messages';
import { UserState, userActions } from 'web/store/reducers/user';
import { RootState } from 'web/store/store';
import isEqual from 'lodash/isEqual';
import { TopBarStatusModes } from 'web/components/ios/IOSStatusBar';

export const PodSetupStart: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { navigate } = useNavigation();

  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  const handlePrimaryButtonClick = () => navigate(PodFTSRouteNames.podFill);
  const handleSecondaryButtonClick = () => navigate('Drawer');

  useEffect(() => {
    dispatch(
      userActions.userUpdate(
        {
          iosStatusBarMode: TopBarStatusModes.BgWhite,
        },
        userState
      )
    );
  }, [dispatch, userState]);

  return (
    <IntroScreen
      title={intl.formatMessage(messages.setupComplete)}
      primaryButtonText={intl.formatMessage(messages.initialScreenCtaButton)}
      primaryButtonHandler={handlePrimaryButtonClick}
      secondaryButtonText={intl.formatMessage(
        messages.initialScreenCancelButton
      )}
      secondaryButtonHandler={handleSecondaryButtonClick}
    >
      <div className="mb-4">
        <PodIcon />
      </div>
      <div className="font-ios font-bold text-4xl mb-3">
        <FormattedMessage
          {...messages.initialScreenText}
          values={{
            highlight: (chunk) => (
              <span className="text-modes-automated">{chunk}</span>
            ),
          }}
        />
      </div>
      <div>
        <Pod />
      </div>
    </IntroScreen>
  );
};
