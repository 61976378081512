import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { Moment } from 'moment';
import { Button } from 'web/components/ios/Button';
import { correctionFactorSegmentMessages } from '../../../BolusSettings.messages';
import { BolusSettingsRoutesEnum } from '../../../types';

export interface CorrectionFactorSegmentScreenValues {
  segmentNumber: number;
  startTime: Moment;
  endTime: Moment;
  value: number;
}

interface SegmentScreenProps {
  route: {
    params: CorrectionFactorSegmentScreenValues;
  };
}

export const CorrectionFactorSegmentScreen: React.FC<SegmentScreenProps> = ({
  route,
}) => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const {
    params: { segmentNumber, startTime, endTime, value },
  } = route;

  const renderTime = (time: Moment) => {
    let nightOrMorning = intl.formatMessage(correctionFactorSegmentMessages.night);
    // 12:00 AM is considered night, so we have to add a special case for it.
    if (time.format('A') === 'AM' && time.format('h:mm') !== '12:00') {
      nightOrMorning = intl.formatMessage(correctionFactorSegmentMessages.morning);
    }

    return `${time.format('h:mm A')} (${nightOrMorning})`;
  };

  return (
    <div className="bg-white h-full pt-[19px] pb-[34px] flex flex-col justify-between">
      <div>
        <div className="px-2 mb-[12px] font-bold text-2xl">
          <FormattedMessage
            {...correctionFactorSegmentMessages.title}
            values={{ segmentNumber }}
          />
        </div>

        <div className="px-2 py-[11px] flex flex-row items-center justify-between border-b-1 border-b-ios-gray-9/[.36]">
          <div>
            <FormattedMessage {...correctionFactorSegmentMessages.startTime} />
          </div>
          <div className="text-ios-gray-9/60">{renderTime(startTime)}</div>
        </div>

        <div className="px-2 py-[11px] flex flex-row items-center justify-between border-b-1 border-b-ios-gray-9/[.36]">
          <div>
            <FormattedMessage {...correctionFactorSegmentMessages.endTime} />
          </div>
          <div className="text-modes-automated">{renderTime(endTime)}</div>
        </div>

        <div className="px-2 py-[21px] flex flex-row items-center justify-between border-b-1 border-b-ios-gray-9/[.36]">
          <div>
            <div>
              <FormattedMessage
                {...correctionFactorSegmentMessages.valueName}
              />
            </div>
            <div className="text-ios-gray-9/60 text-small">
              <FormattedMessage
                {...correctionFactorSegmentMessages.valueRange}
              />
            </div>
          </div>
          <div className="text-modes-automated">{`${value} mg/dL`}</div>
        </div>
      </div>

      <div className="px-2">
        <Button
          colorMode="automated"
          variant="colored"
          full
          onClick={() => navigate(BolusSettingsRoutesEnum.CorrectionFactor)}
        >
          <FormattedMessage {...correctionFactorSegmentMessages.cta} />
        </Button>
      </div>
    </div>
  );
};
