import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'web/store/store';

export const selectCurrentModalData = createSelector(
  (state: RootState) => state.modals,
  (modals) => ({
    currentModal: modals.currentModal,
    showModal: modals.showModal,
  })
);
