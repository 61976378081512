import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useIntl } from 'react-intl';
import { TopBar } from 'web/components/ios/TopBar';
import { BolusAdministrationMain } from './views/BolusAdministrationMain';
import { ConfirmBolus } from './views/ConfirmBolus';
import { ExtendedBolusAdministration } from './views/ExtendedBolusAdministration';
import { BolusCustomFoods } from './views/BolusCustomFoods';
import {
  messages,
  confirmBolusMessages,
  extendedBolusAdministrationMessages,
  bolusCustomFoodsMessages,
} from './BolusAdministration.messages';
import { BolusAdministrationRoutesEnum } from './types';

const Stack = createStackNavigator();

export const BolusAdministrationStack: React.FC = () => {
  const intl = useIntl();

  return (
    <Stack.Navigator
      initialRouteName={BolusAdministrationRoutesEnum.BolusAdministrationMain}
    >
      <Stack.Screen
        name={BolusAdministrationRoutesEnum.BolusAdministrationMain}
        options={{
          title: intl.formatMessage(messages.title),
          header: (props) => <TopBar {...props} expanded />,
        }}
        component={BolusAdministrationMain}
      />

      <Stack.Screen
        name={BolusAdministrationRoutesEnum.BolusCustomFoods}
        options={{
          title: intl.formatMessage(bolusCustomFoodsMessages.title),
          header: (props) => <TopBar {...props} />,
        }}
        component={BolusCustomFoods}
      />

      <Stack.Screen
        name={BolusAdministrationRoutesEnum.ExtendedBolusAdministration}
        options={{
          title: intl.formatMessage(extendedBolusAdministrationMessages.title),
          header: (props) => <TopBar {...props} />,
        }}
        component={ExtendedBolusAdministration}
      />

      <Stack.Screen
        name={BolusAdministrationRoutesEnum.ConfirmBolus}
        options={{
          title: intl.formatMessage(confirmBolusMessages.title),
          header: (props) => <TopBar {...props} backAndCancelMode />,
        }}
        component={ConfirmBolus}
      />
    </Stack.Navigator>
  );
};
