import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const StartInsulinMenuIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className={classes} width="28" height="28" rx="6"/>
      <rect className={classes} width="16" height="16" transform="translate(6 6)"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7109 19.3334V9.55566L17.2295 14.4446L10.7109 19.3334Z"
        fill="white"
      />
    </svg>
  );
};
