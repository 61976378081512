let strings = {
  "en-US": {
    on: "On",
    off: "OFF. Tap to turn ON."
  },
  "en-GB": {
    on: "On",
    off: "OFF. Tap to turn ON."
  },
  "de-DE": {
    on: "Ein",
    off: "Aus. Zum Einschalten."
  },
  "es-US": {
    on: "Prendido",
    off: "Apagado. Toque para Prender."
  },
  "fr-FR": {
    on: "Activé",
    off: "Désactivé. Appuyez pour activer."
  },
  "nl-NL": {
    on: "Aan",
    off: "UIT. Tik om AAN te zetten."
  }
};

export { strings };
