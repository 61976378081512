import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native-web";
import { useSelector } from "react-redux";
import tilesAverageCGMImage from "../../../../../assets/history/multidayHistory/one_day/average_cgm.png";
import tilesBasalInsulinImage from "../../../../../assets/history/multidayHistory/one_day/basal_insulin.png";
import tilesBolusInsulinImage from "../../../../../assets/history/multidayHistory/one_day/bolus_insulin.png";
import tilesCGMAboveImage from "../../../../../assets/history/multidayHistory/one_day/cgm_above.png";
import tilesCGMBelowImage from "../../../../../assets/history/multidayHistory/one_day/cgm_below.png";
import tilesCGMInRangeImage from "../../../../../assets/history/multidayHistory/one_day/cgm_in_range.png";
import totalCarbsImage from "../../../../../assets/history/multidayHistory/one_day/total_carbs.png";
import totalInsulinImage from "../../../../../assets/history/multidayHistory/one_day/total_insulin.png";
import tilesAverageSensorMMOLComma from "../../../../../assets/history/multidayHistory/one_day/average_bg_comma.png";
import tilesAverageSensorMMOL from "../../../../../assets/history/multidayHistory/one_day/avg-sensor-mmol.png";
import tilesBasalInsulinImageComma from "../../../../../assets/history/multidayHistory/one_day/basal_insulin_comma.png";
import tilesBolusInsulinImageComma from "../../../../../assets/history/multidayHistory/one_day/bolus_insulin_comma.png";
import TotalInsulinImageComma from "../../../../../assets/history/multidayHistory/one_day/total_insulin_comma.png";
import { getUnitByLocale } from "../../../helpers/getUnitByLocale";
import replaceWithValues from "../../../helpers/replaceWithValues";
import useLanguageContent from "../../../hooks/useLanguageContent";
import { LangState } from "../../../../../web/store/reducers/language";
import { RootState } from "../../../../../web/store/store";
import theme from "../../../theme";
import { HistoryScreenTile } from "../../molecules";
import { strings } from "./AveragesCardContent";

interface Props {
  sectionOneLabel: string;
  sectionTwoLabel: string;
}
const AveragesCard: React.FC<Props> = ({
  sectionOneLabel,
  sectionTwoLabel
}) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);
  const lowerRange = getUnitByLocale(70, langState.units);
  const higherRange = `${getUnitByLocale(180, langState.units)} ${
    langState.units
  }`;
  const contentVariables = [lowerRange, higherRange];
  return (
    <View
      style={[
        styles.card,
        { marginBottom: 8, paddingHorizontal: 16, paddingTop: 16 }
      ]}>
      <View
        style={{
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignContent: "space-between"
        }}>
        <Text style={[theme.fonts.body2, { alignSelf: "center" }]}>
          {sectionOneLabel}
        </Text>
        <View style={[styles.row]}>
          <HistoryScreenTile
            label={languageContent.averageSensor}
            icon={
              langState.units === "mmol/L"
                ? langState.locale === "de-DE"
                  ? tilesAverageSensorMMOLComma
                  : tilesAverageSensorMMOL
                : tilesAverageCGMImage
            }
          />
          <HistoryScreenTile
            label={languageContent.sensorInRange}
            subLabel={replaceWithValues(
              languageContent.range,
              contentVariables
            )}
            icon={tilesCGMInRangeImage}
          />
          <HistoryScreenTile
            label={languageContent.sensorAbove}
            icon={tilesCGMAboveImage}
          />
          <HistoryScreenTile
            label={languageContent.sensorBelow}
            icon={tilesCGMBelowImage}
          />
        </View>
        <View style={[styles.divider]} />
        <Text style={[theme.fonts.body2, { alignSelf: "center" }]}>
          {sectionTwoLabel}
        </Text>
        <View style={[styles.row]}>
          <HistoryScreenTile
            label={languageContent.totalInsulin}
            icon={
              langState.locale === "de-DE"
                ? TotalInsulinImageComma
                : totalInsulinImage
            }
          />
          <HistoryScreenTile
            label={languageContent.basalInsulin}
            icon={
              langState.locale === "de-DE"
                ? tilesBasalInsulinImageComma
                : tilesBasalInsulinImage
            }
          />
          <HistoryScreenTile
            label={languageContent.bolusInsulin}
            icon={
              langState.locale === "de-DE"
                ? tilesBolusInsulinImageComma
                : tilesBolusInsulinImage
            }
          />
          <HistoryScreenTile
            label={languageContent.totalCarbs}
            icon={totalCarbsImage}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.white,
    shadowColor: "#999",
    shadowOpacity: 0.3,
    shadowRadius: 6,
    shadowOffset: {
      height: 1,
      width: 0
    },
    elevation: 4,
    alignSelf: "center"
  },
  divider: {
    width: "110%",
    height: 2,
    backgroundColor: "#eee",
    alignSelf: "center",
    marginBottom: 8,
    marginTop: 24
  },
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginVertical: 0
  }
});

export default AveragesCard;
