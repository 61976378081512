import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'ios.settings-bolus.title',
    defaultMessage: 'Bolus Settings',
  },

  maximumBolus: {
    id: 'ios.settings-bolus.maximum-bolus',
    defaultMessage: 'Maximum Bolus',
  },
  maximumBolusText: {
    id: 'ios.settings-bolus.maximum-bolus-text',
    defaultMessage: '10 units of insulin in a single bolus',
  },
  extendedBolus: {
    id: 'ios.settings-bolus.extended-bolus',
    defaultMessage: 'Extended Bolus',
  },
  extendedBolusDisclaimer: {
    id: 'ios.settings-bolus.extended-bolus-disclaimer',
    defaultMessage:
      'Extending a bolus means that a meal bolus can be delivered over a prolonged period of time.',
  },

  bolusCalculator: {
    id: 'ios.settings-bolus.bolus-calculator',
    defaultMessage: 'BOLUS CALCULATOR',
  },
  targetGlucose: {
    id: 'ios.settings-bolus.target-glucose',
    defaultMessage: 'Target Glucose & Correct Above',
  },
  targetGlucoseText: {
    id: 'ios.settings-bolus.target-glucose-text',
    defaultMessage:
      'Target Glucose (100 - 130 mg/dL) {br} Correct Above (100 - 150 mg/dL)',
  },
  minimumGlucose: {
    id: 'ios.settings-bolus.minimum-glucose',
    defaultMessage: 'Minimum Glucose for Calculations',
  },
  minimumGlucoseText: {
    id: 'ios.settings-bolus.minimum-glucose-text',
    defaultMessage: '70 mg/dL',
  },
  insulinToCarb: {
    id: 'ios.settings-bolus.insulin-to-carb',
    defaultMessage: 'Insulin to Carb Ratio',
  },
  insulinToCarbText: {
    id: 'ios.settings-bolus.insulin-to-carb-text',
    defaultMessage: 'Between 10 and 15 g of carbs',
  },
  correctionFactor: {
    id: 'ios.settings-bolus.correction-factor',
    defaultMessage: 'Correction Factor',
  },
  correctionFactorText: {
    id: 'ios.settings-bolus.correction-factor-text',
    defaultMessage: 'Between 40 and 55 mg/dL',
  },
  durationInsulin: {
    id: 'ios.settings-bolus.duration-insulin',
    defaultMessage: 'Duration of Insulin Action',
  },
  durationInsulinText: {
    id: 'ios.settings-bolus.duration-insulin-text',
    defaultMessage: '4 hrs',
  },
  reverseCorrection: {
    id: 'ios.settings-bolus.reverse-correction',
    defaultMessage: 'Reverse Correction',
  },
  reverseCorrectionDisclaimer: {
    id: 'ios.settings-bolus.reverse-correction-disclaimer',
    defaultMessage:
      'There may be times when you are about to eat and your blood glucose level is below your Target Glucose level. If Reverse Correction is set to ON, the Bolus Calculator decreases a suggested meal bolus to help counteract your low blood glucose reading.',
  },
});

export const maximumBolusMessages = defineMessages({
  title: {
    id: 'ios.settings-bolus.maximum-bolus.title',
    defaultMessage: 'Maximum Bolus',
  },
  text: {
    id: 'ios.settings-bolus.maximum-bolus.text',
    defaultMessage:
      'The maximum amount of insulin that you can request in a single bolus.',
  },
  maxBolus: {
    id: 'ios.settings-bolus.maximum-bolus.max-bolus',
    defaultMessage: 'Max Bolus',
  },
  maxBolusRange: {
    id: 'ios.settings-bolus.maximum-bolus.max-bolus-range',
    defaultMessage: '(0.05 to 30 U)',
  },
  maxBolusValue: {
    id: 'ios.settings-bolus.maximum-bolus.max-bolus-value',
    defaultMessage: '10 U',
  },
  cta: {
    id: 'ios.settings-bolus.maximum-bolus.cta',
    defaultMessage: 'Save',
  },
});

export const targetGlucoseMessages = defineMessages({
  title: {
    id: 'ios.settings-bolus.target-glucose.title',
    defaultMessage: 'Target Glucose & Correct Above',
  },
  textOne: {
    id: 'ios.settings-bolus.target-glucose.text-one',
    defaultMessage:
      'Target Glucose and Correct Above values are used in both Automated and Manual Modes and can be set for different times of the day.',
  },
  textTwo: {
    id: 'ios.settings-bolus.target-glucose.text-two',
    defaultMessage:
      'In Automated Mode, your basal insulin delivery will be adjusted automatically to bring you toward your Target Glucose value.',
  },
  textThree: {
    id: 'ios.settings-bolus.target-glucose.text-three',
    defaultMessage:
      'In both Automated and Manual Modes, Target Glucose and Correct Above values are used for Bolus Calculations.',
  },
  textFour: {
    id: 'ios.settings-bolus.target-glucose.text-four',
    defaultMessage: 'Review time segments and values.',
  },

  timeSegments: {
    id: 'ios.settings-bolus.target-glucose.time-segments',
    defaultMessage: 'Time Segments',
  },
  totalSegments: {
    id: 'ios.settings-bolus.target-glucose.total',
    defaultMessage: 'Total: 2',
  },

  segmentOneTitle: {
    id: 'ios.settings-bolus.target-glucose.segment-one-title',
    defaultMessage: 'Segment 1: 12:00 AM - 8:00 AM',
  },
  segmentOneValue: {
    id: 'ios.settings-bolus.target-glucose.segment-one-value',
    defaultMessage: 'Target Glucose: 130 mg/dL {br} Correct Above: 150 mg/dL',
  },
  segmentTwoTitle: {
    id: 'ios.settings-bolus.target-glucose.segment-two-title',
    defaultMessage: 'Segment 2: 8:00 AM - 12:00 AM',
  },
  segmentTwoValue: {
    id: 'ios.settings-bolus.target-glucose.segment-two-value',
    defaultMessage: 'Target Glucose: 110 mg/dL {br} Correct Above: 150 mg/dL',
  },

  cta: {
    id: 'ios.settings-bolus.target-glucose.cta',
    defaultMessage: 'Save',
  },
});

export const targetGlucoseSegmentMessages = defineMessages({
  title: {
    id: 'ios.settings-bolus.target-glucose.segments.title',
    defaultMessage: 'Segment {segmentNumber}',
  },
  startTime: {
    id: 'ios.settings-bolus.target-glucose.segments.start-time',
    defaultMessage: 'Start Time',
  },
  endTime: {
    id: 'ios.settings-bolus.target-glucose.segments.end-time',
    defaultMessage: 'End Time',
  },
  morning: {
    id: 'ios.settings-bolus.target-glucose.segments.morning',
    defaultMessage: 'morning',
  },
  night: {
    id: 'ios.settings-bolus.target-glucose.segments.night',
    defaultMessage: 'night',
  },
  targetGlucose: {
    id: 'ios.settings-bolus.target-glucose.segments.target-glucose',
    defaultMessage: 'Target Glucose',
  },
  targetGlucoseText: {
    id: 'ios.settings-bolus.target-glucose.segments.target-glucose-text',
    defaultMessage: '(110 to 150 mg/dL)',
  },
  correctAbove: {
    id: 'ios.settings-bolus.target-glucose.segments.correct-above',
    defaultMessage: 'Correct Above',
  },
  correctAboveText: {
    id: 'ios.settings-bolus.target-glucose.segments.correct-above-text',
    defaultMessage: '(Target Glucose to 200 mg/dL)',
  },
  cta: {
    id: 'ios.settings-bolus.target-glucose.segments.cta',
    defaultMessage: 'Next',
  },
});

export const minimumGlucoseMessages = defineMessages({
  title: {
    id: 'ios.settings-bolus.minimum-glucose.title',
    defaultMessage: 'Minimum Glucose for Calculations',
  },
  text: {
    id: 'ios.settings-bolus.minimum-glucose.text',
    defaultMessage:
      'If your blood glucose is below your Minimum Glucose for Calculations, the Bolus Calculator is disabled so that it does not suggest a bolus.',
  },
  valueName: {
    id: 'ios.settings-bolus.minimum-glucose.value-name',
    defaultMessage: 'Minimum Glucose for Calculations',
  },
  valueRange: {
    id: 'ios.settings-bolus.minimum-glucose.value-range',
    defaultMessage: '(50 - 70 mg/dL)',
  },
  value: {
    id: 'ios.settings-bolus.minimum-glucose.value',
    defaultMessage: '70 mg/dL',
  },
  cta: {
    id: 'ios.settings-bolus.minimum-glucose.cta',
    defaultMessage: 'Save',
  },
});

export const insulinToCarbMessages = defineMessages({
  title: {
    id: 'ios.settings-bolus.insulin-to-carb.title',
    defaultMessage: 'Insulin to Carb Ratio',
  },

  textOne: {
    id: 'ios.settings-bolus.insulin-to-carb.text-one',
    defaultMessage:
      'Number of grams of carbohydrate covered by one unit of insulin. For example, if your IC Ratio is 1:15, then you need to deliver one unit of insulin to cover every fifteen grams of carbohydrate you eat.',
  },
  textTwo: {
    id: 'ios.settings-bolus.insulin-to-carb.text-two',
    defaultMessage: 'Review time segments and values.',
  },

  timeSegments: {
    id: 'ios.settings-bolus.insulin-to-carb.time-segments',
    defaultMessage: 'Time Segments',
  },
  totalSegments: {
    id: 'ios.settings-bolus.insulin-to-carb.total',
    defaultMessage: 'Total: 2',
  },

  segmentOneTitle: {
    id: 'ios.settings-bolus.insulin-to-carb.segment-one-title',
    defaultMessage: 'Segment 1: 12:00 AM - 8:00 AM',
  },
  segmentOneValue: {
    id: 'ios.settings-bolus.insulin-to-carb.segment-one-value',
    defaultMessage: '1 U of insulin covers: {br} 15 g of carbs',
  },
  segmentTwoTitle: {
    id: 'ios.settings-bolus.insulin-to-carb.segment-two-title',
    defaultMessage: 'Segment 2: 8:00 AM - 12:00 AM',
  },
  segmentTwoValue: {
    id: 'ios.settings-bolus.insulin-to-carb.segment-two-value',
    defaultMessage: '1 U of insulin covers: {br} 10 g of carbs',
  },

  cta: {
    id: 'ios.settings-bolus.insulin-to-carb.cta',
    defaultMessage: 'Save',
  },
});

export const insulinToCarbSegmentMessages = defineMessages({
  title: {
    id: 'ios.settings-bolus.insulin-to-carb.segments.title',
    defaultMessage: 'Segment {segmentNumber}',
  },
  startTime: {
    id: 'ios.settings-bolus.insulin-to-carb.segments.start-time',
    defaultMessage: 'Start Time',
  },
  endTime: {
    id: 'ios.settings-bolus.insulin-to-carb.segments.end-time',
    defaultMessage: 'End Time',
  },
  morning: {
    id: 'ios.settings-bolus.insulin-to-carb.segments.morning',
    defaultMessage: 'morning',
  },
  night: {
    id: 'ios.settings-bolus.insulin-to-carb.segments.night',
    defaultMessage: 'night',
  },
  valueName: {
    id: 'ios.settings-bolus.insulin-to-carb.segments.value-name',
    defaultMessage: '1 unit of insulin covers',
  },
  valueRange: {
    id: 'ios.settings-bolus.insulin-to-carb.segments.value-range',
    defaultMessage: '(1 to 150 g carbs)',
  },
  value: {
    id: 'ios.settings-bolus.insulin-to-carb.segments.value',
    defaultMessage: '{value, number} g',
  },
  cta: {
    id: 'ios.settings-bolus.target-glucose.segments.cta',
    defaultMessage: 'Next',
  },
});

export const correctionFactorMessages = defineMessages({
  title: {
    id: 'ios.settings-bolus.correction-factor.title',
    defaultMessage: 'Correction Factor',
  },

  textOne: {
    id: 'ios.settings-bolus.correction-factor.text-one',
    defaultMessage:
      'A value that indicates how much one unit of insulin will lower your blood glucose. For example, if your Correction Factor is 50, one unit of insulin lowers your blood glucose by 50 mg/dL.',
  },
  textTwo: {
    id: 'ios.settings-bolus.correction-factor.text-two',
    defaultMessage: 'Review time segments and values.',
  },

  timeSegments: {
    id: 'ios.settings-bolus.correction-factor.time-segments',
    defaultMessage: 'Time Segments',
  },
  totalSegments: {
    id: 'ios.settings-bolus.correction-factor.total',
    defaultMessage: 'Total: 2',
  },

  segmentOneTitle: {
    id: 'ios.settings-bolus.correction-factor.segment-one-title',
    defaultMessage: 'Segment 1: 12:00 AM - 8:00 AM',
  },
  segmentOneValue: {
    id: 'ios.settings-bolus.correction-factor.segment-one-value',
    defaultMessage: '1 U of insulin lowers glucose by: {br} 55 mg/dL',
  },
  segmentTwoTitle: {
    id: 'ios.settings-bolus.correction-factor.segment-two-title',
    defaultMessage: 'Segment 2: 8:00 AM - 12:00 AM',
  },
  segmentTwoValue: {
    id: 'ios.settings-bolus.correction-factor.segment-two-value',
    defaultMessage: '1 U of insulin lowers glucose by: {br} 40 mg/dL',
  },

  cta: {
    id: 'ios.settings-bolus.correction-factor.cta',
    defaultMessage: 'Save',
  },
});

export const correctionFactorSegmentMessages = defineMessages({
  title: {
    id: 'ios.settings-bolus.correction-factor.segments.title',
    defaultMessage: 'Segment {segmentNumber}',
  },
  startTime: {
    id: 'ios.settings-bolus.correction-factor.segments.start-time',
    defaultMessage: 'Start Time',
  },
  endTime: {
    id: 'ios.settings-bolus.correction-factor.segments.end-time',
    defaultMessage: 'End Time',
  },
  morning: {
    id: 'ios.settings-bolus.correction-factor.segments.morning',
    defaultMessage: 'morning',
  },
  night: {
    id: 'ios.settings-bolus.correction-factor.segments.night',
    defaultMessage: 'night',
  },
  valueName: {
    id: 'ios.settings-bolus.correction-factor.segments.value-name',
    defaultMessage: '1 U of insulin lowers glucose by',
  },
  valueRange: {
    id: 'ios.settings-bolus.correction-factor.segments.value-range',
    defaultMessage: '(1 to 400 mg/dL)',
  },
  cta: {
    id: 'ios.settings-bolus.correction-factor.segments.cta',
    defaultMessage: 'Next',
  },
});

export const durationOfInsulinMessages = defineMessages({
  title: {
    id: 'ios.settings-bolus.duration-of-insulin.title',
    defaultMessage: 'Duration of Insulin Action',
  },
  text: {
    id: 'ios.settings-bolus.duration-of-insulin.text',
    defaultMessage:
      'The length of time that insulin remains active and available in your body after a correction or meal bolus.',
  },
  valueName: {
    id: 'ios.settings-bolus.duration-of-insulin.value-name',
    defaultMessage: 'Duration of Insulin Action',
  },
  valueRange: {
    id: 'ios.settings-bolus.duration-of-insulin.value-range',
    defaultMessage: '(2 to 6 hrs)',
  },
  value: {
    id: 'ios.settings-bolus.duration-of-insulin.value',
    defaultMessage: '4 hrs',
  },
  cta: {
    id: 'ios.settings-bolus.duration-of-insulin.cta',
    defaultMessage: 'Save',
  },
});
