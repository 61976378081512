import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button } from 'web/components/ios/Button';
import { IOSRoutesEnums } from '../types';
import { FormattedMessage } from 'react-intl';
import { messages } from '../FtsStartScreenStack.messages';

export const SoundScreen = () => {
  const { navigate } = useNavigation();

  return (
    <div className="flex justify-between px-2 flex-col flex-1 items-stretch bg-white">
      <div className="flex flex-col">
        <div className="mt-2">
          <div className="text-black">
            <FormattedMessage {...messages.silenceDeviceMessage} />
          </div>
          <div className="mt-2">
            <FormattedMessage {...messages.yourPodSilencedMessage} />
          </div>
        </div>
      </div>

      <div className="w-full mb-4">
        <Button
          className="w-full rounded-lg font-bold"
          onClick={() => navigate(IOSRoutesEnums.AccessibilitySettings)}
          variant="colored"
        >
          <FormattedMessage {...messages.iUnderstandButtonTitle} />
        </Button>
      </div>
    </div>
  );
};
