import React from 'react';
import { useSelector } from 'react-redux';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { IntroScreenLayout } from 'mobile/latest/components/organisms';
import {
  selectShowNewVersionFeatures,
} from 'web/store/reducers/featureFlags/featureFlags.helpers';
import { strings } from './IntroCGMScreenContent';

const IntroCGMScreen: React.FC = () => {
  const image = require('assets/dexcom_on_body.jpg');
  const { languageContent } = useLanguageContent(strings);
  const showSensorSelection = useSelector(selectShowNewVersionFeatures);

  return (
    <IntroScreenLayout
      body={languageContent.bolusCalc}
      image={image}
      navigateTo={showSensorSelection ? 'SensorSelection' : 'InitialSetupG6Stack'}
    />
  );
};

export default IntroCGMScreen;
