const strings = {
  'en-US': {
    sensorSelect: 'Sensor Selection',
  },
  'es-US': {
    sensorSelect: 'Selección del Sensor',
  },
  'en-GB': {
    sensorSelect: 'Sensor Selection',
  },
  'nl-NL': {
    sensorSelect: 'Sensorselectie',
  },
};

export { strings };
