import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import getCurrentHourFormat from "../../../../../helpers/getCurrentHourFormat";
import { getLanguageLong } from "../../../../../helpers/getLanguageLong";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { SettingsSection } from "../../../../organisms";
import { strings } from "./GeneralSettingsScreenContent";
const GeneralSettingsScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <ScrollView>
      <View style={[styles.card]}>
        <SettingsSection
          settingTitle={languageContent.security}
          subtitleText={languageContent.on}
        />
        <SettingsSection
          header={languageContent.timeZone}
          headerStyle={[
            theme.fonts.body2,
            { color: theme.colors.blue, marginBottom: 8, padding: 0 }
          ]}
          settingTitle={languageContent.insulinDelivery}
          subtitleText={`${languageContent.newYork} (${getCurrentHourFormat({
            timeStr: "7:30 pm",
            is24Hour: langState.is24Hour
          })})`}
          settingValueSubTextElement={
            <Text style={[theme.fonts.body3, styles.settingValue]}>
              {languageContent.podDelivers}
            </Text>
          }
        />
        <SettingsSection
          settingTitle={languageContent.deviceTimeZoneTitle}
          subtitleText={`${languageContent.newYork} (${getCurrentHourFormat({
            timeStr: "7:30 pm",
            is24Hour: langState.is24Hour
          })}) - ${languageContent.deviceTimeZoneSubTitle}`}
          settingValueSubTextElement={
            <Text style={[theme.fonts.body3, styles.settingValue]}>
              {languageContent.automaticTimeZone}
            </Text>
          }
        />
        <SettingsSection
          settingTitle={languageContent.language}
          subtitleText={getLanguageLong(langState.language)}
        />
        <SettingsSection
          header={languageContent.diagnostics}
          headerStyle={[
            theme.fonts.body2,
            { color: theme.colors.blue, marginBottom: 8, padding: 0 }
          ]}
          settingTitle={languageContent.checkAlarms}
          subtitleText={languageContent.check}
        />
        <SettingsSection
          settingTitle={languageContent.reset}
          subtitleText={languageContent.clearAllData}
          subtitleTextOnPressHandler={() => navigate("ClearAllData")}
        />
      </View>
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  card: {
    width: "100%",
    backgroundColor: theme.colors.white,
    shadowColor: "#999",
    shadowOpacity: 0.3,
    shadowRadius: 6,
    shadowOffset: {
      height: 1,
      width: 0
    },
    elevation: 6,
    borderBottomWidth: 1,
    borderColor: theme.colors.textInputBorder
  },
  settingValue: {
    color: theme.colors.lightBlue
  }
});
export default GeneralSettingsScreen;
