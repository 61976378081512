import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { InsulinIcon, PodIcon, CgmIcon } from 'web/components/ios/icons';
import { ReactComponent as ActivityIcon } from 'assets/ios/icon-activity.svg';
import { ReactComponent as BolusIcon } from 'assets/ios/icon-bolus.svg';
import { TempBasalOn, InfoIcon } from 'web/components/ios/icons';
import { Button } from 'web/components/ios/Button';
import { BottomNav } from 'web/components/ios/BottomMenu/BottomNav';
import {
  selectCurrentOperationMode,
  selectIsActivityModeActive,
  selectIsTempBasalActive,
} from 'web/store/reducers/user/user.helpers';
import {
  selectLanguageIs24Hours,
  selectLanguageUnits,
} from 'web/store/reducers/language/language.helpers';
import { selectIsBolusDeliveryInProgress } from 'web/store/reducers/bolus/bolus.helpers';
import { selectActiveSensor } from 'web/store/reducers/sensor/sensor.helpers';
import graphPortrait from 'assets/ios/graphs/portrait.png';
import { AppRoutesEnum } from 'web/routes/types';
import { operationModeMessages, messages } from './Dashboard.messages';
import { useNavigation } from '@react-navigation/native';
import {
  showModal,
  setCurrentModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import {
  DashboardInsulinModal,
  DashboardPodDetailsModal,
  IobInfoModal,
} from 'web/components/ios/modals/DashboardModals/DashboardModals';
import { ActivityDrawer } from 'web/components/ios/ActivityDrawer/ActivityDrawer';
import { PodFTSRouteNames } from '../Pod/types';
import { NavState } from 'web/store/reducers/navigation';
import { RootState } from 'web/store/store';
import isEqual from 'lodash/isEqual';
import { selectIsInsulinPaused } from 'web/store/reducers/basal/basal.helpers';
import { TempBasalDrawer } from 'web/components/ios/TempBasalDrawer/TempBasalDrawer';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';
import { BolusDeliveryBar } from './components/BolusDeliveryBar';

export const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const operationMode = useSelector(selectCurrentOperationMode);
  const bolusDeliveryInProgress = useSelector(selectIsBolusDeliveryInProgress);
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );
  const isInsulinPaused = useSelector(selectIsInsulinPaused);
  const currentDate = new Date();
  const is24Hours = useSelector(selectLanguageIs24Hours);
  const units = useSelector(selectLanguageUnits);
  const activeSensor = useSelector(selectActiveSensor);
  const isActivityModeActive = useSelector(selectIsActivityModeActive);
  const isTempBasalActive = useSelector(selectIsTempBasalActive);
  const [showActivityDrawer, setShowActivityDrawer] = useState<boolean>(false);
  const [showTempBasalDrawer, setShowTempBasalDrawer] =
    useState<boolean>(false);

  const handleShowPodDetailsModal = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <DashboardPodDetailsModal
            onConfirm={() => {
              navigate(AppRoutesEnum.podSetup, {
                screen: PodFTSRouteNames.podDetails,
              });
              dispatch(hideModal());
            }}
          />
        ),
      })
    );

    dispatch(showModal());
  };

  const handleShowInsulinDetailsModal = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <DashboardInsulinModal
            onCtaClick={() => {
              dispatch(navigateToRoute(AppRoutesEnum.BasalPrograms));
              dispatch(hideModal());
            }}
          />
        ),
      })
    );

    dispatch(showModal());
  };

  const handleBolusOnClick = () => {
    dispatch(navigateToRoute(AppRoutesEnum.Bolus));
  };

  const handleIobInfoClick = () => {
    dispatch(
      setCurrentModal({
        modal: <IobInfoModal />,
      })
    );
    dispatch(showModal());
  };

  useEffect(() => {
    if (navState.params?.showBottomInsulinModal) {
      handleShowInsulinDetailsModal();
    }
  }, []);

  return (
    <div className="font-ios text-regular h-full bg-white flex justify-between flex-col">
      <div>
        {isInsulinPaused && (
          <div className="bg-ios-yellow flex justify-center text-[12px] font-semibold">
            Insulin delivery is paused
          </div>
        )}
        <div className="px-2 border-b-1 flex flex-row justify-between items-center pt-2">
          <div
            className={classNames(
              `text-modes-${operationMode}`,
              'text-4xl font-bold'
            )}
          >
            <FormattedMessage {...operationModeMessages[operationMode].title} />
          </div>
          <div className="flex flex-row items-end text-menuIcons leading-normal">
            {operationMode === 'manual' && !isTempBasalActive && (
              <button
                className="flex flex-col justify-center items-center mr-2 py-[12px]"
                onClick={handleShowInsulinDetailsModal}
              >
                <InsulinIcon color={`fill-modes-${operationMode}`} />
                <span>
                  <FormattedMessage {...messages.buttonInsulin} />
                </span>
              </button>
            )}

            {isActivityModeActive && (
              <button
                className="flex flex-col justify-center items-center mr-2 bg-ios-activityGreen p-[12px] rounded-t-lg"
                onClick={() => setShowActivityDrawer(true)}
              >
                <ActivityIcon />
                <span>
                  <FormattedMessage {...messages.buttonActivity} />
                </span>
              </button>
            )}

            {isTempBasalActive && (
              <button
                className="flex flex-col justify-center items-center mr-2 bg-ios-tempBasal p-[12px] rounded-t-lg"
                onClick={() => setShowTempBasalDrawer(true)}
              >
                <TempBasalOn color="fill-black" />
                <span>
                  <FormattedMessage {...messages.buttonTempBasal} />
                </span>
              </button>
            )}

            <button
              className="flex flex-col justify-center items-center py-[12px]"
              onClick={handleShowPodDetailsModal}
            >
              <PodIcon color={`fill-modes-${operationMode}`} />
              <span>
                <FormattedMessage {...messages.buttonPod} />
              </span>
            </button>
          </div>
        </div>

        <div className="px-2 mt-3">
          <div className="text-xl mb-4">
            <div className="font-bold mb-1 flex items-center">
              <div>
                <FormattedMessage
                  {...messages.iobUnits}
                  values={{
                    value: 3.1,
                    highlight: (chunk) => (
                      <span
                        className={classNames({
                          'text-black': !bolusDeliveryInProgress,
                          'text-ios-label': bolusDeliveryInProgress,
                        })}
                      >
                        {chunk}
                      </span>
                    ),
                  }}
                />
              </div>
              {bolusDeliveryInProgress && (
                <button
                  className="ml-[10px] mb-[3px]"
                  onClick={handleIobInfoClick}
                >
                  <InfoIcon color={`fill-modes-${operationMode}`} />
                </button>
              )}
            </div>
            <span className="font-bold">
              <FormattedMessage {...messages.lastBolusUnits} />
            </span>
            <span className="block">
              <FormattedDate value={currentDate} month="short" day="2-digit" />{' '}
              (<FormattedTime value={currentDate} hour12={!is24Hours} />)
            </span>
          </div>

          <div>
            <div className="flex flex-row items-center mt-[56px]">
              <span className="font-black text-7xl">121</span>
              <span>
                <CgmIcon operationMode={operationMode} />
              </span>
            </div>
            <span className="font-bold text-xl ml-[10px]">{units}</span>
          </div>
        </div>

        <div className="mb-2">
          <img
            src={graphPortrait}
            alt="Graph"
            onClick={() => navigate(AppRoutesEnum.graph)}
          />
          <div className="mt-1 font-bold text-[11px] text-ios-sensorBrand ml-[11px]">
            {activeSensor}
          </div>
        </div>

        {!bolusDeliveryInProgress && (
          <div className="px-2">
            <Button
              variant="thin"
              colorMode={operationMode}
              full
              disabled={isInsulinPaused}
              onClick={handleBolusOnClick}
            >
              <BolusIcon className="mr-0.5" />
              <FormattedMessage {...messages.buttonBolus} />
            </Button>
          </div>
        )}
      </div>

      <BottomNav />

      {bolusDeliveryInProgress && <BolusDeliveryBar />}

      {showActivityDrawer && (
        <ActivityDrawer onClose={() => setShowActivityDrawer(false)} />
      )}
      {showTempBasalDrawer && (
        <TempBasalDrawer onClose={() => setShowTempBasalDrawer(false)} />
      )}
    </div>
  );
};
