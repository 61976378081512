let strings = {
  'en-US': {
    tempBasal: 'Temp Basal',
    setTempBasal: 'Set Temp Basal',
  },
  'en-GB': {
    tempBasal: 'Temp Basal',
    setTempBasal: 'Set Temp Basal',
  },
  'de-DE': {
    tempBasal: 'Temporäre Basalrate',
    setTempBasal: 'Temporäre Basalrate festlegen',
  },
  'es-US': {
    tempBasal: 'Basal temporal',
    setTempBasal: 'Establecer basal temp...',
  },
  'nl-NL': {
    tempBasal: 'Tijdelijke basaalsne...',
    setTempBasal: 'Tijdelijke basaalsne...',
  },
  'fr-FR': {
    tempBasal: 'Débit basal temporaire',
    setTempBasal: 'Définir le débit basal temporaire',
  },
};

export { strings };
