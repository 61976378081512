import EXPERT_BASAL_HOURS_DATA from "../data/ExpertBasalSegDurationData";

const findExpertBasalStartTimeIndex = (
  basalStateProgramSegmentLabel: string
) => {
  return EXPERT_BASAL_HOURS_DATA.findIndex(
    (EXPERT_BASAL_HOURS_DATA) =>
      EXPERT_BASAL_HOURS_DATA.label === basalStateProgramSegmentLabel
  );
};

export default findExpertBasalStartTimeIndex;
