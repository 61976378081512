let strings = {
  "en-US": {
    segment: "Segment",
    uHr: "U/hr",
    basalRate: "Basal Rate:",
    cancel: "Cancel",
    save: "Save",
    editSegModalTitle: "Edit Segments",
    editSegModalBody:
      "We will walk you through adding a third segment. Start by tapping the Edit icon on the second segment.",
    editSeg: "Edit Segment",
    notAvailable: "Not available for the simulator.",
    ok: "OK"
  },
  "en-GB": {
    segment: "Segment",
    uHr: "U/hr",
    basalRate: "Basal Rate:",
    cancel: "Cancel",
    save: "Save",
    editSegModalTitle: "Edit Segments",
    editSegModalBody:
      "We will walk you through adding a third segment. Start by tapping the Edit icon on the second segment.",
    editSeg: "Edit Segment",
    notAvailable: "Not available for the simulator.",
    ok: "OK"
  },
  "de-DE": {
    segment: "Segment",
    uHr: "E/Std.",
    basalRate: "Basalrate:",
    cancel: "Abbr.",
    save: "Speichern",
    editSegModalTitle: "Segmente bearbeiten",
    editSegModalBody:
      "Wir zeigen Ihnen, wie Sie ein drittes Segment hinzufügen. Tippen Sie zunächst auf das Bearbeiten-Symbol im zweiten Segment.",
    editSeg: "Segment bearbeiten",
    notAvailable: "Nicht für den Simulator verfügbar.",
    ok: "OK"
  },
  "es-US": {
    segment: "Segmento",
    uHr: "U/h",
    basalRate: "Tasa basal:",
    cancel: "Cancelar",
    save: "Guardar",
    editSegModalTitle: "Editar segmentos",
    editSegModalBody:
      "Lo guiaremos para que agregue un tercer segmento. Empiece por tocar el icono Editar en el segundo segmento.",
    editSeg: "Editar segmento",
    notAvailable: "No está disponible en el simulador.",
    ok: "Aceptar"
  },
  "fr-FR": {
    segment: "Segment",
    uHr: "U/h",
    basalRate: "Débit basal :",
    cancel: "Annuler",
    save: "Enregistrer",
    editSegModalTitle: "Modifier les segments",
    editSegModalBody:
      "Nous allons vous guider pour vous aider à ajouter un troisième segment. Commencez en appuyant sur l'icône Modifier sur le deuxième segment.",
    editSeg: "Modifier le segment",
    notAvailable: "Non disponible pour le simulateur.",
    ok: "OK"
  },
  "nl-NL": {
    segment: "Segment",
    uHr: "E/u.",
    basalRate: "Basaalsnelheid:",
    cancel: "Annuleren",
    save: "Opslaan",
    editSegModalTitle: "Segmenten bewerken",
    editSegModalBody:
      "We leiden u door het toevoegen van een derde segment heen. Begin met tikken op het Bewerken-pictogram op het tweede segment.",
    editSeg: "Segment bewerken",
    notAvailable: "Niet beschikbaar voor de simulator.",
    ok: "OK"
  }
};

export { strings };
