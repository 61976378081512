import { createStackNavigator } from "@react-navigation/stack";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { ReactComponent as Chevron } from "../../../../../../../assets/UI_ChevronLeft.svg";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import HistoryTabStack from "../../../../../routes/simulator/HistoryTabStack";
import theme from "../../../../../theme";
import { strings } from "./HistoryDetailScreenContent";

const Stack = createStackNavigator();

const HistoryDetailScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  // const historyState: HistoryState = useSelector(
  //   (state: RootState) => state.multidayHistory,
  //   isEqual
  // );

  // const [historyRangeIndex, setHistoryRangeIndex] = React.useState<number>(0);
  // const [historyRange, setHistoryRange] = React.useState(
  //   historyState.sevenDays
  // );

  const { languageContent } = useLanguageContent(strings);

  // const selectDateRange = (index: number) => {
  //   switch (index) {
  //     case 0:
  //       null;
  //       break;
  //     case 1:
  //       setHistoryRange(historyState.sevenDays);
  //       break;
  //     case 2:
  //       setHistoryRange(historyState.fourteenDays);
  //       break;
  //     case 3:
  //       setHistoryRange(historyState.thirtyDays);
  //       break;
  //     case 4:
  //       setHistoryRange(historyState.sixtyDays);
  //       break;
  //     case 5:
  //       setHistoryRange(historyState.ninetyDays);
  //       break;
  //   }
  //   setHistoryRangeIndex(index);
  // };

  return (
    <>
      <Stack.Navigator initialRouteName="Main">
        {/* <Stack.Screen
          name="Main"
          options={{
            headerShown: false,
            headerStyle: styles.headerStyles
          }}>
          {() => (
            <>
              <View
                style={[
                  styles.dateBar,
                  {
                    justifyContent:
                      historyRangeIndex !== 0 && historyRange
                        ? width < 400
                          ? "flex-start"
                          : "center"
                        : "flex-start"
                  }
                ]}> */}
        {/* multiday history date bar */}
        {/* {historyRangeIndex !== 0 &&
                historyRange &&
                historyRange.dateRange &&
                historyRange.dateRange.fromMonth ? (
                  <View style={styles.dateRange}>
                    <Text style={[theme.fonts.body1, { alignSelf: "center" }]}>
                      {langState.language === "en-US"
                        ? `${historyRange.dateRange.fromMonth} ${historyRange.dateRange.fromDate} - `
                        : `${historyRange.dateRangeSpanish.fromMonth} ${historyRange.dateRangeSpanish.fromDate} - `}
                    </Text>
                    {historyRange.dateRange.fromMonth !==
                    historyRange.dateRange.toMonth ? (
                      <Text
                        style={[theme.fonts.body1, { alignSelf: "center" }]}>
                        {langState.language === "en-US"
                          ? `${historyRange.dateRange.toMonth}`
                          : `${historyRange.dateRangeSpanish.toMonth}`}
                      </Text>
                    ) : null}
                    <Text
                      style={[
                        theme.fonts.body1,
                        styles.toDateStyle
                      ]}>{` ${historyRange.dateRange.toDate}`}</Text>
                  </View>
                ) : (
                  <> */}
        {/* one day history date bar */}
        {/* <Chevron />
                    <Text style={[theme.fonts.body1, { alignSelf: "center" }]}>
                      {`${languageContent.today} ${
                        moment().format("LL").split(",")[0]
                      }`}
                    </Text>
                  </>
                )}
                <HistoryRangeSelector
                  selectDateRange={(index: number) => selectDateRange(index)}
                />
              </View>
              {historyRangeIndex !== 0 && historyRange && historyRangeIndex ? ( */}
        {/* // multiday history
                <MultidayHistorySection
                  historyRange={historyRange}
                  historyRangeIndex={historyRangeIndex}
                />
              ) : (
                // one day history
                <HistoryTabStack />
              )}
            </>
          )}
        </Stack.Screen> */}
        <Stack.Screen
          name="Main"
          options={{
            headerShown: false,
            headerStyle: {
              shadowColor: "#888",
              shadowOpacity: 0.4,
              shadowRadius: 3,
              shadowOffset: {
                height: 3,
                width: 0
              },
              elevation: 8
            }
          }}>
          {(setActiveTab) => (
            <View style={{ flex: 1, backgroundColor: "white" }}>
              <View style={{ padding: 16, marginTop: -6 }}>
                <Chevron />
                <Text
                  style={[
                    theme.fonts.body2,
                    { alignSelf: "center", marginTop: -24, marginBottom: -12 }
                  ]}>
                  {`${languageContent.today}, ${new Date().toLocaleDateString(
                    langState.locale,
                    {
                      month: "long",
                      day: "numeric",
                      year: "numeric"
                    }
                  )}`}
                </Text>
              </View>
              <HistoryTabStack />
            </View>
          )}
        </Stack.Screen>
      </Stack.Navigator>
    </>
  );
};

const styles = StyleSheet.create({
  headerStyles: {
    shadowColor: "#888",
    shadowOpacity: 0.4,
    shadowRadius: 3,
    shadowOffset: {
      height: 3,
      width: 0
    },
    elevation: 8
  },
  dateBar: {
    padding: 16,
    flexDirection: "row",
    zIndex: 100
  },
  dateRange: { flexDirection: "row" },
  historyTabStack: {
    flex: 1,
    zIndex: -1
  },
  toDateStyle: { alignSelf: "center", marginRight: 20 }
});

export default HistoryDetailScreen;
