import * as type from './actions';
import { AndroidVersions } from 'web/data/appVersions';

interface NavStateParams {
  showBottomInsulinModal?: boolean;
}

export interface NavState {
  currentRoute: string;
  simEveryday: boolean;
  navigateTo: string;
  // @ToDo: In the future, this will be a more complex value type once iOS is introduced.
  appVersion: AndroidVersions;
  podStackEntryRoute: 'Drawer' | 'FTS';
  navigateToLabel: string;
  params: NavStateParams;
}

export const initialState: NavState = {
  currentRoute: 'Dashboard',
  simEveryday: false,
  navigateTo: 'Dashboard',
  appVersion: '1.2',
  podStackEntryRoute: 'Drawer',
  navigateToLabel: 'Dashboard',
  params: {},
};

const reducer = (
  state: NavState = initialState,
  action: type.Action
): NavState => {
  switch (action.type) {
    case type.NAVIGATION_UPDATE:
      return { ...state, ...action.payload.navigation };
    case type.NAVIGATION_INIT:
      return { ...initialState };
    case type.NAVIGATE_TO_ROUTE:
      return { ...state, ...action.payload.navigation };
    default:
      return state;
  }
};

export default reducer;
