import React from "react";

interface Props {
  children: JSX.Element;
}

const AuthForm: React.FC<Props> = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}>
      <div
        style={{
          height: 200,
          width: "auto",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}>
        <div
          style={{
            padding: "2rem",
          }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
