let strings = {
  "en-US": {
    doNotProceed: "DO NOT PROCEED",
    completeTraining:
      "You must complete training before you can use this product!",
    haveYouBeenTrained: "Have you been trained to:",
    setupPod: "Set up and change your Pod",
    createBasal: "Create and edit your Basal Program",
    calculateBolus: "Calculate your bolus",
    pauseInsulin: "Pause your insulin delivery",
    respondAlerts: "Respond to your system alarms and ",
    notifications: "notifications",
    setupApp: "Set up Omnipod 5 App",
    connectApp: "Connect your Sensor to Omnipod 5 ",
    app: "App",
    critical:
      "These are critical to the safe and effective use of this product",
    pleaseSelect: "Please select one of the following:",
    willSchedule: "I will schedule training",
    amTraining: "I am in training",
    completedTraining: "I completed training",
    declineTraining: "I decline training"
  },
  "en-GB": {
    doNotProceed: "DO NOT PROCEED",
    completeTraining:
      "You must complete training before you can use this product!",
    haveYouBeenTrained: "Have you been trained to:",
    setupPod: "Set up and change your Pod",
    createBasal: "Create and edit your Basal Program",
    calculateBolus: "Calculate your bolus",
    pauseInsulin: "Pause your insulin delivery",
    respondAlerts: "Respond to your system alarms and ",
    notifications: "notifications",
    setupApp: "Set up Omnipod 5 App",
    connectApp: "Connect your Sensor to Omnipod 5 ",
    app: "App",
    pleaseSelect: "Please select one of the following:",
    willSchedule: "I will schedule training",
    amTraining: "I am in training",
    completedTraining: "I completed training",
    declineTraining: "I decline training"
  },
  "es-US": {
    doNotProceed: "NO CONTINUE",
    completeTraining:
      "¡Debe completar la formación antes de usar este producto!",
    haveYouBeenTrained: "¿Ya recibió la siguiente formación para...?",
    setupPod: "Configurar y cambiar el Pod",
    createBasal: "Crear y editar su programa basal",
    calculateBolus: "Calcular el bolo",
    pauseInsulin: "Pausar la administración de insulina",
    respondAlerts: "Responder a las alarmas y ",
    notifications: "notificaciones del sistema",
    setupApp: "Configurar la aplicación Omnipod 5",
    connectApp: "Conectar su sensor a Omnipod 5 ",
    app: "",
    pleaseSelect: "Seleccione una de las siguientes opciones:",
    willSchedule: "Programaré la formación",
    amTraining: "Estoy realizando la formación",
    completedTraining: "Completé la formación",
    declineTraining: "Rechacé la formación"
  },
  "de-DE": {
    doNotProceed: "NICHT FORTFAHREN",
    completeTraining:
      "Bevor Sie dieses Produkt verwenden können, müssen Sie Ihre Einweisung abschließen!",
    haveYouBeenTrained: "Die Schulung beinhaltet:",
    setupPod: "Einrichten und Wechseln des Pods",
    createBasal: "Basalratenprofil erstellen und ",
    createBasalTwo: "bearbeiten",
    calculateBolus: "Persönlichen Bolus berechnen",
    pauseInsulin: "Insulinverabreichung unterbrechen",
    respondAlerts: "Auf Systemalarme und ",
    notifications: "-meldungen reagieren",
    setupApp: "Einrichtung der Omnipod 5 App",
    connectApp: "Verbinden Sie Ihren Dexcom G6 ",
    app: "mit der Omnipod 5 App",
    bottomText:
      "Die sichere und effektive Verwendung dieses Produkts unerlässlich. Eine unsachgemäße Einrichtung und/oder Verwendung des Omnipod 5 kann zu einer Unter- oder Überdosierung von Insulin führen, was eine Hypoglykämie oder Hyperglykämie zur Folge haben und Ihre Gesundheit und Sicherheit gefährden und zum Tod führen könnte.",
    confirmText: "Ich verstehe und erkenne die oben genannten Bedingungen an",
    button: "Weiter"
  },
  "nl-NL": {
    doNotProceed: "DO NOT PROCEED",
    completeTraining:
      "You must complete training before you can use this product!",
    haveYouBeenTrained: "Have you been trained to:",
    setupPod: "Set up and change your Pod",
    createBasal: "Create and edit your Basal Program",
    calculateBolus: "Calculate your bolus",
    pauseInsulin: "Pause your insulin delivery",
    respondAlerts: "Respond to your system alarms and ",
    notifications: "notifications",
    setupApp: "Set up Omnipod 5 App",
    connectApp: "Connect your Sensor to Omnipod 5 ",
    app: "App",
    critical:
      "These are critical to the safe and effective use of this product",
    pleaseSelect: "Please select one of the following:",
    willSchedule: "I will schedule training",
    amTraining: "I am in training",
    completedTraining: "I completed training",
    declineTraining: "I decline training"
  },
  "fr-FR": {
    doNotProceed: "Ne pas poursuivre",
    completeTraining:
      "Vous devez réaliser la formation pour pouvoir utiliser ce produit!",
    haveYouBeenTrained: "Avez-vous été formé à:",
    setupPod: "Configurez et modifiez votre Pod",
    createBasal: "Créez et modifiez votre programme basal",
    calculateBolus: "Calculez votre bolus",
    pauseInsulin: "Suspendez votre administration d\'insuline",
    respondAlerts: "Répondez aux alarmes et aux notifications de votre système ",
    notifications: "Notifications",
    setupApp: "Configurez l\'Application Omnipod 5",
    connectApp: "Connectez votre Capteur à l\'Application Omnipod 5",
    app: "App",
    critical:
      "Cela est essentiel pour une utilisation sûre et efficace de ce produit.",
    pleaseSelect: "Sélectionnez l\'une des options suivantes:",
    willSchedule: "Je vais programmer ma formation",
    amTraining: "Je suis en formation",
    completedTraining: "J\'ai terminé ma formation",
    declineTraining: "Je ne veux pas être formé"
  },
};

export { strings };
