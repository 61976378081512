import { isEqual } from "lodash"
import React from "react"
import { StyleSheet, Text, View } from "react-native-web"
import { useSelector } from "react-redux"
import useLanguageContent from "../../../hooks/useLanguageContent"
import { LangState } from "../../../../../web/store/reducers/language"
import { RootState } from "../../../../../web/store/store"
import theme from "../../../theme"
import { strings } from "./AutoEventsHeaderContent"

interface Props {}

const AutoEventsHeader: React.FC<Props> = () => {
  const { languageContent } = useLanguageContent(strings)
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual,
  )
  return (
    <View style={styles.card}>
      <View
        style={{
          borderBottomColor: theme.colors.textInputBorder,
          borderBottomWidth: 1,
          flexDirection: "row",
          justifyContent: "space-evenly",
          paddingVertical: 8,
        }}
      >
        <View />
        <View style={{ flexDirection: "column", alignItems: "center" }}>
          <Text style={{ ...theme.fonts.body2 }}>{languageContent.sensor}</Text>
          <Text
            style={{
              ...theme.fonts.body2,
              color: theme.colors.text.grayText,
              marginVertical: -4,
            }}
          >
            {langState.units}
          </Text>
        </View>
        <View style={{ flexDirection: "column", alignItems: "center" }}>
          <Text style={{ ...theme.fonts.body2 }}>
            {languageContent.insulinAmount}
          </Text>
          <Text
            style={{
              ...theme.fonts.body2,
              color: theme.colors.text.grayText,
              marginVertical: -4,
            }}
          >
            {languageContent.u}
          </Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    width: "100%",
    backgroundColor: theme.colors.white,
    alignSelf: "center",
  },
})

export default AutoEventsHeader
