import { useNavigation } from '@react-navigation/native';
import { isEqual } from 'lodash';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { LangState } from 'web/store/reducers/language';
import { UserState } from 'web/store/reducers/user';
import { RootState } from 'web/store/store';
import theme from 'mobile/latest/theme';
import {
  BottomInnerNav,
  CurrentSensorSwitch,
  TransmitterSNRow,
} from 'mobile/latest/components/molecules';
import { strings } from './CGMTransmitterSettingsScreenContent';

const CGMTransmitterSettingsScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={[styles.container]}>
      {userState.automatedMode ? (
        <View style={{ height: '100%' }}>
          <CurrentSensorSwitch
            title={languageContent.currentSensor}
            subtitle={languageContent.sensorText}
            switchText={languageContent.switch}
          />
          <TransmitterSNRow title={languageContent.serialNumber} />
          <View
            style={{
              backgroundColor: theme.colors.textInputBackgroundColor,
              height: '25%',
            }}
          >
            <View style={styles.grayBar} />
            <Text style={[styles.newSN]}>{languageContent.newSN}</Text>
            <Text style={[styles.newSN]}>{languageContent.newSNTwo}</Text>
            <Text style={[styles.newSN]}>{languageContent.newSNThree}</Text>
          </View>
        </View>
      ) : (
        <View style={{ height: '100%' }}>
          <CurrentSensorSwitch
            onPressHandler={() => navigate('SwitchSensorStack')}
            title={languageContent.currentSensor}
            subtitle={languageContent.sensorText}
            switchText={languageContent.switch}
          />
          <TransmitterSNRow title={languageContent.serialNumber} />
        </View>
      )}
      <BottomInnerNav
        leftActionText={languageContent.close}
        leftActionStyle={{ color: theme.colors.purple, fontSize: 16 }}
        middleActionDisabled={userState.automatedMode ? true : false}
        middleActionText={languageContent.delete}
        middleActionStyle={{
          color: userState.automatedMode
            ? theme.colors.placeholder
            : theme.colors.purple,
          marginLeft: langState.language === 'en-US' ? 42 : 16,
          fontSize: 16,
        }}
        rightActionDisabled={userState.automatedMode ? true : false}
        rightActionText={languageContent.enterNew}
        rightActionStyle={{
          color: userState.automatedMode
            ? theme.colors.placeholder
            : theme.colors.purple,
          fontSize: 16,
          marginLeft: langState.language === 'en-US' ? 24 : 20,
        }}
        rightActionNavigateTo="InitialSetupG6Stack"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  grayBar: {
    height: 2,
    backgroundColor: theme.colors.divider,
    marginBottom: 16,
    width: '90%',
    alignSelf: 'center',
  },
  newSN: {
    ...theme.fonts.h5,
    fontSize: 16,
    fontWeight: 300,
    alignSelf: 'center',
    marginTop: 8,
  },
});

export default CGMTransmitterSettingsScreen;
