import { defineMessages } from 'react-intl';

export const historyEventPodMessages = defineMessages({
  title: {
    id: 'ios.history-event-pod.title',
    defaultMessage: 'Pod',
  },
  activatedStatus: {
    id: 'ios.history-event-pod.activated-status',
    defaultMessage: 'Activated',
  },
  deactivatedStatus: {
    id: 'ios.history-event-pod.deactivated-status',
    defaultMessage: 'Deactivated',
  },
});
