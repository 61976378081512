let strings = {
  "en-US": {
    timeSeg: "Time segment %1 (maximum 24)",
    paused: "Insulin delivery is paused"
  },
  "en-GB": {
    timeSeg: "Time segment %1 (maximum 24)",
    paused: "Insulin delivery is paused"
  },
  "de-DE": {
    timeSeg: "Zeitsegment %1 (Maximum 24)",
    paused: "Insulinabgabe ist unterbrochen"
  },
  "es-US": {
    timeSeg: "Segmento de tiempo %1 (máximo 24)",
    paused: "Se pausó la administración de insulina"
  },
  "fr-FR": {
    timeSeg: "Segment temporel de %1 (maximum 24)",
    paused: "L'administration d'insuline a été mise en pause"
  },
  "nl-NL": {
    timeSeg: "Tijdsegment %1 (maximum 24)",
    paused: "Insulinetoediening is gepauzeerd"
  }
};

export { strings };
