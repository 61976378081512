import { isEqual } from 'lodash';
import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import { LangState } from 'web/store/reducers/language';
import { RootState } from 'web/store/store';
import theme from 'mobile/latest/theme';

interface CustomToggleSwitchProps {
  title: string;
  subtitle: string;
  switchText: string;
  onPressHandler?: () => void;
}

const CurrentSensorSwitch: React.FC<CustomToggleSwitchProps> = ({
  title,
  subtitle,
  switchText,
  onPressHandler,
}) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  return (
    <View style={{ backgroundColor: 'white', height: '15%' }}>
      <Text style={[styles.currentSensorText]}>{title}</Text>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingHorizontal: 14,
          marginTop: 6,
        }}
      >
        <Text style={[styles.sensorText, { fontWeight: 300 }]}>{subtitle}</Text>
        <TouchableOpacity onPress={onPressHandler}>
        <Text
          style={[
            styles.sensorText,
            {
              color: theme.colors.purple,
            },
          ]}
        >
          {switchText}
        </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  currentSensorText: {
    ...theme.fonts.h4,
    marginTop: 16,
    marginLeft: 12,
  },
  sensorText: {
    ...theme.fonts.h4,
    fontSize: 16,
  },
});

export default CurrentSensorSwitch;
