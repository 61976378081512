import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  GestureResponderEvent,
} from 'react-native';
import { useIntl } from 'react-intl';
import { messages } from './ScanSuccessful.messages';
import theme from 'mobile/latest/theme';

const styles = StyleSheet.create({
  title: {
    marginTop: 5,
  },
  paragraph: {
    marginTop: 16,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 20,
    color: '#000',
    marginTop: 40,
    marginBottom: 10,
  },
  buttonText: {
    color: theme.colors.purple,
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: '500',
  },
});

const ScanSuccesfull = (props: {
  okButtonOnPressHandler: (event: GestureResponderEvent) => void;
}) => {
  const { okButtonOnPressHandler } = props;
  const intl = useIntl();

  return (
    <>
      <Text style={[theme.fonts.h5, styles.title]}>
        {intl.formatMessage(messages.title)}
      </Text>
      <Text style={styles.paragraph}>
        {intl.formatMessage(messages.paragraphOne)}
      </Text>
      <Text style={styles.paragraph}>
        {intl.formatMessage(messages.paragraphTwo)}
      </Text>
      <View style={styles.buttonsContainer}>
        <TouchableOpacity onPress={okButtonOnPressHandler}>
          <Text style={styles.buttonText}>{intl.formatMessage(messages.cta)}</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

export default ScanSuccesfull;
