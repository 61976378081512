let strings = {
  "en-US": {
    bolus: "Bolus",
    insulinThat:
      "Insulin that is taken with meals or to bring down a high glucose."
  },
  "en-GB": {
    bolus: "Bolus",
    insulinThat:
      "Insulin that is taken with meals or to bring down a high glucose."
  },
  "de-DE": {
    bolus: "Bolus",
    insulinThat:
      "Insulin zur Einnahme zu den Mahlzeiten oder zur Senkung eines hohen Glukosewerts."
  },
  "es-US": {
    bolus: "Bolo",
    insulinThat:
      "Insulina que se consume con las comidas o para bajar la glucosa alta."
  },
  "fr-FR": {
    bolus: "Bolus",
    insulinThat:
      "Insuline prise avec les repas ou pour abaisser un glucose élevé."
  },
  "nl-NL": {
    bolus: "Bolus",
    insulinThat:
      "Insuline toegediend bij maaltijden of om een hoge bloedglucose omlaag te brengen."
  }
};

export { strings };
