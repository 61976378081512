let strings = {
  "en-US": {
    timeSegments: "Time segments",
    total: "Total:"
  },
  "en-GB": {
    timeSegments: "Time segments",
    total: "Total:"
  },
  "de-DE": {
    timeSegments: "Zeitsegmente",
    total: "Gesamt:"
  },
  "es-US": {
    timeSegments: "Segmentos temporales",
    total: "Total:"
  },
  "fr-FR": {
    timeSegments: "Segments temporels",
    total: "Total :"
  },
  "nl-NL": {
    timeSegments: "Tijdsegmenten",
    total: "Totaal:"
  }
};

export { strings };
