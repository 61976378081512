import React from "react";
import { GraphVerticalLine } from "../../atoms";
import { BasalSegmentChartItem } from "../../molecules";

interface Props {
  startTime: any;
  endTime: any;
  maxHeight: any;
  maxBasalRateCalc: any;
  index: any;
  program: any;
  hideBottomBorder?: boolean;
  isPlaceholderSegment?: boolean;
}

const PlaceholderBasalSegment: React.FC<Props> = ({
  startTime,
  maxHeight,
  maxBasalRateCalc,
  index,
  program,
  hideBottomBorder,
  isPlaceholderSegment
}) => {
  return (
    <>
      <GraphVerticalLine maxHeight={maxHeight} marginLeftNumber={-3} />
      {startTime && startTime.toLowerCase() !== `12:00 am` && (
        <BasalSegmentChartItem
          hideBottomBorder={hideBottomBorder}
          segment={{
            start: startTime,
            end: `12:00 am`,
            basalRate: 0
          }}
          maxBasalRateCalc={maxBasalRateCalc}
          maxHeight={maxHeight}
          index={index}
          program={program}
          isPlaceholderSegment
        />
      )}
    </>
  );
};

export default PlaceholderBasalSegment;
