import PickerItem from "../types/pickerItem";

const TWENTY_FOUR_HOURS_DATA: Array<PickerItem> = [];
for (let i: number = 0; i <= 23; i++) {
  TWENTY_FOUR_HOURS_DATA.push({
    label: (i + 1).toString(),
    value: i + 1,
    key: `${i}KEY${i}`,
    index: i
  });
}

export default TWENTY_FOUR_HOURS_DATA;
