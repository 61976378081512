import React from "react";
import { Text, View } from "react-native";
import { StyleSheet } from "react-native-web";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import RemindersItem from "../../atoms/remindersItem";
import { strings } from "./PodRemindersSectionContent";

interface Props {}

const PodRemindersSection: React.FC<Props> = () => {
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ flex: 3 }}>
      <View
        style={{
          flexDirection: "row",
          paddingTop: 16,
          paddingLeft: 14
        }}>
        <Text style={[theme.layout.boldDesc, styles.sectionSubheaderValue]}>
          {languageContent.reminders}
        </Text>
      </View>
      <RemindersItem
        title={languageContent.podExp}
        status={languageContent.alwaysOn}
        description={languageContent.remind}
      />
      <RemindersItem
        title={languageContent.lowPod}
        status={languageContent.alwaysOn}
        description={languageContent.remindWhen}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  sectionHeaderValue: {
    ...theme.fonts.h4,
    textAlign: "left",
    fontWeight: "500"
  },
  sectionSubheaderValue: {
    ...theme.fonts.text600,
    color: theme.colors.text.grayText
  }
});

export default PodRemindersSection;
