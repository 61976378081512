import React from 'react';
import classNames from 'classnames';

interface ListStepProps {
  containerClasses?: string;
  index: number;
  text: string;
}

export const ListStep: React.FC<ListStepProps> = ({ containerClasses, index, text }) => {
  const containerClassesMerged = classNames('flex items-start', containerClasses);
  return (
    <div className={containerClassesMerged}>
      <span className="bg-modes-automated w-[24px] h-[24px] rounded-full text-white text-smaller flex items-center justify-center mr-2 p-1">
        {index}
      </span>
      <span className="flex-initial">{text}</span>
    </div>
  );
};
