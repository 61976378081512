export enum IOSRoutesEnums {
  WelcomeOmnipod5 = 'WelcomeOmnipod5',
  SignIn = 'SignIn',
  TermsConditions = 'TermsConditions',
  OmnipodLinked = 'OmnipodLinked',
  OmnipodSecurity = 'OmnipodSecurity',
  OmnipodSecurityComplete = 'OmnipodSecurityComplete',
  Notifications = 'Notifications',
  Sound = 'Sound',
  AccessibilitySettings = 'AccessibilitySettings',
  DateTime = 'DateTime',
  Great = 'Great',
  SplashScreen = 'SplashScreen',
  BasalFTS = 'BasalFTS',
  NeedHelp = 'NeedHelp',
  General = 'General',
  Timezone = 'Timezone',
  CheckAlarms = 'CheckAlarms',
  Pod = 'Pod',
}
