import React from "react";
import { StyleSheet, View } from "react-native";
import truncate from "../../../helpers/truncate";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import { Paragraph } from "../../atoms";
import { strings } from "./SegmentTopBarContent";

interface Props {
  programName?: string;
  segmentNum?: string;
  showRightUnits?: boolean;
  isOnlyProgramName?: boolean;
  truncateLength?: number;
}

const SegmentTopBar: React.FC<Props> = ({
  programName,
  segmentNum,
  showRightUnits,
  isOnlyProgramName,
  truncateLength
}) => {
  const { languageContent } = useLanguageContent(strings);

  const getLabel = () => {
    let leftSegmentTopBarLabel: string;
    const truncateCharacterLength = truncateLength ? truncateLength : 15;

    if (programName && segmentNum) {
      if (isOnlyProgramName) {
        leftSegmentTopBarLabel = programName;
      } else {
        leftSegmentTopBarLabel =
          truncate(programName, truncateCharacterLength) +
          `: ${languageContent.segment} ${segmentNum}`;
      }
    } else if (!programName) {
      leftSegmentTopBarLabel = `${languageContent.segment} ${segmentNum}`;
    } else {
      leftSegmentTopBarLabel = truncate(programName, truncateCharacterLength);
    }

    return leftSegmentTopBarLabel;
  };

  const [label] = React.useState<string>(getLabel());

  return (
    <View style={styles.wrapper}>
      <Paragraph
        variant="body2"
        label={label}
        styles={{
          marginHorizontal: 16,
          color: theme.colors.greenText,
          width: 160,
          marginVertical: 8
        }}
      />
      <View style={{ paddingHorizontal: 16 }}>
        {showRightUnits && (
          <Paragraph variant="body2" label={languageContent.graph} />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

export default SegmentTopBar;
