let strings = {
  'en-US': {
    changePod: 'Change Pod',
    pod: 'Pod',
  },
  'en-GB': {
    changePod: 'Change Pod',
    pod: 'Pod',
  },
  'de-DE': {
    changePod: 'Pod auswechseln',
    pod: 'Pod',
  },
  'es-US': {
    changePod: 'Cambiar el Pod',
    pod: 'Pod',
  },
  'nl-NL': {
    changePod: 'Pod vervangen',
    pod: 'Pod',
  },
  'fr-FR': {
    changePod: 'Changer le Pod',
    pod: 'Pod',
  },
};

export { strings };
