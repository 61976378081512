let strings = {
  "en-US": {
    programName: "Program Name",
    placeholder: "Basal",
    cancel: "Cancel",
    next: "Next"
  },
  "en-GB": {
    programName: "Programme Name",
    placeholder: "Basal",
    cancel: "Cancel",
    next: "Next"
  },
  "de-DE": {
    programName: "Profilname",
    placeholder: "Basalrate",
    cancel: "Abbr.",
    next: "Weiter"
  },
  "es-US": {
    programName: "Nombre del programa",
    placeholder: "Basal",
    cancel: "Cancelar",
    next: "Siguiente"
  },
  "fr-FR": {
    programName: "Nom du programme",
    placeholder: "Débit basal",
    cancel: "Annuler",
    next: "Suivant"
  },
  "nl-NL": {
    programName: "Programmanaam",
    placeholder: "Basaal",
    cancel: "Annuleren",
    next: "Volgende"
  }
};

export { strings };
