let strings = {
  "en-US": {
    graph: "Graph: U/hr",
    segment: "Segment"
  },
  "en-GB": {
    graph: "Graph: U/hr",
    segment: "Segment"
  },
  "de-DE": {
    graph: "Grafik: E/Std.",
    segment: "Segment"
  },
  "es-US": {
    graph: "Gráfico: U/h",
    segment: "Segmento"
  },
  "fr-FR": {
    graph: "Graphique : U/h",
    segment: "Segment"
  },
  "nl-NL": {
    graph: "Grafiek: E/u.",
    segment: "Segment"
  }
};

export { strings };
