let strings = {
  "en-US": {
    allowExBolus: "Allow Extended Bolus?",
    extending:
      "Extending a bolus means that a meal bolus can be delivered over a prolonged period of time.",
    exBolus: "Extended Bolus",
    cancel: "Cancel",
    next: "Next"
  },
  "en-GB": {
    allowExBolus: "Allow Extended Bolus?",
    extending:
      "Extending a bolus means that a meal bolus can be delivered over a prolonged period of time.",
    exBolus: "Extended Bolus",
    cancel: "Cancel",
    next: "Next"
  },
  "de-DE": {
    allowExBolus: "Verzögerten Bolus zulässig?",
    extending:
      "Verzögerter Bolus bedeutet, dass ein Mahlzeitenbolus über eine längere Zeitdauer abgegeben werden kann.",
    exBolus: "Verzögerter Bolus",
    cancel: "Abbr.",
    next: "Weiter"
  },
  "es-US": {
    allowExBolus: "¿Permitir el bolo extendido?",
    extending:
      "Extender un bolo significa que se puede administrar un bolo de comida durante un período prolongado de tiempo.",
    exBolus: "Bolo extendido",
    cancel: "Cancelar",
    next: "Siguiente"
  },
  "fr-FR": {
    allowExBolus: "Autoriser un bolus prolongé ?",
    extending:
      "La prolongation d'un bolus signifie qu'un bolus repas peut être administré sur une période prolongée.",
    exBolus: "Bolus prolongé",
    cancel: "Annuler",
    next: "Suivant"
  },
  "nl-NL": {
    allowExBolus: "Verlengde bolus toestaan?",
    extending:
      "Het verlengen van een bolus betekent dat een maaltijdbolus over langere tijd kan worden toegediend.",
    exBolus: "Verlengde bolus",
    cancel: "Annuleren",
    next: "Volgende"
  }
};

export { strings };
