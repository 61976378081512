import { createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "../components";
import { ErrorPage, HomePage } from "../pages";
import React from 'react';

const appRoutes = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <HomePage />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />
  }
]);

export default appRoutes;
