import React from 'react';
import { IconProps } from './types';
import classNames from 'classnames';

export const MenuIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={classes} fillRule="evenodd" clipRule="evenodd" d="M6 12.5C6 14.1569 4.65685 15.5 3 15.5C1.34315 15.5 0 14.1569 0 12.5C0 10.8431 1.34315 9.5 3 9.5C4.65685 9.5 6 10.8431 6 12.5ZM15 12.5C15 14.1569 13.6569 15.5 12 15.5C10.3431 15.5 9 14.1569 9 12.5C9 10.8431 10.3431 9.5 12 9.5C13.6569 9.5 15 10.8431 15 12.5ZM21 15.5C22.6569 15.5 24 14.1569 24 12.5C24 10.8431 22.6569 9.5 21 9.5C19.3431 9.5 18 10.8431 18 12.5C18 14.1569 19.3431 15.5 21 15.5Z" />
    </svg>
  );
}
