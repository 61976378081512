import React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import COUNTRIES from "../../../data/countries";
import LANGUAGES from "../../../data/languages";
import getCurrentHourFormat from "../../../helpers/getCurrentHourFormat";
import makePascalCase from "../../../helpers/makePascalCase";
import theme from "../../../theme";
import { LocaleType } from "../../../types/locales";

interface RadioButtonProps {
  item: { label: string; icon?: any; helpText?: string };
  index: number;
  selectedItem: string | null;
  units?: string;
  helpText?: string;
  formatTime?: boolean;
  handleRadioButtonPress: (label: string) => void;
  is24Hour?: boolean;
  locale?: LocaleType;
  disabled?: boolean;
}

const CustomRadioButton: React.FC<RadioButtonProps> = ({
  item,
  index,
  selectedItem,
  units,
  helpText,
  handleRadioButtonPress,
  is24Hour,
  locale,
  disabled = false,
}) => {
  return (
    <View key={`${index}`}>
      <TouchableOpacity
        key={index}
        style={styles.radioButtonContainer}
        disabled={disabled}
        onPress={() => handleRadioButtonPress(item.label)}>
        <View>
          <View style={[styles.outerCircle, disabled && styles.outerCircleDisabled]}>
            <View
              style={[
                styles.innerCircle,
                {
                  backgroundColor:
                    item && selectedItem === item.label
                      ? theme.colors.purple
                      : "transparent"
                }
              ]}
            />
          </View>
        </View>
        <View
          style={{
            width:
              LANGUAGES.filter((obj) => obj.label === item.label).length > 0 ||
              COUNTRIES.filter((obj) => obj.label === item.label).length > 0
                ? "100%"
                : 100
          }}>
          <Image source={item && item.icon} />
          <Text
            style={[
              styles.text,
              {
                marginLeft:
                  item.label === "mg/dL" || item.label === "mmol/L" ? 0 : 10
              },
              disabled && styles.textDisabled,
            ]}>
            {item && !isNaN(Number(item.label))
              ? Number(item.label).toLocaleString(locale, {
                  minimumFractionDigits:
                    units === "mg/dL" ||
                    units === "mmol/L" ||
                    units === "hr" ||
                    units === "hrs"
                      ? 0
                      : 2
                })
              : getCurrentHourFormat({
                  timeStr: item.label,
                  is24Hour: is24Hour as boolean
                })}
            {item && units && ` ${units}`}
          </Text>
        </View>
        {helpText ? <Text>({item && makePascalCase(helpText)})</Text> : null}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  radioButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 270
  },
  outerCircle: {
    borderColor: theme.colors.purple,
    borderWidth: 1.5,
    width: 20,
    height: 20,
    borderRadius: 20,
    marginVertical: 8,
    marginHorizontal: 16,
    justifyContent: "center",
    alignItems: "center"
  },
  outerCircleDisabled: {
    borderColor: theme.colors.adornment,
  },
  innerCircle: {
    height: 13,
    width: 13,
    borderRadius: 16,
    marginLeft: 0.5
  },
  text: {
    textAlign: "left",
  },
  textDisabled: {
    color: theme.colors.adornment,
  }
});

export default CustomRadioButton;
