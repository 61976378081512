import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { ReactComponent as OmniPodLogo } from '../../assets/omnipod5_logo.svg';
import { Button } from 'web/components/ios/Button';
import { IOSRoutesEnums } from '../types';
import { FormattedMessage } from 'react-intl';
import { messages } from '../FtsStartScreenStack.messages';

export const WelcomeOmnipodScreen = () => {
  const { navigate } = useNavigation();

  return (
    <div className="flex justify-start flex-col flex-1 items-stretch bg-white">
      <div className="px-2 mt-3">
        <OmniPodLogo />
        <div
          className="text-modes-automated font-bold mt-4"
          style={{ fontSize: 29 }}
        >
          <FormattedMessage {...messages.welcomeOmnipodTitle} />
        </div>
        <div className="text-black mt-1 mb-2">
          <FormattedMessage {...messages.getStartedMessage} />
        </div>
      </div>
      <div className="px-2 flex bg-cover bg-no-repeat justify-end flex-col grow items-end bg-welcomeOmnipodImage">
        <div className="w-full mb-4">
          <Button
            className="w-full rounded-lg font-bold"
            onClick={() => navigate(IOSRoutesEnums.SignIn)}
            variant="colored"
          >
            <FormattedMessage {...messages.okButtonTitle} />
          </Button>
        </div>
      </div>
    </div>
  );
};
