import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import replaceWithValues from "../../../../../helpers/replaceWithValues";
import truncate from "../../../../../helpers/truncate";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { BasalState } from "../../../../../../../web/store/reducers/basal";
import { LangState } from "../../../../../../../web/store/reducers/language";
import {
  navigationActions,
  NavState
} from "../../../../../../../web/store/reducers/navigation";
import { UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { Segments, Timeline } from "../../../../atoms";
import { CompletedBasalProgramGraph } from "../../../../molecules";
import { strings } from "./InsulinManualScreenContent";

const { height, width } = Dimensions.get("window");

const InsulinScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const [xValue, setXValue] = React.useState<number>(0);
  const [currentUHR, setCurrentUHR] = React.useState<number>(1.0);

  const innerWidth = width - 20;

  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);

  const findCorrectUnits = !userState.isExpert
    ? (22.75).toLocaleString(langState.locale)
    : basalState.activeExpertBasalProgram.unitsPerDay.toLocaleString(
        langState.locale
      );

  const contentVariables = [findCorrectUnits];

  const getLeftValue = React.useCallback(() => {
    const today = new Date();
    const hours = today.getHours();
    const minutes = today.getMinutes();

    const thirtyMinIncrement = innerWidth / 48;
    const hourIncrement = innerWidth / 24;

    let hoursXValue = hours * hourIncrement;

    if (minutes >= 15 && minutes < 45) {
      hoursXValue = hoursXValue + thirtyMinIncrement;
    } else if (minutes >= 45) {
      hoursXValue = hoursXValue + hourIncrement;
    }

    if (hours < 8 || hours >= 16) {
      setCurrentUHR(1.0);
    } else {
      setCurrentUHR(2.0);
    }

    setXValue(hoursXValue);
  }, [innerWidth]);

  React.useEffect(() => {
    getLeftValue();
  }, [getLeftValue]);
  return (
    <View style={[styles.innerWrapper]}>
      <View style={[styles.card]}>
        <View style={[styles.topContainer]}>
          <View style={[styles.programContainer]}>
            <Text
              style={[
                theme.fonts.h4,
                {
                  fontWeight: "500",
                  marginVertical: -4
                }
              ]}>
              {truncate(basalState.activeExpertBasalProgram.programName, 15)}
            </Text>
            <Text style={[theme.fonts.body2, styles.currentBasal]}>
              {languageContent.inProgress}
            </Text>
          </View>
        </View>
        <View
          style={{
            flex: 3
          }}>
          {!userState.isExpert ? (
            <>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  marginBottom: -2,
                  marginTop: 60
                }}>
                <View
                  style={[
                    styles.container,
                    {
                      left: 120
                    }
                  ]}>
                  <Text style={[styles.uhr]}>
                    {`${currentUHR.toLocaleString(langState.locale, {
                      minimumFractionDigits: 2
                    })} `}
                    <Text style={{ fontSize: 12, fontWeight: "400" }}>
                      {languageContent.uHr}
                    </Text>
                  </Text>
                  <View
                    style={[
                      styles.verticalLine,
                      {
                        left: 38
                      }
                    ]}
                  />
                </View>
                <Segments />
              </View>
              <Timeline />
            </>
          ) : (
            <View>
              <CompletedBasalProgramGraph
                maxHeight={150}
                program={basalState.activeExpertBasalProgram}
              />
            </View>
          )}
        </View>
        <View style={[styles.bottomBar]}>
          <View>
            <Text
              style={[
                theme.fonts.text200,
                {
                  paddingHorizontal: 8,
                  paddingVertical: 8,
                  fontWeight: "500"
                }
              ]}>
              {replaceWithValues(languageContent.total, contentVariables)}
            </Text>
          </View>
          <View>
            <TouchableOpacity
              onPress={() => {
                dispatch(
                  navigationActions.navigationUpdate(
                    { navigateTo: "BasalPrograms" },
                    navState
                  )
                );
              }}>
              <Text
                style={[
                  theme.fonts.text200,
                  {
                    paddingHorizontal: 8,
                    paddingVertical: height <= 600 ? 4 : 8,
                    color: theme.colors.purple,
                    fontWeight: "500"
                  }
                ]}>
                {languageContent.view}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    width: Dimensions.get("screen").width,
    padding: 10
  },
  card: {
    width: 280,
    height: "100%",
    backgroundColor: theme.colors.white,
    shadowColor: "#888",
    shadowOpacity: 0.7,
    shadowRadius: 6,
    shadowOffset: {
      height: 1,
      width: 0
    },
    elevation: 10
  },
  currentBasal: { color: theme.colors.blue, fontSize: 14 },
  container: {
    height: "135%",
    position: "absolute",
    zIndex: 6,
    marginTop: -40,
    width: 76
  },
  topContainer: {
    flex: 0.75,
    flexDirection: "row",
    marginLeft: 8,
    marginTop: 8
  },
  verticalLine: {
    zIndex: 6,
    borderColor: theme.colors.green,
    borderWidth: 0.5,
    width: 1,
    height: "100%"
  },
  uhr: {
    borderWidth: 1,
    borderColor: theme.colors.green,
    borderRadius: 4,
    paddingTop: 3,
    ...theme.fonts.body2,
    fontWeight: "700",
    textAlign: "center",
    zIndex: 6
  },
  bottomBar: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: theme.colors.white,
    zIndex: 20,
    shadowColor: "#888",
    shadowOpacity: 0.3,
    shadowRadius: 3,
    shadowOffset: {
      height: -2,
      width: 0
    },
    elevation: 20
  },
  programContainer: {
    flex: 0.5,
    flexDirection: "column",
    marginTop: 2
  }
});

export default InsulinScreen;
