import { useNavigation } from '@react-navigation/native';
import { Button } from 'web/components/ios/Button';
import { useIntl } from 'react-intl';
import { messages } from '../GlucoseGoalRange.messages';

export const GlucoseGoalRange: React.FC = ({}) => {
  const intl = useIntl();
  const { navigate } = useNavigation();

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-9/12">
        <p className="p-6 text-regular mb-2">
          {intl.formatMessage(messages.text)}
        </p>
        <div>
          <div className="flex justify-between items-center w-full pb-2 border-b border-b-ios-gray-7 hover:cursor-pointer">
            <div className="pl-6">
              <p>{intl.formatMessage(messages.upperLimitInputLabel)}</p>
              <p className="text-xs font-semibold text-ios-gray-2">
                {intl.formatMessage(messages.upperLimitPlaceholder)}
              </p>
            </div>
            <div className="pr-6">
              <p className="font font-semibold text-modes-automated">
                <span>
                  {intl.formatMessage(messages.upperLimitValueInputLabel)}
                </span>
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center w-full pt-1 pb-2 border-b border-b-ios-gray-7 hover:cursor-pointer">
            <div className="pl-6">
              <p>{intl.formatMessage(messages.lowerLimitInputLabel)}</p>
              <p className="font-semibold text-xs text-ios-gray-2">
                {intl.formatMessage(messages.lowerLimitPlaceholder)}
              </p>
            </div>
            <div className="pr-6">
              <p className="font-semibold text-modes-automated">
                <span>
                  {intl.formatMessage(messages.lowerLimitValueInputLabel)}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="basis-2/5">
        <div className="pt-2 w-full h-full flex flex-col-reverse">
          <div className="flex flex-col w-full p-6">
            <Button onClick={() => navigate('MainMenu')} variant="colored">
              {intl.formatMessage(messages.saveBtn)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
