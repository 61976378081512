import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'review-settings.title',
    defaultMessage: 'Review your Sensor settings',
    description: 'View title'
  },
  firstParagraph: {
    id: 'review-settings.first-paragraph',
    defaultMessage: 'Your Sensor selection has been confirmed. Review and adjust your FreeStyle Libre 2 Plus Sensor settings.',
    description: 'First paragraph displayed on the view.'
  },
  secondParagraph: {
    id: 'review-settings.second-paragraph',
    defaultMessage: '<highlight>You can adjust these settings later.</highlight>(☰ > Manage Sensor > Sensor Settings)',
    description: 'Second paragraph, text inside "highlight" tag will be bold.'
  },
  cta: {
    id: 'review-settings.cta',
    defaultMessage: 'OK',
    description: 'Confirm button'
  }
});