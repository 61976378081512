import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { BackButton } from "../../components/atoms";
import {
  HypoProtectDurationScreen,
  IntroHypoProtectScreen
} from "../../components/screens";
import { useModeIconSmall } from "../../hooks";
import useLanguageContent from "../../hooks/useLanguageContent";
import theme from "../../theme";
import { strings } from "./HypoProtectStackContent";

const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const HypoProtectStack: React.FC = () => {
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  return (
    <>
      <Stack.Navigator initialRouteName="IntroHypoProtect" mode="card">
        <Stack.Screen
          name="IntroHypoProtect"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.activity} white={false} />
            ),
            headerRight: () => icon
          }}
          component={IntroHypoProtectScreen}
        />
        <Stack.Screen
          name="HypoProtect"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.activity} white={false} />
            ),
            headerRight: () => icon
          }}
          component={HypoProtectDurationScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default HypoProtectStack;
