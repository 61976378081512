import PickerItem from "../types/pickerItem";

const BASAL_RATES_DATA_WITHOUT_ZERO: Array<PickerItem> = [];

for (let i = 0.05; i <= 30.05; i += 0.05) {
  if (i.toFixed(2).toString().slice(-2) === "00") {
    BASAL_RATES_DATA_WITHOUT_ZERO.push({
      index: i,
      key: i.toFixed(2),
      label: i.toFixed(2).toString().slice(0, -3),
      value: Number(i.toFixed(2))
    });
  } else {
    BASAL_RATES_DATA_WITHOUT_ZERO.push({
      index: i,
      key: i.toFixed(2),
      label: i.toFixed(2),
      value: Number(i.toFixed(2))
    });
  }
}
export default BASAL_RATES_DATA_WITHOUT_ZERO;
