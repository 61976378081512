import React from 'react';
import { useIntl } from 'react-intl';
import { createStackNavigator } from '@react-navigation/stack';
import { MainMenu } from './views/MainMenu/MainMenu';
import { messages } from './Menu.messages';

const Stack = createStackNavigator();

export const MenuStack: React.FC = () => {
  const intl = useIntl();
  return (
    <Stack.Navigator initialRouteName="MainMenu">
      <Stack.Screen
        name="MainMenu"
        options={{
          title: intl.formatMessage(messages.title),
          headerShown: false,
        }}
        component={MainMenu}
      />
    </Stack.Navigator>
  );
};
