import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'ios.basal-programs.title',
    defaultMessage: 'Basal Programs',
  },
  inProgress: {
    id: 'ios.basal-programs.in-progress',
    defaultMessage: 'IN PROGRESS',
  },
  paused: {
    id: 'ios.basal-programs.paused',
    defaultMessage: 'PAUSED',
  },
  weekend: {
    id: 'ios.basal-programs.weekend',
    defaultMessage: 'Weekend',
  },
  totalBasal: {
    id: 'ios.basal-programs.total-basal',
    defaultMessage: 'Total Basal: {value}',
  },
  edit: {
    id: 'ios.basal-programs.edit',
    defaultMessage: 'EDIT',
  },
  savedBasalPrograms: {
    id: 'ios.basal-programs.saved-basal-programs',
    defaultMessage: 'Saved Basal Programs',
  },
  total: {
    id: 'ios.basal-programs.total',
    defaultMessage: 'Total: {value}',
  },
  units: {
    id: 'ios.basal-programs.units',
    defaultMessage: '{units} U/day',
  },
  button: {
    id: 'ios.basal-programs.button',
    defaultMessage: 'Create New Program',
  },
});

export const createBasalProgramMessages = defineMessages({
  title: {
    id: 'ios.basal-programs.create.title',
    defaultMessage: 'Create Basal Program',
  },
  programNamePlaceholder: {
    id: 'ios.basal-programs.create.program-name-placeholder',
    defaultMessage: 'Enter the name of your program',
  },
});

export const editBasalProgramMessages = defineMessages({
  title: {
    id: 'ios.basal-programs.edit.title',
    defaultMessage: 'Edit Basal Program',
  },
  programName: {
    id: 'ios.basal-programs.edit.program-name',
    defaultMessage: 'Program Name',
  },
  characterCount: {
    id: 'ios.basal-programs.edit.character-count',
    defaultMessage: '{characterCount}/32 characters',
  },
  next: {
    id: 'ios.basal-programs.edit.next',
    defaultMessage: 'Next',
  },
});

export const confirmBasalProgramMessages = defineMessages({
  title: {
    id: 'ios.basal-programs.confirm.title',
    defaultMessage: 'Confirm Basal Program',
  },
  totalBasal: {
    id: 'ios.basal-programs.confirm.total-basal',
    defaultMessage: 'Total Basal: {totalBasal} U/day',
  },
  timeSegments: {
    id: 'ios.basal-programs.confirm.time-segments',
    defaultMessage: 'TIME SEGMENTS',
  },
  total: {
    id: 'ios.basal-programs.confirm.total',
    defaultMessage: 'TOTAL: {segmentCount}',
  },
  graphLegend: {
    id: 'ios.basal-programs.confirm.graph-legend',
    defaultMessage: 'Graph: U/hr',
  },
  segmentTitle: {
    id: 'ios.basal-programs.confirm.segment-title',
    defaultMessage: 'Segment {segmentNumber}: {startTime} - {endTime}',
  },
  units: {
    id: 'ios.basal-programs.confirm.units',
    defaultMessage: '{units} U/hr',
  },
  save: {
    id: 'ios.basal-programs.confirm.save',
    defaultMessage: 'Save',
  },
});

export const editSegmentMessages = defineMessages({
  segmentNumber: {
    id: 'ios.basal-programs.edit-segment.segment-number',
    defaultMessage: 'Segment {segmentNumber}',
  },
  next: {
    id: 'ios.basal-programs.edit-segment.next',
    defaultMessage: 'Next',
  },
  startTime: {
    id: 'ios.basal-programs.edit-segment.start-time',
    defaultMessage: 'Start Time',
  },
  endTime: {
    id: 'ios.basal-programs.edit-segment.end-time',
    defaultMessage: 'End Time',
  },
  basalRate: {
    id: 'ios.basal-programs.edit-segment.basal-rate',
    defaultMessage: 'Basal Rate',
  },
  basalRateSubText: {
    id: 'ios.basal-programs.edit-segment.basal-rate-sub-text',
    defaultMessage: '(up to 30 U/hr)',
  },
  graphSubText: {
    id: 'ios.basal-programs.edit-segment.graph-sub-text',
    defaultMessage: 'Graph: U/hr',
  },
});

export const menuDrawerMessages = defineMessages({
  start: {
    id: 'ios.basal-programs.menu-drawer.start',
    defaultMessage: 'Start',
  },
  edit: {
    id: 'ios.basal-programs.menu-drawer.edit',
    defaultMessage: 'Edit',
  },
  delete: {
    id: 'ios.basal-programs.menu-drawer.delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'ios.basal-programs.menu-drawer.cancel',
    defaultMessage: 'Cancel',
  },
});
