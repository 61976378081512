let strings = {
  "en-US": {
    personalize: "Personalize Omnipod\u00A05",
    lsMessage1: "Lock Screen Message",
    lsMessage2:
      "This message displays on your lock screen. (Example: If this device is found, please call Alexandra at 555-555-5555).",
    continue: "Continue",
    lsModalMessageTitle: "Custom Lock Screen Message",
    lsModalMessage:
      "Lock screen message not available when Omnipod\u00A05 is operated on a personal device.",
    confirmText: "OK",
    message: "Alexandra's controller if found call 555-555-5555"
  },
  "en-GB": {
    personalize: "Personalise Omnipod\u00A05",
    lsMessage1: "Lock Screen Message",
    lsMessage2:
      "This message displays on your lock screen. (Example: If this device is found, please call Alexandra on 555-555-5555).",
    continue: "Continue",
    lsModalMessageTitle: "Custom Lock Screen Message",
    lsModalMessage:
      "Lock screen message not available when Omnipod\u00A05 is operated on a personal device.",
    confirmText: "OK",
    message: "Alexandra's controller, if found call 555-555-5555"
  },
  "de-DE": {
    personalize: "Omnipod 5 individualisieren",
    lsMessage1: "Meldung auf dem Sperrbildschirm",
    lsMessage2:
      "Diese Nachricht erscheint auf Ihrem Sperrbildschirm. (Beispiel: Wenn Sie dieses Gerät gefunden haben, rufen Sie bitte Alexandra unter 555-555-5555 an).",
    continue: "Weiter",
    lsModalMessageTitle: "Benutzerdefinierte Nachricht auf dem Sperrbildschirm",
    lsModalMessage:
      "Die Nachricht auf dem Sperrbildschirm ist nicht verfügbar, wenn Omnipod 5 auf einem persönlichen Gerät ausgeführt wird.",
    confirmText: "OK",
    message:
      "Alexandras Steuergerät – wenn Sie dieses Gerät gefunden haben, bitte 555-555-5555 anrufen"
  },
  "es-US": {
    personalize: "Personalizar Omnipod 5",
    lsMessage1: "Mensaje en pantalla de bloqueo",
    lsMessage2:
      "Este mensaje aparece en su pantalla de bloqueo. (Ejemplo: PDM de Alejandra. Si lo encuentra, llame al 555-555-5555).",
    continue: "Continuar",
    lsModalMessageTitle: "Mensaje personalizado de la pantalla de bloqueo",
    lsModalMessage:
      "El mensaje de la pantalla de bloqueo no está disponible cuando el Omnipod 5 se usa en un dispositivo personal.",
    confirmText: "Aceptar",
    message: "Controlador de Alexandra; si lo encuentra, llame al 555-555-5555"
  },
  "fr-FR": {
    personalize: "Personnaliser l'Omnipod 5",
    lsMessage1: "Message de l'écran de verrouillage",
    lsMessage2:
      "Ce message s'affiche sur votre écran de verrouillage. (Par exemple : Si vous trouvez ce dispositif, veuillez appeler Alexandra au 555-555-5555).",
    continue: "Continuer",
    lsModalMessageTitle: "Message de l'écran de verrouillage personnalisé",
    lsModalMessage:
      "Le message de l'écran de verrouillage n'est pas disponible lorsque l'Omnipod 5 est utilisé sur un appareil personnel.",
    confirmText: "OK",
    message:
      "Il s'agit du contrôleur d'Alexandra ; si vous le trouvez, appelez le 555-555-5555"
  },
  "nl-NL": {
    personalize: "Personaliseren Omnipod 5",
    lsMessage1: "Bericht Vergrendeld scherm",
    lsMessage2:
      "Dit bericht wordt weergegeven op uw vergrendelingsscherm. (Voorbeeld: Alexandra's PDM. Indien gevonden, bel 555-555-5555)",
    continue: "Doorgaan",
    lsModalMessageTitle: "Aangepast bericht Vergrendeld scherm",
    lsModalMessage:
      "Bericht Vergrendeld scherm niet beschikbaar wanneer Omnipod 5 wordt gebruikt op een eigen apparaat.",
    confirmText: "OK",
    message: "Controller van Alexandra. Gevonden? Bel 555-555-5555"
  }
};

export { strings };
