let strings = {
  "en-US": {
    setDuration: "Set Duration of Insulin Action",
    theLength:
      "The length of time that insulin remains active and available in your body after a correction or meal bolus.",
    duration: "Duration of Insulin Action",
    hrs: "hrs",
    range: "(2 to 6 hrs)",
    cancel: "Cancel",
    next: "Next"
  },
  "en-GB": {
    setDuration: "Set Duration of Insulin Action",
    theLength:
      "The length of time that insulin remains active and available in your body after a correction or meal bolus.",
    duration: "Duration of Insulin Action",
    hrs: "hrs",
    range: "(2 to 6 hrs)",
    cancel: "Cancel",
    next: "Next"
  },
  "de-DE": {
    setDuration: "Dauer der Insulinaktivität festlegen",
    theLength:
      "Die Zeitdauer, in der das Insulin nach einem Korrektur- oder Mahlzeitenbolus in Ihrem Körper aktiv und verfügbar bleibt.",
    duration: "Dauer der Insulinaktivität",
    hrs: "Stdn.",
    range: "(2 bis 6 Stdn.)",
    cancel: "Abbr.",
    next: "Weiter"
  },
  "es-US": {
    setDuration: "Establecer la duración de la acción de la insulina",
    theLength:
      "La cantidad de tiempo que la insulina permanece activa y disponible en su cuerpo después de una corrección o bolo de comida.",
    duration: "Duración de la acción de la insulina",
    hrs: "h",
    range: "(2 a 6 h)",
    cancel: "Cancelar",
    next: "Siguiente"
  },
  "fr-FR": {
    setDuration: "Définir la durée d'action de l'insuline",
    theLength:
      "Temps pendant lequel l'insuline reste active et disponible dans votre organisme après un bolus de correction ou un bolus repas.",
    duration: "Durée d’action de l’insuline",
    hrs: "h",
    range: "(2 à 6 h)",
    cancel: "Annuler",
    next: "Suivant"
  },
  "nl-NL": {
    setDuration: "Duur van de insulineactie instellen",
    theLength:
      "De tijd waarin insuline actief en beschikbaar blijft in het lichaam na een correctie of maaltijdbolus.",
    duration: "Duur van de insulineactie",
    hrs: "u.",
    range: "(2 tot 6 u.)",
    cancel: "Annuleren",
    next: "Volgende"
  }
};

export { strings };
