import React from "react";
import { StyleSheet, Text, View } from "react-native-web";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import { strings } from "./ListHeaderContent";

interface Props {
  numOfSegments: string;
  label?: string;
}

const ListHeader: React.FC<Props> = ({ numOfSegments, label }) => {
  const { languageContent } = useLanguageContent(strings);
  return (
    <View style={styles.segmentHeader}>
      <Text style={[theme.fonts.body3, { color: theme.colors.text.cancel }]}>
        {label ? label : languageContent.timeSegments}
      </Text>
      <Text style={[theme.fonts.body3, { color: theme.colors.text.cancel }]}>
        {languageContent.total} {numOfSegments}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  segmentHeader: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.timeSegmentsBorder,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    backgroundColor: "#EDF2F6"
  }
});

export default ListHeader;
