import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  sensorAlertsTitle: {
    id: 'L2.manage-sensor.sensor-alerts.title',
    defaultMessage: 'Libre Sensor Alerts',
  },
  belowText: {
    id: 'L2.manage-sensor.sensor-alerts.below-text',
    defaultMessage: 'Below {value} mmol/L',
  },
  aboveText: {
    id: 'L2.manage-sensor.sensor-alerts.above-text',
    defaultMessage: 'Above {value} mmol/L',
  },
  on: {
    id: 'L2.manage-sensor.sensor-alerts.on',
    defaultMessage: 'On',
  },
  off: {
    id: 'L2.manage-sensor.sensor-alerts.off',
    defaultMessage: 'Off',
  },
});