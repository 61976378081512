import { useNavigation } from '@react-navigation/native';
import { Button } from 'web/components/ios/Button';
import { IOSBolusRoutesEnums } from '../types';
import { useIntl } from 'react-intl';
import {
  messages,
  CorrectionFactorSegmentsMessages,
  CorrectionFactorSegmentOneMessages,
  CorrectionFactorSegmentTwoMessages,
} from '../Bolus.messages';

interface CorrectionFactorSegmentsProps {
  editModeHandler?: any;
}

export const CorrectionFactorSegments: React.FC<
  CorrectionFactorSegmentsProps
> = ({ editModeHandler }) => {
  const { navigate } = useNavigation();
  const intl = useIntl();

  const segmentsOnClickHandler = (route: string) => {
    editModeHandler(true);
    navigate(route);
  };

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-1/6 flex flex-col justify-between p-6 pb-0 overflow-hidden">
        <p className="text-regular">
          {intl.formatMessage(CorrectionFactorSegmentsMessages.text)}
        </p>
      </div>
      <div className="basis-5/6">
        <div className="w-full h-full flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-center w-full pb-1 mb-1 border-b border-b-ios-gray-7 hover:cursor-pointer">
              <div className="text-sm font-semibold pl-6 text-ios-gray-9 opacity-[.63]">
                <p>{intl.formatMessage(messages.timeSegmentsLabel)}</p>
              </div>
              <div className="pr-6">
                <p className="text-sm font-semibold text-ios-gray-9 opacity-[.63]">
                  <span>{intl.formatMessage(messages.totalLabel)} 2</span>
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                segmentsOnClickHandler(
                  IOSBolusRoutesEnums.CreateCorrectionFactorSegmentOne
                );
              }}
              className="flex justify-between items-center w-full pb-2 mb-1 border-b border-b-ios-gray-7 hover:cursor-pointer"
            >
              <div className="pl-6">
                <p>
                  {`${intl.formatMessage(messages.segmentTitle)} 1: 12:00 AM - 8:00 AM`}
                </p>
                <p className="font-semibold text-xs text-ios-gray-2">
                  {intl.formatMessage(
                    CorrectionFactorSegmentsMessages.placeholder
                  )}
                </p>
                <p className="font-semibold text-xs text-ios-gray-2">
                  {intl.formatMessage(
                    CorrectionFactorSegmentOneMessages.unitOfInsulinInputValue
                  )}
                </p>
              </div>
              <div className="pr-6">
                <p className="font-semibold text-ios-gray-2">
                  <span>{'>'}</span>
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                segmentsOnClickHandler(
                  IOSBolusRoutesEnums.CreateCorrectionFactorSegmentTwo
                );
              }}
              className="flex justify-between items-center w-full pb-2 mb-1 border-b border-b-ios-gray-7 hover:cursor-pointer"
            >
              <div className="pl-6">
                <p>
                  {`${intl.formatMessage(messages.segmentTitle)} 2: 8:00 AM - 12:00 AM`}
                </p>
                <p className="font-semibold text-xs text-ios-gray-2">
                  {intl.formatMessage(
                    CorrectionFactorSegmentsMessages.placeholder
                  )}
                </p>
                <p className="font-semibold text-xs text-ios-gray-2">
                  {intl.formatMessage(
                    CorrectionFactorSegmentTwoMessages.unitOfInsulinInputValue
                  )}
                </p>
              </div>
              <div className="pr-6">
                <p className="font-semibold text-ios-gray-2">
                  <span>{'>'}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full p-6">
            <Button
              onClick={() =>
                navigate(IOSBolusRoutesEnums.SetDurationOfInsulinAction)
              }
              variant="colored"
            >
              {intl.formatMessage(messages.nextBtn)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
