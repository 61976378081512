import React from "react";
import { Text, View } from "react-native";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import { CustomToggleSwitch } from "../../molecules";
import { strings } from "./CompoundToggleContent";

interface Props {
  connectedStateBooleanValue: boolean;
  label: string;
  onValueSubtext?: string;
  onToggle: () => void;
}

const CompoundToggle: React.FC<Props> = ({
  connectedStateBooleanValue,
  label,
  onValueSubtext,
  onToggle
}) => {
  const [toggleValue, setToggleValue] = React.useState<boolean>(
    connectedStateBooleanValue ? connectedStateBooleanValue : false
  );
  const { languageContent } = useLanguageContent(strings);

  return (
    <>
      <View style={[theme.layout.compoundToggleViewStyle]}>
        <View>
          <Text style={[theme.fonts.h4]}>{label}</Text>
          <Text
            style={[
              theme.fonts.body1,
              {
                color: toggleValue
                  ? theme.colors.blue
                  : theme.colors.placeholder
              }
            ]}>
            {toggleValue ? `${languageContent.on}` : `${languageContent.off}`}
          </Text>
        </View>
        <View>
          <CustomToggleSwitch
            value={toggleValue}
            onValueChange={() => {
              setToggleValue(!toggleValue);
              onToggle();
            }}
          />
        </View>
      </View>
      {toggleValue ? (
        <Text style={[theme.fonts.body1, { marginTop: 16 }]}>
          {onValueSubtext && onValueSubtext}
        </Text>
      ) : null}
    </>
  );
};

export default CompoundToggle;
