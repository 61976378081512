import React from "react";
import { StyleSheet, Text, View } from "react-native-web";
import theme from "../../../theme";

interface BottomQuadsSectionProps {
  title: string;
  boxContent: JSX.Element;
  bottomContent: JSX.Element;
}

const BottomQuadSection: React.FC<BottomQuadsSectionProps> = ({
  title,
  boxContent,
  bottomContent
}) => {
  return (
    <View
      style={{
        justifyContent: "center",
        flexDirection: "column"
      }}>
      <Text
        style={[
          theme.fonts.text600,
          title === "GRÁFICO DEL SENSOR" && { marginBottom: -8, marginTop: 9 },
          title === "SENSORGRAFIK" && { marginTop: 24 },
          title === "SENSORGRAFIEK" && { marginTop: 24 },
          title === "INFO VAN DE SENSOR" && { fontSize: 13.5 },
          title === "SENSOR GRAPH" && { marginBottom: -24, marginTop: 24 },
          title === "INFORMACIÓN DEL SENSOR" && {
            marginBottom: 10,
            marginTop: -11
          },
          {
            paddingVertical: 16,
            textAlign: "center"
          }
        ]}>
        {title}
      </Text>
      <View
        style={[
          styles.bottomQuad,
          title === "INFORMACIÓN DEL SENSOR" && { marginTop: -21 },
          title === "SENSOR GRAPH" && { marginTop: 23 }
        ]}>
        {boxContent}
      </View>
      {bottomContent}
    </View>
  );
};

const styles = StyleSheet.create({
  bottomQuad: {
    backgroundColor: "#f2f6f7",
    borderRadius: 3,
    minHeight: 86,
    height: "45%",
    justifyContent: "center",
    width: "8rem"
  }
});

export default BottomQuadSection;
