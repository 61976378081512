let strings = {
  "en-US": {
    customerLogFile: "Enter the Customer Care log file code.",
    sendFiles: "Send Files"
  },
  "en-GB": {
    customerLogFile: "Enter the Customer Care log file code.",
    sendFiles: "Send Files"
  },
  "es-US": {
    customerLogFile: "Establecer un nuevo PIN",
    sendFiles: "Enviar Archivos"
  },
  "de-DE": {
    customerLogFile: "Geben Sie den Protokolldateicode vom Kundenservice ein.",
    sendFiles: "Dateien Senden"
  },
  "nl-NL": {
    customerLogFile: "Voer de logbestandscode van de Cliëntenzorg in.",
    sendFiles: "Stuur bestanden"
  },
  "fr-FR": {
    customerLogFile: "Saisissez le code du fichier journal Service clients.",
    sendFiles: "Envoyer les fichiers"
  },
};

export { strings };
