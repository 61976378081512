import { createStackNavigator } from '@react-navigation/stack';
import { SensorSelection } from 'web/components/pages';
import InitialSetupG6Stack from 'web/routes/G6/InitialSetupG6Stack';
import InitialSetupG7Stack from 'web/routes/G7/InitialSetupG7Stack';
import InitialSetupL2 from 'web/routes/L2/InitialSetupL2';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { strings } from './SwitchSensorStackContent';

const Stack = createStackNavigator();

const SwitchSensorStack = () => {

  const { languageContent } = useLanguageContent(strings);

  return (
    <Stack.Navigator initialRouteName="SwitchSensorStack" mode="card">
      <Stack.Screen
        name="SensorSelection"
        options={{
          title: `${languageContent.sensorSelect}`,
        }}
        component={SensorSelection}
      />
      <Stack.Screen
        name="InitialSetupG6Stack"
        options={{
          title: `${languageContent.sensorSelect}`,
          headerShown: false,
        }}
      >
        {(props) => <InitialSetupG6Stack isFTS />}
      </Stack.Screen>
      <Stack.Screen
        name="InitialSetupG7Stack"
        options={{
          title: `${languageContent.sensorSelect}`,
          headerShown: false,
        }}
      >
        {(props) => <InitialSetupG7Stack isFTS />}
      </Stack.Screen>
      <Stack.Screen
        name="InitialSetupL2"
        options={{
          headerShown: false,
        }}
        component={InitialSetupL2}
      />
    </Stack.Navigator>
  );
};

export default SwitchSensorStack;
