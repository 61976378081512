import React from 'react';
import { useIntl } from 'react-intl';
import { createStackNavigator } from '@react-navigation/stack';
import { BasalProgramsRoutesEnum } from './types';
import { messages } from './BasalPrograms.messages';
import { BasalProgramsMain } from './views/BasalProgramsMain';
import { CreateBasalProgramStack } from './views/CreateBasalProgramStack';
import { EditBasalProgramStack } from './views/EditBasalProgramStack/EditBasalProgramStack';
import { TopBar } from 'web/components/ios/TopBar';

const Stack = createStackNavigator();

export const BasalProgramsStack: React.FC = () => {
  const intl = useIntl();

  return (
    <Stack.Navigator
      initialRouteName={BasalProgramsRoutesEnum.BasalProgramsMain}
      screenOptions={{
        header: (props) => <TopBar {...props} expanded backMode />
      }}
    >
      <Stack.Screen
        name={BasalProgramsRoutesEnum.BasalProgramsMain}
        options={{
          title: intl.formatMessage(messages.title),
        }}
        component={BasalProgramsMain}
      />
      <Stack.Screen
        name={BasalProgramsRoutesEnum.CreateBasalProgramStack}
        options={{
          headerShown: false,
        }}
        component={CreateBasalProgramStack}
      />
      <Stack.Screen
        name={BasalProgramsRoutesEnum.EditBasalProgramStack}
        options={{
          headerShown: false,
        }}
        component={EditBasalProgramStack}
      />
    </Stack.Navigator>
  );
}