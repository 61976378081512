import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./CorrectAboveSettingsScreenContent";

const CorrectAboveSettingsScreen: React.FC = () => {
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);
  return (
    <View style={[styles.container, { backgroundColor: "white" }]}>
      <View style={{ paddingHorizontal: 16, paddingVertical: 32 }}>
        <Text style={[theme.fonts.body2, { marginBottom: 16 }]}>
          {languageContent.targetBGAndCorrectAbove}
        </Text>
        <Text style={[theme.fonts.body2, { marginBottom: 16 }]}>
          {languageContent.automatedMode1}
          <Text style={[{ fontWeight: "bold" }]}>
            {` ${languageContent.targetGlucose} `}
          </Text>
          {languageContent.value}
        </Text>
        <Text style={[theme.fonts.body2, { marginBottom: 16 }]}>
          {languageContent.inBoth1}
          <Text style={[{ fontWeight: "bold" }]}>
            {` ${languageContent.targetGlucose} `}
          </Text>
          {languageContent.and}
          <Text style={[{ fontWeight: "bold" }]}>
            {` ${languageContent.correctAbove} `}
          </Text>
          {languageContent.valueText}
        </Text>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.next}
        rightAction={() => navigate("BolusSettingsCorrectAboveSegments")}
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between"
  }
});

export default CorrectAboveSettingsScreen;
