import React from "react";
import { Button as PaperButton, Card, Modal, Portal } from "react-native-paper";
import { Dimensions, Text, View } from "react-native-web";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import { strings } from "./ConfirmationModalLandscapeContent";

const { height, width } = Dimensions.get("window");

interface Props {
  title: string;
  message?: JSX.Element;
  onDismiss?: () => void;
  hideDismiss?: boolean;
  onConfirm: () => void;
  dismissText?: string;
  confirmText?: string;
  isVisible: boolean;
  purple?: boolean;
}

const ConfirmationModalLandscape: React.FC<Props> = ({
  title,
  message,
  onDismiss,
  hideDismiss,
  onConfirm,
  dismissText,
  confirmText,
  isVisible,
  purple,
}) => {
  const { languageContent } = useLanguageContent(strings);
  const getWidth = () => {
    if (height < 400) {
      return "55%";
    }
    return "50%";
  };
  return (
    <Portal>
      <Modal visible={isVisible} onDismiss={onDismiss}>
        <Card
          style={{
            alignSelf: "center",
            width: getWidth(),
            margin: width < 400 ? 16 : 32,
            padding: 8,
            display: "flex",
            backgroundColor: purple ? theme.colors.modalPurple : "#FAFAFA",
            borderColor: purple ? theme.colors.white : "transparent",
            borderWidth: purple ? 1.5 : 0,
            borderRadius: purple ? 14 : 2,
          }}
          elevation={5}
          accessible>
          <Card.Content>
            <Text
              style={[
                theme.fonts.h3,
                {
                  marginBottom: 16,
                  color: purple
                    ? theme.colors.white
                    : theme.colors.grayScale.gray100,
                },
              ]}>
              {title}
            </Text>
            <View>{message}</View>
          </Card.Content>
          <Card.Actions
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: 16,
            }}>
            {hideDismiss ? null : (
              <PaperButton accessible onPress={onDismiss}>
                <Text
                  style={[
                    theme.fonts.body2,
                    {
                      color: purple ? theme.colors.white : theme.colors.blue,
                      fontWeight: "bold",
                    },
                  ]}>
                  {dismissText ? dismissText : languageContent.defaultNo}
                </Text>
              </PaperButton>
            )}
            <PaperButton accessible onPress={onConfirm}>
              <Text
                style={[
                  theme.fonts.body2,
                  {
                    color: purple ? theme.colors.white : theme.colors.blue,
                    fontWeight: "bold",
                  },
                ]}>
                {confirmText ? confirmText : languageContent.defaultYes}
              </Text>
            </PaperButton>
          </Card.Actions>
        </Card>
      </Modal>
    </Portal>
  );
};

export default ConfirmationModalLandscape;
