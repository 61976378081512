import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, TextInput, View } from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import {
  basalActions,
  BasalState
} from "../../../../../../../../web/store/reducers/basal";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./CustomProgramEntryScreenContent";

const CustomProgramEntryScreen: React.FC = () => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );

  const { languageContent } = useLanguageContent(strings);
  const programName = languageContent.relax;

  const navigation = useNavigation();
  const dispatch = useDispatch();

  return (
    <>
      <View
        style={[
          {
            flex: 1,
            height: "100%",
            backgroundColor: "#fff",
            paddingTop: 24,
            paddingBottom: 32
          }
        ]}>
        <View style={{ marginHorizontal: 16 }}>
          <Text style={[styles.durationHeader, { marginBottom: 16 }]}>
            {languageContent.programName}
          </Text>
          <TextInput
            editable={false}
            style={[styles.inputStyle]}
            placeholderTextColor={theme.colors.placeholder}
            placeholder={`${languageContent.basalOne} 1`}
            value={programName}
            keyboardType="default"
            selectionColor={theme.colors.blue}
            returnKeyType="done"
          />
        </View>
      </View>
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.cancel}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.next}
        rightActionStyle={{
          color: !programName ? theme.colors.placeholder : theme.colors.purple
        }}
        rightAction={() => {
          dispatch(
            basalActions.basalUpdate(
              {
                customProgramName: programName
              },
              basalState
            )
          );
          if (programName) {
            navigation.navigate("CustomProgramSegments");
          }
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  mainText: {
    ...theme.fonts.text200,
    fontWeight: "400",
    marginBottom: 24
  },
  durationHeader: {
    ...theme.fonts.h3
  },
  inputStyle: {
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 8,
    paddingVertical: 12,
    borderColor: theme.colors.textInputBorder,
    backgroundColor: theme.colors.textInputBackgroundColor,
    color: "#000",
    fontSize: 24
  }
});

export default CustomProgramEntryScreen;
