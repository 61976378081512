let strings = {
  "en-US": {
    enter: "Enter a BG Value",
    leftActionText: "Cancel",
    rightActionText: "Save"
  },
  "en-GB": {
    enter: "Enter a BG Value",
    leftActionText: "Cancel",
    rightActionText: "Save"
  },
  "es-US": {
    enter: "Introduzca un valor de GS",
    leftActionText: "Cancelar",
    rightActionText: "Guardar"
  },
  "de-DE": {
    enter: "BZ-Wert eingeben",
    leftActionText: "Abbr.",
    rightActionText: "Speichern"
  },
  "nl-NL": {
    enter: "Voer een BG-waarde in",
    leftActionText: "Annuleren",
    rightActionText: "Opslaan"
  },
  "fr-FR": {
    enter: "Saisir une valeur de glycémie",
    leftActionText: "Annuler",
    rightActionText: "Enregistrer"
  },
};

export { strings };
