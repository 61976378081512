let strings = {
  "en-US": {
    searching: "SEARCHING FOR SENSOR...",
    dexcom: "Dexcom G6",
    lastReading: "Last Reading %1",
    u: "U",
    unit: "0.15 U"
  },
  "en-GB": {
    searching: "SEARCHING FOR SENSOR...",
    dexcom: "Dexcom G6",
    lastReading: "Last Reading %1",
    u: "U",
    unit: "0.15 U"
  },
  "de-DE": {
    searching: "Nach Sensor suchen",
    dexcom: "Dexcom G6",
    lastReading: "Letzter Messwert %1",
    u: "E",
    unit: "0,15 U"
  },
  "es-US": {
    searching: "BUSCANDO SENSOR...",
    dexcom: "Dexcom G6",
    lastReading: "Última lectura %1",
    u: "U",
    unit: "0.15 U"
  },
  "fr-FR": {
    searching: "RECHERCHE DE CAPTEUR...",
    dexcom: "Dexcom G6",
    lastReading: "Dernière mesure %1",
    u: "U"
  },
  "nl-NL": {
    searching: "ZOEKEN NAAR SENSOR...",
    dexcom: "Dexcom G6",
    lastReading: "Laatste meetwaarde %1",
    u: "E",
    unit: "0,15 E"
  }
};

export { strings };
