let strings = {
  "en-US": {
    switchedToManual: "Switched to Manual Mode",
    switchedToAutomated: "Switched to Automated Mode"
  },
  "en-GB": {
    switchedToManual: "Switched to Manual Mode",
    switchedToAutomated: "Switched to Automated Mode"
  },
  "de-DE": {
    switchedToManual: "Umgeschaltet auf \nManuellen Modus",
    switchedToAutomated: "Umgeschaltet auf \nAutomatisierten Modus"
  },
  "es-US": {
    switchedToManual: "Cambiado a modo manual",
    switchedToAutomated: "Cambiado a modo automático"
  },
  "fr-FR": {
    switchedToManual: "Passé en Mode Manuel",
    switchedToAutomated: "Passé en Mode Automatisé"
  },
  "nl-NL": {
    switchedToManual: "Gewijzigd naar Handmatige Modus",
    switchedToAutomated: "Gewijzigd naar Geautomatiseerde Modus"
  }
};

export { strings };
