import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { View, Text } from 'react-native-web';
import { StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { BottomInnerNav } from 'mobile/latest/components/molecules';
import { SettingsSection } from 'mobile/latest/components/organisms';
import theme from 'mobile/latest/theme';
import { L2Paths } from 'web/routes/L2/InitialSetupL2';
import { ManageSensorL2Paths } from 'web/routes/L2/ManageSensorL2Stack';
import {
  setValueForType,
  settingName,
} from 'web/store/reducers/l2Settings/l2SettingsSlice';
import { selectLowGlucoseSettings } from 'web/store/reducers/l2Settings/l2Settings.helpers';
import { messages } from './LowGlucose.messages';
import { messages as L2Messages } from '../L2.messages';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  card: {
    width: '100%',
  },
  settingValue: {
    color: theme.colors.lightBlue,
  },
  disabledValue: {
    color: theme.colors.text.cancel,
  },
  textContainer: {
    padding: 16,
  },
  inputContainer: {
    alignItems: 'flex-end',
  },
  inputValues: {
    width: '100%',
    paddingLeft: 0,
  },
  adornment: {
    color: theme.colors.adornment,
  },
});

interface Props {
  isFTS?: boolean;
}

const DEFAULT_VALUE = '3.3';

export const LowGlucose: React.FC<Props> = ({ isFTS = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const lowGlucoseSettings = useSelector(selectLowGlucoseSettings);

  const [isGlucoseToggled, setIsGlucoseToggled] = useState<boolean>(lowGlucoseSettings.alertOn);
  const [isVolumeToggled, setIsVolumeToggled] = useState<boolean>(lowGlucoseSettings.overrideVolume);
  const [glucoseValue, setGlucoseValue] = useState<string>(lowGlucoseSettings.value);

  const nextClickHandler = () => {
    dispatch(
      setValueForType({
        type: settingName.lowGlucose,
        alertOn: isGlucoseToggled,
        value: DEFAULT_VALUE,
        overrideVolume: isVolumeToggled,
      })
    );

    return isFTS ? navigate(L2Paths.highGlucoseSettings) : navigate(ManageSensorL2Paths.sensorSettings);
  };

  const navRightText = isFTS
    ? intl.formatMessage(L2Messages.bottomNavRightAction)
    : intl.formatMessage(L2Messages.bottomNavRightActionSave);

  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <SettingsSection
          settingTitle={intl.formatMessage(messages.lowGlucoseTitle)}
          hasCustomToggleSwitch
          hideBreak
          startToggleTrue={isGlucoseToggled}
          onToggleChange={(value) => setIsGlucoseToggled(value)}
          settingValueSubTextElement={
            isGlucoseToggled ? (
              <Text style={[theme.fonts.body1, styles.settingValue]}>
                <FormattedMessage {...L2Messages.toggleOn} />
              </Text>
            ) : (
              <Text style={[theme.fonts.body1, styles.disabledValue]}>
                <FormattedMessage {...L2Messages.toggleOff} />
              </Text>
            )
          }
        />

        {isGlucoseToggled && (
          <View>
            <View style={styles.textContainer}>
              <View style={{ marginBottom: 8 }}>
                <Text style={[theme.fonts.h5]}>
                  <FormattedMessage {...messages.lowGlucoseNotify} />
                </Text>
                <Text
                  style={[
                    theme.fonts.body3,
                    { color: theme.colors.text.grayText },
                  ]}
                >
                  <FormattedMessage {...messages.glucoseRange} />
                </Text>
              </View>
              <View>
                <TouchableOpacity
                  style={[
                    theme.layout.inputContainerStyle,
                    styles.inputContainer,
                  ]}
                  activeOpacity={1}
                  onPress={() => setGlucoseValue(DEFAULT_VALUE)}
                >
                  <TextInput
                    style={[theme.layout.inputStyle, styles.inputValues]}
                    selectionColor="transparent"
                    placeholder="--"
                    placeholderTextColor={theme.colors.placeholder}
                    value={glucoseValue}
                    showSoftInputOnFocus={false}
                  />
                  <Text style={styles.adornment}>mmol/L</Text>
                </TouchableOpacity>
              </View>
            </View>

            <SettingsSection
              settingTitle={intl.formatMessage(L2Messages.overrideVolume)}
              hasCustomToggleSwitch
              hideBreak
              startToggleTrue={isVolumeToggled}
              onToggleChange={(value) => setIsVolumeToggled(value)}
              settingValueSubTextElement={
                isVolumeToggled ? (
                  <Text style={[theme.fonts.body1, styles.settingValue]}>
                    <FormattedMessage {...L2Messages.toggleOn} />
                  </Text>
                ) : (
                  <Text style={[theme.fonts.body1, styles.disabledValue]}>
                    <FormattedMessage {...L2Messages.toggleOff} />
                  </Text>
                )
              }
            />

            <View style={styles.textContainer}>
              <Text>
                <FormattedMessage {...L2Messages.overrideVolumeDisclaimer} />
              </Text>
            </View>
          </View>
        )}
      </View>
      <BottomInnerNav
        rightActionText={navRightText}
        rightAction={nextClickHandler}
      />
    </View>
  );
};
