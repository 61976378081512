import React from 'react';
import { useDispatch } from 'react-redux';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { IntroScreenLayout } from 'mobile/latest/components/organisms';
import { strings } from './OutroCGMScreenContent';
import { sensorActions } from 'web/store/reducers/sensor';
import { sensors, SensorType } from 'web/data/sensors';
import { useNavigation } from '@react-navigation/native';

interface Props {
  sensor?: SensorType;
}

const OutroCGMScreen: React.FC<Props> = ({ sensor = sensors.G6 }) => {
  const image = require('assets/cgm_success.jpg');
  const { languageContent } = useLanguageContent(strings);
  const dispatch = useDispatch();
  const { navigate } = useNavigation();

  const navigateToHandler = () => {
    dispatch(sensorActions.sensorUpdate({ activeSensor: sensor }));
    navigate('Drawer');
  }
  
  return (
    <IntroScreenLayout
      body={languageContent.podActivated}
      image={image}
      navigateToHandler={navigateToHandler}
    />
  );
};

export default OutroCGMScreen;
