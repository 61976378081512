let strings = {
  "en-US": {
    set: "Set Maximum Basal Rate",
    upperLimit: "Upper limit for basal rates in a Basal Program or temp basal.",
    adjustLater: "(You can adjust this rate later if your needs change.)",
    maxBasalRate: "Max Basal Rate",
    chooseRate: "Choose Max Basal Rate",
    range: "(%1 to 30 U/hr)",
    cancel: "cancel",
    next: "next",
    unitHour: "U/hr"
  },
  "en-GB": {
    set: "Set Maximum Basal Rate",
    upperLimit:
      "Upper limit for basal rates in a Basal Programme or temp basal.",
    adjustLater: "(You can adjust this rate later if your needs change.)",
    maxBasalRate: "Max Basal Rate",
    chooseRate: "Choose Max Basal Rate",
    range: "(%1 to 30 U/hr)",
    cancel: "cancel",
    next: "next",
    unitHour: "U/hr"
  },
  "de-DE": {
    set: "Maximale Basalrate einstellen",
    upperLimit:
      "Obere Grenze für die Basalraten in einem Basalratenprofil oder einer temporären Basalrate.",
    adjustLater:
      "(Sie können die Rate später anpassen, falls sich die Anforderungen ändern.)",
    maxBasalRate: "Maximale Basalrate",
    chooseRate: "Maximale Basalrate auswählen",
    range: "(%1 bis 30 E/Std.)",
    cancel: "Abbr.",
    next: "Weiter",
    unitHour: "E/Std."
  },
  "es-US": {
    set: "Establecer la tasa basal máxima",
    upperLimit:
      "Límite superior para las tasas basales en un programa basal o basal temporal.",
    adjustLater:
      "(Puede ajustar esta tasa si necesita cambiarla en otro momento).",
    maxBasalRate: "Tasa basal máxima",
    range: "(%1 a 30 U/h)",
    cancel: "cancelar",
    next: "siguiente",
    unitHour: "U/h",
    chooseRate: "Elegir tasa basal máxima"
  },
  "fr-FR": {
    set: "Configuration du débit basal maximum",
    upperLimit:
      "Limite supérieure du débit basal dans un programme basal ou un débit basal temporaire.",
    adjustLater:
      "(Ce débit est ajustable ultérieurement si vos besoins évoluent.)",
    maxBasalRate: "Débit basal max",
    range: "(de %1 à 30 U/h)",
    cancel: "annuler",
    next: "suivant",
    unitHour: "U/h"
  },
  "nl-NL": {
    set: "Maximale basaalsnelheid instellen",
    upperLimit:
      "Bovengrens voor basaalsnelheden in een basaalprogramma of tijdelijke basaalsnelheid.",
    adjustLater: "(Zo nodig kunt u deze snelheid later aanpassen.)",
    maxBasalRate: "Maximale basaalsnelheid",
    range: "(%1 tot 30 E/u)",
    cancel: "annuleren",
    next: "volgende",
    unitHour: "E/u."
  }
};

export { strings };
