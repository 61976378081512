import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { setOptions } from 'web/store/reducers/configurator/configuratorSlice';
import { languageActions } from 'web/store/reducers/language';
import { navigationActions } from 'web/store/reducers/navigation';
import { configurationOptions } from 'web/data/Configurator/options';
import { Device, Language, Version } from 'web/data/Configurator/types';
import {
  getDevices,
  getVersions,
  getLanguages,
  getLanguageNameByLocale,
  getCurrentLanguageData,
} from 'web/data/Configurator/helpers';
import { Locale } from 'web/data/Configurator/types';
import { RootState } from 'web/store/store';
import Button from 'web/components/atoms/Button';
import SelectBox from 'web/components/molecules/SelectBox';
import { getRouteMap } from 'web/data/fastTravelNavData';
import './Configurator.css';
import { sensorActions } from 'web/store/reducers/sensor';
import { sensors } from 'web/data/sensors';
import { CountryType } from 'mobile/latest/types/country';

const Configurator: React.FC = () => {
  const dispatch = useDispatch();
  const configState = useSelector(
    (state: RootState) => state.configurator,
    isEqual
  );

  const [country, setSelectedCountry] = useState(configState.country);
  const [device, setSelectedDevice] = useState(configState.device);
  const [language, setSelectedLanguage] = useState(configState.language);
  const [version, setSelectedVersion] = useState(configState.version);

  const [deviceOptions, setDeviceOptions] = useState<Device[]>([]);
  const [languageOptions, setLanguageOptions] = useState<Language[]>([]);
  const [versionOptions, setVersionOptions] = useState<Version[]>([]);

  const resetSensorStates = () => {
    dispatch(
      sensorActions.sensorUpdate({
        activeSensor: sensors.G6,
        selectedSensorFlow: sensors.G6,
      })
    )};
  
  const updateLanguageData = () => {
    const languageData = getCurrentLanguageData({ country, device, language });
    if (languageData) {
      dispatch(
        languageActions.languageUpdate({
          country: country as CountryType,
          permanentCountry: country as CountryType,
          language: languageData.locale,
          locale: languageData.locale,
          units: languageData.units,
          is24Hour: languageData.is24Hours,
        })
      );
    }
  };

  const updateNavigationData = () => {
    const { label, name } = getRouteMap(device === 'iOS').dashboard;

    dispatch(
      navigationActions.navigationUpdate({
        navigateTo: name,
        currentRoute: name,
        navigateToLabel: label,
      })
    );
  };

  useEffect(() => {
    const { country, device, language, version } = configState;

    if (country && device && language && version) {
      return;
    }

    // Load Initial options based on configurator default country.
    const devices = getDevices(country);
    const defaultDevice = devices[0];
    const languages = getLanguages(country, defaultDevice.name);
    const defaultLanguage = languages[0];
    const versions = getVersions(
      country,
      defaultDevice.name,
      defaultLanguage.name
    );
    const defaultVersion = versions[0];

    // Updates Configurator state.
    dispatch(
      setOptions({
        country,
        device: defaultDevice.name,
        language: defaultLanguage.locale,
        version: defaultVersion,
      })
    );

    // Updates language state.
    if (defaultLanguage) {
      dispatch(
        languageActions.languageUpdate({
          language: defaultLanguage.locale,
          locale: defaultLanguage.locale,
          units: defaultLanguage.units,
          is24Hour: defaultLanguage.is24Hours,
        })
      );
    }

    setSelectedCountry(country);
    setSelectedDevice(defaultDevice.name);
    setSelectedLanguage(defaultLanguage.locale)
    setSelectedVersion(defaultVersion);

    setDeviceOptions(devices);
    setLanguageOptions(languages);
    setVersionOptions(versions);
  }, []);

  // Render methods for the SelectBoxes
  const renderCountryOptions = () =>
    configurationOptions.map((country) => ({
      value: country.name,
      text: country.name,
    }));
  const renderDeviceOptions = () =>
    deviceOptions.map((device) => ({ value: device.name, text: device.name }));
  const renderLanguageOptions = () =>
    languageOptions.map((language) => ({
      value: language.locale,
      text: language.name,
    }));
  const renderVersionOptions = () =>
    versionOptions.map((version) => ({ value: version, text: version }));


  // SelectBox onSelect handlers.
  const handleCountrySelect = (value: string) => {
    const devices = getDevices(value);
    const device = devices[0];
    const languages = getLanguages(value, device.name);
    const language = languages[0];
    const versions = getVersions(value, device.name, language.name);
    const version = versions[0];

    setSelectedCountry(value);
    setSelectedDevice(device.name);
    setSelectedLanguage(language.locale);
    setSelectedVersion(version);
    setDeviceOptions(devices);
    setLanguageOptions(languages);
    setVersionOptions(versions);
  };

  const handleDeviceSelect = (value: string) => {
    const languages = getLanguages(country, value);
    const language = languages[0];
    const versions = getVersions(country, value, language.name);
    const version = versions[0];

    setSelectedDevice(value);
    setSelectedLanguage(language.locale);
    setSelectedVersion(version);
    setLanguageOptions(languages);
    setVersionOptions(versions);
  };

  const handleLanguageSelect = (value: Locale) => {
    const languageName = getLanguageNameByLocale(value);
    const versions = getVersions(country, device, languageName);
    const version = versions[0];

    setSelectedLanguage(value);
    setSelectedVersion(version);
    setVersionOptions(versions);
  };

  const handleLoadSimulator = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(
      setOptions({
        country,
        device,
        language,
        version,
      })
    );
    updateLanguageData();
    updateNavigationData();
    resetSensorStates();
  };

  return (
    <form className="configurator" noValidate>
      <SelectBox
        label="Country:"
        name="country"
        value={country}
        onChange={handleCountrySelect}
        options={renderCountryOptions()}
      />
      <SelectBox
        label="Device:"
        name="device"
        value={device}
        onChange={handleDeviceSelect}
        options={renderDeviceOptions()}
      />
      <SelectBox
        label="Language:"
        name="language"
        value={language}
        onChange={handleLanguageSelect}
        options={renderLanguageOptions()}
      />
      <SelectBox
        label="Version:"
        name="version"
        value={version}
        onChange={(value: Version) => setSelectedVersion(value)}
        options={renderVersionOptions()}
      />
      <Button type="submit" onClick={handleLoadSimulator}>
        Load Simulator
      </Button>
    </form>
  );
};

export default Configurator;