import React from "react";
import { StyleSheet, View } from "react-native";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { Header, Paragraph } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import CompoundToggle from "../../../../../organisms/compoundToggle";
import { strings } from "./TempBasalOnScreenContent";
import { useNavigation } from "@react-navigation/native";

const TempBasalOnScreen: React.FC = () => {
  const { languageContent } = useLanguageContent(strings);
  const { navigate } = useNavigation();

  const [fieldValue, setFieldValue] = React.useState<boolean>(false);

  const handleNav = () => {
    navigate("BolusFTS");
  };

  return (
    <View style={styles.container}>
      <Header label={languageContent.allowPrompt} styles={{ marginTop: 16 }} />
      <View style={{ flex: 1, marginHorizontal: 16 }}>
        <Paragraph
          styles={{ marginBottom: 16 }}
          variant="body1"
          label={languageContent.tempDescription}
        />
        <CompoundToggle
          label={languageContent.tempBasal}
          connectedStateBooleanValue={fieldValue}
          onValueSubtext={languageContent.changeBasalRates}
          onToggle={() => setFieldValue(!fieldValue)}
        />
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.next}
        rightAction={handleNav}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "#fff"
  },
  inputContainer: {
    marginTop: 16,
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

export default TempBasalOnScreen;
