import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers";

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  devTools: true,
  reducer: rootReducer,
  middleware: [thunkMiddleware]
});

export default store;
