import { BottomNav } from 'web/components/ios/BottomMenu/BottomNav';
import { HistoryEventRow } from '../components/HistoryEventRow/HistoryEventRow';
import { historyEvents } from '../components/HistoryEventRow/types';
import { FormattedMessage } from 'react-intl';
import { historyAutoEventsMessages } from '../History.messages';

export const HistoryAutoEvents = () => {
  return (
    <>
      <div className="flex overflow-scroll overscroll-contain flex-col font-ios pt-2 h-full bg-white">
        <p className="text-small pt-2 pb-1 pl-2 font-semibold text-ios-secondaryGray opacity-60">
          <FormattedMessage {...historyAutoEventsMessages.decemberDate} />
        </p>
        <HistoryEventRow time="11:35 AM" eventType={historyEvents.SwitchMode} />
        <HistoryEventRow
          time="11:33 AM"
          mgValue="124 mg/dL"
          totalInsulin="0.15"
          eventType={historyEvents.AutoEvent}
        />
        <HistoryEventRow
          time="11:28 AM"
          mgValue="127 mg/dL"
          totalInsulin="0.15"
          eventType={historyEvents.AutoEvent}
        />

        <HistoryEventRow
          time="11:23 AM"
          mgValue="138 mg/dL"
          totalInsulin="0.05"
          eventType={historyEvents.AutoEvent}
        />
        <HistoryEventRow
          time="11:18 AM"
          mgValue="--"
          totalInsulin="0"
          eventType={historyEvents.AutoEvent}
        />
        <HistoryEventRow
          time="11:13 AM"
          mgValue="139 mg/dL"
          totalInsulin="0"
          eventType={historyEvents.AutoEvent}
        />
        <HistoryEventRow
          time="11:08 AM"
          mgValue="151 mg/dL"
          totalInsulin="0"
          eventType={historyEvents.AutoEvent}
        />
        <HistoryEventRow
          time="11:03 AM"
          mgValue="169 mg/dL"
          totalInsulin="0"
          eventType={historyEvents.AutoEvent}
        />
        <HistoryEventRow
          time="10:59 AM"
          operationMode="automated"
          eventType={historyEvents.SwitchMode}
        />
        <HistoryEventRow
          time="10:54 AM"
          mgValue="124 mg/dL"
          totalInsulin="0.15"
          eventType={historyEvents.AutoEvent}
        />
      </div>
      <BottomNav />
    </>
  );
};
