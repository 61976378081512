interface HistorySummaryBlocksProps {
  icon?: any;
  iconRotate?: string;
  title: string;
  value: string;
  valueUnit?: string;
  placeholder?: string;
  titleColorGray?: boolean;
}

export const HistorySummaryBlocks: React.FC<HistorySummaryBlocksProps> = ({
  icon,
  iconRotate = '0',
  title,
  value,
  valueUnit,
  placeholder,
  titleColorGray = false,
}) => {
  return (
    <div className="w-[167px] h-[95px] px-2 py-1 rounded-xl bg-ios-historySummaryBlocks">
      <div className="flex flex-row mb-2">
        <div className={`mt-0.5 ${icon && 'mr-0.5'} ${iconRotate}`}>{icon}</div>

        <p
          className={`font-semibold text-sm ${titleColorGray && 'text-ios-gray-9 opacity-[.60]'}`}
        >
          {title}
        </p>
      </div>
      <p className="mb-0.5 font-bold text-regular">
        {value}
        <span className="ml-0.5 text-sm font-semibold text-ios-gray-9 opacity-[.60]">
          {valueUnit}
        </span>
      </p>
      <p className="text-xs text-ios-gray-9 opacity-[.60]">{placeholder}</p>
    </div>
  );
};
