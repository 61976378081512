import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { basalActions, BasalState } from "../../../../../../../web/store/reducers/basal";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import fonts from "../../../../../theme/fonts";
import { Button } from "../../../../atoms";
import { BottomInnerNav } from "../../../../molecules";
import { strings } from "./StartInsulinScreenContent";

const StartInsulinScreen: React.FC = () => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );

  const dispatch = useDispatch();
  const { goBack } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const handlePress = () => {
    dispatch(basalActions.basalUpdate({ insulinPaused: false }, basalState));
    setTimeout(() => {
      goBack();
    }, 150);
  };

  return (
    <View style={{ backgroundColor: "white", flex: 1 }}>
      <View style={styles.warningMessageBarView}>
        <Text style={styles.WarningMessageBarText}>
          {languageContent.insulinPaused}
        </Text>
      </View>
      <View
        style={{
          ...theme.layout.wrapper,
          paddingHorizontal: 16
        }}>
        <Text style={{ ...fonts.h5, marginVertical: 24 }}>
          {languageContent.startInsulin}
        </Text>
        <Text style={{ ...fonts.body2 }}>{languageContent.basalRestart}</Text>
      </View>
      <View
        style={{
          marginTop: 270,
          marginBottom: 48,
          marginHorizontal: 16
        }}>
        <Button
          textStyle={{ fontSize: 14 }}
          variant="primary"
          style={{ elevation: 6, marginBottom: 16 }}
          onPress={handlePress}>
          {languageContent.startInsulinButton}
        </Button>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        leftActionStyle={{ color: theme.colors.text.cancel, fontSize: 16 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  warningMessageBarView: {
    flexDirection: "row",
    backgroundColor: theme.colors.yellow,
    alignItems: "flex-end",
    justifyContent: "center",
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 5
  },
  WarningMessageBarText: {
    color: theme.colors.text.cancel,
    fontSize: 14,
    fontWeight: "500",
    lineHeight: 16
  }
});

export default StartInsulinScreen;
