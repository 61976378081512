import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  Animated,
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedNumberFormat } from "mobile/latest/helpers/getLocalizedNumberFormat";
import { getUnitByLocale } from "mobile/latest/helpers/getUnitByLocale";
import replaceWithValues from "mobile/latest/helpers/replaceWithValues";
import useLanguageContent from "mobile/latest/hooks/useLanguageContent";
import { bolusActions, BolusState } from "web/store/reducers/bolus";
import { LangState } from "web/store/reducers/language";
import {
  navigationActions,
  NavState
} from "web/store/reducers/navigation";
import { UserState } from "web/store/reducers/user";
import { RootState } from "web/store/store";
import theme from "mobile/latest/theme";
import BolusParams from "mobile/latest/types/bolusParams";
import { BottomInnerNav } from "mobile/latest/components/molecules";
import { strings } from "./BolusScreenContent";

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity);

// Constants & Keys
const MAX_BOLUS = 15.5;
const CARBS_KEY = "carbs";
const BG_KEY = "bg";
const TOTAL_KEY = "total";
const DEFAULT_BG = 120;
const DEFAULT_CORRECTION = 50;

const BolusScreen: React.FC = () => {
  /**
   * GLOBAL STATE
   */
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );
  /**
   * STATE
   */
  const [mealBolus, setMealBolus] = React.useState<string>("");
  const [correction, setCorrection] = React.useState<string>("");
  const [total, setTotal] = React.useState<string>("");
  const [bg, setBg] = React.useState<string>("");
  const [carbs, setCarbs] = React.useState<string>("");
  const [unit, setUnit] = React.useState<string>("--");
  const [glucose, setGlucose] = React.useState<string>("--");
  const [glucosemmol, setGlucoseMmol] = React.useState<string>("--");
  const [bolusTotal, setBolusTotal] = React.useState<string>("--");

  const [highlighted, setHighlighted] = React.useState<string | null>(null);
  const [usingCGM, setUsingCGM] = React.useState<boolean>(false);

  /**
   * REFS
   */
  const refs = {
    carbsInput: React.useRef<TextInput>(null),
    carbsField: React.useRef<TouchableOpacity>(null),
    bgInput: React.useRef<TextInput>(null),
    totalInput: React.useRef<TextInput>(null)
  };

  const mealBolusU = "3.00";

  const correctionBolusU = "3.00";
  const adjustedBolusU =
    total === "" ? "" : Number(total).toLocaleString(langState.locale);
  const seventyNumber = getUnitByLocale(70, langState.units, langState.locale);
  const maxBolusU = MAX_BOLUS.toLocaleString(langState.locale);

  const contentVariables = [
    mealBolusU,
    correctionBolusU,
    adjustedBolusU,
    seventyNumber,
    maxBolusU
  ];

  /**
   * HOOKS
   */
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  /**
   * FUNCTIONS
   */
  const determineHighlighted = (key: string) => {
    if (highlighted === key) {
      return { borderColor: theme.colors.blue };
    }
  };

  const handleInputFocus = (key: string) => {
    switch (key) {
      case CARBS_KEY:
        setHighlighted(CARBS_KEY);
        setCarbs("45");
        break;
      case BG_KEY:
        setHighlighted(BG_KEY);
        setBg("103");
        setUsingCGM(false);
        break;
      case TOTAL_KEY:
        setHighlighted(TOTAL_KEY);
        setTotal("2.70");
      default:
        break;
    }
  };

  const handleInputBlur = (key: string) => {
    switch (key) {
      case CARBS_KEY:
        if (!carbs) {
          setCarbs("45");
        }
        setHighlighted(null);
        break;
      case TOTAL_KEY:
        if (!total) {
          setTotal("2.70");
        }
        setHighlighted(null);
      default:
        break;
    }
  };

  const handlePressTwo = () => {
    setUnit("45");
  };

  const handlePressThree = () => {
    setGlucose("103");
    setGlucoseMmol("5.6");
  };

  const handlePressFour = () => {
    setBolusTotal("2.70");
  };

  const determineTotalBolusColor = () => {
    const totalNum = Number(total);
    if (totalNum >= MAX_BOLUS) {
      return { color: theme.colors.red };
    }
    return { color: "black" };
  };

  const checkShouldProceed = () => {
    const totalNum = Number(total);
    if (totalNum === MAX_BOLUS || totalNum <= 0) {
      return false;
    }
    return true;
  };

  const getConfirmTextColor = () => {
    const proceed = checkShouldProceed();
    if (!proceed) {
      return { color: theme.colors.placeholder, fontSize: 14 };
    } else {
      return { color: theme.colors.purple, fontSize: 14 };
    }
  };

  const navigateToConfirmBolus = () => {
    const proceed = checkShouldProceed();

    const params: BolusParams = {
      carbs,
      bg,
      total,
      usingCGM,
      mealBolus: Number(mealBolus) > 0 ? mealBolus : total,
      correction
    };
    if (proceed) {
      dispatch(bolusActions.bolusUpdate({ bolusTutorial: false }, bolusState));
      return navigate("ConfirmBolus", params);
    }
  };

  const navigateToExtendBolus = () => {
    const proceed = checkShouldProceed();
    const params: BolusParams = {
      carbs,
      bg,
      total,
      usingCGM,
      mealBolus: Number(mealBolus) > 0 ? mealBolus : total,
      correction
    };
    if (proceed) {
      return navigate("ExtendBolus", params);
    }
  };

  const showExtendBolus = () => {
    if (!userState.automatedMode) {
      return languageContent.extend;
    }
    return undefined;
  };

  /**
   * Calculates the amount of insulin to be used for a Correction Bolus and Total Bolus
   * Incrememnts by 0.05 U, always rounding down.
   * @param currentValue the current raw calculated number before rounding
   */
  const getRoundedValue = (currentValue: number) => {
    const array = currentValue.toFixed(2).split(".");
    const diff = Number(array[1]) % 5;
    const roundedVal = Number(array[1]) - diff;

    let roundedCorrection;

    if (roundedVal < 10) {
      roundedCorrection = `${array[0]}.0${roundedVal.toString()}`;
    } else {
      roundedCorrection = `${array[0]}.${roundedVal.toString()}`;
    }
    return roundedCorrection;
  };

  const doBolusCalculations = React.useCallback(() => {
    const calcluateBolus = () => {
      let carbsPlaceholder = getLocalizedNumberFormat(carbs);
      let carbsNum = Number(carbsPlaceholder);
      let bgPlaceholder = getLocalizedNumberFormat(bg);
      let currentBG = Number(bgPlaceholder);

      if (carbsNum < 0 || isNaN(carbsNum)) {
        carbsNum = 0;
        setCarbs("45");
      }

      if (currentBG < 70 || !bg || bg === "") {
        currentBG = DEFAULT_BG;
      }

      const diff = currentBG - DEFAULT_BG;
      let correctionCalc = diff / DEFAULT_CORRECTION;

      //if it has not reached the threshold of -0.05, set the value to 0.00
      if (correctionCalc < 0 && correctionCalc > -0.049) {
        correctionCalc = 0.0;
      }

      const roundedCorrection = getRoundedValue(correctionCalc);
      setCorrection(roundedCorrection);

      let newTotal;
      let mealBolusCalc = carbsNum / 15;

      if (correctionCalc > 0.1) {
        //correction amount is positive
        newTotal = mealBolusCalc + correctionCalc;
      } else {
        //correction amount is negative
        newTotal = Number(roundedCorrection) + mealBolusCalc;
      }

      const roundedTotal = getRoundedValue(newTotal);
      const roundedMealBolus = getRoundedValue(mealBolusCalc);

      setTotal(roundedTotal);
      if (roundedTotal === "0.00" || roundedTotal.startsWith("-")) {
        setTotal("2.70");
      }

      setMealBolus(roundedMealBolus);
    };

    if (Number(carbs) < 0) {
      return;
    }

    calcluateBolus();
  }, [bg, carbs]);

  /**
   * LIFECYCLE
   */

  React.useEffect(() => {
    doBolusCalculations();
  }, [carbs, bg, doBolusCalculations]);

  React.useEffect(() => {
    setMealBolus("0");
    setCorrection("0");
    setTotal("2.70");
    setBg("103");
    setCarbs("45");
  }, []);

  return (
    <>
      <View
        pointerEvents="box-none"
        style={{ flex: 1, backgroundColor: "#FFFFFF" }}>
        <View pointerEvents="box-none" style={[styles.innerWrapper]}>
          <View pointerEvents="box-none" style={[styles.section]}>
            <View pointerEvents="box-none" style={[styles.inputLabelWrapper]}>
              <View
                pointerEvents="box-none"
                style={{
                  flexDirection: "row",
                  alignItems: "center"
                }}>
                <Image
                  style={{ height: 20, width: 20, marginRight: 8 }}
                  resizeMode="contain"
                  source={require("../../../../../../../assets/bolus_carbs_icon.png")}
                />
                <Text style={[theme.fonts.h4, styles.inputLabel]}>
                  {languageContent.carbsModalTitle}
                </Text>
              </View>
            </View>
            <TouchableOpacity
              style={[
                theme.layout.inputContainerStyle,
                determineHighlighted(CARBS_KEY),
                { zIndex: 99999, height: "30%", marginBottom: -8 }
              ]}
              activeOpacity={1}
              ref={refs.carbsField}
              onPress={() => {
                handlePressTwo();
              }}>
              <TextInput
                style={[
                  theme.layout.inputStyle,
                  { height: "100%", width: "100%", fontSize: 32 }
                ]}
                value={unit}
                editable={false}
                ref={refs.carbsInput}
                keyboardType="decimal-pad"
                selectionColor={theme.colors.blue}
                returnKeyType="done"
                onFocus={() => handleInputFocus(CARBS_KEY)}
                onBlur={() => handleInputBlur(CARBS_KEY)}
              />
              <Text style={[theme.layout.adornment, { fontSize: 16 }]}>
                {languageContent.g}
              </Text>
            </TouchableOpacity>
            <Text
              style={[theme.fonts.body2, styles.units, { marginBottom: 0 }]}>
              {replaceWithValues(languageContent.mealBolus, contentVariables)}
            </Text>
            <View style={[styles.break]} />
          </View>
          <View style={[styles.section]}>
            <View style={[styles.inputLabelWrapper]}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center"
                }}>
                <Image
                  style={{ height: 16, width: 16, marginRight: 8 }}
                  resizeMode="contain"
                  source={require("../../../../../../../assets/bolus_bg_icon.png")}
                />
                <Text style={[theme.fonts.h4, styles.inputLabel]}>
                  {usingCGM
                    ? replaceWithValues(
                        languageContent.sensor,
                        contentVariables
                      )
                    : languageContent.glucose}
                </Text>
              </View>
              <AnimatedTouchable
                onPress={() => {
                  setGlucose("103");
                  setGlucoseMmol("5.6");
                  setUsingCGM(true);
                }}
                style={{
                  borderRadius: 5
                }}>
                {usingCGM ? (
                  <Text
                    style={[theme.fonts.body3, { color: theme.colors.white }]}>
                    {languageContent.useSensor}
                  </Text>
                ) : (
                  <Text style={[theme.fonts.body3, styles.inputLink]}>
                    {languageContent.useSensor}
                  </Text>
                )}
              </AnimatedTouchable>
            </View>
            <TouchableOpacity
              style={[
                theme.layout.inputContainerStyle,
                determineHighlighted("bg"),
                { height: "30%" }
              ]}
              activeOpacity={1}
              onPress={() => handlePressThree()}>
              <TextInput
                style={[
                  theme.layout.inputStyle,
                  {
                    height: "100%",
                    width: "100%",
                    fontSize: 32,
                    opacity: Number(bg) >= 600 ? 0 : null
                  }
                ]}
                value={langState.units === "mg/dL" ? glucose : glucosemmol}
                ref={refs.bgInput}
                placeholder={"103"}
                editable={false}
                placeholderTextColor={theme.colors.placeholder}
                selectionColor={theme.colors.blue}
                keyboardType="decimal-pad"
                returnKeyType="done"
                onFocus={() => handleInputFocus(BG_KEY)}
                onBlur={() => handleInputBlur(BG_KEY)}
              />
              {Number(bg) >= 600 ? (
                <Text
                  style={[
                    theme.layout.inputStyle,
                    {
                      position: "absolute",
                      paddingBottom: 9
                    }
                  ]}>
                  HIGH
                </Text>
              ) : null}
              <Text style={[theme.layout.adornment, { fontSize: 16 }]}>
                {langState.units}
              </Text>
            </TouchableOpacity>
            <Text
              style={[
                theme.fonts.body2,
                styles.units,
                { marginBottom: 32, marginTop: 10 }
              ]}>
              {replaceWithValues(
                languageContent.correctionBolus,
                contentVariables
              )}
            </Text>
          </View>
          <View>
            <View style={[styles.inputLabelWrapper]}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={[theme.fonts.h4, styles.inputLabel]}>
                  {languageContent.totalBolus}
                </Text>
              </View>
              <TouchableOpacity onPress={() => navigate("BolusCalculations")}>
                <Text style={[theme.fonts.body3, styles.inputLink]}>
                  {languageContent.calc}
                </Text>
              </TouchableOpacity>
            </View>
            <TouchableOpacity
              style={[
                theme.layout.inputContainerStyle,
                determineHighlighted(TOTAL_KEY),
                { height: "35%" }
              ]}
              activeOpacity={1}
              onPress={() => handlePressFour()}>
              <TextInput
                style={[
                  theme.layout.inputStyle,
                  determineTotalBolusColor(),
                  { height: "110%", width: "100%", fontSize: 32 }
                ]}
                value={bolusTotal}
                editable={false}
                ref={refs.totalInput}
                keyboardType="decimal-pad"
                returnKeyType="done"
                onFocus={() => handleInputFocus(TOTAL_KEY)}
                onBlur={() => handleInputBlur(TOTAL_KEY)}
              />
              <Text style={[theme.layout.adornment, { fontSize: 16 }]}>
                {languageContent.u}
              </Text>
            </TouchableOpacity>
            <Text style={[theme.fonts.body2, styles.units, { marginTop: 10 }]}>
              {replaceWithValues(languageContent.adjusted, contentVariables)}
            </Text>
          </View>
        </View>
        <BottomInnerNav
          leftAction={true}
          leftActionText={languageContent.cancel}
          leftActionStyle={styles.bottomNav}
          leftNavigationAction={() =>
            dispatch(
              navigationActions.navigationUpdate(
                { navigateTo: "Dashboard" },
                navState
              )
            )
          }
          middleActionText={showExtendBolus()}
          rightActionText={languageContent.confirm}
          middleActionStyle={
            glucose !== "--" && unit !== "--" && bolusTotal !== "--"
              ? styles.bottomNav
              : styles.bottomNavDisabled
          }
          middleNavigationAction={() => navigateToExtendBolus()}
          middleActionDisabled={!checkShouldProceed()}
          rightActionStyle={
            glucose !== "--" && unit !== "--" && bolusTotal !== "--"
              ? styles.bottomNav
              : styles.bottomNavDisabled
          }
          rightAction={() => navigateToConfirmBolus()}
          rightActionDisabled={
            glucose !== "--" && unit !== "--" && bolusTotal !== "--"
              ? false
              : true
          }
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  section: {
    zIndex: 999999
  },
  innerWrapper: {
    flex: 1,
    width: "100%",
    padding: 16,
    marginTop: 8
  },
  inputLabelWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 8,
    paddingBottom: 8
  },

  inputLink: {
    textTransform: "uppercase",
    fontWeight: "700",
    color: theme.colors.purple
  },
  inputLabel: {},

  units: {
    marginTop: 16,
    color: theme.colors.grayScale.gray400
  },
  break: {
    marginVertical: 16
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)"
  },
  modalView: {
    borderRadius: 2,
    margin: 48,
    backgroundColor: "white",
    padding: 24,
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  button: {
    padding: 8,
    elevation: 2,
    alignSelf: "flex-end"
  },
  textStyle: {
    color: theme.colors.purple,
    fontWeight: "bold",
    width: "100%",
    marginTop: 32,
    marginBottom: -16
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 16,
    textAlign: "left"
  },
  modalText: {
    fontSize: 16,
    textAlign: "left"
  },
  bottomNav: {
    fontSize: 14,
    color: theme.colors.purple
  },
  bottomNavDisabled: {
    fontSize: 14,
    color: "#B3BAC3"
  }
});

export default BolusScreen;
