import React from 'react';
import { useIntl } from 'react-intl';
import { createStackNavigator } from '@react-navigation/stack';
import { TopBar } from 'web/components/ios/TopBar';
import { RemindersMain } from './views/RemindersMain';
import { LowPodInsulin } from './views/LowPodInsulin';
import { PodShutOff } from './views/PodShutOff';
import { PodExpiration } from './views/PodExpiration';
import {
  messages,
  lowPodInsulinMessages,
  podShutOffMessages,
  podExpirationMessages,
} from './SettingsReminders.messages';
import { SettingsRemindersRoutesEnum } from './types';

const Stack = createStackNavigator();

export const SettingsRemindersStack: React.FC = () => {
  const intl = useIntl();
  return (
    <Stack.Navigator
      initialRouteName={SettingsRemindersRoutesEnum.RemindersMain}
      screenOptions={{
        header: (props) => (
          <TopBar
            {...props}
            showRightButton
            rightButtonClasses="text-ios-blue"
          />
        ),
      }}
    >
      <Stack.Screen
        name={SettingsRemindersRoutesEnum.RemindersMain}
        options={{
          title: intl.formatMessage(messages.title),
          header: (props) => <TopBar {...props} backMode />,
        }}
        component={RemindersMain}
      />
      <Stack.Screen
        name={SettingsRemindersRoutesEnum.LowPodInsulin}
        options={{
          title: intl.formatMessage(lowPodInsulinMessages.title),
        }}
        initialParams={{
          topBarRightButtonEnabled: true,
        }}
        component={LowPodInsulin}
      />
      <Stack.Screen
        name={SettingsRemindersRoutesEnum.PodShutOff}
        options={{
          title: intl.formatMessage(podShutOffMessages.title),
        }}
        initialParams={{
          topBarRightButtonEnabled: true,
        }}
        component={PodShutOff}
      />
      <Stack.Screen
        name={SettingsRemindersRoutesEnum.PodExpiration}
        options={{
          title: intl.formatMessage(podExpirationMessages.title),
        }}
        initialParams={{
          topBarRightButtonEnabled: true,
        }}
        component={PodExpiration}
      />
    </Stack.Navigator>
  );
};
