import { AppRoutesEnum } from '../routes/types';

export interface Route {
  label: string;
  name: string;
}

export const getRouteMap = (isIOS: boolean): Record<string, Route> => {
  return {
    dashboard: {
      label: 'Dashboard',
      name: isIOS ? AppRoutesEnum.dashboard : AppRoutesEnum.drawer,
    },
    startScreen: {
      label: 'First Time Setup - Start Screen',
      name: 'FTS',
    },
    basalSetup: {
      label: 'First Time Setup - Basal',
      name: AppRoutesEnum.basalFTS,
    },
    bolusSetup: {
      label: 'First Time Setup - Bolus',
      name: AppRoutesEnum.bolusFTS,
    },
    podSetup: {
      label: 'First time Setup - Pod Setup',
      name: AppRoutesEnum.podSetup,
    },
    txSetup: {
      label: 'First time Setup - Sensor Setup',
      name: AppRoutesEnum.txSetup,
    },
    bolusCalculator: {
      label: 'Bolus Calculator',
      name: AppRoutesEnum.Bolus,
    },
    alertsAlarms: {
      label: 'Alerts & Alarms',
      name: 'Notifications',
    },
    manageSensor: {
      label: 'Manage Sensor',
      name: 'SettingsCGMTransmitter',
    },
    settings: {
      label: 'Settings',
      name: isIOS ? AppRoutesEnum.menu : AppRoutesEnum.drawer,
    },
    history: {
      label: 'History',
      name: 'HistoryDetail',
    },
  }
}
