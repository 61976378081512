import React, { PropsWithChildren } from 'react';
import { Button } from 'web/components/ios/Button';

interface IntroScreenProps {
  title: string;
  primaryButtonText: string;
  primaryButtonHandler: React.MouseEventHandler<HTMLButtonElement>;
  secondaryButtonText: string;
  secondaryButtonHandler: React.MouseEventHandler<HTMLButtonElement>;
}

export const IntroScreen: React.FC<PropsWithChildren<IntroScreenProps>> = ({
  children,
  title,
  primaryButtonText,
  primaryButtonHandler,
  secondaryButtonText,
  secondaryButtonHandler,
}) => {
  return (
    <div className="bg-white p-2 pt-4 flex flex-col h-full justify-between">
      <div>
        <div className="font-ios font-bold text-xl text-modes-automated">
          {title}
        </div>

        <div className="mt-[75px]">{children}</div>
      </div>

      <div>
        <Button className="mb-1" variant="colored" full onClick={primaryButtonHandler}>
          {primaryButtonText}
        </Button>
        <Button full onClick={secondaryButtonHandler}>{secondaryButtonText}</Button>
      </div>
    </div>
  );
};
