import React from "react";
import { Linking, StyleSheet, Text, View } from "react-native";
import theme from "../theme";

const styles = StyleSheet.create({
  root: {
    flex: 1
  },
  headerText: {
    fontSize: 18,
    lineHeight: 30,
    fontWeight: "bold"
  },
  trademark: {
    fontSize: 15,
    lineHeight: 18
  },
  bodyContainer: {
    marginTop: 18,
    paddingLeft: 16,
    paddingRight: 16
  },
  textContainer: {
    width: 215
  },

  links: {
    color: "#8d61c8",
    paddingTop: 24,
    paddingLeft: 24,
    fontSize: 14,
    textDecorationLine: "underline"
  }
});

export const tcDataUS = [
  {
    title: "",
    data: [
      <View style={{ marginTop: -10, marginBottom: 8 }}>
        <Text
          style={{
            ...theme.fonts.text200,
            textAlign: "center"
          }}>
          {`
  Insulet Corporation 
  Terms and Conditions for Omnipod®\u00A05`}
        </Text>

        <Text style={{ ...theme.fonts.text600, marginTop: 12 }}>
          {`The Terms and Conditions for Omnipod®\u00A05 consist of the following: `}
        </Text>
      </View>
    ]
  },
  {
    title: "Privacy Policy",
    hideTitle: true,
    data: []
  },
  {
    title: "HIPAA Privacy Notice",
    hideTitle: true,
    data: []
  },
  {
    title: "End User License Agreement (EULA)",
    hideTitle: true,
    data: []
  },
  {
    title: "Terms of Use (ToU)",
    hideTitle: true,
    data: []
  },
  {
    title: "Limited Express Warranty",
    hideTitle: true,
    data: []
  },
  {
    title: "Legal Notices",
    hideTitle: true,
    data: []
  },
  {
    title: "Privacy Policy",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          This Privacy Policy ("Privacy Policy") applies to all personally
          identifiable information (excluding any Protected Health Information
          ("PHI") as such term is defined by the Health Insurance Portability
          and Accountability Act of 1996, as amended ("HIPAA"), collectively,
          ("Personal Data") that is collected on the www.Omnipod.com or other
          Insulet Corporation Websites (the "Websites")), the My Omnipod® mobile
          application or other mobile applications we make accessible or
          available for download (the “Application(s)”), or any other web-based
          communication channels or services created, used, offered or operated
          by Insulet Corporation (collectively the “Services”). Personal Data
          includes information we can use to specifically identify you such as
          your name, address, date of birth, telephone number, email address,
          username, Internet Protocol (IP) address or device identifiers when
          such address is associated with a specific user. This Privacy Policy
          applies only to Personal Data collected through the Services. It does
          not apply to any Personal Data collected by Insulet Corporation
          through other means. The Privacy Policy also does not apply to any
          PHI. For information regarding our policies with respect to PHI,
          please see our{" "}
          <Text
            onPress={() =>
              Linking.openURL("https://www.omnipod.com/hipaa-notice")
            }
            style={styles.links}>
            HIPAA Privacy Notice
          </Text>
          . We encourage you to read this Privacy Policy carefully before using
          the Services. By installing, using, registering, participating or
          otherwise accessing the Services, you consent to our practices
          concerning the collection, processing, use and disclosure of your
          Personal Data in accordance with this Privacy Policy. If you are a
          resident of the State of California, please see our{" "}
          <Text
            onPress={() =>
              Linking.openURL(
                "https://www.omnipod.com/privacy-policy/california-privacy-rights"
              )
            }
            style={styles.links}>
            California Privacy Rights
          </Text>{" "}
          page which applies to Personal Information of California Consumers
          collected online or otherwise. (Capitalized terms are defined in the
          linked policy.) If you are a California Consumer and your connection
          with us is based on an employee or contractor relationship, a business
          transaction, or a health care service covered by HIPAA or the
          California Confidentiality of Medical Information Act (CMIA), you are
          not considered a California Consumer for purposes of the California
          Privacy Policy.
        </Text>
      </View>
    ]
  },
  {
    title: "Revisions and Updates",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          Our commitment to be the leading provider of innovative insulin
          delivery systems means that our business will continue to evolve as we
          introduce new services and features. Because of this, from time to
          time, our policies will be reviewed and may be revised. Insulet
          Corporation reserves the right to change this Privacy Policy at any
          time and notify you by posting an updated version of the Privacy
          Policy on the Websites. It is your responsibility to periodically
          check the Websites so that you are aware of what information we
          collect, how we process and use it, and under what circumstances we
          might disclose it. The amended Privacy Policy will apply between us
          whether or not we have given you specific notice of any change.
        </Text>
      </View>
    ]
  },
  {
    title: "Your Personal Information is Important to Us",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          Insulet Corporation respects the importance of your privacy. You have
          a right to make your own choices about how your Personal Data is used.
          We know that when you provide Personal Data to us you are showing your
          trust, and we take that seriously. Unless you otherwise provide your
          consent, Insulet Corporation will only use your Personal Data as we
          explain in this Privacy Policy.
        </Text>
      </View>
    ]
  },
  {
    title: "Collecting Infromation About You",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          We collect Personal Data from you when you voluntarily choose to
          provide such information such as when you enter your Personal Data on
          our Websites or when you download and register or otherwise access an
          Application. If you contact us, we may also keep a record of that
          contact. By voluntarily providing us with Personal Data, you are
          consenting to our use of it in accordance with this Privacy Policy. If
          you provide Personal Data through the use of the Services, you
          acknowledge and agree that such Personal Data may be transferred from
          your current location to the offices and servers of Insulet
          Corporation and the authorized third parties referred to herein for
          the purposes described in this Privacy Policy.{"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.textMedium, fontSize: 18 }}>
            Non-Identifiable Data:{"\u00A0"}
          </Text>
          {"\n"}
          {"\n"}
          When you interact with Insulet Corporation through the use of the
          Services, we may receive and store certain personally non-identifiable
          information. We may store such information ourselves, or it may be
          included in databases owned and maintained by affiliates, agents or
          service providers of Insulet Corporation. We may use such information
          and pool it with other information to track, for example, the total
          number of visitors to our Websites, the number of visitors to each
          page of our Websites, the domain names of our visitors' Internet
          Service Providers (ISPs), technical mobile device data such as device
          model and name, device operating system and version. It is important
          to note that no Personal Data is available or used in this process.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.textMedium, fontSize: 18 }}>
            Cookies:{"\u00A0"}
          </Text>
          {"\n"}
          {"\n"}
          In operating the Services, we may use various technologies, including
          "cookies" and other tools. A cookie is a piece of information given to
          your web browser or device when you access the Services. Our cookies
          help provide additional functionality to the Services and help us
          analyze usage of the Services more accurately. The use of cookies is a
          common practice adopted by most major sites to better serve their
          users. You may review{" "}
          <Text
            style={styles.links}
            onPress={() =>
              Linking.openURL("https://www.omnipod.com/cookie-policy")
            }>
            Insulet’s Cookie Policy
          </Text>
          , which contains information on how the Services use cookies.
        </Text>
      </View>
    ]
  },
  {
    title: "Using and Disclosing Your Personal Information",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          Our purpose in collecting Personal Data about you is to:{"\n"}
          <Text>
            {"\u2022"} provide you with information which we believe interests
            you;
          </Text>
          {"\n"}
          <Text>{"\u2022"} effectively communicate with you; </Text>
          {"\n"}
          <Text>{"\u2022"} respond to your requests and inquiries;</Text>
          {"\n"}
          <Text>{"\u2022"} provide you with products and services; </Text>
          {"\n"}
          <Text>
            {"\u2022"} provide you with updates and options about our products
            and services;
          </Text>
          {"\n"}
          <Text>
            {"\u2022"} analyze our customers' experiences with our products and
            services, including the Services described above; and{" "}
          </Text>
          {"\n"}
          <Text>{"\u2022"} improve our products and services. </Text>
          {"\n"}
          <Text style={{ ...theme.fonts.body2, fontWeight: "500" }}>
            Insulet Corporation may also use or disclose your Personal Data to
            third parties if we believe that such disclosure is:{"\n"}
          </Text>
          <Text>{"\u2022"} required by law; </Text>
          {"\n"}
          <Text>{"\u2022"} necessary to fulfill the above purposes;</Text>
          {"\n"}
          <Text>
            {"\u2022"} necessary to implement and/or enforce our Terms of Use
            agreement;
          </Text>
          {"\n"}
          <Text>
            {"\u2022"} necessary to protect the rights, property or personal
            safety of another Insulet Corporation user, Website visitor, other
            users of the Services, or any member of the public; or{" "}
          </Text>
          {"\n"}
          <Text>
            {"\u2022"} In order to operate the Services, fulfill the purposes
            set out above or otherwise deliver a product or service, we may
            sometimes share your Personal Data with an affiliate, agent, service
            provider, distributor or other company in a business relationship
            with Insulet Corporation. When we use another company to perform a
            function of this nature, we endeavor to provide them only with the
            information that they need to perform their specific function and we
            instruct them to limit their use of the Personal Data accordingly.
          </Text>
          {"\n"}
          As we develop our business, we might sell or buy businesses or assets.
          In the event of a corporate sale, merger, reorganization, dissolution
          or similar event, Personal Data may be part of the transferred assets.
          Except as described in this Privacy Policy or permitted by law, we
          will not otherwise disclose your Personal Data to other companies
          without your consent.
        </Text>
      </View>
    ]
  },
  {
    title: "When We Will Contact You",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          <Text>
            {"\u2022"} We may contact you at the email address, mail address,
            telephone number, usernames or other contact information which you
            supply us in order to provide you with:
          </Text>
          {"\n"}
          <Text>{"\u2022"} Updated information about the Services;</Text>
          {"\n"}
          <Text>{"\u2022"} respond to your requests and inquiries;</Text>
          {"\n"}
          <Text>
            {"\u2022"} Updated information about Insulet Corporation;{" "}
          </Text>
          {"\n"}
          <Text>
            {"\u2022"} Updated options and information about our products and
            services, including the Omnipod® Insulin Management System;
          </Text>
          {"\n"}
          <Text>
            {"\u2022"} Other information that you have chosen to receive; and
          </Text>
          {"\n"}
          <Text>{"\u2022"} A response to your request(s). </Text>
        </Text>
      </View>
    ]
  },
  {
    title: "What Are Your Choices?",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          You can use certain of the Services without providing any Personal
          Data. However, you may not be able to obtain certain products or
          services from Insulet Corporation. Storage and Security of Your
          Personal Data. At Insulet Corporation, we endeavor to take reasonable
          steps to keep secure any Personal Data that we hold about you, and to
          keep such Personal Data accurate and up to date. Access to your
          Personal Data is restricted within Insulet Corporation to employees,
          agents, affiliates or service providers, who have specific
          authorization. Since no data transmission over the Internet can be
          guaranteed to be totally secure, we do not promise that the
          information you provide to us will always be totally secure. Insulet
          Corporation disclaims any responsibility for events arising from
          unauthorized access to your Personal Data.
        </Text>
      </View>
    ]
  },
  {
    title: "Third-Party Websites",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          Insulet Corporation may offer certain Services through third-party
          websites or provide links to third-party websites for your
          convenience. These third-party websites are not under our control, and
          we are not responsible for the conduct of or content provided by
          third-party websites. Before disclosing your Personal Data on any
          other website, we advise you to examine the terms and conditions and
          privacy policy of each of those websites. For more information
          concerning links to third-party websites, see the Terms of Use
          applicable to the Services. Links to third-party websites do not
          constitute or imply an endorsement by Insulet Corporation.
        </Text>
      </View>
    ]
  },
  {
    title: "Exclusions",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          This Privacy Policy shall not apply to any unsolicited information you
          provide to Insulet Corporation through your use of the Services or
          through any other means. This includes, but is not limited to,
          information posted to any public areas of the Services, any ideas for
          new products or modifications to existing products, and other
          unsolicited submissions (collectively, "Unsolicited Information"). All
          such Unsolicited Information shall be deemed to be non-confidential
          and Insulet Corporation shall be free to reproduce, use, disclose, and
          distribute such Unsolicited Information to others without limitation
          or attribution.
        </Text>
      </View>
    ]
  },
  {
    title: "Children's Online Privacy Protection Act",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          Unless otherwise specified, the Services are intended for adult use in
          the United States of America. No information should be submitted to or
          posted to any of the Services by users under 18 years of age without
          the consent of their parent or legal guardian. We encourage parents
          and legal guardians to monitor their children's Internet usage and to
          help enforce our Privacy Policy by instructing their children to never
          provide Personal Data on any of the Services without their permission.
          If you have reason to believe that a child under the age of 18 has
          provided Personal Data to Insulet Corporation through the use of any
          of the Services without the consent of their parent or legal guardian,
          you may contact us. If you have reason to believe that a child under
          the age of 13 has provided Personal Data to Insulet Corporation
          through the use of any of the Services without the consent of their
          parent or legal guardian, you may contact us and we will endeavor to
          delete that information from our records.
        </Text>
      </View>
    ]
  },
  {
    title: 'California "Do Not Track" Disclosure',
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          In accordance with the California Online Privacy Protection Act
          (CalOPPA) Amendment of 2013, we inform you that Insulet Corporation
          does not respond to Do Not Track requests or signals at this time.
        </Text>
      </View>
    ]
  },
  {
    title: "Other Terms and Conditions",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          Your access to and use of the Services are subject to our Terms of
          Use. The collection, processing, use and disclosure of your PHI are
          governed by our{" "}
          <Text
            style={styles.links}
            onPress={() =>
              Linking.openURL("https://www.omnipod.com/hipaa-notice")
            }>
            HIPAA Privacy Notice
          </Text>
          .
        </Text>
      </View>
    ]
  },
  {
    title: "Accessing Your Personal Data",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          Our HIPAA Privacy Notice explains the various policies and procedures
          we have in place for processing individual requests to access and
          amend any PHI maintained by Insulet Corporation. If you would like to
          request the right to review and/or amend any Personal Data that you
          have previously submitted via the Websites,{" "}
          <Text
            style={styles.links}
            onPress={() =>
              Linking.openURL(
                "https://privacyportal-de.onetrust.com/webform/7796d4e4-991f-4718-8896-8a9026be84d9/a1575c07-4510-4639-a964-08f10fb97506"
              )
            }>
            submit a request
          </Text>{" "}
          or contact our Privacy Officer, as specified below. Additional
          information about requests that you may make is available at the link.
        </Text>
      </View>
    ]
  },
  {
    title: "What to Do If You Have a Problem or Question",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          If Insulet Corporation becomes aware of any ongoing concerns or
          problems with the Services, we will take these issues seriously and
          work to address these concerns or problems when possible. If you have
          any further questions relating to our Privacy Policy, or if you have a
          problem or complaint, please contact us at: {"\n"}
          {"\n"}
          Insulet Corporation {"\n"}Attn: Privacy Officer {"\n"}100 Nagog Park
          {"\n"}Acton, MA 01720 {"\n"}
          Email: <Text style={styles.links}>privacy@insulet.com</Text> {"\n"}
          Toll-Free Phone: (866) 941-0155
        </Text>
      </View>
    ]
  },
  {
    title: "Children's Privacy Policy",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          This Children’s Privacy Policy, which is part of the Insulet
          Corporation Privacy Policy and uses terms that are defined in the
          Insulet Corporation Privacy Policy, sets forth additional provisions
          that apply to the collection, use and disclosure by Insulet
          Corporation of information provided by children under the age of 13
          (“child” or “children”). Insulet Corporation offers or may offer
          Websites and Applications, including the Toby’s T1D Tale mobile
          application, that are primarily intended for use by children and their
          families. This Children’s Privacy Policy is subject to the U.S.
          Children’s Online Privacy Protection Act, as may be amended from
          time-to-time ("COPPA") with respect to information that is personally
          identifiable to a child. When children interact with us through the
          Services, we may collect certain information to help us to improve the
          Services we offer and to understand how children are using the
          Services. This information could include anonymous details about the
          device a child uses to access the Services (e.g., operating system),
          or anonymous information about how a child engages with the Services,
          for example, the age range selected by the child, the character or
          “avatar” selected by the child, how long the child spends on various
          parts of the Services, and the child’s feedback on the Services. This
          information is not identifiable to the child and not considered
          “personally identifiable information” under COPPA or “Personal Data”
          under the Insulet Corporation Privacy Policy. This information may be
          collected by Insulet Corporation or a service provider who provides
          the Services or who provides support to Insulet Corporation relating
          to the Services (e.g., hosting provider). This information may be used
          for evaluation, analytics or any other internal purpose. This
          information may be shared with third parties in furtherance of the
          purpose(s) or as required by law. If you have any questions relating
          to our Children’s Privacy Policy, you may contact us by writing to:
          {"\n"}
          {"\n"}
          Insulet Corporation {"\n"}Attn: Privacy Officer {"\n"}100 Nagog Park
          {"\n"}Acton, MA 01720 {"\n"}
          Email: <Text style={styles.links}>privacy@insulet.com</Text> {"\n"}
          Toll-Free Phone: (866) 941-0155{"\n"} {"\n"}Effective Date: August 11,
          2004 {"\n"}Revision Dates: September 2, 2014; May 9, 2016; July 6,
          2016; June 1, 2019; June 22, 2020; September 15, 2022
        </Text>
      </View>
    ]
  },
  {
    title: "HIPAA Privacy Notice",
    data: [
      <View>
        <Text style={{ ...theme.fonts.h4 }}>
          This notice describes how medical information about you may be used
          and disclosed and how you can get access to this information. Please
          review it carefully.{" "}
        </Text>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          This notice of privacy practices (the "HIPAA Privacy Notice")
          describes how we may use and disclose your Medical Information to
          carry out treatment, payment or health care operations and for other
          purposes that are permitted or required by law, including by the
          Health Insurance Portability and Accountability Act, and all
          regulations issued thereunder ("HIPAA"). It also describes your rights
          to access and control your Medical Information. As used herein,
          "Medical Information" is information about you, including demographic
          information, that may identify you and that relates to your past,
          present or future physical or mental health or condition and related
          health care services.
        </Text>
      </View>
    ]
  },
  {
    title: "Uses and Disclosures of Medical Information",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          We will only use and disclose your Medical Information as permitted by
          law. Except for disclosures outlined in this HIPAA Privacy Notice
          and/or permitted by law, we will obtain your written authorization
          before using your Medical Information or disclosing it to any outside
          persons or organizations. Most uses or disclosures of your Medical
          Information constituting psychotherapy notes will be made only after
          receiving your written authorization. We will not use or disclose your
          Medical Information for purposes of marketing, except as permitted by
          law and/or outlined in this HIPAA Privacy Notice. We will not sell
          your Medical Information, without first obtaining your written
          authorization. You may revoke any written authorization you have
          provided to us at any time, except to the extent that we have made any
          uses or disclosures of your Medical Information in reliance on such
          authorization. To revoke a previously issued authorization, please
          send your request in writing, along with a copy of the authorization
          being revoked to our Privacy Officer. If a copy of the applicable
          authorization is not available, please provide a detailed description
          and date of the same to our Privacy Officer. There are some situations
          where we may use or disclose your Medical Information without your
          prior written authorization, as described further below: {"\n"}
          {"\u2022"} Uses and Disclosures of Your Medical Information Related to
          the Treatment and Services Provided by Us{"\n"}
          {"\u2022"} Treatment, Payment and Health Care Operations: We may use
          your Medical Information for treatment, to obtain payment for
          treatment, for administrative purposes, and to evaluate the quality of
          care that you receive without your authorization. We may use or
          disclose Medical Information about you without your authorization for
          several other reasons.
        </Text>
        <Text style={{ paddingLeft: 25, paddingVertical: 9 }}>
          {"\u2022"}Example of Treatment: In connection with treatment, we may
          use your Medical Information to provide you with one of our products.
        </Text>
        <Text style={{ paddingLeft: 25, paddingVertical: 9 }}>
          {"\u2022"}Example of Payment: We may use your Medical Information to
          generate a health insurance claim and to collect payment on invoices
          for services and/or medical devices provided.{" "}
        </Text>
        <Text style={{ paddingLeft: 25, paddingVertical: 9 }}>
          {"\u2022"}Example of Health Care Operations: We may use your Medical
          Information in order to process and fulfill your orders and to provide
          you with customer service.{" "}
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Appointment Reminder and Other Communications:
        </Text>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          We may use or disclose your Medical Information without your prior
          written authorization to provide you or others with, among other
          things, (i) appointment reminders; (ii) product/supply reorder
          notifications; and/or (iii) information about treatment alternatives
          or other health-related products and services that we provide.
        </Text>
        <Text style={{ ...theme.fonts.text400, paddingTop: 9 }}>
          {"\u2022"} Family, Friends, and Emergencies:
        </Text>
        <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
          If you require emergency treatment and we are unable to obtain your
          consent, we may disclose your Medical Information to a family member
          or relative who is involved in your care.
        </Text>
        <View>
          <Text style={{ ...theme.fonts.text400, paddingTop: 9 }}>
            {"\u2022"} Marketing:{" "}
            <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
              We may use or disclose your Medical Information to provide you
              with marketing communications about the health-related products
              and services that we provide, and about products, services,
              treatment or healthcare providers that may be of interest to you.
            </Text>
          </Text>
        </View>
      </View>
    ]
  },
  {
    title: "Additional Categories of Uses and Disclosures",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Required by Law:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We may use or disclose your Medical Information to the extent that
            applicable law requires the use or disclosure of such Medical
            Information. Where the use and/or disclosure of Medical Information
            is by law, the use or disclosure will be made in compliance with the
            law and will be limited to the relevant requirements of the law. You
            will be notified, as required by law, of any such uses or
            disclosures.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Public Health:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We may disclose your Medical Information for public health
            activities and purposes to a public health authority that is
            permitted by law to collect or receive the information. The
            disclosure will be made for the purpose of preventing or controlling
            disease, injury or disability. We may also disclose your Medical
            Information, if directed by the public health authority, to a
            foreign government agency that is collaborating with the public
            health authority.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Communicable Diseases:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We may disclose your Medical Information, if authorized by law, to a
            person who may have been exposed to a communicable disease or may
            otherwise be at risk of contracting or spreading the disease or
            condition.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Health Oversight:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We may disclose Medical Information to a health oversight agency for
            activities authorized by law, such as audits, investigations, and
            inspections. Oversight agencies seeking this information include
            government agencies that oversee the healthcare system, government
            benefit programs, other government regulatory programs and civil
            rights laws.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Food and Drug Administration:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We may disclose your Medical Information to a person or company as
            directed or required by the Food and Drug Administration: (i) to
            collect or report adverse events (or similar activities with respect
            to food or dietary supplements), product defects or problems
            (including problems with the use or labeling of a product), or
            biological product deviations, (ii) to track FDA-regulated products,
            (iii) to enable product recalls, repairs or replacement, or look
            back (including locating and notifying individuals who have received
            products that have been recalled, withdrawn, or are the subject of
            look back), or (iv) to conduct post-marketing surveillance.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Legal Proceedings:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We may disclose your Medical Information in the course of any
            judicial or administrative proceeding (i) in response to an order of
            a court or administrative tribunal (to the extent such disclosure is
            expressly authorized), and (ii) in certain conditions in response to
            a subpoena, discovery request or other lawful process, after we
            receive satisfactory assurance that the party seeking the
            information has reasonably attempted to notify you of the request or
            has reasonably attempted to secure a qualified protective order (in
            a court or administrative tribunal, or by stipulation) to limit
            disclosure of your Medical Information.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Law Enforement:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We may disclose Medical Information, as long as applicable legal
            requirements are met, for law enforcement purposes. These law
            enforcement purposes include: (i) legal processes otherwise required
            by law, (ii) limited information requests for identification and
            location purposes, (iii) pertaining to victims of a crime, (iv)
            suspicion that death has occurred as a result of criminal conduct,
            (v) in the event that a crime occurs on the premises of the
            practice, and (vi) medical emergency in which it is likely that a
            crime has occurred.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"}Research:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We may disclose your Medical Information to researchers when their
            research has been approved by an institutional review board that has
            reviewed the research proposal and established protocols to ensure
            the privacy of your Medical Information.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Criminal Activity:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            Consistent with applicable federal and state laws, we may disclose
            your Medical Information, if we believe the use or disclosure is
            necessary to prevent or lessen a serious and imminent threat to the
            health or safety of a person or the public. We may also disclose
            Medical Information if it is necessary for law enforcement
            authorities to identify or apprehend an individual.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Military Activity and National Security:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            When the appropriate conditions apply, we may use or disclose
            Medical Information of individuals who are Armed Forces personnel
            (i) for activities deemed necessary by appropriate military command
            authorities; , or (ii) to foreign military authority if you are a
            member of that foreign military service. We may also disclose your
            Medical Information to authorized federal officials for conducting
            national security and intelligence activities.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Workers' Compensation:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We may disclose your Medical Information as authorized to comply
            with workers' compensation laws and other similar
            legally-established programs.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Inmates:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We may use or disclose your Medical Information to a correctional
            institution or law enforcement official if you are an inmate of a
            correctional facility and your physician created or received your
            Medical Information in the course of providing care to you, and
            disclosure is necessary for (i) providing you with health care; (ii)
            the health and safety of you, other inmates, or others at the
            correctional institution; or (iii) the administration and
            maintenance of the safety, security, and good order of the
            correctional institution.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Required Uses and Disclosures:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            Under the law, we must make disclosures to you when required by the
            Secretary of the Department of Health and Human Services to
            investigate or determine our compliance with the requirements of
            HIPAA.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          {"\u2022"} Non-identifiable Information:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We may use or disclose your Medical Information if we have removed
            from it any information that is personally identifiable to you.
          </Text>
        </Text>
      </View>
    ]
  },
  {
    title: "Your Rights",
    data: [
      <View>
        <Text style={{}}>
          The following is a statement of your rights with respect to your
          Medical Information and a brief description of how you may exercise
          these rights.
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          You Have the Right to Inspect and Copy Your Medical Information:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            This means you may inspect and obtain a copy of Medical Information
            about you, provided, however, that applicable law may limit your
            ability to inspect or copy certain types of records. In certain
            circumstances, if we deny your request to review Medical
            Information, you may have a right to have this decision reviewed. If
            you would like to make a request to review your Medical Information,
            <Text
              style={styles.links}
              onPress={() =>
                Linking.openURL(
                  "https://privacyportal-de.onetrust.com/webform/7796d4e4-991f-4718-8896-8a9026be84d9/a1575c07-4510-4639-a964-08f10fb97506"
                )
              }>
              please submit a request here
            </Text>
            . We will respond to your request in a reasonable amount of time. If
            your request is honored, we may charge a nominal fee for
            photocopying expenses. Please contact our Privacy Officer if you
            have questions about access to your Medical Information.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          You May Have the Right to Amend Your Medical Information:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            If you believe that the Medical Information we have about you is
            incorrect or incomplete, you may ask us to make an amendment to your
            Medical Information. You may request an amendment as long as the
            Medical Information is still maintained in our records. If you would
            like to make a request to review your Medical Information,{" "}
            <Text
              style={styles.links}
              onPress={() =>
                Linking.openURL(
                  "https://privacyportal-de.onetrust.com/webform/7796d4e4-991f-4718-8896-8a9026be84d9/a1575c07-4510-4639-a964-08f10fb97506"
                )
              }>
              please submit a request here
            </Text>
            . We will respond to your request in a reasonable amount of time.
            Please contact our Privacy Officer if you have questions about
            requesting an amendment to your Medical Information.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          You Have the Right to Request a Restriction of Your Medical
          Information:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            You may ask us not to use or disclose any part of your Medical
            Information for the purposes of treatment, payment or healthcare
            operations. You may also request that any part of your Medical
            Information not be disclosed to family members or friends who may be
            involved in your care or for notification purposes as described in
            this HIPAA Privacy Notice. Your request must state the specific
            restriction requested and to whom you want the restriction to apply.
            Except as otherwise provided in this HIPAA Privacy Notice, we are
            not required to agree to a restriction that you may request. We are
            required to agree to your request to restrict disclosure of your
            Medical Information to a health plan if (i) the disclosure is to
            carry out payment or healthcare operations and is not otherwise
            required by law; and (ii) your Medical Information pertains solely
            to a healthcare item or service for which you or someone (other than
            the health plan) on your behalf, has paid us in full. If we agree to
            the requested restriction, we may not use or disclose your Medical
            Information in violation of that restriction unless it is needed to
            provide emergency treatment. If you would like to request a
            restriction of the use of your Medical Information,
            <Text
              style={styles.links}
              onPress={() =>
                Linking.openURL(
                  "https://privacyportal-de.onetrust.com/webform/7796d4e4-991f-4718-8896-8a9026be84d9/a1575c07-4510-4639-a964-08f10fb97506"
                )
              }>
              please submit a request here
            </Text>
            . We will respond to your request in a reasonable amount of time.
            Please contact our Privacy Officer if you have questions about
            requesting a restriction of the use of your Medical Information.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          You Have the Right to Request to Receive Confidential Communications
          from Us by Alternative Means or at an Alternative Location:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We will accommodate reasonable requests to receive confidential
            communications from us by alternate means or at an alternative
            location. We may also limit this accommodation by asking you for
            information as to how payment will be handled or specification of an
            alternative address or other method of contact. We will not request
            an explanation from you as to the basis for the request. Please make
            this request in writing to our Privacy Officer{" "}
            <Text
              style={styles.links}
              onPress={() =>
                Linking.openURL(
                  "https://privacyportal-de.onetrust.com/webform/7796d4e4-991f-4718-8896-8a9026be84d9/a1575c07-4510-4639-a964-08f10fb97506"
                )
              }>
              here
            </Text>
            .
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          You Have the Right to Receive an Accounting of Certain Disclosures We
          Have Made, if any, of your Medical Information:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            This right applies to disclosures for purposes other than treatment,
            payment or healthcare operations as described in this HIPAA Privacy
            Notice. It excludes disclosures we may have made to you, for a
            facility directory, to family members or friends involved in your
            care, for notification purposes, for national security or
            intelligence purposes, to correctional institutions or law
            enforcement officials, or as part of a limited data set. You have
            the right to receive specific information regarding these
            disclosures that occurred after April 14, 2003, or as otherwise
            provided for under applicable law. You may request a shorter
            timeframe. The right to receive this information is subject to
            certain exceptions, restrictions and limitations. If you would like
            to request an accounting of certain disclosure of your Medical
            Information,{" "}
            <Text
              style={styles.links}
              onPress={() =>
                Linking.openURL(
                  "https://privacyportal-de.onetrust.com/webform/7796d4e4-991f-4718-8896-8a9026be84d9/a1575c07-4510-4639-a964-08f10fb97506"
                )
              }>
              please submit a request here
            </Text>
            . We will respond to your request in a reasonable amount of time.
            Please contact our Privacy Officer if you have questions about
            requesting an accounting of the disclosures of your Medical
            Information.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          You Have the Right to Obtain a Copy of this HIPAA Privacy Notice :{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            You have the right to obtain a paper copy of this HIPAA Privacy
            Notice from us, upon request, even if you have agreed to accept this
            notice electronically. If you would like to request a paper copy of
            this HIPAA Privacy Notice,
            <Text
              style={styles.links}
              onPress={() =>
                Linking.openURL(
                  "https://privacyportal-de.onetrust.com/webform/7796d4e4-991f-4718-8896-8a9026be84d9/a1575c07-4510-4639-a964-08f10fb97506"
                )
              }>
              please submit a request here
            </Text>
            .
          </Text>
        </Text>
      </View>
    ]
  },
  {
    title: "Our Duties",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text400 }}>
          Generally:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We are required by law to maintain the privacy and security of your
            Medical Information and to provide you with notice of our legal
            duties and privacy practices with respect to Medical Information,
            and to notify you if there is a breach resulting in disclosure of
            your unsecured Medical Information.
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.text400 }}>
          Revisions and Modifications:{" "}
          <Text style={{ ...theme.fonts.text600, fontWeight: "normal" }}>
            We may change this HIPAA Privacy Notice at any time. Before we make
            a significant change in our policies, we will change this HIPAA
            Privacy Notice and post our new notice (the "Revised HIPAA Privacy
            Notice"). We are required to abide by the terms of this HIPAA
            Privacy Notice until a Revised HIPAA Privacy Notice becomes
            effective. The Revised HIPAA Privacy Notice will be effective for
            all Medical Information that we maintain as of the effective date of
            the Revised HIPAA Privacy Notice even if we collected or received
            the Medical Information prior to the effective date of the Revised
            HIPAA Privacy Notice. The current HIPAA Privacy Notice is posted on
            our Website at www.Omnipod.com If you would like to request a paper
            copy of this HIPAA Privacy Notice,{" "}
            <Text
              style={styles.links}
              onPress={() =>
                Linking.openURL(
                  "https://privacyportal-de.onetrust.com/webform/7796d4e4-991f-4718-8896-8a9026be84d9/a1575c07-4510-4639-a964-08f10fb97506"
                )
              }>
              please submit a request here
            </Text>
            .
          </Text>
        </Text>
      </View>
    ]
  },
  {
    title: "What to Do If You Have a Problem or Question",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text400, fontWeight: "normal" }}>
          If you are unable to use the online privacy request form, you may
          obtain assistance by calling our toll-free number: 1-800-591-3455. If
          you have any further questions relating to this HIPAA Privacy Notice
          or if you have a problem or complaint, please contact us in writing or
          by phone at:
          {"\n"}
          Insulet Corporation {"\n"}Attn: Privacy Officer {"\n"}100 Nagog Park
          {"\n"}Acton, MA 01720 {"\n"}
          Email: <Text style={styles.links}>privacy@insulet.com</Text> {"\n"}
          Toll-Free Phone: (866) 941-0155 {"\n"}
          {"\n"}
          Furthermore, if you believe that Insulet has violated your privacy
          rights with respect to your Medical Information, you have the right to
          file a complaint in writing with our Privacy Officer or with the
          Secretary of Health and Human Services at 200 Independence Avenue,
          S.W. Washington, D.C. 20201 or by calling 877-696-6775. Insulet will
          not retaliate against you for filing such a complaint.
          {"\n"} {"\n"}Effective Date: August 11, 2004 {"\n"}Revision Dates:
          April 1, 2009, September 20, 2013, April 22, 2014, September 2, 2014,
          June 1, 2019, September 15, 2022.
        </Text>
      </View>
    ]
  },
  {
    title: "End User License Agreement (EULA)",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text400, fontWeight: "normal" }}>
          This End User License Agreement (“EULA") is a binding agreement
          between Insulet Corporation (referred to as "we" or “us”) and you
          (referred to as “end user” or “you”) and applies to your use
          (including any access to or download from) www.omnipod.com or other
          Insulet Websites (each a “Website,” collectively the “Websites”), the
          My Omnipod® mobile application, Toby’s T1D Tale mobile application,
          and any other mobile applications and related documentation we make
          accessible, downloadable or otherwise available to you (each an
          “Application,” collectively the “Applications”), and any products and
          services accessible, downloadable or otherwise available via the
          Websites or Applications, including all features, functionality, and
          content thereof (collectively the “Services”). By using, accessing or
          downloading any of the Services or by clicking “accept” on this EULA,
          you signify your agreement to the terms and conditions of this EULA.
          If you do not agree to the terms and conditions of this EULA you must
          not use the Services. We reserve the right to change the terms of this
          EULA, in which event we will post the changes on this page. Your use
          of the Services after such changes are posted shall mean that you
          accept such changes. Your use and access to the Services are also
          governed by the Insulet Corporation Privacy Policy, the Insulet
          Corporation HIPAA Privacy Notice and Insulet Corporation Terms of Use,
          which are incorporated herein by reference. Your use of, access to
          and/or download from the Services may require you to acknowledge your
          acceptance of such Privacy Policy and/or Terms of Use, and your
          failure to do so may restrict you from using or accessing certain of
          the Application's features, functionality and content. Any violation
          of such Terms of Use will also be deemed a violation of this EULA.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>1. License{"\n"} </Text>
          {"\n"}
          Subject to the terms of this EULA, we grant you a limited,
          non-exclusive, and nontransferable license to use the Services for
          your personal, non-commercial use strictly in accordance with this
          EULA. You shall not (except as and only to the extent any following
          restriction is prohibited by applicable law or to the extent as may be
          permitted by the licensing terms governing use of any open sourced
          components included with the Services in accordance with Section 4
          hereof): a) copy the Services, except as expressly permitted by this
          limited license; b) modify, translate, adapt, or otherwise create
          derivative works or improvements, whether or not patentable, of the
          Services; c) reverse engineer, disassemble, decompile, decode, or
          otherwise attempt to derive or gain access to the source code of the
          Services or any part thereof; d) remove, delete, alter, or obscure any
          trademarks or any copyright, trademark, patent, or other intellectual
          property or proprietary rights notices from the Services, including
          any copy thereof; e) rent, lease, lend, sell, sublicense, assign,
          distribute, publish, transfer, or otherwise make available the
          Services, or any features, functionality and content of the Services,
          to any third party for any reason, including by making the Services
          available on a network where it is capable of being accessed by more
          than one device at any time; or f) remove, disable, circumvent, or
          otherwise create or implement any workaround to any copy protection,
          rights management, or security features in or protecting the Services;
          or g) use the Services in, or in association with, the design,
          construction, maintenance, or operation of any hazardous environments
          or systems, including any power generation systems; aircraft
          navigation or communication systems, air traffic control systems or
          any other transport management systems; safety-critical applications,
          including medical or life-support systems, vehicle operation
          applications or any police, fire, or other safety response systems;
          and military or aerospace applications, weapons systems, or
          environments.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            2. Reservation of Rights{"\n"}
            {"\n"}
          </Text>
          You acknowledge and agree that the Services are provided under
          license, and not sold, to you. You do not acquire any ownership
          interest in the Services under this EULA, or any other rights thereto
          other than to use the Services in accordance with the limited license
          granted, and subject to all terms and conditions of this EULA. We
          reserve and shall retain our entire right, title, and interest in and
          to the Services, including all copyrights, trademarks, and other
          intellectual property rights therein or relating thereto, except as
          expressly granted to you in this EULA.{"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            3.Collection and Use of Your Information {"\n"}
            {"\n"}
          </Text>
          You acknowledge that when you use the Services, we may use automatic
          means (including, for example, “cookies” and other tools) to collect
          information about your use of, access to and download from the
          Services (including, for example, mobile device data such as model and
          name, operating system and version and usage data such as frequency
          and duration of use). For certain features, functionality and content
          of the Services, you also may be required to provide certain
          information about yourself as a condition to using such features,
          functionality and content (for example, PodderCentral®). All “Personal
          Data” we collect through or in connection with the Services is subject
          to the Insulet Corporation Privacy Policy. All “Protected Health
          Information” or “PHI” we collect through or in connection with the
          Services is subject to the Insulet Corporation HIPAA Privacy Notice.
          By using, accessing and/or providing Personal Data and/or PHI to or
          through the Services, you consent to all actions taken by us with
          respect to your Personal Data and/or PHI in compliance with our
          Privacy Policy and/or our HIPAA Privacy Notice as applicable.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            4.Open Source Software {"\n"}
            {"\n"}
          </Text>
          Notwithstanding anything herein to the contrary, Open Source Software
          (“OSS”) as defined in our{" "}
          <Text
            style={styles.links}
            onPress={() =>
              Linking.openURL(
                "https://www.omnipod.com/sites/default/files/2021-03/Insulet-Corporation-Open-Source-Notice.pdf"
              )
            }>
            Open Source Notice
          </Text>{" "}
          is licensed to you under such OSS’s own applicable license terms,
          which can be found at{" "}
          <Text
            style={styles.links}
            onPress={() =>
              Linking.openURL(
                "https://www.omnipod.com/sites/default/files/2021-03/Insulet-Corporation-Open-Source-Notice.pdf"
              )
            }>
            Insulet Corporation Open Source Notice
          </Text>
          . These OSS license terms are consistent with the license granted in
          Section 1, and may contain additional rights for you. The OSS license
          terms shall take precedence over this EULA to the extent that this
          EULA imposes greater restrictions on you than the applicable OSS
          license terms, solely with respect to the portions of the features,
          functionality and content subject to such OSS license terms.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            5.Geographic Restrictions{"\n"}
          </Text>
          {"\n"}
          The Services are based in the Commonwealth of Massachusetts and/or
          other locations in the United States of America (“USA”) and intended
          for use, access and/or download only by persons located in the USA.
          You acknowledge that you may not be able to use, access and/or
          download all or some of the Services outside of the USA and that use,
          access and download thereto may not be legal by certain persons or in
          certain countries. If you use, access and/or download the Services
          from outside the United States, you are responsible for compliance
          with local laws.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>6.Updates{"\n"}</Text>
          {"\n"}
          We may, from time-to-time and in our sole discretion, develop and
          provide updates to the Services, which may include, but are not
          limited to, upgrades, bug fixes, patches, other error corrections,
          and/or the removal or introduction of features, functionality and
          content (collectively, including related documentation, “Updates").
          You acknowledge and agree that we have no obligation to provide any
          Updates or to continue to provide or enable any particular feature,
          functionality and content. We may make Updates to the Services
          automatically or prompt you to make them manually, depending both on
          the applicable Service and the means by which you use, access or
          download it. For Services you use, access or download from your mobile
          device(s), this may depend, in part, on the settings you have selected
          for your mobile device(s). When your internet connection permits: (a)
          the Services will automatically download and install all available
          Updates; or (b) you may receive notice of or be prompted to download
          and install available Updates. You shall promptly download and install
          all Updates and acknowledge and agree that the Services or portions
          thereof may not properly operate should you fail to do so. You further
          agree that all Updates will be deemed part of the Services and be
          subject to all terms and conditions of this EULA.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            7.Third Party Content{"\n"}
          </Text>
          {"\n"}
          The Services may display, include, or make available third party
          content (including text, graphics, images, logos, photographs, videos,
          sounds, music, voiceovers, source and object code, algorithms,
          software, data, messages, posts, information and/or other materials
          appearing on or in the Services, including applications and other
          products and services) or provide links to third party websites,
          products or services ("Third Party Content"). You acknowledge and
          agree that we are not responsible for Third Party Content, including
          its accuracy, completeness, timeliness, validity, legality, decency,
          quality, or any other aspect thereof. We do not assume and will not
          have any liability or responsibility to you or any other person or
          entity for any Third Party Content. Third Party Content and links
          thereto are provided solely as a convenience to you, and you access
          and use them entirely at your own risk and subject to such third
          parties’ terms and conditions.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            8.Terms and Termination{"\n"}
          </Text>
          {"\n"}
          The term of this EULA commences on the earlier of when you use,
          access, or download the Services or acknowledge your acceptance hereto
          and will continue in effect until terminated by us or by you or as set
          forth herein. a) By Us. We may suspend or terminate this EULA and your
          access to all or any part of the Services at any time and for any
          reason with or without prior notice or liability. We may also change,
          suspend, or discontinue all or any part of the Services, including
          certain features, functionality and content, at any time and for any
          reason with or without prior notice or liability. In addition, this
          EULA will terminate immediately and automatically without any notice
          if you violate any of the terms and conditions of this EULA. b) By
          You. You may terminate this EULA by discontinuing your use of the
          Services, which may include, but is not limited, removing the
          Application(s) from any mobile device(s) by which you access the
          Services. c) Survival. Upon termination: (i) all rights granted to you
          under this EULA will also terminate; and (ii) you must cease all use
          of the Services. If this EULA is terminated by us or by you, all
          sections shall survive the termination. Termination will not limit any
          of our rights or remedies at law or in equity.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            9. Disclaimer of Warranties and Limitations on Liability{"\n"}
          </Text>
          {"\n"}
          USE OF THE SERVICES IS AT YOUR SOLE RISK AND WE ASSUME NO
          RESPONSIBILITY FOR YOUR USE OR ACCESS OF THE SERVICES OR ANY
          CONSEQUENCES THEREOF. THE SERVICES ARE PROVIDED TO YOU "AS IS" AND “AS
          AVAILABLE” WITH ALL FAULTS AND DEFECTS AND WITHOUT WARRANTY OF ANY
          KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, WE, ON OUR
          OWN BEHALF AND ON BEHALF OF OUR AFFILIATES AND ITS AND THEIR
          RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIM ANY AND
          ALL REPRESENTATIONS, AGREEMENTS OR WARRANTIES WHATSOEVER, WHETHER
          EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE
          SERVICES, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND WARRANTIES
          THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE,
          OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, WE PROVIDE NO
          UNDERTAKING, AGREEMENT, OR WARRANTY, AND MAKE NO REPRESENTATION OF ANY
          KIND THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY
          INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE,
          APPLICATIONS, SYSTEMS, MOBILE DEVICES, OR SERVICES, OPERATE WITHOUT
          INTERRUPTION, MEET ANY AVAILABILITY, RELIABILITY, TIMELINESS,
          SECURITY, PERFORMANCE, OR ANY OTHER STANDARDS, BE ERROR OR DEFECT
          FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. SOME
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED
          WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A
          CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY
          NOT APPLY TO YOU. OUR LIABILITY IN SUCH JURISDICTIONS SHALL BE LIMITED
          TO THE MAXIMUM EXTENT PERMITTED BY LAW. TO THE FULLEST EXTENT
          PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE OR OUR AFFILIATES, OR
          ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE
          ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO
          USE THE SERVICES OR THE CONTENT AND SERVICES FOR: (a) PERSONAL INJURY,
          PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES,
          LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER
          FAILURE OR MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL,
          INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES. (b) DIRECT DAMAGES
          IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY
          YOU FOR THE SERVICES (WHICH, FOR THE AVOIDANCE OF DOUBT, SHALL NOT
          INCLUDE THE AMOUNT ACTUALLY PAID BY YOU FOR ANY PRODUCTS OR SERVICES
          YOU PURCHASED FROM US THROUGH THE SERVICES). THE FOREGOING LIMITATIONS
          WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT
          (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER SUCH
          DAMAGES WERE FORESEEABLE OR WE WERE ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF
          LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT
          APPLY TO YOU. OUR LIABILITY IN SUCH JURISDICTIONS SHALL BE LIMITED TO
          THE MAXIMUM EXTENT PERMITTED BY LAW.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            10. Export Regulation and USA Government Rights{"\n"}
            {"\n"}
          </Text>
          The Services, including the Application(s), may be subject to USA
          export control laws, including the US Export Administration Act and
          its associated regulations. You shall not, directly or indirectly,
          export, re-export, or release the Services to, or make the Services
          accessible from, any jurisdiction or country to which export,
          re-export, or release is prohibited by law, rule, or regulation. You
          shall comply with all applicable federal laws, regulations, and rules,
          and complete all required undertakings (including obtaining any
          necessary export license or other governmental approval), prior to
          exporting, re-exporting, releasing, or otherwise making the Services
          available outside the USA. The Services, including the Application(s),
          are commercial computer software, as such term is defined in 48 C.F.R.
          §2.101. Accordingly, if you are an agency of the USA Government or any
          contractor therefore, you receive only those rights with respect to
          the Services as are granted to all other end users under license, in
          accordance with (a) 48 C.F.R. §227.7201 through 48 C.F.R. §227.7204,
          with respect to the Department of Defense and their contractors, or
          (b) 48 C.F.R. §12.212, with respect to all other USA Government
          licensees and their contractors.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            11. Miscellaneous{"\n"}
            {"\n"}
          </Text>
          This EULA, our Privacy Policy, the Terms of Use, our HIPAA Privacy
          Notice and the Open Source Notice constitute the entire agreement
          between you and us with respect to the Services and supersede all
          prior or contemporaneous understandings and agreements, whether
          written or oral, with respect to the Services. If any provision of
          this EULA is found to be invalid, illegal or unenforceable by any
          court, such finding shall not affect the validity of the remaining
          provisions of this EULA which shall be amended to achieve as closely
          as possible the effect of the original term and all other provisions
          of this EULA will continue in full force and effect. No waiver shall
          be effective against us unless we make it in writing, and no such
          waiver shall be construed as a waiver in any other or subsequent
          instance. Headings are for convenience only and have no legal import.
          In the event of a conflict between this EULA and any applicable
          purchase or other terms, the terms of this EULA shall govern. This
          EULA will be governed by and construed in accordance with the internal
          laws of the Commonwealth of Massachusetts applicable to contracts
          entered into and performed exclusively in Massachusetts without giving
          effect to any choice or conflict of law provision or rule. Any court
          of competent jurisdiction sitting within Middlesex County,
          Massachusetts will have exclusive jurisdiction and venue for any
          dispute, legal suit, action or proceeding arising out of or relating
          to the Services or this EULA, and you hereby waive any argument or
          objection that any such court does not have jurisdiction over you or
          such dispute or that venue is not appropriate or convenient. ANY CAUSE
          OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS
          EULA OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE
          CAUSE OF ACTION ACCRUES OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS
          PERMANENTLY BARRED.
        </Text>
      </View>
    ]
  },
  {
    title: "Terms of Use (ToU)",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text400, fontWeight: "normal" }}>
          These Terms of Use ("Agreement") are between Insulet Corporation
          (referred to as "us" or "we") and you and apply to your use (including
          any access to) the www.Omnipod.com or other Insulet Corporation
          Websites (the “Websites”), the My Omnipod® mobile application or other
          mobile applications we make accessible or available for download (the
          "Application(s)"), or any other web-based communication channels or
          services created, used, offered or operated by Insulet Corporation
          (collectively the “Services”). By using or accessing any of the
          Services or by clicking “accept” to this Agreement, you signify your
          agreement to the terms of this Agreement. If you do not agree to the
          terms of this Agreement you must not use the Services. We reserve the
          right to change the terms of this Agreement, in which event we will
          post the changes on this page. Your use of the Services after such
          changes are posted will mean that you accept such changes.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            1. Ownership; use{"\n"}{" "}
          </Text>
          {"\n"}
          All text, graphics, images, logos, photographs, videos, sounds, music,
          voiceovers, source and object code, algorithms, software, data,
          messages, posts, information and other materials appearing on or in
          the Services ("Content") are either owned by us, or provided through
          an arrangement with third parties. Insulet Corporation and any such
          third parties retain all intellectual and proprietary rights to the
          Content, and the Content is protected by United States and
          international intellectual property laws, including, but not limited
          to copyright. You may view, access, download, use and print (if
          applicable) the Content for your personal, non-commercial use,
          provided you retain all copyright and other intellectual property and
          proprietary notices contained in the original Content on any copy. The
          Content may not be used in any manner not expressly authorized by
          Insulet Corporation under this Agreement and the limited rights
          granted to you are revocable at any time, with or without notice to
          you. In no event may you sell, transfer, assign, license, sublicense,
          or modify the Content or display, reproduce, perform, broadcast,
          modify, adapt, translate, make a derivative version of, sell,
          distribute, or otherwise use the Content in any way for any public or
          commercial purpose or provide it to any public or commercial website.
          You may not decompile, reverse engineer, disassemble, decrypt, attempt
          to derive the source code or object code underlying the Services, or
          use any automatic tools to use or access the Services, or to otherwise
          use or access the Services for any unlawful purpose. You may not use
          the Services in any way that could overburden, damage or disable the
          Services and/or impair or interfere with other’s use of the Services.
          Deep linking, framing, and the use or posting of the Content on any
          other website, web-based communication channel, or in a network or
          similar environment permitting use or access by multiple users at the
          same time or for any purpose are expressly prohibited. We reserve all
          rights with respect to the Services not expressly granted herein and
          may discontinue the Services at any time, with or without notice to
          you. Unless otherwise specified, the Services are intended for adult
          use in the United States of America. No information should be
          submitted to or posted to any of the Services by users under 18 years
          of age without the consent of their parent or legal guardian. Any
          unsolicited information you provide to Insulet Corporation through
          your use of the Services or through any other means shall be and
          remain the exclusive property of Insulet Corporation. This includes,
          but is not limited to, information posted to any public areas of the
          Services, any ideas for new products or modifications to existing
          products, and other unsolicited submissions (collectively,
          "Unsolicited Information"). All such Unsolicited Information shall be
          deemed to be non-confidential and Insulet Corporation shall be free to
          reproduce, use, disclose, and distribute such Unsolicited Information
          to others without limitation or attribution. Trademarks, service
          marks, and logos, including but not limited to Omnipod®, the Omnipod
          logo, Omnipod® Insulin Management System, the stylized name of Insulet
          Corporation, Podder™, Toby the Turtle™ and the Toby the Turtle
          character ("Marks") used and displayed on or in any of the Services
          are our registered and/or unregistered Marks in the United States of
          America and elsewhere, and nothing on or in the Services or in this
          Agreement should be construed as granting any license or right to use
          any Marks without our express prior written permission specifically
          for each use. Other product and company names mentioned herein may be
          service marks, trademarks, or registered service marks or registered
          trademarks of their respective owners.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            2. Third-Party Websites{"\n"}
            {"\n"}
          </Text>
          Insulet Corporation may offer certain Services through third-party
          websites or provide links to third-party websites for your
          convenience. These third-party websites are not under our control, and
          we are not responsible for the conduct or availability of or content
          provided by third-party websites. Use of third-party websites is at
          your own risk. Before disclosing your Personal Data on any other
          website, we encourage you to examine the terms and conditions and
          privacy policy of each of those websites. Links to third-party
          websites do not constitute or imply an endorsement by Insulet
          Corporation.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            3.Privacy Policy{"\n"}
            {"\n"}
          </Text>
          For more information concerning what information we collect and what
          we do with such information, see our Privacy Policy (which terms and
          conditions are incorporated into this Agreement by reference) or our
          Children’s Privacy Policy, if applicable. We may obtain and release
          any information if we believe, correctly or not, that such release is
          necessary to comply with law, to enforce this Agreement, the Privacy
          Policy, or the Children’s Privacy Policy, if applicable, or to
          safeguard our property or rights.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            4.CONTENT INFORMATION ONLY; CONSULT YOUR HEALTH CARE PROVIDERS{"\n"}
            {"\n"}
          </Text>
          YOU UNDERSTAND THAT HEALTH CARE AND TREATMENT ARE COMPLEX SUBJECTS
          REQUIRING THE SERVICES OF QUALIFIED HEALTH CARE PROVIDERS. THE CONTENT
          PROVIDED ON OR IN THE SERVICES IS INFORMATIONAL ONLY AND NOT INTENDED
          AS MEDICAL OR HEALTH CARE ADVICE OR RECOMMENDATIONS TO BE USED FOR
          DIAGNOSIS, TREAMENT OR FOR ANY OTHER INDIVIDUAL NEEDS. THE CONTENT IS
          NOT A SUBSTITUTE FOR MEDICAL OR HEALTH CARE ADVICE, RECOMMENDATIONS
          AND/OR SERVICES FROM A QUALIFIED HEALTH CARE PROVIDER. THE CONTENT MAY
          NOT BE RELIED UPON IN ANY WAY IN CONNECTION WITH YOUR PERSONAL HEALTH
          CARE, RELATED DECISIONS AND TREATMENT. ALL SUCH DECISIONS AND
          TREATMENT SHOULD BE DISCUSSED WITH A QUALIFIED HEALTH CARE PROVIDER
          WHO IS FAMILIAR WITH YOUR INDIVIDUAL NEEDS.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            5.Disclaimer of Warranties and Limitations on Liability{"\n"}
          </Text>
          {"\n"}
          Use of the Services is at your sole risk and Insulet Corporation
          assumes no responsibility for your use or access of the Services or
          any consequences thereof. Except for any express warranty stated on or
          in the Services, the Services, its Content, and all items offered for
          sale are provided on an "AS IS" and "AS AVAILABLE" basis without
          warranties of any kind. INSULET CORPORATION DOES NOT MAKE, AND HEREBY
          DISCLAIMS, ANY REPRESENTATION, AGREEMENT OR WARRANTY WHATSOEVER,
          EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
          NONINFRINGEMENT OF THIRD PARTIES' RIGHTS OR ANY WARRANTY THAT THE
          SERVICES OR CONTENT WILL (i) MEET YOUR REQUIREMENTS; (ii) BE
          UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (iii) PROVIDE RESULTS
          THAT WILL BE EFFECTIVE, ACCURATE, OR RELIABLE; (iv) BE FREE FROM ANY
          ERRORS OR ANY DEFECTS, OR THAT SUCH ERRORS OR DEFECTS WILL BE
          CORRECTED. IN NO EVENT SHALL INSULET CORPORATION BE RESPONSIBLE FOR
          ANY DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION ANY DIRECT,
          INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES TO
          ANY PERSON, EVEN IF WE KNOW OR HAVE BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES. Some jurisdictions do not allow the exclusion of implied
          warranties or limitation of incidental or consequential damages, so
          not all of this Section 5 may apply to you. INSULET CORPORATION'S
          LIABILITY IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE MAXIMUM EXTENT
          PERMITTED BY LAW.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            6.Termination of This Agreement{"\n"}
          </Text>
          {"\n"}
          By Us. We reserve the right, in our sole discretion, to suspend or
          terminate this Agreement and your access to all or any part of the
          Services or their Content, at any time and for any reason with or
          without prior notice or liability. We reserve the right to change,
          suspend, or discontinue all or any part of the Services or their
          Content at any time with or without prior notice or liability. By You.
          Your sole means for terminating this Agreement is to discontinue use
          of the Services. Survival. If this Agreement is terminated, all
          sections shall survive the termination.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>7.Miscellaneous{"\n"}</Text>
          {"\n"}
          This Agreement constitutes the entire agreement between you and us,
          and supersedes all prior agreements or communications. If any
          provision of this Agreement is found to be invalid by any court, the
          invalidity of such provision shall not affect the validity of the
          remaining provisions of this Agreement. No waiver shall be effective
          against us unless we make it in writing, and no such waiver shall be
          construed as a waiver in any other or subsequent instance. Headings
          are for convenience only and have no legal import. This Agreement will
          be governed by the laws of the Commonwealth of Massachusetts
          applicable to contracts entered into and performed exclusively in
          Massachusetts. Any court of competent jurisdiction sitting within
          Middlesex County, Massachusetts will have exclusive jurisdiction and
          venue for any dispute arising out of or relating to the Services or
          this Agreement, and you hereby waive any argument that any such court
          does not have jurisdiction over you or such dispute or that venue is
          not appropriate or convenient.
        </Text>
      </View>
    ]
  },
  {
    title: "Limited Express Warranty",
    data: [
      <View>
        <Text style={{ ...theme.fonts.text400, fontWeight: "normal" }}>
          <Text style={{ ...theme.fonts.text400 }}>
            LIMITED EXPRESS WARRANTY, DISCLAIMER OF IMPLIED WARRANTIES AND
            LIMITATION OF REMEDIES FOR THE OMNIPOD® 5 AUTOMATED INSULIN DELIVERY
            SYSTEM HANDHELD CONTROLLER AND PODS (United States of America){" "}
          </Text>
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text400 }}>
            LIMITED EXPRESS WARRANTY COVERAGE
          </Text>
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text400 }}>
            Limited Warranty Coverage for the Omnipod® 5 Automated Insulin
            Delivery System Handheld Controller
          </Text>
          {"\n"}
          {"\n"}
          Subject to the terms and conditions stated herein (''Limited Express
          Warranty''), lnsulet Corporation (''lnsulet'') warrants to you, the
          original purchaser of the Omnipod® 5 Automated Insulin Delivery System
          (''Omnipod 5 System''), that, if lnsulet determines, during the period
          of four (4) years from the date of purchase, that the Omnipod 5 System
          handheld hardware controller (''Controller'') included in your
          shipment manifests a defect in material or workmanship while utilized
          under normal use and conditions, lnsulet will either repair or
          replace, at its sole option, the Controller. If lnsulet chooses to
          reoair the Controller, lnsulet may choose to do so by implementing a
          software update, including an over-the-air software update, without
          further notice to the original purchaser. If lnsulet Corporation
          chooses to replace the Controller, lnsulet Corporation may choose to
          do so by replacing the Controller with an updated Controller. This
          four-year (4) warranty period applies only to new Controllers and, in
          the event the Controller is repaired or replaced, the warranty period
          shall not be extended or reset. Thus, if lnsulet replaces a Controller
          under this Limited Express Warranty, the warranty coverage for the
          replacement Controller shall expire four (4) years from the date of
          purchase of the original Controller.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text200 }}>
            Limited Warranty Coverage for the Omnipod® 5 Automated Insulin
            Delivery System Pods
            {"\n"}
            {"\n"}
          </Text>
          Subject to this Limited Express Warranty, lnsulet warrants to you, the
          original purchaser of the Omnipod® 5 Automated Insulin Delivery
          System, that, if lnsulet determines, during the period of eighteen
          (18) months from the date of manufacture and seventy-two (72) hours
          from the time of activation, that an unexpired Omnipod® 5 Automated
          Insulin Delivery System Pod (''Pod'') included in your shipment
          manifests a defect in material or workmanship while utilized under
          normal use and conditions, lnsulet will replace the Pod. To be
          eligible for replacement, the activation of the Pod must fall within
          both time periods (i.e. occur on or before the expiration date printed
          on the label with a manufacture date no more than eighteen (18) months
          before and on or before a time no more than seventy-two (72) hours
          before you notify lnsuletof the claim).
          {"\n"}
          This eighteen (18) month and seventy-two (72) hour warranty period
          applies only to new Pods and, in the event a Pod is replaced, the
          warranty period shall not be extended or reset. Thus, if lnsulet
          replaces a Pod under this Limited Express Warranty, the warranty
          coverage for the replacement Pod shall expire either eighteen (18)
          months from the manufacture date of the original Pod or seventy­two
          (72) hours from the time of activation of the original Pod, whichever
          occurs first.
          {"\n"}
          {"\n"}
          <Text style={{ ...theme.fonts.text400 }}>
            LIMITED EXPRESS WARRANTY TERMS AND CONDITIONS
            {"\n"}
            {"\n"}
          </Text>
          <Text style={{ textDecorationLine: "underline" }}>
            Claim Procedure
          </Text>
          {"\n"}
          {"\n"}
          To be eligible for this Limited Express Warranty, you must notify
          lnsulet of the claimed defect with the Controller or the Pod within
          the applicable warranty periods by calling Customer Care at
          1-800-591-3455 (from outside the USA: 1-978-600-7850). For a claim
          involving the Controller, you must provide the Controller serial
          number and a description of the claimed defect. For a claim involving
          a Pod, you must provide the Pod lot number and a description of the
          claimed defect. You may also be required to verify the date of
          purchase of the Controller and/or the Pod, the manufacture date of the
          Pod and the time of activation of the Pod. Your failure to follow any
          of the above steps may result in the denial of coverage under this
          Limited Express Warranty. Unless lnsulet elects to repair the
          Controller (which may include, but is not limited to, a repair kit or
          replacement part(s) lnsulet provides) or refers you to a third party,
          you must obtain a prior authorization and return the Controller or the
          Pod to lnsulet. The Controller or Pod must be properly packaged and
          returned to lnsulet according to the instructions provided in the
          Return Merchandise Authorization, or RMA, Kit. With a prior
          authorization, lnsulet will pay all reasonable freight and
          transportation charges, where applicable, incurred in shipping the
          Controller or Pod to lnsulet under this Limited Express Warranty. For
          the avoidance of doubt, this Limited Express Warranty does not cover
          repairs performed or replacements provided by any person or entity
          other than lnsulet, except those performed or provided by third
          parties to which you were explicitly referred by lnsulet.
          {"\n"}
          {"\n"}
          <Text style={{ textDecorationLine: "underline" }}>
            Proof of Purchase
          </Text>
          {"\n"}
          {"\n"}
          In order to verify the date of purchase, the date of manufacture, or
          the time of activation and to determine if the claim under this
          Limited Express Warranty is within the applicable warranty periods,
          lnsulet may require that you provide a valid proof of purchase,
          manufacture or activation. Your failure to provide a valid proof of
          purchase, manufacture or activation, as determined by lnsulet, may
          result in the denial of coverage under this Limited Express Warranty.
          {"\n"}
          {"\n"}
          <Text style={{ textDecorationLine: "underline" }}>Exclusions</Text>
          {"\n"}
          {"\n"}
          This Limited Express Warranty covers only the original purchaser and
          cannot be transferred or assigned with the sale, rental or other
          transfer of the Controller or of the Pod to any other person or
          entity. This Limited Express Warranty will apply only if the
          Controller or the Pod at issue has been used in accordance with the
          Omnipod® 5 Automated Insulin Delivery System User Guide and/or other
          written instructions provided by lnsulet. THIS LIMITED EXPRESS
          WARRANTY DOES NOT APPLY IF THE CONTROLLER OR THE POD HAVE BEEN:
          {"\n"}
          <Text>
            {"\u2022"}Altered, changed or modified by any person or entity other
            than lnsulet;{" "}
          </Text>
          {"\n"}
          <Text>
            {"\u2022"}Opened, serviced or repaired by any person or entitv other
            than lnsulet;
          </Text>
          {"\n"}
          <Text>
            {"\u2022"}Damaged by an act of God or other ''force majeure'' like
            event;
          </Text>
          {"\n"}
          <Text>
            {"\u2022"}Damaged by misuse, abuse, negligence, accident,
            unreasonable use, or improper handling, care or storage;{" "}
          </Text>
          {"\n"}
          <Text>
            {"\u2022"}Damaged by wear and tear, causes unrelated to defective
            materials or workmanship or other circumstances outside of the
            reasonable control of lnsulet.
          </Text>
          {"\n"}
          This Limited Express Warranty does not apply to test strips, batteries
          that are not provided by lnsulet, other accessories, or related
          products provided by third parties (e.g., data management tools,
          CGMs). {"\n"}This Limited Express Warranty does not extend to design
          defects (i.e. claims that the Controller or the Pod should have been
          designed in a different way).
          {"\n"}
          {"\n"}
          <Text style={{ textDecorationLine: "underline" }}>
            DISCLAIMER OF IMPLIED WARRANTIES AND LIMITATION OF REMEDIES{" "}
          </Text>
          {"\n"}
          {"\n"}
          <Text style={{ fontWeight: "bold" }}>
            REPAIR OR REPLACEMENT AS PROVIDED UNDER THE ABOVE LIMITED EXPRESS
            WARRANTY OF THE CONTROLLER OR THE POD IS YOUR EXCLUSIVE REMEDY AND
            THE ENTIRE OBLIGATION OF INSULET. THIS EXCLUSIVE REMEDY SHALL NOT BE
            DEEMED TO HAVE FAILED ITS ESSENTIAL PURPOSE SO LONG AS INSULET IS
            WILLING AND ABLE TO REPAIR OR REPLACE A CONTROLLER OR A POD WITH
            DEFECTS IN MATERIALS OR WORKMANSHIP IN THE MANNER PRESCRIBED BY THE
            ABOVE LIMITED EXPRESS WARRANTY. ANY IMPLIED WARRANTIES, INCLUDING
            WARRANTIES OF MERCHANT ABILITY AND FITNESS FOR A PARTICULAR PURPOSE,
            ARE EXPRESSLY DISCLAIMED. IN NO EVENT SHALL INSULET CORPORATION, ITS
            SUPPLIERS, DISTRIBUTORS, SERVICE PROVIDERS, AND/OR AGENTS BE LIABLE
            FOR AND/OR AGENTS BE LIABLE FOR INDIRECT, SPECIAL, INCIDENTAL, OR
            CONSEQUENTIAL DAMAGES CAUSED BY A DEFECT IN THE CONTROLLER OR A POD
            OR BY A BREACH OF THE ABOVE LIMITED EXPRESS WARRANTY, WHETHER SUCH
            CLAIM IS BASED IN WARRANTY, CONTRACT, TORT OR OTHERWISE.{" "}
          </Text>
          {"\n"}
          {"\n"}
          <Text style={{ fontWeight: "bold" }}>
            Important Additional Provisions
          </Text>
          {"\n"}
          {"\n"}
          INSULET CORPORATION DOES NOT WARRANT THE SUITABILITY OF THE CONTROLLER
          OR THE POD OR THE OMNIPOD® 5 AUTOMATED INSULIN DELIVERY SYSTEM FOR ANY
          SPECIFIC PERSON AS HEAL TH CARE AND TREATMENT ARE COMPLEX SUBJECTS
          REQUIRING THE SERVICES OF QUALIFIED HEAL TH CARE PROVIDERS. The above
          Limited Express Warranty gives you specific legal rights, and you may
          also have other rights which vary by jurisdiction. The above Limited
          Express Warranty applies only to Controllers and the Pods that were
          originally sold for use in the United States of America. Note that
          some jurisdictions do not allow the exclusion of implied warranties or
          the limitation of indirect, special, incidental, or consequential
          damages, so the above exclusions or limitations may not apply to you.
          INSULET CORPORATION'S LIABILITY IN SUCH JURISDICTIONS SHALL BE LIMITED
          TO THE MAXIMUM EXTENT PERMITTED BY LAW. SUCH LIMITATIONS SHALL INCLUDE
          BUT ARE NOT LIMITED TO THE FOLLOWING: ANY IMPLIED WARRANTIES THAT
          CANNOT BE DISCLAIMED UNDER THE LAW OF A PARTICULAR JURISDICTION ARE
          LIMITED, TO THE EXTENT ALLOWED BY LAW, TO THE TIME PERIOD COVERED BY
          THE ABOVE LIMITED EXPRESS WARRANTY, OR TO THE APPLICABLE TIME PERIOD
          PROVIDED BY LAW, WHICHEVER PERIOD IS SHORTER.
          {"\n"}
          {"\n"}
          <Text style={{ fontWeight: "bold" }}>
            No Other Warranty or Agreement
          </Text>
          {"\n"}
          {"\n"}
          Unless modified in writing and signed by both lnsulet and you, the
          foregoing Limited Express Warranty is understood to be the complete
          and exclusive understanding between lnsulet and you, superseding all
          prior warranties and agreements, oral or written, and all other
          communications relating to any defect in, failure or other malfunction
          in a Controller, a Pod, or an Omnipod® 5 Automated Insulin Delivery
          System. No employee, agent or other representative of lnsulet or any
          other party is authorized to make any product warranty or agreement
          applicable to a Controller, a Pod, or an Omnipod® 5 Automated Insulin
          Delivery System in addition to those made in the foregoing.
          {"\n"}
          {"\n"}
          <Text style={{ fontWeight: "bold" }}>
            Consent to Disclaimer of Implied Warranties and the Limitation of
            Remedies
          </Text>
          {"\n"}
          {"\n"}
          If you do not consent to and instead wish to reject the Disclaimer of
          Implied Warranties and the Limitation of Remedies included with the
          Omnipod® 5 Automated Insulin Delivery System, please return any
          Omnipod® 5 Automated Insulin Delivery System products (including any
          Controller and Pods) to lnsulet in exchange for a full refund. Failure
          to return such Omnipod® 5 Automated Insulin Delivery System products
          shall constitute acknowledgement of and consent to the Disclaimer of
          Implied Warranties and the Limitation of Remedies. {"\n"}
          {"\n"}Rev: January 2021
        </Text>
      </View>
    ]
  },
  {
    title: "LEGAL NOTICES",
    data: [
      `© 2004 - 2021 lnsulet Corporation. lnsulet, Omnipod, the Omnipod logo, DASH, the DASH logo, Omnipod DISPLAY, Omnipod VIEW, Simplify Life, Toby the Turtle, Podder and PodderCentral are trademarks or registered trademarks of lnsulet Corporation. All rights reserved. 

The Bluetooth® word mark and logos are registered trademarks owned by the Bluetooth SIG, Inc. and any use of such marks by lnsulet Corporation is under license. Ascensia, the Ascensia Diabetes Care logo, and Contour are trademarks and/or registered trademarks of Ascensia Diabetes Care Holdings AG .. 

All other trademarks are the property of their respective owners. The use of third party trademarks does not constitute an endorsement or imply a relationship or other affiliation.`
    ]
  }
];
