let strings = {
  "en-US": {
    autoMode: "AUTOMATED MODE",
    limited: "LIMITED",
    active: "ACTIVE",
    dexcom: "Dexcom G6",
    u: "U"
  },
  "en-GB": {
    autoMode: "AUTOMATED MODE",
    limited: "LIMITED",
    active: "ACTIVE",
    dexcom: "Dexcom G6",
    u: "U"
  },
  "de-DE": {
    autoMode: "AUTOMATISIERTER MODUS:",
    limited: "EINGESCHRÄNKT",
    active: "AKTIV",
    dexcom: "Dexcom G6",
    u: "E"
  },
  "es-US": {
    autoMode: "MODO AUTOMÁTICO:",
    limited: "LIMITADO",
    active: "ACTIVO",
    dexcom: "Dexcom G6",
    u: "U"
  },
  "fr-FR": {
    autoMode: "MODE AUTOMATISÉ",
    limited: "LIMITÉ",
    active: "ACTIF",
    dexcom: "Dexcom G6",
    u: "U"
  },
  "nl-NL": {
    autoMode: "GEAUTOMATISEERDE MODUS",
    limited: "BEPERKT",
    active: "ACTIEF",
    dexcom: "Dexcom G6",
    u: "E"
  }
};

export { strings };
