import React from 'react';
import classNames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'web/components/ios/Button';
import { ChevronRight } from 'web/components/ios/icons';
import {
  showModal,
  hideModal,
  setCurrentModal,
} from 'web/store/reducers/modals/modalsSlice';
import {
  selectBasalPrograms,
  selectActiveBasalProgram,
} from 'web/store/reducers/basalPrograms/basalPrograms.helpers';
import { setSelectedProgram } from 'web/store/reducers/basalPrograms/basalProgramsSlice';
import { useNavigation } from '@react-navigation/native';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';
import {
  StartBasalProgramModal,
  PauseInsulinModal,
  DeleteBasalProgramModal,
} from 'web/components/ios/modals/BasalProgramsModals/BasalProgramsModals';
import { selectIsInsulinPaused } from 'web/store/reducers/basal/basal.helpers';
import { AppRoutesEnum } from 'web/routes/types';
import { BasalSegmentImage } from '../components/BasalSegmentImage';
import { MenuDrawer } from '../components/MenuDrawer';
import { messages } from '../BasalPrograms.messages';
import { BasalProgramsRoutesEnum } from '../types';

export const BasalProgramsMain: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const basalPrograms = useSelector(selectBasalPrograms);
  const activeProgram = useSelector(selectActiveBasalProgram);
  const isInsulinPaused = useSelector(selectIsInsulinPaused);
  const { navigate } = useNavigation();

  const handlePodCommTimeout = () => {
    dispatch(
      navigateToRoute(AppRoutesEnum.dashboard, { showBottomInsulinModal: true })
    );
  };

  const handleProgramStart = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <StartBasalProgramModal onPodCommTimeout={handlePodCommTimeout} />
        ),
      })
    );
    dispatch(showModal());
  };

  const handleProgramEdit = (id: number) => () => {
    if (activeProgram && activeProgram.id === id) {
      return handleActiveProgramEditClick();
    }

    dispatch(hideModal());
    navigate(BasalProgramsRoutesEnum.EditBasalProgramStack);
  };

  const handleProgramDelete = (id: number) => () => {
    if (id !== 1) {
      dispatch(
        setCurrentModal({
          modal: <DeleteBasalProgramModal />,
        })
      );
      dispatch(showModal());
    }
  };

  const handleBasalProgramClick = (id: number) => () => {
    dispatch(setSelectedProgram({ id }));
    dispatch(
      setCurrentModal({
        modal: (
          <MenuDrawer
            onStart={handleProgramStart}
            onEdit={handleProgramEdit(id)}
            onDelete={handleProgramDelete(id)}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  const handleActiveProgramEditClick = () => {
    dispatch(setSelectedProgram({ id: activeProgram!.id }));

    if (isInsulinPaused) {
      return navigate(BasalProgramsRoutesEnum.EditBasalProgramStack);
    }

    dispatch(
      setCurrentModal({
        modal: (
          <PauseInsulinModal
            onOk={() => navigate(BasalProgramsRoutesEnum.EditBasalProgramStack)}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  const sortedPrograms = [...basalPrograms].sort(
    (a, b) => Number(b.isNew) - Number(a.isNew)
  );

  return (
    <div className="bg-white pt-[21px] pb-[34px] h-full flex flex-col justify-between">
      <div>
        {activeProgram && (
          <div className="px-2 mb-[29px]">
            <div className="text-small font-semibold text-ios-labelGray mb-0.5">
              <FormattedMessage
                {...(isInsulinPaused ? messages.paused : messages.inProgress)}
              />
            </div>
            <div
              className={classNames('font-semibold text-2xl', {
                'text-modes-manual': !isInsulinPaused,
                'text-ios-labelGray': isInsulinPaused,
              })}
            >
              {activeProgram.name}
            </div>
            <div className="flex justify-between items-end mb-[35px]">
              <div className="font-semibold">
                <FormattedMessage
                  {...messages.totalBasal}
                  values={{
                    value: intl.formatMessage(messages.units, {
                      units: activeProgram.unitsTotal,
                    }),
                  }}
                />
              </div>
              <div>
                <Button
                  colorMode="manual"
                  variant="tinted"
                  onClick={handleActiveProgramEditClick}
                >
                  <FormattedMessage {...messages.edit} />
                </Button>
              </div>
            </div>

            <div>
              <BasalSegmentImage imageName={activeProgram.imageName} />
            </div>
          </div>
        )}

        <div>
          <div className="flex items-center justify-between px-2 pb-[15px] border-b-1 border-b-ios-gray-9/[0.36]">
            <div
              className={classNames({
                'text-ios-labelGray': isInsulinPaused,
              })}
            >
              <FormattedMessage {...messages.savedBasalPrograms} />
            </div>
            <div className="text-ios-gray-9/60">
              <FormattedMessage
                {...messages.total}
                values={{ value: basalPrograms.length }}
              />
            </div>
          </div>

          {sortedPrograms.map((basalProgram) => {
            return (
              <div
                className="flex items-center justify-between px-2 py-1 border-b-1 border-b-ios-gray-9/[0.36] cursor-pointer"
                key={basalProgram.id}
                onClick={handleBasalProgramClick(basalProgram.id)}
              >
                <div>
                  <div>{basalProgram.name}</div>
                  <div className="text-small text-ios-gray-9/60">
                    <FormattedMessage
                      {...messages.units}
                      values={{ units: basalProgram.unitsTotal }}
                    />
                  </div>
                </div>

                <div className="flex items-center">
                  {basalProgram.isNew && (
                    <div className="mr-2 px-1 py-0.5 rounded-full bg-ios-activityGreen text-small font-semibold">
                      NEW
                    </div>
                  )}
                  <div>
                    <ChevronRight color="fill-ios-gray-9/30" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="px-2">
        <Button
          variant="colored"
          colorMode="manual"
          full
          onClick={() =>
            navigate(BasalProgramsRoutesEnum.CreateBasalProgramStack)
          }
        >
          <FormattedMessage {...messages.button} />
        </Button>
      </div>
    </div>
  );
};
