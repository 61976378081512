let strings = {
  "en-US": {
    segment: "Segment",
    review:
      "Review the Correction Factor values entered for each time segment.",
    segments: "Time segments",
    oneUnitLowers: "1 U of insulin lowers Glucose by:",
    cancel: "Cancel",
    save: "Save"
  },
  "en-GB": {
    segment: "Segment",
    review:
      "Review the Correction Factor values entered for each time segment.",
    segments: "Time segments",
    oneUnitLowers: "1 U of insulin lowers glucose by:",
    cancel: "Cancel",
    save: "Save"
  },
  "de-DE": {
    segment: "Segment",
    review:
      "Prüfen Sie die Werte für den Korrekturfaktor, die für jedes Zeitsegment eingegeben wurden.",
    segments: "Zeitsegmente",
    oneUnitLowers: "1 E Insulin senkt den Blutzucker:",
    cancel: "Abbr.",
    save: "Speichern"
  },
  "es-US": {
    segment: "Segmento",
    review:
      "Revise los valores del factor de corrección ingresados para cada segmento de tiempo.",
    segments: "Segmentos de tiempo",
    oneUnitLowers: "1 U insulina reduce la glucosa:",
    cancel: "Cancelar",
    save: "Guardar"
  },
  "fr-FR": {
    segment: "Segment",
    review:
      "Vérifiez les valeurs du facteur de correction saisies pour chaque segment temporel.",
    segments: "Segments temporels",
    oneUnitLowers: "1 U d'insuline réduit le glucose de :",
    cancel: "Annuler",
    save: "Enregistrer"
  },
  "nl-NL": {
    segment: "Segment",
    review:
      "Controleer de waarden voor de correctiefactor die voor elk tijdsegment zijn ingevoerd.",
    segments: "Tijdsegmenten",
    oneUnitLowers: "1 E insuline verlaagt de glucose \nmet:",
    cancel: "Annuleren",
    save: "Opslaan"
  }
};

export { strings };
