import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { ScrollView } from "react-native";
import {
  Image,
  StyleSheet,
  Text,
  Pressable,
  useWindowDimensions,
  View
} from "react-native-web";
import { useSelector } from "react-redux";
import segmentOneFilledImage from "../../../../../../../../assets/segment1_filled.png";
import segmentTwoFilledImage from "../../../../../../../../assets/segment2_filled.png";
import segmentTwoTimeImage from "../../../../../../../../assets/segment2_time.png";

import segmentOneFilledImage24 from "../../../../../../../../assets/segment1_filled_24.png";
import segmentTwoFilledImage24 from "../../../../../../../../assets/segment2_filled_24.png";
import segmentTwoTimeImage24 from "../../../../../../../../assets/segment2_time_24.png";
import segmentTwoTimeComma24 from "../../../../../../../../assets/segment2_time_comma.png";
import segmentOneFilledImageComma24 from "../../../../../../../../assets/segment1_filled_comma.png";
import segmentTwoFilledImageComma24 from "../../../../../../../../assets/segment2_filled_comma.png";

import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat";
import { getGraphImageHorizontalPlacement } from "../../../../../../helpers/getGraphImageHorizontalPlacement";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { BasalState } from "../../../../../../../../web/store/reducers/basal";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { BottomInnerNav, SegmentTopBar } from "../../../../../molecules";
import { strings } from "./BasalSegmentTwoScreenContent";

const BasalSegmentTwoScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );

  const [basalRate, setBasalRate] = React.useState<boolean>(false);
  const [endTime, setEndTime] = React.useState<string>("");

  const { languageContent } = useLanguageContent(strings);
  const { navigate } = useNavigation();
  const { width } = useWindowDimensions();

  const getGraphImage = () => {
    if (endTime && basalRate) {
      return langState.is24Hour
        ? langState.locale === "de-DE"
          ? segmentTwoFilledImageComma24
          : segmentTwoFilledImage24
        : segmentTwoFilledImage;
    }
    if (endTime) {
      return langState.is24Hour
        ? langState.locale === "de-DE"
          ? segmentTwoTimeComma24
          : segmentTwoTimeImage24
        : segmentTwoTimeImage;
    }
    return langState.is24Hour
      ? langState.locale === "de-DE"
        ? segmentOneFilledImageComma24
        : segmentOneFilledImage24
      : segmentOneFilledImage;
  };

  return (
    <>
      <ScrollView style={{ backgroundColor: "#fff" }}>
        <SegmentTopBar
          programName={basalState.customProgramName}
          segmentNum="2"
          showRightUnits
        />
        <View style={[styles.break]} />
        <Image
          style={{
            alignSelf: "auto",
            width: getGraphImageHorizontalPlacement(width),
            marginHorizontal: 16,
            height: "35%"
          }}
          resizeMode="contain"
          source={getGraphImage()}
        />
        <View style={[styles.break, { marginBottom: 16 }]} />
        <View style={[{ paddingHorizontal: 16 }]}>
          <View style={[{ flexDirection: "row" }]}>
            <Text style={[theme.fonts.h4, { flex: 1.1 }]}>
              {languageContent.start}
            </Text>
            <Text style={[theme.fonts.h4, { flex: 1 }]}>
              {languageContent.end}
            </Text>
          </View>
          <View style={[{ flexDirection: "row", width: "100%" }]}>
            <View
              style={[
                {
                  flexDirection: "row",
                  marginTop: 5
                }
              ]}>
              <Text
                style={[
                  {
                    fontSize: 32,
                    color: theme.colors.text.inputText
                  }
                ]}>
                {`${getCurrentHourFormat({
                  timeStr: "8:00 am",
                  is24Hour: langState.is24Hour,
                  trimModifier: true
                })}`}

                <Text style={[styles.periodText]}>
                  {langState.is24Hour ? "" : "AM"}
                </Text>
              </Text>
              <View style={[{ alignSelf: "center" }]}>
                <Text style={[{ fontSize: 32 }]}>{" - "}</Text>
              </View>
            </View>
            <Pressable
              onPress={() => setEndTime("12:00")}
              style={[
                theme.layout.mockInput,
                {
                  flex: 1,
                  padding: 5
                }
              ]}>
              <Text style={theme.layout.mockInputStyle}>
                {endTime ? (
                  <Text style={{ color: theme.colors.text.inputText }}>
                    {getCurrentHourFormat({
                      is24Hour: langState.is24Hour,
                      timeStr: "12:00 am",
                      trimModifier: true
                    })}
                    {!langState.is24Hour ? (
                      <Text style={[styles.periodText]}>AM</Text>
                    ) : null}
                  </Text>
                ) : (
                  <Text style={{ color: theme.colors.placeholder }}>––</Text>
                )}
              </Text>
            </Pressable>
          </View>
          <View style={[{ flexDirection: "row" }]}>
            <Text
              style={[
                theme.fonts.body1,
                { flex: 1.1, color: theme.colors.text.cancel }
              ]}>
              {languageContent.morning}
            </Text>
            <Text
              style={[
                theme.fonts.body1,
                { flex: 1.1, color: theme.colors.text.cancel }
              ]}>
              {endTime && languageContent.night}
            </Text>
          </View>
          <View style={[{ marginTop: 16, flex: 1 }]}>
            <Text style={[theme.fonts.h4]}>{languageContent.basalRate}</Text>
            <Text
              style={[
                theme.fonts.body1,
                { color: theme.colors.text.cancel, marginBottom: 8 }
              ]}>
              {languageContent.upTo}
            </Text>
          </View>
          <Pressable
            onPress={() => {
              setBasalRate(true);
            }}
            style={[theme.layout.inputContainerStyle]}>
            {basalRate ? (
              <>
                <Text style={[{ ...theme.layout.inputStyle }]}>
                  {(1.0).toLocaleString(langState.locale, {
                    minimumFractionDigits: 2
                  })}
                </Text>
                <Text style={[theme.layout.adornment]}>
                  {languageContent.unitsHr}
                </Text>
              </>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%"
                }}>
                <Text
                  style={{
                    ...theme.layout.inputStyle,
                    color: theme.colors.placeholder
                  }}>
                  ––
                </Text>
                <Text
                  style={[
                    theme.layout.adornment,
                    {
                      color: theme.colors.placeholder
                    }
                  ]}>
                  {languageContent.unitsHr}
                </Text>
              </View>
            )}
          </Pressable>
        </View>
      </ScrollView>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.next}
        rightAction={() => {
          if (endTime === "" || !basalRate) {
            return;
          }
          navigate("BasalSegmentsConfirm");
        }}
        rightActionStyle={{
          color:
            endTime === "" || !basalRate
              ? theme.colors.placeholder
              : theme.colors.purple
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  periodText: {
    color: theme.colors.text.cancel,
    fontSize: 18
  }
});

export default BasalSegmentTwoScreen;
