import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React, { useState } from "react";
import { Dimensions, StyleSheet, Text, TextInput, View } from "react-native";
import { useSelector } from "react-redux";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { Button } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./LockScreenContent";

const LockScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [inFocus, setInFocus] = useState<boolean>(false);

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  let numberOfLines;

  if (langState.language === "en-US") {
    numberOfLines = 2;
  } else if (langState.language === "es-US" || "nl-NL") {
    numberOfLines = 3;
  } else if (langState.language === "de-DE") {
    numberOfLines = 4;
  } else {
    numberOfLines = 0;
  }

  return (
    <View
      style={[
        theme.layout.wrapper,
        { width: "100%", backgroundColor: "white" }
      ]}>
      <View style={[styles.innerWrapper, { width: "100%" }]}>
        <Text
          style={[
            theme.fonts.h3,
            {
              marginTop: -16,
              marginBottom: 16,
              textAlign: "center",
              fontWeight: "200"
            }
          ]}>
          {languageContent.personalize}
        </Text>
        <Text
          style={[
            theme.fonts.text400,
            { fontWeight: "300", textAlign: "center" }
          ]}>
          {languageContent.lsMessage1}
        </Text>
        <TextInput
          style={[
            styles.textInput,
            theme.fonts.text400,
            {
              borderColor: inFocus
                ? theme.colors.blue
                : theme.colors.textInputBorder,
              fontWeight: "400",
              color: "#000000"
            }
          ]}
          multiline
          numberOfLines={numberOfLines}
          spellCheck={false}
          value={languageContent.message}
          editable={false}
        />
        <Text
          style={[
            theme.fonts.text400,
            { margin: 16, marginTop: 8, fontWeight: "400" }
          ]}>
          {languageContent.lsMessage2}
        </Text>
        <View style={{ paddingBottom: 16, paddingHorizontal: 4 }}>
          <Button
            variant="primary"
            style={{ elevation: 6 }}
            onPress={() => {
              navigate("Background");
            }}>
            {languageContent.continue}
          </Button>
        </View>
      </View>
      <BottomInnerNav />
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    width: Dimensions.get("screen").width,
    padding: 16,
    marginTop: 40
  },
  textInput: {
    margin: 16,
    padding: 16,
    fontWeight: "700",
    borderWidth: 1,
    borderRadius: 6,
    textAlign: "center",
    backgroundColor: theme.colors.textInputBackgroundColor
  }
});

export default LockScreen;
