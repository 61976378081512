import { CountryType } from '../types/country';

const COUNTRIES: Array<{ label: CountryType; value: CountryType }> = [
  { label: 'United States', value: 'United States' },
  { label: 'United Kingdom', value: 'United Kingdom' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Netherlands', value: 'Netherlands' },
  { label: 'France', value: 'France' },
  // { label: "Canada" },
];

export default COUNTRIES;
