import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, Text, View } from "react-native-web";
import { ReactComponent as Dexcom } from "../../../../../../../../assets/dexcom_image.svg";
import { ReactComponent as LogoSVG } from "../../../../../../../../assets/Omnipod_5_LOGO_RGB_MANGO.svg";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { Button } from "../../../../../atoms";
import { strings } from "./ConnectCGMScreenContent";

const NewPodLanding: React.FC = () => {
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ height: 800, backgroundColor: "white" }}>
      <View style={[styles.topContainer]}>
        <LogoSVG width="70%" height="70%" />
        <View>
          <Text style={{ color: theme.colors.purple, ...theme.fonts.h4 }}>
            {languageContent.setupComplete}
          </Text>
        </View>
      </View>
      <View style={[styles.divider]} />
      <View style={{ marginTop: 16, flex: 2 }}>
        <View
          style={{
            alignItems: "center"
          }}>
          <Text
            style={[
              { ...theme.fonts.h4 },
              { textAlign: "center", width: "70%", marginBottom: 24 }
            ]}>
            {languageContent.connectSensorToOmnipod}
          </Text>
          <Dexcom height={"50%"} width={"50%"} />
          <View
            style={{
              width: "100%",
              paddingHorizontal: 16,
              marginTop: 24
            }}>
            <View
              style={{
                marginVertical: 16
              }}>
              <Button
                variant="primary"
                style={{ elevation: 6 }}
                onPress={() => navigate("EnterSN")}>
                {languageContent.connectSensor}
              </Button>
            </View>
            <Button
              variant="default"
              style={{ elevation: 6 }}
              onPress={() => {}}>
              {languageContent.notNow}
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  topContainer: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  },
  divider: {
    marginVertical: 16,
    height: 2,
    width: "100%",
    borderWidth: 1,
    borderColor: theme.colors.textInputBorder
  }
});

export default NewPodLanding;
