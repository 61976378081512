import { defineMessages } from 'react-intl';

export const MaxCustomFoodsReachedMessages = defineMessages({
  title: {
    id: 'ios.modals.max-custom-foods-reached.title',
    defaultMessage: 'Maximum Reached',
  },
  text: {
    id: 'ios.modals.max-custom-foods-reached.text',
    defaultMessage:
      'You have the maximum number (50) of items in Custom Foods.',
  },
  rightButton: {
    id: 'ios.modals.max-custom-foods-reached.right-button',
    defaultMessage: 'OK',
  },
});

export const ConfirmDeleteCustomFoodsMessages = defineMessages({
  title: {
    id: 'ios.modals.confirm-delete-custom-foods.title',
    defaultMessage: 'Delete this Item from Custom Foods?',
  },
  rightButton: {
    id: 'ios.modals.confirm-delete-custom-foods.right-button',
    defaultMessage: 'Yes',
  },
  leftButton: {
    id: 'ios.modals.confirm-delete-custom-foods.left-button',
    defaultMessage: 'No',
  },
});

export const CancelEditingCustomFoodsMesssages = defineMessages({
  title: {
    id: 'ios.modals.cancel-editing-custom-foods.title',
    defaultMessage: 'Are you sure you want to cancel?',
  },
  text: {
    id: 'ios.modals.cancel-editing-custom-foods.text',
    defaultMessage: 'You have not saved your food.',
  },
  rightButton: {
    id: 'ios.modals.cancel-editing-custom-foods.right-button',
    defaultMessage: 'Yes',
  },
  leftButton: {
    id: 'ios.modals.cancel-editing-custom-foods.left-button',
    defaultMessage: 'No',
  },
});
