const calculateDurationFromTimestamps = (start: string, end: string) => {
  // formula: segment duration x basalRate, sum all segments
  let startHour = Number(start.split(":")[0]);
  const startHourHalf = Number(start.split(":")[1].split(" ")[0]);

  let endHour = end === "" ? 12 : Number(end.split(":")[0]);
  const endHourHalf = end === "" ? 0 : Number(end.split(":")[1].split(" ")[0]);

  const startHourAmPm = start.split(" ")[1].toLowerCase();
  const endHourAmPm = end === "" ? "am" : end.split(" ")[1].toLowerCase();

  if (startHourAmPm === "pm" && startHour < 12) {
    startHour += 12;
  } else if (startHourAmPm === "am" && startHour === 12) {
    startHour = 0;
  }

  // convert to 24h time
  if (endHourAmPm === "pm" && endHour < 12) {
    endHour += 12;
  } else if (endHourAmPm === "am" && endHour === 12) {
    //special case for when a segment is from 12am - 12:30 am
    if (endHourAmPm === "am" && endHour === 12 && endHourHalf === 30) {
      endHour = 0.5;
    } else {
      endHour = 24;
    }
  }

  let totalHours = endHour - startHour;
  // subtract 0.5 if half hour in the start time
  if (startHourHalf === 30) {
    totalHours = totalHours - 0.5;
  }
  // add 0.5 if half hour in the end time
  if (endHourHalf === 30) {
    totalHours = totalHours + 0.5;
  }
  //special case for when a segment is from 12am - 12:30 am
  if (endHour == 0.5) {
    totalHours = totalHours - 0.5;
  }

  return totalHours;
};

export default calculateDurationFromTimestamps;
