import {
  CardStyleInterpolators,
  createStackNavigator
} from "@react-navigation/stack";
import React from "react";
import { Animated } from "react-native";
import { BackButton } from "../../components/atoms";
import {
  ChangePodScreen,
  ChangePodScreenTwo,
  NoActivePodScreen
} from "../../components/screens";
import { useModeIconSmall } from "../../hooks";
import useLanguageContent from "../../hooks/useLanguageContent";
import theme from "../../theme";
import { strings } from "./ReplacePodStackContent";

const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const ReplacePodStack: React.FC = () => {
  const { icon } = useModeIconSmall();
  const headerRef = new Animated.ValueXY().y;
  const { languageContent } = useLanguageContent(strings);

  const animateHeader = React.useCallback(() => {
    Animated.timing(headerRef, {
      toValue: 0,
      duration: 600,
      useNativeDriver: false
    }).start();
  }, [headerRef]);

  React.useEffect(() => {
    animateHeader();
  }, [animateHeader]);

  return (
    <>
      <Stack.Navigator initialRouteName="NoActivePod" mode="modal">
        <Stack.Screen
          name="ChangePod"
          options={{
            title: "",
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.changePod} white={false} />
            ),
            headerRight: () => icon
          }}
          component={ChangePodScreen}
        />
        <Stack.Screen
          name="NoActivePod"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.pod} white={false} disabled />
            ),
            headerRight: () => icon,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
          }}
          component={NoActivePodScreen}
        />
        <Stack.Screen
          name="ChangePodTwo"
          options={{
            title: "",
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.changePod} white={false} />
            ),
            headerRight: () => icon
          }}
          component={ChangePodScreenTwo}
        />
      </Stack.Navigator>
    </>
  );
};

export default ReplacePodStack;
