import classNames from 'classnames';
import { ReactComponent as DeleteIcon } from 'assets/ios/delete-custom-food-icon.svg';
import { MouseEventHandler } from 'react';
import { CustomFoodMessages } from './CustomFood.messages';
import { FormattedMessage } from 'react-intl';

interface CustomFoodProps {
  name: string;
  carbs: number;
  newFood?: boolean;
  isEditModeActive?: boolean;
  deleteOnClickHandler: any;
  editCustomFoodHandler: MouseEventHandler<HTMLDivElement> | undefined;
}

export const CustomFood: React.FC<CustomFoodProps> = ({
  name,
  carbs,
  newFood,
  isEditModeActive = false,
  deleteOnClickHandler,
  editCustomFoodHandler,
}) => {
  const carbsContainerClass = classNames(
    'flex items-center basis-2/6 justify-end',
    {
      'justify-between': newFood,
    }
  );

  const deleteIconClass = classNames('w-[22px] h-[22px] mr-1 cursor-pointer', {
    hidden: !isEditModeActive,
  });

  const containerClass = classNames(
    'font-ios text-regular flex justify-between items-center p-2 border-b-[0.5px] border-b-ios-gray-9/36 ',
    {
      'cursor-pointer': !isEditModeActive,
    }
  );

  return (
    <div
      onClick={isEditModeActive ? () => {} : editCustomFoodHandler}
      className={containerClass}
    >
      <div className="flex items-center basis-4/6">
        <DeleteIcon
          onClick={() => {
            isEditModeActive && deleteOnClickHandler();
          }}
          className={deleteIconClass}
        />

        <p>{name}</p>
      </div>

      <div className={carbsContainerClass}>
        {newFood && (
          <div className="text-small font-semibold rounded-[17px] w-[49px] h-[28px] bg-ios-activityGreen text-center  py-[4px] px-[7px]">
            <FormattedMessage {...CustomFoodMessages.newLabel} />
          </div>
        )}
        <p className="text-ios-gray-9/60 ">
          {`${carbs}`}
          <span className="ml-0.5">
            <FormattedMessage {...CustomFoodMessages.carbsUnit} />
          </span>
        </p>

        {isEditModeActive && <p className="text-ios-gray-9/60 ml-[12px]">􀌇</p>}
      </div>
    </div>
  );
};
