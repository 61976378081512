import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'web/components/ios/Button';
import { PodCommunicationModal } from 'web/components/ios/modals/GlobalModals/GlobalModals';
import { ReactComponent as CarbsSvg } from 'assets/ios/icons/carbs.svg';
import { ReactComponent as GlucoseSvg } from 'assets/ios/icons/glucose.svg';
import { selectCurrentOperationMode } from 'web/store/reducers/user/user.helpers';
import { selectBolusDeliveryData } from 'web/store/reducers/bolus/bolus.helpers';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';
import { AppRoutesEnum } from 'web/routes/types';
import {
  showModal,
  hideModal,
  setCurrentModal,
} from 'web/store/reducers/modals/modalsSlice';
import { startBolusDelivery } from 'web/store/reducers/bolus/actions';
import {
  messages,
  confirmBolusMessages,
} from '../BolusAdministration.messages';

export const ConfirmBolus: React.FC = () => {
  const dispatch = useDispatch();
  const { setParams } = useNavigation();
  const {
    carbs,
    glucose,
    mealBolus = 0,
    correctionBolus = 0,
    extendedBolus = 0,
    totalBolus = 0,
    sensorUsed,
    currentTime,
    deliveryTime = 0,
    isExtended = false,
  } = useSelector(selectBolusDeliveryData);
  const operationMode = useSelector(selectCurrentOperationMode);

  const percentages = {
    bolusNow: (mealBolus * 100) / (mealBolus + extendedBolus),
    bolusExtended: (extendedBolus * 100) / (mealBolus + extendedBolus),
  };

  useEffect(() => {
    setParams({
      topBarOnLeftButtonClick: () => {
        dispatch(navigateToRoute(AppRoutesEnum.dashboard));
      },
    });
  }, []);

  const handlePodCommTimeout = () => {
    if (totalBolus) {
      dispatch(startBolusDelivery({ bolus: totalBolus, isExtended }));
      dispatch(hideModal());
      dispatch(navigateToRoute(AppRoutesEnum.dashboard));
    }
  };

  const handleStartClick = () => {
    dispatch(
      setCurrentModal({
        modal: <PodCommunicationModal onTimeout={handlePodCommTimeout} />,
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="h-full bg-white pt-[23px] pb-[34px] flex flex-col justify-between">
      <div className="px-2">
        <div className="flex items-center mb-[15px]">
          <div className="flex text-small font-semibold w-[45%]">
            <div className="mr-[6px]">
              <CarbsSvg />
            </div>
            <div className="mt-0.5">
              <FormattedMessage {...messages.carbsName} />
            </div>
          </div>
          <div className="text-4xl font-bold w-[55%]">
            <FormattedMessage
              {...messages.carbsValue}
              values={{ value: carbs }}
            />
          </div>
        </div>

        <div className="flex items-center mb-[21px]">
          <div className="flex text-small font-semibold w-[45%]">
            <div className="mr-[6px]">
              <GlucoseSvg />
            </div>
            <div className="mt-[2px]">
              {!sensorUsed && currentTime && (
                <FormattedMessage
                  {...messages.bgText}
                  values={{ time: currentTime.format('H:mm A') }}
                />
              )}
              {sensorUsed && currentTime && (
                <FormattedMessage
                  {...confirmBolusMessages.sensorGlucose}
                  values={{ time: currentTime.format('H:mm A') }}
                />
              )}
            </div>
          </div>
          <div className="text-4xl font-bold w-[55%]">
            <FormattedMessage
              {...messages.glucoseValue}
              values={{ value: glucose }}
            />
          </div>
        </div>

        {isExtended && (
          <>
            <div className="flex items-end">
              <div className="text-small font-semibold w-[45%]">
                <FormattedMessage {...confirmBolusMessages.bolusNow} />
              </div>
              <div className="text-3xl font-bold w-[55%]">
                {`${percentages.bolusNow}%`}
              </div>
            </div>
            <div className="mb-[35px]">
              <div>
                <FormattedMessage
                  {...confirmBolusMessages.mealBolus}
                  values={{ value: mealBolus }}
                />
              </div>
              <div>
                <FormattedMessage
                  {...confirmBolusMessages.correctionBolus}
                  values={{ value: correctionBolus }}
                />
              </div>
            </div>

            <div className="flex items-end">
              <div className="text-small font-semibold w-[45%]">
                <FormattedMessage {...confirmBolusMessages.bolusExtended} />
              </div>
              <div className="text-3xl font-bold w-[55%]">
                {`${percentages.bolusExtended}%`}
                <span className="ml-0.5">
              <FormattedMessage
                {...confirmBolusMessages.hours}
                values={{ value: deliveryTime }}
              />
            </span>
              </div>
            </div>
            <div>
              <FormattedMessage
                {...confirmBolusMessages.mealBolus}
                values={{ value: extendedBolus }}
              />
            </div>
          </>
        )}
      </div>

      <div className="border-t-1 border-t-ios-gray-9/[.36] px-2 pt-[12px]">
        <div className="flex items-end font-semibold mb-[10px]">
          <div className="text-small w-[45%]">
            <FormattedMessage {...messages.totalBolusName} />
          </div>
          <div className="text-4xl w-[55%]">
            <FormattedMessage
              {...messages.totalBolusValue}
              values={{ value: totalBolus }}
            />
          </div>
        </div>

        <div className="text-regular text-ios-gray-9/60 font-semibold mb-3">
          <FormattedMessage {...messages.adjustedIob} />
        </div>

        <Button
          colorMode={operationMode}
          variant="colored"
          full
          onClick={handleStartClick}
        >
          <FormattedMessage {...confirmBolusMessages.button} />
        </Button>
      </div>
    </div>
  );
};
