let strings = {
  "en-US": {
    averageSensor: "Average Sensor",
    sensorInRange: "Sensor in Range",
    range: "(%1 to %2)",
    sensorAbove: "Sensor Above",
    sensorBelow: "Sensor Below",
    totalInsulin: "Total Insulin",
    basalInsulin: "Basal Insulin",
    bolusInsulin: "Bolus Insulin",
    totalCarbs: "Total Carbs"
  },
  "en-GB": {
    averageSensor: "Average Sensor",
    sensorInRange: "Sensor in Range",
    range: "(%1 to %2)",
    sensorAbove: "Sensor Above",
    sensorBelow: "Sensor Below",
    totalInsulin: "Total Insulin",
    basalInsulin: "Basal Insulin",
    bolusInsulin: "Bolus Insulin",
    totalCarbs: "Total Carbs"
  },
  "de-DE": {
    averageSensor: "Durchschn. Sensorwert",
    sensorInRange: "Sensor im Bereich",
    range: "(%1 bis %2)",
    sensorAbove: "Sensorwert über",
    sensorBelow: "Sensorwert unter",
    totalInsulin: "Insulin gesamt",
    basalInsulin: "Basalinsulin",
    bolusInsulin: "Bolusinsulin",
    totalCarbs: "Gesamt-KH"
  },
  "es-US": {
    averageSensor: "Sensor promedio",
    sensorInRange: "Sensor en el intervalo",
    range: "(%1 a %2)",
    sensorAbove: "Sensor por encima de",
    sensorBelow: "Sensor por debajo",
    totalInsulin: "Insulina total",
    basalInsulin: "Insulina basal",
    bolusInsulin: "Insulina en bolo",
    totalCarbs: "Carbohidratos totales"
  },
  "fr-FR": {
    averageSensor: "Capteur moyen",
    sensorInRange: "Capteur dans la plage",
    range: "(%1 à %2)",
    sensorAbove: "Capteur supérieur",
    sensorBelow: "Capteur inférieur",
    totalInsulin: "Insuline totale",
    basalInsulin: "Insuline basale",
    bolusInsulin: "Insuline bolus",
    totalCarbs: "Glucides totaux"
  },
  "nl-NL": {
    averageSensor: "Gemidelde sensor",
    sensorInRange: "Sensor in bereik",
    range: "(%1 tot %2)",
    sensorAbove: "Sensor boven",
    sensorBelow: "Sensor onder",
    totalInsulin: "Totale insuline",
    basalInsulin: "Basale insuline",
    bolusInsulin: "Bolusinsuline",
    totalCarbs: "Totale KH"
  }
};

export { strings };
