import React from "react";
import { StyleSheet, Text, View } from "react-native";
import theme from "../../../theme";
import { MultiDayHistoryRowItem } from "../../atoms";
import MultiDayHistoryItemInterface from "../../../types/multiDayHistoryItemInterface";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../web/store/store";
import { LangState } from "../../../../../web/store/reducers/language";
import { isEqual } from "lodash";

interface Props {
  columnOneLabel: string;
  columnOneSubLabel: string;
  columnTwoLabel: string;
  columnTwoSubLabel: string;
  columnThreeLabel: string;
  columnThreeSubLabel: string;
  historyRange: {
    data: MultiDayHistoryItemInterface[];
    dataSpanish: MultiDayHistoryItemInterface[];
  };
}

const DetailsCardMultidayHistory: React.FC<Props> = ({
  columnOneLabel,
  columnOneSubLabel,
  columnTwoLabel,
  columnTwoSubLabel,
  columnThreeLabel,
  columnThreeSubLabel,
  historyRange
}) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  return (
    <View style={styles.card}>
      <View style={styles.header}>
        <View style={{ width: "12%" }} />
        <View style={styles.headerFlex}>
          <Text style={[styles.columnsAlign, { ...theme.fonts.body2 }]}>
            {columnOneLabel}
          </Text>
          <Text
            style={{
              ...theme.fonts.body2,
              color: theme.colors.text.grayText,
              marginVertical: -4
            }}>
            {columnOneSubLabel}
          </Text>
        </View>
        <View style={styles.headerColumn}>
          <Text style={[styles.columnsAlign, { ...theme.fonts.body2 }]}>
            {columnTwoLabel}
          </Text>
          <Text
            style={{
              ...theme.fonts.body2,
              color: theme.colors.text.grayText,
              marginVertical: -4
            }}>
            {columnTwoSubLabel}
          </Text>
        </View>
        <View style={styles.headerColumn}>
          <Text style={[styles.columnsAlign, { ...theme.fonts.body2 }]}>
            {columnThreeLabel}
          </Text>
          <Text
            style={{
              ...theme.fonts.body2,
              color: theme.colors.text.grayText,
              marginVertical: -4
            }}>
            {columnThreeSubLabel}
          </Text>
        </View>
      </View>
      <View>
        {langState.language === "en-US"
          ? historyRange &&
            historyRange.data &&
            historyRange.data.map(
              (item: MultiDayHistoryItemInterface, index: number) => (
                <MultiDayHistoryRowItem key={index} item={item} />
              )
            )
          : historyRange &&
            historyRange.dataSpanish &&
            historyRange.dataSpanish.map(
              (item: MultiDayHistoryItemInterface, index: number) => (
                <MultiDayHistoryRowItem key={index} item={item} />
              )
            )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.white,
    shadowColor: "#999",
    shadowOpacity: 0.3,
    shadowRadius: 6,
    shadowOffset: {
      height: 1,
      width: 0
    },
    elevation: 4,
    alignSelf: "center"
  },
  header: {
    borderBottomColor: theme.colors.textInputBorder,
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingVertical: 8,
    marginLeft: -22
  },
  headerFlex: {
    flexDirection: "column",
    alignItems: "center"
  },
  columnsAlign: {
    width: "60%",
    textAlign: "center"
  },
  headerColumn: {
    flexDirection: "column",
    alignItems: "center",
    marginLeft: -10
  }
});

export default DetailsCardMultidayHistory;
