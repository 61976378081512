import React from 'react';
import { ChevronRight } from 'web/components/ios/icons';
import { ToggleSwitch } from 'web/components/ios/ToggleSwitch';

interface SettingsRowProps {
  text: string;
  subText?: string;
  showCaret?: boolean;
  showStatus?: boolean;
  showToggle?: boolean;
  isToggled?: boolean;
  onClick?: () => void;
  onToggle?: () => void;
  isReadOnlyToggle?: boolean;
  status?: string;
}

export const SettingsRow: React.FC<SettingsRowProps> = ({
  text,
  subText,
  showCaret,
  showStatus,
  showToggle,
  isToggled = false,
  onClick,
  onToggle,
  isReadOnlyToggle = false,
  status = 'On',
}) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className="flex flex-row items-center justify-between py-[13px] px-2 border-b-1 border-b-ios-gray-9/[.36] cursor-pointer"
      onClick={handleOnClick}
    >
      <div>
        <div>{text}</div>
        <div className="text-small text-ios-gray-9/60">{subText}</div>
      </div>
      <div className="text-ios-gray-9/60 flex items-center">
        {showStatus && status}
        {showToggle && (
          <ToggleSwitch
            defaultValue={isToggled}
            onToggle={onToggle}
            readOnly={isReadOnlyToggle}
          />
        )}
        {showCaret && (
          <span className="ml-[12px]">
            <ChevronRight color="fill-ios-gray-9/30" />
          </span>
        )}
      </div>
    </div>
  );
};
