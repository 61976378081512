import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'ios.bolus-administration.title',
    defaultMessage: 'Bolus',
  },

  carbsName: {
    id: 'ios.bolus-administration.carbs',
    defaultMessage: 'CARBS',
  },
  customFoodsButton: {
    id: 'ios.bolus-administration.custom-foods-button',
    defaultMessage: 'Custom Foods',
  },
  carbsValue: {
    id: 'ios.bolus-administration.carbs-value',
    defaultMessage: '{value} g',
  },
  mealBolus: {
    id: 'ios.bolus-administration.meal-bolus',
    defaultMessage: 'Meal Bolus: {value} U',
  },

  glucoseName: {
    id: 'ios.bolus-administration.glucose',
    defaultMessage: 'GLUCOSE',
  },
  bgText: {
    id: 'ios.bolus-administration.bg-time',
    defaultMessage: 'BG ({time})',
  },
  useSensorButton: {
    id: 'ios.bolus-administration.use-sensor-button',
    defaultMessage: 'Use Sensor',
  },
  glucoseValue: {
    id: 'ios.bolus-administration.glucose-value',
    defaultMessage: '{value} mg/dL',
  },
  correctionBolus: {
    id: 'ios.bolus-administration.correction-bolus',
    defaultMessage: 'Correction Bolus: {value} U',
  },

  totalBolusName: {
    id: 'ios.bolus-administration.total-bolus',
    defaultMessage: 'TOTAL BOLUS',
  },
  calculationsButton: {
    id: 'ios.bolus-administration.calculations-button',
    defaultMessage: 'Calculations',
  },
  totalBolusValue: {
    id: 'ios.bolus-administration.total-bolus-value',
    defaultMessage: '{value} U',
  },
  iob: {
    id: 'ios.bolus-administration.iob',
    defaultMessage: 'IOB of 1 U',
  },
  adjustedIob: {
    id: 'ios.bolus-administration.adjusted-iob',
    defaultMessage: 'Adjusted for IOB of 1 U',
  },

  extendBolusCta: {
    id: 'ios.bolus-administration.extend-bolus-cta',
    defaultMessage: 'Extend Bolus',
  },
  confirmCta: {
    id: 'ios.bolus-administration.confirm-cta',
    defaultMessage: 'Confirm',
  },
});

export const bolusCalculationsMessages = defineMessages({
  title: {
    id: 'ios.bolus-administration.bolus-calculations.title',
    defaultMessage: 'Bolus Calculations',
  },
  mealBolus: {
    id: 'ios.bolus-administration.bolus-calculations.meal-bolus',
    defaultMessage: 'Meal Bolus',
  },
  correctionBolus: {
    id: 'ios.bolus-administration.bolus-calculations.correction-bolus',
    defaultMessage: 'Correction Bolus',
  },
  iobAdjustment: {
    id: 'ios.bolus-administration.bolus-calculations.iob-adjustment',
    defaultMessage: 'IOB Adjustment and Adjusted to Sensor: Trending steady',
  },
  yourAdjustment: {
    id: 'ios.bolus-administration.bolus-calculations.your-adjustment',
    defaultMessage: 'Your Adjustment',
  },
  totalBolus: {
    id: 'ios.bolus-administration.bolus-calculations.total-bolus',
    defaultMessage: 'TOTAL BOLUS',
  },
  units: {
    id: 'ios.bolus-administration.bolus-calculations.units',
    defaultMessage: '{value} U',
  },
  button: {
    id: 'ios.bolus-administration.bolus-calculations.button',
    defaultMessage: 'Done',
  },
});

export const extendedBolusAdministrationMessages = defineMessages({
  title: {
    id: 'ios.bolus-administration.extended-bolus.title',
    defaultMessage: 'Extended Bolus',
  },
  now: {
    id: 'ios.bolus-administration.extended-bolus.now',
    defaultMessage: 'NOW',
  },
  units: {
    id: 'ios.bolus-administration.extended-bolus.units',
    defaultMessage: '{value} U',
  },
  extended: {
    id: 'ios.bolus-administration.extended-bolus.extended',
    defaultMessage: 'EXTENDED',
  },

  duration: {
    id: 'ios.bolus-administration.extended-bolus.duration',
    defaultMessage: 'Duration',
  },
  durationRange: {
    id: 'ios.bolus-administration.extended-bolus.duration-range',
    defaultMessage: '(0.5 to 8 hrs)',
  },
  hours: {
    id: 'ios.bolus-administration.extended-bolus.hours',
    defaultMessage: '{value} hrs',
  },

  totalBolus: {
    id: 'ios.bolus-administration.extended-bolus.total-bolus',
    defaultMessage: 'TOTAL BOLUS',
  },
  mealBolus: {
    id: 'ios.bolus-administration.extended-bolus.meal-bolus',
    defaultMessage: 'Meal Bolus',
  },
  correctionBolus: {
    id: 'ios.bolus-administration.extended-bolus.correction-bolus',
    defaultMessage: 'Correction Bolus',
  },

  button: {
    id: 'ios.bolus-administration.extended-bolus.button',
    defaultMessage: 'Confirm',
  },
});

export const confirmBolusMessages = defineMessages({
  title: {
    id: 'ios.bolus-administration.confirm-bolus.title',
    defaultMessage: 'Confirm Bolus',
  },
  sensorGlucose: {
    id: 'ios.bolus-administration.confirm-bolus.sensor-glucose',
    defaultMessage: 'SENSOR ({time})',
  },
  bolusNow: {
    id: 'ios.bolus-administration.confirm-bolus.bolus-now',
    defaultMessage: 'BOLUS NOW',
  },
  mealBolus: {
    id: 'ios.bolus-administration.confirm-bolus.meal-bolus',
    defaultMessage: 'Meal: {value} U',
  },
  correctionBolus: {
    id: 'ios.bolus-administration.confirm-bolus.correction-bolus',
    defaultMessage: 'Correction: {value} U',
  },
  bolusExtended: {
    id: 'ios.bolus-administration.confirm-bolus.bolus-extended',
    defaultMessage: 'BOLUS EXTENDED',
  },
  hours: {
    id: 'ios.bolus-administration.confirm-bolus.hours',
    defaultMessage: '({value} hrs)',
  },
  button: {
    id: 'ios.bolus-administration.confirm-bolus.button',
    defaultMessage: 'Start',
  },
});

export const bolusCustomFoodsMessages = defineMessages({
  title: {
    id: 'ios.bolus-administration.custom-foods.title',
    defaultMessage: 'Custom Foods',
  },
  selectCustomFoods: {
    id: 'ios.bolus-administration.custom-foods.select-custom-foods',
    defaultMessage: 'Select custom food(s) to add',
  },
  sortFoods: {
    id: 'ios.bolus-administration.custom-foods.sort-foods',
    defaultMessage: 'Sort Foods',
  },
  carbs: {
    id: 'ios.bolus-administration.custom-foods.carbs',
    defaultMessage: '{carbs} g',
  },
  total: {
    id: 'ios.bolus-administration.custom-foods.total',
    defaultMessage: 'Total: {carbs} g',
  },
  itemsSelected: {
    id: 'ios.bolus-administration.custom-foods.items-selected',
    defaultMessage: '{selectedItems} items selected',
  },
  addToCalculator: {
    id: 'ios.bolus-administration.custom-foods.add-to-calculator',
    defaultMessage: 'Add to Calculator',
  },
});
