import React from "react";
import SignInImage from "../../../../assets/ms_signin_dark.png";
import { OAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

interface IProps {
  label: string;
}
const AuthButton: React.FC<IProps> = ({ label }) => {
  const navigate = useNavigate();
  const provider = new OAuthProvider("microsoft.com").setCustomParameters({
    tenant: process.env.REACT_APP_TENANT_ID as string
  });

  const auth = getAuth();
  const handlePress = async () => {
    if (auth.currentUser) {
      navigate("/");
      return;
    }

    try {
      const req = await signInWithPopup(auth, provider);

      const res = OAuthProvider.credentialFromResult(req);
      if (res?.accessToken) {
        return res;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <h1 style={{ textAlign: "center" }}>{label}</h1>
      <span onClick={handlePress}>
        <img src={SignInImage} style={{ cursor: "pointer" }} alt="" />
      </span>
    </React.Fragment>
  );
};

export default AuthButton;
