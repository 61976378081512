import { useIntl } from 'react-intl';
import { alertsMessages } from './Alerts.messages';
import { NotificationRow } from './components/NotificationRow';
import { SeparatorRow } from './components/SeparatorRow';
import { BottomNav } from 'web/components/ios/BottomMenu/BottomNav';

export const Alerts = () => {
  const intl = useIntl();

  // @ts-ignore TS doesn't like this, but the code below is correct.
  const formattedMessage = intl.formatMessage(
    alertsMessages.podErrorDescription,
    {
      p: (chunks) => <p>{chunks}</p>,
      p2: (chunks) => <p>{chunks}</p>,
    }
  );

  return (
    <div className="flex flex-col h-full justify-between">
      <div className=" overflow-y-scroll">
        <SeparatorRow title={intl.formatMessage(alertsMessages.actionsTitle)} />
        <SeparatorRow
          title={intl.formatMessage(alertsMessages.notificationHistoryTitle)}
        />
        <SeparatorRow
          bgColor="bg-white"
          title={intl.formatMessage(alertsMessages.todayDateTitle)}
        />
        <NotificationRow
          unread
          title={intl.formatMessage(alertsMessages.podExpiredTitle)}
          description={intl.formatMessage(alertsMessages.podExpiredDescription)}
          time={'1:50 AM'}
        />
        <SeparatorRow
          bgColor="bg-white"
          title={intl.formatMessage(alertsMessages.todayDateTitle)}
        />
        <NotificationRow
          unread
          title={intl.formatMessage(alertsMessages.podExpirationTitle)}
          description={intl.formatMessage(
            alertsMessages.podExpirationDescription
          )}
          time={'7:23 PM'}
        />
        <NotificationRow
          unread
          title={intl.formatMessage(alertsMessages.podErrorTitle)}
          description={formattedMessage}
          time={'12:30 PM'}
        />
        <NotificationRow
          title={intl.formatMessage(alertsMessages.lowPodInsulinTitle)}
          description={intl.formatMessage(
            alertsMessages.lowPodInsulinDescription
          )}
          time={'12:25 PM'}
        />
        <NotificationRow
          title={intl.formatMessage(alertsMessages.noActivePodTitle)}
          description={intl.formatMessage(
            alertsMessages.noActivePodDescription
          )}
          time={'10:30 AM'}
        />
        <NotificationRow
          title={intl.formatMessage(alertsMessages.podShutOffTitle)}
          description={intl.formatMessage(alertsMessages.podShutOffDescription)}
          time={'09:30 PM'}
        />
      </div>
      <BottomNav />
    </div>
  );
};
