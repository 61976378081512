import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import {
  selectNewProgram,
  selectSelectedBasalProgram,
} from 'web/store/reducers/basalPrograms/basalPrograms.helpers';
import { Button } from 'web/components/ios/Button';
import { ChevronRight } from 'web/components/ios/icons';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';
import { AppRoutesEnum } from 'web/routes/types';
import {
  showModal,
  setCurrentModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import {
  CancelEditBasalProgramModal,
  StartActiveBasalProgramModal,
  StartNewBasalProgramModal,
} from 'web/components/ios/modals/BasalProgramsModals/BasalProgramsModals';
import {
  BasalProgram,
  clearNewProgram,
  addProgram,
  setSelectedProgram,
} from 'web/store/reducers/basalPrograms/basalProgramsSlice';
import { BasalSegmentImage } from '../../../components/BasalSegmentImage';
import { confirmBasalProgramMessages } from '../../../BasalPrograms.messages';
import { BasalProgramsRoutesEnum } from '../../../types';

interface ConfirmBasalProgramProps {
  route: { params: RouteParams };
}

interface RouteParams {
  isNew?: boolean;
}

export const ConfirmBasalProgram: React.FC<ConfirmBasalProgramProps> = ({
  route,
}) => {
  const dispatch = useDispatch();
  const selectedProgram = useSelector(selectSelectedBasalProgram);
  const newProgram = useSelector(selectNewProgram);
  // @ts-ignore push is a valid property, but it still complains.
  const { navigate, push, setParams } = useNavigation();
  const [programData, setProgramData] = useState<Partial<BasalProgram>>({});
  const { isNew = false } = route.params;

  const handleConfirmCancel = () => {
    const currentProgram = isNew ? newProgram : selectedProgram;

    if (isNew) {
      dispatch(clearNewProgram());
    }

    if (currentProgram && currentProgram.isActive) {
      return dispatch(
        setCurrentModal({
          modal: (
            <StartActiveBasalProgramModal
              changesSaved={false}
              onPodCommTimeout={() => {
                dispatch(hideModal());
                navigate(BasalProgramsRoutesEnum.BasalProgramsMain);
              }}
            />
          ),
        })
      );
    }

    dispatch(hideModal());
    navigate(BasalProgramsRoutesEnum.BasalProgramsMain);
  };

  useEffect(() => {
    setParams({
      topBarOnLeftButtonClick: () => {
        dispatch(
          setCurrentModal({
            modal: (
              <CancelEditBasalProgramModal
                onYes={handleConfirmCancel}
                isNew={isNew}
              />
            ),
          })
        );
        dispatch(showModal());
      },
    });

    if (isNew) {
      setProgramData(newProgram);
    }

    if (selectedProgram && !isNew) {
      setProgramData(selectedProgram);
    }
  }, []);

  const handlePodCommTimeout = () => {
    dispatch(
      navigateToRoute(AppRoutesEnum.dashboard, { showBottomInsulinModal: true })
    );
  };

  const handleNewProgramNotNow = () => {
    navigate(BasalProgramsRoutesEnum.BasalProgramsMain);
  };

  const handleConfirmBasalProgram = () => {
    if (isNew) {
      dispatch(addProgram({ program: programData }));
      dispatch(setSelectedProgram({ id: programData.id }));
      dispatch(
        setCurrentModal({
          modal: (
            <StartNewBasalProgramModal
              onPodCommTimeout={handlePodCommTimeout}
              onNotNow={handleNewProgramNotNow}
            />
          ),
        })
      );
      return dispatch(showModal());
    }

    if (!programData.isActive) {
      dispatch(
        setCurrentModal({
          modal: (
            <StartNewBasalProgramModal
              onPodCommTimeout={handlePodCommTimeout}
              onNotNow={handleNewProgramNotNow}
            />
          ),
        })
      );
      return dispatch(showModal());
    }

    dispatch(
      setCurrentModal({
        modal: (
          <StartActiveBasalProgramModal
            changesSaved
            onPodCommTimeout={handlePodCommTimeout}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="bg-white h-full flex flex-col justify-between pt-[19px] pb-[34px]">
      <div>
        <div className="px-2 mb-[34px]">
          <div className="text-modes-manual font-semibold">
            {programData.name}
          </div>
          <div className="text-2xl font-semibold mb-[42px]">
            <FormattedMessage
              {...confirmBasalProgramMessages.totalBasal}
              values={{ totalBasal: programData.unitsTotal || 0 }}
            />
          </div>
          <div>
            <BasalSegmentImage imageName={programData.imageName} />
            <div className="text-smallest">
              <FormattedMessage {...confirmBasalProgramMessages.graphLegend} />
            </div>
          </div>
        </div>

        <div className="flex justify-between px-2 pb-[10px] border-b-1 border-b-ios-gray-9/[0.36] text-small text-ios-gray-9/60 font-semibold">
          <div>
            <FormattedMessage {...confirmBasalProgramMessages.timeSegments} />
          </div>
          <div>
            <FormattedMessage
              {...confirmBasalProgramMessages.total}
              values={{ segmentCount: programData.segments?.length }}
            />
          </div>
        </div>

        {programData.segments?.map((segment, index) => (
          <div
            className="flex items-center justify-between px-2 py-1 border-b-1 border-b-ios-gray-9/[0.36] cursor-pointer"
            onClick={() =>
              push(BasalProgramsRoutesEnum.EditSegment, {
                currentProgramId: programData.id,
                segmentId: segment.id,
                isNew,
                viewFromConfirm: true,
              })
            }
            key={`${segment.units}-${segment.startTime}`}
          >
            <div>
              <div>
                <FormattedMessage
                  {...confirmBasalProgramMessages.segmentTitle}
                  values={{
                    segmentNumber: index + 1,
                    startTime: moment()
                      .hour(segment.startTime)
                      .minutes(0)
                      .format('h:mm a'),
                    endTime:
                      segment.endTime &&
                      moment()
                        .hour(segment.endTime)
                        .minutes(0)
                        .format('h:mm a'),
                  }}
                />
              </div>
              <div className="text-small text-ios-gray-9/60">
                <FormattedMessage
                  {...confirmBasalProgramMessages.units}
                  values={{ units: segment.units }}
                />
              </div>
            </div>

            <div>
              <ChevronRight color="fill-ios-gray-9/30" />
            </div>
          </div>
        ))}
      </div>

      <div className="px-2">
        <Button
          variant="colored"
          colorMode="manual"
          full
          onClick={handleConfirmBasalProgram}
        >
          <FormattedMessage {...confirmBasalProgramMessages.save} />
        </Button>
      </div>
    </div>
  );
};
