let strings = {
  "en-US": {
    hypoDetails1:
      "Use the Activity feature during times of increased risk of hypoglycemia.",
    hypoDetails2:
      "The Activity feature reduces basal insulin delivery and sets basal delivery Target Glucose to %1",
    duration: "(1 to 24 hrs)",
    hr: "hr",
    hrs: "hrs",
    setHypoDuration1: "Set Activity feature for %2 hour?",
    setHypoDurationHours: "Set Activity feature for %2 hours?",
    chooseDuration: "Choose Duration",
    dismissText: "Cancel",
    confirmText: "Start",
    leftActionText: "Cancel",
    rightActionText: "Confirm",
    durationEnds: "Ends: %1",
    hourLetter: "h",
    one: "ONE",
    two: "TWO",
    durationLabel: "Duration"
  },
  "en-GB": {
    hypoDetails1:
      "Use the Activity feature during times of increased risk of hypoglycaemia.",
    hypoDetails2:
      "The Activity feature reduces basal insulin delivery and sets basal delivery Target Glucose to %1",
    duration: "(1 to 24 hrs)",
    hr: "hr",
    hrs: "hrs",
    setHypoDuration1: "Set Activity feature for %2 hour?",
    setHypoDurationHours: "Set Activity feature for %2 hours?",
    chooseDuration: "Choose Duration",
    dismissText: "Cancel",
    confirmText: "Start",
    leftActionText: "Cancel",
    rightActionText: "Confirm",
    durationEnds: "Ends: %1",
    hourLetter: "h",
    one: "ONE",
    two: "TWO",
    durationLabel: "Duration"
  },
  "de-DE": {
    hypoDetails1:
      "Verwenden Sie die Aktivitätsfunktion in Zeiten mit erhöhtem Hypoglykämierisiko.",
    hypoDetails2:
      "Die Aktivitätsfunktion reduziert die Basalinsulinabgabe und setzt den Glukose-Zielwert für die Basalabgabe auf %1",
    duration: "(1 Std. bis 24 Stdn.)",
    hr: "Std.",
    hrs: "Stdn.",
    setHypoDuration1: "Aktivitätsfunktion für %2 Stunde einstellen?",
    setHypoDurationHours: "Aktivitätsfunktion für %2 Stunden einstellen?",
    chooseDuration: "Dauer auswählen",
    dismissText: "Abbrechen",
    confirmText: "Starten",
    leftActionText: "Abbr.",
    rightActionText: "Bestätigen",
    durationEnds: "Endet: %1",
    hourLetter: "Std.",
    one: "EINS",
    two: "ZWEI",
    durationLabel: "Dauer"
  },
  "es-US": {
    hypoDetails1:
      "Use la función de Actividad durante los momentos de mayor riesgo de hipoglucemia.",
    hypoDetails2:
      "La función de Actividad reduce la administración de insulina basal y ajusta la administración basal de la Glucosa Objetivo a %1",
    duration: "(1 h a 24 h)",
    hr: "h",
    hrs: "h",
    setHypoDuration1: "¿Establecer la función de Actividad durante %2 hora?",
    setHypoDurationHours:
      "¿Establecer la función de Actividad durante %2 horas?",
    chooseDuration: "Elegir duración",
    dismissText: "Cancelar",
    confirmText: "Iniciar",
    leftActionText: "Cancelar",
    rightActionText: "Confirmar",
    durationEnds: "Finaliza: %1",
    hourLetter: "h",
    one: "UNO",
    two: "DOS",
    durationLabel: "Duración"
  },
  "fr-FR": {
    hypoDetails1:
      "Utilisez la fonction Activité pendant les périodes de risque accru d'hypoglycémie.",
    hypoDetails2:
      "La fonction Activité réduit l'administration d'insuline basale et configure le glucose cible de l'administration basale à %1",
    duration: "(1 à 24 h)",
    hr: "h",
    hrs: "h",
    setHypoDuration1: "Configurer la fonction Activité pour %2 heures ?",
    setHypoDurationHours: "Configurer la fonction Activité pour %2 heures ?",
    chooseDuration: "Choisir la durée",
    dismissText: "Annuler",
    confirmText: "Démarrer",
    leftActionText: "Annuler",
    rightActionText: "Confirmer",
    durationEnds: "Se termine : %1",
    hourLetter: "h",
    one: "UNE",
    two: "DEUX",
    durationLabel: "Durée"
  },
  "nl-NL": {
    hypoDetails1:
      "Gebruik de Activiteitenfunctie tijdens momenten met een verhoogd risico op hypoglykemie.",
    hypoDetails2:
      "De Activiteitenfunctie vermindert de basale insulinetoediening en stelt de basale toediening van de streefwaarde glucose in op %1",
    duration: "(1 tot 24 u.)",
    hr: "u.",
    hrs: "u.",
    setHypoDuration1: "Activiteitsfunctie instellen voor %2 uur?",
    setHypoDurationHours: "Activiteitsfunctie instellen voor %2 uur?",
    chooseDuration: "Kies de duur",
    dismissText: "Annuleren",
    confirmText: "Start",
    leftActionText: "Annuleren",
    rightActionText: "Bevestigen",
    durationEnds: "Eindigt: %1",
    hourLetter: "u.",
    one: "ÉÉN",
    two: "TWEE",
    durationLabel: "Duur"
  }
};

export { strings };
