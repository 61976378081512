import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import AuthContext from './context';
import globalStore from './web/store/store';
import appRoutes from './web/routes/AppRoutes';
import unauthorizedRoutes from './web/routes/UnauthorizedRoutes';

const App = () => {
  const auth = getAuth();
  const [loading, setLoading] = React.useState(false);
  const [isAuthorized, setIsAuthorized] = React.useState(true);

  React.useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (!user) {
        setIsAuthorized(false);
        setLoading(false);
      } else {
        setIsAuthorized(auth.currentUser?.email === user?.email);
        setLoading(false);
      }
    });
  }, [auth, isAuthorized]);

  return loading ? (
    <h1>Redirecting...</h1>
  ) : (
    <AuthContext.Provider value={{ authorized: isAuthorized }}>
      <Provider store={globalStore}>
        <RouterProvider
          router={isAuthorized ? appRoutes : unauthorizedRoutes}
        />
      </Provider>
    </AuthContext.Provider>
  );
};

export default App;
