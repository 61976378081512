import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { useSelector } from "react-redux";
import { getUnitByLocale } from "../../../../../../helpers/getUnitByLocale";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { Paragraph } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./MinBGForCalcScreenContent";
const { height } = Dimensions.get("window");

const MinBGForCalcScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);
  const [glucose, setGlucose] = React.useState<string>();
  const lowerRange = getUnitByLocale(50, langState.units);
  const higherRange = `${getUnitByLocale(70, langState.units)} ${
    langState.units
  }`;
  const contentVariables = [lowerRange, higherRange];

  return (
    <View style={[styles.container]}>
      <View>
        <View style={[{ paddingHorizontal: 16, paddingTop: 24 }]}>
          <Paragraph variant="body1" label={languageContent.bloodGlucose} />
          <View
            style={[
              {
                flexDirection: "row",
                alignItems: "flex-start",
                height: height > 600 ? "65%" : "55%",
                marginTop: 24
              }
            ]}>
            <View style={[{ width: "100%" }]}>
              <View>
                <Text style={[theme.fonts.h4]}>{languageContent.minBG}</Text>
                <Text
                  style={[
                    theme.fonts.body1,
                    { color: theme.colors.grayScale.gray400 }
                  ]}>
                  {replaceWithValues(languageContent.range, contentVariables)}
                </Text>
              </View>
              <TouchableOpacity
                style={[
                  theme.layout.inputContainerStyle,
                  {
                    marginTop: 8,
                    flexDirection: "row",
                    width: "100%"
                  }
                ]}
                onPress={() => {
                  setGlucose("70");
                }}>
                <Text style={[theme.layout.inputStyle]}>
                  {glucose
                    ? getUnitByLocale(Number(glucose), langState.units)
                    : null}
                </Text>
                <Text style={[theme.layout.adornment]}>{langState.units}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.save}
        rightAction={() => navigate("BolusSettingsMain")}
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "white"
  },
  mockInput: {
    borderColor: theme.colors.textInputBorder,
    borderWidth: 1,
    backgroundColor: theme.colors.textInputBackgroundColor,
    width: "100%",
    borderRadius: 5
  },
  unitText: {
    color: theme.colors.grayScale.gray400,
    fontSize: 18
  }
});

export default MinBGForCalcScreen;
