import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { Animated } from "react-native";
import { BackButton } from "../../components/atoms";
import {
  AllowExtendedBolusScreen,
  CorrectionFactorFirstSegmentScreen,
  CorrectionFactorFTSScreen,
  CorrectionFactorSecondSegmentScreen,
  CorrectionFactorSegmentsConfirmScreen,
  DurationOfInsulinActionScreen,
  ICRatioFirstSegmentScreen,
  ICRatioFTSScreen,
  ICRatioSecondSegmentScreen,
  ICRatioSegmentsConfirmScreen,
  IntroBolusFTSScreen,
  SetMaxBolusScreen,
  TargetBGFirstSegmentScreen,
  TargetBGFTSScreen,
  TargetBGSecondSegmentScreen,
  TargetBGSegmentsConfirmScreen
} from "../../components/screens";
import useLanguageContent from "../../hooks/useLanguageContent";
import theme from "../../theme";
import { strings } from "./BolusFTSStackContent";
import { navigationActions, NavState } from "../../../../web/store/reducers/navigation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../web/store/store";
import { isEqual } from "lodash";

const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const BolusFTSStack: React.FC = () => {
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const headerRef = new Animated.ValueXY();
  const { languageContent } = useLanguageContent(strings);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      navigationActions.navigationUpdate({ navigateTo: "BolusFTS" }, navState)
    );
  }, []);

  const animateHeader = React.useCallback(() => {
    Animated.timing(headerRef, {
      toValue: 0,
      duration: 600,
      useNativeDriver: false
    }).start();
  }, [headerRef]);

  React.useEffect(() => {
    animateHeader();
  }, [animateHeader]);

  return (
    <>
      <Stack.Navigator initialRouteName="IntroBolusFTS" mode="card">
        <Stack.Screen
          name="IntroBolusFTS"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={IntroBolusFTSScreen}
        />
        <Stack.Screen
          name="TargetBGFTS"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={TargetBGFTSScreen}
        />
        <Stack.Screen
          name="FirstSegmentTargetBG"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={TargetBGFirstSegmentScreen}
        />
        <Stack.Screen
          name="SecondSegmentTargetBG"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={TargetBGSecondSegmentScreen}
        />
        <Stack.Screen
          name="TargetBGSegmentsConfirm"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={TargetBGSegmentsConfirmScreen}
        />
        <Stack.Screen
          name="ICRatioFTS"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={ICRatioFTSScreen}
        />
        <Stack.Screen
          name="ICRatioFirstSegment"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={ICRatioFirstSegmentScreen}
        />
        <Stack.Screen
          name="ICRatioSecondSegment"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={ICRatioSecondSegmentScreen}
        />
        <Stack.Screen
          name="ICRatioSegmentsConfirm"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={ICRatioSegmentsConfirmScreen}
        />
        <Stack.Screen
          name="CorrectionFactorFTSScreen"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={CorrectionFactorFTSScreen}
        />
        <Stack.Screen
          name="CorrectionFactorFirstSegment"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={CorrectionFactorFirstSegmentScreen}
        />
        <Stack.Screen
          name="CorrectionFactorSecondSegment"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={CorrectionFactorSecondSegmentScreen}
        />
        <Stack.Screen
          name="CorrectionFactorSegmentsConfirm"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={CorrectionFactorSegmentsConfirmScreen}
        />
        <Stack.Screen
          name="DurationOfInsulinAction"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={DurationOfInsulinActionScreen}
        />
        <Stack.Screen
          name="SetMaxBolus"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={SetMaxBolusScreen}
        />
        <Stack.Screen
          name="AllowExtendedBolus"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBolus}
                white={false}
                isFTS
              />
            )
          }}
          component={AllowExtendedBolusScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default BolusFTSStack;
