import { isEqual } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "web/store/store";

function useLanguageContent(strings: any) {
  const appConfig = useSelector(
    (state: RootState) => state.configurator,
    isEqual
  );

  const [languageContent, setLanguageContent] = React.useState<any>(
    strings["en-US"]
  );

  React.useEffect(() => {
    setLanguageContent(strings[appConfig.language]);
  }, [appConfig.language]);

  return { languageContent };
}
export default useLanguageContent;
