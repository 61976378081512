import React from "react";
import {
  Dimensions,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import TempBasalGraph from "../../../../../../../assets/temp_basal_graph.png";
import TempBasalGraph24 from "../../../../../../../assets/temp_basal_graph_24.png";
import TempBasalGraph24Comma from "../../../../../../../assets/temp_basal_graph_german.png";
import theme from "../../../../../theme";
import fonts from "../../../../../theme/fonts";
import { Button } from "../../../../atoms";
import { BottomInnerNav } from "../../../../molecules";
import { strings } from "./SetTempBasalScreenContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../web/store/store";
import { isEqual } from "lodash";

const SetTempBasalScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ backgroundColor: "white", flex: 1 }}>
      <View
        style={{
          height: 36,
          width: "100%",
          marginTop: 3,
          backgroundColor: theme.colors.white,
          justifyContent: "center",
          shadowColor: "#888",
          shadowOpacity: 0.4,
          shadowRadius: 3,
          shadowOffset: {
            height: 3,
            width: 0
          },
          elevation: 8,
          zIndex: 0
        }}>
        <Text
          style={{
            ...fonts.text400,
            alignSelf: "flex-end",
            paddingRight: 16
          }}>
          {languageContent.graph}
        </Text>
      </View>
      <Image
        resizeMode="contain"
        style={{
          width: langState.is24Hour ? "95%" : "100%",
          marginLeft: langState.is24Hour ? "7px" : "0",
          height: 161,
          marginTop: -20
        }}
        source={
          langState.is24Hour
            ? langState.locale === "de-DE"
              ? (TempBasalGraph24Comma as any)
              : (TempBasalGraph24 as any)
            : (TempBasalGraph as any)
        }
      />
      <View
        style={[
          styles.innerWrapper,
          {
            borderTopColor: theme.colors.grayScale.gray800,
            borderTopWidth: 2,
            marginBottom: 8,
            width: "100%"
          }
        ]}>
        <View>
          <Text style={[theme.fonts.h4, styles.inputLabel]}>
            {languageContent.basalRate}
          </Text>
          <Text style={[theme.fonts.body2, styles.units]}>
            {languageContent.upTo}
          </Text>
          <TouchableOpacity
            style={[theme.layout.inputContainerStyle]}
            activeOpacity={1}
            disabled={true}>
            <View style={[theme.layout.inputStyle]}>
              <Text
                style={{
                  fontSize: 32,
                  color: theme.colors.placeholder
                }}>
                ––
              </Text>
            </View>
            <Text
              style={[
                theme.layout.adornment,
                { color: theme.colors.placeholder }
              ]}>
              %
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{ marginTop: 16 }}>
          <Text style={[theme.fonts.h4, styles.inputLabel]}>
            {languageContent.duration}
          </Text>
          <Text style={[theme.fonts.body2, styles.units]}>
            {languageContent.min}
          </Text>
          <TouchableOpacity
            style={[theme.layout.inputContainerStyle]}
            activeOpacity={1}
            disabled={true}>
            <View style={[theme.layout.inputStyle]}>
              <Text
                style={{
                  fontSize: 32,
                  color: theme.colors.placeholder
                }}>
                ––
              </Text>
            </View>
            <Text
              style={[
                theme.layout.adornment,
                { color: theme.colors.placeholder }
              ]}>
              {languageContent.hrs}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{ marginTop: 30 }}>
          <Button variant="default" onPress={() => {}}>
            {languageContent.presets}
          </Button>
        </View>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.leftActionText}
        leftAction={true}
        leftActionNavigateTo="Drawer"
        rightActionDisabled
        rightActionStyle={{ color: theme.colors.placeholder }}
        rightActionText={languageContent.rightActionText}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    width: Dimensions.get("screen").width,
    paddingHorizontal: 16,
    paddingVertical: 8
  },
  inputLabelWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 8,
    paddingBottom: 8
  },
  inputContainerStyle: {
    flexDirection: "row",
    alignItems: "flex-end",
    borderWidth: 1,
    borderColor: theme.colors.textInputBorder,
    backgroundColor: theme.colors.textInputBackgroundColor,
    borderRadius: 5,
    paddingHorizontal: 8
  },
  inputLabel: {},
  inputLink: {
    textTransform: "uppercase",
    fontWeight: "700",
    color: theme.colors.purple
  },
  inputStyle: {
    fontSize: 40,
    width: "90%",
    textAlign: "left",
    textAlignVertical: "bottom",
    paddingHorizontal: 0,
    paddingVertical: 4,
    margin: 0,
    zIndex: 1
  },
  adornment: {
    alignSelf: "flex-end",
    fontSize: 18,
    marginLeft: 6,
    marginBottom: 8,
    color: theme.colors.placeholder
  },
  units: {
    color: theme.colors.grayScale.gray400,
    marginBottom: 4
  },
  break: {
    borderColor: "#eee",
    borderWidth: 1,
    marginVertical: 24
  }
});

export default SetTempBasalScreen;
