let strings = {
  "en-US": {
    settingBasalTitle: "Max Basal Rate",
    unitsPerHour: "%1 U/hr",
    fiveUnitsPerHour: "5 U/hr",
    settingTempTitle: "Temp Basal",
    configured: "Configured to use percent (%)",
    tap: "OFF. Tap to turn ON."
  },
  "en-GB": {
    settingBasalTitle: "Max Basal Rate",
    unitsPerHour: "%1 U/hr",
    fiveUnitsPerHour: "5 U/hr",
    settingTempTitle: "Temp Basal",
    configured: "Configured to use percent (%)",
    tap: "OFF. Tap to turn ON."
  },
  "de-DE": {
    settingBasalTitle: "Maximale Basalrate",
    unitsPerHour: "%1 E/Std",
    fiveUnitsPerHour: "5 E/Std",
    settingTempTitle: "Temporäre Basalrate",
    configured: "Auf Prozentwerte (%) konfiguriert",
    tap: "AUS. Zum Einschalten antippen."
  },
  "es-US": {
    settingBasalTitle: "Tasa basal máxima",
    unitsPerHour: "%1 U/h",
    fiveUnitsPerHour: "5 U/h",
    settingTempTitle: "Basal temporal",
    configured: "Configurado para usar porcentaje (%)",
    tap: "Apagado. Pulse el botón de alternancia para prender."
  },
  "fr-FR": {
    settingBasalTitle: "Débit basal max",
    unitsPerHour: "%1 U/h",
    fiveUnitsPerHour: "5 U/h",
    settingTempTitle: "Débit basal temporaire",
    configured: "Configuré pour utiliser un pourcentage (%)",
    tap: "Désactivé. Appuyez pour activer."
  },
  "nl-NL": {
    settingBasalTitle: "Maximale basaalsnelheid",
    unitsPerHour: "%1 E/u.",
    fiveUnitsPerHour: "5 E/u.",
    settingTempTitle: "Tijdelijke basaalsnelheid",
    configured: "Ingesteld voor gebruik percentage (%)",
    tap: "UIT. Tik om AAN te zetten."
  }
};

export { strings };
