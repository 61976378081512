import { defineMessages } from 'react-intl';

export const startBasalProgramMessages = defineMessages({
  title: {
    id: 'ios.modals.start-basal-program.title',
    defaultMessage: 'Basal Programs'
  },
  body: {
    id: 'ios.modals.start-basal-program.body',
    defaultMessage: 'Start the {programName} Basal Program?'
  },
  cancel: {
    id: 'ios.modals.start-basal-program.cancel',
    defaultMessage: 'Cancel'
  },
  start: {
    id: 'ios.modals.start-basal-program.start',
    defaultMessage: 'Start'
  },
})

export const pauseInsulinMessages = defineMessages({
  title: {
    id: 'ios.modals.pause-insulin.title',
    defaultMessage: 'Pause Insulin?'
  },
  body: {
    id: 'ios.modals.pause-insulin.body',
    defaultMessage: 'You must pause insulin to edit the Basal Program in progress.{br}Pausing insulin cancels any temp basal or extended bolus currently in progress.'
  },
  no: {
    id: 'ios.modals.pause-insulin.no',
    defaultMessage: 'No'
  },
  yes: {
    id: 'ios.modals.pause-insulin.yes',
    defaultMessage: 'Yes'
  },
});

export const genericBasalProgramsModalMessages = defineMessages({
  title: {
    id: 'ios.modals.generic-basal-programs.title',
    defaultMessage: 'Basal Programs'
  },
  insulinPaused: {
    id: 'ios.modals.generic-basal-programs.insulin-paused',
    defaultMessage: 'Insulin paused'
  },
  ok: {
    id: 'ios.modals.generic-basal-programs.ok',
    defaultMessage: 'OK'
  },
});

export const startActiveBasalProgramModalMessages = defineMessages({
  title: {
    id: 'ios.modals.start-active-basal-program.title',
    defaultMessage: 'Start Insulin?'
  },
  changesSaved: {
    id: 'ios.modals.start-active-basal-program.changes-saved',
    defaultMessage: '{programName} changes are saved.{br}You can start this Basal Program now or get a reminder in 30 minutes.'
  },
  changesNotSaved: {
    id: 'ios.modals.start-active-basal-program.changes-not-saved',
    defaultMessage: 'Changes to {programName} were not saved.{br}You can start this unchanged Basal Program now or get a reminder in 30 minutes.'
  },
  notNow: {
    id: 'ios.modals.start-active-basal-program.not-now',
    defaultMessage: 'Not Now'
  },
  startInsulin: {
    id: 'ios.modals.start-active-basal-program.start-insulin',
    defaultMessage: 'Start Insulin'
  },
})

export const startNewBasalProgramModalMessages = defineMessages({
  title: {
    id: 'ios.modals.start-new-basal-program.title',
    defaultMessage: 'Start {programName} basal program?'
  },
  body: {
    id: 'ios.modals.start-new-basal-program.body',
    defaultMessage: 'Your changes have been saved.'
  },
  notNow: {
    id: 'ios.modals.start-new-basal-program.not-now',
    defaultMessage: 'Not Now'
  },
  start: {
    id: 'ios.modals.start-new-basal-program.start',
    defaultMessage: 'Start'
  },
});

export const deleteBasalProgramModalMessages = defineMessages({
  title: {
    id: 'ios.modals.delete-basal-program.title',
    defaultMessage: 'Delete {programName}?'
  },
  body: {
    id: 'ios.modals.delete-basal-program.body',
    defaultMessage: 'Are you sure you want to delete the {programName} Program? (This cannot be undone.)'
  },
  cancel: {
    id: 'ios.modals.delete-basal-program.cancel',
    defaultMessage: 'Cancel'
  },
  delete: {
    id: 'ios.modals.delete-basal-program.delete',
    defaultMessage: 'Delete'
  },
});

export const cancelEditBasalProgramModalMessages = defineMessages({
  bodyEdit: {
    id: 'ios.modals.cancel-edit-basal-program.body-edit',
    defaultMessage: 'Cancel editing this Basal Program?'
  },
  bodyCreate: {
    id: 'ios.modals.cancel-edit-basal-program.body-create',
    defaultMessage: 'Cancel creating this Basal Program?'
  },
});

export const backToPreviousScreenModalMessages = defineMessages({
  title: {
    id: 'ios.modals.back-to-previous-screen.title',
    defaultMessage: 'Go back to previous screen?'
  },
  body: {
    id: 'ios.modals.back-to-previous-screen.body',
    defaultMessage: 'You will lose any changes you made on the current screen if you go back.'
  },
  cancel: {
    id: 'ios.modals.back-to-previous-screen.cancel',
    defaultMessage: 'Cancel'
  },
  yes: {
    id: 'ios.modals.back-to-previous-screen.yes',
    defaultMessage: 'Yes, Go Back'
  },
});
