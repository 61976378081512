import { StackActions, useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { BackHandler, ScrollView, StyleSheet, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import useExpertHardwareBackAction from "../../../../../../hooks/useExpertHardwareBackAction";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { bolusActions } from "../../../../../../../../web/store/reducers/bolus";
import { BolusState } from "../../../../../../../../web/store/reducers/bolus/reducer";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { ListHeader } from "../../../../../atoms";
import { BottomInnerNav, ConfirmationModal } from "../../../../../molecules";
import SegmentListItem from "../../../../../molecules/segmentListItem";
import { strings } from "./ExpertReviewCorrectionFactorScreenContent";
import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat";
import defaultBasalReduxData from "../../../../../../data/defaultBasalReduxData";

const ExpertReviewCorrectionFactorScreen: React.FC = () => {
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [cancelModal, setCancelModal] = React.useState<boolean>(false);

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);
  const backAction = useExpertHardwareBackAction(navigation, "BolusCF");
  const editPressed = React.useRef(false);

  const handleEdit = (index: number) => {
    const editEndHelpText =
      bolusState.newExpertBolusProgram.correctionFactorSegments[index]
        .endHelpText;
    dispatch(
      bolusActions.bolusUpdate(
        {
          newExpertBolusProgram: {
            ...bolusState.newExpertBolusProgram,
            correctionFactorSegments:
              bolusState.newExpertBolusProgram.correctionFactorSegments.slice(
                0,
                index
              )
          }
        },
        bolusState
      )
    );

    if (bolusState.newExpertBolusProgram.isComplete) {
      navigation.navigate("ExpertCorrectionFactorSegment", {
        editEndTime:
          bolusState.newExpertBolusProgram.correctionFactorSegments[
            index
          ].end.toUpperCase(),
        editEndHelpText,
        editIndex: index
      });
    } else {
      navigation.dispatch(
        StackActions.pop(
          bolusState.newExpertBolusProgram.correctionFactorSegments.length -
            index
        )
      );
    }
  };

  React.useEffect(
    () =>
      navigation.addListener("beforeRemove", (e) => {
        if (e.data.action.type === "POP" && !editPressed.current) {
          dispatch(
            bolusActions.bolusUpdate(
              {
                newExpertBolusProgram: {
                  ...bolusState.newExpertBolusProgram,
                  correctionFactorSegments:
                    bolusState.newExpertBolusProgram.correctionFactorSegments.slice(
                      bolusState.newExpertBolusProgram.correctionFactorSegments
                        .length - 1
                    )
                }
              },
              bolusState
            )
          );
        }
      }),
    [navigation]
  );
  React.useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", backAction);

    return () =>
      BackHandler.removeEventListener("hardwareBackPress", backAction);
  }, []);

  return (
    <View style={[styles.container]}>
      <ScrollView>
        <Text
          style={[
            theme.fonts.body1,
            { marginVertical: 24, paddingHorizontal: 16 }
          ]}>
          {languageContent.reviewBody}
        </Text>
        <ListHeader
          numOfSegments={String(
            bolusState.newExpertBolusProgram.correctionFactorSegments.length
          )}
        />
        {bolusState.newExpertBolusProgram.correctionFactorSegments.map(
          (node, index) => (
            <SegmentListItem
              onEditPress={() => {
                editPressed.current = true;
                handleEdit(index);
              }}
              key={String(index)}
              index={index}
              segmentLabel={`${languageContent.segment} ${index + 1}:`}
              startTime={
                getCurrentHourFormat({
                  timeStr: node.start,
                  is24Hour: langState.is24Hour
                })!
              }
              endTime={
                getCurrentHourFormat({
                  timeStr: node.end,
                  is24Hour: langState.is24Hour
                })!
              }
              firstRateValue={languageContent.firstRateValue}
              secondRateValue={`${bolusState.newExpertBolusProgram.correctionFactorSegments[
                index
              ].oneUnitCorrectionFactor.toLocaleString(langState.locale)} ${
                langState.units
              }`}
            />
          )
        )}
      </ScrollView>
      <BottomInnerNav
        leftActionStyle={{ color: theme.colors.text.cancel }}
        leftActionText={languageContent.cancel}
        leftAction
        leftNavigationAction={() => setCancelModal(true)}
        rightActionText={languageContent.save}
        rightAction={() => navigation.navigate("DurationOfInsulinAction")}
        rightActionStyle={{ color: theme.colors.purple }}
      />
      <ConfirmationModal
        title={languageContent.cancelModalTitle}
        isVisible={cancelModal}
        onDismiss={() => setCancelModal(false)}
        onConfirm={() => {
          setCancelModal(false);
          dispatch(
            bolusActions.bolusUpdate(
              {
                newExpertBolusProgram: {
                  ...bolusState.newExpertBolusProgram,
                  correctionFactorSegments:
                    defaultBasalReduxData.correctionFactorSegments
                }
              },
              bolusState
            )
          );
          navigation.navigate("CorrectionFactorFTSScreen");
        }}
        message={
          <View>
            <Text style={[theme.fonts.body1, { marginBottom: 8 }]}>
              {languageContent.cancelModalBody}
            </Text>
          </View>
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between"
  },
  segmentHeader: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.timeSegmentsBorder,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    backgroundColor: "#EDF2F6"
  },
  segmentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    marginVertical: 16
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.divider
  }
});
export default ExpertReviewCorrectionFactorScreen;
