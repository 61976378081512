import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'web/store/store';

export const selectIsExtendedBolusOn = createSelector(
  (state: RootState) => state.bolus,
  (bolus) => {
    return bolus.extendedBolus;
  }
);

export const selectIsReverseCorrectionOn = createSelector(
  (state: RootState) => state.bolus,
  (bolus) => {
    return bolus.reverseCorrection;
  }
);

export const selectIsBolusDeliveryInProgress = createSelector(
  (state: RootState) => state.bolus,
  (bolus) => bolus.bolusDelivery.isInProgress
);

export const selectBolusDeliveryData = createSelector(
  (state: RootState) => state.bolus,
  ({
    bolusDelivery: {
      carbs,
      glucose,
      mealBolus,
      correctionBolus,
      extendedBolus,
      totalBolus,
      sensorUsed,
      currentTime,
      isInProgress,
      deliveryQty,
      isExtended,
      deliveryTime,
    },
  }) => ({
    carbs,
    glucose,
    mealBolus,
    correctionBolus,
    extendedBolus,
    totalBolus,
    sensorUsed,
    currentTime,
    isInProgress,
    deliveryQty,
    isExtended,
    deliveryTime,
  })
);
