import React from "react";
import { Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./LowPodInsulinScreenContent";

const LowPodInsulinScreen: React.FC = () => {
  const [duration, setDuration] = React.useState<string>();
  const [isPressed, setIsPressed] = React.useState<boolean>(false);

  const { languageContent } = useLanguageContent(strings);

  const getAdornmentColor = () => {
    if (duration === "––") {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  return (
    <View style={{ backgroundColor: "white", flex: 1 }}>
      <View style={[theme.layout.wrapper, { marginTop: 16, marginBottom: 24 }]}>
        <View style={[styles.container]}>
          <Text style={[styles.durationHeader]}>
            {languageContent.lowPodInsulin}
          </Text>
          <Text style={[styles.subHeader]}>{languageContent.range}</Text>
          <>
            <Pressable
              style={[theme.layout.inputContainerStyle, styles.pressable]}
              disabled={isPressed}
              onPress={() => {
                setDuration("10");
                setIsPressed(true);
              }}>
              <TextInput
                editable={false}
                style={[theme.layout.inputStyle, styles.textInput]}
                selectionColor={theme.colors.blue}
                placeholder={"––"}
                placeholderTextColor={theme.colors.placeholder}
                value={duration}
              />
              <Text
                style={[
                  theme.layout.adornment,
                  {
                    color: getAdornmentColor()
                  }
                ]}>
                {languageContent.units}
              </Text>
            </Pressable>
          </>
          <Text style={[styles.mainText]}>{languageContent.notify}</Text>
        </View>
        <Text
          style={[
            theme.layout.adornment,
            {
              color: getAdornmentColor()
            }
          ]}>
          {languageContent.units}
        </Text>
      </View>
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.leftActionText}
        leftActionNavigateTo="Drawer"
        leftActionStyle={{ color: theme.colors.text.cancel, fontSize: 16 }}
        rightActionText={languageContent.rightActionText}
        rightActionStyle={
          isPressed
            ? [styles.actionStyle, { color: "#5B34B9" }]
            : styles.actionStyle
        }
        rightActionNavigateTo="default"
        rightActionDisabled={!isPressed}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  mainText: {
    ...theme.fonts.text400,
    fontSize: 14,
    fontWeight: "400",
    marginBottom: 24,
    marginTop: 24
  },
  actionStyle: {
    color: theme.colors.text.cancel,
    fontSize: 16
  },
  container: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    height: 158,
    zIndex: -1
  },
  durationHeader: {
    ...theme.fonts.h5
  },
  textInput: {
    width: 220,
    marginBottom: -2,
    borderColor: "black"
  },
  subHeader: {
    ...theme.fonts.text400,
    color: theme.colors.text.grayText,
    marginBottom: 6,
    fontWeight: "400"
  },
  pressable: {
    borderColor: theme.colors.textInputBorder,
    width: 270,
    height: 60,
    marginTop: 8
  }
});

export default LowPodInsulinScreen;
