import { StackActions, useNavigation } from "@react-navigation/native"
import { isEqual } from "lodash"
import React from "react"
import { BackHandler, ScrollView, StyleSheet, Text, View } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import defaultBasalReduxData from "../../../../../../data/defaultBasalReduxData"
import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat"
import useExpertHardwareBackAction from "../../../../../../hooks/useExpertHardwareBackAction"
import useLanguageContent from "../../../../../../hooks/useLanguageContent"
import { bolusActions } from "../../../../../../../../web/store/reducers/bolus"
import { BolusState } from "../../../../../../../../web/store/reducers/bolus/reducer"
import { LangState } from "../../../../../../../../web/store/reducers/language"
import { RootState } from "../../../../../../../../web/store/store"
import theme from "../../../../../../theme"
import { ListHeader } from "../../../../../atoms"
import { BottomInnerNav, ConfirmationModal } from "../../../../../molecules"
import SegmentListItem from "../../../../../molecules/segmentListItem"
import { strings } from "./ExpertBolusReviewSegmentScreenContent"
const ExpertBolusReviewSegmentScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual,
  )
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual,
  )

  const editPressed = React.useRef(false)
  const [cancelModal, setCancelModal] = React.useState<boolean>(false)

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const { languageContent } = useLanguageContent(strings)
  const backAction = useExpertHardwareBackAction(navigation, "BolusTG")

  const handleEditPress = async (index: number) => {
    dispatch(
      bolusActions.bolusUpdate(
        {
          newExpertBolusProgram: {
            ...bolusState.newExpertBolusProgram,
            segments: bolusState.newExpertBolusProgram.segments.slice(0, index),
          },
        },
        bolusState,
      ),
    )

    if (bolusState.newExpertBolusProgram.isComplete) {
      navigation.navigate("BolusSegments", {
        editEndTime:
          bolusState.newExpertBolusProgram.segments[index].end.toUpperCase(),
        editIndex: index,
      })
    } else {
      navigation.dispatch(
        StackActions.pop(
          bolusState.newExpertBolusProgram.segments.length - index,
        ),
      )
    }
  }

  React.useEffect(
    () =>
      navigation.addListener("beforeRemove", (e) => {
        if (e.data.action.type === "POP" && !editPressed.current) {
          dispatch(
            bolusActions.bolusUpdate(
              {
                newExpertBolusProgram: {
                  ...bolusState.newExpertBolusProgram,
                  segments: bolusState.newExpertBolusProgram.segments.slice(
                    0,
                    bolusState.newExpertBolusProgram.segments.length - 1,
                  ),
                },
              },
              bolusState,
            ),
          )
        }
      }),
    [navigation],
  )

  React.useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", backAction)

    return () =>
      BackHandler.removeEventListener("hardwareBackPress", backAction)
  }, [navigation])

  return (
    <View style={[styles.container]}>
      <ScrollView>
        <Text
          style={[
            theme.fonts.body1,
            { marginVertical: 24, paddingHorizontal: 16 },
          ]}
        >
          {languageContent.body}
        </Text>
        <ListHeader
          numOfSegments={String(
            bolusState.newExpertBolusProgram.segments.length,
          )}
        />
        {bolusState.newExpertBolusProgram.segments.map((node, index) => (
          <SegmentListItem
            onEditPress={() => {
              editPressed.current = true
              handleEditPress(index)
            }}
            key={String(index)}
            index={index}
            segmentLabel={`${languageContent.segmentText} ${index + 1}:`}
            startTime={
              getCurrentHourFormat({
                timeStr: node.start,
                is24Hour: langState.is24Hour,
              })!
            }
            endTime={
              getCurrentHourFormat({
                timeStr: node.end,
                is24Hour: langState.is24Hour,
              })!
            }
            firstRateOrBoldedLabel={languageContent.firstRateText}
            firstRateValue={`${node.targetBG.toLocaleString(
              langState.locale,
            )} ${langState.units}`}
            secondRateOrBoldedLabel={languageContent.secondRateText}
            secondRateValue={`${node.correctAbove.toLocaleString(
              langState.locale,
            )} ${langState.units}`}
          />
        ))}
      </ScrollView>

      <BottomInnerNav
        leftActionStyle={{ color: theme.colors.text.cancel }}
        leftActionText={languageContent.leftActionText}
        leftAction
        leftNavigationAction={() => setCancelModal(true)}
        rightActionText={languageContent.rightActionText}
        rightActionNavigateTo={"ICRatioFTS"}
        rightActionStyle={{ color: theme.colors.purple }}
      />

      <ConfirmationModal
        title={languageContent.cancelConfirmationModalText}
        isVisible={cancelModal}
        onDismiss={() => setCancelModal(false)}
        onConfirm={() => {
          setCancelModal(false)
          dispatch(
            bolusActions.bolusUpdate(
              {
                newExpertBolusProgram: defaultBasalReduxData,
              },
              bolusState,
            ),
          )
          navigation.navigate("IntroBolusFTS")
        }}
        message={
          <View>
            <Text style={[theme.fonts.body1, { marginBottom: 8 }]}>
              {languageContent.cancelModalMessage}
            </Text>
          </View>
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
  },
  segmentHeader: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.timeSegmentsBorder,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    backgroundColor: "#EDF2F6",
  },
  segmentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    marginVertical: 16,
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.divider,
  },
})

export default ExpertBolusReviewSegmentScreen
