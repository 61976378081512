import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { Button } from "../../../../atoms";
import { strings } from "./ClearAllDataReadyScreenContext";

const ClearAllDataReadyScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={[styles.container]}>
      <View style={[styles.warningMessageBarView]}>
        <View>
          <Text style={styles.WarningMessageBarText}>
            {languageContent.noActive}
          </Text>
        </View>
      </View>
      <Text style={[styles.subtext]}>{languageContent.clearData}</Text>
      <Text style={[styles.subtext]}>{languageContent.subtext}</Text>
      <Button
        variant={"primary"}
        disabled={false}
        style={{ elevation: 6, marginTop: 12, width: "90%", marginLeft: 14 }}
        onPress={() => {
          navigate("FTS");
        }}>
        {languageContent.reset}
      </Button>
    </View>
  );
};
const styles = StyleSheet.create({
  subtext: {
    ...theme.fonts.body2,
    fontWeight: "400",
    marginLeft: 16,
    marginTop: 16,
    marginBottom: 16
  },
  container: {
    backgroundColor: "white",
    height: "100%"
  },
  subContainer: {
    backgroundColor: "#EDF2F6"
  },
  warningMessageBarView: {
    flexDirection: "row",
    backgroundColor: theme.colors.yellow,
    alignItems: "flex-end",
    justifyContent: "center",
    paddingTop: 4,
    paddingBottom: 8,
    borderRadius: 5
  },
  WarningMessageBarText: {
    color: theme.colors.text.cancel,
    fontSize: 14,
    fontWeight: "500",
    lineHeight: 16,
    marginTop: 2
  }
});
export default ClearAllDataReadyScreen;
