import { useNavigation } from "@react-navigation/native";
import { decode } from "html-entities";
import { isEqual } from "lodash";
import React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  Image,
  Linking,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View
} from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Arc } from "../../../../../../assets/arcs/mango_arc.svg";
import { ReactComponent as Contact } from "../../../../../../assets/homeMenu/homemenu_contact.svg";
import { ReactComponent as Info } from "../../../../../../assets/homeMenu/homemenu_info.svg";
import Logo from "../../../../../../assets/Omnipod_5_Simulator_LOGO_MANGO.png";
import { ReactComponent as BlackArrow } from "../../../../../../assets/UI_BackArrow.svg";
import replaceWithValues from "../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../web/store/reducers/language";
import { userActions, UserState } from "../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../web/store/store";
import theme from "../../../../theme";
import fonts from "../../../../theme/fonts";
import { ConfirmationModal } from "../../../molecules";
import { strings } from "./MasterNavScreenContent";
const regTM = decode("&reg;");

const CONTACT = "https://na.myomnipod.com/contact";
// const ABOUT = "https://omnipod.com/about-insulet";
const WHY_OMNIPOD = "https://www.omnipod.com/what-is-omnipod/omnipod-5";

interface Props {
  disableClose?: boolean;
}

const MasterNavigationScreen: React.FC<Props> = ({ disableClose }) => {
  //GLOBAL STATE
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  //LOCAL STATE
  const [expertModeModal, setExpertModeModal] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const { goBack, navigate } = useNavigation();
  const { height, width, scale } = useWindowDimensions();

  const { languageContent } = useLanguageContent(strings);

  //FUNCTIONS
  let hideCloseButton = disableClose !== undefined ? disableClose : false;

  const setSelectableMode = () =>
    userState.isExpert ? languageContent.guided : languageContent.selfGuided;

  const contentVariables = [setSelectableMode()];
  //HOOKS
  const toggleExpertMode = () => {
    dispatch(
      userActions.userUpdate({ isExpert: !userState.isExpert }, userState)
    );
  };
  //LIFECYCLE
  const hideAllModals = React.useCallback(() => {
    if (!userState.hideAllModals) {
      dispatch(userActions.userUpdate({ hideAllModals: true }, userState));
    }
  }, [dispatch, userState]);

  const unhideAllModals = React.useCallback(() => {
    if (userState.hideAllModals) {
      dispatch(userActions.userUpdate({ hideAllModals: false }, userState));
    }
  }, [dispatch, userState]);

  React.useEffect(() => {
    if (process.env.JEST_WORKER_ID !== undefined) {
      return;
    }
  }, []);

  React.useEffect(() => {
    hideAllModals();

    return () => {
      unhideAllModals();
    };
  }, [hideAllModals, unhideAllModals]);

  return (
    <>
      <SafeAreaView
        style={{
          flex: 0
        }}
      />
      <View style={{ flex: 1, zIndex: 999 }}>
        <View
          pointerEvents="box-none"
          style={{ marginTop: 24, width: 100, zIndex: 999 }}>
          {hideCloseButton ? null : (
            <TouchableOpacity borderRadius={35} onPress={() => goBack()}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 34,
                  marginHorizontal: 8
                }}>
                <BlackArrow />
                <Text
                  style={{
                    ...theme.fonts.text200,
                    color: theme.colors.modalPurple,
                    alignSelf: "center",
                    paddingLeft: 4
                  }}>
                  {languageContent.back}
                </Text>
              </View>
            </TouchableOpacity>
          )}
        </View>
        <Image
          resizeMode="contain"
          source={Logo}
          style={{
            flex: 1,
            width: width / 1.6,
            height: height / 4,
            marginVertical: -90,
            alignSelf: "center"
          }}
        />
        <View
          style={[
            {
              position: "relative",
              flexDirection: "column",
              flex: 1,
              alignItems: "center",
              marginTop: 16
            }
          ]}>
          <View style={styles.purpleButton}>
            <TouchableOpacity
              borderRadius={35}
              onPress={() => {
                navigate("TourMenu");
              }}>
              <Text style={[styles.navLink, { color: theme.colors.white }]}>
                {languageContent.howItWorks}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.purpleButton}>
            <TouchableOpacity
              borderRadius={35}
              onPress={() => {
                navigate("SimSplash");
              }}>
              <Text style={[styles.navLink, { color: theme.colors.white }]}>
                {languageContent.trySimulator}
              </Text>
            </TouchableOpacity>
          </View>
          <>
            <View style={styles.purpleOutlineButton}>
              <TouchableOpacity
                borderRadius={35}
                onPress={() => {
                  Linking.openURL(WHY_OMNIPOD).catch((err: string) =>
                    console.error("Couldn't load page", err)
                  );
                }}>
                <Text
                  style={[
                    styles.navLink,
                    {
                      color: theme.colors.modalPurple
                    }
                  ]}>
                  {languageContent.whyOmnipod}
                  <Text
                    style={{
                      ...theme.fonts.superscript
                    }}>
                    {`${regTM} `}
                  </Text>
                  5
                </Text>
              </TouchableOpacity>
            </View>
            <View style={styles.purpleOutlineButton}>
              <TouchableOpacity
                borderRadius={35}
                onPress={() => {
                  navigate("HelpfulLinks");
                }}>
                <Text
                  style={[styles.navLink, { color: theme.colors.modalPurple }]}>
                  {languageContent.helpfulLinks}
                </Text>
              </TouchableOpacity>
            </View>
          </>
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onPress={() => {
                if (userState.isExpert) {
                  return toggleExpertMode();
                }
                setExpertModeModal(true);
              }}>
              <Text style={{ color: theme.colors.purple, fontWeight: "700" }}>
                {replaceWithValues(
                  languageContent.experienceMessage,
                  contentVariables
                )}
              </Text>
            </TouchableOpacity>
          </View>
          {/* DEVELOPER ONLY UI ITEMS */}
          {/* <View
            style={{ display: "flex", flexDirection: "row", marginBottom: 12 }}>
            <Text style={{ color: "black", marginRight: 16 }}>Spanish</Text>
            <Switch
              trackColor={{ false: "#767577", true: "#767577" }}
              thumbColor={langState.language === "en-US" ? "#f4f3f4" : "#f4f3f4"}
              ios_backgroundColor="#3e3e3e"
              onValueChange={toggleSwitch}
              value={langState.language === "en-US"}
            />
            <Text style={{ color: "black", marginLeft: 16 }}>English</Text>
          </View> */}
          {/* <View
            style={{ display: "flex", flexDirection: "row", marginBottom: 12 }}>
            <Text style={{ color: "black", marginRight: 16 }}>Demo</Text>
            <Switch
              trackColor={{
                false: theme.colors.mango,
                true: theme.colors.purple
              }}
              thumbColor={langState.language === "en-US" ? "#f4f3f4" : "#f4f3f4"}
              ios_backgroundColor={theme.colors.mango}
              onValueChange={toggleExpertMode}
              value={userState.isExpert}
            />
            <Text style={{ color: "black", marginLeft: 16 }}>Expert</Text>
          </View> */}
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          zIndex: 1,
          marginHorizontal: 16
        }}>
        <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
          <View style={{ marginBottom: Platform.OS === "ios" ? 16 : 8 }}>
            <TouchableOpacity
              borderRadius={35}
              onPress={() => {
                navigate("AboutScreen");
              }}>
              <Info height={50} />
            </TouchableOpacity>
          </View>
          <View
            style={{
              marginBottom: useSafeAreaInsets().bottom - 4,
              marginHorizontal: 16
            }}>
            <TouchableOpacity
              borderRadius={35}
              onPress={() => {
                Linking.openURL(CONTACT).catch((err: string) =>
                  console.error("Couldn't load page", err)
                );
              }}>
              <Contact height={50} />
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            alignSelf: "center",
            marginBottom: useSafeAreaInsets().bottom - 4,
            marginRight: 8
          }}>
          <Text style={styles.disclaimerText}>
            {languageContent.notMedDevice}
          </Text>
        </View>
      </View>
      <Arc style={{ position: "absolute" }} />
      <ConfirmationModal
        isVisible={expertModeModal}
        title={replaceWithValues(languageContent.modalHeader, contentVariables)}
        purple
        message={
          <View>
            <Text style={[theme.fonts.body1, { color: theme.colors.white }]}>
              {languageContent.switchModalMessage}
            </Text>
          </View>
        }
        dismissText={languageContent.modalDismiss}
        confirmText={languageContent.switch}
        onConfirm={() => {
          toggleExpertMode();
          setExpertModeModal(false);
        }}
        onDismiss={() => {
          setExpertModeModal(false);
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  navLink: {
    ...theme.fonts.h3,
    fontWeight: "500",
    marginVertical: 10,
    color: theme.colors.blue,
    alignSelf: "center"
  },
  purpleButton: {
    backgroundColor: theme.colors.modalPurple,
    width: "70%",
    borderRadius: 35,
    marginBottom: 16
  },
  purpleOutlineButton: {
    borderColor: theme.colors.modalPurple,
    backgroundColor: theme.colors.white,
    borderWidth: 2,
    width: "70%",
    borderRadius: 35,
    marginBottom: 16
  },
  disclaimerText: {
    ...fonts.text400,
    color: theme.colors.white,
    fontSize: 14,
    paddingTop: 24
  },
  expertModeText: {
    ...fonts.text400,
    color: theme.colors.grayScale.gray400,
    fontSize: 16,
    fontWeight: "700"
  }
});

export default MasterNavigationScreen;
