import { defineMessages } from 'react-intl';

export const confirmTempBasalMessages = defineMessages({
  text: {
    id: 'ios.modals.confirm-temp-basal.text',
    defaultMessage: 'Start this temp basal?',
  },
  rightButton: {
    id: 'ios.modals.confirm-temp-basal.right-button',
    defaultMessage: 'Start',
  },
  leftButton: {
    id: 'ios.modals.confirm-temp-basal.left-button',
    defaultMessage: 'Cancel',
  },
});

export const cancelTempBasalMessages = defineMessages({
  text: {
    id: 'ios.modals.cancel-temp-basal.text',
    defaultMessage: 'Cancel creating this temp basal?',
  },
  rightButton: {
    id: 'ios.modals.cancel-temp-basal.right-button',
    defaultMessage: 'Yes',
  },
  leftButton: {
    id: 'ios.modals.cancel-temp-basal.left-button',
    defaultMessage: 'No',
  },
});
