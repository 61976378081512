import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'ios.temp-basal-drawer.title',
    defaultMessage: 'Temp Basal',
  },
  decreaseText: {
    id: 'ios.temp-basal-drawer.decrease-text',
    defaultMessage: 'Decrease ↓30%',
  },
  lastUpdated: {
    id: 'ios.temp-basal-drawer.last-updated',
    defaultMessage: 'Updated Today at {date}',
  },
  status: {
    id: 'ios.temp-basal-drawer.status',
    defaultMessage: 'Temp On',
  },
  inProgress: {
    id: 'ios.temp-basal-drawer.in-progress',
    defaultMessage: 'IN PROGRESS ({remainingTime} left)',
  },
  cancelButton: {
    id: 'ios.temp-basal-drawer.cancel-button',
    defaultMessage: 'Cancel Temp Basal',
  },
});
