import { useIntl } from 'react-intl';
import { CarouselSlideLayout } from 'web/components/helpers/Layout/CarouselSlideLayout';
import { messages } from './ApplyStepScenes.messages';
import { StyleSheet, Text } from 'react-native';
import { ReactComponent as ApplyScreen1 } from 'assets/L2/HowToApplySensor_01.svg';
import { ReactComponent as ApplyScreen2 } from 'assets/L2/HowToApplySensor_02.svg';
import { ReactComponent as ApplyScreen3 } from 'assets/L2/HowToApplySensor_03.svg';
import { ReactComponent as ApplyScreen4 } from 'assets/L2/HowToApplySensor_04.svg';
import { ReactComponent as ApplyScreen5 } from 'assets/L2/HowToApplySensor_05.svg';
import { ReactComponent as ApplyScreen6 } from 'assets/L2/HowToApplySensor_06.svg';
import { ReactComponent as ApplyScreen7 } from 'assets/L2/HowToApplySensor_07.svg';
import { ReactComponent as ApplyScreen8 } from 'assets/L2/HowToApplySensor_08.svg';
import { ReactComponent as ApplyScreen9 } from 'assets/L2/HowToApplySensor_09.svg';
import { ReactComponent as ApplyScreen10 } from 'assets/L2/HowToApplySensor_10.svg';

const styles = StyleSheet.create({
  paragraph: {
    display: 'flex',
    marginTop: 16,
    textAlign: 'center',
    justifyContent: 'center',
  },
});

export const ApplyStep1 = () => {
  const intl = useIntl();
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneOne.title)}
      svg={<ApplyScreen1 width={300} style={{ backgroundColor: 'white' }} />}
      mainText={intl.formatMessage(messages.sceneOne.mainText)}
      subText={intl.formatMessage(messages.sceneOne.subText)}
    />
  );
};

export const ApplyStep2 = () => {
  const intl = useIntl();
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneTwo.title)}
      svg={<ApplyScreen2 />}
      mainText={intl.formatMessage(messages.sceneTwo.mainText)}
    />
  );
};
export const ApplyStep3 = () => {
  const intl = useIntl();
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneThree.title)}
      svg={<ApplyScreen3 />}
      mainText={intl.formatMessage(messages.sceneThree.mainText)}
    />
  );
};
export const ApplyStep4 = () => {
  const intl = useIntl();
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneFour.title)}
      svg={<ApplyScreen4 />}
      mainText={intl.formatMessage(messages.sceneFour.mainText)}
    />
  );
};
export const ApplyStep5 = () => {
  const intl = useIntl();
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneFive.title)}
      svg={<ApplyScreen5 />}
      mainText={intl.formatMessage(messages.sceneFive.mainText)}
    />
  );
};
export const ApplyStep6 = () => {
  const intl = useIntl();
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneSix.title)}
      svg={<ApplyScreen6 />}
      mainText={intl.formatMessage(messages.sceneSix.mainText)}
    />
  );
};
export const ApplyStep7 = () => {
  const intl = useIntl();
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneSeven.title)}
      svg={<ApplyScreen7 />}
      mainText={intl.formatMessage(messages.sceneSeven.mainText)}
    />
  );
};
export const ApplyStep8 = () => {
  const intl = useIntl();
  // @ts-ignore TS doesn't like this, but the code below is correct.
  const formattedMessage = intl.formatMessage(messages.sceneEight.mainText, {
    p: (chunks) => <Text style={styles.paragraph}>{chunks[0]}</Text>,
    p2: (chunks) => <Text style={styles.paragraph}>{chunks[0]}</Text>,
  });
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneEight.title)}
      svg={<ApplyScreen8 />}
      mainText={formattedMessage}
    />
  );
};
export const ApplyStep9 = () => {
  const intl = useIntl();
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneNine.title)}
      svg={<ApplyScreen9 />}
      mainText={intl.formatMessage(messages.sceneNine.mainText)}
    />
  );
};
export const ApplyStep10 = () => {
  const intl = useIntl();
  return (
    <CarouselSlideLayout
      title={intl.formatMessage(messages.sceneTen.title)}
      svg={<ApplyScreen10 />}
      mainText={intl.formatMessage(messages.sceneTen.mainText)}
    />
  );
};
