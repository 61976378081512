let strings = {
  "en-US": {
    title: "App Direct Disclosure",
    disclosureText:
      "By my electronic signature, (i) I certify that I am 18 years old or older OR that I am the parent or guardian of the minor child and (ii) I hereby direct Insulet Corporation to share my, or the minor child's",
    disclosureTextCont: "data via the",
    disclosureTextCont2: "application with",
    at: "at",
    disclosureSubtext:
      "(If you are under 18, any data sharing must be agreed to by your parent or guardian on your behalf.)",
    acceptTerms: "I accept the terms above.",
    continue: "continue",
    connecting: "Connecting...",
    sendingInvitation: "Sending Invitation...",
    allSet: "All Set!",
    invitationSent: "Your invitation has been sent.",
    invitationPending: "Invitation: Pending",
    confirmText: "ok"
  },
  "en-GB": {
    title: "App Direct Disclosure",
    disclosureText:
      "By my electronic signature, (i) I certify that I am 18 years old or older OR that I am the parent or guardian of the minor child and (ii) I hereby direct Insulet Corporation to share my, or the minor child’s",
    disclosureTextCont: "data via the",
    disclosureTextCont2: "application with",
    at: "at",
    disclosureSubtext:
      "(If you are under 18, any data sharing must be agreed to by your parent or guardian on your behalf.)",
    acceptTerms: "I accept the terms above.",
    continue: "continue",
    connecting: "Connecting...",
    sendingInvitation: "Sending Invitation...",
    allSet: "All Set!",
    invitationSent: "Your invitation has been sent.",
    invitationPending: "Invitation: Pending",
    confirmText: "ok"
  },
  "de-DE": {
    title: "Direkte Offenlegung in der App",
    disclosureText:
      "Durch meine elektronische Unterschrift (i) bestätige ich, dass ich 18 Jahre alt oder älter bin ODER dass ich der Elternteil oder Erziehungsberechtigte des minderjährigen Kindes bin und (ii) weise die Insulet Corporation hiermit an, meine Daten bzw. die meines minderjährigen Kindes",
    disclosureTextCont: "über die",
    disclosureTextCont2: "Anwendung mit",
    at: "bei",
    disclosureSubtext:
      "zu teilen (wenn Sie unter 18 Jahre alt sind, müssen Ihre Eltern oder Erziehungsberechtigten in Ihrem Namen der Datenweitergabe zustimmen).",
    acceptTerms: "Ich akzeptiere die oben stehenden Nutzungsbedingungen.",
    continue: "Weiter",
    connecting: "Verbindungsaufbau ...",
    sendingInvitation: "Einladung wird gesendet ...",
    allSet: "Fertig!",
    invitationSent: "Ihre Einladung wurde gesendet.",
    invitationPending: "Einladung: Ausstehend",
    confirmText: "OK"
  },
  "es-US": {
    title: "Divulgación de instrucciones de la aplicación",
    disclosureText:
      "Con mi firma electrónica: (i) declaro que tengo 18 años o más o que soy el padre, madre o tutor del menor de edad, y (ii) por el presente indico a Insulet Corporation que comparta mis datos o los datos del",
    disclosureTextCont: "menor usando la",
    disclosureTextCont2: "aplicación con",
    at: "en",
    disclosureSubtext:
      "(Si tiene menos de 18 años, su padre, madre o tutor deben aceptar en su nombre toda divulgación de datos).",
    acceptTerms: "Acepto los términos mencionados arriba.",
    continue: "Continuar",
    connecting: "Conectando...",
    sendingInvitation: "Enviando invitación...",
    allSet: "¡Listo!",
    invitationSent: "Se envió su invitación.",
    invitationPending: "Invitación: Pendiente",
    confirmText: "aceptar"
  },
  "fr-FR": {
    title: "Divulgation directe concernant l'application",
    disclosureText:
      "Par ma signature électronique, (i) je certifie que j'ai 18 ans ou plus OU que je suis le parent ou le tuteur de l'enfant mineur et (ii) j'autorise Insulet Corporation à partager mes données, ou celles de l'enfant mineur",
    disclosureTextCont: "via",
    disclosureTextCont2: "l'application avec",
    at: "à",
    disclosureSubtext:
      "(Si vous avez moins de 18 ans, le partage de données doit être autorisé par votre parent ou tuteur en votre nom.)",
    acceptTerms: "J'accepte les termes ci-dessus.",
    continue: "continuer",
    connecting: "Connexion...",
    sendingInvitation: "Envoi d'une invitation...",
    allSet: "Vous êtes paré(e) !",
    invitationSent: "Votre invitation a été envoyée.",
    invitationPending: "Invitation : En attente",
    confirmText: "ok"
  },
  "nl-NL": {
    title: "App Directe openbaarmaking",
    disclosureText:
      "Door mijn elektronische handtekening, (i) verklaar ik dat ik 18 jaar of ouder ben OF dat ik de ouder of voogd ben van het minderjarige kind en (ii) ik geef Insulet Corporation hierbij opdracht om mijn gegevens  of die van het minderjarige kind te delen.",
    disclosureTextCont: "via de",
    disclosureTextCont2: "applicatie met",
    at: "om",
    disclosureSubtext:
      "(Als u jonger bent dan 18 jaar, moet uw ouder of voogd namens u instemmen met het delen van gegevens).",
    acceptTerms: "Ik accepteer bovenstaande voorwaarden.",
    continue: "doorgaan",
    connecting: "Bezig met verbinden...",
    sendingInvitation: "Uitnodiging wordt verstuurd...",
    allSet: "Helemaal klaar!",
    invitationSent: "Uw uitnodiging is verstuurd.",
    invitationPending: "Uitnodiging: In afwachting",
    confirmText: "OK"
  }
};

export { strings };
