import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, useWindowDimensions, View } from "react-native";
import { useSelector } from "react-redux";
import { ReactComponent as BasalConfirmationImage } from "../../../../../../../../assets/basal-view-complete.svg";
import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { BasalState } from "../../../../../../../../web/store/reducers/basal";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { ListHeader } from "../../../../../atoms";
import { BottomInnerNav, SegmentTopBar } from "../../../../../molecules";
import SegmentListItem from "../../../../../molecules/segmentListItem";
import { strings } from "./BasalSegmentsConfirmScreenContent";

const BasalSegmentsConfirmScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );

  const { width } = useWindowDimensions();
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={styles.container}>
      <View>
        <SegmentTopBar
          programName={basalState.customProgramName}
          showRightUnits
        />
        <View style={[styles.break]} />
        <View
          style={{
            marginVertical: 16,
            width: width
          }}
        />
        <View style={{ marginHorizontal: 8 }}>
          <BasalConfirmationImage
            style={{
              alignSelf: "center"
            }}
            width={"100%"}
            height={"100%"}
          />
        </View>
        <Text
          style={[
            {
              textAlign: "center",
              marginVertical: 16,
              marginTop: 8,
              marginBottom: 24
            },
            theme.fonts.h4
          ]}>
          {languageContent.total}
        </Text>
        <ListHeader numOfSegments="2" />
        <SegmentListItem
          onEditPress={() => navigate("BasalSegmentOne")}
          index={1}
          segmentLabel={`${languageContent.segment} 1:`}
          startTime={String(
            getCurrentHourFormat({
              timeStr: "12:00 am",
              is24Hour: langState.is24Hour!
            })
          )}
          endTime={String(
            getCurrentHourFormat({
              timeStr: "8:00 am",
              is24Hour: langState.is24Hour
            })
          )}
          firstRateOrBoldedLabel={languageContent.basalRate}
          firstRateValue={`${(0.75).toLocaleString(langState.locale)} ${
            languageContent.rateValue
          }`}
        />

        <SegmentListItem
          onEditPress={() => navigate("BasalSegmentTwo")}
          index={2}
          segmentLabel={`${languageContent.segment} 2:`}
          startTime={String(
            getCurrentHourFormat({
              timeStr: "8:00 am",
              is24Hour: langState.is24Hour!
            })
          )}
          endTime={String(
            getCurrentHourFormat({
              timeStr: "12:00 am",
              is24Hour: langState.is24Hour
            })
          )}
          firstRateOrBoldedLabel={languageContent.basalRate}
          firstRateValue={`1 ${languageContent.rateValue}`}
        />
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.save}
        rightAction={() => navigate("TempBasalOn")}
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "#fff"
  },
  segmentHeader: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.timeSegmentsBorder,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    backgroundColor: "#EDF2F6"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.divider
  }
});

export default BasalSegmentsConfirmScreen;
