import { createSlice } from '@reduxjs/toolkit';

const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    showModal: false,
    currentModal: null,
  },
  reducers: {
    setCurrentModal: (state, action) => {
      const { modal } = action.payload;
      state.currentModal = modal;
    },
    showModal: (state) => {
      state.showModal = true;
    },
    hideModal: (state) => {
      state.showModal = false;
    },
  },
})

export const { setCurrentModal, showModal, hideModal } = modalsSlice.actions
export default modalsSlice.reducer;