let strings = {
  "en-US": {
    start: "Start",
    end: "End",
    am: "AM",
    oneUnitDecrease: "1 Unit of insulin lowers glucose by",
    correctionFactor: "Correction Factor",
    night: "Night",
    morning: "Morning",
    range: "(%1 to %2)",
    cancel: "Cancel",
    next: "Next",
    modalTitle: "Segment End Time",
    ok: "OK",
    okCamel: "Ok",
    modalBody:
      "For simulation purposes we have auto-filled this information for you.",
    minValue: "Minimum Value",
    maxValue: "Maximum Value",
    correctionFactorMin: "Correction factor cannot be less than 1\u00A0%3.",
    correctionFactorMax: "Correction factor cannot be more than 400\u00A0%3."
  },
  "en-GB": {
    start: "Start",
    end: "End",
    am: "AM",
    oneUnitDecrease: "1 Unit of Insulin lowers glucose by",
    correctionFactor: "Correction Factor",
    night: "Night",
    morning: "Morning",
    range: "(%1 to %2)",
    cancel: "Cancel",
    next: "Next",
    modalTitle: "Segment End Time",
    ok: "OK",
    okCamel: "Ok",
    modalBody:
      "For simulation purposes we have auto-filled this information for you.",
    minValue: "Minimum Value",
    maxValue: "Maximum Value",
    correctionFactorMin: "Correction factor cannot be less than 1\u00A0%3.",
    correctionFactorMax: "Correction factor cannot be more than 400\u00A0%3."
  },
  "de-DE": {
    start: "Start",
    end: "Ende",
    am: "AM",
    oneUnitDecrease: "1 Einheit Insulin senkt den Blutzucker um",
    correctionFactor: "Korrekturfaktor",
    night: "Nacht",
    morning: "Vormittag",
    range: "(%1 bis %2)",
    cancel: "Abbr.",
    next: "Weiter",
    modalTitle: "Segmentende",
    ok: "OK",
    okCamel: "OK",
    modalBody:
      "Zu Simulationszwecken haben wir diese Informationen automatisch für Sie ausgefüllt.",
    minValue: "Mindestwert",
    maxValue: "Höchstwert",
    correctionFactorMin:
      "Der Korrekturfaktor darf nicht geringer sein als 1 %3.",
    correctionFactorMax:
      "Der Korrekturfaktor darf nicht mehr als 400 %3 betragen."
  },
  "es-US": {
    start: "Iniciar",
    end: "Finalizar",
    am: "a. m.",
    oneUnitDecrease: "1 unidad de insulina reduce la glucosa en",
    night: "Noche",
    morning: "Mañana",
    range: "(%1 a %2)",
    cancel: "Cancelar",
    next: "Siguiente",
    modalTitle: "Hora de finalización del segmento",
    ok: "Aceptar",
    okCamel: "Aceptar",
    modalBody:
      "Completamos automáticamente esta información para fines de simulación.",
    minValue: "Valor mínimo",
    maxValue: "Valor máximo",
    correctionFactorMin: "El factor de corrección no puede ser menor de 1 %3.",
    correctionFactorMax:
      "El factor de corrección no puede ser mayor de 400 %3.",
    correctionFactor: "Reduce la glucosa en"
  },
  "fr-FR": {
    start: "Démarrer",
    end: "Fin",
    am: "du matin",
    oneUnitDecrease: "1 Unité d'insuline réduit le glucose de",
    night: "Nuit",
    morning: "Matin",
    range: "(%1 à %2)",
    cancel: "Annuler",
    next: "Suivant",
    modalTitle: "Heure de fin du segment",
    ok: "OK",
    okCamel: "Ok",
    modalBody:
      "À des fins de simulation, nous avons rempli automatiquement ces informations pour vous.",
    minValue: "Valeur minimum",
    maxValue: "Valeur maximum",
    correctionFactorMin:
      "Le facteur de correction ne peut être inférieur à 1 %3.",
    correctionFactorMax:
      "Le facteur de correction ne peut être supérieur à 400 %3."
  },
  "nl-NL": {
    start: "Start",
    end: "Stop",
    am: "AM",
    oneUnitDecrease: "1 eenheid insuline verlaagt de glucose met",
    night: "Nacht",
    morning: "Ochtend",
    range: "(%1 tot %2)",
    cancel: "Annuleren",
    next: "Volgende",
    modalTitle: "Eindtijd segment",
    ok: "OK",
    okCamel: "OK",
    modalBody:
      "Voor simulatiedoeleinden hebben wij deze informatie automatisch voor u ingevuld.",
    minValue: "Minimumwaarde",
    maxValue: "Maximumwaarde",
    correctionFactorMin: "Correctiefactor mag niet lager zijn dan 1 %3.",
    correctionFactorMax: "Correctiefactor mag niet hoger zijn dan 400 %3."
  }
};

export { strings };
