import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { BottomInnerNav } from "../../../../../molecules";
import { SettingsSection } from "../../../../../organisms";
import { strings } from "./PodShutOffScreenContent";

const PodShutOffScreen: React.FC = () => {
  const [podShutOffToggle, setPodShutOffToggle] = React.useState<boolean>(true);
  const [duration, setDuration] = React.useState<string>();
  const [isPressed, setIsPressed] = React.useState<boolean>(false);

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const getAdornmentColor = () => {
    if (duration === "––") {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  return (
    <View style={{ backgroundColor: "white", flex: 1 }}>
      <View style={[theme.layout.wrapper, { width: "100%" }]}>
        <SettingsSection
          settingTitle={languageContent.shutOff}
          hasCustomToggleSwitch
          startToggleTrue={true}
          onToggleChange={() => setPodShutOffToggle(!podShutOffToggle)}
          settingValueSubTextElement={
            podShutOffToggle ? (
              <TouchableOpacity onPress={() => navigate("PodShutOff")}>
                <Text style={[theme.fonts.body3, styles.settingValue]}>
                  {languageContent.on}
                </Text>
              </TouchableOpacity>
            ) : (
              <Text style={[theme.fonts.body3, styles.disabledValue]}>
                {languageContent.off}
              </Text>
            )
          }
        />
        {podShutOffToggle ? (
          <View style={{ flex: 1 }}>
            <View style={[styles.container]}>
              <Text style={[styles.durationHeader]}>
                {languageContent.inactivity}
              </Text>
              <Text style={[styles.subHeader]}>{languageContent.range}</Text>
              <>
                <Pressable
                  style={[theme.layout.inputContainerStyle, styles.pressable]}
                  disabled={isPressed}
                  onPress={() => {
                    setDuration("4");
                    setIsPressed(true);
                  }}>
                  <TextInput
                    editable={false}
                    style={[theme.layout.inputStyle, styles.textInput]}
                    selectionColor={theme.colors.blue}
                    placeholder={"––"}
                    placeholderTextColor={theme.colors.placeholder}
                    value={duration}
                  />
                  <Text
                    style={[
                      theme.layout.adornment,
                      {
                        color: getAdornmentColor(),
                        marginLeft: -8
                      }
                    ]}>
                    {languageContent.hrs}
                  </Text>
                </Pressable>
              </>
              <Text style={[styles.mainText]}>{languageContent.useful}</Text>
              <Text style={[styles.mainText, { marginTop: 0 }]}>
                {languageContent.interact}
              </Text>
            </View>
            <Text
              style={[
                theme.layout.adornment,
                {
                  color: getAdornmentColor()
                }
              ]}>
              {languageContent.units}
            </Text>
          </View>
        ) : (
          <View style={{ flex: 1 }}></View>
        )}

        <Text
          style={[
            theme.layout.adornment,
            {
              color: getAdornmentColor()
            }
          ]}>
          {languageContent.units}
        </Text>
        <BottomInnerNav
          leftAction={true}
          leftActionText={languageContent.leftActionText}
          leftActionNavigateTo="Drawer"
          leftActionStyle={{ color: theme.colors.text.cancel, fontSize: 16 }}
          rightActionText={languageContent.rightActionText}
          rightActionStyle={
            isPressed
              ? [styles.actionStyle, { color: "#5B34B9" }]
              : styles.actionStyle
          }
          rightActionNavigateTo="default"
          rightActionDisabled={!isPressed}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainText: {
    ...theme.fonts.text400,
    fontSize: 14,
    fontWeight: "400",
    marginBottom: 12,
    marginTop: 24
  },
  actionStyle: {
    color: theme.colors.text.cancel,
    fontSize: 16
  },
  container: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    height: 158,
    zIndex: -1
  },
  durationHeader: {
    ...theme.fonts.h5
  },
  textInput: {
    width: 220,
    marginBottom: -2,
    borderColor: "black"
  },
  subHeader: {
    ...theme.fonts.text400,
    color: theme.colors.text.grayText,
    marginBottom: 6,
    fontWeight: "400"
  },
  pressable: {
    borderColor: theme.colors.textInputBorder,
    width: 270,
    height: 60,
    marginTop: 8
  },
  settingValue: {
    color: theme.colors.lightBlue
  },
  disabledValue: {
    color: theme.colors.text.cancel
  }
});

export default PodShutOffScreen;
