let strings = {
  "en-US": {
    range: "(1 to 24 hrs)",
    podExpiration: "Pod Expiration",
    setNumber:
      "Set the number of hours advance notice \nyou would like to have before your Pod \nexpires.",
    hrs: "hrs",
    leftActionText: "cancel",
    rightActionText: "Save"
  },
  "en-GB": {
    range: "(1 to 24 hrs)",
    podExpiration: "Pod Expiry",
    setNumber:
      "Set the number of hours advance notice \nyou would like to have before your Pod \nexpires.",
    hrs: "hrs",
    leftActionText: "cancel",
    rightActionText: "Save"
  },
  "es-US": {
    range: "(1 a 24 h)",
    podExpiration: "Caducidad del Pod",
    setNumber:
      "Establezca el número de horas antes de que \nsu Pod caduque para recibir una notificación \ncon antelación.",
    hrs: "h",
    leftActionText: "cancelar",
    rightActionText: "guardar"
  },
  "de-DE": {
    range: "(1 bis 24 Stdn.)",
    podExpiration: "Pod-Ablauf",
    setNumber:
      "Legen Sie fest, wie viele Stunden vor \nErreichen des Pod-Endes Sie eine Meldung \nerhalten möchten.",
    hrs: "Stdn.",
    leftActionText: "Abbr.",
    rightActionText: "Speichern"
  },
  "nl-NL": {
    range: "(1 tot 24 u.)",
    podExpiration: "Uiterste gebruiksdatum Pod",
    setNumber:
      "Stel in hoeveel uur van tevoren u een bericht \nwilt krijgen voordat uw Pod niet meer kan \nworden gebruikt.",
    hrs: "u.",
    leftActionText: "Annuleren",
    rightActionText: "Opslaan"
  },
  "fr-FR": {
    range: "(1 à 24 h)",
    podExpiration: "Péremption du Pod",
    setNumber:"Définissez le délai de notification \nsouhaité avant la péremption \nde votre Pod.",
    hrs: "h",
    leftActionText: "annuler",
    rightActionText: "Enregistrer"
  },
};

export { strings };
