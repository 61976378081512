import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from 'web/store/reducers/modals/modalsSlice';
import { selectSelectedBasalProgram } from 'web/store/reducers/basalPrograms/basalPrograms.helpers';
import { setActiveProgram } from 'web/store/reducers/basalPrograms/basalProgramsSlice';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';
import { AppRoutesEnum } from 'web/routes/types';
import {
  basalPauseInsulin,
  basalResumeInsulin,
} from 'web/store/reducers/basal/actions';
import { deleteProgram } from 'web/store/reducers/basalPrograms/basalProgramsSlice';
import { PodCommunicationModal } from '../GlobalModals/GlobalModals';
import { Modal } from '../../Modal';
import {
  startBasalProgramMessages,
  pauseInsulinMessages,
  genericBasalProgramsModalMessages,
  startActiveBasalProgramModalMessages,
  startNewBasalProgramModalMessages,
  deleteBasalProgramModalMessages,
  cancelEditBasalProgramModalMessages,
  backToPreviousScreenModalMessages,
} from './BasalProgramsModals.messages';

interface StartBasalProgramModalProps {
  onPodCommTimeout: () => void;
}

export const StartBasalProgramModal: React.FC<StartBasalProgramModalProps> = ({
  onPodCommTimeout,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedProgram = useSelector(selectSelectedBasalProgram);
  const [showPodComm, setShowPodComm] = useState<boolean>(false);

  const handleTimeout = () => {
    onPodCommTimeout();
    dispatch(basalResumeInsulin());

    if (selectedProgram) {
      dispatch(setActiveProgram({ id: selectedProgram.id }));
    }

    dispatch(hideModal());
  };

  if (showPodComm) {
    return <PodCommunicationModal onTimeout={handleTimeout} />;
  }

  return (
    <Modal
      title={intl.formatMessage(startBasalProgramMessages.title)}
      rightButtonText={intl.formatMessage(startBasalProgramMessages.start)}
      rightButtonOnClick={() => setShowPodComm(true)}
      leftButtonText={intl.formatMessage(startBasalProgramMessages.cancel)}
      leftButtonOnClick={() => dispatch(hideModal())}
    >
      <FormattedMessage
        {...startBasalProgramMessages.body}
        values={{ programName: selectedProgram?.name }}
      />
    </Modal>
  );
};

interface PauseInsulinModalProps {
  onOk: () => void;
}

export const PauseInsulinModal: React.FC<PauseInsulinModalProps> = ({
  onOk,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showPodComm, setShowPodComm] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const handleOkClick = () => {
    onOk();
    dispatch(hideModal());
  };

  if (showConfirmation) {
    dispatch(basalPauseInsulin());

    return (
      <Modal
        title={intl.formatMessage(genericBasalProgramsModalMessages.title)}
        rightButtonText={intl.formatMessage(
          genericBasalProgramsModalMessages.ok
        )}
        rightButtonOnClick={handleOkClick}
      >
        <FormattedMessage
          {...genericBasalProgramsModalMessages.insulinPaused}
        />
      </Modal>
    );
  }

  if (showPodComm) {
    return (
      <PodCommunicationModal onTimeout={() => setShowConfirmation(true)} />
    );
  }

  return (
    <Modal
      title={intl.formatMessage(pauseInsulinMessages.title)}
      rightButtonText={intl.formatMessage(pauseInsulinMessages.yes)}
      rightButtonOnClick={() => setShowPodComm(true)}
      leftButtonText={intl.formatMessage(pauseInsulinMessages.no)}
      leftButtonOnClick={() => dispatch(hideModal())}
    >
      <FormattedMessage
        {...pauseInsulinMessages.body}
        values={{ br: <br /> }}
      />
    </Modal>
  );
};

interface StartActiveBasalProgramModalProps
  extends StartBasalProgramModalProps {
  changesSaved: boolean;
}

export const StartActiveBasalProgramModal: React.FC<
  StartActiveBasalProgramModalProps
> = ({ onPodCommTimeout, changesSaved }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedProgram = useSelector(selectSelectedBasalProgram);
  const [showPodComm, setShowPodComm] = useState<boolean>(false);

  const handleTimeout = () => {
    onPodCommTimeout();
    dispatch(basalResumeInsulin());
    dispatch(hideModal());
  };

  if (showPodComm) {
    return <PodCommunicationModal onTimeout={handleTimeout} />;
  }

  const bodyText = changesSaved
    ? startActiveBasalProgramModalMessages.changesSaved
    : startActiveBasalProgramModalMessages.changesNotSaved;

  const handleNotNowClick = () => {
    dispatch(
      navigateToRoute(AppRoutesEnum.dashboard, {
        showBottomInsulinModal: true,
      })
    );
  };

  return (
    <Modal
      title={intl.formatMessage(startActiveBasalProgramModalMessages.title)}
      rightButtonText={intl.formatMessage(
        startActiveBasalProgramModalMessages.startInsulin
      )}
      rightButtonOnClick={() => setShowPodComm(true)}
      leftButtonText={intl.formatMessage(
        startActiveBasalProgramModalMessages.notNow
      )}
      leftButtonOnClick={handleNotNowClick}
    >
      <FormattedMessage
        {...bodyText}
        values={{ programName: selectedProgram?.name, br: <br /> }}
      />
    </Modal>
  );
};

interface StartNewBasalProgramModalProps extends StartBasalProgramModalProps {
  onNotNow?: () => void;
}

export const StartNewBasalProgramModal: React.FC<
  StartNewBasalProgramModalProps
> = ({ onPodCommTimeout, onNotNow }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedProgram = useSelector(selectSelectedBasalProgram);
  const [showPodComm, setShowPodComm] = useState<boolean>(false);

  const handleTimeout = () => {
    onPodCommTimeout();
    dispatch(setActiveProgram({ id: selectedProgram?.id }));
    dispatch(hideModal());
  };

  if (showPodComm) {
    return <PodCommunicationModal onTimeout={handleTimeout} />;
  }

  const handleNotNowClick = () => {
    if (onNotNow) {
      onNotNow();
    }
    dispatch(hideModal());
  };

  return (
    <Modal
      title={intl.formatMessage(startNewBasalProgramModalMessages.title, {
        programName: selectedProgram?.name,
      })}
      rightButtonText={intl.formatMessage(
        startNewBasalProgramModalMessages.start
      )}
      rightButtonOnClick={() => setShowPodComm(true)}
      leftButtonText={intl.formatMessage(
        startNewBasalProgramModalMessages.notNow
      )}
      leftButtonOnClick={handleNotNowClick}
    >
      <FormattedMessage
        {...startNewBasalProgramModalMessages.body}
        values={{ programName: selectedProgram?.name, br: <br /> }}
      />
    </Modal>
  );
};

export const DeleteBasalProgramModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedProgram = useSelector(selectSelectedBasalProgram);

  const handleDelete = () => {
    dispatch(deleteProgram({ id: selectedProgram?.id }));
    dispatch(hideModal());
  };

  return (
    <Modal
      title={intl.formatMessage(deleteBasalProgramModalMessages.title, {
        programName: selectedProgram?.name,
      })}
      rightButtonText={intl.formatMessage(
        deleteBasalProgramModalMessages.delete
      )}
      rightButtonOnClick={handleDelete}
      leftButtonText={intl.formatMessage(
        deleteBasalProgramModalMessages.cancel
      )}
      leftButtonOnClick={() => dispatch(hideModal())}
    >
      <FormattedMessage
        {...deleteBasalProgramModalMessages.body}
        values={{ programName: selectedProgram?.name }}
      />
    </Modal>
  );
};

interface CancelEditBasalProgramModalProps {
  onYes: () => void;
  isNew?: boolean;
}

export const CancelEditBasalProgramModal: React.FC<
  CancelEditBasalProgramModalProps
> = ({ onYes, isNew = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const bodyMessage = isNew
    ? cancelEditBasalProgramModalMessages.bodyCreate
    : cancelEditBasalProgramModalMessages.bodyEdit;

  return (
    <Modal
      title={intl.formatMessage(startBasalProgramMessages.title)}
      rightButtonText={intl.formatMessage(pauseInsulinMessages.yes)}
      rightButtonOnClick={onYes}
      leftButtonText={intl.formatMessage(pauseInsulinMessages.no)}
      leftButtonOnClick={() => dispatch(hideModal())}
    >
      <FormattedMessage {...bodyMessage} />
    </Modal>
  );
};

interface BackToPreviousScreenModalProps {
  onYes: () => void;
}

export const BackToPreviousScreenModal: React.FC<BackToPreviousScreenModalProps> = ({ onYes }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Modal
      title={intl.formatMessage(backToPreviousScreenModalMessages.title)}
      rightButtonText={intl.formatMessage(backToPreviousScreenModalMessages.yes)}
      rightButtonOnClick={onYes}
      leftButtonText={intl.formatMessage(backToPreviousScreenModalMessages.cancel)}
      leftButtonOnClick={() => dispatch(hideModal())}
    >
      <FormattedMessage {...backToPreviousScreenModalMessages.body} />
    </Modal>
  );
}
