import { StackActions, useNavigation, useRoute } from "@react-navigation/native"
import { isEqual } from "lodash"
import React from "react"
import {
  BackHandler,
  Dimensions,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { asyncTimeout } from "../../../../../../helpers"
import calculateDurationFromTimestamps from "../../../../../../helpers/calculateDurationFromTimetamps"
import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat"
import replaceWithValues from "../../../../../../helpers/replaceWithValues"
import truncate from "../../../../../../helpers/truncate"
import useExpertHardwareBackAction from "../../../../../../hooks/useExpertHardwareBackAction"
import useLanguageContent from "../../../../../../hooks/useLanguageContent"
import { basalActions } from "../../../../../../../../web/store/reducers/basal"
import {
  BasalState,
  defaultExpertBasalProgramsInactive,
  emptyExpertBasalProgram,
  ExpertBasalSegment,
  NEW_BASAL_PROGRAM_ID,
} from "../../../../../../../../web/store/reducers/basal/reducer"
import { LangState } from "../../../../../../../../web/store/reducers/language"
import { UserState } from "../../../../../../../../web/store/reducers/user"
import { RootState } from "../../../../../../../../web/store/store"
import theme from "../../../../../../theme"
import { ListHeader } from "../../../../../atoms"
import {
  BottomInnerNav,
  CompletedBasalProgramGraph,
  ConfirmationModal,
  PodCommAlert,
  SegmentTopBar,
  WarningMessageBar,
} from "../../../../../molecules"
import SegmentListItem from "../../../../../molecules/segmentListItem"
import { strings } from "./ExpertProgramSegmentsReviewScreenContent"

const { height } = Dimensions.get("window")

const ExpertProgramSegmentsReviewScreen: React.FC = () => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual,
  )
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual,
  )
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual,
  )

  const [showBasalProgramErrorModal, setShowBasalProgramErrorModal] =
    React.useState<boolean>(false)
  const [showStartBasalProgramModal, setShowStartBasalProgramModal] =
    React.useState<boolean>(false)
  const [cancelModal, setCancelModal] = React.useState(false)
  const [showStartPodComm, setShowStartPodComm] = React.useState<boolean>(false)

  const navigation = useNavigation()
  const editPressed = React.useRef(false)
  const { navigate } = useNavigation()
  const dispatch = useDispatch()
  const route = useRoute<any>()
  const { languageContent } = useLanguageContent(strings)
  const backAction = useExpertHardwareBackAction(navigation, "Basal")

  const segmentsArrLength = basalState.newExpertBasalProgram.segments.length

  const calculateTotalBasal = (segments: ExpertBasalSegment[]) => {
    let total = 0
    segments.forEach((segment) => {
      const numOfHours = calculateDurationFromTimestamps(
        segment.start,
        segment.end,
      )
      const bolusAmount = numOfHours * segment.basalRate
      total += bolusAmount
    })
    //end in either 0 or 5
    return (Math.ceil(total * 20 - 0.5) / 20).toFixed(2)
  }

  const localeNumberTotalBasal = Number(
    calculateTotalBasal(basalState.newExpertBasalProgram.segments),
  ).toLocaleString(langState.locale)

  const newExpertBasalProgramName = truncate(
    basalState.newExpertBasalProgram.programName,
    15,
  )

  const deliverMessageOneDecimal = (0.05).toLocaleString(langState.locale)
  const deliverMessageTwoDecimal = (0.1).toLocaleString(langState.locale)

  const contentVariables = [
    localeNumberTotalBasal,
    newExpertBasalProgramName,
    deliverMessageOneDecimal,
    deliverMessageTwoDecimal,
  ]

  const checkBasalProgram = () => {
    const total = calculateTotalBasal(basalState.newExpertBasalProgram.segments)
    // check minimum daily insulin
    if (Number(total) <= 0.05) {
      setShowBasalProgramErrorModal(true)
      // check if there is an active pod
    } else if (
      userState.podConfigured &&
      userState.hasTXId &&
      !basalState.newExpertBasalProgram.isComplete
    ) {
      // "Navigate to start basal program";
      dispatch(
        basalActions.basalUpdate(
          {
            newExpertBasalProgram: {
              id: NEW_BASAL_PROGRAM_ID,
              programName: basalState.newExpertBasalProgram.programName,
              unitsPerDay: Number(total),
              isComplete: true,
              isActive: false,
              segments: basalState.newExpertBasalProgram.segments,
            },
          },
          basalState,
        ),
      )
      setShowStartBasalProgramModal(true)
    } else if (
      basalState.newExpertBasalProgram.programName &&
      !basalState.newExpertBasalProgram.isComplete
    ) {
      // Navigate to Program selection screen, new program highlighted
      // allows only one program to be made, put in expertBasalPrograms to create multiple
      dispatch(
        basalActions.basalUpdate(
          {
            newExpertBasalProgram: {
              id: NEW_BASAL_PROGRAM_ID,
              programName: basalState.newExpertBasalProgram.programName,
              unitsPerDay: Number(total),
              isComplete: true,
              isActive: false,
              segments: basalState.newExpertBasalProgram.segments,
            },
          },
          basalState,
        ),
      )
      // User is in FTS
      if (!userState.podConfigured && !userState.hasTXId) {
        navigation.navigate("ExpertTempBasalOn")
      } else {
        navigate("BasalPrograms", { newProgram: true })
      }
    } else {
      // program already made, user was editing, so navigate to Program selection screen, no "new" program icon
      const updatedProgram = {
        id: NEW_BASAL_PROGRAM_ID,
        programName: basalState.newExpertBasalProgram.programName,
        unitsPerDay: Number(total),
        isComplete: true,
        isActive:
          basalState.activeExpertBasalProgram.id === NEW_BASAL_PROGRAM_ID
            ? true
            : false,
        segments: basalState.newExpertBasalProgram.segments,
      }
      if (basalState.insulinPaused) {
        dispatch(
          basalActions.basalUpdate(
            {
              newExpertBasalProgram: updatedProgram,
              activeExpertBasalProgram: { ...updatedProgram },
            },
            basalState,
          ),
        )
      } else {
        dispatch(
          basalActions.basalUpdate(
            {
              newExpertBasalProgram: updatedProgram,
            },
            basalState,
          ),
        )
      }

      setShowStartBasalProgramModal(true)
    }
  }

  const startBasalProgram = async () => {
    await asyncTimeout(500)
    dispatch(
      basalActions.basalUpdate(
        {
          insulinPaused: false,
          expertBasalPrograms: defaultExpertBasalProgramsInactive,
          activeExpertBasalProgram: {
            ...basalState.newExpertBasalProgram,
            isActive: true,
          },
          newExpertBasalProgram: {
            ...basalState.newExpertBasalProgram,
            isActive: true,
          },
        },
        basalState,
      ),
    )
  }

  const handleEditPress = async (index: number) => {
    const editEndHelpText =
      basalState.newExpertBasalProgram.segments[index].endHelpText
    dispatch(
      basalActions.basalUpdate(
        {
          newExpertBasalProgram: {
            ...basalState.newExpertBasalProgram,
            segments: basalState.newExpertBasalProgram.segments.slice(0, index),
          },
        },
        basalState,
      ),
    )
    if (basalState.newExpertBasalProgram.isComplete) {
      navigation.navigate("ExpertProgramSegments", {
        editEndTime: basalState.newExpertBasalProgram.segments[index].end,
        editEndHelpText,
        editBasalRate:
          basalState.newExpertBasalProgram.segments[index].basalRate,
      })
    } else {
      navigation.dispatch(
        StackActions.pop(
          basalState.newExpertBasalProgram.segments.length - index,
        ),
      )
    }
  }

  React.useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", backAction)
    return () =>
      BackHandler.removeEventListener("hardwareBackPress", backAction)
  }, [navigation])

  React.useEffect(
    () =>
      navigation.addListener("beforeRemove", () => {
        dispatch(
          basalActions.basalUpdate(
            {
              newExpertBasalProgram: {
                ...basalState.newExpertBasalProgram,
                segments: basalState.newExpertBasalProgram.segments.slice(
                  0,
                  basalState.newExpertBasalProgram.segments.length - 1,
                ),
              },
            },
            basalState,
          ),
        )
      }),
    [navigation],
  )
  return (
    <>
      {basalState.insulinPaused ? <WarningMessageBar insulinDelivery /> : null}
      <View style={[theme.layout.wrapper]}>
        <SegmentTopBar
          programName={basalState.newExpertBasalProgram.programName}
          truncateLength={20}
          showRightUnits
          isOnlyProgramName
        />
        <View style={[styles.break]} />
        <CompletedBasalProgramGraph
          program={basalState.newExpertBasalProgram}
          maxHeight={height < 600 ? 90 : 150}
        />
        <Text
          style={[
            theme.fonts.h4,
            {
              textAlign: "center",
              marginBottom: 16,
            },
          ]}
        >
          {replaceWithValues(languageContent.totalBasal, contentVariables)}
        </Text>
        <ListHeader numOfSegments={String(segmentsArrLength)} />
        <ScrollView style={styles.segmentStyles}>
          {basalState.newExpertBasalProgram.segments.map((segment, index) => {
            return (
              <SegmentListItem
                onEditPress={() => {
                  editPressed.current = true
                  handleEditPress(index)
                }}
                key={String(index)}
                index={index}
                segmentLabel={`${languageContent.segment} ${index + 1}:`}
                startTime={
                  getCurrentHourFormat({
                    timeStr: segment.start,
                    is24Hour: langState.is24Hour,
                  })!
                }
                endTime={
                  getCurrentHourFormat({
                    timeStr: segment.end,
                    is24Hour: langState.is24Hour,
                  })!
                }
                firstRateOrBoldedLabel={languageContent.basalRate}
                firstRateValue={` ${segment.basalRate.toLocaleString(
                  langState.locale,
                )} ${languageContent.uHr}`}
              />
            )
          })}
        </ScrollView>
        <BottomInnerNav
          leftActionText={languageContent.cancel}
          leftAction
          leftNavigationAction={() => {
            setCancelModal(true)
          }}
          rightActionText={languageContent.save}
          rightAction={() => {
            checkBasalProgram()
          }}
          rightActionStyle={{ color: theme.colors.purple }}
        />
      </View>
      {!userState.hasTXId && !userState.podConfigured ? (
        <ConfirmationModal
          title={languageContent.cancelTitleThree}
          isVisible={cancelModal}
          onDismiss={() => setCancelModal(false)}
          onConfirm={() => {
            setCancelModal(false)
            dispatch(
              basalActions.basalUpdate(
                {
                  newExpertBasalProgram: emptyExpertBasalProgram,
                },
                basalState,
              ),
            )
            navigation.navigate("ExpertIntro")
          }}
          message={
            <View>
              <Text style={[theme.fonts.body1, { marginBottom: 8 }]}>
                {languageContent.cancelBody1}
              </Text>
            </View>
          }
        />
      ) : (
        <ConfirmationModal
          isVisible={cancelModal}
          title={
            route.params && route.params.edit
              ? languageContent.cancelTitleOne
              : languageContent.cancelTitleTwo
          }
          confirmText={languageContent.yes}
          dismissText={languageContent.no}
          onDismiss={() => setCancelModal(false)}
          onConfirm={() => {
            setCancelModal(false)
            if (route.params && route.params.edit) {
              dispatch(
                basalActions.basalUpdate(
                  {
                    newExpertBasalProgram:
                      basalState.copyOfNewExpertBasalProgram,
                    copyOfNewExpertBasalProgram: emptyExpertBasalProgram,
                  },
                  basalState,
                ),
              )
            } else {
              dispatch(
                basalActions.basalUpdate(
                  {
                    newExpertBasalProgram: emptyExpertBasalProgram,
                  },
                  basalState,
                ),
              )
            }
            navigation.navigate("BasalPrograms")
          }}
        />
      )}
      {basalState.insulinPaused ? (
        <ConfirmationModal
          isVisible={showStartBasalProgramModal}
          title={languageContent.startModalTitle}
          message={
            <View>
              <Text style={[theme.fonts.body1, { marginBottom: 8 }]}>
                {replaceWithValues(
                  languageContent.changesSaved,
                  contentVariables,
                )}
              </Text>
              <Text style={[theme.fonts.body1]}>
                {languageContent.startBasalProgram}
              </Text>
            </View>
          }
          confirmText={languageContent.startInsulin}
          dismissText={languageContent.notNow}
          onConfirm={() => {
            setShowStartBasalProgramModal(false)
            setShowStartPodComm(true)
          }}
          onDismiss={() => {
            setShowStartBasalProgramModal(false)
            navigate("BasalPrograms")
          }}
        />
      ) : (
        <ConfirmationModal
          isVisible={showStartBasalProgramModal}
          title={replaceWithValues(languageContent.start, contentVariables)}
          message={
            <View>
              <Text style={[theme.fonts.body1]}>
                {languageContent.changesSavedTwo}
              </Text>
            </View>
          }
          confirmText={languageContent.startProgram}
          dismissText={languageContent.notNow}
          onConfirm={() => {
            setShowStartBasalProgramModal(false)
            setShowStartPodComm(true)
          }}
          onDismiss={() => {
            setShowStartBasalProgramModal(false)
            navigate("BasalPrograms", {
              isNewProgram: route.params && route.params.edit ? false : true,
            })
          }}
        />
      )}
      <ConfirmationModal
        isVisible={showBasalProgramErrorModal}
        title={languageContent.BasalProgramError}
        message={
          <View>
            <Text style={[theme.fonts.body1]}>
              {languageContent.deliverAtLeast}
            </Text>
            <Text />
            <Text>
              {replaceWithValues(
                languageContent.deliverMessageOne,
                contentVariables,
              )}
            </Text>
            <Text />
            <Text>
              {replaceWithValues(
                languageContent.deliverMessageTwo,
                contentVariables,
              )}
            </Text>
          </View>
        }
        hideDismiss
        confirmText={languageContent.ok}
        onConfirm={() => {
          setShowBasalProgramErrorModal(false)
        }}
      />
      {showStartPodComm && (
        <PodCommAlert
          duration={1000}
          screen="Drawer"
          onDismiss={startBasalProgram}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  segmentStyles: {
    height: height < 700 ? "42%" : "52%",
  },
  segmentHeader: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.textInputBorder,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    backgroundColor: theme.colors.grayScale.gray600,
  },
  segmentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    marginVertical: 16,
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder,
    marginBottom: 24,
  },
  graphView: {
    marginHorizontal: 16,
    marginBottom: 16,
    alignItems: "flex-end",
    flexDirection: "row",
  },
})

export default ExpertProgramSegmentsReviewScreen
