import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { Moment } from 'moment';
import { Button } from 'web/components/ios/Button';
import { targetGlucoseSegmentMessages } from '../../../BolusSettings.messages';
import { BolusSettingsRoutesEnum } from '../../../types';

export interface TargetGlucoseSegmentScreenValues {
  segmentNumber: number;
  startTime: Moment;
  endTime: Moment;
  targetGlucose: number;
  correctAbove: number;
}

interface SegmentScreenProps {
  route: {
    params: TargetGlucoseSegmentScreenValues;
  };
}

export const TargetGlucoseSegmentScreen: React.FC<SegmentScreenProps> = ({ route }) => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const {
    params: { segmentNumber, startTime, endTime, targetGlucose, correctAbove },
  } = route;

  const renderTime = (time: Moment) => {
    let nightOrMorning = intl.formatMessage(targetGlucoseSegmentMessages.night);
    // 12:00 AM is considered night, so we have to add a special case for it.
    if (time.format('A') === 'AM' && time.format('h:mm') !== '12:00') {
      nightOrMorning = intl.formatMessage(targetGlucoseSegmentMessages.morning);
    }

    return `${time.format('h:mm A')} (${nightOrMorning})`;
  };

  return (
    <div className="bg-white h-full pt-[19px] pb-[34px] flex flex-col justify-between">
      <div>
        <div className="px-2 mb-[12px] font-bold text-2xl">
          <FormattedMessage
            {...targetGlucoseSegmentMessages.title}
            values={{ segmentNumber }}
          />
        </div>

        <div className="px-2 py-[11px] flex flex-row items-center justify-between border-b-1 border-b-ios-gray-9/[.36]">
          <div>
            <FormattedMessage {...targetGlucoseSegmentMessages.startTime} />
          </div>
          <div className="text-ios-gray-9/60">{renderTime(startTime)}</div>
        </div>

        <div className="px-2 py-[11px] flex flex-row items-center justify-between border-b-1 border-b-ios-gray-9/[.36]">
          <div>
            <FormattedMessage {...targetGlucoseSegmentMessages.endTime} />
          </div>
          <div className="text-modes-automated">{renderTime(endTime)}</div>
        </div>

        <div className="px-2 py-[11px] flex flex-row items-center justify-between border-b-1 border-b-ios-gray-9/[.36]">
          <div>
            <div>
              <FormattedMessage
                {...targetGlucoseSegmentMessages.targetGlucose}
              />
            </div>
            <div className="text-ios-gray-9/60 text-small">
              <FormattedMessage
                {...targetGlucoseSegmentMessages.targetGlucoseText}
              />
            </div>
          </div>
          <div className="text-modes-automated">{`${targetGlucose} mg/dL`}</div>
        </div>

        <div className="px-2 py-[11px] flex flex-row items-center justify-between border-b-1 border-b-ios-gray-9/[.36]">
          <div>
            <div>
              <FormattedMessage
                {...targetGlucoseSegmentMessages.correctAbove}
              />
            </div>
            <div className="text-ios-gray-9/60 text-small">
              <FormattedMessage
                {...targetGlucoseSegmentMessages.correctAboveText}
              />
            </div>
          </div>
          <div className="text-modes-automated">{`${correctAbove} mg/dL`}</div>
        </div>
      </div>

      <div className="px-2">
        <Button
          colorMode="automated"
          variant="colored"
          full
          onClick={() => navigate(BolusSettingsRoutesEnum.TargetGlucose)}
        >
          <FormattedMessage {...targetGlucoseSegmentMessages.cta} />
        </Button>
      </div>
    </div>
  );
};
