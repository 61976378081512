import { NavState } from './index';
import { initialState } from './reducer';

export const NAVIGATION_UPDATE = 'navigation_update';
export const NAVIGATION_INIT = 'navigation_init';

export const NAVIGATE_TO_ROUTE = 'navigate_to_route';

interface ActionUpdate {
  type: ActionType;
  payload: {
    navigation: Partial<NavState>;
  };
}

interface ActionInit {
  type: ActionType;
  payload: {
    navigation: NavState;
  };
}

type ActionType =
  | typeof NAVIGATION_UPDATE
  | typeof NAVIGATION_INIT
  | typeof NAVIGATE_TO_ROUTE;

export const navigationUpdate = (
  data: Partial<NavState>,
  initialState?: NavState
): Action => {
  return {
    type: NAVIGATION_UPDATE,
    payload: {
      navigation: data,
    },
  };
};

export const navigationInit = (): Action => {
  return {
    type: NAVIGATION_INIT,
    payload: {
      navigation: initialState,
    },
  };
};

export const navigateToRoute = (
  routeName: string,
  params?: Record<string, string | boolean | number>,
  routeLabel?: string,
) => {
  return {
    type: NAVIGATE_TO_ROUTE,
    payload: {
      navigation: {
        navigateTo: routeName,
        currentRoute: routeName,
        navigateToLabel: routeLabel ? routeLabel : routeName,
        params,
      },
    },
  };
};

export type Action = ActionUpdate | ActionInit;
