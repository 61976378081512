let strings = {
  "en-US": {
    bloodGlucose:
      "If your glucose is below your Minimum Glucose for Calculations, the Bolus Calculator is disabled so that it does not suggest a bolus.",
    minBG: "Minimum Glucose for Calculations",
    range: "(%1 to %2)",
    cancel: "Cancel",
    save: "Save"
  },
  "en-GB": {
    bloodGlucose:
      "If your glucose is below your Minimum Glucose for Calculations, the Bolus Calculator is disabled so that it does not suggest a bolus.",
    minBG: "Minimum Glucose for Calculations",
    range: "(%1 to %2)",
    cancel: "Cancel",
    save: "Save"
  },
  "de-DE": {
    bloodGlucose:
      "Wenn Ihr Glukosewert unter Ihrem minimalen Blutzucker für Berechnungen liegt, wird der Bolusrechner deaktiviert und schlägt daher keinen Bolus vor.",
    minBG: "Min. Blutzucker für Berechnungen",
    range: "(%1 bis %2)",
    cancel: "Abbr.",
    save: "Speichern"
  },
  "es-US": {
    bloodGlucose:
      "Si su glucosa se encuentra por debajo de su glucosa mínima para cálculos, se desactiva la calculadora de bolo para que no sugiera un",
    minBG: "Glucosa mínima para cálculos",
    range: "(%1 a %2)",
    cancel: "Cancelar",
    save: "Guardar"
  },
  "fr-FR": {
    bloodGlucose:
      "Si votre glucose est inférieur à votre glucose minimum pour les calculs, le calculateur de bolus est désactivé et ne suggère pas de bolus.",
    minBG: "Glucose minimum pour les calculs",
    range: "(%1 à %2)",
    cancel: "Annuler",
    save: "Enregistrer"
  },
  "nl-NL": {
    bloodGlucose:
      "Als uw glucose lager is dan uw minimale glucose voor berekeningen, wordt de boluscalculator uitgeschakeld en geen bolus voorgesteld.",
    minBG: "Minimale glucose voor berekeningen",
    range: "(%1 tot %2)",
    cancel: "Annuleren",
    save: "Opslaan"
  }
};

export { strings };
