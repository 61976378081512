let strings = {
  "en-US": {
    bolusSettingTitle: "Maximum Bolus",
    bolusSettingSubtitle: "10 U of insulin in single bolus",
    extendedSettingTitle: "Extended Bolus",
    on: "On",
    extendedSettingBody2:
      "Extending a bolus means that a meal bolus can be delivered over a prolonged period of time.",
    extendedSettingBody3: "OFF. Tap to turn ON.",
    calcSettingHeader: "Bolus Calculator",
    calcSettingTitle: "Target Glucose & Correct Above",
    calcSettingTextTarget: "Target Glucose (%1 - %2)",
    calcSettingSubTextTarget: "Correct Above (%3 - %4)",
    minBgSettingTitle: "Minimum Glucose for Calculations",
    iCSettingTitle: "Insulin to Carb Ratio",
    betweenCarbs: "Between 10 and 15 g of carbs",
    between: "Between %5 and %6",
    correctionSettingTitle: "Correction Factor",
    reverseSettingTitle: "Reverse Correction",
    reverseSettingSubText2:
      "There may be times when you are about to eat and your glucose level is below your Target Glucose level. If Reverse Correction is set to ON, the Bolus Calculator lowers a suggested meal bolus to help counteract your low glucose reading.",
    reverseSettingSubText3: "OFF. Tap to turn ON.",
    durationSettingTitle: "Duration of Insulin Action",
    durationSettingSubText: "4 hrs"
  },
  "en-GB": {
    bolusSettingTitle: "Maximum Bolus",
    bolusSettingSubtitle: "10 U of insulin in a single bolus",
    extendedSettingTitle: "Extended Bolus",
    on: "On",
    extendedSettingBody2:
      "Extending a bolus means that a meal bolus can be delivered over a prolonged period of time.",
    extendedSettingBody3: "OFF. Tap to turn ON.",
    calcSettingHeader: "Bolus Calculator",
    calcSettingTitle: "Target Glucose & Correct Above",
    calcSettingTextTarget: "Target Glucose (%1 - %2)",
    calcSettingSubTextTarget: "Correct Above (%3 - %4)",
    minBgSettingTitle: "Minimum Glucose for Calculations",
    iCSettingTitle: "Insulin to Carb Ratio",
    betweenCarbs: "Between 10 and 15 g of carbs",
    between: "Between %5 and %6",
    correctionSettingTitle: "Correction Factor",
    reverseSettingTitle: "Reverse Correction",
    reverseSettingSubText2:
      "There may be times when you are about to eat and your glucose level is below your Target Glucose level. If Reverse Correction is set to ON, the Bolus Calculator lowers a suggested meal bolus to help counteract your low glucose reading.",
    reverseSettingSubText3: "OFF. Tap to turn ON.",
    durationSettingTitle: "Duration of Insulin Action",
    durationSettingSubText: "4 hrs"
  },
  "de-DE": {
    bolusSettingTitle: "Maximaler Bolus",
    bolusSettingSubtitle: "10 E Insulin in Einzelbolus",
    extendedSettingTitle: "Verzögerter Bolus",
    on: "Ein",
    extendedSettingBody2:
      "Verzögerter Bolus bedeutet, dass ein Mahlzeitenbolus über eine längere Zeitdauer abgegeben werden kann.",
    extendedSettingBody3: "AUS. Zum Einschalten antippen.",
    calcSettingHeader: "Bolusrechner",
    calcSettingTitle: "Ziel-Blutzucker & Korrigieren über",
    calcSettingTextTarget: "Glukose-Zielwert (%1–%2)",
    calcSettingSubTextTarget: "Korrigieren über (%3–%4)",
    minBgSettingTitle: "Minimaler Blutzucker für Berechnungen",
    iCSettingTitle: "Kohlenhydrat-Insulin-Verhältnis",
    betweenCarbs: "Zwischen 10 und 15 g Kohlenhydrate",
    between: "Zwischen %5 und %6",
    correctionSettingTitle: "Korrekturfaktor",
    reverseSettingTitle: "Gegenläufige Korrektur",
    reverseSettingSubText2:
      "Es kann vorkommen, dass Sie in Kürze etwas essen und Ihre Glukose unter dem Glukose-Zielwert liegt. Wenn die gegenläufige Korrektur auf EIN gestellt ist, senkt der Bolusrechner einen vorgeschlagenen Mahlzeitenbolus, um Ihrem niedrigen Glukosewert entgegenzuwirken.",
    reverseSettingSubText3: "AUS. Zum Einschalten antippen.",
    durationSettingTitle: "Dauer der Insulinaktivität",
    durationSettingSubText: "4 Stunden"
  },
  "es-US": {
    bolusSettingTitle: "Bolo máximo",
    bolusSettingSubtitle: "10 U de insulina en un solo bolo",
    extendedSettingTitle: "Bolo extendido",
    on: "Prendido",
    extendedSettingBody2:
      "Extender un bolo significa que se puede administrar un bolo de comida durante un período prolongado de tiempo.",
    extendedSettingBody3: "Apagado. Toque para Prender.",
    calcSettingHeader: "Calculadora de bolo",
    calcSettingTitle: "Opciones de glucosa objetivo y corregir sobre",
    calcSettingTextTarget: "Glucosa Objetivo (%1 - %2)",
    calcSettingSubTextTarget: "Corregir Sobre (%3 - %4)",
    minBgSettingTitle: "Glucosa mínima para cálculos",
    iCSettingTitle: "Proporción de insulina a carbohidratos",
    betweenCarbs: "10 a 15 g de carb.",
    between: "%5 a %6",
    correctionSettingTitle: "Factor de Corrección",
    reverseSettingTitle: "Corrección Inversa",
    reverseSettingSubText2:
      "Habrá veces en que esté a punto de comer y su nivel de glucosa estará por debajo de su nivel de glucosa objetivo. Si está prendida la corrección inversa, la calculadora de bolo disminuye un bolo de comido sugerido para ayudar a contrarrestar la medición de glucosa baja.",
    reverseSettingSubText3: "Apagado. Toque para Prender.",
    durationSettingTitle: "Duración de la acción de la insulina",
    durationSettingSubText: "4 h"
  },
  "fr-FR": {
    bolusSettingTitle: "Bolus maximum",
    bolusSettingSubtitle: "10 U d'insuline en un seul bolus",
    extendedSettingTitle: "Bolus prolongé",
    on: "Activé",
    extendedSettingBody2:
      "La prolongation d'un bolus signifie qu'un bolus repas peut être administré sur une période prolongée.",
    extendedSettingBody3: "Désactivé. Appuyez pour activer.",
    calcSettingHeader: "Calculateur de bolus",
    calcSettingTitle: "Glucose cible & Corriger si supérieur à",
    calcSettingTextTarget: "Glucose cible (%1 - %2)",
    calcSettingSubTextTarget: "Corriger si supérieur à (%3 - %4)",
    minBgSettingTitle: "Glucose minimum pour les calculs",
    iCSettingTitle: "Rapport insuline/glucides",
    betweenCarbs: "Entre 10 et 15 g de glucides",
    between: "Entre %5 et %6",
    correctionSettingTitle: "Facteur de correction",
    reverseSettingTitle: "Correction inverse",
    reverseSettingSubText2:
      "Il peut arriver que vous soyez sur le point de manger et que votre glycémie soit inférieure à votre glucose cible. Si la correction inverse est activée, le calculateur de bolus réduit le bolus repas suggéré pour aider à compenser votre relevé de glycémie basse.",
    reverseSettingSubText3: "Désactivé. Appuyez pour activer.",
    durationSettingTitle: "Durée d’action de l’insuline",
    durationSettingSubText: "4 h"
  },
  "nl-NL": {
    bolusSettingTitle: "Maximale bolus",
    bolusSettingSubtitle: "10 E insuline in een enkele bolus",
    extendedSettingTitle: "Verlengde bolus",
    on: "Aan",
    extendedSettingBody2:
      "Het verlengen van een bolus betekent dat een maaltijdbolus over langere tijd kan worden toegediend.",
    extendedSettingBody3: "UIT. Tik om AAN te zetten.",
    calcSettingHeader: "Boluscalculator",
    calcSettingTitle: "Streefwaarde glucose & Corrigeren boven",
    calcSettingTextTarget: "Streefwaarde glucose (%1 - %2)",
    calcSettingSubTextTarget: "Corrigeren boven (%3 - %4)",
    minBgSettingTitle: "Minimale glucose voor berekeningen",
    iCSettingTitle: "Insuline/KH-verhouding",
    betweenCarbs: "Tussen 10 en 15 g KH",
    between: "Tussen %5 en %6",
    correctionSettingTitle: "Correctiefactor",
    reverseSettingTitle: "Tegenovergestelde correctie",
    reverseSettingSubText2:
      "Er zullen momenten zijn dat u wilt gaan eten en dat uw glucosespiegel lager is dan de streefwaarde glucose. Als Tegenovergestelde correctie is ingeschakeld, verlaagt de boluscalculator een voorgestelde maaltijdbolus om iets te doen aan uw gemeten lage glucosewaarde.",
    reverseSettingSubText3: "UIT. Tik om AAN te zetten.",
    durationSettingTitle: "Duur van de insulineactie",
    durationSettingSubText: "4 uur"
  }
};

export { strings };
