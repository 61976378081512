import React from "react"
import { StyleSheet, Text, View } from "react-native"
import Home from "../../../../../assets/home_white.svg"
import useLanguageContent from "../../../hooks/useLanguageContent"
import theme from "../../../theme"
import { strings } from "./ClickAboveToastContent"

const ClickAboveToast: React.FC = () => {
  const { languageContent } = useLanguageContent(strings)

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Text style={styles.clickAboveTextStyle}>{languageContent.click}</Text>
      <Home
        width="30"
        height="30"
        style={{ marginHorizontal: -20, marginTop: -4 }}
      />
      <Text style={styles.clickAboveTextStyle}>
        {languageContent.aboveText}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  clickAboveTextStyle: {
    textAlign: "center",
    alignSelf: "center",
    paddingHorizontal: 24,
    color: theme.colors.white,
    ...theme.fonts.body1,
  },
})

export default ClickAboveToast
