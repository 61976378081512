let strings = {
  "en-US": {
    total: "Total Basal: 22 U/day",
    segment: "Segment",
    basalRate: "Basal Rate:",
    cancel: "Cancel",
    save: "Save",
    modalTitle: "Review Your Basal Program",
    editSegModalBody:
      "Check your time segments and basal rates on the next screen.",
    continue: "Continue",
    rateValue: "U/hr"
  },
  "en-GB": {
    total: "Total Basal: 22 U/day",
    segment: "Segment",
    basalRate: "Basal Rate:",
    cancel: "Cancel",
    save: "Save",
    modalTitle: "Review your Basal Programme",
    editSegModalBody:
      "Check your time segments and basal rates on the next screen.",
    continue: "Continue",
    rateValue: "U/hr"
  },
  "de-DE": {
    total: "Gesamte Basalrate: 22 E/Tag",
    segment: "Segment",
    basalRate: "Basalrate:",
    cancel: "Abbr.",
    save: "Speichern",
    modalTitle: "Ihr Basalratenprofil überprüfen",
    editSegModalBody:
      "Prüfen Sie auf dem nächsten Bildschirm Ihre Zeitsegmente und Basalraten.",
    continue: "Weiter",
    rateValue: "E/Std."
  },
  "es-US": {
    total: "Basal total: 22 U/día",
    segment: "Segmento",
    basalRate: "Tasa basal:",
    cancel: "Cancelar",
    save: "Guardar",
    modalTitle: "Revise su programa basal",
    editSegModalBody:
      "Compruebe sus segmentos de tiempo y tasas basales en la siguiente pantalla.",
    continue: "Continuar",
    rateValue: "U/h"
  },
  "fr-FR": {
    total: "Débit basal total : 22 U/jour",
    segment: "Segment",
    basalRate: "Débit basal :",
    cancel: "Annuler",
    save: "Enregistrer",
    modalTitle: "Vérifiez votre programme basal",
    editSegModalBody:
      "Vérifiez vos segments temporels et vos débits basaux à l'écran suivant.",
    continue: "Continuer",
    rateValue: "U/h"
  },
  "nl-NL": {
    total: "Totale basaal: 22 E/dag",
    segment: "Segment",
    basalRate: "Basaalsnelheid:",
    cancel: "Annuleren",
    save: "Opslaan",
    modalTitle: "Uw basaalprogramma bekijken",
    editSegModalBody:
      "Controleer uw tijdsegmenten en basaalsnelheden op het volgende scherm.",
    continue: "Doorgaan",
    rateValue: "E/u."
  }
};

export { strings };
