import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  missingSensorTitle: {
    id: 'L2.settings.missing-sensor-values.title',
    defaultMessage: 'Missing Sensor Values'
  },
  missingSensorNotify: {
    id: 'L2.settings.missing-sensor-values.notification',
    defaultMessage: 'Where there is a signal loss or a problem with your Sensor, the Omnipod 5 App will not be able to display your sensor glucose values or notify you when your glucose is high or low.'
  },
  missingSensorNotifyTwo: {
    id: 'L2.settings.missing-sensor-values.notification-two',
    defaultMessage: 'This alert notifies you when your sensor glucose values have not been received for 20 minutes.'
  },
})
