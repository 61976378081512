import PickerItem from "../types/pickerItem";

const CORRECTION_FACTOR_DATA_MMOL: Array<PickerItem> = [];
let counter = 0;

for (let i = 1; i <= 22.2; i += 0.1) {
  CORRECTION_FACTOR_DATA_MMOL.push({
    label: String(Math.round(10 * i) / 10),
    value: Math.round(10 * i) / 10,
    key: String(Math.round(10 * i) / 10),
    index: ++counter
  });
}

export default CORRECTION_FACTOR_DATA_MMOL;
