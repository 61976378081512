let strings = {
  "en-US": {
    backDefault: "Back",
    agreeDefault: "Agree",
    defaultTitle: "Communicating with Pod"
  },
  "en-GB": {
    backDefault: "Back",
    agreeDefault: "Agree",
    defaultTitle: "Communicating with Pod"
  },
  "de-DE": {
    backDefault: "Zurück",
    agreeDefault: "Zustimmen",
    defaultTitle: "Kommunikation mit dem Pod"
  },
  "es-US": {
    backDefault: "Atrás",
    agreeDefault: "Aceptar"
  },
  "fr-FR": {
    backDefault: "Retour",
    agreeDefault: "Accepter"
  },
  "nl-NL": {
    backDefault: "Terug",
    agreeDefault: "Mee eens"
  }
};

export { strings };
