import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { BottomNav } from 'web/components/ios/BottomMenu/BottomNav';
import { SettingsRow } from 'web/components/ios/SettingsRow';
import {
  setExtendedBolus,
  setReverseCorrection,
} from 'web/store/reducers/bolus/actions';
import {
  selectIsExtendedBolusOn,
  selectIsReverseCorrectionOn,
} from 'web/store/reducers/bolus/bolus.helpers';
import { messages } from '../BolusSettings.messages';
import { BolusSettingsRoutesEnum } from '../types';

export const BolusSettingsMain: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const isExtendedBolusOn = useSelector(selectIsExtendedBolusOn);
  const isReverseCorrectionOn = useSelector(selectIsReverseCorrectionOn);

  const handleExtendedBolusToggle = () =>
    dispatch(setExtendedBolus(!isExtendedBolusOn));

  const handleReverseCorrectionToggle = () =>
    dispatch(setReverseCorrection(!isReverseCorrectionOn));

  return (
    <div className="bg-ios-gray-6 pt-2 h-full flex flex-col justify-between text-regular font-ios">
      <div className="overflow-y-scroll">
        <div className="bg-white">
          <SettingsRow
            text={intl.formatMessage(messages.maximumBolus)}
            subText={intl.formatMessage(messages.maximumBolusText)}
            showCaret
            onClick={() => navigate(BolusSettingsRoutesEnum.MaximumBolus)}
          />
          <SettingsRow
            text={intl.formatMessage(messages.extendedBolus)}
            isToggled={isExtendedBolusOn}
            showToggle
            onToggle={handleExtendedBolusToggle}
          />
        </div>

        <div className="text-modals pt-1 pb-[34px] px-2 text-ios-gray-9/60">
          <FormattedMessage {...messages.extendedBolusDisclaimer} />
        </div>

        <div className="text-modals px-2 pb-1 text-ios-gray-9/60">
          <FormattedMessage {...messages.bolusCalculator} />
        </div>

        <div className="bg-white">
          <SettingsRow
            text={intl.formatMessage(messages.targetGlucose)}
            // @ts-ignore it complaints due to value typings, but it's still treated as a string.
            subText={intl.formatMessage(messages.targetGlucoseText, {
              br: <br />,
            })}
            showCaret
            onClick={() => navigate(BolusSettingsRoutesEnum.TargetGlucoseStack)}
          />
          <SettingsRow
            text={intl.formatMessage(messages.minimumGlucose)}
            subText={intl.formatMessage(messages.minimumGlucoseText)}
            showCaret
            onClick={() => navigate(BolusSettingsRoutesEnum.MinimumGlucose)}
          />
          <SettingsRow
            text={intl.formatMessage(messages.insulinToCarb)}
            subText={intl.formatMessage(messages.insulinToCarbText)}
            showCaret
            onClick={() => navigate(BolusSettingsRoutesEnum.InsulinToCarbStack)}
          />
          <SettingsRow
            text={intl.formatMessage(messages.correctionFactor)}
            subText={intl.formatMessage(messages.correctionFactorText)}
            showCaret
            onClick={() =>
              navigate(BolusSettingsRoutesEnum.CorrectionFactorStack)
            }
          />
          <SettingsRow
            text={intl.formatMessage(messages.durationInsulin)}
            subText={intl.formatMessage(messages.durationInsulinText)}
            showCaret
            onClick={() => navigate(BolusSettingsRoutesEnum.DurationOfInsulin)}
          />
          <SettingsRow
            text={intl.formatMessage(messages.reverseCorrection)}
            isToggled={isReverseCorrectionOn}
            showToggle
            onToggle={handleReverseCorrectionToggle}
          />
        </div>

        <div className="text-modals pt-1 pb-[34px] px-2 text-ios-gray-9/60">
          <FormattedMessage {...messages.reverseCorrectionDisclaimer} />
        </div>
      </div>

      <BottomNav />
    </div>
  );
};
