import { createStackNavigator } from '@react-navigation/stack';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { strings } from 'mobile/latest/routes/simulator/TXSetupStackContent';
import { CGMTransmitterSettingsScreen } from 'mobile/latest/components/screens/simulator/settings';
import theme from 'mobile/latest/theme';
import SwitchSensorStack from '../SwitchSensor/SwitchSensorStack';
import InitialSetupG6Stack from './InitialSetupG6Stack';
import { selectShowNewVersionFeatures } from 'web/store/reducers/featureFlags/featureFlags.helpers';
import { useSelector } from 'react-redux';

const ManageSensorG6Stack = () => {
  const Stack = createStackNavigator();

  const { languageContent } = useLanguageContent(strings);
  const defaultHeaderStyle = theme.layout.defaultHeaderStyle;
  const showSwitchSensor = useSelector(selectShowNewVersionFeatures);

  return (
    <Stack.Navigator initialRouteName="ManageSensorG6Stack" mode="card">
      <Stack.Screen
        name="ManageSensorG6"
        options={{
          title: `${languageContent.manageSensorIOS}`,
          headerStyle: defaultHeaderStyle,
        }}
        component={CGMTransmitterSettingsScreen}
      />
      {showSwitchSensor && (
        <Stack.Screen
          name="SwitchSensorStack"
          options={{
            headerShown: false,
          }}
          component={SwitchSensorStack}
        />
      )}

      <Stack.Screen
        name="InitialSetupG6Stack"
        options={{
          headerShown: false,
        }}
        component={InitialSetupG6Stack}
      />
    </Stack.Navigator>
  );
};

export default ManageSensorG6Stack;
