const strings = {
  'en-US': {
    lineUp: 'Line up the QR code in the green frame.',
    photoAuto: 'Photo is taken automatically.',
    tip: 'Tip: Tap Green frame to focus if needed.',
    cancel: 'Cancel',
  },
  'es-US': {
    lineUp: 'Alinee el código QR dentro del recuadro verde.',
    photoAuto: 'La foto se toma automáticamente.',
    tip: 'Consejo: Toque el recuadro verde para enfocar, si fuera necesario.',
    cancel: 'Cancelar',
  },
};

export { strings };
