import React, { useState } from 'react';
import classNames from 'classnames';

interface ToggleSwitchProps {
  onToggle?: () => void;
  defaultValue?: boolean;
  readOnly?: boolean;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  onToggle,
  defaultValue = false,
  readOnly = false,
}) => {
  const [isToggled, setIsToggled] = useState<boolean>(defaultValue);

  const handleOnClick = () => {
    if (readOnly) {
      return;
    }

    if (onToggle) {
      onToggle();
    }
    setIsToggled(!isToggled);
  };

  const containerClasses = classNames(
    'w-[51px] h-[31px] rounded-full p-[2px] flex relative transition-all cursor-pointer',
    {
      'bg-ios-green': isToggled,
      'bg-ios-toggleSwitch/[.16]': !isToggled,
    }
  );
  const innerClasses = classNames(
    'w-[27px] h-[27px] bg-white rounded-full drop-shadow transition-all absolute',
    {
      'translate-x-[20px]': isToggled,
    }
  );

  return (
    <div className={containerClasses} onClick={handleOnClick}>
      <div className={innerClasses} />
    </div>
  );
};
