import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  home: {
    id: 'ios.bottom-navigation.home',
    defaultMessage: 'Home',
  },
  alerts: {
    id: 'ios.bottom-navigation.alerts',
    defaultMessage: 'Alerts',
  },
  history: {
    id: 'ios.bottom-navigation.history',
    defaultMessage: 'History',
  },
  menu: {
    id: 'ios.bottom-navigation.menu',
    defaultMessage: 'Menu',
  },
});
