import PickerItem from "../types/pickerItem";

const BOLUS_SEG_ONE_DATA_TWO: Array<PickerItem> = [];
let counter = 0;

for (let i = 110; i <= 200; i += 10) {
  BOLUS_SEG_ONE_DATA_TWO.push({
    label: `${i}`,
    value: i,
    key: String(i),
    index: ++counter
  });
}

export default BOLUS_SEG_ONE_DATA_TWO;
