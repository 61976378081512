const replaceWithValues = (
  contentString: string,
  variablesArr: Array<string | number>
) => {
  return contentString.replace(/%(\d+)/g, (_: any, n: number) =>
    variablesArr[+n - 1].toString()
  );
};

export default replaceWithValues;
