import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const InsulinCircleIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });

  return (
    <svg
      width="55"
      height="54"
      viewBox="0 0 55 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27.5" cy="26.5" r="26.5" stroke="#E5E5EA" />
      <path
        className={classes}
        id="Path"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.8491 17.6667C28.2461 17.6667 28.5734 17.9655 28.6181 18.3506L28.6233 18.4409V21.7544H37.2234C37.6205 21.7544 37.9477 22.0533 37.9924 22.4383L37.9976 22.5286V33.0267C37.9976 33.4542 37.651 33.8009 37.2234 33.8009C36.8264 33.8009 36.4992 33.502 36.4545 33.117L36.4492 33.0267L36.4487 23.302L28.6233 23.3027V33.0267C28.6233 33.4542 28.2767 33.8009 27.8491 33.8009C27.4521 33.8009 27.1248 33.502 27.0801 33.117L27.0749 33.0267L27.0747 19.2151H19.25V33.0267C19.25 33.4237 18.9511 33.7509 18.5661 33.7957L18.4758 33.8009C18.0787 33.8009 17.7515 33.502 17.7068 33.117L17.7016 33.0267V18.4409C17.7016 18.0438 18.0005 17.7166 18.3855 17.6719L18.4758 17.6667H27.8491ZM21.269 20.7496H25.1399C25.3537 20.7496 25.527 20.9229 25.527 21.1367V33.5238C25.527 33.7376 25.3537 33.9109 25.1399 33.9109H21.269C21.0552 33.9109 20.8819 33.7376 20.8819 33.5238V21.1367C20.8819 20.9229 21.0552 20.7496 21.269 20.7496ZM34.4303 24.6206H30.5593C30.3455 24.6206 30.1722 24.7939 30.1722 25.0077V33.5238C30.1722 33.7376 30.3455 33.9109 30.5593 33.9109H34.4303C34.644 33.9109 34.8174 33.7376 34.8174 33.5238V25.0077C34.8174 24.7939 34.644 24.6206 34.4303 24.6206ZM39.8496 36.6206C39.8496 36.193 39.503 35.8464 39.0754 35.8464H16.6238L16.5335 35.8516C16.1485 35.8963 15.8496 36.2235 15.8496 36.6206C15.8496 37.0482 16.1962 37.3948 16.6238 37.3948H39.0754L39.1657 37.3896C39.5507 37.3448 39.8496 37.0176 39.8496 36.6206Z"
      />
    </svg>
  );
};
