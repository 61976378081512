import { differenceInHours, differenceInMinutes } from "date-fns";
import { isEqual } from "lodash";
import React from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IndicatorAutomated } from "../../../../../../../assets/Indicator_Automated.svg";
import replaceWithValues from "../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { userActions, UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { strings } from "./ProtectScreenContent";
const { height } = Dimensions.get("window");

const ProtectScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const [expHours, setExpHours] = React.useState<any>();
  const [expMinutes, setExpMinutes] = React.useState<any>();

  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);

  const getHeight = () => {
    if (height < 700 && height > 600) return "70%";
    else if (height <= 600) return "65%";
    else if (height > 700) return "100%";
  };

  const getExpHours = () => {
    if (expHours) {
      return expHours;
    } else {
      return "0";
    }
  };

  const getExpMinutes = () => {
    if (expMinutes) {
      return expMinutes;
    } else {
      return "0";
    }
  };

  const contentVariables = [getExpHours(), getExpMinutes()];
  const onHypoProtectConfirmCancel = React.useCallback(() => {
    try {
      dispatch(
        userActions.userUpdate(
          {
            hypoProtectOn: false,
            hypoProtectEnd: ""
          },
          userState
        )
      );
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, userState]);

  const calcRemainingTime = React.useCallback(() => {
    if (!userState.hypoProtectOn) {
      return;
    }

    const now = new Date();
    const hypoEnd = userState.hypoProtectEnd;
    if (hypoEnd) {
      const expires = new Date(hypoEnd);

      const hoursDiff = differenceInHours(expires, now);
      const minDiff = differenceInMinutes(expires, now);

      const hoursInMinutes = hoursDiff * 60;

      //calculate remaining minutes without hours included
      const remainingMinutes = minDiff - hoursInMinutes;

      //if hours and minutes are both 0 or negative, exit hypo protect
      if (hoursDiff < 0 && remainingMinutes <= 0) {
        onHypoProtectConfirmCancel();
      }

      setExpHours(hoursDiff);
      setExpMinutes(remainingMinutes);

      setTimeout(() => {
        calcRemainingTime();
      }, 60000);
    } else {
      onHypoProtectConfirmCancel();
    }
  }, [
    onHypoProtectConfirmCancel,
    userState.hypoProtectEnd,
    userState.hypoProtectOn
  ]);

  React.useEffect(() => {
    calcRemainingTime();
  }, [calcRemainingTime, userState.hypoProtectEnd]);

  React.useEffect(() => {
    calcRemainingTime();
  }, [calcRemainingTime]);

  return (
    <View style={[styles.innerWrapper]}>
      <View style={[styles.card]}>
        <View style={[styles.topBar]}>
          <Text
            style={[
              theme.fonts.text200,
              {
                paddingHorizontal: 8,
                paddingVertical: 4,
                fontWeight: "400"
              }
            ]}>
            {languageContent.IOB}
            <Text style={{ fontWeight: "700" }}>
              {` ${(0.15).toLocaleString(langState.locale)} ${
                languageContent.unit
              }`}
            </Text>
          </Text>
        </View>

        <View
          style={[
            theme.layout.trueCenter,
            {
              flexDirection: "column",
              flex: 1
            }
          ]}>
          <View
            style={{
              height: "70%",
              marginTop: 2
            }}>
            <IndicatorAutomated height={getHeight()} />
          </View>
          <Text
            style={[theme.fonts.text200, { color: theme.colors.greenText }]}>
            {languageContent.hpInProgress}
          </Text>
        </View>
        <View style={[styles.bottomBar]}>
          <View>
            <Text
              style={[
                { ...theme.fonts.text400 },
                { paddingHorizontal: 6, paddingVertical: 8 }
              ]}>
              {`${replaceWithValues(
                languageContent.remainingHrs,
                contentVariables
              )}`}
            </Text>
          </View>

          <View>
            <TouchableOpacity onPress={() => onHypoProtectConfirmCancel()}>
              <Text
                style={[
                  theme.fonts.text200,
                  {
                    paddingHorizontal: 8,
                    color: theme.colors.purple
                  }
                ]}>
                {languageContent.cancel}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    width: "100%",
    padding: 10
  },
  card: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.white,
    shadowColor: "#888",
    shadowOpacity: 0.7,
    shadowRadius: 6,
    shadowOffset: {
      height: 1,
      width: 0
    },
    elevation: 10
  },
  topBar: {
    width: "100%",
    backgroundColor: theme.colors.white,
    zIndex: 20,
    shadowColor: "#888",
    shadowOpacity: 0.3,
    shadowRadius: 3,
    shadowOffset: {
      height: 2,
      width: 0
    },
    elevation: 6
  },
  bottomBar: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: theme.colors.white,
    zIndex: 20,
    shadowColor: "#888",
    shadowOpacity: 0.3,
    shadowRadius: 3,
    shadowOffset: {
      height: -2,
      width: 0
    },
    elevation: 20
  }
});

export default ProtectScreen;
