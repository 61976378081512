let strings = {
  'en-US': {
    reviewSettings: 'Review Settings',
  },
  'en-GB': {
    reviewSettings: 'Review Settings',
  },
  'nl-NL': {
    reviewSettings: 'Instellingen bekijken',
  },
};

export { strings };