import { isEqual } from "lodash"
import React from "react"
import { StyleSheet, Text, View } from "react-native-web"
import { useSelector } from "react-redux"
import getCurrentHourFormat from "../../../helpers/getCurrentHourFormat"
import { LangState } from "../../../../../web/store/reducers/language"
import { RootState } from "../../../../../web/store/store"
import theme from "../../../theme"

interface Item {
  time: string
  cmgValue: string
  insulinValue: string
  hideBorderBottom?: boolean
}

interface RowProps {
  item: Item
}
const EventRow: React.FC<RowProps> = ({ item }) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual,
  )

  return (
    <View
      style={{
        height: 60,
        justifyContent: "center",
      }}
    >
      <View
        style={[
          styles.header,
          {
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            flex: 1,
            borderBottomColor: item.hideBorderBottom
              ? "transparent"
              : theme.colors.textInputBorder,
            borderBottomWidth: 1,
            marginHorizontal: 8,
          },
        ]}
      >
        <View style={{ width: "20%" }}>
          <Text
            style={[
              {
                fontSize: 14,
                marginLeft: -8,
              },
            ]}
          >
            {getCurrentHourFormat({
              timeStr: item.time,
              is24Hour: langState.is24Hour,
            })}
          </Text>
        </View>
        <View style={[styles.innerContainer, { justifyContent: "center" }]}>
          <Text
            style={{
              ...theme.fonts.body2,
            }}
          >
            {item.cmgValue}
          </Text>
        </View>
        <View style={[styles.innerContainer, { justifyContent: "center" }]}>
          <Text
            style={{
              ...theme.fonts.body2,
              paddingLeft: 72,
              marginRight: -16,
            }}
          >
            {Number(item.insulinValue).toLocaleString(langState.locale)}
          </Text>
        </View>
        <View style={[styles.arrow]} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    padding: 20,
  },
  arrow: {
    width: 72,
    height: 36,
    marginTop: -8,
    alignSelf: "center",
  },
  innerContainer: {
    flex: 2,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
})

export default EventRow
