import { createStackNavigator } from '@react-navigation/stack';
import { BackButton } from '../../../mobile/latest/components/atoms';
import {
  AddNewSensor,
  EnterPairingCode,
  TakePhoto,
  EnterSerialNumber,
  SelectionConfirm,
} from 'web/components/pages';
import theme from '../../../mobile/latest/theme';
import { strings } from 'mobile/latest/routes/simulator/TXSetupStackContent';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { useNavigation } from '@react-navigation/native';

const Stack = createStackNavigator();
const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const InitialSetupG7Stack = (props: { isFTS?: boolean }) => {
  const { isFTS } = props;
  const { languageContent } = useLanguageContent(strings);
  const { navigate } = useNavigation();

  return (
    <Stack.Navigator initialRouteName="InitialSetupG7Stack" mode="card">
      <Stack.Screen
        name="SelectionConfirm"
        options={{
          title: '',
          headerStyle: defaultHeaderStyle,
          headerLeft: () => (
            <BackButton
              text={languageContent.sensorSelect}
              isFTS
              white={false}
            />
          ),
        }}
      >
        {(props) => (
          <SelectionConfirm
            addButtonOnPressHandler={() => navigate('AddNewSensor')}
            notNowButtonOnPressHandler={() =>
              isFTS ? navigate('SensorSelection') : navigate('ManageSensorG7')
            }
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name="AddNewSensor"
        options={{
          title: '',
          headerStyle: defaultHeaderStyle,
          headerLeft: () => (
            <BackButton
              text={languageContent.sensorSelect}
              isFTS
              white={false}
            />
          ),
        }}
        component={AddNewSensor}
      />
      <Stack.Screen
        name="TakePhoto"
        options={{
          title: '',
          headerStyle: defaultHeaderStyle,
          headerLeft: () => (
            <BackButton
              text={languageContent.sensorSelect}
              isFTS
              white={false}
            />
          ),
        }}
        component={TakePhoto}
      />
      <Stack.Screen
        name="EnterPairingCode"
        options={{
          title: '',
          headerStyle: defaultHeaderStyle,
          headerLeft: () => (
            <BackButton
              text={languageContent.sensorSelect}
              isFTS
              white={false}
            />
          ),
        }}
        component={EnterPairingCode}
      />
      <Stack.Screen
        name="EnterSerialNumber"
        options={{
          title: '',
          headerStyle: defaultHeaderStyle,
          headerLeft: () => (
            <BackButton
              text={languageContent.sensorSelect}
              isFTS
              white={false}
            />
          ),
        }}
        component={EnterSerialNumber}
      />
    </Stack.Navigator>
  );
};

export default InitialSetupG7Stack;
