import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { BackButton } from 'mobile/latest/components/atoms';
import theme from 'mobile/latest/theme';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import ReviewSettings from 'web/components/pages/L2/ReviewSettings';
import { LowGlucose } from 'web/components/pages/L2/LowGlucose/LowGlucose';
import { HighGlucose } from 'web/components/pages/L2/HighGlucose/HighGlucose';
import { MissingSensor } from 'web/components/pages/L2/MissingSensor/MissingSensor';
import { ApplySensor } from 'web/components/pages/L2/ApplySensor/ApplySensor';
import { ScanSensor } from 'web/components/pages/L2/ScanSensor/ScanSensor';
import { strings } from './InitialSetupL2Content';
import { SelectionConfirm } from 'web/components/pages';
import { HowToApplyStack } from './HowToApplyStack';
import { HowToScanStack } from './HowToScanStack';
import { useIntl } from 'react-intl';
import { messages } from './InitialSetupL2.messages';
import { OutroCGMScreen } from 'mobile/latest/components/screens';
import { sensors } from 'web/data/sensors';

const Stack = createStackNavigator();
const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

export const L2Paths = {
  reviewL2Settings: 'ReviewL2Settings',
  lowGlucoseSettings: 'ReviewLowGlucose',
  highGlucoseSettings: 'ReviewHighGlucose',
  reviewMissingSensorValues: 'ReviewMissingSensorValeus',
  sensorConfirm: 'SelectionConfirm',
  applySensor: 'ApplySensor',
  howToApply: 'HowToApply',
  scanSensor: 'ScanSensor',
  howToScan: 'HowToScan',
  transmitterOutro: 'OutroCGM',
};

const InitialSetupL2: React.FC = () => {
  const { languageContent } = useLanguageContent(strings);
  const { navigate } = useNavigation();
  const intl = useIntl();
  return (
    <Stack.Navigator initialRouteName="InitialSetupL2" mode="card">
      <Stack.Screen
        name={L2Paths.reviewL2Settings}
        options={{
          title: languageContent.reviewSettings,
          headerStyle: defaultHeaderStyle,
        }}
        component={ReviewSettings}
      />
      <Stack.Screen
        name={L2Paths.lowGlucoseSettings}
        options={{
          title: languageContent.reviewSettings,
          headerStyle: defaultHeaderStyle,
          headerLeft: () => <BackButton navigateTo="SensorSelection" />,
        }}
      >
        {() => <LowGlucose isFTS />}
      </Stack.Screen>
      <Stack.Screen
        name={L2Paths.highGlucoseSettings}
        options={{
          title: languageContent.reviewSettings,
          headerStyle: defaultHeaderStyle,
        }}
      >
        {() => <HighGlucose isFTS />}
      </Stack.Screen>
      <Stack.Screen
        name={L2Paths.reviewMissingSensorValues}
        options={{
          title: languageContent.reviewSettings,
          headerStyle: defaultHeaderStyle,
        }}
      >
        {() => <MissingSensor isFTS />}
      </Stack.Screen>
      <Stack.Screen
        name={L2Paths.sensorConfirm}
        options={{
          title: languageContent.sensorSelect,
          headerStyle: defaultHeaderStyle,
          headerLeft: () => <BackButton />,
        }}
      >
        {() => (
          <SelectionConfirm
            addButtonOnPressHandler={() => navigate(L2Paths.applySensor)}
            notNowButtonOnPressHandler={() => navigate('SensorSelection')}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={L2Paths.applySensor}
        options={{
          title: intl.formatMessage(messages.applySensor),
          headerStyle: defaultHeaderStyle,
        }}
        component={ApplySensor}
      />
      <Stack.Screen
        name={L2Paths.howToApply}
        options={{
          title: intl.formatMessage(messages.applyingSensor),
          headerStyle: defaultHeaderStyle,
        }}
        component={HowToApplyStack}
      />
      <Stack.Screen
        name={L2Paths.scanSensor}
        options={{
          title: intl.formatMessage(messages.scanSensor),
          headerStyle: defaultHeaderStyle,
        }}
        component={ScanSensor}
      />
      <Stack.Screen
        name={L2Paths.howToScan}
        options={{
          title: intl.formatMessage(messages.scanningSensor),
          headerStyle: defaultHeaderStyle,
        }}
        component={HowToScanStack}
      />
      <Stack.Screen
        name={L2Paths.transmitterOutro}
        options={{
          title: '',
          headerStyle: defaultHeaderStyle,
          headerLeft: () => (
            <BackButton
              text={intl.formatMessage(messages.transmitterOutro)}
              white={false}
              isFTS
            />
          ),
        }}
      >
        {() => <OutroCGMScreen sensor={sensors.L2} />}
      </Stack.Screen>
    </Stack.Navigator>
  );
};

export default InitialSetupL2;
