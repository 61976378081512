const strings = {
  "en-US": {
    switchMode: "Switch Mode",
    setTempBasal: "Set Temp Basal",
    activity: "Activity",
    pod: "Pod",
    enterBG: "Enter BG",
    pauseInsulin: "Pause Insulin",
    startInsulin: "Start Insulin",
    manage: "MANAGE PROGRAMS & PRESETS",
    manageSensor: "Manage Sensor",
    basalPrograms: "Basal Programs",
    tempBasal: "Temp Basal Presets",
    history: "History",
    historyDetail: "History Detail",
    notifications: "Notifications",
    manageOmnipod: "MANAGE",
    viewers: "Viewers",
    settings: "Settings",
    general: "General",
    reminders: "Reminders",
    glucoseGoal: "Glucose Goal Range",
    basalAndTemp: "Basal & Temp Basal",
    bolus: "Bolus",
    about: "About"
  },
  "en-GB": {
    switchMode: "Switch Mode",
    setTempBasal: "Set Temp Basal",
    activity: "Activity",
    pod: "Pod",
    enterBG: "Enter BG",
    pauseInsulin: "Pause Insulin",
    startInsulin: "Start Insulin",
    manage: "MANAGE PROGRAMS & PRESETS",
    manageSensor: "Manage Sensor",
    basalPrograms: "Basal Programs",
    tempBasal: "Temp Basal Presets",
    history: "History",
    historyDetail: "History Detail",
    notifications: "Notifications",
    manageOmnipod: "MANAGE",
    viewers: "Viewers",
    settings: "Settings",
    general: "General",
    reminders: "Reminders",
    glucoseGoal: "Glucose Goal Range",
    basalAndTemp: "Basal & Temp Basal",
    bolus: "Bolus",
    about: "About"
  },
  "es-US": {
    switchMode: "Cambiar el modo",
    setTempBasal: "Establecer basal temporal",
    activity: "Actividad",
    pod: "Pod",
    enterBG: "Introducir la GS",
    pauseInsulin: "Pausar la insulina",
    startInsulin: "Iniciar la insulina",
    manage: "ADMINISTRAR LOS PROGRAMAS Y PREAJUSTES",
    manageSensor: "Administrar sensor",
    basalPrograms: "Programas basales",
    tempBasal: "Preajustes de basal temporal",
    history: "Historial",
    historyDetail: "Detalle del historial",
    notifications: "Notificaciones",
    manageOmnipod: "MANEJAR",
    viewers: "Observadores",
    settings: "Ajustes",
    general: "General",
    reminders: "Recordatorios",
    glucoseGoal: "Intervalo del objetivo de glucosa",
    basalAndTemp: "Basal & basal temporal",
    bolus: "Bolo",
    about: "Acerca de"
  },
  "de-DE": {
    switchMode: "Modus umschalten",
    setTempBasal: "Temporäre Basalrate \nfestlegen",
    activity: "Aktivität",
    pod: "Pod",
    enterBG: "BZ eingeben",
    pauseInsulin: "Insulin unterbrechen",
    startInsulin: "Insulin starten",
    manage: "PROFILE UND VORGABEWERTE \nVERWALTEN",
    manageSensor: "Sensor verwalten",
    basalPrograms: "Basalratenprofile",
    tempBasal: "Vorgabewerte für temp. \nBasalrate",
    history: "Verlauf",
    historyDetail: "Verlaufsdetail",
    notifications: "Meldungen",
    manageOmnipod: "VERWALTEN",
    viewers: "Betrachter",
    settings: "Einstellungen",
    general: "Allgemein",
    reminders: "Erinnerungshinweise",
    glucoseGoal: "Glukose-Zielbereich",
    basalAndTemp: "Basal- und temporäre \nBasalrate",
    bolus: "Bolus",
    about: "Infos"
  },
  "nl-NL": {
    switchMode: "Modus wijzigen",
    setTempBasal: "Tijdelijke \nbasaalsnelheid instellen",
    activity: "Activiteit",
    pod: "Pod",
    enterBG: "BG invoeren",
    pauseInsulin: "Insuline pauzeren",
    startInsulin: "Insuline starten",
    manage: "PROGRAMMA'S EN \nVOORINSTELLINGEN BEHEREN",
    manageSensor: "Sensor beheren",
    basalPrograms: "Basalprogramma's",
    tempBasal: "Voorinstellingen van \ntijdelijke basaalsnelheid",
    history: "GESCHIEDENIS",
    historyDetail: "Detail geschiedenis",
    notifications: "Kennisgevingen",
    settings: "Instellingen",
    general: "Algemeen",
    reminders: "Herinneringen",
    glucoseGoal: "Glucosedoelbereik",
    basalAndTemp: "Basaal en tijdelijke \nbasaalsnelheid",
    bolus: "Bolus",
    about: "Over"
  },
  "fr-FR": {
    switchMode: "Changer de mode",
    setTempBasal: "Définir le débit basal temporaire",
    activity: "Activité",
    pod: "Pod",
    enterBG: "Saisir la glycémie",
    pauseInsulin: "Mettre en pause l\'administration d\'insuline",
    startInsulin: "Démarrer l\'administration de l\'insuline",
    manage: "Gérer les programmes et les préréglages",
    manageSensor: "Gérer le Capteur",
    basalPrograms: "Programmes basaux",
    tempBasal: "Préréglages de débit basal temporaire",
    history: "Historique",
    historyDetail: "Détails de l\'historique",
    notifications: "Notifications",
    manageOmnipod: "Gérer le Capteur",
    settings: "Réglages",
    general: "Général",
    reminders: "Rappels",
    glucoseGoal: "Plage d\'objectifs glycémiques",
    basalAndTemp: "Débit basal et débit basal temporaire",
    bolus: "Bolus",
    about: "À propos"
  },
};

export { strings };