import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from 'web/components/ios/Button';
import { SendingInstructionModal } from 'web/components/ios/modals/GlobalModals/GlobalModals';
import { hideModal, setCurrentModal, showModal } from 'web/store/reducers/modals/modalsSlice';
import { durationOfInsulinMessages } from '../BolusSettings.messages';
import { BolusSettingsRoutesEnum } from '../types';

export const DurationOfInsulin: React.FC = () => {
  const { navigate } = useNavigation();
  const dispatch = useDispatch();

  const handlePodCommunicationTimeout = () => {
    dispatch(hideModal());
    navigate(BolusSettingsRoutesEnum.BolusSettingsMain);
  };

  const handleSave = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <SendingInstructionModal onTimeout={handlePodCommunicationTimeout} />
        ),
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="bg-white h-full flex flex-col justify-between pt-[29px] pb-[34px]">
      <div className="px-2">
        <div className="mb-[22px]">
          <FormattedMessage {...durationOfInsulinMessages.text} />
        </div>

        <div className="flex flex-row justify-between items-center border-b-1 border-b-ios-gray-9/[.36] py-1">
          <div>
            <div><FormattedMessage {...durationOfInsulinMessages.valueName} /></div>
            <div className="text-ios-gray-9/60 text-small"><FormattedMessage {...durationOfInsulinMessages.valueRange} /></div>
          </div>
          <div className="text-modes-automated"><FormattedMessage {...durationOfInsulinMessages.value} /></div>
        </div>
      </div>

      <div className="px-2">
        <Button variant="colored" colorMode="automated" full onClick={handleSave}>
          <FormattedMessage {...durationOfInsulinMessages.cta} />
        </Button>
      </div>
    </div>
  );
};
