let strings = {
  "en-US": {
    yesterday: "Yesterday"
  },
  "en-GB": {
    yesterday: "Yesterday"
  },
  "de-DE": {
    yesterday: "Gestern"
  },
  "es-US": {
    yesterday: "Ayer"
  },
  "fr-FR": {
    yesterday: "Hier"
  },
  "nl-NL": {
    yesterday: "Gisteren"
  }
};

export { strings };
