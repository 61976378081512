import React from 'react';
import { useIntl } from 'react-intl';
import { createStackNavigator } from '@react-navigation/stack';
import { ActivityDuration } from './views/ActivityDuration';
import { TopBar } from 'web/components/ios/TopBar';
import { messages } from './Activity.messages';

const Stack = createStackNavigator();

export const ActivityStack: React.FC = () => {
  const intl = useIntl();
  return (
    <Stack.Navigator
      initialRouteName="ActivityDuration"
      screenOptions={{
        header: (props) => <TopBar {...props} expanded />,
      }}
    >
      <Stack.Screen
        name="ActivityDuration"
        options={{
          title: intl.formatMessage(messages.title),
        }}
        component={ActivityDuration}
      />
    </Stack.Navigator>
  );
};
