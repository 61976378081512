let strings = {
  "en-US": {
    totalBolus: "Total Bolus",
    mealBolus: "Meal Bolus",
    extended: "Extended",
    correctionBolus: "Correction Bolus",
    showValueChangedAlert1: "Value Changed",
    showValueChangedAlert2:
      "Percentage value cannot be above 100%. The value you enter has been rounded down to 100%.",
    now: "Now",
    chooseDuration: "Choose Duration",
    duration: "Duration",
    range: "(%1 to 8 hrs)",
    durationLabelHr: "hr",
    durationLabelHrs: "hrs",
    leftActionText: "Cancel",
    rightActionText: "Confirm",
    ok: "Ok",
    u: "U",
    hrs: "hrs"
  },
  "en-GB": {
    totalBolus: "Total Bolus",
    mealBolus: "Meal Bolus",
    extended: "Extended",
    correctionBolus: "Correction Bolus",
    showValueChangedAlert1: "Value Changed",
    showValueChangedAlert2:
      "Percentage value cannot be above 100%. The value you enter has been rounded down to 100%.",
    now: "Now",
    chooseDuration: "Choose Duration",
    duration: "Duration",
    range: "(%1 to 8 hrs)",
    durationLabelHr: "hr",
    durationLabelHrs: "hrs",
    leftActionText: "Cancel",
    rightActionText: "Confirm",
    ok: "Ok",
    u: "U",
    hrs: "hrs"
  },
  "de-DE": {
    totalBolus: "Gesamtbolus",
    mealBolus: "Mahlzeitenbolus",
    extended: "Verzögert",
    correctionBolus: "Korrekturbolus",
    showValueChangedAlert1: "Wert geändert",
    showValueChangedAlert2:
      "Prozentwert darf nicht über 100 % liegen. Der von Ihnen eingegebene Wert wurde auf 100 % abgerundet.",
    now: "Jetzt",
    chooseDuration: "Dauer auswählen",
    duration: "Dauer",
    range: "(%1 bis 8 Stdn.)",
    durationLabelHr: "Std.",
    durationLabelHrs: "Stdn.",
    leftActionText: "Abbr.",
    rightActionText: "Bestätigen",
    ok: "OK",
    u: "E",
    hrs: "Stdn."
  },
  "es-US": {
    totalBolus: "Bolo total",
    mealBolus: "Bolo de comida",
    extended: "Extendido",
    correctionBolus: "Bolo de corrección",
    showValueChangedAlert1: "Valor cambiado",
    showValueChangedAlert2:
      "El valor del porcentaje no puede ser superior a 100 %. El valor que introdujo se redondeó a 100 %.",
    now: "Ahora",
    chooseDuration: "Elegir duración",
    duration: "Duración",
    range: "(%1 a 8 horas)",
    durationLabelHr: "h",
    durationLabelHrs: "h",
    leftActionText: "Cancelar",
    rightActionText: "Confirmar",
    ok: "OK",
    u: "U",
    hrs: "h"
  },
  "fr-FR": {
    totalBolus: "Bolus total",
    mealBolus: "Bolus repas",
    extended: "Prolongé",
    correctionBolus: "Bolus de correction",
    showValueChangedAlert1: "Valeur modifiée",
    showValueChangedAlert2:
      "Le pourcentage ne peut pas être supérieur à 100 %. La valeur que vous avez saisie a été arrondie à 100 %.",
    now: "Maintenant",
    chooseDuration: "Choisir la durée",
    duration: "Durée",
    range: "(%1 à 8 h)",
    durationLabelHr: "h",
    durationLabelHrs: "h",
    leftActionText: "Annuler",
    rightActionText: "Confirmer",
    ok: "Ok",
    u: "U",
    hrs: "h"
  },
  "nl-NL": {
    totalBolus: "Totale bolus",
    mealBolus: "Maaltijdbolus",
    extended: "Verlengd",
    correctionBolus: "Correctiebolus",
    showValueChangedAlert1: "Waarde gewijzigd",
    showValueChangedAlert2:
      "Percentage kan niet hoger zijn dan 100%. De door u ingevoerde waarde is omlaag afgerond naar 100%.",
    now: "Nu",
    chooseDuration: "Kies de duur",
    duration: "Duur",
    range: "(%1 tot 8 u.)",
    durationLabelHr: "u.",
    durationLabelHrs: "u.",
    leftActionText: "Annuleren",
    rightActionText: "Bevestigen",
    ok: "OK",
    u: "E",
    hrs: "u."
  }
};

export { strings };
