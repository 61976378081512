let strings = {
  "en-US": {
    mealBolus: "Meal Bolus: %1 U",
    sensor: "Sensor (%2)",
    glucose: "Glucose",
    useSensor: "USE Sensor",
    correctionBolus: "Correction Bolus: %3 U",
    totalBolus: "Total Bolus",
    calc: "CALCULATIONS",
    adjusted: "Adjusted for IOB of %4 U",
    IOBOne: "IOB of %3 U",
    cancel: "Cancel",
    confirm: "Confirm",
    ok: "Ok",
    carbsModalTitle: "Carbs",
    carbsModalMessage:
      "Begin by entering the grams of carbs you are about to eat into the Carbs field.",
    sensorModalTitle: "Glucose / Sensor",
    sensorModalMessage:
      "Tap USE SENSOR to use the current Sensor value and trend, or you can manually enter your blood glucose (BG).",
    linkModalTitle: "Sensor is now linked to Bolus",
    linkModalMessage:
      "The SmartBolus does all of the work of calculating bolus for you.",
    lowGlucoseModalTitle: "Glucose Value Too Low",
    lowGlucoseModalMessage:
      "You must set a glucose value greater than, or equal to %5 in order for the calculation to work in the simulator.",
    exceedsMaxCarbsTitle: "Exceeds Maximum Carbs",
    exceedsMaxCarbsMessage: "Carbs entry cannot be more than 255\u00A0g.",
    exceedsMaxBolusTitle: "Exceeds Maximum Bolus",
    exceedsMaxBolusMessageBegin:
      "A Total Bolus of %6\u00A0U is not allowed because it exceeds your Maximum Bolus of %6\u00A0U.",
    okCaps: "OK",
    u: "U",
    extend: "Extend Bolus",
    high: "HIGH",
    carbs: "Carbs"
  },
  "en-GB": {
    mealBolus: "Meal bolus %1 U",
    sensor: "Sensor (%2)",
    glucose: "Glucose",
    useSensor: "USE Sensor",
    correctionBolus: "Correction bolus %3 U",
    totalBolus: "Total Bolus",
    calc: "CALCULATIONS",
    adjusted: "Adjusted for IOB %4 of U",
    IOBOne: "IOB of %3 U",
    cancel: "Cancel",
    confirm: "Confirm",
    ok: "Ok",
    carbsModalTitle: "Carbs",
    carbsModalMessage:
      "Begin by entering the grams of carbs you are about to eat into the Carbs field.",
    sensorModalTitle: "Glucose / Sensor",
    sensorModalMessage:
      "Tap USE SENSOR to use the current Sensor value and trend, or you can manually enter your blood glucose (BG).",
    linkModalTitle: "Sensor is now linked to Bolus",
    linkModalMessage:
      "The Bolus Calculator does all of the work of calculating a bolus for you.",
    lowGlucoseModalTitle: "Glucose Value Too Low",
    lowGlucoseModalMessage:
      "You must set a glucose value greater than, or equal to %5 in order for the calculation to work in the simulator.",
    exceedsMaxCarbsTitle: "Exceeds Maximum Carbs",
    exceedsMaxCarbsMessage: "Carbs entry cannot be more than 255\u00A0g",
    exceedsMaxBolusTitle: "Exceeds Maximum Bolus",
    exceedsMaxBolusMessageBegin:
      "A Total Bolus of %6\u00A0U is not allowed because it exceeds your Maximum Bolus %6\u00A0U.",
    okCaps: "OK",
    u: "U",
    extend: "Extend Bolus",
    high: "HIGH",
    carbs: "Carbs"
  },
  "de-DE": {
    mealBolus: "Mahlzeitenbolus: %1 E",
    sensor: "BZ (%2)",
    glucose: "Glukose",
    useSensor: "Sensor VERWENDEN",
    correctionBolus: "Korrekturbolus: %3 E",
    totalBolus: "Gesamtbolus",
    calc: "BERECHNUNGEN",
    adjusted: "Angepasst für %4 E AI",
    IOBOne: " %3 E AI",
    cancel: "Abbr.",
    confirm: "Bestätigen",
    ok: "OK",
    carbsModalTitle: "KH",
    carbsModalMessage:
      "Geben Sie zunächst die Gramm an Kohlenhydraten, die Sie essen möchten, in das Feld „Kohlenhydrate“ ein.",
    sensorModalTitle: "Glukose/Sensor",
    sensorModalMessage:
      "Tippen Sie auf „SENSOR VERWENDEN“, um den aktuellen Sensorwert und den Trend zu verwenden, oder geben Sie Ihren Blutzucker (BZ) manuell ein.",
    linkModalTitle: "Sensor ist nun mit dem Bolus verknüpft",
    linkModalMessage: "SmartBolus berechnet den Bolus automatisch für Sie.",
    lowGlucoseModalTitle: "Glukosewert zu niedrig",
    lowGlucoseModalMessage:
      "Sie müssen einen Glukosewert größer oder gleich %5 einstellen, damit die Berechnung im Simulator funktioniert. ",
    exceedsMaxCarbsTitle: "Übersteigt die maximalen Kohlenhydrate",
    exceedsMaxCarbsMessage:
      "Der Eintrag der Kohlenhydrate darf nicht mehr als 255 g betragen.",
    exceedsMaxBolusTitle: "Übersteigt den Maximalbolus",
    exceedsMaxBolusMessageBegin:
      "Ein Gesamtbolus von %6 E ist nicht zulässig, da er den maximalen Bolus von %6 E überschreitet.",
    okCaps: "OK",
    u: "E",
    extend: "Bolus\nVerzögern",
    high: "HOCH",
    carbs: "KH"
  },
  "es-US": {
    mealBolus: "Bolo de comida: %1 U",
    sensor: "GS (%2)",
    glucose: "Glucosa",
    useSensor: "USAR sensor",
    correctionBolus: "Bolo de corrección: %3 U",
    totalBolus: "Bolo total",
    calc: "CÁLCULOS",
    adjusted: "Ajustado para una IOB de %4 U",
    IOBOne: "IOB de %3 U",
    cancel: "Cancelar",
    confirm: "Confirmar",
    ok: "OK",
    carbsModalTitle: "Carbohidratos",
    carbsModalMessage:
      "Comience por ingresar los gramos de carbohidratos que está a punto de comer en el campo de Carbohidratos.",
    sensorModalTitle: "Glucosa/sensor",
    sensorModalMessage:
      "Toque USAR SENSOR para usar el valor y la tendencia actuales del sensor, o puede introducir manualmente el valor de glucosa en sangre (GS).",
    linkModalTitle: "El Sensor ahora está conectado con el bolo",
    linkModalMessage:
      "SmartBolus hace todo el trabajo de calcular el bolo por usted.",
    lowGlucoseModalTitle: "Valor de glucosa demasiado bajo",
    lowGlucoseModalMessage:
      "Debe indicar un valor de glucosa de %5 o más para que el cálculo funcione en el simulador.",
    exceedsMaxCarbsTitle: "Excede los carb. máximos",
    exceedsMaxCarbsMessage:
      "El valor de carbohidratos introducido no puede superar los 225 g",
    exceedsMaxBolusTitle: "Excede el bolo máximo",
    exceedsMaxBolusMessageBegin:
      "La cantidad total del bolo no puede superar el ajuste de bolo máximo %6 U.",
    okCaps: "Ok",
    u: "U",
    extend: "Bolo \nExtendido",
    carbs: "Carbohidratos"
  },
  "fr-FR": {
    mealBolus: "Bolus repas %1 U",
    sensor: "Capteur (%2)",
    glucose: "Glucose",
    useSensor: "UTILISER le capteur",
    correctionBolus: "Bolus de correction : %3 U",
    totalBolus: "Bolus total",
    calc: "CALCULS",
    adjusted: "Ajusté pour l'InA de %4 U",
    IOBOne: "InA de %3 U",
    cancel: "Annuler",
    confirm: "Confirmer",
    ok: "Ok",
    carbsModalTitle: "Glucides",
    carbsModalMessage:
      "Commencez par saisir les grammes de glucides que vous êtes sur le point d'ingérer dans le champ Glucides.",
    sensorModalTitle: "Glucose / Capteur",
    sensorModalMessage:
      "Appuyez sur UTILISER LE CAPTEUR pour utiliser la valeur et la tendance actuelles du capteur, ou vous pouvez saisir manuellement votre glycémie.",
    linkModalTitle: "Le capteur est désormais lié au bolus",
    linkModalMessage:
      "Le SmartBolus se charge de calculer les bolus pour vous.",
    lowGlucoseModalTitle: "Valeur de glycémie trop basse",
    lowGlucoseModalMessage:
      "Vous devez définir une valeur de glycémie supérieure, ou égale à %5 pour que le calcul fonctionne dans le simulateur.",
    exceedsMaxCarbsTitle: "Glucides maximum dépassés",
    exceedsMaxCarbsMessage:
      "Les glucides saisis ne peuvent pas dépasser 255 g.",
    exceedsMaxBolusTitle: "Bolus maximum dépassé",
    exceedsMaxBolusMessageBegin:
      "Un bolus total de %6 U n’est pas permis car cela dépasse votre bolus maximum de %6 U.",
    okCaps: "OK",
    u: "U"
  },
  "nl-NL": {
    mealBolus: "Maaltijdbolus: %1 E",
    sensor: "Sensor (%2)",
    glucose: "Glucose",
    useSensor: "GEBRUIK sensor",
    correctionBolus: "Correctiebolus: %3 E",
    totalBolus: "Totale bolus",
    calc: "BEREKENINGEN",
    adjusted: "Afgestemd op IOB van %4 E",
    IOBOne: "IOB van %3 E",
    cancel: "Annuleren",
    confirm: "Bevestigen",
    ok: "OK",
    carbsModalTitle: "KH",
    carbsModalMessage:
      "Begin met het invoeren van de grammen koolhydraten die u gaat eten in het veld Koolhydraten.",
    sensorModalTitle: "Glucose/sensor",
    sensorModalMessage:
      "Tik op GEBRUIK SENSOR om de huidige sensorwaarde en trend te gebruiken, of u kunt uw bloedglucose (BG) handmatig invoeren.",
    linkModalTitle: "Sensor is nu gelinkt aan Bolus",
    linkModalMessage:
      "De SmartBolus doet al het werk om de bolus voor u te berekenen.",
    lowGlucoseModalTitle: "Glucosewaarde te laag",
    lowGlucoseModalMessage:
      "U moet een glucosewaarde groter dan of gelijk aan %5 instellen om de berekening in de simulator te laten werken.",
    exceedsMaxCarbsTitle: "Overschrijdt maximale KH",
    exceedsMaxCarbsMessage: "De KH-invoer mag niet hoger zijn dan 255 g.",
    exceedsMaxBolusTitle: "Overschrijdt maximale bolus",
    exceedsMaxBolusMessageBegin:
      "Een totale bolus van %6 E is niet toegestaan omdat deze uw maximale bolus van %6 E overschrijdt.",
    okCaps: "OK",
    u: "E",
  }
};

export { strings };
