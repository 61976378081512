let strings = {
  "en-US": {
    setupComplete: "Setup is complete!",
    setUpNewPod: "Set Up New Pod",
    notNow: "Not Now",
    modalTitle: "Disabled for Simulator",
    modalMessage:
      "This button has been disabled for simulation. Press 'Set Up New Pod' to continue.",
    confirmText: "Ok"
  },
  "en-GB": {
    setupComplete: "Set-up is complete!",
    setUpNewPod: "Set Up New Pod",
    notNow: "Not Now",
    modalTitle: "Disabled for Simulator",
    modalMessage:
      "This button has been disabled for simulation. Press 'Set Up New Pod' to continue.",
    confirmText: "Ok"
  },
  "de-DE": {
    setupComplete: "Einrichtung abgeschlossen!",
    setUpNewPod: "Neuen Pod einrichten",
    notNow: "Jetzt nicht",
    modalTitle: "Für Simulator deaktiviert",
    modalMessage:
      "Diese Schaltfläche wurde für die Simulation deaktiviert. Tippen Sie auf „Neuen Pod einrichten“, um fortzufahren.",
    confirmText: "OK"
  },
  "es-US": {
    setupComplete: "¡Se completó la configuración!",
    setUpNewPod: "Configurar un nuevo Pod",
    notNow: "Ahora no",
    modalTitle: "Inhabilitado para el simulador",
    modalMessage:
      "Este botón se inhabilitó para la simulación. Presione 'Configurar un nuevo Pod' para continuar.",
    confirmText: "Aceptar"
  },
  "fr-FR": {
    setupComplete: "Configuration terminée !",
    setUpNewPod: "Configurer un nouveau Pod",
    notNow: "Pas maintenant",
    modalTitle: "Désactivé pour le simulateur",
    modalMessage:
      "Ce bouton a été désactivé pour la simulation. Appuyez sur « Configurer un nouveau Pod » pour continuer.",
    confirmText: "Ok"
  },
  "nl-NL": {
    setupComplete: "Instellen is voltooid!",
    setUpNewPod: "Nieuwe Pod instellen",
    notNow: "Niet nu",
    modalTitle: "Uitgeschakeld voor simulator",
    modalMessage:
      "Deze knop is uitgeschakeld voor simulatie. Druk op 'Nieuwe Pod' instellen om door te gaan.",
    confirmText: "OK"
  }
};

export { strings };
