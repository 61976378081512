import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import theme from 'mobile/latest/theme';
import { Scenes } from './CarouselViewer';

interface Props {
  sceneMap: Scenes;
  navigationState: {
    index: number;
  };
  jumpTo: (tab: string) => void;
}

const styles = StyleSheet.create({
  tabBar: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 24,
  },
  container: {
    flexDirection: 'row',
  },
  dot: {
    width: 10,
    height: 10,
    backgroundColor: 'transparent',
    borderColor: theme.colors.grayScale.gray400,
    borderWidth: 1,
    borderRadius: 5,
    marginLeft: 4,
    marginRight: 4,
  },
  dotActive: {
    backgroundColor: theme.colors.grayScale.gray400,
    borderColor: theme.colors.grayScale.gray400,
  },
});

export const CarouselDotNavigation: React.FC<Props> = ({
  sceneMap,
  navigationState: { index },
  jumpTo,
}) => {
  const moveTo = (tab: string) => () => jumpTo(tab);

  const renderDots = () =>
    Object.keys(sceneMap).map((key, sceneIndex) => (
      <TouchableOpacity
        onPress={moveTo(key)}
        style={[styles.dot, sceneIndex === index && styles.dotActive]}
      />
    ));

  return (
    <View style={styles.tabBar}>
      <View style={styles.container}>
        {renderDots()}
      </View>
    </View>
  );
};
