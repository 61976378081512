import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';
import { isEqual } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cgmValues from '../../../data/cgmValues.json';
import {
  basalActions,
  BasalState,
} from '../../../../../web/store/reducers/basal';
import {
  bolusActions,
  BolusState,
} from '../../../../../web/store/reducers/bolus';
import { userActions, UserState } from '../../../../../web/store/reducers/user';
import { RootState } from '../../../../../web/store/store.js';

interface Props {
  children: any;
}

const CGMTimerHOC: React.FC<Props> = ({ children }) => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );

  const dispatch = useDispatch();
  const timerRef = React.useRef<any>(null);

  const iterateCGMValues: any = React.useCallback(
    async (interval: number) => {
      const onInsulinPauseCancel = () => {
        try {
          dispatch(
            basalActions.basalUpdate(
              {
                insulinPaused: false,
                insulinPausedTimestamp: '',
              },
              basalState
            )
          );
        } catch (err) {
          console.error(err);
        }
      };

      const checkInsulinPaused = () => {
        if (!basalState.insulinPaused) {
          return;
        }

        const end = basalState.insulinPausedTimestamp;

        if (end) {
          const now = new Date();
          const expires = new Date(end);

          const hoursDiff = differenceInHours(expires, now);
          const minDiff = differenceInMinutes(expires, now);

          const hoursInMinutes = hoursDiff * 60;

          //calculate remaining minutes without hours included
          const reminaingMinutes = minDiff - hoursInMinutes;

          //if hours and minutes are both 0 or negative, exit
          if (hoursDiff < 0 && reminaingMinutes <= 0) {
            onInsulinPauseCancel();
          }
        } else {
          onInsulinPauseCancel();
        }
      };

      checkInsulinPaused();

      const restartTimer = () => {
        return iterateCGMValues(2000);
      };

      timerRef.current = setTimeout(() => {
        const diff = differenceInSeconds(
          new Date(),
          new Date(bolusState.timestamp)
        );
        clearTimeout(timerRef.current);
        if (diff < 30) {
          return restartTimer();
        }

        try {
          //only update pod insulin if the user is in automated mode
          const newPodInsulin = userState.automatedMode
            ? (bolusState.podInsulin - bolusState.cgmInfo.Delivery).toFixed(2)
            : bolusState.podInsulin;

          const currentIndex = bolusState.currentIndex;

          let newIdx = currentIndex === 239 ? 0 : currentIndex + 1;

          /**
           * put the user in limited mode after 10 iterations and mark it seen, so it doesn't appear again
           * else if the user is in limted mode next iteration puts them back in automated mode
           */
          if (
            newIdx % 10 === 0 &&
            userState.automatedMode &&
            !userState.limitedModeSeen
          ) {
            dispatch(
              userActions.userUpdate(
                {
                  limitedMode: false,
                },
                userState
              )
            );
          } else if (userState.limitedMode && newIdx % 10 !== 0) {
            dispatch(userActions.userUpdate({ limitedMode: false }, userState));
          }

          //update bolus state with new CGM info

          dispatch(
            bolusActions.bolusUpdate(
              {
                podInsulin: Number(newPodInsulin),
                currentIndex: newIdx,
                cgmInfo: {
                  Value: cgmValues[newIdx].Value,
                  Trend: cgmValues[newIdx].Trend as any,
                  Delivery: cgmValues[newIdx].Delivery,
                },
                timestamp: new Date().toString(),
              },
              bolusState
            )
          );
        } catch (err) {
          console.error(err);
        }
      }, interval);
    },
    [bolusState, dispatch, userState, basalState]
  );

  React.useEffect(() => {
    // const diff = differenceInSeconds(
    //   new Date(),
    //   new Date(bolusState.timestamp)
    // );
    // setCurrentDiff(diff)

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [iterateCGMValues]);

  return <>{children}</>;
};

export default CGMTimerHOC;
