import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  lowGlucoseTitle: {
    id: 'L2.settings.low-glucose.title',
    defaultMessage: 'Low Glucose'
  },
  lowGlucoseNotify: {
    id: 'L2.settings.low-glucose.notification',
    defaultMessage: 'Notify when glucose goes below this level'
  },
  glucoseRange: {
    id: 'L2.settings.glucose-range',
    defaultMessage: '(3.3 mmol/L to 5.5 mmol/L)'
  }
})