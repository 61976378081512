import { FormattedMessage } from 'react-intl';
import { historyEventSwitchModeMessages } from './HistoryEventSwitchMode.messages';

interface HistoryEventSwitchModeProps {
  time: string;
  operationMode: string;
}
export const HistoryEventSwitchMode: React.FC<HistoryEventSwitchModeProps> = ({
  time,
  operationMode,
}) => {
  return (
    <div
      className={`px-2 bg-modes-${operationMode} py-1 border-b border-b-ios-gray-7`}
    >
      <p className="text-regular text-white">
        {operationMode === 'automated' && (
          <FormattedMessage
            {...historyEventSwitchModeMessages.automatedModeTitle}
          />
        )}
        {operationMode === 'manual' && (
          <FormattedMessage
            {...historyEventSwitchModeMessages.manualModeTitle}
          />
        )}
      </p>
      <div className="flex text-small">
        <p className="mr-0.5 text-ios-gray-10 opacity-60">{time}</p>
      </div>
    </div>
  );
};
