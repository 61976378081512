import {
  ApplyStep1,
  ApplyStep2,
  ApplyStep3,
  ApplyStep4,
  ApplyStep5,
  ApplyStep6,
  ApplyStep7,
  ApplyStep8,
  ApplyStep9,
  ApplyStep10,
} from 'web/components/pages/L2/ApplySteps/ApplyStepScenes';
import { CarouselViewer } from 'web/components/helpers/CarouselViewer/CarouselViewer';

export const HowToApplyStack = () => {
  const sceneMap = {
    ApplyStep1,
    ApplyStep2,
    ApplyStep3,
    ApplyStep4,
    ApplyStep5,
    ApplyStep6,
    ApplyStep7,
    ApplyStep8,
    ApplyStep9,
    ApplyStep10,
  };

  return <CarouselViewer sceneMap={sceneMap} />;
};
