import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { ListHeader } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import SegmentListItem from "../../../../../molecules/segmentListItem";
import { strings } from "./CorrectionFactorSegmentsConfirmScreenContent";
import { BolusState } from "../../../../../../../../web/store/reducers/bolus";
import { UserState } from "../../../../../../../../web/store/reducers/user";
import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat";
const CorrectionFactorSegmentsConfirmScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );

  const { languageContent } = useLanguageContent(strings);
  const { navigate } = useNavigation();

  return (
    <View style={styles.container}>
      <View>
        <Text
          style={[
            theme.fonts.body1,
            { marginVertical: 24, paddingHorizontal: 16 }
          ]}>
          {languageContent.review}
        </Text>
        <ListHeader numOfSegments="2" />
        <SegmentListItem
          onEditPress={() => navigate("CorrectionFactorFirstSegment")}
          index={1}
          segmentLabel={`${languageContent.segment} 1:`}
          startTime={String(
            getCurrentHourFormat({
              timeStr: "12:00 am",
              is24Hour: langState.is24Hour!
            })
          )}
          endTime={String(
            getCurrentHourFormat({
              timeStr: "8:00 am",
              is24Hour: langState.is24Hour
            })
          )}
          firstRateValue={languageContent.oneUnitLowers}
          secondRateValue={`${bolusState.defaultFirstSegment.oneUnitCorrectionFactor?.toLocaleString(
            langState.locale
          )} ${langState.units}`}
        />

        <SegmentListItem
          onEditPress={() => navigate("CorrectionFactorSecondSegment")}
          index={2}
          segmentLabel={`${languageContent.segment} 2:`}
          startTime={String(
            getCurrentHourFormat({
              timeStr: "8:00 am",
              is24Hour: langState.is24Hour!
            })
          )}
          endTime={String(
            getCurrentHourFormat({
              timeStr: "12:00 am",
              is24Hour: langState.is24Hour
            })
          )}
          firstRateValue={languageContent.oneUnitLowers}
          secondRateValue={`${bolusState.defaultSecondSegment.oneUnitCorrectionFactor?.toLocaleString(
            langState.locale
          )} ${langState.units}`}
        />
      </View>
      <BottomInnerNav
        leftActionStyle={{ color: theme.colors.text.cancel }}
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.save}
        rightAction={() => navigate("DurationOfInsulinAction")}
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "#fff"
  },
  segmentHeader: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.timeSegmentsBorder,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    backgroundColor: "#EDF2F6"
  },
  segmentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    marginVertical: 16
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.divider
  }
});

export default CorrectionFactorSegmentsConfirmScreen;
