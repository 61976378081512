import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl,
} from 'react-intl';
import { Modal } from '../../Modal';
import { ConfirmationModalProps } from '../types';
import {
  pauseInsulinConfirmationMessages,
  startInsulinConfirmationMessages,
  timezoneChangeConfirmationMessages,
  didBothAlarmsWorkMessages,
  checkAlarmProgressConfirmationMessages,
} from './GeneralSettingsModal.messages';
import { useEffect } from 'react';
import { selectLanguageIs24Hours } from 'web/store/reducers/language/language.helpers';
import { useSelector } from 'react-redux';

interface CheckAlarmProgressConfirmationModalProps {
  onClose?: () => void;
}

const MODAL_TIMEOUT = 2000;

export const PauseInsulinConfirmationModal: React.FC<
  ConfirmationModalProps
> = ({ onConfirm, onReject }) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage(pauseInsulinConfirmationMessages.title)}
      rightButtonOnClick={onConfirm}
      rightButtonText={intl.formatMessage(
        pauseInsulinConfirmationMessages.yesButton
      )}
      leftButtonOnClick={onReject}
      leftButtonText={intl.formatMessage(
        pauseInsulinConfirmationMessages.noButton
      )}
    >
      <div className="text-left">
        <FormattedMessage {...pauseInsulinConfirmationMessages.text} />
      </div>
      <div className="text-left mt-1">
        <FormattedMessage
          {...pauseInsulinConfirmationMessages.pauseInsulinCancelsText}
        />
      </div>
    </Modal>
  );
};

export const TimezoneChangeConfirmationModal: React.FC<
  ConfirmationModalProps
> = ({ onConfirm, onReject }) => {
  const intl = useIntl();

  const currentDate = new Date();
  const is24Hours = useSelector(selectLanguageIs24Hours);

  return (
    <Modal
      title={intl.formatMessage(timezoneChangeConfirmationMessages.title)}
      rightButtonOnClick={onConfirm}
      rightButtonText={intl.formatMessage(
        timezoneChangeConfirmationMessages.confirmButton
      )}
      leftButtonOnClick={onReject}
      leftButtonText={intl.formatMessage(
        timezoneChangeConfirmationMessages.goBackButton
      )}
    >
      <div className="text-left">
        <FormattedMessage
          {...timezoneChangeConfirmationMessages.reviewTimeCarefullyText}
        />
      </div>
      <div className="text-left mt-1">
        <FormattedMessage
          {...timezoneChangeConfirmationMessages.timezoneText}
        />
      </div>
      <div className="text-left mt-1">
        <FormattedMessage
          {...timezoneChangeConfirmationMessages.currentTimeText}
          values={{
            currentTime: (
              <FormattedTime value={currentDate} hour12={!is24Hours} />
            ),
          }}
        />
      </div>
    </Modal>
  );
};

export const StartInsulinConfirmationModal: React.FC<
  ConfirmationModalProps
> = ({ onConfirm }) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage(startInsulinConfirmationMessages.title)}
      rightButtonText={intl.formatMessage(
        startInsulinConfirmationMessages.yesButton
      )}
      rightButtonOnClick={onConfirm}
      leftButtonText={intl.formatMessage(
        startInsulinConfirmationMessages.noButton
      )}
    >
      <div className="text-left">
        <FormattedMessage {...startInsulinConfirmationMessages.text} />
      </div>

      <div className="text-left mt-1">
        <FormattedMessage {...startInsulinConfirmationMessages.remindedText} />
      </div>
    </Modal>
  );
};

export const DidBothAlarmsWorkConfirmationModal: React.FC<
  ConfirmationModalProps
> = ({ onConfirm, onReject }) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage(didBothAlarmsWorkMessages.title)}
      rightButtonOnClick={onConfirm}
      rightButtonText={intl.formatMessage(didBothAlarmsWorkMessages.yesButton)}
      leftButtonOnClick={onReject}
      leftButtonText={intl.formatMessage(didBothAlarmsWorkMessages.noButton)}
    >
      <div className="text-left">
        <FormattedMessage {...didBothAlarmsWorkMessages.text} />
      </div>
      <div className="text-left mt-1">
        <FormattedMessage {...didBothAlarmsWorkMessages.didPodSoundText} />
      </div>
    </Modal>
  );
};

export const CheckAlarmsProgressConfirmationModal: React.FC<
  CheckAlarmProgressConfirmationModalProps
> = ({ onClose }) => {
  const intl = useIntl();

  useEffect(() => {
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, MODAL_TIMEOUT);
  }, []);

  return (
    <Modal
      title={intl.formatMessage(checkAlarmProgressConfirmationMessages.title)}
    >
      <div className="text-left">
        <FormattedMessage {...checkAlarmProgressConfirmationMessages.text} />
      </div>
      <div className="text-left mt-1">
        <FormattedMessage
          {...checkAlarmProgressConfirmationMessages.appDependingText}
        />
      </div>
      <div className="text-left mt-1">
        <FormattedMessage
          {...checkAlarmProgressConfirmationMessages.hearShortBeepText}
        />
      </div>
    </Modal>
  );
};
