let strings = {
  "en-US": {
    pod: "Pod",
    changePod: "Change Pod"
  },
  "en-GB": {
    pod: "Pod",
    changePod: "Change Pod"
  },
  "de-DE": {
    pod: "Pod",
    changePod: "Pod auswechseln"
  },
  "es-US": {
    pod: "Pod",
    changePod: "Cambiar el Pod"
  },
  "nl-NL": {
    changePod: "Pod vervangen",
    pod: "Pod"
  },
  "fr-FR": {
    pod: "Pod",
    changePod: "Changer le Pod"
  },
};

export { strings };
