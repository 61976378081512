import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'ios.activity.title',
    defaultMessage: 'Activity',
  },
  text: {
    id: 'ios.activity.text',
    defaultMessage:
      'Use the Activity feature during times of increased risk of hypoglycemia. {br} The Activity feature reduces basal insulin delivery and sets basal delivery Target Glucose to 150 mg/dL.',
  },
  duration: {
    id: 'ios.activity.duration',
    defaultMessage: 'Duration',
  },
  ends: {
    id: 'ios.activity.ends',
    defaultMessage: 'Ends: {date}',
  },
  timeRange: {
    id: 'ios.activity.time-range',
    defaultMessage: '(1 hr to 24 hrs)',
  },
  hour: {
    id: 'ios.activity.hour-abbreviation',
    defaultMessage: '{value, plural, one {hr} other {hrs}}',
  },
  confirmButton: {
    id: 'ios.activity.confirm-button',
    defaultMessage: 'Confirm',
  },
});
