let strings = {
  "en-US": {
    range: "(%1 to 2 hrs)",
    pauseDelivery: "How long would you like to pause \ninsulin delivery?",
    pauseInsulin: "Pause Insulin",
    extendedBolusCancelled:
      "All insulin delivery stops during this time. \nYour extended bolus in progress will be \ncanceled.",
    ends: "Ends:",
    hr: "hr",
    hrs: "hrs",
    confirmDurationModalTitle1: "Pause insulin delivery for",
    hours: "hours?",
    hour: "hour?",
    confirmDurationModalMessage:
      "All insulin delivery stops during this time. Your extended bolus in progress will be canceled.",
    dismissText: "No",
    confirmText: "Yes",
    pause: "Pause",
    leftActionText: "cancel",
    duration: "Duration",
    highlighted: "Highlighted"
  },
  "en-GB": {
    range: "(%1 to 2 hrs)",
    pauseDelivery: "How long would you like to pause \ninsulin delivery?",
    pauseInsulin: "Pause Insulin",
    extendedBolusCancelled:
      "All insulin delivery stops during this time. \nYour extended bolus in progress will \nbe cancelled.",
    ends: "Ends:",
    hr: "hr",
    hrs: "hrs",
    confirmDurationModalTitle1: "Pause insulin delivery for",
    hours: "hours?",
    hour: "hour?",
    confirmDurationModalMessage:
      "All insulin delivery stops during this time. Your extended bolus in progress will be cancelled.",
    dismissText: "No",
    confirmText: "Yes",
    pause: "Pause",
    leftActionText: "cancel",
    duration: "Duration",
    highlighted: "Highlighted"
  },
  "de-DE": {
    range: "(%1 bis 2 Stdn.)",
    pauseDelivery: "Wie lange soll die Insulinabgabe \nunterbrochen werden?",
    pauseInsulin: "Insulin unterbrechen",
    extendedBolusCancelled:
      "Alle Insulinabgaben stoppen während \ndieser Zeit. Ihr derzeit laufender \nverzögerter Bolus wird abgebrochen.",
    ends: "Endet:",
    hr: "Std.",
    hrs: "Stdn.",
    confirmDurationModalTitle1: "Insulinabgabe unterbrechen für",
    hours: "Stunden?",
    hour: "Stunde?",
    confirmDurationModalMessage:
      "Alle Insulinabgaben stoppen während dieser Zeit. Ihr derzeit laufender verzögerter Bolus wird abgebrochen.",
    dismissText: "Nein",
    confirmText: "Ja",
    pause: "Unterbrechen",
    leftActionText: "Abbr.",
    duration: "Dauer",
    highlighted: "Hervorgehoben"
  },
  "es-US": {
    range: "(%1 h a 2 h)",
    pauseDelivery:
      "¿Durante cuánto tiempo desea \npausar la administración de \ninsulina?",
    pauseInsulin: "Pausar la insulina",
    extendedBolusCancelled:
      "Toda la administración de insulina se \ndetiene durante este tiempo.",
    ends: "Termina:",
    hr: "h",
    hrs: "h",
    confirmDurationModalTitle1: "Pausar la insulina durante",
    hours: "horas?",
    hour: "hora?",
    confirmDurationModalMessage:
      "Toda la administración de insulina se detuvo durante este tiempo.",
    dismissText: "No",
    confirmText: "Sí",
    pause: "Pausar",
    leftActionText: "cancelar",
    duration: "Duración",
    highlighted: "Resaltado"
  },
  "fr-FR": {
    range: "(%1 à 2 h)",
    pauseDelivery:
      "Pendant combien de temps souhaitez-vous mettre en pause l'administration d'insuline ?",
    pauseInsulin: "Mettre en pause l’administration d’insuline",
    extendedBolusCancelled:
      "Toute administration d'insuline est interrompue pendant ce temps. Votre bolus prolongé en cours sera annulé.",
    ends: "Se termine :",
    hr: "h",
    hrs: "h",
    confirmDurationModalTitle1:
      "Mettre en pause l'administration d'insuline pendant",
    hours: "heures ?",
    hour: "heure ?",
    confirmDurationModalMessage:
      "Toute administration d'insuline est interrompue pendant ce temps. Votre bolus prolongé en cours sera annulé.",
    dismissText: "Non",
    confirmText: "Oui",
    pause: "Pause",
    leftActionText: "annuler",
    duration: "Durée",
    highlighted: "Surligné"
  },
  "nl-NL": {
    range: "(%1 tot 2 u.)",
    pauseDelivery: "Hoelang wilt u de \ninsulinetoediening onderbreken?",
    pauseInsulin: "Insuline pauzeren",
    extendedBolusCancelled:
      "Alle insulinetoediening wordt gedurende deze tijd stopgezet. De toediening van uw verlengde bolus wordt geannuleerd.",
    ends: "Eindigt:",
    hr: "u.",
    hrs: "u.",
    confirmDurationModalTitle1: "Insulinetoediening pauzeren gedurende",
    hours: "uur?",
    hour: "uur?",
    confirmDurationModalMessage:
      "Alle insulinetoediening wordt gedurende deze tijd stopgezet. De toediening van uw verlengde bolus wordt geannuleerd.",
    dismissText: "Nee",
    confirmText: "Ja",
    pause: "Pauzeren",
    leftActionText: "annuleren",
    duration: "Duur",
    highlighted: "Gemarkeerd"
  }
};

export { strings };
