import { Button, Paragraph } from 'mobile/latest/components/atoms';
import theme from 'mobile/latest/theme';
import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { strings } from './SelectionConfirmContent';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { SensorState } from 'web/store/reducers/sensor';
import { RootState } from 'web/store/store';
interface Props {
  addButtonOnPressHandler: () => void;
  notNowButtonOnPressHandler: () => void;
}

const SelectionConfirm: React.FC<Props> = ({
  addButtonOnPressHandler,
  notNowButtonOnPressHandler,
}) => {
  const sensorState: SensorState = useSelector(
    (state: RootState) => state.sensor,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={theme.fonts.h4}>{languageContent.title}</Text>
        <Paragraph
          styles={styles.paragraph}
          variant="body3"
          label={`${languageContent.addSensor}${sensorState.selectedSensorFlow}${languageContent.addSensorTwo}`}
        />
      </View>
      <View style={styles.buttonsContainer}>
        <Button variant="primary" onPress={addButtonOnPressHandler}>
          {languageContent.add}
        </Button>
        <Button variant="cancel" onPress={notNowButtonOnPressHandler}>
          {languageContent.notNow}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    paddingHorizontal: 16,
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    flex: 4,
  },
  paragraph: {
    marginTop: 20,
  },
  buttonsContainer: {
    flex: 1,
    justifyContent: 'space-evenly',
    marginBottom: 10,
  },
});

export default SelectionConfirm;
