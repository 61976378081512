let strings = {
  "en-US": {
    cancel: "CANCEL",
    done: "DONE",
    hrs: "hrs",
    hr: "hr"
  },
  "en-GB": {
    cancel: "CANCEL",
    done: "DONE",
    hrs: "hrs",
    hr: "hr"
  },
  "de-DE": {
    cancel: "ABBR.",
    done: "FERTIG",
    hrs: "Stdn.",
    hr: "Std."
  },
  "es-US": {
    cancel: "CANCELAR",
    done: "LISTO",
    hrs: "h",
    hr: "h"
  },
  "fr-FR": {
    cancel: "ANNULER",
    done: "TERMINÉ",
    hrs: "h",
    hr: "h"
  },
  "nl-NL": {
    cancel: "ANNULEREN",
    done: "KLAAR",
    hrs: "u.",
    hr: "u."
  }
};

export { strings };
