import React from "react"
import { StyleSheet, Text, useWindowDimensions, View } from "react-native"
import { TouchableOpacity } from "react-native-web"
import DateRangeDropdown from "../../../../../assets/history/date_range_dropdown.svg"
import useLanguageContent from "../../../hooks/useLanguageContent"
import theme from "../../../theme"
import { strings } from "./HistoryRangeSelectorContent"

interface Props {
  selectDateRange: (index: number) => void
}

const HistoryRangeSelector: React.FC<Props> = ({ selectDateRange }) => {
  const { languageContent } = useLanguageContent(strings)
  const ranges = [
    `1 ${languageContent.day}`,
    `7 ${languageContent.days}`,
    `14 ${languageContent.days}`,
    `30 ${languageContent.days}`,
    `60 ${languageContent.days}`,
    `90 ${languageContent.days}`,
  ]
  const [dateRanges] = React.useState<string[]>(ranges)

  const [showDropdown, setShowDropdown] = React.useState<boolean>(false)
  const [dropdownHeight, setDropdownHeight] = React.useState<number>(0)

  const { width } = useWindowDimensions()

  const expandCollapseDropdown = () => {
    setDropdownHeight(!showDropdown ? 220 : 0)
    setShowDropdown(!showDropdown)
  }

  return (
    <View
      style={[
        styles.wrapper,
        { left: showDropdown ? (width < 400 ? "70%" : "72%") : "90%" },
      ]}
    >
      <View
        style={
          showDropdown
            ? styles.dropdownStyle
            : [styles.hideDropdownStyle, { right: width < 400 ? 42 : 39 }]
        }
      >
        <TouchableOpacity
          onPress={expandCollapseDropdown}
          style={styles.selectorStyle}
        >
          <View
            style={{
              flexDirection: "row",
            }}
          >
            {showDropdown ? (
              <Text style={styles.label}>{languageContent.selectRange}</Text>
            ) : null}
            <DateRangeDropdown />
          </View>
        </TouchableOpacity>
        {showDropdown ? <View style={[styles.break]} /> : null}
        <View style={{ height: dropdownHeight }}>
          {showDropdown
            ? dateRanges.map((ranges, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    expandCollapseDropdown()
                    selectDateRange(index)
                  }}
                  style={{ paddingVertical: 8, paddingHorizontal: 12 }}
                >
                  <Text>{ranges}</Text>
                </TouchableOpacity>
              ))
            : null}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    position: "absolute",
    zIndex: 21,
    elevation: 9,
    marginTop: 8,
  },
  dropdownStyle: {
    width: 200,
    right: 70,
    elevation: 15,
    marginTop: -4,
    backgroundColor: theme.colors.white,
    shadowColor: "#888",
    shadowRadius: 3,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.7,
  },
  hideDropdownStyle: {
    width: 100,
    elevation: 0,
    marginTop: -4,
    backgroundColor: theme.colors.white,
  },
  selectorStyle: {
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 16,
  },
  label: {
    textAlign: "center",
    alignSelf: "center",
    marginRight: 25,
  },
  arrow: {
    width: 36,
    height: 36,
    marginTop: -8,
    alignSelf: "center",
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.divider,
  },
})

export default HistoryRangeSelector
