let strings = {
  "en-US": {
    sendingLogFiles: "Sending log files",
    logFile:
      "You need a log file code from Customer Care and an unlimited (unmetered) \nWi-Fi connection to send log files.*",
    customerCare: "Customer Care:",
    customerCareNumber: "1-800-591-3455",
    deviceLow:
      "If your device's battery is low, connect it to a power source to send log files.",
    fewMinutes:
      "*Sending log files may take a few minutes. The notification icon clears when the process is complete.",
    leftActionText: "Cancel",
    rightActionText: "Next"
  },
  "en-GB": {
    sendingLogFiles: "Sending log files",
    logFile:
      "You need a log file code from Customer Care and an unlimited (unmetered) \nWi-Fi connection to send log files.*",
    customerCare: "Customer Care:",
    customerCareNumber: "1-800-591-3455",
    deviceLow:
      "If your device's battery is low, connect it to a power source to send log files.",
    fewMinutes:
      "*Sending log files may take a few minutes. The notification icon clears when the process is complete.",
    leftActionText: "Cancel",
    rightActionText: "Next"
  },
  "es-US": {
    sendingLogFiles: "Enviando archivos de registro",
    logFile:
      "Necesita un código del archivo de registro que le haya proporcionado Atención al Cliente y una conexión de Wi-Fi ilimitada para enviar archivos de registro.*",
    customerCare: "Atención al Cliente:",
    customerCareNumber: "1-800-591-3455",
    deviceLow:
      "Si la bateriá de su dispositivo está baja, conéctelo a una fuente de energía para enviar los archivos de registro.",
    fewMinutes:
      "*El envío de archivos de registro puede llevarle unos minutos. El icono de notificación desaparece cuando se completa el proceso.",
    leftActionText: "Cancelar",
    rightActionText: "Siguiente"
  },
  "de-DE": {
    sendingLogFiles: "Protokolldateien werden gesendet",
    logFile:
      "Zum Versenden von Protokolldateien benötigen Sie einen Protokolldateicode vom Kundenservice and eine WLAN-Verbindung.* Wenden Sie sich bitte unter Verwendung der von Ihrem Anbieter bereitgestellten Informationen an unseren Kundenservice.",
    fewMinutes:
      "*Das Senden von Protokolldateien kann einige Minuten dauern. Das Meldungssymbol verschwindet, wenn der Vorgang abgeschlossen ist.",
    leftActionText: "Abbr.",
    rightActionText: "Weiter"
  },
  "nl-NL": {
    sendingLogFiles: "Logbestanden worden verstuurd",
    logFile:
      "U hebt een logbestandcode van de Clientenzorg en een wifi-verbinding nodig om logbestanden te versturen.*",
    customerCare:
      "Neem contact op met de Clientenzorg met de informatie die u van uw leverancier gekregen heeft. Het verzenden van logbestanden kan enkele minuten duren.",
    customerCareNumber: "",
    deviceLow:
      "Het meldingspictogram verdwijnt wanneer het proces voltooid is.",
    fewMinutes: "",
    leftActionText: "Annuleren",
    rightActionText: "Volgende"
  },
  "fr-FR": {
    sendingLogFiles: "Envoi des fichiers journaux en cours",
    logFile: "Vous devez obtenir un code de fichier journal auprès du Service clients et disposer d\'une connexion Wi-Fi illimitée (non restreinte) \npour envoyer les fichiers journaux.",
    customerCare: "Service clients : ",
    customerCareNumber: "1-800-591-3455",
    deviceLow:
      "i la batterie de votre appareil est faible, connectez-le à une source d\'alimentation pour envoyer les fichiers journaux.",
    fewMinutes:
      "*L\'envoi des fichiers journaux peut prendre quelques minutes",
    leftActionText: "Annuler",
    rightActionText: "Suivant"
  },
};

export { strings };
