import { NavigationContainer } from "@react-navigation/native";
import { initializeApp } from "firebase/app";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as PaperProvider } from "react-native-paper";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
/* import { enableExperimentalWebImplementation } from "react-native-gesture-handler"; */

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_ID
};

/* enableExperimentalWebImplementation(true); */
// Initialize Firebase
initializeApp(firebaseConfig);

root.render(
  <React.StrictMode>
    <NavigationContainer>
      <PaperProvider>
        <App />
      </PaperProvider>
    </NavigationContainer>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
