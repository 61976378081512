import { FormattedMessage } from 'react-intl';
import { historyAutoEventMessages } from './HistoryAutoEvent.messages';

interface HistoryAutoEventProps {
  time: string;
  mgValue?: string;
  totalInsulin?: string;
}

export const HistoryAutoEvent: React.FC<HistoryAutoEventProps> = ({
  time,
  mgValue = '--',
  totalInsulin = '0',
}) => {
  return (
    <div className="flex basis-1/2 py-2 min-h-[58px] justify-between items-center w-full px-2 border-b border-b-ios-gray-7">
      <p className="text-regular text-ios-secondaryGray opacity-60">{time}</p>
      <div className="flex basis-1/2 justify-between">
        <p className="text-regular">{mgValue}</p>
        <p className="text-regular">
          {totalInsulin}
          <span className="ml-0.5">
            <FormattedMessage {...historyAutoEventMessages.totalInsulinUnit} />
          </span>
        </p>
      </div>
    </div>
  );
};
