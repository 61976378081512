import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dashboardTabTitle: {
    id: 'tab-stack.tab-title.dashboard',
    defaultMessage: 'DASHBOARD',
    description: 'Main dashboard upper tabs, first tab, all in caps.'
  },
  insulinTabTitle: {
    id: 'tab-stack.tab-title.insulin',
    defaultMessage: 'INSULIN',
    description: 'Main dashboard upper tabs, second tab, all in caps.'
  },
  podInfoTabTitle: {
    id: 'tab-stack.tab-title.pod-info',
    defaultMessage: 'POD INFO',
    description: 'Main dashboard upper tabs, third tab, all in caps.'
  },
})
