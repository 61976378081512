import React, { useState } from 'react';
import { View, Text } from 'react-native';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { useNavigation } from '@react-navigation/core';
import { BottomInnerNav } from 'mobile/latest/components/molecules';
import SerialNumberInput from 'web/components/atoms/serialNumberInput/SerialNumberInput';
import Modal from 'web/components/molecules/modal';
import { ReactComponent as ApplicatorSerial } from 'assets/G7/dexcomG7_applicator_Code.svg';
import G7ManualFlowStyles from 'web/styles/G7ManualFlowStyles';
import ConfirmPairingCode from '../ConfirmPairingCode/ConfirmPairingCode';
import theme from 'mobile/latest/theme';
import { strings } from './EnterPairingCodeContent';

const EnterPairingCode: React.FC = () => {
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);
  const [showApplicatorImg, setShowApplicatorImg] = useState(true);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <View style={G7ManualFlowStyles.container}>
        <View style={G7ManualFlowStyles.textContainerPairingCode}>
          <Text style={theme.fonts.h4}>{languageContent.enter}</Text>
          <View style={G7ManualFlowStyles.paragraph}>
            <Text style={theme.fonts.body3}>{languageContent.pairingCode}</Text>
          </View>
        </View>
        <View style={G7ManualFlowStyles.applicatorContainer}>
          {showApplicatorImg && <ApplicatorSerial width={150} height={150} />}
          <SerialNumberInput
            width={50}
            hideElement={setShowApplicatorImg}
            digits={Array.from(
              languageContent.codeNumber.toString().replace(/\s/g, '')
            )}
          ></SerialNumberInput>
        </View>
        <View style={G7ManualFlowStyles.textContainerSetup}>
          <View style={G7ManualFlowStyles.paragraph}>
            <Text style={theme.fonts.body3}>{languageContent.setUp}</Text>
          </View>
        </View>
      </View>
      <Modal isVisible={showModal}>
        <ConfirmPairingCode
          cancelButtonOnPressHandler={() => setShowModal(false)}
          confirmButtonOnPressHandler={() => navigate('EnterSerialNumber')}
        />
      </Modal>
      <BottomInnerNav
        rightActionDisabled={showApplicatorImg}
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.save}
        leftAction
        leftActionNavigateTo="AddNewSensor"
        rightAction={() => setShowModal(true)}
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </>
  );
};

export default EnterPairingCode;
