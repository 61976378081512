import { Button, Paragraph } from 'mobile/latest/components/atoms';
import theme from 'mobile/latest/theme';
import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { strings } from './AddNewSensorContent';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { useNavigation } from '@react-navigation/core';
import { ReactComponent as ApplicatorQR } from 'assets/G7/dexcomG7_applicator_QR.svg';

const AddNewSensor: React.FC = () => {
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={theme.fonts.h4}>{languageContent.title}</Text>
        <View style={styles.paragraph}>
          <Text style={theme.fonts.body3}>
            {languageContent.tap}
            <Text style={theme.fonts.text600}>{languageContent.takePhoto}</Text>
            {languageContent.toScan}
            {languageContent.qrCode}
          </Text>
        </View>
        <Paragraph
          styles={styles.paragraph}
          variant="body3"
          label={languageContent.manualEnter}
        />
      </View>
      <View style={styles.applicatorContainer}>
        <ApplicatorQR width={150} height={150} />
      </View>
      <View style={styles.buttonsContainer}>
        <Button variant="primary" onPress={() => navigate('TakePhoto')}>
          {languageContent.takePhotoButton}
        </Button>
        <Button variant="G7" onPress={() => navigate('EnterPairingCode')}>
          {languageContent.enterManually}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors.white,
    paddingHorizontal: 16,
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    flex: 2,
  },
  paragraph: {
    marginTop: 10,
  },
  applicatorContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 2,
  },
  buttonsContainer: {
    flex: 1,
    justifyContent: 'space-evenly',
    marginBottom: 10,
  },
});

export default AddNewSensor;
