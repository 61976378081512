import React, { PropsWithChildren } from "react"
import { Text, TouchableOpacity, View } from "react-native"
import { asyncTimeout } from "../helpers"
import theme from "../theme"

export const snackbarTypes = {
  success: "SUCCESS",
  warning: "WARNING",
  error: "ERROR",
  simWarning: "SIM_WARNING",
  clickAbove: "CLICK_ABOVE",
}

export const snackbarLocations = {
  top: "TOP",
  bottom: "BOTTOM",
  underMenu: "UNDER-MENU",
}

interface SnackbarState {
  type: string | null
  location: string
  message: React.ReactNode
}

const initialState: SnackbarState = {
  type: null,
  message: "",
  location: "BOTTOM",
}

interface SnackbarContext {
  showSnackbar: (
    type: SnackbarState["type"],
    message: React.ReactNode,
    location: SnackbarState["location"],
  ) => void
  showing: boolean
}

const SnackbarContext = React.createContext<SnackbarContext>({
  showSnackbar: () => {},
  showing: false,
})

export const SnackbarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [snackbar, setSnackbar] = React.useState<SnackbarState>(initialState)
  const [showing, setShowing] = React.useState<boolean>(false)
  const duration = 5000

  const showSnackbar = (
    type: SnackbarState["type"],
    message: React.ReactNode,
    location: SnackbarState["location"],
  ) => {
    setSnackbar({
      type,
      message,
      location,
    })
    setShowing(true)
  }

  const determineSnackbarColor = () => {
    switch (snackbar.type) {
      case snackbarTypes.simWarning:
        return theme.colors.mango
      case snackbarTypes.warning:
        return theme.colors.mango
      case snackbarTypes.error:
        return theme.colors.red
      case snackbarTypes.clickAbove:
        return theme.colors.modalPurple
      default:
        return theme.colors.green
    }
  }

  const determineSnackbarTextColor = () => {
    if (
      snackbar.type === snackbarTypes.warning ||
      snackbar.type === snackbarTypes.simWarning
    ) {
      return "black"
    } else {
      return "white"
    }
  }

  const determineTopLocation = () => {
    switch (snackbar.location) {
      case snackbarLocations.top:
        return 0
      case snackbarLocations.underMenu:
        return 24
      default:
        return undefined
    }
  }

  const determineBottomLocation = () => {
    switch (snackbar.location) {
      case snackbarLocations.bottom:
        return 8
      default:
        return undefined
    }
  }

  const determineWidth = () => {
    switch (snackbar.type) {
      case snackbarTypes.clickAbove:
        return "75%"
      default:
        return "95%"
    }
  }

  const waitAndCloseSnackbar = async () => {
    await asyncTimeout(duration)
    setShowing(false)
  }

  const initializeSnackback = React.useCallback(() => {
    if (showing) {
      waitAndCloseSnackbar()
    }
  }, [showing])

  React.useEffect(() => {
    initializeSnackback()
  }, [initializeSnackback])

  return (
    <SnackbarContext.Provider value={{ showSnackbar, showing }}>
      {children}
      {showing ? (
        <View
          style={{
            position: "absolute",
            top: determineTopLocation(),
            bottom: determineBottomLocation(),
            width: determineWidth(),
            minWidth: 300,
            zIndex: 999,
            backgroundColor: determineSnackbarColor(),
            paddingVertical: 12,
            paddingHorizontal: 16,
            flexDirection:
              snackbar.type === snackbarTypes.simWarning ? "row" : "column",
            justifyContent:
              snackbar.type === snackbarTypes.simWarning
                ? "space-between"
                : "center",
            alignSelf: "center",
            borderRadius: 5,
          }}
        >
          <Text
            style={[
              theme.fonts.body2,
              {
                color: determineSnackbarTextColor(),
                textAlign: "center",
              },
            ]}
          >
            {snackbar.message}
          </Text>
          {snackbar.type === snackbarTypes.simWarning ? (
            <TouchableOpacity onPress={() => setShowing(false)}>
              <Text
                style={[
                  theme.fonts.body1,
                  {
                    textTransform: "uppercase",
                    fontWeight: "700",
                  },
                ]}
              >
                Okay
              </Text>
            </TouchableOpacity>
          ) : null}
        </View>
      ) : null}
    </SnackbarContext.Provider>
  )
}

export default SnackbarContext
