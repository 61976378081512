import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const PodMenuIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className={classes} width="28" height="28" rx="6"/>
      <rect className={classes} width="16" height="16" transform="translate(6 6)"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0987 6.3186H16.7947C18.9438 6.3186 20.6859 8.06075 20.6859 10.2098V15.2178C20.6859 18.9398 17.6687 21.957 13.9467 21.957C10.2248 21.957 7.20752 18.9398 7.20752 15.2178V10.2098C7.20752 8.06075 8.94967 6.3186 11.0987 6.3186ZM19.2267 10.2098C19.2267 8.86665 18.1379 7.7778 16.7947 7.7778H11.0987C9.75556 7.7778 8.66672 8.86665 8.66672 10.2098V15.2178C8.66672 18.1339 11.0307 20.4978 13.9467 20.4978C16.8628 20.4978 19.2267 18.1339 19.2267 15.2178V10.2098ZM12.1227 8.9778C11.0093 8.9778 10.1067 9.8804 10.1067 10.9938V15.2178C10.1067 17.3386 11.8259 19.0578 13.9467 19.0578C16.0675 19.0578 17.7867 17.3386 17.7867 15.2178V10.9938C17.7867 9.8804 16.8841 8.9778 15.7707 8.9778H12.1227Z"
        fill="white"
      />
    </svg>
  );
};
