import { isEqual } from "lodash";
import React from "react";
import { Text, View } from "react-native";
import { useSelector } from "react-redux";
import { ReactComponent as Reminder } from "../../../../../../../assets/UI_ReminderNotification.svg";
import getCurrentHourFormat from "../../../../../helpers/getCurrentHourFormat";
import replaceWithValues from "../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { DateBanner, NotificationItem } from "../../../../atoms";
import { ConfirmationModal } from "../../../../molecules";
import FilterButton from "../../../../molecules/filterButton";
import { strings } from "./NotificationsScreenContent";

const NotificationsScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);

  const setTomorrowDate = new Date();
  setTomorrowDate.setDate(setTomorrowDate.getDate() + 1);
  const tomorrow = setTomorrowDate.toLocaleDateString(langState.locale, {
    month: "short",
    day: "numeric",
    year: "numeric"
  });

  const setYesterdayDate = new Date();
  setYesterdayDate.setDate(setYesterdayDate.getDate() - 1);
  const yesterday = setYesterdayDate.toLocaleDateString(langState.locale, {
    month: "short",
    day: "numeric",
    year: "numeric"
  });

  const displayModal = () => {
    !userState.isExpert && setShowModal(true);
  };

  const contentVariables: (string | number)[] = [
    getCurrentHourFormat({
      timeStr: "11:27 pm",
      is24Hour: langState.is24Hour
    }) as string | number,
    tomorrow
  ];
  return (
    <View style={{ backgroundColor: "white", height: "100%" }}>
      <FilterButton showModal={displayModal} />
      <DateBanner today={yesterday} />
      <NotificationItem
        title={languageContent.missedBolus}
        icon={<Reminder width="74%" height="74%" />}
        time={
          getCurrentHourFormat({
            timeStr: "5:09 pm",
            is24Hour: langState.is24Hour
          })!
        }
        description={`${
          languageContent.bolusNotDelivered
        } ${getCurrentHourFormat({
          timeStr: "9:00 am",
          is24Hour: langState.is24Hour
        })} - ${getCurrentHourFormat({
          timeStr: "11:00 pm",
          is24Hour: langState.is24Hour
        })}.`}
      />
      <NotificationItem
        title={languageContent.podExpiration}
        icon={<Reminder width="74%" height="74%" />}
        time={
          getCurrentHourFormat({
            timeStr: "4:23 pm",
            is24Hour: langState.is24Hour
          })!
        }
        description={`${replaceWithValues(
          languageContent.podWillExpire,
          contentVariables
        )}.`}
      />
      {showModal ? (
        <ConfirmationModal
          isVisible={showModal}
          title={languageContent.filterModalTitle}
          purple
          message={
            <View>
              <Text
                style={[
                  theme.fonts.body1,
                  {
                    color: theme.colors.white
                  }
                ]}>
                {languageContent.filterModalMessage}
              </Text>
            </View>
          }
          hideDismiss={true}
          confirmText={languageContent.ok}
          onConfirm={() => {
            setShowModal(false);
          }}
        />
      ) : null}
    </View>
  );
};

export default NotificationsScreen;
