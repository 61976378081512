let strings = {
  "en-US": {
    preventAccess: "Prevent unauthorized access",
    reenterPin: "Re-enter your PIN to confirm",
    setPin: "Set a PIN",
    noMatch: "The PIN does not match. Try again.",
    pinNeeded: "A 4-digit PIN is needed to unlock your Omnipod\u00A05."
  },
  "en-GB": {
    preventAccess: "Prevent unauthorised access",
    reenterPin: "Re-enter your PIN to confirm",
    setPin: "Set a PIN",
    noMatch: "The PIN does not match. Try again.",
    pinNeeded: "A 4-digit PIN is needed to unlock your Omnipod\u00A05."
  },
  "de-DE": {
    preventAccess: "Unerlaubten Zugriff verhindern",
    reenterPin: "Geben Sie Ihre PIN erneut ein, um sie zu bestätigen.",
    setPin: "PIN festlegen",
    noMatch: "Die PIN stimmt nicht überein. Erneut versuchen.",
    pinNeeded:
      "Zum Entsperren Ihrer Omnipod 5 App ist ein 4-stelliger PIN-Code erforderlich."
  },
  "es-US": {
    preventAccess: "Impide el acceso no autorizado",
    reenterPin: "Vuelva a ingresar su PIN para confirmar",
    setPin: "Establecer un PIN",
    noMatch: "El PIN no coincide. Vuelva a intentarlo.",
    pinNeeded:
      "Necesita un PIN de 4 dígitos para desbloquear la aplicación Omnipod 5."
  },
  "fr-FR": {
    preventAccess: "Empêche un accès non autorisé",
    reenterPin: "Saisissez de nouveau votre code PIN pour le confirmer",
    setPin: "Définir un code PIN",
    noMatch: "Le code PIN ne correspond pas. Veuillez réessayer.",
    pinNeeded:
      "Un code PIN à 4 chiffres est nécessaire pour débloquer votre Omnipod 5."
  },
  "nl-NL": {
    preventAccess: "Voorkom onbevoegde toegang",
    reenterPin: "Typ uw PIN nogmaals in ter bevestiging",
    setPin: "Stel een PIN in",
    noMatch: "PIN stemt niet overeen. Probeer opnieuw.",
    pinNeeded:
      "U heeft een 4-cijferige PIN nodig om uw Omnipod 5 app te ontgrendelen."
  }
};

export { strings };
