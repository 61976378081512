import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { Animated } from "react-native";
import { BackButton } from "../../components/atoms";
import {
  BolusSettingsScreen,
  CorrectAboveSegmentEditScreen,
  CorrectAboveSettingsScreen,
  CorrectionFactorSegmentEditScreen,
  CorrectionFactorSettingsScreen,
  InsulinDurationSettingsScreen,
  InsulinToCarbSegmentEditScreen,
  MinBGForCalcScreen
} from "../../components/screens";
import { MaxBolusSettingsScreen } from "../../components/screens/simulator/settings";
import {
  CorrectAboveSegmentsScreen,
  InsulinToCarbSettingsScreen
} from "../../components/screens/simulator/settings/bolusSettings";
import { useModeIconSmall } from "../../hooks";
import useLanguageContent from "../../hooks/useLanguageContent";
import theme from "../../theme";
import { strings } from "./BolusSettingsStackContent";

const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const BolusSettingsStack: React.FC = () => {
  const { languageContent } = useLanguageContent(strings);
  const { icon } = useModeIconSmall();

  const animateHeader = React.useCallback(() => {
    const headerRef = new Animated.ValueXY();
    Animated.timing(headerRef, {
      toValue: 0,
      duration: 600,
      useNativeDriver: false
    }).start();
  }, []);

  React.useEffect(() => {
    animateHeader();
  }, [animateHeader]);

  return (
    <>
      <Stack.Navigator initialRouteName="BolusSettingsMain" mode="card">
        <Stack.Screen
          name="BolusSettingsMain"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.bolus} white={false} />
            ),
            headerRight: () => icon
          }}
          component={BolusSettingsScreen}
        />
        <Stack.Screen
          name="BolusSettingsCorrectAbove"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.targetAndCorrect}
                white={false}
                isLongText={true}
              />
            ),
            headerRight: () => icon
          }}
          component={CorrectAboveSettingsScreen}
        />
        <Stack.Screen
          name="BolusSettingsCorrectAboveSegments"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.targetAndCorrect}
                white={false}
                isLongText={true}
              />
            ),
            headerRight: () => icon
          }}
          component={CorrectAboveSegmentsScreen}
        />
        <Stack.Screen
          name="BolusSettingsCorrectAboveSegementEdit"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.targetAndCorrect}
                white={false}
                isLongText={true}
              />
            ),
            headerRight: () => icon
          }}
          component={CorrectAboveSegmentEditScreen}
        />
        <Stack.Screen
          name="BolusSettingsInsulinToCarbSegmentEdit"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.insulinToCarb} white={false} />
            ),
            headerRight: () => icon
          }}
          component={InsulinToCarbSegmentEditScreen}
        />
        <Stack.Screen
          name="BolusSettingsInsulinToCarb"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.insulinToCarb} white={false} />
            ),
            headerRight: () => icon
          }}
          component={InsulinToCarbSettingsScreen}
        />
        <Stack.Screen
          name="BolusSettingsCorrectionFactor"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.correction} white={false} />
            ),
            headerRight: () => icon
          }}
          component={CorrectionFactorSettingsScreen}
        />
        <Stack.Screen
          name="BolusSettingsCorrectionFactorSegmentEdit"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.correction} white={false} />
            ),
            headerRight: () => icon
          }}
          component={CorrectionFactorSegmentEditScreen}
        />
        <Stack.Screen
          name="BolusSettingsInsulinDuration"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.duration}
                white={false}
                isLongText={true}
              />
            ),
            headerRight: () => icon
          }}
          component={InsulinDurationSettingsScreen}
        />
        <Stack.Screen
          name="BolusSettingsMinBGForCalc"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.minimumGlucose}
                white={false}
                isLongText={true}
              />
            ),
            headerRight: () => icon
          }}
          component={MinBGForCalcScreen}
        />
        <Stack.Screen
          name="MaxBolusSettingsScreen"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.maximum} white={false} />
            ),
            headerRight: () => icon
          }}
          component={MaxBolusSettingsScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default BolusSettingsStack;
