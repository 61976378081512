import React, { useEffect } from 'react';
import classNames from 'classnames';
import { StackHeaderProps } from '@react-navigation/stack';
import { ReactComponent as ChevronLeft } from 'assets/ios/chevron-left.svg';
import { ReactComponent as Calendar } from 'assets/ios/calendar.svg';
import { UserState, userActions } from 'web/store/reducers/user';
import { RootState } from 'web/store/store';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { TopBarStatusModes } from './IOSStatusBar';

interface TopBarProps extends StackHeaderProps {
  scene: any;
  expanded?: boolean;
  backMode?: boolean;
  backAndCancelMode?: boolean;
  calendarMode?: boolean;
  editMode?: boolean;
  editModeText?: string;
  editOnClickHandler?: any;
  calendarModeText?: string;
  backTitleClassName?: string;
  variant?: 'Close' | 'Cancel' | 'Back' | 'Expanded';
  rightButtonClasses?: string;
  showRightButton?: boolean;
  hideTitleOverflow?: boolean;
}

// Additional props we use inside "params"
// topBarOnLeftButtonClick -> This will be called when clicking the "Cancel" button (or "Back" when there's only one button).
// topBarOnBackButtonClick -> This will be called when clicking the "Back" button.

export const TopBar: React.FC<TopBarProps> = ({
  scene,
  navigation,
  expanded = false,
  backMode = false,
  backAndCancelMode = false,
  calendarMode = false,
  calendarModeText,
  editMode = false,
  editModeText = 'Edit',
  editOnClickHandler,
  backTitleClassName,
  variant = 'Cancel',
  rightButtonClasses,
  showRightButton = false,
  hideTitleOverflow = true,
}) => {
  const dispatch = useDispatch();
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  const { options } = scene.descriptor;
  const { params } = scene.route;

  const title = options.headerTitle || options.title;

  const combinedRightButtonClasses = classNames(rightButtonClasses, {
    'text-ios-disabled': !params?.topBarRightButtonEnabled,
  });

  const handleCancelClick = () => {
    if (params?.topBarOnLeftButtonClick) {
      return params.topBarOnLeftButtonClick();
    }
    navigation.goBack();
  };

  const handleBackClick = () => {
    if (params?.topBarOnBackButtonClick) {
      return params.topBarOnBackButtonClick();
    }

    navigation.goBack();
  }

  useEffect(() => {
    const updateTopBarStatusMode = (mode: TopBarStatusModes) => {
      dispatch(
        userActions.userUpdate(
          {
            iosStatusBarMode: mode,
          },
          userState
        )
      );
    };

    if (calendarMode || editMode) {
      updateTopBarStatusMode(TopBarStatusModes.BgWhite);
    } else {
      updateTopBarStatusMode(TopBarStatusModes.BgIos);
    }

    return () => {
      updateTopBarStatusMode(TopBarStatusModes.Default);
    };
  }, [backAndCancelMode, calendarMode, dispatch, expanded, userState]);

  if (editMode) {
    return (
      <header className="w-full px-2 pt-1 pb-2.5 bg-white font-ios text-regular">
        <div className="flex justify-between pb-1">
          <button onClick={handleBackClick}>
            <div className="flex flex-row items-center text-regular cursor-pointer">
              <ChevronLeft className="mr-0.5" /> Back
            </div>
          </button>
          <button
            onClick={() => editOnClickHandler()}
            className=" text-ios-blue cursor-pointer mt-1 flex flex-row items-center text-[16px]"
          >
            {editModeText}
          </button>
        </div>
        <div className="font-semibold text-4xl">{title}</div>
      </header>
    );
  }

  if (calendarMode) {
    return (
      <header className="w-full px-2 pb-2.5 bg-white font-ios text-regular">
        <div className="flex justify-end">
          <div className="mt-1 flex flex-row items-center text-[16px]">
            <span className="text-ios-blue">{calendarModeText}</span>
            <Calendar className="ml-1" />
          </div>
        </div>
        <div className="font-semibold text-4xl">{title}</div>
      </header>
    );
  }

  if (backAndCancelMode) {
    if (expanded) {
      return (
        <header className="w-full px-2 pb-2.5 bg-ios-topBar font-ios text-regular shadow">
          <div className="flex justify-between flex-row">
            <button onClick={handleBackClick}>
              <div className="flex flex-row items-center text-regular cursor-pointer">
                <ChevronLeft className="mr-0.5" /> Back
              </div>
            </button>
            <div
              onClick={handleCancelClick}
              className="text-ios-red cursor-pointer"
            >
              Cancel
            </div>
          </div>
          <div className="font-semibold mt-2 text-4xl pl-0.5">{title}</div>
        </header>
      );
    }

    return (
      <header className="grid grid-cols-5 justify-between w-full px-2 pt-[12px] pb-2.5 bg-ios-topBar font-ios text-regular shadow">
        <div className="col-span-1 text-left">
          <button onClick={handleBackClick}>
            <div className="flex flex-row items-center text-regular cursor-pointer">
              <ChevronLeft className="mr-0.5" /> Back
            </div>
          </button>
        </div>
        <div className="col-span-3 font-semibold text-regular text-center text-nowrap text-ellipsis overflow-hidden">
          {title}
        </div>
        <div
          onClick={handleCancelClick}
          className="text-ios-red cursor-pointer col-span-1 text-right"
        >
          Cancel
        </div>
      </header>
    );
  }

  if (expanded) {
    return (
      <header className="w-full px-2 pt-[12px] pb-2.5 bg-ios-topBar font-ios text-regular shadow">
        <div className="text-left mb-[12px]">
          <button onClick={handleCancelClick}>
            {backMode ? (
              <div className="flex flex-row items-center text-regular">
                <ChevronLeft className="mr-0.5" /> Back
              </div>
            ) : (
              <div className="text-ios-red">Cancel</div>
            )}
          </button>
        </div>
        <div className="font-semibold text-4xl">{title}</div>
      </header>
    );
  }

  return (
    <header className="grid grid-cols-5 justify-between w-full px-2 pt-[12px] pb-2.5 bg-ios-topBar font-ios text-regular shadow">
      <div className="col-span-1 text-left">
        <button onClick={handleCancelClick}>
          {backMode ? (
            <div className="flex flex-row items-center text-regular">
              <ChevronLeft className="mr-0.5" /> Back
            </div>
          ) : (
            <div className={backTitleClassName || 'text-ios-red'}>
              {variant}
            </div>
          )}
        </button>
      </div>
      <div
        className={classNames(
          'col-span-3 font-semibold text-regular text-center text-nowrap',
          {
            'text-ellipsis overflow-hidden': hideTitleOverflow,
          }
        )}
      >
        {title}
      </div>
      {showRightButton && (
        <div className="col-span-1 text-right">
          <button
            className={combinedRightButtonClasses}
            disabled={!params?.topBarRightButtonEnabled}
            onClick={params?.topBarOnRightButtonClick}
          >
            Save
          </button>
        </div>
      )}
    </header>
  );
};
