import { useNavigation } from '@react-navigation/native';
import { isEqual } from 'lodash';
import React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSelector } from 'react-redux';
import { ReactComponent as DoNotProceed } from '../../../../../../../../assets/do_not_proceed.svg';
import { ReactComponent as GreenCheckmark } from '../../../../../../../../assets/green_checkmark.svg';
import useLanguageContent from '../../../../../../hooks/useLanguageContent';
import { LangState } from '../../../../../../../../web/store/reducers/language';
import { RootState } from '../../../../../../../../web/store/store';
import theme from '../../../../../../theme';
import { Button } from '../../../../../atoms';
import { strings } from './TrainingScreenContent';

const TrainingScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [accepted, setAccepted] = React.useState(false);

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const handleCheckboxPress = () => {
    setAccepted(!accepted);
  };

  return (
    <ScrollView>
      <View
        style={[
          theme.layout.wrapper,
          { width: '100%', backgroundColor: 'white' },
        ]}
      >
        <View style={[styles.innerWrapper, { width: '100%', marginTop: -4 }]}>
          <View style={{ alignItems: 'center' }}>
            <DoNotProceed width={75} height={75} />
          </View>
          <View style={{ borderWidth: 2, borderColor: 'orange', padding: 5 }}>
            <Text style={[theme.fonts.h5, styles.title, { fontWeight: '600' }]}>
              {languageContent.doNotProceed}
            </Text>
            <Text
              style={[
                theme.fonts.text600,
                { fontWeight: '300', textAlign: 'center', marginBottom: 8 },
              ]}
            >
              {languageContent.completeTraining}
            </Text>
          </View>
          <Text style={[styles.boldListText, { marginBottom: 8 }]}>
            {languageContent.haveYouBeenTrained}
          </Text>
          <Text style={styles.listText}>
            <GreenCheckmark
              width={20}
              height={20}
              style={{ marginBottom: -6, marginRight: 12 }}
            />

            {languageContent.setupPod}
          </Text>
          <Text style={styles.listText}>
            <GreenCheckmark
              width={20}
              height={20}
              style={{ marginBottom: -6, marginRight: 12 }}
            />
            {languageContent.createBasal}
            <Text style={[styles.listText, { marginLeft: 32 }]}>
              {languageContent.createBasalTwo}
            </Text>
          </Text>
          <Text style={styles.listText}>
            <GreenCheckmark
              width={20}
              height={20}
              style={{ marginBottom: -6, marginRight: 12 }}
            />
            {languageContent.calculateBolus}
          </Text>
          <Text style={styles.listText}>
            <GreenCheckmark
              width={20}
              height={20}
              style={{ marginBottom: -6, marginRight: 12 }}
            />
            {languageContent.pauseInsulin}
          </Text>
          <Text style={styles.listText}>
            <GreenCheckmark
              width={20}
              height={20}
              style={{ marginBottom: -6, marginRight: 12 }}
            />

            {languageContent.respondAlerts}
          </Text>
          <Text style={[styles.listText, { marginLeft: 32 }]}>
            {languageContent.notifications}
          </Text>
          <Text style={styles.listText}>
            <GreenCheckmark
              width={20}
              height={20}
              style={{ marginBottom: -6, marginRight: 12 }}
            />

            {languageContent.setupApp}
          </Text>
          <Text style={styles.listText}>
            <GreenCheckmark
              width={20}
              height={20}
              style={{ marginBottom: -6, marginRight: 12 }}
            />

            {languageContent.connectApp}
          </Text>
          <Text style={[styles.listText, { marginLeft: 32 }]}>
            {languageContent.app}
          </Text>
          {langState.language !== 'de-DE' ? (
            <View>
              <Text style={[styles.boldListText, { marginBottom: 16 }]}>
                {languageContent.pleaseSelect}
              </Text>
              <TouchableOpacity
                style={styles.button}
                onPress={() => navigate('ScheduleTraining')}
              >
                <Text style={styles.buttonText}>
                  {languageContent.willSchedule}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button}
                onPress={() => navigate('Acknowledgement')}
              >
                <Text style={styles.buttonText}>
                  {languageContent.amTraining}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button}
                onPress={() => navigate('Acknowledgement')}
              >
                <Text style={styles.buttonText}>
                  {languageContent.completedTraining}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button}
                onPress={() => navigate('Decline')}
              >
                <Text style={styles.buttonText}>
                  {languageContent.declineTraining}
                </Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View>
              <Text
                style={[styles.listText, { marginTop: 12, marginBottom: 16 }]}
              >
                {languageContent.bottomText}
              </Text>
              <Text style={[theme.fonts.text600, { fontWeight: 300 }]}>
                <TouchableOpacity
                  style={[
                    styles.checkbox,
                    { backgroundColor: accepted ? 'black' : 'transparent' },
                  ]}
                  onPress={handleCheckboxPress}
                >
                  {accepted && (
                    <Text
                      style={{
                        ...theme.fonts.text400,
                        color: 'white',
                        marginLeft: 3,
                        marginTop: -2,
                      }}
                    >
                      ✓
                    </Text>
                  )}
                </TouchableOpacity>
              </Text>
              <Text
                style={[
                  styles.subtext,
                  theme.fonts.text600,
                  { fontWeight: 300, marginLeft: 32, marginTop: -28 },
                ]}
              >
                {languageContent.confirmText}
              </Text>
              {accepted ? (
                <Button
                  variant={'primary'}
                  style={{ elevation: 6, width: '100%', marginTop: 40 }}
                  onPress={() => {
                    navigate('AllSet');
                  }}
                >
                  {languageContent.button}
                </Button>
              ) : null}
            </View>
          )}
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 16,
    marginTop: 20,
  },
  bullet: {
    marginRight: 10,
    fontSize: 14,
    lineHeight: 20,
  },
  title: {
    margin: 10,
    textAlign: 'center',
    fontWeight: '200',
  },
  button: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#ccc',
    paddingTop: 16,
    paddingBottom: 16,
    width: '150%',
    marginLeft: -16,
  },
  buttonText: {
    ...theme.fonts.text600,
    color: '#5B34B9',
    marginLeft: 16,
  },
  listText: {
    ...theme.fonts.text600,
    fontWeight: '300',
    textAlign: 'left',
    marginBottom: 4,
  },
  boldListText: {
    ...theme.fonts.text600,
    fontWeight: '600',
    textAlign: 'left',
    paddingTop: 16,
  },
  checkbox: {
    width: 18,
    height: 18,
    borderColor: 'black',
    borderWidth: 1,
    marginRight: 14,
  },
  subtext: {
    fontWeight: '300',
    textAlign: 'left',
    backgroundColor: 'white',
    marginRight: 12,
    fontSize: 14,
  },
});

export default TrainingScreen;
