import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import {
  bolusActions,
  BolusState
} from "../../../../../../../../web/store/reducers/bolus";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { Header, Paragraph } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import { CompoundToggle } from "../../../../../organisms";
import { strings } from "./AllowExtendedBolusScreenContent";

const AllowExtendedBolusScreen: React.FC = () => {
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );

  const { navigate } = useNavigation();
  const dispatch = useDispatch();

  const [fieldValue, setFieldValue] = React.useState<boolean>(
    bolusState.extendedBolus ? bolusState.extendedBolus : false
  );

  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={[styles.container]}>
      <Header
        label={languageContent.allowExBolus}
        styles={{ marginTop: 32, marginBottom: 24, paddingVertical: 0 }}
      />
      <View style={{ paddingHorizontal: 16, flex: 1 }}>
        <Paragraph
          label={languageContent.extending}
          variant="body1"
          styles={{ marginBottom: 16 }}
        />
        <CompoundToggle
          label={languageContent.exBolus}
          connectedStateBooleanValue={fieldValue}
          onToggle={() => setFieldValue(!fieldValue)}
        />
      </View>
      <BottomInnerNav
        leftActionStyle={{ color: theme.colors.text.cancel }}
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.next}
        rightAction={() => {
          dispatch(
            bolusActions.bolusUpdate(
              {
                extendedBolus: fieldValue
              },
              bolusState
            )
          );

          //TODO PICK UP WHERE THIS ENDS
          navigate("PodSetup");
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    height: "100%",
    justifyContent: "space-between"
  },
  inputContainer: {
    marginTop: 16,
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

export default AllowExtendedBolusScreen;
