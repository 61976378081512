let strings = {
  "en-US": {
    setupBasal: "Setup: Basal",
    editBasalProgram: "Edit Basal Program",
    confirmBasal: "Confirm Program"
  },
  "en-GB": {
    setupBasal: "Setup: Basal",
    editBasalProgram: "Edit Basal Programme",
    confirmBasal: "Confirm Programme"
  },
  "de-DE": {
    setup: "Einrichten",
    setupBasal: "Einrichten: Basalrate",
    editBasalProgram: "Einrichten: Basalrate",
    confirmBasal: "Einrichten: Basalrate"
  },
  "es-US": {
    setupBasal: "Configurar: basal",
    editBasalProgram: "Configurar: basal",
    confirmBasal: "Confirmar programa"
  },
  "fr-FR": {
    setupBasal: "Configuration: débit basal",
    editBasalProgram: "Modifier le programme basal",
    confirmBasal: "Confirmer le programme"
  },
};

export { strings };
