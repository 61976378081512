import React from "react";
import { StyleSheet, Text, View } from "react-native";
import replaceWithValues from "../../../helpers/replaceWithValues";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import { strings } from "./WarningMessageBarContent";

interface Props {
  timeSegment?: number;
  insulinDelivery?: boolean;
  Icon?: () => JSX.Element;
  horizontalMargin?: boolean;
}
const WarningMessageBar: React.FC<Props> = ({
  timeSegment,
  insulinDelivery,
  Icon,
  horizontalMargin
}) => {
  const { languageContent } = useLanguageContent(strings);
  const contentVariables = [timeSegment!];

  return (
    <View
      style={[
        styles.warningMessageBarView,
        {
          marginHorizontal: horizontalMargin ? 16 : undefined,
          paddingTop: insulinDelivery ? 8 : undefined
        }
      ]}>
      <View style={{ marginRight: 4, marginBottom: -2 }}>
        {Icon && <Icon />}
      </View>
      {timeSegment ? (
        <View>
          <Text style={styles.WarningMessageBarText}>
            {replaceWithValues(languageContent.timeSeg, contentVariables)}
          </Text>
        </View>
      ) : null}
      {!timeSegment && insulinDelivery ? (
        <View>
          <Text style={styles.WarningMessageBarText}>
            {languageContent.paused}
          </Text>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  warningMessageBarView: {
    flexDirection: "row",
    backgroundColor: theme.colors.yellow,
    alignItems: "flex-end",
    justifyContent: "center",
    paddingTop: 4,
    paddingBottom: 8,
    borderRadius: 5
  },
  WarningMessageBarText: {
    color: theme.colors.text.cancel,
    fontSize: 14,
    fontWeight: "500",
    lineHeight: 16
  }
});

export default WarningMessageBar;
