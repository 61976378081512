import React from 'react';
import { Button } from 'web/components/ios/Button';
import { useState } from 'react';
import { IOSBolusRoutesEnums } from '../types';
import { useNavigation } from '@react-navigation/native';
import { useIntl } from 'react-intl';
import { messages, SetMaximumBolusMessages } from '../Bolus.messages';

export const SetMaximumBolus = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const [isClicked, setIsClicked] = useState(false);

  const onClickHandler = () => {
    setIsClicked(true);
  };

  return (
    <div className="flex flex-col border-t-1 w-full h-full bg-white">
      <div className="basis-2/5 p-6">
        <p className="font-bold text-2xl mb-1">
          {intl.formatMessage(SetMaximumBolusMessages.title)}
        </p>
        <p className="mb-8">
          {intl.formatMessage(SetMaximumBolusMessages.text)}
        </p>
        <div
          onClick={onClickHandler}
          className="flex justify-between items-center w-full pb-2 border-b border-b-ios-gray-7 hover:cursor-pointer"
        >
          <div>
            <div className="flex">
              <p className="text-regular leading-none">
                {intl.formatMessage(SetMaximumBolusMessages.inputLabel)}
              </p>
              <p className="font-semibold ml-0.5 text-xs opacity-[.60] text-ios-gray-9">
                {intl.formatMessage(SetMaximumBolusMessages.inputPlaceholder)}
              </p>
            </div>
            <div>
              <p
                className={`font-semibold mt-2 text-4xl ${isClicked ? 'text-black' : 'opacity-[.30] text-ios-gray-9'}`}
              >
                <span>
                  {isClicked
                    ? '20 U'
                    : intl.formatMessage(
                        SetMaximumBolusMessages.emptyInputValue
                      )}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="basis-9/12">
        <div className=" w-full h-full p-6 flex flex-col-reverse">
          <Button
            onClick={() =>
              isClicked && navigate(IOSBolusRoutesEnums.AllowExtendedBolus)
            }
            variant="colored"
            disabled={!isClicked}
          >
            {intl.formatMessage(messages.nextBtn)}
          </Button>
        </div>
      </div>
    </div>
  );
};
