import { useNavigation } from '@react-navigation/native';
import { useState } from 'react';
import { Button } from 'web/components/ios/Button';
import { IOSBasalRoutesEnums } from '../types';
import { useIntl } from 'react-intl';
import { messages, CreateBasalProgramMessages } from '../Basal.messages';

export const CreateBasalProgram = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const [isClicked, setIsClicked] = useState(false);

  const onClickHandler = () => {
    setIsClicked(true);
  };
  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-2/5 p-6">
        <p className="font-bold text-xl mb-2 text-modes-manual">
          {intl.formatMessage(CreateBasalProgramMessages.inputLabel)}
        </p>
        <div
          onClick={onClickHandler}
          className="flex justify-between items-center w-full mb-1 pb-1 border-b border-b-ios-gray-7 hover:cursor-pointer"
        >
          <div>
            <p
              className={`font-semibold ${isClicked ? '' : 'text-sm text-ios-gray-2'} `}
            >
              {isClicked
                ? intl.formatMessage(messages.basalProgramName)
                : intl.formatMessage(
                    CreateBasalProgramMessages.inputPlaceholder
                  )}
            </p>
          </div>
        </div>
        <p className="font-semibold text-sm text-ios-gray-2">
          {isClicked
            ? `7/25 ${intl.formatMessage(messages.charactersPlaceholder)}`
            : `0/32 ${intl.formatMessage(messages.charactersPlaceholder)}`}
        </p>
      </div>
      <div className="basis-9/12">
        <div className=" w-full h-full p-6 flex flex-col-reverse">
          <Button
            onClick={() =>
              isClicked && navigate(IOSBasalRoutesEnums.SegmentOne)
            }
            variant={isClicked ? 'colored' : 'clear'}
          >
            {intl.formatMessage(messages.nextBtn)}
          </Button>
        </div>
      </div>
    </div>
  );
};
