let strings = {
  "en-US": {
    bolusCalc:
      "You are one step closer to entering Manual Mode. Next, let's connect your Sensor so it can communicate directly with the Pod you've just activated!"
  },
  "en-GB": {
    bolusCalc:
      "You are one step closer to entering Manual Mode. Next, let's connect your Sensor so it can communicate directly with the Pod you've just activated!"
  },
  "de-DE": {
    bolusCalc:
      "Sie sind dem Manuellen Modus einen Schritt näher gekommen. Schließen Sie nun Ihren Sensor an, damit er direkt mit dem Pod kommunizieren kann, den Sie gerade aktiviert haben!"
  },
  "es-US": {
    bolusCalc:
      "Está un paso más cerca de entrar en el Modo Manual. Ahora conectemos el sensor para que pueda comunicarse directamente con el Pod que acaba de activar."
  },
  "fr-FR": {
    bolusCalc:
      "Vous allez bientôt pouvoir entrer en Mode Manuel. Maintenant, connectez votre capteur afin qu'il puisse communiquer directement avec le Pod que vous venez d'activer !"
  },
  "nl-NL": {
    bolusCalc:
      "U bent een stap dichter bij de Handmatige Modus. Sluit vervolgens uw sensor aan, zodat hij rechtstreeks kan communiceren met de Pod die u zojuist hebt geactiveerd!"
  }
};

export { strings };
