import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { useIntl, FormattedMessage } from 'react-intl';
import { ListStep } from 'web/components/ios/ListStep';
import { Button } from 'web/components/ios/Button';
import {
  showModal,
  setCurrentModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import {
  CheckCannulaModal,
  CheckPodModal,
  PodSecureConfirmationModal,
  CannulaInsertionModal,
  CancelPodSetupModal,
  DeactivatePodConfirmModal,
  CannulaInsertedConfirmationModal,
} from 'web/components/ios/modals/ChangePodModals/ChangePodModals';
import { DeactivatePodModal } from 'web/components/ios/modals/GlobalModals/GlobalModals';
import PreparePodGif from 'assets/changePod2.gif';
import { AppRoutesEnum } from 'web/routes/types';
import { checkCannulaMessages } from '../Pod.messages';

export const CheckCannula: React.FC = () => {
  const intl = useIntl();
  const { navigate, setParams } = useNavigation();
  const dispatch = useDispatch();

  const handleCancelPodSetupConfirm = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <DeactivatePodModal
            onTimeout={() => navigate(AppRoutesEnum.dashboard)}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  const handleCancelClick = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <CancelPodSetupModal
            onReject={() => dispatch(hideModal())}
            onConfirm={handleCancelPodSetupConfirm}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  useEffect(() => {
    setParams({ topBarOnLeftButtonClick: handleCancelClick });
  }, [setParams]);

  const handleCheckCannulaButton = () => {
    dispatch(setCurrentModal({ modal: <CheckCannulaModal /> }));
    dispatch(showModal());
  };

  const handleCheckPodButton = () => {
    dispatch(setCurrentModal({ modal: <CheckPodModal /> }));
    dispatch(showModal());
  };

  const handleCannulaInsertedConfirm = () => {
    dispatch(hideModal());
    navigate(AppRoutesEnum.txSetup);
  };

  const handleCannulaInsertedReject = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <DeactivatePodConfirmModal
            onReject={() => handleCannulaInsertionTimeout()}
            onTimeout={() => navigate(AppRoutesEnum.dashboard)}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  const handleCannulaInsertionTimeout = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <CannulaInsertedConfirmationModal
            onConfirm={handleCannulaInsertedConfirm}
            onReject={handleCannulaInsertedReject}
          />
        ),
      })
    );
  };

  const handlePodSecureConfirm = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <CannulaInsertionModal onTimeout={handleCannulaInsertionTimeout} />
        ),
      })
    );
    dispatch(showModal());
  };
  const handleStartClick = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <PodSecureConfirmationModal
            onReject={() => dispatch(hideModal())}
            onConfirm={handlePodSecureConfirm}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="font-ios text-regular h-full bg-white p-2 pb-4 flex justify-between flex-col">
      <div>
        <ListStep
          index={3}
          text={intl.formatMessage(checkCannulaMessages.step3)}
          containerClasses="mb-3"
        />
        <ListStep
          index={4}
          text={
            intl.formatMessage(checkCannulaMessages.step4, {
              br: <br />,
            }) as string
          }
          containerClasses="mb-2"
        />
        <div className="pl-[40px]">
          <div>
            <Button variant="tinted" onClick={handleCheckCannulaButton}>
              <FormattedMessage {...checkCannulaMessages.checkCannulaButton} />
            </Button>
          </div>
          <div className="mt-3 mb-4 flex justify-center">
            <img
              className="w-[235px]"
              src={PreparePodGif}
              alt="Preparing pod"
            />
          </div>
        </div>
        <ListStep
          index={5}
          text={intl.formatMessage(checkCannulaMessages.step5)}
          containerClasses=""
        />
        <div className="pl-[40px] my-2">
          <Button variant="tinted" onClick={handleCheckPodButton}>
            <FormattedMessage {...checkCannulaMessages.checkPodButton} />
          </Button>
        </div>
        <ListStep
          index={6}
          text={intl.formatMessage(checkCannulaMessages.step6)}
          containerClasses=""
        />
      </div>
      <div>
        <Button full variant="colored" onClick={handleStartClick}>
          <FormattedMessage {...checkCannulaMessages.button} />
        </Button>
      </div>
    </div>
  );
};
