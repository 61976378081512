import { MouseEventHandler } from 'react';
import {
  HistoryAutoEvent,
  HistoryEventBasalProgram,
  HistoryEventBolus,
  HistoryEventGlucose,
  HistoryEventPod,
  HistoryEventSwitchMode,
  HistoryEventTempBasal,
} from './HistoryEvents';
import { HistoryEventType, historyEvents } from './types';

interface HistoryEventRowProps {
  eventType: HistoryEventType;
  time?: string;
  value?: string;
  totalInsulin?: string;
  programName?: string;
  operationMode?: string;
  status?: string;
  percent?: string;
  carbs?: string;
  mgValue?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const HistoryEventRow: React.FC<HistoryEventRowProps> = ({
  eventType,
  time = '',
  value = '',
  totalInsulin = '',
  programName = '',
  operationMode = 'manual',
  status = '',
  percent = '',
  carbs = '',
  mgValue = '',
  onClick = () => {},
}) => {
  if (eventType === historyEvents.Glucose) {
    return <HistoryEventGlucose time={time} mgValue={mgValue} />;
  }
  if (eventType === historyEvents.SwitchMode) {
    return <HistoryEventSwitchMode time={time} operationMode={operationMode} />;
  }
  if (eventType === historyEvents.TempBasal) {
    return (
      <HistoryEventTempBasal
        time={time}
        status={status}
        value={value}
        percent={percent}
      />
    );
  }
  if (eventType === historyEvents.Bolus) {
    return (
      <HistoryEventBolus
        time={time}
        status={status}
        carbs={carbs}
        totalInsulin={totalInsulin}
        mgValue={mgValue}
        onClick={onClick}
      />
    );
  }
  if (eventType === historyEvents.Pod) {
    return <HistoryEventPod time={time} status={status} onClick={onClick} />;
  }
  if (eventType === historyEvents.BasalProgram) {
    return (
      <HistoryEventBasalProgram
        time={time}
        status={status}
        programName={programName}
        totalInsulin={totalInsulin}
      />
    );
  }
  if (eventType === historyEvents.AutoEvent) {
    return (
      <HistoryAutoEvent
        time={time}
        mgValue={mgValue}
        totalInsulin={totalInsulin}
      />
    );
  }
  return <></>;
};
