import React, { useEffect, useState } from 'react';
import { FormattedTime, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { selectLanguageIs24Hours } from 'web/store/reducers/language/language.helpers';
import { selectTempBasalEndDate } from 'web/store/reducers/user/user.helpers';
import { cancelTempBasal } from 'web/store/reducers/user/actions';
import {
  hideModal,
  setCurrentModal,
  showModal,
} from 'web/store/reducers/modals/modalsSlice';
import { TempBasalOn } from 'web/components/ios/icons';
import { Button } from '../Button';
import { messages } from './TempBasalDrawer.messages';
import TempBasalDrawerGraph from 'assets/ios/temp-basal-drawer-graph.svg';
import { CancelTempBasalModal } from '../modals/TempBasalModals/TempBasalModals';

interface TempBasalDrawerProps {
  onClose: () => void;
}

export const TempBasalDrawer: React.FC<TempBasalDrawerProps> = ({
  onClose,
}) => {
  const dispatch = useDispatch();
  const [remainingTime, setRemainingTime] = useState<string>('');
  const currentDate = new Date();
  const is24Hour = useSelector(selectLanguageIs24Hours);
  const endDate = useSelector(selectTempBasalEndDate);

  const calculateRemainingTime = () => {
    const currentDate = new Date();
    const timeDifference = moment(endDate).diff(moment(currentDate));
    const remaining = moment.duration(timeDifference);
    setRemainingTime(`${remaining.hours()} hrs ${remaining.minutes()} min`);
  };

  useEffect(() => {
    calculateRemainingTime();
    const interval = setInterval(() => {
      const currentDate = new Date();
      if (moment(currentDate).isAfter(moment(endDate))) {
        dispatch(cancelTempBasal());
        clearInterval(interval);
        dispatch(hideModal());
      }
      calculateRemainingTime();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleCancelClick = () => {
    dispatch(
      setCurrentModal({
        modal: <CancelTempBasalModal handlePodCommTimeout={() => onClose()} />,
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="absolute inset-0 bg-black/50 flex justify-center font-ios items-end w-full">
      <div className="bg-white rounded-[16px] w-full">
        <div className="p-2 pt-0 font-ios">
          <div
            className="pt-1 pb-2 flex align-center justify-center cursor-pointer"
            onClick={onClose}
          >
            <div className="w-[36px] h-[5px] rounded-[100px] bg-ios-gray-9/[.18]" />
          </div>
          <div className="text-modals">
            <div className="flex flex-row items-center text-regular pb-2 border-b-1 mb-2">
              <div>
                <div className="bg-ios-tempBasal rounded-full w-[53px] h-[53px] flex justify-center items-center mr-2">
                  <TempBasalOn color="fill-white" />
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="text-ios-gray-9/60 text-[16px] leading-[21px]">
                  <FormattedMessage
                    {...messages.lastUpdated}
                    values={{
                      date: (
                        <FormattedTime value={currentDate} hour12={!is24Hour} />
                      ),
                    }}
                  />
                </div>
                <div className="text-2xl font-bold">
                  <FormattedMessage {...messages.status} />
                </div>
              </div>
            </div>

            <div className="pb-3">
              <div className="mb-1 text-ios-gray-9/60 text-small font-semibold">
                <FormattedMessage
                  {...messages.inProgress}
                  values={{ remainingTime }}
                />
              </div>
              <div className="text-2xl font-bold">
                <FormattedMessage
                  {...messages.title}
                  values={{ remainingTime }}
                />
              </div>
              <div className="text-regular font-semibold mb-2">
                <FormattedMessage
                  {...messages.decreaseText}
                  values={{ remainingTime }}
                />
              </div>
              <img
                src={TempBasalDrawerGraph}
                alt="temp-basal-complete-segments"
              />
              <Button
                className="text-ios-red"
                variant="clear"
                full
                onClick={handleCancelClick}
              >
                <FormattedMessage {...messages.cancelButton} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
