import { Button, Paragraph } from 'mobile/latest/components/atoms';
import theme from 'mobile/latest/theme';
import React from 'react';
import { View, StyleSheet, Text, Image, TouchableOpacity } from 'react-native';
import { strings } from './TakePhotoContent';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { useNavigation } from '@react-navigation/core';
import { sensorActions } from 'web/store/reducers/sensor';
import { sensors } from 'web/data/sensors';
import { useDispatch } from 'react-redux';

const TakePhoto: React.FC = () => {
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);
  const dispatch = useDispatch();

  const takePhotoOnPressHandler = () => {
    dispatch(sensorActions.sensorUpdate({ activeSensor: sensors.G7 }));
    navigate('Drawer');
  };
  return (
    <View style={styles.container}>
      <View style={styles.qrContainer}>
        <TouchableOpacity onPress={takePhotoOnPressHandler}>
          <Image
            style={styles.qrImg}
            source={require('assets/G7/QR-Image.png')}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.textContainer}>
        <Text style={theme.fonts.text600}>{languageContent.lineUp}</Text>
        <Text style={theme.fonts.text600}>{languageContent.photoAuto}</Text>
        <Paragraph
          styles={styles.paragraph}
          variant="body3"
          label={languageContent.tip}
        />
      </View>
      <View style={styles.buttonsContainer}>
        <Button variant="G7" onPress={() => navigate('AddNewSensor')}>
          {languageContent.cancel}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    paddingHorizontal: 16,
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'column',
  },
  qrContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 3,
  },
  qrImg: {
    height: 350,
    width: 350,
  },
  textContainer: {
    flex: 1,
  },
  paragraph: {
    marginTop: 5,
  },
  buttonsContainer: {
    flex: 1,
    justifyContent: 'space-evenly',
    marginBottom: 10,
  },
});

export default TakePhoto;
