let strings = {
  "en-US": {
    basal: "Basal",
    graph: "Graph: U/hr",
    totalBasal: "Total Basal: %1 U/day",
    timeSegments: "Time Segments",
    total: "Total:",
    segment: "Segment",
    basalRate: "Basal Rate: %2 U/hr"
  },
  "en-GB": {
    basal: "Basal",
    graph: "Graph: U/hr",
    totalBasal: "Total Basal: %1 U/day",
    timeSegments: "Time Segments",
    total: "Total:",
    segment: "Segment",
    basalRate: "Basal Rate: %2 U/hr"
  },
  "de-DE": {
    basal: "Basalrate",
    graph: "Grafik: E/Std.",
    totalBasal: "Gesamtbasalrate: %1 E/Tag",
    timeSegments: "Zeitsegmente",
    total: "Gesamt:",
    segment: "Segment",
    basalRate: "Basalrate: %2 E/Std"
  },
  "es-US": {
    basal: "Basal",
    graph: "Gráfico: U/h",
    totalBasal: "Basal total: %1 U/día",
    timeSegments: "Segmentos temporales",
    total: "Total:",
    segment: "Segmento",
    basalRate: "Tasa basal: %2 U/h"
  },
  "fr-FR": {
    basal: "Débit basal",
    graph: "Graphique : U/h",
    totalBasal: "Débit basal total : %1 U/jour",
    timeSegments: "Segments temporels",
    total: "Total :",
    segment: "Segment",
    basalRate: "Débit basal : %2 U/h"
  },
  "nl-NL": {
    basal: "Basaal",
    graph: "Grafiek: E/u.",
    totalBasal: "Totaal basaal: %1 E/dag",
    timeSegments: "Tijdsegmenten",
    total: "Totaal:",
    segment: "Segment",
    basalRate: "Basaalsnelheid: %2 E/u."
  }
};

export { strings };
