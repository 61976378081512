import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const InfoIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons/x/Info Outline/Light">
        <rect
          width="20"
          height="20"
          transform="translate(0 0.5)"
          fill="white"
        />
        <path
          className={classes}
          id="Mask"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 7.5H11V5.5H9V7.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM9 15.5H11V9.5H9V15.5Z"
        />
        <mask
          id="mask0_79_1935"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="21"
        >
          <path
            id="Mask_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 7.5H11V5.5H9V7.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM9 15.5H11V9.5H9V15.5Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_79_1935)"></g>
      </g>
    </svg>
  );
};
