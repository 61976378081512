let strings = {
  "en-US": {
    night: "Night",
    basalRate: "Basal Rate",
    range: "(%1 to %2 U/hr)",
    uHr: "U/hr",
    reviewModalTitle: "Review Your Basal Program",
    reviewModalMessage:
      "Check your time segments and basal rates on the next screen.",
    continue: "Continue",
    cancel: "Cancel",
    next: "Next",
    basal: "Basal",
    cancelTitleOne: "Cancel editing this basal program?",
    cancelTitleTwo: "Are you sure you want to cancel the basal program?",
    yes: "Yes",
    no: "No",
    cancelModalTitle: "Cancel Bolus setup?",
    cancelModalBody:
      "Cancelling will erase your Bolus entries and return you to the beginning of this section."
  },
  "en-GB": {
    night: "Night",
    basalRate: "Basal Rate",
    range: "(%1 to %2 U/hr)",
    uHr: "U/hr",
    reviewModalTitle: "Review your Basal Programme",
    reviewModalMessage:
      "Check your time segments and basal rates on the next screen.",
    continue: "Continue",
    cancel: "Cancel",
    next: "Next",
    basal: "Basal",
    cancelTitleOne: "Cancel editing this basal programme?",
    cancelTitleTwo: "Are you sure you want to cancel the basal programme?",
    yes: "Yes",
    no: "No",
    cancelModalTitle: "Cancel Bolus set-up?",
    cancelModalBody:
      "Cancelling will erase your Bolus entries and return you to the beginning of this section."
  },
  "de-DE": {
    night: "Nacht",
    basalRate: "Basalrate",
    range: "(%1 bis %2 E/Std)",
    uHr: "E/Std.",
    reviewModalTitle: "Ihr Basalratenprofil überprüfen",
    reviewModalMessage:
      "Prüfen Sie auf dem nächsten Bildschirm Ihre Zeitsegmente und Basalraten.",
    continue: "Weiter",
    cancel: "Abbr.",
    next: "Weiter",
    basal: "Basalrate",
    cancelTitleOne: "Bearbeitung dieses Basalratenprofils abbrechen?",
    cancelTitleTwo:
      "Sind Sie sicher, dass Sie das Basalratenprofil abbrechen möchten?",
    yes: "Ja",
    no: "Nein",
    cancelModalTitle: "Boluseinrichtung abbrechen?",
    cancelModalBody:
      "Wenn Sie den Vorgang abbrechen, werden alle Boluseingaben gelöscht und Sie kehren zum Anfang dieses Abschnittes zurück."
  },
  "es-US": {
    night: "Noche",
    basalRate: "Tasa basal",
    range: "(hasta %2 U/h)",
    uHr: "U/h",
    reviewModalTitle: "Revise su programa basal",
    reviewModalMessage:
      "Compruebe sus segmentos de tiempo y tasas basales en la siguiente pantalla.",
    continue: "Continuar",
    cancel: "Cancelar",
    next: "Siguiente",
    basal: "Basal",
    cancelTitleOne: "¿Cancelar la edición de este programa basal?",
    cancelTitleTwo: "¿Cancelar la creación de este programa basal?",
    yes: "Sí",
    no: "No",
    cancelModalTitle: "¿Cancelar la configuración del bolo?",
    cancelModalBody:
      "Si cancela, se borrarán sus entradas Bolo y volverá al inicio de esta sección."
  },
  "fr-FR": {
    night: "Nuit",
    basalRate: "Débit basal",
    range: "(%1 à %2 U/h)",
    uHr: "U/h",
    reviewModalTitle: "Vérifiez votre programme basal",
    reviewModalMessage:
      "Vérifiez vos segments temporels et vos débits basaux à l'écran suivant.",
    continue: "Continuer",
    cancel: "Annuler",
    next: "Suivant",
    basal: "Débit basal",
    cancelTitleOne: "Annuler la modification de ce programme basal ?",
    cancelTitleTwo: "Êtes-vous sûr(e) de vouloir annuler le programme basal ?",
    yes: "Oui",
    no: "Non",
    cancelModalTitle: "Annuler la configuration du bolus ?",
    cancelModalBody:
      "L'annulation efface vos données de bolus, puis vous renvoie au début de cette section."
  },
  "nl-NL": {
    night: "Nacht",
    basalRate: "Basaalsnelheid",
    range: "(%1 tot %2 E/u.)",
    uHr: "E/u.",
    reviewModalTitle: "Uw basaalprogramma bekijken",
    reviewModalMessage:
      "Controleer uw tijdsegmenten en basaalsnelheden op het volgende scherm.",
    continue: "Doorgaan",
    cancel: "Annuleren",
    next: "Volgende",
    basal: "Basaal",
    cancelTitleOne: "Bewerken van dit basaalprogramma annuleren?",
    cancelTitleTwo: "Weet u zeker dat u het basaalprogramma wilt annuleren?",
    yes: "Ja",
    no: "Nee",
    cancelModalTitle: "Bolusinstellingen annuleren?",
    cancelModalBody:
      "Annuleren wist uw bolusinvoer en brengt u naar het begin van deze sectie."
  }
};

export { strings };
