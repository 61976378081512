let strings = {
  "en-US": {
    updated: "updated"
  },
  "en-GB": {
    updated: "updated"
  },
  "de-DE": {
    updated: "aktualisiert"
  },
  "es-US": {
    updated: "actualizado"
  },
  "fr-FR": {
    updated: "actualisé"
  },
  "nl-NL": {
    updated: "bijgewerkt"
  }
};

export { strings };
