import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DELIVERY_TIME, messages } from '../PauseStartStack.messages';
import { Button } from 'web/components/ios/Button';
import { useDispatch } from 'react-redux';
import {
  showModal,
  setCurrentModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import {
  CommunicatingWithPodConfirmationModal,
  PauseInsulinDeliveryModal,
} from 'web/components/ios/modals/PauseStartInsulinModals/PauseStartInsulinModals';
import { AppRoutesEnum } from 'web/routes/types';
import { basalPauseInsulin } from 'web/store/reducers/basal/actions';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';

export const PauseInsulin = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handlePauseInsulinModal = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <PauseInsulinDeliveryModal
            onConfirm={handleCommunicatingWithPodModal}
            onReject={() => dispatch(hideModal())}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  const handleCommunicatingWithPodModal = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <CommunicatingWithPodConfirmationModal
            onClose={() => {
              dispatch(basalPauseInsulin());
              dispatch(
                navigateToRoute(AppRoutesEnum.dashboard, {
                  showBottomInsulinModal: true,
                })
              );
              dispatch(hideModal());
            }}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="flex justify-between flex-col flex-1 items-stretch bg-white">
      <div>
        <div className="flex flex-col px-2">
          <div className="mt-2 ">
            <div className="text-black text-[17px] font-semibold">
              <FormattedMessage {...messages.howLongTitle} />
            </div>
            <div className="mt-2">
              <FormattedMessage {...messages.allInsulinDeliveryText} />
            </div>
            <div className="mt-4 flex flex-row justify-between items-center">
              <div>
                <FormattedMessage {...messages.DurationTitle} />
                <div className="text-ios-secondaryGray opacity-60">{`(0.5 to 2 ${intl.formatMessage(messages.hrsText)})`}</div>
              </div>
              <div>
                <div className="text-ios-blue">{`${DELIVERY_TIME}${intl.formatMessage(messages.hrsText)}`}</div>
              </div>
            </div>
          </div>
        </div>
        <hr className="bg-ios-secondaryGray mt-1 opacity-[36%]" />
      </div>

      <div className="w-full mb-4 px-2">
        <Button
          className="w-full rounded-lg  bg-modes-manual"
          onClick={handlePauseInsulinModal}
          variant="colored"
        >
          <FormattedMessage {...messages.PauseInsulinButtonTitle} />
        </Button>
      </div>
    </div>
  );
};
