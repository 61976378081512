interface NotificationRowProp {
  title: string;
  unread?: boolean;
  description: string;
  time: string;
}

export const NotificationRow: React.FC<NotificationRowProp> = ({
  title,
  unread,
  description,
  time,
}) => {
  return (
    <div className="flex justify-between w-full py-1 px-2 border-b border-b-ios-gray-7 bg-white">
      <div className="w-[261px]">
        <div className="flex items-center">
          {unread && (
            <div className="rounded-full w-1 h-1 bg-modes-manual"></div>
          )}
          <p className={`${unread ? 'ml-1' : 'ml-2'}  text-regular`}>{title}</p>
        </div>
        <p className="ml-2 text-small text-ios-secondaryGray opacity-60 ">
          {description}
        </p>
      </div>
      <div>
        <p className="text-regular text-ios-secondaryGray opacity-60">{time}</p>
      </div>
    </div>
  );
};
