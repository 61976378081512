import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  Dimensions,
  Platform,
  PlatformIOSStatic,
  StyleSheet,
  Text,
  TextInput,
  Pressable,
  View,
  TouchableOpacity
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import DURATIONS from "../../../../../../data/FTSBolusDurationData";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import {
  bolusActions,
  BolusState
} from "../../../../../../../../web/store/reducers/bolus";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import PickerItem from "../../../../../../types/pickerItem";
import { Header, Paragraph } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import CustomPicker from "../../../../../organisms/customPicker";
import { strings } from "./DurationOfInsulinActionScreenContent";

const { width } = Dimensions.get("window");
const platform = Platform as PlatformIOSStatic;

const getPickerHorizontalPlacement = () => {
  if (platform.isPad) {
    if (width > 1000) {
      return -width / 6.9;
    } else {
      return -width / 5.9;
    }
  } else if (Platform.OS === "android") {
    if (width > 395) {
      return -width / 5.1;
    } else if (width > 380) {
      return -width / 4.3;
    } else {
      return -width / 4;
    }
  } else if (width > 395) {
    return -width / 4.7;
  } else if (width > 380) {
    return -width / 4.3;
  } else {
    return -width / 4.15;
  }
};

const getPickerVerticalPlacement = () => {
  if (platform.isPad) {
    if (width > 1000) {
      return "-45%";
    } else {
      return "-37%";
    }
  } else if (Platform.OS === "android") {
    if (width > 395) {
      return "-57.5%";
    } else if (width > 380) {
      return "-31%";
    } else {
      return "-2%";
    }
  } else if (width > 415) {
    return "-37.5%";
  } else if (width > 395) {
    return "-22%";
  } else if (width > 380) {
    return "-31%";
  } else {
    return "-14.5%";
  }
};

const pickerHorizontalMargin = getPickerHorizontalPlacement();
const pickerVerticalMargin = getPickerVerticalPlacement();

const DurationOfInsulinActionScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );

  const [showDurationPicker, setShowDurationPicker] =
    React.useState<boolean>(false);

  const [duration, setDuration] = React.useState<PickerItem>({
    label: "––",
    value: -1,
    key: "",
    index: 0
  });

  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);

  //set selected value for target BG
  const onConfirm = (value: string | null) => {
    const selected = DURATIONS.find((DURATIONS) => DURATIONS.label === value!);
    setDuration(selected!);
    /* setShowDurationPicker(false); */
  };

  return (
    <View style={[styles.container]}>
      <Header
        label={languageContent.setDuration}
        styles={{ marginTop: 32, marginBottom: 24, paddingVertical: 0 }}
      />
      <View style={[{ flex: 1, width: "100%", paddingHorizontal: 16 }]}>
        <Paragraph
          label={languageContent.theLength}
          variant="body1"
          styles={{ marginBottom: 16 }}
        />
        <View
          style={[
            {
              marginTop: 8,
              zIndex: -1,
              backgroundColor: showDurationPicker ? "#EEF2F6" : "transparent"
            }
          ]}>
          <View style={[{ marginTop: 16 }]}>
            <Text style={[theme.fonts.h4]}>{languageContent.duration}</Text>
            <Text
              style={[
                theme.fonts.body1,
                { color: theme.colors.text.cancel, marginBottom: 8 }
              ]}>
              {languageContent.range}
            </Text>
          </View>
          <Pressable
            onPress={() => onConfirm("3")}
            style={[
              theme.layout.mockInput,
              theme.layout.inputContainerStyle,
              { flex: 1, paddingVertical: 8 }
            ]}>
            <Text style={[theme.layout.mockInputStyle]}>
              {duration.value === -1 ? (
                <Text style={{ color: theme.colors.pureBlack }}>--</Text>
              ) : (
                <Text
                  style={{
                    padding: 8,
                    fontSize: 32
                  }}>
                  {Number(duration.label).toLocaleString(langState.locale)}
                </Text>
              )}
            </Text>
            <Text style={[theme.layout.adornment]}>{languageContent.hrs}</Text>
          </Pressable>
        </View>
      </View>
      <BottomInnerNav
        leftActionStyle={{ color: theme.colors.text.cancel }}
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.next}
        rightAction={() => {
          if (!duration || duration.value < 2 || duration.value > 6) {
            return;
          }

          dispatch(
            bolusActions.bolusUpdate(
              {
                durationOfInsulinAction: duration.value
              },
              bolusState
            )
          );
          navigate("SetMaxBolus");
        }}
        rightActionStyle={{
          color:
            !duration || duration.value < 2 || duration.value > 6
              ? theme.colors.placeholder
              : theme.colors.purple
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "#fff"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  mockInput: {
    borderColor: theme.colors.textInputBorder,
    borderWidth: 1,
    backgroundColor: theme.colors.textInputBackgroundColor,
    width: "100%",
    borderRadius: 5
  },
  periodText: {
    color: theme.colors.grayScale.gray400,
    fontSize: 18
  },
  androidPickerWrapper: {
    flex: 2,
    zIndex: 20,
    marginTop: pickerVerticalMargin,
    marginLeft: pickerHorizontalMargin,
    height: 200,
    marginBottom: 16
  }
});

export default DurationOfInsulinActionScreen;
