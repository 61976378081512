import { ViewerManagementState } from "./index";
import { initialState } from "./reducer";

export const VIEWER_MANAGEMENT_UPDATE = "viewer_management_update";
export const VIEWER_MANAGEMENT_INIT = "viewer_management_init";

interface ActionUpdate {
  type: ActionType;
  payload: {
    viewer: ViewerManagementState;
  };
}

interface ActionInit {
  type: ActionType;
  payload: {
    viewer: ViewerManagementState;
  };
}

type ActionType =
  | typeof VIEWER_MANAGEMENT_UPDATE
  | typeof VIEWER_MANAGEMENT_INIT;

export const viewerUpdate = (
  viewerManagementData: Partial<ViewerManagementState>
): Action => {
  return {
    type: VIEWER_MANAGEMENT_UPDATE,
    payload: {
      viewer: viewerManagementData
    }
  };
};

export const viewerInit = (): Action => {
  return {
    type: VIEWER_MANAGEMENT_INIT,
    payload: {
      viewer: initialState
    }
  };
};

export type Action = ActionUpdate | ActionInit;
