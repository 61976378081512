const WEEKEND_PROGRAM_NAME = "Weekend"
const WEEKEND_PROGRAM_ID = "weekend"
const EVERYDAY_PROGRAM_NAME = "Everyday"
const EVERYDAY_PROGRAM_ID = "everyday"

const defaultBolusReduxData = {
  defaultActiveBolusProgram: {
    id: EVERYDAY_PROGRAM_ID,
    programName: EVERYDAY_PROGRAM_NAME,
    tag: "weekday",
    isComplete: true,
    isActive: false,
    correctionFactorSegments: [
      {
        start: "12:00 am",
        end: "8:00 am",
        endHelpText: "Morning",
        oneUnitCorrectionFactor: 0,
      },
      {
        start: "8:00 am",
        end: "4:00 pm",
        endHelpText: "Afternoon",
        oneUnitCorrectionFactor: 0,
      },
      {
        start: "4:00 pm",
        end: "12:00 am",
        endHelpText: "Night",
        oneUnitCorrectionFactor: 0,
      },
    ],
    icRatioSegments: [
      {
        start: "12:00 am",
        end: "8:00 am",
        endHelpText: "Morning",
        oneUnitInsulinCovers: 0,
      },
      {
        start: "8:00 am",
        end: "4:00 pm",
        endHelpText: "Afternoon",
        oneUnitInsulinCovers: 0,
      },
      {
        start: "4:00 pm",
        end: "12:00 am",
        endHelpText: "Night",
        oneUnitInsulinCovers: 0,
      },
    ],
    segments: [
      {
        start: "12:00 am",
        end: "8:00 am",
        endHelpText: "Morning",
        targetBG: 0,
        correctAbove: 0,
        oneUnitInsulinCovers: 0,
        oneUnitCorrectionFactor: 0,
      },
      {
        start: "8:00 am",
        end: "4:00 pm",
        endHelpText: "Afternoon",
        targetBG: 0,
        correctAbove: 0,
        oneUnitInsulinCovers: 0,
        oneUnitCorrectionFactor: 0,
      },
      {
        start: "4:00 pm",
        end: "12:00 am",
        endHelpText: "Night",
        targetBG: 0,
        correctAbove: 0,
        oneUnitInsulinCovers: 0,
        oneUnitCorrectionFactor: 0,
      },
    ],
  },
  defaultExpertBolusPrograms: [
    {
      id: EVERYDAY_PROGRAM_ID,
      programName: EVERYDAY_PROGRAM_NAME,
      tag: "weekday",
      isComplete: true,
      isActive: false,
      correctionFactorSegments: [
        {
          start: "12:00 am",
          end: "8:00 am",
          endHelpText: "Morning",
          oneUnitCorrectionFactor: 0,
        },
        {
          start: "8:00 am",
          end: "4:00 pm",
          endHelpText: "Afternoon",
          oneUnitCorrectionFactor: 0,
        },
        {
          start: "4:00 pm",
          end: "12:00 am",
          endHelpText: "Night",
          oneUnitCorrectionFactor: 0,
        },
      ],
      icRatioSegments: [
        {
          start: "12:00 am",
          end: "8:00 am",
          endHelpText: "Morning",
          oneUnitInsulinCovers: 0,
        },
        {
          start: "8:00 am",
          end: "4:00 pm",
          endHelpText: "Afternoon",
          oneUnitInsulinCovers: 0,
        },
        {
          start: "4:00 pm",
          end: "12:00 am",
          endHelpText: "Night",
          oneUnitInsulinCovers: 0,
        },
      ],
      segments: [
        {
          start: "12:00 am",
          end: "8:00 am",
          endHelpText: "Morning",
          targetBG: 0,
          correctAbove: 0,

          oneUnitCorrectionFactor: 0,
        },
        {
          start: "8:00 am",
          end: "4:00 pm",
          endHelpText: "Afternoon",
          targetBG: 0,
          correctAbove: 0,

          oneUnitCorrectionFactor: 0,
        },
        {
          start: "4:00 pm",
          end: "12:00 am",
          endHelpText: "Night",
          targetBG: 0,
          correctAbove: 0,

          oneUnitCorrectionFactor: 0,
        },
      ],
    },
    {
      id: WEEKEND_PROGRAM_ID,
      programName: WEEKEND_PROGRAM_NAME,
      tag: "weekend",
      isComplete: true,
      isActive: false,
      correctionFactorSegments: [
        {
          start: "12:00 am",
          end: "8:00 am",
          endHelpText: "Morning",
          oneUnitCorrectionFactor: 0,
        },
        {
          start: "8:00 am",
          end: "4:00 pm",
          endHelpText: "Afternoon",
          oneUnitCorrectionFactor: 0,
        },
        {
          start: "4:00 pm",
          end: "12:00 am",
          endHelpText: "Night",
          oneUnitCorrectionFactor: 0,
        },
      ],
      icRatioSegments: [
        {
          start: "12:00 am",
          end: "8:00 am",
          endHelpText: "Morning",
          oneUnitInsulinCovers: 0,
        },
        {
          start: "8:00 am",
          end: "4:00 pm",
          endHelpText: "Afternoon",
          oneUnitInsulinCovers: 0,
        },
        {
          start: "4:00 pm",
          end: "12:00 am",
          endHelpText: "Night",
          oneUnitInsulinCovers: 0,
        },
      ],
      segments: [
        {
          start: "12:00 am",
          end: "8:00 am",
          endHelpText: "Morning",
          targetBG: 0,
          correctAbove: 0,
          oneUnitInsulinCovers: 0,
          oneUnitCorrectionFactor: 0,
        },
        {
          start: "8:00 am",
          end: "12:00 pm",
          endHelpText: "Night",
          targetBG: 0,
          correctAbove: 0,
          oneUnitInsulinCovers: 0,
          oneUnitCorrectionFactor: 0,
        },
      ],
    },
  ],
}

export default defaultBolusReduxData
