import { useNavigation } from '@react-navigation/native';
import { Button } from 'web/components/ios/Button';
import { IOSBolusRoutesEnums } from '../types';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  messages,
  TargetGlucoseAndCorrectAboveValuesIntroMessages,
} from '../Bolus.messages';

export const TargetGlucoseAndCorrectAboveValuesIntro = () => {
  const { navigate } = useNavigation();
  const intl = useIntl();
  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-9/12 p-2">
        <p className="mb-6 pr-7 font-semibold text-2xl">
          {intl.formatMessage(
            TargetGlucoseAndCorrectAboveValuesIntroMessages.title
          )}
        </p>
        <p className="mb-5 font-normal text-regular">
          {intl.formatMessage(
            TargetGlucoseAndCorrectAboveValuesIntroMessages.textOne
          )}
        </p>
        <p className="mb-5 font-normal text-regular">
          <FormattedMessage
            {...TargetGlucoseAndCorrectAboveValuesIntroMessages.textTwo}
            values={{
              highlight: (chunk) => <span className="font-bold">{chunk}</span>,
            }}
          />
        </p>
        <p className="font-normal text-regular">
          <FormattedMessage
            {...TargetGlucoseAndCorrectAboveValuesIntroMessages.textThree}
            values={{
              highlight: (chunk) => <span className="font-bold">{chunk}</span>,
            }}
          />
        </p>
      </div>
      <div className="basis-2/5">
        <div className=" w-full h-full p-6 flex flex-col-reverse">
          <Button
            onClick={() =>
              navigate(
                IOSBolusRoutesEnums.TargetGlucoseAndCorrectAboveSegmentOne
              )
            }
            variant="colored"
          >
            {intl.formatMessage(messages.nextBtn)}
          </Button>
        </div>
      </div>
    </div>
  );
};
