let strings = {
  "en-US": {
    reminders: "REMINDERS",
    checkBG: "Check BG",
    alwaysOn: "ALWAYS ON",
    podExp: "Pod Expiration",
    remind: "Remind 6 hours before",
    lowPod: "Low Pod Insulin",
    remindWhen: "Remind when 10 units remain"
  },
  "en-GB": {
    reminders: "REMINDERS",
    checkBG: "Check BG",
    alwaysOn: "ALWAYS ON",
    podExp: "Pod Expiry",
    remind: "Remind 6 hours before",
    lowPod: "Low Pod Insulin",
    remindWhen: "Remind when 10 units remain"
  },
  "de-DE": {
    reminders: "ERINNERUNGSHINWEISE",
    checkBG: "BZ prüfen",
    alwaysOn: "IMMER AKTIVIERT",
    podExp: "Pod-Ablauf",
    remind: "Erinnerungshinweis 6 Stunden im Voraus",
    lowPod: "Wenig Insulin im Pod",
    remindWhen: "Erinnerungshinweis, wenn 10 Einheiten übrig sind"
  },
  "es-US": {
    reminders: "RECORDATORIOS",
    checkBG: "Revisar GS",
    alwaysOn: "SIEMPRE PRENDIDOS",
    podExp: "Caducidad del Pod",
    remind: "Recordar 6 horas antes",
    lowPod: "Pod con insulina baja",
    remindWhen: "Recordarme cuando queden 10 unidades"
  },
  "fr-FR": {
    reminders: "RAPPELS",
    checkBG: "Vérifier la glycémie",
    alwaysOn: "TOUJOURS ACTIVÉ",
    podExp: "Péremption du Pod",
    remind: "Rappeler 6 heures avant",
    lowPod: "Insuline du Pod basse",
    remindWhen: "Rappeler quand il reste 10 unités"
  },
  "nl-NL": {
    reminders: "HERINNERINGEN",
    checkBG: "BG controleren",
    alwaysOn: "ALTIJD AAN",
    podExp: "Uiterste gebruiksdatum Pod",
    remind: "Herinner 6 uur van tevoren",
    lowPod: "Weinig insuline in Pod",
    remindWhen: "Herinnering voor 10 laatste eenheden"
  }
};

export { strings };
