import { useNavigation } from '@react-navigation/native';
import { Button } from 'web/components/ios/Button';
import { IOSBolusRoutesEnums } from '../types';
import { useIntl } from 'react-intl';
import {
  messages,
  SetInsulinToCarbRatioIntroMessages,
} from '../Bolus.messages';

export const SetInsulinToCarbRatioIntro = () => {
  const { navigate } = useNavigation();
  const intl = useIntl();
  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-9/12 p-6">
        <p className="mb-5 font-bold text-2xl">
          {intl.formatMessage(SetInsulinToCarbRatioIntroMessages.title)}
        </p>
        <p className="mb-5 font-normal text-regular">
          {intl.formatMessage(SetInsulinToCarbRatioIntroMessages.textOne)}
        </p>
        <p className="mb-5 font-normal text-regular">
          {intl.formatMessage(SetInsulinToCarbRatioIntroMessages.textTwo)}
        </p>
      </div>
      <div className="basis-2/5">
        <div className=" w-full h-full p-6 flex flex-col-reverse">
          <Button
            onClick={() =>
              navigate(IOSBolusRoutesEnums.CreateInsulinToCarbRatioSegmentOne)
            }
            variant="colored"
          >
            {intl.formatMessage(messages.nextBtn)}
          </Button>
        </div>
      </div>
    </div>
  );
};
