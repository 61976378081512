import { FormattedMessage, useIntl } from 'react-intl';
import { HistoryEventRow } from '../components/HistoryEventRow/HistoryEventRow';
import { historyEvents } from '../components/HistoryEventRow/types';
import { historyEventsMessages } from '../History.messages';
import { historyEventTempBasalMessages } from '../components/HistoryEventRow/HistoryEvents/HistoryEventTempBasal/HistoryEventTempBasal.messages';
import { historyEventBolusMessages } from '../components/HistoryEventRow/HistoryEvents/HistoryEventBolus/HistoryEventBolus.messages';
import { historyEventPodMessages } from '../components/HistoryEventRow/HistoryEvents/HistoryEventPod/HistoryEventPod.messages';
import { historyEventBasalProgramMessages } from '../components/HistoryEventRow/HistoryEvents/HistoryEventBasalProgram/HistoryEventBasalProgram.messages';
import { useNavigation } from '@react-navigation/native';
import { IOSHistoryRoutesEnum } from '../types';

export const HistoryEvents = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  return (
    <>
      <div className="flex justify-between px-2 overscroll-contain">
        <p className="font-semibold text-regular mb-2">
          <FormattedMessage {...historyEventsMessages.title} />
        </p>
        <p
          onClick={() => navigate(IOSHistoryRoutesEnum.HistoryAutoEvents)}
          className=" cursor-pointer text-[16px] mb-2 text-ios-blue"
        >
          <FormattedMessage {...historyEventsMessages.autoEventLink} />
        </p>
      </div>
      <div className="pt-1 overflow-scroll">
        <HistoryEventRow
          eventType={historyEvents.Glucose}
          time="2:55 PM"
          mgValue="55 mg/dL"
        />
        <HistoryEventRow
          eventType={historyEvents.SwitchMode}
          operationMode="automated"
          time="1:43 PM"
        />
        <HistoryEventRow
          eventType={historyEvents.TempBasal}
          status={intl.formatMessage(
            historyEventTempBasalMessages.startedStatus
          )}
          time="12:27 PM"
          value={intl.formatMessage(historyEventTempBasalMessages.value)}
          percent={intl.formatMessage(
            historyEventTempBasalMessages.valuePercent
          )}
        />
        <HistoryEventRow
          eventType={historyEvents.Bolus}
          status={intl.formatMessage(historyEventBolusMessages.canceledStatus)}
          time="11:11 PM"
          carbs="63 g"
          totalInsulin="4 U"
          onClick={() =>
            navigate(IOSHistoryRoutesEnum.HistoryBolusCanceledDetails)
          }
        />
        <HistoryEventRow
          eventType={historyEvents.Bolus}
          time="9:05 AM"
          carbs="99 g"
          totalInsulin="10.25 U"
          mgValue="150 mg/dL"
          onClick={() => navigate(IOSHistoryRoutesEnum.HistoryBolusDetails)}
        />
        <HistoryEventRow
          eventType={historyEvents.Pod}
          status={intl.formatMessage(historyEventPodMessages.activatedStatus)}
          time="8:53 AM"
        />
        <HistoryEventRow
          eventType={historyEvents.Pod}
          status={intl.formatMessage(historyEventPodMessages.deactivatedStatus)}
          time="8:48 AM"
          onClick={() =>
            navigate(IOSHistoryRoutesEnum.HistoryPodDeactivatedDetails)
          }
        />
        <HistoryEventRow
          eventType={historyEvents.BasalProgram}
          status={intl.formatMessage(
            historyEventBasalProgramMessages.startedStatus
          )}
          time="7:40 AM"
          totalInsulin="22.5 U/day"
          programName={intl.formatMessage(
            historyEventBasalProgramMessages.programName
          )}
        />
        <HistoryEventRow
          eventType={historyEvents.SwitchMode}
          operationMode="manual"
          time="7:40 AM"
        />
      </div>
    </>
  );
};
