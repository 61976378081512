import { isEqual } from "lodash";
import React from "react";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { IntroScreenLayout } from "../../../../../organisms";
import { strings } from "./IntroBolusFTSScreenContent";

const IntroBolusFTSScreen: React.FC = () => {
  const image = require("../../../../../../../../assets/bolus_intro1.png");
  const { languageContent } = useLanguageContent(strings);
  return (
    <IntroScreenLayout
      heading={languageContent.bolus}
      body={languageContent.insulinThat}
      image={image}
      navigateTo={"TargetBGFTS"}
    />
  );
};

export default IntroBolusFTSScreen;
