import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'ios.main-menu.title',
    defaultMessage: 'Menu',
  },
  switchMode: {
    id: 'ios.main-menu.switch-mode',
    defaultMessage: 'Switch Mode',
  },
  setTempBasal: {
    id: 'ios.main-menu.set-temp-basal',
    defaultMessage: 'Set Temp Basal',
  },
  activity: {
    id: 'ios.main-menu.activity',
    defaultMessage: 'Activity',
  },
  pod: {
    id: 'ios.main-menu.pod',
    defaultMessage: 'Pod',
  },
  enterBg: {
    id: 'ios.main-menu.enter-bg',
    defaultMessage: 'Enter BG',
  },
  startInsulin: {
    id: 'ios.main-menu.start-insulin',
    defaultMessage: 'Start Insulin',
  },
  pauseInsulin: {
    id: 'ios.main-menu.pause-insulin',
    defaultMessage: 'Pause Insulin',
  },

  basalPrograms: {
    id: 'ios.main-menu.basal-programs',
    defaultMessage: 'Basal Programs',
  },
  customFoods: {
    id: 'ios.main-menu.custom-foods',
    defaultMessage: 'Custom Foods',
  },

  general: {
    id: 'ios.main-menu.general',
    defaultMessage: 'General',
  },
  manageSensor: {
    id: 'ios.main-menu.manage-sensor',
    defaultMessage: 'Manage Sensor',
  },
  reminders: {
    id: 'ios.main-menu.reminders',
    defaultMessage: 'Reminders',
  },
  glucoseGoalRange: {
    id: 'ios.main-menu.glucose-goal-range',
    defaultMessage: 'Glucose Goal Range',
  },
  basalTempBasalSettings: {
    id: 'ios.main-menu.basal-temp-basal-settings',
    defaultMessage: 'Basal & Temp Basal Settings',
  },
  bolusSettings: {
    id: 'ios.main-menu.bolus-settings',
    defaultMessage: 'Bolus Settings',
  },
  about: {
    id: 'ios.main-menu.about',
    defaultMessage: 'About',
  },
});