import { createStackNavigator } from "@react-navigation/stack";
import { isEqual } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../../components/atoms";
import {
  IntroBasalScreen,
  MaxBasalRateScreen,
  TempBasalOnScreen
} from "../../components/screens";
import {
  ExpertNewBasalSegmentScreen,
  ExpertProgramEntryScreen,
  ExpertProgramSegmentsReviewScreen
} from "../../components/screens/simulator/basal";
import { useModeIconSmall } from "../../hooks";
import useLanguageContent from "../../hooks/useLanguageContent";
import { basalActions, BasalState } from "../../../../web/store/reducers/basal";
import { RootState } from "../../../../web/store/store";
import theme from "../../theme";
import { strings } from "./ExpertBasalProgramsFTSStackContent";
const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const ExpertBasalProgramsFTSStack: React.FC = () => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );

  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);
  const { icon } = useModeIconSmall();

  const clearLastSegment = () => {
    dispatch(
      basalActions.basalUpdate(
        {
          newExpertBasalProgram: {
            ...basalState.newExpertBasalProgram,
            segments: basalState.newExpertBasalProgram.segments.slice(
              0,
              basalState.newExpertBasalProgram.segments.length - 1
            )
          }
        },
        basalState
      )
    );
  };

  return (
    <>
      <Stack.Navigator initialRouteName="ExpertIntro" mode="card">
        <Stack.Screen
          name="ExpertIntro"
          options={{
            title: "",
            headerStyle: [defaultHeaderStyle],
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBasal}
                white={false}
                isFTS
              />
            )
          }}
          component={IntroBasalScreen}
        />
        <Stack.Screen
          name="ExpertMaxBasal"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBasal}
                white={false}
                isFTS
              />
            )
          }}
          component={MaxBasalRateScreen}
        />
        <Stack.Screen
          name="ExpertProgramEntry"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.editBasalProgram}
                white={false}
                isFTS
              />
            ),
            headerRight: () => icon
          }}
          component={ExpertProgramEntryScreen}
        />
        <Stack.Screen
          name="ExpertProgramSegments"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.editBasalProgram}
                white={false}
                beforeNavigation={clearLastSegment}
                isFTS
              />
            ),

            headerRight: () => icon
          }}
          component={ExpertNewBasalSegmentScreen}
        />
        <Stack.Screen
          name="ExpertProgramSegmentsReview"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.confirmBasal}
                white={false}
                beforeNavigation={clearLastSegment}
                isFTS
              />
            ),
            headerRight: () => icon
          }}
          component={ExpertProgramSegmentsReviewScreen}
        />
        <Stack.Screen
          name="ExpertTempBasalOn"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.setupBasal}
                white={false}
                beforeNavigation={() => {
                  dispatch(
                    basalActions.basalUpdate(
                      {
                        newExpertBasalProgram: {
                          ...basalState.newExpertBasalProgram,
                          isComplete: false
                        }
                      },
                      basalState
                    )
                  );
                }}
                isFTS
              />
            )
          }}
          component={TempBasalOnScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default ExpertBasalProgramsFTSStack;
