import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { Checkbox } from 'web/components/ios/Checkbox';
import { Button } from 'web/components/ios/Button';
import { CancelEditingCustomFoods } from 'web/components/ios/modals/CustomFoodsModals/CustomFoodsModals';
import { selectCustomFoods } from 'web/store/reducers/customFoods/customFoods.helpers';
import {
  showModal,
  setCurrentModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import { bolusCustomFoodsMessages } from '../BolusAdministration.messages';
import { BolusAdministrationRoutesEnum } from '../types';

export const BolusCustomFoods: React.FC = () => {
  const dispatch = useDispatch();
  const [firstSelected, setFirstSelected] = useState<boolean>(false);
  const { customFoods } = useSelector(selectCustomFoods);
  const { navigate, goBack, setParams } = useNavigation();

  const handleCancelConfirm = () => {
    dispatch(hideModal());
    goBack();
  };

  useEffect(() => {
    setParams({
      topBarOnLeftButtonClick: () => {
        dispatch(
          setCurrentModal({
            modal: (
              <CancelEditingCustomFoods
                rightButtonOnClickHandler={handleCancelConfirm}
              />
            ),
          })
        );
        dispatch(showModal());
      },
    });
  }, []);

  const handleCheckboxChange = (id: number) => () => {
    if (id === 1) {
      setFirstSelected(!firstSelected);
    }
  };

  const handleSubmitFoods = () => {
    navigate(BolusAdministrationRoutesEnum.BolusAdministrationMain, {
      usingCustomFoods: true,
    });
  };

  return (
    <div className="bg-white flex flex-col justify-between h-full pb-[34px] pt-[17px]">
      <div>
        <div className="flex justify-between items-end px-2 mb-[11px]">
          <div className="text-modals text-ios-text-gray">
            <FormattedMessage {...bolusCustomFoodsMessages.selectCustomFoods} />
          </div>
          <div className="text-ios-blue cursor-pointer">
            <FormattedMessage {...bolusCustomFoodsMessages.sortFoods} />
          </div>
        </div>

        <div>
          {customFoods.map(({ id, name, carbs }) => (
            <div className="flex justify-between items-end px-2 py-[13px] border-b-1 border-b-ios-gray-9/[.36]">
              <div className="flex items-center">
                <div className="mr-2">
                  <div>
                    <Checkbox
                      onChange={handleCheckboxChange(id)}
                      value={id === 1 ? firstSelected : false}
                    />
                  </div>
                </div>
                <div>{name}</div>
              </div>
              <div className="text-ios-text-gray">
                <FormattedMessage
                  {...bolusCustomFoodsMessages.carbs}
                  values={{ carbs: carbs }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="px-2">
        <div className="text-4xl font-bold mb-[3px]">
          <FormattedMessage
            {...bolusCustomFoodsMessages.total}
            values={{ carbs: firstSelected ? 10 : 0 }}
          />
        </div>
        <div className="text-small text-ios-text-gray mb-[18px]">
          <FormattedMessage
            {...bolusCustomFoodsMessages.itemsSelected}
            values={{ selectedItems: firstSelected ? 1 : 0 }}
          />
        </div>
        <div>
          <Button
            variant="colored"
            colorMode="automated"
            full
            disabled={!firstSelected}
            onClick={handleSubmitFoods}
          >
            <FormattedMessage {...bolusCustomFoodsMessages.addToCalculator} />
          </Button>
        </div>
      </div>
    </div>
  );
};
