let strings = {
  "en-US": {
    loading: "Loading Viewer list, please wait...",
    listOfViewers: "List of people who have permission to view your",
    viewers: "Viewers",
    editModalTitle: "Edit Viewer information",
    disabledForSim: "Disabled for the simulator.",
    ok: "ok",
    deleteModalTitle: "Delete Viewer information",
    deleteModalIsExpertTitle: "Delete this viewer from",
    deleteModalIsExpertBody:
      "Deleted Viewers will no longer have access to your",
    cancel: "Cancel",
    delete: "Delete",
    connecting: "Connecting...",
    deletingViewer: "Deleting Viewer",
    viewerDeleted: "Viewer deleted",
    viewerDeletedBody: "The Viewer will no longer be able to view your",
    viewerDetails: "Viewer Details",
    viewerDetailsBody: "For simulation purpose, no more than",
    viewerDetailsBodyTwo: "additional viewer can be created.",
    addViewer: "add viewer",
    firstName: "Maggie",
    data: "data."
  },
  "en-GB": {
    loading: "Loading Viewer list, please wait...",
    listOfViewers: "List of people who have permission to view your",
    viewers: "Viewers",
    editModalTitle: "Edit Viewer information",
    disabledForSim: "Disabled for the simulator.",
    ok: "ok",
    deleteModalTitle: "Delete Viewer information",
    deleteModalIsExpertTitle: "Delete this viewer from",
    deleteModalIsExpertBody:
      "Deleted Viewers will no longer have access to your",
    cancel: "Cancel",
    delete: "Delete",
    connecting: "Connecting...",
    deletingViewer: "Deleting Viewer",
    viewerDeleted: "Viewer deleted",
    viewerDeletedBody: "The Viewer will no longer be able to view your",
    viewerDetails: "Viewer Details",
    viewerDetailsBody: "For simulation purpose, no more than",
    viewerDetailsBodyTwo: "additional viewer can be created.",
    addViewer: "add viewer",
    firstName: "Maggie",
    data: "data."
  },
  "de-DE": {
    loading: "Betrachter-Liste wird geladen, bitte warten ...",
    listOfViewers:
      "Liste der Personen, die die Erlaubnis haben zum Ansehen Ihrer",
    viewers: "Betrachter",
    editModalTitle: "Betrachterinformationen bearbeiten",
    disabledForSim: "Für Simulator deaktiviert.",
    ok: "OK",
    deleteModalTitle: "Betrachterinformationen bearbeiten",
    deleteModalIsExpertTitle: "Diesen Betrachter löschen aus",
    deleteModalIsExpertBody:
      "Gelöschte Betrachter haben keinen Zugang mehr zu Ihren",
    cancel: "Abbr.",
    delete: "Löschen",
    connecting: "Verbindungsaufbau ...",
    deletingViewer: "Betrachter löschen",
    viewerDeleted: "Betrachter gelöscht",
    viewerDeletedBody:
      "Dieser Betrachter hat keine Betrachtungsrechte mehr für Ihre",
    viewerDetails: "Betrachter-Details",
    viewerDetailsBody: "Zu Simulationszwecken können nicht mehr als",
    viewerDetailsBodyTwo: "zusätzliche Betrachter erstellt werden.",
    addViewer: "Betrachter hinzufügen",
    firstName: "Maggie",
    data: "Daten."
  },
  "es-US": {
    loading: "Cargando lista de observadores; espere...",
    listOfViewers: "Lista de personas que tienen permiso para ver su",
    viewers: "Observadores",
    editModalTitle: "Editar la información de los observadores",
    disabledForSim: "Inhabilitado para el simulador.",
    ok: "aceptar",
    deleteModalTitle: "Eliminar la información de los observadores",
    deleteModalIsExpertTitle: "Eliminar a este observador de",
    deleteModalIsExpertBody:
      "Los observadores eliminados ya no tendrán acceso a su",
    cancel: "Cancelar",
    delete: "Eliminar",
    connecting: "Conectando...",
    deletingViewer: "Eliminando observador",
    viewerDeleted: "Observador eliminado",
    viewerDeletedBody: "El observador ya no podrá ver sus",
    viewerDetails: "Datos del observador",
    viewerDetailsBody: "Para fines de simulación, no más de un",
    viewerDetailsBodyTwo: "observador adicional se puede crear.",
    addViewer: "agregar observador",
    firstName: "Maggie",
    data: "datos."
  },
  "fr-FR": {
    loading: "Chargement de la liste des visiteurs, veuillez patienter...",
    listOfViewers: "Liste des personnes ayant l'autorisation de visualiser vos",
    viewers: "Visiteurs",
    editModalTitle: "Modifier les informations du visiteur",
    disabledForSim: "Désactivé pour le simulateur.",
    ok: "ok",
    deleteModalTitle: "Supprimer les informations du visiteur",
    deleteModalIsExpertTitle: "Supprimer ce visiteur de",
    deleteModalIsExpertBody:
      "Les visiteurs supprimés n'auront plus accès à vos",
    cancel: "Annuler",
    delete: "Supprimer",
    connecting: "Connexion...",
    deletingViewer: "Suppression du visiteur",
    viewerDeleted: "Visiteur supprimé",
    viewerDeletedBody: "Ce visiteur ne pourra plus visualiser vos données",
    viewerDetails: "Coordonnées du visiteur",
    viewerDetailsBody: "À des fins de simulation, pas plus de",
    viewerDetailsBodyTwo:
      "visiteur(s) supplémentaire(s) ne peu(ven)t être créé(s).",
    addViewer: "ajouter un visiteur",
    firstName: "Maggie",
    data: "données."
  },
  "nl-NL": {
    loading: "Laden lijst meekijkers, even wachten...",
    listOfViewers:
      "Lijst van mensen die toestemming hebben tot bekijken van uw",
    viewers: "Meekijkers",
    editModalTitle: "Informatie meekijkers bewerken",
    disabledForSim: "Uitgeschakeld voor simulator.",
    ok: "OK",
    deleteModalTitle: "Informatie meekijker verwijderen",
    deleteModalIsExpertTitle: "Deze meekijker verwijderen van",
    deleteModalIsExpertBody:
      "Als u een meekijker verwijdert, kan deze niet meer bij uw",
    cancel: "Annuleren",
    delete: "Verwijderen",
    connecting: "Bezig met verbinden...",
    deletingViewer: "Meekijker wordt verwijderd",
    viewerDeleted: "Meekijker verwijderd",
    viewerDeletedBody: "Deze meekijker heeft geen inzage meer in uw",
    viewerDetails: "Details meekijker",
    viewerDetailsBody: "Voor simulatiedoeleinde, kan niet meer dan",
    viewerDetailsBodyTwo: "extra meekijker aanmaken.",
    addViewer: "meekijker toevoegen",
    firstName: "Maggie",
    data: "gegevens."
  }
};

export { strings };
