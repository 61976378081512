import { LanguageType } from "../types/language";
import { LanguageLongType } from "../types/languageLong";

export const getLanguageLong = (language: LanguageType) => {
  return {
    "en-US": "English (US)" as LanguageLongType,
    "es-US": "Español" as LanguageLongType,
    "en-GB": "English (UK)" as LanguageLongType,
    "de-DE": "Deutsch" as LanguageLongType,
    "fr-FR": "French" as LanguageLongType,
    "nl-NL": "Dutch" as LanguageLongType
  }[language];
};
