import { defineMessages } from 'react-intl';

export const DELIVERY_TIME = 0.5;

export const messages = defineMessages({
  topBarPauseInsulinTitle: {
    id: 'ios.pause-insulin.top-bar.title',
    defaultMessage: 'Pause Insulin',
  },
  backTitle: {
    id: 'txid.pause-insulin.back-title',
    defaultMessage: 'Back',
  },
  cancelTitle: {
    id: 'txid.pause-insulin.cancel-title',
    defaultMessage: 'Cancel',
  },
  howLongTitle: {
    id: 'ios.pause-insulin.fts.how-long-title',
    defaultMessage: 'How long would you like to pause insulin delivery?',
  },
  allInsulinDeliveryText: {
    id: 'ios.pause-insulin.fts.all-insulin-delivery-text',
    defaultMessage: 'All insulin delivery stops during this time. ',
  },
  DurationTitle: {
    id: 'ios.pause-insulin.fts.duration-title',
    defaultMessage: 'Duration',
  },
  hrsText: {
    id: 'ios.pause-insulin.fts.hrs-text',
    defaultMessage: 'hrs',
  },
  PauseInsulinButtonTitle: {
    id: 'ios.pause-insulin.fts.pause-insulin-button-title',
    defaultMessage: 'Pause Insulin',
  },
  topBarStartInsulinTitle: {
    id: 'ios.start-insulin.top-bar.title',
    defaultMessage: 'Start Insulin',
  },
  startInsulinDeliveryTitle: {
    id: 'ios.start-insulin.start-insulin-delivery.title',
    defaultMessage: 'Do you want to start insulin delivery?',
  },
  restartBasalProgramText: {
    id: 'ios.start-insulin.start-insulin-delivery.title',
    defaultMessage: 'This will restart your "Weekend" Basal Program.',
  },
  StartInsulinButtonTitle: {
    id: 'ios.start-insulin.fts.start-insulin-button-title',
    defaultMessage: 'Start Insulin',
  },
});
