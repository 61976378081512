import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { Paragraph } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./InsulinDurationSettingsScreenContent";

const InsulinDurationSettingsScreen: React.FC = () => {
  const [duration, setDuration] = React.useState<string>("--");
  const [isPressed, setIsPressed] = React.useState<boolean>(false);

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const handlePress = () => {
    setDuration("4");
    setIsPressed(true);
  };

  return (
    <View style={[styles.container]}>
      <View>
        <View style={[{ paddingHorizontal: 16, paddingTop: 24 }]}>
          <Paragraph variant="body2" label={languageContent.lengthOfTime} />
          <View style={[{ marginTop: 16 }]}>
            <Text style={[theme.fonts.h4]}>{languageContent.durationOf}</Text>
            <Text
              style={[
                theme.fonts.body2,
                { color: theme.colors.grayScale.gray400 }
              ]}>
              {languageContent.range}
            </Text>
          </View>
          <TouchableOpacity onPress={handlePress}>
            <View style={[theme.layout.inputContainerStyle, { marginTop: 8 }]}>
              <Text style={[theme.layout.inputStyle, { fontSize: 32 }]}>
                {duration}
              </Text>
              <Text style={[theme.layout.adornment, { fontSize: 18 }]}>
                {languageContent.hrs}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.next}
        rightAction={() => navigate("BolusSettingsMain")}
        rightActionStyle={{
          color: isPressed ? theme.colors.purple : theme.colors.placeholder
        }}
        rightActionDisabled={!isPressed}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "white"
  }
});

export default InsulinDurationSettingsScreen;
