import { Platform, PlatformIOSStatic, useWindowDimensions } from "react-native";

const platform = Platform as PlatformIOSStatic;

export const getGraphImageHorizontalPlacement = (width: number) => {
  if (platform.isPad) {
    if (width > 1000) {
      return "95%";
    } else {
      return "90%";
    }
  } else {
    //TODO FIX THIS
    return -32;
    /* useWindowDimensions().width - 32 */
  }
};
