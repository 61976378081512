import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { BottomNav } from 'web/components/ios/BottomMenu/BottomNav';
import { selectCurrentOperationMode } from 'web/store/reducers/user/user.helpers';
import { Button } from 'web/components/ios/Button';
import { switchToAutoMode } from 'web/store/reducers/user/actions';
import { basalPauseInsulin } from 'web/store/reducers/basal/actions';
import { PodCommunicationModal } from 'web/components/ios/modals/GlobalModals/GlobalModals';
import {
  setCurrentModal,
  showModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import { AppRoutesEnum } from 'web/routes/types';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';
import { autoModeMessages } from '../SwitchMode.messages';

export const SwitchToAuto: React.FC = () => {
  const dispatch = useDispatch();
  const currentOperationMode = useSelector(selectCurrentOperationMode);

  const handlePodCommTimeout = () => {
    dispatch(hideModal());
    dispatch(switchToAutoMode());
    dispatch(navigateToRoute(AppRoutesEnum.dashboard));
  };

  const handleSwitch = () => {
    dispatch(
      setCurrentModal({
        modal: <PodCommunicationModal onTimeout={handlePodCommTimeout} />,
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="font-ios text-regular h-full bg-ios-gray-6 flex justify-between flex-col">
      <div className="flex flex-col h-full px-2 py-3 justify-between bg-white">
        <div>
          <div className="text-2xl font-bold mb-3">
            <FormattedMessage {...autoModeMessages.title} />
          </div>
          <div>
            <p className="mb-3">
              <FormattedMessage {...autoModeMessages.paragraphOne} />
            </p>
            <p>
              <FormattedMessage {...autoModeMessages.paragraphTwo} />
            </p>
          </div>
        </div>

        <div>
          <Button
            onClick={handleSwitch}
            variant="colored"
            colorMode="automated"
            full
          >
            <FormattedMessage {...autoModeMessages.button} />
          </Button>
        </div>
      </div>

      <BottomNav />
    </div>
  );
};
