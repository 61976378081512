import { getMinutes, getHours, lightFormat } from "date-fns";

const getTime = () => {
  const today = new Date();
  let minutes: number | string = getMinutes(today);
  let hours = getHours(today);
  if (hours > 12) {
    hours = hours - 12;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  const time = `${hours}:${minutes} ${lightFormat(today, "a")}`;
  return time;
};

export default getTime;
