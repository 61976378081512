import { isEqual } from "lodash";
import React from "react";
import {
  Animated,
  Dimensions,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View
} from "react-native";
import { useSelector } from "react-redux";
import { ReactComponent as Ellipse } from "../../../../../assets/ellipse.svg";
import useLanguageContent from "../../../hooks/useLanguageContent";
import { LangState } from "../../../../../web/store/reducers/language";
import { RootState } from "../../../../../web/store/store";
import theme from "../../../theme";
import { strings } from "./BasalProgramEntryContent";

const { width } = Dimensions.get("window");

interface BasalProgramEntryProps {
  onPressMenu?: (programName: string) => void;
  onPressModal?: () => void;
  programName: string;
  unitsPerDay: string;
  customEntryStyle?: StyleProp<Animated.Animated>;
}

const BasalProgramEntry: React.FC<BasalProgramEntryProps> = ({
  onPressModal,
  onPressMenu,
  programName,
  unitsPerDay,
  customEntryStyle
}) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={[customEntryStyle ? customEntryStyle : styles.segmentRow]}>
      <TouchableWithoutFeedback
        style={[
          {
            flexDirection: "row",
            width: width * 0.8
          }
        ]}
        onPress={onPressModal}>
        <View style={[{ flexDirection: "row" }]}>
          <View>
            <Text style={[theme.fonts.body1, { width: 250 }]}>
              {programName}
            </Text>
            <Text style={[theme.fonts.body1, { color: theme.colors.blue }]}>
              {`${Number(unitsPerDay).toLocaleString(langState.locale)} ${
                languageContent.uDay
              }`}
            </Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
      <TouchableOpacity
        style={[{ justifyContent: "center" }]}
        onPress={() => (onPressMenu ? onPressMenu(programName) : {})}>
        <Ellipse />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  segmentRow: {
    flexDirection: "row",
    marginVertical: 16,
    marginHorizontal: 16
  },
  iconBadge: {
    backgroundColor: theme.colors.greenText,
    borderRadius: 4,
    marginTop: -14,
    padding: 2
  },
  iconBadgeText: {
    color: theme.colors.white,
    textAlign: "center"
  }
});

export default BasalProgramEntry;
