import { defineMessages } from 'react-intl';

export const CustomFoodsStackMessages = defineMessages({
  editButton: {
    id: 'ios.custom-foods-stack.edit-button',
    defaultMessage: 'Edit',
  },
  doneButton: {
    id: 'ios.custom-foods-stack.done-button',
    defaultMessage: 'Done',
  },
  customFoodsScreenHeaderTitle: {
    id: 'ios.custom-foods-stack.custom-Foods-screen-header-title',
    defaultMessage: 'Custom Foods',
  },
  addCustomFoodsHeaderTitle: {
    id: 'ios.custom-foods-stack.add-custom-foods-header-title',
    defaultMessage: 'Add Custom Food',
  },
});

export const CustomFoodsScreenMessages = defineMessages({
  addButton: {
    id: 'ios.custom-foods-screen.add-button',
    defaultMessage: 'Add Custom Food',
  },
});

export const AddCustomFoodsMessages = defineMessages({
  foodNameLabel: {
    id: 'ios.add-custom-foods.food-name-label',
    defaultMessage: 'FOOD NAME',
  },
  carbsNameLabel: {
    id: 'ios.add-custom-foods.carbs-name-label',
    defaultMessage: 'CARBS (g)',
  },
  saveButton: {
    id: 'ios.add-custom-foods.save-button',
    defaultMessage: 'Save to Custom Foods',
  },
  characterLimitLabel: {
    id: 'ios.add-custom-foods.character-limit-label',
    defaultMessage: '/20 characters',
  },
});
