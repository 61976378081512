import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { ReactComponent as UpArrow } from "../../../../../assets/UI_BigArrowUp.svg";
import useLanguageContent from "../../../hooks/useLanguageContent";
import { strings } from "./TempBasalCancelledContent.js";

const TempBasalCancelled = () => {
  const { languageContent } = useLanguageContent(strings);

  return (
    <View
      style={[
        styles.subItemWrapper,
        {
          marginLeft: 64
        }
      ]}>
      <Text style={[styles.subItemText]}>
        {languageContent.deliveredIncrease}
      </Text>
      <UpArrow height="70%" />
      <Text style={[styles.subItemText]}>{languageContent.percentPerHour}</Text>
    </View>
  );
};
const styles = StyleSheet.create({
  subItemWrapper: {
    padding: 16,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  subItemText: {
    fontSize: 14,
    marginLeft: 10
  }
});

export default TempBasalCancelled;
