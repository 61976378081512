let strings = {
  "en-US": {
    body1UpperLimit:
      "Upper limit for basal rates in a Basal Program or temp basal.",
    maxBasalRate: "Max Basal Rate",
    range: "(%1 to 30 U/hr)",
    setMaxBasalRate: "To set a Max Basal Rate",
    below: "below %2 U/hr",
    uHr: "U/hr",
    modifyYourBasal:
      "modify your Basal Programs and temp basal presets so that all of their basal rates are less than or equal to your desired Max Basal Rate.",
    cancel: "Cancel",
    save: "Save"
  },
  "en-GB": {
    body1UpperLimit:
      "Upper limit for basal rates in a Basal Programme or temp basal.",
    maxBasalRate: "Max Basal Rate",
    range: "(%1 to 30 U/hr)",
    setMaxBasalRate: "To set a Max Basal Rate",
    below: "below %2 U/hr",
    uHr: "U/hr",
    modifyYourBasal:
      "modify your Basal Programmes and temp basal presets so that all of their basal rates are less than or equal to your desired Max Basal Rate.",
    cancel: "Cancel",
    save: "Save"
  },
  "de-DE": {
    body1UpperLimit:
      "Obere Grenze für die Basalraten in einem Basalratenprofil oder einer temporären Basalrate.",
    maxBasalRate: "Maximale Basalrate",
    range: "(%1 bis 30 E/Std.)",
    setMaxBasalRate: "Wenn Sie eine maximale Basalrate",
    below: "unter %2 E/Std.",
    uHr: "E/Std.",
    modifyYourBasal:
      "festlegen möchten, ändern Sie die Vorgabewerte für die Basalratenprofile und die temporäre Basalrate, damit alle Basalraten kleiner oder gleich der gewünschten maximalen Basalrate sind.",
    cancel: "Abbr.",
    save: "Speichern"
  },
  "es-US": {
    body1UpperLimit:
      "Límite superior para las tasas basales en un programa basal o basal temporal.",
    maxBasalRate: "Tasa basal máxima",
    range: "(%1 a 30 U/h)",
    setMaxBasalRate: "Para establecer una tasa basal máxima",
    below: "inferior a %2 U/h",
    uHr: "U/h",
    modifyYourBasal:
      "modifique sus programas basales y los preajustes de basal temporal de modo que todas sus tasas basales sean iguales o inferiores a su tasa basal máxima deseada.",
    cancel: "Cancelar",
    save: "Guardar"
  },
  "fr-FR": {
    body1UpperLimit:
      "Limite supérieure du débit basal dans un programme basal ou un débit basal temporaire.",
    maxBasalRate: "Débit basal max",
    range: "(de %1 à 30 U/h)",
    setMaxBasalRate: "Pour configurer un débit basal max",
    below: "inférieur à %2 U/h",
    uHr: "U/h",
    modifyYourBasal:
      "modifiez vos programmes basaux et vos préréglages de débit basal temporaire pour que tous les débits basaux soient inférieurs ou égaux au débit basal max souhaité.",
    cancel: "Annuler",
    save: "Enregistrer"
  },
  "nl-NL": {
    body1UpperLimit:
      "Bovengrens voor basaalsnelheden in een basaalprogramma of tijdelijke basaalsnelheid.",
    maxBasalRate: "Maximale basaalsnelheid",
    range: "(%1 tot 30 E/u)",
    setMaxBasalRate: "Om een maximale basaalsnelheid in te stellen",
    below: "onder %2 E/u.",
    uHr: "E/u.",
    modifyYourBasal:
      "basaalprogramma en voorinstellingen voor tijdelijke basaalsnelheden aanpassen zodat alle basaalsnelheden lager dan of gelijk zijn aan uw gewenste maximale basaalsnelheid.",
    cancel: "Annuleren",
    save: "Opslaan"
  }
};

export { strings };
