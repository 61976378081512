import { add } from 'date-fns';
import * as type from './actions';

export interface UserState {
  acceptTC: boolean;
  setupComplete: boolean;
  podConfigured: boolean;
  datePodUpdated: string;
  datePodExpires: string;
  datePodActivated: string;
  hasTXId: boolean;
  automatedMode: boolean;
  limitedMode: boolean;
  limitedModeSeen: boolean;
  hypoProtectOn: boolean;
  tempBasalOn: boolean;
  tempBasalEnd: string;
  hypoProtectEnd: string;
  isExpert: boolean;
  hideAllModals: boolean;
  iosStatusBarMode: string;
}

export const initialState: UserState = {
  acceptTC: false,
  setupComplete: false,
  podConfigured: false,
  automatedMode: false,
  limitedMode: false,
  limitedModeSeen: false,
  hypoProtectOn: false,
  tempBasalOn: false,
  tempBasalEnd: '',
  hypoProtectEnd: '',
  datePodUpdated: '',
  datePodExpires: '',
  datePodActivated: '',
  hasTXId: false,
  isExpert: false,
  hideAllModals: false,
  iosStatusBarMode: '',
};

const now = new Date();
const expires = new Date(add(now, { days: 3 }));

export const jumpStartState: UserState = {
  acceptTC: true,
  setupComplete: true,
  podConfigured: true,
  automatedMode: true,
  limitedMode: false,
  limitedModeSeen: false,
  hypoProtectOn: false,
  tempBasalOn: false,
  tempBasalEnd: '',
  hypoProtectEnd: '',
  datePodUpdated: now.toString(),
  datePodExpires: expires.toString(),
  datePodActivated: now.toString(),
  hasTXId: true,
  isExpert: false,
  hideAllModals: false,
  iosStatusBarMode: '',
};

const reducer = (
  state: UserState = initialState,
  action: type.Action
): UserState => {
  switch (action.type) {
    case type.USER_UPDATE:
      return { ...state, ...action.payload.user };
    case type.USER_RESET:
      return { ...jumpStartState };
    case type.USER_INIT:
      return { ...initialState };
    case type.SWITCH_TO_AUTO_MODE:
      return { ...state, automatedMode: true, limitedMode: false };
    case type.SWITCH_TO_MANUAL_MODE:
      return {
        ...state,
        automatedMode: false,
        limitedMode: false,
        hypoProtectOn: false,
        hypoProtectEnd: new Date().toString(),
        tempBasalOn: false,
        tempBasalEnd: new Date().toString(),
      };
    case type.SET_ACTIVITY_MODE:
      if (action.payload.endDate) {
        return {
          ...state,
          hypoProtectOn: true,
          hypoProtectEnd: action.payload.endDate?.toString(),
        };
      }
      return { ...state };
    case type.CANCEL_ACTIVITY_MODE:
      return {
        ...state,
        hypoProtectOn: false,
        hypoProtectEnd: '',
      };
    case type.SET_TEMP_BASAL:
      if (action.payload.endDate) {
        return {
          ...state,
          tempBasalOn: true,
          tempBasalEnd: action.payload.endDate?.toString(),
        };
      }
      return { ...state };
    case type.CANCEL_TEMP_BASAL:
      return {
        ...state,
        tempBasalOn: false,
        tempBasalEnd: '',
      };
    default:
      return state;
  }
};

export default reducer;
