import { camelCase, startCase } from "lodash";

const makePascalCase = (stringToConvert: any) => {
  const convertedString = startCase(camelCase(stringToConvert)).replace(
    / /g,
    ""
  );

  return convertedString;
};

export default makePascalCase;
