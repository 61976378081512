import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
  Dimensions,
  Linking,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
} from "react-native";
import Arc from "../../../../../../assets/arcs/oyster_arc.svg";
import HomeIcon from "../../../../../../assets/tour_home_icon.svg";
import useLanguageContent from "../../../../hooks/useLanguageContent";
import theme from "../../../../theme";
import { strings } from "./HelpfulLinksScreenContent";
import { decode } from "html-entities";

// const AM_I_COVERED = "www.omnipod.com/get-started";
const WHAT_IS_OMNIPOD = "https://www.omnipod.com/what-is-omnipod/omnipod-5";
const IS_IT_RIGHT_FOR_ME = "https://www.omnipod.com/is-omnipod-right-for-me";
const PODDER_TALK = "https://www.omnipod.com/poddertalk";
const LEARN_MORE =
  "https://www.omnipod.com/what-is-omnipod/what-is-pod-therapy/what-is-automated-insulin-delivery";

const regTM = decode("&reg;");
const { height } = Dimensions.get("window");

interface Props {}

const HelpfulLinksScreen: React.FC<Props> = () => {
  const { goBack } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <>
      <SafeAreaView
        style={{
          flex: 0,
        }}
      />
      <View
        pointerEvents="box-none"
        style={{ marginTop: 24, width: 100, zIndex: 999, marginLeft: 16 }}>
        <TouchableOpacity onPress={() => goBack()}>
          <HomeIcon />
        </TouchableOpacity>
      </View>
      <View style={{ flex: 1, zIndex: 999 }}>
        <View style={{ marginBottom: 32, paddingTop: 16 }}>
          <Text
            style={{
              ...theme.fonts.h2,
              fontWeight: "400",
              textAlign: "center",
            }}>
            {languageContent.helpfulLinks}
          </Text>
        </View>
        <View
          style={[
            {
              flexDirection: "column",
              flex: 1,
              alignItems: "flex-end",
              marginTop: 16,
            },
          ]}>
          {/* This section will be implimented in a future release.   */}
          {/* <View style={styles.purpleButton}>
            <TouchableOpacity
              borderRadius={0}
              onPress={() => {
                navigate("LinkScreen", {
                  link: AM_I_COVERED,
                  title: "Am I covered?"
                });
              }}>
              <Text style={[styles.navLink]}>Am I covered?</Text>
            </TouchableOpacity>
          </View> */}
          <View style={styles.purpleButton}>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(WHAT_IS_OMNIPOD).catch((err) =>
                  console.error("Couldn't load page", err)
                );
              }}>
              <Text style={[styles.navLink]}>
                {languageContent.linkScreenLinkText2}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.purpleButton}>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(IS_IT_RIGHT_FOR_ME).catch((err) =>
                  console.error("Couldn't load page", err)
                );
              }}>
              <Text style={[styles.navLink]}>
                {languageContent.linkScreenLinkText3}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.purpleButton}>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(PODDER_TALK).catch((err) =>
                  console.error("Couldn't load page", err)
                );
              }}>
              <Text style={[styles.navLink]}>
                {languageContent.linkScreenLinkText4}
                <Text
                  style={{
                    ...theme.fonts.superscript,
                  }}>
                  {`${regTM} `}
                </Text>
                {languageContent.talk}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.purpleButton}>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(LEARN_MORE).catch((err) =>
                  console.error("Couldn't load page", err)
                );
              }}>
              <Text style={[styles.navLink]}>
                {languageContent.linkScreenLinkText5}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <Arc
        style={{
          position: "absolute",
          bottom: height > 800 ? height / -11 : -1,
        }}
      />
    </>
  );
};
const styles = StyleSheet.create({
  navLink: {
    ...theme.fonts.h3,
    fontWeight: "500",
    marginVertical: 12,
    color: theme.colors.white,
    alignSelf: "flex-start",
    paddingLeft: 32,
  },
  purpleButton: {
    backgroundColor: theme.colors.modalPurple,
    width: "85%",
    borderTopLeftRadius: 35,
    borderBottomLeftRadius: 35,
    marginBottom: 16,
    overflow: "hidden",
  },
});

export default HelpfulLinksScreen;
