import React, { useState, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Button } from 'web/components/ios/Button';
import { setExtendedBolusDeliveryValues } from 'web/store/reducers/bolus/actions';
import { extendedBolusAdministrationMessages } from '../BolusAdministration.messages';
import { BolusAdministrationRoutesEnum } from '../types';

export const ExtendedBolusAdministration: React.FC = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigation();

  const [nowValue, setNowValue] = useState<number | null>(null);
  const [nowUnits, setNowUnits] = useState<number>(6);
  const [extendedValue, setExtendedValue] = useState<number | null>(null);
  const [extendedUnits, setExtendedUnits] = useState<number>(0);
  const [valuesEntered, setValuesEntered] = useState<boolean>(false);
  const [duration, setDuration] = useState<number | null>(null);

  useEffect(() => {
    if (nowValue && extendedValue && duration) {
      setValuesEntered(true);
    }
  }, [nowValue, extendedValue, duration]);

  const handleNowClick = () => {
    setNowValue(40);
    setNowUnits(2.4);
  };

  const handleExtendedClick = () => {
    setExtendedValue(60);
    setExtendedUnits(3.6);
  };

  const handleConfirmClick = () => {
    dispatch(setExtendedBolusDeliveryValues({
      mealBolus: 2.4,
      correctionBolus: 5,
      extendedBolus: 3.6,
      totalBolus: 11,
      deliveryTime: 1.5,
      isExtended: true,
    }));
    navigate(BolusAdministrationRoutesEnum.ConfirmBolus);
  };

  return (
    <div className="bg-white h-full flex flex-col justify-between pb-[34px] pt-[17px]">
      <div>
        <div className="mb-[34px]">
          <div className="px-2 text-small font-semibold mb-1">
            <FormattedMessage {...extendedBolusAdministrationMessages.now} />
          </div>
          <div
            className={classNames(
              'pb-1 px-2 border-b-1 border-b-ios-gray-9/30 text-4xl mb-1 font-bold cursor-pointer',
              {
                'text-ios-gray-9/30': !nowValue,
                'text-black': !!nowValue,
              }
            )}
            onClick={handleNowClick}
          >
            {nowValue ? `${nowValue} %` : '100 %'}
          </div>
          <div className="px-2 text-regular text-ios-gray-9/60">
            <FormattedMessage
              {...extendedBolusAdministrationMessages.units}
              values={{ value: nowUnits }}
            />
          </div>
        </div>

        <div className="mb-[34px]">
          <div className="px-2 text-small font-semibold mb-1">
            <FormattedMessage
              {...extendedBolusAdministrationMessages.extended}
            />
          </div>
          <div
            className={classNames(
              'pb-1 px-2 border-b-1 border-b-ios-gray-9/30 text-4xl mb-1 font-bold cursor-pointer',
              {
                'text-ios-gray-9/30': !extendedValue,
                'text-black': !!extendedValue,
              }
            )}
            onClick={handleExtendedClick}
          >
            {extendedValue ? `${extendedValue}%` : '0%'}
          </div>
          <div className="px-2 text-regular text-ios-gray-9/60">
            <FormattedMessage
              {...extendedBolusAdministrationMessages.units}
              values={{ value: extendedUnits }}
            />
          </div>
        </div>

        <div className="pb-1 px-2 border-b-1 border-b-ios-gray-9/30 flex justify-between items-center">
          <div>
            <div>
              <FormattedMessage
                {...extendedBolusAdministrationMessages.duration}
              />
            </div>
            <div className="text-small text-ios-gray-9/60">
              <FormattedMessage
                {...extendedBolusAdministrationMessages.durationRange}
              />
            </div>
          </div>

          <div
            className={classNames('cursor-pointer', {
              'text-ios-gray-9/60': !duration,
              'text-modes-manual': !!duration,
            })}
            onClick={() => setDuration(1.5)}
          >
            <FormattedMessage
              {...extendedBolusAdministrationMessages.hours}
              values={{ value: duration ? duration : '--' }}
            />
          </div>
        </div>
      </div>

      <div className="px-2 pt-[14px] border-t-1 border-t-ios-gray-9/30">
        <div>
          <div className="flex items-end mb-1">
            <div className="w-1/2 text-small font-semibold">
              <FormattedMessage
                {...extendedBolusAdministrationMessages.totalBolus}
              />
            </div>
            <div className="w-1/2 text-4xl font-bold">
              <FormattedMessage
                {...extendedBolusAdministrationMessages.units}
                values={{ value: 11 }}
              />
            </div>
          </div>
          <div className="flex items-end">
            <div className="w-1/2">
              <FormattedMessage
                {...extendedBolusAdministrationMessages.mealBolus}
              />
            </div>
            <div className="w-1/2">
              <FormattedMessage
                {...extendedBolusAdministrationMessages.units}
                values={{ value: 6 }}
              />
            </div>
          </div>
          <div className="flex items-end mb-[30px]">
            <div className="w-1/2">
              <FormattedMessage
                {...extendedBolusAdministrationMessages.correctionBolus}
              />
            </div>
            <div className="w-1/2">
              <FormattedMessage
                {...extendedBolusAdministrationMessages.units}
                values={{ value: 5 }}
              />
            </div>
          </div>
        </div>
        <Button
          variant="colored"
          colorMode="manual"
          full
          disabled={!valuesEntered}
          onClick={handleConfirmClick}
        >
          <FormattedMessage {...extendedBolusAdministrationMessages.button} />
        </Button>
      </div>
    </div>
  );
};
