import {
  ScanStep1,
  ScanStep2,
  ScanStep3,
  ScanStep4,
} from 'web/components/pages/L2/ScanSteps/ScanStepsScenes';
import { CarouselViewer } from 'web/components/helpers/CarouselViewer/CarouselViewer';

export const HowToScanStack = () => {
  const sceneMap = {
    ScanStep1,
    ScanStep2,
    ScanStep3,
    ScanStep4,
  };

  return <CarouselViewer sceneMap={sceneMap} />;
};
