const strings = {
  'en-US': {
    confirmSerialNumber: 'Confirm Serial Number',
    serialNumber: '1 2 3 4    1 2 3 4    1 2 3 4',
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
  'es-US': {
    confirmSerialNumber: 'Confirmar el Número de Serie',
    serialNumber: '1 2 3 4    1 2 3 4    1 2 3 4',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
  },
};

export { strings };
