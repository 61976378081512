import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  topBarBasalTempSettingsTitle: {
    id: 'ios.basal-temp-settings.top-bar.title',
    defaultMessage: 'Basal & Temp Basal Settings',
  },
  topBarMaxBasalRateTitle: {
    id: 'ios.basal-temp-settings.max-basal-rate.top-bar.title',
    defaultMessage: 'Max Basal Rate',
  },
  topBarTempBasalTitle: {
    id: 'ios.basal-temp-settings.temp-basal.top-bar.title',
    defaultMessage: 'Temp Basal',
  },
  maxBasalRate: {
    id: 'ios.basal-temp-settings.max-basal-rate.title',
    defaultMessage: 'Max Basal Rate',
  },
  tempBasal: {
    id: 'ios.basal-temp-settings.max-basal-rate.title',
    defaultMessage: 'Temp Basal',
  },
  configurePercent: {
    id: 'ios.basal-temp-settings.configure-percent.text',
    defaultMessage: 'Configured to use {unit}',
  },
  tapToTurnOn: {
    id: 'ios.basal-temp-settings.tap-to-turn-on.text',
    defaultMessage: 'OFF. Tap to turn ON.',
  },
  off: {
    id: 'ios.basal-temp-settings.off.text',
    defaultMessage: 'Off',
  },
  upperLimitBasal: {
    id: 'ios.basal-temp-settings.upper-limit-basal.text',
    defaultMessage:
      'Upper limit for basal rates in a Basal{separator} Program or temp basal.',
  },
  toSetMaxBasalRate: {
    id: 'ios.basal-temp-settings.to-set-max-basal-rate.text',
    defaultMessage:
      'To set a Max Basal Rate below 5 U/hr,{separator} modify your Basal Programs and temp basal presets so that all of their basal rates are less{separator} than or equal to your desired Max Basal Rate.',
  },
  saveButton: {
    id: 'ios.basal-temp-settings.save-button.text',
    defaultMessage: 'Save',
  },
  tempBasalChanges: {
    id: 'ios.basal-temp-settings.temp-basal-changes.text',
    defaultMessage:
      'A temp basal changes your basal rate for a set period of time.',
  },
  maxBasalRateUnit: {
    id: 'ios.basal-temp-settings.max-basal-rate-unit.text',
    defaultMessage: '(0.05 to 30 U/hr)',
  },
  adjustBy: {
    id: 'ios.basal-temp-settings.adjust-by.text',
    defaultMessage: 'ADJUST BY',
  },
  percent: {
    id: 'ios.basal-temp-settings.percent.text',
    defaultMessage: 'Percent',
  },
  flatRate: {
    id: 'ios.basal-temp-settings.flat-rate.text',
    defaultMessage: 'Flat Rate',
  },
  basalRateUnit: {
    id: 'ios.basal-temp-settings.flat-rate.text',
    defaultMessage: 'U/hr',
  },
});
