import { useNavigation } from '@react-navigation/native';
import { isEqual } from 'lodash';
import React from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as GreenCheckmark } from '../../../../../../../assets/check.svg';
import useLanguageContent from '../../../../../hooks/useLanguageContent';
import {
  basalActions,
  BasalState,
} from '../../../../../../../web/store/reducers/basal';
import {
  userActions,
  UserState,
} from '../../../../../../../web/store/reducers/user';
import { RootState } from '../../../../../../../web/store/store';
import theme from '../../../../../theme';
import { BottomInnerNav } from '../../../../molecules';
import { strings } from './ExitManualModeScreenContent';
const { height } = Dimensions.get('window');

const ExitManualModeScreen: React.FC = () => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );

  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <>
      <View
        style={[
          theme.layout.wrapper,
          { backgroundColor: 'white', width: '100%' },
        ]}
      >
        <View style={[styles.innerWrapper]}>
          <View>
            <View style={{ height: 200, marginBottom: 16 }}>
              <Text style={[theme.fonts.h4]}>{languageContent.switch}</Text>
              <Text
                style={[theme.fonts.body2, { marginTop: 16, marginBottom: 32 }]}
              >
                {languageContent.inAutomatedMode}
              </Text>
              <View
                style={{
                  backgroundColor: theme.colors.textInputBackgroundColor,
                  marginHorizontal: -16,
                  padding: 16,
                }}
              >
                <Text
                  style={[
                    theme.fonts.body2,
                    { width: '90%', marginBottom: 16 },
                  ]}
                >
                  {languageContent.useAutomatedMode}
                </Text>
                <View style={{ flexDirection: 'row' }}>
                  <GreenCheckmark
                    style={{ height: 24, width: 24, marginRight: 8 }}
                  />
                  <Text style={[theme.fonts.body2, { marginBottom: 16 }]}>
                    {languageContent.anActivePod}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <GreenCheckmark
                    style={{ height: 24, width: 24, marginRight: 8 }}
                  />
                  <Text style={[theme.fonts.body2, { width: '95%' }]}>
                    {languageContent.transmitterSerialNumber}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <BottomInnerNav
          leftActionText={languageContent.close}
          leftActionStyle={{ color: theme.colors.text.cancel }}
          rightActionText={languageContent.switchTwo}
          rightAction={() => {
            try {
              dispatch(
                userActions.userUpdate(
                  {
                    automatedMode: true,
                  },
                  userState
                )
              );
              dispatch(
                basalActions.basalUpdate(
                  {
                    insulinPaused: true,
                  },
                  basalState
                )
              );
              navigate('Drawer');
            } catch (err) {
              console.error(err);
            }
          }}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    paddingHorizontal: 16,
    marginTop: height < 800 ? height / 40 : 16,
  },
});

export default ExitManualModeScreen;
