let strings = {
  "en-US": {
    inProgress: "In Progress",
    view: "VIEW",
    uHr: "U/hr",
    total: "Total: %1 U/day",
    everyDay: "Everyday",
    weekend: "Weekend"
  },
  "en-GB": {
    inProgress: "In Progress",
    view: "VIEW",
    uHr: "U/hr",
    total: "Total: %1 U/day",
    everyDay: "Everyday",
    weekend: "Weekend"
  },
  "de-DE": {
    inProgress: "In Bearbeitung",
    view: "ANSEHEN",
    uHr: "E/Std.",
    total: "Gesamt: %1 E/Tag",
    everyDay: "Jeden Tag",
    weekend: "Wochenende"
  },
  "es-US": {
    inProgress: "En progreso",
    view: "VER",
    uHr: "U/h",
    total: "Total: %1 U/día",
    everyDay: "Programa Basal",
    weekend: "Fin de semana"
  },
  "fr-FR": {
    inProgress: "En cours",
    view: "AFFICHER",
    uHr: "U/h",
    total: "Total : %1 U/jour",
    everyDay: "programme basal",
    weekend: "Week-end"
  },
  "nl-NL": {
    inProgress: "Bezig",
    view: "BEKIJKEN",
    uHr: "E/u.",
    total: "Totaal: %1 E/dag",
    everyDay: "Elke dag",
    weekend: "Weekend"
  }
};

export { strings };
