let strings = {
  "en-US": {
    allowPrompt: "Allow temporary basal rates",
    tempDescription:
      "A temp basal changes your basal rate for a set period of time.",
    tempBasal: "Temp Basal",
    changeBasalRates:
      "Temp basals are set to change basal rates by a percentage. You can change this to a flat rate (U/hr) in Settings.",
    cancel: "cancel",
    next: "next"
  },
  "en-GB": {
    allowPrompt: "Allow temporary basal rates",
    tempDescription:
      "A temp basal changes your basal rate for a set period of time.",
    tempBasal: "Temp Basal",
    changeBasalRates:
      "Temp basals are set to change basal rates by a percentage. You can change this to a flat rate (U/hr) in Settings.",
    cancel: "cancel",
    next: "next"
  },
  "de-DE": {
    allowPrompt: "Temporäre Basalraten zulassen",
    tempDescription:
      "Eine temporäre Basalrate ändert Ihre Basalrate für einen festgelegten Zeitraum.",
    tempBasal: "Temporäre Basalrate",
    changeBasalRates:
      "Temporäre Basalraten sind eingestellt, um die Basalraten um einen Prozentsatz zu ändern. Sie können dies in den Einstellungen zu einem Festwert (E/Std.) ändern.",
    cancel: "Abbr.",
    next: "Weiter"
  },
  "es-US": {
    allowPrompt: "Permitir tasas basales temporales",
    tempDescription:
      "Un basal temporal cambia su tasa basal durante un período de tiempo determinado.",
    tempBasal: "Basal temporal",
    changeBasalRates:
      "Los basales temporales están configurados para cambiar las tasas basales en un porcentaje. Puede cambiarlo a una tasa fija (U/h) en Ajustes.",
    cancel: "cancelar",
    next: "siguiente"
  },
  "fr-FR": {
    allowPrompt: "Autoriser les débits basaux temporaire ?",
    tempDescription:
      "Un débit basal temporaire modifie votre débit basal pour une période donnée.",
    tempBasal: "Débit basal temporaire",
    changeBasalRates:
      "Les débits basaux temporaires sont configurés pour modifier les débits basaux en fonction d'un pourcentage. Vous pouvez les transformer en débit fixe (U/h) dans Réglages.",
    cancel: "annuler",
    next: "suivant"
  },
  "nl-NL": {
    allowPrompt: "Tijdelijke basaalsnelheden toelaten?",
    tempDescription:
      "Een tijdelijke basaalsnelheid wijzigt uw basaalsnelheid gedurende een ingestelde tijdperiode.",
    tempBasal: "Tijdelijke basaalsnelheid",
    changeBasalRates:
      "Tijdelijke basale snelheden zijn ingesteld om basaalsnelheden te wijzigen met een percentage. In de instellingen kunt u dit wijzigen naar een vaste snelheid (E/u).",
    cancel: "annuleren",
    next: "volgende"
  }
};

export { strings };
