import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { GraphScreen } from "../../components/screens";

const Stack = createStackNavigator();

const GraphStack: React.FC = () => {
  return (
    <>
      <Stack.Navigator initialRouteName="GraphScreen" mode="card">
        <Stack.Screen
          name="GraphScreen"
          options={{
            headerShown: false,
            animationEnabled: false
          }}
          component={GraphScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default GraphStack;
