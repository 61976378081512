const strings = {
    "en-US": {
      cancel: 'Cancel',
      confirm: 'Confirm',
      confirmSwitchTo: 'Confirm switching to',
      youAreSwitchingFrom: 'You are switching from',
      to: 'to',
      activeSensorDescription:
        'If you have an active Sensor you should remove it and discard the old Sensor because the Omnipod 5 App will no longer be able to communicate with it.',
    },
    "es-US": {
      cancel: 'Cancel',
      confirm: 'Confirmar',
      confirmSwitchTo: 'Confirmar cambio a',
      youAreSwitchingFrom: 'Está cambiando de ',
      to: 'a',
      activeSensorDescription:
        'Si tiene un Sensor activo, debe quitar y desechar el Sensor viejo porque la Aplicación Omnipod 5 ya no podrá comunicarse con él.',
    },
    "en-GB": {
      cancel: 'Cancel',
      confirm: 'Confirm',
      confirmSwitchTo: 'Confirm switching to',
      youAreSwitchingFrom: 'You are switching from',
      to: 'to',
      activeSensorDescription:
        'If you have an active Sensor you should remove it and discard the old Sensor because the Omnipod 5 App will no longer be able to communicate with it.',

    },
    "nl-NL": {
      cancel: 'Annuleren',
      confirm: 'Bevestigen',
      confirmSwitchTo: 'Bevestig omschakelen naar',
      youAreSwitchingFrom: 'U schakelt over van',
      to: 'naar',
      activeSensorDescription:
        'Als u een actieve sensor heeft, verwijder dan de oude Sensor en gooi deze weg, omdat de Omnipod 5-app er niet langer mee kan communiceren.',
    }
  };
  
  export { strings };
  