import React, { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { createStackNavigator } from '@react-navigation/stack';

import { GraphScreen } from './views/GraphScreen';
import { UserState, userActions } from 'web/store/reducers/user';
import { RootState } from 'web/store/store';
import { TopBarStatusModes } from 'web/components/ios/IOSStatusBar';

const Stack = createStackNavigator();

export const GraphStack: React.FC = () => {
  const dispatch = useDispatch();
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  useEffect(() => {
    dispatch(
      userActions.userUpdate(
        {
          iosStatusBarMode: TopBarStatusModes.Hide,
        },
        userState
      )
    );
    return () => {
      dispatch(
        userActions.userUpdate(
          {
            iosStatusBarMode: TopBarStatusModes.Default,
          },
          userState
        )
      );
    };
  }, [dispatch, userState]);

  return (
    <Stack.Navigator initialRouteName="GraphScreen" mode="card">
      <Stack.Screen
        name="GraphScreen"
        options={{
          headerShown: false,
        }}
        component={GraphScreen}
      />
    </Stack.Navigator>
  );
};
