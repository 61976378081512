import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { ToggleSwitch } from 'web/components/ios/ToggleSwitch';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { PodCommunicationModal } from 'web/components/ios/modals/GlobalModals/GlobalModals';
import { setCurrentModal, showModal, hideModal } from 'web/store/reducers/modals/modalsSlice';
import { SettingsRemindersRoutesEnum } from '../types';
import { podShutOffMessages } from '../SettingsReminders.messages';

export const PodShutOff: React.FC = () => {
  const { navigate, setParams } = useNavigation();
  const dispatch = useDispatch();

  const handlePodTimeout = () => {
    dispatch(hideModal());
    navigate(SettingsRemindersRoutesEnum.RemindersMain);
  }

  const handleSaveClick = () => {
    dispatch(setCurrentModal({
      modal: <PodCommunicationModal onTimeout={handlePodTimeout} />
    }));
    dispatch(showModal());
  };

  useEffect(() => {
    setParams({
      topBarOnRightButtonClick: handleSaveClick
    });
  }, []);

  return (
    <div className="bg-white h-full pt-[19px] text-regular font-ios">
      <div className="px-2 pt-[10px] pb-[21px]">
        <FormattedMessage {...podShutOffMessages.text} />
      </div>

      <div className="flex flex-row items-center justify-between border-y-1 border-ios-gray-9/[.36] p-2">
        <div>
          <FormattedMessage {...podShutOffMessages.podShutOff} />
        </div>
        <div>
          <ToggleSwitch defaultValue readOnly />
        </div>
      </div>

      <div className="flex flex-row items-center justify-between border-b-1 border-ios-gray-9/[.36] p-2 mb-[21px]">
        <div>
          <div>
            <FormattedMessage {...podShutOffMessages.inactivityTimer} />
          </div>
          <div className="text-small text-ios-gray-9/60">
            <FormattedMessage {...podShutOffMessages.inactivityRange} />
          </div>
        </div>
        <div className="text-modes-manual">
          <FormattedMessage {...podShutOffMessages.value} />
        </div>
      </div>

      <div className="px-2 text-small">
        <FormattedMessage {...podShutOffMessages.disclaimer} />
      </div>
    </div>
  );
};