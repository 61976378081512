import { FormattedMessage } from 'react-intl';
import { historyEventPodMessages } from './HistoryEventPod.messages';
import { ChevronRight } from 'web/components/ios/icons';
import { MouseEventHandler } from 'react';

interface HistoryEventPodProps {
  time: string;
  status: string;
  onClick: MouseEventHandler<HTMLDivElement>;
}
export const HistoryEventPod: React.FC<HistoryEventPodProps> = ({
  time,
  status,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className="flex justify-between items-center bg-ios-gray-6 w-full py-1 px-2 border-b border-b-ios-gray-7 cursor-pointer"
    >
      <div>
        <div className="flex">
          <p className="text-regular mr-0.5">
            <FormattedMessage {...historyEventPodMessages.title} />
          </p>
          <p>{status}</p>
        </div>
        <div className="flex text-small">
          <p className="mr-0.5 text-ios-secondaryGray opacity-60">{time}</p>
        </div>
      </div>
      <div className="flex">
        <ChevronRight color="fill-ios-secondaryGray opacity-30" />
      </div>
    </div>
  );
};
