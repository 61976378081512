import { format, subDays } from "date-fns";
import { es } from "date-fns/locale";
import historyData from "../../../../mobile/latest/data/historyData";
import { getLocalizedDateFormat } from "../../../../mobile/latest/helpers/getLocalizedDateFormat";
import HistoryDailyItemState from "../../../../mobile/latest/types/historyDailyItemState";
import * as type from "./actions";

export interface HistoryState {
  sevenDays: {
    title: string;
    dateRange: {
      fromMonth: string;
      fromDate: string;
      toMonth: string;
      toDate: string;
    };
    data: HistoryDailyItemState[];
    titleSpanish: string;
    dateRangeSpanish: {
      fromMonth: string;
      fromDate: string;
      toMonth: string;
      toDate: string;
    };
    dataSpanish: HistoryDailyItemState[];
  };
  fourteenDays: {
    title: string;
    dateRange: {
      fromMonth: string;
      fromDate: string;
      toMonth: string;
      toDate: string;
    };
    data: HistoryDailyItemState[];
    titleSpanish: string;
    dateRangeSpanish: {
      fromMonth: string;
      fromDate: string;
      toMonth: string;
      toDate: string;
    };
    dataSpanish: HistoryDailyItemState[];
  };
  thirtyDays: {
    title: string;
    dateRange: {
      fromMonth: string;
      fromDate: string;
      toMonth: string;
      toDate: string;
    };
    data: HistoryDailyItemState[];
    titleSpanish: string;
    dateRangeSpanish: {
      fromMonth: string;
      fromDate: string;
      toMonth: string;
      toDate: string;
    };
    dataSpanish: HistoryDailyItemState[];
  };
  sixtyDays: {
    title: string;
    dateRange: {
      fromMonth: string;
      fromDate: string;
      toMonth: string;
      toDate: string;
    };
    data: HistoryDailyItemState[];
    titleSpanish: string;
    dateRangeSpanish: {
      fromMonth: string;
      fromDate: string;
      toMonth: string;
      toDate: string;
    };
    dataSpanish: HistoryDailyItemState[];
  };
  ninetyDays: {
    title: string;
    dateRange: {
      fromMonth: string;
      fromDate: string;
      toMonth: string;
      toDate: string;
    };
    data: HistoryDailyItemState[];
    titleSpanish: string;
    dateRangeSpanish: {
      fromMonth: string;
      fromDate: string;
      toMonth: string;
      toDate: string;
    };
    dataSpanish: HistoryDailyItemState[];
  };
}

let historyDataWithDate: HistoryDailyItemState[] = [];
let spanishHistoryDataWithDate: HistoryDailyItemState[] = [];

// create english dates
let daysToSubtract = 1;
historyData.map((item) => {
  const result = subDays(new Date(), daysToSubtract);
  const date = format(result, "LLL d");
  const completeItem = { ...item, date };
  historyDataWithDate.push(completeItem);
  daysToSubtract++;
});

// create spanish dates
let daysToSubtractSpanish = 1;
historyData.map((item) => {
  const result = subDays(new Date(), daysToSubtractSpanish);
  // const date = format(result, "LLL d");
  const date = getLocalizedDateFormat(result, "LLL d", es);
  const completeItem = { ...item, date };
  spanishHistoryDataWithDate.push(completeItem);
  daysToSubtractSpanish++;
});

// create data arrays with correct days
const sevenDaysData: HistoryDailyItemState[] = historyDataWithDate.slice(0, 7);
const fourteenDaysData: HistoryDailyItemState[] = historyDataWithDate.slice(
  0,
  14
);
const thirtyDaysData: HistoryDailyItemState[] = historyDataWithDate.slice(
  0,
  30
);
const sixtyDaysData: HistoryDailyItemState[] = historyDataWithDate.slice(0, 60);
const ninetyDaysData: HistoryDailyItemState[] = historyDataWithDate.slice(
  0,
  90
);

// create spanish data arrays with correct days
const sevenDaysDataSpanish: HistoryDailyItemState[] =
  spanishHistoryDataWithDate.slice(0, 7);
const fourteenDaysDataSpanish: HistoryDailyItemState[] =
  spanishHistoryDataWithDate.slice(0, 14);
const thirtyDaysDataSpanish: HistoryDailyItemState[] =
  spanishHistoryDataWithDate.slice(0, 30);
const sixtyDaysDataSpanish: HistoryDailyItemState[] =
  spanishHistoryDataWithDate.slice(0, 60);
const ninetyDaysDataSpanish: HistoryDailyItemState[] =
  spanishHistoryDataWithDate.slice(0, 90);

// date ranges for history screen
const sevenDaysDateRange = {
  fromMonth: format(new Date(), "LLLL"),
  fromDate: format(new Date(), "d"),
  toMonth: format(subDays(new Date(), 7), "LLLL"),
  toDate: format(subDays(new Date(), 7), "d")
};
const fourteenDaysDateRange = {
  fromMonth: format(new Date(), "LLLL"),
  fromDate: format(new Date(), "d"),
  toMonth: format(subDays(new Date(), 14), "LLLL"),
  toDate: format(subDays(new Date(), 14), "d")
};
const thirtyDaysDateRange = {
  fromMonth: format(new Date(), "LLLL"),
  fromDate: format(new Date(), "d"),
  toMonth: format(subDays(new Date(), 30), "LLLL"),
  toDate: format(subDays(new Date(), 30), "d")
};
const sixtyDaysDateRange = {
  fromMonth: format(new Date(), "LLLL"),
  fromDate: format(new Date(), "d"),
  toMonth: format(subDays(new Date(), 60), "LLLL"),
  toDate: format(subDays(new Date(), 60), "d")
};
const ninetyDaysDateRange = {
  fromMonth: format(new Date(), "LLLL"),
  fromDate: format(new Date(), "d"),
  toMonth: format(subDays(new Date(), 90), "LLLL"),
  toDate: format(subDays(new Date(), 90), "d")
};

// Spanish date ranges for history screen
const sevenDaysDateRangeSpanish = {
  fromMonth: getLocalizedDateFormat(new Date(), "LLLL", es),
  fromDate: getLocalizedDateFormat(new Date(), "d", es),
  toMonth: getLocalizedDateFormat(subDays(new Date(), 7), "LLLL", es),
  toDate: getLocalizedDateFormat(subDays(new Date(), 7), "d", es)
};
const fourteenDaysDateRangeSpanish = {
  fromMonth: getLocalizedDateFormat(new Date(), "LLLL", es),
  fromDate: getLocalizedDateFormat(new Date(), "d", es),
  toMonth: getLocalizedDateFormat(subDays(new Date(), 14), "LLLL", es),
  toDate: getLocalizedDateFormat(subDays(new Date(), 14), "d", es)
};
const thirtyDaysDateRangeSpanish = {
  fromMonth: getLocalizedDateFormat(new Date(), "LLLL", es),
  fromDate: getLocalizedDateFormat(new Date(), "d", es),
  toMonth: getLocalizedDateFormat(subDays(new Date(), 30), "LLLL", es),
  toDate: getLocalizedDateFormat(subDays(new Date(), 30), "d", es)
};
const sixtyDaysDateRangeSpanish = {
  fromMonth: getLocalizedDateFormat(new Date(), "LLLL", es),
  fromDate: getLocalizedDateFormat(new Date(), "d", es),
  toMonth: getLocalizedDateFormat(subDays(new Date(), 60), "LLLL", es),
  toDate: getLocalizedDateFormat(subDays(new Date(), 60), "d", es)
};
const ninetyDaysDateRangeSpanish = {
  fromMonth: getLocalizedDateFormat(new Date(), "LLLL", es),
  fromDate: getLocalizedDateFormat(new Date(), "d", es),
  toMonth: getLocalizedDateFormat(subDays(new Date(), 90), "LLLL", es),
  toDate: getLocalizedDateFormat(subDays(new Date(), 90), "d", es)
};

// set state
export const initialState: HistoryState = {
  sevenDays: {
    title: "7 Days",
    dateRange: sevenDaysDateRange,
    data: sevenDaysData,
    titleSpanish: "7 Dias",
    dateRangeSpanish: sevenDaysDateRangeSpanish,
    dataSpanish: sevenDaysDataSpanish
  },
  fourteenDays: {
    title: "14 Days",
    dateRange: fourteenDaysDateRange,
    data: fourteenDaysData,
    titleSpanish: "14 Dias",
    dateRangeSpanish: fourteenDaysDateRangeSpanish,
    dataSpanish: fourteenDaysDataSpanish
  },
  thirtyDays: {
    title: "30 Days",
    dateRange: thirtyDaysDateRange,
    data: thirtyDaysData,
    titleSpanish: "30 Dias",
    dateRangeSpanish: thirtyDaysDateRangeSpanish,
    dataSpanish: thirtyDaysDataSpanish
  },
  sixtyDays: {
    title: "60 Days",
    dateRange: sixtyDaysDateRange,
    data: sixtyDaysData,
    titleSpanish: "60 Dias",
    dateRangeSpanish: sixtyDaysDateRangeSpanish,
    dataSpanish: sixtyDaysDataSpanish
  },
  ninetyDays: {
    title: "90 Days",
    dateRange: ninetyDaysDateRange,
    data: ninetyDaysData,
    titleSpanish: "90 Dias",
    dateRangeSpanish: ninetyDaysDateRangeSpanish,
    dataSpanish: ninetyDaysDataSpanish
  }
};

const reducer = (
  state: HistoryState = initialState,
  action: type.Action
): HistoryState => {
  switch (action.type) {
    case type.HISTORY_INIT:
      return { ...initialState };
    case type.HISTORY_UPDATE:
      return { ...state, ...action.payload.history };
    default:
      return state;
  }
};

export default reducer;
