import React from "react";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { FTSIntroConfirmation } from "../../../../../organisms";
import { strings } from "./AlertsScreenContent";

const AlertsScreen: React.FC = () => {
  const { languageContent } = useLanguageContent(strings);
  return (
    <FTSIntroConfirmation
      title={languageContent.title}
      description={languageContent.content}
      buttonLabel={languageContent.buttonText}
      navigateTo="Sounds"
    />
  );
};

export default AlertsScreen;
