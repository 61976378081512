import { defineMessages } from 'react-intl';

export const historyEventTempBasalMessages = defineMessages({
  title: {
    id: 'ios.history-event-temp-basal.title',
    defaultMessage: 'Temp Basal',
  },
  value: {
    id: 'ios.history-event-temp-basal.value',
    defaultMessage: 'Decrease for 9.5 hrs',
  },
  valuePercent: {
    id: 'ios.history-event-temp-basal.value-percent',
    defaultMessage: '↓30%',
  },
  startedStatus: {
    id: 'ios.history-event-temp-basal.started-status',
    defaultMessage: 'Started',
  },
});
