import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'web/store/store';

export const selectLanguageUnits = createSelector(
  (state: RootState) => state.language,
  (languageState) => languageState.units
);

export const selectLanguageIs24Hours = createSelector(
  (state: RootState) => state.language,
  (languageState) => languageState.is24Hour
);
