let strings = {
  "en-US": {
    notifications: "Notifications",
    pauseInsulin: "Pause Insulin",
    startInsulin: "Start Insulin",
    transmitter: "Transmitter",
    reminders: "Reminders",
    glucoseGoalRange: "Glucose Goal Range",
    basalAndTempBasal: "Basal & Temp Basal",
    maxBasalRate: "Max Basal Rate",
    about: "About",
    general: "General",
    pod: "Pod",
    history: "History",
    releaseNotes: "Release Notes",
    podExpiration: "Pod Expiration",
    lowPodInsulin: "Low Pod Insulin",
    podShutOff: "Pod Shut-Off",
    bg: "Enter BG",
    legalInformation: "Legal Information",
    sendLogFiles: "Send Log Files",
    logFilesPin: "Send Log Files",
    reset: "Reset"
  },
  "en-GB": {
    notifications: "Notifications",
    pauseInsulin: "Pause Insulin",
    startInsulin: "Start Insulin",
    transmitter: "Transmitter",
    reminders: "Reminders",
    glucoseGoalRange: "Glucose Goal Range",
    basalAndTempBasal: "Basal & Temp Basal",
    maxBasalRate: "Max Basal Rate",
    about: "About",
    general: "General",
    pod: "Pod",
    history: "History",
    releaseNotes: "Release Notes",
    podExpiration: "Pod Expiration",
    lowPodInsulin: "Low Pod Insulin",
    podShutOff: "Pod Shut-Off",
    bg: "Enter BG",
    legalInformation: "Legal Information",
    sendLogFiles: "Send Log Files",
    logFilesPin: "Send Log Files",
    reset: "Reset"
  },
  "de-DE": {
    notifications: "Meldungen",
    pauseInsulin: "Insulin unterbrechen",
    startInsulin: "Insulin starten",
    transmitter: "Sensor verwalten",
    reminders: "Erinnerungshinweise",
    glucoseGoalRange: "Glukose-Zielbereich",
    basalAndTempBasal: "Basalrate & Temporäre",
    maxBasalRate: "Maximale Basalrate",
    about: "Infos",
    general: "Allgemein",
    pod: "Pod",
    history: "Verlauf",
    bg: "BZ eingeben",
    podExpiration: "Pod-Ablauf",
    lowPodInsulin: "Wenig Insulin im Pod",
    podShutOff: "Pod-Abschaltung",
    releaseNotes: "Versionshinweise",
    legalInformation: "Legal Information",
    sendLogFiles: "Protokolldateien se...",
    logFilesPin: "Protokolldateien se...",
    reset: "Zurücksetzen"
  },
  "es-US": {
    notifications: "Notificaciones",
    pauseInsulin: "Pausar la insulina",
    startInsulin: "Iniciar la insulina",
    transmitter: "Administrar sensor",
    reminders: "Recordatorios",
    glucoseGoalRange: "Intervalo del objetivo...",
    basalAndTempBasal: "Basal & basal temporal",
    maxBasalRate: "Tasa basal máxima",
    about: "Acerca de",
    general: "General",
    pod: "Pod",
    history: "Historial",
    bg: "Introducir la GS",
    podExpiration: "Caducidad del Pod",
    lowPodInsulin: "Pod con insulina baja",
    podShutOff: "Apagado del Pod",
    sendLogFiles: "Enviar archivos de r...",
    logFilesPin: "Enviar archivos de r...",
    releaseNotes: "Notas de publicación",
    legalInformation: "Legal Information",
    reset: "Reiniciar"
  },
  "nl-NL": {
    notifications: "Kennisgevingen",
    pauseInsulin: "Insuline pauzeren",
    startInsulin: "Insuline starten",
    transmitter: "Sensor beheren",
    reminders: "Herinneringen",
    glucoseGoalRange: "Glucosedoelbereik",
    basalAndTempBasal: "Basaal en tijdelijke...",
    maxBasalRate: "Maximale basaalsn...",
    about: "Over",
    general: "Algemeen",
    pod: "Pod",
    history: "Geschiedenis",
    bg: "BG invoeren",
    podExpiration: "Uiterste gebruiksda...",
    lowPodInsulin: "Weinig insuline in P...",
    podShutOff: "Pod uitschakelen",
    sendLogFiles: "Stuur logbestanden",
    logFilesPin: "Stuur logbestanden",
    releaseNotes: "Opmerkingen bij uit...",
    legalInformation: "Legal",
    reset: "Resetten"
  },
  "fr-FR": {
    notifications: "Notifications",
    pauseInsulin: "Mettre en pause l'administration d'insuline",
    startInsulin: "Démarrer l'administration de l'insuline",
    transmitter: "transmetteur",
    reminders: "Rappels",
    glucoseGoalRange: "Plage d'objectifs glycémiques",
    basalAndTempBasal: "Débit basal et débit basal temporaire",
    maxBasalRate: "Débit basal max",
    about: "À propos",
    general: "Général",
    pod: "Pod",
    history: "Historique",
    releaseNotes: "Notes de version",
    podExpiration: "Péremption du Pod",
    lowPodInsulin: "Insuline du Pod basse",
    podShutOff: "Arrêt du Pod",
    bg: "Saisir la glycémie",
    legalInformation: "Informations légales",
    sendLogFiles: "Envoyer les fichiers du journal",
    logFilesPin: "Envoyer les fichiers du journal",
    reset: "Réinitialiser"
  },
};

export { strings };
