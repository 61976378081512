let strings = {
  "en-US": {
    loading: "Loading..."
  },
  "en-GB": {
    loading: "Loading..."
  },
  "de-DE": {
    loading: "Wird geladen ..."
  },
  "es-US": {
    loading: "Cargando..."
  },
  "fr-FR": {
    loading: "Chargement…"
  },
  "nl-NL": {
    loading: "Bezig met laden..."
  }
};

export { strings };
