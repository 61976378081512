var DURATIONS: {
  label: string;
  value: number;
  index: number;
  key: string;
}[] = [];
DURATIONS.push({ key: "2", label: "2", value: 2, index: 2 });
DURATIONS.push({ key: "2.5", label: "2.5", value: 2.5, index: 3 });
DURATIONS.push({ key: "3", label: "3", value: 3, index: 4 });
DURATIONS.push({ key: "3.5", label: "3.5", value: 3.5, index: 5 });
DURATIONS.push({ key: "4", label: "4", value: 4, index: 6 });
DURATIONS.push({ key: "4.5", label: "4.5", value: 4.5, index: 7 });
DURATIONS.push({ key: "5", label: "5", value: 5, index: 8 });
DURATIONS.push({ key: "5.5", label: "5.5", value: 5.5, index: 9 });
DURATIONS.push({ key: "6", label: "6", value: 6, index: 10 });

export default DURATIONS;
