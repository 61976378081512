import { useNavigation } from "@react-navigation/native";
import { add } from 'date-fns';
import { isEqual } from "lodash";
import React from "react";
import { Dimensions } from "react-native";
import { Image, StyleSheet, Text, View } from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import ChangePodTwoGif from "../../../../../../../assets/changePod2.gif";
import { ReactComponent as CheckingCannula } from "../../../../../../../assets/checking-cannula.svg";
import PodProperlyInsertedImage from "../../../../../../../assets/pod_properly_inserted.png";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { bolusActions, BolusState } from "../../../../../../../web/store/reducers/bolus";
import { userActions, UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { StepListItem } from "../../../../atoms";
import {
  BottomInnerNav,
  ConfirmationModal,
  PodCommAlert,
  TooltipModal
} from "../../../../molecules";
import { strings } from "./ChangePodTwoScreenContent";
import { NavState } from "../../../../../../../web/store/reducers/navigation";

const { height, width } = Dimensions.get("window");

const ChangePodScreenTwo: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const [cannulaStep, setCannulaStep] = React.useState<boolean>(false);
  const [isSecure, setIsSecure] = React.useState<boolean>(false);
  const [confirmCannula, setConfirmCannula] = React.useState<boolean>(false);
  const [confirmAutomated, setConfirmAutomated] =
    React.useState<boolean>(false);
  const [removePod, setRemovePod] = React.useState<boolean>(false);
  const [recordPodSiteModal, setRecordPodSiteModal] =
    React.useState<boolean>(false);
  const [checkCannulaModal, setCheckCannulaModal] =
    React.useState<boolean>(false);
  const [removePodModal, setRemovePodModal] = React.useState<boolean>(false);
  const [showModalPodSite, setShowModalPodSite] =
    React.useState<boolean>(false);
  const [showModalCannula, setShowModalCannula] =
    React.useState<boolean>(false);
  const [showModalCheckPod, setShowModalCheckPod] =
    React.useState<boolean>(false);
  const [confirmDeactivate, setConfirmDeactivate] =
    React.useState<boolean>(false);
  const [deactivate, setDeactivate] = React.useState<boolean>(false);

  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);

  const handleNav = () => {
    navigate(navState.podStackEntryRoute === "Drawer" ? "Drawer" : "TXSetup");
  };

  const triggerCannulaStep = () => {
    setIsSecure(false);
    setCannulaStep(true);
  };

  const triggerConfirmCannulaStep = () => {
    setConfirmDeactivate(false);
    setConfirmCannula(true);
  };

  const triggerAutomatedConfirm = () => {
    setCannulaStep(false);
    setConfirmCannula(false);
    setCannulaStep(false);
    setConfirmAutomated(true);
  };

  const triggerNextStep = async (automatedMode: boolean) => {
    setCannulaStep(false);
    await updateUser(automatedMode);

    setConfirmCannula(false);
    setCannulaStep(false);
    setConfirmAutomated(false);

    //update with TX Setup for else
    setTimeout(() => {
      navigate(userState.hasTXId ? "Drawer" : "TXSetup");
    }, 150);
  };

  const triggerConfirmDeactivateStep = () => {
    setConfirmCannula(false);
    setConfirmDeactivate(true);
  };

  const triggerDeactivateStep = () => {
    setConfirmDeactivate(false);
    setDeactivate(true);
  };

  const onCannulaDismiss = () => {
    setCannulaStep(false);
    setConfirmCannula(true);
  };

  const onDeactivateDismiss = () => {
    setDeactivate(false);
    setRemovePod(true);
  };

  const onPodRemoved = () => {
    setRemovePod(false);
    navigate("NoActivePod");
  };

  const handleNextAction = () => {
    setIsSecure(true);
  };

  const updateUser = async (automatedMode: boolean) => {
    try {
      const today = new Date();
      const expires = new Date(add(today, { days: 3 }));

      if (userState.setupComplete) {
        dispatch(
          userActions.userUpdate(
            {
              datePodActivated: today.toString(),
              datePodUpdated: today.toString(),
              datePodExpires: expires.toString(),
              podConfigured: true,
              automatedMode: automatedMode
            },
            userState
          )
        );
        dispatch(
          bolusActions.bolusUpdate(
            {
              podInsulin: 125
            },
            bolusState
          )
        );
      } else {
        dispatch(
          userActions.userUpdate(
            {
              datePodActivated: today.toString(),
              datePodUpdated: today.toString(),
              datePodExpires: expires.toString(),
              podConfigured: true
            },
            userState
          )
        );
        dispatch(
          bolusActions.bolusUpdate(
            {
              podInsulin: 125
            },
            bolusState
          )
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <View
        style={[
          theme.layout.wrapper,
          { backgroundColor: "white", marginTop: -4 }
        ]}>
        <View style={[styles.innerWrapper]}>
          <StepListItem
            textBeforeHighlight={`${languageContent.cleanSite}\n `}
            highlightedText={languageContent.recordPod}
            stepNumber="3"
            highlightedTextCallback={() => {
              setRecordPodSiteModal(true);
            }}
            lineHeight={32}
          />
          <View style={styles.stepFourContainer}>
            <StepListItem
              textBeforeHighlight={`${languageContent.removeTab} `}
              highlightedText={`${languageContent.checkCannula}`}
              textAfterHighlight={`.\n${languageContent.removeBacking}`}
              stepNumber="4"
              highlightedTextCallback={() => {
                setCheckCannulaModal(true);
              }}
              lineHeight={180}
            />
            <Image
              style={styles.imageCanvas}
              resizeMode="contain"
              source={ChangePodTwoGif}
            />
          </View>
          <StepListItem
            highlightedText={languageContent.checkPod}
            textAfterHighlight={` ${languageContent.applySite}`}
            stepNumber="5"
            highlightedTextCallback={() => {
              setRemovePodModal(true);
            }}
          />
          <StepListItem
            textBeforeHighlight={`${languageContent.tap} `}
            boldedText={languageContent.startLower}
            textAfterHighlight={` ${languageContent.insertCannulaBasal} `}
            stepNumber="6"
            lastStepOnPage
          />
        </View>
      </View>

      <BottomInnerNav
        rightActionText={languageContent.start}
        leftActionText={languageContent.cancel}
        rightAction={handleNav}
        rightActionStyle={{ color: theme.colors.purple }}
      />
      {cannulaStep ? (
        <PodCommAlert
          message={languageContent.cannulaStep}
          showCannula={true}
          onDismiss={onCannulaDismiss}
          duration={2000}
        />
      ) : null}
      {deactivate ? (
        <PodCommAlert
          title={languageContent.deactiveTitle}
          message={languageContent.deactiveMessage}
          duration={2000}
          onDismiss={onDeactivateDismiss}
        />
      ) : null}
      <ConfirmationModal
        isVisible={removePod}
        title={languageContent.removePod}
        message={
          <View>
            <Text style={[theme.fonts.body1]}>
              {languageContent.removePodDelivering}
              <Text style={[theme.fonts.body1, { fontWeight: "bold" }]}>
                {languageContent.continue}
              </Text>
            </Text>
          </View>
        }
        hideDismiss={true}
        confirmText={languageContent.continueCamel}
        onConfirm={() => {
          onPodRemoved();
        }}
      />
      {isSecure ? (
        <ConfirmationModal
          isVisible={isSecure}
          title={languageContent.isPod}
          modalStyle={{
            width: 400,
            height: 200,
            marginTop: height * 0.5,
            marginLeft: width * 0.3275
          }}
          message={
            <View>
              <Text style={[theme.fonts.body2]}>
                {`${languageContent.securelyAttached} `}
                <Text
                  style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                  {`${languageContent.yes} `}
                </Text>
                {languageContent.insertCannula}
              </Text>
            </View>
          }
          onDismiss={() => setIsSecure(false)}
          onConfirm={() => {
            triggerCannulaStep();
          }}
        />
      ) : null}
      <ConfirmationModal
        isVisible={confirmCannula}
        title={languageContent.insertedProperly}
        message={
          <View>
            <Text style={[theme.fonts.body1]}>{languageContent.pinkColor}</Text>
            <Image
              style={{
                width: "100%",
                marginVertical: -46
              }}
              resizeMode="contain"
              source={PodProperlyInsertedImage}
            />
          </View>
        }
        onDismiss={() => {
          triggerConfirmDeactivateStep();
        }}
        onConfirm={() => {
          userState.automatedMode
            ? triggerAutomatedConfirm()
            : triggerNextStep(false);
        }}
      />
      <ConfirmationModal
        isVisible={confirmAutomated}
        title={languageContent.switchToAutomated}
        message={
          <View>
            <Text style={[theme.fonts.body1]}></Text>
          </View>
        }
        confirmText={languageContent.confirmText}
        dismissText={languageContent.dismissText}
        onConfirm={() => {
          triggerNextStep(true);
        }}
        onDismiss={() => {
          triggerNextStep(false);
        }}
      />
      <ConfirmationModal
        isVisible={confirmDeactivate}
        title={languageContent.deactivatePod}
        message={
          <View>
            <Text style={[theme.fonts.body1]}>
              {languageContent.deactivatePodMessage}
            </Text>
          </View>
        }
        confirmText={languageContent.deactivateConfirm}
        dismissText={languageContent.cancel}
        onConfirm={() => {
          triggerDeactivateStep();
        }}
        onDismiss={() => {
          triggerConfirmCannulaStep();
        }}
      />
      <ConfirmationModal
        isVisible={showModalPodSite}
        title={languageContent.disabledForSim}
        purple
        message={
          <View>
            <Text style={[theme.fonts.body1, { color: theme.colors.white }]}>
              {languageContent.detailsForRecording}
            </Text>
          </View>
        }
        hideDismiss={true}
        confirmText={languageContent.OK}
        onConfirm={() => {
          setShowModalPodSite(false);
        }}
      />
      <ConfirmationModal
        isVisible={showModalCannula}
        title={languageContent.disabledForSim}
        purple
        message={
          <View>
            <Text style={[theme.fonts.body1, { color: theme.colors.white }]}>
              {languageContent.detailsForRecording}
            </Text>
          </View>
        }
        hideDismiss={true}
        confirmText={languageContent.OK}
        onConfirm={() => {
          setShowModalCannula(false);
        }}
      />
      <ConfirmationModal
        isVisible={showModalCheckPod}
        title={languageContent.disabledForSim}
        purple
        message={
          <View>
            <Text style={[theme.fonts.body1, { color: theme.colors.white }]}>
              {languageContent.detailsForChecking}
            </Text>
          </View>
        }
        hideDismiss={true}
        confirmText={languageContent.OK}
        onConfirm={() => {
          setShowModalCheckPod(false);
        }}
      />
      <TooltipModal
        title={languageContent.selectAPod}
        body={
          <Text style={[theme.fonts.body3, { marginBottom: 8 }]}>
            {languageContent.useThePodSites}
          </Text>
        }
        isVisible={recordPodSiteModal}
        onDismiss={() => setRecordPodSiteModal(false)}
        position="top"
      />
      <TooltipModal
        body={
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: 200 }}>
              <Text
                style={[
                  theme.fonts.body1,
                  {
                    fontWeight: "700",
                    color: theme.colors.mango,
                    marginBottom: 8
                  }
                ]}>
                {languageContent.checkTheCannula}
              </Text>
              <Text style={[theme.fonts.body3, { marginBottom: 8 }]}>
                {`${languageContent.cannulaSticksOut} `}
                <Text style={{ fontWeight: "700" }}>
                  {languageContent.cancelCaps}
                </Text>
                .
              </Text>
            </View>
            <View style={{ justifyContent: "center", marginLeft: 0 }}>
              <CheckingCannula />
            </View>
          </View>
        }
        isVisible={checkCannulaModal}
        onDismiss={() => setCheckCannulaModal(false)}
        position="mid"
      />
      <TooltipModal
        title={languageContent.checkThePod}
        body={
          <Text style={[theme.fonts.body3, { marginBottom: 8 }]}>
            {`${languageContent.podDamaged} `}
            <Text style={{ fontWeight: "700" }}>
              {languageContent.cancelCaps}
            </Text>
            .
          </Text>
        }
        isVisible={removePodModal}
        onDismiss={() => setRemovePodModal(false)}
        position="bottom"
      />
    </>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    paddingHorizontal: 24,
    marginTop: 40
  },
  stepBorder: {
    flexDirection: "row",
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.textInputBorder,
    minHeight: 24,
    marginTop: 24
  },
  stepBorderEnd: {
    flexDirection: "row",
    minHeight: 24,
    marginTop: 24
  },
  stepBlueCircle: {
    backgroundColor: theme.colors.blue,
    borderRadius: 50,
    height: 30,
    width: 30,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
    marginLeft: -15,
    marginTop: -40
  },
  stepTextAlign: {
    marginTop: -40
  },
  stepFourContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  imageCanvas: {
    flexDirection: "row",
    width: 180,
    height: 180,
    marginLeft: -220
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0.75)"
  }
});

export default ChangePodScreenTwo;
