import React, { useState } from 'react';
import { ReactComponent as EmptyCheckbox } from 'assets/ios/icons/checkbox-empty.svg';
import { ReactComponent as CheckedCheckbox } from 'assets/ios/icons/checkbox-filled.svg';

interface CheckboxProps {
  defaultValue?: boolean;
  value?: boolean;
  onChange?: () => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ defaultValue, value, onChange }) => {
  const [checked, setChecked] = useState<boolean>(defaultValue || false);

  const handleCheckboxClick = () => {
    if (value !== undefined && onChange) {
      return onChange();
    }

    setChecked(!checked);
  }

  const valueToBeChecked = value !== undefined ? value : checked;

  return (
    <div className="cursor-pointer" onClick={handleCheckboxClick}>
      {valueToBeChecked && <CheckedCheckbox />}
      {!valueToBeChecked && <EmptyCheckbox />}
    </div>
  );
};