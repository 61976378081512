import React from "react"
import { StyleProp, Text, TextStyle } from "react-native"
import fonts from "../../../theme/fonts"

interface Props {
  variant?: "h1" | "h2" | "h3" | "h4"
  label: string
  styles?: StyleProp<TextStyle>
}

const Header: React.FC<Props> = ({ variant, label, styles }) => {
  const getVariant = () => {
    switch (variant) {
      case "h1":
        return fonts.h1

      case "h2":
        return fonts.h2

      case "h3":
        return fonts.h3

      case "h4":
        return fonts.h4

      default:
        return fonts.h3
    }
  }

  return (
    <Text
      style={[getVariant(), { padding: 16, marginBottom: 5 }, styles && styles]}
    >
      {label}
    </Text>
  )
}

export default Header
