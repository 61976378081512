let strings = {
  "en-US": {
    iob: "IOB"
  },
  "en-GB": {
    iob: "IOB"
  },
  "de-DE": {
    iob: "AI"
  },
  "es-US": {
    iob: "IOB"
  },
  "fr-FR": {
    iob: "InA"
  },
  "nl-NL": {
    iob: "IOB"
  }
};

export { strings };
