import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { BackButton } from "../../components/atoms";
import { ExpertBasalConfirmationModal } from "../../components/organisms";
import {
  ExpertBasalProgramsScreen,
  ExpertNewBasalSegmentScreen,
  ExpertProgramEntryScreen,
  ExpertProgramSegmentsReviewScreen
} from "../../components/screens/simulator/basal";
import { useModeIconSmall } from "../../hooks";
import useLanguageContent from "../../hooks/useLanguageContent";
import theme from "../../theme";
import { strings } from "./ExpertBasalProgramsFTSStackContent";

const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const ExpertBasalProgramsStack: React.FC = () => {
  const [backModal, setBackModal] = React.useState<boolean>(false);
  const { languageContent } = useLanguageContent(strings);

  const { icon } = useModeIconSmall();

  const showBackModal = () => {
    setBackModal(true);
  };

  const closeBackModal = () => {
    setBackModal(false);
  };

  return (
    <>
      <Stack.Navigator initialRouteName="BasalPrograms" mode="card">
        <Stack.Screen
          name="BasalPrograms"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text="Basal Programs" white={false} />
            ),
            headerRight: () => icon
          }}
          component={ExpertBasalProgramsScreen}
        />
        <Stack.Screen
          name="ExpertProgramEntry"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={"Edit Basal Program"}
                white={false}
                showModalOnPress={showBackModal}
                modalElement={
                  <ExpertBasalConfirmationModal
                    isVisible={backModal}
                    closeModal={closeBackModal}
                  />
                }
              />
            ),
            headerRight: () => icon
          }}
          component={ExpertProgramEntryScreen}
        />
        <Stack.Screen
          name="ExpertProgramSegments"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text="Edit Basal Program"
                white={false}
                showModalOnPress={showBackModal}
                modalElement={
                  <ExpertBasalConfirmationModal
                    isVisible={backModal}
                    closeModal={closeBackModal}
                  />
                }
              />
            ),
            headerRight: () => icon
          }}
          component={ExpertNewBasalSegmentScreen}
        />
        <Stack.Screen
          name="ExpertProgramSegmentsReview"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text="Confirm Program"
                white={false}
                showModalOnPress={showBackModal}
                modalElement={
                  <ExpertBasalConfirmationModal
                    isVisible={backModal}
                    closeModal={closeBackModal}
                  />
                }
              />
            ),
            headerRight: () => icon
          }}
          component={ExpertProgramSegmentsReviewScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default ExpertBasalProgramsStack;
