let strings = {
  "en-US": {
    create: "Create Basal Program",
    programIntro:
      "Your Basal Program is your daily schedule for continuous insulin delivery in Manual Mode. It contains one or more time segments, starting and ending at midnight.",
    cancel: "cancel",
    next: "next"
  },
  "en-GB": {
    create: "Create Basal Programme",
    programIntro:
      "Your Basal Programme is your daily schedule for continuous insulin delivery in Manual Mode. It contains one or more time segments, starting and ending at midnight.",
    cancel: "cancel",
    next: "next"
  },
  "de-DE": {
    create: "Basalratenprofil erstellen",
    programIntro:
      "Ihr Basalratenprofil definiert den täglichen Zeitplan für die kontinuierliche Insulinabgabe im manuellen Modus. Es enthält ein oder mehrere Zeitsegmente, mit dem Beginn und Ende um Mitternacht.",
    cancel: "abbr.",
    next: "Weiter"
  },
  "es-US": {
    create: "Crear un programa basal",
    programIntro:
      "Su programa basal es su programa diario de administración continua de insulina en el Modo Manual. Contiene uno o más segmentos de tiempo que comienzan y terminan a la medianoche.",
    cancel: "cancelar",
    next: "siguiente"
  },
  "fr-FR": {
    create: "Créer programme basal",
    programIntro:
      "Votre programme basal constitue votre programme quotidien pour l'administration continue d'insuline en Mode Manuel. Il comprend au moins un segment temporel, commençant et finissant à minuit.",
    cancel: "annuler",
    next: "suivant"
  },
  "nl-NL": {
    create: "Basaalprogramma aanmaken",
    programIntro:
      "Het basaalprogramma is uw dagelijkse schema voor continue insulinetoediening in de handmatige modus. Het bevat één of meer tijdsegmenten, die om middernacht beginnen en eindigen.",
    cancel: "annuleren",
    next: "volgende"
  }
};

export { strings };
