import React from "react";
import { StyleSheet, Text, View } from "react-native";
import theme from "../../../theme";

interface CustomToggleSwitchProps {
  title: string;
}

const TransmitterSNRow: React.FC<CustomToggleSwitchProps> = ({ title }) => {
  return (
    <View>
      <Text style={[styles.serialNumberText]}>{title}</Text>
      <View style={[styles.serialNumberRow]}>
        <Text style={[styles.serialNumber]}>8</Text>
        <Text style={[styles.serialNumber]}>0</Text>
        <Text style={[styles.serialNumber]}>4</Text>
        <Text style={[styles.serialNumber]}>J</Text>
        <Text style={[styles.serialNumber]}>J</Text>
        <Text style={[styles.serialNumber, { marginRight: "0%" }]}>7</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  serialNumber: {
    ...theme.fonts.h2,
    marginRight: "8%"
  },
  serialNumberText: {
    ...theme.fonts.h4,
    padding: 16,
    fontWeight: "500",
    backgroundColor: theme.colors.textInputBackgroundColor
  },
  serialNumberRow: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: theme.colors.textInputBackgroundColor,
    paddingVertical: "8%"
  }
});

export default TransmitterSNRow;
