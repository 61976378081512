let strings = {
  "en-US": {
    range: "(1 to 24 hrs)",
    inactivity: "Inactivity Timer",
    useful: "This is useful if you are prone to \n hypoglycemia unawareness.",
    interact:
      "If you do not interact with your Omnipod 5 \nApp within the Pod Shut-Off time period, an \nadvisory alarm sounds. If you do not respond within 15 minutes, a hazard alarm sounds. You must then deactivate and change your Pod to start insulin delivery.",
    on: "On",
    leftActionText: "cancel",
    rightActionText: "Save",
    shutOff: "Pod Shut-Off",
    off: "Off",
    hrs: "hrs"
  },
  "en-GB": {
    range: "(1 to 24 hrs)",
    inactivity: "Inactivity Timer",
    useful: "This is useful if you are prone to \n hypoglycemia unawareness.",
    interact:
      "If you do not interact with your Omnipod 5 \nApp within the Pod Shut-Off time period, an \nadvisory alarm sounds. If you do not respond within 15 minutes, a hazard alarm sounds. You must then deactivate and change your Pod to start insulin delivery.",
    on: "On",
    leftActionText: "cancel",
    rightActionText: "Save",
    shutOff: "Pod Shut-Off",
    off: "Off",
    hrs: "hrs"
  },
  "es-US": {
    range: "(1 to 24 h)",
    inactivity: "Cronómetro de inactividad",
    useful:
      "Esta herramienta es útil si tiende a presentar insensibilidad a la hipoglucemia.",
    interact:
      "Si no interactúa con su aplicación Omnipod 5 durante el período de apagado del Pod, suena una alarma de advertencia. Si no responde en 15 minutos, suena una alarma de peligro. Entonces debe desactivar y cambiar su Pod para iniciar la administración de insulina.",
    on: "Prendido",
    leftActionText: "cancelar",
    rightActionText: "guardar",
    shutOff: "Apagado del Pod",
    off: "Apagado",
    hrs: "h"
  },
  "de-DE": {
    range: "(1 bis 24 Stdn.)",
    inactivity: "Zeitgeber für Inaktivität",
    useful:
      "Dies ist nützlich, wenn Sie dazu neigen, eine Hypoglykämie nicht zu bemerken.",
    interact:
      "Wenn Sie mit Ihrer Omnipod 5 App nicht innerhalb des Zeitraums der Pod-Abschaltung interagieren, ertönt ein Hinweisalarm. Wenn Sie nicht innerhalb von 15 Minuten reagieren, ertönt ein Gefahrenalarm. Sie müssen dann Ihren Pod deaktivieren und austauschen, um die Insulinabgabe zu aktivieren.",
    on: "Ein",
    leftActionText: "Abbr.",
    rightActionText: "Speichern",
    shutOff: "Pod-Abschaltung",
    off: "Aus",
    hrs: "Stdn."
  },
  "nl-NL": {
    range: "(1 tot 24 uur)",
    inactivity: "Inactiviteitstimer",
    useful: "Dit is nuttig als u vaak ongemerkt \nhypoglykemie hebt.",
    interact:
      "Als u uw Omnipod 5 app niet binnen de \nuitschakeltijd van de Pod gebruikt, gaat er een waarschuwingsalarm af. Als u niet binnen 15 minuten reageert, gaat er een gevarenalarm af. Schakel dan de Pod uit en vervang deze om de insulinetoediening te starten.",
    on: "Aan",
    leftActionText: "Annuleren",
    rightActionText: "Opslaan",
    shutOff: "Pod uitschakelen",
    off: "Uit",
    hrs: "uur"
  },
  "fr-FR": {
    range: "(1 à 24 h)",
    inactivity: "Minuteur d\'inactivité",
    useful: "Ceci est utile si vous êtes sujet(te) à \nl\'hypoglycémie asymptomatique.",
    interact:
      "Si vous n\'utilisez pas votre application Omnipod 5 pendant la période d\'arrêt du Pod, une alarme d\'alerte sera émise. Si vous ne répondez pas dans les 15 minutes, une alarme de danger sera émise. Vous devez alors désactiver et remplacer votre Pod pour démarrer l\'administration d\'insuline.",
    on: "Activé",
    leftActionText: "Annuler",
    rightActionText: "Enregistrer",
    shutOff: "Arrêt du Pod",
    off: "Désactivé",
    hrs: "h"
  },
};

export { strings };
