import { FormattedMessage } from 'react-intl';
import { historyEventBolusMessages } from './HistoryEventBolus.messages';
import { ChevronRight } from 'web/components/ios/icons';
import { MouseEventHandler } from 'react';

interface HistoryEventBolusProps {
  time: string;
  status: string;
  carbs: string;
  totalInsulin: string;
  mgValue?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}
export const HistoryEventBolus: React.FC<HistoryEventBolusProps> = ({
  time,
  status,
  carbs,
  totalInsulin,
  mgValue,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className="flex justify-between items-center w-full py-1 px-2 border-b border-b-ios-gray-7 cursor-pointer"
    >
      <div>
        <div className="flex">
          <p className="text-regular mr-0.5">
            <FormattedMessage {...historyEventBolusMessages.title} />
          </p>
          <p>{status}</p>
        </div>
        <div className="flex text-small">
          <p className="mr-0.5 text-ios-secondaryGray opacity-60">{`${time} • ${carbs}`}</p>
          {mgValue && (
            <>
              <p className="mr-0.5 text-ios-secondaryGray opacity-60">•</p>
              <p className="mr-0.5">{mgValue}</p>
            </>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <p className="text-regular mr-2">{totalInsulin}</p>
        <ChevronRight color="fill-ios-secondaryGray opacity-30" />
      </div>
    </div>
  );
};
