import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const TempBasalOn: React.FC<IconProps> = ({
  color,
  width = '26',
  height = '27',
}) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8996 0.183899V4.0839H14.2996L18.1996 7.9826L22.1009 4.0839H19.5009V0.183899H16.8996ZM16.9 13.1831C16.1824 13.1831 15.6 12.602 15.6 11.8831C15.6 11.1655 16.1824 10.5831 16.9 10.5831C17.6189 10.5831 18.2 11.1655 18.2 11.8831C18.2 12.602 17.6189 13.1831 16.9 13.1831ZM26.0001 26.1836H0.00012207V23.5823H26.0001V26.1836ZM3.89934 4.0839L7.79934 0.183899H7.80194L11.7006 4.0839H9.10064V7.9826H6.49934V4.0839H3.89934ZM5.19999 20.9831C5.91889 20.9831 6.49999 20.402 6.49999 19.6831C6.49999 18.9655 5.91889 18.3831 5.19999 18.3831C4.48239 18.3831 3.89999 18.9655 3.89999 19.6831C3.89999 20.402 4.48239 20.9831 5.19999 20.9831ZM6.49999 15.7831C6.49999 16.502 5.91889 17.0831 5.19999 17.0831C4.48239 17.0831 3.89999 16.502 3.89999 15.7831C3.89999 15.0655 4.48239 14.4831 5.19999 14.4831C5.91889 14.4831 6.49999 15.0655 6.49999 15.7831ZM5.19999 13.1831C5.91889 13.1831 6.49999 12.602 6.49999 11.8831C6.49999 11.1655 5.91889 10.5831 5.19999 10.5831C4.48239 10.5831 3.89999 11.1655 3.89999 11.8831C3.89999 12.602 4.48239 13.1831 5.19999 13.1831ZM9.09999 13.1831C8.38239 13.1831 7.79999 12.602 7.79999 11.8831C7.79999 11.1655 8.38239 10.5831 9.09999 10.5831C9.81889 10.5831 10.4 11.1655 10.4 11.8831C10.4 12.602 9.81889 13.1831 9.09999 13.1831ZM13 13.1831C13.7189 13.1831 14.3 12.602 14.3 11.8831C14.3 11.1655 13.7189 10.5831 13 10.5831C12.2824 10.5831 11.7 11.1655 11.7 11.8831C11.7 12.602 12.2824 13.1831 13 13.1831ZM19.5 19.6831C19.5 18.9655 20.0824 18.3831 20.8 18.3831C21.5189 18.3831 22.1 18.9655 22.1 19.6831C22.1 20.402 21.5189 20.9831 20.8 20.9831C20.0824 20.9831 19.5 20.402 19.5 19.6831ZM20.8 14.4831C20.0824 14.4831 19.5 15.0655 19.5 15.7831C19.5 16.502 20.0824 17.0831 20.8 17.0831C21.5189 17.0831 22.1 16.502 22.1 15.7831C22.1 15.0655 21.5189 14.4831 20.8 14.4831ZM19.5 11.8831C19.5 11.1655 20.0824 10.5831 20.8 10.5831C21.5189 10.5831 22.1 11.1655 22.1 11.8831C22.1 12.602 21.5189 13.1831 20.8 13.1831C20.0824 13.1831 19.5 12.602 19.5 11.8831Z"
        fill="black"
      />
      <mask
        id="mask0_105_1069"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="27"
        height="27"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.8996 0.183899V4.0839H14.2996L18.1996 7.9826L22.1009 4.0839H19.5009V0.183899H16.8996ZM16.9 13.1831C16.1824 13.1831 15.6 12.602 15.6 11.8831C15.6 11.1655 16.1824 10.5831 16.9 10.5831C17.6189 10.5831 18.2 11.1655 18.2 11.8831C18.2 12.602 17.6189 13.1831 16.9 13.1831ZM26.0001 26.1836H0.00012207V23.5823H26.0001V26.1836ZM3.89934 4.0839L7.79934 0.183899H7.80194L11.7006 4.0839H9.10064V7.9826H6.49934V4.0839H3.89934ZM5.19999 20.9831C5.91889 20.9831 6.49999 20.402 6.49999 19.6831C6.49999 18.9655 5.91889 18.3831 5.19999 18.3831C4.48239 18.3831 3.89999 18.9655 3.89999 19.6831C3.89999 20.402 4.48239 20.9831 5.19999 20.9831ZM6.49999 15.7831C6.49999 16.502 5.91889 17.0831 5.19999 17.0831C4.48239 17.0831 3.89999 16.502 3.89999 15.7831C3.89999 15.0655 4.48239 14.4831 5.19999 14.4831C5.91889 14.4831 6.49999 15.0655 6.49999 15.7831ZM5.19999 13.1831C5.91889 13.1831 6.49999 12.602 6.49999 11.8831C6.49999 11.1655 5.91889 10.5831 5.19999 10.5831C4.48239 10.5831 3.89999 11.1655 3.89999 11.8831C3.89999 12.602 4.48239 13.1831 5.19999 13.1831ZM9.09999 13.1831C8.38239 13.1831 7.79999 12.602 7.79999 11.8831C7.79999 11.1655 8.38239 10.5831 9.09999 10.5831C9.81889 10.5831 10.4 11.1655 10.4 11.8831C10.4 12.602 9.81889 13.1831 9.09999 13.1831ZM13 13.1831C13.7189 13.1831 14.3 12.602 14.3 11.8831C14.3 11.1655 13.7189 10.5831 13 10.5831C12.2824 10.5831 11.7 11.1655 11.7 11.8831C11.7 12.602 12.2824 13.1831 13 13.1831ZM19.5 19.6831C19.5 18.9655 20.0824 18.3831 20.8 18.3831C21.5189 18.3831 22.1 18.9655 22.1 19.6831C22.1 20.402 21.5189 20.9831 20.8 20.9831C20.0824 20.9831 19.5 20.402 19.5 19.6831ZM20.8 14.4831C20.0824 14.4831 19.5 15.0655 19.5 15.7831C19.5 16.502 20.0824 17.0831 20.8 17.0831C21.5189 17.0831 22.1 16.502 22.1 15.7831C22.1 15.0655 21.5189 14.4831 20.8 14.4831ZM19.5 11.8831C19.5 11.1655 20.0824 10.5831 20.8 10.5831C21.5189 10.5831 22.1 11.1655 22.1 11.8831C22.1 12.602 21.5189 13.1831 20.8 13.1831C20.0824 13.1831 19.5 12.602 19.5 11.8831Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_105_1069)"></g>
    </svg>
  );
};
