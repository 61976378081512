import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  nextBtn: {
    id: 'ios.basal.fts.next-btn',
    defaultMessage: 'Next',
  },
  backBtn: {
    id: 'ios.basal.fts.back-btn',
    defaultMessage: 'Back',
  },
  cancelBtn: {
    id: 'ios.basal.fts.cancel-btn',
    defaultMessage: 'Cancel',
  },
  saveBtn: {
    id: 'ios.basal.fts.save-btn',
    defaultMessage: 'Save',
  },
  noBtn: {
    id: 'ios.basal.fts.no-btn',
    defaultMessage: 'No',
  },
  yesBtn: {
    id: 'ios.basal.fts.yes-btn',
    defaultMessage: 'Yes',
  },
  headerTitle: {
    id: 'ios.basal.fts.header-title',
    defaultMessage: 'Setup: Basal',
  },
  units: {
    id: 'ios.basal.fts.units',
    defaultMessage: 'U/hr',
  },
  emptyValue: {
    id: 'ios.basal.fts.empty-value',
    defaultMessage: '--',
  },
  basalProgramName: {
    id: 'ios.basal.fts.basal-program-name',
    defaultMessage: 'Weekend',
  },
  charactersPlaceholder: {
    id: 'ios.basal.fts.basal-program.characters-placeholder',
    defaultMessage: 'characters',
  },
  basalRateInputPlaceholder: {
    id: 'ios.basal.fts.basal-segment.basal-rate-input-placeholder',
    defaultMessage: '(up to 30 U/hr)',
  },
});

export const IntroBasalMessages = defineMessages({
  headerTitle: {
    id: 'ios.basal.fts.intro-screen.header-title',
    defaultMessage: 'Setup: <highlight>Basal</highlight>',
  },
  text: {
    id: 'ios.basal.fts.intro-screen.text',
    defaultMessage: 'Continuous delivery of a small amount of insulin.',
  },
});

export const SetMaximumBasalRatesMessages = defineMessages({
  title: {
    id: 'ios.basal.fts.set-maximum-basal-rate-screen.title',
    defaultMessage: 'Set Maximum Basal Rate',
  },
  text: {
    id: 'ios.basal.fts.set-maximum-basal-rate-screen.text',
    defaultMessage:
      'Upper limit for basal rates in a basal Program or temp basal.',
  },
  inputLabel: {
    id: 'ios.basal.fts.set-maximum-basal-rate-screen.input-label',
    defaultMessage: 'Max Basal Rate',
  },
  inputPlaceholder: {
    id: 'ios.basal.fts.set-maximum-basal-rate-screen.input-placeholder',
    defaultMessage: '(0.05 to 30 U/hr)',
  },
});

export const CreateBasalProgramIntroMessages = defineMessages({
  title: {
    id: 'ios.basal.fts.create-basal-program-intro-screen.title',
    defaultMessage: 'Create Basal Program',
  },
  text: {
    id: 'ios.basal.fts.create-basal-program-intro-screen.text',
    defaultMessage:
      'Your Basal Program is your daily schedule for continuous insulin delivery in Manual Mode. It contains one or more time segments, starting and ending at midnight.',
  },
});

export const CreateBasalProgramMessages = defineMessages({
  inputLabel: {
    id: 'ios.basal.fts.create-basal-program-screen.input-label',
    defaultMessage: 'Program Name',
  },
  inputPlaceholder: {
    id: 'ios.basal.fts.create-basal-program-screen.input-placeholder',
    defaultMessage: 'Enter the name of your program',
  },
});

export const CreateBasalSegmentsMessages = defineMessages({
  segmentTitle: {
    id: 'ios.basal.fts.create-basal-segments-screen.segment-title',
    defaultMessage: 'Segment',
  },
  startTimeInputLabel: {
    id: 'ios.basal.fts.create-basal-segments-screen.start-time-input-label',
    defaultMessage: 'Start Time',
  },
  endTimeInputLabel: {
    id: 'ios.basal.fts.create-basal-segments-screen.end-time-input-label',
    defaultMessage: 'End Time',
  },
  basalRateInputLabel: {
    id: 'ios.basal.fts.create-basal-segments-screen.basal-rate-input-label',
    defaultMessage: 'Basal Rate',
  },
});

export const BasalSegmentOneMessages = defineMessages({
  startTimeInputValue: {
    id: 'ios.basal.fts.basal-segment-one-screen.start-time-input-value',
    defaultMessage: '12:00 AM (night)',
  },
  endTimeInputValue: {
    id: 'ios.basal.fts.basal-segment-one-screen.end-time-input-value',
    defaultMessage: '8:00 AM (morning)',
  },
  basalRateInputValue: {
    id: 'ios.basal.fts.basal-segment-one-screen.basal-rate-input-value',
    defaultMessage: '0.75 U/hr',
  },
});

export const BasalSegmentTwoMessages = defineMessages({
  startTimeInputValue: {
    id: 'ios.basal.fts.basal-segment-two-screen.start-time-input-value',
    defaultMessage: '8:00 AM (morning)',
  },
  endTimeInputValue: {
    id: 'ios.basal.fts.basal-segment-two-screen.end-time-input-value',
    defaultMessage: '12:00 AM (night)',
  },
  basalRateInputValue: {
    id: 'ios.basal.fts.basal-segment-two-screen.basal-rate-input-value',
    defaultMessage: '1 U/hr',
  },
});

export const BasalSegmentsMessages = defineMessages({
  totalBasalTitle: {
    id: 'ios.basal.fts.basal-segments-screen.total-basal-title',
    defaultMessage: 'Total Basal:',
  },
  totalBasalvalue: {
    id: 'ios.basal.fts.basal-segments-screen.total-basal-value',
    defaultMessage: '21.75 U/day',
  },
  timeSegmentsLabel: {
    id: 'ios.basal.fts.basal-segments-screen.time-segments-label',
    defaultMessage: 'TIME SEGMENTS',
  },
  totalLabel: {
    id: 'ios.basal.fts.basal-segments-screen.total-label',
    defaultMessage: 'TOTAL:',
  },
});

export const TemporaryBasalSwitchMessages = defineMessages({
  title: {
    id: 'ios.basal.fts.temporary-basal-switch-screen.title',
    defaultMessage: 'Allow temporary basal rates?',
  },
  text: {
    id: 'ios.basal.fts.temporary-basal-switch-screen.text',
    defaultMessage:
      'A temp basal changes your basal rate for a set period of time.',
  },
  tempBasal: {
    id: 'ios.basal.fts.temporary-basal-switch-screen.temp-basal-label',
    defaultMessage: 'Temp Basal',
  },
  tempBasalActiveText: {
    id: 'ios.basal.fts.temporary-basal-switch-screen.temp-basal-active-text',
    defaultMessage:
      'Temp basals are set to change basal rates by a percentage. You can change this to a flat rate (U/hr) in Settings.',
  },
});

export const CancelSetupModalMessages = defineMessages({
  title: {
    id: 'ios.basal.fts.cancel-setup-modal.title',
    defaultMessage: 'Cancel basal setup?',
  },
  text: {
    id: 'ios.basal.fts.cancel-setup-modal.text',
    defaultMessage:
      'Canceling will erase your basal entries and return you to the beginning of this section.',
  },
});
