import { useIntl } from 'react-intl';
import { HistorySummaryBlocks } from '../components/HistorySummaryBlocks/HistorySummaryBlocks';
import { historySummaryMessages } from '../History.messages';
import {
  DropIcon,
  ArrowIcon,
  InsulinIcon,
  BolusIcon,
  CarbsIcon,
} from 'web/components/ios/icons';

export const HistorySummary = () => {
  const intl = useIntl();
  return (
    <>
      <div className="flex flex-col basis-1/2 px-2">
        <p className="font-semibold text-regular mb-2">
          {intl.formatMessage(historySummaryMessages.yourSensorTitle)}
        </p>
        <div className="flex flex-col basis-1/2 justify-between gap-[10px]">
          <div className="flex justify-between gap-[10px] ">
            <HistorySummaryBlocks
              icon={
                <DropIcon width="11" height="12" color="fill-modes-manual" />
              }
              title={intl.formatMessage(
                historySummaryMessages.averageSensorBlockTitle
              )}
              value={intl.formatMessage(
                historySummaryMessages.averageSensorBlockValue
              )}
              valueUnit={intl.formatMessage(
                historySummaryMessages.averageSensorBlockValueUnit
              )}
            />
            <HistorySummaryBlocks
              icon={
                <DropIcon
                  width="11"
                  height="12"
                  color="fill-ios-activityGreen"
                />
              }
              title={intl.formatMessage(
                historySummaryMessages.sensorInRangeBlockTitle
              )}
              value={intl.formatMessage(
                historySummaryMessages.sensorInRangeBlockValue
              )}
              valueUnit={intl.formatMessage(
                historySummaryMessages.sensorInRangeBlockValueUnit
              )}
              placeholder={intl.formatMessage(
                historySummaryMessages.sensorInRangeBlockPlaceholder
              )}
            />
          </div>
          <div className="flex justify-between gap-[10px] ">
            <HistorySummaryBlocks
              icon={
                <ArrowIcon width="14" height="14" color="fill-ios-highBg" />
              }
              title={intl.formatMessage(
                historySummaryMessages.sensorAboveBlockTitle
              )}
              value={intl.formatMessage(
                historySummaryMessages.sensorAboveBlockValue
              )}
              valueUnit={intl.formatMessage(
                historySummaryMessages.sensorAboveBlockValueUnit
              )}
              placeholder={intl.formatMessage(
                historySummaryMessages.sensorAboveBlockPlaceholder
              )}
            />
            <HistorySummaryBlocks
              iconRotate="rotate-180"
              icon={<ArrowIcon width="14" height="14" color="fill-ios-lowBg" />}
              title={intl.formatMessage(
                historySummaryMessages.sensorBelowBlockTitle
              )}
              value={intl.formatMessage(
                historySummaryMessages.sensorBelowBlockValue
              )}
              valueUnit={intl.formatMessage(
                historySummaryMessages.sensorBelowBlockValueUnit
              )}
              placeholder={intl.formatMessage(
                historySummaryMessages.sensorBelowBlockPlaceholder
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col basis-1/2 px-2">
        <p className="font-semibold text-regular mb-2">
          {intl.formatMessage(historySummaryMessages.InsulinAndCarbsTitle)}
        </p>
        <div className="flex flex-col basis-1/2 justify-between gap-[10px]">
          <div className="flex justify-between gap-[10px] ">
            <HistorySummaryBlocks
              titleColorGray
              title={intl.formatMessage(
                historySummaryMessages.totalInsulinBlockTitle
              )}
              value={intl.formatMessage(
                historySummaryMessages.totalInsulinBlockValue
              )}
              valueUnit={intl.formatMessage(
                historySummaryMessages.totalInsulinBlockValueUnit
              )}
            />
            <HistorySummaryBlocks
              icon={
                <BolusIcon width="12" height="12" color="fill-ios-gray-9" />
              }
              titleColorGray
              title={intl.formatMessage(
                historySummaryMessages.bolusInsulinBlockTitle
              )}
              value={intl.formatMessage(
                historySummaryMessages.bolusInsulinBlockValue
              )}
              valueUnit={intl.formatMessage(
                historySummaryMessages.bolusInsulinBlockValueUnit
              )}
              placeholder={intl.formatMessage(
                historySummaryMessages.bolusInsulinBlockPlaceholder
              )}
            />
          </div>
          <div className="flex justify-between gap-[10px] ">
            <HistorySummaryBlocks
              icon={
                <InsulinIcon
                  width="13"
                  height="13"
                  color="fill-ios-gray-9 opacity-[.60]"
                />
              }
              titleColorGray
              title={intl.formatMessage(
                historySummaryMessages.basalInsulinBlockTitle
              )}
              value={intl.formatMessage(
                historySummaryMessages.basalInsulinBlockValue
              )}
              valueUnit={intl.formatMessage(
                historySummaryMessages.basalInsulinBlockValueUnit
              )}
              placeholder={intl.formatMessage(
                historySummaryMessages.basalInsulinBlockPlaceholder
              )}
            />
            <HistorySummaryBlocks
              icon={
                <CarbsIcon width="13" height="13" color="fill-ios-gray-9" />
              }
              titleColorGray
              title={intl.formatMessage(
                historySummaryMessages.totalCarbsBlockTitle
              )}
              value={intl.formatMessage(
                historySummaryMessages.totalCarbsBlockValue
              )}
              valueUnit={intl.formatMessage(
                historySummaryMessages.totalCarbsBlockValueUnit
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};
