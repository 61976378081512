export enum BolusAdministrationRoutesEnum {
  BolusAdministrationMain = 'BolusAdministrationMain',
  ExtendedBolusAdministration = 'ExtendedBolusAdministration',
  ConfirmBolus = 'ConfirmBolus',
  BolusCustomFoods = 'BolusCustomFoods',
}

export type BolusAdministrationStackParamList = {
  [BolusAdministrationRoutesEnum.BolusAdministrationMain]: {
    usingCustomFoods?: boolean;
  };
}
