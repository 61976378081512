import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  TextInput,
  View
} from "react-native";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import theme from "../../../../../theme";
import { Button } from "../../../../atoms";
import { BottomInnerNav } from "../../../../molecules";
import { strings } from "./LogFilesPinScreenContent";

const SetPinScreen: React.FC = () => {
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const [fileNum, setFileNum] = React.useState<Array<string>>(["", "", "", ""]);

  const populateFileNum = () => {
    setFileNum(["8", "0", "4", "2"]);
  };

  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={0}
      style={[
        theme.layout.wrapper,
        { width: "100%", backgroundColor: "white" }
      ]}>
      <View style={[styles.innerWrapper, { width: "100%" }]}>
        <View>
          <Text style={[theme.fonts.h5, styles.pinFont]}>
            {languageContent.customerLogFile}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}>
          <TextInput
            selectionColor={theme.colors.blue}
            value={fileNum[0]}
            style={[styles.pinInput]}
            editable={false}
            onFocus={populateFileNum}
          />
          <TextInput
            selectionColor={theme.colors.blue}
            value={fileNum[1]}
            style={[styles.pinInput]}
            editable={false}
            onFocus={populateFileNum}
          />
          <TextInput
            selectionColor={theme.colors.blue}
            value={fileNum[2]}
            style={[styles.pinInput]}
            editable={false}
            onFocus={populateFileNum}
          />
          <TextInput
            selectionColor={theme.colors.blue}
            value={fileNum[3]}
            style={[styles.pinInput]}
            editable={false}
            onFocus={populateFileNum}
          />
        </View>
        <View
          style={{ paddingBottom: 16, paddingHorizontal: 8, marginTop: 12 }}>
          <Button
            variant={"primary"}
            disabled={false}
            style={{ elevation: 6, marginTop: 24 }}
            onPress={() => {
              navigate("AboutScreen");
            }}>
            {languageContent.sendFiles}
          </Button>
        </View>
        <View>
          <Text style={[theme.fonts.body1, { margin: 24 }]}>
            {languageContent.pinNeeded}
          </Text>
        </View>
      </View>
      <BottomInnerNav />
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 16,
    marginTop: 20
  },
  pinInput: {
    width: "12%",
    height: 64,
    marginRight: 12,
    fontWeight: "500",
    borderWidth: 1,
    borderColor: theme.colors.textInputBorder,
    backgroundColor: theme.colors.textInputBackgroundColor,
    textAlign: "center",
    fontSize: 32,
    borderRadius: 6,
    minWidth: 40
  },
  pinFont: {
    marginBottom: 24,
    textAlign: "center",
    fontWeight: "300"
  }
});

export default SetPinScreen;
