import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'web/components/ios/Button';
import { podDetailsMessages } from '../Pod.messages';
import { ReactComponent as NoActivePod } from '../../assets/no-active-pod_icon.svg';
import { PodFTSRouteNames } from '../types';
import { useNavigation } from '@react-navigation/native';

export const NoActivePodScreen = () => {
  const { navigate } = useNavigation();

  const handlePodSetupStart = () => navigate(PodFTSRouteNames.setupStart);

  return (
    <div className="flex justify-between flex-col flex-1 items-stretch bg-white">
      <div className="flex flex-col px-2">
        <div className="mt-2">
          <div className="text-ios-secondaryGray font-semibold opacity-60">
            <FormattedMessage {...podDetailsMessages.podStatusTitle} />
          </div>
          <div className="text-ios-red font-bold mt-1" style={{ fontSize: 34 }}>
            <FormattedMessage {...podDetailsMessages.noActiveTitle} />
          </div>
          <div className="flex justify-center">
            <div className="w-[235px] mt-[69px]">
              <NoActivePod />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mb-4 px-2">
        <Button
          className="w-full rounded-lg font-bold"
          onClick={handlePodSetupStart}
          variant="colored"
        >
          <FormattedMessage {...podDetailsMessages.setUpNewPodButtonTitle} />
        </Button>
      </div>
    </div>
  );
};
