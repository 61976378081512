import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { useSelector } from "react-redux";
import { getUnitByLocale } from "../../../../../helpers/getUnitByLocale";
import replaceWithValues from "../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import fonts from "../../../../../theme/fonts";
import { Paragraph } from "../../../../atoms";
import { BottomInnerNav } from "../../../../molecules";
import { strings } from "./CGMBGGoalRangeSettingsScreenContent";
const { height } = Dimensions.get("window");

const CGMBGGoalRangeSettingsScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [upperLimit, setUpperLimit] = React.useState<string>();
  const [lowerLimit, setLowerLimit] = React.useState<string>();
  const [isPressed, setIsPressed] = React.useState<boolean>(false);

  const { goBack } = useNavigation();
  const { languageContent } = useLanguageContent(strings);
  const upperLimitMax = `${getUnitByLocale(200, langState.units)} ${
    langState.units
  }`;
  const lowerLimitMax = `${getUnitByLocale(70, langState.units)} ${
    langState.units
  }`;
  const contentVariables = [upperLimitMax, lowerLimitMax];

  return (
    <View style={[styles.container]}>
      <View>
        <View
          style={[
            {
              paddingHorizontal: 16,
              paddingTop: 24,
              backgroundColor: "white",
              height: "140%"
            }
          ]}>
          <Paragraph variant="body1" label={languageContent.bodyText1} />
          <View
            style={[
              {
                flexDirection: "row",
                alignItems: "flex-start",
                height: height > 600 ? "75%" : "65%",
                marginTop: 24
              }
            ]}>
            <View style={[{ width: "100%" }]}>
              <View>
                <Text style={{ ...fonts.h4 }}>
                  {languageContent.upperLimit}
                </Text>
                <Text
                  style={[
                    { ...fonts.body1, color: theme.colors.grayScale.gray400 }
                  ]}>
                  {replaceWithValues(
                    languageContent.upperMax,
                    contentVariables
                  )}
                </Text>
              </View>
              <TouchableOpacity
                style={[
                  theme.layout.inputContainerStyle,
                  {
                    marginTop: 8,
                    flexDirection: "row"
                  }
                ]}
                onPress={() => {
                  setLowerLimit("180");
                  if (upperLimit === "70") {
                    setIsPressed(true);
                  }
                }}>
                <Text style={[theme.layout.inputStyle]}>
                  {lowerLimit
                    ? getUnitByLocale(Number(lowerLimit), langState.units)
                    : null}
                </Text>
                <Text style={[theme.layout.adornment]}>{langState.units}</Text>
              </TouchableOpacity>
              <View>
                <Text style={[fonts.h4, { marginTop: 16 }]}>
                  {languageContent.lowerLimit}
                </Text>
                <Text
                  style={[
                    fonts.body1,
                    { color: theme.colors.grayScale.gray400 }
                  ]}>
                  {replaceWithValues(
                    languageContent.lowerMax,
                    contentVariables
                  )}
                </Text>
              </View>
              <TouchableOpacity
                style={[
                  theme.layout.inputContainerStyle,
                  {
                    marginTop: 8,
                    flexDirection: "row"
                  }
                ]}
                onPress={() => {
                  setUpperLimit("70");
                  if (lowerLimit === "180") {
                    setIsPressed(true);
                  }
                }}>
                <Text style={[theme.layout.inputStyle]}>
                  {upperLimit
                    ? getUnitByLocale(Number(upperLimit), langState.units)
                    : null}
                </Text>
                <Text style={theme.layout.adornment}>{langState.units}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.save}
        rightAction={() => goBack()}
        rightActionStyle={
          isPressed
            ? [styles.actionStyle, { color: "#5B34B9" }]
            : styles.actionStyle
        }
        rightActionDisabled={!isPressed}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between"
  },
  mockInput: {
    borderColor: theme.colors.textInputBorder,
    borderWidth: 1,
    backgroundColor: theme.colors.textInputBackgroundColor,
    width: "100%",
    borderRadius: 5
  },
  unitText: {
    color: theme.colors.grayScale.gray400,
    fontSize: 18
  },
  actionStyle: {
    color: theme.colors.text.cancel,
    fontSize: 16
  }
});

export default CGMBGGoalRangeSettingsScreen;
