import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const SwitchMenuIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className={classes} width="28" height="28" rx="6"/>
      <rect className={classes} width="16" height="16" transform="translate(6 6)"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4 17.2C16.6208 17.2 16.8208 17.1112 16.9656 16.9656L21.7656 12.1656C21.9104 12.0208 22 11.8208 22 11.6C22 11.3792 21.9104 11.1792 21.7656 11.0344L16.9656 6.2344C16.8208 6.0896 16.6208 6 16.4 6C15.9576 6 15.6 6.3584 15.6 6.8V8.4H12.4L10.8 10H15.6H17.2V8.7312L20.0688 11.6L17.2 14.4688V13.2H16.4H15.6H12.4V11.6C12.4 11.1584 12.0416 10.8 11.6 10.8C11.3792 10.8 11.1792 10.8896 11.0344 11.0344L6.2344 15.8344C6.0888 15.9792 6 16.1792 6 16.4C6 16.6208 6.0888 16.8208 6.2344 16.9656L11.0344 21.7656C11.1792 21.9112 11.3792 22 11.6 22C12.0416 22 12.4 21.6424 12.4 21.2V19.6H15.6L17.2 18H12.4H10.8V19.2688L7.9312 16.4L10.8 13.5312V14.8H12.4H15.6V16.4C15.6 16.8424 15.9576 17.2 16.4 17.2"
        fill="white"
      />
      <mask
        id="mask0_146_10603"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="6"
        width="16"
        height="16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4 17.2C16.6208 17.2 16.8208 17.1112 16.9656 16.9656L21.7656 12.1656C21.9104 12.0208 22 11.8208 22 11.6C22 11.3792 21.9104 11.1792 21.7656 11.0344L16.9656 6.2344C16.8208 6.0896 16.6208 6 16.4 6C15.9576 6 15.6 6.3584 15.6 6.8V8.4H12.4L10.8 10H15.6H17.2V8.7312L20.0688 11.6L17.2 14.4688V13.2H16.4H15.6H12.4V11.6C12.4 11.1584 12.0416 10.8 11.6 10.8C11.3792 10.8 11.1792 10.8896 11.0344 11.0344L6.2344 15.8344C6.0888 15.9792 6 16.1792 6 16.4C6 16.6208 6.0888 16.8208 6.2344 16.9656L11.0344 21.7656C11.1792 21.9112 11.3792 22 11.6 22C12.0416 22 12.4 21.6424 12.4 21.2V19.6H15.6L17.2 18H12.4H10.8V19.2688L7.9312 16.4L10.8 13.5312V14.8H12.4H15.6V16.4C15.6 16.8424 15.9576 17.2 16.4 17.2"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_146_10603)"></g>
    </svg>
  );
};
