let strings = {
  'en-US': {
    serialNumber: 'Transmitter Serial Number',
    close: 'Close',
    delete: 'Delete',
    enterNew: 'Enter New',
    cgmTrans: 'Transmitter',
    unavailable: 'Not available for the simulator.',
    ok: 'Ok',
    newSN: 'To delete or enter a new SN,',
    newSNTwo: 'switch to Manual Mode.',
    currentSensor: 'Current Sensor',
    sensorText: 'Dexcom G6',
    switch: 'Switch >',
  },
  'en-GB': {
    serialNumber: 'Transmitter Serial Number',
    close: 'Close',
    delete: 'Delete',
    enterNew: 'Enter New',
    cgmTrans: 'Transmitter',
    unavailable: 'Not available for the simulator.',
    ok: 'Ok',
    newSN: 'To delete or enter a new SN,',
    newSNTwo: 'switch to Manual Mode.',
    currentSensor: 'Current Sensor',
    sensorText: 'Dexcom G6',
    switch: 'Switch >',
  },
  'de-DE': {
    serialNumber: 'Transmitter-Seriennummer',
    close: 'Schließen',
    delete: 'Löschen',
    enterNew: 'Neu',
    cgmTrans: 'Transmitter',
    unavailable: 'Nicht für den Simulator verfügbar.',
    ok: 'OK',
    newSN: 'Um eine SN zu löschen oder eine neue,',
    newSNTwo: 'SN einzugeben, wechseln Sie in den',
    newSNThree: 'manuellen Modus.',
    currentSensor: 'Aktueller Sensor',
    sensorText: 'Dexcom G6',
    switch: 'Umschalten >',
  },
  'es-US': {
    serialNumber: 'Número de serie del transmisor',
    close: 'Cerrar',
    delete: 'Eliminar',
    enterNew: 'Ingresar',
    cgmTrans: 'Transmisor',
    unavailable: 'No está disponible en el simulador.',
    ok: 'Aceptar',
    newSN: 'Para cancelar, cambie al modo manual',
    currentSensor: 'Sensor actual',
    sensorText: 'Dexcom G6',
    switch: 'Cambiar >',
  },
  'fr-FR': {
    serialNumber: 'Numéro de série du transmetteur',
    close: 'Fermer',
    delete: 'Supprimer',
    enterNew: 'Nouveau',
    cgmTrans: 'Transmetteur',
    unavailable: 'Non disponible pour le simulateur.',
    ok: 'Ok',
    currentSensor: 'Capteur actuel',
    sensorText: 'Dexcom G6',
    switch: 'Changer >',
  },
  'nl-NL': {
    serialNumber: 'Serienummer van zender',
    close: 'Sluiten',
    delete: 'Verwijderen',
    enterNew: 'Nieuwe',
    cgmTrans: 'Zender',
    unavailable: 'Niet beschikbaar voor de simulator.',
    ok: 'OK',
    currentSensor: 'Huidige Sensor',
    sensorText: 'Dexcom G6',
    switch: 'Overschakelen >',
  },
};

export { strings };
