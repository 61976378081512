let strings = {
  "en-US": {
    targetBGAndCorrectAbove:
      "Target Glucose and Correct Above values are used in both Automated and Manual Modes and can be set for different times of the day.",
    automatedMode1:
      "In Automated Mode, your basal insulin delivery will be adjusted automatically using your",
    inBoth1: "In both Automated and Manual Modes,",
    cancel: "Cancel",
    next: "Next",
    targetGlucose: "Target Glucose",
    and: "and",
    correctAbove: "Correct Above",
    valueText: "values are used for Bolus Calculations.",
    value: "value."
  },
  "en-GB": {
    targetBGAndCorrectAbove:
      "Target Glucose and Correct Above values are used in both Automated and Manual Modes and can be set for different times of the day.",
    automatedMode1:
      "In Automated Mode, your basal insulin delivery will be adjusted automatically using your",
    inBoth1: "In both Automated and Manual Modes,",
    cancel: "Cancel",
    next: "Next",
    targetGlucose: "Target Glucose",
    and: "and",
    correctAbove: "Correct Above",
    valueText: "values are used for Bolus Calculations.",
    value: "value."
  },
  "de-DE": {
    targetBGAndCorrectAbove:
      "Glukose-Zielwerte und Korrigieren über-Werte werden sowohl im Automatisierter als auch manuellen Modus verwendet und können für verschiedene Tageszeiten eingestellt werden.",
    automatedMode1:
      "Im Automatisierten Modus wird Ihre Basalinsulinabgabe automatisch angepasst. Dies geschieht mithilfe Ihres",
    inBoth1:
      "Sowohl im Automatisierter als auch im manuellen Modus werden die Werte",
    cancel: "Abbr.",
    next: "Weiter",
    targetGlucose: "Glukose-Zielwert",
    and: "und",
    correctAbove: "Korrigieren über",
    valueText: "zur Bolusberechnung verwendet.",
    value: "Werts."
  },
  "es-US": {
    targetBGAndCorrectAbove:
      "Los valores de glucosa objetivo y de corregir sobre se utilizan tanto en el Modo manual como en el automático, y se pueden establecer para diferentes momentos del día.",
    automatedMode1:
      "En el Modo Automatizado, la administración de insulina basal se ajustará automáticamente para que llegue a su valor de",
    inBoth1: "Tanto en Modo automático como en Modo manual, los valores de",
    cancel: "Cancelar",
    next: "Siguiente",
    targetGlucose: "glucosa objetivo",
    and: "y",
    correctAbove: "Corregir sobre",
    valueText: "se utilizan para calcular el bolo.",
    value: ""
  },
  "fr-FR": {
    targetBGAndCorrectAbove:
      "Les valeurs de Glucose cible et Corriger si supérieur à sont utilisées en Modes Automatisé et en Mode Manuel et peuvent être configurées pour différentes heures de la journée.",
    automatedMode1:
      "En Mode Automatisé, votre administration basale d'insuline sera ajustée automatiquement en utilisant votre",
    inBoth1: "En Modes Automatisé et Manuel, les valeurs",
    cancel: "Annuler",
    next: "Suivant",
    targetGlucose: "Glucose cible",
    and: "et",
    correctAbove: "Corriger si supérieur à",
    valueText: "sont utilisées pour les calculs de bolus.",
    value: "."
  },
  "nl-NL": {
    targetBGAndCorrectAbove:
      "Streefwaarden glucose en Corrigeren boven-waarden worden zowel in de Geautomatiseerde als in de Handmatige Modus gebruikt en kunnen worden ingesteld voor verschillende tijdstippen van de dag.",
    automatedMode1:
      "In de Geautomatiseerde Modus wordt de basale insulinetoediening automatisch bijgesteld met gebruikmaking van uw",
    inBoth1: "In zowel de automatische als handmatige modus worden de",
    cancel: "Annuleren",
    next: "Volgende",
    targetGlucose: "Streefwaarde glucose",
    and: "en",
    correctAbove: "Corrigeren boven",
    valueText: "waarden worden gebruikt voor de bolusberekeningen.",
    value: "waarde."
  }
};

export { strings };
