let strings = {
  "en-US": {
    wrongDuration: "in 4 days",
    duration: "in 3 days",
    expiresDay: "in %1 days",
    unitsWithU: "%2 U",
    expires: "Expires: Friday",
    insulinLeft: "INSULIN LEFT IN POD",
    status: "POD STATUS",
    changePod: "CHANGE POD",
    modalMessage:
      "Deactivation cancels your current temp basal and extended bolus.",
    back: "Back",
    deactivePod: "Deactivate Pod",
    deactivateModalTitle: "Deactivate your Pod?",
    deactivateModalMessage:
      "If you deactivate this Pod you will not be able to use it. You will need to set up a new Pod.",
    confirmTextDeactivate: "Deactivate Pod",
    pleaseWait: "Please wait...",
    removePodModalTitle: "Remove Pod",
    cancel: "Cancel",
    removePodModalMessage1:
      "Your Pod may still be delivering insulin. Remove it from your body, then tap",
    removePodModalMessage2: "CONTINUE.",
    continue: "Continue",
    close: "close",
    cancelModalTitle: "Activity feature in progress",
    units: "50+ Units",
    confirm: "Yes",
    lastUpdate: "updated Today",
    expiresDate: "July 15"
  },
  "en-GB": {
    wrongDuration: "in 4 days",
    duration: "in 3 days",
    expiresDay: "in %1 days",
    unitsWithU: "%2 U",
    expires: "Expires: Friday",
    insulinLeft: "INSULIN LEFT IN POD",
    status: "POD STATUS",
    changePod: "CHANGE POD",
    modalMessage:
      "Deactivation cancels your current temp basal and extended bolus.",
    back: "Back",
    deactivePod: "Deactivate Pod",
    deactivateModalTitle: "Deactivate your Pod?",
    deactivateModalMessage:
      "If you deactivate this Pod you will not be able to use it. You will need to set up a new Pod.",
    confirmTextDeactivate: "Deactivate Pod",
    pleaseWait: "Please wait...",
    removePodModalTitle: "Remove Pod",
    cancel: "Cancel",
    removePodModalMessage1:
      "Your Pod may still be delivering insulin. Remove it from your body, then tap",
    removePodModalMessage2: "CONTINUE.",
    continue: "Continue",
    close: "close",
    cancelModalTitle: "Activity feature in progress",
    units: "50+ Units",
    confirm: "Yes",
    lastUpdate: "updated Today",
    expiresDate: "July 15"
  },
  "de-DE": {
    wrongDuration: "in 4 Tagen",
    duration: "in 3 Tagen",
    expiresDay: "in %1 Tagen",
    unitsWithU: "%2 E",
    expires: "Läuft ab: Donnerstag",
    insulinLeft: "INSULIN ÜBRIG IM POD",
    status: "POD-STATUS",
    changePod: "POD AUSWECHSELN",
    modalMessage:
      "Deaktivierung löscht Ihre aktuelle temporäre Basalrate und den verzögerten Bolus.",
    back: "Zurück",
    deactivePod: "Pod deaktivieren",
    deactivateModalTitle: "Soll der Pod jetzt deaktiviert werden?",
    deactivateModalMessage:
      "Wenn Sie diesen Pod deaktivieren, werden Sie ihn nicht verwenden können. Sie werden einen neuen Pod einrichten müssen.",
    confirmTextDeactivate: "Pod deaktivieren",
    pleaseWait: "Bitte warten ...",
    removePodModalTitle: "Pod entfernen",
    cancel: "Abbr.",
    removePodModalMessage1:
      "Möglicherweise gibt Ihr Pod weiterhin Insulin ab. Entfernen Sie ihn von Ihrem Körper und tippen Sie auf",
    removePodModalMessage2: "WEITER.",
    continue: "Weiter",
    close: "Schließen",
    cancelModalTitle: "Aktivitätsfunktion in Bearbeitung",
    units: "Mehr als 50 Einheiten",
    confirm: "Ja",
    lastUpdate: "aktualisiert Huete",
    expiresDate: "12. Jan."
  },
  "es-US": {
    wrongDuration: "en 4 días",
    duration: "en 3 días",
    expiresDay: "en %1 día",
    unitsWithU: "%2 U",
    expires: "Caduca: Viernes",
    insulinLeft: "AÚN HAY INSULINA EN EL POD",
    status: "ESTADO DEL POD",
    changePod: "CAMBIAR EL POD",
    modalMessage:
      "La desactivación cancela el basal temporal y bolo extendido actuales.",
    back: "Atrás",
    deactivePod: "Desactivar Pod",
    deactivateModalTitle: "¿Desactivar el Pod?",
    deactivateModalMessage:
      "Si desactiva este Pod, no podrá usarlo. Deberá configurar un nuevo Pod.",
    confirmTextDeactivate: "Desactivar Pod",
    pleaseWait: "Espere...",
    removePodModalTitle: "Quitar Pod",
    cancel: "Cancelar",
    removePodModalMessage1:
      "Es posible que su Pod todavía esté administrando insulina. Quítelo de su cuerpo y luego toque",
    removePodModalMessage2: "CONTINUAR.",
    continue: "Continuar",
    close: "cerrar",
    cancelModalTitle: "Función de Actividad en proceso",
    units: "Más de unidades",
    lastUpdate: "actualizado Hoy",
    expiresDate: "11 ago."
  },
  "fr-FR": {
    wrongDuration: "dans 4 jours",
    duration: "dans 3 jours",
    expiresDay: "dans %1 jours",
    unitsWithU: "%2 U",
    expires: "Péremption : %1",
    insulinLeft: "INSULINE RESTANTE DANS LE POD",
    status: "ÉTAT DU POD",
    changePod: "CHANGER LE POD",
    modalMessage:
      "La désactivation annule votre débit basal temporaire et votre bolus prolongé actuels.",
    back: "Retour",
    deactivePod: "Désactiver le Pod",
    deactivateModalTitle: "Désactiver votre Pod ?",
    deactivateModalMessage:
      "Si vous désactivez ce Pod, vous ne serez pas en mesure de l'utiliser. Vous devrez configurer un nouveau Pod.",
    confirmTextDeactivate: "Désactiver le Pod",
    pleaseWait: "Veuillez patienter...",
    removePodModalTitle: "Retirer le Pod",
    cancel: "Annuler",
    removePodModalMessage1:
      "Votre Pod administre peut-être toujours de l'insuline. Retirez-le, puis appuyez sur",
    removePodModalMessage2: "CONTINUER.",
    continue: "Continuer",
    close: "fermer",
    cancelModalTitle: "Fonction Activité en cours",
    units: "+ de 50 Unités"
  },
  "nl-NL": {
    wrongDuration: "in 4 dagen",
    duration: "in 3 dagen",
    expiresDay: "in %1 dag",
    unitsWithU: "%2 E",
    expires: "Verloopt in: Vandaag",
    insulinLeft: "INSULINE OVER IN POD",
    status: "POD-STATUS",
    changePod: "POD VERVANGEN",
    modalMessage:
      "Deactivering annuleert uw huidige tijdelijke basaalsnelheid en verlengde bolus.",
    back: "Terug",
    deactivePod: "Pod deactiveren",
    deactivateModalTitle: "Uw Pod deactiveren?",
    deactivateModalMessage:
      "Als u deze Pod deactiveert, kunt u hem niet gebruiken. U moet een nieuwe Pod instellen.",
    confirmTextDeactivate: "Pod deactiveren",
    pleaseWait: "Even wachten...",
    removePodModalTitle: "Pod verwijderen",
    cancel: "Annuleren",
    removePodModalMessage1:
      "Het kan zijn dat de Pod nog insuline toedient. Verwijder de Pod van uw lichaam en tik op",
    removePodModalMessage2: "DOORGAAN.",
    continue: "Doorgaan",
    close: "sluiten",
    cancelModalTitle: "Activiteitenfunctie bezig",
    units: "50+ eenheden",
    lastUpdate: "bijgewerkt Vandaag",
    expiresDate: "19 mei"
  }
};

export { strings };
