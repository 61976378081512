import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { ReactComponent as Edit } from "../../../../../assets/UI_Edit.svg";
import getCurrentHourFormat from "../../../helpers/getCurrentHourFormat";
import replaceWithValues from "../../../helpers/replaceWithValues";
import useLanguageContent from "../../../hooks/useLanguageContent";
import { LangState } from "../../../../../web/store/reducers/language";
import { RootState } from "../../../../../web/store/store";
import theme from "../../../theme";
import { strings } from "./BasalPrograminProgressContent";
const LIGHT_BLUE = "#EDF2F6";

const BasalProgramInProgress: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);
  const totalBasalNumber = (28.8).toLocaleString(langState.locale);
  const basalRate = (1.2).toLocaleString(langState.locale);
  const contentVariables = [totalBasalNumber, basalRate];
  const basalRateBold = languageContent.basalRate.split(" %2")[0];
  const basalRateNotBold = languageContent.basalRate.split(":")[1];

  return (
    <>
      <View style={[styles.subItemWrapper]}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text style={[{ fontSize: 16, color: theme.colors.greenText }]}>
            {languageContent.basal} 1
          </Text>
          <Text style={[styles.subItemText]}>{languageContent.graph}</Text>
        </View>
        <View style={{ flexDirection: "column", marginTop: 64 }}>
          <Text style={{ textAlign: "center" }}>
            {(1.2).toLocaleString(langState.locale)}
          </Text>
          <View style={{ height: 3, backgroundColor: theme.colors.blue }} />
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}>
            <Text>
              {getCurrentHourFormat({
                timeStr: "12:00 am",
                is24Hour: langState.is24Hour
              })}
            </Text>
            <Text>
              {" "}
              {getCurrentHourFormat({
                timeStr: "12:00 am",
                is24Hour: langState.is24Hour
              })}
            </Text>
          </View>
        </View>
        <Text
          style={{ fontSize: 16, textAlign: "center", paddingVertical: 16 }}>
          {replaceWithValues(languageContent.totalBasal, contentVariables)}
        </Text>
      </View>
      <View
        style={{
          backgroundColor: LIGHT_BLUE,
          borderTopWidth: 1,
          borderTopColor: theme.colors.textInputBorder,
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.textInputBorder
        }}>
        <View
          style={{
            paddingVertical: 8,
            paddingHorizontal: 16,
            flexDirection: "row",
            justifyContent: "space-between"
          }}>
          <Text
            style={[styles.subItemText, { color: theme.colors.text.grayText }]}>
            {languageContent.timeSegments}
          </Text>
          <Text
            style={[styles.subItemText, { color: theme.colors.text.grayText }]}>
            {languageContent.total} 1
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.subItemWrapper,
          {
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottomColor: theme.colors.blue,
            borderBottomWidth: 3
          }
        ]}>
        <View>
          <Text style={{ fontWeight: "700", fontSize: 16 }}>
            {`${languageContent.segment} 1: `}
            <Text
              style={{
                fontWeight: "400",
                color: theme.colors.blue,
                fontSize: 16
              }}>
              {`${getCurrentHourFormat({
                timeStr: "12:00 am",
                is24Hour: langState.is24Hour
              })} - ${getCurrentHourFormat({
                timeStr: "12:00 am",
                is24Hour: langState.is24Hour
              })}`}
            </Text>
          </Text>
          <Text
            style={{
              color: theme.colors.blue,
              fontWeight: "700",
              fontSize: 16,
              paddingTop: 8
            }}>
            {basalRateBold}
            <Text style={{ fontWeight: "400", fontSize: 16 }}>
              {replaceWithValues(basalRateNotBold, contentVariables)}
            </Text>
          </Text>
        </View>
        <View style={{ justifyContent: "center" }}>
          <Edit />
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  subItemWrapper: {
    padding: 16
  },
  subItemText: {
    fontSize: 14
  }
});

export default BasalProgramInProgress;
