let strings = {
  "en-US": {
    no: "No",
    yes: "Yes"
  },
  "en-GB": {
    no: "No",
    yes: "Yes"
  },
  "de-DE": {
    no: "Nein",
    yes: "Ja"
  },
  "es-US": {
    no: "No",
    yes: "Sí"
  },
  "fr-FR": {
    no: "Non",
    yes: "Oui"
  },
  "nl-NL": {
    no: "Nee",
    yes: "Ja"
  }
};

export { strings };
