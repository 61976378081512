import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  nextBtn: {
    id: 'ios.bolus.fts.next-btn',
    defaultMessage: 'Next',
  },
  backBtn: {
    id: 'ios.bolus.fts.back-btn',
    defaultMessage: 'Back',
  },
  cancelBtn: {
    id: 'ios.bolus.fts.cancel-btn',
    defaultMessage: 'Cancel',
  },
  saveBtn: {
    id: 'ios.bolus.fts.save-btn',
    defaultMessage: 'Save',
  },
  noBtn: {
    id: 'ios.bolus.fts.no-btn',
    defaultMessage: 'No',
  },
  yesBtn: {
    id: 'ios.bolus.fts.yes-btn',
    defaultMessage: 'Yes',
  },
  headerTitle: {
    id: 'ios.bolus.fts.header-title',
    defaultMessage: 'Setup: Bolus',
  },
  units: {
    id: 'ios.bolus.fts.units',
    defaultMessage: 'U/hr',
  },
  emptyValue: {
    id: 'ios.bolus.fts.empty-value',
    defaultMessage: '--',
  },
  hours: {
    id: 'ios.bolus.fts.hours',
    defaultMessage: 'hrs',
  },
  charactersPlaceholder: {
    id: 'ios.bolus.fts.bolus-program.characters-placeholder',
    defaultMessage: 'characters',
  },
  segmentTitle: {
    id: 'ios.bolus.fts.bolus-segment.segment-title',
    defaultMessage: 'Segment',
  },
  startTimeInputLabel: {
    id: 'ios.bolus.fts.bolus-segment.start-time-input-label',
    defaultMessage: 'Start Time',
  },
  endTimeInputLabel: {
    id: 'ios.bolus.fts.bolus-segment.end-time-input-label',
    defaultMessage: 'End Time',
  },
  targetGlucoseInputLabel: {
    id: 'ios.bolus.fts.bolus-segment.target-glucose-input-label',
    defaultMessage: 'Target Glucose',
  },
  correctAboveInputLabel: {
    id: 'ios.bolus.fts.bolus-segment.correct-above-input-label',
    defaultMessage: 'Correct Above',
  },
  targetGlucoseInputPlaceholder: {
    id: 'ios.bolus.fts.bolus-segment.target-glucose-input-placeholder',
    defaultMessage: '(110 to 150 mg/dL)',
  },
  correctAboveInputPlaceholder: {
    id: 'ios.bolus.fts.bolus-segment.correct-above-input-placeholder',
    defaultMessage: '(Target Glucose to 200 mg/dL)',
  },
  targetGlucoseAndCorrectAboveUnitEmptyValue: {
    id: 'ios.bolus.fts.target-glucose-and-correct-above-segment.unit-empty-value',
    defaultMessage: '-- mg/dL',
  },
  correctionFactorUnitEmptyValue: {
    id: 'ios.bolus.fts.correction-factor-segment.unit-empty-value',
    defaultMessage: '-- mg/dL',
  },
  timeSegmentsLabel: {
    id: 'ios.bolus.fts.bolus-segments-screen.time-segments-label',
    defaultMessage: 'TIME SEGMENTS',
  },
  totalLabel: {
    id: 'ios.bolus.fts.bolus-segments-screen.total-label',
    defaultMessage: 'TOTAL:',
  },
});

export const IntroBolusMessages = defineMessages({
  headerTitle: {
    id: 'ios.bolus.fts.intro-screen.header-title',
    defaultMessage: 'Setup: <highlight>Bolus</highlight>',
  },
  text: {
    id: 'ios.bolus.fts.intro-screen.text',
    defaultMessage:
      'Insulin that is taken with meals or to bring down high glucose.',
  },
});

export const CancelSetupModalMessages = defineMessages({
  title: {
    id: 'ios.bolus.fts.cancel-setup-modal.title',
    defaultMessage: 'Cancel bolus setup?',
  },
  text: {
    id: 'ios.bolus.fts.cancel-setup-modal.text',
    defaultMessage:
      'Canceling will erase your bolus entries and return you to the beginning of this section.',
  },
});

export const TargetGlucoseAndCorrectAboveValuesIntroMessages = defineMessages({
  title: {
    id: 'ios.bolus.fts.target-glucose-and-correct-above-intro-screen.title',
    defaultMessage: 'Set Target Glucose and Correct Above values',
  },
  textOne: {
    id: 'ios.bolus.fts.target-glucose-and-correct-above-intro-screen.text-one',
    defaultMessage:
      'These values are used in both Automated and Manual Modes and can be set for different times of the day.',
  },
  textTwo: {
    id: 'ios.bolus.fts.target-glucose-and-correct-above-intro-screen.text-two',
    defaultMessage:
      'In Automated Mode, your bolus insulin delivery will be adjusted automatically to bring you toward your <highlight>Target Glucose</highlight> value.',
  },
  textThree: {
    id: 'ios.bolus.fts.target-glucose-and-correct-above-intro-screen.text-three',
    defaultMessage:
      'In both Automated and Manual Modes, <highlight>Target Glucose</highlight> and <highlight>Correct Above</highlight> values are used for bolus calculations.',
  },
});

export const TargetGlucoseAndCorrectAboveSegmentOneMessages = defineMessages({
  startTimeInputValue: {
    id: 'ios.bolus.fts.target-glucose-and-correct-above-segment-one.start-time-input-value',
    defaultMessage: '12:00 AM (night)',
  },
  endTimeInputValue: {
    id: 'ios.bolus.fts.target-glucose-and-correct-above-segment-one.end-time-input-value',
    defaultMessage: '8:00 AM (morning)',
  },
  targetGlucoseInputValue: {
    id: 'ios.bolus.fts.target-glucose-and-correct-above-segment-one.target-glucose-input-value',
    defaultMessage: '110 mg/dL',
  },
  correctAboveInputValue: {
    id: 'ios.bolus.fts.target-glucose-and-correct-above-segment-one.correct-above-input-value',
    defaultMessage: '120 mg/dL',
  },
});

export const TargetGlucoseAndCorrectAboveSegmentTwoMessages = defineMessages({
  startTimeInputValue: {
    id: 'ios.bolus.fts.target-glucose-and-correct-above-segment-two.start-time-input-value',
    defaultMessage: '8:00 AM (morning)',
  },
  endTimeInputValue: {
    id: 'ios.bolus.fts.target-glucose-and-correct-above-segment-two.end-time-input-value',
    defaultMessage: '12:00 AM (night)',
  },
  targetGlucoseInputValue: {
    id: 'ios.bolus.fts.target-glucose-and-correct-above-segment-two.target-glucose-input-value',
    defaultMessage: '120 mg/dL',
  },
  correctAboveInputValue: {
    id: 'ios.bolus.fts.target-glucose-and-correct-above-segment-two.correct-above-input-value',
    defaultMessage: '130 mg/dL',
  },
});

export const TargetGlucoseAndCorrectAboveSegmentsMessages = defineMessages({
  text: {
    id: 'ios.bolus.fts.bolus-segments-screen.text',
    defaultMessage:
      'Review the Target Glucose and Correct Above values entered for each time segment.',
  },
});

export const SetInsulinToCarbRatioIntroMessages = defineMessages({
  title: {
    id: 'ios.bolus.fts.set-insulin-to-carb-ratio-intro-screen.title',
    defaultMessage: 'Set Insulin to Carb (IC) Ratios',
  },
  textOne: {
    id: 'ios.bolus.fts.set-insulin-to-carb-ratio-intro-screen.text-one',
    defaultMessage:
      'Number of grams of carbohydrate covered by one unit of insulin.',
  },

  textTwo: {
    id: 'ios.bolus.fts.set-insulin-to-carb-ratio-intro-screen.text-two',
    defaultMessage:
      'For example, if your IC Ratio is 1:15, then you need to deliver one unit of insulin to cover every fifteen grams of carbohydrate you eat.',
  },
});

export const CreateInsulinToCarbRatioSegmentMessages = defineMessages({
  unitOfInsulinLabel: {
    id: 'ios.bolus.fts.set-insulin-to-carb-ratio-segment-screen.unit-of-insulin-label',
    defaultMessage: '1 unit of insulin covers',
  },
  unitOfInsulinPlaceholder: {
    id: 'ios.bolus.fts.set-insulin-to-carb-ratio-segment-screen.unit-of-insulin-placeholder',
    defaultMessage: '( 1 to 150 g carbs )',
  },
  unitOfInsulinEmptyValue: {
    id: 'ios.bolus.fts.set-insulin-to-carb-ratio-segment-screen.unit-of-insulin-empty-value',
    defaultMessage: '-- g',
  },
});

export const BolusInsulinToCarbRatioSegmentOneMessages = defineMessages({
  startTimeInputValue: {
    id: 'ios.bolus.fts.bolus-insulin-to-carb-ratio-segment-one-screen.start-time-input-value',
    defaultMessage: '12:00 AM (night)',
  },
  endTimeInputValue: {
    id: 'ios.bolus.fts.bolus-insulin-to-carb-ratio-segment-one-screen.end-time-input-value',
    defaultMessage: '8:00 AM (morning)',
  },
  unitOfInsulinInputValue: {
    id: 'ios.bolus.fts.bolus-insulin-to-carb-ratio-segment-one-screen.units-of-insulin-input-value ',
    defaultMessage: '15 g',
  },
});

export const BolusInsulinToCarbRatioSegmentTwoMessages = defineMessages({
  startTimeInputValue: {
    id: 'ios.bolus.fts.bolus-insulin-to-carb-ratio-segment-two-screen.start-time-input-value',
    defaultMessage: '8:00 AM (morning)',
  },
  endTimeInputValue: {
    id: 'ios.bolus.fts.bolus-insulin-to-carb-ratio-segment-two-screen.end-time-input-value',
    defaultMessage: '12:00 AM (night)',
  },
  unitOfInsulinInputValue: {
    id: 'ios.bolus.fts.bolus-insulin-to-carb-ratio-segment-two-screen.units-of-insulin-input-value',
    defaultMessage: '20 g',
  },
});

export const InsulinToCarbRatioSegmentsMessages = defineMessages({
  text: {
    id: 'ios.bolus.fts.insulin-to-carb-ratio-segments-screen.text',
    defaultMessage:
      'Review the Insulin to Carb (IC) Ratio values entered for each time segment.',
  },
  placeholder: {
    id: 'ios.bolus.fts.insulin-to-carb-ratio-segments-screen.placeholder',
    defaultMessage: '1 U of insulin covers:',
  },
  unitOfInsulinSegmentOneValue: {
    id: 'ios.bolus.fts.insulin-to-carb-ratio-segments-screen.units-of-insulin-value-one',
    defaultMessage: '15 g of carbs',
  },
  unitOfInsulinSegmentTwoValue: {
    id: 'ios.bolus.fts.insulin-to-carb-ratio-segments-screen.units-of-insulin-value-two',
    defaultMessage: '20 g of carbs',
  },
});

export const SetCorrectionFactorIntroMessages = defineMessages({
  title: {
    id: 'ios.bolus.fts.set-correction-factor-intro-screen.title',
    defaultMessage: 'Set Correction Factors',
  },
  textOne: {
    id: 'ios.bolus.fts.set-correction-factor-intro-screen.text-one',
    defaultMessage:
      'A value that indicates how much one unit of insulin will lower your glucose.',
  },

  textTwo: {
    id: 'ios.bolus.fts.set-correction-factor-intro-screen.text-two',
    defaultMessage:
      'For example, if your Correction Factor is 50, one unit of insulin lowers your glucose by 50 mg/dL.',
  },
});

export const CreateCorrectionFactorSegmentMessages = defineMessages({
  unitOfInsulinLabel: {
    id: 'ios.bolus.fts.create-correction-factor-segment-screen.unit-of-insulin-label',
    defaultMessage: '1 Unit of insulin lowers glucose by',
  },
  unitOfInsulinPlaceholder: {
    id: 'ios.bolus.fts.create-correction-factor-segment-screen.unit-of-insulin-placeholder',
    defaultMessage: '(1 to 400 mg/dL)',
  },
});

export const CorrectionFactorSegmentOneMessages = defineMessages({
  startTimeInputValue: {
    id: 'ios.bolus.fts.correction-factor-segment-one.start-time-input-value',
    defaultMessage: '12:00 AM (night)',
  },
  endTimeInputValue: {
    id: 'ios.bolus.fts.correction-factor-segment-one.end-time-input-value',
    defaultMessage: '8:00 AM (morning)',
  },
  unitOfInsulinInputValue: {
    id: 'ios.bolus.fts.correction-factor-segment-one.units-of-insulin-value',
    defaultMessage: '80 mg/dL',
  },
});

export const CorrectionFactorSegmentTwoMessages = defineMessages({
  startTimeInputValue: {
    id: 'ios.bolus.fts.correction-factor-segment-two.start-time-input-value',
    defaultMessage: '8:00 AM (morning)',
  },
  endTimeInputValue: {
    id: 'ios.bolus.fts.correction-factor-segment-two.end-time-input-value',
    defaultMessage: '12:00 AM (night)',
  },
  unitOfInsulinInputValue: {
    id: 'ios.bolus.fts.correction-factor-segment-two.units-of-insulin-value',
    defaultMessage: '120 mg/dL',
  },
});

export const CorrectionFactorSegmentsMessages = defineMessages({
  text: {
    id: 'ios.bolus.fts.correction-factor-segments-screen.text',
    defaultMessage:
      'Review the Correction Factor values entered for each time segment.',
  },
  placeholder: {
    id: 'ios.bolus.fts.correction-factor-segments-screen.placeholder',
    defaultMessage: '1 U of insulin lowers glucose by:',
  },
});

export const SetDurationOfInsulinActionMessages = defineMessages({
  title: {
    id: 'ios.bolus.fts.set-duration-of-insulin-action.title',
    defaultMessage: 'Set Duration of Insulin Action',
  },
  text: {
    id: 'ios.bolus.fts.set-duration-of-insulin-action.text',
    defaultMessage:
      'The length of time that insulin remains active and available in your body after a correction or meal bolus.',
  },
  inputLabel: {
    id: 'ios.bolus.fts.set-duration-of-insulin-action.input-label',
    defaultMessage: 'Duration of Insulin Action',
  },
  inputPlaceholder: {
    id: 'ios.bolus.fts.set-duration-of-insulin-action.input-placeholder',
    defaultMessage: '(2 to 6 hrs)',
  },
});

export const SetMaximumBolusMessages = defineMessages({
  title: {
    id: 'ios.bolus.fts.set-maximum-bolus.title',
    defaultMessage: 'Set Maximum Bolus',
  },
  text: {
    id: 'ios.bolus.fts.set-maximum-bolus.text',
    defaultMessage:
      'The maximum amount of insulin that you can request in a single bolus.',
  },
  inputLabel: {
    id: 'ios.bolus.fts.set-maximum-bolus.input-label',
    defaultMessage: 'Max Bolus',
  },
  inputPlaceholder: {
    id: 'ios.bolus.fts.set-maximum-bolus.input-placeholder',
    defaultMessage: '(0.05 to 30 U)',
  },
  emptyInputValue: {
    id: 'ios.bolus.fts.set-maximum-bolus.empty-input-value',
    defaultMessage: '-- U',
  },
});

export const AllowExtendedBolusMessages = defineMessages({
  title: {
    id: 'ios.bolus.fts.allow-extended-bolus-screen.title',
    defaultMessage: 'Allow Extended Bolus?',
  },
  text: {
    id: 'ios.bolus.fts.allow-extended-bolus-screen.text',
    defaultMessage:
      'Extending a bolus means that a meal bolus can be delivered over a prolonged period of time.',
  },
  tempBolus: {
    id: 'ios.bolus.fts.allow-extended-bolus-screen.temp-bolus-label',
    defaultMessage: 'Extended Bolus',
  },
});
