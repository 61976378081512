let strings = {
  "en-US": {
    start: "Start",
    end: "End",
    night: "Night",
    endTime: "End Time"
  },
  "en-GB": {
    start: "Start",
    end: "End",
    night: "Night",
    endTime: "End Time"
  },
  "de-DE": {
    start: "Start",
    end: "Ende",
    night: "Nacht",
    endTime: "Endzeit"
  },
  "es-US": {
    start: "Iniciar",
    end: "Finalizar",
    night: "Noche",
    endTime: "Hora de finalización"
  },
  "fr-FR": {
    start: "Démarrer",
    end: "Fin",
    night: "Nuit",
    endTime: "Heure de fin"
  },
  "nl-NL": {
    start: "Start",
    end: "Stop",
    night: "Nacht",
    endTime: "Eindtijd"
  }
};

export { strings };
