import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  mainText: {
    id: 'L2.apply-sensor.main-text',
    defaultMessage:
      'Apply a new Sensor. The sensor should be applied ot the back of your upper arm.',
  },
  howToApply: {
    id: 'L2.apply-sensor.how-to-apply',
    defaultMessage: 'HOW TO APPLY A SENSOR',
  },
  cta: {
    id: 'L2.apply-sensor.cta',
    defaultMessage: 'CONTINUE',
  },
});
