let strings = {
  "en-US": {
    correctionBolus: "Correction Bolus",
    sensor: "Sensor",
    target: "Target Glucose",
    correctionFactor: "Correction Factor",
    mealAdjustment: "Meal IOB adjustment",
    mealIOB: "Meal IOB",
    correctionsIOBAdjustment1: "Correction IOB adjustment",
    correctionIOB: "Correction IOB",
    mealBolus: "Meal Bolus",
    carbs: "Carbs",
    ratio: "IC Ratio",
    correctionsIOBAdjustment2: "Correction IOB adjustment",
    remainingIOBAdjustment: "Remaining correction IOB",
    adjustedMealBolus: "Adjusted meal bolus",
    adjustedForSensor: "Adjusted for Sensor: Trending steady",
    calculatedBolus: "Calculated Bolus",
    yourAdjustment: "Your Adjustment",
    calcBolusCorrectionBolus: "Correction bolus",
    calcBolusMealBolus: "Meal bolus",
    totalBolus: "Total Bolus",
    correctRange: "Correct Above:",
    close: "close",
    modalTitle: "Bolus Calculations",
    modalBody:
      "The values shown here are for simulation purposes only and are not representative of actual calculations. Actual calculations will vary based on user settings.",
    ok: "OK",
    u: "U"
  },
  "en-GB": {
    correctionBolus: "Correction Bolus",
    sensor: "Sensor",
    target: "Target Glucose",
    correctionFactor: "Correction Factor",
    mealAdjustment: "Meal IOB adjustment",
    mealIOB: "Meal IOB",
    correctionsIOBAdjustment1: "Correction IOB adjustment",
    correctionIOB: "Correction IOB",
    mealBolus: "Meal Bolus",
    carbs: "Carbs",
    ratio: "IC Ratio",
    correctionsIOBAdjustment2: "Correction IOB adjustment",
    remainingIOBAdjustment: "Remaining correction IOB",
    adjustedMealBolus: "Adjusted meal bolus",
    adjustedForSensor: "Adjusted for Sensor: Trending steady",
    calculatedBolus: "Calculated Bolus",
    yourAdjustment: "Your Adjustment",
    calcBolusCorrectionBolus: "Correction bolus",
    calcBolusMealBolus: "Meal bolus",
    totalBolus: "Total Bolus",
    correctRange: "Correct Above:",
    close: "close",
    modalTitle: "Bolus Calculations",
    modalBody:
      "The values shown here are for simulation purposes only and are not representative of actual calculations. Actual calculations will vary based on user settings.",
    ok: "OK",
    u: "U"
  },
  "de-DE": {
    correctionBolus: "Korrekturbolus",
    sensor: "Sensor",
    target: "Glukose-Zielwert",
    correctionFactor: "Korrekturfaktor",
    mealAdjustment: "Anpassung Mahlzeit-AI",
    mealIOB: "Mahlzeit-AI",
    correctionsIOBAdjustment1: "Anpassung Korrektur-AI",
    correctionIOB: "Korrektur-AI",
    mealBolus: "Mahlzeitenbolus",
    carbs: "KH",
    ratio: "KI-Verhältnis",
    correctionsIOBAdjustment2: "Anpassung Korrektur-AI",
    remainingIOBAdjustment: "Verbleibendes Korrektur-AI",
    adjustedMealBolus: "Angepasster Mahlzeitenbolus",
    adjustedForSensor: "Angepasst auf Sensor: Stabiler Trend",
    calculatedBolus: "Berechneter Bolus",
    yourAdjustment: "Ihre Anpassung",
    calcBolusCorrectionBolus: "Korrekturbolus",
    calcBolusMealBolus: "Mahlzeitenbolus",
    totalBolus: "Gesamtbolus",
    correctRange: "Korrigieren über:",
    close: "Schließen",
    modalTitle: "Bolusberechnungen",
    modalBody:
      "Die hier gezeigten Werte dienen nur zu Simulationszwecken und sind nicht repräsentativ für tatsächliche Berechnungen. Die tatsächlichen Berechnungen hängen von den Benutzereinstellungen ab.",
    ok: "OK",
    u: "E"
  },
  "es-US": {
    correctionBolus: "Bolo de corrección",
    sensor: "Sensor",
    target: "Glucosa Objetivo",
    correctionFactor: "Factor de Corrección",
    mealAdjustment: "Ajuste de la IOB de la comida",
    mealIOB: "IOB de la comida",
    correctionsIOBAdjustment1: "Ajuste de la IOB de corrección",
    correctionIOB: "IOB de corrección",
    mealBolus: "Bolo de comida",
    carbs: "Carb.",
    ratio: "Proporción de IC",
    correctionsIOBAdjustment2: "Ajuste de la IOB de corrección",
    remainingIOBAdjustment: "IOB de corrección restante",
    adjustedMealBolus: "Bolo de comida ajustado",
    adjustedForSensor: "Ajustado por el sensor: Tendencia constante",
    calculatedBolus: "Bolo calculado",
    yourAdjustment: "Su ajuste",
    calcBolusCorrectionBolus: "Bolo de corrección",
    calcBolusMealBolus: "Bolo de comida",
    totalBolus: "Bolo total",
    correctRange: "Corregir Sobre:",
    close: "cerrar",
    modalTitle: "Cálculos del bolo",
    modalBody:
      "Los valores mostrados aquí son solo para fines de simulación y no son representativos de los cálculos reales. Los cálculos reales variarán según los ajustes del usuario.",
    ok: "Aceptar",
    u: "U"
  },
  "fr-FR": {
    correctionBolus: "Bolus de correction",
    sensor: "Capteur",
    target: "Glucose cible",
    correctionFactor: "Facteur de correction",
    mealAdjustment: "Ajustement de l’InA des repas",
    mealIOB: "InA des repas",
    correctionsIOBAdjustment1: "Ajustement de l’InA de correction",
    correctionIOB: "InA de correction",
    mealBolus: "Bolus repas",
    carbs: "Glucides",
    ratio: "Rapport I/G",
    correctionsIOBAdjustment2: "Ajustement de l’InA de correction",
    remainingIOBAdjustment: "InA de correction restante",
    adjustedMealBolus: "Bolus repas ajusté",
    adjustedForSensor: "Ajusté en fonction du capteur : Tendance stable",
    calculatedBolus: "Bolus calculé",
    yourAdjustment: "Votre ajustement",
    calcBolusCorrectionBolus: "Bolus de correction",
    calcBolusMealBolus: "Bolus repas",
    totalBolus: "Bolus total",
    correctRange: "Corriger si supérieur à :",
    close: "fermer",
    modalTitle: "Calculs de bolus",
    modalBody:
      "Les valeurs indiquées ici sont utilisées à des fins de simulation uniquement et ne sont pas représentatives de calculs réels. Les calculs réels varient en fonction des réglages de l'utilisateur.",
    ok: "OK",
    u: "U"
  },
  "nl-NL": {
    correctionBolus: "Correctiebolus",
    sensor: "Sensor",
    target: "Streefwaarde glucose",
    correctionFactor: "Correctiefactor",
    mealAdjustment: "Aanpassing van maaltijd-IOB",
    mealIOB: "Maaltijd-IOB",
    correctionsIOBAdjustment1: "Aanpassing van correctie-IOB",
    correctionIOB: "Correctie-IOB",
    mealBolus: "Maaltijdbolus",
    carbs: "KH",
    ratio: "I/KH-verhouding",
    correctionsIOBAdjustment2: "Aanpassing van correctie-IOB",
    remainingIOBAdjustment: "Resterende correctie-IOB",
    adjustedMealBolus: "Bijgestelde maaltijdbolus",
    adjustedForSensor: "Bijgesteld voor sensor: Trend is constant",
    calculatedBolus: "Berekende bolus",
    yourAdjustment: "Uw aanpassing",
    calcBolusCorrectionBolus: "Correctiebolus",
    calcBolusMealBolus: "Maaltijdbolus",
    totalBolus: "Totale bolus",
    correctRange: "Corrigeren boven:",
    close: "sluiten",
    modalTitle: "Bolusberekening",
    modalBody:
      "De hier getoonde waarden dienen uitsluitend voor simulatiedoeleinden en zijn niet representatief voor de werkelijke berekeningen. De werkelijke berekeningen variëren afhankelijk van de instellingen van de gebruiker.",
    ok: "OK",
    u: "E"
  }
};

export { strings };
