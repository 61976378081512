import { useNavigation, useRoute } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  BackHandler,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import defaultBasalReduxData from "../../../../../../data/defaultBasalReduxData";
import findExpertBasalStartTimeIndex from "../../../../../../helpers/findExpertBasalStartTimeIndex";
import findExpertBasalStartTimeLabel from "../../../../../../helpers/findExpertBasalStartTimeLabel";
import getAdjustedDataForEndTimePicker from "../../../../../../helpers/getAdjustedDataForEndTimePicker";
import { getLocalizedNumberFormat } from "../../../../../../helpers/getLocalizedNumberFormat";
import useExpertHardwareBackAction from "../../../../../../hooks/useExpertHardwareBackAction";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { bolusActions } from "../../../../../../../../web/store/reducers/bolus";
import { BolusState } from "../../../../../../../../web/store/reducers/bolus/reducer";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import {
  BottomInnerNav,
  ConfirmationModal,
  SegmentTopBar
} from "../../../../../molecules";
import { StartAndEndTimeSection } from "../../../../../organisms";
import { strings } from "./ExpertICRatioSegmentScreensContent";
interface Props {
  navigation: any;
}
const NOT_HIGHLIGHTED = "";
const ONE_UNIT_FIELD = "ONE_UNIT";

const ExpertICRatioSegmentScreens: React.FC<Props> = ({ navigation }) => {
  //GLOBAL STATE
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const segmentsArrLength =
    bolusState.newExpertBolusProgram.icRatioSegments.length;

  const { languageContent } = useLanguageContent(strings);

  //LOCAL STATE
  const [cancelModal, setCancelModal] = React.useState(false);
  const [fieldValue, setFieldValue] = React.useState<string>("");
  const [startTime, setStartTime] = React.useState<string>("12:00 am");
  const [endTime, setEndTime] = React.useState({
    label: "",
    helpText: languageContent.helpText
  });
  const [showMinimumValueModal, setShowMinimumValueModal] =
    React.useState<boolean>(false);
  const [showMaximumValueModal, setShowMaximumValueModal] =
    React.useState<boolean>(false);

  const [highlighted, setHighlighted] = React.useState<string>("");
  const [background, setBackground] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");

  const [adjustedEndTimeData, setAdjustedEndTimeData] = React.useState<any[]>();
  const [showEndTimePicker, setShowEndTimePicker] =
    React.useState<boolean>(false);

  const [showBolusRatePicker, setShowBolusRatePicker] =
    React.useState<boolean>();
  const [borderColor, setBorderColor] = React.useState(
    theme.colors.textInputBorder
  );
  const [endTimeIndex, setEndTimeIndex] = React.useState<number>(0);
  const [rerenderPicker, setRerenderPicker] = React.useState<boolean>(false);
  const [segmentIndex, setSegmentIndex] = React.useState<number>(
    bolusState.newExpertBolusProgram.icRatioSegments.length + 1
  );
  const [initialRender, setInitialRender] = React.useState<boolean>(true);
  //HOOKS
  const fieldRef = React.useRef<TextInput>(null);
  const route = useRoute<any>();
  const backAction = useExpertHardwareBackAction(navigation, "BolusIC");
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  //FUNCTIONS

  const checkFieldValue = () => {
    if (fieldValue !== "" && Number(fieldValue) < 1) {
      setError(ONE_UNIT_FIELD);
      setShowMinimumValueModal(true);
    }
    if (fieldValue !== "" && Number(fieldValue) > 150) {
      setError(ONE_UNIT_FIELD);
      setShowMaximumValueModal(true);
    }
  };
  const toggleBolusRatePicker = () => {
    if (borderColor === theme.colors.blue) {
      setBorderColor(theme.colors.textInputBorder);
      setShowBolusRatePicker(false);
    } else {
      setBorderColor(theme.colors.blue);
      setShowBolusRatePicker(true);
      setShowEndTimePicker(false);
    }
  };

  const toggleEndTimePicker = () => {
    setShowEndTimePicker((showEndTimePicker) => !showEndTimePicker);
    if (showBolusRatePicker) {
      toggleBolusRatePicker();
    }
  };

  const endTimePickerUpdate = (value: string) => {
    setShowEndTimePicker(false);
    const selected = adjustedEndTimeData?.find(
      (adjustedEndTimeData) => adjustedEndTimeData.label === value
    );
    setEndTime({
      label: selected.label.toUpperCase(),
      helpText: selected.helpText
    });

    setEndTimeIndex(selected.index);

    if (!bolusState.newExpertBolusProgram.hasUpdate) {
      dispatch(
        bolusActions.bolusUpdate(
          {
            newExpertBolusProgram: {
              ...bolusState.newExpertBolusProgram,
              hasUpdate: true
            }
          },
          bolusState
        )
      );
    }
  };

  const getAdornmentColor = () => {
    if (!fieldValue) {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState<boolean>(false);

  const handleNext = () => {
    if (Number(fieldValue) < 1 || Number(fieldValue) > 150 || !endTime) {
      return;
    }
    if (endTime.label === "") {
      return;
    }

    dispatch(
      bolusActions.bolusUpdate(
        {
          newExpertBolusProgram: {
            ...bolusState.newExpertBolusProgram,
            hasUpdate: false,
            icRatioSegments: [
              ...bolusState.newExpertBolusProgram.icRatioSegments,
              {
                start: startTime,
                end: endTime.label.toLowerCase(),
                startTimeHelpText: endTime.helpText,
                endHelpText: endTime.helpText,
                oneUnitInsulinCovers: fieldValue.includes(",")
                  ? Number(getLocalizedNumberFormat(fieldValue))
                  : Number(fieldValue)
              }
            ]
          }
        },
        bolusState
      )
    );

    if (endTime.label && endTime.label === "12:00 AM") {
      return setShowConfirmationModal(true);
    }

    return navigation.push("ExpertICRatioSegment");
  };

  //LIFECYCLE

  const setPickerDynamicStartTime = React.useCallback(() => {
    let adjustedStartTime = "";
    if (
      startTime &&
      startTime.toLowerCase() === "12:00 am" &&
      segmentsArrLength === 0
    ) {
      adjustedStartTime = "12:30 am";
    } else if (segmentsArrLength === 0) {
      adjustedStartTime = "12:30 am";
      setStartTime("12:00 am");
    } else {
      // findExpertBasalStartTimeIndex: find the index of previous segment end time in the data set of hours
      // findExpertBasalStartTimeLabel: find 30 minutes past where the index is, use that as the base value for the picker
      adjustedStartTime = findExpertBasalStartTimeLabel(
        findExpertBasalStartTimeIndex(
          bolusState.newExpertBolusProgram.icRatioSegments[
            segmentsArrLength - 1
          ].end.toUpperCase()
        )
      );
    }

    setAdjustedEndTimeData(
      getAdjustedDataForEndTimePicker(adjustedStartTime.toUpperCase())
    );
  }, [bolusState.newExpertBolusProgram.icRatioSegments]);

  React.useEffect(() => {
    checkFieldValue();
  }, [fieldValue]);

  React.useEffect(() => {
    if (rerenderPicker) {
      setRerenderPicker(false);
    }
  }, [rerenderPicker]);

  React.useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", backAction);
    setPickerDynamicStartTime();

    setSegmentIndex(segmentsArrLength + 1);

    if (
      endTime.label.toLowerCase() !== "12:00 am" &&
      endTime.label.toLowerCase() !== "00:00" &&
      segmentsArrLength > 0
    ) {
      setStartTime(
        bolusState.newExpertBolusProgram.icRatioSegments[segmentsArrLength - 1]
          .end
      );
    }

    if (
      bolusState.newExpertBolusProgram.isComplete &&
      route.params &&
      route.params.editEndTime
    ) {
      if (initialRender) {
        let helpText;
        if (segmentsArrLength === 0 && route.params.editEndHelpText) {
          helpText = route.params.editEndHelpText;
        } else {
          helpText =
            bolusState.newExpertBolusProgram.icRatioSegments[
              segmentsArrLength - 1
            ].startTimeHelpText!;
        }
        setEndTime({
          label: route.params.editEndTime,
          helpText
        });
      }
    }
    return () =>
      BackHandler.removeEventListener("hardwareBackPress", backAction);
  }, [
    bolusState.newExpertBolusProgram.isComplete,
    bolusState.newExpertBolusProgram.isActive,
    bolusState.newExpertBolusProgram.icRatioSegments,
    segmentsArrLength,
    route.params,
    initialRender,
    setPickerDynamicStartTime
  ]);

  React.useEffect(
    () =>
      navigation.addListener("beforeRemove", () => {
        dispatch(
          bolusActions.bolusUpdate(
            {
              newExpertBolusProgram: {
                ...bolusState.newExpertBolusProgram,
                icRatioSegments:
                  bolusState.newExpertBolusProgram.icRatioSegments.slice(
                    0,
                    bolusState.newExpertBolusProgram.icRatioSegments.length - 1
                  )
              }
            },
            bolusState
          )
        );
      }),
    [navigation]
  );
  //RETURN
  return (
    <View style={[styles.container]}>
      <View>
        <SegmentTopBar segmentNum={String(segmentIndex)} />
        <View style={[styles.break, { marginBottom: 16 }]} />
        <View>
          <StartAndEndTimeSection
            is24Hour={langState.is24Hour}
            locale={langState.locale}
            startTime={startTime}
            toggleEndTimePicker={toggleEndTimePicker}
            endTime={endTime}
            segmentsArrLength={segmentsArrLength}
            routeParams={route.params}
            adjustedEndTimeData={adjustedEndTimeData}
            showEndTimePicker={showEndTimePicker}
            onConfirm={endTimePickerUpdate}
            onDismiss={() => setShowEndTimePicker(false)}
            endTimeIndex={endTimeIndex}
            icRatio
          />
          <View
            style={[
              {
                paddingHorizontal: 16,
                marginHorizontal: -16,
                paddingBottom: 16,
                zIndex: -1,
                backgroundColor: background ? "#EEF2F6" : "transparent"
              }
            ]}>
            <View
              style={[
                {
                  marginHorizontal: 16
                }
              ]}>
              <Text style={[theme.fonts.h4]}>
                {languageContent.insulinCovers}
              </Text>
              <Text
                style={[
                  theme.fonts.body1,
                  { color: theme.colors.text.cancel, marginBottom: 8 }
                ]}>
                {languageContent.carbs}
              </Text>
            </View>
            <TouchableOpacity
              style={[
                theme.layout.inputContainerStyle,
                {
                  zIndex: 999,
                  marginHorizontal: 16,
                  borderColor:
                    highlighted === ONE_UNIT_FIELD
                      ? theme.colors.blue
                      : theme.colors.textInputBorder
                }
              ]}
              activeOpacity={1}
              onPress={() => {
                fieldRef.current?.focus();
                setBackground(ONE_UNIT_FIELD);
                setHighlighted(ONE_UNIT_FIELD);
              }}>
              <TextInput
                style={[
                  theme.layout.inputStyle,
                  {
                    color: error
                      ? theme.colors.red
                      : theme.colors.text.inputText
                  }
                ]}
                value={String(fieldValue)}
                keyboardType="number-pad"
                selectionColor={theme.colors.blue}
                returnKeyType="done"
                ref={fieldRef}
                placeholder={"––"}
                placeholderTextColor={theme.colors.placeholder}
                onBlur={() => {
                  setHighlighted(NOT_HIGHLIGHTED);
                  setBackground(NOT_HIGHLIGHTED);
                }}
                onChangeText={(val) => setFieldValue(String(val))}
              />
              <Text
                style={[
                  theme.layout.adornment,
                  {
                    color: getAdornmentColor()
                  }
                ]}>
                g
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <BottomInnerNav
        leftActionStyle={{ color: theme.colors.text.cancel }}
        leftActionText={languageContent.cancel}
        leftAction
        leftNavigationAction={() => setCancelModal(true)}
        rightActionText={languageContent.next}
        rightAction={handleNext}
        rightActionStyle={{
          color:
            !fieldValue ||
            Number(fieldValue) < 1 ||
            Number(fieldValue) > 150 ||
            !endTime.label
              ? theme.colors.placeholder
              : theme.colors.purple
        }}
      />
      {showConfirmationModal && (
        <ConfirmationModal
          isVisible={true}
          title={languageContent.confirmModalTitle}
          confirmText={languageContent.confirmModalButtonText}
          message={
            <View>
              <Text style={theme.fonts.body1}>
                {languageContent.confirmModalBody}
              </Text>
            </View>
          }
          hideDismiss={true}
          onConfirm={() => {
            setShowConfirmationModal(false);
            navigate("ExpertReviewICRatioSegment");
          }}
        />
      )}
      <ConfirmationModal
        isVisible={showMinimumValueModal}
        title={languageContent.minimumModalTitle}
        message={
          <View>
            <Text style={[theme.fonts.body1]}>
              {languageContent.minimumModalBody}
            </Text>
          </View>
        }
        confirmText={languageContent.ok}
        hideDismiss={true}
        onConfirm={() => {
          setError("");
          setFieldValue("");
          setShowMinimumValueModal(false);
        }}
      />
      <ConfirmationModal
        isVisible={showMaximumValueModal}
        title={languageContent.maximumModalTitle}
        message={
          <View>
            <Text style={[theme.fonts.body1]}>
              {languageContent.maximumModalBody}
            </Text>
          </View>
        }
        confirmText={languageContent.ok}
        hideDismiss={true}
        onConfirm={() => {
          setError("");
          setFieldValue("");
          setShowMaximumValueModal(false);
        }}
      />
      <ConfirmationModal
        title={languageContent.cancelModalTitle}
        isVisible={cancelModal}
        onDismiss={() => setCancelModal(false)}
        onConfirm={() => {
          setCancelModal(false);
          dispatch(
            bolusActions.bolusUpdate(
              {
                newExpertBolusProgram: {
                  ...bolusState.newExpertBolusProgram,
                  icRatioSegments: defaultBasalReduxData.icRatioSegments
                }
              },
              bolusState
            )
          );
          navigation.navigate("ICRatioFTS");
        }}
        message={
          <View>
            <Text style={[theme.fonts.body1, { marginBottom: 8 }]}>
              {languageContent.cancelModalBody}
            </Text>
          </View>
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  mockInput: {
    borderColor: theme.colors.textInputBorder,
    borderWidth: 1,
    backgroundColor: theme.colors.textInputBackgroundColor,
    width: "100%",
    borderRadius: 5
  },

  periodText: {
    color: theme.colors.text.cancel,
    fontSize: 18
  }
});

export default ExpertICRatioSegmentScreens;
