let strings = {
  "en-US": {
    training: "Training typically takes 90 minutes or less.",
    waysTo: "3 ways to schedule your training:",
    callInsulet: "Call Insulet at ",
    customerCare: "<customer_care_helpline>",
    fillForm: "Fill out a form at ",
    omnipodURL: "www.omnipod.com/setup ",
    callBack: "to get a call back.",
    health: "Call your healthcare provider.",
    ok: "OK"
  },
  "en-GB": {
    training: "Training typically takes 90 minutes or less.",
    waysTo: "3 ways to schedule your training:",
    callInsulet: "Call Insulet at ",
    customerCare: "<customer_care_helpline>",
    fillForm: "Fill out a form at ",
    omnipodURL: "www.omnipod.com/setup ",
    callBack: "to get a call back.",
    health: "Call your healthcare provider.",
    ok: "OK"
  },
  "es-US": {
    training: "La formación se realiza habitualmente en 90 minutos o menos.",
    waysTo: "3 formas de programar la formación:",
    callInsulet: "Llame a Insulet al  ",
    customerCare: "<customer_care_helpline>",
    fillForm: "Complete el formulario en ",
    omnipodURL: "www.omnipod.com/setup ",
    callBack: "para que le devolvamos la llamada",
    health: "Llame a su proveedor de salud",
    ok: "OK"
  },
  "de-DE": {
    training: "Training typically takes 90 minutes or less.",
    waysTo: "3 ways to schedule your training:",
    callInsulet: "Call Insulet at ",
    customerCare: "<customer_care_helpline>",
    fillForm: "Fill out a form at ",
    omnipodURL: "www.omnipod.com/setup ",
    callBack: "to get a call back.",
    health: "Call your healthcare provider.",
    ok: "OK"
  },
  "nl-NL": {
    training: "Training typically takes 90 minutes or less.",
    waysTo: "3 ways to schedule your training:",
    callInsulet: "Call Insulet at ",
    customerCare: "<customer_care_helpline>",
    fillForm: "Fill out a form at ",
    omnipodURL: "www.omnipod.com/setup ",
    callBack: "to get a call back.",
    health: "Call your healthcare provider.",
    ok: "OK"
  },
  "fr-FR": {
    training: "Training typically takes 90 minutes or less.",
    waysTo: "3 ways to schedule your training:",
    callInsulet: "Call Insulet at ",
    customerCare: "<customer_care_helpline>",
    fillForm: "Fill out a form at ",
    omnipodURL: "www.omnipod.com/setup ",
    callBack: "to get a call back.",
    health: "Call your healthcare provider.",
    ok: "OK"
  },
};

export { strings };
