import { flagNames } from '../store/reducers/featureFlags/featureFlagsSlice';

export type SensorType = 'Dexcom G6' | 'Dexcom G7' | 'FreeStyle Libre 2 Plus' | 'No Sensor';
interface Sensor {
  [p: string]: SensorType;
}
export const sensors: Sensor = {
  G6: 'Dexcom G6',
  G7: 'Dexcom G7',
  L2: 'FreeStyle Libre 2 Plus',
  NO_SENSOR: 'No Sensor',
};

export const getActiveSensorList = (activeFlags: string[]) => {
  const activeSensors: SensorType[] = [sensors.G6];

  if (activeFlags.includes(flagNames.SHOW_G7)) {
    activeSensors.push(sensors.G7);
  }

  if (activeFlags.includes(flagNames.SHOW_L2)) {
    activeSensors.push(sensors.L2);
  }

  // We want "No Sensor" to be the last item in the list always.
  activeSensors.push(sensors.NO_SENSOR);

  return activeSensors;
};