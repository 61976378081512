import { FormattedMessage } from 'react-intl';
import { historyEventBasalProgramMessages } from './HistoryEventBasalProgram.messages';

interface HistoryEventBasalProgramProps {
  time: string;
  status: string;
  programName: string;
  totalInsulin: string;
}
export const HistoryEventBasalProgram: React.FC<
  HistoryEventBasalProgramProps
> = ({ time, status, programName, totalInsulin }) => {
  return (
    <div className="flex justify-between items-center w-full py-1 px-2 border-b border-b-ios-gray-7">
      <div>
        <div className="flex">
          <p className="text-regular mr-0.5">
            <FormattedMessage {...historyEventBasalProgramMessages.title} />
          </p>
          <p>{status}</p>
        </div>
        <div className="flex text-small">
          <p className="mr-0.5 text-ios-secondaryGray opacity-60">{`${time} •`}</p>
          <p className="text-modes-manual">{`“${programName}“`}</p>
        </div>
      </div>
      <div>
        <p className="text-regular">{totalInsulin}</p>
      </div>
    </div>
  );
};
