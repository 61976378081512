import { FormattedMessage } from 'react-intl';
import { historyEventGlucoseMessages } from './HistoryEventGlucose.messages';

interface HistoryEventGlucoseProps {
  time: string;
  mgValue: string;
}
export const HistoryEventGlucose: React.FC<HistoryEventGlucoseProps> = ({
  time,
  mgValue,
}) => {
  return (
    <div className="py-1 px-2 border-b border-b-ios-gray-7">
      <p className="text-regular">
        <FormattedMessage {...historyEventGlucoseMessages.title} />
      </p>
      <div className="flex text-small">
        <p className="mr-0.5  text-ios-secondaryGray opacity-60">{`${time} • `}</p>
        <p>{mgValue}</p>
      </div>
    </div>
  );
};
