import {
  View,
  Text,
  StyleSheet,
  GestureResponderEvent,
} from 'react-native';
import { useIntl } from 'react-intl';
import { messages } from './CheckPodCompatibility.messages';
import theme from 'mobile/latest/theme';
import { ReactComponent as PodCompatibility } from '../../../../../assets/G7/dexcomG7_pod_compatibility.svg';
import { Button } from 'mobile/latest/components/atoms';

const styles = StyleSheet.create({
  title: {
    marginTop: 5,
  },
  paragraph: {
    marginTop: 10,
  },
  buttonsContainer: {
    marginTop: 30,
  },
  applicatorContainer: {
    padding: 25,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor: theme.colors.grayScale.gray500,
    borderBottomWidth: 2,
    gap: 20,
  },
});

const CheckPodCompatibility = (props: {
  continueButtonOnPressHandler: (event: GestureResponderEvent) => void;
}) => {
  const { continueButtonOnPressHandler } = props;
  const intl = useIntl();

  return (
    <>
      <Text style={[theme.fonts.h4, styles.title]}>
        {intl.formatMessage(messages.title)}
      </Text>
      <View style={styles.applicatorContainer}>
        <PodCompatibility width={180} height={180} />
      </View>
      <Text style={[theme.fonts.body1,styles.paragraph]}>
        {intl.formatMessage(messages.paragraph)}
      </Text>
      <View style={styles.buttonsContainer}>
        <Button variant="primary" onPress={continueButtonOnPressHandler}>
          <Text>{intl.formatMessage(messages.confirmButton)}</Text>
        </Button>
      </View>
    </>
  );
};

export default CheckPodCompatibility;
