import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  GestureResponderEvent,
} from 'react-native';
import { strings } from './ConfirmSerialNumberContent';
import theme from 'mobile/latest/theme';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';

const styles = StyleSheet.create({
  title: {
    marginTop: 5,
  },
  paragraph: {
    marginTop: 45,
    textAlign: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 20,
    color: '#000',
    marginTop: 40,
    marginBottom: 10,
  },
  buttonText: {
    color: theme.colors.purple,
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: '500',
  },
});

const ConfirmSerialNumber = (props: {
  cancelButtonOnPressHandler: (event: GestureResponderEvent) => void;
  confirmButtonOnPressHandler: (event: GestureResponderEvent) => void;
}) => {
  const { cancelButtonOnPressHandler, confirmButtonOnPressHandler } = props;
  const { languageContent } = useLanguageContent(strings);
  return (
    <>
      <Text style={[theme.fonts.h5, styles.title]}>
        {languageContent.confirmSerialNumber}
      </Text>
      <Text style={[theme.fonts.h5, styles.paragraph]}>
        {languageContent.serialNumber}
      </Text>
      <View style={styles.buttonsContainer}>
        <TouchableOpacity onPress={cancelButtonOnPressHandler}>
          <Text style={styles.buttonText}>{languageContent.cancel}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={confirmButtonOnPressHandler}>
          <Text style={styles.buttonText}>{languageContent.confirm}</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

export default ConfirmSerialNumber;
