let strings = {
  "en-US": {
    header:
      "The maximum amount of insulin that you can request in a single bolus.",
    maxBolus: "Max Bolus",
    range: "(%1 to 30 U/hr)",
    cancel: "Cancel",
    save: "Save",
    modalTitle: "Feature unavailable in Simulator.",
    ok: "OK",
    u: "U"
  },
  "en-GB": {
    header:
      "The maximum amount of insulin that you can request in a single bolus.",
    maxBolus: "Max Bolus",
    range: "(%1 to 30 U/hr)",
    cancel: "Cancel",
    save: "Save",
    modalTitle: "Feature unavailable in Simulator.",
    ok: "OK",
    u: "U"
  },
  "de-DE": {
    header:
      "Die maximale Insulinmenge, die mit einem einzelnen Bolus angefordert werden kann.",
    maxBolus: "Max. Bolus",
    range: "(%1 bis 30 E/Std.)",
    cancel: "Abbr.",
    save: "Speichern",
    modalTitle: "Funktion im Simulator nicht verfügbar.",
    ok: "OK",
    u: "E"
  },
  "es-US": {
    header:
      "La cantidad máxima de insulina que puede solicitar en un solo bolo.",
    maxBolus: "Bolo máximo",
    range: "(%1 a 30 U)",
    cancel: "Cancelar",
    save: "Guardar",
    modalTitle: "La función no está disponible en el simulador.",
    ok: "Aceptar",
    u: "U"
  },
  "fr-FR": {
    header:
      "Quantité maximale d'insuline que vous pouvez demander en un seul bolus.",
    maxBolus: "Bolus max",
    range: "(de %1 à 30 U/h)",
    cancel: "Annuler",
    save: "Enregistrer",
    modalTitle: "Fonction disponible avec le simulateur.",
    ok: "OK",
    u: "U"
  },
  "nl-NL": {
    header:
      "De maximale hoeveelheid insuline die u in één bolus kunt toedienen.",
    maxBolus: "Maximale bolus",
    range: "(%1 tot 30 E/u)",
    cancel: "Annuleren",
    save: "Opslaan",
    modalTitle: "Functie niet beschikbaar in simulator.",
    ok: "OK",
    u: "E"
  }
};

export { strings };
