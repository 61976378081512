import React, { useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  attentionMessages,
  communicatingWithPodMessages,
  pauseInsulinDeliveryMessages,
} from './PauseStartInsulinModals.message';
import { Modal } from '../../Modal';
import { ConfirmationModalProps } from '../types';
import { Spinner } from '../../Spinner';

const MODAL_TIMEOUT = 2000;

interface CommunicatingWithPodConfirmationModalProps {
  onClose?: () => void;
}

export const PauseInsulinDeliveryModal: React.FC<ConfirmationModalProps> = ({
  onConfirm,
  onReject,
}) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage(
        {
          ...pauseInsulinDeliveryMessages.title,
        },
        {
          separator: <br />,
        }
      )}
      rightButtonText={intl.formatMessage(
        pauseInsulinDeliveryMessages.yesButton
      )}
      rightButtonOnClick={onConfirm}
      leftButtonOnClick={onReject}
      leftButtonText={intl.formatMessage(pauseInsulinDeliveryMessages.noButton)}
    >
      <div className=" text-center">
        <FormattedMessage
          {...pauseInsulinDeliveryMessages.text}
          values={{ separator: <br /> }}
        />
      </div>
    </Modal>
  );
};

export const AttentionDeliveryModal: React.FC<ConfirmationModalProps> = ({
  onConfirm,
  onReject,
}) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage(attentionMessages.title)}
      rightButtonText={intl.formatMessage(attentionMessages.okButton)}
      rightButtonOnClick={onConfirm}
      leftButtonOnClick={onReject}
      leftButtonText={intl.formatMessage(attentionMessages.cancelButton)}
    >
      <div>
        <FormattedMessage
          {...attentionMessages.text}
          values={{ separator: <br /> }}
        />
      </div>
    </Modal>
  );
};

export const CommunicatingWithPodConfirmationModal: React.FC<
  CommunicatingWithPodConfirmationModalProps
> = ({ onClose }) => {
  const intl = useIntl();

  useEffect(() => {
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, MODAL_TIMEOUT);
  }, []);

  return (
    <Modal title={intl.formatMessage(communicatingWithPodMessages.title)}>
      <Spinner />
      <div className="text-center">
        <FormattedMessage {...communicatingWithPodMessages.text} />
      </div>
    </Modal>
  );
};
