import { useNavigation, useRoute } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { View, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { asyncTimeout } from "../../../helpers";
import useLanguageContent from "../../../hooks/useLanguageContent";
import { basalActions } from "../../../../../web/store/reducers/basal";
import {
  BasalState,
  emptyExpertBasalProgram
} from "../../../../../web/store/reducers/basal/reducer";
import { RootState } from "../../../../../web/store/store";
import theme from "../../../theme";
import { ConfirmationModal } from "../../molecules";
import { strings } from "./ExpertBasalConfirmationModalContent";

interface Props {
  isVisible: boolean;
  closeModal: () => void;
}

const ExpertBasalConfirmationModal: React.FC<Props> = ({
  isVisible,
  closeModal
}) => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );
  const segments = basalState.newExpertBasalProgram.segments;

  const route = useRoute<any>();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);

  const restoreProgramFromCopy = () => {
    if (basalState.newExpertBasalProgram.isComplete) {
      if (
        route.name === "ExpertProgramSegmentsReview" ||
        route.name === "ExpertProgramSegments"
      ) {
        dispatch(
          basalActions.basalUpdate(
            {
              newExpertBasalProgram: basalState.copyOfNewExpertBasalProgram
            },
            basalState
          )
        );
      } else {
        dispatch(
          basalActions.basalUpdate(
            {
              newExpertBasalProgram: basalState.copyOfNewExpertBasalProgram,
              copyOfNewExpertBasalProgram: emptyExpertBasalProgram
            },
            basalState
          )
        );
      }
    }
  };

  const trimSegment = () => {
    if (
      basalState.newExpertBasalProgram.segments.length === 0 &&
      route.params
    ) {
      dispatch(
        basalActions.basalUpdate(
          {
            newExpertBasalProgram: {
              ...basalState.newExpertBasalProgram,
              segments: basalState.copyOfNewExpertBasalProgram.segments,
              hasUpdate: false
            }
          },
          basalState
        )
      );
    } else {
      dispatch(
        basalActions.basalUpdate(
          {
            newExpertBasalProgram: {
              ...basalState.newExpertBasalProgram,
              segments: segments.slice(
                0,
                basalState.newExpertBasalProgram.segments.length - 1
              ),
              hasUpdate: false
            }
          },
          basalState
        )
      );
    }
  };

  const onConfirm = async () => {
    closeModal();
    if (
      (route.params && route.params.edit) ||
      (route.params && route.params.editEndTime)
    ) {
      restoreProgramFromCopy();
    } else {
      trimSegment();
    }

    await asyncTimeout(500);
    navigation.goBack();
  };

  return (
    <ConfirmationModal
      isVisible={isVisible}
      title={languageContent.goBack}
      message={
        <View>
          <Text style={[theme.fonts.body1]}>{languageContent.loseChanges}</Text>
        </View>
      }
      confirmText={languageContent.yes}
      dismissText={languageContent.cancel}
      onDismiss={closeModal}
      onConfirm={onConfirm}
    />
  );
};

export default ExpertBasalConfirmationModal;
