import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const PodIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });

  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={classes} fillRule="evenodd" clipRule="evenodd" d="M8.604 0.537598H18.216C21.8425 0.537598 24.7824 3.47748 24.7824 7.104V15.555C24.7824 21.8358 19.6908 26.9274 13.41 26.9274C7.12919 26.9274 2.0376 21.8358 2.0376 15.555V7.104C2.0376 3.47748 4.97748 0.537598 8.604 0.537598ZM22.32 7.104C22.32 4.83742 20.4826 3 18.216 3H8.604C6.33742 3 4.5 4.83742 4.5 7.104V15.555C4.5 20.4759 8.48914 24.465 13.41 24.465C18.3309 24.465 22.32 20.4759 22.32 15.555V7.104ZM8.982 5.025C7.84871 5.025 6.93 5.94371 6.93 7.077V15.555C6.93 19.1338 9.83119 22.035 13.41 22.035C16.9888 22.035 19.89 19.1338 19.89 15.555V7.077C19.89 5.94371 18.9713 5.025 17.838 5.025H8.982Z" />
    </svg>
  );
}
