import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { ReactComponent as Edit } from "../../../../../../../../assets/UI_Edit.svg";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { ListHeader } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./InsulinToCarbSettingsScreenContent";

const InsulinToCarbSettingsScreen: React.FC = () => {
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={[styles.container]}>
      <View>
        <Text
          style={[
            theme.fonts.body2,
            { marginVertical: 24, paddingHorizontal: 16 }
          ]}>
          {languageContent.reviewTime}
        </Text>
        <ListHeader numOfSegments="3" />
        <View style={[styles.segmentRow]}>
          <View>
            <Text>
              <Text style={[theme.fonts.body2, { fontWeight: "500" }]}>
                {`${languageContent.segmentOne} `}
              </Text>
              <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
                12:00 am - 5:00 am
              </Text>
            </Text>
            <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
              <Text style={[{ fontWeight: "500" }]}>
                {languageContent.insulinCovers}
              </Text>
            </Text>
            <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
              {languageContent.fifteenOfCarbs}
            </Text>
          </View>
          <View style={[{ justifyContent: "center" }]}>
            <TouchableOpacity
              onPress={() => navigate("BolusSettingsInsulinToCarbSegmentEdit")}>
              <Edit />
            </TouchableOpacity>
          </View>
        </View>
        <View style={[styles.break]} />
        <View style={[styles.segmentRow]}>
          <View>
            <Text>
              <Text style={[theme.fonts.body2, { fontWeight: "500" }]}>
                {`${languageContent.segmentTwo} `}
              </Text>
              <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
                5:00 am - 9:00 am
              </Text>
            </Text>
            <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
              <Text style={[{ fontWeight: "500" }]}>
                {languageContent.insulinCovers}
              </Text>
            </Text>
            <Text style={[theme.fonts.body1, { color: theme.colors.blue }]}>
              {languageContent.tenOfCarbs}
            </Text>
          </View>
          <View style={[{ justifyContent: "center" }]}>
            <Edit />
          </View>
        </View>
        <View style={[styles.break]} />
        <View style={[styles.segmentRow]}>
          <View>
            <Text>
              <Text style={[theme.fonts.body2, { fontWeight: "500" }]}>
                {`${languageContent.segmentThree} `}
              </Text>
              <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
                9:00 am - 12:00 am
              </Text>
            </Text>
            <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
              <Text style={[{ fontWeight: "500" }]}>
                {languageContent.insulinCovers}
              </Text>
            </Text>
            <Text style={[theme.fonts.body2, { color: theme.colors.blue }]}>
              {languageContent.thirteenOfCarbs}
            </Text>
          </View>
          <View style={[{ justifyContent: "center" }]}>
            <Edit />
          </View>
        </View>
        <View style={[styles.break]} />
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.save}
        rightAction={() => navigate("BolusSettingsMain")}
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "white"
  },
  segmentHeader: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.textInputBorder,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    backgroundColor: "#EDF2F6"
  },
  segmentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    marginVertical: 16
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  }
});

export default InsulinToCarbSettingsScreen;
