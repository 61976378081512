import classNames from 'classnames';

interface SeparatorRowProp {
  title: string;
  bgColor?: string;
}

export const SeparatorRow: React.FC<SeparatorRowProp> = ({
  title,
  bgColor = '',
}) => {
  const classes = classNames(
    `text-modals w-full pt-2 pb-1  border-b border-b-ios-gray-7 ${bgColor}`
  );
  return (
    <div className={classes}>
      <p className="ml-2 text-modals text-ios-secondaryGray opacity-60 tracking-[-0.24px]">
        {title}
      </p>
    </div>
  );
};
