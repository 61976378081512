import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { ReactComponent as CloseEyeSVG } from "../../../../../../../../assets/eyeClosed.svg";
import { ReactComponent as OpenEyeSVG } from "../../../../../../../../assets/eyeOpened.svg";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { Button } from "../../../../../atoms";
import { strings } from "./SignInScreenContent";

const SignInScreen: React.FC = () => {
  const [omnipodID, setOmnipodID] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);

  const handleIDPress = () => {
    setOmnipodID("jborges");
    setIsFormValid(Boolean(password));
  };

  const handlePasswordPress = () => {
    setPassword("Simulator1234");
    setIsFormValid(Boolean(omnipodID));
  };

  const togglePasswordVisibility = () => {
    if (password === "Simulator1234") {
      setPasswordVisible(!passwordVisible);
    }
  };

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View
      style={[
        theme.layout.wrapper,
        { width: "100%", backgroundColor: "white" }
      ]}>
      <View style={[styles.innerWrapper, { width: "100%" }]}>
        <Text
          style={[
            theme.fonts.h3,
            { margin: 16, textAlign: "center", fontWeight: "200" }
          ]}>
          {languageContent.signIn}
        </Text>
        <Text
          style={[
            theme.fonts.text400,
            { fontWeight: "300", textAlign: "center" }
          ]}>
          {languageContent.enterId}
        </Text>

        <TouchableOpacity onPress={handleIDPress}>
          <Text
            style={[
              styles.textInput,
              { borderColor: omnipodID ? "#5B34B9" : "#DEE4EA" }
            ]}>
            {omnipodID ? omnipodID : languageContent.omnipodID}
          </Text>
        </TouchableOpacity>

        <TouchableOpacity onPress={handlePasswordPress}>
          <View
            style={[
              styles.textInput,
              { borderColor: password ? "#5B34B9" : "#DEE4EA" }
            ]}>
            <Text style={{ fontSize: 16 }}>
              {passwordVisible || password
                ? passwordVisible
                  ? password
                  : "*".repeat(password.length)
                : languageContent.password}
            </Text>
            <TouchableOpacity
              onPress={togglePasswordVisibility}
              style={styles.passwordIcon}>
              {passwordVisible ? (
                <CloseEyeSVG height={20} width={20} />
              ) : (
                <OpenEyeSVG height={20} width={20} />
              )}
            </TouchableOpacity>
          </View>
        </TouchableOpacity>

        <View
          style={{ paddingBottom: 16, paddingHorizontal: 8, marginTop: 12 }}>
          <Button
            variant={isFormValid ? "primary" : "cancel"}
            disabled={!isFormValid}
            style={{ elevation: 6 }}
            onPress={() => {
              navigate("Training");
            }}>
            {languageContent.signIn}
          </Button>

          <Text
            style={[
              theme.fonts.text600,
              { fontWeight: "300", textAlign: "center", marginTop: 12 }
            ]}>
            {languageContent.noAccount}
          </Text>
          <TouchableOpacity onPress={() => navigate("Help")}>
            <Text
              style={[
                theme.fonts.text600,
                {
                  fontWeight: "600",
                  textAlign: "center",
                  marginTop: 12,
                  color: "#5B34B9"
                }
              ]}>
              {languageContent.needHelp}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const height = Dimensions.get("window").height;

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 16,
    marginTop: height < 800 ? height / 40 : height / 20
  },
  passwordIcon: {
    position: "absolute",
    top: 15,
    right: 10
  },
  textInput: {
    margin: 8,
    fontSize: 16,
    padding: 16,
    fontWeight: "400",
    borderWidth: 2,
    textAlign: "left",
    borderRadius: 6,
    backgroundColor: theme.colors.textInputBackgroundColor
  }
});

export default SignInScreen;
