import { useRoute } from "@react-navigation/native"
import { isEqual } from "lodash"
import React, { useCallback } from "react"
import {
  BackHandler,
  Dimensions,
  Platform,
  PlatformIOSStatic,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native"
import { useDispatch, useSelector } from "react-redux"
import IconSmallHazard from "../../../../../../../../assets/Icon_Small_Hazard.svg"
import BASAL_RATES_DATA_WITH_ZERO from "../../../../../../data/basalRatesDataWithZero"
import forbiddenTimes from "../../../../../../data/forbiddenTimesToShowTwelve"
import findExpertBasalStartTimeIndex from "../../../../../../helpers/findExpertBasalStartTimeIndex"
import findExpertBasalStartTimeLabel from "../../../../../../helpers/findExpertBasalStartTimeLabel"
import getAdjustedDataForBasalRatePicker from "../../../../../../helpers/getAdjustedDataForBasalRatePicker"
import getAdjustedDataForEndTimePicker from "../../../../../../helpers/getAdjustedDataForEndTimePicker"
import getBasalRateAdornmentColor from "../../../../../../helpers/getBasalRateAdornmentColor"
import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat"
import replaceWithValues from "../../../../../../helpers/replaceWithValues"
import useExpertHardwareBackAction from "../../../../../../hooks/useExpertHardwareBackAction"
import useLanguageContent from "../../../../../../hooks/useLanguageContent"
import { basalActions } from "../../../../../../../../web/store/reducers/basal"
import {
  BasalState,
  emptyExpertBasalProgram,
} from "../../../../../../../../web/store/reducers/basal/reducer"
import { LangState } from "../../../../../../../../web/store/reducers/language"
import { UserState } from "../../../../../../../../web/store/reducers/user"
import { RootState } from "../../../../../../../../web/store/store"
import theme from "../../../../../../theme"
import PickerItem from "../../../../../../types/pickerItem"
import { GraphVerticalLine } from "../../../../../atoms"
import {
  BasalSegmentChartItem,
  BottomInnerNav,
  ConfirmationModal,
  SegmentTopBar,
  WarningMessageBar,
} from "../../../../../molecules"
import {
  CurrentBasalSegment,
  PlaceholderBasalSegment,
  StartAndEndTimeSection,
} from "../../../../../organisms"
import CustomPicker from "../../../../../organisms/customPicker"
import { strings } from "./ExpertNewBasalSegmentScreenContent"
interface Props {
  navigation: any
}

const defaultBasalRate = {
  inputLabel: "",
  label: "",
  value: 0,
  key: "INIT",
  index: 600,
}

const { width } = Dimensions.get("window")
const platform = Platform as PlatformIOSStatic

const MAX_HEIGHT = 120

const getPickerHorizontalPlacement = () => {
  if (platform.isPad) {
    if (width > 1000) {
      return -width / 6.3
    } else {
      return -width / 5.3
    }
  } else if (Platform.OS === "android") {
    if (width > 395) {
      return -width / 4.8
    } else if (width > 380) {
      return -width / 3.93
    } else {
      return -width / 3.6
    }
  } else if (width > 395) {
    return -width / 4.28
  } else if (width > 380) {
    return -width / 3.93
  } else {
    return -width / 3.78
  }
}

const getPickerVerticalPlacement = () => {
  if (platform.isPad) {
    if (width > 1000) {
      return "-15%"
    } else {
      return "-19%"
    }
  } else if (Platform.OS === "android") {
    if (width > 395) {
      return "-34.5%"
    } else if (width > 380) {
      return "-42.5%"
    } else {
      return "-45.5%"
    }
  } else if (width > 395) {
    return "-39%"
  } else if (width > 380) {
    return "-42.5%"
  } else {
    return "-44%"
  }
}

const pickerHorizontalMargin = getPickerHorizontalPlacement()
const pickerVerticalMargin = getPickerVerticalPlacement()

const ExpertNewBasalSegmentScreen: React.FC<Props> = ({ navigation }) => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual,
  )
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual,
  )
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual,
  )

  const [cancelModal, setCancelModal] = React.useState<boolean>(false)
  const [segmentIndex, setSegmentIndex] = React.useState<number>(
    basalState.newExpertBasalProgram.segments.length + 1,
  )
  const [initialRender, setInitialRender] = React.useState<boolean>(true)
  // time state
  const { languageContent } = useLanguageContent(strings)
  const [startTime, setStartTime] = React.useState("12:00 am")
  const [endTime, setEndTime] = React.useState({
    label: "",
    helpText: languageContent.night,
  })

  // Show Pickers State
  const [showEndTimePicker, setShowEndTimePicker] =
    React.useState<boolean>(false)
  const [showBasalRatePicker, setShowBasalRatePicker] =
    React.useState<boolean>(false)

  const [borderColor, setBorderColor] = React.useState<string>(
    theme.colors.textInputBorder,
  )

  const [adjustedMaxBasalData, setAdjustedMaxBasalData] =
    React.useState<any[]>()

  // 12pm on graph
  const [showTwelve, setShowTwelve] = React.useState<boolean>(true)

  //basal state
  const [basalRate, setBasalRate] = React.useState<PickerItem>(defaultBasalRate)
  const [endTimeIndex, setEndTimeIndex] = React.useState<number>(0)

  const [maxBasalRateCalc, setMaxBasalRateCalc] = React.useState<any>(3)

  // EndTime Picker State
  const [adjustedEndTimeData, setAdjustedEndTimeData] = React.useState<any[]>()

  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState<boolean>(false)

  const segmentsArrLength = basalState.newExpertBasalProgram.segments.length
  const route = useRoute<any>()
  const dispatch = useDispatch()
  const backAction = useExpertHardwareBackAction(navigation, "Basal")

  // set max value for FTS maxBasalRate. First time maxRateForBasalRatePicker comes from ExpertProgramEntryScreen.tsx
  // every other time maxRateForBasalRatePicker comes from this screen route.params
  const [maxRateForBasalRatePicker] = React.useState<string>(
    basalState.maxBasal ? basalState.maxBasal : "30",
  )

  const basalRateLowRange = (0.05).toLocaleString(langState.locale, {
    minimumFractionDigits: 2,
  })
  const basalRateHighRange = basalState.maxBasal
    ? Number(basalState.maxBasal).toLocaleString(langState.locale, {
        minimumFractionDigits: 2,
      })
    : "30"
  const contentVariables = [basalRateLowRange, basalRateHighRange]

  const toggleBasalRatePicker = () => {
    if (borderColor === theme.colors.blue) {
      setBorderColor(theme.colors.textInputBorder)
      setShowBasalRatePicker(false)
    } else {
      setBorderColor(theme.colors.blue)
      setShowBasalRatePicker(true)
      setShowEndTimePicker(false)
    }
  }

  const toggleEndTimePicker = () => {
    setShowEndTimePicker((showEndTimePicker) => !showEndTimePicker)
    if (showBasalRatePicker) {
      toggleBasalRatePicker()
    }
  }

  const onBasalRateConfirm = (value: string | null) => {
    const selected = adjustedMaxBasalData?.find(
      (adjustedMaxBasalData: any) => adjustedMaxBasalData.label === value,
    )

    const maxRates = Math.max.apply(
      Math,
      basalState.newExpertBasalProgram.segments.map((segment) => {
        return segment.basalRate
      }),
    )

    const maxRate = Math.max.apply(Math, [maxRates, Number(selected.label)])

    if (maxRate < 1) {
      setMaxBasalRateCalc(1)
    } else if (maxRate >= 1 && maxRate < 2) {
      setMaxBasalRateCalc(2)
    } else {
      setMaxBasalRateCalc(Math.floor(maxRate) + 1)
    }
    setBasalRate(selected)

    if (!basalState.newExpertBasalProgram.hasUpdate) {
      dispatch(
        basalActions.basalUpdate(
          {
            newExpertBasalProgram: {
              ...basalState.newExpertBasalProgram,
              hasUpdate: true,
            },
          },
          basalState,
        ),
      )
    }

    toggleBasalRatePicker()
  }

  const onEndTimeConfirm = (value: string | null) => {
    setShowEndTimePicker(false)

    const selected = adjustedEndTimeData?.find(
      (adjustedEndTimeData) => adjustedEndTimeData.label === value,
    )

    setEndTime({
      label: selected.label.toUpperCase(),
      helpText: selected.helpText,
    })
    setEndTimeIndex(selected.index)

    hideshowTwelve(selected.label.toLowerCase())

    if (!basalState.newExpertBasalProgram.hasUpdate) {
      dispatch(
        basalActions.basalUpdate(
          {
            newExpertBasalProgram: {
              ...basalState.newExpertBasalProgram,
              hasUpdate: true,
            },
          },
          basalState,
        ),
      )
    }
  }

  const onBasalRateDismiss = () => {
    setShowBasalRatePicker(false)
    setBorderColor(theme.colors.textInputBorder)
  }
  const onEndTimeDismiss = () => {
    setShowEndTimePicker(false)
  }

  const setPickerDynamicStartTime = React.useCallback(() => {
    let adjustedStartTime = ""
    if (
      startTime &&
      startTime.toLowerCase() === "12:00 am" &&
      segmentsArrLength === 0
    ) {
      adjustedStartTime = "12:30 am"
    } else if (segmentsArrLength === 0) {
      adjustedStartTime = "12:30 am"
      setStartTime("12:00 am")
    } else {
      // findExpertBasalStartTimeIndex: find the index of previous segment end time in the data set of hours
      // findExpertBasalStartTimeLabel: find 30 minutes past where the index is, use that as the base value for the picker
      adjustedStartTime = findExpertBasalStartTimeLabel(
        findExpertBasalStartTimeIndex(
          basalState.newExpertBasalProgram.segments[
            segmentsArrLength - 1
          ].end.toUpperCase(),
        ),
      )
    }

    setAdjustedEndTimeData(
      getAdjustedDataForEndTimePicker(adjustedStartTime.toUpperCase()),
    )
  }, [basalState.newExpertBasalProgram.segments, segmentsArrLength, startTime])

  const setPickerDynamicBasalRate = React.useCallback(() => {
    setAdjustedMaxBasalData(
      getAdjustedDataForBasalRatePicker(
        maxRateForBasalRatePicker.toString(),
        BASAL_RATES_DATA_WITH_ZERO,
      ),
    )
  }, [maxRateForBasalRatePicker])

  const handleLeftNavigationAction = () => {
    setCancelModal(true)
  }

  const handleRightNavigationAction = () => {
    if (endTime.label === "" || basalRate?.label === "") {
      return
    }

    dispatch(
      basalActions.basalUpdate(
        {
          newExpertBasalProgram: {
            ...basalState.newExpertBasalProgram,
            hasUpdate: false,
            segments: [
              ...basalState.newExpertBasalProgram.segments,
              {
                start: startTime,
                end: endTime.label.toLowerCase(),
                endHelpText: endTime.helpText,
                startTimeHelpText: endTime.helpText,
                basalRate: basalRate && Number(basalRate.label),
              },
            ],
          },
        },
        basalState,
      ),
    )
    if (endTime.label && endTime.label === "12:00 AM") {
      return setShowConfirmationModal(true)
    }
    return navigation.push(
      "ExpertProgramSegments",
      !userState.podConfigured &&
        !userState.hasTXId && {
          maxRateForBasalRatePicker,
        },
    )
  }

  const hideshowTwelve = useCallback(
    (time?: string) => {
      setShowTwelve(true)
      // don't show 12pm when updating picker and too close to forbidden times
      if (time && forbiddenTimes.includes(time)) {
        setShowTwelve(false)
        // don't show 12pm when arriving from edit and too close to forbidden times
      } else if (forbiddenTimes.includes(endTime.label.toLowerCase())) {
        setShowTwelve(false)
      } else {
        // after selecting picker time, don't show 12pm on the next screen if too close to forbidden times
        basalState.newExpertBasalProgram.segments.map((segment) => {
          if (forbiddenTimes.includes(segment.end)) {
            setShowTwelve(false)
          }
        })
      }
    },
    [basalState.newExpertBasalProgram.segments, endTime.label],
  )

  React.useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", backAction)
    setPickerDynamicStartTime()
    setPickerDynamicBasalRate()

    hideshowTwelve()

    setSegmentIndex(segmentsArrLength + 1)

    const max = Math.max.apply(
      Math,
      basalState.newExpertBasalProgram.segments.map((segment) => {
        return segment.basalRate
      }),
    )

    if (basalRate && Number(basalRate.label) > max) {
      if (max === -Infinity || max === Infinity) {
        setMaxBasalRateCalc(3)
      }
      setMaxBasalRateCalc(Math.floor(Number(basalRate.label)) + 1)
    } else {
      setMaxBasalRateCalc(Math.floor(max) + 1)
    }

    if (segmentsArrLength > 0 && initialRender) {
      setStartTime(
        basalState.newExpertBasalProgram.segments[segmentsArrLength - 1].end,
      )
    }

    // helptext and endtime
    if (route.params && route.params.editEndTime) {
      if (initialRender) {
        let helpText
        if (segmentsArrLength === 0 && route.params.editEndHelpText) {
          helpText = route.params.editEndHelpText
        } else {
          helpText =
            basalState.newExpertBasalProgram.segments[segmentsArrLength - 1]
              .startTimeHelpText!
        }
        setEndTime({
          label: route.params.editEndTime,
          helpText,
        })
        setEndTimeIndex(route.params.editIndex)
      }

      // set basalRate when editing program
      if (initialRender) {
        if (route.params.editBasalRate === 0) {
          const currentBasalRate = BASAL_RATES_DATA_WITH_ZERO.find(
            (BASAL_RATES_DATA_WITH_ZERO) =>
              BASAL_RATES_DATA_WITH_ZERO.label === "0",
          )
          setBasalRate(currentBasalRate!)
        } else {
          // pad values to match what is being search for
          let splitRouteParams
          let newPaddedBasalRate = ""
          //  check if value is whole number
          if (String(route.params.editBasalRate).includes(".")) {
            splitRouteParams = String(route.params.editBasalRate).split(".")
            //  check that the number after the decimal is single value
            if (splitRouteParams[1].length === 1) {
              newPaddedBasalRate = `${splitRouteParams[0]}.${splitRouteParams[1]}0`
            } else {
              newPaddedBasalRate = String(route.params.editBasalRate)
            }
          } else {
            newPaddedBasalRate = String(route.params.editBasalRate)
          }

          const currentBasalRate = BASAL_RATES_DATA_WITH_ZERO.find(
            (BASAL_RATES_DATA_WITH_ZERO) =>
              BASAL_RATES_DATA_WITH_ZERO.label === newPaddedBasalRate,
          )
          setBasalRate(currentBasalRate!)
        }
      }
    }
    setInitialRender(false)

    return () =>
      BackHandler.removeEventListener("hardwareBackPress", backAction)
  }, [
    initialRender,
    basalRate,
    basalState.newExpertBasalProgram.isComplete,
    basalState.newExpertBasalProgram.segments,
    hideshowTwelve,
    route.params,
    segmentsArrLength,
    setPickerDynamicStartTime,
    setPickerDynamicBasalRate,
  ])

  React.useEffect(
    () =>
      navigation.addListener("beforeRemove", () => {
        dispatch(
          basalActions.basalUpdate(
            {
              newExpertBasalProgram: {
                ...basalState.newExpertBasalProgram,
                segments: basalState.newExpertBasalProgram.segments.slice(
                  0,
                  basalState.newExpertBasalProgram.segments.length - 1,
                ),
              },
            },
            basalState,
          ),
        )
      }),
    [navigation],
  )

  return (
    <>
      <ScrollView>
        {basalState.insulinPaused ? (
          <WarningMessageBar insulinDelivery />
        ) : null}
        <SegmentTopBar
          programName={basalState.newExpertBasalProgram?.programName}
          segmentNum={segmentIndex.toString()}
          showRightUnits
        />
        <View style={[styles.break]} />
        <View style={[styles.graphView, { height: MAX_HEIGHT + 32 }]}>
          {/* Show all segments on graph */}
          {segmentsArrLength > 0 && maxBasalRateCalc ? (
            basalState.newExpertBasalProgram.segments.map(
              (segment: any, index: number) => (
                <CurrentBasalSegment
                  key={index}
                  startTime={startTime}
                  endTime={endTime.label}
                  segmentsArrLength={segmentsArrLength}
                  maxHeight={MAX_HEIGHT}
                  segment={segment}
                  maxBasalRateCalc={maxBasalRateCalc}
                  index={index}
                  program={basalState.newExpertBasalProgram}
                  segmentEndTime={
                    basalState.newExpertBasalProgram.segments[index].end.split(
                      "",
                    )[0]
                  }
                />
              ),
            )
          ) : (
            <>
              {/**
               * FIRST ITEM
               */}
              <GraphVerticalLine maxHeight={MAX_HEIGHT} marginLeftNumber={-1} />
              <BasalSegmentChartItem
                hideBottomBorder
                segment={{
                  start: startTime,
                  end: endTime.label === "" ? "12:00 am" : endTime.label,
                  basalRate:
                    basalRate && basalRate?.label === ""
                      ? 0
                      : Number(basalRate.label),
                }}
                maxBasalRateCalc={maxBasalRateCalc}
                maxHeight={MAX_HEIGHT}
                index={segmentsArrLength}
                program={basalState.newExpertBasalProgram}
                basalRateKey={basalRate.key}
              />
            </>
          )}
          {/* newly created segment after the first segment */}
          {endTime.label !== "" &&
            basalRate &&
            segmentsArrLength !== 0 &&
            startTime !== "12:00 am" && (
              <BasalSegmentChartItem
                hideBottomBorder
                segment={{
                  start: startTime,
                  end: endTime.label,
                  basalRate: basalRate && Number(basalRate.label),
                }}
                maxBasalRateCalc={maxBasalRateCalc}
                maxHeight={MAX_HEIGHT}
                index={segmentsArrLength + 2}
                program={basalState.newExpertBasalProgram}
                basalRateKey={basalRate?.key}
              />
            )}
          {/* shows noon on the timeline, disappears when end time is too close to cleanly display */}
          {showTwelve ? (
            <Text style={styles.showTwelveText}>
              {langState.is24Hour ? "12:00" : "12pm"}
            </Text>
          ) : null}
          {/* placeholder segment for new segment before selecting endtime or basal rate */}
          {endTime.label === "" &&
            basalRate.label === "" &&
            segmentsArrLength !== 0 && (
              <BasalSegmentChartItem
                hideBottomBorder
                segment={{
                  start: startTime,
                  end: "12:00 am",
                  basalRate: 0,
                }}
                maxBasalRateCalc={maxBasalRateCalc}
                maxHeight={MAX_HEIGHT}
                index={segmentsArrLength + 2}
                program={basalState.newExpertBasalProgram}
                isPlaceholderSegment={true}
              />
            )}
          {/* placeholder segment: leftover time after selecting an endtime */}
          {endTime.label &&
          endTime.label !== "12:00 am" &&
          endTime.label !== "" ? (
            <PlaceholderBasalSegment
              hideBottomBorder={endTime.label === "12:00 am"}
              startTime={endTime.label}
              endTime={getCurrentHourFormat({
                timeStr: "12:00 am",
                is24Hour: langState.is24Hour,
              })}
              maxHeight={MAX_HEIGHT}
              maxBasalRateCalc={maxBasalRateCalc}
              index={segmentsArrLength + 2}
              program={basalState.newExpertBasalProgram}
              isPlaceholderSegment={true}
            />
          ) : null}
          {endTime.label === "12:00 am" ? (
            <GraphVerticalLine maxHeight={MAX_HEIGHT} marginLeftNumber={-3} />
          ) : null}
        </View>
        <View style={[styles.break, { marginBottom: 16 }]} />
        {segmentIndex > 22 && (
          <WarningMessageBar
            timeSegment={segmentIndex}
            Icon={IconSmallHazard}
            horizontalMargin
          />
        )}
        <StartAndEndTimeSection
          is24Hour={langState.is24Hour}
          locale={langState.locale}
          startTime={startTime}
          endTime={endTime}
          segmentsArrLength={segmentsArrLength}
          routeParams={route.params}
          endTimeIndex={endTimeIndex}
          adjustedEndTimeData={adjustedEndTimeData}
          showEndTimePicker={showEndTimePicker}
          toggleEndTimePicker={toggleEndTimePicker}
          onConfirm={onEndTimeConfirm}
          onDismiss={onEndTimeDismiss}
        />
        <View
          style={{
            backgroundColor:
              borderColor === theme.colors.blue ? "#EEF2F6" : "transparent",
            paddingHorizontal: 16,
            height: 158,
            zIndex: -1,
          }}
        >
          <Text style={[styles.durationHeader]}>
            {languageContent.basalRate}
          </Text>
          <Text style={[styles.subHeader]}>
            {replaceWithValues(languageContent.range, contentVariables)}
          </Text>
          <TouchableOpacity
            style={[
              theme.layout.inputContainerStyle,
              {
                borderColor: borderColor,
              },
            ]}
            activeOpacity={1}
            onPress={() => toggleBasalRatePicker()}
          >
            <TextInput
              editable={false}
              style={[theme.layout.inputStyle]}
              placeholder={"––"}
              placeholderTextColor={theme.colors.placeholder}
              selectionColor={theme.colors.blue}
              value={
                basalRate?.label === ""
                  ? basalRate?.label
                  : Number(basalRate?.label).toLocaleString(langState.locale, {
                      minimumFractionDigits: 2,
                    })
              }
              showSoftInputOnFocus={false}
            />
            <Text
              style={[
                theme.layout.adornment,
                {
                  color: getBasalRateAdornmentColor(basalRate?.label),
                },
              ]}
            >
              {languageContent.uHr}
            </Text>
          </TouchableOpacity>
        </View>
        {adjustedMaxBasalData && endTime.label ? (
          <View
            style={
              (styles.basalRatePickerWrapper,
              {
                display: showBasalRatePicker ? "flex" : "none",
              })
            }
          >
            <CustomPicker
              is24Hour={langState.is24Hour}
              locale={langState.locale}
              title={languageContent.basalRate}
              values={adjustedMaxBasalData}
              isVisible={showBasalRatePicker}
              onConfirm={(value: string | null) => onBasalRateConfirm(value)}
              selectedValue={basalRate && basalRate.label}
              onDismiss={onBasalRateDismiss}
              units={languageContent.uHr}
            />
          </View>
        ) : null}
        <View>
          {showConfirmationModal && (
            <ConfirmationModal
              isVisible={true}
              title={languageContent.reviewModalTitle}
              message={
                <View>
                  <Text style={[theme.fonts.body1]}>
                    {languageContent.reviewModalMessage}
                  </Text>
                </View>
              }
              hideDismiss
              confirmText={languageContent.continue}
              onConfirm={() => {
                setShowConfirmationModal(false)
                navigation.navigate("ExpertProgramSegmentsReview")
              }}
            />
          )}
        </View>
      </ScrollView>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        leftAction
        leftNavigationAction={handleLeftNavigationAction}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.next}
        rightAction={handleRightNavigationAction}
        rightActionStyle={{
          color:
            endTime.label === "" || basalRate?.label === ""
              ? theme.colors.placeholder
              : theme.colors.purple,
        }}
      />
      {!userState.hasTXId && !userState.podConfigured ? (
        <ConfirmationModal
          title={languageContent.cancelModalTitle}
          isVisible={cancelModal}
          onDismiss={() => setCancelModal(false)}
          onConfirm={() => {
            setCancelModal(false)
            dispatch(
              basalActions.basalUpdate(
                {
                  newExpertBasalProgram: emptyExpertBasalProgram,
                },
                basalState,
              ),
            )
            navigation.navigate("ExpertIntro")
          }}
          message={
            <View>
              <Text style={[theme.fonts.body1, { marginBottom: 8 }]}>
                {languageContent.cancelModalBody}
              </Text>
            </View>
          }
        />
      ) : (
        <ConfirmationModal
          isVisible={cancelModal}
          title={
            route.params && route.params.editEndTime
              ? languageContent.cancelTitleOne
              : languageContent.cancelTitleTwo
          }
          confirmText={languageContent.yes}
          dismissText={languageContent.no}
          onDismiss={() => setCancelModal(false)}
          onConfirm={() => {
            setCancelModal(false)
            if (route.params && route.params.editEndTime) {
              dispatch(
                basalActions.basalUpdate(
                  {
                    newExpertBasalProgram:
                      basalState.copyOfNewExpertBasalProgram,
                    copyOfNewExpertBasalProgram: emptyExpertBasalProgram,
                  },
                  basalState,
                ),
              )
            } else {
              dispatch(
                basalActions.basalUpdate(
                  {
                    newExpertBasalProgram: emptyExpertBasalProgram,
                  },
                  basalState,
                ),
              )
            }

            navigation.navigate("BasalPrograms")
          }}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder,
  },
  mainText: {
    ...theme.fonts.text200,
    fontWeight: "400",
    marginBottom: 24,
  },
  durationHeader: {
    ...theme.fonts.h3,
  },
  subHeader: {
    ...theme.fonts.text200,
    color: theme.colors.text.cancel,
    marginBottom: 6,
    fontWeight: "400",
  },
  graphView: {
    marginHorizontal: 16,
    marginBottom: 16,
    alignItems: "flex-end",
    flexDirection: "row",
  },
  basalRatePickerWrapper: {
    flex: 2,
    zIndex: 20,
    marginTop: pickerVerticalMargin,
    marginLeft: pickerHorizontalMargin,
    height: 200,
  },
  showTwelveText: {
    fontSize: 12,
    position: "absolute",
    left: width / 2 - 32,
  },
})

export default ExpertNewBasalSegmentScreen
