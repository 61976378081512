import { useNavigation } from "@react-navigation/native";
import React from "react";
import { View } from "react-native";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { Header } from "../../../../../atoms";
import Paragraph from "../../../../../atoms/paragraph";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./CreateBasalProgramIntroScreenContent";

const CreateBasalProgramIntroScreen: React.FC = () => {
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);
  return (
    <>
      <View
        style={[
          theme.layout.wrapper,
          {
            width: "100%",
            backgroundColor: "white"
          }
        ]}>
        <Header label={languageContent.create} />
        <View style={{ paddingHorizontal: 16 }}>
          <Paragraph label={languageContent.programIntro} />
        </View>
      </View>
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.cancel}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.next}
        rightAction={() => {
          navigate("BasalProgramEntry");
        }}
      />
    </>
  );
};

export default CreateBasalProgramIntroScreen;
