import { defineMessages } from 'react-intl';

export const operationModeMessages = {
  automated: defineMessages({
    title: {
      id: 'dashboard.operation-mode.auto.title',
      defaultMessage: 'Automated',
    },
  }),
  manual: defineMessages({
    title: {
      id: 'dashboard.operation-mode.manual.title',
      defaultMessage: 'Manual',
    },
  }),
  limited: defineMessages({
    title: {
      id: 'dashboard.operation-mode.limited.title',
      defaultMessage: 'Limited',
    },
  }),
};
export const messages = defineMessages({
  buttonTempBasal: {
    id: 'dashboard.button.temp-basal',
    defaultMessage: 'Temp On',
  },
  buttonInsulin: {
    id: 'dashboard.button.insulin',
    defaultMessage: 'Insulin',
  },
  buttonActivity: {
    id: 'dashboard.button.activity',
    defaultMessage: 'Activity',
  },
  buttonPod: {
    id: 'dashboard.button.pod',
    defaultMessage: 'Pod',
  },
  buttonBolus: {
    id: 'dashboard.button.bolus',
    defaultMessage: 'Bolus',
  },
  iobUnits: {
    id: 'dashboard.iob-units',
    defaultMessage: 'IOB <highlight>{value} Units</highlight>',
  },
  lastBolusUnits: {
    id: 'dashboard.last-bolus.units',
    defaultMessage: 'Last Bolus 3.5 Units',
  },
});

export const bolusDeliveryBarMessages = defineMessages({
  deliveringBolus: {
    id: 'dashboard.bolus-delivery-bar.delivering-bolus',
    defaultMessage: 'Delivering Bolus ({currentProgress}%)',
  },
  deliveringExtendedBolus: {
    id: 'dashboard.bolus-delivery-bar.delivering-extended-bolus',
    defaultMessage: 'Delivering Extended Bolus ({currentProgress}%)',
  },
  bolusValue: {
    id: 'dashboard.bolus-delivery-bar.bolus-value',
    defaultMessage: '{current} U of {total} U',
  },
  hoursRemaining: {
    id: 'dashboard.bolus-delivery-bar.hours-remaining',
    defaultMessage: '({hours} hr {mins} min remaining)',
  },
  cancelExtendedBolus: {
    id: 'dashboard.bolus-delivery-bar.cancel-extended-bolus',
    defaultMessage: 'Are you sure you want to cancel the extended bolus?',
  },
  cancel: {
    id: 'dashboard.bolus-delivery-bar.cancel',
    defaultMessage: 'Cancel',
  },
});