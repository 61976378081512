import * as type from "./actions";
import moment, { Moment } from 'moment';

import defaultBasalReduxData from "../../../../mobile/latest/data/defaultBasalReduxData";
import defaultBolusReduxData from "../../../../mobile/latest/data/defaultBolusReduxData";

interface BolusSegment {
  start: string;
  end: string;
  targetBG: number;
  correctAbove: number;
  oneUnitInsulinCovers?: number;
  oneUnitCorrectionFactor?: number;
  endHelpText?: string;
  startTimeHelpText?: string;
}

interface ICRatioSegment {
  start: string;
  end: string;
  endHelpText?: string;
  startTimeHelpText?: string;
  oneUnitInsulinCovers: number;
}

interface ICorrectionFactor {
  start: string;
  end: string;
  endHelpText?: string;
  startTimeHelpText?: string;
  oneUnitCorrectionFactor: number;
}

export interface BolusDelivery {
  carbs: number;
  glucose: number;

  mealBolus: number;
  correctionBolus: number;
  extendedBolus: number;

  totalBolus: number;
  sensorUsed: boolean;
  currentTime: Moment;
  isInProgress: boolean;
  deliveryQty: number;
  isExtended: boolean;
  deliveryTime: number;
}

export interface BolusState {
  lastBolus: string;
  dateLastBolus: string;
  insulinPaused: boolean;
  bolusTutorial: boolean;
  podInsulin: number;
  cgmInfo: {
    Value: number;
    Trend:
      | "Flat"
      | "Angeld Down"
      | "Angled Up"
      | "Double Up"
      | "Double Down"
      | "Up";
    Delivery: number;
  };
  currentIndex: number;
  timestamp: string;
  pauseIterating: boolean;
  defaultFirstSegment: BolusSegment;
  defaultSecondSegment: BolusSegment;
  defaultThirdSegment: BolusSegment;
  durationOfInsulinAction: number;
  maxBolus: number;
  extendedBolus: boolean;
  reverseCorrection: boolean;
  basalProgramName: string;
  basalProgramTag: string;
  customFirstSegment: BolusSegment;
  customSecondSegment: BolusSegment;
  customThirdSegment: BolusSegment;
  activeExpertBolusProgram: BolusProgram;
  newExpertBolusProgram: BolusProgram;
  copyOfNewExpertBolusProgram: BolusProgram;
  expertBolusPrograms: Array<BolusProgram>;

  // Data used for iOS Bolus delivery.
  bolusDelivery: Partial<BolusDelivery>
}

export interface BolusProgram {
  id: string;
  programName: string;
  tag: string;
  segments: Array<BolusSegment>;
  isComplete: boolean;
  isActive: boolean;
  icRatioSegments: Array<ICRatioSegment>;
  correctionFactorSegments: Array<ICorrectionFactor>;
  hasUpdate?: boolean; // If the values on the edit/entry screen have been changed or not.
}

const now = new Date();

export const jumpStartState: BolusState = {
  lastBolus: "0.00",
  dateLastBolus: now.toString(),
  insulinPaused: false,
  bolusTutorial: true,
  podInsulin: 125,
  cgmInfo: {
    Value: 135,
    Trend: "Flat",
    Delivery: 0.1
  },
  currentIndex: 0,
  timestamp: now.toString(),
  pauseIterating: false,
  durationOfInsulinAction: 0,
  maxBolus: 0,
  extendedBolus: false,
  reverseCorrection: false,
  basalProgramName: "",
  basalProgramTag: "",
  defaultFirstSegment: {
    start: "12:00",
    end: "8:00",
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0
  },
  defaultSecondSegment: {
    start: "8:00",
    end: "6:00",
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0
  },
  defaultThirdSegment: {
    start: "6:00",
    end: "12:00",
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0
  },
  customFirstSegment: {
    start: "12:00",
    end: "8:00",
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0
  },
  customSecondSegment: {
    start: "8:00",
    end: "6:00",
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0
  },
  customThirdSegment: {
    start: "6:00",
    end: "12:00",
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0
  },
  activeExpertBolusProgram: defaultBolusReduxData.defaultActiveBolusProgram,
  newExpertBolusProgram: defaultBasalReduxData,
  copyOfNewExpertBolusProgram: defaultBasalReduxData,
  expertBolusPrograms: defaultBolusReduxData.defaultExpertBolusPrograms,

  bolusDelivery: {
    carbs: 0,
    glucose: 0,
    mealBolus: 0,
    correctionBolus: 0,
    extendedBolus: 0,
    totalBolus: 0,
    sensorUsed: false,
    currentTime: moment(),
    isInProgress: false,
    deliveryQty: 0,
    isExtended: false,
    deliveryTime: 0,
  }
};

export const initialState: BolusState = {
  lastBolus: "0.00",
  dateLastBolus: now.toString(),
  insulinPaused: false,
  bolusTutorial: true,
  podInsulin: 125,
  cgmInfo: {
    Value: 135,
    Trend: "Flat",
    Delivery: 0.1
  },
  currentIndex: 0,
  timestamp: "",
  pauseIterating: true,
  durationOfInsulinAction: 0,
  maxBolus: 0,
  extendedBolus: false,
  reverseCorrection: false,
  basalProgramName: "",
  basalProgramTag: "",
  defaultFirstSegment: {
    start: "12:00",
    end: "8:00",
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0
  },
  defaultSecondSegment: {
    start: "8:00",
    end: "12:00",
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0
  },
  defaultThirdSegment: {
    start: "6:00",
    end: "12:00",
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0
  },
  customFirstSegment: {
    start: "12:00",
    end: "8:00",
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0
  },
  customSecondSegment: {
    start: "8:00",
    end: "6:00",
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0
  },
  customThirdSegment: {
    start: "6:00",
    end: "12:00",
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0
  },
  activeExpertBolusProgram: defaultBolusReduxData.defaultActiveBolusProgram,
  newExpertBolusProgram: defaultBasalReduxData,
  copyOfNewExpertBolusProgram: defaultBasalReduxData,
  expertBolusPrograms: defaultBolusReduxData.defaultExpertBolusPrograms,

  bolusDelivery: {
    carbs: 0,
    glucose: 0,
    mealBolus: 0,
    correctionBolus: 0,
    extendedBolus: 0,
    totalBolus: 0,
    sensorUsed: false,
    currentTime: moment(),
    isInProgress: false,
    deliveryQty: 0,
    isExtended: false,
    deliveryTime: 0,
  }
};

const reducer = (
  state: BolusState = initialState,
  action: type.Action
): BolusState => {
  switch (action.type) {
    case type.BOLUS_INIT:
      return { ...initialState };
    case type.BOLUS_UPDATE:
      return { ...state, ...action.payload.bolus };
    case type.SET_BOLUS_DELIVERY_VALUES:
      return {
        ...state,
        bolusDelivery: {
          ...state.bolusDelivery,
          ...action.payload.bolus.bolusDelivery,
        }
      }
    default:
      return state;
  }
};

export default reducer;
