import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import './Button.css';

interface Props {
  type?: 'submit' | 'button' | 'reset';
  onClick?: Function;
}

const Button: React.FC<PropsWithChildren<Props>> = (props) => {
  const { type = 'button', onClick, children } = props;
  const handleOnClick = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      className={classNames('button', 'bg-modes-automated')}
      type={type}
      onClick={handleOnClick}
    >
      {children}
    </button>
  );
};

export default Button;
