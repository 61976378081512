let strings = {
  'en-US': {
    podExpiration: 'Pod Expiration',
    beforeExpiration: '4 hrs before expiration',
    lowPodInsulin: 'Low Pod Insulin',
    lowPodU: '10 U',
    shutOff: 'Pod Shut-Off',
    hrs: '4 hrs',
    checkBG: 'Check glucose after Bolus',
    offTap: 'OFF. Tap to turn ON.',
    setReminder: 'Set a reminder when creating a bolus',
    missedBolus: 'Missed Bolus',
    dontBolus: "If you don't bolus in a set time period",
    bolusBGReminder: 'Bolus Glucose Reminder',
    confidenceReminders: 'Confidence Reminders',
    atStartEnd: 'At start and end of bolus, extended bolus and temp basal',
    confidenceRemindersOne:
      'If Confidence Reminders are \non, you will hear a beap at the \nstart and end of a temp basal or \nbolus',
    programRemindersOne:
      'If Program Reminders are on, \nthe Pod beeps every 60 minutes \nduring a temp basal or extended \nbolus.',
    modalTitle: 'Program Reminders',
    modalMessage1:
      'Pod will beep every 60 minutes during temp basal or ext. bolus',
    confirmText: 'Ok',
    ok: 'Ok',
  },
  'en-GB': {
    podExpiration: 'Pod Expiry',
    beforeExpiration: '4 hrs before expiration',
    lowPodInsulin: 'Low Pod Insulin',
    lowPodU: '10 U',
    shutOff: 'Pod Shut-Off',
    hrs: '4 hrs',
    checkBG: 'Check glucose after Bolus',
    offTap: 'OFF. Tap to turn ON.',
    setReminder: 'Set a reminder when creating a bolus',
    missedBolus: 'Missed Bolus',
    dontBolus: "If you don't bolus in a set time period",
    bolusBGReminder: 'Bolus Glucose Reminder',
    confidenceReminders: 'Confidence Reminders',
    atStartEnd: 'At start and end of bolus, extended bolus and temp basal',
    confidenceRemindersOne:
      'If Confidence Reminders are \non, you will hear a beap at the \nstart and end of a temp basal or \nbolus',
    programRemindersOne:
      'If Programme Reminders are on, \nthe Pod beeps every 60 minutes \nduring a temp basal or extended \nbolus.',
    modalTitle: 'Programme Reminders',
    modalMessage1:
      'Pod will beep every 60 minutes during temp basal or ext. bolus',
    confirmText: 'Ok',
    ok: 'Ok',
  },
  'es-US': {
    podExpiration: 'Caducidad del Pod',
    beforeExpiration: '4 h antes de la caducidad',
    lowPodInsulin: 'Pod con insulina baja',
    lowPodU: '10 U',
    shutOff: 'Apagado del Pod',
    hrs: '4 h',
    checkBG: 'Compruebe la glucosa después del bolo',
    offTap: 'Apagado. Pulse para Prender.',
    setReminder: 'Configure un recordatorio cuando cree un bolo',
    missedBolus: 'Bolo omitido',
    dontBolus: 'Si no administra un bolo en un período establecido',
    bolusBGReminder: 'Recordatorio de glucosa tras bolo',
    confidenceReminders: 'Recordatorios de confianza',
    atStartEnd:
      'Al inicio y finalización del bolo, el bolo extendido y el basal temporal',
    modalTitle: 'Recordatorios de programa',
    modalMessage1:
      'El Pod emitirá un pitido cada minutos durante el basal temporal o el bolo extendido',
    confirmText: 'Aceptar',
    notAvailModalTitle: 'Recordatorios',
    notAvailModalBody: 'No están disponibles en el simulador.',
    ok: 'Aceptar',
  },
  'de-DE': {
    podExpiration: 'Pod-Ablauf',
    beforeExpiration: '4 Stdn. vor dem Ablaufdatum',
    lowPodInsulin: 'Wenig Insulin im Pod',
    lowPodU: '10 E',
    shutOff: 'Pod-Abschaltung',
    hrs: '4 Stunden',
    checkBG: 'Glukose nach Bolus überprüfen',
    offTap: 'AUS. Zum Einschalten antippen.',
    setReminder: 'Erinnerung beim Erstellen eines Bolus festlegen',
    missedBolus: 'Verpasster Bolus',
    dontBolus: 'Wenn in einem festgelegten Zeitraum kein Bolus abgegeben wird.',
    bolusBGReminder: 'Bolus-Glukose-Erinnerungshinweis',
    confidenceReminders: 'Bestätigungs-Erinnerungshinweise',
    atStartEnd:
      'Bei Start und Ende eines Bolus, verzögertem Bolus und temporärer Basalrate',
    modalTitle: 'Profil-Erinnerungen',
    modalMessage1:
      'Der Pod gibt während einer temporären Basalrate oder eines verzögerten Bolus alle 60 Minuten einen Signalton aus.',
    confirmText: 'OK',
    notAvailModalTitle: 'Erinnerungshinweise',
    notAvailModalBody: 'Nicht für den Simulator verfügbar.',
    ok: 'OK',
  },
  'nl-NL': {
    podExpiration: 'Uiterste gebruiksdatum Pod',
    beforeExpiration: '4 u. vóór vervaldatum',
    lowPodInsulin: 'Weinig insuline in Pod',
    lowPodU: '10 E',
    shutOff: 'Pod uitschakelen',
    hrs: '4 uur',
    checkBG: 'Check glucose after Bolus',
    offTap: 'Uit. Tik om Aan te Zetten.',
    setReminder: 'Set a reminder when creating a bolus',
    missedBolus: 'Missed Bolus',
    dontBolus: "If you don't bolus in a set time period",
    bolusBGReminder: 'Bolus Glucose Reminder',
    confidenceReminders: 'Bevestigingsherinneringen',
    atStartEnd:
      'Bij start/einde van bolus, verlengde bolus en tijdelijke basaalsnelheid',
    confidenceRemindersOne:
      'Als bevestigingsherinneringen zijn ingeschakeld, hoort u een pieptoon aan het begin en aan het einde van een tijdelijke basaalsnelheid of bolus.',
    programRemindersOne:
      'Als programmaherinneringen zijn ingeschakeld, piept de Pod elke 60 minuten tijdens een tijdelijke basaalsnelheid of verlengde bolus.',
    modalTitle: 'Program Reminders',
    modalMessage1:
      'De Pod piept elke 60 min tijdens tijdelijke basaalsnelheid of verlengde bolus',
    confirmText: 'Ok',
    ok: 'Ok',
  },
  'fr-FR': {
    podExpiration: 'Péremption du Pod',
    beforeExpiration: '4 h avant péremption',
    lowPodInsulin: 'Insuline du Pod basse',
    lowPodU: '10 U',
    shutOff: 'Arrêt du Pod',
    hrs: '4 h',
    checkBG: 'Check glucose after Bolus',
    offTap: 'Désactivé. Appuyez pour Activer.',
    setReminder: 'Set a reminder when creating a bolus',
    missedBolus: 'Bolus oublié',
    dontBolus: "If you don't bolus in a set time period",
    bolusBGReminder: 'Bolus Glucose Reminder',
    confidenceReminders: 'Rappels de confiance',
    atStartEnd:
      'Au début et à la fin du bolus, du bolus prolongé et du débit basal temporaire',
    confidenceRemindersOne:
      'Si les rappels de confiance sont activés, un bip est émis au début et à la fin du débit basal temporaire ou du bolus.',
    programRemindersOne:
      'Si les rappels de programme sont activés, le Pod émet un bip toutes les 60 minutes pendant le débit basal temporaire ou le bolus prolongé.',
    modalTitle: 'Rappels de programme',
    modalMessage1:
      'Le Pod émet un bip toutes les 60 minutes pendant le débit basal temporaire ou le bolus prolongé',
    confirmText: 'Ok',
    ok: 'Ok',
  },
};

export { strings };
