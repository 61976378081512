import React from "react";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { FTSIntroConfirmation } from "../../../../../organisms";
import { strings } from "./PTHOWelcomeScreenContent";

const PTHOWelcomeScreen: React.FC = () => {
  const { languageContent } = useLanguageContent(strings);

  return (
    <FTSIntroConfirmation
      title={languageContent.great}
      description={languageContent.buildProfile}
      buttonLabel={languageContent.setupProfile}
      navigateTo={"BasalFTS"}
      largeTitle
    />
  );
};

export default PTHOWelcomeScreen;
