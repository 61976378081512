import React, { useState, ComponentType } from 'react';
import { SceneMap, TabView } from 'react-native-tab-view';
import { CarouselDotNavigation } from './CarouselDotNavigation';

interface Props {
  sceneMap: Scenes;
}

interface Route {
  key: string;
}

export interface Scenes {
  [key: string]: ComponentType;
}

const generateRoutes = (sceneMap: Scenes) =>
  Object.keys(sceneMap).map((key) => ({ key }));

export const CarouselViewer: React.FC<Props> = ({ sceneMap }) => {
  const [index, setIndex] = useState<number>(0);
  const [routes] = useState<Route[]>(generateRoutes(sceneMap));

  return (
    <TabView
      navigationState={{ index, routes }}
      onIndexChange={setIndex}
      renderScene={SceneMap(sceneMap)}
      tabBarPosition="bottom"
      sceneContainerStyle={{
        justifyContent: 'center',
      }}
      renderTabBar={({ navigationState, jumpTo }) => (
        <CarouselDotNavigation
          sceneMap={sceneMap}
          navigationState={navigationState}
          jumpTo={jumpTo}
        />
      )}
    />
  );
};
