import { defineMessages } from 'react-intl';

export const pauseInsulinConfirmationMessages = defineMessages({
  title: {
    id: 'ios.modals.pause-insulin.title',
    defaultMessage: 'Pause Insulin?',
  },
  text: {
    id: 'ios.modals.pause-insulin.text',
    defaultMessage: 'You must pause insulin to Change Time Settings.',
  },
  pauseInsulinCancelsText: {
    id: 'ios.modals.pause-insulin-cancels.text',
    defaultMessage:
      'Pausing Insulin cancels any temp basal or extended bolus currently in progress',
  },
  yesButton: {
    id: 'ios.modals.pause-insulin.yes-button',
    defaultMessage: 'Yes',
  },
  noButton: {
    id: 'ios.modals.pause-insulin.no-button',
    defaultMessage: 'No',
  },
});

export const timezoneChangeConfirmationMessages = defineMessages({
  title: {
    id: 'ios.modals.timezone-change.title',
    defaultMessage: 'Confirm Change',
  },
  reviewTimeCarefullyText: {
    id: 'ios.modals.review-time-carefully.text',
    defaultMessage:
      'Review the time carefully. Correct time is essential for correct basal insulin delivery and history records.',
  },
  timezoneText: {
    id: 'ios.modals.timezone.text',
    defaultMessage: 'Time zone: New York (GMT -5)',
  },
  currentTimeText: {
    id: 'ios.modals.current-time.text',
    defaultMessage: 'Current time: {currentTime}',
  },
  confirmButton: {
    id: 'ios.modals.timezone-change.confirm-button',
    defaultMessage: 'Confirm',
  },
  goBackButton: {
    id: 'ios.modals.timezone-change.go-back-button',
    defaultMessage: 'Go Back',
  },
});

export const checkAlarmProgressConfirmationMessages = defineMessages({
  title: {
    id: 'ios.modals.check-alarms-progress.title',
    defaultMessage: 'Check Alarms in Progress',
  },
  text: {
    id: 'ios.modals.test-your-alarms.text',
    defaultMessage: 'A test of your alarms should result in the following:',
  },
  appDependingText: {
    id: 'ios.modals.app-depending.text',
    defaultMessage:
      'App: Depending on your sound settings, you will experience either a beep and three vibrations, or only three vibrations.',
  },
  hearShortBeepText: {
    id: 'ios.modals.hear-short-beep.text',
    defaultMessage:
      'Pod: You will hear a short beep followed by a continuous tone.',
  },
});

export const didBothAlarmsWorkMessages = defineMessages({
  title: {
    id: 'ios.modals.did-both-alarms-work.title',
    defaultMessage: 'Did Both Alarms Work?',
  },
  text: {
    id: 'ios.modals.did-app-beep.text',
    defaultMessage: 'Did the App beep and vibrate, or vibrate only?',
  },
  didPodSoundText: {
    id: 'ios.modals.did-pod-sound.text',
    defaultMessage:
      'Did the Pod sound a short beep and then a continuous tone?',
  },
  yesButton: {
    id: 'ios.modals.did-both-alarms-work.yes-button',
    defaultMessage: 'Yes',
  },
  noButton: {
    id: 'ios.modals.did-both-alarms-work.no-button',
    defaultMessage: 'No',
  },
});

export const startInsulinConfirmationMessages = defineMessages({
  title: {
    id: 'ios.modals.settings.start-insulin.title',
    defaultMessage: 'Start Insulin?',
  },
  text: {
    id: 'ios.modals.settings.start-insulin.text',
    defaultMessage: 'This will restart your Weekend Basal Program.',
  },
  remindedText: {
    id: 'ios.modals.settings.start-insulin.reminded.text',
    defaultMessage:
      'If you don’t start now, you will be reminded in 30 minutes.',
  },
  yesButton: {
    id: 'ios.modals.settings.start-insulin.yes-button',
    defaultMessage: 'Yes',
  },
  noButton: {
    id: 'ios.modals.settings.start-insulin.no-button',
    defaultMessage: 'No',
  },
});
