import React from 'react';
import { useIntl } from 'react-intl';
import { createStackNavigator } from '@react-navigation/stack';
import { TopBar } from 'web/components/ios/TopBar';
import { EditProgramName } from './views/EditProgramName';
import { ConfirmBasalProgram } from './views/ConfirmBasalProgram';
import { EditSegment } from './views/EditSegment';
import { BasalProgramsRoutesEnum } from '../../types';
import { editBasalProgramMessages, confirmBasalProgramMessages } from '../../BasalPrograms.messages';

const Stack = createStackNavigator();

export const EditBasalProgramStack: React.FC = () => {
  const intl = useIntl();

  return (
    <Stack.Navigator
      initialRouteName={BasalProgramsRoutesEnum.EditProgramName}
      screenOptions={{
        header: (props) => <TopBar {...props} backAndCancelMode />
      }}
    >
      <Stack.Screen
        name={BasalProgramsRoutesEnum.EditProgramName}
        options={{
          title: intl.formatMessage(editBasalProgramMessages.title),
          header: (props) => <TopBar {...props} />,
        }}
        component={EditProgramName}
      />
      <Stack.Screen
        name={BasalProgramsRoutesEnum.ConfirmBasalProgram}
        options={{
          title: intl.formatMessage(confirmBasalProgramMessages.title),
        }}
        component={ConfirmBasalProgram}
      />
      <Stack.Screen
        name={BasalProgramsRoutesEnum.EditSegment}
        options={{
          title: intl.formatMessage(editBasalProgramMessages.title),
        }}
        component={EditSegment}
      />
    </Stack.Navigator>
  );
}
