import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet, Text, View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import { Button } from 'mobile/latest/components/atoms';
import theme from 'mobile/latest/theme';
import { messages } from './ReviewSettings.messages';
import { L2Paths } from 'web/routes/L2/InitialSetupL2';


const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    paddingHorizontal: 16,
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    marginTop: 24,
  },
  paragraph: {
    marginBottom: 8,
  },
  buttonContainer: {
    marginTop: 24,
  },
  bold: {
    fontWeight: '600',
  },
});

const ReviewSettings: React.FC = () => {
  const { navigate } = useNavigation();
  return (
    <View style={styles.container}>
      <Text style={theme.fonts.h4}>
        <FormattedMessage {...messages.title} />
      </Text>
      <View style={styles.textContainer}>
        <View style={styles.paragraph}>
          <Text>
            <FormattedMessage {...messages.firstParagraph} />
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text>
            <FormattedMessage
              {...messages.secondParagraph}
              values={{
                highlight: (chunk) => (
                  <div style={{ fontWeight: styles.bold.fontWeight, marginBottom: 8 }}>{chunk}</div>
                ),
              }}
            />
          </Text>
        </View>
      </View>

      <View style={styles.buttonContainer}>
        <Button variant="primary" onPress={() => navigate(L2Paths.lowGlucoseSettings)}>
          <Text>
            <FormattedMessage {...messages.cta} />
          </Text>
        </Button>
      </View>
    </View>
  );
};

export default ReviewSettings;
