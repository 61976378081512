import React from "react"
import { View } from "react-native-web"
import theme from "../../../theme"

interface Props {
  maxHeight: number
  marginLeftNumber: number
}

const GraphVerticalLine: React.FC<Props> = ({
  maxHeight,
  marginLeftNumber,
}) => {
  return (
    <View
      style={{
        flexDirection: "column",
        alignItems: "center",
        marginLeft: marginLeftNumber,
      }}
    >
      <View
        style={{
          borderRadius: 4,
          height: 4,
          width: 4,
          backgroundColor: theme.colors.graphBlue,
        }}
      />
      <View
        style={{
          height: maxHeight,
          width: 1.5,
          backgroundColor: theme.colors.graphBlue,
        }}
      />
    </View>
  )
}

export default GraphVerticalLine
