import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { BasalState } from "../../../../../../../../web/store/reducers/basal";
import { UserState } from "../../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { SettingsSection } from "../../../../../organisms";
import { strings } from "./BasalTempSettingsScreenContent";

const BasalTempSettingsScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );
  const { navigate } = useNavigation();

  const [fieldValue, setFieldValue] = React.useState<boolean>(false);

  const { languageContent } = useLanguageContent(strings);
  const contentVariables = [basalState.maxBasal];

  return (
    <View>
      <View style={[styles.card]}>
        <SettingsSection
          settingTitle={languageContent.settingBasalTitle}
          subtitleText={
            userState.isExpert
              ? replaceWithValues(
                  languageContent.unitsPerHour,
                  contentVariables
                )
              : languageContent.fiveUnitsPerHour
          }
          subtitleTextOnPressHandler={() => navigate("SettingsMaxBasalRate")}
        />
        <SettingsSection
          settingTitle={languageContent.settingTempTitle}
          hasCustomToggleSwitch
          onToggleChange={() => setFieldValue(!fieldValue)}
          settingValueSubTextElement={
            fieldValue ? (
              <Text style={[theme.fonts.body2, styles.settingValue]}>
                {languageContent.configured}
              </Text>
            ) : (
              <Text style={[theme.fonts.body2, styles.disabledValue]}>
                {languageContent.tap}
              </Text>
            )
          }
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    width: "100%",
    height: "350%",
    backgroundColor: theme.colors.white,
    marginBottom: 8,
    borderBottomWidth: 1,
    borderColor: theme.colors.textInputBorder
  },
  settingValue: {
    color: theme.colors.lightBlue
  },

  disabledValue: {
    color: theme.colors.text.cancel
  }
});

export default BasalTempSettingsScreen;
