import { defineMessages } from 'react-intl';

export const historySummaryMessages = defineMessages({
  calendarDate: {
    id: 'ios.history-summary.calendar-date',
    defaultMessage: 'Dec 7',
  },
  headerTitle: {
    id: 'ios.history-summary.header-title',
    defaultMessage: 'History',
  },
  summaryTogglerTitle: {
    id: 'ios.history-summary.summary-toggler-title',
    defaultMessage: 'Summary',
  },
  eventsTogglerTitle: {
    id: 'ios.history-summary.events-toggler-title',
    defaultMessage: 'Events',
  },
  yourSensorTitle: {
    id: 'ios.history-summary.your-sensor-title',
    defaultMessage: 'Your Sensor',
  },
  averageSensorBlockTitle: {
    id: 'ios.history-summary.average-sensor-block-title',
    defaultMessage: 'Average Sensor',
  },
  averageSensorBlockValue: {
    id: 'ios.history-summary.average-sensor-block-value',
    defaultMessage: '142',
  },
  averageSensorBlockValueUnit: {
    id: 'ios.history-summary.average-sensor-block-value-unit',
    defaultMessage: 'mg/dL',
  },
  sensorInRangeBlockTitle: {
    id: 'ios.history-summary.sensor-in-Range-block-title',
    defaultMessage: 'Sensor in Range',
  },
  sensorInRangeBlockValue: {
    id: 'ios.history-summary.sensor-in-Range-block-value',
    defaultMessage: '54',
  },
  sensorInRangeBlockValueUnit: {
    id: 'ios.history-summary.sensor-in-Range-block-value-unit',
    defaultMessage: '%',
  },
  sensorInRangeBlockPlaceholder: {
    id: 'ios.history-summary.sensor-in-Range-block-placeholder',
    defaultMessage: '70 to 180 mg/dL',
  },
  sensorAboveBlockTitle: {
    id: 'ios.history-summary.sensor-above-block-title',
    defaultMessage: 'Sensor Above',
  },
  sensorAboveBlockValue: {
    id: 'ios.history-summary.sensor-above-block-value',
    defaultMessage: '40',
  },
  sensorAboveBlockValueUnit: {
    id: 'ios.history-summary.sensor-above-block-value-unit',
    defaultMessage: '%',
  },
  sensorAboveBlockPlaceholder: {
    id: 'ios.history-summary.sensor-above-block-placeholder',
    defaultMessage: 'above 180 mg/dL',
  },
  sensorBelowBlockTitle: {
    id: 'ios.history-summary.sensor-below-block-title',
    defaultMessage: 'Sensor Below',
  },
  sensorBelowBlockValue: {
    id: 'ios.history-summary.sensor-below-block-value',
    defaultMessage: '8',
  },
  sensorBelowBlockValueUnit: {
    id: 'ios.history-summary.sensor-below-block-value-unit',
    defaultMessage: '%',
  },
  sensorBelowBlockPlaceholder: {
    id: 'ios.history-summary.sensor-below-block-placeholder',
    defaultMessage: 'below 70 mg/dL',
  },
  InsulinAndCarbsTitle: {
    id: 'ios.history-summary.insulin-and-carbs-title',
    defaultMessage: 'Insulin & Carbs',
  },
  totalInsulinBlockTitle: {
    id: 'ios.history-summary.total-insulin-block-title',
    defaultMessage: 'Total Insulin',
  },
  totalInsulinBlockValue: {
    id: 'ios.history-summary.total-insulin-block-value',
    defaultMessage: '50.1',
  },
  totalInsulinBlockValueUnit: {
    id: 'ios.history-summary.total-insulin-block-value-unit',
    defaultMessage: 'U',
  },
  bolusInsulinBlockTitle: {
    id: 'ios.history-summary.bolus-insulin-block-title',
    defaultMessage: 'Bolus Insulin',
  },
  bolusInsulinBlockValue: {
    id: 'ios.history-summary.bolus-insulin-block-value',
    defaultMessage: '78',
  },
  bolusInsulinBlockValueUnit: {
    id: 'ios.history-summary.bolus-insulin-block-value-unit',
    defaultMessage: '%',
  },
  bolusInsulinBlockPlaceholder: {
    id: 'ios.history-summary.bolus-insulin-block-placeholder',
    defaultMessage: '27.3 U',
  },
  basalInsulinBlockTitle: {
    id: 'ios.history-summary.basal-insulin-block-title',
    defaultMessage: 'Basal Insulin',
  },
  basalInsulinBlockValue: {
    id: 'ios.history-summary.basal-insulin-block-value',
    defaultMessage: '22',
  },
  basalInsulinBlockValueUnit: {
    id: 'ios.history-summary.basal-insulin-block-value-unit',
    defaultMessage: '%',
  },
  basalInsulinBlockPlaceholder: {
    id: 'ios.history-summary.basal-insulin-block-placeholder',
    defaultMessage: '31.8 U',
  },
  totalCarbsBlockTitle: {
    id: 'ios.history-summary.total-carbs-block-title',
    defaultMessage: 'Total Carbs',
  },
  totalCarbsBlockValue: {
    id: 'ios.history-summary.total-carbs-block-value',
    defaultMessage: '51',
  },
  totalCarbsBlockValueUnit: {
    id: 'ios.history-summary.total-carbs-block-value-unit',
    defaultMessage: 'g',
  },
});

export const historyEventsMessages = defineMessages({
  title: {
    id: 'ios.history-events.title',
    defaultMessage: 'Your Events',
  },
  autoEventLink: {
    id: 'ios.history-events.auto-event-link',
    defaultMessage: 'Auto Events',
  },
});

export const historyAutoEventsMessages = defineMessages({
  headerTitle: {
    id: 'ios.history-auto-events.header-title',
    defaultMessage: 'Auto Events',
  },
  decemberDate: {
    id: 'ios.history-auto-events.december-date',
    defaultMessage: 'DECEMBER 7',
  },
});

export const historyPodDeactivatedDetailsMessages = defineMessages({
  headerTitle: {
    id: 'ios.history-pod-deactivated-details.header-title',
    defaultMessage: 'Pod Deactivated',
  },
  detailsTitle: {
    id: 'ios.history-pod-deactivated-details.details-title',
    defaultMessage: 'DETAILS',
  },
  timeLabel: {
    id: 'ios.history-pod-deactivated-details.time-label',
    defaultMessage: 'Time',
  },
  timeValue: {
    id: 'ios.history-pod-deactivated-details.time-value',
    defaultMessage: 'Dec 7, 8:48 AM',
  },
  lotNoLabel: {
    id: 'ios.history-pod-deactivated-details.lot-number-label',
    defaultMessage: 'Lot No.',
  },
  lotNoValue: {
    id: 'ios.history-pod-deactivated-details.lot-number-value',
    defaultMessage: 'PP1U08142029',
  },
  sequenceNoLabel: {
    id: 'ios.history-pod-deactivated-details.sequence-number-label',
    defaultMessage: 'Sequence No.',
  },
  sequenceNoValue: {
    id: 'ios.history-pod-deactivated-details.sequence-number-value',
    defaultMessage: '47839475937',
  },
});

export const historyBolusDetailsMessages = defineMessages({
  headerTitle: {
    id: 'ios.history-bolus-details.header-title',
    defaultMessage: 'Bolus',
  },
  detailsTitle: {
    id: 'ios.history-bolus-details.details-title',
    defaultMessage: 'DETAILS',
  },
  mealBolusTitle: {
    id: 'ios.history-bolus-details.meal-bolus-title',
    defaultMessage: 'Meal Bolus',
  },
  mealBolusValue: {
    id: 'ios.history-bolus-details.meal-bolus-value',
    defaultMessage: '9.25 U',
  },
  correctionBolusTitle: {
    id: 'ios.history-bolus-details.correction-bolus-title',
    defaultMessage: 'Correction Bolus',
  },
  correctionBolusValue: {
    id: 'ios.history-bolus-details.correction-bolus-value',
    defaultMessage: '1 U',
  },
  iobTitle: {
    id: 'ios.history-bolus-details.iob-title',
    defaultMessage: 'IOB Adjustment',
  },
  iobValue: {
    id: 'ios.history-bolus-details.iob-value',
    defaultMessage: '-1.25 U',
  },
  yourAdjustmentTitle: {
    id: 'ios.history-bolus-details.your-adjustment-title',
    defaultMessage: 'Your Adjustment',
  },
  yourAdjustmentValue: {
    id: 'ios.history-bolus-details.your-adjustment-value',
    defaultMessage: '1.25 U',
  },
  adjustmentForSensorTitle: {
    id: 'ios.history-bolus-details.adjustment-for-sensor-title',
    defaultMessage: 'Adjusted for Sensor: Trending steady',
  },
  adjustmentForSensorValue: {
    id: 'ios.history-bolus-details.adjustment-for-sensor-value',
    defaultMessage: '0 U',
  },
  totalBolusCalculatedTitle: {
    id: 'ios.history-bolus-details.total-bolus-calculated-title',
    defaultMessage: 'Total Bolus* (Calculated)',
  },
  totalBolusDelivered: {
    id: 'ios.history-bolus-details.total-bolus-delivered',
    defaultMessage: '10.25 U delivered',
  },
  totalBolusValue: {
    id: 'ios.history-bolus-details.total-bolus-value',
    defaultMessage: '10.25 U',
  },
  totalBolusDescription: {
    id: 'ios.history-bolus-details.total-bolus-description',
    defaultMessage:
      '* Bolus doses are rounded down to the nearest 0.05 U increment.',
  },
  startTimeBolusLabel: {
    id: 'ios.history-bolus-details.start-time-bolus-label',
    defaultMessage: 'Start Time of Bolus',
  },
  startTimeBolusValue: {
    id: 'ios.history-bolus-details.start-time-bolus-Value',
    defaultMessage: 'Dec 7, 9:05 AM',
  },
  carbsManualLabel: {
    id: 'ios.history-bolus-details.carbs-manual-label',
    defaultMessage: 'Carbs (Manual)',
  },
  carbsManualValue: {
    id: 'ios.history-bolus-details.carbs-manual-value',
    defaultMessage: '99 g',
  },
  sensorLabel: {
    id: 'ios.history-bolus-details.sensor-label',
    defaultMessage: 'Sensor',
  },
  sensorValue: {
    id: 'ios.history-bolus-details.sensor-value',
    defaultMessage: '150 mg/dL',
  },
});

export const historyBolusCanceledDetailsMessages = defineMessages({
  headerTitle: {
    id: 'ios.history-bolus-canceled-details.header-title',
    defaultMessage: 'Bolus Canceled',
  },
  detailsTitle: {
    id: 'ios.history-bolus-canceled-details.details-title',
    defaultMessage: 'DETAILS',
  },
  canceledDetailsValue: {
    id: 'ios.history-bolus-canceled-details.canceled-details-value',
    defaultMessage:
      'Bolus canceled prior to completion. 4 U (of 6.5 U) delivered.',
  },
  mealBolusTitle: {
    id: 'ios.history-bolus-canceled-details.meal-bolus-title',
    defaultMessage: 'Meal Bolus',
  },
  mealBolusValue: {
    id: 'ios.history-bolus-canceled-details.meal-bolus-value',
    defaultMessage: '4 U',
  },
  correctionBolusTitle: {
    id: 'ios.history-bolus-canceled-details.correction-bolus-title',
    defaultMessage: 'Correction Bolus',
  },
  correctionBolusValue: {
    id: 'ios.history-bolus-canceled-details.correction-bolus-value',
    defaultMessage: '0 U',
  },
  iobTitle: {
    id: 'ios.history-bolus-canceled-details.iob-title',
    defaultMessage: 'IOB Adjustment',
  },
  iobValue: {
    id: 'ios.history-bolus-canceled-details.iob-value',
    defaultMessage: '-1.25 U',
  },
  yourAdjustmentTitle: {
    id: 'ios.history-bolus-canceled-details.your-adjustment-title',
    defaultMessage: 'Your Adjustment',
  },
  yourAdjustmentValue: {
    id: 'ios.history-bolus-canceled-details.your-adjustment-value',
    defaultMessage: '1.25 U',
  },
  totalBolusCalculatedTitle: {
    id: 'ios.history-bolus-canceled-details.total-bolus-calculated-title',
    defaultMessage: 'Total Bolus* (Calculated)',
  },
  totalBolusDelivered: {
    id: 'ios.history-bolus-canceled-details.total-bolus-delivered',
    defaultMessage: '4 U (of 6.5 U) delivered',
  },
  totalBolusValue: {
    id: 'ios.history-bolus-canceled-details.total-bolus-value',
    defaultMessage: '4 U',
  },
  totalBolusDescription: {
    id: 'ios.history-bolus-canceled-details.total-bolus-description',
    defaultMessage:
      '* Bolus doses are rounded down to the nearest 0.05 U increment.',
  },
  startTimeBolusLabel: {
    id: 'ios.history-bolus-canceled-details.start-time-bolus-label',
    defaultMessage: 'Start Time of Bolus',
  },
  startTimeBolusValue: {
    id: 'ios.history-bolus-canceled-details.start-time-bolus-Value',
    defaultMessage: 'Dec 7, 11:11 AM',
  },
  carbsManualLabel: {
    id: 'ios.history-bolus-canceled-details.carbs-manual-label',
    defaultMessage: 'Carbs (Manual)',
  },
  carbsManualValue: {
    id: 'ios.history-bolus-canceled-details.carbs-manual-value',
    defaultMessage: '63 g',
  },
  glucoseLabel: {
    id: 'ios.history-bolus-canceled-details.glucose-label',
    defaultMessage: 'Glucose',
  },
  glucoseValue: {
    id: 'ios.history-bolus-canceled-details.glucose-value',
    defaultMessage: '-- mg/dL',
  },
});
