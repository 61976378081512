import theme from 'mobile/latest/theme';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { ReactComponent as DexcomG7Logo } from 'assets/G7/dexcomG7_Logo.svg';
import { BottomInnerNav } from 'mobile/latest/components/molecules';
import { useNavigation } from '@react-navigation/core';
import { strings } from './ManageSensorG7Content';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { sensors } from 'web/data/sensors';
import { Modal } from 'web/components/molecules';
import CheckPodCompatibility from '../CheckPodCompatibility/CheckPodCompatibility';
import { useState } from 'react';

const ManageSensorG7 = () => {
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);
  const [showCheckPodCompatibilityModal, setShowCheckPodCompatibilityModal] =
    useState(false);
  return (
    <View style={styles.container}>
      <View style={[styles.itemsContainer, styles.currentSensorContainer]}>
        <Text style={[theme.fonts.text400]}>
          {languageContent.currentSensor}
        </Text>
        <View style={styles.switchContainer}>
          <Text style={[theme.fonts.body3]}>{sensors.G7}</Text>
          <TouchableOpacity onPress={() => navigate('SwitchSensorStack')}>
            <Text style={[theme.fonts.text600, { color: theme.colors.purple }]}>
              {`${languageContent.switch} >`}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={[styles.itemsContainer, styles.sensorInformationContainer]}>
        <Text style={[theme.fonts.text400]}>
          {languageContent.sensorInformation}
        </Text>
        <Text style={[theme.fonts.body3]}>{languageContent.pairingCode}</Text>
        <Text>{languageContent.pairingNumber}</Text>
      </View>
      <View style={[styles.itemsContainer, styles.serialNumberContainer]}>
        <Text style={[theme.fonts.body3]}>{languageContent.serialNumber}</Text>
        <Text style={[theme.fonts.body3]}>{languageContent.serialNumb}</Text>
      </View>
      <View style={[styles.itemsContainer, styles.sensorImageContainer]}>
        <DexcomG7Logo width={150} height={150} />
      </View>
      <View>
        <BottomInnerNav
          leftActionStyle={theme.fonts.text400}
          rightActionStyle={theme.fonts.text400}
          middleActionStyle={theme.fonts.text400}
          leftActionText={languageContent.close}
          middleActionText={languageContent.delete}
          rightActionText={languageContent.enterNew}
          leftActionNavigateTo="Drawer"
          rightAction={() => setShowCheckPodCompatibilityModal(true)}
        />
      </View>
      <Modal isVisible={showCheckPodCompatibilityModal}>
        <CheckPodCompatibility
          continueButtonOnPressHandler={() => navigate('InitialSetupG7Stack')}
        />
      </Modal>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors.white,
    display: 'flex',
    gap: 1,
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemsContainer: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderColor: theme.colors.divider,
    borderBottomWidth: 0.5,
    display: 'flex',
    gap: 10,
  },
  currentSensorContainer: {},
  sensorInformationContainer: {},
  serialNumberContainer: {},
  sensorImageContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  bottomInnerNav: {
    fontSize: 12,
  },
});
export default ManageSensorG7;
