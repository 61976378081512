let strings = {
  "en-US": {
    firstNameLabel: "First Name",
    lastNameLabel: "Last Name",
    emailLabel: "Email",
    confirmEmailLabel: "Confirm Email",
    relationshipLabel: "Relationship",
    relationshipOptional: "Relationship (optional)",
    family: "Family",
    invitation: "To invite someone to view your",
    invitationCont:
      "data on their mobile device, fill in their information below.",
    viewerDetails: "Viewer Details",
    modalAutoFill:
      "For simulation purposes, we have auto-filled this information for you.",
    ok: "ok",
    connecting: "Connecting...",
    pleaseWait: "Please wait...",
    leftActionText: "Cancel",
    rightActionText: "add viewer",
    fName: "Maggie",
    lName: "Smith",
    email: "MaggieS@example.com"
  },
  "en-GB": {
    firstNameLabel: "First Name",
    lastNameLabel: "Last Name",
    emailLabel: "Email",
    confirmEmailLabel: "Confirm Email",
    relationshipLabel: "Relationship",
    relationshipOptional: "Relationship (optional)",
    family: "Family",
    invitation: "To invite someone to view your",
    invitationCont:
      "data on their mobile device, fill in their information below.",
    viewerDetails: "Viewer Details",
    modalAutoFill:
      "For simulation purposes, we have auto-filled this information for you.",
    ok: "ok",
    connecting: "Connecting...",
    pleaseWait: "Please wait...",
    leftActionText: "Cancel",
    rightActionText: "add viewer",
    fName: "Maggie",
    lName: "Smith",
    email: "MaggieS@example.com"
  },
  "de-DE": {
    firstNameLabel: "Vorname",
    lastNameLabel: "Nachname",
    emailLabel: "E-Mail",
    confirmEmailLabel: "E-Mail bestätigen",
    relationshipLabel: "Beziehung",
    relationshipOptional: "Beziehung (optional)",
    family: "Familie",
    invitation: "Um eine andere Person einzuladen, sich Ihre",
    invitationCont:
      "Daten auf ihrem mobilen Gerät anzusehen, geben Sie die entsprechenden Informationen unten ein.",
    viewerDetails: "Betrachter-Details",
    modalAutoFill:
      "Zu Simulationszwecken haben wir diese Informationen automatisch für Sie ausgefüllt.",
    ok: "OK",
    connecting: "Verbindungsaufbau ...",
    pleaseWait: "Bitte warten ...",
    leftActionText: "Abbr.",
    rightActionText: "Betrachter hinzufügen",
    fName: "Maggie",
    lName: "Smith",
    email: "MaggieS@example.com"
  },
  "es-US": {
    firstNameLabel: "Nombre",
    lastNameLabel: "Apellido",
    emailLabel: "Correo electrónico",
    confirmEmailLabel: "Confirmar correo electrónico",
    relationshipLabel: "Relación",
    relationshipOptional: "Relación (opcional)",
    family: "Familia",
    invitation: "Si desea invitar a alguien más a ver sus",
    invitationCont:
      "datos en su dispositivo móvil, llene la información siguiente.",
    viewerDetails: "Datos del observador",
    modalAutoFill:
      "Completamos automáticamente esta información para propósitos de simulación.",
    ok: "aceptar",
    connecting: "Conectando...",
    pleaseWait: "Espere...",
    leftActionText: "Cancelar",
    rightActionText: "agregar observador",
    fName: "Maggie",
    lName: "Smith",
    email: "MaggieS@ejemplo.com"
  },
  "fr-FR": {
    firstNameLabel: "Prénom",
    lastNameLabel: "Nom de famille",
    emailLabel: "Courriel",
    confirmEmailLabel: "Confirmer le courriel",
    relationshipLabel: "Relation",
    relationshipOptional: "Relation (facultatif)",
    family: "Famille",
    invitation: "Pour inviter une personne à visualiser vos",
    invitationCont:
      "données sur son appareil mobile, remplissez ses coordonnées ci-dessous.",
    viewerDetails: "Coordonnées du visiteur",
    modalAutoFill:
      "À des fins de simulation, nous avons rempli automatiquement ces informations pour vous.",
    ok: "ok",
    connecting: "Connexion...",
    pleaseWait: "Veuillez patienter...",
    leftActionText: "Annuler",
    rightActionText: "ajouter un visiteur",
    fName: "Maggie",
    lName: "Smith",
    email: "MaggieS@exemple.com"
  },
  "nl-NL": {
    firstNameLabel: "Voornaam",
    lastNameLabel: "Achternaam",
    emailLabel: "E-mail",
    confirmEmailLabel: "E-mail bevestigen",
    relationshipLabel: "Relatie",
    relationshipOptional: "Relatie (niet verplicht)",
    family: "Familie",
    invitation: "Om iemand uit te nodigen uw",
    invitationCont:
      "gegevens op diens mobiele apparaat te bekijken, vult u hieronder zijn/haar gegevens in.",
    viewerDetails: "Details meekijker",
    modalAutoFill:
      "Voor simulatiedoeleinden hebben wij deze informatie automatisch voor u ingevuld.",
    ok: "OK",
    connecting: "Bezig met verbinden...",
    pleaseWait: "Even wachten...",
    leftActionText: "Annuleren",
    rightActionText: "meekijker toevoegen",
    fName: "Maggie",
    lName: "Smith",
    email: "MaggieS@example.com"
  }
};

export { strings };
