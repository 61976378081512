import { LanguageType } from "../types/language";
import { LanguageLongType } from "../types/languageLong";

const LANGUAGES: Array<{ label: LanguageLongType; value: LanguageType }> = [
  { label: "English (US)", value: "en-US" },
  { label: "Español", value: "es-US" },
  { label: "English (UK)", value: "en-GB" },
  { label: "Deutsch", value: "de-DE" },
  { label: "Dutch", value: "nl-NL" },
  { label: "French", value: "fr-FR" }
];

export default LANGUAGES;
