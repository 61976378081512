import React from "react";
import { Dimensions, Text, View } from "react-native-web";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import { strings } from "./TabStackHeaderContent";

interface Props {
  label: string;
  hasIOB?: boolean;
  labelCentered?: boolean;
}

const { height } = Dimensions.get("window");

const TabStackHeader: React.FC<Props> = ({ label, hasIOB, labelCentered }) => {
  const { languageContent } = useLanguageContent(strings);
  return (
    <View
      style={{
        width: "100%",
        backgroundColor: theme.colors.white,
        zIndex: 20,
        shadowColor: "#888",
        shadowOpacity: 0.3,
        shadowRadius: 3,
        shadowOffset: {
          height: 2,
          width: 0
        },
        elevation: 6
      }}>
      <Text
        style={[
          theme.fonts.text200,
          {
            paddingHorizontal: 8,
            paddingVertical: 8,
            fontWeight: height <= 1000 ? "200" : "400",
            textAlign: labelCentered ? "center" : "left"
          }
        ]}>
        {hasIOB && `${languageContent.iob} `}
        <Text
          style={[
            { fontWeight: "700" },
            labelCentered && height >= 995
              ? theme.fonts.h4
              : theme.fonts.text200
          ]}>
          {label}
        </Text>
      </Text>
    </View>
  );
};

export default TabStackHeader;
