import {
  initialState as customFoodsInitialState,
  CustomFoodsState,
} from './reducer';

export const CUSTOM_FOODS_UPDATE = 'custom_foods_update';
export const CUSTOM_FOODS_INIT = 'custom_foods_init';

interface ActionUpdate {
  type: ActionType;
  payload: {
    customFoods: Partial<CustomFoodsState>;
  };
}

interface ActionInit {
  type: ActionType;
  payload: {
    customFoods: CustomFoodsState;
  };
}

type ActionType = typeof CUSTOM_FOODS_UPDATE | typeof CUSTOM_FOODS_INIT;

export const customFoodsUpdate = (
  customFoodsData: Partial<CustomFoodsState>,
  initialState?: CustomFoodsState
): Action => {
  return {
    type: CUSTOM_FOODS_UPDATE,
    payload: {
      customFoods: customFoodsData,
    },
  };
};

export const customFoodsInit = (): Action => {
  return {
    type: CUSTOM_FOODS_INIT,
    payload: {
      customFoods: customFoodsInitialState,
    },
  };
};

export type Action = ActionUpdate | ActionInit;
