let strings = {
  "en-US": {
    cleanSite: "Clean site where Pod will be applied.",
    recordPod: "RECORD POD SITE (optional)",
    removeTab: "Remove tab and",
    checkCannula: "check cannula",
    removeBacking: "Remove paper backing.",
    checkPod: "Check Pod",
    applySite: "and apply it to site.",
    tap: "Tap",
    startLower: "start",
    insertCannulaBasal: "to insert cannula and begin \nyour basal delivery.",
    start: "Start",
    cancel: "Cancel",
    cannulaStep: "Inserting the cannula",
    deactiveTitle: "Deactivating Pod",
    deactiveMessage: "Please wait...",
    removePod: "Remove Pod",
    removePodDelivering:
      "Your Pod may still be delivering insulin. Remove it from your body, then tap ",
    continue: "CONTINUE.",
    continueCamel: "Continue",
    isPod: "Is Pod securely in place?",
    securelyAttached: "If the Pod is securely attached to your body, tap",
    yes: "YES",
    insertCannula: "to insert the cannula.",
    insertedProperly: "Is cannula properly inserted?",
    pinkColor:
      "A pink color at the top of the Pod confirms that the cannula is inserted properly.",
    deactivatePod: "Deactivate your Pod?",
    deactivatePodMessage:
      "If you deactivate this Pod you will not be able to use it. You will need to set up a new Pod.",
    deactivateConfirm: "Deactivate Pod",
    disabledForSim: "Disabled for Simulator",
    detailsForRecording:
      "Details for recording a Pod site are only available in the full application and are disabled for the simulation.",
    OK: "OK",
    detailsForChecking:
      "Details for checking your Pod are only available in the full application and are disabled for the simulation.",
    selectAPod: "Select a Pod Site",
    useThePodSites: "Use the Pod Site map to track your recent Pod sites.",
    checkTheCannula: "Check the cannula",
    cannulaSticksOut:
      "If the cannula sticks out beyond the adhesive backing, you cannot use this Pod. Tap",
    checkThePod: "Check the Pod",
    cancelCaps: "CANCEL",
    podDamaged:
      "If your Pod is damaged or dirty, or if the adhesive is wet or folded, tap",
    switchToAutomated: "Switch to Automated Mode?",
    confirmText: "Yes",
    dismissText: "No"
  },
  "en-GB": {
    cleanSite: "Clean site where Pod will be applied.",
    recordPod: "RECORD POD SITE (optional)",
    removeTab: "Remove tab and",
    checkCannula: "check cannula",
    removeBacking: "Remove paper backing.",
    checkPod: "Check Pod",
    applySite: "and apply it to site.",
    tap: "Tap",
    startLower: "start",
    insertCannulaBasal: "to insert cannula and begin \nyour basal delivery.",
    start: "Start",
    cancel: "Cancel",
    cannulaStep: "Inserting the cannula",
    deactiveTitle: "Deactivating Pod",
    deactiveMessage: "Please wait…",
    removePod: "Remove Pod",
    removePodDelivering:
      "Your Pod may still be delivering insulin. Remove it from your body, then tap ",
    continue: "CONTINUE.",
    continueCamel: "Continue",
    isPod: "Is Pod securely in place?",
    securelyAttached: "If the Pod is securely attached to your body, tap",
    yes: "YES",
    insertCannula: "to insert the cannula.",
    insertedProperly: "Is cannula properly inserted?",
    pinkColor:
      "A pink colour at the top of the Pod confirms that the cannula is inserted properly.",
    deactivatePod: "Deactivate your Pod?",
    deactivatePodMessage:
      "If you deactivate this Pod you will not be able to use it. You will need to set up a new Pod.",
    deactivateConfirm: "Deactivate Pod",
    disabledForSim: "Disabled for Simulator",
    detailsForRecording:
      "Details for recording a Pod site are only available in the full application and are disabled for the simulation.",
    OK: "OK",
    detailsForChecking:
      "Details for checking your Pod are only available in the full application and are disabled for the simulation.",
    selectAPod: "Select a Pod Site",
    useThePodSites: "Use the Pod Site map to track your recent Pod sites.",
    checkTheCannula: "Check the cannula",
    cannulaSticksOut:
      "If the cannula sticks out beyond the adhesive backing, you cannot use this Pod. Tap",
    checkThePod: "Check the Pod",
    cancelCaps: "CANCEL",
    podDamaged:
      "If your Pod is damaged or dirty, or if the adhesive is wet or folded, tap",
    switchToAutomated: "Switch to Automated Mode?",
    confirmText: "Yes",
    dismissText: "No"
  },
  "de-DE": {
    cleanSite: "Platzierungsstelle säubern, an der der \nPod angebracht wird.",
    recordPod: "POD-PLATZIERUNGSSTELLE \nAUFZEICHNEN (optional)",
    removeTab: "Lasche entfernen und",
    checkCannula: "Kanüle \nüberprüfen",
    removeBacking: "Dann Abdeckpapier entfernen.",
    checkPod: "Pod prüfen",
    applySite: "und dann an der \nPod-Platzierungsstelle anbringen.",
    tap: "Auf",
    startLower: "Starten",
    insertCannulaBasal:
      "tippen, um die Kanüle \neinzuführen und die Basalratenabgabe \nzu starten.",
    start: "Starten",
    cancel: "Abbr.",
    cannulaStep: "Kanüle wird eingeführt",
    deactiveTitle: "Pod wird deaktiviert",
    deactiveMessage: "Bitte warten ...",
    removePod: "Pod entfernen",
    removePodDelivering:
      "Möglicherweise gibt Ihr Pod weiterhin Insulin ab. Entfernen Sie ihn von Ihrem Körper und tippen Sie auf",
    continue: "WEITER.",
    continueCamel: "Weiter",
    isPod: "Ist der Pod sicher an seinem Platz?",
    securelyAttached:
      "Wenn der Pod sicher an Ihrem Körper besfestigt ist, tippen Sie auf",
    yes: "JA",
    insertCannula: "um die Kanüle einzuführen.",
    insertedProperly: "Ist die Kanüle korrekt eingeführt?",
    pinkColor:
      "Eine rosa Einfärbung an der Oberseite des Pods bestätigt, dass die Kanüle ordnungsgemäß eingeführt wurde.",
    deactivatePod: "Soll der Pod jetzt deaktiviert werden?",
    deactivatePodMessage:
      "Wenn Sie diesen Pod deaktivieren, werden Sie ihn nicht verwenden können. Sie werden einen neuen Pod einrichten müssen.",
    deactivateConfirm: "Pod deaktivieren",
    disabledForSim: "Für Simulator deaktiviert",
    detailsForRecording:
      "Die Details zur Aufzeichnung einer Pod-Platzierungsstelle sind nur in der vollständigen Anwendung verfügbar und für die Simulation deaktiviert.",
    OK: "OK",
    detailsForChecking:
      "Die Details zur Überprüfung Ihres Pods sind nur in der vollständigen Anwendung verfügbar und wurden für die Simulation deaktiviert.",
    selectAPod: "Pod-Platzierungsstelle auswählen",
    useThePodSites:
      "In der Pod-Platzierungsstellen-Übersicht können Sie die zuvor verwendeten Pod-Platzierungsstellen erkennen.",
    checkTheCannula: "Kanüle überprüfen",
    cannulaSticksOut:
      "Wenn die Kanüle über das Klebepolster hinausragt, können Sie diesen Pod nicht verwenden. Tippen Sie auf",
    checkThePod: "Pod prüfen",
    cancelCaps: "ABBRECHEN",
    podDamaged:
      "Falls der Pod beschädigt oder verschmutzt oder das Klebepolster feucht oder gefaltet ist, tippen Sie auf",
    switchToAutomated: "Auf automatisierten Modus umschalten?",
    confirmText: "Ja",
    dismissText: "Nein"
  },
  "es-US": {
    cleanSite: "Limpie el sitio donde aplicará el Pod.",
    recordPod: "REGISTRAR EL SITIO DEL POD \n(opcional)",
    removeTab: "Retire la pestaña y",
    checkCannula: "verifique el estado \nde la cánula",
    removeBacking: "",
    checkPod: "Verifique el estado del Pod",
    applySite: "y colóquelo \nen el sitio.",
    tap: "Pulse",
    startLower: "INICIAR",
    insertCannulaBasal:
      "para insertar la cánula y \ncomenzar la administración basal.",
    start: "Iniciar",
    cancel: "Cancelar",
    cannulaStep: "Insertando la cánula",
    deactiveTitle: "Desactivando el Pod",
    deactiveMessage: "Espere...",
    removePod: "Quitar Pod",
    removePodDelivering:
      "Es posible que su Pod todavía esté administrando insulina. Quítelo de su cuerpo y luego toque ",
    continue: "CONTINUAR.",
    continueCamel: "Continuar",
    isPod: "¿Está bien colocado el Pod en su sitio?",
    securelyAttached: "Si el Pod está bien sujeto a su cuerpo, toque",
    yes: "SÍ",
    insertCannula: "para insertar la cánula.",
    insertedProperly: "¿La cánula se insertó correctamente?",
    pinkColor:
      "Una indicación de color rosa en la parte superior del Pod confirma que la cánula se insertó correctamente.",
    deactivatePod: "¿Desactivar el Pod?",
    deactivatePodMessage:
      "Si desactiva este Pod, no podrá usarlo. Deberá configurar un nuevo Pod.",
    deactivateConfirm: "Desactivar Pod",
    disabledForSim: "Inhabilitado para el simulador",
    detailsForRecording:
      "Los detalles para registrar un sitio de Pod están disponibles solo en la aplicación completa y se inhabilitan para la simulación.",
    OK: "Aceptar",
    detailsForChecking:
      "Los detalles para revisar su Pod están disponibles solo en la aplicación completa y se inhabilitan para la simulación.",
    selectAPod: "Seleccione un sitio del Pod",
    useThePodSites:
      "Use el mapa de Sitios del Pod para rastrear sus sitios recientes.",
    checkTheCannula: "Verifique el estado de la cánula",
    cannulaSticksOut:
      "Si la cánula sobresale de la parte adhesiva, no puede usar este Pod. Pulse",
    checkThePod: "Verifique el estado del Pod",
    cancelCaps: "CANCELAR",
    podDamaged:
      "Si un Pod está dañado, sucio o si el adhesivo está mojado o plegado, pulse",
    switchToAutomated: "¿Cambiar al Modo Automatizado?",
    confirmText: "Sí",
    dismissText: "No"
  },
  "fr-FR": {
    cleanSite: "Nettoyez le site où le Pod sera appliqué.",
    recordPod: "CONSIGNEZ LE SITE DU POD (facultatif)",
    removeTab: "Retirez la languette et",
    checkCannula: "vérifiez la canule",
    removeBacking: "Retirez le film en papier",
    checkPod: "Vérifiez le Pod",
    applySite: "et appliquez-le sur le site",
    tap: "Appuyez sur",
    startLower: "démarrer",
    insertCannulaBasal:
      "pour introduire la canule et lancer l'administration basale",
    start: "Démarrer",
    cancel: "Annuler",
    cannulaStep: "Insertion de la canule",
    deactiveTitle: "Désactivation du Pod",
    deactiveMessage: "Veuillez patienter...",
    removePod: "Retirer le Pod",
    removePodDelivering:
      "Votre Pod administre peut-être toujours de l'insuline. Retirez-le, puis appuyez sur",
    continue: "CONTINUER.",
    continueCamel: "Continuer",
    isPod: "Le Pod est-il bien en place ?",
    securelyAttached: "Si le Pod est bien fixé à votre corps, appuyez sur",
    yes: "OUI",
    insertCannula: "pour insérer la canule.",
    insertedProperly: "La canule est-elle bien insérée ?",
    pinkColor:
      "Du rose en haut du Pod confirme que la canule est correctement insérée.",
    deactivatePod: "Désactiver votre Pod ?",
    deactivatePodMessage:
      "Si vous désactivez ce Pod, vous ne serez pas en mesure de l'utiliser. Vous devrez configurer un nouveau Pod.",
    deactivateConfirm: "Désactiver le Pod",
    disabledForSim: "Désactivé pour le simulateur",
    detailsForRecording:
      "Les informations détaillées pour consigner le site d'un Pod ne sont disponibles que dans l'application complète et sont désactivées pour la simulation.",
    OK: "OK",
    detailsForChecking:
      "Les informations détaillées pour vérifier votre Pod ne sont disponibles que dans l'application complète et sont désactivées pour la simulation.",
    selectAPod: "Choisir un site de Pod",
    useThePodSites:
      "Utiliser la carte de sites de Pod pour suivre les sites de Pods récemment utilisés.",
    checkTheCannula: "Vérifier la canule",
    cannulaSticksOut:
      "Si la canule dépasse du tampon adhésif, vous ne pouvez pas utiliser ce Pod. Appuyez sur",
    checkThePod: "Vérifier le Pod",
    cancelCaps: "ANNULER",
    podDamaged:
      "Si votre Pod est endommagé, sale ou si l'adhésif est mouillé ou plié, appuyez sur",
    switchToAutomated: "Passer en Mode Automatisé ?",
    confirmText: "Oui",
    dismissText: "Non"
  },
  "nl-NL": {
    cleanSite: "Reinig de plaats waar de Pod zal \nworden aangebracht.",
    recordPod: "PODPLAATS REGISTREREN (optioneel)",
    removeTab: "Verwijder tabblad en",
    checkCannula: "canule controleren",
    removeBacking: "Verwijder de papieren beschermlaag",
    checkPod: "Pod controleren",
    applySite: "en op plaats \naanbrengen",
    tap: "Tik op",
    startLower: "start",
    insertCannulaBasal:
      "om canule in te brengen \nen met uw basaaltoediening te \nbeginnen.",
    start: "Start",
    cancel: "Annuleren",
    cannulaStep: "De canule inbrengen",
    deactiveTitle: "Deactivering van Pod",
    deactiveMessage: "Even wachten...",
    removePod: "Pod verwijderen",
    removePodDelivering:
      "Het kan zijn dat de Pod nog insuline toedient. Verwijder de Pod van uw lichaam en tik op",
    continue: "DOORGAAN.",
    continueCamel: "Doorgaan",
    isPod: "Zit de Pod stevig op zijn plaats?",
    securelyAttached: "Als de Pod goed op uw lichaam is aangebracht, tikt u op",
    yes: "JA",
    insertCannula: "om de canule in te brengen.",
    insertedProperly: "Is de canule correct ingebracht?",
    pinkColor:
      "Een roze kleur bovenaan de Pod bevestigt dat de canule correct is ingebracht.",
    deactivatePod: "Uw Pod deactiveren?",
    deactivatePodMessage:
      "Als u deze Pod deactiveert, kunt u hem niet gebruiken. U moet een nieuwe Pod instellen.",
    deactivateConfirm: "Pod deactiveren",
    disabledForSim: "Uitgeschakeld voor simulator",
    detailsForRecording:
      "Details voor het registreren van een Pod-plaats zijn alleen beschikbaar in de volledige applicatie en zijn uitgeschakeld voor de simulatie.",
    OK: "OK",
    detailsForChecking:
      "Details voor het controleren van uw Pod zijn alleen beschikbaar in de volledige applicatie en zijn uitgeschakeld voor de simulatie.",
    selectAPod: "Plaats voor Pod kiezen",
    useThePodSites:
      "U kunt via het Pod-plaatsoverzicht kijken welke Pod-plaatsen recent gebruikt zijn.",
    checkTheCannula: "De canule controleren",
    cannulaSticksOut:
      "Als de canule uitsteekt buiten de beschermlaag van de pleister, mag u deze Pod niet gebruiken. Tik op",
    checkThePod: "Controleer de Pod",
    cancelCaps: "ANNULEREN",
    podDamaged:
      "Als de Pod beschadigd of vuil is, of als de beschermlaag nat of gevouwen is, tik op",
    switchToAutomated: "Wijzigen naar de automatische modus?",
    confirmText: "Ja",
    dismissText: "Nee"
  }
};

export { strings };
