import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'web/components/ios/Button';
import { selectCurrentOperationMode } from 'web/store/reducers/user/user.helpers';
import { ReactComponent as WeekendProgramActiveGraph } from 'assets/ios/basal-segments/weekend-segments-active.svg';
import { ReactComponent as WeekendProgramPausedGraph } from 'assets/ios/basal-segments/weekend-segments-paused.svg';
import { ReactComponent as RelaxDayProgramActiveGraph } from 'assets/ios/basal-segments/relax-day-segments-active.svg';
import { ReactComponent as RelaxDayProgramPausedGraph } from 'assets/ios/basal-segments/relax-day-segments-paused.svg';
import { selectIsInsulinPaused } from 'web/store/reducers/basal/basal.helpers';
import { selectActiveBasalProgram } from 'web/store/reducers/basalPrograms/basalPrograms.helpers';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';
import { AppRoutesEnum } from 'web/routes/types';
import { hideModal } from 'web/store/reducers/modals/modalsSlice';
import {
  dashboardPodDetailsMessages,
  dashboardInsulinDetailsMessages,
  iobInfoMessages,
} from './DashboardModals.messages';
import { ConfirmationModalProps } from '../types';
import { Modal } from '../../Modal';
import { InsulinCircleIcon, PodCircleIcon } from '../../icons';
import { BottomModal } from '../../BottomModal';

export const DashboardPodDetailsModal: React.FC<ConfirmationModalProps> = ({
  onConfirm,
}) => {
  const operationMode = useSelector(selectCurrentOperationMode);

  return (
    <BottomModal>
      <div className="flex flex-row items-center">
        <div>
          <PodCircleIcon color={`fill-modes-${operationMode}`} />
        </div>
        <div className="ml-2">
          <div className="text-base text-ios-secondaryGray opacity-60">
            <FormattedMessage
              {...dashboardPodDetailsMessages.updatedTodayTitle}
            />
          </div>
          <div className="font-bold text-xl mt-[4px]">
            <FormattedMessage {...dashboardPodDetailsMessages.podTitle} />
          </div>
        </div>
      </div>

      <hr className="text-bg my-[12.5px]" />
      <div className="font-semibold text-base text-ios-secondaryGray opacity-60">
        <FormattedMessage {...dashboardPodDetailsMessages.insulinLeftTitle} />
      </div>
      <div className="font-bold mt-[12px] leading-none  text-[40px]">
        <FormattedMessage {...dashboardPodDetailsMessages.unitsTitle} />
      </div>
      <div className="font-semibold mt-3 text-base text-ios-secondaryGray opacity-60">
        <FormattedMessage {...dashboardPodDetailsMessages.podStatusTitle} />
      </div>
      <div className="font-bold text-xl mt-2">
        <FormattedMessage
          {...dashboardPodDetailsMessages.expiresTomorrowTitle}
        />
      </div>
      <div className="font-semibold mt-[4px] text-base text-ios-secondaryGray opacity-60">
        <FormattedMessage {...dashboardPodDetailsMessages.februarySubtitle} />
      </div>

      <Button
        colorMode={operationMode}
        className={'w-full rounded-lg mt-3 mb-3'}
        onClick={onConfirm}
        variant="colored"
      >
        <FormattedMessage
          {...dashboardPodDetailsMessages.viewPodDetailsButton}
        />
      </Button>
    </BottomModal>
  );
};

interface DashboardInsulinModalProps {
  onCtaClick: () => void;
}

export const DashboardInsulinModal: React.FC<DashboardInsulinModalProps> = ({
  onCtaClick,
}) => {
  const dispatch = useDispatch();
  const operationMode = useSelector(selectCurrentOperationMode);
  const activeBasalProgram = useSelector(selectActiveBasalProgram);
  const isInsulinPaused = useSelector(selectIsInsulinPaused);

  const handleOnClose = () => {
    dispatch(
      navigateToRoute(AppRoutesEnum.dashboard, {
        showBottomInsulinModal: false,
      })
    );
  };

  const handleViewBasalProgramsButtonClick = () => {
    if (onCtaClick) {
      onCtaClick();
    }
  };

  const circleIconColor = isInsulinPaused
    ? 'fill-ios-insulinIconGray'
    : `fill-modes-${operationMode}`;

  const renderGraph = () => {
    switch (activeBasalProgram?.name) {
      case 'Relax Day':
        return isInsulinPaused ? (
          <RelaxDayProgramPausedGraph />
        ) : (
          <RelaxDayProgramActiveGraph />
        );
      case 'Weekend':
      default:
        return isInsulinPaused ? (
          <WeekendProgramPausedGraph />
        ) : (
          <WeekendProgramActiveGraph />
        );
    }
  };

  return (
    <BottomModal onClose={handleOnClose}>
      <div className="flex flex-row items-center">
        <div>
          <InsulinCircleIcon color={circleIconColor} />
        </div>
        <div className="ml-2">
          <div className="text-base text-ios-secondaryGray opacity-60">
            <FormattedMessage
              {...dashboardInsulinDetailsMessages.updatedTodayText}
            />
          </div>
          <div className="font-bold text-xl">
            <FormattedMessage
              {...dashboardInsulinDetailsMessages.insulinTitle}
            />
          </div>
        </div>
      </div>

      <hr className="text-bg my-2" />
      <div className="font-semibold text-base text-ios-secondaryGray opacity-60">
        {isInsulinPaused ? (
          <FormattedMessage {...dashboardInsulinDetailsMessages.pausedTitle} />
        ) : (
          <FormattedMessage
            {...dashboardInsulinDetailsMessages.inProgressTitle}
          />
        )}
      </div>
      <div
        className={classNames('font-bold mt-[4px]  text-[22px] ', {
          'text-ios-secondaryGray opacity-60': isInsulinPaused,
        })}
      >
        {activeBasalProgram?.name}
      </div>
      <div className="font-semibold mt-[4px] text-base">
        <FormattedMessage
          {...dashboardInsulinDetailsMessages.totalTitle}
          values={{ amount: activeBasalProgram?.unitsTotal }}
        />
      </div>
      <div className="mt-1">
        {renderGraph()}
      </div>

      <Button
        colorMode={operationMode}
        className={'w-full rounded-lg mb-3'}
        onClick={handleViewBasalProgramsButtonClick}
        variant="colored"
      >
        <FormattedMessage
          {...dashboardInsulinDetailsMessages.viewBasalProgramsButtonTitle}
        />
      </Button>
    </BottomModal>
  );
};

export const IobInfoModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Modal
      title={intl.formatMessage(iobInfoMessages.title)}
      rightButtonText={intl.formatMessage(iobInfoMessages.ok)}
      rightButtonOnClick={() => dispatch(hideModal())}
    >
      <FormattedMessage {...iobInfoMessages.text} />
    </Modal>
  );
};
