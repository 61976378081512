import { UserState } from './index';
import { initialState as userInitState, jumpStartState } from './reducer';

export const USER_UPDATE = 'user_update';
export const USER_RESET = 'user_reset';
export const USER_INIT = 'user_init';
export const SWITCH_TO_MANUAL_MODE = 'switch_to_manual_mode';
export const SWITCH_TO_AUTO_MODE = 'switch_to_auto_mode';
export const SET_ACTIVITY_MODE = 'set_activity_mode';
export const CANCEL_ACTIVITY_MODE = 'cancel_activity_mode';
export const SET_TEMP_BASAL = 'set_temp_basal';
export const CANCEL_TEMP_BASAL = 'cancel_temp_basal';

export interface Action {
  type: ActionType;
  payload: {
    user?: Partial<UserState> | UserState;
    endDate?: Date;
  };
}

type ActionType =
  | typeof USER_UPDATE
  | typeof USER_RESET
  | typeof USER_INIT
  | typeof SWITCH_TO_AUTO_MODE
  | typeof SWITCH_TO_MANUAL_MODE
  | typeof SET_ACTIVITY_MODE
  | typeof CANCEL_ACTIVITY_MODE
  | typeof SET_TEMP_BASAL
  | typeof CANCEL_TEMP_BASAL;

export const userUpdate = (
  userData: Partial<UserState>,
  initialState: UserState
): Action => {
  return {
    type: USER_UPDATE,
    payload: {
      user: userData,
    },
  };
};

export const userReset = (): Action => {
  return {
    type: USER_RESET,
    payload: {
      user: jumpStartState,
    },
  };
};

export const userInit = (): Action => {
  return {
    type: USER_INIT,
    payload: {
      user: userInitState,
    },
  };
};

export const switchToManualMode = () => {
  return {
    type: SWITCH_TO_MANUAL_MODE,
  };
};

export const switchToAutoMode = () => {
  return {
    type: SWITCH_TO_AUTO_MODE,
  };
};

interface ActivityModePayload {
  endDate: Date;
}

export const setActivityMode = ({ endDate }: ActivityModePayload): Action => {
  return {
    type: SET_ACTIVITY_MODE,
    payload: {
      endDate,
    },
  };
};

export const cancelActivityMode = () => {
  return {
    type: CANCEL_ACTIVITY_MODE,
  };
};

interface TempBasalPayload {
  endDate: Date;
}

export const setTempBasal = ({ endDate }: TempBasalPayload): Action => {
  return {
    type: SET_TEMP_BASAL,
    payload: {
      endDate,
    },
  };
};

export const cancelTempBasal = () => {
  return {
    type: CANCEL_TEMP_BASAL,
  };
};
