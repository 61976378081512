import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { NavigationContainer } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import {
  DashboardScreen,
  PodInfoScreen,
  ProtectScreen
} from "../../components/screens";
import useLanguageContent from "../../hooks/useLanguageContent";
import { RootState } from "../../../../web/store/store";
import theme from "../../theme";
import { strings } from "./HypoProtectTabStackContent";

interface Props {
  activeTab: number;
  setActiveTab: (index: number) => void;
}

const HypoProtectTabStack: React.FC<Props> = ({ activeTab, setActiveTab }) => {
  const userState = useSelector((state: RootState) => state.user, isEqual);

  const { languageContent } = useLanguageContent(strings);

  const TabTheme = {
    dark: false,
    colors: {
      primary: theme.colors.blue,
      background: userState.limitedMode
        ? theme.colors.limitedMode
        : userState.automatedMode
        ? theme.colors.purple
        : theme.colors.tabBackground,
      card: theme.colors.white
    }
  };

  const tabBarOptions = {
    activeTintColor: "white",
    inactiveTintColor: "black",
    indicatorStyle: { backgroundColor: "red", height: "100%" },
    pressOpacity: 1
  };

  const Tab = createMaterialTopTabNavigator();

  React.useEffect(() => {
    document.querySelectorAll("[role=tab]").forEach((el, index) => {
      if (index === 0) {
        (el as HTMLElement).style.paddingLeft = "4px";
      }
      if (index === 1) {
        (el as HTMLElement).style.backgroundColor =
          theme.colors.hypoProtectGreen;
      }
    });
  }, []);

  return (
    <NavigationContainer independent theme={TabTheme as any}>
      <Tab.Navigator
        initialRouteName="Dashboard"
        tabBarOptions={{
          activeTintColor: theme.colors.blue,
          inactiveTintColor: theme.colors.grayScale.gray200,
          allowFontScaling: false,

          labelStyle: {
            fontSize: 14,
            fontWeight: "400",
            alignSelf: "center",
            width: "100%"
          },
          style: {
            shadowColor: "#888",
            shadowOpacity: 0.4,
            shadowRadius: 3,
            shadowOffset: {
              height: 3,
              width: 0
            },
            elevation: 8
          }
        }}
        tabBarPosition="top">
        <Tab.Screen
          name={languageContent.dash}
          component={DashboardScreen}
          listeners={{
            focus: (e: any) => {
              setActiveTab(0);
            }
          }}
        />
        <Tab.Screen
          name={languageContent.hypo}
          component={ProtectScreen}
          listeners={{
            focus: (e: any) => {
              setActiveTab(1);
            }
          }}
        />
        <Tab.Screen
          name={languageContent.pod}
          component={PodInfoScreen}
          listeners={{
            focus: (e: any) => {
              setActiveTab(2);
            }
          }}
        />
      </Tab.Navigator>
    </NavigationContainer>
  );
};

export default HypoProtectTabStack;
