import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { IOSRoutesEnums } from '../../FtsStartScreenStack/types';
import { FormattedMessage } from 'react-intl';
import { messages } from '../SettingsScreenStack.messages';
import { ReactComponent as ChevronRightIcon } from '../../assets/chevron-right.svg';
import { selectCurrentOperationMode } from 'web/store/reducers/user/user.helpers';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

export const GeneralSettingsScreen = () => {
  const { navigate } = useNavigation();
  const operationMode = useSelector(selectCurrentOperationMode);

  return (
    <div className="flex justify-between flex-col flex-1 items-stretch bg-ios-gray-6">
      <div className="flex flex-col">
        <div
          className={classNames(
            'mt-3 bg-white px-2 py-1 flex flex-row items-center justify-between border-b-0.5 border-solid border-ios-secondaryGray/[.36]',
            {
              'opacity-30': operationMode === 'automated',
              'cursor-pointer': operationMode === 'manual',
            }
          )}
          onClick={
            operationMode === 'manual'
              ? () => navigate(IOSRoutesEnums.Timezone)
              : undefined
          }
        >
          <div>
            <div className="text-black">
              <FormattedMessage {...messages.insulinDeliveryTimeZoneTitle} />
            </div>
            <div className="text-ios-secondaryGray text-sm opacity-60">
              <FormattedMessage {...messages.insulinDeliveryTimeZoneMessage} />
            </div>
          </div>
          <div>
            <ChevronRightIcon />
          </div>
        </div>
        <div
          className={classNames(
            'bg-white px-2 py-1 flex flex-row items-center justify-between border-b-ios-secondaryGray/[.36] border-b-0.5',
            {
              'opacity-30': operationMode === 'automated',
              'cursor-pointer': operationMode === 'manual',
            }
          )}
          onClick={
            operationMode === 'manual'
              ? () => navigate(IOSRoutesEnums.CheckAlarms)
              : undefined
          }
        >
          <div>
            <div className="text-black">
              <FormattedMessage {...messages.checkAlarmsTitle} />
            </div>
            <div className="text-ios-secondaryGray text-sm opacity-60">
              <FormattedMessage {...messages.checkAlarmsMessage} />
            </div>
          </div>
          <div>
            <ChevronRightIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
