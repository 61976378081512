let strings = {
  "en-US": {
    graph: "Graph: U/hr",
    basalRate: "Basal Rate",
    duration: "Duration",
    upTo: "(up to 30 U/hr)",
    hrs: "hrs",
    min: "(30 min to 12 hrs)",
    presets: "Select From Presets",
    editingModalTitle: "Edit Temp Basal",
    editingModalMessage: "Not available for the simulator.",
    confirmText: "Ok",
    leftActionText: "CANCEL",
    rightActionText: "CONFIRM"
  },
  "en-GB": {
    graph: "Graph: U/hr",
    basalRate: "Basal Rate",
    duration: "Duration",
    upTo: "(up to 30 U/hr)",
    hrs: "hrs",
    min: "(30 mins to 12 hrs)",
    presets: "Select From Presets",
    editingModalTitle: "Edit Temp Basal",
    editingModalMessage: "Not available for the simulator.",
    confirmText: "Ok",
    leftActionText: "CANCEL",
    rightActionText: "CONFIRM"
  },
  "de-DE": {
    graph: "Grafik: E/Std.",
    basalRate: "Basalrate",
    duration: "Dauer",
    upTo: "(bis zu 30 E/Std.)",
    hrs: "Stdn.",
    min: "(30 Min. bis 12 Stdn.)",
    presets: "Aus Vorgabewerten auswählen",
    editingModalTitle: "Temporäre Basalrate bearbeiten",
    editingModalMessage: "Nicht für den Simulator verfügbar.",
    confirmText: "OK",
    leftActionText: "Abbr.",
    rightActionText: "BESTÄTIGEN"
  },
  "es-US": {
    graph: "Gráfico: U/h",
    basalRate: "Tasa basal",
    duration: "Duración",
    upTo: "(hasta 30 U/h)",
    hrs: "h",
    min: "(30 min a 12 h)",
    presets: "Seleccionar entre preajustes",
    editingModalTitle: "Editar basal temporal",
    editingModalMessage: "No está disponible en el simulador.",
    confirmText: "Aceptar",
    leftActionText: "CANCELAR",
    rightActionText: "CONFIRMAR"
  },
  "fr-FR": {
    graph: "Graphique : U/h",
    basalRate: "Débit basal",
    duration: "Durée",
    upTo: "(jusqu'à 30 U/h)",
    hrs: "h",
    min: "(30 min à 12 h)",
    presets: "Sélectionner depuis les préréglages",
    editingModalTitle: "Modifier le débit basal temporaire",
    editingModalMessage: "Non disponible pour le simulateur.",
    confirmText: "Ok",
    leftActionText: "ANNULER",
    rightActionText: "CONFIRMER"
  },
  "nl-NL": {
    graph: "Grafiek: E/u.",
    basalRate: "Basaalsnelheid",
    duration: "Duur",
    upTo: "(tot 30 E/u.)",
    hrs: "u.",
    min: "(30 min. tot 12 u.)",
    presets: "Kies uit voorinstellingen",
    editingModalTitle: "Tijdelijke basaalsnelheid bewerken",
    editingModalMessage: "Niet beschikbaar voor de simulator.",
    confirmText: "OK",
    leftActionText: "ANNULEREN",
    rightActionText: "BEVESTIGEN"
  }
};

export { strings };
