let strings = {
  'en-US': {
    enter: 'Enter Serial Number',
    printed:
      'The Sensor serial number (SN) is printed on the sensor applicator.',
    setUp:
      'If you have already set up your Sensor in the Dexcom app, you can find the pairing code and serial number in your Dexcom app Sensor settings.',
    save: 'Save',
    cancel: 'Cancel',
    confirmPairingCode: 'Confirm Serial Number',
    codeNumber: '1 2 3 4    1 2 3 4    1 2 3 4',
    confirm: 'Confirm',
    // The following strings are used when you are on the enter serial number flow and you click cancel
    cancelPairing: 'Cancel new Pairing Code?',
    loseChanges:
      'You will lose any changes made on the current screen if you cancel.',
  },
  'es-US': {
    enter: 'Ingresar Número de Serie',
    printed:
      'El Número de Serie (SN) del Sensor está impreso en el aplicador del Sensor.',
    setUp:
      'Si ya tiene configurado su Sensor en la aplicación de Dexcom, puede encontrar el código de emparejamiento y el Número de serie en sus ajustes del Sensor de la aplicación de Dexcom.',
    save: 'Guardar',
    cancel: 'Cancelar',
    confirmPairingCode: 'Confirmar el Número de Serie',
    codeNumber: '1 2 3 4    1 2 3 4    1 2 3 4',
    confirm: 'Confirmar',
    // The following strings are used when you are on the enter serial number flow and you click cancel
    cancelPairing: '¿Cancelar nuevo Código de Emparejamiento?',
    loseChanges:
      'Perderá los cambios realizados en la pantalla actual si cancela.',
  },
};

export { strings };
