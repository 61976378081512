import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Animated } from 'react-native';
import { useIntl } from 'react-intl';
import { messages } from './BasalTempSettingsStack.messages';
import { TopBar } from 'web/components/ios/TopBar';
import { BasalTempSettings } from './views/BasalTempSettings';
import { MaxBasalRate } from './views/MaxBasalRate';
import { BasalTempSettingRoutesEnum } from './types';
import { TempBasal } from './views/TempBasal';

const Stack = createStackNavigator();

const IOSBasalTempSettingsStack: React.FC = () => {
  const intl = useIntl();
  const headerRef = new Animated.ValueXY().y;

  const animateHeader = React.useCallback(() => {
    Animated.timing(headerRef, {
      toValue: 0,
      duration: 600,
      useNativeDriver: false,
    }).start();
  }, [headerRef]);

  React.useEffect(() => {
    animateHeader();
  }, [animateHeader]);

  return (
    <>
      <Stack.Navigator initialRouteName="BolusSettingsMain" mode="modal">
        <Stack.Screen
          name="BolusSettingsMain"
          options={{
            headerTitle: intl.formatMessage(
              messages.topBarBasalTempSettingsTitle
            ),
            header: (props) => (
              <TopBar {...props} backMode hideTitleOverflow={false} />
            ),
          }}
          component={BasalTempSettings}
        />
        <Stack.Screen
          name={BasalTempSettingRoutesEnum.MaxBasalRate}
          options={{
            headerTitle: intl.formatMessage(messages.topBarMaxBasalRateTitle),
            header: (props) => <TopBar {...props} variant="Cancel" />,
          }}
          component={MaxBasalRate}
        />
        <Stack.Screen
          name={BasalTempSettingRoutesEnum.TempBasal}
          options={{
            headerTitle: intl.formatMessage(messages.topBarTempBasalTitle),
            header: (props) => <TopBar {...props} variant="Cancel" />,
          }}
          component={TempBasal}
        />
      </Stack.Navigator>
    </>
  );
};

export default IOSBasalTempSettingsStack;
