import { useNavigation } from "@react-navigation/native";
import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native-web";
import { ReactComponent as Logo } from "../../../../../../../assets/Omnipod_5_Simulator_LOGO_MANGO.svg";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { SettingsSection } from "../../../../organisms";
import { strings } from "./AboutScreenContent";

const AboutScreen: React.FC = () => {
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <ScrollView style={{ backgroundColor: "white" }}>
      <View style={styles.bodyContainer}>
        <View style={styles.logoContainer}>
          <Logo />
        </View>
        <View>
          <Text style={styles.text}>{languageContent.version}</Text>
          <View style={[styles.subtext, { fontWeight: 300 }]}>
            <Text style={{ marginBottom: 4 }}>{languageContent.insulet}</Text>
            <Text style={{ marginBottom: 4 }}>{languageContent.udi}</Text>
            <Text style={{ marginBottom: 4 }}>{languageContent.copyright}</Text>
            <View style={styles.separator} />
            <Text style={{ marginBottom: 4 }}>
              {languageContent.customerCare}
            </Text>
            <Text style={[styles.customerCare]}>
              {languageContent.customerCareHotline}
            </Text>
            <Text style={{ marginBottom: 4 }}>
              {languageContent.omnipodURL}
            </Text>
          </View>
        </View>
        <View style={{ marginLeft: -12, width: "110%" }}>
          <View
            style={[styles.separator, { marginBottom: -4, width: "110%" }]}
          />
          <SettingsSection
            settingTitle={languageContent.serial}
            subtitleText={languageContent.number}
            aboutSubtitleText={true}
          />
          <SettingsSection
            settingTitle={languageContent.fccID}
            subtitleText={languageContent.fccIDFull}
            aboutSubtitleText={true}
          />
          <SettingsSection
            settingTitle={languageContent.podVersion}
            subtitleText={languageContent.podVersionNumber}
            aboutSubtitleText={true}
          />
          <SettingsSection
            settingTitle={languageContent.releaseNotes}
            subtitleText={languageContent.versionText}
            subtitleTextOnPressHandler={() => navigate("ReleaseNotes")}
          />
          <SettingsSection
            settingTitle={languageContent.lastCommunication}
            subtitleText={languageContent.lastCommunicationSubtext}
            aboutSubtitleText={true}
          />
          <SettingsSection
            settingTitle={languageContent.lastSync}
            subtitleText={languageContent.lastSyncSubtext}
            aboutSubtitleText={true}
          />
          <SettingsSection
            settingTitle={languageContent.logFiles}
            subtitleText={languageContent.sendLogFiles}
            subtitleTextOnPressHandler={() => navigate("SendLogFiles")}
          />
          <SettingsSection
            settingTitle={languageContent.deviceInformation}
            subtitleText={languageContent.deviceInformationSubtext}
            aboutSubtitleText={true}
          />
          <SettingsSection
            settingTitle={languageContent.legalInformation}
            subtitleText={languageContent.legalInformationSubtext}
            subtitleTextOnPressHandler={() => navigate("LegalInformation")}
          />
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  logoContainer: {
    display: "flex",
    maxHeight: 80,
    maxWidth: "40%",
    paddingLeft: "50%",
    alignItems: "center",
    marginTop: -20
  },
  text: {
    paddingTop: 10,
    fontSize: 20,
    fontWeight: "600",
    textAlign: "center",
    marginBottom: 4
  },
  subtext: {
    fontSize: 14,
    fontWeight: "300",
    textAlign: "center",
    marginBottom: 8
  },
  customerCare: {
    fontSize: 18,
    fontWeight: "300",
    textAlign: "center",
    marginBottom: 8
  },
  separator: {
    marginTop: 10,
    marginBottom: 12,
    height: 1,
    backgroundColor: "#d1d1d1"
  },
  bodyContainer: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 40
  }
});

export default AboutScreen;
