import { useNavigation, useRoute } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, TextInput, View } from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { basalActions } from "../../../../../../../../web/store/reducers/basal";
import {
  BasalState,
  emptyExpertBasalProgram,
} from "../../../../../../../../web/store/reducers/basal/reducer";
import { UserState } from "../../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { BottomInnerNav, WarningMessageBar } from "../../../../../molecules";
import { strings } from "./ExpertProgramEntryScreenContent";

const ExpertProgramEntryScreen: React.FC = () => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  const route = useRoute<any>();
  const [programName, setProgramName] = React.useState<string>(
    route.params && route.params.edit
      ? basalState.newExpertBasalProgram.programName
      : ""
  );

  const dispatch = useDispatch();
  const { navigate, goBack } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const updateProgramName = (val: string) => {
    setProgramName(val);

    if (val !== "" && !basalState.activeExpertBasalProgram?.hasUpdate) {
      dispatch(
        basalActions.basalUpdate(
          {
            newExpertBasalProgram: {
              ...basalState.newExpertBasalProgram,
              hasUpdate: true,
            },
          },
          basalState
        )
      );
    }
  };

  return (
    <>
      {basalState.insulinPaused ? <WarningMessageBar insulinDelivery /> : null}
      <View style={[theme.layout.wrapper, { marginTop: 24, marginBottom: 32 }]}>
        <View style={{ paddingHorizontal: 16 }}>
          <Text style={[styles.durationHeader, { marginBottom: 16 }]}>
            {languageContent.programName}
          </Text>
          <TextInput
            style={[styles.inputStyle]}
            placeholderTextColor={theme.colors.placeholder}
            placeholder={`${languageContent.placeholder} 1`}
            value={programName}
            keyboardType="default"
            selectionColor={theme.colors.blue}
            returnKeyType="done"
            onChangeText={updateProgramName}
          />
        </View>
      </View>
      <BottomInnerNav
        leftAction={true}
        leftNavigationAction={() => {
          if (!basalState.newExpertBasalProgram.isComplete) {
            dispatch(
              basalActions.basalUpdate(
                {
                  newExpertBasalProgram: {
                    ...basalState.newExpertBasalProgram,
                    segments: [],
                  },
                },
                basalState
              )
            );
          }
          goBack();
        }}
        leftActionText={languageContent.cancel}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.next}
        rightActionStyle={{
          color: theme.colors.purple,
        }}
        rightAction={() => {
          if (
            !basalState.newExpertBasalProgram.isComplete ||
            !route.params ||
            !userState.podConfigured ||
            !userState.hasTXId
          ) {
            dispatch(
              basalActions.basalUpdate(
                {
                  newExpertBasalProgram: {
                    ...emptyExpertBasalProgram,
                    programName,
                  },
                },
                basalState
              )
            );
            if (programName) {
              navigate(
                "ExpertProgramSegments",
                route.params && {
                  maxRateForBasalRatePicker:
                    route.params.maxRateForBasalRatePicker,
                }
              );
            } else {
              dispatch(
                basalActions.basalUpdate(
                  {
                    newExpertBasalProgram: {
                      ...emptyExpertBasalProgram,
                      programName: `${languageContent.placeholder} 1`,
                    },
                  },
                  basalState
                )
              );
              navigate(
                "ExpertProgramSegments",
                route.params && {
                  maxRateForBasalRatePicker:
                    route.params.maxRateForBasalRatePicker,
                }
              );
            }
          } else {
            dispatch(
              basalActions.basalUpdate(
                {
                  newExpertBasalProgram: {
                    ...basalState.newExpertBasalProgram,
                    programName,
                  },
                },
                basalState
              )
            );
            navigate("ExpertProgramSegmentsReview", { edit: true });
          }
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  mainText: {
    ...theme.fonts.text200,
    fontWeight: "400",
    marginBottom: 24,
  },
  durationHeader: {
    ...theme.fonts.h3,
  },
  inputStyle: {
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 8,
    paddingVertical: 12,
    borderColor: theme.colors.textInputBorder,
    backgroundColor: theme.colors.textInputBackgroundColor,
    color: theme.colors.text.inputText,
    fontSize: 24,
  },
});

export default ExpertProgramEntryScreen;
