import { useNavigation } from "@react-navigation/native";
import React from "react";
import { TouchableOpacity } from "react-native";
import { StyleSheet, Text, View } from "react-native-web";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./InsulinToCarbSegmentEditScreenContent";

const InsulinToCarbSegmentEditScreen: React.FC = () => {
  const [units, setUnits] = React.useState<string>("--");
  const [pressed, setIsPressed] = React.useState<boolean>(false);

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const handlePress = () => {
    setUnits("130");
    setIsPressed(true);
  };

  return (
    <View style={[styles.container]}>
      <View>
        <Text
          style={[
            theme.fonts.body2,
            {
              marginHorizontal: 16,
              marginVertical: 8,
              color: theme.colors.greenText
            }
          ]}>
          {languageContent.seg}
        </Text>
        <View style={[styles.break, { marginBottom: 16 }]} />
        <View style={[{ padding: 16 }]}>
          <View style={[{ flexDirection: "row" }]}>
            <Text style={[theme.fonts.h5, { flex: 1.1 }]}>
              {languageContent.start}
            </Text>
            <Text style={[theme.fonts.h5, { flex: 1 }]}>
              {languageContent.end}
            </Text>
          </View>
          <View
            style={[{ flexDirection: "row", width: "100%", height: "20%" }]}>
            <View
              style={[
                {
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flex: 1,
                  paddingVertical: 8
                }
              ]}>
              <Text
                style={[
                  {
                    fontSize: 30
                  }
                ]}>
                12:00 <Text style={[styles.periodText]}>AM</Text>
              </Text>
              <View style={[{ alignSelf: "center" }]}>
                <Text style={[{ fontSize: 30 }]}>{" - "}</Text>
              </View>
            </View>
            <View style={[theme.layout.mockInput, { flex: 1 }]}>
              <Text style={[theme.layout.mockInputStyle, { fontSize: 30 }]}>
                5:00 <Text style={[styles.periodText]}>AM</Text>
              </Text>
            </View>
          </View>
          <View style={[{ flexDirection: "row", marginTop: 8 }]}>
            <Text
              style={[
                theme.fonts.body2,
                { flex: 1.1, color: theme.colors.grayScale.gray400 }
              ]}>
              {languageContent.night}
            </Text>
            <Text
              style={[
                theme.fonts.body2,
                { flex: 1, color: theme.colors.grayScale.gray400 }
              ]}>
              {languageContent.night}
            </Text>
          </View>
          <View style={[{ marginTop: 16 }]}>
            <Text style={[theme.fonts.h5]}>
              {languageContent.insulinCovers}
            </Text>
            <Text
              style={[
                theme.fonts.body2,
                { color: theme.colors.grayScale.gray400 }
              ]}>
              {languageContent.range}
            </Text>
          </View>
          <TouchableOpacity onPress={handlePress}>
            <View style={[theme.layout.inputContainerStyle, { marginTop: 8 }]}>
              <Text style={[theme.layout.inputStyle, { fontSize: 30 }]}>
                {units}
              </Text>
              <Text style={[theme.layout.adornment]}>g</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      <BottomInnerNav
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.next}
        rightAction={() => navigate("BolusSettingsInsulinToCarb")}
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "white"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  periodText: {
    color: theme.colors.grayScale.gray400,
    fontSize: 14,
    marginLeft: -7
  }
});

export default InsulinToCarbSegmentEditScreen;
