import { useNavigation, useRoute } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BlackArrow } from "../../../../../assets/UI_BackArrow.svg";
import { ReactComponent as PurpleArrow } from "../../../../../assets/UI_BackArrowPurple.svg";
import { ReactComponent as WhiteArrow } from "../../../../../assets/UI_BackArrowWhite.svg";
import { asyncTimeout } from "../../../helpers";
import { basalActions, BasalState } from "../../../../../web/store/reducers/basal";
import { RootState } from "../../../../../web/store/store";
import theme from "../../../theme";

interface Props {
  text?: string;
  isFTS?: boolean;
  white?: boolean;
  navigateTo?: string;
  disabled?: boolean;
  beforeNavigation?: () => void;
  showModalOnPress?: () => void;
  modalElement?: JSX.Element;
  isLongText?: boolean;
}

const BackButton: React.FC<Props> = ({
  isFTS,
  text,
  white,
  navigateTo,
  disabled,
  beforeNavigation,
  showModalOnPress,
  modalElement,
  isLongText
}) => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );

  const navigation = useNavigation();
  const route = useRoute<any>();
  const dispatch = useDispatch();

  const handleGoBackExpertBasalProgram = async () => {
    if (
      (route.params && route.params.edit) ||
      (route.params && route.params.editEndTime)
    ) {
      dispatch(
        basalActions.basalUpdate(
          {
            newExpertBasalProgram: basalState.copyOfNewExpertBasalProgram
          },
          basalState
        )
      );
    } else {
      dispatch(
        basalActions.basalUpdate(
          {
            newExpertBasalProgram: {
              ...basalState.newExpertBasalProgram,
              segments: basalState.newExpertBasalProgram.segments.slice(
                0,
                basalState.newExpertBasalProgram.segments.length - 1
              ),
              hasUpdate: false
            }
          },
          basalState
        )
      );
    }
    await asyncTimeout(500);
    navigation.goBack();
  };

  const handlePress = async () => {
    // This is special logic for clicking the button inside the Expert Basal Programs flow.
    if (
      (route.name === "ExpertProgramSegments" ||
        route.name === "ExpertProgramEntry") &&
      !basalState.newExpertBasalProgram.hasUpdate
    ) {
      handleGoBackExpertBasalProgram();
      return;
    } else if (modalElement && showModalOnPress) {
      showModalOnPress();
      return;
    }

    if (disabled) {
      return;
    }

    if (beforeNavigation) {
      beforeNavigation();
    }

    if (navigateTo) {
      navigation.navigate(navigateTo);
    } else {
      navigation.goBack();
    }
  };
  return (
    <View>
      <TouchableOpacity
        borderRadius={24}
        onPress={handlePress}
        style={[styles.container]}>
        {white ? (
          <WhiteArrow width="100%" />
        ) : isFTS ? (
          <PurpleArrow />
        ) : (
          <BlackArrow />
        )}
        <Text
          style={[
            styles.text,
            white && { color: theme.colors.white },
            isFTS && { color: theme.colors.purple },
            isLongText && { fontSize: 14, marginLeft: -24 }
          ]}>
          {text}
        </Text>
      </TouchableOpacity>
      {modalElement}
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    ...theme.fonts.text200,
    paddingLeft: 30
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    marginHorizontal: 8
  },
  containerAndroid: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    marginHorizontal: 8
  }
});

export default BackButton;
