import { createStackNavigator } from "@react-navigation/stack";
import { isEqual } from "lodash";
import React from "react";
import { Animated } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../../components/atoms";
import {
  BasalProgramsScreen,
  CustomProgramEntryScreen,
  CustomProgramSegmentsReviewScreen,
  CustomProgramSegmentsScreen,
  CustomProgramSegmentThreeScreen,
  CustomProgramSegmentTwoScreen
} from "../../components/screens/simulator/basal";
import { useModeIconSmall } from "../../hooks";
import useLanguageContent from "../../hooks/useLanguageContent";
import { navigationActions, NavState } from "../../../../web/store/reducers/navigation";
import { RootState } from "../../../../web/store/store";
import theme from "../../theme";
import { strings } from "./BasalProgramsStackContent";

const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const BasalProgramsStack: React.FC = () => {
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const { languageContent } = useLanguageContent(strings);

  const { icon } = useModeIconSmall();
  const dispatch = useDispatch();
  const headerRef = new Animated.ValueXY().y;

  const animateHeader = React.useCallback(() => {
    Animated.timing(headerRef, {
      toValue: 0,
      duration: 600,
      useNativeDriver: false
    }).start();
  }, [headerRef]);

  React.useEffect(() => {
    animateHeader();
  }, [animateHeader]);

  return (
    <>
      <Stack.Navigator initialRouteName="BasalPrograms" mode="card">
        <Stack.Screen
          name="BasalPrograms"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.basalPrograms}
                white={false}
                beforeNavigation={() =>
                  dispatch(
                    navigationActions.navigationUpdate(
                      { navigateTo: "Dashboard" },
                      navState
                    )
                  )
                }
              />
            ),
            headerRight: () => icon
          }}
          component={BasalProgramsScreen}
        />
        <Stack.Screen
          name="CustomProgramEntry"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.editBasalProgram}
                white={false}
              />
            ),
            headerRight: () => icon
          }}
          component={CustomProgramEntryScreen}
        />
        <Stack.Screen
          name="CustomProgramSegments"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.confirmProgram} white={false} />
            ),
            headerRight: () => icon
          }}
          component={CustomProgramSegmentsScreen}
        />
        <Stack.Screen
          name="CustomProgramSegmentTwo"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.editBasalProgram}
                white={false}
              />
            ),
            headerRight: () => icon
          }}
          component={CustomProgramSegmentTwoScreen}
        />
        <Stack.Screen
          name="CustomProgramSegmentThree"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.editBasalProgram}
                white={false}
              />
            ),
            headerRight: () => icon
          }}
          component={CustomProgramSegmentThreeScreen}
        />
        <Stack.Screen
          name="CustomProgramSegmentsReview"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.confirmProgram} white={false} />
            ),
            headerRight: () => icon
          }}
          component={CustomProgramSegmentsReviewScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default BasalProgramsStack;
