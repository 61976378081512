import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const BolusIcon: React.FC<IconProps> = ({
  color,
  width = '12',
  height = '13',
}) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.11828 0.75C8.58347 0.75 8.96057 1.12711 8.96057 1.59229C8.96057 2.03012 8.62653 2.38992 8.1994 2.43073L8.11828 2.43459H6.72401V2.97304L9.30946 4.31624C9.5884 4.46115 9.76344 4.74934 9.76344 5.06368V11.9077C9.76344 12.3729 9.38633 12.75 8.92115 12.75H2.84229C2.37711 12.75 2 12.3729 2 11.9077V5.06368C2 4.74932 2.17506 4.46111 2.45403 4.31621L5.03943 2.97327V2.43459H3.75986C3.29467 2.43459 2.91756 2.05748 2.91756 1.59229C2.91756 1.15447 3.25161 0.79467 3.67874 0.753856L3.75986 0.75H8.11828ZM8.07855 5.57542L5.88185 4.43384L3.68459 5.57542V11.0649H8.07855V5.57542Z"
        fill="#3C3C43"
        fill-opacity="0.6"
      />
    </svg>
  );
};
