import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { View, Text } from 'react-native-web';
import { StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { BottomInnerNav } from 'mobile/latest/components/molecules';
import { SettingsSection } from 'mobile/latest/components/organisms';
import theme from 'mobile/latest/theme';
import { messages } from './MissingSensor.messages';
import { messages as L2Messages } from '../L2.messages';
import {
  settingName,
  setValueForType,
} from 'web/store/reducers/l2Settings/l2SettingsSlice';
import { L2Paths } from 'web/routes/L2/InitialSetupL2';
import { selectMissingSensorValuesSettings } from 'web/store/reducers/l2Settings/l2Settings.helpers';
import { ManageSensorL2Paths } from 'web/routes/L2/ManageSensorL2Stack';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  card: {
    width: '100%',
  },
  settingValue: {
    color: theme.colors.lightBlue,
  },
  disabledValue: {
    color: theme.colors.text.cancel,
  },
  textContainer: {
    paddingHorizontal: 16,
  },
});

interface Props {
  isFTS?: boolean;
}

export const MissingSensor: React.FC<Props> = ({ isFTS = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const missingSensorValuesSettings = useSelector(
    selectMissingSensorValuesSettings
  );

  const [isMissingSensorToggled, setIsMissingSensorToggled] = useState<boolean>(
    missingSensorValuesSettings.alertOn
  );
  const [isVolumeToggled, setIsVolumeToggled] = useState<boolean>(
    missingSensorValuesSettings.overrideVolume
  );

  const nextClickHandler = () => {
    dispatch(
      setValueForType({
        type: settingName.missingSensorValues,
        alertOn: isMissingSensorToggled,
        overrideVolume: isVolumeToggled,
      })
    );
    return isFTS ? navigate(L2Paths.sensorConfirm) : navigate(ManageSensorL2Paths.sensorSettings);
  };

  const navRightText = isFTS
    ? intl.formatMessage(L2Messages.bottomNavRightAction)
    : intl.formatMessage(L2Messages.bottomNavRightActionSave);

  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <SettingsSection
          settingTitle={intl.formatMessage(messages.missingSensorTitle)}
          hasCustomToggleSwitch
          hideBreak
          onToggleChange={(value) => setIsMissingSensorToggled(value)}
          startToggleTrue={isMissingSensorToggled}
          settingValueSubTextElement={
            isMissingSensorToggled ? (
              <Text style={[theme.fonts.body1, styles.settingValue]}>
                <FormattedMessage {...L2Messages.toggleOn} />
              </Text>
            ) : (
              <Text style={[theme.fonts.body1, styles.disabledValue]}>
                <FormattedMessage {...L2Messages.toggleOff} />
              </Text>
            )
          }
        />

        {isMissingSensorToggled && (
          <View>
            <View style={styles.textContainer}>
              <View style={{ marginBottom: 8 }}>
                <Text style={[theme.fonts.body2]}>
                  <FormattedMessage {...messages.missingSensorNotify} />
                </Text>

                <Text style={[theme.fonts.body2, { marginTop: 16 }]}>
                  <FormattedMessage {...messages.missingSensorNotifyTwo} />
                </Text>
              </View>
            </View>

            <SettingsSection
              settingTitle={intl.formatMessage(L2Messages.overrideVolume)}
              hasCustomToggleSwitch
              hideBreak
              onToggleChange={(value) => setIsVolumeToggled(value)}
              startToggleTrue={isVolumeToggled}
              settingValueSubTextElement={
                isVolumeToggled ? (
                  <Text style={[theme.fonts.body1, styles.settingValue]}>
                    <FormattedMessage {...L2Messages.toggleOn} />
                  </Text>
                ) : (
                  <Text style={[theme.fonts.body1, styles.disabledValue]}>
                    <FormattedMessage {...L2Messages.toggleOff} />
                  </Text>
                )
              }
            />

            <View style={styles.textContainer}>
              <Text>
                <FormattedMessage {...L2Messages.overrideVolumeDisclaimer} />
              </Text>
            </View>
          </View>
        )}
      </View>
      <BottomInnerNav
        rightActionText={navRightText}
        rightAction={nextClickHandler}
      />
    </View>
  );
};
