import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  morning: {
    id: 'date.morning',
    defaultMessage: 'morning',
  },
  night: {
    id: 'date.night',
    defaultMessage: 'night',
  },
})