import { FormattedMessage } from 'react-intl';
import { BottomNav } from 'web/components/ios/BottomMenu/BottomNav';
import { historyBolusCanceledDetailsMessages } from '../History.messages';

export const HistoryBolusCanceledDetails = () => {
  return (
    <>
      <div className="flex overflow-scroll  flex-col font-ios pt-2 h-full bg-white">
        <p className="text-small pt-1 pb-2 pl-2 font-semibold text-ios-secondaryGray opacity-60">
          <FormattedMessage
            {...historyBolusCanceledDetailsMessages.detailsTitle}
          />
        </p>
        <p className="text-regular pt-1 pb-2 px-2">
          <FormattedMessage
            {...historyBolusCanceledDetailsMessages.canceledDetailsValue}
          />
        </p>
        <div className="text-modals">
          <div className="flex justify-between w-full py-1 px-2">
            <p>
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.mealBolusTitle}
              />
            </p>
            <p className="text-ios-secondaryGray opacity-60">
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.mealBolusValue}
              />
            </p>
          </div>
          <div className="flex justify-between w-full py-1 px-2">
            <p>
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.correctionBolusTitle}
              />
            </p>
            <p className="text-ios-secondaryGray opacity-60">
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.correctionBolusValue}
              />
            </p>
          </div>
          <div className="flex justify-between w-full py-1 px-2">
            <div>
              <p>
                <FormattedMessage
                  {...historyBolusCanceledDetailsMessages.iobTitle}
                />
              </p>
            </div>

            <p className="text-ios-secondaryGray opacity-60">
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.iobValue}
              />
            </p>
          </div>
          <div className="flex justify-between w-full py-1 px-2">
            <p>
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.yourAdjustmentTitle}
              />
            </p>
            <p className="text-ios-secondaryGray opacity-60">
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.yourAdjustmentValue}
              />
            </p>
          </div>
        </div>
        <div className="text-regular">
          <div className="flex flex-col py-2 min-h-[58px] px-2 border-b border-b-ios-gray-7">
            <div className="flex w-full justify-between">
              <div>
                <p>
                  <FormattedMessage
                    {...historyBolusCanceledDetailsMessages.totalBolusCalculatedTitle}
                  />
                </p>
                <p className=" text-small  text-ios-secondaryGray opacity-60">
                  <FormattedMessage
                    {...historyBolusCanceledDetailsMessages.totalBolusDelivered}
                  />
                </p>
              </div>
              <p className="text-ios-secondaryGray opacity-60">
                <FormattedMessage
                  {...historyBolusCanceledDetailsMessages.totalBolusValue}
                />
              </p>
            </div>
            <p className="mt-1 tracking-[-0.08px] text-modals text-ios-secondaryGray opacity-60">
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.totalBolusDescription}
              />
            </p>
          </div>

          <div className="flex justify-between w-full py-2 min-h-[58px] px-2 border-b border-b-ios-gray-7">
            <p>
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.startTimeBolusLabel}
              />
            </p>
            <p className="text-ios-secondaryGray opacity-60">
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.startTimeBolusValue}
              />
            </p>
          </div>
          <div className="flex justify-between w-full py-2 min-h-[58px] px-2 border-b border-b-ios-gray-7">
            <p>
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.carbsManualLabel}
              />
            </p>
            <p className="text-ios-secondaryGray opacity-60">
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.carbsManualValue}
              />
            </p>
          </div>
          <div className="flex justify-between w-full py-2 min-h-[58px] px-2 border-b border-b-ios-gray-7">
            <p>
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.glucoseLabel}
              />
            </p>
            <p>
              <FormattedMessage
                {...historyBolusCanceledDetailsMessages.glucoseValue}
              />
            </p>
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
};
