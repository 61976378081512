import { isEqual } from "lodash";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { ReactComponent as UpArrow } from "../../../../../assets/UI_BigArrowUp.svg";
import { ReactComponent as Chevron } from "../../../../../assets/UI_ChevronLeftBlue.svg";
import getCurrentHourFormat from "../../../helpers/getCurrentHourFormat";
import useLanguageContent from "../../../hooks/useLanguageContent";
import { LangState } from "../../../../../web/store/reducers/language";
import { RootState } from "../../../../../web/store/store";
import theme from "../../../theme";
import { strings } from "./TempBasalStartedContent";

const MID_BLUE = "#0093e8";

const TempBasalStarted = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);

  return (
    <>
      <View style={[styles.subItemWrapper]}>
        <Text style={[styles.subItemText, { alignSelf: "flex-end" }]}>
          {languageContent.graphKey}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          paddingHorizontal: 16,
          marginTop: 16
        }}>
        <View
          style={{
            flex: 2,
            borderBottomColor: theme.colors.blue,
            borderBottomWidth: 3
          }}>
          <Text style={{ textAlign: "center", paddingTop: 60 }}>
            {(1.2).toLocaleString(langState.locale)}
          </Text>
        </View>
        <View
          style={{
            flex: 0.75,
            borderBottomColor: MID_BLUE,
            borderBottomWidth: 4.5,
            borderLeftColor: theme.colors.placeholder,
            borderLeftWidth: 0.5,
            borderRightColor: theme.colors.placeholder,
            borderRightWidth: 0.5,
            height: 80
          }}>
          <Text
            style={{
              textAlign: "center",
              paddingTop: 60
            }}>
            {(1.74).toLocaleString(langState.locale)}
          </Text>
        </View>
        <View
          style={{
            flex: 2,
            borderBottomColor: theme.colors.blue,
            borderBottomWidth: 3
          }}>
          <Text style={{ textAlign: "center", paddingTop: 60 }}>
            {(1.2).toLocaleString(langState.locale)}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingHorizontal: 16,
          paddingBottom: 8
        }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1
          }}>
          <Text>
            {getCurrentHourFormat({
              timeStr: "10:30 am",
              is24Hour: langState.is24Hour
            })}
          </Text>
          <Text style={{ paddingRight: 2 }}>
            {getCurrentHourFormat({
              timeStr: "11:59 am",
              is24Hour: langState.is24Hour
            })}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={{ alignSelf: "flex-end" }}>
            {getCurrentHourFormat({
              timeStr: "02:00 am",
              is24Hour: langState.is24Hour
            })}
          </Text>
        </View>
      </View>
      <View
        style={{
          backgroundColor: theme.colors.midGray,
          flexDirection: "row",
          justifyContent: "space-around",
          paddingVertical: 16
        }}>
        <Text style={{ fontSize: 14 }}>{languageContent.changeBasal}</Text>
        <Text style={{ fontSize: 14 }}>{languageContent.byHowMuch}</Text>
        <Text style={{ fontSize: 14 }}>{languageContent.duration}</Text>
      </View>
      <View
        style={{
          borderBottomWidth: 2,
          borderBottomColor: theme.colors.text.grayText
        }}>
        <View
          style={[
            styles.subItemWrapper,
            {
              flexDirection: "row",
              justifyContent: "space-evenly"
            }
          ]}>
          <View style={[styles.row]}>
            <Chevron width={40} />
            <Text style={{ color: theme.colors.blue, fontSize: 14 }}>
              {languageContent.increase}
            </Text>
          </View>
          <View
            style={[
              styles.row,
              {
                justifyContent: "center"
              }
            ]}>
            <UpArrow height="70%" />
            <Text style={{ fontSize: 14, color: theme.colors.blue }}>45 %</Text>
          </View>
          <View
            style={[
              styles.row,
              {
                justifyContent: "flex-end"
              }
            ]}>
            <Text
              style={{
                color: theme.colors.blue,
                fontSize: 14,
                paddingRight: 16
              }}>
              {`${(0.5).toLocaleString(langState.locale)} ${
                languageContent.hr
              }`}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  subItemWrapper: {
    padding: 16
  },
  subItemText: {
    fontSize: 14
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    width: "33%"
  }
});

export default TempBasalStarted;
