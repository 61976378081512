import { useNavigation } from '@react-navigation/native';
import theme from 'mobile/latest/theme';
import { useState } from 'react';
import { Switch } from 'react-native-web';
import { Button } from 'web/components/ios/Button';
import { useIntl } from 'react-intl';
import { messages, TemporaryBasalSwitchMessages } from '../Basal.messages';
import { AppRoutesEnum } from 'web/routes/types';

export const TemporaryBasalSwitch: React.FC = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const [toggleValue, setToggleValue] = useState(false);

  const handleToggleChange = () => {
    setToggleValue(!toggleValue);
  };

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-1/5 flex flex-col p-6">
        <p className="font-bold text-xl mb-1">
          {intl.formatMessage(TemporaryBasalSwitchMessages.title)}
        </p>
        <p>{intl.formatMessage(TemporaryBasalSwitchMessages.text)}</p>
      </div>
      <div className="basis-4/5">
        <div className="w-full h-full flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-center w-full pb-1 mb-1 border-b border-b-ios-gray-7 hover:cursor-pointer">
              <div className="text-lg pl-6">
                <p>
                  {intl.formatMessage(TemporaryBasalSwitchMessages.tempBasal)}
                </p>
              </div>
              <div className="pr-6">
                <Switch
                  style={[
                    {
                      transform: [{ scaleX: 1.2 }, { scaleY: 1.2 }],
                    },
                  ]}
                  value={toggleValue}
                  onValueChange={handleToggleChange}
                  activeThumbColor={theme.colors.white}
                  trackColor={{
                    false: theme.colors.placeholder,
                    true: theme.colors.green,
                  }}
                />
              </div>
            </div>
            {toggleValue && (
              <p className="px-6">
                {intl.formatMessage(
                  TemporaryBasalSwitchMessages.tempBasalActiveText
                )}
              </p>
            )}
          </div>
          <div className="flex flex-col w-full p-6">
            <Button
              onClick={() => navigate(AppRoutesEnum.bolusFTS)}
              variant={'colored'}
            >
              {intl.formatMessage(messages.nextBtn)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
