import { createStackNavigator } from '@react-navigation/stack';
import { BackButton } from '../../../mobile/latest/components/atoms';
import theme from '../../../mobile/latest/theme';
import { strings } from 'mobile/latest/routes/simulator/TXSetupStackContent';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import {
  ConnectCGMScreen,
  EnterSNScreen,
  OutroCGMScreen,
} from 'mobile/latest/components/screens';
import { selectShowNewVersionFeatures } from 'web/store/reducers/featureFlags/featureFlags.helpers';
import { SelectionConfirm } from 'web/components/pages';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

const Stack = createStackNavigator();
const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const InitialSetupG6Stack = (props: { isFTS?: boolean }) => {
  const { isFTS } = props;
  const { languageContent } = useLanguageContent(strings);
  const { navigate } = useNavigation();
  const showConnectCGM = useSelector(selectShowNewVersionFeatures);

  return (
    <Stack.Navigator initialRouteName="InitialSetupG6Stack" mode="card">
      {!showConnectCGM && isFTS && (
        <Stack.Screen
          name="ConnectCGM"
          options={{ headerShown: false }}
          component={ConnectCGMScreen}
        />
      )}
      {showConnectCGM && (
        <Stack.Screen
          name="SelectionConfirm"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.sensorSelect}
                isFTS={isFTS}
                white={false}
              />
            ),
          }}
        >
          {(props) => (
            <SelectionConfirm
              addButtonOnPressHandler={() => navigate('EnterSN')}
              notNowButtonOnPressHandler={() =>
                isFTS ? navigate('SensorSelection') : navigate('ManageSensorG6')
              }
            />
          )}
        </Stack.Screen>
      )}
      <Stack.Screen
        name="EnterSN"
        options={{
          title: '',
          headerStyle: defaultHeaderStyle,
          headerLeft: () => (
            <BackButton
              text={languageContent.enterSN}
              isFTS={isFTS}
              white={false}
            />
          ),
        }}
        component={EnterSNScreen}
      />
      <Stack.Screen
        name="OutroCGM"
        options={{
          title: '',
          headerStyle: defaultHeaderStyle,
          headerLeft: () => (
            <BackButton
              text={languageContent.transmitter}
              white={false}
              isFTS={isFTS}
            />
          ),
        }}
        component={OutroCGMScreen}
      />
    </Stack.Navigator>
  );
};

export default InitialSetupG6Stack;
