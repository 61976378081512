let strings = {
  "en-US": {
    bolus: "Bolus",
    bolusCalculations: "Bolus Calculations",
    confirmBolus: "Confirm Bolus",
    extendedBolus: "Extended Bolus",
    cancelIOS: "Cancel",
    bolusIOS: "Bolus",
    backIOS: "Back"
  },
  "en-GB": {
    bolus: "Bolus",
    bolusCalculations: "Bolus Calculations",
    confirmBolus: "Confirm Bolus",
    extendedBolus: "Extended Bolus",
    cancelIOS: "",
    bolusIOS: "Bolus",
    backIOS: ""
  },
  "de-DE": {
    bolus: "Bolus",
    bolusCalculations: "Bolusberechnungen",
    confirmBolus: "Bolus bestätigen",
    extendedBolus: "Verzögerter Bolus",
    cancelIOS: "",
    bolusIOS: "Bolus",
    backIOS: ""
  },
  "es-US": {
    bolus: "Bolo",
    bolusCalculations: "Cálculos del bolo",
    confirmBolus: "Confirmar bolo",
    extendedBolus: "Bolo extendido",
    bolusIOS: "Bolus"
  },
  "fr-FR": {
    bolus: "Bolo",
    bolusCalculations: "Calculs de bolus",
    confirmBolus: "Confirmer le bolus",
    extendedBolus: "Bolus prolongé",
    cancelIOS: "",
    bolusIOS: "Bolus",
    backIOS: ""
  },
  "nl-NL": {
    bolus: "Bolus",
    bolusCalculations: "Bolusberekeningen",
    confirmBolus: "Bevestig de bolus",
    extendedBolus: "Verlengde bolus",
    cancelIOS: "",
    bolusIOS: "Bolus",
    backIOS: ""
  }
};

export { strings };
