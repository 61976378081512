import { useNavigation } from "@react-navigation/native";
import { differenceInMinutes } from "date-fns";
import { isEqual } from "lodash";
import moment from "moment";
import React from "react";
import { Text, View } from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import getCurrentHourFormat from "../../../../../helpers/getCurrentHourFormat";
import replaceWithValues from "../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import {
  navigationActions,
  NavState
} from "../../../../../../../web/store/reducers/navigation";
import { userActions, UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { Button } from "../../../../atoms";
import PodDetailsSection from "../../../../atoms/podDetailsSection";
import {
  BottomInnerNav,
  ConfirmationModal,
  PodCommAlert
} from "../../../../molecules";
import PodRemindersSection from "../../../../organisms/podRemindersSection";
import { strings } from "./PodDetailsScreenContent";
const today = new Date();

const PodDetailsScreen: React.FC = () => {
  const bolusStatePodInsulin = useSelector(
    (state: RootState) => state.bolus.podInsulin
  );

  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const [daysDiff, setDaysDiff] = React.useState<any>();
  const [lastUpdateDay, setLastUpdateDay] = React.useState<any>();
  const [lastUpdateTime, setLastUpdateTime] = React.useState<any>();
  const [expiresDate, setExpiresDate] = React.useState<any>();
  const [expiresDay, setExpiresDay] = React.useState<any>("");
  const [expiresTime, setExpiresTime] = React.useState<any>();
  const [currentPodInsulin, setCurrentPodInsulin] =
    React.useState<number>(bolusStatePodInsulin);
  const [showCancelDeliveryWarning, setShowCancelDeliveryWarning] =
    React.useState<boolean>(false);
  const [deactivate, setDeactivate] = React.useState<boolean>(false);
  const [isDeactivating, setIsDeactivating] = React.useState<boolean>(false);
  const [removePod, setRemovePod] = React.useState<boolean>(false);
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);

  const getWarningText = () => (
    <View>
      <Text style={[theme.fonts.body1]}>{languageContent.modalMessage}</Text>
    </View>
  );

  const dismissDeactivate = () => {
    setDeactivate(false);
  };

  const onIsDeactivatingDismiss = () => {
    setIsDeactivating(false);
    setRemovePod(true);
  };

  const triggerDeactivateStep = () => {
    setDeactivate(false);
    setIsDeactivating(true);
  };

  const onConfirm = () => {
    setShowCancelDeliveryWarning(false);
    setDeactivate(true);
  };

  const onPodRemoved = () => {
    setRemovePod(false);
    dispatch(
      userActions.userUpdate(
        {
          podConfigured: false,
          automatedMode: userState.automatedMode ? true : false,
          hasTXId: true
        },
        userState
      )
    );
    //navigate to No Active Pod Screen
    setTimeout(() => {
      dispatch(
        navigationActions.navigationUpdate(
          { navigateTo: "ReplacePod" },
          navState
        )
      );
    }, 150);
  };
  const contentVariables = [expiresDay, currentPodInsulin];

  const getPodInsulinValue = () => {
    if (currentPodInsulin < 50) {
      return `${replaceWithValues(
        languageContent.unitsWithU,
        contentVariables
      )}`;
    } else {
      return `${languageContent.units}`;
    }
  };

  const handleDatePodUpdated = React.useCallback(() => {
    const lastUpdate = new Date(userState.datePodUpdated);
    setDaysDiff(differenceInMinutes(today, lastUpdate));

    //gets the Pod Insulin date format
    const day = moment().subtract(daysDiff, "minutes").calendar();

    setLastUpdateDay(day.split(" at")[0]);
    const formatLastUpdateTime = day.split(" at")[1].trim();
    setLastUpdateTime(formatLastUpdateTime);
    //moment needs to accept a Date object as an arg -- string is being deprecated
    const podExp = new Date(userState.datePodExpires);
    const podExpDay = moment(podExp).calendar();

    setExpiresDay(podExpDay.split(" ")[0]);
    setExpiresDate(
      podExp.toLocaleDateString(langState.locale, {
        month: "short",
        day: "numeric"
      })
    );
    setExpiresTime(
      getCurrentHourFormat({ date: podExp, is24Hour: langState.is24Hour })
    );
  }, [daysDiff, userState.datePodExpires, userState.datePodUpdated]);

  React.useEffect(() => {
    handleDatePodUpdated();
  }, [userState.datePodUpdated, handleDatePodUpdated]);

  React.useEffect(() => {
    setCurrentPodInsulin(bolusStatePodInsulin);
  }, [bolusStatePodInsulin]);

  return (
    <>
      <View
        style={[
          theme.layout.wrapper,
          { marginTop: 0, backgroundColor: "white", width: "100%" }
        ]}>
        <PodDetailsSection
          title={languageContent.insulinLeft}
          value={getPodInsulinValue()}
          lastUpdateDay={languageContent.lastUpdate}
          lastUpdateTime={getCurrentHourFormat({
            timeStr: lastUpdateTime,
            is24Hour: langState.is24Hour
          })}
        />

        <PodDetailsSection
          title={languageContent.status}
          value={languageContent.expires}
          expiresDate={languageContent.expiresDate}
          expiresTime={`(${getCurrentHourFormat({
            timeStr: "11:51 am",
            is24Hour: langState.is24Hour
          })})`}
        />

        <PodRemindersSection />
        <View
          style={{
            marginTop: 48,
            marginBottom: 24,
            marginHorizontal: 16
          }}>
          <Button
            variant="primary"
            style={{
              elevation: 6,
              marginTop: -80
            }}
            onPress={() => onPodRemoved()}>
            {languageContent.changePod}
          </Button>
        </View>
      </View>

      {showCancelDeliveryWarning && (
        <ConfirmationModal
          isVisible={showCancelDeliveryWarning}
          title={languageContent.cancelModalTitle}
          message={getWarningText()}
          dismissText={languageContent.back}
          onDismiss={() => {
            setShowCancelDeliveryWarning(false);
          }}
          confirmText={languageContent.deactivePod}
          onConfirm={() => {
            onConfirm();
          }}
        />
      )}

      {deactivate && (
        <ConfirmationModal
          isVisible={deactivate}
          title={languageContent.deactivateModalTitle}
          message={
            <View>
              <Text style={[theme.fonts.body1]}>
                {languageContent.deactivateModalMessage}
              </Text>
            </View>
          }
          confirmText={languageContent.confirmText}
          dismissText={languageContent.cancel}
          onConfirm={() => {
            triggerDeactivateStep();
          }}
          onDismiss={() => {
            dismissDeactivate();
          }}
        />
      )}
      {isDeactivating && (
        <PodCommAlert
          title={languageContent.confirmText}
          message={languageContent.pleaseWait}
          duration={3000}
          onDismiss={onIsDeactivatingDismiss}
        />
      )}
      {removePod && (
        <ConfirmationModal
          isVisible={removePod}
          title={languageContent.removePodModalTitle}
          message={
            <View>
              <Text style={[theme.fonts.body1]}>
                {`${languageContent.removePodModalMessage1} `}
                <Text style={[theme.fonts.body1, { fontWeight: "bold" }]}>
                  {languageContent.removePodModalMessage2}
                </Text>
              </Text>
            </View>
          }
          hideDismiss={true}
          confirmText={languageContent.continue}
          onConfirm={() => {
            onPodRemoved();
          }}
        />
      )}
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.close}
        leftNavigationAction={() => {
          dispatch(
            navigationActions.navigationUpdate(
              { navigateTo: "Dashboard" },
              navState
            )
          );
          navigate("Drawer");
        }}
        leftActionStyle={{ color: theme.colors.text.cancel }}
      />
    </>
  );
};

export default PodDetailsScreen;
