import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  Animated,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
/* import * as Progress from "react-native-progress"; */
import { useDispatch, useSelector } from "react-redux";
import { getViewerStatus } from "../../../../../helpers/getViewerStatus";
import truncate from "../../../../../helpers/truncate";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { UserState } from "../../../../../../../web/store/reducers/user";
import { viewerActions } from "../../../../../../../web/store/reducers/viewerManagement";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { ListHeader } from "../../../../atoms";
import {
  BottomInnerNav,
  ConfirmationModal,
  PodCommAlert,
  ViewerEntry,
} from "../../../../molecules";
import { ViewerManagementEditMenu } from "../../../../organisms";
import { strings } from "./ViewersEntryScreenContent";

const ViewersEntryScreen: React.FC = () => {
  const viewerManagementState = useSelector(
    (state: RootState) => state.viewerManagement,
    isEqual
  );
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [loading, setLoading] = React.useState<boolean>(true);
  const [editMenu, setEditMenu] = React.useState<boolean>(false);
  const [editNewViewerMenu, setEditNewViewerMenu] =
    React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [showViewerDeletedModal, setShowViewerDeletedModal] =
    React.useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = React.useState<boolean>(false);
  const [showGuidedModeDeleteModal, setShowGuidedModeDeleteModal] =
    React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showPodCommOne, setShowPodCommOne] = React.useState<boolean>(false);
  const [showPodCommTwo, setShowPodCommTwo] = React.useState<boolean>(false);

  const [viewerName] = React.useState<string>(
    viewerManagementState?.defaultViewer?.firstName +
      " " +
      viewerManagementState?.defaultViewer?.lastName
  );
  const [newViewerName, setNewViewerName] = React.useState<string>("");

  const width = useWindowDimensions().width;
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  React.useEffect(() => {
    setNewViewerName(
      viewerManagementState?.newViewer?.firstName +
        " " +
        viewerManagementState?.newViewer?.lastName
    );
  }, [viewerManagementState]);

  React.useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <Animated.View style={[styles.progressContainer]}>
          {/* <Progress.Bar */}
          {/*   indeterminate={true} */}
          {/*   width={width} */}
          {/*   height={5} */}
          {/*   color={theme.colors.purple} */}
          {/*   borderRadius={1} */}
          {/*   borderWidth={0.2} */}
          {/*   borderColor={theme.colors.loading.loadingBarLight} */}
          {/*   unfilledColor={theme.colors.loading.loadingBarLight} */}
          {/* /> */}
          <View>
            <Text style={styles.loadingText}>{languageContent.loading}</Text>
          </View>
        </Animated.View>
      ) : (
        <>
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => {
              setEditMenu(false);
              setEditNewViewerMenu(false);
            }}
            style={[theme.layout.wrapper, styles.container]}>
            <View style={styles.pageHeader}>
              <Text style={styles.headerText}>
                {`${languageContent.listOfViewers} Omnipod\u00a05 ${languageContent.data}`}
              </Text>
            </View>
            <ListHeader
              label={languageContent.viewers}
              numOfSegments={
                viewerManagementState &&
                viewerManagementState.newViewer?.firstName ===
                  languageContent.firstName
                  ? String(2)
                  : String(1)
              }
            />
            {/* default viewer */}
            <TouchableOpacity
              style={styles.itemContainer}
              onPress={() => {
                setEditMenu(false);
                setEditNewViewerMenu(false);
              }}>
              <ViewerEntry
                onPressMenu={() => {
                  setEditMenu(true);
                  setEditNewViewerMenu(false);
                }}
                onPressItem={() => {
                  //TODO FIX THIS
                  /* setEditMenu(false); */
                  /* !userState.isExpert ? setShowEditModal(true) : null; */
                }}
                viewerName={truncate(viewerName, 15, "...")}
                status={
                  viewerManagementState && viewerManagementState.defaultViewer
                    ? getViewerStatus(
                        viewerManagementState.defaultViewer.status,
                        langState.language
                      )!
                    : ""
                }
                badgeColor={theme.colors.blue}
                statusColor={theme.colors.text.dark}
              />
              <View style={[styles.break]} />
              {editMenu ? (
                <ViewerManagementEditMenu
                  onPressEdit={() => {
                    //TODO FIX THIS
                    /* !userState.isExpert ? setShowEditModal(true) : null; */
                  }}
                  onPressDelete={() => {
                    //TODO FIX THIS
                    /* !userState.isExpert */
                    /*   ? setShowGuidedModeDeleteModal(true) */
                    /*   : null; */
                  }}
                />
              ) : null}
            </TouchableOpacity>
            {/* new viewer */}
            {viewerManagementState &&
            viewerManagementState.newViewer &&
            viewerManagementState.newViewer.firstName !== "" ? (
              <TouchableOpacity
                onPress={() => {
                  setEditNewViewerMenu(false);
                  setEditMenu(false);
                }}
                style={
                  (styles.itemContainer, { marginTop: editMenu ? -53 : 0 })
                }>
                <ViewerEntry
                  onPressMenu={() => {
                    setEditNewViewerMenu(true);
                  }}
                  onPressItem={() => {
                    setEditNewViewerMenu(false);
                  }}
                  viewerName={
                    viewerManagementState && viewerManagementState.newViewer
                      ? truncate(newViewerName, 15, "...")
                      : ""
                  }
                  status={
                    viewerManagementState && viewerManagementState.newViewer
                      ? viewerManagementState.newViewer.status
                      : ""
                  }
                  badgeColor={theme.colors.placeholder}
                  statusColor={theme.colors.placeholder}
                />
                <View style={[styles.break]} />
              </TouchableOpacity>
            ) : null}
            {editNewViewerMenu ? (
              <ViewerManagementEditMenu
                onPressEdit={() => {
                  //TODO FIX THIS
                  /* !userState.isExpert ? setShowEditModal(true) : null; */
                }}
                onPressDelete={() => {
                  setShowDeleteModal(true);
                }}
              />
            ) : null}
            {/* edit modal */}
            <ConfirmationModal
              isVisible={showEditModal}
              title={languageContent.editModalTitle}
              message={
                <View>
                  <Text style={[theme.fonts.body1, { color: "white" }]}>
                    {languageContent.disabledForSim}
                  </Text>
                </View>
              }
              purple
              hideDismiss={true}
              confirmText={languageContent.ok}
              onConfirm={() => {
                setShowEditModal(false);
              }}
              onDismiss={() => setShowEditModal(false)}
            />
            {/* guided mode delete modal */}
            <ConfirmationModal
              isVisible={showGuidedModeDeleteModal}
              title={languageContent.deleteModalTitle}
              message={
                <View>
                  <Text style={[theme.fonts.body1, { color: "white" }]}>
                    {languageContent.disabledForSim}
                  </Text>
                </View>
              }
              purple
              hideDismiss={true}
              confirmText={languageContent.ok}
              onConfirm={() => {
                setShowGuidedModeDeleteModal(false);
              }}
            />
            {/* delete modal for user added viewer, calls showPodCommOne */}
            <ConfirmationModal
              isVisible={showDeleteModal}
              title={`${languageContent.deleteModalIsExpertTitle} Omnipod\u00a05?`}
              message={
                <View>
                  <Text style={[theme.fonts.body1]}>
                    {`${languageContent.deleteModalIsExpertBody} Omnipod\u00a05 ${languageContent.data}`}
                  </Text>
                </View>
              }
              dismissText={languageContent.cancel}
              confirmText={languageContent.delete}
              onConfirm={() => {
                setShowDeleteModal(false);
                setShowPodCommOne(true);
              }}
              onDismiss={() => {
                setShowDeleteModal(false);
              }}
            />
            {showPodCommOne ? (
              <PodCommAlert
                title={languageContent.connecting}
                duration={2000}
                onDismiss={() => {
                  setShowPodCommOne(false);
                  setShowPodCommTwo(true);
                }}
              />
            ) : null}
            {showPodCommTwo ? (
              <PodCommAlert
                title={languageContent.deletingViewer}
                duration={2000}
                onDismiss={() => {
                  setShowPodCommTwo(false);
                  setShowViewerDeletedModal(true);
                  setEditNewViewerMenu(false);
                  dispatch(
                    viewerActions.viewerUpdate({
                      newViewer: {
                        firstName: "",
                        lastName: "",
                        email: "",
                        relationship: "",
                        status: "",
                      },
                    })
                  );
                }}
              />
            ) : null}
            {/* confirm delete modal */}
            <ConfirmationModal
              isVisible={showViewerDeletedModal}
              title={languageContent.viewerDeleted}
              message={
                <View>
                  <Text style={theme.fonts.body1}>
                    {`${languageContent.viewerDeletedBody} Omnipod\u00a05 ${languageContent.data}`}
                  </Text>
                </View>
              }
              hideDismiss={true}
              confirmText={languageContent.ok}
              onConfirm={() => setShowViewerDeletedModal(false)}
            />
            {/* no more than 1 additional viewer */}
            <ConfirmationModal
              isVisible={showErrorModal}
              title={languageContent.viewerDetails}
              message={
                <View>
                  <Text style={[theme.fonts.body1, { color: "white" }]}>
                    {`${languageContent.viewerDetailsBody} 1 ${languageContent.viewerDetailsBodyTwo}`}
                  </Text>
                </View>
              }
              hideDismiss={true}
              dismissText=""
              confirmText={languageContent.ok}
              onConfirm={() => {
                setShowErrorModal(false);
              }}
              purple
              onDismiss={() => setShowErrorModal(false)}
            />
          </TouchableOpacity>
        </>
      )}
      <BottomInnerNav
        leftAction={false}
        leftActionText=" "
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.addViewer}
        rightActionStyle={{
          color: loading ? theme.colors.placeholder : theme.colors.purple,
        }}
        rightAction={() => {
          if (
            (viewerManagementState && !viewerManagementState.newViewer) ||
            viewerManagementState.newViewer?.firstName === ""
          ) {
            setEditMenu(false);
            navigate("AddViewers");
          } else {
            //TODO FIX THIS
            /* userState.isExpert ? null : setShowErrorModal(true); */
          }
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    flex: 2,
  },
  loadingText: {
    textAlign: "left",
    paddingTop: 12,
    marginLeft: 16,
    ...theme.fonts.body1,
  },
  container: { marginTop: 32, marginBottom: 32 },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder,
  },
  pageHeader: {
    paddingHorizontal: 16,
    paddingTop: 0,
    paddingBottom: 20,
    alignSelf: "center",
    alignItems: "center",
  },
  headerText: { color: theme.colors.text.cancel, ...theme.fonts.body1 },
  itemContainer: {
    zIndex: 20,
    elevation: 0,
    marginTop: 0,
  },
});

export default ViewersEntryScreen;
