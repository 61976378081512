import { useNavigation, useRoute } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  BackHandler,
  Dimensions,
  Platform,
  PlatformIOSStatic,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import CORRECTION_FACTOR_DATA from "../../../../../../data/correctionFactorData";
import CORRECTION_FACTOR_DATA_MMOL from "../../../../../../data/correctionFactorDataMmol";
import defaultBasalReduxData from "../../../../../../data/defaultBasalReduxData";
import findExpertBasalStartTimeIndex from "../../../../../../helpers/findExpertBasalStartTimeIndex";
import findExpertBasalStartTimeLabel from "../../../../../../helpers/findExpertBasalStartTimeLabel";
import getAdjustedDataForEndTimePicker from "../../../../../../helpers/getAdjustedDataForEndTimePicker";
import { getUnitByLocale } from "../../../../../../helpers/getUnitByLocale";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useExpertHardwareBackAction from "../../../../../../hooks/useExpertHardwareBackAction";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { bolusActions } from "../../../../../../../../web/store/reducers/bolus";
import { BolusState } from "../../../../../../../../web/store/reducers/bolus/reducer";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import {
  BottomInnerNav,
  ConfirmationModal,
  SegmentTopBar
} from "../../../../../molecules";
import { StartAndEndTimeSection } from "../../../../../organisms";
import CustomPicker from "../../../../../organisms/customPicker";
import { strings } from "./ExpertCorrectionFactorSegmentScreensContent";
interface Props {
  navigation: any;
}

const { width } = Dimensions.get("window");
const NOT_HIGHLIGHTED = "";
const ONE_UNIT_FIELD = "ONE_UNIT";
const platform = Platform as PlatformIOSStatic;

const getPickerHorizontalPlacement = () => {
  if (platform.isPad) {
    if (width > 1000) {
      return -width / 5.2;
    } else {
      return -width / 4.3;
    }
  } else if (Platform.OS === "android") {
    if (width > 395) {
      return -width / 3.95;
    } else if (width > 380) {
      return -width / 3.25;
    } else {
      return -width / 3;
    }
  } else if (width > 395) {
    return -width / 3.55;
  } else if (width > 380) {
    return -width / 3.25;
  } else {
    return -width / 3.13;
  }
};

const getPickerVerticalPlacement = () => {
  if (platform.isPad) {
    if (width > 1000) {
      return "-15%";
    } else {
      return "-22%";
    }
  } else if (Platform.OS === "android") {
    if (width > 395) {
      return "-29.5%";
    } else if (width > 380) {
      return "-37.3%";
    } else {
      return "-39%";
    }
  } else if (width > 395) {
    return "-34%";
  } else if (width > 380) {
    return "-37.3%";
  } else {
    return "-39%";
  }
};

const pickerHorizontalMargin = getPickerHorizontalPlacement();
const pickerVerticalMargin = getPickerVerticalPlacement();

const ExpertCorrectionFactorSegmentScreens: React.FC<Props> = ({
  navigation
}) => {
  //GLOBAL STATE
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);

  const segmentsArrLength =
    bolusState.newExpertBolusProgram.correctionFactorSegments.length;

  //LOCAL STATE
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState<boolean>(false);
  const [fieldValue, setFieldValue] = React.useState<string>("");
  const [startTime, setStartTime] = React.useState<string>("12:00 am");
  const [endTime, setEndTime] = React.useState({
    label: "",
    helpText: languageContent.endTimeHelpText
  });
  const [cancelModal, setCancelModal] = React.useState(false);

  const [showPicker, setShowPicker] = React.useState<boolean>(false);

  const [showMinimumValueModal, setShowMinimumValueModal] =
    React.useState<boolean>(false);
  const [showMaximumValueModal, setShowMaximumValueModal] =
    React.useState<boolean>(false);

  const [highlighted, setHighlighted] = React.useState<string>("");
  const [background, setBackground] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");

  const [adjustedEndTimeData, setAdjustedEndTimeData] = React.useState<any[]>();
  const [showEndTimePicker, setShowEndTimePicker] =
    React.useState<boolean>(false);
  const [showBolusRatePicker, setShowBolusRatePicker] =
    React.useState<boolean>();
  const [borderColor, setBorderColor] = React.useState(
    theme.colors.textInputBorder
  );

  const [rerenderPicker, setRerenderPicker] = React.useState<boolean>(false);
  const [segmentIndex, setSegmentIndex] = React.useState<number>(
    bolusState.newExpertBolusProgram.correctionFactorSegments.length + 1
  );
  const [endTimeIndex, setEndTimeIndex] = React.useState<number>(0);
  //HOOKS
  const fieldRef = React.useRef<TextInput>(null);
  const route = useRoute<any>();
  const backAction = useExpertHardwareBackAction(navigation, "BolusCF");
  //FUNCTIONS

  const lowerRange = getUnitByLocale(1, langState.units, langState.locale);
  const higherRange = `${getUnitByLocale(
    400,
    langState.units,
    langState.locale
  )} ${langState.units}`;
  const errorCannotBeLessThan = `${getUnitByLocale(
    1,
    langState.units,
    langState.locale
  )} ${langState.units}`;

  const contentVariables = [lowerRange, higherRange, errorCannotBeLessThan];

  const checkFieldValue = () => {
    if (fieldValue !== "" && Number(fieldValue) < 1) {
      setError(ONE_UNIT_FIELD);
      setShowMinimumValueModal(true);
    }
    if (fieldValue !== "" && Number(fieldValue) > 400) {
      setError(ONE_UNIT_FIELD);
      setShowMaximumValueModal(true);
    }
  };
  const toggleBolusRatePicker = () => {
    if (borderColor === theme.colors.blue) {
      setBorderColor(theme.colors.textInputBorder);
      setShowBolusRatePicker(false);
    } else {
      setBorderColor(theme.colors.blue);
      setShowBolusRatePicker(true);
      setShowEndTimePicker(false);
    }
  };

  const toggleEndTimePicker = () => {
    setShowEndTimePicker((showEndTimePicker) => !showEndTimePicker);
    if (showBolusRatePicker) {
      toggleBolusRatePicker();
    }
  };

  const endTimePickerUpdate = (value: string) => {
    setShowEndTimePicker(false);
    const selected = adjustedEndTimeData?.find(
      (adjustedEndTimeData) => adjustedEndTimeData.label === value
    );
    setEndTime({
      label: selected.label.toUpperCase(),
      helpText: selected.helpText
    });

    setEndTimeIndex(selected.index);

    if (!bolusState.newExpertBolusProgram.hasUpdate) {
      dispatch(
        bolusActions.bolusUpdate(
          {
            newExpertBolusProgram: {
              ...bolusState.newExpertBolusProgram,
              hasUpdate: true
            }
          },
          bolusState
        )
      );
    }
  };

  const getAdornmentColor = () => {
    if (!fieldValue) {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  const handleNext = () => {
    if (Number(fieldValue) < 1 || Number(fieldValue) > 400 || !endTime) {
      return;
    }
    if (endTime.label === "") {
      return;
    }

    dispatch(
      bolusActions.bolusUpdate(
        {
          newExpertBolusProgram: {
            ...bolusState.newExpertBolusProgram,
            hasUpdate: false,
            correctionFactorSegments: [
              ...bolusState.newExpertBolusProgram.correctionFactorSegments,
              {
                start: startTime,
                end: endTime.label.toLowerCase(),
                startTimeHelpText: endTime.helpText,
                endHelpText: endTime.helpText,
                oneUnitCorrectionFactor: Number(fieldValue)
              }
            ]
          }
        },
        bolusState
      )
    );

    if (endTime.label && endTime.label === "12:00 AM") {
      return setShowConfirmationModal(true);
    }

    return navigation.push("ExpertCorrectionFactorSegment");
  };

  //LIFECYCLE

  const setPickerDynamicStartTime = React.useCallback(() => {
    let adjustedStartTime = "";
    if (
      startTime &&
      startTime.toLowerCase() === "12:00 am" &&
      segmentsArrLength === 0
    ) {
      adjustedStartTime = "12:30 am";
    } else if (segmentsArrLength === 0) {
      adjustedStartTime = "12:30 am";
      setStartTime("12:00 am");
    } else {
      // findExpertBasalStartTimeIndex: find the index of previous segment end time in the data set of hours
      // findExpertBasalStartTimeLabel: find 30 minutes past where the index is, use that as the base value for the picker
      adjustedStartTime = findExpertBasalStartTimeLabel(
        findExpertBasalStartTimeIndex(
          bolusState.newExpertBolusProgram.correctionFactorSegments[
            segmentsArrLength - 1
          ].end.toUpperCase()
        )
      );
    }

    setAdjustedEndTimeData(
      getAdjustedDataForEndTimePicker(adjustedStartTime.toUpperCase())
    );
  }, [bolusState.newExpertBolusProgram.correctionFactorSegments]);

  React.useEffect(() => {
    checkFieldValue();
  }, [fieldValue]);

  React.useEffect(() => {
    if (rerenderPicker) {
      setRerenderPicker(false);
    }
  }, [rerenderPicker]);

  React.useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", backAction);
    setPickerDynamicStartTime();

    setSegmentIndex(segmentsArrLength + 1);

    if (
      endTime.label.toLowerCase() !== "12:00 am" &&
      endTime.label.toLowerCase() !== "00:00" &&
      segmentsArrLength > 0
    ) {
      setStartTime(
        bolusState.newExpertBolusProgram.correctionFactorSegments[
          segmentsArrLength - 1
        ].end
      );
    }

    if (
      bolusState.newExpertBolusProgram.isComplete &&
      route.params &&
      route.params.editEndTime
    ) {
      let helpText;
      if (segmentsArrLength === 0 && route.params.editEndHelpText) {
        helpText = route.params.editEndHelpText;
      } else {
        helpText =
          bolusState.newExpertBolusProgram.correctionFactorSegments[
            segmentsArrLength - 1
          ].startTimeHelpText!;
      }
      setEndTime({
        label: route.params.editEndTime,
        helpText
      });
    }

    return () =>
      BackHandler.removeEventListener("hardwareBackPress", backAction);
  }, [
    bolusState.newExpertBolusProgram.isComplete,
    bolusState.newExpertBolusProgram.isActive,
    bolusState.newExpertBolusProgram.correctionFactorSegments,
    segmentsArrLength,
    route.params,
    setPickerDynamicStartTime
  ]);

  React.useEffect(
    () =>
      navigation.addListener("beforeRemove", () => {
        dispatch(
          bolusActions.bolusUpdate(
            {
              newExpertBolusProgram: {
                ...bolusState.newExpertBolusProgram,
                correctionFactorSegments:
                  bolusState.newExpertBolusProgram.correctionFactorSegments.slice(
                    0,
                    bolusState.newExpertBolusProgram.correctionFactorSegments
                      .length - 1
                  )
              }
            },
            bolusState
          )
        );
      }),
    [navigation]
  );

  //RETURN
  return (
    <View style={[styles.container]}>
      <View>
        <SegmentTopBar segmentNum={String(segmentIndex)} />
        <View style={[styles.break, { marginBottom: 16 }]} />
        <View>
          <StartAndEndTimeSection
            is24Hour={langState.is24Hour}
            locale={langState.locale}
            startTime={startTime}
            toggleEndTimePicker={toggleEndTimePicker}
            endTime={endTime}
            segmentsArrLength={segmentsArrLength}
            routeParams={route.params}
            adjustedEndTimeData={adjustedEndTimeData}
            showEndTimePicker={showEndTimePicker}
            onConfirm={endTimePickerUpdate}
            onDismiss={() => setShowEndTimePicker(false)}
            endTimeIndex={endTimeIndex}
            correctionFactor
          />
          <View
            style={[
              {
                paddingHorizontal: 16,
                marginHorizontal: -16,
                paddingBottom: 16,
                zIndex: -1,
                backgroundColor: background ? "#EEF2F6" : "transparent"
              }
            ]}>
            <View
              style={[
                {
                  marginHorizontal: 16
                }
              ]}>
              <Text style={[theme.fonts.h4]}>
                {languageContent.lowerGlucose}
              </Text>
              <Text
                style={[
                  theme.fonts.body1,
                  { color: theme.colors.text.cancel, marginBottom: 8 }
                ]}>
                {replaceWithValues(languageContent.range, contentVariables)}
              </Text>
            </View>
            <TouchableOpacity
              style={[
                theme.layout.inputContainerStyle,
                {
                  zIndex: 999,
                  marginHorizontal: 16,
                  borderColor:
                    highlighted === ONE_UNIT_FIELD
                      ? theme.colors.blue
                      : theme.colors.textInputBorder
                }
              ]}
              activeOpacity={1}
              onPress={() => {
                fieldRef.current?.focus();
                setBackground(ONE_UNIT_FIELD);
                setHighlighted(ONE_UNIT_FIELD);
                setShowPicker(true);
              }}>
              <TextInput
                style={[
                  theme.layout.inputStyle,
                  {
                    color: error
                      ? theme.colors.red
                      : theme.colors.text.inputText
                  }
                ]}
                value={
                  fieldValue === ""
                    ? ""
                    : Number(fieldValue).toLocaleString(langState.locale)
                }
                selectionColor={theme.colors.blue}
                returnKeyType="done"
                ref={fieldRef}
                placeholder={"––"}
                placeholderTextColor={theme.colors.placeholder}
                onBlur={() => {
                  setHighlighted(NOT_HIGHLIGHTED);
                  setBackground(NOT_HIGHLIGHTED);
                }}
                onChangeText={(val) => setFieldValue(String(val))}
              />
              <Text
                style={[
                  theme.layout.adornment,
                  {
                    color: getAdornmentColor()
                  }
                ]}>
                {langState.units}
              </Text>
            </TouchableOpacity>
          </View>
          <View>
            {CORRECTION_FACTOR_DATA && (
              <CustomPicker
                is24Hour={langState.is24Hour}
                locale={langState.locale}
                title={languageContent.lowerGlucose}
                values={
                  langState.units === "mg/dL"
                    ? CORRECTION_FACTOR_DATA
                    : CORRECTION_FACTOR_DATA_MMOL
                }
                initialIndex={langState.units === "mg/dL" ? 49 : 19}
                selectedValue={fieldValue}
                isVisible={showPicker}
                onConfirm={(value: string) => {
                  setFieldValue(value);
                  setShowPicker(false);
                  setHighlighted(NOT_HIGHLIGHTED);
                  setBackground(NOT_HIGHLIGHTED);
                }}
                units={langState.units}
                onDismiss={() => {
                  setShowPicker(false);
                  setHighlighted(NOT_HIGHLIGHTED);
                  setBackground(NOT_HIGHLIGHTED);
                }}
              />
            )}
          </View>
        </View>
      </View>
      <BottomInnerNav
        leftActionStyle={{ color: theme.colors.text.cancel }}
        leftActionText={languageContent.cancel}
        leftAction
        leftNavigationAction={() => setCancelModal(true)}
        rightActionText={languageContent.next}
        rightAction={handleNext}
        rightActionStyle={{
          color:
            !fieldValue ||
            Number(fieldValue) < 1 ||
            Number(fieldValue) > getUnitByLocale(400, langState.units) ||
            !endTime
              ? theme.colors.placeholder
              : theme.colors.purple
        }}
      />
      {showConfirmationModal && (
        <ConfirmationModal
          isVisible={true}
          title={languageContent.confirmModalTitle}
          confirmText={languageContent.continue}
          message={
            <View>
              <Text style={theme.fonts.body1}>
                {languageContent.confirmModalBody}
              </Text>
            </View>
          }
          hideDismiss={true}
          onConfirm={() => {
            setShowConfirmationModal(false);
            navigate("ExpertReviewCorrectionFactorScreen");
          }}
        />
      )}
      <ConfirmationModal
        isVisible={showMinimumValueModal}
        title={languageContent.errorModalTitle}
        message={
          <View>
            <Text style={theme.fonts.body1}>
              {replaceWithValues(
                languageContent.errorModalBody,
                contentVariables
              )}
            </Text>
          </View>
        }
        confirmText={languageContent.ok}
        hideDismiss={true}
        onConfirm={() => {
          setError("");
          setFieldValue("");
          setShowMinimumValueModal(false);
        }}
      />
      <ConfirmationModal
        isVisible={showMaximumValueModal}
        title={languageContent.maximumValue}
        message={
          <View>
            <Text style={[theme.fonts.body1]}>
              {replaceWithValues(
                languageContent.moreThanModalBody,
                contentVariables
              )}
            </Text>
          </View>
        }
        confirmText={languageContent.ok}
        hideDismiss={true}
        onConfirm={() => {
          setError("");
          setFieldValue("");
          setShowMaximumValueModal(false);
        }}
      />
      <ConfirmationModal
        title={languageContent.cancelModalTitle}
        isVisible={cancelModal}
        onDismiss={() => setCancelModal(false)}
        onConfirm={() => {
          setCancelModal(false);
          dispatch(
            bolusActions.bolusUpdate(
              {
                newExpertBolusProgram: {
                  ...bolusState.newExpertBolusProgram,
                  correctionFactorSegments:
                    defaultBasalReduxData.correctionFactorSegments
                }
              },
              bolusState
            )
          );
          navigation.navigate("CorrectionFactorFTSScreen");
        }}
        message={
          <View>
            <Text style={[theme.fonts.body1, { marginBottom: 8 }]}>
              {languageContent.cancelModalBody}
            </Text>
          </View>
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  periodText: {
    color: theme.colors.text.cancel,
    fontSize: 18
  },
  androidPickerWrapper: {
    flex: 2,
    zIndex: 20,
    marginTop: pickerVerticalMargin,
    marginLeft: pickerHorizontalMargin,
    height: 200,
    marginBottom: -56
  }
});

export default ExpertCorrectionFactorSegmentScreens;
