import React, { ReactNode } from 'react';
import { View } from 'react-native';
import '../styles.css';
import { selectShowIOSFeatures } from 'web/store/reducers/featureFlags/featureFlags.helpers';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { IOSStatusBar } from 'web/components/ios/IOSStatusBar';

interface Props {
  children: ReactNode;
}

const IPhoneX: React.FC<Props> = ({ children }) => {
  const showIOSFeatures = useSelector(selectShowIOSFeatures);

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10%',
      }}
    >
      <div
        className={classNames('iphonex', {
          'iphonex-dimensions': showIOSFeatures,
          'android-dimensions': !showIOSFeatures,
          'tracking-ios': showIOSFeatures,
          'font-ios': showIOSFeatures,
          'text-regular': showIOSFeatures,
        })}
      >
        <div className="front">
          <div
            className={classNames('screen', {
              'iphonex-max-width': showIOSFeatures,
              'android-max-width': !showIOSFeatures,
            })}
          >
            <div className="screen__view">
              <div className="dribbbleLogo">
                <View
                  style={{
                    width: showIOSFeatures ? 375 : 300,
                    height: showIOSFeatures ? 812 : 620,
                    justifyContent: 'center',
                    alignSelf: 'center',
                  }}
                >
                  {showIOSFeatures && <IOSStatusBar />}
                  {children}
                </View>
              </div>
            </div>
            <div className="screen__front">
              <div className="screen__front-speaker" />
              <div className="screen__front-camera" />
            </div>
          </div>
          <div className="front__line" />
          <div className="front__line front__line-second" />
        </div>
        <div className="phoneButtons phoneButtons-right" />
        <div className="phoneButtons phoneButtons-left" />
        <div className="phoneButtons phoneButtons-left2" />
        <div className="phoneButtons phoneButtons-left3" />
        <div className="back" />
      </div>
    </div>
  );
};

export default IPhoneX;
