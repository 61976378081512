import { defineMessages } from 'react-intl';

export const dashboardPodDetailsMessages = defineMessages({
  podTitle: {
    id: 'ios.modals.dashboard-pod-details.pod.title',
    defaultMessage: 'Pod',
  },
  text: {
    id: 'ios.modals.deactivate-your-pod.change-your-pod.text',
    defaultMessage:
      'To change your Pod you must first deactivate the current Pod. Deactivating your Pod will stop insulin delivery.',
  },
  deactivatePodButton: {
    id: 'ios.modals.deactivate-your-pod.deactivate-pod.button',
    defaultMessage: 'Deactivate Pod',
  },
  cancelButton: {
    id: 'ios.modals.deactivate-your-pod.cancel.button',
    defaultMessage: 'Cancel',
  },
  updatedTodayTitle: {
    id: 'ios.modals.dashboard-pod-details.updated-today.title',
    defaultMessage: 'Updated Today at 9:41 AM',
  },
  insulinLeftTitle: {
    id: 'ios.modals.dashboard-pod-details.insulin-left.title',
    defaultMessage: 'INSULIN LEFT IN POD',
  },
  unitsTitle: {
    id: 'ios.modals.dashboard-pod-details.units.title',
    defaultMessage: '27 U',
  },
  podStatusTitle: {
    id: 'ios.modals.dashboard-pod-details.pod-status.title',
    defaultMessage: 'POD STATUS',
  },
  expiresTomorrowTitle: {
    id: 'ios.modals.dashboard-pod-details.expires-tomorrow.title',
    defaultMessage: 'Expires: Tomorrow',
  },
  februarySubtitle: {
    id: 'ios.modals.dashboard-pod-details.february.subtitle',
    defaultMessage: 'Feb 11 (10:15 PM)',
  },
  viewPodDetailsButton: {
    id: 'ios.modals.dashboard-pod-details.view-pod-details.button',
    defaultMessage: 'View Pod Details',
  },
});

export const dashboardInsulinDetailsMessages = defineMessages({
  insulinTitle: {
    id: 'ios.modals.dashboard-insulin-details.insulin.title',
    defaultMessage: 'Insulin',
  },
  updatedTodayText: {
    id: 'ios.modals.dashboard-insulin-details.updated-today.text',
    defaultMessage: 'Updated Today at 9:41 AM',
  },
  pausedTitle: {
    id: 'ios.modals.dashboard-insulin-details.paused.title',
    defaultMessage: 'PAUSED',
  },
  inProgressTitle: {
    id: 'ios.modals.dashboard-insulin-details.in-progress.title',
    defaultMessage: 'IN PROGRESS',
  },
  weekendTitle: {
    id: 'ios.modals.dashboard-insulin-details.weekend.title',
    defaultMessage: 'Weekend',
  },
  everydayTitle: {
    id: 'ios.modals.dashboard-insulin-details.everyday.title',
    defaultMessage: 'Everyday',
  },
  uDay: {
    id: 'ios.modals.dashboard-insulin-details.u-day.title',
    defaultMessage: 'U/day',
  },
  totalTitle: {
    id: 'ios.modals.dashboard-insulin-details.total.title',
    defaultMessage: 'Total: {amount} U/day',
  },
  viewBasalProgramsButtonTitle: {
    id: 'ios.modals.dashboard-insulin-details.view-basal-program.button.title',
    defaultMessage: 'View Basal Programs',
  },
});

export const iobInfoMessages = defineMessages({
  title: {
    id: 'ios.modals.dashboard-iob-info.title',
    defaultMessage: 'IOB is Estimated',
  },
  text: {
    id: 'ios.modals.dashboard-iob-info.text',
    defaultMessage:
      'Waiting for a bolus confirmation from your Pod. IOB is an estimate until the bolus is confirmed.',
  },
  ok: {
    id: 'ios.modals.dashboard-iob-info.ok',
    defaultMessage: 'OK',
  },
});
