import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { Button } from "../../../../../atoms";
import { strings } from "./ScheduleTrainingContent";

const ScheduleTrainingScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { languageContent } = useLanguageContent(strings);
  const { navigate } = useNavigation();

  React.useEffect(() => {
    if (langState.language === "de-DE" || langState.language === "nl-NL") {
      navigate("AllSet");
    }
  }, [langState.language, navigate]);

  return (
    <View
      style={[
        theme.layout.wrapper,
        { width: "100%", backgroundColor: "white" }
      ]}>
      <View style={[styles.innerWrapper, { width: "100%" }]}>
        <Text
          style={[
            theme.fonts.text400,
            { fontWeight: "600", textAlign: "left" }
          ]}>
          {languageContent.training}
        </Text>
        <Text
          style={[
            theme.fonts.text600,
            { fontWeight: "600", textAlign: "left", paddingTop: 16 }
          ]}>
          {languageContent.waysTo}
        </Text>
        <Text
          style={[
            theme.fonts.text600,
            { fontWeight: "300", textAlign: "left", marginTop: 20 }
          ]}>
          {languageContent.callInsulet}
          <Text
            style={[
              theme.fonts.text600,
              { fontWeight: "600", textAlign: "left", paddingTop: 16 }
            ]}>
            {languageContent.customerCare}
          </Text>
        </Text>

        <Text
          style={[
            theme.fonts.text600,
            { fontWeight: "300", textAlign: "left", paddingTop: 26 }
          ]}>
          {languageContent.fillForm}
          <Text
            style={[
              theme.fonts.text600,
              { fontWeight: "600", textAlign: "left", paddingTop: 16 }
            ]}>
            {languageContent.omnipodURL}
            <Text
              style={[
                theme.fonts.text600,
                { fontWeight: "300", textAlign: "left", paddingTop: 26 }
              ]}>
              {languageContent.callBack}
            </Text>
          </Text>
        </Text>
        <Text
          style={[
            theme.fonts.text600,
            { fontWeight: "300", textAlign: "left", paddingTop: 26 }
          ]}>
          {languageContent.health}
        </Text>
        <Button
          variant={"primary"}
          style={{ elevation: 6, width: "100%", marginTop: 40 }}
          onPress={() => {
            navigate("Training");
          }}>
          {languageContent.ok}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 16,
    marginTop: 20
  },
  title: {
    margin: 16,
    textAlign: "center",
    fontWeight: "200"
  }
});

export default ScheduleTrainingScreen;
