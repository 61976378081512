let strings = {
  "en-US": {
    start: "Start",
    end: "End",
    night: "Night",
    morning: "Morning",
    basalRate: "Basal Rate",
    upTo: "up to 30 U/hr",
    unitsHr: "U/hr",
    cancel: "Cancel",
    next: "Next",
    modalTitle: "Segment Details",
    ok: "OK",
    modalBody:
      "For simulation purposes we have auto-filled this information for you."
  },
  "en-GB": {
    start: "Start",
    end: "End",
    night: "Night",
    morning: "Morning",
    basalRate: "Basal Rate",
    upTo: "up to 30 U/hr",
    unitsHr: "U/hr",
    cancel: "Cancel",
    next: "Next",
    modalTitle: "Segment Details",
    ok: "OK",
    modalBody:
      "For simulation purposes we have auto-filled this information for you."
  },
  "de-DE": {
    start: "Start",
    end: "Ende",
    night: "Nacht",
    morning: "Vormittag",
    basalRate: "Basalrate",
    upTo: "bis zu 30 E/Std.",
    unitsHr: "E/Std.",
    cancel: "Abbr.",
    next: "Weiter",
    modalTitle: "Segmentdetails",
    ok: "OK",
    modalBody:
      "Zu Simulationszwecken haben wir diese Informationen automatisch für Sie ausgefüllt."
  },
  "es-US": {
    start: "Iniciar",
    end: "Finalizar",
    night: "Noche",
    morning: "Mañana",
    basalRate: "Tasa basal",
    upTo: "hasta 30 U/h",
    unitsHr: "U/h",
    cancel: "Cancelar",
    next: "Siguiente",
    modalTitle: "Detalles del segmento",
    ok: "Aceptar",
    modalBody:
      "Completamos automáticamente esta información para fines de simulación."
  },
  "fr-FR": {
    start: "Démarrer",
    end: "Fin",
    night: "Nuit",
    morning: "Matin",
    basalRate: "Débit basal",
    upTo: "jusqu'à 30 U/h",
    unitsHr: "U/h",
    cancel: "Annuler",
    next: "Suivant",
    modalTitle: "Détails du segment",
    ok: "OK",
    modalBody:
      "À des fins de simulation, nous avons rempli automatiquement ces informations pour vous."
  },
  "nl-NL": {
    start: "Start",
    end: "Stop",
    night: "Nacht",
    morning: "Ochtend",
    basalRate: "Basaalsnelheid",
    upTo: "tot 30 E/u.",
    unitsHr: "E/u.",
    cancel: "Annuleren",
    next: "Volgende",
    modalTitle: "Segmentdetails",
    ok: "OK",
    modalBody:
      "Voor simulatiedoeleinden hebben wij deze informatie automatisch voor u ingevuld."
  }
};

export { strings };
