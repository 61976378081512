import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Dimensions, StyleSheet, Text, View } from "react-native-web";
import { ReactComponent as LogoSVG } from "../../../../../../../assets/Omnipod_5_LOGO_RGB_MANGO.svg";
import PodActive from "../../../../../../../assets/pod_activate.svg";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import theme from "../../../../../theme";
import { Button } from "../../../../atoms";
import { strings } from "./NewPodLandingScreenContent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../web/store/store";
import { isEqual } from "lodash";
import {
  NavState,
  navigationActions
} from "../../../../../../../web/store/reducers/navigation";
const { height } = Dimensions.get("screen");

const NewPodLandingScreen: React.FC = () => {
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);
  const dispatch = useDispatch();

  const getSizeValue = () => {
    if (height <= 600) return "45%";
    else if (height < 700 && height > 600) return "52%";
    else if (height >= 700 && height < 800) return "56%";
    else if (height >= 800) return "58%";
  };

  React.useEffect(() => {
    dispatch(
      navigationActions.navigationUpdate(
        { podStackEntryRoute: "Drawer" },
        navState
      )
    );
  }, []);

  return (
    <View style={{ backgroundColor: "white" }}>
      <View style={[styles.topContainer]}>
        <LogoSVG width="75%" height="75%" style={{ flex: 1 }} />
      </View>
      <View style={{ flex: 2 }}>
        <View
          style={{
            alignItems: "center"
          }}>
          <Text
            style={{
              color: theme.colors.purple,
              ...theme.fonts.h4,
              marginBottom: 16
            }}>
            {languageContent.setupComplete}
          </Text>
          <img
            src={PodActive}
            height={getSizeValue()}
            width={getSizeValue()}
            alt=""
          />
          <View
            style={{
              width: "100%",
              paddingHorizontal: 16
            }}>
            <View style={{ marginVertical: 16 }}>
              <Button
                variant="primary"
                style={{ elevation: 6 }}
                onPress={() => {
                  dispatch(
                    navigationActions.navigationUpdate(
                      { podStackEntryRoute: "FTS" },
                      navState
                    )
                  );
                  navigate("ChangePod");
                }}>
                {languageContent.setUpNewPod}
              </Button>
            </View>

            <Button
              variant="cancel"
              style={{ elevation: 6 }}
              onPress={() => {}}>
              {languageContent.notNow}
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  topContainer: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginBottom: 32,
    marginTop: 32
  }
});

export default NewPodLandingScreen;
