import { StyleSheet } from 'react-native';

const UISelectorStyles = StyleSheet.create({
  container: {
    marginBottom: 16,
    zIndex: 1,
  },
  label: {
    fontSize: 32,
    marginBottom: 8,
    fontWeight: 'bold',
  },
  radio: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#783cbc',
    marginBottom: 8,
  },
  radioText: {
    fontSize: 24,
    color: '#783cbc',
    fontWeight: 'bold',
  },
  radioButton: {
    marginRight: 8,
    color: '#783cbc',
  },
});

export default UISelectorStyles;
