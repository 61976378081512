let strings = {
  "en-US": {
    title: "Notifications",
    content:
      "To use the Omnipod\u00A05 app, keep Notifications on. Notifications alert you when an issue needs your attention. You cannot use the app if you turn off Notifications.",
    buttonText: "I Understand"
  },
  "en-GB": {
    title: "Notifications",
    content:
      "To use the Omnipod\u00A05 app, keep Notifications on. Notifications alert you when an issue needs your attention. You cannot use the app if you turn off Notifications.",
    buttonText: "I Understand"
  },
  "de-DE": {
    title: "Meldungen",
    content:
      "Lassen Sie die Meldungen eingeschaltet, um die Omnipod\u00A05 App zu verwenden. Meldungen warnen Sie, wenn ein Problem Ihre Aufmerksamkeit erfordert. Sie können die App nicht verwenden, wenn Sie die Meldungen ausschalten",
    buttonText: "Ich verstehe"
  },
  "es-US": {
    title: "Notificaciones",
    content:
      "Para usar Aplicación Omnipod 5, mantenga las notificaciones prendidas. Las notificaciones le advierten cuando debe atender alguna cuestión. No puede usar la aplicación si apaga las notificaciones.",
    buttonText: "Entiendo"
  },
  "fr-FR": {
    title: "Notifications",
    content:
      "Les notifications vous alertent lorsqu'un problème requiert votre attention. Ne désactivez pas les notifications.",
    buttonText: "J'ai compris"
  },
  "nl-NL": {
    title: "Kennisgevingen",
    content:
      "Houd kennisgevingen ingeschakeld om de Omnipod 5 app te gebruiken. Kennisgevingen waarschuwen u wanneer er iets is waar u naar moet kijken. U kunt de app niet gebruiken als kennisgevingen zijn uitgeschakeld.",
    buttonText: "Begrepen"
  }
};

export { strings };
