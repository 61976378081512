let strings = {
  "en-US": {
    ok: "OK",
    okCamel: "Ok",
    segmentTwo: "Segment 2",
    start: "Start",
    end: "End",
    night: "Night",
    morning: "Morning",
    oneUnitCovers: "1 Unit of insulin covers",
    range: "(1 to 150 g carbs)",
    modalTitle: "Segment End Time",
    modalDesc:
      "For simulation purposes we have auto-filled this information for you.",
    cancel: "Cancel",
    next: "Next",
    minVal: "Minimum Value",
    minValMessage: "Total insulin carb ratio cannot be less than 1\u00A0g.",
    maxVal: "Maximum Value",
    maxValMessage: "Total insulin carb ratio cannot be more than 150\u00A0g."
  },
  "en-GB": {
    ok: "OK",
    okCamel: "Ok",
    segmentTwo: "Segment 2",
    start: "Start",
    end: "End",
    night: "Night",
    morning: "Morning",
    oneUnitCovers: "1 Unit of insulin covers",
    range: "(1 to 150 g carbs)",
    modalTitle: "Segment End Time",
    modalDesc:
      "For simulation purposes we have auto-filled this information for you.",
    cancel: "Cancel",
    next: "Next",
    minVal: "Minimum Value",
    minValMessage: "Total insulin carb ratio cannot be less than 1\u00A0g.",
    maxVal: "Maximum Value",
    maxValMessage: "Total insulin carb ratio cannot be more than 150\u00A0g."
  },
  "de-DE": {
    ok: "OK",
    okCamel: "OK",
    segmentTwo: "Segment 2",
    start: "Start",
    end: "Ende",
    night: "Nacht",
    morning: "Vormittag",
    oneUnitCovers: "1 Einheit Insulin reicht für",
    range: "(1 bis 150 g KH)",
    modalTitle: "Segmentende",
    modalDesc:
      "Zu Simulationszwecken haben wir diese Informationen automatisch für Sie ausgefüllt.",
    cancel: "Abbr.",
    next: "Weiter",
    minVal: "Mindestwert",
    minValMessage:
      "Das Gesamt-Kohlenhydrat-Insulin-Verhältnis darf nicht niedriger sein als 1 g.",
    maxVal: "Höchstwert",
    maxValMessage:
      "Das Gesamt-Kohlenhydrat-Insulin-Verhältnis darf nicht höher sein als 150 g."
  },
  "es-US": {
    ok: "Aceptar",
    okCamel: "Aceptar",
    segmentTwo: "Segmento 2",
    start: "Iniciar",
    end: "Finalizar",
    night: "Noche",
    morning: "Mañana",
    oneUnitCovers: "1 unidad de insulina cubre",
    range: "(1 a 150 g de carb.)",
    modalTitle: "Hora de finalización del segmento",
    modalDesc:
      "Completamos automáticamente esta información para fines de simulación.",
    cancel: "Cancelar",
    next: "Siguiente",
    minVal: "Valor mínimo",
    minValMessage: "La proporción de IC total no puede ser menor de 1 g.",
    maxVal: "Valor máximo",
    maxValMessage: "La proporción de IC total no puede ser mayor de 150 g."
  },
  "fr-FR": {
    ok: "OK",
    okCamel: "Ok",
    segmentTwo: "Segment 2",
    start: "Démarrer",
    end: "Fin",
    night: "Nuit",
    morning: "Matin",
    oneUnitCovers: "1 unité d'insuline couvre",
    range: "(1 à 150 g de glucides)",
    modalTitle: "Heure de fin du segment",
    modalDesc:
      "À des fins de simulation, nous avons rempli automatiquement ces informations pour vous.",
    cancel: "Annuler",
    next: "Suivant",
    minVal: "Valeur minimum",
    minValMessage:
      "Le rapport insuline/glucides total ne peut pas être inférieur à 1 g.",
    maxVal: "Valeur maximum",
    maxValMessage:
      "Le rapport insuline/glucides total ne peut pas être supérieur à 150 g."
  },
  "nl-NL": {
    ok: "OK",
    okCamel: "OK",
    segmentTwo: "Segment 2",
    start: "Start",
    end: "Stop",
    night: "Nacht",
    morning: "Ochtend",
    oneUnitCovers: "1 eenheid insuline is voldoende voor",
    range: "(1 tot 150 g KH)",
    modalTitle: "Eindtijd segment",
    modalDesc:
      "Voor simulatiedoeleinden hebben wij deze informatie automatisch voor u ingevuld.",
    cancel: "Annuleren",
    next: "Volgende",
    minVal: "Minimumwaarde",
    minValMessage: "Totale I/KH-verhouding mag niet minder zijn dan 1 g.",
    maxVal: "Maximumwaarde",
    maxValMessage: "Totale I/KH-verhouding mag niet meer zijn dan 150 g."
  }
};

export { strings };
