import React from "react";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { IntroScreenLayout } from "../../../../organisms";
import { strings } from "./IntroHypoProtectScreenContent";

const IntroHypoProtectScreen: React.FC = () => {
  const image = require("../../../../../../../assets/intro_hypo_protect.jpg");
  const { languageContent } = useLanguageContent(strings);
  return (
    <IntroScreenLayout
      body={languageContent.exercise}
      image={image}
      navigateTo="HypoProtect"
    />
  );
};

export default IntroHypoProtectScreen;
