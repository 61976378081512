import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { hideModal } from 'web/store/reducers/modals/modalsSlice';
import { menuDrawerMessages } from '../BasalPrograms.messages';

interface MenuDrawerProps {
  onStart?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onCancel?: () => void;
}

export const MenuDrawer: React.FC<MenuDrawerProps> = ({
  onStart,
  onEdit,
  onDelete,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const handleCancelClick = () => {
    if (onCancel) {
      onCancel();
    }
    dispatch(hideModal());
  };

  return (
    <div className="absolute inset-0 bg-black/50">
      <div className="absolute bottom-0 px-1 pb-4 w-full">
        <div className="bg-white rounded-[13px] mb-1 flex flex-col">
          {onStart && (
            <button
              className="border-b-1 border-b-ios-gray-9/[0.36] text-xl px-2 py-[18px]"
              onClick={onStart}
            >
              <FormattedMessage {...menuDrawerMessages.start} />
            </button>
          )}
          {onEdit && (
            <button
              className="border-b-1 border-b-ios-gray-9/[0.36] text-xl px-2 py-[18px]"
              onClick={onEdit}
            >
              <FormattedMessage {...menuDrawerMessages.edit} />
            </button>
          )}
          {onDelete && (
            <button
              className="text-xl px-2 py-[18px] text-ios-red"
              onClick={onDelete}
            >
              <FormattedMessage {...menuDrawerMessages.delete} />
            </button>
          )}
        </div>

        <button
          className="bg-white rounded-[13px] w-full px-2 py-[18px] text-xl text-ios-blue font-semibold"
          onClick={handleCancelClick}
        >
          <FormattedMessage {...menuDrawerMessages.cancel} />
        </button>
      </div>
    </div>
  );
};
