import { useNavigation } from "@react-navigation/native"
import React from "react"
import { StyleSheet, Text, TouchableOpacity, View } from "react-native-web"
import theme from "../../../theme"

interface Props {
  label: string
  destination: string
  destinationParams?: Object
}
const TourSectionButton: React.FC<Props> = ({
  label,
  destination,
  destinationParams,
}) => {
  const { navigate } = useNavigation()

  return (
    <View style={styles.purpleButton}>
      <TouchableOpacity
        borderRadius={0}
        onPress={() => {
          {
            destinationParams
              ? navigate(destination, destinationParams)
              : navigate(destination)
          }
        }}
      >
        <Text style={styles.navLink}>{label}</Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  navLink: {
    ...theme.fonts.h3,
    fontWeight: "500",
    marginVertical: 10,
    color: theme.colors.white,
    alignSelf: "flex-start",
    paddingLeft: 32,
  },
  navLinkController: {
    ...theme.fonts.h4,
    fontWeight: "500",
    marginVertical: 10,
    color: theme.colors.white,
    alignSelf: "flex-start",
    paddingLeft: 32,
  },
  purpleButton: {
    backgroundColor: theme.colors.modalPurple,
    width: "85%",
    borderTopLeftRadius: 35,
    borderBottomLeftRadius: 35,
    marginBottom: 16,
    overflow: "hidden",
  },
})

export default TourSectionButton
