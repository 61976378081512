import { createSlice } from '@reduxjs/toolkit';

export const settingName = {
  lowGlucose: 'lowGlucose',
  highGlucose: 'highGlucose',
  missingSensorValues: 'missingSensorValues',
};

interface payloadBody {
  type: 'lowGlucose' | 'highGlucose' | 'missingSensorValues';
  alertOn: boolean;
  overrideVolume: boolean;
  value?: string;
}

const l2SettingsSlice = createSlice({
  name: 'l2Settings',
  initialState: {
    lowGlucose: {
      alertOn: false,
      overrideVolume: false,
      value: '--'
    },
    highGlucose: {
      alertOn: false,
      overrideVolume: false,
      value: '--'
    },
    missingSensorValues: {
      alertOn: false,
      overrideVolume: false,
    },
  },
  reducers: {
    setValueForType: (state, action) => {
      const { type, alertOn, overrideVolume, value = undefined }: payloadBody = action.payload;
      state[type].alertOn = alertOn;
      state[type].overrideVolume = overrideVolume;
      if (value) {
        // @ts-ignore missingSensorValues will never contain a value, and TS complains because it assumes it will.
        state[type].value = value;
      }
    }
  },
});

export const { setValueForType } = l2SettingsSlice.actions;
export const reducer = l2SettingsSlice.reducer;