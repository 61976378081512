import { useNavigation } from '@react-navigation/native';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'web/components/ios/Button';
import { customFoodsActions } from 'web/store/reducers/customFoods';
import { selectCustomFoods } from 'web/store/reducers/customFoods/customFoods.helpers';
import { CustomFoodType } from 'web/store/reducers/customFoods/reducer';
import { IOSCustomFoodsEnums } from '../types';
import {
  hideModal,
  setCurrentModal,
  showModal,
} from 'web/store/reducers/modals/modalsSlice';
import {
  CancelEditingCustomFoods,
  MaxCustomFoodsReached,
} from 'web/components/ios/modals/CustomFoodsModals/CustomFoodsModals';
import { AddCustomFoodsMessages } from '../CustomFoods.messages';
import { FormattedMessage } from 'react-intl';

interface AddCustomFoodScreenProps {
  editCustomFood: CustomFoodType | undefined;
  setEditCustomFood: Dispatch<SetStateAction<CustomFoodType | undefined>>;
}

export const AddCustomFoodScreen: React.FC<AddCustomFoodScreenProps> = ({
  editCustomFood,
  setEditCustomFood,
}) => {
  const { navigate, setParams } = useNavigation();
  const dispatch = useDispatch();
  const [foodName, setFoodName] = useState(
    editCustomFood ? editCustomFood.name : ''
  );
  const [foodCarbs, setFoodCarbs] = useState(
    editCustomFood ? editCustomFood.carbs.toString() : ''
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { customFoods } = useSelector(selectCustomFoods);

  useEffect(() => {
    setParams({
      topBarOnLeftButtonClick: () => {
        showConfirmationModal
          ? handleConfirmationModal()
          : navigate(IOSCustomFoodsEnums.CustomFoodScreen);
      },
    });
  }, [showConfirmationModal]);

  const cancelEditingCustomFoodHandler = () => {
    setEditCustomFood(undefined);
    dispatch(hideModal());
    navigate(IOSCustomFoodsEnums.CustomFoodScreen);
  };

  const handleConfirmationModal = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <CancelEditingCustomFoods
            rightButtonOnClickHandler={() => cancelEditingCustomFoodHandler()}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  const maxCustomFoodReachedModalHandler = () => {
    dispatch(
      setCurrentModal({
        modal: <MaxCustomFoodsReached />,
      })
    );
    dispatch(showModal());
  };

  const isCustomFoodNotValid = (): boolean => {
    return (
      foodName.length > 20 ||
      foodName.length === 0 ||
      foodName.length === undefined ||
      foodCarbs === '0' ||
      foodCarbs === undefined ||
      foodCarbs === ''
    );
  };

  const updateCustomFood = () => {
    if (
      editCustomFood?.name !== foodName ||
      editCustomFood?.carbs.toString() !== foodCarbs
    ) {
      const updatedCustomFoods = customFoods.find(
        (customFood) => customFood.id === editCustomFood?.id
      );
      if (updatedCustomFoods) {
        updatedCustomFoods.name = foodName;
        updatedCustomFoods.carbs = Number.parseInt(foodCarbs);
        updatedCustomFoods.newFood = false;
        dispatch(
          customFoodsActions.customFoodsUpdate({
            customFoods: customFoods,
          })
        );
        setEditCustomFood(undefined);
        navigate(IOSCustomFoodsEnums.CustomFoodScreen);
      }
    }
  };

  const addCustomFood = () => {
    if (customFoods.length === 50) {
      maxCustomFoodReachedModalHandler();
    } else {
      dispatch(
        customFoodsActions.customFoodsUpdate({
          customFoods: [
            ...customFoods,
            {
              id: customFoods.length + 1,
              name: foodName,
              carbs: Number.parseInt(foodCarbs),
              newFood: true,
            },
          ],
        })
      );
      navigate(IOSCustomFoodsEnums.CustomFoodScreen);
    }
  };

  const onClickHandler = () => {
    if (editCustomFood) {
      updateCustomFood();
    } else {
      addCustomFood();
    }
  };

  const onChangeHandler = (event: any) => {
    switch (event.target.id) {
      case 'food-name-input':
        if (editCustomFood) {
          if (editCustomFood?.name !== event.target.value) {
            setShowConfirmationModal(true);
          } else {
            setShowConfirmationModal(false);
          }
        } else {
          if (event.target.value !== '') {
            setShowConfirmationModal(true);
          } else {
            setShowConfirmationModal(false);
          }
        }
        setFoodName(event.target.value);
        break;

      case 'food-carbs-input':
        if (Number.parseInt(event.target.value) || event.target.value === '') {
          if (editCustomFood) {
            if (editCustomFood?.carbs !== Number.parseInt(event.target.value)) {
              setShowConfirmationModal(true);
            } else {
              setShowConfirmationModal(false);
            }
          } else {
            if (event.target.value !== '') {
              setShowConfirmationModal(true);
            } else {
              setShowConfirmationModal(false);
            }
          }
          setFoodCarbs(event.target.value);
        }
        break;

      default:
        break;
    }
  };

  return (
    <div className="flex flex-col justify-between w-full bg-white h-full font-ios text-regular ">
      <div>
        <div className="mt-3">
          <p className="text-small font-semibold pl-2 mb-1">
            <FormattedMessage {...AddCustomFoodsMessages.foodNameLabel} />
          </p>
          <input
            onChange={onChangeHandler}
            value={foodName}
            type="text"
            name="food-name-input"
            id="food-name-input"
            placeholder="Enter a name"
            maxLength={20}
            required
            className="w-full outline-non pl-2 pb-1 text-4xl font-bold invalid:text-ios-gray-9/30 border-b-[0.5px] border-b-ios-gray-9/30"
          />
          <p className=" pl-2 pt-1 text-small text-ios-gray-9/60">
            {`${foodName.length}`}
            <span>
              <FormattedMessage
                {...AddCustomFoodsMessages.characterLimitLabel}
              />
            </span>
          </p>
        </div>
        <div className="mt-4">
          <p className="text-small font-semibold pl-2 mb-1">
            <FormattedMessage {...AddCustomFoodsMessages.carbsNameLabel} />
          </p>
          <input
            onChange={onChangeHandler}
            type="text"
            inputMode="numeric"
            pattern="\d*"
            maxLength={3}
            value={foodCarbs}
            name="food-carbs-input"
            id="food-carbs-input"
            placeholder="0 g"
            required
            className="w-full outline-none  pl-2 pb-1 text-4xl font-bold invalid:text-ios-gray-9/30 border-b-[0.5px] border-b-ios-gray-9/30"
          />
        </div>
      </div>
      <div className="px-2">
        <Button
          className="w-full mb-4"
          variant="colored"
          disabled={isCustomFoodNotValid()}
          onClick={onClickHandler}
        >
          <FormattedMessage {...AddCustomFoodsMessages.saveButton} />
        </Button>
      </div>
    </div>
  );
};
