import React from 'react';
import { SegmentImagesEnum } from 'web/store/reducers/basalPrograms/basalProgramsSlice';
import { ReactComponent as WeekendSegment1 } from 'assets/ios/basal-segments/weekend-segment-1.svg';
import { ReactComponent as WeekendSegment2 } from 'assets/ios/basal-segments/weekend-segment-2.svg';
import { ReactComponent as RelaxDaySegment1Empty } from 'assets/ios/basal-segments/relax-day-segment-1-empty.svg';
import { ReactComponent as RelaxDaySegment1 } from 'assets/ios/basal-segments/relax-day-segment-1.svg';
import { ReactComponent as RelaxDaySegment2Empty } from 'assets/ios/basal-segments/relax-day-segment-2-empty.svg';
import { ReactComponent as RelaxDaySegment2 } from 'assets/ios/basal-segments/relax-day-segment-2.svg';
import { ReactComponent as WeekendSegments } from 'assets/ios/basal-segments/weekend-segments.svg';
import { ReactComponent as RelaxDaySegments } from 'assets/ios/basal-segments/relax-day-segments.svg';

interface  BasalSegmentImageProps {
  imageName?: string;
}

export const BasalSegmentImage: React.FC<BasalSegmentImageProps> = ({ imageName }) => {
  switch (imageName) {
    case SegmentImagesEnum.RelaxDaySegments:
      return <RelaxDaySegments />;
    case SegmentImagesEnum.WeekendSegments:
      return <WeekendSegments />;
    case SegmentImagesEnum.RelaxDaySegment1:
      return <RelaxDaySegment1 />;
    case SegmentImagesEnum.RelaxDaySegment2Empty:
      return <RelaxDaySegment2Empty />;
    case SegmentImagesEnum.RelaxDaySegment2:
      return <RelaxDaySegment2 />;
    case SegmentImagesEnum.WeekendSegment2:
      return <WeekendSegment2 />;
    case SegmentImagesEnum.WeekendSegment1:
      return <WeekendSegment1 />;
    case SegmentImagesEnum.RelaxDaySegment1Empty:
    default:
      return <RelaxDaySegment1Empty />;
  }
}