import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import LatestAppStack from '../latest/routes/AppStack';
import { hideModal } from 'web/store/reducers/modals/modalsSlice';
import { selectCurrentModalData } from 'web/store/reducers/modals/modals.helpers';

const Stack = createStackNavigator();

const NavigationCluster = () => {
  const currentModalData = useSelector(selectCurrentModalData);
  const dispatch = useDispatch();

  return (
    <NavigationContainer independent={true}>
      <Stack.Navigator initialRouteName={'root'} headerMode="none" mode="modal">
        <Stack.Screen
          name="root"
          options={{
            headerShown: false,
            animationEnabled: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          listeners={() => ({
            transitionStart: (e) => dispatch(hideModal()),
          })}
          component={LatestAppStack}
        />
      </Stack.Navigator>
      {currentModalData.showModal && currentModalData.currentModal}
    </NavigationContainer>
  );
};

export default NavigationCluster;
