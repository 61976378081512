import theme from 'mobile/latest/theme';
import { StyleSheet } from 'react-native';

const G7ManualFlowStyles = StyleSheet.create({
    container: {
      height: '100%',
      width: '100%',
      backgroundColor: theme.colors.white,
      paddingHorizontal: 16,
      paddingTop: 24,
      display: 'flex',
      flexDirection: 'column',
    },
    textContainerPairingCode: {
      flex: 1,
    },
    textContainerSetup: {
      flex: 2,
    },
    paragraph: {
      marginTop: 15,
    },
    modalParagraph: {
      marginTop: 15,
      textAlign: 'center',
    },
    applicatorContainer: {
      padding: 25,
      backgroundColor: theme.colors.applicatorContainerBackground,
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 20,
    },
  });

export default G7ManualFlowStyles;