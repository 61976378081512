export const getUnitByLocale = (
  value: number,
  units: 'mg/dL' | 'mmol/L',
  lang?:
    | 'en-US'
    | 'es-US'
    | 'en-CA'
    | 'fr-CA'
    | 'fr-FR'
    | 'de-DE'
    | 'nl-NL'
    | 'en-GB'
) => {
  let res;
  if (units === 'mg/dL') {
    return value;
  }

  const val = Math.round(value / 18);
  if (val === 0) {
    res = (val + 1).toLocaleString(lang ? lang : 'en-US');
    return res;
  } else {
    let res = (value / 18.02).toFixed(1);
    res = Number(res).toLocaleString(lang ? lang : 'en-US');
    return res;
  }
};
