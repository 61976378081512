import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  lastUpdated: {
    id: 'ios.activity-drawer.last-updated',
    defaultMessage: 'Updated Today at {date}'
  },
  activityStatus: {
    id: 'ios.activity-drawer.activity-status',
    defaultMessage: 'Activity is ON'
  },
  status: {
    id: 'ios.activity-drawer.status',
    defaultMessage: 'STATUS'
  },
  remainingTime: {
    id: 'ios.activity-drawer.remaining-time',
    defaultMessage: 'Remaining ({remainingTime})'
  },
  cancelButton: {
    id: 'ios.activity-drawer.cancel-button',
    defaultMessage: 'Cancel Activity'
  },
})