import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const DropIcon: React.FC<IconProps> = ({
  color,
  width = '18',
  height = '18',
}) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons/History/AverageCGM/Light">
        <rect width="18" height="18" fill="white" />
        <g id="Icons/History/AverageCGM">
          <path
            className={classes}
            id="Path 2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.9375 15.3449C9.41906 15.3449 9 14.9423 9 14.4443C9 13.9463 9.41906 13.5437 9.9375 13.5437C11.2509 13.5437 11.8125 12.9953 11.8125 11.7075C11.8125 11.2094 12.2316 10.8069 12.75 10.8069C13.2675 10.8069 13.6875 11.2094 13.6875 11.7075C13.6875 13.985 12.2859 15.3449 9.9375 15.3449ZM9.69677 0.264093C9.33194 -0.0880311 8.66806 -0.0880311 8.30323 0.264093C7.60742 0.935922 1.5 6.94635 1.5 11.0206C1.5 14.8687 4.86484 18 9 18C13.1352 18 16.5 14.8687 16.5 11.0206C16.5 6.94635 10.3926 0.935922 9.69677 0.264093Z"
            fill="#006FDD"
          />
          <mask
            id="mask0_0_435"
            maskUnits="userSpaceOnUse"
            x="1"
            y="0"
            width="16"
            height="18"
          >
            <path
              id="Path 2_2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.9375 15.3449C9.41906 15.3449 9 14.9423 9 14.4443C9 13.9463 9.41906 13.5437 9.9375 13.5437C11.2509 13.5437 11.8125 12.9953 11.8125 11.7075C11.8125 11.2094 12.2316 10.8069 12.75 10.8069C13.2675 10.8069 13.6875 11.2094 13.6875 11.7075C13.6875 13.985 12.2859 15.3449 9.9375 15.3449ZM9.69677 0.264093C9.33194 -0.0880311 8.66806 -0.0880311 8.30323 0.264093C7.60742 0.935922 1.5 6.94635 1.5 11.0206C1.5 14.8687 4.86484 18 9 18C13.1352 18 16.5 14.8687 16.5 11.0206C16.5 6.94635 10.3926 0.935922 9.69677 0.264093Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_0_435)"></g>
        </g>
      </g>
    </svg>
  );
};
