import { defineMessages } from 'react-intl';

export const historyEventSwitchModeMessages = defineMessages({
  automatedModeTitle: {
    id: 'ios.history-event-switch-mode.automated-mode-title',
    defaultMessage: 'Switched to Automated Mode',
  },
  manualModeTitle: {
    id: 'ios.history-event-switch-mode.manual-mode-title',
    defaultMessage: 'Switched to Manual Mode',
  },
});
