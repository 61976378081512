import React, { useState } from 'react';
import { FormattedDate, FormattedTime, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentOperationMode } from 'web/store/reducers/user/user.helpers';
import { selectLanguageIs24Hours } from 'web/store/reducers/language/language.helpers';
import {
  showModal,
  setCurrentModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import classNames from 'classnames';
import { Button } from 'web/components/ios/Button';
import { messages } from '../Activity.messages';
import { ConfirmActivityModal } from 'web/components/ios/modals/ActivityModals/ActivityModals';
import { AppRoutesEnum } from 'web/routes/types';
import { navigateToRoute } from 'web/store/reducers/navigation/actions';
import { setActivityMode } from 'web/store/reducers/user/actions';

const TARGET_HOURS = 9;

export const ActivityDuration: React.FC = () => {
  const dispatch = useDispatch();
  const [currentHours, setCurrentHours] = useState<number | null>(null);
  const currentOperationMode = useSelector(selectCurrentOperationMode);
  const is24Hours = useSelector(selectLanguageIs24Hours);
  const futureDate = new Date().setHours(new Date().getHours() + TARGET_HOURS);
  const handlePodCommTimeout = () => {
    dispatch(hideModal());
    dispatch(setActivityMode({ endDate: new Date(futureDate) }));
    dispatch(navigateToRoute(AppRoutesEnum.dashboard));
  };
  const handleOnConfirm = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <ConfirmActivityModal
            hours={TARGET_HOURS}
            handlePodCommTimeout={handlePodCommTimeout}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="font-ios text-regular h-full bg-white flex justify-between flex-col pb-4">
      <div className="pt-2">
        <div className="px-2">
          <FormattedMessage
            {...messages.text}
            values={{
              br: (
                <>
                  <br />
                  <br />
                </>
              ),
            }}
          />
        </div>

        <div className="flex flex-row items-center justify-between border-b-1 pb-2 px-2 mt-4">
          <div>
            <div>
              <FormattedMessage {...messages.duration} />
            </div>
            <div className="text-small text-ios-gray-9/60">
              {!!currentHours && (
                <span className="block text-small tex">
                  <FormattedMessage
                    {...messages.ends}
                    values={{
                      date: (
                        <>
                          <FormattedDate value={futureDate} weekday="short" /> (
                          <FormattedTime
                            value={futureDate}
                            hour12={!is24Hours}
                          />
                          )
                        </>
                      ),
                    }}
                  />
                </span>
              )}
              {!currentHours && (
                <span>
                  <FormattedMessage {...messages.timeRange} />
                </span>
              )}
            </div>
          </div>
          <div
            className={classNames({
              'text-ios-gray-9/60': !currentHours,
              [`text-modes-${currentOperationMode}`]: !!currentHours,
            })}
          >
            <span
              className="cursor-pointer"
              onClick={() => setCurrentHours(TARGET_HOURS)}
            >
              {currentHours || '--'}{' '}
              <FormattedMessage
                {...messages.hour}
                values={{ value: currentHours }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="px-2">
        <Button
          variant="colored"
          colorMode={currentOperationMode}
          full
          disabled={!currentHours}
          onClick={handleOnConfirm}
        >
          <FormattedMessage {...messages.confirmButton} />
        </Button>
      </div>
    </div>
  );
};
