import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { hideModal } from 'web/store/reducers/modals/modalsSlice';
import { Modal } from '../../Modal';
import {
  cancelTempBasalMessages,
  confirmTempBasalMessages,
} from './TempBasalModals.message';
import { PodCommunicationModal } from '../GlobalModals/GlobalModals';
import { cancelTempBasal } from 'web/store/reducers/user/actions';

interface ConfirmTempBasalModalProps {
  handlePodCommTimeout: () => void;
}

export const ConfirmTempBasalModal: React.FC<ConfirmTempBasalModalProps> = ({
  handlePodCommTimeout,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showPodComm, setShowPodComm] = useState<boolean>(false);

  if (showPodComm) {
    return <PodCommunicationModal onTimeout={handlePodCommTimeout} />;
  }

  return (
    <Modal
      rightButtonText={intl.formatMessage(confirmTempBasalMessages.rightButton)}
      rightButtonOnClick={() => setShowPodComm(true)}
      leftButtonText={intl.formatMessage(confirmTempBasalMessages.leftButton)}
      leftButtonOnClick={() => dispatch(hideModal())}
      title={intl.formatMessage(confirmTempBasalMessages.text)}
    />
  );
};

interface CancelTempBasalModalProps {
  handlePodCommTimeout?: () => void;
}

export const CancelTempBasalModal: React.FC<CancelTempBasalModalProps> = ({
  handlePodCommTimeout,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showPodComm, setShowPodComm] = useState<boolean>(false);

  const onPodCommTimeout = () => {
    dispatch(cancelTempBasal());
    if (handlePodCommTimeout) {
      handlePodCommTimeout();
    }
    dispatch(hideModal());
  };

  if (showPodComm) {
    return <PodCommunicationModal onTimeout={onPodCommTimeout} />;
  }

  return (
    <Modal
      rightButtonText={intl.formatMessage(cancelTempBasalMessages.rightButton)}
      rightButtonOnClick={() => setShowPodComm(true)}
      leftButtonText={intl.formatMessage(cancelTempBasalMessages.leftButton)}
      leftButtonOnClick={() => dispatch(hideModal())}
      title={intl.formatMessage(cancelTempBasalMessages.text)}
      width="w-[273px]"
    />
  );
};
