let strings = {
  "en-US": {
    viewBolusCalc: "view bolus calculations"
  },
  "en-GB": {
    viewBolusCalc: "view bolus calculations"
  },
  "de-DE": {
    viewBolusCalc: "Bolusberechnungen anzeigen"
  },
  "es-US": {
    viewBolusCalc: "ver los cálculos del bolo"
  },
  "fr-FR": {
    viewBolusCalc: "afficher les calculs de bolus"
  },
  "nl-NL": {
    viewBolusCalc: "bolusberekening weergeven"
  }
};

export { strings };
