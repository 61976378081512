let strings = {
  "en-US": {
    bolus: "Bolus:",
    calc: "Calculated",
    glucose: "Glucose:",
    carbs: "Carbs:",
    manual: "Manual"
  },
  "en-GB": {
    bolus: "Bolus:",
    calc: "Calculated",
    glucose: "Glucose:",
    carbs: "Carbs:",
    manual: "Manual"
  },
  "de-DE": {
    bolus: "Bolus:",
    calc: "Berechnet",
    glucose: "Glukose:",
    carbs: "KH:",
    manual: "Manuell"
  },
  "es-US": {
    bolus: "Bolo:",
    calc: "Calculado",
    glucose: "GS:",
    carbs: "Carbohidratos:",
    manual: "Manual"
  },
  "fr-FR": {
    bolus: "Bolus :",
    calc: "Calculé",
    glucose: "Glucose :",
    carbs: "Glucides :",
    manual: "Manuel"
  },
  "nl-NL": {
    bolus: "Bolus:",
    calc: "Berekend",
    glucose: "Glucose:",
    carbs: "KH:",
    manual: "Handmatig"
  }
};

export { strings };
