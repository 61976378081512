let strings = {
  "en-US": {
    enterSN: "Enter SN",
    transmitter: "Transmitter",
    sensorSelect: "Sensor Selection",
    applySensor: "Apply Sensor",
    applyingSensor: "Applying a Sensor",
    scanSensor: "Scan Sensor",
    scanningSensor: "Scanning a Sensor",
    manageSensorIOS: "Manage Sensor",
    confirmIOS: "Confirm",
    headerTextIOS: "Confirm Serial Number"
  },
  "en-GB": {
    enterSN: "Enter SN",
    sensorSelect: "Sensor Selection",
    applySensor: "Apply Sensor",
    applyingSensor: "Applying a Sensor",
    scanSensor: "Scan Sensor",
    scanningSensor: "Scanning a Sensor",
    transmitter: "Transmitter",
    manageSensorIOS: "Manage Sensor",
    confirmIOS: "Confirm",
    headerTextIOS: "Confirm Serial Number"
  },
  "de-DE": {
    enterSN: "SN eingeben",
    manageSensorIOS: "",
    confirmIOS: "",
    headerTextIOS: "",
    transmitter: "Transmitter",
    sensorSelect: "Sensor Selection",
    applySensor: "Apply Sensor",
    applyingSensor: "Applying a Sensor",
    scanSensor: "Scan Sensor",
    scanningSensor: "Scanning a Sensor"
  },
  "es-US": {
    enterSN: "Administrar SN",
    manageSensorIOS: "Administrar sensor",
    confirmIOS: "",
    headerTextIOS: "",
    transmitter: "Transmisor",
    sensorSelect: "Selección del Sensor",
    applySensor: "Apply Sensor",
    applyingSensor: "Applying a Sensor",
    scanSensor: "Scan Sensor",
    scanningSensor: "Scanning a Sensor"
  },
  "nl-NL": {
    enterSN: "SN invoeren",
    transmitter: "Zender",
    sensorSelect: "Sensorselectie",
    applySensor: "Breng de sensor aan",
    applyingSensor: "Een sensor aanbrengen",
    scanSensor: "Scan de Sensor",
    scanningSensor: "Een Sensor scannen",
    manageSensorIOS: "Sensor beheren",
    confirmIOS: "Bevestigen",
    headerTextIOS: "Bevestig serienummer"
  },
  "fr-FR": {
    enterSN: "Saisir le numéro de série",
    transmitter: "Émetteur",
    manageSensorIOS: "Gérer le capteur",
    confirmIOS: "Confirmer",
    headerTextIOS: "Confirmer le numéro de série"
  }
};

export { strings };
