const strings = {
  'en-US': {
    confirmPairingCode: 'Confirm Pairing Code',
    codeNumber: '1  2  3  4',
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
  'es-US': {
    confirmPairingCode: 'Confirmar Código de Emparejamiento',
    codeNumber: '1 2 3 4',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
  },
};

export { strings };
