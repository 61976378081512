import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { BackHandler, Pressable, StyleSheet, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedNumberFormat } from "../../../../../../helpers/getLocalizedNumberFormat";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useExpertHardwareBackAction from "../../../../../../hooks/useExpertHardwareBackAction";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import {
  bolusActions,
  BolusState
} from "../../../../../../../../web/store/reducers/bolus";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { Header, Paragraph } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./SetMaxBolusScreenContent";

const MAX_BOLUS_FIELD = "MAX_BOLUS";
interface Props {
  navigation: any;
}

const SetMaxBolusScreen: React.FC<Props> = ({ navigation }) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );

  const [fieldValue, setFieldValue] = React.useState<string>(
    bolusState.maxBolus ? String(bolusState.maxBolus) : ""
  );

  const [highlighted, setHighlighted] = React.useState<string>("");

  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);
  const backAction = useExpertHardwareBackAction(navigation, "MaxBolus");

  const lowerRange = (0.05).toLocaleString(langState.locale);
  const contentVariables = [lowerRange];

  const getAdornmentColor = () => {
    if (!fieldValue) {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  React.useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", backAction);

    return () =>
      BackHandler.removeEventListener("hardwareBackPress", backAction);
  }, []);

  return (
    <View style={[styles.container]}>
      <Header
        label={languageContent.setMaxBolus}
        styles={{ marginTop: 32, marginBottom: 24, paddingVertical: 0 }}
      />
      <View style={[{ flex: 1, width: "100%", paddingHorizontal: 16 }]}>
        <Paragraph
          label={languageContent.maxAmount}
          variant="body1"
          styles={{ marginBottom: 16 }}
        />
        <View
          style={[
            {
              paddingBottom: 16,
              marginTop: 8,
              zIndex: -1
            }
          ]}>
          <View style={[{ marginTop: 16 }]}>
            <Text style={[theme.fonts.h4]}>{languageContent.maxBolus}</Text>
            <Text
              style={[
                theme.fonts.body1,
                { color: theme.colors.text.cancel, marginBottom: 8 }
              ]}>
              {replaceWithValues(languageContent.range, contentVariables)}
            </Text>
          </View>
          <Pressable
            style={[
              theme.layout.inputContainerStyle,
              {
                zIndex: 999,
                borderColor:
                  highlighted === MAX_BOLUS_FIELD
                    ? theme.colors.blue
                    : theme.colors.textInputBorder
              }
            ]}
            onPress={() => {
              setFieldValue("20");
              setHighlighted(MAX_BOLUS_FIELD);
            }}>
            <Text style={[theme.layout.inputStyle]}>
              {fieldValue === "" ? "" : fieldValue}
            </Text>
            <Text
              style={[
                theme.layout.adornment,
                {
                  color: getAdornmentColor()
                }
              ]}>
              {languageContent.u}
            </Text>
          </Pressable>
        </View>
      </View>
      <BottomInnerNav
        leftActionStyle={{ color: theme.colors.text.cancel }}
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.next}
        rightAction={() => {
          if (Number(fieldValue) < 0.05 || Number(fieldValue) > 30) {
            return;
          }

          dispatch(
            bolusActions.bolusUpdate(
              {
                maxBolus: fieldValue.includes(",")
                  ? Number(getLocalizedNumberFormat(fieldValue))
                  : Number(fieldValue)
              },
              bolusState
            )
          );
          navigate("AllowExtendedBolus");
        }}
        rightActionStyle={{
          color:
            Number(fieldValue) < 0.05 || Number(fieldValue) > 30
              ? theme.colors.placeholder
              : theme.colors.purple
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "#fff"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },

  periodText: {
    color: theme.colors.grayScale.gray400,
    fontSize: 18
  }
});

export default SetMaxBolusScreen;
