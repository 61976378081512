import React from "react";
import { StyleSheet, Text, View } from "react-native-web";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import { strings } from "./PodDetailsSectionContent";

interface Props {
  title: string;
  value: string;
  lastUpdateDay?: string;
  lastUpdateTime?: string;
  expiresDate?: string;
  expiresTime?: string;
}

const PodDetailsSection: React.FC<Props> = ({
  title,
  value,
  lastUpdateDay,
  lastUpdateTime,
  expiresDate,
  expiresTime
}) => {
  const { languageContent } = useLanguageContent(strings);
  return (
    <View style={[styles.container]}>
      <View style={{ flexDirection: "column" }}>
        <Text style={[theme.layout.boldDesc, styles.sectionSubheaderValue]}>
          {title}
        </Text>
        <Text style={[styles.sectionHeaderValue, { marginVertical: 8 }]}>
          {value}
        </Text>
        <Text
          style={[
            styles.sectionSubheaderValue,
            {
              lineHeight: 22,
              fontWeight: "400"
            }
          ]}>
          {lastUpdateDay && `(${lastUpdateDay}, ${lastUpdateTime})`}
          {expiresDate && `${expiresDate} ${expiresTime}`}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder,
    paddingHorizontal: 16,
    paddingVertical: 12
  },
  sectionHeaderValue: {
    ...theme.fonts.h4,
    textAlign: "left",
    fontWeight: "500"
  },
  sectionSubheaderValue: {
    ...theme.fonts.text600,
    color: theme.colors.text.grayText
  }
});

export default PodDetailsSection;
