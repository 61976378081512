import React from "react";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { IntroScreenLayout } from "../../../../organisms";
import { strings } from "./IntroTempBasalScreenContent";

const IntroTempBasalScreen: React.FC = () => {
  const image = require("../../../../../../../assets/setup_intro_bg_basal_5.png");
  const { languageContent } = useLanguageContent(strings);
  return (
    <IntroScreenLayout
      body={languageContent.tempBasalIntro}
      image={image}
      navigateTo="TempBasal"
    />
  );
};

export default IntroTempBasalScreen;
