import { useIntl } from 'react-intl';
import { BottomNav } from 'web/components/ios/BottomMenu/BottomNav';
import { HistoryToggler } from '../components/HistoryToggler/HistoryToggler';
import { HistorySummary } from './HistorySummary';
import { HistoryEvents } from './HistoryEvents';
import { historySummaryMessages } from '../History.messages';
import { useState } from 'react';

export const History = () => {
  const intl = useIntl();
  const [isToggle, setIsToggle] = useState(false);
  return (
    <>
      <div className="font-ios pt-2 h-full bg-white flex justify-between flex-col">
        <div
          onClick={() => setIsToggle(!isToggle)}
          className="flex basis-1/12 px-2"
        >
          <HistoryToggler
            isToggle={isToggle}
            leftTitle={intl.formatMessage(
              historySummaryMessages.summaryTogglerTitle
            )}
            rightTitle={intl.formatMessage(
              historySummaryMessages.eventsTogglerTitle
            )}
          />
        </div>
        <div className="flex flex-col basis-11/12 overflow-scroll">
          {isToggle ? <HistoryEvents /> : <HistorySummary />}
        </div>
        <BottomNav />
      </div>
    </>
  );
};
