let strings = {
  "en-US": {
    filter: "Filter By Date"
  },
  "en-GB": {
    filter: "Filter By Date"
  },
  "de-DE": {
    filter: "Nach Datum filtern"
  },
  "es-US": {
    filter: "Filtrar por fecha"
  },
  "fr-FR": {
    filter: "Filtrer par date"
  },
  "nl-NL": {
    filter: "Op datum filteren"
  }
};

export { strings };
