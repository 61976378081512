import React from "react";
import { isEqual } from "lodash";
import { View, Text, StyleSheet } from "react-native";
import { getUnitByLocale } from "../../../helpers/getUnitByLocale";
import { LangState } from "../../../../../web/store/reducers/language";
import { RootState } from "../../../../../web/store/store";
import useLanguageContent from "../../../hooks/useLanguageContent";
import { useSelector } from "react-redux";
import { strings } from "./HypoDetailsContent";
import replaceWithValues from "../../../helpers/replaceWithValues";
import theme from "../../../theme";

const HypoDetails: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const hypoUnits = getUnitByLocale(150, langState.units, langState.locale);
  const contentVariables = [hypoUnits];

  const { languageContent } = useLanguageContent(strings);
  return (
    <View style={{ paddingHorizontal: 16 }}>
      <Text style={[styles.mainText]}>{languageContent.hypoDetails1}</Text>
      <Text style={[styles.mainText]}>
        {replaceWithValues(languageContent.hypoDetails2, contentVariables)}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  mainText: {
    ...theme.fonts.text200,
    fontWeight: "400",
    marginBottom: 24
  }
});

export default HypoDetails;
