import { initialState as sensorInitialState, SensorState } from './reducer';

export const SENSOR_UPDATE = 'sensor_update';
export const SENSOR_INIT = 'sensor_init';
export const SENSOR_RESET = 'sensor_reset';

interface ActionUpdate {
  type: ActionType;
  payload: {
    sensor: Partial<SensorState>;
  };
}

interface ActionInit {
  type: ActionType;
  payload: {
    sensor: SensorState;
  };
}

type ActionType = typeof SENSOR_UPDATE | typeof SENSOR_INIT;

export const sensorUpdate = (
  sensorData: Partial<SensorState>,
  initialState?: SensorState
): Action => {
  return {
    type: SENSOR_UPDATE,
    payload: {
      sensor: sensorData,
    },
  };
};

export const sensorInit = (): Action => {
  return {
    type: SENSOR_INIT,
    payload: {
      sensor: sensorInitialState,
    },
  };
};

export type Action = ActionUpdate | ActionInit;
