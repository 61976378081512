import { defineMessages } from 'react-intl';

export const CustomFoodMessages = defineMessages({
  newLabel: {
    id: 'ios.custom-food.new-label',
    defaultMessage: 'NEW',
  },
  carbsUnit: {
    id: 'ios.custom-food.carbs-unit',
    defaultMessage: 'g',
  },
});
