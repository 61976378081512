import { useNavigation } from "@react-navigation/native";
import { decode } from "html-entities";
import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Keyboard,
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  TextInput,
  View
} from "react-native";
import { TouchableOpacity } from "react-native-web";
import { ReactComponent as CloseEyeSVG } from "../../../../../../../../assets/eyeClosed.svg";
import { ReactComponent as OpenEyeSVG } from "../../../../../../../../assets/eyeOpened.svg";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./SetPinScreenContent";

const dot = decode("&bull;");

const { height } = Dimensions.get("window");

interface Fields {
  one: string;
  two: string;
  three: string;
  four: string;
}

const initialFields = {
  one: "",
  two: "",
  three: "",
  four: ""
};

const SetPinScreen: React.FC = () => {
  const [fields, setFields] = useState<Fields>(initialFields);
  const [pin, setPin] = useState<Fields>(initialFields);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [secure, setSecure] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [fieldInFocus, setFieldInFocus] = useState<string>("");

  // refs for each input box
  const oneRef = React.useRef<TextInput>(null);
  const twoRef = React.useRef<TextInput>(null);
  const threeRef = React.useRef<TextInput>(null);
  const fourRef = React.useRef<TextInput>(null);

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const handleBlur = (field: string) => {
    if (field === fieldInFocus) {
      setFieldInFocus("");
    }
  };

  const changeField = (key: string, value: string) => {
    let val = value;

    //if they delete a value from a text input, set the state to an empty string
    if (val === "") {
      setFields({ ...fields, [key]: "" });
      return;
    }
    //if they enter a value that is not a number, early return
    if (isNaN(parseInt(val))) {
      return;
    }

    setFields({ ...fields, [key]: val });
    // set focus for the next input based on the current input's key.
    if (!error) {
      switch (key) {
        case "one":
          twoRef.current?.focus();
          break;
        case "two":
          threeRef.current?.focus();
          break;
        case "three":
          fourRef.current?.focus();
          break;
      }
    }
  };

  useEffect(() => {
    if (confirm && fields.one && fields.two && fields.three && fields.four) {
      // making sure the pin and the confirm pin match.
      if (
        fields.one === pin.one &&
        fields.two === pin.two &&
        fields.three === pin.three &&
        fields.four === pin.four
      ) {
        // do work here
        navigate("Alerts");
      } else {
        // let them know it failed and start them over by clearing state and setting focus back to the first input.
        setFields(initialFields);
        setPin(initialFields);
        setConfirm(false);
        setError(true);
        Keyboard.dismiss();
      }
    }
    if (!confirm && fields.one && fields.two && fields.three && fields.four) {
      // this changes us over to the confirm step, clearing fields and setting their values to a second set of state called pin.
      setConfirm(true);
      setPin({ ...fields });
      setFields(initialFields);
      oneRef.current?.focus();
    }
  }, [fields, error, confirm, pin]);

  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={0}
      style={[
        theme.layout.wrapper,
        { width: "100%", backgroundColor: "white" }
      ]}>
      <View style={[styles.innerWrapper, { width: "100%" }]}>
        <Text
          style={[
            theme.fonts.h3,
            { marginVertical: 16, textAlign: "center", fontWeight: "200" }
          ]}>
          {languageContent.preventAccess}
        </Text>
        <View>
          {confirm ? (
            <Text
              style={[
                theme.fonts.h4,
                styles.pinFont,
                { color: theme.colors.purple }
              ]}>
              {languageContent.reenterPin}
            </Text>
          ) : (
            <Text style={[theme.fonts.h4, styles.pinFont]}>
              {languageContent.setPin}
            </Text>
          )}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}>
          <TextInput
            keyboardType="phone-pad"
            selectionColor={theme.colors.blue}
            returnKeyType="done"
            value={secure && fields.one ? dot : fields.one}
            onChangeText={(value) => {
              changeField("one", value);
            }}
            onFocus={() => {
              setError(false);
              setFieldInFocus("one");
            }}
            ref={oneRef}
            style={[
              styles.pinInput,
              {
                borderColor:
                  fieldInFocus === "one"
                    ? theme.colors.blue
                    : theme.colors.textInputBorder
              }
            ]}
            onBlur={() => handleBlur("one")}
            maxLength={1}
            autoFocus
          />
          <TextInput
            keyboardType="number-pad"
            selectionColor={theme.colors.blue}
            returnKeyType="done"
            value={secure && fields.two ? dot : fields.two}
            onChangeText={(value) => {
              changeField("two", value);
            }}
            ref={twoRef}
            maxLength={1}
            style={[
              styles.pinInput,
              {
                borderColor:
                  fieldInFocus === "two"
                    ? theme.colors.blue
                    : theme.colors.textInputBorder
              }
            ]}
            onFocus={() => {
              setError(false);
              setFieldInFocus("two");
            }}
            onBlur={() => handleBlur("two")}
          />
          <TextInput
            keyboardType="number-pad"
            selectionColor={theme.colors.blue}
            returnKeyType="done"
            value={secure && fields.three ? dot : fields.three}
            onChangeText={(value) => {
              changeField("three", value);
            }}
            ref={threeRef}
            maxLength={1}
            style={[
              styles.pinInput,
              {
                borderColor:
                  fieldInFocus === "three"
                    ? theme.colors.blue
                    : theme.colors.textInputBorder
              }
            ]}
            onFocus={() => {
              setError(false);
              setFieldInFocus("three");
            }}
            onBlur={() => handleBlur("three")}
          />
          <TextInput
            keyboardType="number-pad"
            selectionColor={theme.colors.blue}
            returnKeyType="done"
            value={secure && fields.four ? dot : fields.four}
            onChangeText={(value) => {
              changeField("four", value);
              {
                value !== "" && Keyboard.dismiss();
              }
            }}
            ref={fourRef}
            maxLength={1}
            style={[
              styles.pinInput,
              {
                borderColor:
                  fieldInFocus === "four"
                    ? theme.colors.blue
                    : theme.colors.textInputBorder
              }
            ]}
            onFocus={() => {
              setError(false);
              setFieldInFocus("four");
            }}
            onBlur={() => handleBlur("four")}
          />
          <View style={{ justifyContent: "center" }}>
            <TouchableOpacity
              onPress={() => {
                setSecure(!secure);
              }}>
              {secure ? <CloseEyeSVG /> : <OpenEyeSVG />}
            </TouchableOpacity>
          </View>
        </View>
        <View>
          {error && (
            <Text
              style={[
                theme.fonts.text200,
                {
                  color: theme.colors.red,
                  marginTop: 24,
                  marginHorizontal: 24
                }
              ]}>
              {languageContent.noMatch}
            </Text>
          )}
          <Text style={[theme.fonts.body1, { margin: 24 }]}>
            {languageContent.pinNeeded}
          </Text>
        </View>
      </View>
      <BottomInnerNav />
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    width: Dimensions.get("screen").width,
    padding: 16,
    marginTop: height < 800 ? height / 40 : height / 20
  },
  pinInput: {
    width: "12%",
    height: 64,
    marginRight: 12,
    fontWeight: "500",
    borderWidth: 1,
    borderColor: theme.colors.textInputBorder,
    backgroundColor: theme.colors.textInputBackgroundColor,
    textAlign: "center",
    fontSize: 32,
    borderRadius: 6,
    minWidth: 40
  },
  pinFont: {
    marginBottom: 24,
    textAlign: "center",
    fontWeight: "300"
  }
});

export default SetPinScreen;
