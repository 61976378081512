let strings = {
  "en-US": {
    exercise:
      "The Activity feature is useful during times of increased hypoglycemia risk (or reduced insulin needs) such as exercise. Exercise and other activities may increase the risk of hypoglycemia."
  },
  "en-GB": {
    exercise:
      "The Activity feature is useful during times of increased hypoglycaemia risk (or reduced insulin needs) such as exercise. Exercise and other activities may increase the risk of hypoglycaemia."
  },
  "de-DE": {
    exercise:
      "Die Aktivitätsfunktion ist nützlich, wenn ein erhöhtes Hypoglykämierisiko (oder ein geringerer Insulinbedarf) besteht, z. B. beim Sport. Sport und andere Aktivitäten können das Risiko einer Hypoglykämie erhöhen."
  },
  "es-US": {
    exercise:
      "La función de Actividad es útil cuando hay mayor riesgo de hipoglucemia (o menor necesidad de insulina), como al hacer ejercicio. El ejercicio y otras actividades podrían aumentar el riesgo de hipoglucemia."
  },
  "fr-FR": {
    exercise:
      "La fonction Activité est utile pendant les périodes de risque accru d'hypoglycémie (ou de besoins réduits en insuline) comme l'exercice. L'exercice et d'autres activités peuvent augmenter le risque d'hypoglycémie."
  },
  "nl-NL": {
    exercise:
      "De Activiteitenfunctie is nuttig in tijden van verhoogd risico op hypoglykemie (of verminderde insulinebehoefte), zoals bij inspanning. Lichaamsbeweging en andere activiteiten kunnen het risico van hypoglykemie verhogen."
  }
};

export { strings };
