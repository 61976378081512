import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Dimensions, StyleSheet, Text, View } from "react-native";
import theme from "../../../theme";
import { Button } from "../../atoms";
import { BottomInnerNav } from "../../molecules";

const { height, width } = Dimensions.get("window");

interface Props {
  title: string;
  description: string;
  buttonLabel: string;
  largeTitle?: boolean;
  navigateTo: any;
}

const FTSIntroConfirmation: React.FC<Props> = ({
  title,
  description,
  buttonLabel,
  largeTitle,
  navigateTo
}) => {
  const { navigate } = useNavigation();

  return (
    <View
      style={[
        theme.layout.wrapper,
        { width: "100%", backgroundColor: "white" }
      ]}>
      <View style={[styles.innerWrapper, { width: "100%" }]}>
        {largeTitle ? (
          <Text style={styles.largeTitle}>{title}</Text>
        ) : (
          <Text style={styles.title}>{title}</Text>
        )}

        <Text style={styles.description}>{description}</Text>
        <View style={{ marginHorizontal: 4 }}>
          <Button
            variant="primary"
            style={{ elevation: 6 }}
            onPress={() => {
              navigate(navigateTo);
            }}>
            {buttonLabel}
          </Button>
        </View>
      </View>
      <BottomInnerNav />
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 16,
    marginTop: 40
  },
  largeTitle: {
    marginTop: 16,
    marginHorizontal: 16,
    fontSize: 36
  },
  title: {
    ...theme.fonts.h3,
    marginTop: -20,
    textAlign: "center",
    fontWeight: "200"
  },
  description: {
    ...theme.fonts.text400,
    margin: 16,
    fontWeight: "400"
  }
});

export default FTSIntroConfirmation;
