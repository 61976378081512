import React from "react";
import { View } from "react-native";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { Header, Paragraph } from "../../../../../atoms";
import { BottomInnerNav } from "../../../../../molecules";
import { strings } from "./ICRatioFTSScreenContent";

const ICRatioFTSScreen: React.FC = () => {
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ backgroundColor: "#fff", flex: 1 }}>
      <Header
        label={languageContent.set}
        styles={{ marginTop: 32, marginBottom: 24, paddingVertical: 0 }}
      />
      <View style={[{ flex: 1, paddingHorizontal: 16 }]}>
        <Paragraph
          label={languageContent.number}
          variant="body1"
          styles={{ marginBottom: 16 }}
        />
        <Paragraph
          label={languageContent.example}
          variant="body1"
          styles={{ marginBottom: 16 }}
        />
      </View>
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.cancel}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.next}
        rightActionNavigateTo={"ICRatioFirstSegment"}
        rightActionStyle={{
          color: theme.colors.purple
        }}
      />
    </View>
  );
};

export default ICRatioFTSScreen;
