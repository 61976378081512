import React from "react"
import { StyleSheet, Text, TouchableOpacity, View } from "react-native-web"
import HOURS_DATA from "../data/hoursData"
import theme from "../theme"
const ITEM_HEIGHT = 40

const getHoursItemsList = (listName: "ONE" | "TWO") => {
  const getItemLabel = (item: any) => {
    if (item.value <= 24 && item.value >= 0) {
      if (item.label === "1") {
        return ` hr`
      } else {
        return ` hrs`
      }
    }
  }

  const getMarginLeftValue = (item: { label: any }) => {
    if (Number(item.label) >= 10) {
      return "30%"
    } else {
      return "38%"
    }
  }

  let list: JSX.Element[] = []

  HOURS_DATA.forEach((item, index) => {
    list.push(
      <View
        key={`${listName}-${index}`}
        style={{
          height: ITEM_HEIGHT,
        }}
      >
        <TouchableOpacity activeOpacity={1}>
          <Text
            style={[
              styles.text,
              listName === "ONE" ? styles.labelOne : styles.labelTwo,
              {
                marginLeft: getMarginLeftValue(item),
              },
            ]}
          >
            {item.label}
            <Text
              style={{
                fontSize: 18,
                fontWeight: listName === "ONE" ? "700" : "500",
              }}
            >
              {getItemLabel(item)}
            </Text>
          </Text>
        </TouchableOpacity>
      </View>,
    )
  })
  return list
}

const styles = StyleSheet.create({
  text: {
    textAlign: "left",
    zIndex: 3,
  },
  labelOne: {
    color: theme.colors.blue,
    fontSize: 32,
    fontWeight: "700",
  },
  labelTwo: {
    color: theme.colors.text.grayText,
    fontSize: 24,
    fontWeight: "500",
  },
})

export default getHoursItemsList
