import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { Button } from 'web/components/ios/Button';
import { useIntl } from 'react-intl';
import {
  messages,
  CreateInsulinToCarbRatioSegmentMessages,
} from '../Bolus.messages';

interface CreateInsulinToCarbRatioSegmentProps {
  bolusSegmentTitle: string;
  startTimeValue: string;
  endTimeValue: string;
  unitOfInsulinValue: string;
  navigationRoute: string;
  editMode: boolean;
}

export const CreateInsulinToCarbRatioSegment: React.FC<
  CreateInsulinToCarbRatioSegmentProps
> = ({
  bolusSegmentTitle,
  startTimeValue,
  endTimeValue,
  unitOfInsulinValue,
  navigationRoute,
  editMode,
}) => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const [endTimeIsClicked, setEndTimeIsClicked] = useState(false);
  const [unitOfInsulinIsClicked, setUnitOfInsulinIsClicked] = useState(false);

  useEffect(() => {
    setEndTimeIsClicked(editMode);
    setUnitOfInsulinIsClicked(editMode);
  }, []);

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-9/12">
        <p className="p-6 font-bold text-xl mb-2">{bolusSegmentTitle}</p>
        <div>
          <div className="flex justify-between items-center mb-2 w-full pb-2 border-b border-b-ios-gray-7">
            <div>
              <p className="pl-6">
                {intl.formatMessage(messages.startTimeInputLabel)}
              </p>
            </div>
            <div>
              <p className="pr-6 font-semibold text-ios-gray-2">
                <span>{startTimeValue}</span>
              </p>
            </div>
          </div>
          <div
            onClick={() => setEndTimeIsClicked(true)}
            className="flex justify-between items-center mb-2 w-full pb-2 border-b border-b-ios-gray-7 hover:cursor-pointer"
          >
            <div>
              <p className="pl-6">
                {intl.formatMessage(messages.endTimeInputLabel)}
              </p>
            </div>
            <div>
              <p
                className={`pr-6 font-semibold ${endTimeIsClicked ? 'text-modes-automated' : 'text-ios-gray-2'}`}
              >
                <span>
                  {endTimeIsClicked
                    ? endTimeValue
                    : intl.formatMessage(messages.emptyValue)}
                </span>
              </p>
            </div>
          </div>
          <div
            onClick={() => endTimeIsClicked && setUnitOfInsulinIsClicked(true)}
            className="flex-col w-full pb-2 border-b border-b-ios-gray-7 hover:cursor-pointer"
          >
            <div className="flex pl-6">
              <p className="text-regular leading-none">
                {intl.formatMessage(
                  CreateInsulinToCarbRatioSegmentMessages.unitOfInsulinLabel
                )}
              </p>
              <p className="font-semibold ml-0.5 text-xs opacity-[.60] text-ios-gray-9">
                {intl.formatMessage(
                  CreateInsulinToCarbRatioSegmentMessages.unitOfInsulinPlaceholder
                )}
              </p>
            </div>
            <div className="pl-6">
              <p
                className={`font-semibold mt-2 text-4xl ${unitOfInsulinIsClicked ? 'text-black' : 'opacity-[.30] text-ios-gray-9'}`}
              >
                <span>
                  {unitOfInsulinIsClicked
                    ? unitOfInsulinValue
                    : intl.formatMessage(
                        CreateInsulinToCarbRatioSegmentMessages.unitOfInsulinEmptyValue
                      )}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="basis-2/5">
        <div className="pt-2 w-full h-full flex flex-col-reverse">
          <div className="flex flex-col w-full p-6">
            <Button
              onClick={() =>
                endTimeIsClicked &&
                unitOfInsulinIsClicked &&
                navigate(navigationRoute)
              }
              variant="colored"
              disabled={
                endTimeIsClicked && unitOfInsulinIsClicked ? false : true
              }
            >
              {editMode
                ? intl.formatMessage(messages.saveBtn)
                : intl.formatMessage(messages.nextBtn)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
