import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'web/store/store';

export const selectLowGlucoseSettings = createSelector(
  (state: RootState) => state.l2Settings,
  (settings) => settings.lowGlucose
);

export const selectHighGlucoseSettings = createSelector(
  (state: RootState) => state.l2Settings,
  (settings) => settings.highGlucose
);

export const selectMissingSensorValuesSettings = createSelector(
  (state: RootState) => state.l2Settings,
  (settings) => settings.missingSensorValues
);
