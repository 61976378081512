import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import autoEventsData from "../../../../../data/autoEventsData";
import theme from "../../../../../theme";
import { AutoEventsHeader, SwitchedModeRow } from "../../../../atoms";
import EventRow from "../../../../atoms/eventRow";

const MID_BLUE = "#0093e8";
const PURPLE = "#7a339d";

const AutoEventScreen: React.FC = () => {
  return (
    <>
      <AutoEventsHeader />
      <ScrollView style={{ flex: 1, backgroundColor: theme.colors.white }}>
        <View
          style={[
            styles.row,
            {
              backgroundColor: MID_BLUE
            }
          ]}>
          <SwitchedModeRow time="1:56 pm" manual />
        </View>
        {autoEventsData.SectionOne.map((item, index) => (
          <EventRow item={item} key={index} />
        ))}

        <View
          style={[
            styles.row,
            {
              backgroundColor: PURPLE
            }
          ]}>
          <SwitchedModeRow time="12:15 pm" auto />
        </View>
        <View
          style={[
            styles.row,
            {
              backgroundColor: MID_BLUE
            }
          ]}>
          <SwitchedModeRow time="11:57 am" manual />
        </View>
        {autoEventsData.SectionTwo.map((item, index) => (
          <EventRow item={item} key={index} />
        ))}
        <View
          style={[
            styles.row,
            {
              backgroundColor: PURPLE
            }
          ]}>
          <SwitchedModeRow time="11:32 am" auto />
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  row: {
    height: 60,
    justifyContent: "center"
  }
});

export default AutoEventScreen;
