import React from 'react';
import { useIntl } from 'react-intl';
import { createStackNavigator } from '@react-navigation/stack';
import { TopBar } from 'web/components/ios/TopBar';
import { BolusSettingsRoutesEnum } from '../../types';
import { TargetGlucoseMain } from './views/TargetGlucoseMain';
import { TargetGlucoseSegmentScreen } from './views/TargetGlucoseSegmentScreen';
import { targetGlucoseMessages } from '../../BolusSettings.messages';

const Stack = createStackNavigator();

export const TargetGlucoseStack: React.FC = () => {
  const intl = useIntl();
  return (
    <Stack.Navigator
      initialRouteName={BolusSettingsRoutesEnum.TargetGlucose}
      screenOptions={{
        header: (props) => <TopBar {...props} />,
      }}
    >
      <Stack.Screen
        name={BolusSettingsRoutesEnum.TargetGlucose}
        options={{
          title: intl.formatMessage(targetGlucoseMessages.title),
        }}
        component={TargetGlucoseMain}
      />
      <Stack.Screen
        name={BolusSettingsRoutesEnum.SegmentScreen}
        options={{
          title: intl.formatMessage(targetGlucoseMessages.title),
          header: (props) => <TopBar {...props} backAndCancelMode />,
        }}
        component={TargetGlucoseSegmentScreen}
      />
    </Stack.Navigator>
  );
};
