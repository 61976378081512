import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'web/store/store';
import { flagNames } from './featureFlagsSlice';

export const selectActiveFeatureFlags = createSelector(
  (state: RootState) => state.featureFlags,
  (featureFlags) => Object.keys(featureFlags).filter((key) => featureFlags[key])
);

export const selectShowNewVersionFeatures = createSelector(
  selectActiveFeatureFlags,
  (activeFlags) => {
    // Until we figure out a better way,
    // We need to keep this list updated whenever we add a new sensor.
    return (
      activeFlags.includes(flagNames.SHOW_G7) ||
      activeFlags.includes(flagNames.SHOW_L2)
    );
  }
);

export const selectShowIOSFeatures = createSelector(
  selectActiveFeatureFlags,
  (activeFlags) => activeFlags.includes(flagNames.SHOW_IOS)
);
