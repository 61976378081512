import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { basalActions, BasalState } from "../../../web/store/reducers/basal";
import { bolusActions, BolusState } from "../../../web/store/reducers/bolus";
import { RootState } from "../../../web/store/store";

function useExpertHardwareBackAction(
  navigation: any,
  screenName: "Basal" | "BolusTG" | "BolusIC" | "BolusCF" | "MaxBolus"
) {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );

  const dispatch = useDispatch();

  const backAction = () => {
    switch (screenName) {
      //Expert basal Screens
      case "Basal":
        dispatch(
          basalActions.basalUpdate(
            {
              newExpertBasalProgram: {
                ...basalState.newExpertBasalProgram,
                segments: basalState.newExpertBasalProgram.segments.slice(
                  0,
                  basalState.newExpertBasalProgram.segments.length - 1
                )
              }
            },
            basalState
          )
        );
        navigation.goBack();
        return true;

      //Expert Target Glucose And Correct Above Screens
      case "BolusTG":
        dispatch(
          bolusActions.bolusUpdate(
            {
              newExpertBolusProgram: {
                ...bolusState.newExpertBolusProgram,
                segments: bolusState.newExpertBolusProgram.segments.slice(
                  0,
                  bolusState.newExpertBolusProgram.segments.length - 1
                )
              }
            },
            bolusState
          )
        );
        navigation.goBack();
        return true;
      //Expert IC Ratio Screens
      case "BolusIC":
        dispatch(
          bolusActions.bolusUpdate(
            {
              newExpertBolusProgram: {
                ...bolusState.newExpertBolusProgram,
                icRatioSegments:
                  bolusState.newExpertBolusProgram.icRatioSegments.slice(
                    0,
                    bolusState.newExpertBolusProgram.icRatioSegments.length - 1
                  )
              }
            },
            bolusState
          )
        );
        navigation.goBack();
        return true;

      case "BolusCF":
        dispatch(
          bolusActions.bolusUpdate(
            {
              newExpertBolusProgram: {
                ...bolusState.newExpertBolusProgram,
                correctionFactorSegments:
                  bolusState.newExpertBolusProgram.correctionFactorSegments.slice(
                    0,
                    bolusState.newExpertBolusProgram.correctionFactorSegments
                      .length - 1
                  )
              }
            },
            bolusState
          )
        );
        navigation.goBack();
        return true;

      case "MaxBolus":
        dispatch(
          bolusActions.bolusUpdate(
            {
              maxBolus: 0
            },
            bolusState
          )
        );
        navigation.goBack();
        return true;
    }
  };

  return backAction;
}

export default useExpertHardwareBackAction;
