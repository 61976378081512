import { BasalState } from './index';
import { initialState as basalInitState, jumpStartState } from './reducer';

export const BASAL_UPDATE = 'basal_update';
export const BASAL_RESET = 'basal_reset';
export const BASAL_INIT = 'user_init';
export const BASAL_PAUSE_INSULIN = 'basal_pause_insulin';
export const BASAL_RESUME_INSULIN = 'basal_resume_insulin';
export const BASAL_ADJUST_BY = 'basal_adjust_by';

interface ActionUpdate {
  type: ActionType;
  payload: {
    basal: Partial<BasalState>;
  };
}

interface ActionReset {
  type: ActionType;
  payload: {
    basal: BasalState;
  };
}

interface ActionInit {
  type: ActionType;
  payload: {
    user: BasalState;
  };
}

type ActionType =
  | typeof BASAL_UPDATE
  | typeof BASAL_RESET
  | typeof BASAL_INIT
  | typeof BASAL_PAUSE_INSULIN
  | typeof BASAL_RESUME_INSULIN
  | typeof BASAL_ADJUST_BY;

export const basalUpdate = (
  basalData: Partial<BasalState>,
  initialState: BasalState
): Action => {
  return {
    type: BASAL_UPDATE,
    payload: {
      basal: basalData,
    },
  };
};

export const basalReset = (): Action => {
  return {
    type: BASAL_RESET,
    payload: {
      basal: basalInitState,
    },
  };
};

export const basalInit = (): Action => {
  return {
    type: BASAL_INIT,
    payload: {
      basal: basalInitState,
    },
  };
};

export const basalPauseInsulin = () => {
  return {
    type: BASAL_PAUSE_INSULIN,
  };
};

export const basalResumeInsulin = () => {
  return {
    type: BASAL_RESUME_INSULIN,
  };
};

export const basalAdjustBy = (value: string | undefined) => {
  return {
    type: BASAL_UPDATE,
    payload: {
      basal: {
        adjustBy: value,
      },
    },
  };
};

export type Action = ActionUpdate | ActionReset | ActionInit;
