const primaryFont = {
  normal: "Roboto",
  light: "Roboto",
  medium: "Roboto",
  bold: "Roboto"
};

const cardFont = {
  medium: "NunitoSans-SemiBold"
};

interface Fonts {
  primary: {
    normal: string;
    light: string;
    medium: string;
    bold: string;
  };
  card: {
    medium: string;
  };
  h1: any;
  h2: any;
  h3: any;
  h4: any;
  h5: any;
  text200: any;
  text400: any;
  text600: any;
  body1: any;
  body2: any;
  body3: any;
  textMedium: any;
  superscript: any;
}

const fonts: Fonts = {
  primary: primaryFont,
  card: cardFont,
  h1: {
    fontSize: 50,
    fontFamily: primaryFont.bold,
    letterSpacing: -0.9,
    fontWeight: "500"
  },
  h2: {
    fontSize: 28,
    fontWeight: "500",
    fontFamily: primaryFont.bold
  },
  h3: {
    fontSize: 24,
    fontWeight: "500",
    fontFamily: primaryFont.bold
  },
  h4: {
    fontSize: 21,
    fontWeight: "500",
    fontFamily: primaryFont.bold
  },
  h5: {
    fontSize: 18,
    fontWeight: "500",
    fontFamily: primaryFont.bold
  },
  text200: {
    fontSize: 18,
    lineHeight: 26,
    fontWeight: "500",
    fontFamily: primaryFont.bold
  },
  text400: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "500",
    fontFamily: primaryFont.bold
  },
  text600: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: "500",
    fontFamily: primaryFont.bold
  },
  body1: {
    fontSize: 18,
    lineHeight: 26,
    fontWeight: "400",
    fontFamily: primaryFont.light
  },
  body2: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "400",
    fontFamily: primaryFont.medium
  },
  body3: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: "400",
    fontFamily: primaryFont.medium
  },

  textMedium: {
    fontFamily: primaryFont.medium,
    fontWeight: "500"
  },
  superscript: {
    fontFamily: "Source Sans Pro"
  },
};

export default fonts;
