import { useNavigation } from "@react-navigation/native";
import React from "react";
import { TouchableOpacity, TouchableWithoutFeedback } from "react-native";
import { StyleSheet, Text, View } from "react-native-web";
import { ReactComponent as LogoSVG } from "../../../../../../../../assets/Omnipod_5_LOGO_RGB_MANGO_TM.svg";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import theme from "../../../../../../theme";
import { Button } from "../../../../../atoms";
import { strings } from "./InsuletTCScreenContent";

const InsuletTCScreen: React.FC = () => {
  const [accepted, setAccepted] = React.useState(false);

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  const handleCheckboxPress = () => {
    setAccepted(!accepted); //
  };

  return (
    <View style={{ backgroundColor: "white", height: "100%", width: "100%" }}>
      <View style={[{ width: "100%" }]}>
        <LogoSVG width={245} height={245} style={{ alignSelf: "center" }} />
        <TouchableOpacity
          style={[
            styles.checkbox,
            { backgroundColor: accepted ? "black" : "transparent" }
          ]}
          onPress={handleCheckboxPress}>
          {accepted && (
            <Text
              style={{ color: "white", textAlign: "center", lineHeight: 18 }}>
              ✓
            </Text>
          )}
        </TouchableOpacity>
        <Text
          style={[styles.subtext, theme.fonts.text600, { fontWeight: 300 }]}>
          {languageContent.iCertify1}
          <TouchableWithoutFeedback onPress={() => navigate("ReadTC")}>
            <Text
              style={{ fontWeight: "600", textDecorationLine: "underline" }}>
              {languageContent.iCertify2US}
            </Text>
          </TouchableWithoutFeedback>
          {languageContent.iCertify3}
        </Text>
        {accepted ? (
          <Button
            variant={"primary"}
            style={{ elevation: 6, width: 285, marginLeft: 8 }}
            onPress={() => {
              navigate("SignIn");
            }}>
            {languageContent.continue}
          </Button>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  subtext: {
    fontWeight: "300",
    textAlign: "left",
    backgroundColor: "white",
    marginBottom: 24,
    marginLeft: 60,
    marginRight: 12
  },
  checkbox: {
    width: 18,
    height: 18,
    borderColor: "black",
    borderWidth: 1,
    marginLeft: 24,
    marginBottom: -22
  }
});

export default InsuletTCScreen;
