import React from "react";
import { Switch, View } from "react-native-web";
import theme from "mobile/latest/theme";

interface CustomToggleSwitchProps {
  value: boolean;
  leftBorder?: boolean;
  onValueChange?: () => void;
}

const CustomToggleSwitch: React.FC<CustomToggleSwitchProps> = ({
  value,
  onValueChange,
  leftBorder
}) => {
  return (
    <View
      style={[
        {
          borderLeftWidth: leftBorder ? 2 : undefined,
          paddingLeft: leftBorder ? 16 : undefined,
          borderColor: leftBorder ? theme.colors.divider : undefined
        }
      ]}>
      <Switch
        style={[
          {
            transform: [{ scaleX: 1 }, { scaleY: 1 }]
          }
        ]}
        value={value}
        onValueChange={onValueChange}
        activeThumbColor={theme.colors.purple}
        thumbColor={theme.colors.white}
        trackColor={{
          false: theme.colors.placeholder,
          true: theme.colors.lightPurple
        }}
      />
    </View>
  );
};

export default CustomToggleSwitch;
