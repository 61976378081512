import {
  StyleSheet,
  View,
} from 'react-native';
import theme from 'mobile/latest/theme';
import React, { PropsWithChildren } from 'react';

interface Props {
  isVisible: boolean;
}

const Modal: React.FC<PropsWithChildren<Props>> = (props) => {
  const { isVisible, children } = props;

  return isVisible ? (
    <View style={styles.container}>
      <View style={styles.modal}>{children}</View>
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors.modalBakground,
    paddingHorizontal: 16,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
  },
  modal: {
    display: 'flex',
    backgroundColor: '#FFF',
    borderRadius: 2,
    padding: 15,
    shadowColor: '#0f0f0f',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.5,
    shadowRadius: 10,
  },
});

export default Modal;
