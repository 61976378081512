import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { Moment } from 'moment';
import { Button } from 'web/components/ios/Button';
import { FormattedTime } from 'web/components/helpers/FormattedTime/FormattedTime';
import { insulinToCarbSegmentMessages } from '../../../BolusSettings.messages';
import { BolusSettingsRoutesEnum } from '../../../types';

export interface InsulinToCarbSegmentScreenValues {
  segmentNumber: number;
  startTime: Moment;
  endTime: Moment;
  value: number;
}

interface SegmentScreenProps {
  route: {
    params: InsulinToCarbSegmentScreenValues;
  };
}

export const InsulinToCarbSegmentScreen: React.FC<SegmentScreenProps> = ({
  route,
}) => {
  const intl = useIntl();
  const { navigate } = useNavigation();
  const {
    params: { segmentNumber, startTime, endTime, value },
  } = route;

  return (
    <div className="bg-white h-full pt-[19px] pb-[34px] flex flex-col justify-between">
      <div>
        <div className="px-2 mb-[12px] font-bold text-2xl">
          <FormattedMessage
            {...insulinToCarbSegmentMessages.title}
            values={{ segmentNumber }}
          />
        </div>

        <div className="px-2 py-[11px] flex flex-row items-center justify-between border-b-1 border-b-ios-gray-9/[.36]">
          <div>
            <FormattedMessage {...insulinToCarbSegmentMessages.startTime} />
          </div>
          <div className="text-ios-gray-9/60">
            <FormattedTime time={startTime} />
          </div>
        </div>

        <div className="px-2 py-[11px] flex flex-row items-center justify-between border-b-1 border-b-ios-gray-9/[.36] mb-[31px]">
          <div>
            <FormattedMessage {...insulinToCarbSegmentMessages.endTime} />
          </div>
          <div className="text-modes-automated">
            <FormattedTime time={endTime} />
          </div>
        </div>

        <div className="px-2">
          <div className="mb-[12px]">
            <span>
              <FormattedMessage {...insulinToCarbSegmentMessages.valueName} />
            </span>
            <span className="text-ios-gray-9/60 text-small ml-[5px]">
              <FormattedMessage {...insulinToCarbSegmentMessages.valueRange} />
            </span>
          </div>
          <div className="text-4xl font-bold border-b-1 border-b-ios-gray-9/[.36] pb-[12px]">
            <FormattedMessage
              {...insulinToCarbSegmentMessages.value}
              values={{ value }}
            />
          </div>
        </div>
      </div>

      <div className="px-2">
        <Button
          colorMode="automated"
          variant="colored"
          full
          onClick={() => navigate(BolusSettingsRoutesEnum.InsulinToCarb)}
        >
          <FormattedMessage {...insulinToCarbSegmentMessages.cta} />
        </Button>
      </div>
    </div>
  );
};
