let strings = {
  "en-US": {
    help: "Help",
    signIn:
      "You need to sign in to the Omnipod web site to unlock this device before you can begin setup.",
    noAccount: "Need an account?",
    createAccount: "Create an account at: \nwww.omnipod.com/setup",
    forgotAccount: "Forgot your Omnipod ID or password?",
    resetPassword:
      "To reset your Omnipod ID or password, go to: \nwww.omnipod.com/setup",
    otherQuestions: "For other questions, contact Customer Care.",
    customerCare: "<customer_care_helpline>"
  },
  "en-GB": {
    help: "Help",
    signIn:
      "You need to sign in to the Omnipod web site to unlock this device before you can begin setup.",
    noAccount: "Need an account?",
    createAccount: "Create an account at: \nwww.omnipod.com/setup",
    forgotAccount: "Forgot your Omnipod ID or password?",
    resetPassword:
      "To reset your Omnipod ID or password, go to: \nwww.omnipod.com/setup",
    otherQuestions: "For other questions, contact Customer Care.",
    customerCare: "<customer_care_helpline>"
  },
  "es-US": {
    help: "Ayuda",
    signIn:
      "Debe inciar sesión en el sitio web de Omnipod para desbloquear este dispositivio antes de comenzar la configuración.",
    noAccount: "¿Necesita una cuenta?",
    createAccount: "Crear una cuenta en \nwww.omnipod.com/setup",
    forgotAccount: "¿Olvidó su ID o contraseña de Omnipod?",
    resetPassword:
      "Para restablecer su ID o contraseña de Omnipod, diríjase a: \nwww.omnipod.com/setup",
    otherQuestions:
      "Si tiene alguna otra duda, contacte con Atención al Cliente.",
    customerCare: "<customer_care_helpline>"
  },
  "de-DE": {
    help: "Hilfe",
    signIn:
      "Um mit der Einrichtung beginnen zu können, müssen Sie sich zuerst auf der Omnipod Homepage anmelden, um das Gerät freizuschalten.",
    noAccount: "Benötigen Sie ein Konto?",
    createAccount: "Erstellen Sie ein Konto unter \nwww.omnipod.com/setup",
    forgotAccount:
      "Haben Sie Ihre Omnipod-ID oder lhr Omnipod-Passwort vergessen?",
    resetPassword:
      "Zum Zurücksetzen Ihrer Omnipod-ID oder Ihres Omnipod-Passworts gehen Sie zue: \nwww.omnipod.com/setup",
    otherQuestions:
      "Bei weiteren Fragen wenden Sie sich bitte unter Verwendung der von Ihrem Anbieter bereitgestellten Informationen an den Kundenservice."
  },
  "nl-NL": {
    help: "Help",
    signIn:
      "U moet u aanmelden bij de Omnipod-website om dit apparaat te ontgrendelen voordat u kunt beginnen met de instelling.",
    noAccount: "Hebt u een account nodig?",
    createAccount: "Maak een account aan op \nwww.omnipod.com/setup",
    forgotAccount: "Uw Omnipod-ID of -wachtwoord vergeten?",
    resetPassword:
      "Om uw Omnipod-ID of wachtwoord aan te maken, gaat u naar: \nwww.omnipod.com/setup",
    otherQuestions:
      "Neem voor andere vragen contact op met de Cliëntenzorg met de informatie die u van uw leverancier gekregen heeft."
  },
  "fr-FR": {
    help: "AIDE",
    signIn:
    "Vous devez vous connecter au site Internet Omnipod pour déverrouiller cet appareil avant de pouvoir commencer la configuration.",
  noAccount: "Besoin d\'un compte ?",
  createAccount: "Créer un compte sur: \nwww.omnipod.com/setup",
  forgotAccount: "Vous avez oublié votre identifiant ou votre mot de passe ?",
  resetPassword:
    "TPour réinitialiser votre identifiant ou votre mot de passe Omnipod, allez sur: \nwww.omnipod.com/setup",
  otherQuestions: "Pour toute autre question, contactez le Service clients.",
  customerCare: "<customer_care_helpline>"
  }
};

export { strings };
