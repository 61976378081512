import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Animated } from 'react-native';
import { BackButton } from '../../components/atoms';
import { IntroCGMScreen } from '../../components/screens';
import useLanguageContent from '../../hooks/useLanguageContent';
import theme from '../../theme';
import DrawerStack from './DrawerStack';
import { strings } from './TXSetupStackContent';
import { NavState, navigationActions } from 'web/store/reducers/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'web/store/store';
import { isEqual } from 'lodash';
import InitialSetupG7Stack from 'web/routes/G7/InitialSetupG7Stack';
import InitialSetupL2 from 'web/routes/L2/InitialSetupL2';
import { SensorSelection } from 'web/components/pages';
import InitialSetupG6Stack from 'web/routes/G6/InitialSetupG6Stack';

const Stack = createStackNavigator();
const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const TXSetupStack: React.FC = () => {
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const headerRef = new Animated.ValueXY();
  const { languageContent } = useLanguageContent(strings);
  const dispatch = useDispatch();

  const animateHeader = React.useCallback(() => {
    Animated.timing(headerRef, {
      toValue: 0,
      duration: 600,
      useNativeDriver: false,
    }).start();
  }, [headerRef]);

  React.useEffect(() => {
    dispatch(
      navigationActions.navigationUpdate({ navigateTo: 'TXSetup' }, navState)
    );
  }, []);

  React.useEffect(() => {
    animateHeader();
  }, [animateHeader]);

  return (
    <Stack.Navigator initialRouteName="IntroCGM" mode="card">
      <Stack.Screen
        name="Drawer"
        options={{ headerShown: false }}
        component={DrawerStack}
      />

      <Stack.Screen
        name="IntroCGM"
        options={{
          title: '',
          headerStyle: defaultHeaderStyle,
          headerLeft: () => (
            <BackButton
              text={languageContent.transmitter}
              white={false}
              isFTS
            />
          ),
        }}
        component={IntroCGMScreen}
      />
      <Stack.Screen
        name="SensorSelection"
        options={{
          title: '',
          headerStyle: defaultHeaderStyle,
          headerLeft: () => (
            <BackButton
              text={languageContent.sensorSelect}
              isFTS
              white={false}
              navigateTo="IntroCGM"
            />
          ),
        }}
      >
        {() => <SensorSelection isFTS />}
      </Stack.Screen>
      <Stack.Screen name="InitialSetupG6Stack" options={{ headerShown: false }}>
        {(props) => <InitialSetupG6Stack isFTS />}
      </Stack.Screen>
      <Stack.Screen name="InitialSetupG7Stack" options={{ headerShown: false }}>
        {(props) => <InitialSetupG7Stack isFTS />}
      </Stack.Screen>
      <Stack.Screen name="InitialSetupL2" options={{ headerShown: false }} component={InitialSetupL2} />
    </Stack.Navigator>
  );
};

export default TXSetupStack;
