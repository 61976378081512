import React from "react";
import { StyleSheet, Text, View } from "react-native";
import theme from "../../../theme";
import { ExpandableList } from "../../molecules";

interface Props {
  columnOneLabel: string;
  columnOneSubLabel: string;
  columnTwoLabel: string;
  columnTwoSubLabel: string;
  columnThreeLabel: string;
  columnThreeSubLabel: string;
}

const DetailsCard: React.FC<Props> = ({
  columnOneLabel,
  columnOneSubLabel,
  columnTwoLabel,
  columnTwoSubLabel,
  columnThreeLabel,
  columnThreeSubLabel
}) => {
  return (
    <View style={styles.card}>
      <View
        style={{
          borderBottomColor: theme.colors.textInputBorder,
          borderBottomWidth: 1,
          flexDirection: "row",
          justifyContent: "space-evenly",
          paddingVertical: 8
        }}>
        <View />
        <View
          style={{
            flexDirection: "column",
            alignItems: "center"
          }}>
          <Text style={{ ...theme.fonts.body2 }}>{columnOneLabel}</Text>
          <Text
            style={{
              ...theme.fonts.body2,
              color: theme.colors.text.grayText,
              marginVertical: -4
            }}>
            {columnOneSubLabel}
          </Text>
        </View>
        <View style={{ flexDirection: "column", alignItems: "center" }}>
          <Text style={{ ...theme.fonts.body2 }}>{columnTwoLabel}</Text>
          <Text
            style={{
              ...theme.fonts.body2,
              color: theme.colors.text.grayText,
              marginVertical: -4
            }}>
            {columnTwoSubLabel}
          </Text>
        </View>
        <View style={{ flexDirection: "column", alignItems: "center" }}>
          <Text style={{ ...theme.fonts.body2 }}>{columnThreeLabel}</Text>
          <Text
            style={{
              ...theme.fonts.body2,
              color: theme.colors.text.grayText,
              marginVertical: -4
            }}>
            {columnThreeSubLabel}
          </Text>
        </View>
      </View>
      <View>
        <ExpandableList />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.white,
    shadowColor: "#999",
    shadowOpacity: 0.3,
    shadowRadius: 6,
    shadowOffset: {
      height: 1,
      width: 0
    },
    elevation: 4,
    alignSelf: "center"
  }
});

export default DetailsCard;
