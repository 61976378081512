import { useRouteError } from "react-router-dom";

type ErrorResponse = {
  data: string;
  status: number;
  statusText: string;
  message?: string;
};

//Have to use the any here. The useRouteError() hook is typed as "unknown"
//see: https://github.com/remix-run/react-router/discussions/9628

const ErrorPage = () => {
  const error: any = useRouteError();

  const errorCheck = (error: any): ErrorResponse => {
    console.error(error.error);
    return "data" && error && "status" in error && "statusText" in error;
  };

  if (errorCheck(error)) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
        }}>
        <div
          style={{
            alignSelf: "center",
          }}>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occured:</p>
          <p>
            <i>{error.statusText || error.error}</i>
          </p>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ErrorPage;
