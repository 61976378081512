import { Country } from './types';

export const configurationOptions: Country[] = [
  {
    name: 'United States',
    devices: [
      {
        name: 'Android/Controller',
        languages: [
          {
            name: 'English (US)',
            locale: 'en-US',
            is24Hours: false,
            units: 'mg/dL',
            versions: ['3.0', '1.2'],
          },
          {
            name: 'Spanish (US)',
            locale: 'es-US',
            is24Hours: false,
            units: 'mg/dL',
            versions: ['3.0', '1.2'],
          },
        ],
      },
      {
        name: 'iOS',
        languages: [
          {
            name: 'English (US)',
            locale: 'en-US',
            is24Hours: false,
            units: 'mg/dL',
            versions: ['1.0'],
          },
        ],
      },
    ],
  },
  {
    name: 'United Kingdom',
    devices: [
      {
        name: 'Android/Controller',
        languages: [
          {
            name: 'English (UK)',
            locale: 'en-GB',
            is24Hours: true,
            units: 'mmol/L',
            versions: ['2.0', '1.2'],
          },
          {
            name: 'Dutch',
            locale: 'nl-NL',
            is24Hours: true,
            units: 'mmol/L',
            versions: ['2.0'],
          },
        ],
      },
    ],
  },
  {
    name: 'Germany',
    devices: [
      {
        name: 'Android/Controller',
        languages: [
          {
            name: 'German',
            locale: 'de-DE',
            is24Hours: true,
            units: 'mg/dL',
            versions: ['1.2'],
          },
          {
            name: 'English (UK)',
            locale: 'en-GB',
            is24Hours: true,
            units: 'mmol/L',
            versions: ['1.2'],
          },
          {
            name: 'Dutch',
            locale: 'nl-NL',
            is24Hours: true,
            units: 'mmol/L',
            versions: ['1.2'],
          },
        ],
      },
    ],
  },
  {
    name: 'Netherlands',
    devices: [
      {
        name: 'Android/Controller',
        languages: [
          {
            name: 'Dutch',
            locale: 'nl-NL',
            is24Hours: true,
            units: 'mmol/L',
            versions: ['2.0'],
          },
          {
            name: 'English (UK)',
            locale: 'en-GB',
            is24Hours: true,
            units: 'mmol/L',
            versions: ['2.0'],
          },
        ],
      },
    ],
  },
  {
    name: 'France',
    devices: [
      {
        name: 'Android/Controller',
        languages: [
          {
            name: 'French',
            locale: 'fr-FR',
            is24Hours: false,
            units: 'mg/dL',
            versions: ['1.2'],
          },
        ],
      },
    ],
  },
];
