let strings = {
  "en-US": {
    seg: "Segment 1",
    start: "Start",
    end: "End",
    night: "Night",
    insulinCovers: "1 Unit of Insulin covers",
    range: "(1 to 150 g of carbs)",
    cancel: "Cancel",
    next: "Next"
  },
  "en-GB": {
    seg: "Segment 1",
    start: "Start",
    end: "End",
    night: "Night",
    insulinCovers: "1 Unit of insulin covers",
    range: "(1 to 150 g carbs)",
    cancel: "Cancel",
    next: "Next"
  },
  "de-DE": {
    seg: "Segment 1",
    start: "Start",
    end: "Ende",
    night: "Nacht",
    insulinCovers: "1 Einheit Insulin reicht für:",
    range: "(1 bis 150 g KH)",
    cancel: "Abbr.",
    next: "Weiter"
  },
  "es-US": {
    seg: "Segmento 1",
    start: "Iniciar",
    end: "Finalizar",
    night: "Noche",
    insulinCovers: "1 unidad de insulina cubre",
    range: "(1 a 150 g de carb.)",
    cancel: "Cancelar",
    next: "Siguiente"
  },
  "fr-FR": {
    seg: "Segment 1",
    start: "Démarrer",
    end: "Fin",
    night: "Nuit",
    insulinCovers: "1 unité d'insuline couvre",
    range: "(1 à 150 g de glucides)",
    cancel: "Annuler",
    next: "Suivant"
  },
  "nl-NL": {
    seg: "Segment 1",
    start: "Start",
    end: "Stop",
    night: "Nacht",
    insulinCovers: "1 eenheid insuline is voldoende voor",
    range: "(1 tot 150 g KH)",
    cancel: "Annuleren",
    next: "Volgende"
  }
};

export { strings };
