export enum BolusSettingsRoutesEnum {
  BolusSettingsMain = 'BolusSettingsMain',
  MaximumBolus = 'MaximumBolus',
  TargetGlucoseStack = 'TargetGlucoseStack',
  TargetGlucose = 'TargetGlucose',
  SegmentScreen = 'SegmentScreen',
  MinimumGlucose = 'MinimumGlucose',
  InsulinToCarbStack = 'InsulinToCarbStack',
  InsulinToCarb = 'InsulinToCarb',
  InsulinToCarbSegment = 'InsulinToCarbSegment',
  CorrectionFactorStack = 'CorrectionFactorStack',
  CorrectionFactor = 'CorrectionFactor',
  CorrectionFactorSegment = 'CorrectionFactorSegment',
  DurationOfInsulin = 'DurationOfInsulin',
}
