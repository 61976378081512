import * as type from './actions';

interface BasalSegment {
  start: string;
  end: string;
  targetBG: number;
  correctAbove: number;
  oneUnitInsulinCovers: number;
  oneUnitCorrectionFactor: number;
}

export interface ExpertBasalSegment {
  start: string;
  end: string;
  endHelpText?: string;
  startTimeHelpText?: string;
  basalRate: number;
}

export interface BasalProgram {
  id: string;
  programName: string;
  unitsPerDay: number;
  segments: Array<ExpertBasalSegment>;
  isComplete: boolean;
  isActive: boolean;
  hasUpdate?: boolean; // If the values on the edit/entry screen have been changed or not.
}

export const WEEKEND_PROGRAM_NAME = 'Weekend';
export const WEEKEND_PROGRAM_ID = 'weekend';
export const EVERYDAY_PROGRAM_NAME = 'Everyday';
export const EVERYDAY_PROGRAM_ID = 'everyday';
export const NEW_BASAL_PROGRAM_ID = 'new';

export interface BasalState {
  lastBasal: string;
  dateLastBasal: string;
  basalTutorial: boolean;
  maxBasal: string;
  extendedBasal: boolean;
  insulinPaused: boolean;
  insulinPausedTimestamp: string;
  defaultProgramName: string;
  defaultFirstSegment: BasalSegment;
  defaultSecondSegment: BasalSegment;
  defaultThirdSegment: BasalSegment;
  customProgramName: string;
  customFirstSegment: BasalSegment;
  customSecondSegment: BasalSegment;
  customProgramThirdSegment: boolean;
  activeExpertBasalProgram: BasalProgram;
  newExpertBasalProgram: BasalProgram;
  copyOfNewExpertBasalProgram: BasalProgram;
  expertBasalPrograms: Array<BasalProgram>;
  adjustBy: string | undefined;
}

export const emptyExpertBasalProgram: BasalProgram = {
  id: NEW_BASAL_PROGRAM_ID,
  programName: '',
  unitsPerDay: 0,
  segments: [],
  isComplete: false,
  isActive: false,
  hasUpdate: false,
};

export const defaultExpertBasalPrograms = [
  {
    id: EVERYDAY_PROGRAM_ID,
    programName: EVERYDAY_PROGRAM_NAME,
    unitsPerDay: 32,
    isComplete: true,
    isActive: true,
    segments: [
      {
        start: '12:00 am',
        end: '8:00 am',
        endHelpText: 'Morning',
        basalRate: 1,
      },
      {
        start: '8:00 am',
        end: '4:00 pm',
        endHelpText: 'Afternoon',
        basalRate: 2,
      },
      {
        start: '4:00 pm',
        end: '12:00 am',
        endHelpText: 'Night',
        basalRate: 1,
      },
    ],
  },
  {
    id: WEEKEND_PROGRAM_ID,
    programName: WEEKEND_PROGRAM_NAME,
    unitsPerDay: 22,
    isComplete: true,
    isActive: false,
    segments: [
      {
        start: '12:00 am',
        end: '8:00 am',
        endHelpText: 'Morning',
        basalRate: 0.75,
      },
      {
        start: '8:00 am',
        end: '12:00 am',
        endHelpText: 'Night',
        basalRate: 1,
      },
    ],
  },
];

export const defaultExpertBasalProgramsInactive = [
  {
    id: EVERYDAY_PROGRAM_ID,
    programName: EVERYDAY_PROGRAM_NAME,
    unitsPerDay: 32,
    isComplete: true,
    isActive: false,
    segments: [
      {
        start: '12:00 am',
        end: '8:00 am',
        endHelpText: 'Morning',
        basalRate: 1,
      },
      {
        start: '8:00 am',
        end: '4:00 pm',
        endHelpText: 'Afternoon',
        basalRate: 2,
      },
      {
        start: '4:00 pm',
        end: '12:00 am',
        endHelpText: 'Night',
        basalRate: 1,
      },
    ],
  },
  {
    id: WEEKEND_PROGRAM_ID,
    programName: WEEKEND_PROGRAM_NAME,
    unitsPerDay: 22,
    isComplete: true,
    isActive: false,
    segments: [
      {
        start: '12:00 am',
        end: '8:00 am',
        endHelpText: 'Morning',
        basalRate: 0.75,
      },
      {
        start: '8:00 am',
        end: '12:00 am',
        endHelpText: 'Night',
        basalRate: 1,
      },
    ],
  },
];

export const defaultActiveBasalProgram: BasalProgram = {
  id: EVERYDAY_PROGRAM_ID,
  programName: EVERYDAY_PROGRAM_NAME,
  unitsPerDay: 32,
  isComplete: true,
  isActive: false,
  segments: [
    {
      start: '12:00 am',
      end: '8:00 am',
      endHelpText: 'Morning',
      basalRate: 1,
    },
    {
      start: '8:00 am',
      end: '4:00 pm',
      endHelpText: 'Afternoon',
      basalRate: 2,
    },
    {
      start: '4:00 pm',
      end: '12:00 am',
      endHelpText: 'Night',
      basalRate: 1,
    },
  ],
};

export const initialState: BasalState = {
  lastBasal: '0.00',
  dateLastBasal: '',
  adjustBy: 'percent (%)',
  basalTutorial: true,
  maxBasal: '30',
  extendedBasal: false,
  insulinPaused: false,
  insulinPausedTimestamp: '',
  defaultProgramName: 'Weekdays',
  defaultFirstSegment: {
    start: '12:00',
    end: '8:00',
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0,
  },
  defaultSecondSegment: {
    start: '8:00',
    end: '12:00',
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0,
  },
  customProgramName: 'Relax Day',
  customProgramThirdSegment: false,
  customFirstSegment: {
    start: '12:00',
    end: '8:00',
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0,
  },
  customSecondSegment: {
    start: '8:00',
    end: '6:00',
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0,
  },
  defaultThirdSegment: {
    start: '6:00',
    end: '12:00',
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0,
  },
  activeExpertBasalProgram: defaultActiveBasalProgram,
  newExpertBasalProgram: emptyExpertBasalProgram,
  copyOfNewExpertBasalProgram: emptyExpertBasalProgram,
  expertBasalPrograms: defaultExpertBasalPrograms,
};

const now = new Date();

export const jumpStartState: BasalState = {
  lastBasal: '0.00',
  dateLastBasal: now.toString(),
  adjustBy: 'percent (%)',
  basalTutorial: true,
  maxBasal: '30',
  extendedBasal: false,
  insulinPaused: false,
  insulinPausedTimestamp: '',
  defaultProgramName: '',
  defaultFirstSegment: {
    start: '12:00',
    end: '8:00',
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0,
  },
  defaultSecondSegment: {
    start: '8:00',
    end: '12:00',
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0,
  },
  customProgramName: 'Relax Day',
  customProgramThirdSegment: false,
  customFirstSegment: {
    start: '12:00',
    end: '8:00',
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0,
  },
  customSecondSegment: {
    start: '8:00',
    end: '6:00',
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0,
  },
  defaultThirdSegment: {
    start: '6:00',
    end: '12:00',
    targetBG: 0,
    correctAbove: 0,
    oneUnitInsulinCovers: 0,
    oneUnitCorrectionFactor: 0,
  },
  activeExpertBasalProgram: defaultActiveBasalProgram,
  newExpertBasalProgram: emptyExpertBasalProgram,
  copyOfNewExpertBasalProgram: emptyExpertBasalProgram,
  expertBasalPrograms: defaultExpertBasalPrograms,
};

const reducer = (
  state: BasalState = initialState,
  action: type.Action
): BasalState => {
  switch (action.type) {
    case type.BASAL_RESET:
      return { ...initialState };
    case type.BASAL_UPDATE:
      // @ts-ignore
      return { ...state, ...action.payload.basal };
    case type.BASAL_PAUSE_INSULIN:
      return { ...state, insulinPaused: true };
    case type.BASAL_RESUME_INSULIN:
      return { ...state, insulinPaused: false };
    default:
      return state;
  }
};

export default reducer;
