import React from "react";
import { StyleSheet, Text, View } from "react-native-web";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import theme from "../../../../../theme";
import { BottomInnerNav } from "../../../../molecules";
import { strings } from "./SendLogFilesScreenContent";

const SendLogFilesScreen: React.FC = () => {
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={styles.container}>
      <Text style={[theme.fonts.h3, styles.title]}>
        {languageContent.sendingLogFiles}
      </Text>
      <Text style={[theme.fonts.body2, styles.subtext]}>
        {languageContent.logFile}
      </Text>
      <Text style={[theme.fonts.body2, styles.subtext]}>
        {languageContent.customerCare}
      </Text>
      <Text style={[theme.fonts.body2, styles.subtext]}>
        {languageContent.customerCareNumber}
      </Text>
      <Text style={[theme.fonts.body2, styles.subtext]}>
        {languageContent.deviceLow}
      </Text>
      <Text style={[theme.fonts.body2, styles.subtext]}>
        {languageContent.fewMinutes}
      </Text>
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.leftActionText}
        leftActionNavigateTo="AboutScreen"
        leftActionStyle={{ color: theme.colors.text.cancel, fontSize: 18 }}
        rightActionText={languageContent.rightActionText}
        rightActionStyle={[styles.actionStyle, { color: "#5B34B9" }]}
        rightActionNavigateTo="LogFilesPin"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    marginTop: 16,
    marginLeft: 12
  },
  subtext: {
    paddingLeft: 12,
    paddingRight: 4,
    marginTop: 16
  },
  container: {
    backgroundColor: "white",
    height: "100%"
  }
});

export default SendLogFilesScreen;
