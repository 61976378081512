let strings = {
  "en-US": {
    searching: "SEARCHING...",
    lastReading: "Last Reading",
    viewGraph: "VIEW GRAPH",
    lastBolus: "LAST BOLUS",
    sensorGraph: "SENSOR GRAPH",
    sensorInfo: "SENSOR INFO",
    units: "Units",
    view: "VIEW",
    date: "May 16 (11:59 am)",
  },
  "en-GB": {
    searching: "SEARCHING...",
    lastReading: "Last Reading",
    viewGraph: "VIEW GRAPH",
    lastBolus: "LAST BOLUS",
    sensorGraph: "SENSOR GRAPH",
    sensorInfo: "SENSOR INFO",
    units: "Units",
    view: "VIEW",
    date: "May 16 (11:59 am)"
  },
  "de-DE": {
    searching: "SUCHEN...",
    lastReading: "Letzter Messwert",
    viewGraph: "GRAFIK ANZEIGEN",
    lastBolus: "LETZTER BOLUS",
    sensorGraph: "SENSORGRAFIK",
    sensorInfo: "SENSOR-INFOS",
    units: "Einheiten",
    view: "ANSEHEN",
    date: "12. Jan. (10:31)"
  },
  "es-US": {
    searching: "BUSCANDO...",
    lastReading: "Última lectura",
    viewGraph: "VER GRÁFICO",
    lastBolus: "ÚLTIMO BOLO",
    sensorGraph: "GRÁFICO DEL SENSOR",
    sensorInfo: "INFORMACIÓN DEL SENSOR",
    units: "Unidades",
    view: "VER",
    date: "08-ago. (3:21 PM)"
  },
  "fr-FR": {
    searching: "RECHERCHE…",
    lastReading: "Dernière mesure",
    viewGraph: "AFFICHER LE GRAPHIQUE",
    lastBolus: "DERNIER BOLUS",
    sensorGraph: "GRAPHIQUE DU CAPTEUR",
    sensorInfo: "INFOS DU CAPTEUR",
    units: "Unités",
    view: "AFFICHER"
  },
  "nl-NL": {
    searching: "BEZIG MET ZOEKEN...",
    lastReading: "Laatste meetwaarde",
    viewGraph: "GRAFIEK BEKIJKEN",
    lastBolus: "LAATSTE BOLUS",
    sensorGraph: "SENSORGRAFIEK",
    sensorInfo: "INFO VAN DE SENSOR",
    units: "Eenheden",
    view: "BEKIJKEN"
  }
};

export { strings };
