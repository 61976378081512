import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'web/components/ios/Button';
import {
  selectSelectedBasalProgram,
} from 'web/store/reducers/basalPrograms/basalPrograms.helpers';
import {
  relaxDayData,
  updateNewProgram,
} from 'web/store/reducers/basalPrograms/basalProgramsSlice';
import { hideModal, setCurrentModal, showModal } from 'web/store/reducers/modals/modalsSlice';
import {
  CancelEditBasalProgramModal,
  StartActiveBasalProgramModal,
} from 'web/components/ios/modals/BasalProgramsModals/BasalProgramsModals';
import {
  createBasalProgramMessages,
  editBasalProgramMessages,
} from '../../../BasalPrograms.messages';
import { BasalProgramsRoutesEnum } from '../../../types';

interface EditProgramNameProps {
  isNew?: boolean;
}

export const EditProgramName: React.FC<EditProgramNameProps> = ({
  isNew = false,
}) => {
  const { navigate, setParams } = useNavigation();
  const dispatch = useDispatch();
  const selectedProgram = useSelector(selectSelectedBasalProgram);
  const [userInteraction, setUserInteraction] = useState<boolean>(false);
  const [programName, setProgramName] = useState<string>('');

  const handleConfirmCancel = () => {
    if (selectedProgram && selectedProgram.isActive && !isNew) {
      return dispatch(
        setCurrentModal({
          modal: (
            <StartActiveBasalProgramModal
              changesSaved={false}
              onPodCommTimeout={() => {
                dispatch(hideModal());
                navigate(BasalProgramsRoutesEnum.BasalProgramsMain);
              }}
            />
          ),
        })
      );
    }

    dispatch(hideModal());
    navigate(BasalProgramsRoutesEnum.BasalProgramsMain);
  };

  useEffect(() => {
    setParams({
      topBarOnLeftButtonClick: () => {
        dispatch(
          setCurrentModal({
            modal: (
              <CancelEditBasalProgramModal
                onYes={handleConfirmCancel}
                isNew={isNew}
              />
            ),
          })
        );
        dispatch(showModal());
      },
    });
  }, []);

  useEffect(() => {
    if (selectedProgram && !isNew) {
      setProgramName(selectedProgram.name);
      setUserInteraction(true);
    }
  }, [selectedProgram]);

  const handleNameInput = () => {
    if (!isNew) {
      return;
    }

    setUserInteraction(true);
    setProgramName(relaxDayData.name);
  };

  const handleNextStep = () => {
    if (!isNew) {
      return navigate(BasalProgramsRoutesEnum.ConfirmBasalProgram, { isNew: false });
    }

    dispatch(
      updateNewProgram({
        programData: {
          name: programName,
          segments: [{ id: 1, startTime: 24 }],
        },
      })
    );
    navigate(BasalProgramsRoutesEnum.EditSegment, {
      segmentId: 1,
      isNew: true,
    });
  };

  return (
    <div className="bg-white h-full pt-[19px] pb-[34px] flex flex-col justify-between">
      <div>
        <div className="text-2xl font-bold text-modes-manual px-2 mb-[15px]">
          <FormattedMessage {...editBasalProgramMessages.programName} />
        </div>

        <div
          className="py-[11px] px-2 border-b-1 border-b-ios-gray-9/[0.36] mb-[15px] cursor-pointer"
          onClick={handleNameInput}
        >
          {programName && programName}
          {!programName && (
            <span className="text-ios-text-gray">
              <FormattedMessage
                {...createBasalProgramMessages.programNamePlaceholder}
              />
            </span>
          )}
        </div>

        <div className="text-small text-ios-gray-9/60 px-2">
          <FormattedMessage
            {...editBasalProgramMessages.characterCount}
            values={{ characterCount: programName.length || 0 }}
          />
        </div>
      </div>

      <div className="px-2">
        <Button
          colorMode="manual"
          variant="colored"
          full
          disabled={!userInteraction}
          onClick={handleNextStep}
        >
          <FormattedMessage {...editBasalProgramMessages.next} />
        </Button>
      </div>
    </div>
  );
};
