const EVERYDAY_PROGRAM_ID = "everyday";

const defaultBasalReduxData = {
  id: EVERYDAY_PROGRAM_ID,
  programName: "",
  tag: "",
  segments: [],
  icRatioSegments: [],
  correctionFactorSegments: [],
  isComplete: false,
  isActive: false
};

export default defaultBasalReduxData;
