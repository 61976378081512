import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import moment from "moment";
import React from "react";
import {
  Dimensions,
  Platform,
  PlatformIOSStatic,
  StyleSheet,
  Text,
  TextInput,
  View
} from "react-native";
import { TouchableOpacity } from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import HOURS_DATA from "../../../../../data/hoursData";
import getHoursItemsList from "../../../../../helpers/getHoursItemsList";
import replaceWithValues from "../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { userActions, UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import PickerItem from "../../../../../types/pickerItem";
import { BottomInnerNav, HypoDetails } from "../../../../molecules";
import { strings } from "./HypoProtectDurationScreenContent";

const HypoProtectDurationScreen: React.FC = () => {
  /**
   * CONTEXT
   */
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  /**
   * STATE
   */
  const [duration, setDuration] = React.useState<PickerItem>({
    label: "––",
    value: -1,
    key: "INIT",
    index: 0
  });
  const [ends, setEnds] = React.useState<string>("");
  const [endsDate, setEndsDate] = React.useState<Date>();
  const [durations, setDurations] =
    React.useState<Array<PickerItem>>(HOURS_DATA);

  const [borderColor] = React.useState<string>(theme.colors.textInputBorder);
  const [listItemsOne, setListItemsOne] = React.useState<any>(null);
  const [listItemsTwo, setListItemsTwo] = React.useState<any>(null);

  const { languageContent } = useLanguageContent(strings);
  const contentVariables = [ends, duration.value];

  /**
   * CUSTOM FUNCTIONS
   */

  const onHypoProtectConfirm = () => {
    try {
      dispatch(
        userActions.userUpdate(
          {
            hypoProtectOn: true,
            hypoProtectEnd: endsDate?.toString()
          },
          userState
        )
      );

      setTimeout(() => {
        navigate("Drawer");
      }, 150);
    } catch (err) {
      console.error(err);
    }
  };

  const getAdornmentColor = () => {
    if (!duration.label) {
      return theme.colors.placeholder;
    } else if (duration.label === "––" || !duration.value) {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  const getCalcDuration = () => {
    if (!duration || duration.label === "––" || !duration.value) {
      return `${languageContent.duration}`;
    } else {
      return `${replaceWithValues(
        languageContent.durationEnds,
        contentVariables
      )}`;
    }
  };

  const handlePress = () => {
    setDuration({
      label: "4",
      value: 4,
      key: "4",
      index: 5
    });
  };

  /**
   * LIFECYCLE
   */

  React.useEffect(() => {
    setDurations(HOURS_DATA);
  }, []);

  React.useEffect(() => {
    if (!listItemsOne || !listItemsTwo) {
      setListItemsOne(getHoursItemsList(languageContent.one));
      setListItemsTwo(getHoursItemsList(languageContent.two));
    }
  }, [durations, listItemsOne, listItemsTwo]);

  React.useEffect(() => {
    if (!duration || duration.label === "––" || !duration.value) {
      return;
    }

    const today = new Date();
    const end = moment(today).add(Number(duration.label), "h").toDate();

    //to be stored in context and Realm
    setEndsDate(new Date(end));

    //this is only for the Ends section under Duration
    const endsDate = moment(end).format("MMM DD").split(" at")[0];
    const endsTime = moment(end).format("h:mm a");
    setEnds(`${endsDate} ${endsTime}`);
  }, [duration]);

  return (
    <>
      <View style={{ paddingTop: 24, height: "100%", backgroundColor: "#fff" }}>
        <HypoDetails />
        <View
          style={[
            styles.durationContainer,
            {
              backgroundColor:
                borderColor === theme.colors.blue ? "#EEF2F6" : "transparent"
            }
          ]}>
          <Text style={[styles.durationHeader]}>
            {languageContent.durationLabel}
          </Text>
          <Text style={[styles.subHeader]}>{getCalcDuration()}</Text>
          <>
            <TouchableOpacity
              style={theme.layout.inputContainerStyle}
              activeOpacity={1}
              onPress={() => handlePress()}>
              {duration.value === -1 ? (
                <TextInput
                  style={[
                    theme.layout.inputStyle,
                    {
                      color: theme.colors.placeholder,
                      width: "90%"
                    }
                  ]}
                  selectionColor={"transparent"}
                  value={"––"}
                  editable={false}
                  onFocus={() => {
                    handlePress();
                  }}
                  showSoftInputOnFocus={false}
                />
              ) : (
                <TextInput
                  style={[
                    theme.layout.inputStyle,
                    { width: "100%", border: 0 }
                  ]}
                  selectionColor={"transparent"}
                  value={duration.label}
                  editable={false}
                  onFocus={() => {
                    handlePress();
                  }}
                  showSoftInputOnFocus={false}
                />
              )}
              <Text
                style={[
                  theme.layout.adornment,
                  {
                    color: getAdornmentColor()
                  }
                ]}>
                {duration.label === "1"
                  ? languageContent.hr
                  : languageContent.hrs}
              </Text>
            </TouchableOpacity>
          </>
        </View>
      </View>
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.leftActionText}
        leftActionNavigateTo="Drawer"
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.rightActionText}
        rightActionStyle={{
          color:
            duration.label === "––" || !duration.label
              ? theme.colors.placeholder
              : theme.colors.purple
        }}
        rightAction={() => {
          onHypoProtectConfirm();
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  durationContainer: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    height: 158,
    zIndex: -1
  },
  durationHeader: {
    ...theme.fonts.h3
  },
  subHeader: {
    ...theme.fonts.text200,
    color: theme.colors.text.grayText,
    marginBottom: 6,
    fontWeight: "400"
  }
});

export default HypoProtectDurationScreen;
