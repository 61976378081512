import { isEqual } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import { FTSIntroConfirmation } from "../../../../../organisms";
import { strings } from "./SoundsScreenContent";
const SoundsScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { languageContent } = useLanguageContent(strings);
  return (
    <FTSIntroConfirmation
      title={languageContent.sounds}
      description={`${
        langState.permanentCountry === "United States"
          ? languageContent.dontSilenceUS
          : languageContent.dontSilenceIntl
      } \n\n${
        langState.permanentCountry === "United States"
          ? languageContent.dontSilence2
          : languageContent.dontSilence2Intl
      }`}
      buttonLabel={languageContent.buttonLabel}
      navigateTo="PTHO"
    />
  );
};

export default SoundsScreen;
