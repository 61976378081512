import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Card, Modal, Portal } from "react-native-paper";
import {
  Animated,
  Dimensions,
  Easing,
  Image,
  StyleSheet,
  Text,
  View
} from "react-native-web";
import PodCommunicating from "../../../../../assets/pod_communicating.png";
import SpinnerSVG from "../../../../../assets/spinner.svg";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import { strings } from "./PodCommAlertContent";

interface Props {
  screen?: string;
  screenProps?: any;
  duration: number;
  title?: string;
  message?: string;
  messageTwo?: string;
  messageThree?: string | Element;
  showCannula?: boolean;
  onDismiss?: () => void;
}

const { width } = Dimensions.get("window");

const PodCommAlert: React.FC<Props> = ({
  screen,
  screenProps,
  duration,
  title,
  message,
  messageTwo,
  messageThree,
  showCannula,
  onDismiss
}) => {
  const { languageContent } = useLanguageContent(strings);
  const [displayMessage, setDisplayMessage] = useState<string | Element>(
    message ? message : languageContent.pleaseWait
  );
  const { navigate } = useNavigation();

  let spinValue = new Animated.Value(0);

  Animated.loop(
    Animated.timing(spinValue, {
      toValue: 1,
      duration: 2000,
      easing: Easing.linear,
      useNativeDriver: true
    })
  ).start();

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"]
  });

  const handleTwoMessages = React.useCallback(() => {
    setTimeout(() => {
      setDisplayMessage(messageTwo!);

      setTimeout(() => {
        if (onDismiss) {
          onDismiss();
        }

        if (screen) {
          if (screenProps) {
            navigate(screen, screenProps && screenProps);
          }
          navigate(screen);
        }
      }, duration / 2);
    }, duration / 2);
  }, [duration, messageTwo, navigate, onDismiss, screen, screenProps]);

  const handleThreeMessages = React.useCallback(() => {
    setTimeout(() => {
      setDisplayMessage(messageTwo!);

      setTimeout(() => {
        setDisplayMessage(messageThree!);

        setTimeout(() => {
          if (onDismiss) {
            onDismiss();
          }
          if (screen) {
            navigate(screen, screenProps && screenProps);
          }
        }, duration / 3);
      }, duration / 3);
    }, duration / 3);
  }, [
    duration,
    messageThree,
    messageTwo,
    navigate,
    onDismiss,
    screen,
    screenProps
  ]);

  useEffect(() => {
    if (messageTwo && !messageThree) {
      handleTwoMessages();
    } else if (messageThree) {
      handleThreeMessages();
    } else {
      setTimeout(() => {
        if (onDismiss) {
          onDismiss();
        }

        if (screen) {
          navigate(screen, screenProps && screenProps);
        }
      }, duration);
    }
  }, [
    duration,
    handleThreeMessages,
    handleTwoMessages,
    messageThree,
    messageTwo,
    navigate,
    onDismiss,
    screen,
    screenProps
  ]);

  return (
    <>
      <Portal>
        <Modal visible={true}>
          <Card style={[styles.card]} elevation={5} accessible>
            <View style={[styles.cardWrapper]}>
              <View
                style={{
                  display: "flex"
                }}>
                <Text
                  style={[
                    {
                      paddingLeft: 16
                    },
                    theme.fonts.h4
                  ]}>
                  {title ? title : languageContent.defaultTitle}
                </Text>
                <View style={[styles.imageWrapper]}>
                  <Animated.View
                    style={[
                      {
                        transform: [{ rotate: spin }]
                      },
                      styles.animatedSpinner
                    ]}>
                    <SpinnerSVG width="86" />
                  </Animated.View>
                  <View style={[styles.messageWrapper]}>
                    <Text style={[message, theme.fonts.body2]}>
                      {displayMessage}
                    </Text>
                  </View>
                </View>
                {showCannula ? (
                  <View style={[styles.cannulaWrapper]}>
                    <View style={[styles.cannulaInnerWrapper]} />
                    <View
                      style={[
                        theme.layout.trueCenter,
                        { borderTopColor: theme.colors.purple }
                      ]}>
                      <Image
                        style={[styles.cannulaImage]}
                        resizeMode="contain"
                        source={PodCommunicating}
                      />
                    </View>
                  </View>
                ) : null}
              </View>
            </View>
          </Card>
        </Modal>
      </Portal>
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    margin: width < 400 ? 16 : 32,
    padding: 16,
    display: "flex",
    backgroundColor: theme.colors.primary.background
  },
  cardWrapper: {
    display: "flex",
    alignItems: "center"
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 16
  },
  animatedSpinner: {
    marginRight: 8,
    marginLeft: 8
  },
  messageWrapper: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    width: "70%"
  },
  message: {
    textAlign: "left",
    alignSelf: "flex-start"
  },
  cannulaWrapper: {
    marginBottom: width < 400 ? -16 : -24,
    marginHorizontal: 16
  },
  cannulaInnerWrapper: {
    paddingHorizontal: 16,
    height: 2,
    width: "100%",
    borderWidth: 1,
    borderColor: theme.colors.divider
  },
  cannulaImage: {
    width: "80%",
    alignSelf: "center"
  }
});

export default PodCommAlert;
