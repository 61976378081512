let strings = {
  "en-US": {
    review:
      "Review the Insulin to Carb (IC) Ratio values entered for each time segment.",
    segments: "Time segments",
    segment: "Segment",
    insulinCovers: "1 U of insulin covers:",
    carbs: "g of carbs",
    cancel: "Cancel",
    save: "Save"
  },
  "en-GB": {
    review:
      "Review the Insulin to Carb (IC) Ratio values entered for each time segment.",
    segments: "Time segments",
    segment: "Segment",
    insulinCovers: "1 U of insulin covers:",
    carbs: "g of carbs",
    cancel: "Cancel",
    save: "Save"
  },
  "de-DE": {
    review:
      "Prüfen Sie das Kohlenhydrat-Insulin-Verhältnis (KI-Verhältnis), das für jedes Zeitsegment eingegeben wurde.",
    segments: "Zeitsegmente",
    segment: "Segment",
    insulinCovers: "1 E Insulin reicht für:",
    carbs: "g Kohlenhydrate",
    cancel: "Abbr.",
    save: "Speichern"
  },
  "es-US": {
    review:
      "Revise los valores de proporción insulina/carbohidratos (IC) que haya introducido para cada segmento temporal.",
    segments: "Segmentos de tiempo",
    segment: "Segmento",
    insulinCovers: "1 U de insulina cubre:",
    carbs: "g de carb.",
    cancel: "Cancelar",
    save: "Guardar"
  },
  "fr-FR": {
    review:
      "Vérifiez les valeurs de rapport insuline/glucides (I/G) saisies pour chaque segment temporel.",
    segments: "Segments temporels",
    segment: "Segment",
    insulinCovers: "1 U d'insuline couvre :",
    carbs: " g de glucides",
    cancel: "Annuler",
    save: "Enregistrer"
  },
  "nl-NL": {
    review:
      "Controleer de waarden voor de verhouding insuline/koolhydraten (I/KH-verhouding) die voor elk tijdsegment zijn ingevoerd.",
    segments: "Tijdsegmenten",
    segment: "Segment",
    insulinCovers: "1 E insuline is voldoende voor:",
    carbs: " g KH",
    cancel: "Annuleren",
    save: "Opslaan"
  }
};

export { strings };
