import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { Button } from 'web/components/ios/Button';
import { maximumBolusMessages } from '../BolusSettings.messages';
import { BolusSettingsRoutesEnum } from '../types';

export const MaximumBolus: React.FC = () => {
  const { navigate } = useNavigation();

  return (
    <div className="bg-white px-2 pt-[29px] h-full text-regular font-ios flex flex-col justify-between pb-[34px]">
      <div>
        <div className="pb-[22px]">
          <FormattedMessage {...maximumBolusMessages.text} />
        </div>

        <div className="mb-[12px]">
          <span>
            <FormattedMessage {...maximumBolusMessages.maxBolus} />
          </span>
          <span className="ml-1 text-small text-ios-gray-9/60">
            <FormattedMessage {...maximumBolusMessages.maxBolusRange} />
          </span>
        </div>

        <div className="border-b-1 border-b-ios-gray-9/[.36] pb-[12px] text-4xl font-bold">
          <FormattedMessage {...maximumBolusMessages.maxBolusValue} />
        </div>
      </div>

      <Button
        colorMode="automated"
        variant="colored"
        full
        onClick={() => navigate(BolusSettingsRoutesEnum.BolusSettingsMain)}
      >
        <FormattedMessage {...maximumBolusMessages.cta} />
      </Button>
    </div>
  );
};
