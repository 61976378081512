import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import theme from 'mobile/latest/theme';
import { L2Paths } from 'web/routes/L2/InitialSetupL2';
import { Container, Card } from 'web/components/helpers/Layout/Layout';
import { messages } from './ScanSensor.messages';
import { ReactComponent as HelpIcon } from 'assets/Help-icon.svg';
import { ReactComponent as ScanSensor0 } from 'assets/L2/HowToScanSensor_00.svg';
import Modal from 'web/components/molecules/modal';
import SensorSuccessful from '../ScanSuccessful/ScanSuccessful';

const styles = StyleSheet.create({
  howToApply: {
    color: theme.colors.purple,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  howToApplyContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    marginVertical: 16,
    alignItems: 'center',
  },
  icon: {
    marginRight: 8,
  },
  scanNowContainer: {
    alignItems: 'center',
  },
});

export const ScanSensor: React.FC = () => {
  
  const [showModal, setShowModal] = useState<boolean>(false);
  const { navigate } = useNavigation();
  const handleScanNowClick = () => setShowModal(true);
  const handleHowToScanClick = () => navigate(L2Paths.howToScan);
  const handleModalCtaClick = () => {
    setShowModal(false);
    navigate(L2Paths.transmitterOutro);
  };
  return (
    <Container>
      <ScrollView>
        <Card>
          <View style={styles.scanNowContainer}>
            <TouchableOpacity onPress={handleScanNowClick}>
              <Text style={[theme.fonts.h5]}>
                <FormattedMessage {...messages.cta} />
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.image}>
            <ScanSensor0 width="200" height="314" />
          </View>
          <View style={styles.howToApplyContainer}>
            <TouchableOpacity
              style={styles.howToApplyContainer}
              onPress={handleHowToScanClick}
            >
              <View style={styles.icon}>
                <HelpIcon />
              </View>
              <Text style={styles.howToApply}>
                <FormattedMessage {...messages.howToScan} />
              </Text>
            </TouchableOpacity>
          </View>
        </Card>
      </ScrollView>

      <Modal isVisible={showModal}>
        <SensorSuccessful okButtonOnPressHandler={handleModalCtaClick} />
      </Modal>
    </Container>
  );
};
