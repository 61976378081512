import EXPERT_BASAL_HOURS_DATA from "../data/ExpertBasalSegDurationData";

const adjDataForEndTimePicker = (adjustedStartTime: string) => {
  const baseValue = EXPERT_BASAL_HOURS_DATA.find(
    (EXPERT_BASAL_HOURS_DATA) =>
      EXPERT_BASAL_HOURS_DATA.label === adjustedStartTime
  );
  let adjDataArray: any = [];
  const lastTwelveAmIndex = 48;
  if (adjustedStartTime.toLowerCase() === "12:00 am") {
    EXPERT_BASAL_HOURS_DATA.map((item) => {
      if (item.label === "" || item.index! === lastTwelveAmIndex) {
        adjDataArray.push(item);
      }
    });
  } else {
    baseValue &&
      EXPERT_BASAL_HOURS_DATA.map((item) => {
        if (item.label === "" || item.index! >= baseValue.index!) {
          adjDataArray.push(item);
        }
      });
  }

  return adjDataArray;
};

export default adjDataForEndTimePicker;
