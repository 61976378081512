import React from 'react';
import { useIntl } from 'react-intl';
import { createStackNavigator } from '@react-navigation/stack';
import { TopBar } from 'web/components/ios/TopBar';
import { BolusSettingsRoutesEnum } from './types';
import {
  messages,
  maximumBolusMessages,
  minimumGlucoseMessages,
  durationOfInsulinMessages,
} from './BolusSettings.messages';
import { BolusSettingsMain } from './views/BolusSettingsMain';
import { MaximumBolus } from './views/MaximumBolus';
import { MinimumGlucose } from './views/MinimumGlucose';
import { TargetGlucoseStack } from './views/TargetGlucoseStack/TargetGlucoseStack';
import { InsulinToCarbStack } from './views/InsulinToCarbStack/InsulinToCarbStack';
import { CorrectionFactorStack } from './views/CorrectionFactorStack/CorrectionFactorStack';
import { DurationOfInsulin } from './views/DurationOfInsulin';

const Stack = createStackNavigator();

export const BolusSettingsStack: React.FC = () => {
  const intl = useIntl();

  return (
    <Stack.Navigator
      initialRouteName={BolusSettingsRoutesEnum.BolusSettingsMain}
      screenOptions={{
        header: (props) => <TopBar {...props} />,
      }}
    >
      <Stack.Screen
        name={BolusSettingsRoutesEnum.BolusSettingsMain}
        options={{
          title: intl.formatMessage(messages.title),
          header: (props) => <TopBar {...props} backMode />,
        }}
        component={BolusSettingsMain}
      />
      <Stack.Screen
        name={BolusSettingsRoutesEnum.MaximumBolus}
        options={{
          title: intl.formatMessage(maximumBolusMessages.title),
        }}
        component={MaximumBolus}
      />
      <Stack.Screen
        name={BolusSettingsRoutesEnum.TargetGlucoseStack}
        options={{
          headerShown: false,
        }}
        component={TargetGlucoseStack}
      />
      <Stack.Screen
        name={BolusSettingsRoutesEnum.MinimumGlucose}
        options={{
          title: intl.formatMessage(minimumGlucoseMessages.title),
        }}
        component={MinimumGlucose}
      />
      <Stack.Screen
        name={BolusSettingsRoutesEnum.InsulinToCarbStack}
        options={{
          headerShown: false,
        }}
        component={InsulinToCarbStack}
      />
      <Stack.Screen
        name={BolusSettingsRoutesEnum.CorrectionFactorStack}
        options={{
          headerShown: false,
        }}
        component={CorrectionFactorStack}
      />
      <Stack.Screen
        name={BolusSettingsRoutesEnum.DurationOfInsulin}
        options={{
          title: intl.formatMessage(durationOfInsulinMessages.title),
        }}
        component={DurationOfInsulin}
      />
    </Stack.Navigator>
  );
};
