let strings = {
    "en-US": {
      currentSensor: "Current Sensor",
      switch: "Switch",
      toAdd: "To add a new Sensor, you must first delete this Sensor.",
      sensor: "SN: 3MH000Z4ZMH",
      deleteSensor: "Delete Sensor",
      close: "Close",
      settings: "Sensor Settings",
      delete: "Delete",
      enterNew: "Enter New",
      cgmTrans: "Transmitter",
      unavailable: "Not available for the simulator.",
      ok: "Ok",
      endsIn: "Ends In: ",
      days: " days",
      sensorInformation: "Sensor Information",
      pairingCode: "Pairing Code",
      pairingNumber: "1 2 3 4",
      serialNumber: "Serial Number",
      serialNumb: "1234 1234 1234",
    },
    "en-GB": {
      currentSensor: "Current Sensor",
      switch: "Switch",
      toAdd: "To add a new Sensor, you must first delete this Sensor.",
      sensor: "SN: 3MH000Z4ZMH",
      deleteSensor: "Delete Sensor",
      close: "Close",
      serialNumber: "Serial Number",
      settings: "Sensor Settings",
      delete: "Delete",
      enterNew: "Enter New",
      cgmTrans: "Transmitter",
      unavailable: "Not available for the simulator.",
      ok: "Ok",
      endsIn: "Ends In: ",
      days: " days"
    },
    "de-DE": {
      currentSensor: "Current Sensor",
      switch: "Switch",
      toAdd: "To add a new Sensor, you must first delete this Sensor.",
      sensor: "SN: 3MH000Z4ZMH",
      deleteSensor: "Delete Sensor",
      close: "Close",
      settings: "Sensor Settings",
      serialNumber: "Serial Number",
      delete: "Delete",
      enterNew: "Enter New",
      cgmTrans: "Transmitter",
      unavailable: "Not available for the simulator.",
      ok: "Ok",
      endsIn: "Ends In: ",
      days: " days"
    },
    "es-US": {
      currentSensor: "Sensor Actual",
      switch: "Cambiar",
      toAdd: "Para agregar un nuevo sensor, primero debe eliminar este sensor.",
      sensor: "SN: 3MH000Z4ZMH",
      deleteSensor: "Eliminar Sensor",
      close: "Cerrar",
      settings: "Configuración de Sensores",
      delete: "Borrar",
      enterNew: "Ingresar",
      cgmTrans: "Transmisor",
      unavailable: "No está disponible en el simulador.",
      ok: "Aceptar",
      sensorInformation: "Información del Sensor",
      pairingCode: "Código de Emparejamiento",
      pairingNumber: "1 2 3 4",
      serialNumber: "Número de Serie",
      serialNumb: "1234 1234 1234"
    },
    "fr-FR": {
      currentSensor: "Current Sensor",
      switch: "Switch",
      toAdd: "To add a new Sensor, you must first delete this Sensor.",
      sensor: "SN: 3MH000Z4ZMH",
      deleteSensor: "Delete Sensor",
      close: "Close",
      settings: "Sensor Settings",
      serialNumber: "Transmitter Serial Number",
      delete: "Delete",
      enterNew: "Enter New",
      cgmTrans: "Transmitter",
      unavailable: "Not available for the simulator.",
      ok: "Ok",
      endsIn: "Ends In: ",
      days: " days"
    },
    "nl-NL": {
      currentSensor: "Huidige Sensor",
      switch: "Overschakelen",
      toAdd:
        "Om een nieuwe sensor toe te voegen, moet u eerst deze Sensor verwijderen.",
      sensor: "SN: 3MH000Z4ZMH",
      deleteSensor: "Sensor verwijderen",
      close: "Sluiten",
      settings: "Sensorinstellingen",
      serialNumber: "Serienummer van zender",
      delete: "Verwijderen",
      enterNew: "Nieuwe invoeren",
      cgmTrans: "Zender",
      unavailable: "Niet beschikbaar voor de simulator",
      ok: "OK",
      endsIn: "Verloopt over:",
      days: " dagen"
    }
  };
  
  export { strings };
  