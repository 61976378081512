let strings = {
  "en-US": {
    ok: "OK",
    okCamel: "Ok",
    range: "(%1 to 30 U)",
    alertMaxTitle: "Maximum Value",
    alertMaxBody: "Max Bolus cannot be more than 30\u00A0U.",
    alertMinTitle: "Minimum Value",
    alertMinBody: "Minimum Bolus cannot be less than %1\u00A0U.",
    setMaxBolus: "Set Max Bolus",
    maxAmount:
      "The maximum amount of insulin that you can request in a single bolus.",
    maxBolus: "Max Bolus",
    maxBolusValue: "Max Bolus Value",
    maxBolusBody: "Max Bolus must be an increment of %1\u00A0U.",
    cancel: "Cancel",
    next: "Next",
    u: "U"
  },
  "en-GB": {
    ok: "OK",
    okCamel: "Ok",
    range: "(%1 to 30 U)",
    alertMaxTitle: "Maximum Value",
    alertMaxBody: "Max Bolus cannot be more than 30\u00A0U.",
    alertMinTitle: "Minimum Value",
    alertMinBody: "Minimum Bolus cannot be less than %1\u00A0U.",
    setMaxBolus: "Set Max Bolus",
    maxAmount:
      "The maximum amount of insulin that you can request in a single bolus.",
    maxBolus: "Max Bolus",
    maxBolusValue: "Max Bolus Value",
    maxBolusBody: "Max Bolus must be an increment of %1\u00A0U.",
    cancel: "Cancel",
    next: "Next",
    u: "U"
  },
  "de-DE": {
    ok: "OK",
    okCamel: "OK",
    range: "(%1 bis 30 E)",
    alertMaxTitle: "Höchstwert",
    alertMaxBody: "Der maximale Bolus darf nicht größer sein als 30 E.",
    alertMinTitle: "Mindestwert",
    alertMinBody: "Der minimale Bolus darf nicht kleiner sein als %1 E.",
    setMaxBolus: "Maximalen Bolus festlegen",
    maxAmount:
      "Die maximale Insulinmenge, die mit einem einzelnen Bolus angefordert werden kann.",
    maxBolus: "Max. Bolus",
    maxBolusValue: "Maximaler Boluswert",
    maxBolusBody: "Der maximale Bonus muss ein Vielfaches sein von %1 E sein.",
    cancel: "Abbr.",
    next: "Weiter",
    u: "E"
  },
  "es-US": {
    ok: "Aceptar",
    okCamel: "Aceptar",
    range: "(%1 a 30 U/h)",
    alertMaxTitle: "Valor máximo",
    alertMaxBody: "El bolo máximo no puede ser mayor de 30 U.",
    alertMinTitle: "Valor mínimo",
    alertMinBody: "El bolo mínimo no puede ser menor de %1 U.",
    setMaxBolus: "Establecer bolo máximo",
    maxAmount:
      "La cantidad máxima de insulina que puede solicitar en un solo bolo.",
    maxBolus: "Bolo máximo",
    maxBolusValue: "Valor del bolo máximo",
    maxBolusBody: "El bolo máximo debe ser un incremento de %1 U.",
    cancel: "Cancelar",
    next: "Siguiente",
    u: "U"
  },
  "fr-FR": {
    ok: "OK",
    okCamel: "Ok",
    range: "(de %1 à 30 U)",
    alertMaxTitle: "Valeur maximum",
    alertMaxBody: "Le bolus maximum ne peut pas être supérieur à 30 U.",
    alertMinTitle: "Valeur minimum",
    alertMinBody: "Le bolus minimum ne peut pas être inférieur à %1 U.",
    setMaxBolus: "Configurer un bolus max",
    maxAmount:
      "Quantité maximale d'insuline que vous pouvez demander en un seul bolus.",
    maxBolus: "Bolus max",
    maxBolusValue: "Valeur de bolus max",
    maxBolusBody: "Le bolus max doit être un incrément de %1 U.",
    cancel: "Annuler",
    next: "Suivant",
    u: "U"
  },
  "nl-NL": {
    ok: "OK",
    okCamel: "OK",
    range: "(%1 tot 30 E)",
    alertMaxTitle: "Maximumwaarde",
    alertMaxBody: "Maximale bolus mag niet hoger zijn dan 30 E.",
    alertMinTitle: "Minimumwaarde",
    alertMinBody: "Minimale bolus mag niet lager zijn dan %1 E.",
    setMaxBolus: "Maximale bolus instellen",
    maxAmount:
      "De maximale hoeveelheid insuline die u in één bolus kunt toedienen.",
    maxBolus: "Maximale bolus",
    maxBolusValue: "Waarde maximale bolus",
    maxBolusBody: "Maximale bolus moet een toename zijn van %1 E.",
    cancel: "Annuleren",
    next: "Volgende",
    u: "E"
  }
};

export { strings };
