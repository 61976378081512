import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat";
import { getLocalizedNumberFormat } from "../../../../../../helpers/getLocalizedNumberFormat";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import {
  bolusActions,
  BolusState
} from "../../../../../../../../web/store/reducers/bolus";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { BottomInnerNav, SegmentTopBar } from "../../../../../molecules";
import { strings } from "./ICRatioFirstSegmentScreenContent";

const ONE_UNIT_FIELD = "ONE_UNIT";

const ICRatioFirstSegmentScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );

  const segment = bolusState.defaultFirstSegment;

  const [fieldValue, setFieldValue] = React.useState<string>(
    segment.oneUnitInsulinCovers ? String(segment.oneUnitInsulinCovers) : ""
  );

  const { navigate } = useNavigation();
  const dispatch = useDispatch();

  const determineEndTime = () => {
    if (
      segment.oneUnitInsulinCovers &&
      segment.oneUnitInsulinCovers >= 1 &&
      segment.oneUnitInsulinCovers <= 150
    ) {
      return true;
    }

    return false;
  };

  const [endTime, setEndTime] = React.useState<boolean>(determineEndTime);

  const fieldRef = React.useRef<TextInput>(null);

  const [background, setBackground] = React.useState<string>("");

  const { languageContent } = useLanguageContent(strings);

  const getAdornmentColor = () => {
    if (!fieldValue) {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  return (
    <View style={styles.container}>
      <View>
        <SegmentTopBar segmentNum="1" />
        <View style={[styles.break, { marginBottom: 16 }]} />
        <View style={[{ paddingVertical: 16 }]}>
          <View style={[{ flexDirection: "row", paddingHorizontal: 16 }]}>
            <Text style={[theme.fonts.h4, { flex: 1.1 }]}>
              {languageContent.start}
            </Text>
            <Text style={[theme.fonts.h4, { flex: 1 }]}>
              {languageContent.end}
            </Text>
          </View>
          <View
            style={[
              {
                width: "100%",
                flexDirection: "row",
                paddingLeft: 8,
                paddingHorizontal: 14
              }
            ]}>
            <View style={[styles.startTimeContainer]}>
              <Text
                style={[
                  {
                    paddingTop: 8,
                    color: theme.colors.pureBlack,
                    fontSize: 32
                  }
                ]}>
                {getCurrentHourFormat({
                  is24Hour: langState.is24Hour,
                  timeStr: "12:00 am",
                  trimModifier: true
                })}
                {!langState.is24Hour ? (
                  <Text style={[styles.periodText]}>AM</Text>
                ) : null}
              </Text>

              <View style={[{ alignSelf: "center" }]}>
                <Text style={[{ fontSize: 32 }]}>{" - "}</Text>
              </View>
            </View>
            <Pressable
              onPress={() => setEndTime(true)}
              style={[theme.layout.mockInput, { flex: 1, paddingVertical: 8 }]}>
              <Text style={[theme.layout.mockInputStyle]}>
                {endTime ? (
                  <Text style={{ color: theme.colors.pureBlack }}>
                    {`${getCurrentHourFormat({
                      timeStr: "8:00 am",
                      is24Hour: langState.is24Hour,
                      trimModifier: true
                    })}`}
                    {!langState.is24Hour ? (
                      <Text style={[styles.periodText]}>AM</Text>
                    ) : null}
                  </Text>
                ) : (
                  <Text
                    style={{
                      color: theme.colors.placeholder,
                      fontSize: 32
                    }}>
                    ––
                  </Text>
                )}
              </Text>
            </Pressable>
          </View>
          <View style={styles.helpTextRow}>
            <Text style={[theme.fonts.body1, styles.startTimeContainer]}>
              {languageContent.night}
            </Text>
            <Text style={[theme.fonts.body1, styles.endTimeHelpText]}>
              {endTime && languageContent.morning}
            </Text>
          </View>
          <View
            style={[
              {
                marginTop: 8,
                zIndex: -1,
                backgroundColor: background ? "#EEF2F6" : "transparent"
              }
            ]}>
            <View
              style={[
                {
                  marginTop: 16,
                  marginHorizontal: 16
                }
              ]}>
              <Text style={[theme.fonts.h4]}>
                {languageContent.oneUnitCovers}
              </Text>
              <Text
                style={[
                  theme.fonts.body1,
                  { color: theme.colors.text.cancel, marginBottom: 8 }
                ]}>
                {languageContent.range}
              </Text>
            </View>
            <Pressable
              style={[
                theme.layout.inputContainerStyle,
                {
                  zIndex: 999,
                  marginHorizontal: 16
                }
              ]}
              onPress={() => {
                fieldRef.current?.focus();
                setFieldValue("15");
              }}>
              {fieldValue ? (
                <Text
                  style={[
                    theme.layout.inputStyle,
                    { color: theme.colors.text.inputText }
                  ]}>
                  {String(fieldValue)}
                </Text>
              ) : (
                <Text
                  style={{
                    color: theme.colors.placeholder,
                    fontSize: 32
                  }}>
                  ––
                </Text>
              )}
              <Text
                style={[
                  theme.layout.adornment,
                  {
                    color: getAdornmentColor()
                  }
                ]}>
                g
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
      <BottomInnerNav
        leftActionStyle={{ color: theme.colors.text.cancel }}
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.next}
        rightAction={() => {
          if (Number(fieldValue) < 1 || Number(fieldValue) > 150 || !endTime) {
            return;
          }
          dispatch(
            bolusActions.bolusUpdate(
              {
                defaultFirstSegment: {
                  ...segment,
                  oneUnitInsulinCovers: fieldValue.includes(",")
                    ? Number(getLocalizedNumberFormat(fieldValue))
                    : Number(fieldValue)
                }
              },
              bolusState
            )
          );
          navigate("ICRatioSecondSegment");
        }}
        rightActionStyle={{
          color:
            !fieldValue ||
            Number(fieldValue) < 1 ||
            Number(fieldValue) > 150 ||
            !endTime
              ? theme.colors.placeholder
              : theme.colors.purple
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "#fff"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  mockInput: {
    borderColor: theme.colors.textInputBorder,
    borderWidth: 1,
    backgroundColor: theme.colors.textInputBackgroundColor,
    width: "100%",
    borderRadius: 5
  },

  periodText: {
    color: theme.colors.text.cancel,
    fontSize: 18
  },
  startTimeContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1.2
  },
  startTimeHelpText: {
    flex: 1.1,
    color: theme.colors.text.cancel,
    paddingLeft: 16
  },
  endTimeHelpText: {
    flex: 1,
    color: theme.colors.text.cancel,
    paddingHorizontal: 16
  },

  helpTextRow: {
    flexDirection: "row",
    marginTop: 8,
    zIndex: -1,
    marginHorizontal: 14
  }
});

export default ICRatioFirstSegmentScreen;
