import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'web/store/store';

export const selectUserState = (state: RootState) => state.user;

export const selectCurrentOperationMode = createSelector(
  (state: RootState) => state.user,
  (user) => {
    if (user.limitedMode) {
      return 'limited';
    }

    if (user.automatedMode) {
      return 'automated';
    }

    return 'manual';
  }
);

export const selectIsAutoMode = createSelector(
  (state: RootState) => state.user,
  (user) => user.automatedMode
);

export const selectIOSStatusBarMode = createSelector(
  (state: RootState) => state.user,
  (user) => user.iosStatusBarMode
);

export const selectCurrentOperationModeName = createSelector(
  selectCurrentOperationMode,
  (operationMode) => {
    const firstLetter = operationMode.substring(0, 1).toUpperCase();
    const restWord = operationMode.substring(1);
    return `${firstLetter}${restWord}`;
  }
);

export const selectIsActivityModeActive = createSelector(
  selectUserState,
  (user) => user.hypoProtectOn
);

export const selectActivityModeEndDate = createSelector(
  selectUserState,
  (user) => new Date(user.hypoProtectEnd)
);

export const selectIsTempBasalActive = createSelector(
  selectUserState,
  (user) => user.tempBasalOn
);

export const selectTempBasalEndDate = createSelector(
  selectUserState,
  (user) => new Date(user.tempBasalEnd)
);
