import { defineMessages } from 'react-intl';
import { DELIVERY_TIME } from 'web/containers/ios/PauseStart/PauseStartStack.messages';

export const pauseInsulinDeliveryMessages = defineMessages({
  title: {
    id: 'ios.modals.pause-insulin.title',
    defaultMessage: `Pause insulin delivery{separator} for ${DELIVERY_TIME} hrs?`,
  },
  text: {
    id: 'ios.modals.pause-insulin.text',
    defaultMessage: 'All insulin delivery stops during this{separator} time.',
  },
  yesButton: {
    id: 'ios.modals.pause-insulin.yes-button',
    defaultMessage: 'Yes',
  },
  noButton: {
    id: 'ios.modals.pause-insulin.no-button',
    defaultMessage: 'No',
  },
});

export const attentionMessages = defineMessages({
  title: {
    id: 'ios.modals.pause-insulin.title',
    defaultMessage: 'Attention: 0 U/hr',
  },
  text: {
    id: 'ios.modals.pause-insulin.text',
    defaultMessage:
      'You have at least one segment set to 0 U/hr. Basal insulin delivery will stop{separator} during  these time segments.',
  },
  okButton: {
    id: 'ios.modals.pause-insulin.yes-button',
    defaultMessage: 'OK',
  },
  cancelButton: {
    id: 'ios.modals.pause-insulin.no-button',
    defaultMessage: 'Cancel',
  },
});

export const communicatingWithPodMessages = defineMessages({
  title: {
    id: 'ios.modals.pause-insulin.communicating-with-pod.title',
    defaultMessage: 'Communicating with Pod',
  },
  text: {
    id: 'ios.modals.pause-insulin.communicating-with-pod.text',
    defaultMessage: 'Please wait...',
  },
});
