export type HistoryEventType =
  | 'Glucose'
  | 'SwitchMode'
  | 'TempBasal'
  | 'Bolus'
  | 'Pod'
  | 'BasalProgram'
  | 'AutoEvent';
interface HistoryEvent {
  [name: string]: HistoryEventType;
}
export const historyEvents: HistoryEvent = {
  Glucose: 'Glucose',
  SwitchMode: 'SwitchMode',
  TempBasal: 'TempBasal',
  Bolus: 'Bolus',
  Pod: 'Pod',
  BasalProgram: 'BasalProgram',
  AutoEvent: 'AutoEvent',
};
