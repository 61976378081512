import PickerItem from "../types/pickerItem";

const getAdjustedDataForBasalRatePicker = (
  adjustedMaxBasalRate: string,
  dataArray: PickerItem[]
) => {
  const baseValue = dataArray.find(
    (dataArray) => dataArray.label === adjustedMaxBasalRate
  );

  let adjDataArray: any = [];

  baseValue &&
    dataArray.map((item) => {
      if (item.label === "" || item.index! <= baseValue.index!) {
        adjDataArray.push(item);
      }
    });

  return adjDataArray;
};
export default getAdjustedDataForBasalRatePicker;
