import { useNavigation } from '@react-navigation/core';
import { isEqual } from 'lodash';
import React from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as LogoSVG } from '../../../../../../../assets/Omnipod_5_LOGO_RGB_MANGO_TM.svg';
import { ReactComponent as LogoSimulator } from '../../../../../../../assets/Omnipod_5_Simulator_LOGO_MANGO.svg';
import COUNTRIES from '../../../../../data/countries';
import useLanguageContent from '../../../../../hooks/useLanguageContent';
import {
  LangState,
  languageActions,
} from '../../../../../../../web/store/reducers/language';
import { UserState } from '../../../../../../../web/store/reducers/user';
import { RootState } from '../../../../../../../web/store/store';
import theme from '../../../../../theme';
import { CountryType } from '../../../../../types/country';
import { BottomInnerNav } from '../../../../molecules';
import CustomRadioButton from '../../../../molecules/customRadioButton';
import { strings } from './CountrySettingsScreenContent';

const CountrySettingsScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { languageContent } = useLanguageContent(strings);
  const { navigate } = useNavigation();
  const dispatch = useDispatch();

  const handleRadioButtonPress = (label: string) => {
    dispatch(
      languageActions.languageUpdate(
        {
          country: label as CountryType,
        },
        langState
      )
    );
  };

  return (
    <>
      <View style={{ height: '93%', width: '100%', backgroundColor: 'white' }}>
        <View style={styles.section}>
          {userState.podConfigured && userState.hasTXId ? (
            <LogoSimulator
              height={245}
              width={245}
              style={{ alignSelf: 'center', marginBottom: -54 }}
            />
          ) : (
            <LogoSVG
              height={245}
              width={245}
              style={{ alignSelf: 'center', marginBottom: -54 }}
            />
          )}
        </View>
        <View style={styles.section}>
          <Text style={theme.fonts.body1}>
            {languageContent.selectLanguage}
          </Text>
        </View>
        <ScrollView>
          {COUNTRIES.map((node: any, index: number) => (
            <View key={`${index}--${node.label}`} style={styles.section}>
              <CustomRadioButton
                locale={langState.locale}
                item={node}
                index={index}
                handleRadioButtonPress={handleRadioButtonPress}
                selectedItem={langState.country}
                disabled={langState.country !== node.label}
              />
            </View>
          ))}
        </ScrollView>
      </View>
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.back}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        leftActionNavigateTo="SelectLanguageSettings"
        rightActionText={languageContent.continue}
        rightAction={() => {
          navigate('TC');
        }}
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  section: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grayScale.gray600,
    width: '100%',
  },
});

export default CountrySettingsScreen;
