let strings = {
  "en-US": {
    hpInProgress: "Activity feature in progress",
    remainingHrs: "Remaining: (%1 hrs %2 min)",
    cancel: "CANCEL",
    cancelHypoProtectModalTitle: "Cancel Activity feature?",
    IOB: "IOB",
    dismissText: "No",
    confirmText: "Yes",
    unit: "U"
  },
  "en-GB": {
    hpInProgress: "Activity feature in progress",
    remainingHrs: "Remaining: (%1 hrs %2 min)",
    cancel: "CANCEL",
    cancelHypoProtectModalTitle: "Cancel Activity feature?",
    IOB: "IOB",
    dismissText: "No",
    confirmText: "Yes",
    unit: "U"
  },
  "de-DE": {
    hpInProgress: "Aktivitätsfunktion in Bearbeitung",
    remainingHrs: "Verbleibend: (%1 Stdn. %2 Min.)",
    cancel: "Abbr.",
    cancelHypoProtectModalTitle: "Aktivitätsfunktion abbrechen?",
    IOB: "AI",
    dismissText: "Nein",
    confirmText: "Ja",
    unit: "E"
  },
  "es-US": {
    hpInProgress: "La actividad está prendida",
    remainingHrs: "Restante: (%1 h %2 min)",
    cancel: "CANCELAR",
    cancelHypoProtectModalTitle: "¿Cancelar la función de Actividad?",
    IOB: "IOB",
    dismissText: "No",
    confirmText: "Sí",
    unit: "U"
  },
  "fr-FR": {
    hpInProgress: "Fonction Activité en cours",
    remainingHrs: "Temps restant : (%1 h %2 min)",
    cancel: "ANNULER",
    cancelHypoProtectModalTitle: "Annuler la fonction Activité ?",
    IOB: "InA",
    dismissText: "Non",
    confirmText: "Oui",
    unit: "U"
  },
  "nl-NL": {
    hpInProgress: "Activiteitenfunctie bezig",
    remainingHrs: "Resterend: (%1 uur %2 min)",
    cancel: "ANNULEREN",
    cancelHypoProtectModalTitle: "Activiteitenfunctie annuleren?",
    IOB: "IOB",
    dismissText: "Nee",
    confirmText: "Ja",
    unit: "E"
  }
};

export { strings };
