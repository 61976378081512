let strings = {
  'en-US': {
    bolus: 'Bolus',
    targetAndCorrect: 'Target Glucose & Correct Above',
    insulinToCarb: 'Insulin to Carb Ratio',
    correction: 'Correction Factor',
    duration: 'Duration of Insulin Action',
    minimumGlucose: 'Minimum Glucose for Calculations',
    maximum: 'Maximum Bolus',
  },
  'en-GB': {
    bolus: 'Bolus',
    targetAndCorrect: 'Target Glucose & Correct Above',
    insulinToCarb: 'Insulin to Carb Ratio',
    correction: 'Correction Factor',
    duration: 'Duration of Insulin Action',
    minimumGlucose: 'Minimum Glucose for Calculations',
    maximum: 'Maximum Bolus',
  },
  'de-DE': {
    bolus: 'Bolus',
    targetAndCorrect: 'Glukose-Zielwert & Korrigieren ü..',
    insulinToCarb: 'Kohlenhydrat-Insulin...',
    correction: 'Korrekturfaktor',
    duration: 'Dauer der Insulinaktivität',
    minimumGlucose: 'Min. Blutzucker für Berechnungen',
    maximum: 'Maximaler Bolus',
  },
  'es-US': {
    bolus: 'Bolo',
    targetAndCorrect: 'Opciones de glucosa objetivo...',
    insulinToCarb: 'Proporción de insulina..',
    correction: 'Factor de corrección',
    duration: 'Duración de la Acción de la...',
    minimumGlucose: 'Glucosa mínima',
    maximum: 'Bolo máximo',
  },
  'nl-NL': {
    bolus: 'Bolus',
    targetAndCorrect: 'Streefwaarde gluco...',
    insulinToCarb: 'Insuline/KH-verhou...',
    correction: 'Correctiefactor',
    duration: 'Duur van de insulin...',
    minimumGlucose: 'Minimale glucose',
    maximum: 'Maximale bolus',
  },
  'fr-FR': {
    bolus: 'Bolus',
    targetAndCorrect: 'Glucose cible et Corriger si supérieur à',
    insulinToCarb: 'Rapport insuline/glucides',
    correction: 'Facteur de correction',
    duration: "Durée d'action de l'insuline",
    minimumGlucose: 'Glucose minimum pour les calculs',
    maximum: 'Bolus maximum',
  },
};

export { strings };
