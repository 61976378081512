import { isEqual } from "lodash";
import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native-web";
import { useSelector } from "react-redux";
import { getUnitByLocale } from "../../../../../helpers/getUnitByLocale";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { strings } from "./BolusCalculationsScreenContent";

const CorrectionBolus = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);

  return (
    <>
      <View style={[styles.row, { flex: 1 }]}>
        <Text style={[theme.fonts.h4]}>{languageContent.correctionBolus}</Text>
        <Text style={[theme.fonts.h4]}>
          {`${(0.5).toLocaleString(langState.locale)} `}
          <Text style={[theme.fonts.body2]}>{languageContent.u}</Text>
        </Text>
      </View>
      <View style={[styles.contentContainer, { paddingTop: 12 }]}>
        <Text style={[styles.contentText]}>{`${
          languageContent.sensor
        } = 160${", "}${languageContent.target} = 120`}</Text>
        <Text style={[styles.contentText]}>
          {`${languageContent.correctionFactor} = 50`}
        </Text>
        <Text style={[styles.contentText]}>
          {`160 - 120/50 = ${(0.8).toLocaleString(langState.locale)} ${
            languageContent.u
          }`}
        </Text>
      </View>
      <View style={[styles.secondaryContentContainer]}>
        <Text style={[styles.contentText, { color: theme.colors.text.dark }]}>
          {languageContent.mealAdjustment}
        </Text>
        <Text
          style={[
            styles.contentText
          ]}>{`${languageContent.mealIOB} = 0 ${languageContent.u}`}</Text>
        <Text style={[styles.contentText]}>
          {`${(0.8).toLocaleString(langState.locale)} ${
            languageContent.u
          } - 0 ${languageContent.u} = ${(0.8).toLocaleString(
            langState.locale
          )} ${languageContent.u}`}
        </Text>
      </View>
      <View style={[styles.secondaryContentContainer]}>
        <Text style={[styles.contentText, { color: theme.colors.text.dark }]}>
          {languageContent.correctionsIOBAdjustment1}
        </Text>
        <Text style={[styles.contentText]}>
          {`${languageContent.correctionIOB} = ${(0.3).toLocaleString(
            langState.locale
          )} ${languageContent.u}`}
        </Text>
        <Text style={[styles.contentText]}>
          {`${(0.8).toLocaleString(langState.locale)} ${
            languageContent.u
          } - ${(0.3).toLocaleString(langState.locale)} ${
            languageContent.u
          } = ${(0.5).toLocaleString(langState.locale)} ${languageContent.u}`}
        </Text>
      </View>
    </>
  );
};

const MealBolus = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { languageContent } = useLanguageContent(strings);

  return (
    <>
      <View
        style={[
          styles.row,
          {
            paddingTop: 16
          }
        ]}>
        <Text style={[theme.fonts.h4]}>{languageContent.mealBolus}</Text>
        <Text style={[theme.fonts.h4]}>
          {`${(7.75).toLocaleString(langState.locale)} `}
          <Text style={[theme.fonts.body2]}>{languageContent.u}</Text>
        </Text>
      </View>
      <View style={[styles.contentContainer, { paddingTop: 12 }]}>
        <Text
          style={[
            styles.contentText
          ]}>{`${languageContent.carbs} = 75 g, ${languageContent.ratio} = 10 g/${languageContent.u}`}</Text>
        <Text style={[styles.contentText]}>
          75/10 = {(7.5).toLocaleString(langState.locale)} {languageContent.u}
        </Text>
      </View>
      <View style={[styles.secondaryContentContainer]}>
        <Text style={[styles.contentText, { color: theme.colors.text.dark }]}>
          {languageContent.correctionsIOBAdjustment2}
        </Text>
        <Text style={[styles.contentText]}>
          {`${languageContent.remainingIOBAdjustment} = 0 ${languageContent.u}`}
        </Text>
        <Text style={[styles.contentText]}>
          {`${(7.5).toLocaleString(langState.locale)} ${
            languageContent.u
          } - 0 ${languageContent.u} = ${(7.75).toLocaleString(
            langState.locale
          )} ${languageContent.u}`}
        </Text>
      </View>
      <View style={[styles.secondaryContentContainer]}>
        <Text style={[styles.contentText]}>
          {`${languageContent.adjustedMealBolus} = ${(7.75).toLocaleString(
            langState.locale
          )} ${languageContent.u}`}
        </Text>
        <Text style={[styles.contentText]}>
          {languageContent.adjustedForSensor}
        </Text>
      </View>
    </>
  );
};

const CalculatedBolus = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);

  return (
    <>
      <View style={[styles.row]}>
        <Text style={[theme.fonts.h4]}>{languageContent.calculatedBolus}</Text>
        <Text style={[theme.fonts.h4]}>
          {`${(7.75).toLocaleString(langState.locale)} `}
          <Text style={[theme.fonts.body2]}>{languageContent.u}</Text>
        </Text>
      </View>
      <View style={{ paddingTop: 16 }}>
        <View style={[styles.row]}>
          <Text
            style={[
              theme.fonts.h4,
              { fontWeight: "400", color: theme.colors.text.grayText }
            ]}>
            {languageContent.yourAdjustment}
          </Text>
          <Text
            style={[
              theme.fonts.h4,
              { fontWeight: "400", color: theme.colors.text.grayText }
            ]}>
            {`${(0.5).toLocaleString(langState.locale)} `}
            <Text style={[theme.fonts.body2]}>{languageContent.u}</Text>
          </Text>
        </View>
      </View>
      <View style={[styles.secondaryContentContainer]}>
        <Text style={[styles.contentText]}>
          {`${
            languageContent.calcBolusCorrectionBolus
          } + ${(0.5).toLocaleString(langState.locale)} ${languageContent.u}`}
        </Text>
        <Text style={[styles.contentText]}>
          {`${languageContent.calcBolusMealBolus} + ${(7.75).toLocaleString(
            langState.locale
          )} ${languageContent.u}`}
        </Text>
      </View>
    </>
  );
};

const TotalBolus = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={[styles.row]}>
      <Text style={[theme.fonts.h4]}>{languageContent.totalBolus}</Text>
      <Text style={[theme.fonts.h4, { color: theme.colors.text.grayText }]}>
        =
      </Text>
      <Text style={[theme.fonts.h4]}>
        {`${(8.25).toLocaleString(langState.locale)} `}
        <Text style={[theme.fonts.body2]}>{languageContent.u}</Text>
      </Text>
    </View>
  );
};

const Divider = () => <View style={[styles.divider]} />;

const BolusCalculationsScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);

  return (
    <>
      <ScrollView style={[styles.scroll, { backgroundColor: "#FFFFFF" }]}>
        <View style={{ marginBottom: 48 }}>
          <CorrectionBolus />
          <MealBolus />
          <Divider />
          <CalculatedBolus />
          <Divider />
          <TotalBolus />
          <Divider />
          <View style={{ paddingHorizontal: 24 }}>
            <Text style={[styles.contentText]}>
              {`${languageContent.correctRange} ${getUnitByLocale(
                130,
                langState.units,
                langState.locale
              )} ${langState.units}`}
            </Text>
          </View>
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 16,
    marginTop: 20
  },
  contentText: {
    ...theme.fonts.body1,
    fontSize: 13,
    lineHeight: 26,
    fontWeight: "400",
    color: theme.colors.text.grayText,
    marginTop: -6
  },
  contentContainer: { paddingHorizontal: 24, paddingTop: 24 },
  secondaryContentContainer: { paddingHorizontal: 24, paddingTop: 16 },
  divider: {
    marginVertical: 32,
    borderTopColor: theme.colors.textInputBorder,
    borderWidth: 0.5,
    borderBottomColor: theme.colors.textInputBorder,
    borderRightColor: "transparent",
    borderLeftColor: "transparent"
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  scroll: {
    paddingTop: 24,
    paddingHorizontal: 16,
    marginVertical: 0
  },
  bottomNav: {
    fontSize: 14
  }
});

export default BolusCalculationsScreen;
