import { createStackNavigator } from "@react-navigation/stack";
import { isEqual } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { BackButton } from "../../components/atoms";
import {
  ExitAutoModeScreen,
  ExitManualModeScreen
} from "../../components/screens";
import { useModeIconSmall } from "../../hooks";
import useLanguageContent from "../../hooks/useLanguageContent";
import { UserState } from "../../../../web/store/reducers/user";
import { RootState } from "../../../../web/store/store";
import theme from "../../theme";
import { strings } from "./SwitchModeStackContent";

const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const SwitchModeStack: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  return (
    <>
      <Stack.Navigator initialRouteName="Switch" mode="card">
        <Stack.Screen
          name="Switch"
          options={{
            title: "",
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.switchMode} white={false} />
            ),
            headerRight: () => icon
          }}
          component={
            userState.automatedMode ? ExitAutoModeScreen : ExitManualModeScreen
          }
        />
      </Stack.Navigator>
    </>
  );
};

export default SwitchModeStack;
