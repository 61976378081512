import React from 'react';
import { Moment } from 'moment';
import { useIntl } from 'react-intl';
import { messages } from './formattedTime.messages';

interface FormattedTimeProps {
  time: Moment;
}
export const FormattedTime: React.FC<FormattedTimeProps> = ({ time }) => {
  const intl = useIntl();
  let nightOrMorning = intl.formatMessage(messages.night);
  // 12:00 AM is considered night, so we have to add a special case for it.
  if (time.format('A') === 'AM' && time.format('h:mm') !== '12:00') {
    nightOrMorning = intl.formatMessage(messages.morning);
  }

  return <span>{`${time.format('h:mm A')} (${nightOrMorning})`}</span>;
}