import { FormattedMessage } from 'react-intl';
import { historyEventTempBasalMessages } from './HistoryEventTempBasal.messages';

interface HistoryEventTempBasalProps {
  time: string;
  status: string;
  value: string;
  percent: string;
}
export const HistoryEventTempBasal: React.FC<HistoryEventTempBasalProps> = ({
  time,
  status,
  value,
  percent,
}) => {
  return (
    <div className="flex justify-between items-center w-full py-1 px-2 border-b border-b-ios-gray-7">
      <div>
        <div className="flex">
          <p className="text-regular mr-0.5">
            <FormattedMessage {...historyEventTempBasalMessages.title} />
          </p>
          <p>{status}</p>
        </div>
        <p className="mr-0.5 text-small text-ios-secondaryGray opacity-60">{`${time} • ${value}`}</p>
      </div>
      <div>
        <p className="text-regular">{percent}</p>
      </div>
    </div>
  );
};
