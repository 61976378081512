export enum AppRoutesEnum {
  // Dashboard and Menu are both iOS specific, since Drawer covers both on Android.
  dashboard = 'Dashboard',
  menu = 'Menu',
  drawer = 'Drawer',
  txSetup = 'TXSetup',
  switchMode = 'SwitchMode',
  podSetup = 'PodSetup',
  generalSettings = 'GeneralSettings',
  basalFTS = 'BasalFTS',
  bolusFTS = 'BolusFTS',
  graph = 'Graph',
  pauseStartInsulin = 'PauseStartInsulin',
  glucoseGoalRange = 'GlucoseGoalRange',
  settingsReminders = 'SettingsReminders',
  History = 'History',
  SettingsBolus = 'SettingsBolus',
  SetTempBasal = 'SetTempBasal',
  Bolus = 'Bolus',
  Alerts = 'Alerts',
  CustomFoods = 'CustomFoods',
  BasalPrograms = 'BasalPrograms',
}
