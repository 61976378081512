let strings = {
  "en-US": {
    missedBolus: "Missed Bolus",
    bolusNotDelivered: "Meal bolus not delivered between",
    podExpiration: "Pod Expiration",
    podWillExpire: "Pod expires at %1, %2",
    filterModalTitle: "Filter By Date",
    filterModalMessage: "Not available for the simulator.",
    ok: "OK"
  },
  "en-GB": {
    missedBolus: "Missed Bolus",
    bolusNotDelivered: "Meal bolus not delivered between",
    podExpiration: "Pod Expiry",
    podWillExpire: "Pod expires at %1, %2",
    filterModalTitle: "Filter By Date",
    filterModalMessage: "Not available for the simulator.",
    ok: "OK"
  },
  "de-DE": {
    missedBolus: "Verpasster Bolus",
    bolusNotDelivered: "Kein Mahlzeitenbolus abgegeben zwischen",
    podExpiration: "Pod-Ende",
    podWillExpire: "Pod läuft ab am %1 um %2",
    filterModalTitle: "Nach Datum filtern",
    filterModalMessage: "Nicht für den Simulator verfügbar.",
    ok: "OK"
  },
  "es-US": {
    missedBolus: "Bolo omitido",
    bolusNotDelivered: "Bolo de comida no administrado entre",
    podExpiration: "Caducidad del Pod",
    podWillExpire: "El Pod caduca el %1, %2",
    filterModalTitle: "Filtrar por fecha",
    filterModalMessage: "No está disponible en el simulador.",
    ok: "Aceptar"
  },
  "fr-FR": {
    missedBolus: "Bolus oublié",
    bolusNotDelivered: "Aucun bolus repas administré entre",
    podExpiration: "Péremption du Pod",
    podWillExpire: "Le Pod sera périmé à %1, %2",
    filterModalTitle: "Filtrer par date",
    filterModalMessage: "Non disponible pour le simulateur.",
    ok: "OK"
  },
  "nl-NL": {
    missedBolus: "Gemiste bolus",
    bolusNotDelivered: "Maaltijdbolus niet toegediend tussen",
    podExpiration: "Uiterste gebruiksdatum Pod",
    podWillExpire: "Pod bruikbaar tot %1, %2",
    filterModalTitle: "Op datum filteren",
    filterModalMessage: "Niet beschikbaar voor de simulator.",
    ok: "OK"
  }
};

export { strings };
