let strings = {
  "en-US": {
    defaultTitle: "Communicating with Pod",
    pleaseWait: "Please wait..."
  },
  "en-GB": {
    defaultTitle: "Communicating with Pod",
    pleaseWait: "Please wait..."
  },
  "de-DE": {
    defaultTitle: "Kommunikation mit dem Pod",
    pleaseWait: "Bitte warten..."
  },
  "es-US": {
    defaultTitle: "Comunicando con el Pod",
    pleaseWait: "Espere..."
  },
  "fr-FR": {
    defaultTitle: "Communication avec le Pod",
    pleaseWait: "Veuillez patienter..."
  },
  "nl-NL": {
    defaultTitle: "Communicatie met Pod",
    pleaseWait: "Even wachten..."
  }
};

export { strings };
