import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { NavigationContainer } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { Dimensions, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { AutoEventScreen, SummaryScreen } from "../../components/screens";
import useLanguageContent from "../../hooks/useLanguageContent";
import { LangState } from "../../../../web/store/reducers/language";
import { RootState } from "../../../../web/store/store";
import theme from "../../theme";
import { strings } from "./HistoryTabStackContent";

const Tab = createMaterialTopTabNavigator();

const { height } = Dimensions.get("window");

interface Props {}

const HistoryTabStack: React.FC<Props> = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { languageContent } = useLanguageContent(strings);

  const TabTheme = {
    dark: false,
    colors: {
      primary: theme.colors.blue,
      background: theme.colors.tabBackground,
      card: theme.colors.white
    },
    shadowColor: "#888",
    shadowOpacity: 0.4,
    shadowRadius: 3,
    shadowOffset: {
      height: 3,
      width: 0
    },
    elevation: 8
  };

  return (
    <>
      <NavigationContainer independent theme={TabTheme as any}>
        <Tab.Navigator
          initialRouteName={languageContent.summary}
          tabBarOptions={{
            activeTintColor: theme.colors.blue,
            inactiveTintColor: theme.colors.grayScale.gray200,
            labelStyle: {
              fontSize: height <= 600 ? 16 : 18,
              fontWeight: "400",
              alignSelf: "center",
              width: "100%",
              padding: 0
            },
            style: {
              shadowColor: "#888",
              shadowOpacity: 0.4,
              shadowRadius: 3,
              shadowOffset: {
                height: 3,
                width: 0
              },
              elevation: 8
            }
          }}
          tabBarPosition="top">
          <Tab.Screen
            name={languageContent.summary}
            component={SummaryScreen}
            listeners={{
              focus: () => {}
            }}
            options={{
              tabBarLabel: ({ focused }) => (
                <View
                  style={{
                    width: "100%",
                    alignItems: "center"
                  }}>
                  <Text
                    style={{
                      color: focused
                        ? theme.colors.blue
                        : theme.colors.grayScale.gray200,
                      textAlign: "center",
                      fontSize: 18,
                      fontWeight: "400"
                    }}>
                    {languageContent.summary.toUpperCase()}
                  </Text>
                </View>
              )
            }}
          />
          <Tab.Screen
            name={languageContent.autoEvents}
            component={AutoEventScreen}
            listeners={{
              focus: () => {}
            }}
          />
        </Tab.Navigator>
      </NavigationContainer>
    </>
  );
};

export default HistoryTabStack;
