import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  topBarGeneralTitle: {
    id: 'txid.top-bar.general-title',
    defaultMessage: 'General',
  },
  topBarTimezoneTitle: {
    id: 'txid.top-bar.timezone-title',
    defaultMessage: 'Timezone',
  },
  topBarCheckAlarmsTitle: {
    id: 'txid.top-bar.check-alarms-title',
    defaultMessage: 'Check Alarms',
  },
  backTitle: {
    id: 'txid.back-title',
    defaultMessage: 'Back',
  },
  closeTitle: {
    id: 'txid.close-title',
    defaultMessage: 'Close',
  },
  cancelTitle: {
    id: 'txid.cancel-title',
    defaultMessage: 'Cancel',
  },
  insulinDeliveryTimeZoneTitle: {
    id: 'txid.insulin-delivery-timezone-title',
    defaultMessage: 'Insulin Delivery Time Zone',
  },
  insulinDeliveryTimeZoneInputTitle: {
    id: 'txid.insulin-delivery-timezone-title',
    defaultMessage: 'Insulin Delivery{separator} Time Zone',
  },
  insulinDeliveryTimeZoneMessage: {
    id: 'txid.insulin-delivery-timezone-message',
    defaultMessage:
      'New York (7:30 PM). Your Pod delivers insulin according to this time zone',
  },
  checkAlarmsTitle: {
    id: 'txid.check-alarms-title',
    defaultMessage: 'Check Alarms',
  },
  checkAlarmsMessage: {
    id: 'txid.check-alarms-message',
    defaultMessage: 'Check Omnipod 5 App and Pod Alarms',
  },
  yourPodDeliversMessage: {
    id: 'txid.your-pod-delivers-message',
    defaultMessage: 'Your Pod delivers insulin according to this time zone:',
  },
  pauseInsulinButtonTitle: {
    id: 'txid.pause-insulin-title',
    defaultMessage: 'Pause Insulin',
  },
  saveButtonTitle: {
    id: 'txid.save-title',
    defaultMessage: 'Save',
  },
  newYorkTimezoneTitle: {
    id: 'txid.new-york-timezone-title',
    defaultMessage: 'New York (GMT -5)',
  },
  youPauseInsulinMessage: {
    id: 'txid.you-pause-insulin-message',
    defaultMessage: 'You must pause insulin delivery to change time settings.',
  },
  checkOmnipodAppMessage: {
    id: 'txid.check-omnipod-app-message',
    defaultMessage:
      'Check Omnipod 5 App and Pod alarms to ensure they are working properly.',
  },
  checkAlarmsButtonTitle: {
    id: 'txid.check-alarms-title',
    defaultMessage: 'Check Alarms',
  },
  appFailedBeepMessage: {
    id: 'txid.app-failed-beep-message',
    defaultMessage:
      'If your Omnipod 5 App failed to beep or vibrate, contact Customer Care immediately. {separator} 1-800-591-3455 or localized number immediately.',
  },
  activatedPodFailedBeepMessage: {
    id: 'txid.activated-pod-failed-beep-message',
    defaultMessage:
      'If an activated Pod failed to beep, change the Pod immediately.',
  },
  checkAlarmsAgainButtonTitle: {
    id: 'txid.check-alarms-again-button-title',
    defaultMessage: 'Check Alarms Again',
  },
});
