import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../SettingsScreenStack.messages';
import { Button } from 'web/components/ios/Button';
import { useDispatch } from 'react-redux';
import {
  showModal,
  setCurrentModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import {
  DidBothAlarmsWorkConfirmationModal,
  CheckAlarmsProgressConfirmationModal,
  StartInsulinConfirmationModal,
} from 'web/components/ios/modals/GeneralSettingsModals/GeneralSettingsModal';
import { useNavigation } from '@react-navigation/native';
import { IOSRoutesEnums } from '../../FtsStartScreenStack/types';
import { CommunicatingWithPodConfirmationModal } from 'web/components/ios/modals/PauseStartInsulinModals/PauseStartInsulinModals';

export const CheckAlarmsScreen = () => {
  const dispatch = useDispatch();
  const [isAlarmError, setIsAlarmError] = useState(false);
  const { navigate } = useNavigation();

  const handleShowCommunicatingWithPodConfirmationModal = () => {
    setIsAlarmError(false);
    dispatch(
      setCurrentModal({
        modal: (
          <CommunicatingWithPodConfirmationModal
            onClose={() => {
              navigate(IOSRoutesEnums.General);
              dispatch(hideModal());
            }}
          />
        ),
      })
    );

    dispatch(showModal());
  };

  const handleDidBothAlarmsWork = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <DidBothAlarmsWorkConfirmationModal
            onReject={() => {
              setIsAlarmError(true);
              dispatch(hideModal());
            }}
            onConfirm={handleStartInsulinAlarmsProgress}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  const handleCheckAlarmsProgress = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <CheckAlarmsProgressConfirmationModal
            onClose={handleDidBothAlarmsWork}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  const handleStartInsulinAlarmsProgress = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <StartInsulinConfirmationModal
            onConfirm={handleShowCommunicatingWithPodConfirmationModal}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="flex justify-between flex-col flex-1 items-stretch bg-white">
      {isAlarmError ? (
        <div className="flex flex-col">
          <div className="mt-2 px-2">
            <div className="text-ios-red">
              <FormattedMessage
                {...messages.appFailedBeepMessage}
                values={{
                  separator: <div className="my-1" />,
                }}
              />
            </div>
            <div className="text-ios-red mt-2">
              <FormattedMessage {...messages.activatedPodFailedBeepMessage} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="mt-2 px-2">
            <div className="text-black">
              <FormattedMessage {...messages.checkOmnipodAppMessage} />
            </div>
          </div>
        </div>
      )}

      <div className="w-full mb-4 px-2">
        <Button
          className="w-full rounded-lg bg-modes-manual"
          onClick={handleCheckAlarmsProgress}
          variant="colored"
        >
          {isAlarmError ? (
            <FormattedMessage {...messages.checkAlarmsAgainButtonTitle} />
          ) : (
            <FormattedMessage {...messages.checkAlarmsButtonTitle} />
          )}
        </Button>
      </div>
    </div>
  );
};
