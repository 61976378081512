let strings = {
  "en-US": {
    start: "Start",
    edit: "Edit",
    delete: "Delete"
  },
  "en-GB": {
    start: "Start",
    edit: "Edit",
    delete: "Delete"
  },
  "de-DE": {
    start: "Start",
    edit: "Bearbeiten",
    delete: "Löschen"
  },
  "es-US": {
    start: "Iniciar",
    edit: "Editar",
    delete: "Eliminar"
  },
  "fr-FR": {
    start: "Démarrer",
    edit: "Modifier",
    delete: "Supprimer"
  },
  "nl-NL": {
    start: "Start",
    edit: "Bewerken",
    delete: "Verwijderen"
  }
};

export { strings };
