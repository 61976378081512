import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const CarbsIcon: React.FC<IconProps> = ({
  color,
  width = '13',
  height = '14',
}) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0888 13.5H10.0883C9.73444 13.5 9.34593 13.1918 9.34593 12.6875V8.08333C9.34593 8.08333 8.76588 8.08171 8.12496 8.08333C8.52189 4.29383 9.6607 0.5 10.4313 0.5C10.6525 0.5 10.8292 0.693375 10.8307 0.960958C10.8356 1.71658 10.8322 9.95913 10.8312 12.6875C10.8312 13.136 10.4986 13.5 10.0888 13.5ZM3.50763 6.76708C3.50763 6.39279 3.27308 6.27038 2.76173 5.86088C2.38417 5.55754 2.12417 5.08467 2.17238 4.60313C2.30888 3.22892 2.63931 0.5 2.63931 0.5H2.96432V3.75H3.50763L3.62842 0.5H3.95723L4.05094 3.75H4.59425L4.72533 0.5H5.02976L5.13755 3.75H5.68086V0.5H5.97825C5.97825 0.5 6.34334 3.21104 6.49339 4.58958C6.54648 5.07817 6.27184 5.56025 5.88562 5.86575C5.37156 6.27254 5.13755 6.38954 5.13755 6.76979V12.6875C5.13755 13.136 4.773 13.5 4.32232 13.5C3.87218 13.5 3.50763 13.136 3.50763 12.6875V6.76708Z"
        fill="#3C3C43"
        fill-opacity="0.6"
      />
    </svg>
  );
};
