let strings = {
  'en-US': {
    clearData: 'Clear all data',
    reset: 'To reset the Omnipod 5 app, you must first deactivate your Pod.',
    deactivatePod: 'Deactivate Pod',
  },
  'en-GB': {
    clearData: 'Clear all data',
    reset: 'To reset the Omnipod 5 app, you must first deactivate your Pod.',
    deactivatePod: 'Deactivate Pod',
  },
  'es-US': {
    clearData: 'Borrar todos los datos.',
    reset:
      'Para reiniciar la aplicación Omnipod 5, primero debe desactivar el Pod.',
    deactivatePod: 'Desactivar Pod',
  },
  'de-DE': {
    clearData: 'Alle Daten löschen.',
    reset:
      'Wenn Sie die Omnipod 5-App zurücksetzen möchten, müssen Sie zuerst den Pod deaktivieren.',
    deactivatePod: 'Pod Deaktivieren',
  },
  'nl-NL': {
    clearData: 'Wis alle gegevens',
    reset: 'Om de Omnipod 5-app te resetten moet u eerst uw Pod deactiveren.',
    deactivatePod: 'Pod deactiveren',
  },
  'fr-FR': {
    clearData: 'Effacer toutes les données',
    reset:
      "Pour réinitialiser l'application Omnipod 5, désactivez d'abord votre Pod.",
    deactivatePod: 'Désactiver le Pod',
  },
};
export { strings };
