let strings = {
  "en-US": {
    podActivated:
      "Congratulations! You've sucessfully activated a Pod and paired your Sensor with the active Pod. You are now ready to experience the simplicty of automated insulin delivery."
  },
  "en-GB": {
    podActivated:
      "Congratulations! You've sucessfully activated a Pod and paired your Sensor with the active Pod. You are now ready to experience the simplicty of automated insulin delivery."
  },
  "de-DE": {
    podActivated:
      "Herzlichen Glückwunsch! Sie haben erfolgreich einen Pod aktiviert und Ihren Sensor mit dem aktiven Pod gekoppelt. Jetzt sind Sie bereit, die Einfachheit der automatisierten Insulin-Dosierung zu erleben."
  },
  "es-US": {
    podActivated:
      "¡Felicitaciones! Activó correctamente un Pod y emparejó el Sensor con el Pod activo. Ahora está listo para sentir la simplicidad de la administración de insulina automatizada."
  },
  "fr-FR": {
    podActivated:
      "Félicitations ! Vous avez activé un Pod et apparié votre capteur avec le Pod actif avec succès. Vous êtes désormais prêt(e) à faire l'expérience de la simplicité conférée par l'administration automatisée de l'insuline."
  },
  "nl-NL": {
    podActivated:
      "Gefeliciteerd! U hebt een Pod geactiveerd en uw sensor gekoppeld aan de actieve Pod. U bent nu klaar om de eenvoud van automatische insulinetoediening te ervaren."
  }
};

export { strings };
