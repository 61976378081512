import React, { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import { ChevronRight } from 'web/components/ios/icons/ChevronRight';

interface MenuItemProps {
  icon?: ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

export const MenuItem: React.FC<PropsWithChildren<MenuItemProps>> = ({
  icon,
  onClick,
  children,
  disabled = false,
}) => {
  const iconClasses = classNames('fill-ios-gray-7', {
    'opacity-30': disabled
  });

  const handleOnClick = () => {
    if (!disabled) {
      onClick();
    }
  }

  return (
    <div className="px-2 py-[12px] bg-white cursor-pointer flex flex-row items-center justify-between border-b-ios-secondaryGray/[.18] border-b-[0.5px]" onClick={handleOnClick}>
      <div className="flex flex-row items-center">
        {icon && <div className="mr-2">{icon}</div>}
        <div className={classNames({ 'opacity-30': disabled })}>{children}</div>
      </div>
      <div><ChevronRight color={iconClasses} /></div>
    </div>
  );
};
