import React from 'react';
// We are using a PNG, since the SVG built doesn't work correctly with web due to radial gradiant being used.
import SpinnerImage from 'assets/ios/spinner.png';

export const Spinner = () => {
  return (
    <div className="flex items-center justify-center mb-2">
      <img className="w-[20px] animate-spin" src={SpinnerImage} alt="Loading..." />
    </div>
  );
}