import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  Animated,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import BasalDefaultProgram from "../../../../../../../../assets/basal_default_program.png";
import BasalDefaultProgram24 from "../../../../../../../../assets/basal_default_program_24hr.png";
import BasalDefaultProgramComma24 from "../../../../../../../../assets/basal_default_program_24hr_comma.png";
import { ReactComponent as CheckBox } from "../../../../../../../../assets/ic_check_box.svg";
import { ReactComponent as HighlightRemove } from "../../../../../../../../assets/ic_highlight_remove.svg";
import { ReactComponent as Edit } from "../../../../../../../../assets/UI_Edit.svg";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import {
  basalActions,
  BasalState
} from "../../../../../../../../web/store/reducers/basal";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { UserState } from "../../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { ListHeader } from "../../../../../atoms";
import { BasalProgramEntry, BottomInnerNav } from "../../../../../molecules";
import { strings } from "./BasalProgramsScreenContent";

const BasalProgramsScreen: React.FC = () => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [editMenu, setEditMenu] = React.useState<boolean>(false);
  const highlightEditRef = React.useRef(new Animated.Value(0)).current;

  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);

  const interpolateColor = highlightEditRef.interpolate({
    inputRange: [0, 100],
    outputRange: [theme.colors.white, theme.colors.mango]
  });

  const editSegment = () => {
    setEditMenu(false);
    userState.isExpert &&
      dispatch(
        basalActions.basalUpdate(
          {
            customProgramThirdSegment: false
          },
          basalState
        )
      );

    navigate("CustomProgramEntry");
  };

  const editDropdownMenu = (
    <View style={[{ alignItems: "flex-end" }]}>
      <View
        style={{
          width: 135,
          marginRight: 8,
          zIndex: 20,
          elevation: 6,
          marginTop: -64,
          backgroundColor: theme.colors.white,
          shadowColor: "#888",
          shadowRadius: 3,
          shadowOffset: {
            width: 0,
            height: 6
          },
          shadowOpacity: 0.5
        }}>
        <TouchableOpacity
          onPress={() => {}}
          style={{
            flexDirection: "row",
            padding: 16,
            alignItems: "center"
          }}>
          <CheckBox />
          <Text
            style={[
              theme.fonts.body1,
              { color: theme.colors.grayScale.gray400, marginLeft: 12 }
            ]}>
            {languageContent.start}
          </Text>
        </TouchableOpacity>
        <View style={[styles.break]} />
        <TouchableOpacity
          onPress={editSegment}
          style={{
            flexDirection: "row",
            padding: 16,
            alignItems: "center"
          }}>
          <Edit />
          <Text
            style={[
              theme.fonts.body1,
              { color: theme.colors.grayScale.gray400, marginLeft: 8 }
            ]}>
            {languageContent.edit}
          </Text>
        </TouchableOpacity>
        <View style={[styles.break]} />
        <TouchableOpacity
          onPress={() => {}}
          style={{
            flexDirection: "row",
            padding: 16,
            alignItems: "center"
          }}>
          <HighlightRemove />
          <Text
            style={[
              theme.fonts.body1,
              { color: theme.colors.grayScale.gray400, marginLeft: 12 }
            ]}>
            {languageContent.delete}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  return (
    <TouchableOpacity
      style={[styles.container]}
      activeOpacity={1}
      onPress={() => setEditMenu(false)}>
      <View>
        <View
          style={[
            {
              justifyContent: "space-between",
              backgroundColor: theme.colors.white,
              margin: 16,
              zIndex: 20,
              elevation: 3,
              shadowColor: "#888",
              shadowRadius: 3,
              shadowOffset: {
                width: 0,
                height: 4
              },
              shadowOpacity: 0.5
            }
          ]}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}>
            <View>
              <Text style={theme.fonts.h4}>
                {basalState.defaultProgramName}
              </Text>
              <Text style={[theme.fonts.body1, { color: theme.colors.blue }]}>
                {languageContent.current}
              </Text>
            </View>
          </View>
          <Image
            style={{ width: "100%", height: 50 }}
            resizeMode="contain"
            source={
              langState.is24Hour
                ? langState.locale === "de-DE"
                  ? BasalDefaultProgramComma24
                  : BasalDefaultProgram24
                : BasalDefaultProgram
            }
          />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginVertical: 8
            }}>
            <Text
              style={[
                {
                  paddingHorizontal: 16,
                  marginVertical: 8
                },
                theme.fonts.body1
              ]}>
              {languageContent.totalUDay}
            </Text>
            <TouchableOpacity onPress={() => {}}>
              <Text
                style={[
                  theme.fonts.body1,
                  {
                    paddingHorizontal: 16,
                    marginVertical: 8,
                    textTransform: "uppercase",
                    color: theme.colors.purple,
                    fontWeight: "bold"
                  }
                ]}>
                {languageContent.edit}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <ListHeader label={languageContent.savedBasal} numOfSegments="2" />
        <BasalProgramEntry
          onPressModal={() => {}}
          onPressMenu={() => {}}
          programName={languageContent.weekend}
          unitsPerDay="22"
        />
        <View style={[styles.break]} />
        <BasalProgramEntry
          onPressModal={() => {}}
          onPressMenu={() => setEditMenu(true)}
          programName={languageContent.relax}
          unitsPerDay={basalState.customProgramThirdSegment ? "19" : "10"}
          customEntryStyle={{
            backgroundColor: interpolateColor,
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 16
          }}
        />
        <View style={[styles.break]} />
        {editMenu && editDropdownMenu}
      </View>
      <BottomInnerNav
        leftActionEmpty={true}
        rightActionText={languageContent.createNew}
        rightAction={() => {}}
        rightActionStyle={{ color: theme.colors.purple }}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "#fff"
  },
  segmentHeader: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.textInputBorder,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    backgroundColor: "#EDF2F6"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  }
});

export default BasalProgramsScreen;
