import { useNavigation } from '@react-navigation/native';
import { Button } from 'web/components/ios/Button';
import { IOSBasalRoutesEnums } from '../types';
import { useIntl } from 'react-intl';
import { messages, CreateBasalProgramIntroMessages } from '../Basal.messages';

export const CreateBasalProgramIntro = () => {
  const { navigate } = useNavigation();
  const intl = useIntl();
  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-2/5 p-6">
        <p className="font-bold text-2xl mb-1">
          {intl.formatMessage(CreateBasalProgramIntroMessages.title)}
        </p>
        <p className="font-normal text-sm">
          {intl.formatMessage(CreateBasalProgramIntroMessages.text)}
        </p>
      </div>
      <div className="basis-9/12">
        <div className=" w-full h-full p-6 flex flex-col-reverse">
          <Button
            onClick={() => navigate(IOSBasalRoutesEnums.CreateBasalProgram)}
            variant="colored"
          >
            {intl.formatMessage(messages.nextBtn)}
          </Button>
        </div>
      </div>
    </div>
  );
};
