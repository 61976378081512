import { useNavigation, useRoute } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BolusDisabled } from "../../../../../../../assets/bolus/bolus_button_disabled.svg";
import { ReactComponent as IobInfoPurple } from "../../../../../../../assets/iob_Info_purple.svg";
import { snackbarLocations, snackbarTypes } from "../../../../../context";
import { asyncTimeout } from "../../../../../helpers/asyncTimeout";
import { getUnitByLocale } from "../../../../../helpers/getUnitByLocale";
import replaceWithValues from "../../../../../helpers/replaceWithValues";
import { useCGM, useSnackbar } from "../../../../../hooks";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { bolusActions, BolusState } from "../../../../../../../web/store/reducers/bolus";
import { LangState } from "../../../../../../../web/store/reducers/language";
import {
  navigationActions,
  NavState
} from "../../../../../../../web/store/reducers/navigation";
import { UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import BolusParams from "../../../../../types/bolusParams";
import { ConfirmationModal } from "../../../../molecules";
import { strings } from "./DeliverBolusScreenContent";

const { height, width } = Dimensions.get("window");

const DeliverBolusScreen: React.FC = () => {
  //GLOBAL STATE
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  //LOCAL STATE
  const [cancelModal, setCancelModal] = React.useState<boolean>(false);

  // If we're in extended, it takes 10 seconds, otherwise it takes 3 seconds.

  const [progress, setProgress] = React.useState<boolean>(userState.isExpert);
  const [progressPercentage, setProgressPercentage] = React.useState<number>(0);

  //HOOKS
  const snackbar = useSnackbar();
  const { trendingIcon } = useCGM();
  const dispatch = useDispatch();
  const route = useRoute();
  const { total, extended } = route.params as BolusParams;
  const duration = extended ? 10000 : 3000;
  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  //FUNCTIONS
  const determineProgressFillWidth = () => {
    return {
      width: `${progressPercentage}%`
    };
  };

  const incrementProgress = () => setProgressPercentage(progressPercentage + 1);
  const startProgress = () => {
    if (progressPercentage >= 99) {
      setProgressPercentage(0);
    }
    setProgress(true);
  };

  const haltBolus = () => {
    setProgress(false);
    setCancelModal(true);
  };

  const makeProgress = async () => {
    // We figure out how long each percent should last and increment the bar after waiting that duration.
    const onePercentInTime = duration / 100;
    await asyncTimeout(onePercentInTime);
    incrementProgress();
  };

  const calculateCurrentUnits = () => {
    return Number(
      (
        Math.round(Number(total) * (progressPercentage / 100) * 20 - 0.05) / 20
      ).toFixed(2)
    ).toLocaleString(langState.locale, { minimumFractionDigits: 2 });
  };

  const totalBolusU = Number(total).toLocaleString(langState.locale);

  const contentVariables = [calculateCurrentUnits(), totalBolusU];

  //LIFECYCLE
  React.useEffect(() => {
    if (progressPercentage === 100) {
      const now = new Date();

      dispatch(
        bolusActions.bolusUpdate(
          {
            lastBolus: total,
            dateLastBolus: now.toString(),
            bolusTutorial: false,
            podInsulin: bolusState.podInsulin - Number(total)
          },
          bolusState
        )
      );

      dispatch(
        navigationActions.navigationUpdate(
          { navigateTo: "Dashboard" },
          navState
        )
      );
    }
    // This keeps calling makeProgress until it the bar is filled.
    if (progressPercentage < 100 && progress) {
      makeProgress();
    }
  }, [progress, progressPercentage, makeProgress()]);

  //RETURN
  return (
    <>
      <View style={[styles.innerWrapper, { backgroundColor: "#FFFFFF" }]}>
        <View style={[styles.darkArea]}>
          <View style={[styles.header]}>
            <Text style={[styles.whiteText, styles.headerText, theme.fonts.h3]}>
              {languageContent.deliverBolus}
            </Text>
          </View>
          <View style={[styles.statusBox]}>
            <View>
              <View>
                <Text style={[styles.whiteText, theme.fonts.body2]}>
                  {replaceWithValues(languageContent.approx, contentVariables)}
                </Text>
              </View>
              <View style={[styles.progressPercentage]}>
                <View style={[styles.progressPercentageBar]}>
                  <View
                    style={[
                      styles.progressPercentageFill,
                      determineProgressFillWidth()
                    ]}
                  />
                </View>
                <Text style={[styles.percentText, theme.fonts.body2]}>
                  ({progressPercentage}%)
                </Text>
              </View>
              <Text
                onPress={() => navigate("Drawer")}
                style={[
                  styles.whiteText,
                  theme.fonts.body1,
                  styles.cancelButton
                ]}>
                {languageContent.cancel}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.mainContentWrapper}>
          <View
            style={{
              flex: 2,
              flexDirection: "row",
              justifyContent: "center"
            }}>
            <View style={styles.bottomQuadContainer}>
              <Text
                style={[
                  theme.fonts.text400,
                  theme.fonts.textMedium,
                  {
                    marginVertical: 16,
                    marginBottom: 24
                  }
                ]}>
                {languageContent.insulinOnBoard}
              </Text>
              <View
                style={{
                  flexDirection: "column",
                  borderRadius: 100,
                  width: 125,
                  height: 125,
                  borderWidth: 5,
                  borderColor: theme.colors.purple,
                  justifyContent: "center"
                }}>
                <IobInfoPurple style={{ alignSelf: "center", margin: 0 }} />
                <Text style={[styles.bolusNumber]}>
                  {(0.15).toLocaleString(langState.locale)}
                </Text>
                <Text style={[styles.units, theme.fonts.body2]}>
                  {languageContent.units}
                </Text>
              </View>
            </View>

            <View style={[styles.divider]} />

            <View style={styles.bottomQuadContainer}>
              <View
                style={{
                  flexDirection: "column",
                  alignContent: "center"
                }}>
                <>
                  <Text
                    style={[
                      theme.fonts.text400,
                      {
                        marginHorizontal: 48,
                        marginVertical: 16,
                        marginBottom: 30,
                        whiteSpace: "nowrap"
                      }
                    ]}>
                    {languageContent.sensorInfo}
                  </Text>

                  <View
                    style={[
                      styles.bottomQuad,
                      {
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: -8,
                        marginLeft:
                          langState.language === "nl-NL" ||
                          langState.language === "es-US"
                            ? 60
                            : 32
                      }
                    ]}>
                    <View
                      style={{
                        flexDirection: "row",
                        height: 40,
                        marginTop: 12
                      }}>
                      <>
                        <Text style={[styles.bigNumber]}>
                          {getUnitByLocale(
                            bolusState.cgmInfo.Value,
                            langState.units
                          )}
                        </Text>
                        {trendingIcon}
                      </>
                    </View>
                    <View>
                      <Text style={[styles.units]}>{langState.units}</Text>
                    </View>
                  </View>
                  <TouchableOpacity>
                    <Text
                      style={{
                        color: theme.colors.purple,
                        fontWeight: "700",
                        textAlign: "center",
                        paddingTop: 12
                      }}>
                      {languageContent.viewGraph}
                    </Text>
                  </TouchableOpacity>
                </>
              </View>
            </View>
          </View>
          <View
            style={{
              flex: Platform.OS === "android" ? 0.5 : 0.6,
              justifyContent: "center",
              alignItems: "center",
              marginTop: -40
            }}>
            <View>
              <BolusDisabled style={styles.bolusFlowButton} />
            </View>
          </View>
        </View>
      </View>
      <ConfirmationModal
        isVisible={cancelModal}
        dismissText={languageContent.no}
        confirmText={languageContent.yes}
        onConfirm={() => {
          navigate("Drawer");
          snackbar.showSnackbar(
            snackbarTypes.success,
            languageContent.cancelModalSuccess,
            snackbarLocations.bottom
          );
        }}
        onDismiss={() => {
          setCancelModal(false);
          startProgress();
        }}
        title=""
        message={
          <Text style={[theme.fonts.body1]}>
            {languageContent.cancelModalMessage}
          </Text>
        }
      />
    </>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    height: "100%"
  },
  darkArea: {
    justifyContent: "center",
    backgroundColor: "#323232",
    paddingTop: height < 800 ? height / 40 : height / 20,
    flex: 1
  },
  whiteText: {
    color: theme.colors.white
  },
  header: { paddingVertical: height <= 600 ? "6%" : "10%" },
  headerText: {
    textAlign: "center",
    fontWeight: "bold"
  },
  statusBox: {
    backgroundColor: "#222222",
    marginBottom: height <= 600 ? "12%" : "20%",
    marginHorizontal: 16,
    paddingHorizontal: 16,
    paddingVertical: 24
  },
  cancelButton: {
    textTransform: "uppercase"
  },
  progressPercentage: {
    flexDirection: "row",
    marginVertical: 16
  },
  progressPercentageBar: {
    borderColor: "#eee",
    backgroundColor: "#eee",
    borderWidth: 1,
    width: "85%",
    height: 24
  },
  progressPercentageFill: {
    backgroundColor: "#53b022",
    height: "100%"
  },
  percentText: {
    height: "100%",
    paddingHorizontal: 8,
    color: theme.colors.grayScale.gray400
  },
  mainContentWrapper: {
    flex: 0.94,
    zIndex: 2,
    width: "100%",
    backgroundColor: theme.colors.white
  },
  bottomQuadContainer: {
    flex: 1,
    alignItems: "center",
    marginTop: "5%"
  },
  bottomQuad: {
    backgroundColor: "#f2f6f7",
    borderRadius: 3,
    minHeight: 8,
    width: 130,
    marginLeft: 32,
    justifyContent: "center"
  },
  bottomQuadCircle: {
    backgroundColor: "#f2f6f7",
    borderRadius: 75,
    minHeight: 135,
    minWidth: 135,
    justifyContent: "center",
    borderWidth: 10,
    borderColor: theme.colors.purple
  },
  bolusNumber: {
    textAlign: "center",
    textAlignVertical: "center",
    fontFamily: theme.fonts.primary.bold,
    fontWeight: "bold",
    fontSize: 42,
    color: theme.colors.placeholder
  },
  bolusDate: {
    fontFamily: theme.fonts.primary.medium,
    textAlignVertical: "center",
    textAlign: "center",
    paddingVertical: 8
  },
  bolusFlowButton: {
    width: 56,
    height: 56
  },
  bigNumber: {
    fontSize: 32,
    fontWeight: "700",
    color: theme.colors.purple,
    textAlign: "center",
    marginRight: -16,
    marginLeft: 12
  },
  units: {
    fontFamily: theme.fonts.primary.medium,
    textAlignVertical: "center",
    textAlign: "center",
    marginBottom: 12
  },
  divider: {
    width: 2,
    height: height < 800 ? "70%" : "80%",
    backgroundColor: "#d9e0e7",
    alignSelf: "center",
    top: -10
  }
});

export default DeliverBolusScreen;
