import PickerItem from "../types/pickerItem";

const BOLUS_SEG1_DATA: Array<PickerItem> = [
  {
    label: "110",
    value: 110,
    key: "110",
    index: 6
  },
  {
    label: "120",
    value: 120,
    key: "120",
    index: 5
  },
  {
    label: "130",
    value: 130,
    key: "130",
    index: 4
  },
  {
    label: "140",
    value: 140,
    key: "140",
    index: 3
  },
  {
    label: "150",
    value: 150,
    key: "150",
    index: 2
  }
];

export default BOLUS_SEG1_DATA;
