let strings = {
  'en-US': {
    clearData: 'Clear all data',
    reset: 'Reset',
    noActive: 'No active Pod',
    subtext:
      'Before you reset the Omnipod 5 App, make sure you have a written record of all of your Basal Programs, temp basal presets, and settings such as Target Glucose, Correction Factor, and IC Ratio.',
  },
  'en-GB': {
    clearData: 'Clear all data',
    reset: 'Reset',
    noActive: 'No active Pod',
    subtext:
      'Before you reset the Omnipod 5 App, make sure you have a written record of all of your Basal Programmes, temp basal presets, and settings such as Target Glucose, Correction Factor, and IC Ratio.',
  },
  'es-US': {
    clearData: 'Borrar todos los datos.',
    reset: 'Reiniciar',
    noActive: 'No hay ningún Pod activo',
    subtext:
      'Antes de reiniciar la aplicación Omnipod 5, asegúrese de tener un registro escrito de todos sus programas basales, preajustes de basal temporal y ajustes como la glucoso objetivo, el factor de corrección y la proporción de IC.',
  },
  'de-DE': {
    clearData: 'Alle Daten löschen.',
    reset: 'Zurücksetzen',
    noActive: 'Kein aktiver Pod',
    subtext:
      'Bevor Sie die Omnipod 5 App zurücksetzen, vergewissern Sie sich, dass Sie alle Ihre Basalratenprofile, Vorgabewerte für die temp. Basalrate und Einstellungen wie den Glukose-Zielwert, den Korrekturfaktor und das KI-Verhältnis schriftlich aufgezeichnet haben.',
  },
  'nl-NL': {
    clearData: 'Wis alle gegevens',
    reset: 'Resetten',
    noActive: 'Geen actieve pod',
    subtext:
      "Voordat u de Omnipod 5-app reset, moet u ervoor zorgen dat u een schriftelijk overzicht hebt van al uw basaalprogramma's, tijdelijke basaalinstellingen en instellingen zoals doelglucose, correctiefactor en IC-ratio.",
  },
  'fr-FR': {
    clearData: 'Effacer toutes les données',
    reset: 'Réinitialiser',
    noActive: 'Aucun Pod actif',
    subtext:
      "Avant de réinitialiser l'application Omnipod 5, assurez-vous de consigner par écritvos programmes de débit basal et les réglages tels que votre glucose cible, votre facteur de correction et votre rapport I\u2044G.",
  },
};
export { strings };
