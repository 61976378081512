let strings = {
  "en-US": {
    switchMode: "Switch Mode"
  },
  "en-GB": {
    switchMode: "Switch Mode"
  },
  "es-US": {
    switchMode: "Cambiar el modo"
  },
  "de-DE": {
    switchMode: "Modus umschalten"
  },
  "nl-NL": {
    switchMode: "Modus wijzigen"
  },
  "fr-FR": {
    switchMode: "Changer de mode"
  }
};

export { strings };
