let strings = {
  "en-US": {
    delivered: "Delivered: Increase %1 45% for 16 min",
    deliveredIncrease: "Delivered: Increase",
    percentPerHour: "45% for 16 min"
  },
  "en-GB": {
    delivered: "Delivered: Increase %1 45% for 16 min",
    deliveredIncrease: "Delivered: Increase",
    percentPerHour: "45% for 16 min"
  },
  "de-DE": {
    delivered: "Abgegeben: %1 für 16 Min. um 45 % erhöhen",
    deliveredIncrease: "Abgegeben: Erhöhung",
    percentPerHour: "45 % für 16 min"
  },
  "es-US": {
    delivered: "Administrado: Aumentar %1 en 45 % por 16 min",
    deliveredIncrease: "Administrado: Aumentar",
    percentPerHour: "45 % por 16 min"
  },
  "fr-FR": {
    delivered: "Administré : Augmentation de %1 45 % pour 16 min",
    deliveredIncrease: "Administré : Augmentation",
    percentPerHour: "de 45 % pour 16 min"
  },
  "nl-NL": {
    delivered: "Toegediend: Toename %1 45% gedurende 16 min",
    deliveredIncrease: "Toegediend: Verhogen",
    percentPerHour: "45% gedurende 16 min"
  }
};

export { strings };
