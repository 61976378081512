let strings = {
  "en-US": {
    lengthOfTime:
      "The length of time that insulin remains active and available in your body after a correction or meal bolus.",
    durationOf: "Duration of Insulin Action",
    hrs: "hrs",
    cancel: "Cancel",
    next: "Next",
    range: "(2 to 6 hrs)"
  },
  "en-GB": {
    lengthOfTime:
      "The length of time that insulin remains active and available in your body after a correction or meal bolus.",
    durationOf: "Duration of Insulin Action",
    hrs: "hrs",
    cancel: "Cancel",
    next: "Next",
    range: "(2 to 6 hrs)"
  },
  "de-DE": {
    lengthOfTime:
      "Die Zeitdauer, in der das Insulin nach einem Korrektur- oder Mahlzeitenbolus in Ihrem Körper aktiv und verfügbar bleibt.",
    durationOf: "Dauer der Insulinaktivität",
    hrs: "Stdn.",
    cancel: "Abbr.",
    next: "Weiter",
    range: "(2 bis 6 Std.)"
  },
  "es-US": {
    lengthOfTime:
      "La cantidad de tiempo que la insulina permanece activa y disponible en su cuerpo después de una corrección o bolo de comida.",
    durationOf: "Duración de la acción de la insulina",
    hrs: "h",
    cancel: "Cancelar",
    next: "Guardar",
    range: "(2 a 6 h)"
  },
  "fr-FR": {
    lengthOfTime:
      "Temps pendant lequel l'insuline reste active et disponible dans votre organisme après un bolus de correction ou un bolus repas.",
    durationOf: "Durée d’action de l’insuline",
    hrs: "h",
    cancel: "Annuler",
    next: "Suivant",
    range: "(2 à 6 h)"
  },
  "nl-NL": {
    lengthOfTime:
      "De tijd waarin insuline actief en beschikbaar blijft in het lichaam na een correctie of maaltijdbolus.",
    durationOf: "Duur van de insulineactie",
    hrs: "u.",
    cancel: "Annuleren",
    next: "Volgende",
    range: "(2 tot 6 u.)"
  }
};

export { strings };
