import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { FormattedMessage } from 'react-intl';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import theme from 'mobile/latest/theme';
import { Container } from 'web/components/helpers/Layout/Layout';
import { ManageSensorL2Paths } from 'web/routes/L2/ManageSensorL2Stack';
import { useSelector } from 'react-redux';
import {
  selectLowGlucoseSettings,
  selectHighGlucoseSettings,
  selectMissingSensorValuesSettings,
} from 'web/store/reducers/l2Settings/l2Settings.helpers';
import { messages } from './ManageSensorSettings.messages';
import { messages as lowGlucoseMessages } from 'web/components/pages/L2/LowGlucose/LowGlucose.messages';
import { messages as highGlucoseMessages } from 'web/components/pages/L2/HighGlucose/HighGlucose.messages';
import { messages as missingSensorMessages } from 'web/components/pages/L2/MissingSensor/MissingSensor.messages';
import { ReactComponent as ChevronRight } from 'assets/chevron-right.svg';

const styles = StyleSheet.create({
  title: {
    marginBottom: theme.layout.spacing.small,
    color: theme.colors.web.blue,
    padding: theme.layout.spacing.small,
  },
  settingRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.layout.spacing.normal,
    paddingHorizontal: theme.layout.spacing.small,
    paddingBottom: theme.layout.spacing.normal,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.web.lightGray,
  },
  settingTitle: {},
  settingValue: {
    color: theme.colors.web.lightBlue,
    marginTop: theme.layout.spacing.tiny,
  },
  settingCta: {
    color: theme.colors.web.lightBlue,
    display: 'flex',
    alignItems: 'center',
  },
});

export const ManageSensorSettings: React.FC = () => {
  const { navigate } = useNavigation();
  const lowGlucoseSettings = useSelector(selectLowGlucoseSettings);
  const highGlucoseSettings = useSelector(selectHighGlucoseSettings);
  const missingSensorValuesSettings = useSelector(
    selectMissingSensorValuesSettings
  );
  const handleLowGlucoseClick = () => navigate(ManageSensorL2Paths.lowGlucose);
  const handleHighGlucoseClick = () =>
    navigate(ManageSensorL2Paths.highGlucose);
  const handleMissingSensorValuesClick = () =>
    navigate(ManageSensorL2Paths.missingSensorValues);

  return (
    <Container>
      <View>
        <Text style={[theme.fonts.text600, styles.title]}>
          <FormattedMessage {...messages.sensorAlertsTitle} />
        </Text>

        <View style={[styles.settingRow]}>
          <View>
            <Text style={[theme.fonts.text600, styles.settingTitle]}>
              <FormattedMessage {...lowGlucoseMessages.lowGlucoseTitle} />
            </Text>
            {lowGlucoseSettings.value !== '--' && (
              <Text style={[theme.fonts.text600, styles.settingValue]}>
                <FormattedMessage
                  {...messages.belowText}
                  values={{ value: lowGlucoseSettings.value }}
                />
              </Text>
            )}
          </View>
          <View>
            <TouchableOpacity onPress={handleLowGlucoseClick}>
              <Text style={[theme.fonts.text600, styles.settingCta]}>
                {lowGlucoseSettings.alertOn ? (
                  <FormattedMessage {...messages.on} />
                ) : (
                  <FormattedMessage {...messages.off} />
                )}
                <ChevronRight />
              </Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={[styles.settingRow]}>
          <View>
            <Text style={[theme.fonts.text600, styles.settingTitle]}>
              <FormattedMessage {...highGlucoseMessages.highGlucoseTitle} />
            </Text>
            {highGlucoseSettings.value !== '--' && (
              <Text style={[theme.fonts.text600, styles.settingValue]}>
                <FormattedMessage
                  {...messages.aboveText}
                  values={{ value: highGlucoseSettings.value }}
                />
              </Text>
            )}
          </View>
          <View>
            <TouchableOpacity onPress={handleHighGlucoseClick}>
              <Text style={[theme.fonts.text600, styles.settingCta]}>
                {highGlucoseSettings.alertOn ? (
                  <FormattedMessage {...messages.on} />
                ) : (
                  <FormattedMessage {...messages.off} />
                )}
                <ChevronRight />
              </Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={[styles.settingRow]}>
          <View>
            <Text style={[theme.fonts.text600]}>
              <FormattedMessage {...missingSensorMessages.missingSensorTitle} />
            </Text>
          </View>
          <View>
            <TouchableOpacity onPress={handleMissingSensorValuesClick}>
              <Text style={[theme.fonts.text600, styles.settingCta]}>
                {missingSensorValuesSettings.alertOn ? (
                  <FormattedMessage {...messages.on} />
                ) : (
                  <FormattedMessage {...messages.off} />
                )}
                <ChevronRight />
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Container>
  );
};
