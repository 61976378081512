import React from "react"
import { StyleSheet, Text, View } from "react-native-web"
import BGTargetGray from "../../../../../assets/history/bg_target_gray.svg"
import BolusGray from "../../../../../assets/history/bolus_gray.svg"
import CarbsGray from "../../../../../assets/history/carbs_gray.svg"
import theme from "../../../theme"
import MultiDayHistoryItemInterface from "../../../types/multiDayHistoryItemInterface"

interface MultiDayHistoryRowProps {
  item: MultiDayHistoryItemInterface
}
const MultiDayHistoryRowItem: React.FC<MultiDayHistoryRowProps> = ({
  item,
}) => {
  return (
    <View style={styles.outerContainer}>
      <View style={[styles.header]}>
        <View style={{ width: "18%" }}>
          <Text style={styles.dateStyle}>{item.date}</Text>
        </View>
        <View style={styles.innerContainer}>
          <BGTargetGray />
          <Text style={styles.text}>{item.cgmInRange}</Text>
        </View>
        <View style={styles.innerContainer}>
          <BolusGray />
          <Text style={styles.text}>{item.totalInsulin}</Text>
        </View>
        <View style={styles.innerContainer}>
          <CarbsGray />
          <Text style={styles.text}>{item.totalCarbs}</Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  outerContainer: {
    height: 60,
    justifyContent: "center",
  },
  header: {
    padding: 20,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 1,
    borderBottomColor: theme.colors.textInputBorder,
    borderBottomWidth: 1,
  },
  innerContainer: {
    width: "20%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: 24,
  },
  dateStyle: {
    fontSize: 14,
    textAlign: "left",
    color: theme.colors.graphBlue,
  },
  text: {
    paddingLeft: 8,
    ...theme.fonts.body2,
  },
})

export default MultiDayHistoryRowItem
