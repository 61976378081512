import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { hideModal } from 'web/store/reducers/modals/modalsSlice';
import { Modal } from '../../Modal';
import { confirmActivityMessages, cancelActivityMessages } from './ActivityModals.messages';
import { PodCommunicationModal } from '../GlobalModals/GlobalModals';
import { cancelActivityMode } from 'web/store/reducers/user/actions';

interface ConfirmActivityModalProps {
  hours: number;
  handlePodCommTimeout: () => void;
}

export const ConfirmActivityModal: React.FC<ConfirmActivityModalProps> = ({
  hours,
  handlePodCommTimeout,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showPodComm, setShowPodComm] = useState<boolean>(false);

  if (showPodComm) {
    return <PodCommunicationModal onTimeout={handlePodCommTimeout} />;
  }

  return (
    <Modal
      rightButtonText={intl.formatMessage(confirmActivityMessages.rightButton)}
      rightButtonOnClick={() => setShowPodComm(true)}
      leftButtonText={intl.formatMessage(confirmActivityMessages.leftButton)}
      leftButtonOnClick={() => dispatch(hideModal())}
      title={intl.formatMessage(confirmActivityMessages.text, { hours })}
    />
  );
};

interface CancelActivityModalProps {
  handlePodCommTimeout?: () => void;
}
export const CancelActivityModal: React.FC<CancelActivityModalProps> = ({
  handlePodCommTimeout,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showPodComm, setShowPodComm] = useState<boolean>(false);

  const onPodCommTimeout = () => {
    dispatch(cancelActivityMode());
    if (handlePodCommTimeout) {
      handlePodCommTimeout();
    }
    dispatch(hideModal());
  }

  if (showPodComm) {
    return <PodCommunicationModal onTimeout={onPodCommTimeout} />;
  }

  return (
    <Modal
      rightButtonText={intl.formatMessage(cancelActivityMessages.rightButton)}
      rightButtonOnClick={() => setShowPodComm(true)}
      leftButtonText={intl.formatMessage(cancelActivityMessages.leftButton)}
      leftButtonOnClick={() => dispatch(hideModal())}
      title={intl.formatMessage(cancelActivityMessages.text)}
    />
  );
};
