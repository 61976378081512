import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { fillPodMessages } from '../Pod.messages';
import { ListStep } from 'web/components/ios/ListStep';
import { Button } from 'web/components/ios/Button';
import { setCurrentModal, showModal } from 'web/store/reducers/modals/modalsSlice';
import { PodPrimingModal } from 'web/components/ios/modals/ChangePodModals/ChangePodModals';
import PodFillGif from 'assets/changePod.gif';
import { PodFTSRouteNames } from '../types';

export const FillPod: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { navigate } = useNavigation();

  const handlePodCommunicationOnClose = () => navigate(PodFTSRouteNames.podCheckCannula);

  const handleStartClick = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <PodPrimingModal onClose={handlePodCommunicationOnClose} />
        ),
      })
    );
    dispatch(showModal());
  };

  return (
    <div className="font-ios text-regular h-full bg-white p-2 pb-4 flex justify-between flex-col">
      <div>
        <ListStep index={1} text={intl.formatMessage(fillPodMessages.step1)} containerClasses="mb-3" />
        <ListStep index={2} text={intl.formatMessage(fillPodMessages.step2)} containerClasses="mb-[125px]" />
        <div className="flex justify-center">
          <img className="w-[235px]" src={PodFillGif} alt="Pod Filling" />
        </div>
      </div>
      <div>
        <Button full variant="colored" onClick={handleStartClick}>
          <FormattedMessage {...fillPodMessages.button} />
        </Button>
      </div>
    </div>
  );
};
