import { isEqual } from "lodash";
import React from "react";
import {
  Image,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View
} from "react-native";
import { useSelector } from "react-redux";
import { getUnitByLocale } from "../../../../../helpers/getUnitByLocale";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { BottomInnerNav } from "../../../../molecules";
import { strings } from "./EnterBGScreenContent";

const EnterBGScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const [isPressed, setIsPressed] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>("");

  const { languageContent } = useLanguageContent(strings);

  const getAdornmentColor = () => {
    if (value === "––") {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  const getUnits = (language: string) => {
    return {
      "en-US": "mg/dL",
      "es-US": "mg/dL",
      "de-DE": "mmol/L",
      "en-GB": "mmol/L",
      "nl-NL": "mmol/L"
    }[language];
  };

  return (
    <View style={[styles.container]}>
      <View style={{ flexDirection: "row" }}>
        <Image
          style={[styles.image]}
          resizeMode="contain"
          source={require("../../../../../../../assets/bolus_bg_icon.png")}
        />
        <Text style={[styles.headerText]}>{languageContent.enter}</Text>
      </View>
      <Pressable
        style={[theme.layout.inputContainerStyle, styles.pressable]}
        disabled={isPressed}
        onPress={() => {
          setValue("127");
          setIsPressed(true);
        }}>
        <TextInput
          editable={false}
          style={[theme.layout.inputStyle, styles.textInput]}
          selectionColor={theme.colors.blue}
          placeholder={"––"}
          placeholderTextColor={theme.colors.placeholder}
          value={
            value
              ? getUnitByLocale(Number(value), langState.units).toString()
              : ""
          }
        />
        <Text
          style={[
            theme.layout.adornment,
            {
              color: getAdornmentColor()
            }
          ]}>
          {getUnits(langState.language)}
        </Text>
      </Pressable>
      <View style={{ flex: 1 }}></View>
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.leftActionText}
        leftActionNavigateTo="Drawer"
        rightActionText={languageContent.rightActionText}
        rightActionStyle={
          isPressed
            ? [styles.actionStyle, { color: "#5B34B9" }]
            : styles.actionStyle
        }
        rightActionNavigateTo="Drawer"
        rightActionDisabled={!isPressed}
        leftActionStyle={[styles.actionStyle]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  headerText: {
    ...theme.fonts.h4,
    marginTop: 24
  },
  actionStyle: {
    color: theme.colors.text.cancel,
    fontSize: 16
  },
  image: {
    height: 24,
    width: 24,
    marginRight: 12,
    marginLeft: 12,
    marginTop: 24
  },
  container: {
    backgroundColor: "white",
    height: "100%"
  },
  pressable: {
    borderColor: theme.colors.textInputBorder,
    marginTop: 24,
    marginLeft: 12,
    width: "92%",
    flexDirection: "row"
  },
  textInput: {
    width: "100%",
    marginBottom: -2,
    borderColor: "black",
    color: "#20ac8c"
  }
});

export default EnterBGScreen;
