import { defineMessages } from 'react-intl';

export const messages = {
  sceneOne: defineMessages({
    title: {
      id: 'l2.how-to-scan.scene-one.title',
      defaultMessage: 'SCANNING WITH OMNIPOD 5 APP',
    },
    mainText: {
      id: 'l2.how-to-scan.scene-one.main-text',
      defaultMessage:
        'The Omnipod 5 App updates your sensor glucose values every 5 minutes<p>You will not need to scan again until you start a new Sensor.</p>',
    },
  }),
  sceneTwo: defineMessages({
    title: {
      id: 'l2.how-to-scan.scene-two.title',
      defaultMessage: 'STEP 1',
    },
    mainText: {
      id: 'l2.how-to-scan.scene-two.main-text',
      defaultMessage: 'Hold the back of your smartphone near the Sensor.',
    },
  }),
  sceneThree: defineMessages({
    title: {
      id: 'l2.how-to-scan.scene-three.title',
      defaultMessage: 'STEP 2',
    },
    mainText: {
      id: 'l2.how-to-scan.scene-three.main-text',
      defaultMessage: 'Wait until you get two tones or vibrations.',
    },
  }),
  sceneFour: defineMessages({
    title: {
      id: 'l2.how-to-scan.scene-four.title',
      defaultMessage: 'STEP 3',
    },
    mainText: {
      id: 'l2.how-to-scan.scene-four.main-text',
      defaultMessage:
        "To scan the Sensor, you may need to reposition your smartphone near your Sensor in order to locate your smartphone's NFC antenna.",
    },
  }),
};
