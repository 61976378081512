import { useNavigation } from '@react-navigation/native';
import { isEqual } from 'lodash';
import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import GraphKeyImageGerman from '../../../../../../../assets/graphKeyGerman.png';
import GraphKeyImage from '../../../../../../../assets/graphKeyScreen.png';
import GraphImageAutoMGDL from '../../../../../../../assets/graphScreenAuto.png';
import GraphImageAutoMGDL24 from '../../../../../../../assets/graphScreenAuto24.png';
import GraphImageAutoGermanMGDL24 from '../../../../../../../assets/graphScreenAutoGermanMGDL24.png';
import GraphImageAutoGermanMMOL24 from '../../../../../../../assets/graphScreenAutoGermanMMOL24.png';
import GraphImageAutoMMOL from '../../../../../../../assets/graphScreenAutoMMOL.png';
import GraphImageAutoMMOL24 from '../../../../../../../assets/graphScreenAutoMMOL24.png';
import GraphImageAutoSpanishMGDL from '../../../../../../../assets/graphScreenAutoSpanish.png';
import GraphImageAutoSpanishMGDL24 from '../../../../../../../assets/graphScreenAutoSpanishMGDL24.png';
import GraphImageAutoSpanishMMOL from '../../../../../../../assets/graphScreenAutoSpanishMMOL.png';
import GraphImageAutoSpanishMMOL24 from '../../../../../../../assets/graphScreenAutoSpanishMMOL24.png';
import {
  default as GraphImageManual,
  default as GraphImageManualMGDL,
} from '../../../../../../../assets/graphScreenManual.png';
import GraphImageManualMGDL24 from '../../../../../../../assets/graphScreenManual24.png';
import GraphImageManualGermanMGDL24 from '../../../../../../../assets/graphScreenManualGermanMGDL24.png';
import GraphImageManualGermanMMOL24 from '../../../../../../../assets/graphScreenManualGermanMMOL24.png';
import GraphImageManualMMOL from '../../../../../../../assets/graphScreenManualMMOL.png';
import GraphImageManualMMOL24 from '../../../../../../../assets/graphScreenManualMMOL24.png';
import GraphImageManualSpanishMGDL from '../../../../../../../assets/graphScreenManualSpanishMGDL.png';
import GraphImageManualSpanishMGDL24 from '../../../../../../../assets/graphScreenManualSpanishMGDL24.png';
import GraphImageManualSpanishMMOL from '../../../../../../../assets/graphScreenManualSpanishMMOL.png';
import GraphImageManualSpanishMMOL24 from '../../../../../../../assets/graphScreenManualSpanishMMOL24.png';
import { LangState } from '../../../../../../../web/store/reducers/language';
import { RootState } from '../../../../../../../web/store/store';

const GraphScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { goBack } = useNavigation();
  const userState = useSelector((state: RootState) => state.user, isEqual);
  const [graphLegend, setGraphLegend] = React.useState(false);

  const getGraphImage = () => {
    if (graphLegend) {
      if (langState.locale === 'de-DE') {
        return GraphKeyImageGerman;
      }
      return GraphKeyImage;
    }

    const graphFilter: {
      [key: string]: {
        [mode: number]: {
          [is24Hour: number]: {
            [unit: string]: any;
          };
        };
      };
    } = {
      'en-US': {
        0: {
          //automated
          0: {
            'mmol/L': GraphImageAutoMMOL24,
            'mg/dL': GraphImageAutoMGDL24,
          }, //24hr
          1: {
            'mmol/L': GraphImageAutoMMOL,
            'mg/dL': GraphImageAutoMGDL,
          }, //12hr
        },
        1: {
          //manual
          0: {
            'mmol/L': GraphImageManualMMOL24,
            'mg/dL': GraphImageManualMGDL24,
          }, //24hr
          1: {
            'mmol/L': GraphImageManualMMOL,
            'mg/dL': GraphImageManualMGDL,
          }, //12hr
        },
      },
      'en-GB': {
        0: {
          //automated
          0: {
            'mmol/L': GraphImageAutoMMOL24,
            'mg/dL': GraphImageAutoMGDL24,
          }, //24hr
          1: {
            'mmol/L': GraphImageAutoMMOL,
            'mg/dL': GraphImageAutoMGDL,
          }, //12hr
        },
        1: {
          //manual
          0: {
            'mmol/L': GraphImageManualMMOL24,
            'mg/dL': GraphImageAutoMMOL24,
          }, //24hr
          1: {
            'mmol/L': GraphImageManualMMOL,
            'mg/dL': GraphImageManualMGDL,
          }, //12hr
        },
      },
      'de-DE': {
        0: {
          //automated
          0: {
            'mmol/L': GraphImageAutoGermanMMOL24,
            'mg/dL': GraphImageAutoGermanMGDL24,
          }, //24hr
          1: {
            'mmol/L': GraphImageAutoGermanMMOL24,
            'mg/dL': GraphImageAutoGermanMGDL24,
          },
        },
        1: {
          //manual
          0: {
            'mmol/L': GraphImageManualGermanMMOL24,
            'mg/dL': GraphImageManualGermanMGDL24,
          }, //24hr
          1: {
            'mmol/L': GraphImageManualGermanMMOL24,
            'mg/dL': GraphImageManualGermanMGDL24,
          },
        },
      },
      'es-US': {
        0: {
          //automated
          0: {
            'mmol/L': GraphImageAutoSpanishMMOL24,
            'mg/dL': GraphImageAutoSpanishMGDL24,
          }, //24hrgra
          1: {
            'mmol/L': GraphImageAutoSpanishMMOL,
            'mg/dL': GraphImageAutoSpanishMGDL,
          }, //12hr
        },
        1: {
          //manual
          0: {
            'mmol/L': GraphImageManualSpanishMMOL24,
            'mg/dL': GraphImageManualSpanishMGDL24,
          }, //24hr
          1: {
            'mmol/L': GraphImageManualSpanishMMOL,
            'mg/dL': GraphImageManualSpanishMGDL,
          }, //12hr
        },
      },
      'nl-NL': {
        0: {
          //automated
          0: {
            'mmol/L': GraphImageAutoMMOL24,
            'mg/dL': GraphImageAutoMGDL24,
          }, //24hr
          1: {
            'mmol/L': GraphImageAutoMMOL,
            'mg/dL': GraphImageAutoMGDL,
          }, //12hr
        },
        1: {
          //manual
          0: {
            'mmol/L': GraphImageManualMMOL24,
            'mg/dL': GraphImageManualMGDL24,
          }, //24hr
          1: {
            'mmol/L': GraphImageManualMMOL,
            'mg/dL': GraphImageManualMGDL,
          }, //12hr
        },
      },
      'fr-FR': {
        0: {
          //automated
          0: {
            'mmol/L': GraphImageAutoMMOL24,
            'mg/dL': GraphImageAutoMGDL24,
          }, //24hr
          1: {
            'mmol/L': GraphImageAutoMMOL,
            'mg/dL': GraphImageAutoMGDL,
          }, //12hr
        },
        1: {
          //manual
          0: {
            'mmol/L': GraphImageManualMMOL24,
            'mg/dL': GraphImageManualMGDL24,
          }, //24hr
          1: {
            'mmol/L': GraphImageManualMMOL,
            'mg/dL': GraphImageManualMGDL,
          }, //12hr
        },
      },
    };

    const locale = graphFilter[langState.locale];
    const mode = locale[Number(!userState.automatedMode)];
    const hours = mode[Number(!langState.is24Hour)];
    const image = hours[langState.units];

    return image;
  };

  const handleClose = () => {
    goBack();
  };

  return (
    <View
      style={[
        styles.graphWrapper,
        {
          width: getGraphImage() === GraphImageManual ? '1110px' : '1113px',
        },
      ]}
    >
      <Image
        style={{
          position: 'absolute',
          width: getGraphImage() === GraphImageManual ? '79.5%' : '77%',
          height: getGraphImage() === GraphImageManual ? '50%' : '47.5%',
          top: getGraphImage() === GraphImageManual ? '155px' : '165px',
          left: getGraphImage() === GraphImageManual ? '-283px' : '-279px',
          transform: [{ rotate: '90deg' }],
        }}
        source={getGraphImage() as any}
        resizeMode="contain"
        resizeMethod="resize"
      />
      {graphLegend ? (
        <View style={styles.closeGraphLegend}>
          <TouchableOpacity
            activeOpacity={1}
            style={[
              styles.innerButton,
              {
                top: '540px',
                left: '240px',
              },
            ]}
            onPress={() => setGraphLegend(false)}
          />
        </View>
      ) : (
        <>
          <View style={styles.closeGraphView}>
            <TouchableOpacity
              style={[
                styles.innerButton,
                {
                  top: '550px',
                  left: '250px',
                },
              ]}
              activeOpacity={1}
              onPress={() => {
                handleClose();
              }}
            />
          </View>
          <View style={[styles.timelineView]}>
            <TouchableOpacity
              activeOpacity={1}
              style={[
                styles.innerButton,
                {
                  top: '540px',
                  left: '5px',
                },
              ]}
              onPress={() => {
                setGraphLegend(true);
              }}
            />
          </View>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  graphWrapper: {
    flex: 1,
    backgroundColor: '#E0E0E0',
  },
  closeGraphView: {
    position: 'absolute',
    opacity: 0.5,
  },
  closeGraphLegend: {
    position: 'absolute',
    opacity: 0.5,
  },
  innerButton: {
    position: 'absolute',
    height: '50px',
    width: '50px',
  },
  timelineView: {
    opacity: 0.5,
    position: 'absolute',
  },
});

export default GraphScreen;
