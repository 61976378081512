import { isEqual } from "lodash"
import React from "react"
import { Dimensions, Text, View } from "react-native"
import { Card, Modal, Portal } from "react-native-paper"
import { Image } from "react-native-web"
import { useSelector } from "react-redux"
import YellowAlert from "../../../../../assets/yellow_alert.png"
import useLanguageContent from "../../../hooks/useLanguageContent"
import { LangState } from "../../../../../web/store/reducers/language"
import { RootState } from "../../../../../web/store/store"
import theme from "../../../theme"
import { BulletPointText, Button } from "../../atoms"
import { strings } from "./CheckBGModalContent"

const { width } = Dimensions.get("window")

interface Props {
  onDismiss: () => void
  onConfirm: () => void
  isVisible: boolean
}

const CheckBGModal: React.FC<Props> = ({ onDismiss, onConfirm, isVisible }) => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual,
  )
  const { languageContent } = useLanguageContent(strings)
  return (
    <Portal>
      <Modal visible={isVisible} onDismiss={onDismiss} dismissable={false}>
        <Card
          style={{
            margin: width < 400 ? 16 : 32,
            padding: 10,
            display: "flex",
            backgroundColor: "#FAFAFA",
            borderRadius: 0,
          }}
          elevation={5}
        >
          <Card.Content>
            <View
              style={{
                width: "100%",
                alignItems: "flex-end",
                marginTop: -16,
                marginBottom: 12,
              }}
            >
              <Text style={{ color: theme.colors.lightBlue }}>
                {`${new Date().toLocaleDateString(langState.locale, {
                  month: "short",
                  day: "numeric",
                })} ${new Date().toLocaleTimeString(langState.locale)}`}
              </Text>
            </View>
            <Text
              style={[
                theme.fonts.h3,
                {
                  color: theme.colors.grayScale.gray100,
                  textAlign: "center",
                  fontWeight: "bold",
                },
              ]}
            >
              {languageContent.checkBG}
            </Text>
            <Image
              style={{
                height: 60,
                alignSelf: "center",
                marginTop: 24,
              }}
              resizeMode="contain"
              source={YellowAlert}
            />
            <View>
              <View
                style={{
                  borderBottomColor: theme.colors.tabBackground,
                  borderBottomWidth: 1,
                  marginTop: 24,
                }}
              />
              <Text style={[theme.fonts.body1, { marginTop: 16 }]}>
                {languageContent.useAMeter}
              </Text>
              <BulletPointText bulletPointText={languageContent.ifLow} />
              <BulletPointText bulletPointText={languageContent.ifHigh} />
            </View>
          </Card.Content>
          <Card.Actions>
            <View
              style={{
                marginTop: 12,
                marginBottom: 4,
                width: "100%",
              }}
            >
              <Button
                variant="primary"
                style={{ elevation: 6 }}
                onPress={onConfirm}
              >
                {languageContent.next}
              </Button>
              <Button
                variant="default"
                style={{ elevation: 6, marginTop: 20 }}
                onPress={onDismiss}
              >
                {languageContent.back}
              </Button>
            </View>
          </Card.Actions>
        </Card>
      </Modal>
    </Portal>
  )
}

export default CheckBGModal
