import React from "react";
import { View } from "react-native";
import useLanguageContent from "../../../hooks/useLanguageContent";
import theme from "../../../theme";
import Button from "../../atoms/button";
import { strings } from "./FilterButtonContent";

interface FilterButtonProps {
  showModal: () => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({ showModal }) => {
  const { languageContent } = useLanguageContent(strings);
  return (
    <View style={{ margin: 16 }}>
      <Button
        variant="default"
        style={{
          elevation: 6,
          backgroundColor: theme.colors.textInputBackgroundColor
        }}
        onPress={() => {}}>
        {languageContent.filter}
      </Button>
    </View>
  );
};

export default FilterButton;
