import { useNavigation } from '@react-navigation/native';
import backgroundImage from 'assets/ios/setup-intro-bg-bolus.png';
import bolusIcon from 'assets/ios/ios-fts-bolus-icon.svg';
import { Button } from 'web/components/ios/Button';
import { IOSBolusRoutesEnums } from 'web/containers/ios/Bolus/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages, IntroBolusMessages } from '../Bolus.messages';

export const IntroBolus = () => {
  const { navigate } = useNavigation();
  const intl = useIntl();

  return (
    <div className="flex border-t-1 flex-col w-full h-full bg-white">
      <div className="basis-2/5 pt-8 px-6">
        <div className="flex items-center justify-center border-0.5 border-ios-gray-7 mb-5 w-12 h-12 rounded-full">
          <div
            className="bg-cover w-5 h-5 "
            style={{ backgroundImage: `url(${bolusIcon})` }}
          ></div>
        </div>
        <h1 className="text-4xl font-bold mb-2">
          <FormattedMessage
            {...IntroBolusMessages.headerTitle}
            values={{
              highlight: (chunk) => (
                <span className="text-modes-automated">{chunk}</span>
              ),
            }}
          />
        </h1>
        <p className="text-regular">
          {intl.formatMessage(IntroBolusMessages.text)}
        </p>
      </div>
      <div className="basis-9/12">
        <div
          className="bg-cover w-full h-full p-6 flex flex-col-reverse"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <Button
            className=""
            onClick={() =>
              navigate(
                IOSBolusRoutesEnums.TargetGlucoseAndCorrectAboveValuesIntro
              )
            }
            variant="colored"
          >
            {intl.formatMessage(messages.nextBtn)}
          </Button>
        </div>
      </div>
    </div>
  );
};
