import React from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../../../context";
interface IRoutes {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<IRoutes> = ({ children }) => {
  const authorized = React.useContext(AuthContext);

  return authorized ? children : <Navigate to="/signin" />;
};

export default ProtectedRoute;
