import { defineMessages } from 'react-intl';

export const checkCannulaMessages = defineMessages({
  title: {
    id: 'ios.modals.check-cannula.title',
    defaultMessage: 'Check the Cannula',
  },
  text: {
    id: 'ios.modals.check-cannula.text',
    defaultMessage:
      'If the cannula sticks out beyond the adhesive backing, you cannot use this Pod. Tap CANCEL.',
  },
  button: {
    id: 'ios.modals.check-cannula.button',
    defaultMessage: 'OK',
  },
});

export const checkPodMessages = defineMessages({
  title: {
    id: 'ios.modals.check-pod.title',
    defaultMessage: 'Check the Pod',
  },
  text: {
    id: 'ios.modals.check-pod.text',
    defaultMessage:
      'If your Pod is damaged or dirty, or if the adhesive is wet or folded, tap CANCEL.',
  },
  button: {
    id: 'ios.modals.check-pod.button',
    defaultMessage: 'OK',
  },
});

export const podPrimingMessages = defineMessages({
  title: {
    id: 'ios.modals.pod-priming.title',
    defaultMessage: 'Communicating with Pod',
  },
  text: {
    id: 'ios.modals.pod-priming.text',
    defaultMessage: 'Priming. Please wait...',
  },
});

export const podSecureConfirmationMessages = defineMessages({
  title: {
    id: 'ios.modals.pod-secure.title',
    defaultMessage: 'Is Pod securely in place?',
  },
  text: {
    id: 'ios.modals.pod-secure.text',
    defaultMessage:
      'If the Pod is securely attached to your body, tap "Yes" to insert the cannula.',
  },
  confirmButton: {
    id: 'ios.modals.pod-secure.confirm-button',
    defaultMessage: 'Yes',
  },
  rejectButton: {
    id: 'ios.modals.pod-secure.reject-button',
    defaultMessage: 'No',
  },
});

export const cannulaInsertionMessages = defineMessages({
  title: {
    id: 'ios.modals.cannula-insertion.title',
    defaultMessage: 'Communicating with Pod',
  },
  textFirst: {
    id: 'ios.modals.cannula-insertion.text-first',
    defaultMessage: 'Please wait...',
  },
  textSecond: {
    id: 'ios.modals.cannula-insertion.text-second',
    defaultMessage: 'Inserting the cannula',
  },
  confirmTitle: {
    id: 'ios.modals.cannula-insertion.confirm-title',
    defaultMessage: 'Is cannula properly inserted?',
  },
  confirmText: {
    id: 'ios.modals.cannula-insertion.confirm-text',
    defaultMessage:
      'A pink color at the top of the Pod confirms that the cannula is inserted properly.',
  },
  confirmButton: {
    id: 'ios.modals.cannula-insertion.confirm-button',
    defaultMessage: 'Yes',
  },
  rejectButton: {
    id: 'ios.modals.cannula-insertion.reject-button',
    defaultMessage: 'No',
  },
});

export const cancelPodSetupMessages = defineMessages({
  title: {
    id: 'ios.modals.cancel-pod-setup.title',
    defaultMessage: 'Cancel Pod setup?',
  },
  text: {
    id: 'ios.modals.cancel-pod-setup.text',
    defaultMessage:
      'If you cancel Pod setup, you will not be able to use this Pod. You will need to setup a new Pod. (This cannot be undone.)',
  },
  confirmButton: {
    id: 'ios.modals.cancel-pod-setup.confirm-button',
    defaultMessage: 'Yes',
  },
  rejectButton: {
    id: 'ios.modals.cancel-pod-setup.reject-button',
    defaultMessage: 'No',
  },
});

export const deactivatePodMessages = defineMessages({
  title: {
    id: 'ios.modals.deactivate-pod.title',
    defaultMessage: 'Deactivate your Pod?',
  },
  text: {
    id: 'ios.modals.deactivate-pod.text',
    defaultMessage:
      'If you deactivate this Pod, you will not be able to use it. You will need to set up a new Pod.',
  },
  confirmButton: {
    id: 'ios.modals.deactivate-pod.confirm-button',
    defaultMessage: 'Deactivate Pod',
  },
  rejectButton: {
    id: 'ios.modals.deactivate-pod.reject-button',
    defaultMessage: 'Cancel',
  },
  deactivationTitle: {
    id: 'ios.modals.deactivate-pod.deactivating-title',
    defaultMessage: 'Deactivating Pod',
  },
  deactivationText: {
    id: 'ios.modals.deactivate-pod.deactivating-text',
    defaultMessage: 'Please wait...',
  },
});

export const deactivateYourPodMessages = defineMessages({
  title: {
    id: 'ios.modals.deactivate-your-pod.title',
    defaultMessage: 'Deactivate your Pod?',
  },
  text: {
    id: 'ios.modals.deactivate-your-pod.change-your-pod.text',
    defaultMessage:
      'To change your Pod you must first deactivate the current Pod. Deactivating your Pod will stop insulin delivery.',
  },
  deactivatePodButton: {
    id: 'ios.modals.deactivate-your-pod.deactivate-pod.button',
    defaultMessage: 'Deactivate Pod',
  },
  cancelButton: {
    id: 'ios.modals.deactivate-your-pod.cancel.button',
    defaultMessage: 'Cancel',
  },
});
