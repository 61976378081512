import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  currentSensor: {
    id: 'L2.manage-sensor.current-sensor',
    defaultMessage: 'Current Sensor'
  },
  switchCta: {
    id: 'L2.manage-sensor.switch-cta',
    defaultMessage: 'Switch'
  },
  endsIn: {
    id: 'L2.manage-sensor.ends-in',
    defaultMessage: 'Ends In: 14 days (11:13 PM)'
  },
  serialNumber: {
    id: 'L2.manage-sensor.serial-number',
    defaultMessage: 'SN: 3MH000Z4ZMH'
  },
  deleteSensorDisclaimer: {
    id: 'L2.manage-sensor.delete-sensor-disclaimer',
    defaultMessage: 'To add a new Sensor, you must first delete this Sensor.'
  },
  sensorSettingsCta: {
    id: 'L2.manage-sensor.sensor-settings-cta',
    defaultMessage: 'SENSOR SETTINGS'
  },
  deleteSensorCta: {
    id: 'L2.manage-sensor.delete-sensor-cta',
    defaultMessage: 'DELETE SENSOR'
  },
  close: {
    id: 'L2.manage-sensor.close',
    defaultMessage: 'CLOSE'
  },
});