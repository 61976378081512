import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment/moment';
import {
  getCurrentSegment,
  selectNewProgram,
  selectBasalProgram,
} from 'web/store/reducers/basalPrograms/basalPrograms.helpers';
import { Button } from 'web/components/ios/Button';
import { FormattedTime } from 'web/components/helpers/FormattedTime/FormattedTime';
import {
  addNewSegment,
  clearNewProgram,
  updateNewProgram,
  updateProgramSegment,
} from 'web/store/reducers/basalPrograms/basalProgramsSlice';
import { relaxDayData } from 'web/store/reducers/basalPrograms/basalProgramsSlice';
import { RootState } from 'web/store/store';
import {
  CancelEditBasalProgramModal,
  StartActiveBasalProgramModal,
  BackToPreviousScreenModal,
} from 'web/components/ios/modals/BasalProgramsModals/BasalProgramsModals';
import {
  showModal,
  setCurrentModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import { BasalSegmentImage } from '../../../components/BasalSegmentImage';
import {
  editSegmentMessages,
  confirmBasalProgramMessages,
} from '../../../BasalPrograms.messages';
import { BasalProgramsRoutesEnum } from '../../../types';

interface EditSegmentProps {
  route: { params: RouteParams };
}

interface RouteParams {
  segmentId: number;
  currentProgramId: number;
  isNew?: boolean;
  viewFromConfirm?: boolean;
}

export const EditSegment: React.FC<EditSegmentProps> = ({ route }) => {
  // @ts-ignore it complains about push, but it's a valid property from useNavigation.
  const { navigate, setParams, push, goBack } = useNavigation();
  const dispatch = useDispatch();

  const {
    segmentId,
    currentProgramId,
    isNew = false,
    viewFromConfirm = false,
  } = route.params;
  const selectedProgram = useSelector((state: RootState) =>
    selectBasalProgram(state, currentProgramId)
  );
  const newProgram = useSelector(selectNewProgram);

  const relaxDaySegment = relaxDayData.segments.find(
    (segment) => segment.id === segmentId
  );

  const [userInteraction, setUserInteraction] = useState<boolean>(false);

  const [programId, setProgramId] = useState<number>();
  const [programName, setProgramName] = useState<string>('');
  const [startTime, setStartTime] = useState<number>();
  const [endTime, setEndTime] = useState<number>();
  const [units, setUnits] = useState<number>();
  const [imageName, setImageName] = useState<string>();

  const handleConfirmCancel = () => {
    const currentProgram = isNew ? newProgram : selectedProgram;

    if (isNew) {
      dispatch(clearNewProgram());
    }

    if (currentProgram && currentProgram.isActive) {
      return dispatch(
        setCurrentModal({
          modal: (
            <StartActiveBasalProgramModal
              changesSaved={false}
              onPodCommTimeout={() => {
                dispatch(hideModal());
                navigate(BasalProgramsRoutesEnum.BasalProgramsMain);
              }}
            />
          ),
        })
      );
    }

    dispatch(hideModal());
    navigate(BasalProgramsRoutesEnum.BasalProgramsMain);
  };

  useEffect(() => {
    setParams({
      topBarOnLeftButtonClick: () => {
        dispatch(
          setCurrentModal({
            modal: (
              <CancelEditBasalProgramModal
                onYes={handleConfirmCancel}
                isNew={isNew}
              />
            ),
          })
        );
        dispatch(showModal());
      },
    });

    const currentProgram = isNew ? newProgram : selectedProgram;
    if (currentProgram) {
      setProgramId(currentProgram.id);

      if (currentProgram.name) {
        setProgramName(currentProgram.name);
      }

      const segment = getCurrentSegment(currentProgram, segmentId);

      if (segment) {
        setStartTime(segment.startTime);
        setEndTime(segment.endTime);
        setUnits(segment.units);
        setImageName(segment.imageName);
      }
    }
  }, [segmentId, programId]);

  const handleGoBack = () => {
    dispatch(hideModal());
    goBack();
  };

  useEffect(() => {
    setParams({
      topBarOnBackButtonClick: () => {
        if (isNew && (endTime || units)) {
          dispatch(
            setCurrentModal({
              modal: (
                <BackToPreviousScreenModal
                  onYes={handleGoBack}
                />
              ),
            })
          );
          dispatch(showModal());
          return;
        }

        goBack();
      },
    });
  }, [endTime, units]);

  useEffect(() => {
    if (endTime && units && isNew) {
      setUserInteraction(true);
      setImageName(relaxDaySegment?.imageName);
    }
  }, [endTime, units]);

  const renderSegmentImage = () => {
    let comparedImage = imageName;
    if (isNew) {
      comparedImage = userInteraction
        ? relaxDaySegment?.imageName
        : relaxDaySegment?.noDataImage;
    }

    return <BasalSegmentImage imageName={comparedImage} />;
  };

  const handleEndTimeClick = () => {
    if (isNew) {
      setEndTime(relaxDaySegment?.endTime);
    }
  };

  const handleUnitsClick = () => {
    if (isNew) {
      setUnits(relaxDaySegment?.units);
    }
  };

  const handleSegmentSave = () => {
    dispatch(
      updateProgramSegment({
        programId,
        isNew,
        segmentData: {
          id: segmentId,
          startTime,
          endTime,
          units,
          imageName,
        },
      })
    );

    if (isNew) {
      if (viewFromConfirm) {
        return navigate(BasalProgramsRoutesEnum.ConfirmBasalProgram, { isNew });
      }

      if (newProgram.segments && newProgram.segments.length < 2) {
        dispatch(addNewSegment({ segmentDefaults: { startTime: endTime } }));
      }

      if (segmentId !== 2) {
        return push(BasalProgramsRoutesEnum.EditSegment, {
          segmentId: segmentId + 1,
          isNew: true,
        });
      }

      if (segmentId === 2) {
        dispatch(
          updateNewProgram({
            programData: {
              unitsTotal: relaxDayData.unitsTotal,
              imageName: relaxDayData.imageName,
            },
          })
        );
      }
    }

    return navigate(BasalProgramsRoutesEnum.ConfirmBasalProgram, { isNew });
  };

  return (
    <div className="bg-white h-full flex flex-col justify-between pt-[19px] pb-[34px]">
      <div>
        <div className="px-2 mb-[10px]">
          <div className="text-modes-manual font-semibold">{programName}</div>
          <div className="text-2xl font-bold mb-[31px]">
            <FormattedMessage
              {...editSegmentMessages.segmentNumber}
              values={{ segmentNumber: segmentId }}
            />
          </div>

          <div>{renderSegmentImage()}</div>
          <div className="text-smallest mt-[-20px]"><FormattedMessage {...editSegmentMessages.graphSubText} /></div>
        </div>

        <div className="px-2 border-b-1 border-b-ios-gray-9/[0.36] py-[15px] flex justify-between items-center">
          <div>
            <FormattedMessage {...editSegmentMessages.startTime} />
          </div>
          <div className="text-ios-gray-9/60">
            {startTime && (
              <FormattedTime time={moment().hour(startTime).minutes(0)} />
            )}
          </div>
        </div>

        <div className="px-2 border-b-1 border-b-ios-gray-9/[0.36] py-[15px] flex justify-between items-center">
          <div>
            <FormattedMessage {...editSegmentMessages.endTime} />
          </div>
          <div
            className={classNames('cursor-pointer', {
              'text-modes-manual': !!endTime,
              'text-ios-gray-9/60': !endTime,
            })}
            onClick={handleEndTimeClick}
          >
            {endTime && (
              <FormattedTime time={moment().hour(endTime).minutes(0)} />
            )}
            {!endTime && '--'}
          </div>
        </div>

        <div className="px-2 border-b-1 border-b-ios-gray-9/[0.36] py-[15px] flex justify-between items-center">
          <div>
            <div>
              <FormattedMessage {...editSegmentMessages.basalRate} />
            </div>
            <div className="text-small text-ios-gray-9/[0.36]">
              <FormattedMessage {...editSegmentMessages.basalRateSubText} />
            </div>
          </div>
          <div
            className={classNames('cursor-pointer', {
              'text-modes-manual': !!units,
              'text-ios-gray-9/60': !units,
            })}
            onClick={handleUnitsClick}
          >
            {units && (
              <FormattedMessage
                {...confirmBasalProgramMessages.units}
                values={{ units: units }}
              />
            )}
            {!units && '--'}
          </div>
        </div>
      </div>

      <div className="px-2">
        <Button
          variant="colored"
          colorMode="manual"
          full
          disabled={!userInteraction}
          onClick={handleSegmentSave}
        >
          <FormattedMessage {...editSegmentMessages.next} />
        </Button>
      </div>
    </div>
  );
};
