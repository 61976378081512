import { isEqual } from 'lodash';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { NavState } from 'web/store/reducers/navigation';
import Configurator from 'web/components/organisms/Configurator';
import { RootState } from '../../store/store';
import NavigationRouter from '../../../mobile/navigationRouter';
import { SimulatorContainer } from '../../components';
import HomeHeader from '../../components/molecules/homeHeader';
import NavigationController from '../../components/molecules/navController';
import { IPhoneX } from '../../components/organisms';
import IntlProviderWrapper from '../../data/IntlProviderWrapper';

const HomePage: React.FC = () => {
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  return (
    <View style={styles.container}>
      <HomeHeader />
      <View style={styles.view}>
        <View>
          <Configurator />
        </View>
        <View style={{ marginHorizontal: 128 }}>
          <View>
            <IntlProviderWrapper>
              <SimulatorContainer>
                <IPhoneX>
                  <NavigationRouter key={navState.navigateTo} />
                </IPhoneX>
              </SimulatorContainer>
            </IntlProviderWrapper>
          </View>
        </View>
        <View>
          <NavigationController />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  view: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 32,
    flexWrap: 'wrap',
  },
});

export default HomePage;
