let strings = {
  "en-US": {
    introModalTitle: "Transmitter Serial Number",
    introModalMessage:
      "Here you will connect your Transmitter with your active Pod by simply entering the transmitter serial number. Updating the Transmitter serial number is not required with every Pod change, only when starting a new Transmitter.",
    connectSensorOmnipod: "Connect your Sensor to \nOmnipod\u00A05",
    transmitterNum: "Enter your transmitter serial number (SN).",
    snTransmitter:
      "The SN is printed on the back of your \ntransmitter and on the transmitter box.",
    findSNTransmitter:
      "If you have already set up your transmitter in \nthe Dexcom App, you can find the SN in the \nDexcom Settings.",
    cancel: "Cancel",
    save: "Save",
    confirmText: "OK",
    populateSNModalTitle: "Enter SN",
    populateSNModalMessage:
      "For simulation purposes we have auto-filled this information for you.",
    demoStepModalTitle: "Connecting Transmitter",
    demoStepModalMessage:
      "Normally this will take up to 20 minutes. We are speeding up time for the purpose of the demonstration.",
    connectedSensorStepModalTitle: "Transmitter Connected",
    connectedSensorStepModalMessage:
      "Your transmitter can be used for 90 days, you will not be required to enter the transmitter serial number at every Pod change.",
    defaultTitle: "Communicating with Pod",
    pleaseWait: "Please wait..."
  },
  "en-GB": {
    introModalTitle: "Transmitter Serial Number",
    introModalMessage:
      "Here you will connect your Transmitter with your active Pod by simply entering the transmitter serial number. Updating the Transmitter serial number is not required with every Pod change, only when starting a new Transmitter.",
    connectSensorOmnipod: "Connect your Sensor to \nOmnipod\u00A05",
    transmitterNum: "Enter your transmitter serial number (SN).",
    snTransmitter:
      "The SN is printed on the back of your \ntransmitter and on the transmitter box.",
    findSNTransmitter:
      "If you have already set up your transmitter in \nthe Dexcom App, you can find the SN in the \nDexcom Settings.",
    cancel: "Cancel",
    save: "Save",
    confirmText: "OK",
    populateSNModalTitle: "Enter SN",
    populateSNModalMessage:
      "For simulation purposes we have auto-filled this information for you.",
    demoStepModalTitle: "Connecting Transmitter",
    demoStepModalMessage:
      "Normally this will take up to 20 minutes. We are speeding up time for the purpose of the demonstration.",
    connectedSensorStepModalTitle: "Transmitter Connected",
    connectedSensorStepModalMessage:
      "Your transmitter can be used for 90 days, you will not be required to enter the transmitter serial number at every Pod change.",
    defaultTitle: "Communicating with Pod",
    pleaseWait: "Please wait..."
  },
  "de-DE": {
    introModalTitle: "Transmitter-Seriennummer",
    introModalMessage:
      "Hier können Sie Ihren Transmitter mit Ihrem aktiven Pod verbinden, indem Sie einfach die Seriennummer des Transmitters eingeben. Die Seriennummer des Transmitters muss nicht bei jedem Pod-Wechsel aktualisiert werden, sondern nur bei der Inbetriebnahme eines neuen Transmitters.",
    connectSensorOmnipod: "Verbinden Sie Ihren Sensor mit \ndem Omnipod 5.",
    transmitterNum: "Geben Sie Ihre Transmitter-Seriennummer \n(SN) ein.",
    snTransmitter:
      "Die SN befindet sich auf der Rückseite \nIhres Transmitters und auf dem \nTransmitterkarton aufgedruckt.",
    findSNTransmitter:
      "Wenn Sie Ihren Transmitter bereits in der \nDexcom-App eingerichtet haben, finden Sie \ndie SN in den Dexcom-Einstellungen.",
    cancel: "Abbr.",
    save: "Speichern",
    confirmText: "OK",
    populateSNModalTitle: "SN eingeben",
    populateSNModalMessage:
      "Zu Simulationszwecken haben wir diese Informationen automatisch für Sie ausgefüllt.",
    demoStepModalTitle: "Transmitter wird verbunden",
    demoStepModalMessage:
      "Dies dauert in der Regel bis zu 20 Minuten. Zu Demonstrationszwecken wird dieser Prozess beschleunigt.",
    connectedSensorStepModalTitle: "Transmitter verbunden",
    connectedSensorStepModalMessage:
      "Ihr Transmitter kann 90 Tage lang verwendet werden. Sie müssen die Seriennummer des Transmitters nicht bei jedem Pod-Wechsel eingeben.",
    defaultTitle: "Kommunikation mit dem Pod",
    pleaseWait: "Bitte warten..."
  },
  "es-US": {
    introModalTitle: "Número de serie del transmisor",
    introModalMessage:
      "Aquí conectará el transmisor con su Pod activo al introducir el número de serie del transmisor. No es necesario que actualice el número de serie del transmisor con cada cambio de Pod, sino solo cuando se inicia un nuevo transmisor.",
    connectSensorOmnipod: "Conecte su sensor a la aplicación \nOmnipod 5",
    transmitterNum: "Introduzca el número de serie (SN) de su \ntransmisor.",
    snTransmitter:
      "El SN está indicado en la parte posterior \nde su transmisor y en la caja del \ntransmisor.",
    findSNTransmitter:
      "Si ya configuró el transmisor en la aplicación \nDexcom, puede encontrar el SN en los \najustes del Dexcom.",
    cancel: "Cancelar",
    save: "Guardar",
    confirmText: "Aceptar",
    populateSNModalTitle: "Ingrese el SN",
    populateSNModalMessage:
      "Completamos automáticamente esta información para fines de simulación.",
    demoStepModalTitle: "Conectando transmisor",
    demoStepModalMessage:
      "Normalmente, esto tardará hasta 20 minutos. Estamos acelerando el tiempo con fines de demostración.",
    connectedSensorStepModalTitle: "Transmisor conectado",
    connectedSensorStepModalMessage:
      "El transmisor se puede usar durante 90 días; no se le pedirá que introduzca el número de serie del transmisor con cada cambio de Pod."
  },
  "fr-FR": {
    introModalTitle: "Numéro de série du transmetteur",
    introModalMessage:
      "Ici, vous connecterez votre transmetteur à votre Pod actif en saisissant simplement le numéro de série du transmetteur. La mise à jour du numéro de série du transmetteur n'est pas nécessaire à chaque changement de Pod, uniquement lors du démarrage d'un nouveau transmetteur.",
    connectSensorOmnipod: "Connectez votre Capteur à l'Omnipod 5.",
    transmitterNum: "Saisissez le numéro de série (NS) de votre transmetteur.",
    snTransmitter:
      "Le NS est imprimé au dos de votre transmetteur et sur le boîtier du transmetteur.",
    findSNTransmitter:
      "Si vous avez déjà paramétré votre transmetteur dans l'application Dexcom, vous pouvez trouver le NS dans les réglages Dexcom.",
    cancel: "Annuler",
    save: "Enregistrer",
    confirmText: "OK",
    populateSNModalTitle: "Saisir le NS",
    populateSNModalMessage:
      "À des fins de simulation, nous avons rempli automatiquement ces informations pour vous.",
    demoStepModalTitle: "Connexion du transmetteur",
    demoStepModalMessage:
      "Généralement, cela peut prendre jusqu'à 20 minutes. Nous accélérons ce délai à des fins de démonstration.",
    connectedSensorStepModalTitle: "Transmetteur connecté",
    connectedSensorStepModalMessage:
      "Votre transmetteur peut être utilisé pendant 90 jours, vous n'aurez pas à saisir le numéro de série du transmetteur à chaque changement de Pod."
  },
  "nl-NL": {
    introModalTitle: "Serienummer van zender",
    introModalMessage:
      "Hier verbindt u uw zender met uw actieve Pod door eenvoudig het serienummer van de zender in te voeren. Het bijwerken van het serienummer van de zender is niet vereist bij elke wijziging van de Pod, maar alleen wanneer u een nieuwe zender opstart.",
    connectSensorOmnipod: "Verbind uw sensor met de \nOmnipod 5.",
    transmitterNum: "Voer het serienummer (SN) van uw \nZender in.",
    snTransmitter:
      "Het SN staat op de achterkant van uw \nzender en op de zenderdoos.",
    findSNTransmitter:
      "Als u de set-up van uw Zender al hebt \ningevoerd op de Dexcom app, kunt u het SN \nterugvinden in de Dexcom-instellingen.",
    cancel: "Annuleren",
    save: "Opslaan",
    confirmText: "OK",
    populateSNModalTitle: "SN invoeren",
    populateSNModalMessage:
      "Voor simulatiedoeleinden hebben wij deze informatie automatisch voor u ingevuld.",
    demoStepModalTitle: "Bezig met verbinden",
    demoStepModalMessage:
      "Dit duurt doorgaans maximaal 20 minuten. We versnellen de tijd voor het doel van de demonstratie.",
    connectedSensorStepModalTitle: "Zender verbonden",
    connectedSensorStepModalMessage:
      "Uw zender kan 90 dagen worden gebruikt, u hoeft het serienummer van de zender niet bij elke Pod-verandering in te voeren."
  }
};

export { strings };
