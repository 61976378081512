export enum IOSBolusRoutesEnums {
  IOSBolusFTS = 'IOSBolusFTS',
  IntroBolus = 'IntroBolus',
  TargetGlucoseAndCorrectAboveValuesIntro = 'TargetGlucoseAndCorrectAboveValuesIntro',
  TargetGlucoseAndCorrectAboveSegmentOne = 'SegmentOne',
  TargetGlucoseAndCorrectAboveSegmentTwo = 'SegmentTwo',
  TargetGlucoseAndCorrectAboveSegments = 'BolusSegments',
  SetInsulinToCarbRatioIntro = 'SetInsulinToCarbRatioIntro',
  CreateInsulinToCarbRatioSegmentOne = 'CreateInsulinToCarbRatioSegmentOne',
  CreateInsulinToCarbRatioSegmentTwo = 'CreateInsulinToCarbRatioSegmentTwo',
  InsulinToCarbRatioSegments = 'InsulinToCarbRatioSegments',
  SetCorrectionFactorIntro = 'SetCorrectionFactorIntro',
  CreateCorrectionFactorSegmentOne = 'CreateCorrectionFactorSegmentOne',
  CreateCorrectionFactorSegmentTwo = 'CreateCorrectionFactorSegmentTwo',
  CorrectionFactorSegments = 'CorrectionFactorSegments',
  SetDurationOfInsulinAction = 'SetDurationOfInsulinAction',
  SetMaximumBolus = 'SetMaximumBolus',
  AllowExtendedBolus = 'AllowExtendedBolus',
}
