let strings = {
  "en-US": {
    sensor: "Sensor",
    insulinAmount: "Insulin Amount",
    u: "U"
  },
  "en-GB": {
    sensor: "Sensor",
    insulinAmount: "Insulin Amount",
    u: "U"
  },
  "de-DE": {
    sensor: "Sensor",
    insulinAmount: "Insulinmenge",
    u: "E"
  },
  "es-US": {
    sensor: "Sensor",
    insulinAmount: "Cantidad de insulina",
    u: "U"
  },
  "fr-FR": {
    sensor: "Capteur",
    insulinAmount: "Quantité d'insuline",
    u: "U"
  },
  "nl-NL": {
    sensor: "Sensor",
    insulinAmount: "Hoeveelheid insuline",
    u: "E"
  }
};

export { strings };
