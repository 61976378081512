import React from "react";
import {
  Image,
  StyleProp,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
  ViewStyle
} from "react-native";
import theme from "../../../theme";

interface StepListItemProps {
  stepNumber: string;
  textBeforeHighlight?: string;
  highlightedTextCallback?: any;
  highlightedText?: string;
  textAfterHighlight?: string;
  stepImage?: any;
  style?: StyleProp<ViewStyle>;
  lastStepOnPage?: boolean;
  boldedText?: string;
  lineHeight?: number;
}

const StepListItem: React.FC<StepListItemProps> = ({
  stepNumber,
  highlightedTextCallback,
  textBeforeHighlight,
  highlightedText,
  textAfterHighlight,
  stepImage,
  style,
  lastStepOnPage,
  boldedText,
  lineHeight
}) => {
  const { width } = useWindowDimensions();
  let stepStyle: StyleProp<ViewStyle>;
  if (lastStepOnPage) {
    stepStyle = styles.stepBorderEnd;
  } else {
    stepStyle = styles.stepBorder;
  }

  return (
    <View
      style={[
        style && style,
        {
          marginBottom: 8
        }
      ]}>
      <View style={[styles.container]}>
        <View>
          <View style={[styles.stepBlueCircle]}>
            <Text
              style={[
                {
                  color: "white"
                },
                theme.fonts.body3
              ]}>
              {stepNumber}
            </Text>
          </View>
          {!lastStepOnPage && (
            <View
              style={[
                styles.spacer,
                {
                  height: lineHeight ? lineHeight : 24
                }
              ]}
            />
          )}
        </View>
        <View style={{ flexDirection: "column" }}>
          <View>
            <Text style={[theme.fonts.body3, { marginRight: 8 }]}>
              {textBeforeHighlight}
              {highlightedText && (
                <Text
                  style={[
                    {
                      color: theme.colors.blueLink,
                      textDecorationLine: "underline"
                    }
                  ]}
                  onPress={highlightedTextCallback}>
                  {highlightedText}
                </Text>
              )}
              {boldedText && (
                <Text
                  style={[
                    {
                      textTransform: "uppercase",
                      fontWeight: "bold"
                    }
                  ]}>
                  {boldedText}
                </Text>
              )}
              {textAfterHighlight}
            </Text>
          </View>
          {stepImage && (
            <View style={{ width: "100%", zIndex: -1 }}>
              <Image
                style={[styles.image]}
                resizeMode="contain"
                source={stepImage}
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    marginRight: 16,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    zIndex: 9
  },
  stepBorder: {
    flexDirection: "row",
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.textInputBorder,
    minHeight: 24
  },
  stepBorderEnd: {
    flexDirection: "row",
    minHeight: 24
  },
  stepBlueCircle: {
    backgroundColor: theme.colors.blueCircle,
    borderRadius: 50,
    height: 24,
    width: 24,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
    marginLeft: -15
  },
  image: {
    width: 250,
    marginTop: -68,
    marginBottom: -80,
    marginLeft: 24,
    zIndex: -1
  },
  spacer: {
    marginTop: 8,
    width: 0.5,
    backgroundColor: theme.colors.textInputBorder
  }
});

export default StepListItem;
