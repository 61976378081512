import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
  selectCurrentOperationMode,
  selectIsActivityModeActive,
  selectIsAutoMode,
  selectIsTempBasalActive,
} from 'web/store/reducers/user/user.helpers';
import { AppRoutesEnum } from 'web/routes/types';
import { BottomNav } from 'web/components/ios/BottomMenu/BottomNav';
import {
  ActivityMenuIcon,
  SwitchMenuIcon,
  SetTempBasalMenuIcon,
  PodMenuIcon,
  EnterBGMenuIcon,
  PauseInsulinMenuIcon,
  StartInsulinMenuIcon,
} from 'web/components/ios/icons';
import { MenuSection } from '../../components/MenuSection';
import { MenuItem } from '../../components/MenuItem';
import { messages } from '../../Menu.messages';
import { selectShowIOSFeatures } from 'web/store/reducers/featureFlags/featureFlags.helpers';
import { PodFTSRouteNames } from 'web/containers/ios/Pod/types';
import { selectIsInsulinPaused } from 'web/store/reducers/basal/basal.helpers';

export const MainMenu: React.FC = () => {
  const isInsulinPaused = useSelector(selectIsInsulinPaused);

  const operationMode = useSelector(selectCurrentOperationMode);
  const { navigate } = useNavigation();
  const isAutoMode = useSelector(selectIsAutoMode);
  const isTempBasalActive = useSelector(selectIsTempBasalActive);
  const showIOSFeatures = useSelector(selectShowIOSFeatures);
  const isActivityModeActive = useSelector(selectIsActivityModeActive);

  return (
    <div className="font-ios text-regular h-full bg-ios-gray-6 flex justify-between flex-col">
      <div className="overflow-y-scroll">
        <div className="px-2 pt-[24px] pb-1 bg-white">
          <h1 className="font-bold text-4xl">
            <FormattedMessage {...messages.title} />
          </h1>
        </div>

        <MenuSection title="ACTIONS" className="pb-4">
          <MenuItem
            onClick={() => navigate(AppRoutesEnum.switchMode)}
            icon={<SwitchMenuIcon color={`fill-modes-${operationMode}`} />}
          >
            <FormattedMessage {...messages.switchMode} />
          </MenuItem>
          <MenuItem
            onClick={() => navigate(AppRoutesEnum.SetTempBasal)}
            icon={
              <SetTempBasalMenuIcon
                color={classNames(`fill-modes-${operationMode}`, {
                  'opacity-30': isAutoMode || isTempBasalActive,
                })}
              />
            }
            disabled={isAutoMode || isTempBasalActive}
          >
            <FormattedMessage {...messages.setTempBasal} />
          </MenuItem>
          <MenuItem
            onClick={() => navigate('HypoProtect')}
            icon={
              <ActivityMenuIcon
                color={classNames(`fill-modes-${operationMode}`, {
                  'opacity-30': !isAutoMode || isActivityModeActive,
                })}
              />
            }
            disabled={!isAutoMode || isActivityModeActive}
          >
            <FormattedMessage {...messages.activity} />
          </MenuItem>
          <MenuItem
            onClick={() =>
              showIOSFeatures
                ? navigate(AppRoutesEnum.podSetup, {
                    screen: PodFTSRouteNames.podDetails,
                  })
                : navigate('PodDetailsScreen')
            }
            icon={<PodMenuIcon color={`fill-modes-${operationMode}`} />}
          >
            <FormattedMessage {...messages.pod} />
          </MenuItem>
          <MenuItem
            onClick={() => navigate('EnterBGScreen')}
            icon={<EnterBGMenuIcon color={`fill-modes-${operationMode}`} />}
          >
            <FormattedMessage {...messages.enterBg} />
          </MenuItem>
          {isInsulinPaused ? (
            <MenuItem
              onClick={() => navigate('StartInsulin')}
              icon={
                <StartInsulinMenuIcon color={`fill-modes-${operationMode}`} />
              }
            >
              <FormattedMessage {...messages.startInsulin} />
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => navigate('PauseInsulin')}
              icon={
                <PauseInsulinMenuIcon
                  color={classNames(`fill-modes-${operationMode}`, {
                    'opacity-30': isAutoMode,
                  })}
                />
              }
              disabled={isAutoMode}
            >
              <FormattedMessage {...messages.pauseInsulin} />
            </MenuItem>
          )}
        </MenuSection>

        <MenuSection title="MANAGE PROGRAMS & PRESETS" className="pb-4">
          <MenuItem
            onClick={() => navigate(AppRoutesEnum.BasalPrograms)}
            disabled={isAutoMode}
          >
            <FormattedMessage {...messages.basalPrograms} />
          </MenuItem>
          <MenuItem onClick={() => navigate(AppRoutesEnum.CustomFoods)}>
            <FormattedMessage {...messages.customFoods} />
          </MenuItem>
        </MenuSection>

        <MenuSection title="SETTINGS">
          <MenuItem onClick={() => navigate(AppRoutesEnum.generalSettings)}>
            <FormattedMessage {...messages.general} />
          </MenuItem>
          <MenuItem onClick={() => navigate('SettingsCGMTransmitter')}>
            <FormattedMessage {...messages.manageSensor} />
          </MenuItem>
          <MenuItem onClick={() => navigate(AppRoutesEnum.settingsReminders)}>
            <FormattedMessage {...messages.reminders} />
          </MenuItem>
          <MenuItem onClick={() => navigate('SettingsCGMBGGoalRange')}>
            <FormattedMessage {...messages.glucoseGoalRange} />
          </MenuItem>
          <MenuItem onClick={() => navigate('SettingsBasalTemp')}>
            <FormattedMessage {...messages.basalTempBasalSettings} />
          </MenuItem>
          <MenuItem onClick={() => navigate(AppRoutesEnum.SettingsBolus)}>
            <FormattedMessage {...messages.bolusSettings} />
          </MenuItem>
          <MenuItem onClick={() => navigate('AboutScreen')}>
            <FormattedMessage {...messages.about} />
          </MenuItem>
        </MenuSection>
      </div>

      <BottomNav />
    </div>
  );
};
