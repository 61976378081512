import { defineMessages } from 'react-intl';

export const confirmActivityMessages = defineMessages({
  text: {
    id: 'ios.modals.confirm-activity.text',
    defaultMessage: 'Set Activity feature for {hours, plural, one {{hours, number} hr} other {{hours, number} hrs}}?',
  },
  rightButton: {
    id: 'ios.modals.confirm-activity.right-button',
    defaultMessage: 'Start'
  },
  leftButton: {
    id: 'ios.modals.confirm-activity.left-button',
    defaultMessage: 'Cancel'
  },
});

export const cancelActivityMessages = defineMessages({
  text: {
    id: 'ios.modals.cancel-activity.text',
    defaultMessage: 'Cancel Activity feature?',
  },
  rightButton: {
    id: 'ios.modals.activity.right-button',
    defaultMessage: 'Yes'
  },
  leftButton: {
    id: 'ios.modals.activity.left-button',
    defaultMessage: 'No'
  },
});
