import { CountryType } from "mobile/latest/types/country";
import { LanguageType } from "mobile/latest/types/language";
import { LocaleType } from "mobile/latest/types/locales";
import * as type from "./actions";
import en_US from 'lang/en-US.json';
import es_US from 'lang/es-US.json';
import de_DE from 'lang/de-DE.json';
import en_GB from 'lang/en-GB.json';
import fr_FR from 'lang/fr-FR.json';
import nl_NL from 'lang/nl-NL.json';

function getLocalizedMessages({ locale }: Partial<LangState>) {
  switch (locale) {
    case 'es-US':
      return es_US;
    case 'en-GB':
      return en_GB;
    case 'de-DE':
      return de_DE;
    case 'fr-FR':
      return fr_FR;
    case 'nl-NL':
      return nl_NL;
    case 'en-US':
    default:
      return en_US;
  }
}

export interface LangState {
  language: LanguageType;
  locale: LocaleType;
  country: CountryType;
  //permanentCountry is used to prevent WelcomeStack from breaking. changing country breaks routes/scenes in WelcomeStack
  permanentCountry: CountryType;
  units: 'mg/dL' | 'mmol/L';
  showLocaleScreens: boolean;
  is24Hour: boolean;
  messages: any;
}

export const initialState: LangState = {
  language: 'en-US',
  locale: 'en-US',
  country: 'United States',
  permanentCountry: 'United States',
  units: 'mg/dL',
  showLocaleScreens: true,
  is24Hour: false,
  messages: en_US,
};

const reducer = (
  state: LangState = initialState,
  action: type.Action,
): LangState => {
  switch (action.type) {
    case type.LANGUAGE_UPDATE:
      return { ...state, ...action.payload.language, messages: getLocalizedMessages(action.payload.language) };
    case type.LANGUAGE_INIT:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;
