let strings = {
  "en-US": {
    glucoseManual: "Glucose: Manual",
    switchManual: "Switched to Manual Mode",
    switchAutomated: "Switched to Automated Mode",
    basalProgramStarted: "Basal Program started:",
    basalProgramInProgress: "Basal Program in progress:",
    basal1: "Basal 1",
    tempBasalCanceled: "Temp basal canceled:",
    tempBasalCanceledSubtext: "Increased 45% for %1 hr",
    tempBasalStarted: "Temp basal started:",
    basalProgramContinued: "Basal Program continued:",
    insulinDeliveryStarted: "Insulin Delivery Started",
    insulinDeliveryPaused: "Insulin Delivery Paused",
    lot: "Lot No: 2315255875",
    sequence: "Sequence No: 41007",
    podActived: "Pod Activated",
    lastIOB: "Last IOB: %2 U",
    podDeactivated: "Pod Deactivated"
  },
  "en-GB": {
    glucoseManual: "Glucose: Manual",
    switchManual: "Switched to Manual Mode",
    switchAutomated: "Switched to Automated Mode",
    basalProgramStarted: "Basal Programme started:",
    basalProgramInProgress: "Basal Programme in progress:",
    basal1: "Basal 1",
    tempBasalCanceled: "Temp basal canceled:",
    tempBasalCanceledSubtext: "Increased 45% for %1 hr",
    tempBasalStarted: "Temp basal started:",
    basalProgramContinued: "Basal Programme continued:",
    insulinDeliveryStarted: "Insulin Delivery Started",
    insulinDeliveryPaused: "Insulin Delivery Paused",
    lot: "Lot No: 2315255875",
    sequence: "Sequence No: 41007",
    podActived: "Pod Activated",
    lastIOB: "Last IOB: %2 U",
    podDeactivated: "Pod Deactivated"
  },
  "de-DE": {
    glucoseManual: "Glukose: Manuell",
    switchManual: "Umgeschaltet auf \nManuellen Modus",
    switchAutomated: "Umgeschaltet auf \nAutomatisierten Modus",
    basalProgramStarted: "Basalratenprofil gestartet:",
    basalProgramInProgress: "Basalratenprofil in Bearbeitung:",
    basal1: "Basal 1",
    tempBasalCanceled: "Temporäre Basalrate abgebrochen:",
    tempBasalCanceledSubtext: "Erhöht um 45 % für %1 Stunde",
    tempBasalStarted: "Temporäre Basalrate gestartet:",
    basalProgramContinued: "Basalratenprofil fortgesetzt:",
    insulinDeliveryStarted: "Insulinabgabe gestartet",
    insulinDeliveryPaused: "Insulinabgabe unterbrochen",
    lot: "Chargennr.: 2315255875",
    sequence: "Seriennr.: 41007",
    podActived: "Pod aktiviert",
    lastIOB: "Letztes AI: %2 E",
    podDeactivated: "Pod deaktiviert"
  },
  "es-US": {
    glucoseManual: "GS: Manual",
    switchManual: "Cambiado a modo manual",
    switchAutomated: "Cambiado a modo automático",
    basalProgramStarted: "Programa basal iniciado:",
    basalProgramInProgress: "Programa basal en progreso:",
    basal1: "Basal 1",
    tempBasalCanceled: "Basal temporal cancelado:",
    tempBasalCanceledSubtext: "Aumento de 45 % por %1 hr",
    tempBasalStarted: "Basal temporal iniciado:",
    basalProgramContinued: "Programa basal continuado:",
    insulinDeliveryStarted: "De insulina iniciada",
    insulinDeliveryPaused: "De insulina en pausa",
    lot: "Lote n.º: 2315255875",
    sequence: "Secuencia n.º: 41007",
    podActived: "Pod activado",
    lastIOB: "Última IOB: %2 U",
    podDeactivated: "Pod desactivado"
  },
  "nl-NL": {
    glucoseManual: "GS: Manual",
    switchManual: "Gewijzigd naar handmatige modus",
    switchAutomated: "Gewijzigd naar Geautomatiseerde Modus",
    basalProgramStarted: "Programa basal iniciado:",
    basalProgramInProgress: "Verlengde bolus geannuleerd:",
    basal1: "Basal 1",
    tempBasalCanceled: "Verlengde bolus toegediend:",
    tempBasalCanceledSubtext: "",
    tempBasalStarted: "Basaalprogramma gestart: 1segment",
    basalProgramContinued: "Basaalprogramma voortgezet:",
    insulinDeliveryStarted: "Insuline gestart",
    insulinDeliveryPaused: "Insuline op pauze",
    lot: "Partij: n.º: 2315255875",
    sequence: "Volgnummer: n.º: 41007",
    podActived: "Pod geactiveerd",
    lastIOB: "Nu: %2 U",
    podDeactivated: "Pod verwijderd"
  },
  "fr-FR": {
    glucoseManual: "Glucose: Manual",
    switchManual: "Passé en Mode Manuel",
    switchAutomated: "Passé en Mode Automatisé",
    basalProgramStarted: "Programme de débit basal démarré:",
    basalProgramInProgress: "Programme basal actif:",
    basal1: "Débit basal 1",
    tempBasalCanceled: "Débit basal temporaire annulé:",
    tempBasalCanceledSubtext: "Augmenter 45% pendant %1 hr",
    tempBasalStarted: "Débit basal temporaire démarré:",
    basalProgramContinued: "Suite du programme de débit basal:",
    insulinDeliveryStarted: "Administration d'insuline démarrée",
    insulinDeliveryPaused: "Administration d'insuline mise en pause",
    lot: "Lot No: 2315255875",
    sequence: "N° de séquence: 41007",
    podActived: "Pod activé",
    lastIOB: "Last IOB: %2 U",
    podDeactivated: "Pod désactivé"
  },
};

export { strings };
