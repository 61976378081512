let strings = {
  "en-US": {
    sensor: "Sensor",
    insulinCarbs: "Insulin & Carbs",
    colOneLabel: "Glucose",
    colTwoLabel: "Bolus",
    colThreeLabel: "Carbs",
    u: "U"
  },
  "en-GB": {
    sensor: "Sensor",
    insulinCarbs: "Insulin & Carbs",
    colOneLabel: "Glucose",
    colTwoLabel: "Bolus",
    colThreeLabel: "Carbs",
    u: "U"
  },
  "de-DE": {
    sensor: "Sensor",
    insulinCarbs: "Insulin & Kohlenhydrate",
    colOneLabel: "Glukose",
    colTwoLabel: "Bolus",
    colThreeLabel: "KH",
    u: "E"
  },
  "es-US": {
    sensor: "Sensor",
    insulinCarbs: "Insulina y carbohidratos",
    colOneLabel: "Glucosa",
    colTwoLabel: "Bolo",
    colThreeLabel: "Carbohidratos",
    u: "U"
  },
  "fr-FR": {
    sensor: "Capteur",
    insulinCarbs: "Insuline & glucides",
    colOneLabel: "Glucose",
    colTwoLabel: "Bolus",
    colThreeLabel: "Glucides",
    u: "U"
  },
  "nl-NL": {
    sensor: "Sensor",
    insulinCarbs: "Insuline en KH",
    colOneLabel: "Glucose",
    colTwoLabel: "Bolus",
    colThreeLabel: "KH",
    u: "E"
  }
};

export { strings };
