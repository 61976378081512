import { createStackNavigator } from '@react-navigation/stack';
import { AppRoutesEnum } from 'web/routes/types';
import { TopBar } from 'web/components/ios/TopBar';
import { useIntl } from 'react-intl';
import { alertsMessages } from './Alerts.messages';
import { Alerts } from './Alerts';

const Stack = createStackNavigator();

export const IOSAlertsStack = () => {
  const intl = useIntl();
  return (
    <Stack.Navigator
      initialRouteName={AppRoutesEnum.History}
      screenOptions={{
        header: (props) => (
          <TopBar
            {...props}
            calendarMode
            calendarModeText={intl.formatMessage(alertsMessages.calendarTitle)}
          />
        ),
      }}
    >
      <Stack.Screen
        name={AppRoutesEnum.History}
        options={{
          headerTitle: intl.formatMessage(alertsMessages.headerTitle),
        }}
        component={Alerts}
      />
    </Stack.Navigator>
  );
};
