let strings = {
  "en-US": {
    deliverBolus: "Delivering Bolus",
    approx: "Approx %1 U of %2 U delivered",
    cancel: "Cancel",
    insulinOnBoard: "INSULIN ON BOARD",
    units: "Units",
    sensorInfo: "SENSOR INFO",
    viewGraph: "VIEW GRAPH",
    ok: "Ok",
    deliverModalTitle: "Delivering Bolus",
    deliverModalMessage:
      "Bolus delivery time depends on the total bolus. We are speeding this process up for simulation purposes.",
    yes: "Yes",
    no: "No",
    cancelModalSuccess: "Bolus Canceled",
    cancelModalMessage: "Are you sure you want to cancel the bolus?"
  },
  "en-GB": {
    deliverBolus: "Delivering Bolus",
    approx: "Approx. %1 U of %2 U delivered",
    cancel: "Cancel",
    insulinOnBoard: "INSULIN ON BOARD",
    units: "Units",
    sensorInfo: "SENSOR INFO",
    viewGraph: "VIEW GRAPH",
    ok: "Ok",
    deliverModalTitle: "Delivering Bolus",
    deliverModalMessage:
      "Bolus delivery time depends on the total bolus. We are speeding this process up for simulation purposes.",
    yes: "Yes",
    no: "No",
    cancelModalSuccess: "Bolus Cancelled",
    cancelModalMessage: "Are you sure you want to cancel the bolus?"
  },
  "de-DE": {
    deliverBolus: "Bolusabgabe",
    approx: "Ca. %1 E von %2 E abgegeben",
    cancel: "Abbr.",
    insulinOnBoard: "AKTIVES INSULIN",
    units: "Einheiten",
    sensorInfo: "SENSOR-INFOS",
    viewGraph: "GRAFIK ANZEIGEN",
    ok: "OK",
    deliverModalTitle: "Bolusabgabe",
    deliverModalMessage:
      "Die Dauer der Bolusabgabe hängt vom Gesamtbolus ab. Zu Simulationszwecken wird dieser Prozess beschleunigt.",
    yes: "Ja",
    no: "Nein",
    cancelModalSuccess: "Bolus abgebrochen",
    cancelModalMessage: "Möchten Sie den Bolus wirklich abbrechen?"
  },
  "es-US": {
    deliverBolus: "Administración de un bolo",
    approx: "Aprox. %1 U de %2 U administradas",
    cancel: "Cancelar",
    insulinOnBoard: "INSULINA ACTIVA",
    units: "Unidades",
    sensorInfo: "INFO. DEL SENSOR",
    viewGraph: "VER GRÁFICO",
    ok: "Aceptar",
    deliverModalTitle: "Administrando bolo",
    deliverModalMessage:
      "El tiempo de administración del bolo depende del bolo total. Estamos acelerando este proceso con fines de simulación.",
    yes: "Sí",
    no: "No",
    cancelModalSuccess: "Bolo cancelado",
    cancelModalMessage: "¿Confirma que desea cancelar el bolo?"
  },
  "fr-FR": {
    deliverBolus: "Bolus en cours",
    approx: "Environ %1 U sur %2 U administrées",
    cancel: "Annuler",
    insulinOnBoard: "INSULINE ACTIVE",
    units: "Unités",
    sensorInfo: "INFOS DU CAPTEUR",
    viewGraph: "AFFICHER LE GRAPHIQUE",
    ok: "Ok",
    deliverModalTitle: "Bolus en cours",
    deliverModalMessage:
      "Le temps d'administration du bolus dépend du bolus total. Nous accélérons ce processus à des fins de simulation.",
    yes: "Oui",
    no: "Non",
    cancelModalSuccess: "Bolus annulé.",
    cancelModalMessage: "Voulez-vous vraiment annuler le bolus ?"
  },
  "nl-NL": {
    deliverBolus: "Toediening van bolus",
    approx: "Ca. %1 E van %2 E toegediend",
    cancel: "Annuleren",
    insulinOnBoard: "INSULINE 'ON BOARD'",
    units: "Eenheden",
    sensorInfo: "INFO VAN DE SENSOR",
    viewGraph: "GRAFIEK BEKIJKEN",
    ok: "OK",
    deliverModalTitle: "Toediening van bolus",
    deliverModalMessage:
      "Toedieningstijd bolus afhankelijk van de totale bolus. We versnellen dit proces voor simulatiedoeleinden.",
    yes: "Ja",
    no: "Nee",
    cancelModalSuccess: "Bolus geannuleerd",
    cancelModalMessage: "Weet u zeker dat u de bolus wilt annuleren?"
  }
};

export { strings };
