import * as type from './actions';

export type CustomFoodType = {
  id: number;
  name: string;
  carbs: number;
  newFood?: boolean;
};

export interface CustomFoodsState {
  customFoods: CustomFoodType[];
}

export const initialState: CustomFoodsState = {
  customFoods: [
    {
      id: 1,
      name: 'Yogurt',
      carbs: 10,
    },
  ],
};

const reducer = (
  state: CustomFoodsState = initialState,
  action: type.Action
): CustomFoodsState => {
  switch (action.type) {
    case type.CUSTOM_FOODS_UPDATE:
      return { ...state, ...action.payload.customFoods };
    case type.CUSTOM_FOODS_INIT:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;
