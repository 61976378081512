import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import React from 'react';
import { Animated } from 'react-native';
import { BackButton } from '../../components/atoms';
import {
  AlertsScreen,
  CountrySettingsScreen,
  InsuletTCScreen,
  LanguageSettingsScreen,
  LockScreen,
  PersonalizeBackgroundScreen,
  PTHOWelcomeScreen,
  SetPinScreen,
  SetupLoadingScreen,
  SoundsScreen,
} from '../../components/screens';
import AcknowledgementScreen from '../../components/screens/simulator/firstTimeSetup/introFTS/acknowledgementScreen';
import AllSetScreen from '../../components/screens/simulator/firstTimeSetup/introFTS/allSetScreen';
import DeclineScreen from '../../components/screens/simulator/firstTimeSetup/introFTS/declineScreen';
import HelpScreen from '../../components/screens/simulator/firstTimeSetup/introFTS/helpScreen';
import ReadTCScreen from '../../components/screens/simulator/firstTimeSetup/introFTS/readTCScreen';
import ScheduleTrainingScreen from '../../components/screens/simulator/firstTimeSetup/introFTS/scheduleTrainingScreen';
import SignInScreen from '../../components/screens/simulator/firstTimeSetup/introFTS/signInScreen';
import TrainingScreen from '../../components/screens/simulator/firstTimeSetup/introFTS/trainingScreen';
import theme from '../../theme';

const Stack = createStackNavigator();

const SetupStack: React.FC = () => {
  const headerRef = new Animated.ValueXY().y;

  const animateHeader = React.useCallback(() => {
    Animated.timing(headerRef, {
      toValue: 0,
      duration: 600,
      useNativeDriver: false,
    }).start();
  }, [headerRef]);

  React.useEffect(() => {
    animateHeader();
  }, [animateHeader]);

  return (
    <>
      <Stack.Navigator initialRouteName="SelectLanguageSettings" mode="modal">
        <Stack.Screen
          name="SelectLanguageSettings"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={LanguageSettingsScreen}
        />
        <Stack.Screen
          name="SelectCountrySettings"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={CountrySettingsScreen}
        />
        <Stack.Screen
          name="TC"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          }}
          component={InsuletTCScreen}
        />
        <Stack.Screen
          name="ReadTC"
          options={{
            headerLeft: () => <BackButton navigateTo={'TC'} />,
            title: 'Terms & Conditions',
            animationEnabled: true,
            headerTitleStyle: { ...theme.fonts.body1 },
            headerTitleContainerStyle: {
              marginTop: 15,
              justifyContent: 'center',
            },
            headerLeftContainerStyle: {
              marginTop: 15,
            },
            gestureDirection: 'horizontal',
          }}
          component={ReadTCScreen}
        />
        <Stack.Screen
          name="SignIn"
          options={{
            headerShown: false,
            animationEnabled: false,
          }}
          component={SignInScreen}
        />
        <Stack.Screen
          name="Training"
          options={{
            headerShown: false,
            animationEnabled: false,
          }}
          component={TrainingScreen}
        />
        <Stack.Screen
          name="ScheduleTraining"
          options={{
            headerShown: false,
            animationEnabled: false,
          }}
          component={ScheduleTrainingScreen}
        />
        <Stack.Screen
          name="Decline"
          options={{
            headerShown: false,
            animationEnabled: false,
          }}
          component={DeclineScreen}
        />
        <Stack.Screen
          name="Acknowledgement"
          options={{
            headerShown: false,
            animationEnabled: false,
          }}
          component={AcknowledgementScreen}
        />
        <Stack.Screen
          name="Help"
          options={{
            headerShown: false,
            animationEnabled: false,
          }}
          component={HelpScreen}
        />
        <Stack.Screen
          name="AllSet"
          options={{
            headerShown: false,
            animationEnabled: false,
          }}
          component={AllSetScreen}
        />
        <Stack.Screen
          name="Setup"
          options={{
            headerShown: false,
            animationEnabled: false,
          }}
          component={SetupLoadingScreen}
        />
        <Stack.Screen
          name="Lock"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: 'horizontal',
          }}
          component={LockScreen}
        />
        <Stack.Screen
          name="Background"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: 'horizontal',
          }}
          component={PersonalizeBackgroundScreen}
        />
        <Stack.Screen
          name="SetPin"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: 'horizontal',
          }}
          component={SetPinScreen}
        />
        <Stack.Screen
          name="Alerts"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: 'horizontal',
          }}
          component={AlertsScreen}
        />
        <Stack.Screen
          name="Sounds"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: 'horizontal',
          }}
          component={SoundsScreen}
        />
        <Stack.Screen
          name="PTHO"
          options={{
            headerShown: false,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: 'horizontal',
          }}
          component={PTHOWelcomeScreen}
        />
      </Stack.Navigator>
    </>
  );
};

export default SetupStack;
