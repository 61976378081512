import React, { useState, useEffect } from 'react';
import { Button } from 'web/components/ios/Button';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { messages } from '../SettingsScreenStack.messages';
import {
  showModal,
  setCurrentModal,
  hideModal,
} from 'web/store/reducers/modals/modalsSlice';
import {
  PauseInsulinConfirmationModal,
  StartInsulinConfirmationModal,
  TimezoneChangeConfirmationModal,
} from 'web/components/ios/modals/GeneralSettingsModals/GeneralSettingsModal';
import { useNavigation } from '@react-navigation/native';
import { IOSRoutesEnums } from '../../FtsStartScreenStack/types';
import { CommunicatingWithPodConfirmationModal } from 'web/components/ios/modals/PauseStartInsulinModals/PauseStartInsulinModals';

export const TimezoneScreen = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigation();

  const [canSave, setCanSave] = useState(false);
  const [showStartInsulinModal, setShowStartInsulinModal] = useState(false);

  const handleShowCommunicatingWithPodConfirmationModal = (
    canStartInsulin = false
  ) => {
    dispatch(
      setCurrentModal({
        modal: (
          <CommunicatingWithPodConfirmationModal
            onClose={() => {
              if (canSave) {
                setShowStartInsulinModal(true);
              }
              if (canStartInsulin) {
                navigate(IOSRoutesEnums.General);
              }
              dispatch(hideModal());
            }}
          />
        ),
      })
    );

    dispatch(showModal());
    setCanSave(true);
  };

  const handlePauseInsulinClick = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <PauseInsulinConfirmationModal
            onReject={() => dispatch(hideModal())}
            onConfirm={() => handleShowCommunicatingWithPodConfirmationModal()}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  const handleOnSave = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <TimezoneChangeConfirmationModal
            onReject={() => dispatch(hideModal())}
            onConfirm={() => handleShowCommunicatingWithPodConfirmationModal()}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  const handleStartInsulinClick = () => {
    dispatch(
      setCurrentModal({
        modal: (
          <StartInsulinConfirmationModal
            onConfirm={() => {
              handleShowCommunicatingWithPodConfirmationModal(true);
            }}
          />
        ),
      })
    );
    dispatch(showModal());
  };

  useEffect(() => {
    if (showStartInsulinModal) {
      handleStartInsulinClick();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showStartInsulinModal]);

  return (
    <div className="flex justify-between flex-col flex-1 items-stretch bg-white">
      <div className="flex flex-col">
        <div className="mt-2 px-2">
          <div className="text-black">
            <FormattedMessage {...messages.yourPodDeliversMessage} />
          </div>
        </div>
        <hr className="mt-[12px] ht-[2px] bg-ios-secondaryGray opacity-35" />
        <div className="mt-2 py-1 px-2 flex flex-row justify-between">
          <div
            className={classNames('text-ios-secondaryGray text-pretty', {
              'opacity-30': !canSave,
            })}
          >
            <FormattedMessage
              {...messages.insulinDeliveryTimeZoneInputTitle}
              values={{ separator: <br /> }}
            />
          </div>
          <div
            className={classNames('text-modes-manual text-nowrap', {
              'opacity-30': !canSave,
            })}
          >
            <FormattedMessage {...messages.newYorkTimezoneTitle} />
          </div>
        </div>
        <hr className="my-1 bg-ios-secondaryGray opacity-35" />

        {!canSave && (
          <div className="mt-2 px-2">
            <div className="text-black">
              <FormattedMessage {...messages.youPauseInsulinMessage} />
            </div>
          </div>
        )}
      </div>
      <div className="w-full mb-4 px-2">
        <Button
          className="w-full rounded-lg bg-modes-manual"
          onClick={() => (canSave ? handleOnSave() : handlePauseInsulinClick())}
          variant="colored"
        >
          {canSave ? (
            <FormattedMessage {...messages.saveButtonTitle} />
          ) : (
            <FormattedMessage {...messages.pauseInsulinButtonTitle} />
          )}
        </Button>
      </div>
    </div>
  );
};
