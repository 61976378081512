import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import getCurrentHourFormat from "../../../../../../helpers/getCurrentHourFormat";
import { getUnitByLocale } from "../../../../../../helpers/getUnitByLocale";
import replaceWithValues from "../../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../../hooks/useLanguageContent";
import {
  bolusActions,
  BolusState
} from "../../../../../../../../web/store/reducers/bolus";
import { LangState } from "../../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../../web/store/store";
import theme from "../../../../../../theme";
import { BottomInnerNav, SegmentTopBar } from "../../../../../molecules";
import { strings } from "./CorrectionFactorSecondSegmentScreenContent";

const ONE_UNIT_FIELD = "ONE_UNIT";

const CorrectionFactorSecondSegmentScreen: React.FC = () => {
  const bolusState: BolusState = useSelector(
    (state: RootState) => state.bolus,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);
  const segment = bolusState.defaultSecondSegment;

  const [fieldValue, setFieldValue] = React.useState<string>(
    segment.oneUnitCorrectionFactor
      ? String(segment.oneUnitCorrectionFactor)
      : ""
  );

  const [highlighted, setHighlighted] = React.useState<string>("");

  const determineEndTime = () => {
    if (
      segment.oneUnitCorrectionFactor &&
      segment.oneUnitCorrectionFactor >= 1 &&
      segment.oneUnitCorrectionFactor <= 400
    ) {
      return true;
    }

    return false;
  };

  const [endTime, setEndTime] = React.useState<boolean>(determineEndTime);

  const lowerRange = getUnitByLocale(1, langState.units, langState.locale);
  const higherRange = `${getUnitByLocale(
    400,
    langState.units,
    langState.locale
  )} ${langState.units}`;
  const contentVariables = [lowerRange, higherRange];

  const getAdornmentColor = () => {
    if (!fieldValue) {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  return (
    <View style={[styles.container]}>
      <View>
        <SegmentTopBar segmentNum="2" />
        <View style={[styles.break, { marginBottom: 16 }]} />
        <View style={{ paddingVertical: 16 }}>
          <View style={{ flexDirection: "row", paddingHorizontal: 16 }}>
            <Text style={[theme.fonts.h4, { flex: 1.1 }]}>
              {languageContent.start}
            </Text>
            <Text style={[theme.fonts.h4, { flex: 1 }]}>
              {languageContent.end}
            </Text>
          </View>
          <View
            style={[
              {
                flexDirection: "row",
                width: "100%",
                paddingLeft: 8,
                paddingHorizontal: 14
              }
            ]}>
            <View style={styles.startTimeContainer}>
              <Text
                style={[
                  {
                    color: theme.colors.pureBlack,
                    fontSize: 32,
                    paddingTop: 8
                  }
                ]}>
                {getCurrentHourFormat({
                  timeStr: "8:00 am",
                  is24Hour: langState.is24Hour,
                  trimModifier: true
                })}
                {!langState.is24Hour ? (
                  <Text style={[styles.periodText]}>AM</Text>
                ) : null}
              </Text>
              <View style={[{ alignSelf: "center" }]}>
                <Text style={[{ fontSize: 32 }]}>{" - "}</Text>
              </View>
            </View>
            <Pressable
              onPress={() => setEndTime(true)}
              style={[theme.layout.mockInput, { flex: 1, paddingVertical: 8 }]}>
              <Text style={theme.layout.mockInputStyle}>
                {endTime ? (
                  <Text style={{ color: theme.colors.text.inputText }}>
                    {getCurrentHourFormat({
                      is24Hour: langState.is24Hour,
                      timeStr: "12:00 am",
                      trimModifier: true
                    })}
                    {!langState.is24Hour && (
                      <Text style={[styles.periodText]}>AM</Text>
                    )}
                  </Text>
                ) : (
                  <Text
                    style={{
                      color: theme.colors.placeholder,
                      padding: 8,
                      fontSize: 32
                    }}>
                    ––
                  </Text>
                )}
              </Text>
            </Pressable>
          </View>
          <View style={styles.helpTextRow}>
            <Text style={[theme.fonts.body1, styles.startTimeContainer]}>
              {languageContent.morning}
            </Text>
            <Text style={[theme.fonts.body1, styles.endTimeHelpText]}>
              {endTime && languageContent.night}
            </Text>
          </View>
          <View
            style={[
              {
                marginTop: 8,
                zIndex: -1
              }
            ]}>
            <View style={[{ marginTop: 16, marginHorizontal: 16 }]}>
              <Text style={[theme.fonts.h4]}>
                {`1 ${languageContent.oneUnitDecrease}`}
              </Text>
              <Text
                style={[
                  theme.fonts.body1,
                  { color: theme.colors.text.cancel, marginBottom: 8 }
                ]}>
                {replaceWithValues(languageContent.range, contentVariables)}
              </Text>
            </View>
            <Pressable
              style={[
                theme.layout.inputContainerStyle,
                {
                  zIndex: 999,
                  marginHorizontal: 16,
                  borderColor:
                    highlighted === ONE_UNIT_FIELD
                      ? theme.colors.blue
                      : theme.colors.textInputBorder
                }
              ]}
              activeOpacity={1}
              onPress={() => {
                setHighlighted(ONE_UNIT_FIELD);
                setFieldValue("120");
              }}>
              {fieldValue ? (
                <Text
                  style={[
                    theme.layout.inputStyle,
                    { color: theme.colors.text.inputText }
                  ]}>
                  {String(getUnitByLocale(Number(fieldValue), langState.units))}
                </Text>
              ) : (
                <Text
                  style={{
                    color: theme.colors.placeholder,
                    fontSize: 32
                  }}>
                  ––
                </Text>
              )}
              <Text
                style={[
                  theme.layout.adornment,
                  {
                    color: getAdornmentColor()
                  }
                ]}>
                {langState.units}
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
      <BottomInnerNav
        leftActionStyle={{ color: theme.colors.text.cancel }}
        leftActionText={languageContent.cancel}
        rightActionText={languageContent.next}
        rightAction={() => {
          if (!fieldValue || !endTime) {
            return;
          }
          dispatch(
            bolusActions.bolusUpdate(
              {
                defaultSecondSegment: {
                  ...segment,
                  oneUnitCorrectionFactor: Number(fieldValue)
                }
              },
              bolusState
            )
          );
          navigate("CorrectionFactorSegmentsConfirm");
        }}
        rightActionStyle={{
          color:
            !fieldValue || !endTime
              ? theme.colors.placeholder
              : theme.colors.purple
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "space-between",
    backgroundColor: "#fff"
  },
  break: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.textInputBorder
  },
  mockInput: {
    borderColor: theme.colors.textInputBorder,
    borderWidth: 1,
    backgroundColor: theme.colors.textInputBackgroundColor,
    width: "100%",
    borderRadius: 5
  },
  periodText: {
    color: theme.colors.text.cancel,
    fontSize: 18
  },
  startTimeContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1.2
  },
  startTimeHelpText: {
    flex: 1.1,
    color: theme.colors.text.cancel,
    paddingLeft: 16
  },
  endTimeHelpText: {
    flex: 1,
    color: theme.colors.text.cancel,
    paddingHorizontal: 16
  },

  helpTextRow: {
    flexDirection: "row",
    marginTop: 8,
    zIndex: -1,
    marginHorizontal: 14
  }
});

export default CorrectionFactorSecondSegmentScreen;
