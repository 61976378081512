import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'L2.scan-sensor.modal.title',
    defaultMessage: 'Scan successful',
  },
  paragraphOne: {
    id: 'L2.scan-sensor.modal.paragraph-one',
    defaultMessage:
      'You do not need to scan again until you start a new Sensor.',
  },
  paragraphTwo: {
    id: 'L2.scan-sensor.modal.paragraph-two',
    defaultMessage:
      'The Omnipod 5 App updates your sensor glucose values every 5 minutes.',
  },
  cta: {
    id: 'L2.scan-sensor.modal.cta',
    defaultMessage: 'OK',
  },
});
