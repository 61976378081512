import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const PauseInsulinMenuIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className={classes} width="28" height="28" rx="6"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6982 18.8888H17.8411V8.4126H14.6982V18.8888Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46045 18.8888H12.6033V8.4126H9.46045V18.8888Z"
        fill="white"
      />
    </svg>
  );
};
