import { BottomNav } from 'web/components/ios/BottomMenu/BottomNav';
import { FormattedMessage } from 'react-intl';
import { historyPodDeactivatedDetailsMessages } from '../History.messages';

export const HistoryPodDeactivatedDetails = () => {
  return (
    <>
      <div className="flex overflow-scroll flex-col font-ios pt-2 h-full bg-white">
        <p className="text-small pt-1 pb-2 pl-2 font-semibold text-ios-secondaryGray opacity-60">
          <FormattedMessage
            {...historyPodDeactivatedDetailsMessages.detailsTitle}
          />
        </p>
        <div className="text-regular">
          <div className="flex justify-between w-full py-2 min-h-[58px] px-2 border-b border-b-ios-gray-7">
            <p>
              <FormattedMessage
                {...historyPodDeactivatedDetailsMessages.timeLabel}
              />
            </p>
            <p className="text-ios-secondaryGray opacity-60">
              <FormattedMessage
                {...historyPodDeactivatedDetailsMessages.timeValue}
              />
            </p>
          </div>
          <div className="flex justify-between w-full py-2 min-h-[58px] px-2 border-b border-b-ios-gray-7">
            <p>
              <FormattedMessage
                {...historyPodDeactivatedDetailsMessages.lotNoLabel}
              />
            </p>
            <p className="text-ios-secondaryGray opacity-60">
              <FormattedMessage
                {...historyPodDeactivatedDetailsMessages.lotNoValue}
              />
            </p>
          </div>
          <div className="flex justify-between w-full py-2 min-h-[58px] px-2 border-b border-b-ios-gray-7">
            <p>
              <FormattedMessage
                {...historyPodDeactivatedDetailsMessages.sequenceNoLabel}
              />
            </p>
            <p className="text-ios-secondaryGray opacity-60">
              <FormattedMessage
                {...historyPodDeactivatedDetailsMessages.sequenceNoValue}
              />
            </p>
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
};
