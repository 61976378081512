import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'web/store/store';

export const selectIsConfidenceRemindersOn = createSelector(
  (state: RootState) => state.remindersSettings,
  (remindersSettings) => remindersSettings.confidenceRemindersOn
);

export const selectIsProgramRemindersOn = createSelector(
  (state: RootState) => state.remindersSettings,
  (remindersSettings) => remindersSettings.programRemindersOn
);
