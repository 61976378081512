import { combineReducers } from 'redux';
import basalReducer from './basal';
import bolusReducer from './bolus';
import languageReducer from './language';
import historyReducer from './multidayHistory';
import navigationReducer from './navigation';
import userReducer from './user';
import viewerManagementReducer from './viewerManagement';
import featureFlagsReducer from './featureFlags/featureFlagsSlice';
import sensorReducer from './sensor';
import customFoodsReducer from './customFoods';
import configuratorReducer from './configurator/configuratorSlice';
import modalsReducer from './modals/modalsSlice';
import { reducer as l2SettingsReducer } from './l2Settings/l2SettingsSlice';
import { reducer as remindersSettingsReducer } from './remindersSettings/remindersSettingsSlice';
import { reducer as basalProgramsReducer } from './basalPrograms/basalProgramsSlice';

export default combineReducers({
  basal: basalReducer,
  bolus: bolusReducer,
  user: userReducer,
  nav: navigationReducer,
  language: languageReducer,
  multidayHistory: historyReducer,
  viewerManagement: viewerManagementReducer,
  featureFlags: featureFlagsReducer,
  sensor: sensorReducer,
  customFoods: customFoodsReducer,
  configurator: configuratorReducer,
  l2Settings: l2SettingsReducer,
  modals: modalsReducer,
  remindersSettings: remindersSettingsReducer,
  basalPrograms: basalProgramsReducer,
});
