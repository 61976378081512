import { useNavigation } from '@react-navigation/native';
import { Button } from 'web/components/ios/Button';
import completeBasalSegments from 'web/assets/ios/complete-basal-segments.svg';
import { IOSBasalRoutesEnums } from '../types';
import { useIntl } from 'react-intl';
import {
  messages,
  BasalSegmentsMessages,
  CreateBasalSegmentsMessages,
} from '../Basal.messages';

interface props {
  editModeHandler?: any;
}

const BasalSegments: React.FC<props> = ({ editModeHandler }) => {
  const { navigate } = useNavigation();
  const intl = useIntl();

  const basalSegmentsOnClickHandler = (route: string) => {
    editModeHandler(true);
    navigate(route);
  };

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="basis-1/3 flex flex-col justify-between p-6 overflow-hidden">
        <div>
          <p className="font-bold text-lg text-modes-manual">
            {intl.formatMessage(messages.basalProgramName)}
          </p>
          <p className="font-bold text-xl">
            {`${intl.formatMessage(BasalSegmentsMessages.totalBasalTitle)} ${intl.formatMessage(BasalSegmentsMessages.totalBasalvalue)}`}
          </p>
        </div>

        <img
          src={completeBasalSegments}
          alt="segment-one-no-end-time-selected"
        />
      </div>
      <div className="basis-9/12">
        <div className="w-full h-full flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-center w-full pb-1 mb-1 border-b border-b-ios-gray-7 hover:cursor-pointer">
              <div className="text-sm font-semibold pl-6 text-ios-gray-9 opacity-[.63]">
                <p>
                  {intl.formatMessage(BasalSegmentsMessages.timeSegmentsLabel)}
                </p>
              </div>
              <div className="pr-6">
                <p className="text-sm font-semibold text-ios-gray-9 opacity-[.63]">
                  <span>
                    {intl.formatMessage(BasalSegmentsMessages.totalLabel)} 2
                  </span>
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                basalSegmentsOnClickHandler(IOSBasalRoutesEnums.SegmentOne);
              }}
              className="flex justify-between items-center w-full pb-2 mb-1 border-b border-b-ios-gray-7 hover:cursor-pointer"
            >
              <div className="pl-6">
                <p>
                  {`${intl.formatMessage(CreateBasalSegmentsMessages.segmentTitle)} 1: 12:00 AM - 8:00 AM`}
                </p>
                <p className="font-semibold text-xs text-ios-gray-2">
                  0.75 {intl.formatMessage(messages.units)}
                </p>
              </div>
              <div className="pr-6">
                <p className="font-semibold text-ios-gray-2">
                  <span>{'>'}</span>
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                basalSegmentsOnClickHandler(IOSBasalRoutesEnums.SegmentTwo);
              }}
              className="flex justify-between items-center w-full pb-2 mb-1 border-b border-b-ios-gray-7 hover:cursor-pointer"
            >
              <div className="pl-6">
                <p>
                  {`${intl.formatMessage(CreateBasalSegmentsMessages.segmentTitle)} 2: 8:00 AM - 12:00 AM`}
                </p>
                <p className="font-semibold text-xs text-ios-gray-2">
                  1 {intl.formatMessage(messages.units)}
                </p>
              </div>
              <div className="pr-6">
                <p className="font-semibold text-ios-gray-2">
                  <span>{'>'}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full p-6">
            <Button
              onClick={() => navigate(IOSBasalRoutesEnums.TemporaryBasalSwitch)}
              variant={'colored'}
            >
              {intl.formatMessage(messages.saveBtn)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasalSegments;
