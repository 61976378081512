import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  GestureResponderEvent,
} from 'react-native';
import { strings } from './ConfirmSensorSwitchContent';
import theme from 'mobile/latest/theme';
import useLanguageContent from 'mobile/latest/hooks/useLanguageContent';
import { SensorType } from 'web/data/sensors';

const styles = StyleSheet.create({
  title: {
    marginTop: 5,
  },
  paragraph: {
    marginTop: 20,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 20,
    color: '#000',
    marginTop: 40,
    marginBottom: 10,
  },
  buttonText: {
    color: theme.colors.purple,
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: '500',
  },
});

const ConfirmSensorSwitch = (props: {
  sensorSelected: SensorType;
  activeSensor: SensorType;
  cancelButtonOnPressHandler: (event: GestureResponderEvent) => void;
  confirmButtonOnPressHandler: (event: GestureResponderEvent) => void;
}) => {
  const {
    activeSensor,
    sensorSelected,
    cancelButtonOnPressHandler,
    confirmButtonOnPressHandler,
  } = props;
  const { languageContent } = useLanguageContent(strings);

  return (
    <>
      <Text style={[theme.fonts.h5, styles.title]}>
        {`${languageContent.confirmSwitchTo} \n${sensorSelected}`}
      </Text>
      <Text style={styles.paragraph}>
        {`${languageContent.youAreSwitchingFrom} ${activeSensor} ${languageContent.to} ${sensorSelected}.`}
      </Text>
      <Text style={styles.paragraph}>
        {languageContent.activeSensorDescription}
      </Text>
      <View style={styles.buttonsContainer}>
        <TouchableOpacity onPress={cancelButtonOnPressHandler}>
          <Text style={styles.buttonText}>{languageContent.cancel}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={confirmButtonOnPressHandler}>
          <Text style={styles.buttonText}>{languageContent.confirm}</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

export default ConfirmSensorSwitch;
