import React from "react";
import {
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View
} from "react-native-web";
import theme from "../../../theme";

interface TooltipModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  title?: string;
  body: string | JSX.Element;
  position: "top" | "mid" | "bottom";
}

const TooltipModal: React.FC<TooltipModalProps> = ({
  isVisible,
  onDismiss,
  title,
  body,
  position
}) => {
  const { height } = useWindowDimensions();
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onRequestClose={onDismiss}>
      <View
        style={{
          ...styles.centeredView,
          width: "100%"
        }}>
        <TouchableOpacity onPress={onDismiss} activeOpacity={1}>
          <View
            style={[
              styles.modal,
              {
                marginTop:
                  position === "top"
                    ? height * 0.05
                    : 0 || position === "mid"
                    ? height * -0.1
                    : 0 || position === "bottom"
                    ? height * 0.2
                    : 0,
                marginBottom:
                  position === "top"
                    ? height * 0.5
                    : 0 || position === "mid"
                    ? height * 0.2
                    : 0,
                marginLeft: -20
              }
            ]}>
            <View>
              {title && (
                <Text style={[theme.fonts.body1, styles.title]}>{title}</Text>
              )}
            </View>
            {body}
          </View>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.75)",
    paddingHorizontal: 40
  },
  modal: {
    flexDirection: "column",
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderWidth: 3,
    borderRadius: 5,
    borderColor: theme.colors.mango,
    backgroundColor: theme.colors.white
  },
  title: {
    fontWeight: "700",
    color: theme.colors.mango,
    marginBottom: 8
  }
});

export default TooltipModal;
