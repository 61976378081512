import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { ReactComponent as NextScreen } from 'assets/next_screen.svg';
import theme from 'mobile/latest/theme';
import {
  NavState,
  navigationActions,
} from 'web/store/reducers/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'web/store/store';
import { isEqual } from 'lodash';

interface IntroLayoutProps {
  heading?: string;
  body: string;
  image: any;
  navigateTo?: string;
  navigateToHandler?: () => void;
}

const IntroScreenLayout: React.FC<IntroLayoutProps> = ({
  heading,
  body,
  image,
  navigateTo,
  navigateToHandler,
}) => {
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const { navigate } = useNavigation();
  const dispatch = useDispatch();

  const onPressHandler = () => {
    if(!navigateTo){
      return
    }
    if (navigateTo === 'Drawer') {
      dispatch(
        navigationActions.navigationUpdate(
          { navigateTo: 'Dashboard' },
          navState
        )
      );
    }
    navigate(navigateTo);
  };

  return (
    <View style={[styles.innerWrapper]}>
      <View style={{ alignItems: 'center' }}>
        {heading ? (
          <Text
            style={[
              theme.fonts.h3,
              {
                marginTop: 32,
                paddingHorizontal: 42,
                textAlign: 'center',
                fontWeight: '700',
              },
            ]}
          >
            {heading}
          </Text>
        ) : null}
        <Text
          style={[
            theme.fonts.body3,
            {
              paddingHorizontal: 42,
              marginTop: heading ? 16 : 32,
              marginBottom: 42,
              textAlign: 'left',
              fontSize: 16,
            },
          ]}
        >
          {body}
        </Text>
      </View>
      <View style={{ flex: 1, width: '100%', justifyContent: 'center' }}>
        <View
          pointerEvents="box-none"
          style={{
            marginBottom: -32,
            borderRadius: 50,
            zIndex: 2,
            alignSelf: 'center',
          }}
        >
          <TouchableOpacity
            onPress={navigateToHandler ? navigateToHandler : onPressHandler}
          >
            <NextScreen
              style={{
                height: 64,
                width: 64,
                backgroundColor: theme.colors.purple,
                borderRadius: 64,
              }}
            />
          </TouchableOpacity>
        </View>
        <Image
          resizeMode="cover"
          resizeMethod="resize"
          style={{ width: '110%', height: '100%' }}
          source={image}
        />
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  innerWrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
});
export default IntroScreenLayout;
