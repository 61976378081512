import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import { isEqual } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { BackButton } from '../components/atoms';
import {
  AboutScreen,
  HistoryDetailScreen,
  NotificationsScreen,
  PauseInsulinScreen,
  PodDetailsScreen,
  StartInsulinScreen,
} from '../components/screens';
import { LegalInformationScreen } from '../components/screens/main';
import LogFilesPinScreen from '../components/screens/main/aboutScreen/logFilesPin';
import ReleaseNotesScreen from '../components/screens/main/aboutScreen/releaseNotes';
import SendLogFilesScreen from '../components/screens/main/aboutScreen/sendLogFiles';
import EnterBGScreen from '../components/screens/simulator/dashboard/enterBGScreen/EnterBGScreen';
import {
  BasalTempSettingsScreen,
  CGMBGGoalRangeSettingsScreen,
  GeneralSettingsScreen,
  LanguageSettingsScreen,
  MaxBasalRateSettingsScreen,
  ReminderSettingsScreen,
} from '../components/screens/simulator/settings';
import { ClearAllDataScreen } from '../components/screens/simulator/settings/clearAllData';
import { ClearAllDataReadyScreen } from '../components/screens/simulator/settings/clearAllDataReady';
import { PodExpirationSettingsScreen } from '../components/screens/simulator/settings/remindersSettings';
import LowPodInsulinScreen from '../components/screens/simulator/settings/remindersSettings/lowPodInsulinScreen';
import PodShutOffScreen from '../components/screens/simulator/settings/remindersSettings/podShutOffScreen';
import { useModeIconSmall } from '../hooks';
import useLanguageContent from '../hooks/useLanguageContent';
import {
  navigationActions,
  NavState,
} from '../../../web/store/reducers/navigation';
import { RootState } from '../../../web/store/store';
import theme from '../theme';
import { strings } from './NestedSettingsStackContent';
import ManageSensorG7Stack from 'web/routes/G7/ManageSensorG7Stack';
import ManageSensorG6Stack from 'web/routes/G6/ManageSensorG6Stack';
import { ManageSensorL2Stack } from 'web/routes/L2/ManageSensorL2Stack';
import { sensors } from 'web/data/sensors';
import { SensorState } from 'web/store/reducers/sensor';

const Stack = createStackNavigator();

const defaultHeaderStyle = theme.layout.defaultHeaderStyle;

const PodDetailsStack: React.FC = () => {
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const dispatch = useDispatch();
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator initialRouteName="PodDetails" mode="card">
        <Stack.Screen
          name="PodDetails"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: 'horizontal',
            headerLeft: () => (
              <BackButton
                text={languageContent.pod}
                white={false}
                navigateTo={'Drawer'}
                beforeNavigation={() =>
                  dispatch(
                    navigationActions.navigationUpdate(
                      { navigateTo: 'Dashboard' },
                      navState
                    )
                  )
                }
              />
            ),
            headerRight: () => icon,
          }}
          component={PodDetailsScreen}
        />
      </Stack.Navigator>
    </View>
  );
};

const EnterBGStack: React.FC = () => {
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator initialRouteName="EnterBG" mode="card">
        <Stack.Screen
          name="EnterBG"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureDirection: 'horizontal',
            headerLeft: () => (
              <BackButton text={languageContent.bg} navigateTo={'Drawer'} />
            ),
            headerRight: () => icon,
          }}
          component={EnterBGScreen}
        />
      </Stack.Navigator>
    </View>
  );
};

const HistoryDetailStack: React.FC = () => {
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator initialRouteName="History" mode="card">
        <Stack.Screen
          name="History"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.history}
                white={false}
                navigateTo="Drawer"
              />
            ),
            headerRight: () => icon,
          }}
          component={HistoryDetailScreen}
        />
      </Stack.Navigator>
    </View>
  );
};

const NotificationsStack: React.FC = () => {
  const navState: NavState = useSelector(
    (state: RootState) => state.nav,
    isEqual
  );

  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);
  const dispatch = useDispatch();

  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator initialRouteName="default" mode="card">
        <Stack.Screen
          name="default"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => {
              return (
                <View style={{ paddingTop: 4 }}>
                  <BackButton
                    text={languageContent.notifications}
                    white={false}
                    beforeNavigation={() =>
                      dispatch(
                        navigationActions.navigationUpdate(
                          { navigateTo: 'Dashboard' },
                          navState
                        )
                      )
                    }
                  />
                </View>
              );
            },
            headerRight: () => icon,
          }}
          component={NotificationsScreen}
        />
      </Stack.Navigator>
    </View>
  );
};

const PauseInsulinStack: React.FC = () => {
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator initialRouteName="default" mode="card">
        <Stack.Screen
          name="default"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.pauseInsulin}
                white={false}
                navigateTo="Drawer"
              />
            ),
            headerRight: () => icon,
          }}
          component={PauseInsulinScreen}
        />
      </Stack.Navigator>
    </View>
  );
};

const StartInsulinStack: React.FC = () => {
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator initialRouteName="default" mode="card">
        <Stack.Screen
          name="default"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.startInsulin}
                white={false}
                navigateTo="Drawer"
              />
            ),
            headerRight: () => icon,
          }}
          component={StartInsulinScreen}
        />
      </Stack.Navigator>
    </View>
  );
};

const CGMTransmitterSettingsStack: React.FC = () => {
  const sensorState: SensorState = useSelector(
    (state: RootState) => state.sensor,
    isEqual
  );

  switch(sensorState.activeSensor) {
    case sensors.G7:
      return <ManageSensorG7Stack />;
    case sensors.L2:
      return <ManageSensorL2Stack />;
    default:
      return <ManageSensorG6Stack />;
  }
};

const RemindersSettingsStack: React.FC = () => {
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator initialRouteName="default" mode="card">
        <Stack.Screen
          name="default"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.reminders} white={false} />
            ),
            headerRight: () => icon,
          }}
          component={ReminderSettingsScreen}
        />
        <Stack.Screen
          name="PodExpiration"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.podExpiration} white={false} />
            ),
            headerRight: () => icon,
          }}
          component={PodExpirationSettingsScreen}
        />
        <Stack.Screen
          name="LowPodInsulin"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.lowPodInsulin} white={false} />
            ),
            headerRight: () => icon,
          }}
          component={LowPodInsulinScreen}
        />
        <Stack.Screen
          name="PodShutOff"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.podShutOff} white={false} />
            ),
            headerRight: () => icon,
          }}
          component={PodShutOffScreen}
        />
      </Stack.Navigator>
    </View>
  );
};

const CGMBGGoalRangeSettingsStack: React.FC = () => {
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator initialRouteName="default" mode="card">
        <Stack.Screen
          name="default"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.glucoseGoalRange}
                white={false}
              />
            ),
            headerRight: () => icon,
          }}
          component={CGMBGGoalRangeSettingsScreen}
        />
      </Stack.Navigator>
    </View>
  );
};

const BasalTempSettingsStack: React.FC = () => {
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator initialRouteName="default" mode="card">
        <Stack.Screen
          name="default"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.basalAndTempBasal}
                white={false}
              />
            ),
            headerRight: () => icon,
          }}
          component={BasalTempSettingsScreen}
        />
        <Stack.Screen
          name="SettingsMaxBasalRate"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.maxBasalRate} white={false} />
            ),
            headerRight: () => icon,
          }}
          component={MaxBasalRateSettingsScreen}
        />
      </Stack.Navigator>
    </View>
  );
};

const AboutScreenStack: React.FC = () => {
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator initialRouteName="AboutScreen" mode="card">
        <Stack.Screen
          name="AboutScreen"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.about} white={false} />
            ),
            headerRight: () => icon,
          }}
          component={AboutScreen}
        />
        <Stack.Screen
          name="ReleaseNotes"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.releaseNotes} white={false} />
            ),
            headerRight: () => icon,
          }}
          component={ReleaseNotesScreen}
        />
        <Stack.Screen
          name="LegalInformation"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton
                text={languageContent.legalInformation}
                white={false}
              />
            ),
            headerRight: () => icon,
          }}
          component={LegalInformationScreen}
        />
        <Stack.Screen
          name="SendLogFiles"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.sendLogFiles} white={false} />
            ),
            headerRight: () => icon,
          }}
          component={SendLogFilesScreen}
        />
        <Stack.Screen
          name="LogFilesPin"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.logFilesPin} white={false} />
            ),
            headerRight: () => icon,
          }}
          component={LogFilesPinScreen}
        />
      </Stack.Navigator>
    </View>
  );
};

const GeneralSettingsStack: React.FC = () => {
  const { icon } = useModeIconSmall();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator initialRouteName="default" mode="card">
        <Stack.Screen
          name="default"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.general} white={false} />
            ),
            headerRight: () => icon,
          }}
          component={GeneralSettingsScreen}
        />
        <Stack.Screen
          name="SelectLanguageSettings"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.general} white={false} />
            ),
            headerRight: () => icon,
          }}
          component={LanguageSettingsScreen}
        />
        <Stack.Screen
          name="ClearAllData"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.reset} white={false} />
            ),
            headerRight: () => icon,
          }}
          component={ClearAllDataScreen}
        />
        <Stack.Screen
          name="ResetPDMReady"
          options={{
            title: '',
            headerStyle: defaultHeaderStyle,
            headerLeft: () => (
              <BackButton text={languageContent.reset} white={false} />
            ),
            headerRight: () => icon,
          }}
          component={ClearAllDataReadyScreen}
        />
      </Stack.Navigator>
    </View>
  );
};

export {
  NotificationsStack,
  PodDetailsStack,
  PauseInsulinStack,
  StartInsulinStack,
  HistoryDetailStack,
  CGMTransmitterSettingsStack,
  RemindersSettingsStack,
  CGMBGGoalRangeSettingsStack,
  BasalTempSettingsStack,
  AboutScreenStack,
  GeneralSettingsStack,
  EnterBGStack,
};
