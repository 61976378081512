import theme from "../theme";

const getBasalRateAdornmentColor = (basalRateLabel: string) => {
  if (!basalRateLabel) {
    return theme.colors.placeholder;
  } else {
    return theme.colors.adornment;
  }
};

export default getBasalRateAdornmentColor;
