import React from 'react';
import classNames from 'classnames';
import { IconProps } from './types';

export const CheckIcon: React.FC<IconProps> = ({ color }) => {
  const classes = classNames(color, {
    'fill-ios-baseIcon': !color,
  });

  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes}
        d="M5.75684 14.8364C5.3999 14.8364 5.14258 14.6787 4.89355 14.355L0.585449 8.86816C0.427734 8.66895 0.344727 8.45312 0.344727 8.26221C0.344727 7.80566 0.668457 7.48193 1.1416 7.48193C1.44043 7.48193 1.64795 7.59814 1.85547 7.88867L5.72363 12.9521L13.1777 1.07373C13.377 0.758301 13.5596 0.650391 13.8916 0.650391C14.3481 0.650391 14.647 0.949219 14.647 1.40576C14.647 1.59668 14.5889 1.7793 14.4395 2.02002L6.59521 14.3716C6.396 14.6787 6.11377 14.8364 5.75684 14.8364Z"
        fill="currentColor"
      />
    </svg>
  );
};
