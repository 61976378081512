import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import { Button } from "../../../../atoms";
import { strings } from "./ClearAllDataScreenContext";

const ClearAllDataScreen: React.FC = () => {
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );

  const { navigate } = useNavigation();
  const { languageContent } = useLanguageContent(strings);

  return (
    <View style={[styles.container]}>
      <Text style={[styles.subtext]}>{languageContent.clearData}</Text>
      <View
        style={[
          styles.subContainer,
          { height: langState.language === "de-DE" ? "32%" : "28%" }
        ]}>
        <Text style={[styles.subtext]}>{languageContent.reset}</Text>
        <Button
          variant={"primary"}
          disabled={false}
          style={{ elevation: 6, marginTop: 12, width: "90%", marginLeft: 14 }}
          onPress={() => {
            navigate("ResetPDMReady");
          }}>
          {languageContent.deactivatePod}
        </Button>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  subtext: {
    ...theme.fonts.body2,
    fontWeight: "400",
    marginLeft: 16,
    marginTop: 16,
    marginBottom: 16
  },
  container: {
    backgroundColor: "white",
    height: "100%"
  },
  subContainer: {
    backgroundColor: "#EDF2F6"
  }
});
export default ClearAllDataScreen;
