import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { NavigationContainer } from '@react-navigation/native';
import { useIntl } from 'react-intl';
import { isEqual } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'web/store/store';
import { WarningMessageBar } from '../../components/molecules';
import {
  DashboardScreen,
  InsulinManualScreen,
  InsulinScreen,
  PodInfoScreen,
} from '../../components/screens';
import theme from '../../theme';
import { messages } from './TabStack.messages';

const Tab = createMaterialTopTabNavigator();

interface Props {
  setActiveTab: (index: number) => void;
}

const TabStack: React.FC<Props> = ({ setActiveTab }) => {
  const userState = useSelector((state: RootState) => state.user, isEqual);
  const basalState = useSelector((state: RootState) => state.basal, isEqual);
  const TabTheme = {
    dark: false,
    colors: {
      primary: theme.colors.blue,
      background: userState.limitedMode
        ? theme.colors.limitedMode
        : userState.automatedMode
          ? theme.colors.purple
          : theme.colors.tabBackground,
      card: theme.colors.white,
    },
  };
  const intl = useIntl();

  return (
    <>
      {basalState.insulinPaused && !userState.automatedMode ? (
        <WarningMessageBar insulinDelivery />
      ) : null}
      <NavigationContainer independent theme={TabTheme as any}>
        <Tab.Navigator
          initialRouteName="Dashboard"
          tabBarOptions={{
            activeTintColor: theme.colors.blue,
            inactiveTintColor: theme.colors.grayScale.gray200,
            allowFontScaling: false,
            labelStyle: {
              fontSize: 14,
              fontWeight: '400',
              alignSelf: 'center',
              width: '100%',
            },
            style: {
              shadowColor: '#888',
              shadowOpacity: 0.4,
              shadowRadius: 3,
              shadowOffset: {
                height: 3,
                width: 0,
              },
              elevation: 8,
            },
          }}
          tabBarPosition="top"
        >
          <Tab.Screen
            name={intl.formatMessage(messages.dashboardTabTitle)}
            component={DashboardScreen}
            listeners={{
              focus: (e: any) => {
                setActiveTab(0);
              },
            }}
          />
          <Tab.Screen
            name={intl.formatMessage(messages.insulinTabTitle)}
            component={
              userState.automatedMode ? InsulinScreen : InsulinManualScreen
            }
            listeners={{
              focus: (e: any) => {
                setActiveTab(1);
              },
            }}
          />
          <Tab.Screen
            name={intl.formatMessage(messages.podInfoTabTitle)}
            component={PodInfoScreen}
            listeners={{
              focus: (e: any) => {
                setActiveTab(2);
              },
            }}
          />
        </Tab.Navigator>
      </NavigationContainer>
    </>
  );
};

export default TabStack;
