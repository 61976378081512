import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import moment from "moment";
import React from "react";
import { Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import replaceWithValues from "../../../../../helpers/replaceWithValues";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { basalActions, BasalState } from "../../../../../../../web/store/reducers/basal";
import { LangState } from "../../../../../../../web/store/reducers/language";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import PickerItem from "../../../../../types/pickerItem";
import { Button } from "../../../../atoms";
import { BottomInnerNav } from "../../../../molecules";
import { strings } from "./PauseInsulinScreenContent";
import getCurrentHourFormat from "../../../../../helpers/getCurrentHourFormat";

const PauseInsulinScreen: React.FC = () => {
  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );
  const langState: LangState = useSelector(
    (state: RootState) => state.language,
    isEqual
  );
  const [duration, setDuration] = React.useState<PickerItem>({
    label: "––",
    value: -1,
    key: "INIT",
    index: 0
  });
  const [ends, setEnds] = React.useState<any>();
  const [endsDate, setEndsDate] = React.useState<Date>();
  const [isPressed, setIsPressed] = React.useState<boolean>(false);

  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const { languageContent } = useLanguageContent(strings);

  const insulinLowHrs = (0.5).toLocaleString(langState.locale);
  const contentVariables = [insulinLowHrs];

  const PauseInsulinDetails = () => (
    <View style={{ paddingHorizontal: 16 }}>
      <Text style={[styles.headerText]}>{languageContent.pauseDelivery}</Text>
      <Text style={[styles.mainText]}>
        {languageContent.extendedBolusCancelled}
      </Text>
    </View>
  );

  const onPauseConfirm = () => {
    try {
      dispatch(
        basalActions.basalUpdate(
          {
            insulinPaused: true,
            insulinPausedTimestamp: String(endsDate)
          },
          basalState
        )
      );
      setTimeout(() => {
        navigate("Drawer");
      }, 150);
    } catch (err) {
      console.error(err);
    }
  };

  const getAdornmentColor = () => {
    if (duration.label === "––") {
      return theme.colors.placeholder;
    } else {
      return theme.colors.adornment;
    }
  };

  const getCalcDuration = () => {
    if (!duration || duration.label === "––" || !duration.value) {
      return replaceWithValues(languageContent.range, contentVariables);
    } else {
      return `${languageContent.ends} ${ends}`;
    }
  };

  React.useEffect(() => {
    if (!duration || duration.label === "––" || !duration.value) {
      return;
    }

    const today = new Date();
    const end = moment(today).add(Number(duration.label), "h").toDate();

    //to be stored in context and Realm
    setEndsDate(new Date(end));

    //this is only for the Ends section under Duration
    const endsDate = end.toLocaleDateString(langState.locale, {
      month: "short",
      day: "2-digit"
    });
    const endsTime = moment(end).format("h:mm a");
    setEnds(
      `${endsDate} ${getCurrentHourFormat({
        timeStr: endsTime,
        is24Hour: langState.is24Hour
      })}`
    );
  }, [duration, langState]);

  return (
    <View style={{ backgroundColor: "white", flex: 1 }}>
      <View style={[{ marginTop: 24, marginBottom: 32 }]}>
        <PauseInsulinDetails />
        <View
          style={{
            paddingHorizontal: 16,
            paddingVertical: 12,
            height: 158,
            zIndex: -1
          }}>
          <Text style={[styles.durationHeader]}>
            {languageContent.pauseInsulin}
          </Text>
          <Text style={[styles.subHeader]}>{getCalcDuration()}</Text>
          <>
            <Pressable
              style={[
                theme.layout.inputContainerStyle,
                {
                  borderColor: theme.colors.textInputBorder,
                  width: 270,
                  height: 60
                }
              ]}
              disabled={isPressed}
              onPress={() => {
                setDuration({ label: "2", value: -1, key: "INIT", index: 0 });
                setIsPressed(true);
              }}>
              <TextInput
                editable={false}
                style={[
                  theme.layout.inputStyle,
                  { width: 220, marginBottom: -2, borderColor: "black" }
                ]}
                selectionColor={theme.colors.blue}
                placeholder={"––"}
                placeholderTextColor={theme.colors.placeholder}
                value={duration.label}
              />

              <Text
                style={[
                  theme.layout.adornment,
                  {
                    color: getAdornmentColor(),
                    marginLeft: langState.language === "de-DE" ? -12 : 0
                  }
                ]}>
                {duration.value == 1 || duration.value == 0.5
                  ? languageContent.hr
                  : languageContent.hrs}
              </Text>
            </Pressable>
          </>
        </View>
      </View>

      <View style={{ marginBottom: 48 }}>
        <Button
          variant="primary"
          textStyle={{ fontSize: 14 }}
          style={{
            elevation: 6,
            marginHorizontal: 16,
            marginTop: 75,
            marginBottom: 20
          }}
          onPress={() => {
            if (duration.label !== "2") {
              return;
            }
            onPauseConfirm();
          }}>
          {languageContent.pause}
        </Button>
      </View>
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.leftActionText}
        leftActionNavigateTo="Drawer"
        leftActionStyle={{ color: theme.colors.text.cancel, fontSize: 16 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  headerText: {
    ...theme.fonts.h5,
    marginBottom: 24
  },
  mainText: {
    ...theme.fonts.text400,
    fontSize: 15,
    fontWeight: "400",
    marginBottom: 24
  },
  durationHeader: {
    ...theme.fonts.h5
  },
  subHeader: {
    ...theme.fonts.text400,
    color: theme.colors.text.grayText,
    marginBottom: 6,
    fontWeight: "400"
  },

  ios: {
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 8,
    backgroundColor: theme.colors.textInputBackgroundColor,
    color: "#000",
    fontSize: 40
  },
  container: {
    paddingTop: 60,
    paddingBottom: 30,
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: theme.colors.textInputBackgroundColor
  },
  wrapperHorizontal: {
    height: 100,
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    color: "black"
  },
  wrapperVertical: {
    height: 200,
    justifyContent: "center",
    alignItems: "center",
    margin: 24,
    color: "black"
  },
  OptionWrapper: {
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 32,
    paddingRight: 16,
    height: 46,
    borderWidth: 3,
    borderRadius: 10
  }
});

export default PauseInsulinScreen;
