import { isEqual } from "lodash";
import React from "react";
import {
  Dimensions,
  Platform,
  PlatformIOSStatic,
  StyleSheet,
  Text,
  View
} from "react-native";
import { useSelector } from "react-redux";
import { ReactComponent as IndicatorAutomated } from "../../../../../../../assets/Indicator_Automated.svg";
import { ReactComponent as IndicatorLimited } from "../../../../../../../assets/Indicator_Limited.svg";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import TabStackHeader from "../../../../atoms/tabStackHeader/TabStackHeader";
import { strings } from "./InsulinScreenContent";

const platform = Platform as PlatformIOSStatic;

const { height } = Dimensions.get("window");
const InsulinScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );
  const { languageContent } = useLanguageContent(strings);
  const getHeight = () => {
    if (platform.isPad) {
      return "100%";
    } else {
      if (height < 700 && height > 600) return "70%";
      else if (height <= 600) return "65%";
      else if (height > 700) return "100%";
    }
  };
  return (
    <View style={[styles.innerWrapper]}>
      <View style={[styles.card]}>
        <TabStackHeader label={"0.15 U"} hasIOB />
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flex: 1,
            marginBottom: 24
          }}>
          <Text
            style={[
              theme.fonts.text200,
              {
                marginLeft: 2,
                marginTop: 24,
                color: userState.limitedMode
                  ? theme.colors.limitedMode
                  : theme.colors.purple
              }
            ]}>
            {languageContent.autoMode}{" "}
            {userState.limitedMode
              ? languageContent.limited
              : languageContent.active}
          </Text>
          <View style={{ height: "65%" }}>
            {userState.limitedMode ? (
              <IndicatorLimited height={getHeight()} />
            ) : (
              <IndicatorAutomated height={getHeight()} />
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    padding: 8
  },
  card: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.white,
    shadowColor: "#888",
    shadowOpacity: 0.7,
    shadowRadius: 6,
    shadowOffset: {
      height: 1,
      width: 0
    },
    elevation: 10
  },
  bigNumber: {
    fontSize: Dimensions.get("screen").height < 700 ? 50 : 90,
    fontWeight: "400",
    color: theme.colors.blue,
    textAlign: "center",
    textAlignVertical: "center"
  },
  measurement: {
    fontFamily: theme.fonts.primary.medium,
    textAlign: "center",
    textAlignVertical: "center",
    marginBottom: "15%"
  }
});

export default InsulinScreen;
