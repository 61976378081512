import React, { PropsWithChildren } from 'react';

interface MenuSectionProps {
  className?: string;
  title: string;
}

export const MenuSection: React.FC<PropsWithChildren<MenuSectionProps>> = ({
  className,
  title,
  children,
}) => {
  return (
    <div className={className}>
      <div className="px-2 py-1 text-modals text-menuItems">{title}</div>
      <div>{children}</div>
    </div>
  );
};
