import React from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../BasalTempSettingsStack.messages';
import { SettingsRow } from 'web/components/ios/SettingsRow';
import { useNavigation } from '@react-navigation/native';
import { BasalTempSettingRoutesEnum } from '../types';
import { useSelector } from 'react-redux';
import { BasalState } from 'web/store/reducers/basal';
import { RootState } from 'web/store/store';
import { isEqual } from 'lodash';

export const BasalTempSettings = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();

  const basalState: BasalState = useSelector(
    (state: RootState) => state.basal,
    isEqual
  );

  return (
    <div className="bg-ios-gray-6 pt-4 h-full flex flex-col justify-between text-regular font-ios">
      <div>
        <div className="bg-white">
          <SettingsRow
            text={intl.formatMessage(messages.maxBasalRate)}
            subText={`5 ${intl.formatMessage(messages.basalRateUnit)}`}
            showCaret
            onClick={() => navigate(BasalTempSettingRoutesEnum.MaxBasalRate)}
          />
          <SettingsRow
            text={intl.formatMessage(messages.tempBasal)}
            subText={
              basalState.adjustBy
                ? intl.formatMessage(messages.configurePercent, {
                    unit: basalState.adjustBy,
                  })
                : intl.formatMessage(messages.tapToTurnOn)
            }
            showCaret
            showStatus={!basalState.adjustBy}
            status="Off"
            onClick={() => navigate(BasalTempSettingRoutesEnum.TempBasal)}
          />
        </div>
      </div>
    </div>
  );
};
