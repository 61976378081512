import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import React from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native-web";
import { TextInput } from "react-native-paper";
import { useSelector } from "react-redux";
import useLanguageContent from "../../../../../hooks/useLanguageContent";
import { UserState } from "../../../../../../../web/store/reducers/user";
import { RootState } from "../../../../../../../web/store/store";
import theme from "../../../../../theme";
import {
  BottomInnerNav,
  ConfirmationModal,
  PodCommAlert,
} from "../../../../molecules";
import { strings } from "./AddViewersScreenContent";

const AddViewersScreen: React.FC = () => {
  const userState: UserState = useSelector(
    (state: RootState) => state.user,
    isEqual
  );

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showPodComm, setShowPodComm] = React.useState<boolean>(false);

  const { languageContent } = useLanguageContent(strings);

  const [firstName, setFirstName] = React.useState<string>(
    languageContent.firstNameLabel
  );
  const [lastName, setLastName] = React.useState<string>(
    languageContent.lastNameLabel
  );
  const [email, setEmail] = React.useState<string>(languageContent.emailLabel);
  const [confirmEmail, setConfirmEmail] = React.useState<string>(
    languageContent.confirmEmailLabel
  );
  const [relationship, setRelationship] = React.useState<string>(
    languageContent.relationshipOptional
  );

  const [firstNameLabel, setFirstNameLabel] = React.useState<string>("");
  const [lastNameLabel, setLastNameLabel] = React.useState<string>("");
  const [emailLabel, setEmailLabel] = React.useState<string>("");
  const [confirmEmailLabel, setConfirmEmailLabel] = React.useState<string>("");
  const [relationshipLabel, setRelationshipLabel] = React.useState<string>("");

  const [proceed, setProceed] = React.useState<boolean>(false);

  const { navigate } = useNavigation();

  const inputRef = React.useRef();

  const setValues = () => {
    //TODO FIX THIS
    /* !userState.isExpert ? setShowModal(true) : null; */
    setFirstNameLabel(languageContent.firstNameLabel);
    setFirstName(languageContent.fName);
    setLastNameLabel(languageContent.lastNameLabel);
    setLastName(languageContent.lName);
    setEmailLabel(languageContent.emailLabel);
    setEmail(languageContent.email);
    setConfirmEmailLabel(languageContent.confirmEmailLabel);
    setConfirmEmail(languageContent.email);
    setRelationshipLabel(languageContent.relationshipLabel);
    setRelationship(languageContent.family);
    setProceed(true);
  };

  return (
    <>
      <ScrollView>
        <TouchableOpacity onPress={setValues}>
          <View style={styles.headerStyle}>
            <Text
              style={
                theme.fonts.body1
              }>{`${languageContent.invitation} Omnipod\u00a05 ${languageContent.invitationCont}`}</Text>
          </View>
          <View style={{ paddingHorizontal: 18 }}>
            {/* @ts-ignore */}
            <TextInput
              style={styles.inputStyle}
              mode="outlined"
              theme={{
                colors: {
                  text:
                    firstNameLabel === ""
                      ? theme.colors.graphBlue
                      : theme.colors.text.dark,
                  placeholder:
                    firstNameLabel === ""
                      ? theme.colors.placeholder
                      : theme.colors.text.dark,
                },
              }}
              activeOutlineColor={theme.colors.purple}
              label={firstNameLabel}
              value={firstName}
              onFocus={setValues}
            />
            {/* @ts-ignore */}
            <TextInput
              style={styles.inputStyle}
              mode="outlined"
              label={lastNameLabel}
              theme={{
                colors: {
                  text:
                    lastNameLabel === ""
                      ? theme.colors.graphBlue
                      : theme.colors.text.dark,
                  placeholder:
                    lastNameLabel === ""
                      ? theme.colors.placeholder
                      : theme.colors.text.dark,
                },
              }}
              activeOutlineColor={theme.colors.purple}
              selectionColor={"transparent"}
              value={lastName}
              onFocus={setValues}
            />
            {/* @ts-ignore */}
            <TextInput
              style={styles.inputStyle}
              mode="outlined"
              label={emailLabel}
              theme={{
                colors: {
                  text:
                    emailLabel === ""
                      ? theme.colors.graphBlue
                      : theme.colors.text.dark,
                  placeholder:
                    emailLabel === ""
                      ? theme.colors.placeholder
                      : theme.colors.text.dark,
                },
              }}
              activeOutlineColor={theme.colors.purple}
              selectionColor={"transparent"}
              value={email}
              onFocus={setValues}
            />
            {/* @ts-ignore */}
            <TextInput
              style={styles.inputStyle}
              mode="outlined"
              label={confirmEmailLabel}
              theme={{
                colors: {
                  text:
                    confirmEmailLabel === ""
                      ? theme.colors.graphBlue
                      : theme.colors.text.dark,
                  placeholder:
                    confirmEmailLabel === ""
                      ? theme.colors.placeholder
                      : theme.colors.text.dark,
                },
              }}
              activeOutlineColor={theme.colors.purple}
              selectionColor={"transparent"}
              value={confirmEmail}
              onFocus={setValues}
            />
            {/* @ts-ignore */}
            <TextInput
              style={styles.inputStyle}
              mode="outlined"
              label={relationshipLabel}
              theme={{
                colors: {
                  text:
                    relationshipLabel === ""
                      ? theme.colors.graphBlue
                      : theme.colors.text.dark,
                  placeholder:
                    relationshipLabel === ""
                      ? theme.colors.placeholder
                      : theme.colors.text.dark,
                },
              }}
              activeOutlineColor={theme.colors.purple}
              selectionColor={"transparent"}
              placeholder={languageContent.relationshipLabel}
              value={relationship}
              onFocus={setValues}
            />
          </View>
          {/* guided mode delete modal */}
          <ConfirmationModal
            isVisible={showModal}
            title={languageContent.viewerDetails}
            message={
              <View>
                <Text style={[theme.fonts.body1, { color: "white" }]}>
                  {languageContent.modalAutoFill}
                </Text>
              </View>
            }
            purple
            hideDismiss={true}
            confirmText={languageContent.ok}
            onConfirm={() => {
              setValues();
              setShowModal(false);
            }}
          />
        </TouchableOpacity>
        {showPodComm ? (
          <PodCommAlert
            title={languageContent.connecting}
            message={languageContent.pleaseWait}
            duration={3000}
            onDismiss={() => {
              navigate("ViewAppDisclosure", {
                firstName: firstName,
                lastName: lastName,
                email: email,
                relationship: relationship,
              });
              setShowPodComm(false);
            }}
          />
        ) : null}
      </ScrollView>
      <BottomInnerNav
        leftAction={true}
        leftActionText={languageContent.leftActionText}
        leftActionStyle={{ color: theme.colors.text.cancel }}
        rightActionText={languageContent.rightActionText}
        rightActionStyle={{
          color: !proceed ? theme.colors.placeholder : theme.colors.purple,
        }}
        leftNavigationAction={() => {
          navigate("Drawer");
        }}
        rightAction={() => {
          //TODO FIX THIS
          /* proceed ? setShowPodComm(true) : null; */
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  headerStyle: {
    padding: 16,
    marginTop: 12,
  },
  inputStyle: {
    height: 50,
    marginTop: 24,
    backgroundColor: theme.colors.white,
  },
});

export default AddViewersScreen;
