let strings = {
  "en-US": {
    declined: "Training has been declined.",
    confirm: "You must confirm the following:",
    accept: "I accept the terms above.",
    continue: "Continue",
    termsOfUse:
      "1. I have reviewed the instructions, precautions, and warnings found in the User Guide provided with the Omnipod 5, and I fully understand those instructions, precautions, and warnings.\n\n2. I understand that adequate training is critical to safe setup and use of the Omnipod 5.\n\n3. I understand that improper setup and/or use of the Omnipod 5 may cause under- or over- delivery of insulin, which may lead to hypoglycemia or hyperglycemia, and may put my health and safety at risk, and could lead to death.\n\n4. By using the Omnipod 5 without receiving training from a healthcare provider regarding proper setup and use of the Omnipod 5 (contrary to the warnings, precautions, and instructions provided herein and in the User Guide), I voluntarily accept any risk to my health and safety resulting from improper setup or use of the Omnipod 5.\n\n5. I hereby release the manufacturers, designers, distributors, and sellers of the Omnipod 5 from any and all liability in connection with any injury I may sustain resulting from improper setup or use of the Omnipod 5."
  },
  "en-GB": {
    declined: "Training has been declined.",
    confirm: "You must confirm the following:",
    accept: "I accept the terms above.",
    continue: "Continue",
    termsOfUse:
      "1. I have reviewed the instructions, precautions, and warnings found in the User Guide provided with the Omnipod 5, and I fully understand those instructions, precautions, and warnings.\n\n2. I understand that adequate training is critical to safe setup and use of the Omnipod 5.\n\n3. I understand that improper setup and/or use of the Omnipod 5 may cause under- or over- delivery of insulin, which may lead to hypoglycemia or hyperglycemia, and may put my health and safety at risk, and could lead to death.\n\n4. By using the Omnipod 5 without receiving training from a healthcare provider regarding proper setup and use of the Omnipod 5 (contrary to the warnings, precautions, and instructions provided herein and in the User Guide), I voluntarily accept any risk to my health and safety resulting from improper setup or use of the Omnipod 5.\n\n5. I hereby release the manufacturers, designers, distributors, and sellers of the Omnipod 5 from any and all liability in connection with any injury I may sustain resulting from improper setup or use of the Omnipod 5."
  },
  "es-US": {
    declined: "Se rechazó la formación.",
    confirm: "Debe confirmar lo siguiente:",
    accept: "Acepto los términos expuestos anteriormente.",
    continue: "Continuar",
    termsOfUse:
      "1. He revisado las instrucciones, precauciones y advertencias de esta Guía del usuario proporcionada con Omnipod 5, y comprendo por completo esas instrucciones, precauciones y advertencias.\n\n2. Comprendo que una capacitación adecuada es fundamental para la configuración y el uso seguros de Omnipod 5.\n\n3. Comprendo que la configuración y/o el uso inapropiado de Omnipod 5 podría causar una administración insuficiente o excesiva de insulina, lo que podría generar hipoglucemia o hiperglucemia, y poner mi salud y seguridad en riesgo, o incluso causarme la muerte.\n\n4. Si utilizo Omnipod 5 sin recibir capacitación de un proveedor de atención médica sobre la configuración y uso correctos de Omnipod 5 (en oposición a las advertencias, precauciones e instrucciones proporcionadas aquí y en la Guía del usuario), acepto voluntariamente la responsabilidad sobre cualquier riesgo para mi salud o seguridad que sea resultado de una configuración o uso incorrectos de Omnipod 5.\n\n5. Por la presente, exonero a los fabricantes, diseñadores, distribuidores y vendedores de Omnipod 5 toda responsabilidad relacionada con cualquier lesión que pudiera padecer como resultado de la configuración o uso incorrectos de Omnipod 5."
  },
  "de-DE": {
    declined: "Training has been declined.",
    confirm: "You must confirm the following:",
    accept: "I accept the terms above.",
    continue: "Continue",
    termsOfUse:
      "1. I have reviewed the instructions, precautions, and warnings found in the User Guide provided with the Omnipod 5, and I fully understand those instructions, precautions, and warnings.\n\n2. I understand that adequate training is critical to safe setup and use of the Omnipod 5.\n\n3. I understand that improper setup and/or use of the Omnipod 5. may cause under- or over- delivery of insulin, which may lead to hypoglycemia or hyperglycemia, and may put my health and safety at risk, and could lead to death.\n\n4. By using the Omnipod 5. without receiving training from a healthcare provider regarding proper setup and use of the Omnipod 5. (contrary to the warnings, precautions, and instructions provided herein and in the User Guide), I voluntarily accept any risk to my health and safety resulting from improper setup or use of the Omnipod 5..\n\n5. I hereby release the manufacturers, designers, distributors, and sellers of the Omnipod 5. from any and all liability in connection with any injury I may sustain resulting from improper setup or use of the Omnipod 5."
  },
  "nl-NL": {
    declined: "Training has been declined.",
    confirm: "You must confirm the following:",
    accept: "I accept the terms above.",
    continue: "Continue",
    termsOfUse:
      "1. I have reviewed the instructions, precautions, and warnings found in the User Guide provided with the Omnipod 5, and I fully understand those instructions, precautions, and warnings.\n\n2. I understand that adequate training is critical to safe setup and use of the Omnipod 5.\n\n3. I understand that improper setup and/or use of the Omnipod 5 may cause under- or over- delivery of insulin, which may lead to hypoglycemia or hyperglycemia, and may put my health and safety at risk, and could lead to death.\n\n4. By using the Omnipod 5 without receiving training from a healthcare provider regarding proper setup and use of the Omnipod 5 (contrary to the warnings, precautions, and instructions provided herein and in the User Guide), I voluntarily accept any risk to my health and safety resulting from improper setup or use of the Omnipod 5.\n\n5. I hereby release the manufacturers, designers, distributors, and sellers of the Omnipod 5 from any and all liability in connection with any injury I may sustain resulting from improper setup or use of the Omnipod 5."
  },
  "fr-FR": {
    declined: "Training has been declined.",
    confirm: "You must confirm the following:",
    accept: "I accept the terms above.",
    continue: "Continue",
    termsOfUse:
      "1. I have reviewed the instructions, precautions, and warnings found in the User Guide provided with the Omnipod 5, and I fully understand those instructions, precautions, and warnings.\n\n2. I understand that adequate training is critical to safe setup and use of the Omnipod 5.\n\n3. I understand that improper setup and/or use of the Omnipod 5 may cause under- or over- delivery of insulin, which may lead to hypoglycemia or hyperglycemia, and may put my health and safety at risk, and could lead to death.\n\n4. By using the Omnipod 5 without receiving training from a healthcare provider regarding proper setup and use of the Omnipod 5 (contrary to the warnings, precautions, and instructions provided herein and in the User Guide), I voluntarily accept any risk to my health and safety resulting from improper setup or use of the Omnipod 5.\n\n5. I hereby release the manufacturers, designers, distributors, and sellers of the Omnipod 5 from any and all liability in connection with any injury I may sustain resulting from improper setup or use of the Omnipod 5."
  },
};

export { strings };
