let strings = {
  "en-US": {
    segment: "Segment",
    basalRate: "Basal Rate:",
    cancel: "Cancel",
    save: "Save",
    edit: "Edit Segment",
    notAvailable: "Not available for the simulator.",
    ok: "OK",
    uHr: "U/hr"
  },
  "en-GB": {
    segment: "Segment",
    basalRate: "Basal Rate:",
    cancel: "Cancel",
    save: "Save",
    edit: "Edit Segment",
    notAvailable: "Not available for the simulator.",
    ok: "OK",
    uHr: "U/hr"
  },
  "de-DE": {
    segment: "Segment",
    basalRate: "Basalrate:",
    cancel: "Abbr.",
    save: "Speichern",
    edit: "Segment bearbeiten",
    notAvailable: "Nicht für den Simulator verfügbar.",
    ok: "OK",
    uHr: "E/Std."
  },
  "es-US": {
    segment: "Segmento",
    basalRate: "Tasa basal:",
    cancel: "Cancelar",
    save: "Guardar",
    edit: "Editar segmento",
    notAvailable: "No está disponible en el simulador.",
    ok: "Aceptar",
    uHr: "U/h"
  },
  "fr-FR": {
    segment: "Segment",
    basalRate: "Débit basal :",
    cancel: "Annuler",
    save: "Enregistrer",
    edit: "Modifier le segment",
    notAvailable: "Non disponible pour le simulateur.",
    ok: "OK",
    uHr: "U/h"
  },
  "nl-NL": {
    segment: "Segment",
    basalRate: "Basaalsnelheid:",
    cancel: "Annuleren",
    save: "Opslaan",
    edit: "Segment bewerken",
    notAvailable: "Niet beschikbaar voor de simulator.",
    ok: "OK",
    uHr: "E/u."
  }
};

export { strings };
