import React from 'react';
import { Button } from 'web/components/ios/Button';
import { useIntl } from 'react-intl';
import { messages } from '../BasalTempSettingsStack.messages';
import { useNavigation } from '@react-navigation/native';

export const MaxBasalRate = () => {
  const intl = useIntl();
  const { navigate } = useNavigation();

  return (
    <div className="flex justify-between flex-col flex-1 items-stretch bg-white">
      <div>
        <div className="flex flex-col px-2">
          <div className="text-black mt-[29px]">
            {intl.formatMessage(messages.upperLimitBasal, {
              separator: <br />,
            })}
          </div>

          <div className="mt-[29px] flex flex-row justify-between items-center">
            <div>
              <div>{intl.formatMessage(messages.maxBasalRate)}</div>
              <div className="text-ios-secondaryGray opacity-60">
                {intl.formatMessage(messages.maxBasalRateUnit)}
              </div>
            </div>
            <div className="text-ios-purple">{`5 ${intl.formatMessage(messages.basalRateUnit)}`}</div>
          </div>
        </div>
        <hr className="mt-1" />
        <div className="px-2 mt-[28px] text-small">
          {intl.formatMessage(messages.toSetMaxBasalRate, {
            separator: <br />,
          })}
        </div>
      </div>

      <div className="w-full mb-4 px-2">
        <Button
          className="w-full rounded-lg"
          onClick={() => navigate('BolusSettingsMain')}
          variant="colored"
        >
          {intl.formatMessage(messages.saveButton)}
        </Button>
      </div>
    </div>
  );
};
