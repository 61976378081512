let strings = {
  "en-US": {
    setTarget: "Set Target Glucose and Correct Above values",
    theseValue:
      "These values are used in both Automated and Manual Modes and can be set for different times of the day.",
    inAutomatedMode:
      "In Automated Mode, your basal insulin delivery will be adjusted automatically to bring you toward your",
    targetBG: "Target Glucose",
    value: "value.",
    inBothAutomated: "In both Automated and Manual Modes,",
    and: "and",
    correctAbove: "Correct Above",
    valuesAre: "values are used for bolus calculations.",
    cancel: "cancel",
    next: "next"
  },
  "en-GB": {
    setTarget: "Set Target Glucose and Correct Above values",
    theseValue:
      "These values are used in both Automated and Manual Modes and can be set for different times of the day.",
    inAutomatedMode:
      "In Automated Mode, your basal insulin delivery will be adjusted automatically to bring you toward your",
    targetBG: "Target Glucose",
    value: "value.",
    inBothAutomated: "In both Automated and Manual Modes,",
    and: "and",
    correctAbove: "Correct Above",
    valuesAre: "values are used for bolus calculations.",
    cancel: "cancel",
    next: "next"
  },
  "de-DE": {
    setTarget: "Glukose-Zielwert und Korrigieren Über-Wert festlegen",
    theseValue:
      "Diese Werte werden sowohl im Automatisierter als auch manuellen Modus verwendet und können für verschiedene Tageszeiten eingestellt werden.",
    inAutomatedMode:
      "Im Automatisierter Modus wird Ihre Basalinsulinabgabe automatisch angepasst, um Sie in die Nähe Ihres",
    targetBG: "Glukose-Zielwert",
    value: "zu bringen.",
    inBothAutomated:
      "Sowohl im Automatisierter als auch im manuellen Modus werden die Werte für",
    and: "und",
    correctAbove: "Korrigieren über",
    valuesAre: "für die Bolusberechnung verwendet.",
    cancel: "Abbr.",
    next: "Weiter"
  },
  "es-US": {
    setTarget: "Establecer la glucosa objetivo y los valores de corregir sobre",
    theseValue:
      "Estos valores se utilizan tanto en el Modo manual como en el automaticó, y se pueden establecer para diferentes momentos del día.",
    inAutomatedMode:
      "En Modo automaticó, la administración de insulina basal se ajustará automáticamente para que llegue a su valor de",
    targetBG: "glucosa objetivo",
    value: "",
    inBothAutomated:
      "Tanto en Modo automaticó como en Modo manual, los valores de",
    and: "y",
    correctAbove: "corregir sobre",
    valuesAre: "se utilizan para calcular el bolo.",
    cancel: "cancelar",
    next: "siguiente"
  },
  "fr-FR": {
    setTarget:
      "Définir les valeurs de Glucose cible et Corriger si supérieur à",
    theseValue:
      "Ces valeurs sont utilisées en Modes Automatisé et Manuel et peuvent être configurées pour différentes heures de la journée.",
    inAutomatedMode:
      "En Mode Automatisé, votre administration basale d'insuline sera ajustée automatiquement pour vous ramener vers votre valeur de",
    targetBG: "Glucose cible",
    value: ".",
    inBothAutomated: "En Modes Automatisé et Manuel, les valeurs",
    and: "et",
    correctAbove: "Corriger si supérieur à",
    valuesAre: "sont utilisées pour les calculs de bolus.",
    cancel: "annuler",
    next: "suivant"
  },
  "nl-NL": {
    setTarget:
      "Streefwaarde glucose en waarden voor Corrigeren boven instellen",
    theseValue:
      "Deze waarden worden zowel in de automatische als in de handmatige modus gebruikt en kunnen worden ingesteld voor verschillende tijdstippen van de dag.",
    inAutomatedMode:
      "In de Geautomatiseerde Modus wordt de basale insulinetoediening automatisch bijgesteld om u te brengen naar uw",
    targetBG: "Streefwaarde glucose",
    value: "waarde.",
    inBothAutomated: "In zowel de automatische als handmatige modus worden de",
    and: "en",
    correctAbove: "Corrigeren boven",
    valuesAre: "gebruikt voor de bolusberekeningen.",
    cancel: "annuleren",
    next: "volgende"
  }
};

export { strings };
